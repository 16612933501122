import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';

import { UploadedDocument } from '../../../models/UploadedDocument';
import { CreateAndLinkDocument } from '../global-files.actions';
import { DocumentDTO } from '../global-files.service';

@Component({
    selector: 'app-add-file-to-object-modal',
    templateUrl: './add-file-to-object-modal.component.html',
    styleUrls: ['./add-file-to-object-modal.component.scss'],
})
export class AddFileToObjectModalComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Input() document: UploadedDocument;
    @Input() objectId: string;

    fileUploadForm: FormGroup;

    constructor(public activeModal: NgbActiveModal, private fb: FormBuilder, private store: Store, private logger: NGXLogger, private toastr: ToastrService) {}

    ngOnInit(): void {
        this.createFileUploadForm();
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    private createFileUploadForm() {
        this.fileUploadForm = this.fb.group({
            url: [this.document?.url ? this.document.url : '', [Validators.required]],
            description: [''],
        });
    }

    createDocumentAndLink() {
        const documentDTO: DocumentDTO = {
            objectId: this.objectId,
            url: this.fileUploadForm.get('url').value,
            description: this.fileUploadForm.get('description').value,
        };
        this.subscriptions.add(
            this.store.dispatch(new CreateAndLinkDocument(documentDTO)).subscribe({
                next: () => {
                    this.logger.debug('Successfully uploaded and linked document');
                    this.toastr.success('Uploaded and linked new document');
                    this.activeModal.close();
                },
                error: () => {
                    this.logger.debug('Failed to upload or link document');
                    this.toastr.error('Oops, something went wrong, Please try again later...');
                },
            }),
        );
    }
}
