<div class="modal-wrapper">
    <div class="modal-header">
        <h2
            class="modal-title"
            id="exampleModalLabel">
            Link product
        </h2>
        <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="activeModal.close()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <div *ngIf="(products$ | async)?.length !== 0">
            <div class="form-group m-b-2">
                <label>Choose product</label>
                <div class="d-flex">
                    <div class="ng-autocomplete">
                        <ng-autocomplete
                            [(ngModel)]="selectedProduct"
                            [data]="availableProducts"
                            autocomplete="off"
                            [searchKeyword]="keyword"
                            (selected)="selectEvent($event)"
                            [itemTemplate]="itemTemplate"
                            placeholder="Choose product">
                        </ng-autocomplete>

                        <ng-template
                            #itemTemplate
                            let-item>
                            <a [innerHTML]="item.title"></a>
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button
            class="button button--primary"
            (click)="linkProduct()">
            Link product
        </button>
    </div>
</div>
