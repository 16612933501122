export class AddRelatedEmployee {
    static readonly type = '[Related Employee] Add related employee';
    constructor(public uuid: string, public employees: string) {}
}

export class FetchRelatedEmployees {
    static readonly type = '[Related Employee] Fetch related employees';
    constructor() {}
}

export class DeleteRelatedEmployee {
    static readonly type = '[Related Employee] Delete related employee';
    constructor(public uuid: string, public employeeUuid: string) {}
}
