<div class="dashboard__tile__title">
    <span>Latest comments</span>
    <button
        class="button button--icon button--small"
        type="button">
        <i class="uil uil-arrow-right"></i>
    </button>
</div>
<div class="dashboard__tile__content">
    <div class="table__overflow">
        <table
            [dataSource]="dataSource"
            [hidden]="!(this.dataSource?.data?.length > 0)"
            mat-table
            matSort
            matSortActive="createdOn"
            matSortDirection="desc">
            <ng-container matColumnDef="creator.firstName">
                <th
                    *matHeaderCellDef
                    mat-header-cell
                    mat-sort-header>
                    User
                </th>
                <td
                    *matCellDef="let row"
                    mat-cell>
                    <app-pill-user [userId]="row.creatorUuid"></app-pill-user>
                </td>
            </ng-container>

            <ng-container matColumnDef="content">
                <th
                    *matHeaderCellDef
                    mat-header-cell>
                    Content
                </th>
                <td
                    *matCellDef="let row"
                    mat-cell>
                    <small
                        [innerHTML]="row.content"
                        class="color--grey"></small>
                </td>
            </ng-container>

            <ng-container matColumnDef="category">
                <th
                    *matHeaderCellDef
                    mat-header-cell
                    mat-sort-header>
                    Category
                </th>
                <td
                    *matCellDef="let row"
                    mat-cell>
                    <span>{{ row.category }}</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="commentable">
                <th
                    *matHeaderCellDef
                    mat-header-cell>
                    Reference
                </th>
                <td
                    *matCellDef="let row"
                    mat-cell>
                    <app-pill-project
                        *ngIf="row.category === 'project'"
                        [projectUuid]="row.commentableId"></app-pill-project>
                    <app-pill-employee
                        *ngIf="row.category === 'employee'"
                        [employeeId]="row.commentableId"></app-pill-employee>
                    <app-pill-contact
                        *ngIf="row.category === 'contactperson'"
                        [contactPersonUuid]="row.commentableId"></app-pill-contact>
                    <app-pill-product
                        *ngIf="row.category === 'product'"
                        [productUuid]="row.commentableId"></app-pill-product>
                    <app-pill-partner
                        *ngIf="row.category === 'partner'"
                        [partnerUuid]="row.commentableId"></app-pill-partner>
                    <app-pill-lead
                        *ngIf="row.category === 'lead'"
                        [leadUuid]="row.commentableId"></app-pill-lead>
                    <app-pill-crmdeal
                        *ngIf="row.category === 'crmdeal'"
                        [dealUuid]="row.commentableId"></app-pill-crmdeal>
                    <app-pill-customer
                        *ngIf="row.category === 'customer'"
                        [customerUuid]="row.commentableId">
                    </app-pill-customer>
                </td>
            </ng-container>

            <ng-container matColumnDef="createdOn">
                <th
                    *matHeaderCellDef
                    mat-header-cell
                    mat-sort-header>
                    Created
                </th>
                <td
                    *matCellDef="let row"
                    mat-cell>
                    <span>{{ row.createdOn | date: 'short':timezone | amTimeAgo }}</span>
                </td>
            </ng-container>

            <tr
                *matHeaderRowDef="displayedColumns"
                mat-header-row></tr>
            <tr
                *matRowDef="let row; columns: displayedColumns"
                mat-row></tr>
        </table>
    </div>

    <div
        *ngIf="this.noComments && !this.isLoading"
        class="dashboard__tile__content">
        <app-message-empty
            [icon]="'../../assets/img/open-box.png'"
            [title]="'There are no new comments...'"></app-message-empty>
    </div>

    <div *ngIf="this.isLoading">
        <app-loading-message></app-loading-message>
    </div>
</div>
