import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import MailingObject, { getDescription, getMailingTypeInfo, getMailingTypesByModule } from 'src/app/models/MailingObject';
import { Module } from 'src/app/models/Module';
import { FetchMailingObjects, FetchModules, RemoveMailFromModule } from 'src/app/modules/module.actions';
import { ModuleState } from 'src/app/modules/module.state';
import { ConfirmationModalComponent } from 'src/app/shared/confirmation-modal/confirmation-modal.component';
import { UserState } from 'src/app/users/user.state';

import { AddMailToMailinglistModalComponent } from './add-mail-to-mailinglist-modal/add-mail-to-mailinglist-modal.component';

@Component({
    selector: 'app-mail-setup',
    templateUrl: './mail-setup.component.html',
    styleUrls: ['./mail-setup.component.scss'],
})
export class MailSetupComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Select(ModuleState.Modules) modules$: Observable<Module[]>;
    @Select(ModuleState.MailingObjectsPerModule)
    mailingObjectsPerModule$: Observable<{ module: string; mailingObjects: MailingObject[] }[]>;
    @Select(UserState.hasUserOwnerRole) isOwner$: Observable<boolean>;

    displayedModules: Module[];
    sessionModules: string[];
    isLoading = true;
    mailingObjectsPerModule: {
        module: string;
        mailingObjects: MailingObject[];
    }[];

    displayedColumns: string[] = ['mail', 'action', 'timing', 'edit'];
    dataSource: MatTableDataSource<MailingObject> = new MatTableDataSource<MailingObject>();
    @ViewChild(MatSort) sort: MatSort;

    constructor(private modalService: NgbModal, private store: Store, private logger: NGXLogger, private toastr: ToastrService) {}

    ngOnInit(): void {
        this.sessionModules = JSON.parse(sessionStorage.getItem('allowedModules'));
        this.subscriptions.add(
            this.modules$.subscribe((modules) => {
                if (modules == null) {
                    this.store.dispatch(new FetchModules());
                } else {
                    this.store.dispatch(new FetchMailingObjects());
                    this.displayedModules = modules.filter((m) => this.sessionModules.find((k) => k == m.key));
                }
            }),
        );

        this.mailingObjectsPerModule$.subscribe((objects) => {
            if (objects != null && objects.every((o) => o.mailingObjects != undefined)) {
                this.mailingObjectsPerModule = objects;
                this.isLoading = false;
            } else {
                this.store.dispatch(new FetchMailingObjects());
            }
        });
    }

    getMailObjectsByModuleKey(moduleKey: string): MailingObject[] {
        const mailingObjects = this.mailingObjectsPerModule.find((mo) => mo.module == moduleKey);
        if (mailingObjects) {
            return mailingObjects.mailingObjects;
        }
        return [];
    }

    openAddMailModal(module: Module) {
        this.logger.debug('Opening AddMailToMailinglistModal');
        const modalRef = this.modalService.open(AddMailToMailinglistModalComponent, { windowClass: 'modal-prompt', animation: false });
        modalRef.componentInstance.module = module;
    }

    deleteMail(module: Module, mail: MailingObject) {
        this.logger.debug('Opening confirmationModal');
        const modalRef = this.modalService.open(ConfirmationModalComponent, {
            windowClass: 'modal-prompt',
            animation: false,
        });
        modalRef.componentInstance.type = 'Delete';
        modalRef.componentInstance.title = 'Delete email';
        const text = module != null ? module.title : 'global';
        modalRef.componentInstance.message =
            'Are you sure you want to remove this email from the ' + text + ' mailing list?\nThis email will no longer receive notifications.';
        this.subscriptions.add(
            modalRef.componentInstance.closeEvent.subscribe((val) => {
                if (val) {
                    this.logger.debug('Attempt to remove mail');
                    this.store.dispatch(new RemoveMailFromModule(module.uuid, mail.uuid)).subscribe(
                        (res) => {
                            this.toastr.info('Successfully removed mail.');
                        },
                        (err) => {
                            this.logger.error('Failed to remove mail from module');
                            this.toastr.error('Oops, something went wrong. Please try again later.');
                        },
                    );
                }
            }),
        );
    }

    getTypeDescription(mailingTypeString) {
        return getMailingTypeInfo(mailingTypeString)[1];
    }

    getCronDescription(cron) {
        const description = getDescription(cron);
        if (description) {
            return description;
        }
        return 'none';
    }

    getMailingTypesByModule(name) {
        return getMailingTypesByModule(name);
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
