<div class="module__logo">
    <img src="../../../assets/img/logo-exact.png" />
</div>

<app-loading-message *ngIf="isLoading"></app-loading-message>

<div *ngIf="!isLoading">
    <h3 *ngIf="!(loggedIn$ | async)">Currently not logged in with Exact</h3>

    <div *ngIf="!(loggedIn$ | async)">
        <a
            [href]="authUrl"
            target="_blank"
            class="button button--primary w-100"
            >Login with Exact</a
        >
    </div>

    <div *ngIf="loggedIn$ | async">
        <h1>Logged In Successfully</h1>
        <button></button>
        <button
            (click)="logout()"
            class="button button--primary w-100">
            Logout
        </button>
    </div>
</div>
