import { Component, EventEmitter, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-update-timeslot-save-modal',
    templateUrl: './update-timeslot-save-modal.component.html',
    styleUrls: ['./update-timeslot-save-modal.component.scss'],
})
export class UpdateTimeslotSaveModalComponent implements OnInit {
    public closeEvent: EventEmitter<boolean> = new EventEmitter();

    constructor(public activeModal: NgbActiveModal) {}

    ngOnInit(): void {}

    confirm() {
        this.closeEvent.emit(true);
        this.activeModal.close();
    }

    decline() {
        this.closeEvent.emit(false);
        this.activeModal.close();
    }
}
