import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbDropdownModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TagInputModule } from 'ngx-chips';
import { OrderModule } from 'ngx-order-pipe';
import { NgPipesModule } from 'ngx-pipes';
import { SortablejsModule } from 'ngx-sortablejs';
import { NgxTrimDirectiveModule } from 'ngx-trim-directive';

import { SharedModule } from '../shared/shared.module';
import { AddCampaignModalComponent } from './add-campaign-modal/add-campaign-modal.component';
import { AddTagModalComponent } from './add-tag-modal/add-tag-modal.component';
import { BatchAddLeadsComponent } from './batch-add-leads/batch-add-leads.component';
import { MailchimpCampaignListComponent } from './mailchimp-campaign-list/mailchimp-campaign-list.component';
import { MailchimpCampaignReportComponent } from './mailchimp-campaign-report/mailchimp-campaign-report.component';
import { MailchimpOverviewComponent } from './mailchimp-overview/mailchimp-overview.component';
import { MailchimpRecipientListListComponent } from './mailchimp-recipient-list-list/mailchimp-recipient-list-list.component';
import { MailchimpRecipientsModalComponent } from './mailchimp-recipients-modal/mailchimp-recipients-modal.component';
import { MailchimpTagListComponent } from './mailchimp-tag-list/mailchimp-tag-list.component';
import { MailchimpTemplateListComponent } from './mailchimp-template-list/mailchimp-template-list.component';
import { MailchimpTemplateThumbnailComponent } from './mailchimp-template-thumbnail/mailchimp-template-thumbnail.component';
import { routing } from './mailchimp.routing';

@NgModule({
    declarations: [
        MailchimpOverviewComponent,
        MailchimpCampaignListComponent,
        MailchimpRecipientListListComponent,
        MailchimpCampaignReportComponent,
        MailchimpRecipientsModalComponent,
        MailchimpTemplateListComponent,
        MailchimpTemplateThumbnailComponent,
        AddCampaignModalComponent,
        BatchAddLeadsComponent,
        MailchimpTagListComponent,
        AddTagModalComponent,
    ],
    imports: [
        CommonModule,
        SharedModule,
        routing,
        NgbModule,
        NgbDropdownModule,
        NgxTrimDirectiveModule,
        ReactiveFormsModule,
        NgPipesModule,
        OrderModule,
        TagInputModule,
        SortablejsModule,
    ],
    exports: [MailchimpOverviewComponent],
})
export class MailchimpModule {}
