<form
    [formGroup]="warehouseFormGroup"
    (ngSubmit)="addWarehouse()"
    class="modal-wrapper">
    <div class="modal-header">
        <h2
            class="modal-title"
            id="exampleModalLabel">
            New warehouse
        </h2>
        <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="activeModal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <div class="row">
            <div class="col">
                <div class="form-group required m-b-2">
                    <label>Title</label>
                    <input
                        type="text"
                        placeholder="Title"
                        formControlName="title"
                        (focus)="hideErrorMessage()"
                        trim="blur"
                        #focussed />
                    <span
                        class="form-error"
                        *ngIf="warehouseFormGroup.controls.title.errors && inputIsWrong"
                        >Invalid warehouse title</span
                    >
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="form-group required m-b-2">
                    <label>Short name</label>
                    <input
                        type="text"
                        placeholder="Short Name"
                        formControlName="shortName"
                        (focus)="hideErrorMessage()"
                        trim="blur"
                        #focussed />
                    <span
                        class="form-error"
                        *ngIf="warehouseFormGroup.controls.shortName.errors && inputIsWrong"
                        >Invalid warehouse title</span
                    >
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="form-group m-b-2">
                    <label>Korte omschrijving</label>
                    <textarea
                        class="resize-vertical"
                        formControlName="description"
                        placeholder="Description"
                        (keyup)="onDescriptionKeyUp()"
                        trim="blur"></textarea>
                    <small
                        ><span *ngIf="currentAmountOfDescriptionCharacters == null">0</span>{{ currentAmountOfDescriptionCharacters }}/{{
                            DESCRIPTION_MAX_LENGTH
                        }}</small
                    >
                    <span
                        class="form-error"
                        *ngIf="descriptionIsTooLong"
                        >Message cannot contain more than 1000 characters</span
                    >
                </div>
            </div>
        </div>

        <div class="form-group m-b-2">
            <label>Tags</label>
            <app-global-tags [type]="'warehouse'"></app-global-tags>
        </div>

        <h3 class="m-t-4 m-b-4">Address</h3>

        <div formGroupName="address">
            <div class="m-b-2">
                <div class="row">
                    <div class="col-8">
                        <div class="form-group required">
                            <label>Street</label>
                            <input
                                type="text"
                                placeholder="Street*"
                                formControlName="street"
                                (focus)="hideErrorMessage()"
                                trim="blur" />
                            <span
                                class="form-error"
                                *ngIf="warehouseFormGroup.get('address.street').errors && inputIsWrong"
                                >Street is required</span
                            >
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="form-group required">
                            <label>Number</label>
                            <input
                                type="number"
                                placeholder="Number*"
                                formControlName="number"
                                (focus)="hideErrorMessage()" />
                            <span
                                class="form-error"
                                *ngIf="warehouseFormGroup.get('address.number').errors && inputIsWrong"
                                >Number is required</span
                            >
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <label>Box</label>
                            <input
                                type="text"
                                placeholder="Box"
                                formControlName="bus"
                                trim="blur" />
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <label>Ext</label>
                            <input
                                type="number"
                                placeholder="Ext"
                                formControlName="ext" />
                        </div>
                    </div>
                </div>
            </div>

            <div class="m-b-2">
                <div class="row">
                    <div class="col-4">
                        <div class="form-group required">
                            <label>Zipcode</label>
                            <input
                                type="text"
                                placeholder="Zipcode*"
                                formControlName="postal"
                                (focus)="hideErrorMessage()"
                                trim="blur" />
                            <span
                                class="form-error"
                                *ngIf="warehouseFormGroup.get('address.postal').errors && inputIsWrong"
                                >Zipcode is required</span
                            >
                        </div>
                    </div>
                    <div class="col-8">
                        <div class="form-group required">
                            <label>City</label>
                            <input
                                type="text"
                                placeholder="City*"
                                formControlName="city"
                                (focus)="hideErrorMessage()"
                                trim="blur" />
                            <span
                                class="form-error"
                                *ngIf="warehouseFormGroup.get('address.city').errors && inputIsWrong"
                                >City is required</span
                            >
                        </div>
                    </div>
                </div>
            </div>

            <div
                class="form-group m-b-2 required"
                formGroupName="country">
                <label>Country</label>
                <select
                    formControlName="iso2"
                    (focus)="hideErrorMessage()">
                    <option
                        *ngFor="let country of allCountries"
                        [ngValue]="country['alpha-2']">
                        {{ country.name }}
                    </option>
                </select>
                <span
                    class="form-error"
                    *ngIf="warehouseFormGroup.get('address.country.iso2').errors && inputIsWrong"
                    >Country is required</span
                >
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <button
            type="button"
            class="button button--outline"
            (click)="activeModal.close()">
            Close
        </button>
        <button
            type="submit"
            [ngClass]="{ 'button--loading': loading }"
            class="button button--primary">
            Create warehouse
        </button>
    </div>
</form>
