import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subscription } from 'rxjs';
import { Partner } from 'src/app/models/Partner';
import { PartnerDetailsModalComponent } from 'src/app/partners/partner-details-modal/partner-details-modal.component';
import { PartnerState } from 'src/app/partners/partners.state';

import { FetchPartners } from '../../../partners/partners.actions';

@Component({
    selector: 'app-pill-partner',
    templateUrl: './pill-partner.component.html',
    styleUrls: ['./pill-partner.component.scss'],
})
export class PillPartnerComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();
    @Select(PartnerState.getPartners) partners$: Observable<Partner[]>;
    @Input() partner: Partner;
    @Input() partnerUuid: string;
    @Input() title: string;
    fetchedPartner: Partner;
    isLoaded = false;
    loggedIn = false;

    constructor(private modalService: NgbModal, private store: Store, private logger: NGXLogger) {}

    ngOnInit(): void {
        this.loggedIn = localStorage.getItem('token') != null;
        if (!this.partner && this.title && this.partnerUuid) {
            this.isLoaded = true;
        } else {
            if (!this.partner && this.partnerUuid && typeof this.partnerUuid != 'undefined') {
                this.subscriptions.add(
                    this.partners$.subscribe((partners) => {
                        if (!partners) {
                            this.logger.debug('fetching partners in pill-partners');
                            // this.store.dispatch(new FetchPartnerById(this.partnerUuid));
                            this.store.dispatch(new FetchPartners());
                            return;
                        }

                        this.partner = partners.find((m) => m.uuid == this.partnerUuid);
                        this.isLoaded = true;
                    }),
                );
            } else if (this.partner) this.isLoaded = true;
        }
    }

    openPartnerDetailsModal() {
        if (this.loggedIn) {
            this.logger.debug('Opening partnerDetailModal');
            const modalRef = this.modalService.open(PartnerDetailsModalComponent, {
                windowClass: 'modal-huge',
                animation: false,
            });
            this.partner ? (modalRef.componentInstance.partner = this.partner) : (modalRef.componentInstance.partnerUuid = this.partnerUuid);
        }
    }

    getTitle(): string {
        return this.partner ? this.partner.title : this.title;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
