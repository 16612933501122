<app-app-holder>
    <app-navbar></app-navbar>

    <app-sidebar>
        <app-comanage-subnav></app-comanage-subnav>
    </app-sidebar>

    <app-content>
        <app-comanage-not-logged-in (credentialsIsset)="checkCredentials($event)"></app-comanage-not-logged-in>

        <div>
            <div class="app__title">
                <h1>
                    CoManage Contacts
                    <span *ngIf="this.dataSource?.data">({{ this.dataSource.data.length }})</span>
                </h1>
            </div>

            <app-searchbar
                [(value)]="filter"
                (valueChange)="applyFilter($event)"></app-searchbar>

            <div *ngIf="!this.dataSource?.data">
                <app-loading-message></app-loading-message>
            </div>

            <div
                class="m-t-4"
                *appIsPermitted="{
                    module: 'COMANAGE',
                    operator: 'AND',
                    actions: ['READ']
                }">
                <div
                    class="table__overflow"
                    *ngIf="coManageContacts$ | async">
                    <table
                        mat-table
                        [dataSource]="dataSource"
                        matSort
                        matSortActive="name"
                        matSortDirection="asc">
                        <ng-container matColumnDef="name">
                            <th
                                mat-header-cell
                                *matHeaderCellDef
                                class="td-small"
                                mat-sort-header>
                                Name
                            </th>
                            <td
                                mat-cell
                                *matCellDef="let row">
                                <span>{{ row.name }}</span>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="email">
                            <th
                                mat-header-cell
                                *matHeaderCellDef
                                mat-sort-header>
                                Email
                            </th>
                            <td
                                mat-cell
                                *matCellDef="let row">
                                <app-pill-mailto
                                    *ngIf="row.email"
                                    [mailtoEmail]="row.email"></app-pill-mailto>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="telephone">
                            <th
                                mat-header-cell
                                *matHeaderCellDef
                                mat-sort-header>
                                Telephone
                            </th>
                            <td
                                mat-cell
                                *matCellDef="let row">
                                <app-pill-telephone
                                    *ngIf="row.telephone"
                                    [contactPhonenumber]="row.telephone"></app-pill-telephone>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="type">
                            <th
                                mat-header-cell
                                *matHeaderCellDef
                                mat-sort-header>
                                Type
                            </th>
                            <td
                                mat-cell
                                *matCellDef="let row">
                                <span *ngIf="row.customer">Customer</span>
                                <span *ngIf="row.supplier">Supplier</span>
                                <span *ngIf="!row.customer && !row.supplier">Contact</span>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="number">
                            <th
                                mat-header-cell
                                *matHeaderCellDef
                                mat-sort-header>
                                Number
                            </th>
                            <td
                                mat-cell
                                *matCellDef="let row">
                                <span>
                                    <span *ngIf="row.customer">Customer # {{ row.customer_number }}</span>
                                    <span *ngIf="row.supplier">Supplier # {{ row.supplier_number }}</span>
                                </span>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="edit">
                            <th
                                mat-header-cell
                                *matHeaderCellDef
                                class="td-small"></th>
                            <td
                                mat-cell
                                *matCellDef="let row">
                                <span
                                    ngbDropdown
                                    class="dropdown"
                                    *appIsPermitted="{
                                        module: 'COMANAGE',
                                        operator: 'OR',
                                        actions: ['EDIT']
                                    }">
                                    <button
                                        type="button"
                                        ngbDropdownToggle
                                        class="dropdown-toggle button button--icon button--small"
                                        id="dropdownMenuButton"></button>
                                    <span
                                        ngbDropdownMenu
                                        class="dropdown-menu dropdown-menu-right"
                                        aria-labelledby="dropdownMenuButton">
                                        <a
                                            class="dropdown-item"
                                            (click)="addToMailchimp(row)"
                                            >Add to mailchimp</a
                                        >
                                    </span>
                                </span>
                            </td>
                        </ng-container>

                        <tr
                            mat-header-row
                            *matHeaderRowDef="displayedColumns"></tr>
                        <tr
                            mat-row
                            *matRowDef="let row; columns: displayedColumns"></tr>

                        <tr *matNoDataRow>
                            <td colspan="6">No Contacts matching the filter "{{ filter }}"</td>
                        </tr>
                    </table>
                </div>
            </div>
            <mat-paginator
                [pageSizeOptions]="[15]"
                showFirstLastButtons></mat-paginator>
        </div>
    </app-content>
</app-app-holder>
