<a
    (click)="$event.stopPropagation(); openInvoiceInformationModal()"
    class="pill">
    <i class="las la-file-invoice-dollar"></i>
    <ng-container *ngIf="!yukiDocumentId; else withOnlyId">
        {{ title | abbreviation}}
    </ng-container>

    <ng-template #withOnlyId>
        <ng-container *ngIf="yukiDocument; else loading">
            {{yukiDocument.subject | abbreviation}}
        </ng-container>
    </ng-template>
</a>

<ng-template #loading>
    <app-loading-message></app-loading-message>
</ng-template>
