import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import MailchimpTagModel from 'src/app/models/MailchimpTagModel';
import RecipientListModel from 'src/app/models/RecipientListModel';

import { AddTagModalComponent } from '../add-tag-modal/add-tag-modal.component';
import { FetchAudience, FetchTags, RemoveTag } from '../mailchimp.actions';
import { MailchimpService } from '../mailchimp.service';
import { MailchimpState } from '../mailchimp.state';

@Component({
    selector: 'app-mailchimp-tag-list',
    templateUrl: './mailchimp-tag-list.component.html',
    styleUrls: ['./mailchimp-tag-list.component.scss'],
})
export class MailchimpTagListComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Select(MailchimpState.audiences) lists$: Observable<RecipientListModel[]>;
    @Select(MailchimpState.tags) tags$: Observable<MailchimpTagModel[]>;
    filter: string;
    lists: RecipientListModel[];
    isset = true;
    loading = true;

    constructor(private mailchimpService: MailchimpService, private modalService: NgbModal, private toastr: ToastrService, private store: Store) {}

    ngOnInit(): void {
        this.subscriptions.add(
            this.mailchimpService.checkIfApiKeyExists().subscribe((e) => {
                if (e.key) {
                    this.lists$.subscribe((l) => {
                        if (l.length != 0) {
                            this.loading = false;
                        }
                    });
                    this.store.dispatch(new FetchAudience()).subscribe(() => {
                        this.lists$.subscribe((l) => {
                            this.lists = l;
                            l.forEach((el) => {
                                this.store.dispatch(new FetchTags(el.id)).subscribe(() => (this.loading = false));
                            });
                        });
                    });
                }
            }),
        );
    }

    addTagModal() {
        const modalRef = this.modalService.open(AddTagModalComponent, {
            windowClass: 'modal-prompt',
        });
        modalRef.componentInstance.lists = this.lists;
    }

    removeTag(tag: MailchimpTagModel) {
        this.lists.forEach((list) => {
            this.subscriptions.add(
                this.mailchimpService.getTags(list.id).subscribe((t) => {
                    t.forEach((el) => {
                        if (el.id == tag.id) {
                            this.store.dispatch(new RemoveTag(list.id, tag.id)).subscribe(() => {
                                this.toastr.success('Tag deleted successfully!');
                            });
                        }
                    });
                }),
            );
        });
    }

    checkKey(set: boolean) {
        this.isset = set;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
