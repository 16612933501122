<div class="list__item pointer">
    <div
        class="list__item__icon document__icon document__icon--folder"
        (click)="navigate.emit(directory.uuid)">
        <i class="las la-folder"></i>
    </div>
    <div
        class="list__item__content"
        (click)="navigate.emit(directory.uuid)">
        <strong>{{ directory.name }}</strong>
    </div>
    <div class="list__item__extra">
        <span ngbDropdown>
            <button
                ngbDropdownToggle
                class="button button--icon button--dropdown button--small"></button>
            <span
                ngbDropdownMenu
                class="dropdown-menu dropdown-menu-right"
                aria-labelledby="dropdownMenuButton">
                <a
                    class="dropdown-item color--red"
                    *appIsPermitted="{
                        module: 'DOCUMENTS',
                        operator: 'AND',
                        actions: ['DELETE']
                    }"
                    (click)="delete($event)"
                    >Delete</a
                >
            </span>
        </span>
    </div>
</div>
