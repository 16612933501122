<app-app-holder>
    <app-navbar></app-navbar>

    <app-sidebar>
        <app-mailchimp-overview></app-mailchimp-overview>
    </app-sidebar>

    <app-content
        *appIsPermitted="{
            module: 'MAILCHIMP',
            operator: 'AND',
            actions: ['READ']
        }">
        <app-mailchimp-not-logged-in (keyIsset)="checkKey($event)"></app-mailchimp-not-logged-in>

        <div *ngIf="isset">
            <div class="app__title">
                <h1>Mailchimp - Templates ({{ (templates$ | async).length }})</h1>
            </div>

            <app-searchbar [(value)]="filter"></app-searchbar>

            <div class="m-t-4">
                <div *ngIf="loading">
                    <app-loading-message></app-loading-message>
                </div>
                <table
                    class="table-view"
                    *ngIf="!loading">
                    <tr>
                        <th>Template name</th>
                        <th>Category</th>
                        <!--<th>Type</th>-->
                    </tr>
                    <tr *ngFor="let template of templates$ | async | orderBy: 'name':false:true | filterBy: ['name', 'createdBy']:filter">
                        <td
                            *ngIf="template.name"
                            (click)="openTemplateThumbnail(template)">
                            {{ template.name }}
                        </td>
                        <td>
                            <span *ngIf="template.category">{{ template.category }}</span>
                        </td>
                        <!--<td><span *ngIf="template.type">{{template.type}}</span></td>
                        <td>
                            <span ngbDropdown>
                                <button ngbDropdownToggle class="button button--icon button--dropdown button--small">
                                </button>
                                <span ngbDropdownMenu class="dropdown-menu dropdown-menu-right"
                                    aria-labelledby="dropdownMenuButton"> 
                                </span>
                            </span>
                        </td>-->
                    </tr>
                </table>
            </div>
        </div>
    </app-content>
</app-app-holder>
