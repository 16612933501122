import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { AuthenticationService } from '../authentication/authentication.service';
import { VeldaBrand } from '../models/VeldaBrand';
import { VeldaBrandDTO } from '../models/VeldaBrandDTO';

@Injectable({
    providedIn: 'root',
})
export class VeldaArticlesService {
    httpOptions = {};

    constructor(private httpClient: HttpClient, private authenticationService: AuthenticationService) {
        this.httpOptions = authenticationService.getHeaders();
    }

    getAllBrands(): Observable<VeldaBrand[]> {
        return this.httpClient.get<VeldaBrand[]>(`${environment.baseUrl}/velda-articles/brands`, this.httpOptions);
    }

    addBrand(brand: VeldaBrandDTO): Observable<VeldaBrand> {
        return this.httpClient.post<VeldaBrand>(`${environment.baseUrl}/velda-articles/brands`, brand, this.httpOptions);
    }

    editBrand(brandUuid: string, brand: VeldaBrandDTO): Observable<VeldaBrand> {
        return this.httpClient.put<VeldaBrand>(`${environment.baseUrl}/velda-articles/brands/${brandUuid}`, brand, this.httpOptions);
    }

    deleteBrand(brandUuid: string): Observable<string> {
        return this.httpClient.delete<string>(`${environment.baseUrl}/velda-articles/brands/${brandUuid}`, this.httpOptions);
    }
}
