import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-pill-colored-string',
    templateUrl: './pill-colored-string.component.html',
    styleUrls: ['./pill-colored-string.component.scss'],
})
export class PillColoredStringComponent implements OnInit {
    @Input() color: string;
    @Input() label: string;
    style;

    ngOnInit(): void {
        this.style = {
            background: this.color,
            borderRadius: '8rem',
            width: '1rem',
            height: '1rem',
        };
    }
}
