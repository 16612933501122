import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import CrmStage, { CRMStageType } from 'src/app/models/CrmStage';

import { AddCrmStage, UpdateCrmStage } from '../crm.actions';

@Component({
    selector: 'app-add-crmstage-modal',
    templateUrl: './add-crmstage-modal.component.html',
    styleUrls: ['./add-crmstage-modal.component.scss'],
})
export class AddCrmstageModalComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Input() stage: CrmStage;

    stageForm: FormGroup;
    descriptionTooLong = false;
    currentAmountOfExtraInfoCharacters = 0;
    descriptionMaxLength = 1000;

    constructor(private store: Store, private fb: FormBuilder, public activeModal: NgbActiveModal, private toastr: ToastrService, private logger: NGXLogger) {}

    ngOnInit(): void {
        if (this.stage != undefined) {
            this.stageForm = this.fb.group({
                name: [this.stage.name, [Validators.required]],
                probability: [this.stage.probability, [Validators.required, Validators.max(100), Validators.min(0)]],
                rottingIn: [this.stage.rottingIn, [Validators.required, Validators.min(0)]],
                type: [this.stage.type, [Validators.required]],
                description: [this.stage.description, [Validators.maxLength(this.descriptionMaxLength)]],
            });
        } else {
            this.stageForm = this.fb.group({
                name: ['', [Validators.required]],
                probability: ['', [Validators.required, Validators.max(100), Validators.min(0)]],
                rottingIn: ['', [Validators.required, Validators.min(0)]],
                type: [CRMStageType.NORMAL, [Validators.required]],
                description: ['', [Validators.maxLength(this.descriptionMaxLength)]],
            });
        }
    }

    public get crmStageType(): typeof CRMStageType {
        return CRMStageType;
    }

    addStage($event) {
        if (this.stage != undefined) {
            const stage = { ...this.stage };
            stage.name = this.stageForm.get('name').value;
            stage.probability = this.stageForm.get('probability').value;
            stage.rottingIn = this.stageForm.get('rottingIn').value;
            stage.type = this.stageForm.get('type').value;
            stage.description = this.stageForm.get('description').value;

            this.logger.debug('Attempt to update cfmStage');
            this.subscriptions.add(
                this.store.dispatch(new UpdateCrmStage(stage)).subscribe(
                    (res) => {
                        this.logger.debug('Succesfully updated cfmStage');
                        this.toastr.success('Stage successfully updated.');
                        this.activeModal.close();
                    },
                    (err) => {
                        this.logger.error('Failed to update crmDeal');
                        this.toastr.error('Oops, something went wrong. Please try again later.');
                    },
                ),
            );
        } else {
            const crmStage: CrmStage = {
                uuid: null,
                name: this.stageForm.get('name').value,
                probability: this.stageForm.get('probability').value,
                rottingIn: this.stageForm.get('rottingIn').value,
                sequence: null,
                type: this.stageForm.get('type').value,
                crmDeals: [],
                description: this.stageForm.get('description').value,
            };
            this.logger.debug('Attempt to add cfmStage');
            this.subscriptions.add(
                this.store.dispatch(new AddCrmStage(crmStage)).subscribe(
                    (res) => {
                        this.logger.debug('Succesfully added crmStage');
                        this.toastr.success('Stage successfully added.');
                        this.activeModal.close();
                    },
                    (err) => {
                        this.logger.debug('Failed to add crmStage');
                        this.toastr.error('Oops, something went wrong. Please try again later.');
                    },
                ),
            );
        }
    }

    onDescriptionKeyUp() {
        this.descriptionTooLong = false;
        this.currentAmountOfExtraInfoCharacters = this.stageForm.controls.description.value.length;
        if (this.currentAmountOfExtraInfoCharacters > this.descriptionMaxLength) {
            this.descriptionTooLong = true;
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
