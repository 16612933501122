import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { AuthenticationService } from '../authentication/authentication.service';
import Budget from '../models/Budget';
import BudgetExpenditure from '../models/BudgetExpenditure';
import BudgetTemplate from '../models/BudgetTemplate';
import EmployeeWithBudgets from '../models/EmployeeWithBudgets';
import { EmployeeWithCosts } from '../models/EmployeeWithCosts';

@Injectable({
    providedIn: 'root',
})
export class BudgetService {
    httpOptions = {};

    constructor(private httpClient: HttpClient, private authenticationService: AuthenticationService) {
        this.httpOptions = authenticationService.getHeaders();
    }

    addBudgetTemplate(budgetTemplate: BudgetTemplate) {
        return this.httpClient.post<BudgetTemplate>(`${environment.baseUrl}/budget-template`, budgetTemplate, this.httpOptions);
    }

    addBudget(budget: any, linkedEmployees: string[]) {
        budget.linkedEmployees = linkedEmployees;
        return this.httpClient.post<any>(`${environment.baseUrl}/budget`, budget, this.httpOptions);
    }

    addBudgetExpenditure(budgetId: string, budgetExpenditure: BudgetExpenditure) {
        return this.httpClient.post<BudgetExpenditure>(`${environment.baseUrl}/budget/${budgetId}/expenditures`, budgetExpenditure, this.httpOptions);
    }
    fetchAllBudgets() {
        return this.httpClient.get<Budget[]>(`${environment.baseUrl}/budget`, this.httpOptions);
    }

    fetchBudgetTemplates() {
        return this.httpClient.get<BudgetTemplate[]>(`${environment.baseUrl}/budget-template`, this.httpOptions);
    }

    fetchEmployeesForBudget() {
        return this.httpClient.get<EmployeeWithBudgets[]>(`${environment.baseUrl}/budget`, this.httpOptions);
    }

    fetchArchivedBudgets() {
        return this.httpClient.get<Budget[]>(`${environment.baseUrl}/budget/archived`, this.httpOptions);
    }

    fetchBudgetsApproachingEndDate() {
        return this.httpClient.get<Budget[]>(`${environment.baseUrl}/budget/approaching-end-date`, this.httpOptions);
    }

    fetchBudgetById(id: string) {
        return this.httpClient.get<Budget>(`${environment.baseUrl}/budget/${id}`, this.httpOptions);
    }

    deleteBudgetTemplate(budgetTemplateId: string) {
        return this.httpClient.delete(`${environment.baseUrl}/budget-template/${budgetTemplateId}`, this.httpOptions);
    }

    archiveBudget(budgetId: string) {
        return this.httpClient.patch<Budget>(`${environment.baseUrl}/budget/${budgetId}`, {}, this.httpOptions);
    }

    updateBudgetTemplate(budgetTemplate: BudgetTemplate) {
        return this.httpClient.put(`${environment.baseUrl}/budget-template/${budgetTemplate.uuid}`, budgetTemplate, this.httpOptions);
    }

    updateBudget(budget: any, linkedEmployees: string[]): Observable<any[]> {
        budget.linkedEmployees = linkedEmployees;
        return this.httpClient.put<any[]>(`${environment.baseUrl}/budget/${budget.uuid}`, budget, this.httpOptions);
    }

    updateBudgetExpenditure(budgetExpenditure: BudgetExpenditure) {
        return this.httpClient.put<BudgetExpenditure>(
            `${environment.baseUrl}/budget/${budgetExpenditure.uuid}/expenditures`,
            budgetExpenditure,
            this.httpOptions,
        );
    }

    deleteBudgetExpenditure(budget: Budget, budgetExpenditure: BudgetExpenditure) {
        return this.httpClient.delete(`${environment.baseUrl}/budget/${budget.uuid}/expenditures/${budgetExpenditure.uuid}`, this.httpOptions);
    }

    getInvoice(url: string) {
        return this.httpClient.get<any>(`${environment.baseUrl}/budget/invoice?url=${url}`, this.httpOptions);
    }

    getActualCostsForYear(year: number) {
        return this.httpClient.get<EmployeeWithCosts[]>(`${environment.baseUrl}/financial/budget/actual-costs/?year=${year}`, this.httpOptions);
    }

    getBudgetedCostsForYear(year: number) {
        return this.httpClient.get<EmployeeWithCosts[]>(`${environment.baseUrl}/financial/budget/budgeted-costs/?year=${year}`, this.httpOptions);
    }

    deleteBudget(id: string) {
        return this.httpClient.delete<Budget>(`${environment.baseUrl}/budget/${id}`, this.httpOptions);
    }
}
