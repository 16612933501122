import { Component, Input } from '@angular/core';
import YukiDocument from 'src/app/models/yuki/YukiDocument';

@Component({
    selector: 'app-simple-document-list',
    templateUrl: './simple-document-list.component.html',
    styleUrls: ['./simple-document-list.component.scss'],
})
export class SimpleDocumentListComponent {
    @Input() documents: YukiDocument[];
}
