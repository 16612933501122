import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { append, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { tap } from 'rxjs/operators';

import { Applicant } from '../models/Applicants/Applicant';
import { ApplicantLane } from '../models/Applicants/ApplicantLane';
import ApplicantReasonOfLoss from '../models/Applicants/ApplicantReasonOfLoss';
import {
    AddApplicant,
    AddApplicantLane,
    AddApplicantReasonOfLoss,
    ArchiveApplicant,
    FetchApplicantLanes,
    FetchApplicantReasonsOfLoss,
    FetchApplicants,
    FetchArchivedApplicants,
    MoveApplicant,
    MoveApplicantLaneSequence,
    RemoveApplicantLane,
    RemoveApplicantReasonOfLoss,
    UnArchiveApplicant,
    UpdateApplicant,
    UpdateApplicantLane,
    UpdateApplicantReasonOfLoss,
} from './applicant.actions';
import { ApplicantService } from './applicant.service';
import { ApplicantLaneService } from './applicantLane.service';
import { ApplicantReasonOfLossService } from './applicantReasonOfLoss.service';

export interface ApplicantStateModel {
    lanes: ApplicantLane[];
    applicants: Applicant[];
    archivedApplicants: Applicant[];
    reasonsOfLoss: ApplicantReasonOfLoss[];
}

@State<ApplicantStateModel>({
    name: 'applicant',
    defaults: {
        lanes: null,
        applicants: null,
        archivedApplicants: null,
        reasonsOfLoss: null,
    },
})
@Injectable()
export class ApplicantState {
    constructor(
        private applicantService: ApplicantService,
        private applicantLaneService: ApplicantLaneService,
        private applicantReasonOfLossService: ApplicantReasonOfLossService,
    ) {}

    @Selector()
    static Applicants(state: ApplicantStateModel): Applicant[] {
        return state.applicants;
    }

    @Selector()
    static ArchivedApplicants(state: ApplicantStateModel): Applicant[] {
        return state.archivedApplicants;
    }

    @Selector()
    static Lanes(state: ApplicantStateModel): ApplicantLane[] {
        const allApplicants = state.applicants;
        const lanesWithApplicants = JSON.parse(JSON.stringify(state.lanes));
        lanesWithApplicants.forEach((lane) => {
            lane.applicants = allApplicants.filter((applicant) => applicant.laneUuid === lane.uuid);
        });
        return lanesWithApplicants.sort((a, b) => a.sequence - b.sequence);
    }

    @Selector()
    static ReasonsOfLoss(state: ApplicantStateModel): ApplicantReasonOfLoss[] {
        return state.reasonsOfLoss;
    }

    @Action(FetchApplicants)
    fetch(ctx: StateContext<ApplicantStateModel>) {
        return this.applicantService.getAllApplicants().pipe(
            tap((applicants) => {
                ctx.setState(
                    patch<ApplicantStateModel>({
                        applicants: applicants,
                    }),
                );
            }),
        );
    }

    @Action(AddApplicant)
    addApplicant(ctx: StateContext<ApplicantStateModel>, action: AddApplicant) {
        return this.applicantService.addApplicant(action.applicant).pipe(
            tap((applicant) => {
                ctx.setState(
                    patch<ApplicantStateModel>({
                        applicants: append<Applicant>([applicant]),
                    }),
                );
            }),
        );
    }

    @Action(UpdateApplicant)
    updateApplicant(ctx: StateContext<ApplicantStateModel>, action: UpdateApplicant) {
        return this.applicantService.updateApplicant(action.applicant).pipe(
            tap((applicant) => {
                ctx.setState(
                    patch<ApplicantStateModel>({
                        applicants: updateItem<Applicant>((a) => a.uuid === action.applicant.uuid, applicant),
                    }),
                );
            }),
        );
    }

    @Action(FetchArchivedApplicants)
    fetchArchivedApplicants(ctx: StateContext<ApplicantStateModel>) {
        return this.applicantService.getArchivedApplicants().pipe(
            tap((applicants) => {
                ctx.setState(
                    patch<ApplicantStateModel>({
                        archivedApplicants: applicants,
                    }),
                );
            }),
        );
    }

    @Action(ArchiveApplicant)
    archiveApplicant(ctx: StateContext<ApplicantStateModel>, action: ArchiveApplicant) {
        return this.applicantService.archiveApplicant(action.uuid).pipe(
            tap((applicant) => {
                ctx.setState(
                    patch<ApplicantStateModel>({
                        applicants: removeItem<Applicant>((applicant) => applicant.uuid === action.uuid),
                        archivedApplicants: append<Applicant>([applicant]),
                    }),
                );
            }),
        );
    }

    @Action(UnArchiveApplicant)
    unArchiveApplicant(ctx: StateContext<ApplicantStateModel>, action: UnArchiveApplicant) {
        return this.applicantService.unArchiveApplicant(action.uuid).pipe(
            tap((applicant) => {
                ctx.setState(
                    patch<ApplicantStateModel>({
                        applicants: append<Applicant>([applicant]),
                        archivedApplicants: removeItem<Applicant>((applicant) => applicant.uuid === action.uuid),
                    }),
                );
            }),
        );
    }

    // Applicant CRM
    @Action(FetchApplicantLanes)
    fetchApplicantLanes(ctx: StateContext<ApplicantStateModel>) {
        return this.applicantLaneService.getAllApplicantLanes().pipe(
            tap((lanes) => {
                ctx.setState(
                    patch<ApplicantStateModel>({
                        lanes: lanes,
                    }),
                );
            }),
        );
    }

    @Action(AddApplicantLane)
    addApplicantLane(ctx: StateContext<ApplicantStateModel>, action: AddApplicantLane) {
        return this.applicantLaneService.addLane(action.applicantLane).pipe(
            tap((lane) => {
                ctx.setState(
                    patch<ApplicantStateModel>({
                        lanes: append<ApplicantLane>([lane]),
                    }),
                );
            }),
        );
    }

    @Action(UpdateApplicantLane)
    updateApplicantLane(ctx: StateContext<ApplicantStateModel>, action: UpdateApplicantLane) {
        return this.applicantLaneService.updateLane(action.laneUuid, action.applicantLane).pipe(
            tap((lane) => {
                ctx.setState(
                    patch<ApplicantStateModel>({
                        lanes: updateItem<ApplicantLane>((applicantLane) => applicantLane.uuid === action.laneUuid, lane),
                    }),
                );
            }),
        );
    }

    @Action(MoveApplicantLaneSequence)
    moveApplicantLaneSequence(ctx: StateContext<ApplicantStateModel>, action: MoveApplicantLaneSequence) {
        return this.applicantLaneService.moveLaneSequence(action.laneUuid, action.sequence).pipe(
            tap((lanes) => {
                ctx.setState(
                    patch<ApplicantStateModel>({
                        lanes: lanes,
                    }),
                );
            }),
        );
    }

    @Action(MoveApplicant)
    moveApplicant(ctx: StateContext<ApplicantStateModel>, action: MoveApplicant) {
        ctx.setState(
            patch<ApplicantStateModel>({
                applicants: updateItem<Applicant>(
                    (a) => a.uuid === action.applicantUuid,
                    patch<Applicant>({
                        laneUuid: action.laneUuid,
                    }),
                ),
            }),
        );
        return this.applicantLaneService.moveApplicant(action.laneUuid, action.applicantUuid, action.reason, action.reasonUuid).pipe(
            tap((applicant) => {
                ctx.setState(
                    patch<ApplicantStateModel>({
                        applicants: updateItem<Applicant>((a) => a.uuid === action.applicantUuid, applicant),
                    }),
                );
            }),
        );
    }

    @Action(RemoveApplicantLane)
    removeApplicantLane(ctx: StateContext<ApplicantStateModel>, action: RemoveApplicantLane) {
        return this.applicantLaneService.removeLane(action.laneUuid).pipe(
            tap(() => {
                ctx.setState(
                    patch<ApplicantStateModel>({
                        applicants: removeItem<Applicant>((l) => l.uuid === action.laneUuid),
                    }),
                );
            }),
        );
    }

    @Action(FetchApplicantReasonsOfLoss)
    fetchApplicantReasonOfLoss(ctx: StateContext<ApplicantStateModel>) {
        return this.applicantReasonOfLossService.getAllApplicantReasonOfLoss().pipe(
            tap((reasons) => {
                ctx.setState(
                    patch<ApplicantStateModel>({
                        reasonsOfLoss: reasons,
                    }),
                );
            }),
        );
    }

    @Action(AddApplicantReasonOfLoss)
    addApplicantReasonOfLoss(ctx: StateContext<ApplicantStateModel>, action: AddApplicantReasonOfLoss) {
        return this.applicantReasonOfLossService.addApplicantReasonOfLoss(action.reason).pipe(
            tap((reason) => {
                ctx.setState(
                    patch<ApplicantStateModel>({
                        reasonsOfLoss: append<ApplicantReasonOfLoss>([reason]),
                    }),
                );
            }),
        );
    }

    @Action(UpdateApplicantReasonOfLoss)
    updateApplicantReasonOfLoss(ctx: StateContext<ApplicantStateModel>, action: UpdateApplicantReasonOfLoss) {
        return this.applicantReasonOfLossService.updateApplicantReasonOfLoss(action.reasonUuid, action.reason).pipe(
            tap((reason) => {
                ctx.setState(
                    patch<ApplicantStateModel>({
                        reasonsOfLoss: updateItem<ApplicantReasonOfLoss>((r) => r.uuid === action.reasonUuid, reason),
                    }),
                );
            }),
        );
    }

    @Action(RemoveApplicantReasonOfLoss)
    removeApplicantReasonOfLoss(ctx: StateContext<ApplicantStateModel>, action: RemoveApplicantReasonOfLoss) {
        return this.applicantReasonOfLossService.removeReasonOfLoss(action.reasonUuid).pipe(
            tap(() => {
                ctx.setState(
                    patch<ApplicantStateModel>({
                        reasonsOfLoss: removeItem<ApplicantReasonOfLoss>((r) => r.uuid === action.reasonUuid),
                    }),
                );
            }),
        );
    }
}
