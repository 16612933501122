<div *ngIf="isLoading"><app-loading-message></app-loading-message></div>
<div class="table__overflow">
    <table
        *ngIf="!isLoading && (archivedCrmDeals$ | async).length > 0"
        mat-table
        [dataSource]="dataSource"
        matSort>
        <ng-container matColumnDef="title">
            <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header>
                Title
            </th>
            <td
                mat-cell
                *matCellDef="let deal">
                {{ deal.title }}
            </td>
        </ng-container>

        <ng-container matColumnDef="linked items">
            <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header>
                Linked items
            </th>
            <td
                mat-cell
                *matCellDef="let deal">
                <app-pill-contact
                    *ngIf="deal.contactPerson"
                    [contactPerson]="deal.contactPerson"></app-pill-contact>
                <app-pill-customer
                    *ngIf="deal.customer"
                    [customerUuid]="deal.customer.uuid"
                    [customer]="deal.customer"></app-pill-customer>
                <app-pill-partner
                    *ngIf="deal.partner"
                    [partnerUuid]="deal.partner.uuid"
                    [partner]="deal.partner"></app-pill-partner>
            </td>
        </ng-container>

        <ng-container matColumnDef="price">
            <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header>
                Price
            </th>
            <td
                mat-cell
                *matCellDef="let deal">
                {{ deal.price | currency: 'EUR' }}
            </td>
        </ng-container>

        <ng-container matColumnDef="edit">
            <th
                mat-header-cell
                *matHeaderCellDef
                class="td-small"></th>
            <td
                mat-cell
                *matCellDef="let deal"
                class="td-small">
                <div
                    ngbDropdown
                    class="dropdown"
                    *appIsPermitted="{
                        module: 'CRM',
                        operator: 'OR',
                        actions: ['EDIT']
                    }">
                    <button
                        ngbDropdownToggle
                        class="button button--icon button--small dropdown-toggle"
                        id="dropdownMenuButton"></button>
                    <div
                        ngbDropdownMenu
                        class="dropdown-menu dropdown-menu-right"
                        aria-labelledby="dropdownMenuButton">
                        <a
                            class="dropdown-item"
                            *appIsPermitted="{
                                module: 'CRM',
                                operator: 'AND',
                                actions: ['UPDATE']
                            }"
                            (click)="deAchiveDeal(deal)"
                            >Dearchive</a
                        >
                        <a
                            class="dropdown-item color--red"
                            *appIsPermitted="{
                                module: 'CRM',
                                operator: 'AND',
                                actions: ['DELETE']
                            }"
                            (click)="delete(deal)"
                            >Delete</a
                        >
                    </div>
                </div>
            </td>
        </ng-container>

        <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns"></tr>
        <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
</div>
<p *ngIf="!isLoading && (archivedCrmDeals$ | async).length <= 0">There are no archived deals.</p>
