import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { append, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { tap } from 'rxjs/operators';

import FileSystemEntity from '../models/FileSystemEntity';
import {
    AddDirectory,
    AddDocument,
    AddRootDirectory,
    DeleteFileSystemEntity,
    FetchDirectoryContent,
    FetchRootDirectories,
    FetchStructure,
    Navigate,
    UpdateDirectory,
    UpdateDocument,
} from './documents.actions';
import { DocumentService } from './documents.service';

export interface DocumentStateModel {
    rootDirectories: FileSystemEntity[];
    currentDirectory: FileSystemEntity;
    currentPathName: any[];
    selectedFilesSystemElements: FileSystemEntity[];
    lastAddedDocument: FileSystemEntity;
    structure: any;
}

@State<DocumentStateModel>({
    name: 'document',
    defaults: {
        rootDirectories: [],
        currentDirectory: null,
        currentPathName: [],
        selectedFilesSystemElements: [],
        lastAddedDocument: null,
        structure: null,
    },
})
@Injectable()
export class DocumentState {
    dirFound = false;
    pathName: any[] = [];
    constructor(private documentService: DocumentService) {}

    @Selector()
    static rootDirectories(state: DocumentStateModel): FileSystemEntity[] {
        return state?.rootDirectories;
    }

    @Selector()
    static structure(state: DocumentStateModel): any {
        return state?.structure;
    }

    @Selector()
    static currentDirectory(state: DocumentStateModel): FileSystemEntity {
        return state?.currentDirectory;
    }

    @Selector()
    static currentPathName(state: DocumentStateModel): string[] {
        return state?.currentPathName;
    }

    @Selector()
    static selectedFilesSystemElements(state: DocumentStateModel): FileSystemEntity[] {
        return state?.selectedFilesSystemElements;
    }

    @Selector()
    static lastAddedDocument(state: DocumentStateModel): FileSystemEntity {
        return state?.lastAddedDocument;
    }

    @Action(FetchRootDirectories)
    fetchRootDirectories(ctx: StateContext<DocumentStateModel>) {
        return this.documentService.getRootDirectory().pipe(
            tap((root) => {
                ctx.setState(
                    patch<DocumentStateModel>({
                        rootDirectories: root,
                    }),
                );
                root.forEach((r) => {
                    this.fetchDirectoryContent(ctx, new FetchDirectoryContent(r.uuid)).subscribe();
                });
            }),
        );
    }

    @Action(AddRootDirectory)
    addRoot(ctx: StateContext<DocumentStateModel>, action: AddRootDirectory) {
        return this.documentService.addRootDirectory(action.name).pipe(
            tap((root) => {
                ctx.setState(
                    patch<DocumentStateModel>({
                        rootDirectories: append([root]),
                    }),
                );
            }),
        );
    }

    @Action(FetchDirectoryContent)
    fetchDirectoryContent(ctx: StateContext<DocumentStateModel>, action: FetchDirectoryContent) {
        return this.documentService.getDirectoryContent(action.directoryId).pipe(
            tap((content) => {
                ctx.setState(
                    patch<DocumentStateModel>({
                        rootDirectories: updateItem<FileSystemEntity>(
                            (r) => r.uuid === action.directoryId,
                            patch<FileSystemEntity>({
                                docs: content,
                            }),
                        ),
                    }),
                );
            }),
        );
    }

    @Action(FetchStructure)
    fetchStructure(ctx: StateContext<DocumentStateModel>) {
        return this.documentService.getStructure().pipe(
            tap((structure) => {
                ctx.setState(
                    patch<DocumentStateModel>({
                        structure: structure,
                    }),
                );
            }),
        );
    }

    @Action(Navigate)
    navigate(ctx: StateContext<DocumentStateModel>, action: Navigate) {
        return this.documentService.getDirectory(action.uuid).pipe(
            tap((dir) => {
                this.documentService.getDirectoryContent(action.uuid).subscribe((content) => {
                    const structure = ctx.getState().structure;
                    this.pathName = [];
                    this.findFullPath(structure, action.uuid);
                    ctx.setState(
                        patch<DocumentStateModel>({
                            currentPathName: this.pathName,
                        }),
                    );
                    this.dirFound = false;

                    dir.docs = content;
                    ctx.setState(
                        patch<DocumentStateModel>({
                            currentDirectory: dir,
                        }),
                    );
                });
            }),
        );
    }

    @Action(AddDirectory)
    addDirectory(ctx: StateContext<DocumentStateModel>, action: AddDirectory) {
        return this.documentService.addDirectory(action.parentId, action.name).pipe(
            tap((dir) => {
                ctx.setState(
                    patch<DocumentStateModel>({
                        currentDirectory: patch<FileSystemEntity>({
                            docs: append([dir]),
                        }),
                    }),
                );
                this.fetchStructure(ctx).subscribe();
            }),
        );
    }

    @Action(AddDocument)
    addDocument(ctx: StateContext<DocumentStateModel>, action: AddDocument) {
        return this.documentService.addDocument(action.parentId, action.name).pipe(
            tap((doc) => {
                ctx.setState(
                    patch<DocumentStateModel>({
                        lastAddedDocument: doc,
                        currentDirectory: patch<FileSystemEntity>({
                            docs: append([doc]),
                        }),
                    }),
                );
            }),
        );
    }

    @Action(UpdateDocument)
    updateDocument(ctx: StateContext<DocumentStateModel>, action: UpdateDocument) {
        return this.documentService.updateDocument(action.document.uuid, action.document.name, action.document.content).pipe(
            tap((doc) => {
                if (ctx.getState().currentDirectory != undefined && doc.directory != undefined) {
                    if (ctx.getState().currentDirectory.uuid == doc.directory.uuid) {
                        ctx.setState(
                            patch<DocumentStateModel>({
                                currentDirectory: patch<FileSystemEntity>({
                                    docs: updateItem<FileSystemEntity>((d) => d.uuid === doc.uuid, doc),
                                }),
                            }),
                        );
                    }
                }
            }),
        );
    }

    @Action(UpdateDirectory)
    updateDirectory(ctx: StateContext<DocumentStateModel>, action: UpdateDirectory) {
        return this.documentService.updateDirectory(action.directory.uuid, action.directory.name).pipe(
            tap((dir) => {
                if (ctx.getState().currentDirectory != undefined) {
                    if (ctx.getState().currentDirectory.uuid == dir.uuid) {
                        ctx.setState(
                            patch<DocumentStateModel>({
                                currentDirectory: patch<FileSystemEntity>({
                                    name: dir.name,
                                }),
                            }),
                        );
                    }
                    if (ctx.getState().rootDirectories.find((r) => r.uuid === dir.uuid) != null) {
                        ctx.setState(
                            patch<DocumentStateModel>({
                                rootDirectories: updateItem<FileSystemEntity>((r) => r.uuid === dir.uuid, dir),
                            }),
                        );
                    }
                }
                this.fetchStructure(ctx).subscribe();
            }),
        );
    }

    @Action(DeleteFileSystemEntity)
    deleteFileSystemEntity(ctx: StateContext<DocumentStateModel>, action: DeleteFileSystemEntity) {
        return this.documentService.deleteFileSystemEntity(action.entity.uuid).pipe(
            tap((res) => {
                if (res) {
                    if (ctx.getState().rootDirectories.find((r) => r.uuid == action.entity.uuid)) {
                        ctx.setState(
                            patch<DocumentStateModel>({
                                rootDirectories: removeItem<FileSystemEntity>((r) => r.uuid === action.entity.uuid),
                            }),
                        );
                    } else if (ctx.getState().currentDirectory.uuid === action.entity.uuid) {
                        const index = ctx.getState().currentPathName.findIndex((e) => e.uuid == action.entity.uuid);
                        this.navigate(ctx, new Navigate(ctx.getState().currentPathName[index - 1].uuid)).subscribe();
                    } else {
                        ctx.setState(
                            patch<DocumentStateModel>({
                                currentDirectory: patch<FileSystemEntity>({
                                    docs: removeItem<FileSystemEntity>((d) => d.uuid === action.entity.uuid),
                                }),
                            }),
                        );
                    }
                }
                this.fetchStructure(ctx).subscribe();
            }),
        );
    }

    findFullPath(structure, target) {
        structure.forEach((directory) => {
            if (!this.dirFound) {
                this.pathName.push(directory);

                if (directory.uuid === target) {
                    this.dirFound = true;
                } else {
                    if (directory.children == undefined || directory.children == null) {
                        this.pathName.pop();
                        return;
                    }
                    this.findFullPath(directory.children, target);
                }
            }
        });
        if (!this.dirFound) {
            this.pathName.pop();
        }
    }
}
