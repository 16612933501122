import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { Store } from '@ngxs/store';

import { CustomRolesState } from '../settings/roles/custom.roles.state';
import { checkAllowed } from './utils/authorization-utils';

@Directive({
    selector: '[appIsPermitted]',
})
export class IsPermittedDirective {
    private hasView = false;

    constructor(private store: Store, private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef) {}

    /*
    AND = all modules have to be active
    OR = any module active
    */
    @Input() set appIsPermitted(permissions: Permissions) {
        let allowed;
        const allowedActions = JSON.parse(sessionStorage.getItem('allowedActions'));
        if (allowedActions) {
            allowed = checkAllowed(allowedActions, permissions);
        } else {
            this.store.selectOnce(CustomRolesState.AllowedActions).subscribe((allowedActions) => {
                allowed = checkAllowed(allowedActions, permissions);
            });
        }

        if (allowed != undefined) {
            if (allowed) {
                this.viewContainer.createEmbeddedView(this.templateRef);
            } else {
                this.viewContainer.clear();
            }
            this.hasView = allowed;
        }
    }
}
