<form
    (ngSubmit)="submit()"
    class="modal-wrapper">
    <div class="modal-header">
        <h2
            class="modal-title"
            id="exampleModalLabel">
            Update days per month
        </h2>
        <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="activeModal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <input
            type="number"
            [(ngModel)]="days"
            name="days-per-month" />
    </div>

    <div class="modal-footer">
        <input
            [disabled]="!days"
            class="button button--primary"
            type="submit"
            value="Update" />
        <button
            class="button button--outline"
            (click)="activeModal.dismiss()">
            Cancel
        </button>
    </div>
</form>
