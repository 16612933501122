import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '../authentication/auth.guard';
import { CompanyGuard } from '../companies/company.guard';
import { ApplicantsOverviewComponent } from './applicants-overview/applicants-overview.component';

export const routes: Routes = [
    { path: '', redirectTo: 'applicants/overview', pathMatch: 'full' },
    {
        path: 'applicants/overview',
        component: ApplicantsOverviewComponent,
        canActivate: [AuthGuard, CompanyGuard],
    },
];

export const routing: ModuleWithProviders<any> = RouterModule.forChild(routes);
