import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Select } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { Query } from 'src/app/models/Query';
import { QueryDTO } from 'src/app/models/QueryDTO';
import { QueryService } from 'src/app/queries/queries.service';

import { VeldaArticlesState } from '../../velda-articles.state';

@Component({
    selector: 'app-velda-articles-articles-detail-modal',
    templateUrl: './velda-articles-articles-detail-modal.component.html',
    styleUrls: ['./velda-articles-articles-detail-modal.component.scss'],
})
export class VeldaArticlesArticlesDetailModalComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    /* used to retrieve the first line of the query that includes the params */
    queryParamsRegex = /entries\([a-zA-Z:", ]+\)/;

    /* used to retrieve the value of the section param */
    //querySectionValueRegex: RegExp = /(?<=section: ")[a-zA-z ]+/; //deze kan nog niet gebruikt worden omdat safari geen support heeft voor lookbehind
    querySectionValueRegex = /(section: ".*")/;

    @Select(VeldaArticlesState.queries) queries$: Observable<Query[]>;

    @Input() data: any;
    @Input() entity: string;

    languages: any[];
    loading = true;

    smallImages: string[] = [];
    mediumImages: string[] = [];
    bigImages: string[] = [];

    constructor(private queryService: QueryService, public activeModal: NgbActiveModal) {}

    ngOnInit(): void {
        this.subscriptions.add(
            this.queryService.getLanguages().subscribe((res) => {
                if (!JSON.parse(JSON.stringify(res)).errors) {
                    this.languages = JSON.parse(JSON.stringify(res)).data.entries;
                    this.loading = false;
                }
            }),
        );

        this.data.images.forEach((image) => {
            this.smallImages.push(image.resumoThumbnail);
            this.mediumImages.push(image.crop_400x400);
            this.bigImages.push(image.crop_800x800);
        });
    }

    getKeysForRegularValue(obj) {
        if (obj) {
            return Object.keys(obj).filter((key) => !Array.isArray(obj[key]));
        } else return [];
    }

    getKeysForArrayValue(obj) {
        return Object.keys(obj).filter((key) => Array.isArray(obj[key]));
    }

    translate(event) {
        this.loading = true;

        this.subscriptions.add(
            this.queries$.subscribe((queries) => {
                /* retrieve the query for the current entity (ex. brands, collections, ...) */
                const query = queries.find((q) => q.type === this.entity);

                /* get value for site language. if 'default' use null without surrounding quotes */
                const site = event.target.value != 'default' ? `"${event.target.value}"` : null;

                /* construct new line of query params. get section value by regex */
                //let entryParams = `entry(section: "${query.payload.match(this.querySectionValueRegex)[0]}", site: ${site}, id: ${this.data.id})`;
                const entryParams = `entry(section: "${query.payload
                    .match(this.querySectionValueRegex)[0]
                    .replace('section: ', '')
                    .replace('"', '')}", site: ${site}, id: ${this.data.id})`;

                /* construct a new queryDTO: copy of earlier query but line of params replaced by regex */
                const languageQuery: QueryDTO = {
                    type: 'custom',
                    payload: query.payload.replace(this.queryParamsRegex, entryParams),
                };

                this.queryService.runCustomQuery(languageQuery).subscribe((res) => {
                    if (!JSON.parse(JSON.stringify(res)).errors) {
                        console.log(JSON.parse(JSON.stringify(res)).errors);

                        this.data = JSON.parse(JSON.stringify(res)).data.entry;
                        this.loading = false;
                    }
                });
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
