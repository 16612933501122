import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { ContactpersonDetailComponent } from 'src/app/contacts/contactperson-detail/contactperson-detail.component';
import { FetchContacts } from 'src/app/contacts/contacts.actions';
import { ContactState } from 'src/app/contacts/contacts.state';
import { ContactPerson } from 'src/app/models/ContactPerson';

@Component({
    selector: 'app-pill-contact',
    templateUrl: './pill-contact.component.html',
    styleUrls: ['./pill-contact.component.scss'],
})
export class PillContactComponent implements OnInit, OnDestroy {
    @Select(ContactState.Contacts) contactPersons$: Observable<ContactPerson[]>;
    @Input() contactPerson: ContactPerson;
    @Input() contactPersonUuid: string;
    @Input() title: string;
    isLoading = true;
    private subscriptions = new Subscription();

    constructor(private modalService: NgbModal, private store: Store) {}

    ngOnInit(): void {
        if (this.contactPerson || (this.title && this.contactPersonUuid)) {
            this.isLoading = false;
        }

        if (!this.contactPerson) {
            this.subscriptions.add(
                this.contactPersons$.subscribe((contacts) => {
                    if (!contacts) {
                        this.store.dispatch(new FetchContacts());
                        return;
                    }
                    this.contactPerson = JSON.parse(JSON.stringify(contacts.find((c) => c.uuid == this.contactPersonUuid)));
                    this.isLoading = false;
                }),
            );
        }
    }

    openContactpersonDetail() {
        const modalRef = this.modalService.open(ContactpersonDetailComponent, {
            windowClass: 'modal-pane',
            animation: false,
        });
        modalRef.componentInstance.contactPersonUuid = this.contactPerson.uuid;
        modalRef.componentInstance.inOverview = false;
    }

    getTitle(): string {
        return this.contactPerson ? this.contactPerson.firstName + ' ' + this.contactPerson.lastName : this.title;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
