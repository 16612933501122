import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatTreeModule } from '@angular/material/tree';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { NgbDropdownModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TagInputModule } from 'ngx-chips';
import { OrderModule } from 'ngx-order-pipe';
import { NgPipesModule } from 'ngx-pipes';
import { SortablejsModule } from 'ngx-sortablejs';
import { NgxTrimDirectiveModule } from 'ngx-trim-directive';

import { SharedModule } from '../shared/shared.module';
import { DocumentsAddModalComponent } from './documents-add-modal/documents-add-modal.component';
import { DocumentsDirComponent } from './documents-dir/documents-dir.component';
import { DocumentsDirectoryComponent } from './documents-directory/documents-directory.component';
import { DocumentsDocDetailComponent } from './documents-doc-detail/documents-doc-detail.component';
import { DocumentsDocComponent } from './documents-doc/documents-doc.component';
import { DocumentsOverviewComponent } from './documents-overview/documents-overview.component';
import { routing } from './documents.routes';

@NgModule({
    declarations: [
        DocumentsOverviewComponent,
        DocumentsDirectoryComponent,
        DocumentsDocComponent,
        DocumentsDirComponent,
        DocumentsDocDetailComponent,
        DocumentsAddModalComponent,
    ],
    imports: [
        CommonModule,
        SharedModule,
        routing,
        NgbModule,
        NgbDropdownModule,
        NgxTrimDirectiveModule,
        ReactiveFormsModule,
        NgPipesModule,
        OrderModule,
        TagInputModule,
        SortablejsModule,
        CKEditorModule,
        MatTreeModule,
    ],
    exports: [DocumentsOverviewComponent],
})
export class DocumentsModule {}
