import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subscription } from 'rxjs';
import { VeldaCountry } from 'src/app/models/VeldaCountry';

import { VeldaLanguagesCountriesDeleteModalComponent } from '../velda-languages-countries-delete-modal/velda-languages-countries-delete-modal.component';
import { VeldaLanguagesCountriesEditModalComponent } from '../velda-languages-countries-edit-modal/velda-languages-countries-edit-modal.component';
import { VeldaLanguagesState } from '../velda-languages.state';

@Component({
    selector: 'app-velda-languages-countries-list',
    templateUrl: './velda-languages-countries-list.component.html',
    styleUrls: ['./velda-languages-countries-list.component.scss'],
})
export class VeldaLanguagesCountriesListComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Select(VeldaLanguagesState.countries) countries$: Observable<VeldaCountry[]>;

    displayedColumns: string[] = ['countryName', 'countryCode', 'edit'];
    dataSource: MatTableDataSource<VeldaCountry>;
    filter: string;
    @ViewChild(MatSort) sort: MatSort;

    constructor(private modalService: NgbModal, private logger: NGXLogger) {}

    ngOnInit(): void {
        this.subscriptions.add(
            this.countries$.subscribe((countries) => {
                /* setup datasource */
                this.dataSource = new MatTableDataSource(countries);
                this.dataSource.sort = this.sort;

                /* sortingDataAccessor to sort on nested objs */
                this.dataSource.sortingDataAccessor = (item, property) => {
                    if (property.includes('.')) return property.split('.').reduce((o, i) => o[i], item);
                    return item[property];
                };
            }),
        );
    }

    applyFilter(filterValue) {
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }

    openEditVeldaCountryModal(country: VeldaCountry) {
        this.logger.debug('Opening VeldaLanguagesCountriesEditModal');
        const modalref = this.modalService.open(VeldaLanguagesCountriesEditModalComponent, { windowClass: 'modal-prompt' });
        modalref.componentInstance.country = country;
    }

    openDeleteVeldaCountryModal(country: VeldaCountry) {
        this.logger.debug('Opening VeldaLanguagesCountriesDeleteModal');
        const modalref = this.modalService.open(VeldaLanguagesCountriesDeleteModalComponent, { windowClass: 'modal-prompt' });
        modalref.componentInstance.country = country;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
