<div *ngIf="!isLoading">
    <div class="d-flex align-items-center">
        <div class="w-100">
            <app-searchbar [(value)]="filter" (valueChange)="applyFilter($event)"></app-searchbar>
        </div>
        <div class="d-flex nowrap">
            <span ngbDropdown class="dropdown m-l-2">
                <button ngbDropdownToggle class="button button--select">
                    Options
                </button>
                <span ngbDropdownMenu class="dropdown-menu-right">
                    <form>
                        <label ngbDropdownItem *appIsPermitted="{
                                module: 'TIMESHEETS',
                                operator: 'AND',
                                actions: ['EDIT']
                            }" (click)="sendReminders()">Send reminders</label>
                        <label ngbDropdownItem *appIsPermitted="{
                                module: 'TIMESHEETS',
                                operator: 'AND',
                                actions: ['READ']
                            }" (click)="openCronjobSettings()">Reminder settings</label>
                        <label ngbDropdownItem (click)="addNewTimesheet()" *appIsPermitted="{
                                module: 'TIMESHEETS',
                                operator: 'AND',
                                actions: ['CREATE']
                            }">Add a Timesheet</label>
                    </form>
                </span>
            </span>
            <app-global-export-csv [componentName]="this.constructor.name" [exporter]="exporter"
                class="m-l-2"></app-global-export-csv>
        </div>
    </div>

    <div class="m-t-4">
        <div class="table__overflow">
            <table mat-table [dataSource]="dataSource" matSort matTableExporter #exporter="matTableExporter">
                <ng-container matColumnDef="projects">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Projects
                    </th>
                    <td mat-cell *matCellDef="let project">
                        <app-pill-project [project]="project">
                        </app-pill-project>
                    </td>
                </ng-container>
                <ng-container matColumnDef="isBilled">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="billed-column">
                        Has been billed
                    </th>
                    <td mat-cell *matCellDef="let project" class="billed-row">
                        <div *ngFor="let slot of getTimeslotsForProject(project)" id="timeslotRow">
                            <span id="billed">
                                <i *ngIf="!slot.billed" class="las la-times-circle billed" style="color: red"></i>
                                <i *ngIf="slot.billed" class="las la-check-circle billed" style="color: green"></i>
                            </span>
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="timeslots">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Logged time by Employee
                    </th>
                    <td mat-cell *matCellDef="let project">
                        <div *ngFor="let slot of getTimeslotsForProject(project)" id="timeslotRow">
                            <app-pill-employee [employee]="getEmployeeByTimeslot(slot)"
                                class="employee-pill"></app-pill-employee>
                            <span id="time">: {{ slot.minutes | minutesToMinutesAndHours }}
                                hours ({{ getTotalDaysFromMinutes(slot.minutes) }}
                                days)</span>
                                <span *ngIf="slot.jiraDiscrepancyDetected">
                                    <i class="las la-exclamation-circle icon-large" style="color: orange" 
                                    matTooltip="Jira timediscrepancy detected of {{slot.jiraDiscrepancyInMinutes}} minutes !"
                                    matTooltipPosition="after"></i>
                                </span>
                                <span *ngIf="slot.jiraDiscrepancyDetected" id="jiraDiscrepancyMinutes">{{slot.jiraDiscrepancyInMinutes}} minute(s) !</span>
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="totalHours">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Total hours
                    </th>
                    <td mat-cell *matCellDef="let project">
                        {{getTotalWorkedMinutesForProject(project) | minutesToMinutesAndHours }} hours
                    </td>
                </ng-container>
                <ng-container matColumnDef="edit">
                    <th mat-header-cell *matHeaderCellDef class="td-small"></th>
                    <td mat-cell *matCellDef="let row" class="td-icon">
                      <button *appIsPermitted="{ module: 'TIMESHEETS', operator: 'AND', actions: ['READ'] }"
                        class="button button--icon button--small"
                        (click)="openInvoiceDialog(getTimeslotsForProject(row)); $event.stopPropagation()">
                        Send Invoice
                      </button>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns">
                </tr>
                <tr class="pointer" mat-row *matRowDef="let row; columns: displayedColumns">
                </tr>
                <tr *matNoDataRow>
                    <td colspan="5">No timesheets matching the filter "{{ filter }}"</td>
                </tr>
            </table>
        </div>
    </div>

    <app-loading-message *ngIf="isLoading || sendingReminders"></app-loading-message>