import { Component, EventEmitter, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { format } from 'date-fns';

@Component({
    selector: 'app-date-modal',
    templateUrl: './date-modal.component.html',
    styleUrls: ['./date-modal.component.scss'],
})
export class DateModalComponent implements OnInit {
    public closeEvent: EventEmitter<string> = new EventEmitter();

    date: string = format(new Date(), 'yyyy-MM-dd');

    constructor(public activeModal: NgbActiveModal) {}

    ngOnInit(): void {}

    close() {
        this.closeEvent.emit(this.date);
        this.activeModal.close();
    }
}
