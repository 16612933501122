import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { fromUnixTime, isAfter } from 'date-fns';
import jwt_decode, { JwtPayload } from 'jwt-decode';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import User from '../models/User';
import { FetchAllowedModules, FetchRoles } from '../settings/roles/custom.roles.actions';
import { ClearStore } from '../users/user.actions';

@Injectable({
    providedIn: 'root',
})
export class AuthenticationService {
    httpOptions = {
        headers: {},
    };

    constructor(private httpClient: HttpClient) {
        if (this.getToken()) {
            this.rebuildHeaders();
        }
    }

    login(user: User) {
        return this.httpClient.post<User>(environment.baseUrl + '/auth/login', user).pipe(
            tap((userData) => {
                !user.mfaEnabled ? this.storeToken(userData.accessToken) : userData;
            }),
        );
    }

    loginWithMfa(user: User, mfaCode: string) {
        return this.httpClient.post<User>(environment.baseUrl + '/auth/login/mfa/' + mfaCode, user).pipe(
            tap((userData) => {
                this.storeToken(userData.accessToken);
            }),
        );
    }

    registerGoogleUser(email: string, imageUrl: string) {
        return this.httpClient
            .post<any>(environment.baseUrl + '/auth/register-google-user', { email, imageUrl })
            .pipe(tap((userData) => this.storeToken(userData.accessToken)));
    }

    registerAppUser(user: User) {
        return this.httpClient.post<User>(environment.baseUrl + '/auth/register', user);
    }

    isUserLoggedIn(): boolean {
        if (!this.getToken()) return false;

        const expirationDate: Date = fromUnixTime(jwt_decode<JwtPayload>(this.getToken()).exp);
        return isAfter(expirationDate, new Date());
    }

    logOut(): Observable<string> {
        return this.httpClient.delete<string>(environment.baseUrl + '/auth/revoke-token', this.httpOptions);
    }

    private storeToken(accessToken: string): void {
        localStorage.setItem('token', `Bearer ${accessToken}`);
        this.rebuildHeaders();
    }

    getToken(): string {
        return localStorage.getItem('token');
    }

    public getHeaders(): any {
        return this.httpOptions;
    }

    public loginWasSuccesfull(logger: NGXLogger, store: Store, router: Router, redirectUrl: string) {
        logger.debug('Login succesful');
        store.dispatch(new ClearStore()).subscribe(() => {
            store.dispatch(new FetchRoles());
            store.dispatch(new FetchAllowedModules());

            // Check for redirect url otherwise go to resume overview
            if (redirectUrl) {
                router.navigate([redirectUrl]).catch(() => router.navigate(['/dashboard']));
            } else {
                router.navigate(['/dashboard']);
            }
        });
    }

    private rebuildHeaders() {
        const headers: HttpHeaders = new HttpHeaders({
            'Content-type': 'application/json',
            Authorization: this.getToken(),
        });
        this.httpOptions.headers = headers;
    }
}
