<div class="modal-wrapper">
    <div class="modal-header">
        <h2
            class="modal-title"
            id="exampleModalLabel">
            Link product
        </h2>
        <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="activeModal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <div *ngIf="(allProducts$ | async)?.length != 0">
            <div class="form-group m-b-2">
                <label>Choose product</label>
                <div class="d-flex">
                    <div class="ng-autocomplete">
                        <ng-autocomplete
                            [(ngModel)]="chosenProduct"
                            [data]="actualProducts"
                            [searchKeyword]="keyword"
                            (selected)="selectEvent($event)"
                            [itemTemplate]="itemTemplate"
                            autocomplete="off"
                            [notFoundTemplate]="notFoundTemplate"
                            placeholder="Choose product"
                            [disabled]="isUpdate">
                        </ng-autocomplete>

                        <ng-template
                            #itemTemplate
                            let-item>
                            <a [innerHTML]="item.title"></a>
                        </ng-template>

                        <ng-template
                            #notFoundTemplate
                            let-notFound>
                            <div>+ Add new</div>
                        </ng-template>
                    </div>
                    <button
                        class="button button--outline button--icon m-l-2"
                        (click)="openAddProductModal()">
                        <i class="las la-plus"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button
            class="button button--primary"
            (click)="addNewProducts()">
            Link product
        </button>
    </div>
</div>
