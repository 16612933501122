import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { AuthenticationService } from '../authentication/authentication.service';

@Injectable({
    providedIn: 'root',
})
export class VatValidationService {
    httpOptions = {};

    constructor(private httpClient: HttpClient, private authenticationService: AuthenticationService) {
        this.httpOptions = authenticationService.getHeaders();
    }

    getPartnerInformationFromVat(vatNumber: string): Observable<any> {
        return this.httpClient.get<any>(
            `${environment.vatAbstractApiUrl}/validate/?api_key=${environment.VAT_API_KEY}&vat_number=${vatNumber}`,
            this.httpOptions,
        );
    }

    getExtraInfoInformationFromDomain(domain: string): Observable<any> {
        return this.httpClient.get<any>(
            `${environment.ceAbstractApiUrl}/?api_key=${environment.COMPANY_ENRICHMENT_API_KEY}&domain=${domain}`,
            this.httpOptions,
        );
    }
}
