<app-app-holder>
    <app-navbar></app-navbar>

    <app-sidebar *ngIf="!isLoading">
        <div class="sidebar-spacing">
            <h3>Documents</h3>
        </div>

        <span class="divider divider--small"></span>

        <div *ngIf="isLoading">
            <app-loading-message></app-loading-message>
        </div>
        <span
            *appIsPermitted="{
                module: 'DOCUMENTS',
                operator: 'AND',
                actions: ['READ']
            }">
            <mat-tree
                style="background-color: white"
                class="app__subnav"
                *ngIf="!isLoading"
                [dataSource]="dataSource"
                [treeControl]="treeControl">
                <mat-tree-node
                    *matTreeNodeDef="let dir"
                    matTreeNodePadding>
                    <a
                        (click)="selectDirectory(dir, dir.level)"
                        [ngClass]="{
                            app__subnav__active: selectedDirectory.uuid === dir.uuid
                        }">
                        <span><i class="lar la-folder color--yellow"></i> {{ dir.name }}</span>
                    </a>
                </mat-tree-node>
                <mat-tree-node
                    *matTreeNodeDef="let dir; when: hasChild"
                    matTreeNodePadding>
                    <i
                        matTreeNodeToggle
                        *ngIf="!treeControl.isExpanded(dir)"
                        class="las la-chevron-right color--grey"></i>
                    <i
                        matTreeNodeToggle
                        *ngIf="treeControl.isExpanded(dir)"
                        class="las la-chevron-down color--grey"></i>
                    <a
                        (click)="selectDirectory(dir)"
                        [ngClass]="{
                            app__subnav__active: selectedDirectory.uuid === dir.uuid
                        }">
                        <span>
                            <i
                                *ngIf="!treeControl.isExpanded(dir)"
                                class="lar la-folder color--yellow"></i>
                            <i
                                *ngIf="treeControl.isExpanded(dir)"
                                class="lar la-folder-open color--yellow"></i>
                            {{ dir.name }}
                        </span>
                    </a>
                </mat-tree-node>
            </mat-tree>
        </span>

        <span
            *appIsPermitted="{
                module: 'DOCUMENTS',
                operator: 'AND',
                actions: ['READ']
            }">
            <div
                class="app__subnav"
                *ngIf="!isLoading">
                <div
                    *ngIf="roots.length > 0"
                    class="divider divider--small"></div>

                <a
                    (click)="addRoot()"
                    *appIsPermitted="{
                        module: 'DOCUMENTS',
                        operator: 'AND',
                        actions: ['CREATE']
                    }"
                    ><span><i class="las la-plus-circle"></i> Add folder</span></a
                >
            </div>
        </span>
    </app-sidebar>

    <app-content *ngIf="!isLoading">
        <div *ngIf="roots.length > 0">
            <app-documents-directory
                *appIsPermitted="{
                    module: 'DOCUMENTS',
                    operator: 'AND',
                    actions: ['READ']
                }"
                (deletedFolder)="onDelete()"></app-documents-directory>

            <div
                *appIsPermitted="{
                    module: 'DOCUMENTS',
                    operator: 'AND',
                    actions: ['READ']
                }"
                class="document__pagination">
                <i class="las la-folder-open"></i>
                <span *ngFor="let dir of pathNames$ | async">
                    &rsaquo;
                    <a (click)="selectDirectory(dir)"> {{ dir.name }}</a>
                </span>
            </div>
        </div>
        <span *ngIf="roots.length <= 0">
            <app-message-empty
                [title]="'No folders...'"
                [subtitle]="'You have no folders yet. Please create one first.'"
                [icon]="'../../assets/img/open-box.png'">
                <a
                    class="button button--primary"
                    *appIsPermitted="{
                        module: 'DOCUMENTS',
                        operator: 'AND',
                        actions: ['CREATE']
                    }"
                    (click)="addRoot()"
                    >Add first folder</a
                >
            </app-message-empty>
        </span>
    </app-content>

    <ng-template #loading>
        <app-loading-message></app-loading-message>
    </ng-template>
</app-app-holder>
