<app-searchbar
    [(value)]="filter"
    (valueChange)="applyFilter($event)"></app-searchbar>

<div class="m-t-4">
    <table
        mat-table
        [dataSource]="dataSource"
        matSort
        matSortActive="email"
        matSortDirection="asc">
        <ng-container matColumnDef="email">
            <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header>
                <i class="las la-envelope"></i>Applicant
            </th>
            <td
                mat-cell
                *matCellDef="let row">
                <app-pill-applicant [applicant]="row"></app-pill-applicant>
            </td>
        </ng-container>

        <ng-container matColumnDef="createdOn">
            <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header>
                <i class="las la-calendar"></i>
                created on
            </th>
            <td
                mat-cell
                *matCellDef="let row">
                {{ row.createdOn | date }}
            </td>
        </ng-container>

        <ng-container matColumnDef="lastMailSent">
            <th
                mat-header-cell
                *matHeaderCellDef>
                <i class="las la-calendar"></i>
                Last update
            </th>
            <td
                mat-cell
                *matCellDef="let row">
                <span>{{ row.lastModified | amTimeAgo }}</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="status">
            <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header>
                Status
            </th>
            <td
                mat-cell
                *matCellDef="let row">
                <span class="tag">{{ row.status }}</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="edit">
            <th
                mat-header-cell
                *matHeaderCellDef
                class="td-small"></th>
            <td
                mat-cell
                *matCellDef="let row">
                <span ngbDropdown>
                    <button
                        ngbDropdownToggle
                        class="button button--icon button--dropdown button--small"></button>
                    <span
                        ngbDropdownMenu
                        class="dropdown-menu dropdown-menu-right"
                        aria-labelledby="dropdownMenuButton">
                        <span
                            *appIsPermitted="{
                                module: 'RESUME',
                                operator: 'AND',
                                actions: ['READ']
                            }">
                            <a
                                *ngIf="row.resumeUpload"
                                class="dropdown-item"
                                href="{{ row.resumeUpload }}"
                                >View PDF</a
                            >
                        </span>
                        <span
                            *appIsPermitted="{
                                module: 'RESUME',
                                operator: 'AND',
                                actions: ['EDIT']
                            }">
                            <a
                                class="dropdown-item"
                                (click)="editApplicant(row)"
                                >Edit</a
                            >
                        </span>
                        <div
                            class="dropdown-divider"
                            *appIsPermitted="{
                                module: 'APPLICANTS',
                                operator: 'AND',
                                actions: ['DELETE', 'EDIT']
                            }"></div>
                        <a
                            class="dropdown-item color--red"
                            *appIsPermitted="{
                                module: 'APPLICANTS',
                                operator: 'AND',
                                actions: ['DELETE']
                            }"
                            (click)="archiveApplicant(row)"
                            >Archive</a
                        >
                    </span>
                </span>
            </td>
        </ng-container>

        <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns"></tr>
        <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns"></tr>

        <tr *matNoDataRow>
            <td colspan="6">No applicants matching the filter "{{ filter }}"</td>
        </tr>
    </table>
</div>
