import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

import { AuthenticationService } from '../authentication/authentication.service';
import CampaignModel from '../models/CampaignModel';
import CampaignReportModel from '../models/CampaignReportModel';
import Lead from '../models/Lead';
import MailchimpContentInfo from '../models/MailchimpContentInfo';
import MailchimpTagModel from '../models/MailchimpTagModel';
import MailchimpTemplate from '../models/MailchimpTemplate';
import RecipientListModel from '../models/RecipientListModel';
import RecipientModel from '../models/RecipientModel';
import AddCampaignModel from './add-campaign-modal/add-campaing-model';
import BatchAddLeadsModel from './batch-add-leads/batchAddLeadsModel';
import UpdateLeadModel from './mailchimp-recipients-modal/update-lead-model';

@Injectable({
    providedIn: 'root',
})
export class MailchimpService {
    httpOptions = {};

    constructor(private httpClient: HttpClient, private authenticationService: AuthenticationService) {
        this.httpOptions = authenticationService.getHeaders();
    }

    getMailchimpIsEnabled() {
        return this.httpClient.get<boolean>(environment.baseUrl + '/mailchimp/isEnabled', this.httpOptions);
    }

    getCampaigns() {
        return this.httpClient.get<CampaignModel[]>(environment.baseUrl + '/mailchimp/campaigns', this.httpOptions);
    }

    getReport(campaignId: string) {
        return this.httpClient.get<CampaignReportModel>(environment.baseUrl + `/mailchimp/${campaignId}/report`, this.httpOptions);
    }

    getCampaingContent(campaignId: string) {
        return this.httpClient.get<MailchimpContentInfo>(environment.baseUrl + `/mailchimp/${campaignId}/content`, this.httpOptions);
    }

    getListById(listId: string) {
        return this.httpClient.get<RecipientListModel>(environment.baseUrl + `/mailchimp/${listId}/list`, this.httpOptions);
    }

    getLists() {
        return this.httpClient.get<RecipientListModel[]>(environment.baseUrl + '/mailchimp/lists', this.httpOptions);
    }

    getRecipients(listId: string) {
        return this.httpClient.get<RecipientModel[]>(environment.baseUrl + `/mailchimp/${listId}/recipients`, this.httpOptions);
    }

    getTemplates() {
        return this.httpClient.get<MailchimpTemplate[]>(environment.baseUrl + `/mailchimp/templates`, this.httpOptions);
    }

    getTemplateById(templateId: string) {
        return this.httpClient.get<void>(environment.baseUrl + `/mailchimp/templates/${templateId}`, this.httpOptions);
    }

    addCampaign(model: AddCampaignModel) {
        return this.httpClient.post<CampaignModel>(environment.baseUrl + `/mailchimp/addCampaign`, JSON.parse(JSON.stringify(model)), this.httpOptions);
    }

    checkIfApiKeyExists() {
        return this.httpClient.get<any>(environment.baseUrl + '/mailchimp/keyExists', this.httpOptions);
    }

    getTags(listId: any) {
        return this.httpClient.get<MailchimpTagModel[]>(environment.baseUrl + `/mailchimp/${listId}/tags`, this.httpOptions);
    }

    getLeads(companyUuid: string) {
        return this.httpClient.get<Lead[]>(environment.baseUrl + `/lead/${companyUuid}/lead-list`, this.httpOptions);
    }

    batchAddLeads(body: BatchAddLeadsModel) {
        return this.httpClient.post<boolean>(environment.baseUrl + `/mailchimp/batchAddLeads`, JSON.parse(JSON.stringify(body)), this.httpOptions);
    }

    addTagToList(body: any) {
        return this.httpClient.post<MailchimpTagModel>(environment.baseUrl + `/mailchimp/addTag`, JSON.parse(JSON.stringify(body)), this.httpOptions);
    }

    removeTagFromList(listId: string, tagId: string) {
        return this.httpClient.delete<boolean>(environment.baseUrl + `/mailchimp/${listId}/removeTag/${tagId}`, this.httpOptions);
    }

    addRecipient(body: UpdateLeadModel) {
        return this.httpClient.post<RecipientModel>(environment.baseUrl + `/mailchimp/addLeadToList`, JSON.parse(JSON.stringify(body)), this.httpOptions);
    }
}
