<div class="modal-header">
    <app-loading-message *ngIf="isLoading"></app-loading-message>
    <h2
        *ngIf="!isLoading"
        class="modal-title">
        {{ lead?.email }}
    </h2>
    <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<nav
    ngbNav
    #nav="ngbNav"
    class="tabs__modal">
    <ng-container ngbNavItem>
        <a ngbNavLink>Information</a>
        <ng-template ngbNavContent>
            <ul
                class="list__specs"
                *ngIf="lead">
                <li>
                    <label>Name</label>
                    {{ lead.firstName + ' ' + lead.lastName }}
                </li>
                <li>
                    <label>Email</label>
                    <app-pill-mailto [mailtoEmail]="lead.email"></app-pill-mailto>
                </li>
                <li *ngIf="lead.phoneNumber"><label>Phonenumber</label><app-pill-telephone [contactPhonenumber]="lead.phoneNumber"></app-pill-telephone></li>
                <li *ngIf="lead.address && lead.address?.street"><label>Address</label><app-pill-address [address]="lead.address"></app-pill-address></li>
                <li *ngIf="this.moduleService.checkModuleActive('MAILCHIMP')">
                    <label>Mailchimp</label
                    ><app-pill-mailchimp
                        [email]="lead.email"
                        [firstname]="lead.firstName"
                        [lastname]="lead.lastName"
                        [phone]="lead.phoneNumber"></app-pill-mailchimp>
                </li>
                <li *ngIf="lead.channelKey"><label>Channel</label> {{ lead.channelKey }}</li>
                <ng-template *ngIf="lead.fields">
                    <li *ngIf="lead.fields.message"><label>Message</label> {{ lead.fields.message }}</li>
                    <li *ngIf="lead.fields.url"><label>Origin url</label> {{ lead.fields.url }}</li>
                    <div [innerHtml]="getFields()"></div>
                </ng-template>
                <li>
                    <label>Tags</label>
                    <app-global-tags
                        [objectUuid]="lead.uuid"
                        [type]="'lead'"></app-global-tags>
                </li>
            </ul>
        </ng-template>
    </ng-container>
    <ng-container
        ngbNavItem
        *ngIf="this.moduleService.checkModuleActive(eModule.COMMENTS)">
        <a ngbNavLink>Comments</a>
        <ng-template ngbNavContent>
            <app-comments-overview [objectId]="lead.uuid"></app-comments-overview>
        </ng-template>
    </ng-container>
    <ng-container
        ngbNavItem
        *ngIf="this.moduleService.checkModuleActive(eModule.THERMOSTAR_CONFIGURATOR_PREVIEW)">
        <a ngbNavLink>Configurator</a>
        <ng-template ngbNavContent>
            <div class="resume__info">
                <div *ngIf="lead.channelKey === 'configuratorform'">
                    <app-configurator-preview [fields]="lead.fields"></app-configurator-preview>
                </div>
            </div>
        </ng-template>
    </ng-container>
    <ng-container
        ngbNavItem
        *ngIf="this.moduleService.checkModuleActive(eModule.GOOGLE) || this.moduleService.checkModuleActive(eModule.OUTLOOK)">
        <a ngbNavLink>Communication</a>
        <ng-template ngbNavContent>
            <app-mailing-list
                *ngIf="lead"
                [lead]="lead"></app-mailing-list>
        </ng-template>
    </ng-container>
</nav>

<div
    class="modal-body"
    *ngIf="isLoading">
    <app-loading-message></app-loading-message>
</div>

<div
    class="modal-body"
    *ngIf="!isLoading">
    <div [ngbNavOutlet]="nav"></div>
    <app-message-empty
        *ngIf="!lead"
        [title]="'no info yet.'"
        [subtitle]="'Start generating leads.'"
        [icon]="'../../assets/img/open-box.png'">
    </app-message-empty>
</div>

<div class="modal-footer">
    <button
        type="button"
        class="button button--outline"
        (click)="activeModal.close()">
        Close
    </button>
    <div
        ngbDropdown
        placement="top-right"
        class="dropdown"
        *appIsPermitted="{
            module: 'LEADS',
            operator: 'OR',
            actions: ['DELETE', 'EDIT']
        }">
        <app-loading-message *ngIf="isLoading"></app-loading-message>
        <button
            type="button"
            ngbDropdownToggle
            class="button dropdown-toggle"
            id="dropdownMenuButton"
            *ngIf="!isLoading">
            Actions
        </button>
        <div
            ngbDropdownMenu
            class="dropdown-menu"
            aria-labelledby="dropdownMenuButton"
            *ngIf="!isLoading">
            <a
                class="dropdown-item"
                *appIsPermitted="{
                    module: 'LEADS',
                    operator: 'AND',
                    actions: ['EDIT']
                }"
                (click)="toggleLeadRead(lead)"
                >Mark as unread</a
            >
            <a
                class="dropdown-item"
                *appIsPermitted="{
                    module: 'LEADS',
                    operator: 'AND',
                    actions: ['EDIT']
                }"
                (click)="openLeadDetailModalComponent()"
                >Edit lead</a
            >
            <span *ngIf="!archived">
                <a
                    class="dropdown-item"
                    *appIsPermitted="{
                        module: 'CRM',
                        operator: 'AND',
                        actions: ['CREATE']
                    }"
                    (click)="openLeadToDealModal(lead)"
                    >Create deal</a
                >
                <a
                    *appIsPermitted="{
                        module: 'LEADS',
                        operator: 'AND',
                        actions: ['DELETE']
                    }"
                    class="dropdown-item color--red"
                    (click)="openLeadDeleteModalComponent()"
                    >Archive</a
                >
            </span>
            <span
                *appIsPermitted="{
                    module: 'LEADS',
                    operator: 'AND',
                    actions: ['DELETE']
                }">
                <a
                    *ngIf="archived"
                    class="dropdown-item color--red"
                    (click)="openLeadDeleteModalComponent()"
                    >Unarchive</a
                >
            </span>
        </div>
    </div>
</div>
