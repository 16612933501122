<app-app-holder>
    <app-navbar></app-navbar>

    <app-sidebar>
        <app-partners-subnav></app-partners-subnav>
    </app-sidebar>

    <app-content>
        <div *ngIf="works; else loading">
            <div *ngIf="works?.length !== 0">
                <div class="app__title">
                    <h1>Projects overview</h1>
                    <span>
                        <a
                            (click)="openAddWorkModal()"
                            class="button button--primary"
                            >Add project</a
                        >
                    </span>
                </div>

                <app-detail-projects-list
                    *ngIf="works; else loading"
                    [projects]="works">
                </app-detail-projects-list>
            </div>
        </div>
    </app-content>

    <ng-template #loading>
        <app-loading-message></app-loading-message>
    </ng-template>
</app-app-holder>
