import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';
import { Warehouse } from 'src/app/models/Warehouse';

import { ArchiveWarehouse } from '../warehouse.actions';

@Component({
    selector: 'app-warehouse-delete-modal',
    templateUrl: './warehouse-delete-modal.component.html',
    styleUrls: ['./warehouse-delete-modal.component.scss'],
})
export class WarehouseDeleteModalComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();
    @Input() warehouse: Warehouse;

    constructor(public activeModal: NgbActiveModal, private store: Store, private router: Router, private logger: NGXLogger) {}

    ngOnInit(): void {}

    archiveWarehouse() {
        this.logger.debug('attempt to archive warehouse');
        this.subscriptions.add(
            this.store.dispatch(new ArchiveWarehouse(this.warehouse.uuid)).subscribe(() => {
                this.logger.debug('Succesfully archived warehouse');
                this.activeModal.close();
                this.logger.debug('Navigating to inventory warehouses');
                this.router.navigate(['/inventory/warehouses']);
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
