import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import timeGridPlugin from '@fullcalendar/timegrid';
import { NgbDropdownModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { OrderModule } from 'ngx-order-pipe';
import { NgPipesModule } from 'ngx-pipes';
import { SortablejsModule } from 'ngx-sortablejs';
import { NgxTrimDirectiveModule } from 'ngx-trim-directive';

import { SharedModule } from '../shared/shared.module';
import { CalendarEventDetailModalComponent } from './calendar-event-detail-modal/calendar-event-detail-modal.component';
import { CalendarEventListComponent } from './calendar-event-list/calendar-event-list.component';
import { CalendarOverviewComponent } from './calendar-overview/calendar-overview.component';
import { routing } from './calendar.routing';
import { GoogleCalendarNotLoggedInComponent } from './google-calendar-not-logged-in/google-calendar-not-logged-in.component';
import { GoogleCalendarComponent } from './google-calendar/google-calendar.component';

FullCalendarModule.registerPlugins([dayGridPlugin, listPlugin, timeGridPlugin]);

@NgModule({
    declarations: [
        CalendarOverviewComponent,
        GoogleCalendarComponent,
        GoogleCalendarNotLoggedInComponent,
        CalendarEventListComponent,
        CalendarEventDetailModalComponent,
    ],
    imports: [
        CommonModule,
        SharedModule,
        routing,
        NgbModule,
        FullCalendarModule,
        NgbDropdownModule,
        NgxTrimDirectiveModule,
        NgPipesModule,
        NgPipesModule,
        OrderModule,
        SortablejsModule,
    ],
})
export class CalendarModule {}
