<div class="notification-container">
    <nav
        ngbNav
        #nav="ngbNav"
        class="tabs__modal"
        (navChange)="navChange()">
        <ng-container ngbNavItem>
            <a ngbNavLink>All</a>
            <ng-template ngbNavContent>
                <ng-container *ngIf="notifications$ | async; else loading">
                    <ng-container *ngIf="notifications.length > 0; else emptyAll">
                        <div class="notification-panel">
                            <app-notification-message
                                *ngFor="let notification of notifications"
                                [notification]="notification"
                                class="notification">
                            </app-notification-message>
                        </div>
                        <p
                            (click)="archiveAllNotifications()"
                            class="notification__archive-all">
                            <i class="las la-archive m-r-2"></i>Archive all notifications
                        </p>
                    </ng-container>
                </ng-container>
            </ng-template>
        </ng-container>
        <ng-container ngbNavItem>
            <a ngbNavLink>Archived</a>
            <ng-template ngbNavContent>
                <ng-container *ngIf="fetchedArchivedNotifications; else loading">
                    <ng-container *ngIf="archivedNotifications.length > 0; else emptyArchived">
                        <div class="notification-panel">
                            <app-notification-message
                                *ngFor="let notification of archivedNotifications"
                                [notification]="notification"
                                class="notification">
                            </app-notification-message>
                        </div>
                        <p
                            (click)="deleteAllArchivedNotifications()"
                            class="notification__archive-all">
                            <i class="las la-trash-alt m-r-2"></i>Delete all archived notifications
                        </p>
                    </ng-container>
                </ng-container>
            </ng-template>
        </ng-container>
    </nav>

    <div [ngbNavOutlet]="nav"></div>
</div>

<ng-template #loading>
    <app-loading-message></app-loading-message>
</ng-template>

<ng-template #emptyAll>
    <app-message-empty [subtitle]="'No notifications...'"></app-message-empty>
</ng-template>

<ng-template #emptyArchived>
    <app-message-empty [subtitle]="'No archived notifications...'"></app-message-empty>
</ng-template>
