<div class="form-group m-b-2">
    <label>Description</label>
    <textarea
        (focus)="hideErrorMessage()"
        (keyup)="onDescriptionKeyUp()"
        class="resize-vertical"
        [(ngModel)]="description"
        trim="blur">
    </textarea>
    <small> {{ currentAmountOfDescriptionCharacters }}/{{ DESCRIPTION_MAX_LENGTH }} </small>
    <span
        class="form-error"
        *ngIf="descriptionIsTooLong">
        Description cannot contain more than 1000 characters
    </span>
</div>
