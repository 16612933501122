<app-app-holder>
    <app-navbar></app-navbar>

    <app-sidebar>
        <app-projects-subnav></app-projects-subnav>
    </app-sidebar>

    <app-content>
        <div class="container-fluid">
            <div class="row">
                <div class="col">
                    <div
                        class="work"
                        *ngIf="project as project">
                        <div class="work__header">
                            <div class="work__info">
                                <h2>{{ project.title }}</h2>
                                <time>
                                    {{ project.startDate | date }} -
                                    <span *ngIf="!project.present">{{ project.endDate | date }}</span>
                                    <span *ngIf="project.present">present</span>
                                </time>

                                <!--
                            <small *ngIf="resume.daysSinceCreation != 0">Created {{resume.daysSinceCreation}} days ago</small>
                            <small *ngIf="resume.daysSinceCreation == 0">Created today</small>
                            --></div>
                            <div class="resume__actions">
                                <ng-template #popContent>
                                    <div class="d-flex">
                                        <span class="color--grey m-r-1">Created by</span>
                                        <span>{{ user.firstName + ' ' + user.lastName }}</span>
                                    </div>
                                    <div
                                        class="d-flex"
                                        *ngIf="work.partnerName">
                                        <span class="color--grey m-r-1">Company</span>
                                        <span>{{ project.partnerName }}</span>
                                    </div>
                                </ng-template>
                                <span
                                    class="button button--outline button--small m-r-2"
                                    [ngbPopover]="popContent"
                                    popoverTitle="Information"
                                    ><i class="uil uil-info-circle"></i> Info</span
                                >

                                <div
                                    ngbDropdown
                                    class="dropdown">
                                    <button
                                        ngbDropdownToggle
                                        class="button button--icon button--small dropdown-toggle"
                                        id="dropdownMenuButton">
                                        <span>Actions</span>
                                    </button>
                                    <div
                                        ngbDropdownMenu
                                        class="dropdown-menu dropdown-menu-right"
                                        aria-labelledby="dropdownMenuButton">
                                        <a
                                            *ngIf="this.moduleService.checkModuleActive(eModule.EMPLOYEES)"
                                            class="dropdown-item"
                                            (click)="openEmployeeAssignModal(project)"
                                            >Assign employee</a
                                        >
                                        <div class="dropdown-divider"></div>
                                        <a
                                            class="dropdown-item"
                                            (click)="openWorkUpdateModal(project)"
                                            >Edit Project</a
                                        >
                                        <a
                                            class="dropdown-item color--red"
                                            (click)="openWorkDeleteModal(project)"
                                            >Archive Project</a
                                        >
                                    </div>
                                </div>
                                <!--<img src="assets/img/alpinedigital.png" />-->

                                <span
                                    class="tag tag--light tag--big m-r-2 m-b-2"
                                    *ngFor="let tag of project.tags | orderBy: 'value':true">
                                    {{ tag.title }}
                                </span>
                            </div>
                        </div>

                        <div>
                            <label>Location: </label>
                            <span *ngIf="project.location && project.location.number != 0">
                                {{ project.location.street }}
                                {{ project.location.number }},
                                {{ project.location.postal }}
                                {{ project.location.city }}
                            </span>
                            <span *ngIf="!project.location">No Location added...</span>
                        </div>

                        <div>
                            <label>Description: </label>
                            <span
                                class="format-newlines"
                                *ngIf="project.description">
                                {{ project.description }}
                            </span>
                            <span *ngIf="!project.description">No description added...</span>
                        </div>

                        <div>
                            <label>Extra Info: </label>
                            <span *ngIf="project.pdfUpload">
                                <a
                                    [href]="project.pdfUpload"
                                    target="_blank"
                                    >View PDF</a
                                >
                            </span>
                            <span *ngIf="!project.pdfUpload">No PDF uploaded...</span>
                        </div>

                        <section *ngIf="this.moduleService.checkModuleActive(eModule.EMPLOYEES)">
                            <header>
                                Assigned Employees
                                <!-- <button type="button" class="button button--small button--primary" (click)="openProjectAddModal()">Add</button> -->
                            </header>
                            <app-assigned-employee-list
                                *ngIf="project.employees"
                                [object]="project"
                                [assignedEmployees]="project.employees"></app-assigned-employee-list>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    </app-content>

    <ng-template #loading>
        <app-loading-message></app-loading-message>
    </ng-template>
</app-app-holder>
