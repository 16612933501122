import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MomentModule } from 'ngx-moment';

import { SharedModule } from '../shared/shared.module';
import { ComanageContactsComponent } from './comanage-contacts/comanage-contacts.component';
import { ComanageCustomersComponent } from './comanage-customers/comanage-customers.component';
import { ComanageInvoicesComponent } from './comanage-invoices/comanage-invoices.component';
import { ComanageOffersComponent } from './comanage-offers/comanage-offers.component';
import { ComanageSubnavComponent } from './comanage-subnav/comanage-subnav.component';
import { routing } from './comanage.routing';

@NgModule({
    declarations: [ComanageContactsComponent, ComanageSubnavComponent, ComanageInvoicesComponent, ComanageOffersComponent, ComanageCustomersComponent],
    imports: [CommonModule, SharedModule, routing, MatTableModule, CdkTableModule, MatSortModule, MatPaginatorModule, MomentModule],
    exports: [],
})
export class ComanageModule {}
