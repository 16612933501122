<table class="table-view">
    <tr>
        <th>Language</th>
        <th>Strength</th>
        <th class="td-small"></th>
    </tr>
    <tr *ngFor="let language of languages">
        <td><strong>{{language.language}}</strong></td>
        <td>
            <ng-container
                *ngIf="language.strength !== 'mothertongue'; else mothertongue">{{language.strength | titlecase}}</ng-container>
            <ng-template #mothertongue>Mother tongue</ng-template>
        </td>
        <td class="td-icon">
            <div
                class="dropdown"
                ngbDropdown>
                <button
                    class="button button--icon button--small dropdown-toggle"
                    id="dropdownMenuButton"
                    ngbDropdownToggle
                    type="button"></button>
                <div
                    aria-labelledby="dropdownMenuButton"
                    class="dropdown-menu dropdown-menu-right"
                    ngbDropdownMenu>
                    <a (click)="openLanguageEditModal(language)" class="dropdown-item">
                        Edit language
                    </a>
                    <a (click)="openLanguageDeleteModal(language)" class="dropdown-item color--red">
                        Delete
                    </a>
                </div>
            </div>
        </td>
    </tr>
    <tr *ngIf="languages?.length === 0">
        <td colspan="3">
            No languages added yet...
        </td>
    </tr>
    <tr>
        <td (click)="openLanguageAddModal()" class="table-view__add" colspan="3">
            <i class="las la-plus"></i>
            Add language
        </td>
    </tr>
</table>
