<form (ngSubmit)="updateCategoryName()">
    <div class="modal-header">
        <h2
            class="modal-title"
            id="exampleModalLabel">
            Edit category
        </h2>
        <span></span>
        <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="activeModal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <span class="row">
            <label for="categoryName">Category name</label>
            <input
                [(ngModel)]="newCategoryName"
                id="categoryName"
                type="text"
                name="categoryName" />
        </span>

        <span class="row">
            <label for="excludeFromFinances">Exclude from finances</label>
            <input
                [(ngModel)]="excludeFromFinances"
                id="excludeFromFinances"
                type="checkbox"
                name="excludeFromFinances" />
        </span>
    </div>

    <div class="modal-footer">
        <input
            type="submit"
            class="button button--primary"
            value="Update"
            (click)="updateCategoryName()" />
        <button
            type="button"
            class="button button--outline"
            (click)="activeModal.dismiss()">
            Cancel
        </button>
    </div>
</form>
