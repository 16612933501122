<a
    class="pill"
    (click)="$event.stopPropagation(); openOverheadCategoryDetailModal()">
    <i class="las la-chart-bar"></i>
    <ng-container *ngIf="overheadCategory; else loading">
        {{ overheadCategory.categoryName }}
    </ng-container>
</a>

<ng-template #loading>
    <app-loading-message></app-loading-message>
</ng-template>
