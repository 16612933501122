import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subscription } from 'rxjs';
import { FetchCompany } from 'src/app/companies/company.actions';
import { CompanyState } from 'src/app/companies/company.state';
import { Company } from 'src/app/models/Company';
import { Module } from 'src/app/models/Module';
import { ModuleEnum } from 'src/app/models/ModuleEnum';
import { CustomRolesState } from 'src/app/settings/roles/custom.roles.state';
import { ClearStore, Logout } from 'src/app/users/user.actions';
import { UserState } from 'src/app/users/user.state';

import { Notification } from '../../models/Notification';
import { NotificationModalComponent } from '../../notifications/notification-modal/notification-modal.component';
import { NotificationsState } from '../../notifications/notifications.state';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();
    @Select(CustomRolesState.AllowedModuleKeys) allowedModules$: Observable<string[]>;
    @Select(CompanyState.company) company$: Observable<Company>;
    @Select(NotificationsState.notifications) notifications$: Observable<Notification[]>;

    profilePath: string;
    profileName: string;
    logoPlaceholder: string;
    logo: string;
    modules: Module[];
    company: Company;

    eModule = ModuleEnum;

    checkedModules: string[] = [];
    notifications: Notification[];
    numberOfUnreadNotifications: number;

    constructor(private router: Router, private store: Store, private logger: NGXLogger, private modalService: NgbModal) {}

    ngOnInit(): void {
        this.logoPlaceholder = '../../../assets/img/logo.svg';

        this.subscriptions.add(
            this.allowedModules$.subscribe((allowedModules) => {
                this.checkedModules = JSON.parse(JSON.stringify(allowedModules));
            }),
        );
        this.subscriptions.add(
            this.store.select(UserState.userInfo).subscribe((storeUser) => {
                if (storeUser) {
                    this.profileName = storeUser.firstName + ' ' + storeUser.lastName;
                    this.profilePath = 'https://eu.ui-avatars.com/api/?background=random&bold=true&name=' + storeUser.firstName + '%20' + storeUser.lastName;
                }
            }),
        );

        this.subscriptions.add(
            this.company$.subscribe((company) => {
                if (!company) {
                    this.logger.debug('Fetching company');
                    this.store.dispatch(new FetchCompany());
                    return;
                }

                company && company.logo ? (this.logo = company.logo) : (this.logo = this.logoPlaceholder);
                company?.modules ? (this.modules = company.modules) : (this.modules = null);

                this.company = company;
            }),
        );

        this.subscriptions.add(
            this.notifications$.subscribe((notifications) => {
                this.notifications = notifications;
                this.numberOfUnreadNotifications = notifications.reduce((unreadMessages: number, notification: Notification) => {
                    if (!notification.read) unreadMessages++;
                    return unreadMessages;
                }, 0);
            }),
        );
    }

    checkActiveAndPermitted(eModuleName) {
        return this.checkedModules.filter((m) => m?.toLowerCase() == eModuleName.toLowerCase()).length > 0;
    }

    logout() {
        this.logger.debug('Attempt to logout of resumo');
        this.subscriptions.add(
            this.store.dispatch(new Logout()).subscribe(() => {
                this.logger.debug('Logout succesful');
                localStorage.removeItem('token');
                this.logger.debug('Clearning store');
                this.store.dispatch(new ClearStore()).subscribe(() => this.router.navigate(['/login']));
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    showNotifications() {
        this.modalService.open(NotificationModalComponent, {
            windowClass: 'modal-notifications',
            backdropClass: 'modal-backdrop--invisible',
            animation: false,
        });
    }
}
