import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';

import { SharedModule } from '../shared/shared.module';
import { VeldaLanguagesCountriesAddModalComponent } from './velda-languages-countries-add-modal/velda-languages-countries-add-modal.component';
import { VeldaLanguagesCountriesDeleteModalComponent } from './velda-languages-countries-delete-modal/velda-languages-countries-delete-modal.component';
import { VeldaLanguagesCountriesEditModalComponent } from './velda-languages-countries-edit-modal/velda-languages-countries-edit-modal.component';
import { VeldaLanguagesCountriesListComponent } from './velda-languages-countries-list/velda-languages-countries-list.component';
import { VeldaLanguagesCountriesOverviewComponent } from './velda-languages-countries-overview/velda-languages-countries-overview.component';
import { VeldaLanguagesLanguagesAddModalComponent } from './velda-languages-languages-add-modal/velda-languages-languages-add-modal.component';
import { VeldaLanguagesLanguagesDeleteModalComponent } from './velda-languages-languages-delete-modal/velda-languages-languages-delete-modal.component';
import { VeldaLanguagesLanguagesEditModalComponent } from './velda-languages-languages-edit-modal/velda-languages-languages-edit-modal.component';
import { VeldaLanguagesLanguagesListComponent } from './velda-languages-languages-list/velda-languages-languages-list.component';
import { VeldaLanguagesLanguagesOverviewComponent } from './velda-languages-languages-overview/velda-languages-languages-overview.component';
import { VeldaLanguagesSubnavComponent } from './velda-languages-subnav/velda-languages-subnav.component';
import { routing } from './velda-languages.routing';

@NgModule({
    declarations: [
        VeldaLanguagesSubnavComponent,
        VeldaLanguagesCountriesListComponent,
        VeldaLanguagesCountriesOverviewComponent,
        VeldaLanguagesLanguagesOverviewComponent,
        VeldaLanguagesLanguagesListComponent,
        VeldaLanguagesCountriesAddModalComponent,
        VeldaLanguagesCountriesEditModalComponent,
        VeldaLanguagesCountriesDeleteModalComponent,
        VeldaLanguagesLanguagesAddModalComponent,
        VeldaLanguagesLanguagesEditModalComponent,
        VeldaLanguagesLanguagesDeleteModalComponent,
    ],
    imports: [CommonModule, SharedModule, routing, CdkTableModule, MatTableModule, MatSortModule, NgbDropdownModule, AutocompleteLibModule],
})
export class VeldaLanguagesModule {}
