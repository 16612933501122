<form
    [formGroup]="calendarCallForm"
    class="modal-wrapper"
    (ngSubmit)="AddCalendarCall()">
    <div class="modal-header">
        <h2 class="modal-title">Add new Calendar Call</h2>
    </div>

    <div class="modal-body">
        <div class="row">
            <div class="col">
                <div class="form-group m-b-2">
                    <label for="title">Title</label>
                    <input
                        id="title"
                        formControlName="title"
                        type="text"
                        placeholder="Title*" />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="form-group m-b-2">
                    <label for="description">Description</label>
                    <input
                        id="description"
                        formControlName="description"
                        type="text"
                        placeholder="Description*" />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="form-group m-b-2">
                    <label for="activityTypeId">Link A Contact</label>
                    <select formControlName="contact">
                        <option
                            *ngFor="let contact of contacts"
                            [ngValue]="{ type: 'contact', id: contact.id }">
                            {{ contact.first_name }} {{ contact.last_name }}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="form-group m-b-2">
                    <label for="activityTypeId">Link A Company</label>
                    <select formControlName="company">
                        <option
                            *ngFor="let company of companies"
                            [ngValue]="{ type: 'company', id: company.id }">
                            {{ company.name }}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="form-group m-b-2">
                    <label for="startsAt">Start Time</label>
                    <mat-form-field>
                        <input
                            formControlName="startsAt"
                            matInput
                            type="datetime-local"
                            placeholder="Start date" />
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="form-group m-b-2">
                    <label for="endsAt">End Time</label>
                    <mat-form-field>
                        <input
                            formControlName="endsAt"
                            matInput
                            type="datetime-local"
                            placeholder="End date" />
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <input
            [ngClass]="!calendarCallForm.valid ? 'button button--disabled w-100' : 'button button--primary w-100'"
            type="submit"
            [disabled]="!calendarCallForm.valid"
            value="Add Event" />
    </div>
</form>
