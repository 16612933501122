import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';
import { Education } from 'src/app/models/resume/Education';

import { DeleteEducation } from '../resume.actions';

@Component({
    selector: 'app-education-delete-modal',
    templateUrl: './education-delete-modal.component.html',
    styleUrls: ['./education-delete-modal.component.scss'],
})
export class EducationDeleteModalComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Input() education: Education;

    constructor(public activeModal: NgbActiveModal, private store: Store, private logger: NGXLogger) {}

    ngOnInit(): void {}

    deleteEducation() {
        this.logger.debug('Attempt to delete education');
        this.subscriptions.add(
            this.store.dispatch(new DeleteEducation(this.education.uuid)).subscribe(
                () => {
                    this.logger.debug('Succesfully deleted education');
                    this.activeModal.close();
                },
                (error) => {
                    this.logger.debug('Failed to deleted education');
                },
            ),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
