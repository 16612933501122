import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { AuthenticationService } from '../authentication/authentication.service';
import Budget from '../models/Budget';
import { Employee } from '../models/Employee';
import LinkedEmployee from '../models/LinkedEmployee';
import { LinkedEmployeeViewModel } from '../models/LinkedEmployeeViewModel';
import Project from '../models/Project';
import { StockUpdateHistoryModel } from '../models/StockUpdateHistoryModel';
import { YukiFileObject } from '../models/YukiFileObject';
import { ProjectService } from '../projects/project.service';

@Injectable({
    providedIn: 'root',
})
export class EmployeeService {
    httpOptions = {};

    constructor(private httpClient: HttpClient, private authenticationService: AuthenticationService, private projectService: ProjectService) {
        this.httpOptions = authenticationService.getHeaders();
    }

    getAllEmployees(): Observable<Employee[]> {
        return this.httpClient.get<Employee[]>(environment.baseUrl + '/employee/employee-list', this.httpOptions);
    }

    getSimpleInformationForAllEmployees(): Observable<Employee[]> {
        return this.httpClient.get<Employee[]>(environment.baseUrl + '/employee/employee-list/simple-information', this.httpOptions);
    }

    getAllLinkedEmployees(): Observable<LinkedEmployee[]> {
        return this.httpClient.get<LinkedEmployee[]>(environment.baseUrl + '/related-employees', this.httpOptions);
    }

    addEmployee(employee: Employee): Observable<Employee> {
        return this.httpClient.post<Employee>(environment.baseUrl + '/employee/', employee, this.httpOptions);
    }

    updateEmployee(employee: Employee): Observable<Employee> {
        return this.httpClient.put<Employee>(environment.baseUrl + '/employee/' + employee.uuid, employee, this.httpOptions);
    }

    deleteEmployee(employeeUuid: string): Observable<string> {
        return this.httpClient.delete<string>(environment.baseUrl + '/employee/' + employeeUuid, this.httpOptions);
    }

    archiveEmployee(employeeUuid: string): Observable<Employee> {
        return this.httpClient.put<Employee>(environment.baseUrl + '/employee/archive/' + employeeUuid, {}, this.httpOptions);
    }

    checkIfUserIsLinked(employeeUuid: string): Observable<boolean> {
        return this.httpClient.get<boolean>(environment.baseUrl + '/employee/linked/' + employeeUuid, this.httpOptions);
    }

    getBudgetsForEmployee(employeeUuid: string) {
        return this.httpClient.get<Budget[]>(`${environment.baseUrl}/budget/employee/${employeeUuid}`, this.httpOptions);
    }

    getEmployeeById(employeeUuid: string) {
        return this.httpClient.get<Employee>(`${environment.baseUrl}/employee/${employeeUuid}`, this.httpOptions);
    }

    getAllProjects() {
        return this.httpClient.get<Project[]>(`${environment.baseUrl}/project`, this.httpOptions);
    }

    getProjectListByEmployee(employeeUuid: string) {
        return this.httpClient.get<Project[]>(`${environment.baseUrl}/project/` + employeeUuid, this.httpOptions);
    }

    getAllLinkedProjects(employeeUuid: string): Observable<Project[]> {
        if (this.projectService.getTokenIfExists() != '') {
            return this.httpClient.get<Project[]>(
                `${environment.baseUrl}/timesheetsmanagement/projects-for-employee${this.projectService.getTokenIfExists()}`,
                this.httpOptions,
            );
        }
        return this.httpClient.get<Project[]>(`${environment.baseUrl}/employee/${employeeUuid}/projects`, this.httpOptions);
    }

    getAllYukiFileObjectsByEmployee(employeeUuid: string) {
        return this.httpClient.get<YukiFileObject[]>(environment.baseUrl + '/file/link/employee/' + employeeUuid, this.httpOptions);
    }

    getAllLinkedEmployeesForEmployee(employeeId: string, type?: string): Observable<LinkedEmployeeViewModel[]> {
        let url = `${environment.baseUrl}/related-employees/${employeeId}`;
        if (type) {
            url += `?type=${type}`;
        }
        return this.httpClient.get<LinkedEmployeeViewModel[]>(url, this.httpOptions);
    }

    getStockUpdateHistoryForEmployee(employeeId: string): Observable<StockUpdateHistoryModel[]> {
        return this.httpClient.get<StockUpdateHistoryModel[]>(`${environment.baseUrl}/product/history/${employeeId}`, this.httpOptions);
    }
}
