<div
    class="table__overflow"
    *ngIf="!this.isLoading">
    <table
        class="table-view"
        *ngIf="linkedObjects.length > 0">
        <tr>
            <th
                scope="col"
                *ngIf="itemLinkType === 'contact'">
                Contacts
            </th>
            <th
                scope="col"
                *ngIf="itemLinkType === 'deal'">
                Deals
            </th>
            <th
                scope="col"
                *ngIf="itemLinkType === 'project'">
                Projects
            </th>
            <th
                scope="col"
                *ngIf="itemLinkType === 'product'">
                Products
            </th>
            <th
                scope="col"
                *ngIf="itemLinkType === 'employee'">
                Employees
            </th>
            <th width="1"></th>
        </tr>
        <tr *ngFor="let item of linkedObjects">
            <td>
                <app-pill-contact
                    *ngIf="itemLinkType === 'contact'"
                    [contactPerson]="item"></app-pill-contact>
                <app-pill-project
                    *ngIf="itemLinkType === 'project'"
                    [project]="item"></app-pill-project>
                <app-pill-crmdeal
                    *ngIf="itemLinkType === 'deal'"
                    [deal]="item"></app-pill-crmdeal>
                <app-pill-product
                    *ngIf="itemLinkType === 'product'"
                    [product]="item"></app-pill-product>
                <app-pill-employee
                    *ngIf="itemLinkType === 'employee'"
                    [employee]="item"></app-pill-employee>
            </td>
            <td
                class="td-icon"
                (click)="unLinkItemFunction(item)">
                <button
                    type="button"
                    class="button button--icon">
                    <i class="las la-trash color--red"></i>
                </button>
            </td>
        </tr>

        <tr>
            <td
                colspan="3"
                class="table-view__add"
                (click)="openGlobalAutocomplete()">
                <i class="las la-plus"></i> New {{ itemLinkType }}
            </td>
        </tr>
        <app-loading-message *ngIf="removingLink"></app-loading-message>
    </table>

    <div *ngIf="linkedObjects.length === 0">
        <app-message-empty
            [title]="emptyMessageTitle"
            [subtitle]="emptyMessageDescription"
            [icon]="'../../assets/img/open-box.png'">
            <a
                *appIsPermitted="{ module: itemLinkType.toUpperCase() + 'S', operator: 'AND', actions: ['CREATE'] }"
                (click)="openGlobalAutocomplete()"
                class="button button--primary"
                >Add {{ itemLinkType }}</a
            >
        </app-message-empty>
    </div>
</div>

<div *ngIf="this.isLoading">
    <app-loading-message></app-loading-message>
</div>
