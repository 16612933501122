<div>
    <div
        class="message pointer m-b-2"
        [ngClass]="{
            'message--success': messageSuccess,
            'message--warning': messageWarning,
            'message--urgent': messageUrgent,
            'message--info': messageInfo
        }">
        <p>
            <strong>{{ title }}</strong>
        </p>
        <p>{{ message }}</p>
    </div>
</div>
