import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { append, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { format } from 'date-fns';
import * as moment from 'moment';
import { tap } from 'rxjs/operators';

import Project from '../models/Project';
import { ROUTES } from '../models/ROUTES';
import { Timesheet } from '../models/Timesheet';
import { Timeslot } from '../models/Timeslot';
import { CreateNotification } from '../notifications/notifications.actions';
import {
    AddTimesheet,
    AddTimeslot,
    ChangeSelectedDate,
    ChangeTimesheetStatus,
    FetchAllProjectsProtected,
    FetchCurrentTimesheet,
    FetchTimesheetByUserAndDate,
    FetchTimesheets,
    FetchTimesheetsByProjectIdAndYearAndMonth,
    FetchTimesheetsForEmployee,
    FetchTimesheetsForProject,
    RemoveTimesheet,
    RemoveTimeslot,
    ResetTimesheetsByProjectIdAndYearAndMonth,
    SetTimeslotToIsBilled,
    UpdateExtraInfo,
    UpdateHappinessScore,
    UpdateRequiredHours,
    UpdateTimeSlot,
    UpdateTimesheet,
} from './timesheets.actions';
import { TimesheetsService } from './timesheets.service';

export interface TimesheetStateModel {
    selectedDate: string;
    timesheets: Timesheet[];
    currentTimesheetsForProject: Timesheet[];
    timesheetsForProject: Timesheet[];
    publicTimesheet: Timesheet;
    allProjectsProtected: Project[];
    timesheetsForProjectInMonthAndYear: Timesheet[];
}

@State<TimesheetStateModel>({
    name: 'timesheet',
    defaults: {
        selectedDate: moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD'),
        timesheets: null,
        currentTimesheetsForProject: null,
        timesheetsForProject: null,
        publicTimesheet: null,
        allProjectsProtected: null,
        timesheetsForProjectInMonthAndYear: null,
    },
})
@Injectable()
export class TimesheetState {
    constructor(private timesheetsService: TimesheetsService, private store: Store) {}

    @Selector()
    static selectedDate(state: TimesheetStateModel): string {
        return state?.selectedDate;
    }

    @Selector()
    static timesheets(state: TimesheetStateModel): Timesheet[] {
        return state?.timesheets;
    }

    @Selector()
    static currentTimesheets(state: TimesheetStateModel): Timesheet[] {
        return state?.currentTimesheetsForProject;
    }

    @Selector()
    static publicTimesheet(state: TimesheetStateModel): Timesheet {
        return state?.publicTimesheet;
    }

    @Selector()
    static timesheetsForProject(state: TimesheetStateModel): Timesheet[] {
        return state?.timesheetsForProject;
    }

    @Selector()
    static timesheetsForProjectInMonthAndYear(state: TimesheetStateModel): Timesheet[] {
        return state?.timesheetsForProjectInMonthAndYear;
    }

    @Selector()
    static allProjectsProtected(state: TimesheetStateModel): Project[] {
        return state?.allProjectsProtected;
    }

    @Action(FetchCurrentTimesheet)
    fetchCurrentTimesheets(ctx: StateContext<TimesheetStateModel>, action: FetchCurrentTimesheet) {
        return this.timesheetsService.getTimesheetsByProjectUuid(action.projectUuid).pipe(
            tap((timesheets) => {
                ctx.setState(
                    patch<TimesheetStateModel>({
                        currentTimesheetsForProject: this.timesheetsService.parseTimesheetsStatus(timesheets),
                    }),
                );
            }),
        );
    }

    @Action(FetchTimesheets)
    fetchTimesheets(ctx: StateContext<TimesheetStateModel>) {
        return this.timesheetsService.getTimesheets(ctx.getState().selectedDate).pipe(
            tap((timesheets) => {
                ctx.setState(
                    patch<TimesheetStateModel>({
                        timesheets: timesheets,
                    }),
                );
            }),
        );
    }

    @Action(FetchTimesheetsForProject)
    fetchTimesheetsForProject(ctx: StateContext<TimesheetStateModel>, action: FetchTimesheetsForProject) {
        return this.timesheetsService.getTimeSheetsForProject(action.projectId).pipe(
            tap((timesheets) => {
                ctx.setState(
                    patch<TimesheetStateModel>({
                        timesheetsForProject: this.timesheetsService.parseTimesheetsStatus(timesheets),
                    }),
                );
            }),
        );
    }

    @Action(FetchTimesheetByUserAndDate)
    fetchTimesheetByUserAndDate(ctx: StateContext<TimesheetStateModel>, action: FetchTimesheetByUserAndDate) {
        return this.timesheetsService.getTimesheetByUserAndDate(action.userId, action.date).pipe(
            tap((timesheet) => {
                ctx.setState(
                    patch<TimesheetStateModel>({
                        publicTimesheet: timesheet,
                    }),
                );
            }),
        );
    }

    @Action(ChangeSelectedDate)
    changeSelectedDate(ctx: StateContext<TimesheetStateModel>, action: ChangeSelectedDate) {
        let value = ctx.getState().selectedDate;
        if (action.expDate) {
            value = moment(new Date(action.expDate)).format('YYYY-MM-DD');
        }
        if (!action.expDate && action.amount != 0) {
            value = moment(ctx.getState().selectedDate).add(action.amount, 'months').endOf('month').format('YYYY-MM-DD');
        } else if (action.amount != null) {
            value = moment().clone().endOf('month').add(-1, 'month').format('YYYY-MM-DD');
        }
        ctx.setState(
            patch<TimesheetStateModel>({
                selectedDate: value,
            }),
        );

        this.fetchTimesheets(ctx).subscribe();
    }

    @Action(AddTimesheet)
    addTimesheet(ctx: StateContext<TimesheetStateModel>, action: AddTimesheet) {
        return this.timesheetsService.addTimesheet(action.employeeUuid, ctx.getState().selectedDate, action.hoursRequired).pipe(
            tap((t) => {
                ctx.setState(
                    patch<TimesheetStateModel>({
                        timesheets: append<Timesheet>([this.timesheetsService.parseTimesheetStatus(t)]),
                    }),
                );
                this.store.dispatch(
                    new CreateNotification({
                        message: `A timesheet for <strong>${t.employee.firstName} ${t.employee.lastName}</strong> for the month <strong>${format(
                            new Date(t.expirationDate.toString()),
                            'MMMM',
                        )}</strong> is updated`,
                        route: ROUTES.TIMESHEETS.route,
                    }),
                );
            }),
        );
    }

    @Action(UpdateTimesheet)
    updateTimesheet(ctx: StateContext<TimesheetStateModel>, action: UpdateTimesheet) {
        return this.timesheetsService.updateTimesheet(action.timesheet).pipe(
            tap((timesheet) => {
                if (ctx.getState().timesheets) {
                    ctx.setState(
                        patch<TimesheetStateModel>({
                            timesheets: updateItem<Timesheet>((ts) => ts.uuid === action.timesheet.uuid, timesheet),
                        }),
                    );
                }
                if (ctx.getState().publicTimesheet) {
                    ctx.setState(
                        patch<TimesheetStateModel>({
                            publicTimesheet: timesheet,
                        }),
                    );
                }
                this.store.dispatch(
                    new CreateNotification({
                        message: `A timesheet for <strong>${timesheet.employee.firstName} ${
                            timesheet.employee.lastName
                        }</strong> for the month <strong>${format(new Date(timesheet.expirationDate.toString()), 'MMMM')}</strong> is updated`,
                        route: ROUTES.TIMESHEETS.route,
                    }),
                );
            }),
        );
    }

    @Action(RemoveTimesheet)
    removeTimesheet(ctx: StateContext<TimesheetStateModel>, action: RemoveTimesheet) {
        return this.timesheetsService.removeTimesheet(action.timesheetUuid).pipe(
            tap(() => {
                const timesheet: Timesheet = ctx.getState().timesheets.find((timesheet) => timesheet.uuid === action.timesheetUuid);
                ctx.setState(
                    patch<TimesheetStateModel>({
                        timesheets: removeItem<Timesheet>((ts) => ts.uuid === action.timesheetUuid),
                    }),
                );
                this.store.dispatch(
                    new CreateNotification({
                        message: `A timesheet for <strong>${timesheet.employee.firstName} ${
                            timesheet.employee.lastName
                        }</strong> for the month <strong>${format(new Date(timesheet.expirationDate.toString()), 'MMMM')}</strong> is updated`,
                        route: ROUTES.TIMESHEETS.route,
                    }),
                );
            }),
        );
    }

    @Action(AddTimeslot)
    addTimeslot(ctx: StateContext<TimesheetStateModel>, action: AddTimeslot) {
        return this.timesheetsService.addTimeslot(action.timesheetUuid, action.projectUuid, action.minutes, action.pdfUrl, action.description).pipe(
            tap((timesheet) => {
                if (ctx.getState().timesheets) {
                    ctx.setState(
                        patch<TimesheetStateModel>({
                            timesheets: updateItem<Timesheet>((t) => t.uuid === action.timesheetUuid, timesheet),
                        }),
                    );
                }
                if (ctx.getState().publicTimesheet) {
                    ctx.setState(
                        patch<TimesheetStateModel>({
                            publicTimesheet: timesheet,
                        }),
                    );
                }
                this.store.dispatch(
                    new CreateNotification({
                        message: `A timeslot for the timesheet of <strong>${timesheet.employee.firstName} ${
                            timesheet.employee.lastName
                        }</strong> for the month <strong>${format(new Date(timesheet.expirationDate.toString()), 'MMMM')}</strong> is added`,
                        route: ROUTES.TIMESHEETS.route,
                    }),
                );
            }),
        );
    }

    @Action(UpdateTimeSlot)
    updateTimeSlot(ctx: StateContext<TimesheetStateModel>, action: UpdateTimeSlot) {
        return this.timesheetsService.updateTimeslot(action.timeslot).pipe(
            tap((timesheet) => {
                if (ctx.getState().timesheets) {
                    ctx.setState(
                        patch<TimesheetStateModel>({
                            timesheets: updateItem<Timesheet>((t) => t.uuid === action.timesheetUuid, timesheet),
                        }),
                    );
                }
                if (ctx.getState().publicTimesheet) {
                    ctx.setState(
                        patch<TimesheetStateModel>({
                            publicTimesheet: timesheet,
                        }),
                    );
                }
                this.store.dispatch(
                    new CreateNotification({
                        message: `A timeslot for the timesheet of <strong>${timesheet.employee.firstName} ${
                            timesheet.employee.lastName
                        }</strong> for the month <strong>${format(new Date(timesheet.expirationDate.toString()), 'MMMM')}</strong> is updated`,
                        route: ROUTES.TIMESHEETS.route,
                    }),
                );
            }),
        );
    }

    @Action(SetTimeslotToIsBilled)
    setTimeslotToIsBilled(ctx: StateContext<TimesheetStateModel>, action: SetTimeslotToIsBilled) {
        const state = ctx.getState();
        const { timesheetId, timeslotId, billed } = action;

        const updatedTimesheets = state.timesheets.map((timesheet) => {
            if (timesheet.uuid === timesheetId) {
                const updatedTimeslots = timesheet.timeslots.map((timeslot) => {
                    if (timeslot.uuid === timeslotId) {
                        return { ...timeslot, billed };
                    }
                    return timeslot;
                });

                return { ...timesheet, timeslots: updatedTimeslots };
            }
            return timesheet;
        });

        ctx.patchState({ timesheets: updatedTimesheets });
    }

    @Action(RemoveTimeslot)
    removeTimeSlot(ctx: StateContext<TimesheetStateModel>, action: RemoveTimeslot) {
        return this.timesheetsService.removeTimeslot(action.timeslotUuid).pipe(
            tap(() => {
                const timesheet: Timesheet = ctx.getState().timesheets?.find((timesheet) => timesheet.uuid === action.timesheetUuid);
                if (ctx.getState().timesheets) {
                    ctx.setState(
                        patch<TimesheetStateModel>({
                            timesheets: updateItem<Timesheet>(
                                (t) => t.uuid === action.timesheetUuid,
                                patch({
                                    timeslots: removeItem((timeslot: Timeslot) => timeslot.uuid === action.timeslotUuid),
                                }),
                            ),
                        }),
                    );
                }
                if (ctx.getState().publicTimesheet) {
                    ctx.setState(
                        patch<TimesheetStateModel>({
                            publicTimesheet: patch({
                                timeslots: removeItem((timeslot: Timeslot) => timeslot.uuid === action.timeslotUuid),
                            }),
                        }),
                    );
                }
                if (!timesheet) return;
                this.store.dispatch(
                    new CreateNotification({
                        message: `A timeslot for the timesheet of <strong>${timesheet.employee.firstName} ${
                            timesheet.employee.lastName
                        }</strong> for the month <strong>${format(new Date(timesheet.expirationDate.toString()), 'MMMM')}</strong> is removed`,
                        route: ROUTES.TIMESHEETS.route,
                    }),
                );
            }),
        );
    }

    @Action(UpdateExtraInfo)
    updateExtraInfo(ctx: StateContext<TimesheetStateModel>, action: UpdateExtraInfo) {
        return this.timesheetsService.updateExtraInfo(action.extraInfo, action.timesheetUuid).pipe(
            tap((timesheet) => {
                ctx.setState(
                    patch<TimesheetStateModel>({
                        timesheets: updateItem<Timesheet>((t) => t.uuid === timesheet.uuid, timesheet),
                    }),
                );
            }),
        );
    }

    @Action(UpdateHappinessScore)
    updateHappinessScore(ctx: StateContext<TimesheetStateModel>, action: UpdateHappinessScore) {
        return this.timesheetsService.updateHappinessScore(action.timesheetUuid, action.happinessScore).pipe(
            tap((timesheet) => {
                ctx.setState(
                    patch<TimesheetStateModel>({
                        timesheets: updateItem<Timesheet>((t) => t.uuid === timesheet.uuid, this.timesheetsService.parseTimesheetStatus(timesheet)),
                    }),
                );
            }),
        );
    }

    @Action(FetchTimesheetsForEmployee)
    fetchTimesheetsForEmployee(ctx: StateContext<TimesheetStateModel>, action: FetchTimesheetsForEmployee) {
        return this.timesheetsService.fetchTimesheetsForEmployee(action.employeeId).pipe(
            tap((timesheets) => {
                ctx.setState(
                    patch<TimesheetStateModel>({
                        timesheets: this.timesheetsService.parseTimesheetsStatus(timesheets),
                    }),
                );
            }),
        );
    }

    @Action(ChangeTimesheetStatus)
    changeTimesheetStatus(ctx: StateContext<TimesheetStateModel>, action: ChangeTimesheetStatus) {
        return this.timesheetsService.changeTimesheetStatus(action.timesheetId, action.status).pipe(
            tap(() => {
                const timesheet: Timesheet = ctx.getState().timesheets.find((timesheet) => timesheet.uuid === action.timesheetId);
                ctx.setState(
                    patch<TimesheetStateModel>({
                        timesheets: updateItem<Timesheet>(
                            (timesheet) => timesheet.uuid === action.timesheetId,
                            patch<Timesheet>({
                                status: action.status,
                            }),
                        ),
                    }),
                );
                this.store.dispatch(
                    new CreateNotification({
                        message: `The status of the timesheet of <strong>${timesheet.employee.firstName} ${
                            timesheet.employee.lastName
                        }</strong> for the month <strong>${format(new Date(timesheet.expirationDate.toString()), 'MMMM')}</strong> is updated to <strong>${
                            action.status.displayName
                        }</strong>`,
                        route: ROUTES.TIMESHEETS.route,
                    }),
                );
            }),
        );
    }

    @Action(UpdateRequiredHours)
    updateRequiredHours(ctx: StateContext<TimesheetStateModel>, action: UpdateRequiredHours) {
        return this.timesheetsService.updateRequiredHours(action.timesheetId, action.hours).pipe(
            tap(() => {
                ctx.setState(
                    patch<TimesheetStateModel>({
                        timesheets: updateItem<Timesheet>(
                            (timesheet) => timesheet.uuid === action.timesheetId,
                            patch<Timesheet>({
                                hoursRequired: action.hours,
                            }),
                        ),
                    }),
                );
            }),
        );
    }

    @Action(FetchTimesheetsByProjectIdAndYearAndMonth)
    fetchTimesheetsByProjectIdAndYearAndMonth(ctx: StateContext<TimesheetStateModel>, action: FetchTimesheetsByProjectIdAndYearAndMonth) {
        return this.timesheetsService.fetchTimesheetsByProjectIdAndYearAndMonth(action.projectId, action.year, action.month).pipe(
            tap((timesheets) => {
                ctx.setState(
                    patch<TimesheetStateModel>({
                        timesheetsForProjectInMonthAndYear: timesheets,
                    }),
                );
            }),
        );
    }

    @Action(FetchAllProjectsProtected)
    fetchAllProjectsProtected(ctx: StateContext<TimesheetStateModel>, action: FetchAllProjectsProtected) {
        return this.timesheetsService.fetchAllProjectsProtected(action.employeeUuid).pipe(
            tap((projects) => {
                ctx.setState(
                    patch<TimesheetStateModel>({
                        allProjectsProtected: projects,
                    }),
                );
            }),
        );
    }

    @Action(ResetTimesheetsByProjectIdAndYearAndMonth)
    resetTimesheetsByProjectIdAndYearAndMonth(ctx: StateContext<TimesheetStateModel>) {
        ctx.setState(
            patch<TimesheetStateModel>({
                timesheetsForProjectInMonthAndYear: null,
            }),
        );
    }
}
