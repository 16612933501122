import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-velda-articles-subnav',
    templateUrl: './velda-articles-subnav.component.html',
    styleUrls: ['./velda-articles-subnav.component.scss'],
})
export class VeldaArticlesSubnavComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
