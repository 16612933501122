import { GlobalFile } from 'src/app/models/GlobalFile';

import { ChildInvoiceLinkDTO } from './add-child-link-modal/add-child-link-modal.component';
import { DocumentDTO } from './global-files.service';
import { LinkFileDTO } from './link-object-to-file-modal/link-object-to-file-modal.component';

export class FetchGlobalFiles {
    static readonly type = '[GlobalFile[]] Get GlobalFiles';
}

export class GetGlobalFilesObject {
    static readonly type = '[GlobalFileObject] Get FilesObject';

    constructor(public objectUuid: string) {}
}

export class GetAllGlobalFilesObjects {
    static readonly type = '[GlobalFileObject[]] Get all FilesObject';
}

export class LinkFileToObject {
    static readonly type = '[GlobalFileObject] Link file to object';

    constructor(public fileUuid: string, public linkFileDTOs: LinkFileDTO[]) {}
}

export class LinkFileToOverhead {
    static readonly type = '[GlobalFileObject] Link file to overhead';

    constructor(public fileUuid: string, public price: number, public yukiId: string, public description: string) {}
}

export class AddUndefinedObjectFilesToObject {
    static readonly type = '[GlobalFile] Add saved files to object';

    constructor(public objectUuid: string) {}
}

export class GetAllYukiFileObjects {
    static readonly type = '[GlobalFileObject] Get all YukiFileObjects';

    constructor(public fileUuid: string) {}
}

export class GetAllYukiOverheadFileObjects {
    static readonly type = '[FileOverhead] Get all FileOverheads for current file';

    constructor(public fileUuid: string) {}
}

export class AddUndefinedObjectFile {
    static readonly type = '[GlobalFile] Add File for undefined object';

    constructor(public file: GlobalFile, public key: number) {}
}

export class RemoveUndefinedObjectFile {
    static readonly type = '[GlobalFile] Remove File for undefined object';

    constructor(public file: GlobalFile, public key: number) {}
}

export class ResetUndefinedObjectFiles {
    static readonly type = '[GlobalFile] Reset files for undefined objects';
}

export class DeleteLinkedObject {
    static readonly type = '[GlobalFile] Delete linked object';

    constructor(public fileUuid: string, public objectUuid: string) {}
}

export class GetAllObjectsToLink {
    static readonly type = '[LinkFile] Get all objects to link';
}

export class GetAllObjectsLinkedToFile {
    static readonly type = '[] Get all objects linked to file';

    constructor(public fileId: string) {}
}

export class GetAllObjectsLinked {
    static readonly type = '[] Get all objects linked';
}

export class GetAllFilesLinkedToObject {
    static readonly type = '[] Get all files linked to object';

    constructor(public objectId: string) {}
}

export class AddSubLinkToLinkedObjectToFile {
    static readonly type = '[ChildInvoiceLink] Add sub link';

    constructor(public childInvoiceLinkDTOs: ChildInvoiceLinkDTO[]) {}
}

export class DeleteSubLinkToLinkedObjectToFile {
    static readonly type = '[ChildInvoiceLink] Delete sub link';

    constructor(public linkId: string) {}
}

export class CreateAndLinkDocument {
    static readonly type = '[] Create and Link document';

    constructor(public documentDTO: DocumentDTO) {}
}

export class UpdateLinkedObject {
    static readonly type = '[LinkFile] Update Link file';

    constructor(public fileUuid: string, public objectUuid: string, public linkFileDTO: LinkFileDTO) {}
}

export class UpdateChildInvoiceLink {
    static readonly type = '[ChildInvoiceLink] Update child invoice link';

    constructor(public childLinkId: string, public childInvoiceLinkDTO: ChildInvoiceLinkDTO) {}
}

export class MapLinksToBudgetExpenditures {
    static readonly type = '[MapLinksToBudgetExpenditures]';
}
