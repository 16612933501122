import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { append, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { tap } from 'rxjs/operators';

import { Query } from '../models/Query';
import { VeldaBrand } from '../models/VeldaBrand';
import { QueryService } from '../queries/queries.service';
import { AddBrand, AddQuery, DeleteBrand, DeleteQuery, EditBrand, EditQuery, FetchBrands, FetchQueries } from './velda-articles.actions';
import { VeldaArticlesService } from './velda-articles.service';

export interface VeldaArticlesStateModel {
    brands: VeldaBrand[];
    queries: Query[];
}

@State<VeldaArticlesStateModel>({
    name: 'veldaArticles',
    defaults: {
        brands: null,
        queries: null,
    },
})
@Injectable()
export class VeldaArticlesState {
    constructor(private veldaArticlesService: VeldaArticlesService, private queryService: QueryService) {}

    @Selector()
    static brands(state: VeldaArticlesStateModel): VeldaBrand[] {
        return state?.brands;
    }

    @Selector()
    static queries(state: VeldaArticlesStateModel): Query[] {
        return state?.queries;
    }

    @Action(FetchBrands)
    fetchBrands(ctx: StateContext<VeldaArticlesStateModel>) {
        return this.veldaArticlesService.getAllBrands().pipe(
            tap((brands: VeldaBrand[]) => {
                ctx.setState(
                    patch<VeldaArticlesStateModel>({
                        brands: brands,
                    }),
                );
            }),
        );
    }

    @Action(FetchQueries)
    fetchQueries(ctx: StateContext<VeldaArticlesStateModel>) {
        return this.queryService.getAllQueries().pipe(
            tap((queries: Query[]) => {
                ctx.setState(
                    patch<VeldaArticlesStateModel>({
                        queries: queries,
                    }),
                );
            }),
        );
    }

    @Action(AddQuery)
    addQuery(ctx: StateContext<VeldaArticlesStateModel>, action: AddQuery) {
        return this.queryService.addQuery(action.query).pipe(
            tap((query: Query) => {
                ctx.setState(
                    patch<VeldaArticlesStateModel>({
                        queries: append<Query>([query]),
                    }),
                );
            }),
        );
    }

    @Action(EditQuery)
    editQuery(ctx: StateContext<VeldaArticlesStateModel>, action: EditQuery) {
        return this.queryService.editQuery(action.queryUuid, action.query).pipe(
            tap((query: Query) => {
                ctx.setState(
                    patch<VeldaArticlesStateModel>({
                        queries: updateItem((q) => q.uuid === query.uuid, query),
                    }),
                );
            }),
        );
    }

    @Action(AddBrand)
    addBrand(ctx: StateContext<VeldaArticlesStateModel>, action: AddBrand) {
        return this.veldaArticlesService.addBrand(action.brand).pipe(
            tap((brand: VeldaBrand) => {
                ctx.setState(
                    patch<VeldaArticlesStateModel>({
                        brands: append<VeldaBrand>([brand]),
                    }),
                );
            }),
        );
    }

    @Action(EditBrand)
    editBrand(ctx: StateContext<VeldaArticlesStateModel>, action: EditBrand) {
        return this.veldaArticlesService.editBrand(action.brandUuid, action.brand).pipe(
            tap((brand: VeldaBrand) => {
                ctx.setState(
                    patch<VeldaArticlesStateModel>({
                        brands: updateItem<VeldaBrand>((b) => b.uuid === brand.uuid, brand),
                    }),
                );
            }),
        );
    }

    @Action(DeleteBrand)
    deleteBrand(ctx: StateContext<VeldaArticlesStateModel>, action: DeleteBrand) {
        return this.veldaArticlesService.deleteBrand(action.brandUuid).pipe(
            tap((brandUuid: string) => {
                ctx.setState(
                    patch<VeldaArticlesStateModel>({
                        brands: removeItem<VeldaBrand>((b) => b.uuid === brandUuid),
                    }),
                );
            }),
        );
    }

    @Action(DeleteQuery)
    deleteQuery(ctx: StateContext<VeldaArticlesStateModel>, action: DeleteQuery) {
        return this.queryService.deleteQueryByUuid(action.queryUuid).pipe(
            tap((queryUuid: string) => {
                ctx.setState(
                    patch<VeldaArticlesStateModel>({
                        queries: removeItem<Query>((q) => q.uuid === queryUuid),
                    }),
                );
            }),
        );
    }
}
