import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';
import { ActivateAccount } from 'src/app/users/user.actions';

@Component({
    selector: 'app-activation',
    templateUrl: './activation.component.html',
    styleUrls: ['./activation.component.scss'],
})
export class ActivationComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();
    token: string;

    constructor(private store: Store, private activatedRoute: ActivatedRoute, private router: Router, private logger: NGXLogger) {}

    ngOnInit(): void {
        // Get token from url
        const urlParameters = this.activatedRoute.snapshot.queryParams;
        this.token = urlParameters.token;

        // If token is empty redirect to login page
        if (!this.token) {
            this.router.navigate(['/', 'login']);
        }
        // Activate the account
        this.logger.debug('Email verification attempt');
        this.subscriptions.add(
            this.store.dispatch(new ActivateAccount(this.token)).subscribe(() => {
                this.logger.debug('Email verification successful');
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
