import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';
import { Resume } from 'src/app/models/resume/Resume';
import { CountryService } from 'src/app/shared/country.service';

import { FetchResumes, GetEducations, GetExperiences, GetLanguages, UpdateResume } from '../resume.actions';

@Component({
    selector: 'app-resume-personinfo-update-modal',
    templateUrl: './resume-personinfo-update-modal.component.html',
    styleUrls: ['./resume-personinfo-update-modal.component.scss'],
})
export class ResumePersoninfoUpdateModalComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Input() resume: Resume;
    newResume: Resume;
    resumeFormGroup: FormGroup;
    errorMessage: string;
    loading = false;
    updateResumeWentWrong = false;
    DESCRIPTION_MAX_LENGTH = 1000;
    currentAmountOfDescriptionCharacters: number;
    descriptionIsTooLong = false;

    allCountries = [];

    constructor(
        public activeModal: NgbActiveModal,
        private formBuilder: FormBuilder,
        private store: Store,
        private countryService: CountryService,
        private logger: NGXLogger,
    ) {}

    ngOnInit(): void {
        this.allCountries = this.countryService.getAllCountries();

        this.resumeFormGroup = this.formBuilder.group({
            firstName: [''],
            lastName: [''],
            gender: [''],
            description: [''],
            birthday: [null],
            address: this.formBuilder.group({
                street: [''],
                number: [null],
                bus: [''],
                ext: [null],
                postal: [''],
                city: [''],
                country: this.formBuilder.group({
                    iso2: [''],
                }),
            }),
            contactInformation: this.formBuilder.group({
                email: [null, Validators.email],
                phoneNumber: [null],
            }),
            pdfUrls: [[]],
        });
        this.setFormValues();
    }

    setFormValues() {
        this.currentAmountOfDescriptionCharacters = this.resume?.description?.length;
        if (this.resume) {
            this.resumeFormGroup.setValue({
                firstName: this.resume.firstName,
                lastName: this.resume.lastName,
                gender: this.resume.gender,
                birthday: this.resume?.birthday ? this.resume.birthday : null,
                description: this.resume.description,
                address: {
                    street: this.resume?.address?.street ? this.resume.address.street : '',
                    number: this.resume?.address?.number ? this.resume.address.number : null,
                    bus: this.resume?.address?.bus ? this.resume.address.bus : '',
                    ext: this.resume?.address?.ext ? this.resume.address.ext : null,
                    postal: this.resume?.address?.postal ? this.resume.address.postal : '',
                    city: this.resume?.address?.city ? this.resume.address.city : '',
                    country: {
                        iso2: this.resume?.address?.country?.iso2 ? this.resume.address.country.iso2 : '',
                    },
                },
                contactInformation: {
                    email: this.resume?.contactInformation?.email ? this.resume.contactInformation.email : '',
                    phoneNumber: this.resume?.contactInformation?.phoneNumber ? this.resume.contactInformation.phoneNumber : null,
                },
                pdfUrls: this.resume.pdfUrls?.length > 0 ? this.resume.pdfUrls : null,
            });
        }
    }

    onDescriptionKeyUp() {
        this.descriptionIsTooLong = false;
        this.currentAmountOfDescriptionCharacters = this.resumeFormGroup.controls.description.value.length;
        if (this.currentAmountOfDescriptionCharacters > this.DESCRIPTION_MAX_LENGTH) {
            this.descriptionIsTooLong = true;
        }
    }

    updateResume() {
        this.loading = true;

        if (this.descriptionIsTooLong) {
            return;
        }

        this.newResume = this.resumeFormGroup.value;
        this.newResume.uuid = this.resume.uuid;

        if (
            this.newResume.contactInformation.email === '' &&
            (this.newResume.contactInformation.phoneNumber === null || this.newResume.contactInformation.phoneNumber === '')
        ) {
            this.newResume.contactInformation = null;
        }

        this.logger.debug('Attempt to update resume');
        this.subscriptions.add(
            this.store.dispatch(new UpdateResume(this.newResume)).subscribe(
                () => {
                    this.logger.debug('Succesfully updated resume');
                    this.store.dispatch(new FetchResumes()).subscribe(() => {
                        this.store.dispatch(new GetEducations(this.newResume.uuid));
                        this.store.dispatch(new GetLanguages(this.newResume.uuid));
                        this.store.dispatch(new GetExperiences(this.newResume.uuid));
                    });
                    this.activeModal.close();
                },
                (error) => {
                    this.logger.error('Failed to update resume');
                    this.errorMessage = error?.error?.message;
                    this.loading = false;
                    this.updateResumeWentWrong = true;
                },
            ),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
