<div *ngIf="!isLoading">
    <div class="d-flex align-items-center">
        <div class="w-100">
            <app-searchbar [(value)]="filter" (valueChange)="applyFilter($event)"></app-searchbar>
        </div>
        <div class="d-flex nowrap">
            <span ngbDropdown class="dropdown m-l-2">
                <button ngbDropdownToggle class="button button--select">
                    Options
                </button>
                <span ngbDropdownMenu class="dropdown-menu-right">
                    <form>
                        <label ngbDropdownItem *appIsPermitted="{
                                module: 'TIMESHEETS',
                                operator: 'AND',
                                actions: ['EDIT']
                            }" (click)="sendReminders()">Send reminders</label>
                        <label ngbDropdownItem *appIsPermitted="{
                                module: 'TIMESHEETS',
                                operator: 'AND',
                                actions: ['READ']
                            }" (click)="openCronjobSettings()">Reminder settings</label>
                        <label ngbDropdownItem (click)="addNewTimesheet()" *appIsPermitted="{
                                module: 'TIMESHEETS',
                                operator: 'AND',
                                actions: ['CREATE']
                            }">Add a Timesheet</label>
                    </form>
                </span>
            </span>
            <app-global-export-csv [componentName]="this.constructor.name" [exporter]="exporter"
                class="m-l-2"></app-global-export-csv>
        </div>
    </div>

    <div class="m-t-4">
        <div class="table__overflow">
            <table mat-table [dataSource]="dataSource" matSort matTableExporter #exporter="matTableExporter">
                <ng-container matColumnDef="happiness">
                    <th mat-header-cell *matHeaderCellDef class="td-small"></th>
                    <td mat-cell *matCellDef="let timesheet" class="td-icon">
                        <span *ngIf="timesheet.happyScore !== null; else noHappyScore">
                            <span class="happiness-emoji m-r-2" [ngbTooltip]="emojiScoreTip">
                                {{ emojis[timesheet.happyScore].emoji }}
                            </span>
                            <ng-template #emojiScoreTip> score: {{ timesheet.happyScore + 1 }}/{{ this.emojis.length }}
                            </ng-template>
                        </span>
                        <ng-template #noHappyScore>
                            <span class="happiness-emoji happiness-emoji--gray"
                                [ngbTooltip]="emptyEmojiScoreTip">🙂</span>
                            <ng-template #emptyEmojiScoreTip> No score provided... </ng-template>
                        </ng-template>
                    </td>
                </ng-container>
                <ng-container matColumnDef="timesheet">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Timesheet
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <app-pill-timesheet [timesheet]="row"></app-pill-timesheet>
                    </td>
                </ng-container>

                <ng-container matColumnDef="timeslots">
                    <th mat-header-cell *matHeaderCellDef>
                        Timeslots
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <div *ngFor="let timeslot of row.timeslots">
                            <app-pill-project *ngIf="timeslot.project" [project]="timeslot.project"
                                class="project-pill">
                            </app-pill-project>

                            <span class="displayed-time">:
                                {{ timeslot.minutes | minutesToMinutesAndHours }}
                                hours ({{ getTotalDaysFromMinutes(timeslot.minutes) }}
                                days)
                            </span>

                            <span *ngIf="timeslot.jiraDiscrepancyDetected">
                                <i class="las la-exclamation-circle icon-large" style="color: orange" 
                                matTooltip="Jira timediscrepancy detected of {{timeslot.jiraDiscrepancyInMinutes}} minutes !"
                                matTooltipPosition="after"></i>
                            </span>
                            <span *ngIf="timeslot.jiraDiscrepancyDetected" id="jiraDiscrepancyMinutes">{{timeslot.jiraDiscrepancyInMinutes}} minute(s) !</span>
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="totalHours">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Total Hours
                    </th>

                    <td mat-cell *matCellDef="let row">
                        <span [ngbTooltip]="tipContent">
                            <ng-template #tipContent>
                                <span *ngIf="row.hoursRequired > getTotalMinutes(row) / 60"><i
                                        class="las la-long-arrow-alt-down red"></i> {{
                                    getRequiredHoursDiffPercentage(row) }} %, ({{
                                    getTotalMinutes(row) | minutesToMinutesAndHours
                                    }}
                                    /{{ row.hoursRequired * 60 | minutesToMinutesAndHours }})</span>
                                <span *ngIf="row.hoursRequired === getTotalMinutes(row) / 60"><i
                                        class="las la-check green"></i> hours match</span>
                                <span *ngIf="row.hoursRequired < getTotalMinutes(row) / 60"><i
                                        class="las la-long-arrow-alt-up red"></i> {{ getRequiredHoursDiffPercentage(row)
                                    }}%, ({{
                                    getTotalMinutes(row) | minutesToMinutesAndHours
                                    }}
                                    /{{ row.hoursRequired * 60 | minutesToMinutesAndHours }})</span>
                            </ng-template>
                            <i *ngIf="row.hoursRequired > getTotalMinutes(row) / 60"
                                class="las la-long-arrow-alt-down red"></i>
                            <i *ngIf="row.hoursRequired === getTotalMinutes(row) / 60" class="las la-check green"></i>
                            <i *ngIf="row.hoursRequired < getTotalMinutes(row) / 60"
                                class="las la-long-arrow-alt-up red"></i>
                            {{ getTotalMinutes(row) | minutesToMinutesAndHours }}
                        </span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="edit">
                    <th mat-header-cell *matHeaderCellDef class="td-small"></th>
                    <td mat-cell *matCellDef="let row" class="td-icon">
                        <span ngbDropdown class="dropdown" *appIsPermitted="{
                                module: 'TIMESHEETS',
                                operator: 'OR',
                                actions: ['DELETE']
                            }">
                            <button type="button" ngbDropdownToggle
                                class="button button--icon button--small dropdown-toggle" id="dropdownMenuButton"
                                (click)="$event.stopPropagation()"></button>
                            <span ngbDropdownMenu class="dropdown-menu dropdown-menu-right"
                                aria-labelledby="dropdownMenuButton">
                                <a *appIsPermitted="{ module: 'TIMESHEETS', operator: 'AND', actions: ['READ'] }"
                                    class="dropdown-item"
                                    (click)="copyUrlToClipboard(row); $event.stopPropagation()">Copy url to
                                    clipboard</a>
                                <a *appIsPermitted="{ module: 'TIMESHEETS', operator: 'AND', actions: ['READ'] }"
                                    class="dropdown-item" (click)="sendReminder(row); $event.stopPropagation()">Send
                                    reminder</a>
                                <span *appIsPermitted="{
                                        module: 'TIMESHEETS',
                                        operator: 'AND',
                                        actions: ['UPDATE']
                                    }">
                                    <a *ngIf="row.status.key !== 'DONE'" class="dropdown-item"
                                        (click)="changeStatus('DONE', row); $event.stopPropagation()">Approve</a>
                                </span>
                                <span *appIsPermitted="{
                                        module: 'TIMESHEETS',
                                        operator: 'AND',
                                        actions: ['UPDATE']
                                    }">
                                    <a *ngIf="row.status.key !== 'REVIEW'" class="dropdown-item"
                                        (click)="changeStatus('REVIEW', row); $event.stopPropagation()">Mark as
                                        review</a>
                                </span>
                                <span *appIsPermitted="{
                                        module: 'TIMESHEETS',
                                        operator: 'AND',
                                        actions: ['UPDATE']
                                    }">
                                    <a *ngIf="row.status.key !== 'EDIT'" class="dropdown-item"
                                        (click)="changeStatus('EDIT', row); $event.stopPropagation()">Reject</a>
                                </span>

                                <a class="dropdown-item color--red" *appIsPermitted="{
                                        module: 'TIMESHEETS',
                                        operator: 'AND',
                                        actions: ['DELETE']
                                    }" (click)="deleteTimesheet(row); $event.stopPropagation()">Delete</a>
                            </span>
                        </span>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr class="pointer" mat-row *matRowDef="let row; columns: displayedColumns"
                    (click)="openTimesheetDetailModal(row); $event.stopPropagation()"></tr>

                <tr *matNoDataRow>
                    <td colspan="5">No timesheets matching the filter "{{ filter }}"</td>
                </tr>
            </table>
        </div>
    </div>
</div>

<app-loading-message *ngIf="isLoading || sendingReminders"></app-loading-message>