<div class="d-flex align-items-center">
    <div class="d-flex nowrap">
        <span
            ngbDropdown
            class="dropdown m-r-2">
            <button
                ngbDropdownToggle
                class="button button--select">
                Selected ({{ getAmount() }})
            </button>
            <span
                ngbDropdownMenu
                class="dropdown-menu-right">
                <div ngbDropdownItem>Edit company</div>
                <div
                    ngbDropdownItem
                    *appIsPermitted="{
                        module: 'MAILCHIMP',
                        operator: 'AND',
                        actions: ['CREATE']
                    }"
                    (click)="addToMailchimp()">
                    Add to Mailchimp
                </div>
                <div
                    ngbDropdownItem
                    (click)="addTagsInBulk()">
                    Add tags
                </div>
                <div
                    ngbDropdownItem
                    class="color--red"
                    *appIsPermitted="{
                        module: 'CONTACTS',
                        operator: 'AND',
                        actions: ['DELETE']
                    }"
                    (click)="deleteContacts()">
                    Delete
                </div>
            </span>
        </span>
    </div>
    <div class="w-100">
        <app-searchbar
            [(value)]="filter"
            (valueChange)="applyFilter($event)"></app-searchbar>
    </div>
    <div class="w-100">
        <app-tag-searchbar
            [(value)]="tagFilter"
            (valueChange)="applyTagFilter($event)"
            [type]="'contactperson'"></app-tag-searchbar>
    </div>
</div>

<div class="m-t-4">
    <div
        class="table__overflow"
        *ngIf="contacts$ | async; else loading">
        <table
            mat-table
            [dataSource]="dataSource"
            matSort
            matSortActive="firstName"
            matSortDirection="asc">
            <ng-container matColumnDef="checkBox">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    class="td-small">
                    <input
                        type="checkbox"
                        (change)="checkAll()"
                        [checked]="allCheck" />
                </th>
                <td
                    mat-cell
                    *matCellDef="let row">
                    <input
                        type="checkbox"
                        (change)="checkChange(row)"
                        [checked]="getCurrentCheck(row)" />
                </td>
            </ng-container>

            <ng-container matColumnDef="firstName">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header>
                    Contactperson
                </th>
                <td
                    mat-cell
                    *matCellDef="let row">
                    <app-pill-contact [contactPerson]="row"></app-pill-contact>
                </td>
            </ng-container>

            <ng-container matColumnDef="contactInformation.phoneNumber">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header>
                    Phone
                </th>
                <td
                    mat-cell
                    *matCellDef="let row">
                    <app-pill-telephone
                        *ngIf="row.contactInformation.phoneNumber"
                        [contactPhonenumber]="row.contactInformation.phoneNumber"></app-pill-telephone>
                </td>
            </ng-container>

            <ng-container matColumnDef="contactInformation.email">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header>
                    Email
                </th>
                <td
                    mat-cell
                    *matCellDef="let row">
                    <app-pill-mailto [mailtoEmail]="row.contactInformation.email"></app-pill-mailto>
                </td>
            </ng-container>

            <ng-container matColumnDef="partnerName">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header>
                    Company
                </th>
                <td
                    mat-cell
                    *matCellDef="let row">
                    <app-pill-partner
                        *ngIf="row.partnerUuid"
                        [partnerUuid]="row.partnerUuid"></app-pill-partner>
                </td>
            </ng-container>

            <ng-container matColumnDef="creatorId">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header>
                    Creator
                </th>
                <td
                    mat-cell
                    *matCellDef="let row">
                    <app-pill-user [userId]="row.creatorId"></app-pill-user>
                </td>
            </ng-container>

            <ng-container matColumnDef="profession">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header>
                    Profession
                </th>
                <td
                    mat-cell
                    *matCellDef="let row">
                    {{ row.profession }}
                </td>
            </ng-container>

            <ng-container matColumnDef="edit">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    class="td-small"></th>
                <td
                    mat-cell
                    *matCellDef="let row">
                    <span
                        ngbDropdown
                        class="dropdown"
                        *appIsPermitted="{
                            module: 'CONTACTS',
                            operator: 'OR',
                            actions: ['EDIT', 'DELETE']
                        }">
                        <button
                            type="button"
                            ngbDropdownToggle
                            class="button button--icon button--small dropdown-toggle"
                            id="dropdownMenuButton"></button>
                        <span
                            ngbDropdownMenu
                            class="dropdown-menu dropdown-menu-right"
                            aria-labelledby="dropdownMenuButton">
                            <a
                                class="dropdown-item"
                                *appIsPermitted="{
                                    module: 'CONTACTS',
                                    operator: 'AND',
                                    actions: ['EDIT']
                                }"
                                (click)="openUpdateContactPersonModal(row)"
                                >Edit</a
                            >
                            <div
                                class="dropdown-divider"
                                *appIsPermitted="{
                                    module: 'CONTACTS',
                                    operator: 'AND',
                                    actions: ['EDIT', 'DELETE']
                                }"></div>
                            <a
                                class="dropdown-item color--red"
                                *appIsPermitted="{
                                    module: 'CONTACTS',
                                    operator: 'AND',
                                    actions: ['DELETE']
                                }"
                                (click)="openContactPersonDeleteModal(row)"
                                >Delete</a
                            >
                        </span>
                    </span>
                </td>
            </ng-container>

            <tr
                mat-header-row
                *matHeaderRowDef="displayedColumns"></tr>
            <tr
                mat-row
                *matRowDef="let row; columns: displayedColumns"></tr>

            <tr *matNoDataRow>
                <td colspan="8">No contacts matching the filters</td>
            </tr>
        </table>
    </div>
</div>

<ng-template #loading>
    <app-loading-message></app-loading-message>
</ng-template>
