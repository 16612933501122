<div
    class="modal-wrapper"
    *ngIf="customer">
    <div class="modal-header">
        <h2
            class="modal-title"
            id="exampleModalLabel">
            {{ customer.firstName }} {{ customer.lastName }}
        </h2>
        <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="activeModal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <nav
        ngbNav
        #nav="ngbNav"
        class="tabs__modal">
        <ng-container ngbNavItem>
            <a ngbNavLink>Information</a>
            <ng-template ngbNavContent>
                <ul class="list__specs">
                    <ul class="list__specs">
                        <li *ngIf="customer.address"><label>Address</label><app-pill-address [address]="customer.address"></app-pill-address></li>
                        <li *ngIf="customer.contactInformation.email">
                            <label>Emailaddress</label><app-pill-mailto [mailtoEmail]="customer.contactInformation.email"></app-pill-mailto>
                        </li>
                        <li *ngIf="this.moduleService.checkModuleActive(eModule.MAILCHIMP)">
                            <label>Mailchimp</label
                            ><app-pill-mailchimp
                                [email]="customer.contactInformation.email"
                                [firstname]="customer.firstName"
                                [lastname]="customer.lastName"
                                [phone]="customer.contactInformation.phoneNumber"></app-pill-mailchimp>
                        </li>
                        <li *ngIf="customer.contactInformation.phoneNumber">
                            <label>Phonenumber</label><app-pill-telephone [contactPhonenumber]="customer.contactInformation.phoneNumber"></app-pill-telephone>
                        </li>
                        <li *ngIf="customer.contactInformation.phoneNumber">
                            <label>Whatsapp</label><app-pill-whatsapp [phoneNumber]="customer.contactInformation.phoneNumber"></app-pill-whatsapp>
                        </li>
                        <li *ngIf="customer.contactInformation.linkedIn">
                            <label>LinkedIn</label><app-pill-linkedin [linkedinUrl]="customer.contactInformation.linkedIn"></app-pill-linkedin>
                        </li>
                        <li *ngIf="customer.contactPersonUuid && contact">
                            <label>Contact</label>
                            <app-pill-contact [contactPerson]="contact"></app-pill-contact>
                        </li>
                        <li *ngIf="customer.crmDeals && crmDeals.length > 0">
                            <label>Deals</label>
                            <span *ngFor="let deal of crmDeals"><app-pill-crmdeal [deal]="deal"></app-pill-crmdeal> &nbsp;&nbsp;</span>
                        </li>
                        <li *ngIf="customer.projects && customer.projects.length !== 0">
                            <label>Projects</label>
                            <span *ngFor="let project of customer.projects"><app-pill-project [project]="project"></app-pill-project> &nbsp;&nbsp;</span>
                        </li>
                        <li>
                            <label>Tags</label
                            ><app-global-tags
                                [objectUuid]="customer.uuid"
                                [type]="'customer'"></app-global-tags>
                        </li>
                    </ul>
                </ul>
            </ng-template>
        </ng-container>
        <ng-container
            ngbNavItem
            *ngIf="this.moduleService.checkModuleActive(eModule.CRM)">
            <a ngbNavLink>Deals</a>
            <ng-template ngbNavContent>
                <app-global-link-autocomplete
                    [itemType]="'customer'"
                    [item]="customer"
                    [itemLinkType]="'deal'">
                </app-global-link-autocomplete>
            </ng-template>
        </ng-container>

        <ng-container
            ngbNavItem
            *ngIf="this.moduleService.checkModuleActive(eModule.PROJECTS)">
            <a ngbNavLink>Projects</a>
            <ng-template ngbNavContent>
                <app-global-link-autocomplete
                    [itemType]="'customer'"
                    [item]="customer"
                    [itemLinkType]="'work'">
                </app-global-link-autocomplete>
            </ng-template>
        </ng-container>
        <ng-container
            ngbNavItem
            *ngIf="this.moduleService.checkModuleActive(eModule.COMMENTS)">
            <a ngbNavLink>Comments</a>
            <ng-template ngbNavContent>
                <app-comments-overview [objectId]="customer.uuid"></app-comments-overview>
            </ng-template>
        </ng-container>
    </nav>

    <div
        class="modal-body"
        *ngIf="isLoading">
        <app-loading-message></app-loading-message>
    </div>
    <div
        class="modal-body"
        *ngIf="!isLoading">
        <div [ngbNavOutlet]="nav"></div>
    </div>

    <div class="modal-footer">
        <button
            type="button"
            class="button button--outline"
            (click)="activeModal.close()">
            Close
        </button>
        <div
            ngbDropdown
            placement="top-right"
            class="dropdown"
            *appIsPermitted="{
                module: 'CUSTOMERS',
                operator: 'OR',
                actions: ['EDIT', 'DELETE']
            }">
            <button
                type="button"
                ngbDropdownToggle
                class="button dropdown-toggle"
                id="dropdownMenuButton">
                Actions
            </button>
            <div
                ngbDropdownMenu
                class="dropdown-menu"
                aria-labelledby="dropdownMenuButton">
                <a
                    class="dropdown-item"
                    *appIsPermitted="{
                        module: 'CUSTOMERS',
                        operator: 'AND',
                        actions: ['EDIT']
                    }"
                    (click)="openUpdateCustomerModal()"
                    >Edit customer</a
                >
                <a
                    class="dropdown-item color--red"
                    *appIsPermitted="{
                        module: 'CUSTOMERS',
                        operator: 'AND',
                        actions: ['DELETE']
                    }"
                    (click)="deleteCustomer()"
                    >Delete</a
                >
            </div>
        </div>
    </div>
</div>
