import { Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';
import FileSystemEntity from 'src/app/models/FileSystemEntity';

import { DocumentsListService } from '../documents-list.service';

@Component({
    selector: 'app-link-document-modal',
    templateUrl: './link-document-modal.component.html',
    styleUrls: ['./link-document-modal.component.scss'],
})
export class LinkDocumentModalComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    public submitEvent: EventEmitter<string> = new EventEmitter();
    allDocuments: FileSystemEntity[];

    isLoading = true;

    filter = '';

    constructor(private documentsListService: DocumentsListService, public modalService: NgbActiveModal, private logger: NGXLogger) {}

    ngOnInit(): void {
        this.logger.debug('Fetching allFiles');
        this.subscriptions.add(
            this.documentsListService.getAllFiles().subscribe((docs) => {
                this.allDocuments = docs;
                this.isLoading = false;
            }),
        );
    }

    submit(id) {
        this.submitEvent.emit(id);
        this.modalService.close();
    }

    filteredArray(): FileSystemEntity[] {
        return this.allDocuments.filter((d) => d.name.toLowerCase().includes(this.filter.toLowerCase()));
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
