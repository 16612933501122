<app-date-navigation
    (selectedDateUpdated)="updateSelectedDate()"
    [dateNavigationOptions]="dateNavigationOptions"></app-date-navigation>

<ng-container *ngIf="incomeForEmployee?.detailId === employee.uuid && costsForEmployee?.detailId === employee.uuid; else loading">
    <app-default-line-chart
        (clickEvent)="displayInfo($event)"
        [data]="data"
        [isFinancial]="true"
        [legendPosition]="legendPosition"
        [objectLabels]="labels"
        [options]="options"
        [xLabels]="xLabels">
    </app-default-line-chart>

    <div class="row">
        <div class="col-6">
            <p class="m-t-4">
                <i class="las la-chart-bar m-r-2"></i>
                Total margin in {{ dateNavigationOptions.selectedDate | date: 'y' }}:
                <strong>{{ calculateTotalMarginForSelectedYearInPct() | percent: '1.2-2' }}</strong>
            </p>
            <p class="m-t-4">
                <i class="las la-chart-bar m-r-2"></i>
                Total margin in {{ dateNavigationOptions.selectedDate | date: 'y' }}:
                <strong>{{ calculateTotalMarginForSelectedYear()  | currency: 'EUR' }}</strong>
            </p>
        </div>
        <div class="col-6">
            <p class="m-t-4">
                <i class="las la-chart-bar m-r-2"></i>
                Total income in {{ dateNavigationOptions.selectedDate | date: 'y' }}:
                <strong>{{ calculateTotalIncomeForSelectedYear() | currency: 'EUR' }}</strong>
            </p>
            <p class="m-t-4">
                <i class="las la-chart-bar m-r-2"></i>
                Total cost in {{ dateNavigationOptions.selectedDate | date: 'y' }}:
                <strong>{{ calculateTotalCostForSelectedYear() | currency: 'EUR' }}</strong>
            </p>
        </div>
    </div>

</ng-container>
<ng-template #loading>
    <app-loading-message></app-loading-message>
</ng-template>
