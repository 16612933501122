<span>
    <ng-template #popContent>
        <ul class="list__specs">
            <li *ngIf="offer.offer_number"><label>Offer number</label> {{ offer.offer_number }}</li>
            <li *ngIf="offer.currency"><label>Currency</label> {{ offer.currency }}</li>
            <li *ngIf="offer.date"><label>Date</label> {{ offer.date }}</li>
            <li *ngIf="offer.due_date"><label>Due date</label> {{ offer.due_date }}</li>
            <li *ngIf="offer.status">
                <label>Status</label>
                <span class="tag">{{ offer.status }}</span>
            </li>
            <li *ngIf="offer.contact"><label>Contact</label> {{ offer.contact.email }}</li>
        </ul>
    </ng-template>
    <a
        class="pill pill--comanageOffer"
        *ngIf="offer"
        [ngbPopover]="popContent"
        popoverTitle="{{ offer.title }}">
        <img src="../../../assets/img/logo_comanage_small.svg" />
        {{ offer.offer_number }}, {{ offer.title }}
    </a>
</span>
