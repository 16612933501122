import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-resume-settings',
    templateUrl: './resume-settings.component.html',
    styleUrls: ['./resume-settings.component.scss'],
})
export class ResumeSettingsComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
