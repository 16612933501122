import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { append, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { Unit } from '../models/Unit';
import { AddUnit, DeleteUnit, FetchUnits, UpdateUnit } from './unit.actions';
import { UnitService } from './unit.service';

export interface UnitStateModel {
    units: Unit[];
}

@State<UnitStateModel>({
    name: 'unit',
    defaults: {
        units: null,
    },
})
@Injectable()
export class UnitState {
    @Selector()
    static Units(state: UnitStateModel): Unit[] {
        return state?.units;
    }

    constructor(private unitService: UnitService) {}

    @Action(FetchUnits)
    fetchunits(ctx: StateContext<UnitStateModel>): Observable<Unit[]> {
        return this.unitService.getAllUnits().pipe(
            tap((units) => {
                ctx.setState(
                    patch<UnitStateModel>({
                        units: units,
                    }),
                );
            }),
        );
    }

    @Action(AddUnit)
    addUnit(ctx: StateContext<UnitStateModel>, action: AddUnit): Observable<Unit> {
        return this.unitService.addUnit(action.unit).pipe(
            tap((unit) => {
                ctx.setState(
                    patch<UnitStateModel>({
                        units: append<Unit>([unit]),
                    }),
                );
            }),
        );
    }

    @Action(UpdateUnit)
    updateUnit(ctx: StateContext<UnitStateModel>, action: UpdateUnit): Observable<Unit> {
        return this.unitService.updateUnit(action.unit).pipe(
            tap((unit) => {
                ctx.setState(
                    patch<UnitStateModel>({
                        units: updateItem<Unit>((u) => u.uuid === action.unit.uuid, unit),
                    }),
                );
            }),
        );
    }

    @Action(DeleteUnit)
    deleteUnit(ctx: StateContext<UnitStateModel>, action: DeleteUnit) {
        return this.unitService.deleteUnit(action.unitUuid).pipe(
            tap(() => {
                ctx.setState(
                    patch<UnitStateModel>({
                        units: removeItem<Unit>((u) => u.uuid === action.unitUuid),
                    }),
                );
            }),
        );
    }
}
