import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';
import { ResetPassword } from 'src/app/users/user.actions';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    // Declare properties
    resetPasswordFormGroup: FormGroup;
    loading = false;
    hasSubmitted = false;
    inputIsWrong = false;
    token: string;
    newPassword: string;
    passwordRepeat: string;
    passwordsDontMatch = false;
    errorMessage: string;
    resetPasswordWentWrong = false;
    email: string;

    constructor(
        private formBuilder: FormBuilder,
        private store: Store,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private logger: NGXLogger,
    ) {}

    ngOnInit(): void {
        // Get token from url
        const urlParameters = this.activatedRoute.snapshot.queryParams;
        this.token = urlParameters.token;
        this.email = urlParameters.email;

        // If token is empty redirect to login
        if (!this.token) {
            this.router.navigate(['/', 'login']);
        }

        // Initialize and declare form data and validators
        this.resetPasswordFormGroup = this.formBuilder.group({
            newPassword: ['', [Validators.minLength(8), Validators.required]],
            newPasswordRepeat: ['', [Validators.minLength(8), Validators.required]],
        });
    }

    // Change password of the user
    resetPassword() {
        this.logger.debug('Password reset attempt');

        this.loading = true;

        // Check if form data fulfills the validators
        if (!this.resetPasswordFormGroup.valid) {
            this.inputIsWrong = true;
            this.loading = false;
            return;
        }

        // Check if the values of the two password fields are equal
        if (!(this.resetPasswordFormGroup.controls.newPassword.value === this.resetPasswordFormGroup.controls.newPasswordRepeat.value)) {
            this.passwordsDontMatch = true;
            this.loading = false;
            return;
        }

        // Disable form fields
        this.resetPasswordFormGroup.disable();

        // Send the new password to the api
        this.subscriptions.add(
            this.store.dispatch(new ResetPassword(this.token, this.newPassword, this.passwordRepeat)).subscribe(
                () => {
                    this.logger.debug('Password reset successfully');
                    this.hasSubmitted = true;
                },
                (error) => {
                    this.logger.error('Password reset failed');
                    this.errorMessage = error?.error?.message ?? 'Error message was empty';
                    this.resetPasswordWentWrong = true;
                    this.resetPasswordFormGroup.enable();
                    this.hasSubmitted = false;
                    this.loading = false;
                },
            ),
        );
    }

    onNewPasswordChange() {
        this.newPassword = this.resetPasswordFormGroup.controls.newPassword.value;
    }

    onPasswordRepeatChange() {
        this.passwordRepeat = this.resetPasswordFormGroup.controls.newPasswordRepeat.value;
    }

    // Hides the error messages
    hideErrorMessage() {
        this.inputIsWrong = false;
        this.passwordsDontMatch = false;
        this.resetPasswordWentWrong = true;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
