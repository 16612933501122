<a
    *ngIf="!(loggedIn$ | async)"
    [href]="loginUrl"
    class="message--module-inactive message--module-inactive--exact"
    (click)="activeModal.dismiss()">
    <span
        *ngIf="!isLoading"
        class="col">
        <strong class="row">You are not logged in with Exact.</strong>
        <small class="row">Click to connect your account.</small>
    </span>
    <app-loading-message *ngIf="isLoading"></app-loading-message>
</a>
