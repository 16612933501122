import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import RecipientModel from 'src/app/models/RecipientModel';
import { environment } from 'src/environments/environment';

import { AuthenticationService } from '../../../authentication/authentication.service';

@Injectable({
    providedIn: 'root',
})
export class PillMailchimpService {
    httpOptions = {};

    constructor(private httpClient: HttpClient, private authenticationService: AuthenticationService) {
        this.httpOptions = authenticationService.getHeaders();
    }

    getMailchimpIsEnabled() {
        return this.httpClient.get<boolean>(environment.baseUrl + '/mailchimp/isEnabled', this.httpOptions);
    }

    checkIfApiKeyExists() {
        return this.httpClient.get<any>(environment.baseUrl + '/mailchimp/keyExists', this.httpOptions);
    }

    getAccountIsLinked(email: string) {
        return this.httpClient.get<RecipientModel>(environment.baseUrl + `/mailchimp/${email}/isLinked`, this.httpOptions);
    }
}
