<div class="modal-header">
    <span></span>
    <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>



<div class="modal-body">
    <div *ngIf="pdf; else unavailable">
        <iframe
        [cachedSrc]="pdf"
        class="w-100 h-100"></iframe>
    </div>
</div>

<div class="modal-footer">
    <button
        type="button"
        class="button button--outline"
        (click)="activeModal.dismiss()">
        Close
    </button>

    <span
        ngbDropdown
        class="dropdown">
        <button
            ngbDropdownToggle
            class="button dropdown-toggle"
            id="dropdownMenuButton">
            Options
        </button>
        <div
            ngbDropdownMenu
            class="dropdown-menu dropdown-menu-right"
            aria-labelledby="dropdownMenuButton">
            <span>
                <a
                    class="dropdown-item"
                    (click)="openInNewWindow()"
                    >Open in new window</a
                >
            </span>
        </div>
    </span>
</div>

<ng-template #unavailable>
    <div class="body">
        <b>Preview not available.</b>
        <p>You can open this in a new window.</p>
        <a
            class="button button--primary"
            *appIsPermitted="{
                module: 'PARTNERS',
                operator: 'AND',
                actions: ['CREATE']
            }"
            (click)="openInNewWindow()"
            >Open in new window</a
        >
    </div>
</ng-template>
