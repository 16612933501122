<div class="d-flex flex-column flex-md-row space-between w-100 m-b-6">
    <a
        class="button button--social-login m-b-2 w-100"
        (click)="loginWithGoogle()">
        <img src="../../../assets/img/logo-google.png" />
        with Google
    </a>
    <!--<span class="button button--social-login">
    <img src="https://image.flaticon.com/icons/svg/733/733547.svg"/>
    with Facebook
</span>-->
</div>
