<table class="table-view">
    <tr>
        <td class="td-icon">
            <ng-container *ngIf="tags.length < 1">No technical skills added yet...</ng-container>
            <ng-container *ngFor="let skill of tags">
                <app-pill-global-tag
                    *ngIf="skill"
                    [tag]="skill"
                    class="resume-v2__experience-skill">
                </app-pill-global-tag>
            </ng-container>
        </td>
    </tr>
    <tr>
        <td (click)="openEditSkillsModal()" class="table-view__add">
            <i class="las la-pen"></i>
            Edit skills
        </td>
    </tr>
</table>
