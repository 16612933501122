<div class="message__empty">
    <div
        *ngIf="icon"
        class="message__empty__icon">
        <img [src]="icon" />
    </div>
    <header class="message__empty__title">
        {{ title }}
    </header>
    <p *ngIf="subtitle">{{ subtitle }}</p>
    <p
        *ngIf="htmlSubtitle"
        [innerHTML]="htmlSubtitle"></p>
    <ng-content> </ng-content>
</div>
