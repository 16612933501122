import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { Query } from 'src/app/models/Query';
import { DeleteQuery } from 'src/app/velda-articles/velda-articles.actions';

@Component({
    selector: 'app-query-delete-modal',
    templateUrl: './query-delete-modal.component.html',
    styleUrls: ['./query-delete-modal.component.scss'],
})
export class QueryDeleteModalComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Input() query: Query;

    constructor(private store: Store, public activeModal: NgbActiveModal, private toastrService: ToastrService, private logger: NGXLogger) {}

    ngOnInit(): void {}

    deleteQuery() {
        this.logger.debug('Attempt to delete query');

        this.subscriptions.add(
            this.store.dispatch(new DeleteQuery(this.query.uuid)).subscribe(
                () => {
                    this.toastrService.success('Query deleted successfully!');
                    this.logger.debug('Query deleted successfully!');
                    this.activeModal.close();
                },
                (error) => {
                    this.toastrService.error('Failed to delete query!');
                    this.logger.error('Failed to delete query!');
                },
            ),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
