/**
 * Should be used when creating a new laneType
 */
export default interface LaneModel {
    uuid: string;
    name: string;
    probability: number;
    rottingIn: number;
    sequence: number;
    type: LaneType;
    description: string;
    color: string;
}

export enum LaneType {
    WIN = 'WIN',
    LOSS = 'LOSS',
    NORMAL = 'NORMAL',
    OTHER = 'OTHER',
    ARCHIVED = 'ARCHIVED',
}

export enum LaneItemStatus {
    ONGOING = 'ONGOING',
    WON = 'WON',
    LOST = 'LOST',
    OTHER = 'OTHER',
    ARCHIVED = 'ARCHIVED',
}
