import { moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input, OnDestroy, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { ApplicantLane } from 'src/app/models/Applicants/ApplicantLane';
import { AddLaneModalComponent } from 'src/app/shared/add-lane-modal/add-lane-modal.component';
import { ConfirmationModalComponent } from 'src/app/shared/confirmation-modal/confirmation-modal.component';

import { AddApplicantLane, MoveApplicantLaneSequence, RemoveApplicantLane, UpdateApplicantLane } from '../applicant.actions';

@Component({
    selector: 'app-manage-applicant-lanes-modal',
    templateUrl: './manage-applicant-lanes-modal.component.html',
    styleUrls: ['./manage-applicant-lanes-modal.component.scss'],
})
export class ManageApplicantLanesModalComponent implements OnDestroy {
    private subscriptions = new Subscription();

    @Input() lanes: ApplicantLane[];

    displayedColumns: string[] = ['lane name', 'probability', 'rotting days', 'lane type', 'edit'];
    dataSource: MatTableDataSource<ApplicantLane> = new MatTableDataSource<ApplicantLane>();
    @ViewChild(MatSort) sort: MatSort;

    constructor(
        public activeModal: NgbActiveModal,
        private store: Store,
        private toastr: ToastrService,
        private modalService: NgbModal,
        private logger: NGXLogger,
    ) {}

    drop(event) {
        this.logger.debug('Attempt to move lane');
        this.subscriptions.add(this.store.dispatch(new MoveApplicantLaneSequence(this.lanes[event.previousIndex].uuid, event.currentIndex)).subscribe());
        moveItemInArray(this.lanes, event.previousIndex, event.currentIndex);
    }

    delete(uuid: string) {
        this.logger.debug('Opening confirmationModal');
        const modalRef = this.modalService.open(ConfirmationModalComponent, {
            windowClass: 'modal-prompt',
        });
        modalRef.componentInstance.type = 'Delete';
        modalRef.componentInstance.title = 'Delete lane';
        modalRef.componentInstance.message =
            'Are you sure you want to delete ' + this.lanes.find((s) => s.uuid === uuid).name + '?\n Also make sure the lane does not contain any applicants.';
        this.subscriptions.add(
            modalRef.componentInstance.closeEvent.subscribe((val) => {
                if (val) {
                    this.logger.debug('Attempt to delete lane');
                    this.store.dispatch(new RemoveApplicantLane(uuid)).subscribe({
                        next: () => {
                            this.logger.debug('Succesfully removed lane');
                            this.toastr.success('Lane successfully removed.');
                        },
                        error: () => {
                            this.logger.debug('Failed to remove lane');
                            this.toastr.error('Removing stage failed. Please check if the lane still contains applicants.');
                        },
                    });
                }
            }),
        );
    }

    openAddLaneModal(lane?: ApplicantLane) {
        if (lane) {
            const modalRef = this.modalService.open(AddLaneModalComponent, {
                windowClass: 'modal-prompt',
            });
            modalRef.componentInstance.lane = lane;
            this.subscriptions.add(
                modalRef.componentInstance.emitObject.subscribe((lane: ApplicantLane) => {
                    this.store.dispatch(new UpdateApplicantLane(lane.uuid, lane)).subscribe();
                }),
            );
        } else {
            const modalRef = this.modalService.open(AddLaneModalComponent, {
                windowClass: 'modal-prompt',
            });
            this.subscriptions.add(
                modalRef.componentInstance.emitObject.subscribe((lane: ApplicantLane) => {
                    this.store.dispatch(new AddApplicantLane(lane)).subscribe();
                }),
            );
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
