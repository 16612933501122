<div
    class="sidebar-spacing"
    *ngIf="user$ | async as user">
    <h3>{{ user.firstName }} {{ user.lastName }}</h3>
    <small>{{ user.email }}</small>
</div>

<span class="divider divider--small"></span>

<nav class="app__subnav">
    <a
        [routerLink]="['/', 'settings', 'account']"
        routerLinkActive="app__subnav__active"
        >Account settings</a
    >
    <a
        [routerLink]="['/', 'settings', 'company']"
        routerLinkActive="app__subnav__active"
        >Company settings</a
    >
    <a
        [routerLink]="['/', 'settings', 'members']"
        routerLinkActive="app__subnav__active"
        >Company members</a
    >
    <a
        [routerLink]="['/', 'settings', 'roles']"
        routerLinkActive="app__subnav__active"
        >Roles & permissions</a
    >
    <a
        [routerLink]="['/', 'settings', 'module']"
        routerLinkActive="app__subnav__active"
        >Company modules</a
    >
    <a
        [routerLink]="['/', 'settings', 'mailing']"
        routerLinkActive="app__subnav__active"
        >Mailing setup</a
    >
    <a
        [routerLink]="['/', 'settings', 'module-setup']"
        routerLinkActive="app__subnav__active"
        *ngIf="user?.role == eRole.ROLE_SUPER_ADMIN"
        >Module setup</a
    >
    <div class="app__subnav__divider"></div>
    <a
        [routerLink]="['/', 'settings', 'general']"
        routerLinkActive="app__subnav__active"
        >General settings</a
    >
    <a
        [routerLink]="['/', 'settings', 'tags']"
        routerLinkActive="app__subnav__active"
        >Company tags</a
    >
    <a
        [routerLink]="['/', 'settings', 'resume']"
        routerLinkActive="app__subnav__active"
        *ngIf="this.moduleService.checkModuleActive(eModule.RESUME)"
        >Resume settings</a
    >
    <a
        [routerLink]="['/', 'settings', 'project']"
        routerLinkActive="app__subnav__active"
        *ngIf="this.moduleService.checkModuleActive(eModule.PROJECTS)"
        >Project settings</a
    >
    <a
        [routerLink]="['/', 'settings', 'api']"
        routerLinkActive="app__subnav__active"
        *ngIf="this.moduleService.checkModuleActive(eModule.API_SETTINGS)"
        >API settings</a
    >
    <div class="app__subnav__divider"></div>
    <a
        [routerLink]="['/', 'settings', 'app-info']"
        routerLinkActive="app__subnav__active"
        >Application info</a
    >
</nav>
