import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { CompanyState } from 'src/app/companies/company.state';
import { Warehouse } from 'src/app/models/Warehouse';

import { WarehouseAddModalComponent } from '../warehouse-add-modal/warehouse-add-modal.component';
import { WarehouseDetailModalComponent } from '../warehouse-detail-modal/warehouse-detail-modal.component';
import { FetchWarehouses } from '../warehouse.actions';
import { WarehouseState } from '../warehouse.state';

@Component({
    selector: 'app-warehouses-overview',
    templateUrl: './warehouses-overview.component.html',
    styleUrls: ['./warehouses-overview.component.scss'],
})
export class WarehousesOverviewComponent implements OnInit {
    @Select(WarehouseState.Warehouses) allWarehouses$: Observable<Warehouse[]>;
    @Select(CompanyState.companyUuid) companyUuid$: Observable<string>;

    constructor(private store: Store, private modalService: NgbModal, private titleService: Title, private logger: NGXLogger) {
        this.titleService.setTitle('Warehouses overview');
    }

    ngOnInit(): void {
        this.logger.debug('Fetching warehouses');
        this.store.dispatch(new FetchWarehouses());
    }

    openAddWarehouseModal() {
        this.logger.debug('Opening warehouseAddModal');
        this.modalService.open(WarehouseAddModalComponent, {
            windowClass: 'modal-pane',
            animation: false,
        });
    }

    openWarehouseDetailModal(warehouse: Warehouse) {
        this.logger.debug('Opening warehouseDetailModal');
        const modalRef = this.modalService.open(WarehouseDetailModalComponent, {
            windowClass: 'modal-pane',
            animation: false,
        });
        modalRef.componentInstance.warehouseUuid = warehouse.uuid;
    }
}
