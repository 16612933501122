import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { CompanyState } from 'src/app/companies/company.state';
import { WarehouseLocation } from 'src/app/models/WarehouseLocation';

import { WarehouseLocationsAddModalComponent } from '../warehouse-locations-add-modal/warehouse-locations-add-modal.component';
import { FetchWarehouseLocations } from '../warehouse.location.actions';
import { WarehouseLocationState } from '../warehouse.location.state';

@Component({
    selector: 'app-warehouse-locations-overview',
    templateUrl: './warehouse-locations-overview.component.html',
    styleUrls: ['./warehouse-locations-overview.component.scss'],
})
export class WarehouseLocationsOverviewComponent implements OnInit {
    @Select(WarehouseLocationState.WarehouseLocations)
    allWarehouseLocations$: Observable<WarehouseLocation[]>;
    @Select(CompanyState.companyUuid) companyUuid$: Observable<string>;

    constructor(private store: Store, private modalService: NgbModal, private titleService: Title, private logger: NGXLogger) {
        this.titleService.setTitle('Warehouse locations overview');
    }

    ngOnInit(): void {
        this.logger.debug('Fetching warehouseLocations');
        this.store.dispatch(new FetchWarehouseLocations());
    }

    openAddWarehouseLocationModal() {
        this.logger.debug('Opening addWarehouseLocationModal');
        this.modalService.open(WarehouseLocationsAddModalComponent, {
            windowClass: 'modal-pane',
            animation: false,
        });
    }

    changeSelectedCategory($event) {}
}
