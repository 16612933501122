import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { append, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { tap } from 'rxjs/operators';

import { VeldaCountry } from '../models/VeldaCountry';
import { VeldaLanguage } from '../models/VeldaLanuage';
import { AddCountry, AddLanguage, DeleteCountry, DeleteLanguage, EditCountry, EditLanguage, FetchCountries, FetchLanguages } from './velda-languages.actions';
import { VeldaLanguagesService } from './velda-languages.service';

export interface VeldaLanguagesStateModel {
    countries: VeldaCountry[];
    languages: VeldaLanguage[];
}

@State<VeldaLanguagesStateModel>({
    name: 'veldaLanguages',
    defaults: {
        countries: null,
        languages: null,
    },
})
@Injectable()
export class VeldaLanguagesState {
    constructor(private veldaLanguagesService: VeldaLanguagesService) {}

    @Selector()
    static countries(state: VeldaLanguagesStateModel): VeldaCountry[] {
        return state?.countries;
    }

    @Selector()
    static languages(state: VeldaLanguagesStateModel): VeldaLanguage[] {
        return state?.languages;
    }

    @Action(FetchCountries)
    fetchCountries(ctx: StateContext<VeldaLanguagesStateModel>) {
        return this.veldaLanguagesService.getAllCountries().pipe(
            tap((countries: VeldaCountry[]) => {
                ctx.setState(
                    patch<VeldaLanguagesStateModel>({
                        countries: countries,
                    }),
                );
            }),
        );
    }

    @Action(AddCountry)
    addCountry(ctx: StateContext<VeldaLanguagesStateModel>, action: AddCountry) {
        return this.veldaLanguagesService.addCountry(action.country).pipe(
            tap((country: VeldaCountry) => {
                ctx.setState(
                    patch<VeldaLanguagesStateModel>({
                        countries: append<VeldaCountry>([country]),
                    }),
                );
            }),
        );
    }

    @Action(EditCountry)
    editCountry(ctx: StateContext<VeldaLanguagesStateModel>, action: EditCountry) {
        return this.veldaLanguagesService.editCountry(action.countryUuid, action.country).pipe(
            tap((country: VeldaCountry) => {
                ctx.setState(
                    patch<VeldaLanguagesStateModel>({
                        countries: updateItem<VeldaCountry>((c) => c.uuid === country.uuid, country),
                    }),
                );
            }),
        );
    }

    @Action(DeleteCountry)
    deleteCountry(ctx: StateContext<VeldaLanguagesStateModel>, action: DeleteCountry) {
        return this.veldaLanguagesService.deleteCountry(action.countryUuid).pipe(
            tap((countryUuid: string) => {
                ctx.setState(
                    patch<VeldaLanguagesStateModel>({
                        countries: removeItem<VeldaCountry>((c) => c.uuid === countryUuid),
                    }),
                );
            }),
        );
    }

    @Action(FetchLanguages)
    fetchLanguages(ctx: StateContext<VeldaLanguagesStateModel>) {
        return this.veldaLanguagesService.getAllLanguages().pipe(
            tap((languages: VeldaLanguage[]) => {
                ctx.setState(
                    patch<VeldaLanguagesStateModel>({
                        languages: languages,
                    }),
                );
            }),
        );
    }

    @Action(AddLanguage)
    addLanguage(ctx: StateContext<VeldaLanguagesStateModel>, action: AddLanguage) {
        return this.veldaLanguagesService.addLanguage(action.language).pipe(
            tap((language: VeldaLanguage) => {
                ctx.setState(
                    patch<VeldaLanguagesStateModel>({
                        languages: append<VeldaLanguage>([language]),
                    }),
                );
            }),
        );
    }

    @Action(EditLanguage)
    editLanguage(ctx: StateContext<VeldaLanguagesStateModel>, action: EditLanguage) {
        return this.veldaLanguagesService.editLanguage(action.languageUuid, action.language).pipe(
            tap((language: VeldaLanguage) => {
                ctx.setState(
                    patch<VeldaLanguagesStateModel>({
                        languages: updateItem<VeldaLanguage>((l) => l.uuid === language.uuid, language),
                    }),
                );
            }),
        );
    }

    @Action(DeleteLanguage)
    deleteLanguage(ctx: StateContext<VeldaLanguagesStateModel>, action: DeleteLanguage) {
        return this.veldaLanguagesService.deleteLanguage(action.languageUuid).pipe(
            tap((languageUuid: string) => {
                ctx.setState(
                    patch<VeldaLanguagesStateModel>({
                        languages: removeItem<VeldaLanguage>((l) => l.uuid === languageUuid),
                    }),
                );
            }),
        );
    }
}
