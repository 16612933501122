<div class="modal-wrapper">
    <div class="modal-header">
        <h2 class="modal-title">Confirm new child invoice links</h2>

        <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="activeModal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <table
            mat-table
            [dataSource]="newLinksDatasource">
            <ng-container matColumnDef="object">
                <th
                    mat-header-cell
                    *matHeaderCellDef>
                    Object
                </th>
                <td
                    mat-cell
                    *matCellDef="let row">
                    <app-pill-overhead-category
                        *ngIf="row.type.toLowerCase() === 'overheadCategory'.toLowerCase()"
                        [overheadCategoryId]="row.newObjectLinkId">
                    </app-pill-overhead-category>
                    <app-pill-employee
                        *ngIf="row.type.toLowerCase() === 'employee'.toLowerCase()"
                        [employeeId]="row.newObjectLinkId">
                    </app-pill-employee>
                    <app-pill-project
                        *ngIf="row.type.toLowerCase() === 'project'.toLowerCase()"
                        [projectUuid]="row.newObjectLinkId">
                    </app-pill-project>
                    <app-pill-budget
                        *ngIf="row.type.toLowerCase() === 'budget'.toLowerCase()"
                        [budgetUuid]="row.newObjectLinkId">
                    </app-pill-budget>
                </td>
            </ng-container>

            <ng-container matColumnDef="amount">
                <th
                    mat-header-cell
                    *matHeaderCellDef>
                    Amount
                </th>
                <td
                    mat-cell
                    *matCellDef="let row; let i = index"
                    class="td-icon">
                    <div class="input--currency amount-column">
                        <input
                            type="number"
                            [(ngModel)]="newChildLinks[i].amount"
                            (input)="checkAmountOverflow()" />
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="remove">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    class="td-small"></th>
                <td
                    mat-cell
                    *matCellDef="let row; let i = index"
                    class="td-icon">
                    <button
                        (click)="$event.stopPropagation(); deleteNewChildLink(i, row)"
                        class="button button--icon button--small">
                        <i class="las la-trash color--red"></i>
                    </button>
                </td>
            </ng-container>

            <ng-container matColumnDef="percentage">
                <th
                    mat-header-cell
                    *matHeaderCellDef></th>
                <td
                    mat-cell
                    *matCellDef="let row; let i = index">
                    {{ getPercentageForLink(row) }}
                </td>
            </ng-container>

            <tr
                mat-header-row
                *matHeaderRowDef="displayedColumnsNewLinks"></tr>
            <tr
                mat-row
                *matRowDef="let row; columns: displayedColumnsNewLinks"></tr>
            <tr *matNoDataRow>
                <td colspan="4">No new sub links yet...</td>
            </tr>
        </table>
        <small
            *ngIf="amountOverflowMessage"
            class="form-error">
            {{ amountOverflowMessage }} {{ calculateLinkedAmount() - linkPrice | currency: 'EUR' }}...
        </small>
        <div class="form-helptext m-t-4">
            <p>This financial information is retrieved from the timesheets that are linked to your project for the billed month on the invoice.</p>
            <p *ngIf="childLinks.length > 1">Do you want to save these links?</p>
            <p *ngIf="childLinks.length === 1">Do you want to save this link?</p>
        </div>
    </div>
    <div class="modal-footer">
        <button
            class="button button--outline"
            (click)="activeModal.close()">
            Cancel
        </button>
        <button
            class="button button--primary"
            (click)="saveLinks()">
            Save
        </button>
    </div>
</div>
