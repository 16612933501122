<div class="modal-header">
    <h2
        class="modal-title"
        id="exampleModalLabel">
        Reason of losing this deal?
    </h2>
    <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="cancel()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <div
        class="row"
        *ngIf="!addNewReason"
        [formGroup]="reasonForm">
        <div class="col">
            <app-global-autocomplete
                [parentForm]="reasonForm"
                [formControlName]="'reason'"
                [data]="reasons$ | async"
                [label]="'Reasons'"
                [notFoundMessage]="'No reasons found'"
                [keyword]="'reason'"
                [placeholder]="'Choose a reason'"
                [dataLoaded]="!isLoading"
                [required]="true"></app-global-autocomplete>
        </div>
    </div>

    <div
        class="row"
        *ngIf="addNewReason">
        <div class="col">
            <div class="form-group m-b-2">
                <label>Reason:</label>
                <textarea
                    class="resize-vertical"
                    (keyup)="onKeyUp()"
                    type="text"
                    placeholder="why did you lose this deal"
                    [(ngModel)]="reason"
                    rows="5"></textarea>
                <small><span *ngIf="currentLength === null">0</span>{{ currentLength }}/{{ maxLength }}</small>
            </div>
        </div>
    </div>

    <a (click)="this.addNewReason = !this.addNewReason">
        {{ this.addNewReason ? 'Select an existing reason' : 'Or add a new one' }}
    </a>
</div>

<div class="modal-footer">
    <button
        class="button button--primary"
        (click)="submit()">
        Add reason
    </button>
    <button
        type="button"
        class="button button--outline"
        (click)="cancel()">
        Skip
    </button>
</div>
