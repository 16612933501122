<form (ngSubmit)="saveInvoice()" [formGroup]="invoiceForm" class="modal-wrapper">
    <div class="modal-header">
        <h2 class="modal-title">
            Create new invoice
        </h2>

        <button
            (click)="activeModal.dismiss()"
            aria-label="Close"
            class="close"
            type="button">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">

        <section>
            <p class="sectiontitle sectiontitle--first">Financial</p>
            <div class="d-flex justify-content-center align-items-end m-b-2">
                <div class="form-group required w-100">
                    <label for="amountExcl">Amount excl. VAT</label>
                    <div class="input--currency">
                        <input
                            formControlName="amountExcl"
                            id="amountExcl"
                            step="0.01"
                            type="number"/>
                    </div>
                </div>
                <button
                    (click)="calculateAmountExcl()"
                    [ngbTooltip]="amountExclCalcBtnTooltip"
                    class="button button--icon m-l-2"
                    type="button">
                    <i class="las la-calculator"></i>
                </button>
            </div>

            <div class="d-flex align-items-end m-b-2">
                <div class="form-group required w-100">
                    <label for="vatAmount">VAT amount</label>
                    <div class="input--currency">
                        <input
                            formControlName="vatAmount"
                            id="vatAmount"
                            step="0.01"
                            type="number"/>
                    </div>
                </div>
                <div class="vat-dropdown form-group required m-l-2 vat-select">
                    <label for="vat">VAT</label>
                    <select
                        (change)="updateVATAmount()"
                        class="select"
                        formControlName="vat"
                        id="vat">
                        <option
                            *ngFor="let value of VATs"
                            [ngValue]="value">
                            <span
                                *ngIf="value.value !== 'OTHER'; else otherPercent">{{ value.percent / 100 | percent }}</span>
                            <ng-template #otherPercent>Other</ng-template>
                        </option>
                    </select>
                </div>
                <button
                    (click)="calculateVatAmount()"
                    [ngbTooltip]="vatAmountCalcBtnTooltip"
                    class="button button--icon m-l-2"
                    type="button">
                    <i class="las la-calculator"></i>
                </button>
            </div>

            <div class="divider divider--small"></div>

            <div class="row">
                <div class="col">
                    <div class="form-group m-b-2 required">
                        <label for="amount">Amount incl. VAT</label>
                        <div class="input--currency">
                            <input
                                formControlName="amount"
                                id="amount"
                                step="0.01"
                                type="number"/>
                        </div>
                        <small
                            *ngIf="checkAmountError()"
                            class="form-error">
                            Amount excl. VAT + VAT amount and amount incl. VAT doesn't match
                        </small>
                    </div>
                </div>
            </div>
        </section>

        <section>
            <p class="sectiontitle">Information</p>
            <div
                class="row">
                <div class="col">
                    <div class="form-group m-b-2 required">
                        <label for="invoiceNumber">Invoice number</label>
                        <input
                            formControlName="invoiceNumber"
                            id="invoiceNumber"
                            type="text"/>
                        <small
                            *ngIf="invoiceForm.get('invoiceNumber').invalid && invoiceForm.get('invoiceNumber').touched"
                            class="form-error">
                            Invoice number is required...
                        </small>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col">
                    <div class="form-group m-b-2">
                        <label for="subject">Subject</label>
                        <input
                            formControlName="subject"
                            id="subject"
                            type="text"/>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col">
                    <div class="form-group required m-b-2">
                        <ng-container *ngIf="folders$ | async; else loading">
                            <label for="folder">Folder</label>
                            <ng-autocomplete
                                [data]="folders"
                                [itemTemplate]="folderTemplate"
                                [notFoundTemplate]="folderNotFoundTemplate"
                                formControlName="folder"
                                id="folder"
                                placeholder="Choose folder"
                                searchKeyword="description">
                            </ng-autocomplete>

                            <ng-template #folderTemplate let-item>
                                <div class="autocomplete--item">
                                    <a [innerHTML]="item.description"></a>
                                </div>
                            </ng-template>

                            <ng-template #folderNotFoundTemplate>
                                <div>No folder found...</div>
                            </ng-template>
                        </ng-container>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col">
                    <div class="form-group required m-b-2">
                        <ng-container *ngIf="partners$ | async; else loading">
                            <label for="linkedResumoContact">Resumo contact</label>
                            <ng-autocomplete
                                [data]="partners"
                                [itemTemplate]="partnerTemplate"
                                [notFoundTemplate]="notFoundTemplate"
                                formControlName="linkedContact"
                                id="linkedResumoContact"
                                placeholder="Choose linked contact"
                                searchKeyword="title">
                            </ng-autocomplete>

                            <ng-template #partnerTemplate let-item>
                                <div class="autocomplete--item">
                                    <a [innerHTML]="item.title"></a>
                                </div>
                            </ng-template>

                            <ng-template #notFoundTemplate>
                                <div (click)="openCreateContactModal()">+ Add new contact</div>
                            </ng-template>
                        </ng-container>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col">
                    <div class="form-group required">
                        <label for="documentDate">Document date</label>
                        <input formControlName="documentDate" id="documentDate" type="date">
                    </div>
                </div>
            </div>

            <div class="row">
                <description-input [formControl]="invoiceForm.controls['description']"></description-input>
            </div>
        </section>

        <section>
            <p class="sectiontitle">Invoice</p>
            <app-file-upload formControlName="invoiceUrl" type="file"></app-file-upload>
        </section>
    </div>

    <div class="modal-footer">
        <button (click)="activeModal.close()" class="button button--outline" type="button">Cancel</button>
        <button [disabled]="invoiceForm.invalid" class="button button--primary">Save</button>
    </div>
</form>

<ng-template #loading>
    <app-loading-message></app-loading-message>
</ng-template>

<ng-template #amountExclCalcBtnTooltip> Calculate amount excl. based on total amount and VAT.</ng-template>

<ng-template #vatAmountCalcBtnTooltip> Calculate VAT based on total and amount excl.</ng-template>

