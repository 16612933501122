<form
    [formGroup]="resumeFormGroup"
    (ngSubmit)="addResume()"
    class="modal-wrapper">
    <div class="modal-header">
        <h2
            class="modal-title" 
            id="exampleModalLabel">
            New resume
        </h2>
        <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="activeModal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-visual">
        <img src="https://image.flaticon.com/icons/svg/2206/2206464.svg" />
    </div>

    <div class="modal-body">
        <div class="row">
            <div class="col-6">
                <div class="form-group required m-b-2">
                    <label>Firstname</label>
                    <input
                        type="text"
                        placeholder="Firstname"
                        formControlName="firstName"
                        (focus)="hideErrorMessage()"
                        trim="blur"
                        #focussed />
                    <span
                        class="form-error"
                        *ngIf="resumeFormGroup.controls.firstName.errors && inputIsWrong"
                        >Invalid first name</span
                    >
                </div>
            </div>
            <div class="col-6">
                <div class="form-group required m-b-2">
                    <label>Lastname</label>
                    <input
                        type="text"
                        placeholder="Lastname"
                        formControlName="lastName"
                        (focus)="hideErrorMessage()"
                        trim="blur" />
                    <span
                        class="form-error"
                        *ngIf="resumeFormGroup.controls.lastName.errors && inputIsWrong"
                        >Invalid last name</span
                    >
                </div>
            </div>
        </div>

        <div class="form-group required m-b-2">
            <label>Job title</label>
            <input
                type="text"
                placeholder="Jobtitle"
                formControlName="jobTitle"
                (focus)="hideErrorMessage()"
                trim="blur" />
            <span class="form-helptext">E.g. projectmanager</span>
            <span
                class="form-error"
                *ngIf="resumeFormGroup.controls.jobTitle.errors && inputIsWrong"
                >Jobtitle is required</span
            >
        </div>

        <div class="form-group required m-b-2">
            <label>Gender</label>
            <select formControlName="gender">
                <option value="MALE">Male</option>
                <option value="FEMALE">Female</option>
                <option value="OTHER">Other</option>
            </select>
            <span
                class="form-error"
                *ngIf="resumeFormGroup.get('gender').errors && inputIsWrong"
                >Gender is required</span
            >
        </div>
    </div>

    <div class="modal-footer">
        <button
            type="button"
            class="button button--outline"
            (click)="activeModal.close()">
            Close
        </button>
        <button
            type="submit"
            [ngClass]="{ 'button--loading': loading }"
            class="button button--primary">
            Create resume
        </button>
    </div>
</form>
