<form
    [formGroup]="fileSystemEntityFormGroup"
    (ngSubmit)="submit()"
    class="modal-wrapper">
    <div class="modal-header">
        <h2
            *ngIf="type === 'Doc'"
            class="modal-title"
            id="exampleModalLabel">
            Add a file
        </h2>
        <h2
            *ngIf="type === 'Dir'"
            class="modal-title"
            id="exampleModalLabel">
            Add a folder
        </h2>
        <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="activeModal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <div class="form-group required">
            <label *ngIf="type === 'Doc'">File name</label>
            <label *ngIf="type === 'Dir'">Folder name</label>
            <input
                type="text"
                placeholder="name"
                formControlName="name"
                trim="blur"
                #focussed />
            <span
                class="form-error"
                *ngIf="!fileSystemEntityFormGroup.valid && fileSystemEntityFormGroup.touched && checkValid()"
                >Please enter a name.</span
            >
            <span
                class="form-error"
                *ngIf="fileSystemEntityFormGroup.touched && !checkValid()"
                >This name already exists in the current folder.</span
            >
        </div>
    </div>

    <div class="modal-footer">
        <button
            type="button"
            class="button button--outline"
            (click)="activeModal.close()">
            Close
        </button>
        <button
            *ngIf="type === 'Doc'"
            type="submit"
            [ngClass]="!fileSystemEntityFormGroup.valid || !checkValid() ? 'button button--disabled' : 'button button--primary'"
            [disabled]="!fileSystemEntityFormGroup.valid">
            Add file
        </button>
        <button
            *ngIf="type === 'Dir'"
            type="submit"
            [ngClass]="!fileSystemEntityFormGroup.valid || !checkValid() ? 'button button--disabled' : 'button button--primary'"
            [disabled]="!fileSystemEntityFormGroup.valid">
            Add folder
        </button>
    </div>
</form>
