import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-bulk-add-tag-modal',
    templateUrl: './bulk-add-tag-modal.component.html',
    styleUrls: ['./bulk-add-tag-modal.component.scss'],
})
export class BulkAddTagModalComponent implements OnInit {
    @Input() objects;
    @Input() type;

    objectUuids = [];

    constructor(public activeModal: NgbActiveModal) {}

    ngOnInit(): void {
        this.objects.forEach((element) => this.objectUuids.push(element.uuid));
    }
}
