import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { CompanyState } from 'src/app/companies/company.state';
import { Resume } from 'src/app/models/resume/Resume';

import { AddResume } from '../resume.actions';

@Component({
    selector: 'app-resume-add-modal',
    templateUrl: './resume-add-modal.component.html',
    styleUrls: ['./resume-add-modal.component.scss'],
})
export class ResumeAddModalComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Select(CompanyState.companyUuid) companyUuid$: Observable<string>;

    @Input() applicantUuid: string;

    resume: Resume;
    resumeFormGroup: FormGroup;
    loading = false;
    inputIsWrong = false;

    @ViewChild('focussed', { static: false })
    set input(element: ElementRef<HTMLInputElement>) {
        if (element) {
            element.nativeElement.focus();
        }
    }

    constructor(
        public activeModal: NgbActiveModal,
        private formBuilder: FormBuilder,
        private store: Store,
        private router: Router,
        private toastr: ToastrService,
        private logger: NGXLogger,
    ) {}

    ngOnInit(): void {
        this.resumeFormGroup = this.formBuilder.group({
            firstName: ['', [Validators.required]],
            lastName: ['', [Validators.required]],
            jobTitle: ['', [Validators.required]],
            gender: ['', [Validators.required]],
        });
    }

    addResume() {
        this.loading = true;

        if (!this.resumeFormGroup.valid) {
            this.inputIsWrong = true;
            this.loading = false;
            return;
        }

        this.resumeFormGroup.disable();

        this.resume = this.resumeFormGroup.value;

        this.subscriptions.add(this.companyUuid$.subscribe((uuid) => (this.resume.companyUuid = uuid)));

        this.logger.debug('Attempt to add resume');
        this.subscriptions.add(
            this.store.dispatch(new AddResume(this.resume)).subscribe(
                (state) => {
                    this.logger.debug('Succesfully added resume');
                    this.toastr.success('Resume added succesfully');
                    this.activeModal.close();
                    this.logger.debug('Navigating to resume details');
                    this.router.navigate(['/', 'resume', state.resume.currentResume.uuid, 'detail']);
                },
                () => {
                    this.logger.error('Failed to add resume');
                    this.loading = false;
                    this.resumeFormGroup.enable();
                },
            ),
        );
    }
    // Hides the error messages that appear after a user has submitted invalid data
    hideErrorMessage() {
        this.inputIsWrong = false;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
