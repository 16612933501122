import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { Employee } from 'src/app/models/Employee';
import { JiraEmployeeCredential } from 'src/app/models/jiraEmployeeCredential';
import { JiraLoginService } from 'src/app/shared/login-components/jira-login/jira-login.service';

import { AddEmployee } from '../employees.actions';

@Component({
    selector: 'app-employees-add-modal',
    templateUrl: './employees-add-modal.component.html',
    styleUrls: ['./employees-add-modal.component.scss'],
})
export class EmployeesAddModalComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();
    public emitObject: EventEmitter<Employee> = new EventEmitter();

    @Input() employee: Employee;
    @Input() inOverview: boolean;

    public newEmployee: Employee;
    public employeeFormGroup: FormGroup;
    public errorMessage: string;
    public connectionError: string;
    public loading = false;
    public inputIsWrong = false;
    public employeeWentWrong = false;
    public error: any = { isError: false, errorMessage: '' };
    public extraInfoMaxLength = 1000;
    public currentAmountOfExtraInfoCharacters: number;
    public extraInfoIsTooLong = false;
    isValidDate = false;
    jiraEmployeeIdList$: JiraEmployeeCredential[] = [];
    jiraEmployeeIdListLoaded = false;

    @ViewChild('focussed', { static: false })
    set input(element: ElementRef<HTMLInputElement>) {
        if (element) {
            element.nativeElement.focus();
        }
    }

    constructor(
        public activeModal: NgbActiveModal,
        private formBuilder: FormBuilder,
        private store: Store,
        private toastr: ToastrService,
        private logger: NGXLogger,
        private jiraLoginService: JiraLoginService,
    ) {}

    ngOnInit(): void {
        // eslint-disable-next-line @typescript-eslint/ban-types
        this.jiraLoginService.getJiraEmployeeIDs().subscribe((data: Object[]) => {
            for (const obj of data) {
                const name = obj['employeeName'] ? obj['employeeName'] : '';
                const id = obj['employeeId'] ? obj['employeeId'] : '';
                const jiraEmployeeCredential = new JiraEmployeeCredential(name, id);
                this.jiraEmployeeIdList$.push(jiraEmployeeCredential);
            }
            this.jiraEmployeeIdListLoaded = true;
        });

        if (this.employee) {
            this.employee.extraInfo
                ? (this.currentAmountOfExtraInfoCharacters = this.employee.extraInfo.length)
                : (this.currentAmountOfExtraInfoCharacters = 0);
        }
        this.employeeFormGroup = this.formBuilder.group({
            firstName: [this.employee ? this.employee.firstName : '', [Validators.required, Validators.minLength(2), Validators.maxLength(30)]],
            lastName: [this.employee ? this.employee.lastName : '', [Validators.required, Validators.minLength(2), Validators.maxLength(30)]],
            extraInfo: [''],
            hireDate: [this.employee ? this.employee.hireDate : '', Validators.required],
            birthday: [this.employee ? this.employee.birthday : '', Validators.required],
            profession: [this.employee ? this.employee.profession : ''],
            jiraEmployeeId: this.jiraEmployeeIdList$,
            contactInformation: this.formBuilder.group({
                phoneNumber: [this.employee && this.employee.contactInformation ? this.employee.contactInformation.phoneNumber : ''],
                email: [
                    this.employee && this.employee.contactInformation ? this.employee.contactInformation.email : '',
                    [Validators.email, Validators.required],
                ],
                website: [this.employee && this.employee.contactInformation ? this.employee.contactInformation.website : ''],
                linkedIn: [this.employee && this.employee.contactInformation ? this.employee.contactInformation.linkedIn : ''],
            }),
        });
    }

    onDescriptionKeyUp() {
        this.extraInfoIsTooLong = false;
        this.currentAmountOfExtraInfoCharacters = this.employeeFormGroup.controls.extraInfo.value.length;
        if (this.currentAmountOfExtraInfoCharacters > this.extraInfoMaxLength) {
            this.extraInfoIsTooLong = true;
        }
    }

    saveEmployee() {
        this.loading = true;

        if (this.extraInfoIsTooLong) {
            this.loading = false;
            return;
        }

        const hireDate = new Date(this.employeeFormGroup?.get('hireDate').value);
        const birthdayDate = new Date(this.employeeFormGroup?.get('birthday').value);
        if (!this.employeeFormGroup.valid || hireDate <= birthdayDate) {
            this.inputIsWrong = true;
            this.loading = false;
            return;
        }

        this.employeeFormGroup.disable();

        this.newEmployee = this.employeeFormGroup.value;
        this.newEmployee.contactInformation.email = this.newEmployee.contactInformation.email.toLowerCase();

        if (this.newEmployee.contactInformation.website) {
            if (this.newEmployee.contactInformation.website.substring(0, 8) !== 'https://') {
                if (this.newEmployee.contactInformation.website.substring(0, 7) === 'http://') {
                    this.newEmployee.contactInformation.website = 'https://' + this.newEmployee.contactInformation.website.substring(7);
                } else {
                    this.newEmployee.contactInformation.website = 'https://' + this.newEmployee.contactInformation.website;
                }
            }
        }

        this.logger.debug('Attempt to add employee');
        this.newEmployee.globalTags = [];
        this.subscriptions.add(
            this.store.dispatch(new AddEmployee(this.newEmployee)).subscribe(
                (state) => {
                    this.logger.debug('Succesfully added employee');
                    this.toastr.success('Employee added successfully');
                    this.emitObject.emit(
                        state.employee.employees.find((e) => e.firstName == this.newEmployee.firstName && e.lastName == this.newEmployee.lastName),
                    );
                    this.activeModal.dismiss();
                },
                (error) => {
                    this.logger.error('Failed to add employee');
                    this.errorMessage = error?.error?.message ?? 'Error message was empty';

                    this.loading = false;
                    this.employeeFormGroup.enable();

                    if (!this.errorMessage) {
                        this.connectionError = 'No connection to the server';
                    }

                    this.employeeWentWrong = true;
                },
            ),
        );
    }

    hideErrorMessage() {
        this.inputIsWrong = false;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
