import { OnChanges, OnDestroy } from '@angular/core';
import { Component, Input, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { MailchimpService } from 'src/app/mailchimp/mailchimp.service';
import Lead from 'src/app/models/Lead';
import { ConfirmationModalComponent } from 'src/app/shared/confirmation-modal/confirmation-modal.component';
import AddNewRecipientModel from 'src/app/shared/mailchimp/add-recipient-modal/AddNewRecipientModel';
import { AddRecipientsToListModalComponent } from 'src/app/shared/mailchimp/add-recipients-to-list-modal/add-recipients-to-list-modal.component';

import { LeadDeleteModalComponent } from '../lead-delete-modal/lead-delete-modal.component';
import { LeadDetailModalComponent } from '../lead-detail-modal/lead-detail-modal.component';
import { LeadToDealModalComponent } from '../lead-to-deal-modal/lead-to-deal-modal.component';
import { ArchiveLead, UpdateReadOfLead } from '../lead.actions';
import { LeadState } from '../lead.state';

@Component({
    selector: 'app-leads-list',
    templateUrl: './leads-list.component.html',
    styleUrls: ['./leads-list.component.scss'],
})
export class LeadsListComponent implements OnChanges, OnDestroy {
    private subscriptions = new Subscription();

    @Select(LeadState.getLeads) allLeads$: Observable<Lead[]>;
    @Select(LeadState.archivedLeads) archivedLeads$: Observable<Lead[]>;

    @Input() channel = '';
    dataSourceIsOfTypeArchived = false;

    leads: Lead[];
    allCheck = false;
    checkBoxes: any[] = [];

    filter: string;
    displayedColumns: string[] = ['checkbox', 'read', 'fullname', 'email', 'createdOn', 'channelKey', 'edit'];
    dataSource: MatTableDataSource<Lead>;
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    constructor(
        private modalService: NgbModal,
        private store: Store,
        private logger: NGXLogger,
        private mailchimpService: MailchimpService,
        private toastr: ToastrService,
    ) {}

    /* we use ngOnChanges to handle channel changed */
    ngOnChanges(changes) {
        this.checkBoxes = [];
        this.allCheck = false;
        if (this.channel == '') {
            this.subscriptions.add(
                this.allLeads$.subscribe((allLeads) => {
                    /* load leads from all channels on first load */
                    /* setup datasource just like in ngOnInit for other tables */
                    /* if not first load, filter on channel */
                    if (changes.channel.firstChange) {
                        /* setup datasource */
                        this.dataSource = new MatTableDataSource(allLeads);
                        this.dataSource.sort = this.sort;

                        /* sortingDataAccessor to sort on nested objs */
                        this.dataSource.sortingDataAccessor = (item, property) => {
                            if (property.includes('.')) return property.split('.').reduce((o, i) => o[i], item);
                            return item[property];
                        };
                    } else {
                        this.dataSource.data = allLeads;
                    }

                    this.leads = allLeads;
                    this.leads.forEach((l) => {
                        const check = {
                            lead: l.uuid,
                            value: false,
                        };
                        this.checkBoxes.push(check);
                    });
                }),
            );

            /* load archived leads when channel gets selected */
        } else if (this.channel == 'archived') {
            this.subscriptions.add(
                this.archivedLeads$.subscribe((archivedLeads) => {
                    this.dataSource.data = archivedLeads;
                    this.dataSourceIsOfTypeArchived = true;

                    this.leads = archivedLeads;
                    this.leads.forEach((l) => {
                        const check = {
                            lead: l.uuid,
                            value: false,
                        };
                        this.checkBoxes.push(check);
                    });
                }),
            );

            /* filter on channel if it changes */
        } else {
            this.subscriptions.add(
                this.allLeads$.subscribe((allLeads) => {
                    const filteredLeads = allLeads.filter((lead) => lead.channelKey == this.channel);
                    this.dataSource.data = filteredLeads;
                    this.dataSourceIsOfTypeArchived = false;
                    this.checkBoxes = [];
                    this.leads = filteredLeads;
                    this.leads.forEach((l) => {
                        const check = {
                            lead: l.uuid,
                            value: false,
                        };
                        this.checkBoxes.push(check);
                    });
                }),
            );
        }
    }

    applyFilter(filterValue) {
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }

    toggleLeadRead(lead: Lead) {
        const changedLead = JSON.parse(JSON.stringify(lead));
        changedLead.read = !lead.read;
        this.logger.debug('Attempt to update read of lead');
        this.subscriptions.add(this.store.dispatch(new UpdateReadOfLead(changedLead)).subscribe());
    }

    getCurrentCheck(lead: Lead) {
        if (this.checkBoxes.find((check) => check.lead === lead.uuid) != undefined) {
            return this.checkBoxes.find((check) => check.lead === lead.uuid).value;
        } else {
            const check = { lead: lead.uuid, value: false };
            this.checkBoxes.push(check);
            return check.value;
        }
    }

    checkChange(lead: Lead) {
        this.checkBoxes.find((check) => check.lead === lead.uuid).value = !this.checkBoxes.find((check) => check.lead === lead.uuid).value;
    }

    checkAll() {
        this.allCheck = !this.allCheck;
        this.checkBoxes.forEach((el) => {
            el.value = this.allCheck;
        });
    }

    getAmount() {
        return this.checkBoxes.filter((l) => l.value).length;
    }

    addToMailchimp() {
        if (this.getAmount() <= 0) {
            this.toastr.warning('You have not selected any leads.');
        } else {
            this.logger.debug('Checking if Mailchimp api key exists');
            this.subscriptions.add(
                this.mailchimpService.checkIfApiKeyExists().subscribe(
                    (res) => {
                        if (res.key) {
                            this.logger.debug('Found Mailchimp key');
                            const recipients: AddNewRecipientModel[] = [];
                            this.leads.forEach((lead) => {
                                if (this.checkBoxes.find((check) => check.lead === lead.uuid).value) {
                                    const rec: AddNewRecipientModel = {
                                        firstname: lead.firstName,
                                        lastname: lead.lastName,
                                        email: lead.email,
                                        phone: lead.phoneNumber,
                                        tags: [],
                                        listId: '',
                                    };
                                    recipients.push(rec);
                                }
                            });
                            this.logger.debug('opening addRecipientsToListModalComponent');
                            const modalRef = this.modalService.open(AddRecipientsToListModalComponent, { windowClass: 'modal-prompt' });
                            modalRef.componentInstance.recipients = recipients;
                        } else {
                            this.logger.debug('No Mailchimp key found');
                            this.toastr.warning('Please set it in the module settings.', 'Missing mailchimp key');
                        }
                    },
                    () => this.logger.error('Failed to find Mailchimp key'),
                ),
            );
        }
    }

    archiveLeads() {
        if (this.getAmount() <= 0) {
            this.toastr.warning('You have not selected any leads.');
        } else {
            const selectedLeads = this.leads.filter((lead) => this.checkBoxes.find((check) => check.lead === lead.uuid).value);

            this.logger.debug('Opening confirmationModalComponent type:Archive');
            const modalRef = this.modalService.open(ConfirmationModalComponent, { windowClass: 'modal-prompt' });
            modalRef.componentInstance.type = 'Archive';
            modalRef.componentInstance.title = 'Archive leads';
            if (this.getAmount() == 1) {
                modalRef.componentInstance.message = 'Are you sure you want to archive ' + this.getAmount() + ' lead?';
            } else {
                modalRef.componentInstance.message = 'Are you sure you want to archive ' + this.getAmount() + ' leads?';
            }
            this.subscriptions.add(
                modalRef.componentInstance.closeEvent.subscribe((val) => {
                    if (val) {
                        this.logger.debug('Attempt to archive leads');
                        selectedLeads.forEach((lead) => {
                            this.store.dispatch(new ArchiveLead(lead, false)).subscribe(
                                () => {
                                    this.logger.debug('Succesfully archived lead');
                                },
                                () => this.logger.error('Failed to archive lead with id:' + lead.uuid),
                            );
                        });
                        if (this.getAmount() == 1) {
                            this.toastr.info(this.getAmount() + ' lead archived.');
                        } else {
                            this.toastr.info(this.getAmount() + ' leads archived.');
                        }
                        this.checkAll();
                    }
                }),
            );
        }
    }

    openLeadUpdateModal(lead: Lead, archived?: boolean) {
        this.logger.debug('Opening leadDetailModal - update');
        const modalRef = this.modalService.open(LeadDetailModalComponent, {
            windowClass: 'modal-huge',
            animation: false,
        });
        modalRef.componentInstance.lead = lead;
        archived === true ? (modalRef.componentInstance.archived = true) : (modalRef.componentInstance.archived = false);
    }

    openLeadAddModal() {
        this.logger.debug('Opening leadDetailModal - add');
        this.modalService.open(LeadDetailModalComponent, {
            windowClass: 'modal-huge',
            animation: false,
        });
    }

    openLeadDeleteModal(lead: Lead, archived?: boolean) {
        this.logger.debug('Opening leadDeleteModal');
        const modalRef = this.modalService.open(LeadDeleteModalComponent, {
            windowClass: 'modal-prompt',
        });
        modalRef.componentInstance.lead = lead;
        archived ? (modalRef.componentInstance.archived = archived) : (modalRef.componentInstance.archived = false);
    }

    openLeadToDealModal(lead: Lead) {
        this.logger.debug('opening LeadToDealModal');
        const modalRef = this.modalService.open(LeadToDealModalComponent, {
            windowClass: 'modal-prompt',
        });
        modalRef.componentInstance.lead = lead;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
