import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, catchError, throwError } from 'rxjs';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
    lastErrorThrown: Date = new Date();

    constructor(private toastr: ToastrService) {}
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError((err: any) => {
                if (this.lastErrorThrown != undefined && this.lastErrorThrown.getTime() > new Date().getTime() - 10) {
                    return throwError(() => err);
                }
                this.lastErrorThrown = new Date();
                const message = err?.error?.message ? err?.error?.message : 'An unexpected error occurred.';
                this.toastr.error(message, 'Error');
                return throwError(() => err);
            }),
        );
    }
}
