import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';
import { Experience } from 'src/app/models/resume/Experience';

import { DeleteExperience } from '../resume.actions';

@Component({
    selector: 'app-experience-delete-modal',
    templateUrl: './experience-delete-modal.component.html',
    styleUrls: ['./experience-delete-modal.component.scss'],
})
export class ExperienceDeleteModalComponent implements OnInit, OnDestroy {
    @Input() experience: Experience;
    private subscriptions = new Subscription();

    constructor(public activeModal: NgbActiveModal, private store: Store, private logger: NGXLogger) {}

    ngOnInit(): void {}

    deleteExperience() {
        this.logger.debug('Attempt to delete experience');
        this.subscriptions.add(
            this.store.dispatch(new DeleteExperience(this.experience.uuid)).subscribe(
                () => {
                    this.logger.debug('Succesfully deleted experience');
                    this.activeModal.close();
                },
                (error) => {
                    this.logger.error('Failed to deleted experience');
                },
            ),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
