import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { CompanyState } from '../companies/company.state';

@Injectable({
    providedIn: 'root',
})
export class OnboardingGuard implements CanActivate {
    constructor(private store: Store, private router: Router, private logger: NGXLogger) {}

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.store.select(CompanyState.company).pipe(
            tap((company) => {
                company.title ? this.router.navigate(['/dashboard']) : of(true);
            }),
            catchError((error) => {
                this.logger.debug('Fetching company failed, navigate to onboarding (ob)');

                return of(true);
            }),
            map(() => true),
        );
    }
}
