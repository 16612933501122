import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-pill-comanage-offer',
    templateUrl: './pill-comanage-offer.component.html',
    styleUrls: ['./pill-comanage-offer.component.scss'],
})
export class PillComanageOfferComponent implements OnInit {
    @Input() offer: any;

    constructor() {}

    ngOnInit(): void {}
}
