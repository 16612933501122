import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { format } from 'date-fns';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';

import Budget from '../../models/Budget';
import BudgetExpenditure from '../../models/BudgetExpenditure';
import { AddBudgetExpenditure, UpdateBudgetExpenditure } from '../budget.actions';

@Component({
    selector: 'app-add-budget-expenditure-modal',
    templateUrl: './add-budget-expenditure-modal.component.html',
    styleUrls: ['./add-budget-expenditure-modal.component.scss'],
})
export class AddBudgetExpenditureModalComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    budgetExpenditureForm: FormGroup;

    @Input() type: string;
    @Input() budget: Budget;
    @Input() budgetExpenditure: BudgetExpenditure;

    constructor(public activeModal: NgbActiveModal, private fb: FormBuilder, private store: Store, private toastr: ToastrService, private logger: NGXLogger) {}

    ngOnInit(): void {
        if (this.budgetExpenditure) {
            this.budgetExpenditureForm = this.fb.group({
                expenditure: [this.budgetExpenditure.expenditure, [Validators.required]],
                cost: [this.budgetExpenditure.cost, [Validators.required]],
                date: [format(new Date(this.budgetExpenditure.date), 'yyyy-MM-dd'), [Validators.required]],
                invoiceUrl: [this.budgetExpenditure.invoiceUrl],
            });
        } else {
            this.budgetExpenditureForm = this.fb.group({
                expenditure: ['', [Validators.required]],
                cost: ['', [Validators.required]],
                date: [format(new Date(), 'yyyy-MM-dd'), [Validators.required]],
                invoiceUrl: [''],
            });
            this.budgetExpenditureForm.get('cost').setValue(this.budget.totalSpend - this.getTotalSpend());
        }
    }

    addBudgetExpenditure($event: any) {
        if (this.budgetExpenditure) {
            const budgetExpenditure: BudgetExpenditure = {
                uuid: this.budgetExpenditure.uuid,
                expenditure: this.budgetExpenditureForm.get('expenditure').value,
                cost: this.budgetExpenditureForm.get('cost').value,
                date: this.budgetExpenditureForm.get('date').value,
                invoiceUrl: this.budgetExpenditureForm.get('invoiceUrl').value,
            };
            this.subscriptions.add(
                this.store.dispatch(new UpdateBudgetExpenditure(budgetExpenditure)).subscribe(
                    (res) => {
                        this.toastr.success('Expenditure successfully updated');
                        this.activeModal.close();
                    },
                    (error) => {
                        this.logger.error('Failed to update expenditure');
                        this.toastr.error('Oops, something went wrong, Please try again later...');
                    },
                ),
            );
        } else {
            const budgetExpenditure: BudgetExpenditure = {
                uuid: null,
                expenditure: this.budgetExpenditureForm.get('expenditure').value,
                cost: this.budgetExpenditureForm.get('cost').value,
                date: this.budgetExpenditureForm.get('date').value,
                invoiceUrl: this.budgetExpenditureForm.get('invoiceUrl').value,
            };
            this.subscriptions.add(
                this.store.dispatch(new AddBudgetExpenditure(this.budget.uuid, budgetExpenditure)).subscribe(
                    (res) => {
                        this.toastr.success('Expenditure successfully added');
                        this.activeModal.close();
                    },
                    (error) => {
                        this.logger.error('Failed to add expenditure');
                        this.toastr.error('Oops, something went wrong, Please try again later...');
                    },
                ),
            );
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    getTotalSpend(): number {
        let total = 0;
        if (this.budget.budgetExpenditures) {
            this.budget.budgetExpenditures.forEach((b) => (total += b.cost));
        }
        return total + this.budgetExpenditureForm.get('cost').value;
    }
}
