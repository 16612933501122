<app-app-holder>
    <app-navbar></app-navbar>

    <app-sidebar>
        <app-exact-overview></app-exact-overview>
    </app-sidebar>

    <app-content>
        <app-exact-not-logged-in (credentialsIsset)="checkCredentials($event)"></app-exact-not-logged-in>

        <div *ngIf="isset">
            <div class="app__title">
                <h1>Exact - Payments</h1>
            </div>

            <app-searchbar [(value)]="filter"></app-searchbar>

            <div
                class="m-t-4"
                *appIsPermitted="{
                    module: 'EXACT',
                    operator: 'AND',
                    actions: ['READ']
                }">
                <table class="table-view">
                    <tr>
                        <th scope="col">Description</th>
                        <th scope="col">DocumentSubject</th>
                        <th scope="col">Discount</th>
                        <th scope="col">Currency</th>
                        <th scope="col">Status</th>
                    </tr>
                    <tr *ngFor="let invoice of payments$ | async | orderBy: 'Description':false:true | filterBy: ['Description']:filter">
                        <td>
                            {{ invoice.Description }}
                        </td>
                        <td>
                            <span>{{ invoice.AddressLine1 }}</span>
                        </td>
                        <td>
                            <span>{{ invoice.DocumentSubject }}</span>
                        </td>
                        <td>
                            <span class="tag">{{ invoice.Currency }}</span>
                        </td>
                        <td>
                            <span>{{ invoice.Status }}</span>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </app-content>
</app-app-holder>
