import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subscription } from 'rxjs';
import { ContactState } from 'src/app/contacts/contacts.state';
import { ContactPerson } from 'src/app/models/ContactPerson';
import { ModuleEnum } from 'src/app/models/ModuleEnum';
import { Partner } from 'src/app/models/Partner';
import User from 'src/app/models/User';
import { FetchPartners } from 'src/app/partners/partners.actions';
import { PartnerState } from 'src/app/partners/partners.state';
import { ModuleService } from 'src/app/services/module.service';
import { UserState } from 'src/app/users/user.state';

import { ContactpersonDeleteModalComponent } from '../contactperson-delete-modal/contactperson-delete-modal.component';
import { ContactpersonDetailModalComponent } from '../contactperson-detail-modal/contactperson-detail-modal.component';
import { FetchContacts } from '../contacts.actions';

@Component({
    selector: 'app-contactperson-detail',
    templateUrl: './contactperson-detail.component.html',
    styleUrls: ['./contactperson-detail.component.scss'],
})
export class ContactpersonDetailComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Select(PartnerState.getPartners) partners$: Observable<Partner[]>;
    @Select(UserState.userInfo) currentUser$: Observable<User>;
    @Select(ContactState.Contacts) contacts$: Observable<ContactPerson[]>;

    @Input() contactPersonUuid: string;

    contactPerson: ContactPerson;
    user: User;
    partner: Partner;

    isLoading = true;
    eModule = ModuleEnum;

    constructor(
        public activeModal: NgbActiveModal,
        private modalService: NgbModal,
        private store: Store,
        public moduleService: ModuleService,
        private logger: NGXLogger,
    ) {}

    ngOnInit(): void {
        this.store.dispatch(new FetchContacts());
        this.subscriptions.add(
            this.contacts$.subscribe((contacts) => {
                if (contacts != null) {
                    this.contactPerson = contacts.filter((c) => c.uuid == this.contactPersonUuid)[0];
                }
                if (this.contactPerson != null || this.contactPerson != undefined) {
                    this.isLoading = false;
                }
            }),
        );
        this.logger.debug('Attempt to fetch partners');
        this.subscriptions.add(
            this.store.dispatch(new FetchPartners()).subscribe(() => {
                this.partners$.subscribe(
                    (partners) => {
                        this.logger.debug('SuccesFully fetched partners');
                        if (this.contactPerson.partnerUuid) {
                            this.partner = partners.find((p) => p.uuid === this.contactPerson.partnerUuid);
                        }
                    },
                    (error) => this.logger.error('Failed to fetch partners'),
                );
            }),
        );

        this.subscriptions.add(
            this.currentUser$.subscribe((user) => {
                this.user = user;
            }),
        );
    }

    getFullName() {
        return `${this.contactPerson.firstName} ${this.contactPerson.lastName}`;
    }

    openContactPersonUpdateModal() {
        this.logger.debug('Opening contactPersonDetailModal - update');
        const modalRef = this.modalService.open(ContactpersonDetailModalComponent);
        modalRef.componentInstance.contactPersonUuid = this.contactPerson.uuid;
        modalRef.componentInstance.inOverview = false;
    }

    openContactPersonDeleteModal() {
        this.logger.debug('Opening contactPersonDeleteModal');
        const modalRef = this.modalService.open(ContactpersonDeleteModalComponent, { windowClass: 'modal-prompt' });
        modalRef.componentInstance.contactPerson = this.contactPerson;
        this.subscriptions.add(modalRef.componentInstance.closeEvent.subscribe(() => this.activeModal.dismiss()));
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
