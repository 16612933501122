<a
    target="_blank"
    href="https://www.google.com/maps/place/{{ address.street }} {{ address.number }}, {{ address.postal }} {{ address.city }}"
    class="pill pill--address"
    *ngIf="valid">
    <i class="las la-map-marker"></i>
    <span *ngIf="address.number != 0">
        {{ address.street }} {{ address.number }}, {{ address.postal }}
        {{ address.city }}
    </span>
    <span *ngIf="address.bus">{{ address.bus }}</span>
    <span *ngIf="address.ext">{{ address.ext }}</span>
</a>

<a
    class="pill pill--address"
    *ngIf="!valid">
    <i class="las la-map-marker"></i>
    <div>Invalid / no location added...</div>
</a>
