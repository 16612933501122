import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
    MbscCalendarEvent,
    MbscEventClickEvent,
    MbscEventCreatedEvent,
    MbscEventUpdateEvent,
    MbscEventcalendar,
    MbscEventcalendarOptions,
    MbscEventcalendarView,
} from '@mobiscroll/angular';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { addMinutes, differenceInMinutes, format, formatISO, isEqual } from 'date-fns';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';

import { FetchSimpleInformationForAllEmployees } from '../../employees/employees.actions';
import { EmployeeState } from '../../employees/employees.state';
import { Employee } from '../../models/Employee';
import { HolidayDay } from '../../models/HolidayDay';
import PlannedEvent from '../../models/PlannedEvent';
import { FetchGeneralSettings } from '../../settings/settings.actions';
import { SettingsState } from '../../settings/settings.state';
import { ConfirmationModalComponent } from '../../shared/confirmation-modal/confirmation-modal.component';
import { isPermitted } from '../../shared/utils/authorization-utils';
import { AddPlanningModalComponent } from '../add-planning-modal/add-planning-modal.component';
import { PlanningDetailComponent } from '../planning-detail/planning-detail.component';
import { AddPlanning, DeletePlanning, FetchPlanning, UpdatePlanning } from '../planning.actions';
import { PlanningState } from '../planning.state';

@Component({
    selector: 'app-planning-timeline',
    templateUrl: './planning-timeline.component.html',
    styleUrls: ['./planning-timeline.component.scss'],
})
export class PlanningTimelineComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    constructor(private modalService: NgbModal, private store: Store, private logger: NGXLogger, private toastr: ToastrService) {}

    @Select(EmployeeState.EmployeesSimpleInformation) employees$: Observable<Employee[]>;
    @Select(PlanningState.PlannedEvents) plannedEvents$: Observable<PlannedEvent[]>;
    @Select(SettingsState.HolidayDays) holidayDays$: Observable<HolidayDay[]>;

    events = [];
    employees = [];

    today: string = format(new Date(), 'dd-MM-yyyy');

    @ViewChild(MbscEventcalendar) calendar;

    calendarOptions: MbscEventcalendarOptions = {
        clickToCreate: 'double',
        dragToCreate: true,
        dragToMove: true,
        dragToResize: true,
        colors: [
            {
                background: '#EEE',
                recurring: {
                    repeat: 'weekly',
                    weekDays: 'SA,SU',
                },
            },
            {
                background: '#006eff',
                date: new Date(),
            },
        ],
        onEventClick: (args) => {
            if (isPermitted({ module: 'WORKS', operator: 'AND', actions: ['READ'] })) {
                this.eventClick(args);
            }
        },
        onEventCreated: (args) => {
            /* setTimeout so onEventUpdated is called first, Mobiscroll documentation is using this as well
             * https://demo.mobiscroll.com/angular/timeline/create-read-update-delete-CRUD#
             * */
            setTimeout(() => {
                if (isPermitted({ module: 'WORKS', operator: 'AND', actions: ['CREATE'] })) {
                    this.addEvent(args);
                }
            });
        },
        onEventDeleted: (args) => {
            if (isPermitted({ module: 'WORKS', operator: 'AND', actions: ['DELETE'] })) {
                this.deleteEvent(args.event);
            }
        },
        onEventUpdate: (args) => {
            if (isPermitted({ module: 'WORKS', operator: 'AND', actions: ['UPDATE'] })) {
                this.updateEvent(args);
            }
        },
    };
    timelineView: MbscEventcalendarView = {
        timeline: { type: 'month' },
    };

    calendarView: MbscEventcalendarView = {
        calendar: { type: 'year' },
    };

    eventView: MbscEventcalendarView = this.timelineView;

    defaultEvent() {
        return {
            title: 'New planning',
            color: '#b5d2f7',
        };
    }

    isUpdatingRecurring: boolean;
    updateArgs: MbscEventUpdateEvent;

    ngOnInit(): void {
        this.fetchData();
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    public switchViewToCalendar() {
        this.eventView = this.calendarView;
    }

    public switchViewToTimeline() {
        this.eventView = this.timelineView;
    }

    private fetchData() {
        this.subscriptions.add(
            this.employees$.subscribe((employees) => {
                if (!employees) {
                    this.logger.debug('Fetching employees');
                    this.store.dispatch(new FetchSimpleInformationForAllEmployees());
                    return;
                }
                this.employees = PlanningTimelineComponent.addIdToEmployee(employees);
            }),
        );

        this.subscriptions.add(
            this.plannedEvents$.subscribe((plannedEvents) => {
                if (!plannedEvents) {
                    this.logger.debug('Fetching planning');
                    this.store.dispatch(new FetchPlanning());
                    return;
                }
                this.events = this.parseWorkEmployeeLinkToCalendarEvent(plannedEvents);
            }),
        );

        this.subscriptions.add(
            this.holidayDays$.subscribe((holidayDays) => {
                if (!holidayDays) {
                    this.logger.debug('Fetching general settings');
                    this.store.dispatch(new FetchGeneralSettings());
                    return;
                }
                holidayDays.forEach((day) => {
                    this.calendarOptions.colors.push({
                        background: 'rgba(0,110,255,0.1)',
                        date: new Date(day.localDate),
                    });
                });
                // This is necessary to display the loaded data
                this.calendar?.refresh();
            }),
        );
    }

    private static addIdToEmployee(employee) {
        return employee.map((e) => ({
            ...e,
            id: e.uuid,
        }));
    }

    private parseWorkEmployeeLinkToCalendarEvent(plannedEvents: any[]): MbscCalendarEvent[] {
        const events: MbscCalendarEvent[] = [];
        if (plannedEvents) {
            plannedEvents.forEach((event) => {
                const newEvent: MbscCalendarEvent = {
                    start: event.start,
                    end: event.end,
                    resource: event.resource,
                    title: event.projectTitle,
                    color: event.timelineColor,
                    plannedEvent: event,
                    allDay: event.allDay,
                    recurring: event.recurring,
                    recurringException: event.recurringException ?? [],
                };
                events.push(newEvent);
            });
        }
        return events;
    }

    private addEvent(args: MbscEventCreatedEvent): void {
        if (!this.isUpdatingRecurring) {
            const modalRef = this.modalService.open(AddPlanningModalComponent, {
                windowClass: 'modal-pane',
                animation: false,
            });
            modalRef.componentInstance.args = args;
            modalRef.componentInstance.type = 'Add';
            this.subscriptions.add(
                modalRef.dismissed.subscribe(() => {
                    this.events = this.events.filter((event) => event.id !== args.event.id);
                }),
            );
        } else {
            const modalRef = this.modalService.open(ConfirmationModalComponent, {
                windowClass: 'modal-prompt',
                animation: false,
            });
            modalRef.componentInstance.type = 'YesOrNo';
            modalRef.componentInstance.title = 'Update event';
            modalRef.componentInstance.htmlMessage = `<p>Do you want to update all these recurring events for project <strong>${args.event.title}</strong>?</p>`;
            this.subscriptions.add(
                modalRef.componentInstance.closeEvent.subscribe((value) => {
                    if (value) {
                        if (isEqual(args.event.start as Date, this.updateArgs.oldEventOccurrence.start as Date)) {
                            // Dragged back
                            const newEndDate: Date = addMinutes(
                                this.updateArgs.event.plannedEvent.start,
                                Math.abs(differenceInMinutes(args.event.start as Date, args.event.end as Date)),
                            );
                            const recurringException: string[] = PlanningTimelineComponent.formatRecurringExceptions(args);
                            const newPlanning = {
                                workId: args.event.plannedEvent.projectId,
                                employeeId: args.event.resource,
                                startMoment: formatISO(args.event.plannedEvent.start),
                                endMoment: formatISO(newEndDate),
                                pricePerHour: args.event.plannedEvent.pricePerHour,
                                timelineColor: args.event.color,
                                allDay: args.event.allDay,
                                recurring: args.event.plannedEvent.recurring,
                                recurringException: recurringException,
                                uuid: args.event.plannedEvent.uuid,
                            };
                            this.callUpdatePlanning(newPlanning);
                        } else if (isEqual(args.event.end as Date, this.updateArgs.oldEventOccurrence.end as Date)) {
                            // Dragged front
                            const newStartDate: Date = addMinutes(
                                this.updateArgs.event.plannedEvent.end,
                                -Math.abs(differenceInMinutes(args.event.start as Date, args.event.end as Date)),
                            );
                            const recurringException: string[] = PlanningTimelineComponent.formatRecurringExceptions(args);
                            const newPlanning = {
                                workId: args.event.plannedEvent.projectId,
                                employeeId: args.event.resource,
                                startMoment: formatISO(newStartDate),
                                endMoment: formatISO(args.event.plannedEvent.end),
                                pricePerHour: args.event.plannedEvent.pricePerHour,
                                timelineColor: args.event.color,
                                allDay: args.event.allDay,
                                recurring: args.event.plannedEvent.recurring,
                                recurringException: recurringException,
                                uuid: args.event.plannedEvent.uuid,
                            };
                            this.callUpdatePlanning(newPlanning);
                        } else {
                            // Moved
                            const newStartDate: Date = addMinutes(
                                this.updateArgs.event.plannedEvent.start,
                                differenceInMinutes(args.event.start as Date, this.updateArgs.oldEventOccurrence.start as Date),
                            );
                            const newEndDate: Date = addMinutes(
                                this.updateArgs.event.plannedEvent.end,
                                differenceInMinutes(args.event.end as Date, this.updateArgs.oldEventOccurrence.end as Date),
                            );
                            const recurringException: string[] = PlanningTimelineComponent.formatRecurringExceptions(args);

                            const newPlanning = {
                                workId: args.event.plannedEvent.projectId,
                                employeeId: args.event.resource,
                                startMoment: formatISO(newStartDate),
                                endMoment: formatISO(newEndDate),
                                pricePerHour: args.event.plannedEvent.pricePerHour,
                                timelineColor: args.event.color,
                                allDay: args.event.allDay,
                                recurring: args.event.plannedEvent.recurring,
                                recurringException: recurringException,
                                uuid: args.event.plannedEvent.uuid,
                            };
                            this.callUpdatePlanning(newPlanning);
                        }
                    } else {
                        const newPlanning: any = {
                            workId: args.event.plannedEvent.projectId,
                            employeeId: args.event.resource,
                            startMoment: formatISO(args.event.start as Date),
                            endMoment: formatISO(args.event.end as Date),
                            pricePerHour: args.event.plannedEvent.pricePerHour,
                            timelineColor: args.event.color,
                            allDay: args.event.plannedEvent.allDay,
                            recurringException: args.event.recurringException,
                        };
                        this.store.dispatch(new AddPlanning(newPlanning)).subscribe(
                            (res) => {
                                this.toastr.success('Planned event successfully added');
                            },
                            (error) => {
                                this.logger.error('Failed to add planned event');
                                this.toastr.error('Oops, something went wrong, Please try again later...');
                            },
                        );
                        this.updateArgs.event.start = this.updateArgs.oldEvent.start;
                        this.updateNonRecurringEvent(this.updateArgs);
                    }
                    this.isUpdatingRecurring = false;
                }),
            );
            this.subscriptions.add(
                modalRef.dismissed.subscribe(() => {
                    // TODO: restore event -> not with a http call
                    this.store.dispatch(new FetchPlanning());
                    this.isUpdatingRecurring = false;
                }),
            );
        }
    }

    private static formatRecurringExceptions(args: MbscEventCreatedEvent | MbscEventUpdateEvent): string[] {
        const recurringException: string[] = [];
        if (Array.isArray(args.event.recurringException)) {
            for (let i = 0; i < args.event.recurringException.length; i++) {
                if (args.event.recurringException[i] instanceof Date) {
                    recurringException.push(format(args.event.recurringException[i] as Date, 'yyyy-MM-dd'));
                } else {
                    recurringException.push(args.event.recurringException[i] as string);
                }
            }
        }
        return recurringException;
    }

    private updateEvent(args: MbscEventUpdateEvent): void {
        if (args.event.recurring) {
            this.isUpdatingRecurring = true;
            this.updateArgs = args;
        } else {
            this.updateNonRecurringEvent(args);
        }
    }

    private updateNonRecurringEvent(args: MbscEventUpdateEvent) {
        const recurringException: string[] = PlanningTimelineComponent.formatRecurringExceptions(args);

        const newPlanning = {
            workId: args.event.plannedEvent.projectId,
            employeeId: args.event.plannedEvent.resource,
            startMoment: args.event.start instanceof Date ? formatISO(args.event.start) : null,
            endMoment: args.event.end instanceof Date ? formatISO(args.event.end) : null,
            pricePerHour: args.event.plannedEvent.pricePerHour,
            timelineColor: args.event.color,
            allDay: args.event.allDay,
            recurring: args.event.recurring,
            recurringException: recurringException,
            uuid: args.event.plannedEvent.uuid,
        };

        this.callUpdatePlanning(newPlanning);
    }

    private callUpdatePlanning(planning) {
        this.store.dispatch(new UpdatePlanning(planning)).subscribe(
            (res) => {
                this.toastr.success('Planned event successfully updated');
            },
            (error) => {
                this.logger.error('Failed to update planned event');
                this.toastr.error('Oops, something went wrong, Please try again later...');
            },
        );
    }

    private eventClick(args: MbscEventClickEvent) {
        const modalRef = this.modalService.open(PlanningDetailComponent, {
            windowClass: 'modal-pane',
            animation: false,
        });
        modalRef.componentInstance.selectedProjectId = args.event.plannedEvent.projectId;
        modalRef.componentInstance.plannedEvent = args.event.plannedEvent;
        modalRef.componentInstance.employeeId = args.event.resource;
        modalRef.componentInstance.args = args;
    }

    private deleteEvent(event: MbscCalendarEvent): void {
        this.events = this.events.filter((item) => item.id !== event.id);
        const modalRef = this.modalService.open(ConfirmationModalComponent, {
            windowClass: 'modal-prompt',
            animation: false,
        });
        modalRef.componentInstance.type = 'YesOrNo';
        modalRef.componentInstance.title = 'Delete event';
        modalRef.componentInstance.htmlMessage = `<p>Are you sure you want to delete <strong>${event.title}</strong>?</p>`;
        this.subscriptions.add(
            modalRef.componentInstance.closeEvent.subscribe((value) => {
                if (value) {
                    this.logger.debug('Attempt to delete event');
                    this.store.dispatch(new DeletePlanning(event.plannedEvent.uuid));
                } else {
                    this.events = [...this.events, event];
                }
            }),
        );
    }
}
