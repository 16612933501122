<div class="modal-header">
    <h2
        class="modal-title"
        id="exampleModalLabel">
        Delete Work Status
    </h2>
    <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    Are you sure you want to delete this status?
    <div
        *ngIf="errorMessage"
        class="form-error">
        This status is already assigned to a resumé. Please make sure this status is not assigned before removal.
    </div>
</div>

<div class="modal-footer">
    <button
        class="button button--danger"
        (click)="deleteProjectStatus()">
        Delete
    </button>
    <button
        type="button"
        class="button button--outline"
        (click)="activeModal.close()">
        Cancel
    </button>
</div>
