import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { isSameYear } from 'date-fns';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';

import { GeneralSettings } from '../../models/GeneralSettings';
import { HolidayDay } from '../../models/HolidayDay';
import { DateNavigationOptions, DateNavigationType, SelectedDateClass } from '../../shared/date-navigation/date-navigation.component';
import { UpdateHolidayDays } from '../settings.actions';

@Component({
    selector: 'app-update-holiday-days-modal',
    templateUrl: './update-holiday-days-modal.component.html',
    styleUrls: ['./update-holiday-days-modal.component.scss'],
})
export class UpdateHolidayDaysModalComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Input() holidayDays: HolidayDay[];

    holidayDaysForm: FormGroup;

    selectedDate: Date = new Date();
    dateNavigationOptions: DateNavigationOptions = new DateNavigationOptions();

    displayedColumns: string[] = ['date', 'description', 'delete'];
    dataSource: MatTableDataSource<HolidayDay> = new MatTableDataSource<HolidayDay>();

    constructor(public activeModal: NgbActiveModal, private store: Store, private toastr: ToastrService, private logger: NGXLogger, private fb: FormBuilder) {}

    ngOnInit(): void {
        this.createNavigationOptions();
        this.createMatTable();
        this.createDayForm();
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    submit(): void {
        if (this.holidayDaysForm.valid) {
            this.addHoliday();
        }
        const settings: GeneralSettings = {
            holidayDays: this.holidayDays,
        };
        this.subscriptions.add(
            this.store.dispatch(new UpdateHolidayDays(settings)).subscribe(
                (res) => {
                    this.toastr.success('Holiday days successfully updated');
                    this.activeModal.close();
                },
                (error) => {
                    this.logger.error('Failed to update holiday days');
                    this.toastr.error('Oops, something went wrong, Please try again later...');
                },
            ),
        );
    }

    deleteHoliday(i: number) {
        this.holidayDays.splice(i, 1);
        this.filterTable();
    }

    addHoliday() {
        const holidayDay: HolidayDay = {
            localDate: new Date(this.holidayDaysForm.value.date),
            description: this.holidayDaysForm.value.description,
        };
        this.holidayDays.push(holidayDay);
        this.holidayDays.sort((day1, day2) => day1.localDate.getTime() - day2.localDate.getTime());
        this.dataSource.data = this.holidayDays;
        this.filterTable();
        this.holidayDaysForm.reset();
    }

    private createDayForm() {
        this.holidayDaysForm = this.fb.group({
            date: ['', [Validators.required]],
            description: [''],
        });
    }

    private createMatTable() {
        this.dataSource.data = this.holidayDays;
        this.dataSource.filterPredicate = function (holidayDay, filter) {
            return isSameYear(new Date(filter), new Date(holidayDay.localDate));
        };
        this.filterTable();
    }

    private createNavigationOptions() {
        this.dateNavigationOptions.selectedDate = this.selectedDate;
        this.dateNavigationOptions.dateNavigationType = DateNavigationType.YEAR;
        this.dateNavigationOptions.selectedDateClass = SelectedDateClass.SMALL;
    }

    filterTable() {
        this.dataSource.filter = this.dateNavigationOptions.selectedDate.toString();
    }
}
