import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbDropdownModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { TagInputModule } from 'ngx-chips';
import { NgxTrimDirectiveModule } from 'ngx-trim-directive';

import { SharedModule } from '../shared/shared.module';
import { DetailActivityComponent } from './detail-activity/detail-activity.component';
import { DetailContactpersonsComponent } from './detail-contactpersons/detail-contactpersons.component';
import { DetailCvsComponent } from './detail-cvs/detail-cvs.component';
import { DetailProjectsComponent } from './detail-projects/detail-projects.component';
import { DetailSettingsComponent } from './detail-settings/detail-settings.component';
import { routing } from './partnerdetails.routing';
import { PartnersModule } from './partners.module';

@NgModule({
    declarations: [DetailContactpersonsComponent, DetailActivityComponent, DetailSettingsComponent, DetailCvsComponent, DetailProjectsComponent],
    imports: [
        CommonModule,
        SharedModule,
        PartnersModule,
        AutocompleteLibModule,
        routing,
        ReactiveFormsModule,
        NgbModule,
        NgbDropdownModule,
        NgxTrimDirectiveModule,
        TagInputModule,
    ],
    exports: [DetailContactpersonsComponent, DetailActivityComponent, DetailSettingsComponent, DetailCvsComponent, DetailProjectsComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PartnerDetailsModule {}
