import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import User from 'src/app/models/User';

import { DisableMfaAuthentication } from '../user.actions';

@Component({
    selector: 'app-user-manage-two-factor-delete-modal',
    templateUrl: './user-manage-two-factor-delete-modal.component.html',
    styleUrls: ['./user-manage-two-factor-delete-modal.component.scss'],
})
export class UserManageTwoFactorDeleteModalComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    password: string;
    authenticationError: string;

    @Input() user: User;

    constructor(public activeModal: NgbActiveModal, private store: Store, private logger: NGXLogger, private toastr: ToastrService) {}

    ngOnInit(): void {}

    disable2FA() {
        this.logger.debug('Attempt to disable 2FA for ' + this.user.firstName);
        this.subscriptions.add(
            this.store.dispatch(new DisableMfaAuthentication(this.password)).subscribe(
                () => {
                    this.logger.debug('Successfully disabled MFA');
                    this.toastr.success('MFA is disabled for ' + this.user.firstName);
                    this.activeModal.close();
                },
                (error) => {
                    this.authenticationError = 'Your password is not correct... Please try again';
                    this.logger.error('Failed to disable MFA');
                },
            ),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
