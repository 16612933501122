<div class="dashboard__tile__title">
    <span>Product Stock Update History</span>
    <button
        type="button"
        class="button button--icon button--small"
        (click)="goToStockUpdateHistory()">
        <i class="uil uil-arrow-right"></i>
    </button>
</div>

<div class="dashboard__tile__content">
    <div class="table__overflow">
        <table
            mat-table
            [dataSource]="dataSource"
            matSort
            [hidden]="!(this.dataSource?.data?.length > 0)"
            matSortActive="lastModified"
            matSortDirection="desc">
            <ng-container matColumnDef="updatedProductTitle">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header>
                    Product title
                </th>
                <td
                    mat-cell
                    *matCellDef="let row">
                    <app-pill-product
                        *ngIf="row.updatedProductUuid"
                        [productUuid]="row.updatedProductUuid"
                        [productTitle]="row.updatedProductTitle"></app-pill-product>
                </td>
            </ng-container>

            <ng-container matColumnDef="productAmountState">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header>
                    Stock changed
                </th>
                <td
                    mat-cell
                    *matCellDef="let row">
                    <span>{{ row.productAmountState | lowercase }} by {{ row.amount }}</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="currentStock">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header>
                    New amount
                </th>
                <td
                    mat-cell
                    *matCellDef="let row">
                    <span>{{ row.currentStock }}</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="updatedBy">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header>
                    Updated by
                </th>
                <td
                    mat-cell
                    *matCellDef="let row">
                    <app-pill-user [userId]="row.updatedByUserUuid"></app-pill-user>
                </td>
            </ng-container>

            <ng-container matColumnDef="lastModified">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header>
                    Updated
                </th>
                <td
                    mat-cell
                    *matCellDef="let row">
                    <span>{{ row.lastModified | amTimeAgo }}</span>
                </td>
            </ng-container>

            <tr
                mat-header-row
                *matHeaderRowDef="displayedColumns"></tr>
            <tr
                mat-row
                *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
    </div>
    <div
        class="dashboard__tile__content"
        *ngIf="this.noHistory && !this.isLoading">
        <app-message-empty
            [title]="'No recent stock updates'"
            [icon]="'../../assets/img/open-box.png'"></app-message-empty>
    </div>

    <div *ngIf="isLoading">
        <app-loading-message></app-loading-message>
    </div>
</div>
