<div
    [ngClass]="embeded ? '' : 'modal-wrapper'"
    *ngIf="(allProducts$ | async)?.length != 0">
    <div
        *ngIf="!embeded"
        class="modal-header">
        <h2
            *ngIf="isChild"
            class="modal-title"
            id="exampleModalLabel">
            Link parent product
        </h2>
        <h2
            *ngIf="!isChild"
            class="modal-title"
            id="exampleModalLabel">
            Link child product
        </h2>
        <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="activeModal.close()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div [ngClass]="embeded ? '' : 'modal-body'">
        <div class="form-group m-b-2">
            <label>Choose product</label>
            <div class="d-flex">
                <div class="ng-autocomplete">
                    <ng-autocomplete
                        [(ngModel)]="selectedProduct"
                        [data]="actualProducts"
                        [searchKeyword]="keyword"
                        (selected)="selectEvent($event)"
                        [itemTemplate]="itemTemplate"
                        [notFoundTemplate]="notFoundTemplate"
                        placeholder="Choose product"
                        autocomplete="off"
                        [disabled]="isUpdate">
                    </ng-autocomplete>

                    <ng-template
                        #itemTemplate
                        let-item>
                        <a [innerHTML]="item.title"></a>
                    </ng-template>

                    <ng-template
                        #notFoundTemplate
                        let-notFound>
                        <div (click)="openCreateProductModal()">+ Add new product</div>
                    </ng-template>
                </div>
                <button
                    class="button button--outline button--icon m-l-2"
                    (click)="openCreateProductModal()">
                    <i class="las la-plus"></i>
                </button>
            </div>
        </div>

        <div class="d-flex m-b-2">
            <div class="form-group w-50 m-r-2">
                <label>Amount needed</label>
                <input
                    type="number"
                    placeholder="Amount"
                    [(ngModel)]="amount"
                    #focussed />
            </div>
            <div class="form-group w-50">
                <label>Unittype</label>
                <input
                    type="text"
                    value="Unittype"
                    disabled
                    [(ngModel)]="unitType" />
            </div>
        </div>
    </div>

    <div [ngClass]="embeded ? '' : 'modal-footer'">
        <span
            *ngIf="isChild"
            type="button"
            class="button button--primary w-100"
            (click)="add()"
            >Link parent product</span
        >
        <span
            *ngIf="!isChild"
            type="button"
            class="button button--primary w-100"
            (click)="add()"
            >Link child product</span
        >
    </div>
</div>
