<table class="table-view">
    <ng-container *ngIf="experiences">
        <tr *ngFor="let experience of experiences">
            <td>
                <div class="resume-v2__experience">
                    <section class="resume-v2__experience-info-container resume-v2__experience-container">
                        <div class="resume-v2__experience-info">
                            <p class="resume-v2__experience-job-title">{{experience.jobTitle}}</p>
                            <div>
                                <u>{{experience.organization}}</u> - {{experience.startDate | date: 'y'}} >
                                <ng-container
                                    *ngIf="!experience.present">{{experience.endDate | date:'y'}}</ng-container>
                                <ng-container *ngIf="experience.present">present</ng-container>
                            </div>
                        </div>
                        <div
                            *ngIf="showActions"
                            class="dropdown"
                            ngbDropdown>
                            <button
                                class="button button--icon button--small dropdown-toggle"
                                id="dropdownMenuButton"
                                ngbDropdownToggle
                                type="button"></button>
                            <div
                                aria-labelledby="dropdownMenuButton"
                                class="dropdown-menu dropdown-menu-right"
                                ngbDropdownMenu>
                                <a
                                    (click)="openUpdateExperienceModal(experience)"
                                    class="dropdown-item"
                                >Edit experience</a
                                >
                                <a
                                    (click)="openDeleteExperienceModal(experience)"
                                    class="dropdown-item color--red"
                                >Delete</a
                                >
                            </div>
                        </div>
                    </section>

                    <section *ngIf="experience.description" class="resume-v2__experience-container">
                        <p>{{experience.description}}</p>
                    </section>

                    <section *ngIf="experience.tags.length > 0" class="resume-v2__experience-container">
                        <div *ngFor="let skill of experience.tags" class="resume-v2__experience-skill tag">
                            {{skill}}
                        </div>
                    </section>
                </div>
            </td>
        </tr>
    </ng-container>
    <tr *ngIf="experiences?.length === 0">
        <td colspan="3">
            No experience added yet...
        </td>
    </tr>
    <tr>
        <td (click)="openExperienceAddModal()" class="table-view__add" colspan="3">
            <i class="las la-plus"></i>
            Add experience
        </td>
    </tr>
</table>
