export class MONTHS {
    static readonly JANUARY = new MONTHS('JAN', 'january', 1);
    static readonly FEBRUARY = new MONTHS('FEB', 'february', 2);
    static readonly MARCH = new MONTHS('MAR', 'march', 3);
    static readonly APRIL = new MONTHS('APR', 'april', 4);
    static readonly MAY = new MONTHS('MAY', 'may', 5);
    static readonly JUNE = new MONTHS('JUN', 'june', 6);
    static readonly JULY = new MONTHS('JUL', 'july', 7);
    static readonly AUGUST = new MONTHS('AUG', 'august', 8);
    static readonly SEPTEMBER = new MONTHS('SEP', 'september', 9);
    static readonly OCTOBER = new MONTHS('OCT', 'october', 10);
    static readonly NOVEMBER = new MONTHS('NOV', 'november', 11);
    static readonly DECEMBER = new MONTHS('DEC', 'december', 12);

    private constructor(public readonly short: string, public readonly long: string, public readonly number: number) {}
}
