export class FetchIncomeForEmployee {
    static readonly type = '[Income] Fetch income for employee';

    constructor(public employeeId: string, public year: number = new Date().getFullYear()) {}
}

export class FetchCostsForEmployee {
    static readonly type = '[Income] Fetch costs for employee';

    constructor(public employeeId: string, public year: number = new Date().getFullYear()) {}
}

export class FetchIncomeForProject {
    static readonly type = '[Income] Fetch income for project';

    constructor(public projectId: string, public year: number = new Date().getFullYear()) {}
}

export class FetchCostsForProject {
    static readonly type = '[Income] Fetch costs for project';

    constructor(public projectId: string, public year: number = new Date().getFullYear()) {}
}

// This action will be called from remove-financial-data.interceptor when necessary
export class ResetFinancialData {
    static readonly type = '[Financial] Reset financial data';
}

export class FetchFinancialDataForOverheadCategories {
    static readonly type = '[Financial Data] Fetch financial data for overhead categories';

    constructor(public year: number = new Date().getFullYear()) {}
}

export class GetAllFinancialInformationForEmployee {
    static readonly type = '[Financial Information] Get all financial information for employee';

    constructor(public employeeId: string, public month = new Date().getMonth(), public year: number = new Date().getFullYear()) {}
}

export class GetAllFinancialInformationForProject {
    static readonly type = '[Financial Information] Get all financial information for project';

    constructor(public projectId: string, public month = new Date().getMonth(), public year: number = new Date().getFullYear()) {}
}

export class GetAllFinancialInformationForCategory {
    static readonly type = '[Financial Information] Get all financial information for category';

    constructor(public categoryId: string, public month = new Date().getMonth(), public year: number = new Date().getFullYear()) {}
}

export class FetchFinancialDataForOverheadCategoryByCategoryId {
    static readonly type = '[Financial Data] Fetch financial data for overhead category by category id';

    constructor(public categoryId: string, public year: number = new Date().getFullYear()) {}
}

export class GetBillableInformationForEmployees {
    static readonly type = '[Billable Information] Get billable information for employees';

    constructor(public year: number = new Date().getFullYear()) {}
}

export class GetBillableInformationForEmployeeByEmployeeId {
    static readonly type = '[Billable Information] Get billable information for employee by employee id';

    constructor(public employeeId: string, public year: number = new Date().getFullYear()) {}
}

export class GetHappinessScoreInformationForEmployees {
    static readonly type = '[HappinessScore Information] Get happiness score information for employees';
    constructor(public year: number = new Date().getFullYear()) {}
}

export class GetHappinessScoreInformationForEmployeeByEmployeeId {
    static readonly type = '[HappinessScore Information] Get happiness score information for employee by employee id';
    constructor(public employeeId: string, public year: number = new Date().getFullYear()) {}
}

export class GetEmployeesFinancialData {
    static readonly type = '[ObjectWithFinancialData] Get financial data for employees';
    constructor(public year: number) {}
}

export class GetProjectsFinancialData {
    static readonly type = '[ObjectWithFinancialData] Get financial data for projects';
    constructor(public year: number) {}
}

export class GetOverheadCategoriesFinancialOverviewData {
    static readonly type = '[ObjectWithFinancialData] Get financial data for overhead categories';
    constructor(public year: number) {}
}

export class GetTurnoverData {
    static readonly type = '[TurnoverData] Get Turnover data';
    constructor(public year: number) {}
}
