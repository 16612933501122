import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { ChartDataset, ChartOptions, ChartType } from 'chart.js';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subscription } from 'rxjs';
import { Product } from 'src/app/models/Product';
import { FetchProducts } from 'src/app/products/product.actions';
import { ProductState } from 'src/app/products/product.state';

@Component({
    selector: 'app-dashboard-low-stock-product-widget',
    templateUrl: './dashboard-low-stock-product-widget.component.html',
    styleUrls: ['./dashboard-low-stock-product-widget.component.scss'],
})
export class DashboardLowStockProductWidgetComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Select(ProductState.Products) products$: Observable<Product[]>;

    isLoading = true;
    noLowStock = false;

    xArray: string[] = [];
    yArray: number[] = [];

    public barChartOptions: ChartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            y: {
                max: 100,
            },
        },
        plugins: {
            datalabels: {
                anchor: 'end',
                align: 'end',
            },
        },
    };

    public barChartLabels: string[] = this.xArray;
    public barChartType: ChartType = 'bar';
    public barChartLegend = false;
    public barChartPlugins = [pluginDataLabels];
    public barChartData: ChartDataset[] = [
        {
            data: this.yArray,
            borderColor: '#006EFF',
            borderWidth: 1,
            backgroundColor: 'rgba(0,110,255,.25)',
            hoverBackgroundColor: 'rgba(0,110,255,.75)',
            hoverBorderColor: 'rgba(0,110,255,1)',
            label: 'In stock',
        },
    ];

    constructor(private router: Router, private logger: NGXLogger, private store: Store) {}

    ngOnInit(): void {
        this.subscriptions.add(
            this.products$.subscribe({
                next: (products) => {
                    let filteredProducts = null;
                    if (products) {
                        filteredProducts = products.filter((mat) => {
                            if (mat.stockNotification >= mat.inStock) {
                                return mat;
                            }
                        });
                    } else {
                        this.store.dispatch(new FetchProducts());
                    }

                    if (filteredProducts && filteredProducts.length == 0) {
                        this.logger.debug('Retrieved 0 products from the state');
                        this.isLoading = false;
                        this.noLowStock = true;
                    } else if (filteredProducts && filteredProducts.length > 0) {
                        this.logger.debug('Retrieved products, filtering with low stock');
                        this.noLowStock = false;
                        filteredProducts.forEach((product) => {
                            if (this.xArray.indexOf(product.title) === -1 && product.inStock <= product.stockNotification) {
                                this.xArray.push(
                                    product.title + '\n' + Math.round(100 - (product.inStock / product.stockNotification) * 100) + '% below threshhold \n ',
                                );
                                this.yArray.push((product.inStock / product.stockNotification) * 100);
                            }
                        });
                        this.isLoading = false;
                    }
                },
                error: () => {
                    this.logger.error('Failed to retrieve low stock products');
                },
            }),
        );
    }

    goToInventory() {
        this.logger.debug('Navigating to inventory products');
        this.router.navigate(['/inventory', 'products']);
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
