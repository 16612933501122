import { Component, Input, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { BudgetState } from 'src/app/budget/budget.state';

import { BudgetDetailModalComponent } from '../../../budget/budget-detail-modal/budget-detail-modal.component';
import Budget from '../../../models/Budget';

@Component({
    selector: 'app-pill-budget',
    templateUrl: './pill-budget.component.html',
    styleUrls: ['./pill-budget.component.scss'],
})
export class PillBudgetComponent implements OnInit, OnDestroy {
    @Input() budget: Budget;
    @Input() budgetUuid: string;
    @Select(BudgetState.Budgets) budgets$: Observable<Budget[]>;
    budgets: any = [];
    attentionLevel: number;
    totalSpend: number;
    actualCost: number;
    checkAttentionLevel = Budget.checkAttentionLevel;
    getTotalSpend = Budget.getTotalSpend;
    private subscriptions = new Subscription();

    constructor(private modalService: NgbModal, private store: Store) {}

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.budgetUuid) {
            this.budget = this.budgets.find((b) => b.uuid == this.budgetUuid);
        }
    }

    ngOnInit(): void {
        if (this.budgetUuid) {
            this.subscriptions.add(
                this.budgets$.subscribe((budgets) => {
                    this.budgets = budgets;
                }),
            );
            if (this.budgets && this.budgets.length > 0) {
                this.budgets.forEach((budget) => {
                    if (!this.budget) {
                        this.budget = budget.budgets.find((b) => b.uuid == this.budgetUuid);
                    }
                });
            }
        }
        this.attentionLevel = this.checkAttentionLevel(this.budget);
        this.totalSpend = this.getTotalSpend(this.budget);
        this.actualCost = this.budget.budgetExpenditures?.reduce((acc, exp) => acc + exp.cost, 0);
    }

    openBudgetDetailModel() {
        const modalRef = this.modalService.open(BudgetDetailModalComponent, {
            windowClass: 'modal-pane',
            animation: false,
        });
        modalRef.componentInstance.budgetId = this.budget.uuid;
    }
}
