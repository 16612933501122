<app-app-holder>
    <app-navbar></app-navbar>
    <app-content>
        <div class="app__title">
            <h1>
                {{ selectedDate$ | async | date: 'MMMM yyyy' }}
                <span *ngIf="timesheets$ | async as timesheets">({{ timesheets.length }} timesheets)</span>
            </h1>
            <span>
                <button class="button button--primary" id="spacer" (click)="importFromJiraForSelectedMonth()">
                    Import all Tempo timesheets for {{ selectedDate$ | async | date: 'MMMM yyyy' }}
                </button>
            </span>
            <span>
                <button mat-icon-button class="button button--icon m-r-2" (click)="refresh()">
                    <i class="las la-sync"></i>
                </button>
            </span>
            <span>
                <button class="button button--icon m-r-1" (click)="moveDate(-1)">
                    <i class="las la-arrow-left"></i>
                </button>
                <button class="button m-r-1" (click)="moveDate(0)">
                    Last month
                </button>
                <button class="button button--icon" (click)="moveDate(1)">
                    <i class="las la-arrow-right"></i>
                </button>
            </span>
        </div>

        <div *ngIf="!isLoading">
            <span *appIsPermitted="{
                    module: 'TIMESHEETS',
                    operator: 'AND',
                    actions: ['READ']
                }">
                <nav #nav="ngbNav" ngbNav>
                    <ng-container ngbNavItem>
                        <a ngbNavLink (click)="byEmployeeSelected()">By employee</a>
                    </ng-container>
                    <ng-container ngbNavItem>
                        <a ngbNavLink (click)="byProjectSelected()">By project</a>
                    </ng-container>
                </nav>
                <div *ngIf="timesheets$ | async as timesheets; else loading">
                    <div *ngIf="timesheets && timesheets.length > 0; else empty">
                        <app-timesheets-list *ngIf="sortedByEmployee">
                        </app-timesheets-list>
                        <app-timesheets-project-list *ngIf="sortedByProject">
                        </app-timesheets-project-list>
                    </div>
                </div>
            </span>

        </div>
        <app-loading-message *ngIf="isLoading"></app-loading-message>
    </app-content>

    <ng-template #empty>
        <app-message-empty [title]="'No Timesheets...'"
            [subtitle]="'Timesheets let you register you employees\'s working hours'"
            [icon]="'../../assets/img/open-box.png'">
            <a (click)="addNewTimesheet()" class="button button--primary" *appIsPermitted="{
                    module: 'TIMESHEETS',
                    operator: 'AND',
                    actions: ['CREATE']
                }">Add a Timesheet</a>
        </app-message-empty>
    </ng-template>
</app-app-holder>