<div class="d-flex space-between align-items-end">
    <div class="d-flex m-r-2">
        <div class="form-group m-r-2">
            <label for="startDate">Start date</label>
            <input
                (change)="executeFilter()"
                [(ngModel)]="startDate"
                class="input--bg-transparant"
                id="startDate"
                type="date" />
        </div>

        <div class="form-group">
            <label for="endDate">End date</label>
            <input
                (change)="executeFilter()"
                [(ngModel)]="endDate"
                class="input--bg-transparant"
                id="endDate"
                type="date" />
        </div>
    </div>

    <div class="w-100 m-r-2">
        <app-searchbar
            (valueChange)="applyFilter($event)"
            [(value)]="filter"></app-searchbar>
    </div>

    <button
        (click)="$event.stopPropagation(); applyFilterPaid()"
        *ngIf="currentFolder?.id === 2"
        [ngClass]="{ 'filter--checked': !paidChecked }"
        [ngbTooltip]="paidFilterTip"
        class="button button--icon m-r-2"
        title="paid">
        <span [ngClass]="{ 'color--red': !paidChecked }">€</span>
    </button>

    <button
        (click)="$event.stopPropagation(); applyFilterVerified()"
        [ngClass]="{ 'filter--checked': !verifiedChecked }"
        [ngbTooltip]="verifiedFilterTip"
        class="button button--icon m-r-2"
        title="verified">
        <ng-container *ngIf="verifiedChecked; else notVerifiedCheckedButton"><i class="lar la-check-circle"></i> </ng-container>
        <ng-template #notVerifiedCheckedButton><i class="las la-times-circle"></i></ng-template>
    </button>

    <button
        (click)="$event.stopPropagation(); applyFilterFlagged()"
        [ngClass]="{ 'filter--checked': flaggedChecked }"
        [ngbTooltip]="flaggedTip"
        class="button button--icon m-r-2"
        title="flagged">
        <svg
            *ngIf="flaggedChecked; else notFlagged"
            class="flag-icon flag-icon--filled"
            height="24"
            viewBox="0 0 24 24"
            width="24"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M4 24h-2v-24h2v24zm18-21.387s-1.621 1.43-3.754 1.43c-3.36 0-3.436-2.895-7.337-2.895-2.108 0-4.075.98-4.909 1.694v12.085c1.184-.819 2.979-1.681 4.923-1.681 3.684 0 4.201 2.754 7.484 2.754 2.122 0 3.593-1.359 3.593-1.359v-12.028z" />
        </svg>
        <ng-template #notFlagged>
            <svg
                class="flag-icon"
                height="24"
                viewBox="0 0 24 24"
                width="24"
                xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M17.246 4.042c-3.36 0-3.436-2.895-7.337-2.895-2.108 0-4.075.98-4.909 1.694v-2.841h-2v24h2v-9.073c1.184-.819 2.979-1.681 4.923-1.681 3.684 0 4.201 2.754 7.484 2.754 2.122 0 3.593-1.359 3.593-1.359v-12.028s-1.621 1.429-3.754 1.429zm1.754 9.544c-.4.207-.959.414-1.593.414-.972 0-1.498-.363-2.371-.964-1.096-.755-2.596-1.79-5.113-1.79-1.979 0-3.71.679-4.923 1.339v-7.488c1.019-.902 2.865-1.949 4.909-1.949 1.333 0 1.894.439 2.741 1.103.966.756 2.288 1.792 4.596 1.792.627 0 1.215-.086 1.754-.223v7.766z" />
            </svg>
        </ng-template>
    </button>

    <button
        (click)="$event.stopPropagation(); applyFilterVisible()"
        [ngClass]="{ 'filter--checked': visibleChecked }"
        [ngbTooltip]="visibleTip"
        class="button button--icon m-r-2">
        <i
            *ngIf="visibleChecked; else notVisible"
            class="las la-eye"></i>
        <ng-template #notVisible>
            <i class="las la-eye-slash"></i>
        </ng-template>
    </button>

    <div class="d-flex nowrap">
        <span
            class="dropdown"
            ngbDropdown>
            <button
                class="button button--select"
                ngbDropdownToggle>
                {{ filterLabel }}
            </button>
            <span
                class="dropdown-menu-right"
                ngbDropdownMenu>
                <form>
                    <div>
                        <label ngbDropdownItem>
                            <input
                                (change)="applyFilterLinked($event)"
                                [title]="defaultFilterLabel"
                                class="rdbInvisible"
                                id="Filter"
                                name="linkedRadio"
                                type="radio" />
                            All
                        </label>
                    </div>
                    <div>
                        <label ngbDropdownItem>
                            <input
                                (change)="applyFilterLinked($event)"
                                class="rdbInvisible"
                                id="notLinked"
                                name="linkedRadio"
                                title="Not linked"
                                type="radio" />
                            <i class="uil uil-link color--red"></i>
                            Not linked
                        </label>
                    </div>
                    <div>
                        <label ngbDropdownItem>
                            <input
                                (change)="applyFilterLinked($event)"
                                class="rdbInvisible"
                                id="partialyLinked"
                                name="linkedRadio"
                                title="Partially linked"
                                type="radio" />
                            <i class="uil uil-link color--yellow"></i>
                            Partially linked
                        </label>
                    </div>
                    <div>
                        <label ngbDropdownItem>
                            <input
                                (change)="applyFilterLinked($event)"
                                class="rdbInvisible"
                                id="fullLinked"
                                name="linkedRadio"
                                title="Full linked"
                                type="radio" />
                            <i class="uil uil-link color--green"></i>
                            Full linked
                        </label>
                    </div>
                    <div>
                        <label ngbDropdownItem>
                            <input
                                (change)="applyFilterLinked($event)"
                                class="rdbInvisible"
                                id="overLinked"
                                name="linkedRadio"
                                title="Over linked"
                                type="radio" />
                            <i class="uil uil-link-broken color--red"></i>
                            Over linked
                        </label>
                    </div>
                </form>
            </span>
        </span>
    </div>
    <div class="d-flex nowrap m-l-2">
        <app-global-export-csv
            *ngIf="currentFolder$ | async"
            [componentName]="this.constructor.name"
            [exporter]="exporter">
        </app-global-export-csv>
    </div>
</div>

<div class="m-t-4">
    <mat-paginator
        [pageSizeOptions]="[20, 50, 100]"
        aria-label="Select page of Yuki documents"
        class="table__paginator table__paginator--top"
        showFirstLastButtons>
    </mat-paginator>

    <table
        #exporter="matTableExporter"
        [dataSource]="dataSource"
        mat-table
        matSort
        matTableExporter>
        <ng-container matColumnDef="verified">
            <th
                *matHeaderCellDef
                class="td-small"
                mat-header-cell></th>
            <td
                *matCellDef="let row"
                class="td-icon"
                mat-cell>
                <div class="icon__wrapper d-flex">
                    <i
                        *ngIf="row.verified"
                        [ngbTooltip]="verifiedTip"
                        class="lar la-check-circle color--green"></i>
                </div>
            </td>
            <td
                *matFooterCellDef
                mat-footer-cell></td>
        </ng-container>

        <ng-container matColumnDef="invoiceNumber">
            <th
                *matHeaderCellDef
                mat-header-cell
                mat-sort-header>
                Invoice number
            </th>
            <td
                *matCellDef="let row"
                mat-cell>
                {{ row.invoiceNumber }}
            </td>
            <td
                *matFooterCellDef
                mat-footer-cell>
                <strong>Total:</strong>
            </td>
        </ng-container>

        <ng-container matColumnDef="subject">
            <th
                *matHeaderCellDef
                mat-header-cell
                mat-sort-header>
                Document
            </th>
            <td
                *matCellDef="let row"
                mat-cell>
                {{ row.subject?.length > 50 ? (row.subject | slice: 0:50) + '...' : row.subject }}
            </td>
            <td
                *matFooterCellDef
                mat-footer-cell>
                <strong *ngIf="currentFolder?.id !== 2">Total:</strong>
            </td>
        </ng-container>

        <ng-container matColumnDef="amount">
            <th
                *matHeaderCellDef
                mat-header-cell
                mat-sort-header>
                Amount
            </th>
            <td
                *matCellDef="let row"
                mat-cell>
                <div class="d-flex align-items-center justify-content-center">
                    <div>
                        <p>{{ row.amountExcl | currency: 'EUR' }} excl.</p>
                        <p class="form-helptext form-helptext--no-padding">
                            {{ row.amount | currency: 'EUR' }} incl. -
                            <span *ngIf="row.vat.value !== 'OTHER'; else otherVat">
                                {{ row.vat.percent / 100 | percent }}
                            </span>
                            <ng-template #otherVat>Other percentage</ng-template>
                        </p>
                    </div>
                    <div
                        *ngIf="row.folderId === 2 && !row.paid"
                        class="badge badge--danger h-fit m-l-4">
                        unpaid
                    </div>
                </div>
            </td>
            <td
                *matFooterCellDef
                mat-footer-cell>
                {{ totalAmount | currency: 'EUR' }}
            </td>
        </ng-container>

        <ng-container matColumnDef="contactName">
            <th
                *matHeaderCellDef
                mat-header-cell
                mat-sort-header>
                Contact name
            </th>
            <td
                *matCellDef="let row"
                mat-cell>
                {{ row.contactName }}
            </td>
            <td
                *matFooterCellDef
                mat-footer-cell></td>
        </ng-container>

        <ng-container matColumnDef="documentDate">
            <th
                *matHeaderCellDef
                mat-header-cell
                mat-sort-header>
                Date
            </th>
            <td
                *matCellDef="let row"
                mat-cell>
                {{ row.documentDate | date }}
            </td>
            <td
                *matFooterCellDef
                mat-footer-cell></td>
        </ng-container>

        <ng-container matColumnDef="sublinks">
            <th
                *matHeaderCellDef
                mat-header-cell>
                Links
            </th>
            <td
                *matCellDef="let row"
                mat-cell>
                <div
                    [ngbPopover]="popContent"
                    class="d-flex"
                    triggers="mouseenter:mouseleave">
                    <div class="d-flex align-items-center m-r-4 icon__wrapper">
                        <i
                            *ngIf="roundedAmount(row.amountExcl) === roundedAmount(row.totalAmount)"
                            class="uil uil-link color--green"></i>
                        <i
                            *ngIf="
                                roundedAmount(row.amountExcl) !== roundedAmount(row.totalAmount) &&
                                roundedAmount(row.totalAmount) < roundedAmount(row.amountExcl) &&
                                roundedAmount(row.totalAmount) !== 0
                            "
                            class="uil uil-link color--yellow"></i>
                        <i
                            *ngIf="roundedAmount(row.totalAmount) === 0"
                            class="uil uil-link color--red"></i>
                        <i
                            *ngIf="roundedAmount(row.totalAmount) > roundedAmount(row.amountExcl)"
                            class="uil uil-link-broken color--red"></i>
                    </div>
                    <div>
                        <p>{{ getNumberOfLinksByFile(row.uuid) }} Link(s)</p>
                        <p
                            [ngClass]="{
                                'sublink--fully': isFullySublinked(row.uuid),
                                'sublink--partially': isPartiallySublinked(row.uuid),
                                'sublink--over': isOverSublinked(row.uuid)
                            }"
                            class="form-helptext form-helptext--no-padding">
                            {{ getNumberOfSublinksByFile(row.uuid) }} Sublink(s)
                        </p>
                    </div>
                </div>

                <ng-template #popContent>
                    <table
                        *ngIf="getLinksByFile(row.uuid)?.linkedObjects?.length > 0; else nolinks"
                        class="table-view">
                        <tr>
                            <th>File name</th>
                            <th>Amount</th>
                        </tr>

                        <ng-container *ngFor="let link of getLinksByFile(row.uuid)?.linkedObjects">
                            <tr>
                                <td>
                                    <app-pill-employee
                                        *ngIf="link.objectType.toLowerCase() === 'employee'"
                                        [employeeId]="link.objectId">
                                    </app-pill-employee>
                                    <app-pill-project
                                        *ngIf="link.objectType.toLowerCase() === 'project'"
                                        [projectUuid]="link.objectId">
                                    </app-pill-project>
                                    <app-pill-budget
                                        *ngIf="link.objectType.toLowerCase() === 'budget'"
                                        [budgetUuid]="link.objectId">
                                    </app-pill-budget>
                                    <app-pill-overhead-category
                                        *ngIf="link.objectType.toLowerCase() === 'overheadcategory'"
                                        [overheadCategoryId]="link.objectId">
                                    </app-pill-overhead-category>
                                </td>
                                <td>
                                    {{ link.price | currency: 'EUR' }}
                                </td>
                            </tr>
                            <tr *ngFor="let sublink of link.childInvoiceLinks">
                                <td>
                                    <i class="las la-arrow-right m-r-4"></i>

                                    <app-pill-employee
                                        *ngIf="sublink.objectType.toLowerCase() === 'employee'"
                                        [employeeId]="sublink.objectId">
                                    </app-pill-employee>
                                    <app-pill-project
                                        *ngIf="sublink.objectType.toLowerCase() === 'project'"
                                        [projectUuid]="sublink.objectId">
                                    </app-pill-project>
                                    <app-pill-budget
                                        *ngIf="sublink.objectType.toLowerCase() === 'budget'"
                                        [budgetUuid]="sublink.objectId">
                                    </app-pill-budget>
                                    <app-pill-overhead-category
                                        *ngIf="sublink.objectType.toLowerCase() === 'overheadcategory'"
                                        [overheadCategoryId]="sublink.objectId">
                                    </app-pill-overhead-category>
                                </td>
                                <td>
                                    {{ sublink.amount | currency: 'EUR' }}
                                </td>
                            </tr>
                        </ng-container>
                    </table>
                    <ng-template #nolinks> This invoice isn't linked to anything...</ng-template>
                </ng-template>
            </td>
            <td
                *matFooterCellDef
                mat-footer-cell></td>
        </ng-container>

        <ng-container matColumnDef="edit">
            <th
                *matHeaderCellDef
                class="td-small"
                mat-header-cell>
                <span ngbDropdown>
                    <button
                        (click)="$event.stopPropagation()"
                        class="button button--icon button--dropdown button--small m-l-1"
                        ngbDropdownToggle></button>
                    <span
                        aria-labelledby="dropdownMenuButton"
                        class="dropdown-menu dropdown-menu-right"
                        ngbDropdownMenu>
                        <app-table-settings
                            [allColumns]="getAllColumns()"
                            [defaultColumns]="getDefaultColumns()"
                            [tableId]="'yuki-documents-list'"></app-table-settings>
                    </span>
                </span>
            </th>
            <td
                *matCellDef="let row"
                class="td-icon"
                mat-cell>
                <div class="icons-list">
                    <app-yuki-icons [yukiDocument]="row"></app-yuki-icons>
                </div>
            </td>
            <td
                *matFooterCellDef
                mat-footer-cell></td>
        </ng-container>

        <tr
            *matHeaderRowDef="displayedColumns"
            mat-header-row></tr>
        <tr
            (click)="openDetailModal(row)"
            *matRowDef="let row; columns: displayedColumns"
            class="pointer"
            mat-row></tr>
        <tr
            *matFooterRowDef="displayedColumns"
            mat-footer-row></tr>

        <tr *matNoDataRow>
            <td
                [attr.colspan]="displayedColumns.length"
                class="mat-cell">
                <ng-container *ngIf="currentFolder$ && getDocumentsForCurrentFolder$ | async; else loading">
                    There are no invoices in this folder or matching your filters...
                </ng-container>
            </td>
        </tr>
    </table>
</div>

<ng-template #loading>
    <app-loading-message></app-loading-message>
</ng-template>

<ng-template #empty>
    <app-message-empty
        [icon]="'../../assets/img/open-box.png'"
        [subtitle]="'There are no documents in this folder!'"
        [title]="'No documents...'">
    </app-message-empty>
</ng-template>

<ng-template #verifiedFilterTip>
    <ng-container *ngIf="verifiedChecked; else notVerifiedMessage">Show all documents</ng-container>
    <ng-template #notVerifiedMessage>Show only unverified documents</ng-template>
</ng-template>

<ng-template #flaggedTip>
    <span *ngIf="flaggedChecked; else notFlaggedMessage">Show all documents</span>
    <ng-template #notFlaggedMessage>Show only flagged documents</ng-template>
</ng-template>

<ng-template #visibleTip>
    <span *ngIf="visibleChecked; else notVisibleMessage">Show only visible documents</span>
    <ng-template #notVisibleMessage>Show all documents</ng-template>
</ng-template>

<ng-template #paidFilterTip>
    <span *ngIf="paidChecked; else notPaidMessage">Show only non paid documents</span>
    <ng-template #notPaidMessage>Show all documents</ng-template>
</ng-template>

<ng-template #verifiedTip>Document is verified</ng-template>
<ng-template #paidTip>Document is paid</ng-template>
<ng-template #unpaidTip>Document isn't paid by the client yet</ng-template>
