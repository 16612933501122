export class FetchCalendarEvents {
    static readonly type = '[any] Fetch calendarEvents';
    constructor() {}
}

export class FetchActivityTypes {
    static readonly type = '[any] Fetch activityTypes';
    constructor() {}
}

export class FetchContacts {
    static readonly type = '[TeamleaderContact] Fetch contacts';
    constructor() {}
}

export class FetchDepartments {
    static readonly type = '[TeamleaderDepartment] Fetch departments';
    constructor() {}
}

export class FetchCompanies {
    static readonly type = '[any] Fetch companies';
    constructor() {}
}

export class FetchEmployees {
    static readonly type = '[any] Fetch employees';
    constructor() {}
}

export class FetchDeals {
    static readonly type = '[any] Fetch deals';
    constructor() {}
}

export class FetchDealPhases {
    static readonly type = '[any] Fetch dealPhases';
    constructor() {}
}

export class AddCalendarEvent {
    static readonly type = '[any] Add calendarEvent';
    constructor(public calendarEvent: any, public eventType: string) {}
}

export class AddDeal {
    static readonly type = '[any] Add deal';
    constructor(public deal: any) {}
}
