import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subscription } from 'rxjs';
import { CompanyState } from 'src/app/companies/company.state';
import { Employee } from 'src/app/models/Employee';
import { DeleteRelatedEmployee } from 'src/app/related-employees/related.employees.action';

@Component({
    selector: 'app-assigned-employee-delete-modal',
    templateUrl: './assigned-employee-delete-modal.component.html',
    styleUrls: ['./assigned-employee-delete-modal.component.scss'],
})
export class AssignedEmployeeDeleteModalComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Select(CompanyState.companyUuid) companyUuid$: Observable<string>;
    @Input() object: any;
    @Input() employee: Employee;

    constructor(public activeModal: NgbActiveModal, private store: Store, private logger: NGXLogger) {}

    ngOnInit(): void {}

    deleteRelatedEmployee() {
        this.logger.debug('Attempt to delete relatedEmployee');
        this.subscriptions.add(
            this.store.dispatch(new DeleteRelatedEmployee(this.object.uuid, this.employee.uuid)).subscribe(
                () => {
                    this.logger.debug('Succesfully deleted relatedEmployee');
                    this.activeModal.close();
                },
                (error) => this.logger.error('Failed to delete relatedEmployee'),
            ),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
