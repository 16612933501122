import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subscription } from 'rxjs';
import { Product } from 'src/app/models/Product';

import {
    AddUndefinedProductCategory,
    FetchProductCategories,
    LinkProductCategory,
    RemoveUndefinedProductCategory,
    UnLinkProductCategory,
} from '../product.actions';
import { ProductState } from '../product.state';

@Component({
    selector: 'app-product-category-link',
    templateUrl: './product-category-link.component.html',
    styleUrls: ['./product-category-link.component.scss'],
})
export class ProductCategoryLinkComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();
    @Select(ProductState.UndefinedProductCategories)
    undefinedProductCategories$: Observable<string[][]>;
    @Select(ProductState.ProductCategories) productCategories$: Observable<string[]>;
    @Select(ProductState.Products) products$: Observable<Product[]>;

    @Input() product: Product;
    @Input() isEditing = false;

    productCategories: string[] = [];
    allCategories: string[] = [];

    undefinedProductCategories: string[] = [];
    currentUndefinedProductKey: number = undefined;

    isLoading = true;

    constructor(private store: Store, private logger: NGXLogger) {}

    ngOnInit(): void {
        if (this.product) {
            this.subscriptions.add(
                this.products$.subscribe((products) => {
                    this.product = JSON.parse(JSON.stringify(products.find((p) => p.uuid == this.product.uuid)));
                    this.productCategories = this.product.productCategories;
                }),
            );
        }

        this.subscriptions.add(
            this.productCategories$.subscribe((allProductCategories) => {
                if (allProductCategories) {
                    this.allCategories = JSON.parse(JSON.stringify(allProductCategories));
                    this.isLoading = false;
                } else {
                    this.logger.debug('fetching product categories');
                    this.store.dispatch(new FetchProductCategories());
                }
                if (!this.product) {
                    this.undefinedProductCategories$.subscribe((undefinedProductCategories) => {
                        if (this.currentUndefinedProductKey == undefined) {
                            this.currentUndefinedProductKey = undefinedProductCategories.length;
                        }
                        this.undefinedProductCategories = undefinedProductCategories[this.currentUndefinedProductKey];
                    });
                }
            }),
        );
    }

    add(event) {
        const category = event.value;
        if (this.product) {
            this.store.dispatch(new LinkProductCategory(this.product.uuid, category));
        } else {
            this.store.dispatch(new AddUndefinedProductCategory(category, this.currentUndefinedProductKey));
        }
    }

    remove(event) {
        const category = event;
        if (this.product) {
            this.store.dispatch(new UnLinkProductCategory(this.product.uuid, category));
        } else {
            this.store.dispatch(new RemoveUndefinedProductCategory(category, this.currentUndefinedProductKey));
        }
    }

    switchEdit() {
        this.isEditing = !this.isEditing;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
