import { ContactPerson } from './ContactPerson';
import CrmDealReasonOfLoss from './CrmDealReasonOfLoss';
import CrmStage from './CrmStage';
import Customer from './Customer';
import { GlobalTag } from './GlobalTag';
import { Partner } from './Partner';
import { Product } from './Product';

export default interface CrmDeal {
    uuid: string;
    title: string;
    description: string;
    price: number;
    status: CRMDealStatus;
    reason: CrmDealReasonOfLoss;
    dateSinceLastMovement: Date;
    partner: Partner;
    contactPerson: ContactPerson;
    products: Product[];
    crmStage: CrmStage;
    comanageOffer: number;
    globalTags: GlobalTag[];
    customer: Customer;

    contactPersonUuid?: string;
    customerUuid?: string;
    partnerUuid?: string;
}

export class CRMDealDTO {
    uuid: string;
    title: string;
    status: CRMDealStatus;
    dateSinceLastMovement: Date;
    price: number;
    comanageOffer: number;

    description: string;

    partnerUuid: string;
    contactPersonUuid: string;
    customerUuid: string;

    crmStageUuid: string;
    crmDealReasonOffLossUuid: string;

    constructor(deal: CrmDeal) {
        this.uuid = deal.uuid;
        this.title = deal.title;
        this.status = deal.status;
        this.dateSinceLastMovement = deal.dateSinceLastMovement;
        this.price = deal.price;
        this.comanageOffer = deal.comanageOffer;
        this.description = deal.description.substring(0, 999);
        this.partnerUuid = deal.partner ? deal.partner.uuid : null;
        this.contactPersonUuid = deal.contactPerson ? deal.contactPerson.uuid : null;
        this.customerUuid = deal.customer ? deal.customer.uuid : null;
        this.crmStageUuid = deal.crmStage.uuid;
        this.crmDealReasonOffLossUuid = deal.reason ? deal.reason.uuid : null;
    }
}

export enum CRMDealStatus {
    ONGOING = 'ONGOING',
    WON = 'WON',
    LOST = 'LOST',
    OTHER = 'OTHER',
    ARCHIVED = 'ARCHIVED',
}
