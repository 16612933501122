import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subscription } from 'rxjs';

import { YukiSettings } from '../../models/yuki/YukiSettings';
import { BudgetInvoicesSettingsComponent } from '../../yuki/budget-invoices-settings/budget-invoices-settings.component';
import { FetchYukiSettings } from '../../yuki/yuki.actions';
import { YukiState } from '../../yuki/yuki.state';
import { UpdateYukiSettingsModalComponent } from '../update-yuki-settings-modal/update-yuki-settings-modal.component';

@Component({
    selector: 'app-yuki-settings',
    templateUrl: './yuki-settings.component.html',
    styleUrls: ['./yuki-settings.component.scss'],
})
export class YukiSettingsComponent implements OnInit, OnDestroy {
    @Select(YukiState.yukiSettings) yukiSettings$: Observable<YukiSettings>;
    yukiSettings: YukiSettings;
    private subscriptions = new Subscription();

    constructor(private store: Store, private logger: NGXLogger, private modalService: NgbModal) {}

    ngOnInit(): void {
        this.fetchData();
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    openUpdateYukiSettingsModal() {
        const modalRef = this.modalService.open(UpdateYukiSettingsModalComponent, { windowClass: 'modal-prompt', animation: false });
        modalRef.componentInstance.yukiSettings = this.yukiSettings;
    }

    openUpdateInvoicesBudgetExpenditureLinkModal() {
        const modalRef = this.modalService.open(BudgetInvoicesSettingsComponent, { windowClass: 'modal-prompt', animation: false });
        modalRef.componentInstance.yukiSettings = this.yukiSettings;
    }

    private fetchData() {
        this.subscriptions.add(
            this.yukiSettings$.subscribe((settings) => {
                if (!settings) {
                    this.logger.debug('Fetching YukiSettings');
                    this.store.dispatch(new FetchYukiSettings());
                    return;
                }

                this.yukiSettings = settings;
            }),
        );
    }
}
