import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import FileSystemEntity from 'src/app/models/FileSystemEntity';
import { ConfirmationModalComponent } from 'src/app/shared/confirmation-modal/confirmation-modal.component';

import { DocumentsAddModalComponent } from '../documents-add-modal/documents-add-modal.component';
import { DeleteFileSystemEntity, Navigate, UpdateDirectory } from '../documents.actions';
import { DocumentService } from '../documents.service';
import { DocumentState } from '../documents.state';

@Component({
    selector: 'app-documents-directory',
    templateUrl: './documents-directory.component.html',
    styleUrls: ['./documents-directory.component.scss'],
})
export class DocumentsDirectoryComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Select(DocumentState.currentDirectory)
    directory$: Observable<FileSystemEntity>;
    @Input() selectDir;
    @Output() deletedFolder = new EventEmitter<void>();
    currentDirectory: FileSystemEntity;
    content: FileSystemEntity[];
    isLoading = true;

    filter = '';
    searching = false;
    searchResults: Observable<FileSystemEntity[]>;

    name: string;
    editName = false;

    constructor(
        private store: Store,
        private modalService: NgbModal,
        private toastr: ToastrService,
        private documentService: DocumentService,
        private logger: NGXLogger,
    ) {}

    ngOnInit(): void {
        this.subscriptions.add(
            this.directory$.subscribe(
                (dir) => {
                    if (dir != undefined) {
                        this.isLoading = false;
                        this.content = dir.docs;
                        this.currentDirectory = dir;
                        this.name = dir.name;
                    }
                },
                (err) => {
                    this.isLoading = false;
                    console.log(err);
                },
            ),
        );
    }

    navigate(dir: any) {
        this.logger.debug('Attempt to navigate up');
        this.store.dispatch(new Navigate(dir.uuid));
    }

    saveName() {
        this.editName = false;
        this.currentDirectory.name = this.name;
        this.logger.debug('Attempt update directoryName');
        this.subscriptions.add(
            this.store.dispatch(new UpdateDirectory(this.currentDirectory)).subscribe(
                () => {
                    this.logger.debug('Sucessfully updated directoryName');
                    this.toastr.success('Name updated successfully.');
                },
                (err) => {
                    this.logger.debug('Failed to update directoryName');
                    this.toastr.error('Oops, something went wrong.\nPlease try again later.');
                },
            ),
        );
    }

    addDirectory() {
        this.logger.debug('Opening documentsAddModal - addDir');
        const modalRef = this.modalService.open(DocumentsAddModalComponent, {
            windowClass: 'modal-prompt',
        });
        modalRef.componentInstance.type = 'Dir';
        modalRef.componentInstance.content = this.content;
        modalRef.componentInstance.currentDirId = this.currentDirectory.uuid;
    }

    addDocument() {
        this.logger.debug('Opening documentsAddModal - addDoc');
        const modalRef = this.modalService.open(DocumentsAddModalComponent, {
            windowClass: 'modal-prompt',
        });
        modalRef.componentInstance.type = 'Doc';
        modalRef.componentInstance.content = this.content;
        modalRef.componentInstance.currentDirId = this.currentDirectory.uuid;
    }

    deleteCurrentFolder() {
        this.logger.debug('Opening confirmationModal - delCurrentFolder');
        const modalRef = this.modalService.open(ConfirmationModalComponent, {
            windowClass: 'modal-prompt',
        });
        modalRef.componentInstance.type = 'Delete';
        modalRef.componentInstance.title = 'Delete folder';
        modalRef.componentInstance.message = 'Are you sure you want to delete the current folder?\n All contents of this folder will be deleted along with it.';
        this.subscriptions.add(
            modalRef.componentInstance.closeEvent.subscribe((val) => {
                if (val) {
                    this.logger.debug('Attempt to delete current folder');
                    this.store.dispatch(new DeleteFileSystemEntity(this.currentDirectory)).subscribe(
                        () => {
                            this.logger.debug('Succesfully deleted current folder');
                            this.toastr.success('Folder deleted successfully.');
                            this.deletedFolder.emit();
                        },
                        (err) => {
                            this.logger.debug('Failed to delete current folder');
                            this.toastr.error('Oops, something went wrong.\nPlease try again later.');
                        },
                    );
                }
            }),
        );
    }

    filtering() {
        if (this.filter != undefined && this.filter != '') {
            this.searching = true;
            this.searchResults = this.documentService.filterFilesAndDirs(this.filter);
        } else {
            this.searching = false;
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
