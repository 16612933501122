<form
    [formGroup]="leadFormGroup"
    (ngSubmit)="submit()"
    class="modal-wrapper">
    <div class="modal-header">
        <h2
            *ngIf="lead"
            class="modal-title"
            id="exampleModalLabel">
            Edit lead
        </h2>
        <h2
            *ngIf="!lead"
            class="modal-title"
            id="exampleModalLabel">
            Add lead
        </h2>
        <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="activeModal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <div class="row">
            <div class="col-6">
                <div class="form-group m-b-2">
                    <label>Firstname</label>
                    <input
                        type="text"
                        placeholder="Firstname"
                        formControlName="firstName"
                        trim="blur" />
                </div>
            </div>
            <div class="col-6">
                <div class="form-group m-b-2">
                    <label>Lastname</label>
                    <input
                        type="text"
                        placeholder="Lastname"
                        formControlName="lastName"
                        trim="blur" />
                </div>
            </div>
        </div>

        <h3 class="m-t-4 m-b-4">Address</h3>

        <div formGroupName="address">
            <div class="m-b-2">
                <div class="row">
                    <div class="col-6">
                        <div class="form-group required">
                            <label>Street</label>
                            <input
                                type="text"
                                placeholder="Street"
                                formControlName="street"
                                trim="blur" />
                        </div>
                    </div>
                    <div class="col-2">
                        <div class="form-group required">
                            <label>Number</label>
                            <input
                                type="number"
                                placeholder="Number"
                                formControlName="number" />
                        </div>
                    </div>
                    <div class="col-2">
                        <div class="form-group">
                            <label>Box</label>
                            <input
                                type="text"
                                placeholder="Box"
                                formControlName="bus"
                                trim="blur" />
                        </div>
                    </div>
                    <div class="col-2">
                        <div class="form-group">
                            <label>Ext</label>
                            <input
                                type="number"
                                placeholder="Ext"
                                formControlName="ext" />
                        </div>
                    </div>
                </div>
            </div>

            <div class="m-b-2">
                <div class="row">
                    <div class="col-3">
                        <div class="form-group required">
                            <label>Zipcode</label>
                            <input
                                type="text"
                                placeholder="Zipcode"
                                formControlName="postal"
                                trim="blur" />
                        </div>
                    </div>
                    <div class="col-9">
                        <div class="form-group required">
                            <label>City</label>
                            <input
                                type="text"
                                placeholder="City"
                                formControlName="city"
                                trim="blur" />
                        </div>
                    </div>
                </div>
            </div>

            <div class="m-b-2">
                <div class="row">
                    <div class="col">
                        <div
                            class="form-group required"
                            formGroupName="country">
                            <label>Country</label>
                            <select formControlName="iso2">
                                <option
                                    *ngFor="let country of allCountries"
                                    [ngValue]="country['alpha-2']">
                                    {{ country.name }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <h3 class="m-t-4 m-b-4">Contact information</h3>

        <div class="form-group m-b-2 required">
            <label>Email</label>
            <input
                type="text"
                placeholder="Email"
                formControlName="email"
                trim="blur" />
            <div *ngIf="email.invalid && (email.dirty || email.touched)">
                <span
                    class="form-error"
                    *ngIf="email.errors.email"
                    >Enter a valid email</span
                >
            </div>
        </div>

        <div class="form-group m-b-2">
            <label>Phonenumber</label>
            <input
                type="text"
                placeholder="Phonenumber"
                formControlName="phoneNumber"
                trim="blur" />
        </div>

        <div class="form-group m-b-2">
            <label>Tags</label>
            <app-global-tags
                *ngIf="!lead"
                [type]="'lead'"></app-global-tags>
        </div>

        <!-- <div class="form-group m-b-2">

            <textarea formControlName="message" placeholder="Message" trim="blur" #messageTextArea></textarea>
            <small>{{ messageTextArea.value.length }}/{{DESCRIPTION_MAX_LENGTH}}</small>

            <div *ngIf="message.invalid && (message.dirty || message.touched)">
                <span class="form-error" *ngIf="message.errors.maxlength">Message cannot contain more than {{DESCRIPTION_MAX_LENGTH}} characters</span>
                <span class="form-error" *ngIf="message.errors.minlength">Message must contain more than {{DESCRIPTION_MIN_LENGTH}} characters</span>
            </div>
        </div> -->
    </div>
    <span
        class="form-error"
        *ngIf="updateLeadWentWrong"
        >{{ errorMessage }}</span
    >

    <div class="modal-footer">
        <button
            type="button"
            class="button button--outline"
            (click)="activeModal.close()">
            Close
        </button>
        <input
            *ngIf="!lead"
            [disabled]="!leadFormGroup.valid"
            type="submit"
            [ngClass]="!leadFormGroup.valid ? 'button button--disabled' : 'button button--primary'"
            value="Add" />
        <input
            *ngIf="lead"
            [disabled]="!leadFormGroup.valid"
            type="submit"
            [ngClass]="!leadFormGroup.valid ? 'button button--disabled' : 'button button--primary'"
            value="Update" />
    </div>
</form>
