import { AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { GetAllObjectsLinked } from 'src/app/shared/global-files/global-files.actions';

import YukiDocument from '../../models/yuki/YukiDocument';
import {
    FetchAllDocuments,
    FetchFile,
    FetchNextFile,
    FetchPreviousFile,
    SwitchNextFileToFile,
    SwitchPreviousFileToFile,
    VerifyYukiDocument,
} from '../yuki.actions';
import { YukiState } from '../yuki.state';

@Component({
    selector: 'app-yuki-detail-modal',
    templateUrl: './yuki-detail-modal.component.html',
    styleUrls: ['./yuki-detail-modal.component.scss'],
})
export class YukiDetailModalComponent implements OnInit, OnDestroy, AfterViewInit {
    @Select(YukiState.documentsForCurrentFolder)
    documentsForCurrentFolder$: Observable<YukiDocument[]>;
    documents: YukiDocument[] = null;
    currentIndex = 0;
    @Input() yukiDocument: YukiDocument;
    @Input() folderId: number;
    // if true => linking tab will be displayed automatically
    @Input() onLinkTab = false;
    @ViewChild('detailTabs') nav?;
    private subscriptions = new Subscription();

    constructor(
        private store: Store,
        public activeModal: NgbActiveModal,
        private logger: NGXLogger,
        private sanitizer: DomSanitizer,
        private fb: FormBuilder,
        private toastr: ToastrService,
        private changeDetectorRef: ChangeDetectorRef,
    ) {}

    ngOnInit(): void {
        this.fetchData();
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    ngAfterViewInit() {
        if (this.onLinkTab) this.nav.select(2);

        this.changeDetectorRef.detectChanges();
    }

    previousDocument() {
        this.currentIndex--;
        this.yukiDocument = this.documents[this.currentIndex];

        this.logger.debug('Switching previous file to current file');
        this.store.dispatch(new SwitchPreviousFileToFile());

        // First document => no previous file
        if (this.currentIndex < 1) return;

        this.logger.debug('Fetching next file');
        this.fetchPreviousFile();
    }

    nextDocument() {
        // Last document of folder => close modal
        if (this.currentIndex >= this.documents.length - 1) {
            this.activeModal.dismiss();
            this.toastr.success('You reached the last document for this folder.');
            return;
        }

        this.currentIndex++;
        this.yukiDocument = this.documents[this.currentIndex];

        this.logger.debug('Switching next file to current file');
        this.store.dispatch(new SwitchNextFileToFile());

        // Last document => no next file
        if (this.currentIndex + 1 >= this.documents.length) return;

        this.logger.debug('Fetching next file');
        this.fetchNextFile();
    }

    switchToLinkTab() {
        this.nav.select(2);
    }

    closeModal() {
        this.store.dispatch(new FetchAllDocuments(this.folderId));
        this.store.dispatch(GetAllObjectsLinked);
        this.activeModal.dismiss();
    }

    verifyDocument() {
        this.subscriptions.add(
            this.store.dispatch(new VerifyYukiDocument(this.yukiDocument.yukiId)).subscribe({
                next: () => {
                    this.toastr.success('Yuki document successfully verified.');
                },
                error: () => {
                    this.logger.error('Failed to update Yuki document.');
                    this.toastr.error('Oops, something went wrong, Please try again later...');
                },
            }),
        );
    }

    private fetchData() {
        this.subscriptions.add(
            this.documentsForCurrentFolder$.subscribe((documents) => {
                this.documents = documents;
                this.currentIndex = this.documents.findIndex((document) => document.yukiId === this.yukiDocument.yukiId);
                this.yukiDocument = this.documents[this.currentIndex];
            }),
        );

        if (this.yukiDocument.contentType) {
            this.logger.debug('Fetching file');
            this.fetchFile();
        }

        if (this.documents.length > this.currentIndex + 1 && this.documents[this.currentIndex + 1].contentType) {
            this.logger.debug('Fetching next file');
            this.fetchNextFile();
        }
    }

    private fetchFileErrorHandling(error) {
        if (error.status === 500) {
            this.logger.error('Failed fetch file of Yuki document due to request error to the Yuki API.');
            this.toastr.error(
                'Oops something went wrong on the server while fetching file of this Yuki document... Are you sure you placed your Yuki credentials?',
            );
            return;
        }
        if (error.status === 401) {
            this.logger.error('Failed fetch file of Yuki document due to problems with Yuki credentials.');
            this.toastr.error('Oops something went wrong on the server... Are you sure you placed your Yuki credentials?');
            return;
        }
        if (error.status === 404) {
            this.logger.error('Failed fetch file of Yuki document due to not found.');
            this.toastr.error('Oops, something went wrong, Please try again later...');
            return;
        }
        this.logger.error('Failed to update VAT and VAT amount of Yuki document');
        this.toastr.error('Oops, something went wrong, Please try again later...');
    }

    private fetchFile() {
        if (!this.yukiDocument.fileName) return;

        this.store.dispatch(new FetchFile(this.yukiDocument.uuid)).subscribe({
            error: (error) => this.fetchFileErrorHandling(error),
        });
    }

    private fetchNextFile() {
        if (!this.documents[this.currentIndex + 1].fileName) return;

        this.store.dispatch(new FetchNextFile(this.documents[this.currentIndex + 1].uuid)).subscribe({
            error: (error) => this.fetchFileErrorHandling(error),
        });
    }

    private fetchPreviousFile() {
        if (!this.documents[this.currentIndex - 1].fileName) return;

        this.store.dispatch(new FetchPreviousFile(this.documents[this.currentIndex - 1].uuid)).subscribe({
            error: (error) => this.fetchFileErrorHandling(error),
        });
    }
}

export interface YukiDocumentDTO {
    vatPercent: number;
    vatAmount: number;
    amount: number;
    amountExcl: number;
    description: string;
    subject: string;
    contactName: string;
    invoiceNumber: string;
    linkedContactId: string;
}
