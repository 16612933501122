import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as moment from 'moment';
import { Observable, Subscription, tap } from 'rxjs';
import { Employee } from 'src/app/models/Employee';
import Project from 'src/app/models/Project';
import { Timesheet } from 'src/app/models/Timesheet';
import { Timeslot } from 'src/app/models/Timeslot';
import { ProjectService } from 'src/app/projects/project.service';

import { TimesheetsService } from '../timesheets.service';

@Component({
    selector: 'app-invoice-dialog',
    templateUrl: './invoice-dialog.component.html',
    styleUrls: ['./invoice-dialog.component.scss'],
})
export class InvoiceDialogComponent implements OnInit {
    private subscriptions = new Subscription();
    selectedTimeslots: Timeslot[] = [];
    timeslots: Timeslot[];
    timesheets: Timesheet[];
    uniqueProjects: Project[];
    employee: Employee;
    project: Project;
    isLoaded = false;
    isDisabled = true;
    partnerName: string;
    invoiceDate: string;
    subjectWithDate: string;

    constructor(
        private projectService: ProjectService,
        private timesheetsService: TimesheetsService,
        public dialogRef: MatDialogRef<InvoiceDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
        this.timeslots = data.projectTimeslots;
        this.timesheets = data.timesheets;
        this.uniqueProjects = data.uniqueProjects;
    }

    ngOnInit(): void {
        this.subscriptions.add(this.getProjectDetails().subscribe());
        this.invoiceDate = moment(this.createInvoiceDate()).format('YYYY-MM-DD');
        this.subjectWithDate = this.getSubjectWithDateByTimesheet();
    }

    getProjectDetails(): Observable<Project> {
        this.project = null;

        return this.projectService.getProjectDetail(this.timeslots[0].project.uuid).pipe(
            tap((project: Project) => {
                this.project = project;
                this.partnerName = this.getPartnerName();
                this.isLoaded = true;
            }),
        );
    }

    getPartnerName(): string {
        if (this.project && this.project.partnerName && this.project.partnerName !== '') {
            return this.project.partnerName;
        } else {
            return 'NO PARTNERNAME SET';
        }
    }

    getEmployeeByTimeslot(timeslot: Timeslot): Employee {
        this.employee = null;
        this.timesheets.forEach((sheet) => {
            sheet.timeslots.forEach((slot) => {
                if (slot.uuid === timeslot.uuid) {
                    this.employee = sheet.employee;
                }
            });
        });

        return this.employee;
    }

    checkIfAllInvoiceLinesHaveBeenBilled(): boolean {
        let allTimeslotsHaveBeenBilled = true;
        this.timeslots.forEach((slot) => {
            if (slot.billed.valueOf() == false) {
                allTimeslotsHaveBeenBilled = false;
            }
        });

        return allTimeslotsHaveBeenBilled;
    }

    createInvoiceDate(): Date {
        const expirationDate: any = this.timesheets[0].expirationDate;
        return new Date(expirationDate);
    }

    getSubjectWithDateByTimesheet(): string {
        const expirationDate: Date = this.createInvoiceDate();
        const subjectText = 'Invoice for consultancy ';
        let formattedDate: string;

        if (expirationDate instanceof Date) {
            formattedDate = expirationDate.toLocaleDateString('en', {
                month: 'long',
                year: 'numeric',
            });
        } else {
            return subjectText + 'DATE UNKNOWN';
        }

        return subjectText + formattedDate;
    }

    calculateWorkedHoursForTimeslot(timeslot: Timeslot): number {
        const workedHours = timeslot.minutes / 60;
        return Number(workedHours.toFixed(2));
    }

    toggleTimeslotSelection(checked: boolean, timeslot: Timeslot): void {
        if (checked) {
            // Add timeslot
            this.selectedTimeslots.push(timeslot);
        } else {
            const index = this.selectedTimeslots.indexOf(timeslot);
            if (index !== -1) {
                // Remove timeslot
                this.selectedTimeslots.splice(index, 1);
            }
        }
        if (this.selectedTimeslots.length > 0) {
            this.isDisabled = false;
        } else {
            this.isDisabled = true;
        }
    }

    closeDialog(): void {
        this.subscriptions.unsubscribe();
        this.dialogRef.close();
    }
}
