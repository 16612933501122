import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { CrystalLightboxModule } from '@crystalui/angular-lightbox';
import { NgbActiveModal, NgbDropdownModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TagInputModule } from 'ngx-chips';
import { OrderModule } from 'ngx-order-pipe';
import { NgPipesModule } from 'ngx-pipes';
import { NgxTrimDirectiveModule } from 'ngx-trim-directive';

import { CompaniesModule } from './../companies/companies.module';
import { SharedModule } from './../shared/shared.module';
import { EducationDeleteModalComponent } from './education-delete-modal/education-delete-modal.component';
import { EducationDetailModalComponent } from './education-detail-modal/education-detail-modal.component';
import { ExperienceDeleteModalComponent } from './experience-delete-modal/experience-delete-modal.component';
import { ExperienceDetailModalComponent } from './experience-detail-modal/experience-detail-modal.component';
import { LanguageDeleteModalComponent } from './language-delete-modal/language-delete-modal.component';
import { LanguageDetailModalComponent } from './language-detail-modal/language-detail-modal.component';
import { OnboardingComponent } from './onboarding/onboarding.component';
import { OverviewComponent } from './overview/overview.component';
import { ResumeAddModalComponent } from './resume-add-modal/resume-add-modal.component';
import { ResumeDeleteModalComponent } from './resume-delete-modal/resume-delete-modal.component';
import { ResumeDetailModalComponent } from './resume-detail-modal/resume-detail-modal.component';
import { ResumeDetailPageComponent } from './resume-detail-page/resume-detail-page.component';
import { ResumeDetailComponent } from './resume-detail/resume-detail.component';
import { ResumeEducationListComponent } from './resume-education-list/resume-education-list.component';
import { ResumeExperienceListComponent } from './resume-experience-list/resume-experience-list.component';
import { ResumeLanguageListComponent } from './resume-language-list/resume-language-list.component';
import { ResumeListComponent } from './resume-list/resume-list.component';
import { ResumePersoninfoUpdateModalComponent } from './resume-personinfo-update-modal/resume-personinfo-update-modal.component';
import { ResumeShareContactpersonListComponent } from './resume-share-contactperson-list/resume-share-contactperson-list.component';
import { ResumeShareModalComponent } from './resume-share-modal/resume-share-modal.component';
import { ResumeVariantDeleteModalComponent } from './resume-variant-delete-modal/resume-variant-delete-modal.component';
import { ResumeVariantDetailModalComponent } from './resume-variant-detail-modal/resume-variant-detail-modal.component';
import { ResumeVariantSkillDeleteModalComponent } from './resume-variant-skill-delete-modal/resume-variant-skill-delete-modal.component';
import { ResumeVariantSkillDetailModalComponent } from './resume-variant-skill-detail-modal/resume-variant-skill-detail-modal.component';
import { ResumeVariantSkillListComponent } from './resume-variant-skill-list/resume-variant-skill-list.component';
import { routing } from './resume.routing';
import { ResumeTechnicalSkillsListComponent } from './resume-technical-skills-list/resume-technical-skills-list.component';
import { TechnicalSkillsAddModalComponent } from './technical-skills-add-modal/technical-skills-add-modal.component';
import { ManageVariantsComponent } from './manage-variants/manage-variants.component';

@NgModule({
    declarations: [
        OnboardingComponent,
        OverviewComponent,
        ResumeDetailComponent,
        ResumeListComponent,
        ResumeDeleteModalComponent,
        ResumeEducationListComponent,
        EducationDeleteModalComponent,
        ResumeAddModalComponent,
        ResumePersoninfoUpdateModalComponent,
        ResumeExperienceListComponent,
        ExperienceDeleteModalComponent,
        ResumeShareModalComponent,
        ResumeShareContactpersonListComponent,
        ResumeLanguageListComponent,
        LanguageDeleteModalComponent,
        EducationDetailModalComponent,
        ExperienceDetailModalComponent,
        LanguageDetailModalComponent,
        ResumeDetailPageComponent,
        ResumeDetailModalComponent,
        ResumeVariantSkillDetailModalComponent,
        ResumeVariantSkillDeleteModalComponent,
        ResumeVariantSkillListComponent,
        ResumeVariantDeleteModalComponent,
        ResumeVariantDetailModalComponent,
        ResumeTechnicalSkillsListComponent,
        TechnicalSkillsAddModalComponent,
        ManageVariantsComponent,
    ],
    imports: [
        CommonModule,
        SharedModule,
        CompaniesModule,
        routing,
        NgbModule,
        NgbDropdownModule,
        NgxTrimDirectiveModule,
        TagInputModule,
        NgPipesModule,
        OrderModule,
        CrystalLightboxModule,
        MatTableModule,
        CdkTableModule,
        MatSortModule,
    ],
    providers: [NgbActiveModal],
    exports: [ResumeDetailComponent],
})
export class ResumesModule {}
