<div [hidden]="!this.dataSource?.data">
    <app-searchbar
        [(value)]="filter"
        (valueChange)="applyFilter($event)"></app-searchbar>

    <div class="m-t-4">
        <table
            mat-table
            [dataSource]="dataSource"
            matSort>
            <ng-container
                *ngFor="let disCol of this.displayedColumns; let colIndex = index"
                [matColumnDef]="disCol">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header>
                    {{ disCol }}
                </th>
                <td
                    mat-cell
                    *matCellDef="let row"
                    (click)="openDetailModal(row)">
                    <span *ngIf="exceptionColumnsWithValue.get(disCol) === undefined">
                        {{ row[disCol] }}
                    </span>

                    <span
                        *ngIf="
                            exceptionColumnsWithValue.get(disCol) !== undefined &&
                            row[disCol].length > 0 &&
                            !this.urlRegex.test(row[disCol][0][exceptionColumnsWithValue.get(disCol)])
                        ">
                        {{ row[disCol][0][exceptionColumnsWithValue.get(disCol)] }}
                    </span>

                    <span
                        *ngIf="
                            exceptionColumnsWithValue.get(disCol) !== undefined &&
                            row[disCol].length > 0 &&
                            this.urlRegex.test(row[disCol][0][exceptionColumnsWithValue.get(disCol)])
                        ">
                        <img [src]="row[disCol][0][exceptionColumnsWithValue.get(disCol)]" />
                    </span>
                </td>
            </ng-container>

            <tr
                mat-header-row
                *matHeaderRowDef="displayedColumns"></tr>
            <tr
                mat-row
                *matRowDef="let row; columns: displayedColumns"></tr>

            <tr *matNoDataRow>
                <td [attr.colspan]="this.displayedColumns.length">No {{ this.entity }} matching the filter "{{ filter }}"</td>
            </tr>
        </table>
    </div>
</div>

<div *ngIf="this.loading">
    <app-loading-message></app-loading-message>
</div>
