import { Pipe, PipeTransform } from '@angular/core';

import { Applicant } from '../models/Applicants/Applicant';

@Pipe({
    name: 'filterApplicants',
})
export class FilterApplicantsPipe implements PipeTransform {
    transform(applicants: Applicant[] | undefined, filter: string): Applicant[] {
        const lowerCaseFilter = filter?.toLocaleLowerCase();
        return applicants?.filter(
            (applicant) =>
                !filter ||
                (applicant.contactInformation && applicant.contactInformation.email?.toLocaleLowerCase().includes(lowerCaseFilter)) ||
                applicant.firstName.toLocaleLowerCase().includes(lowerCaseFilter) ||
                applicant.lastName.toLocaleLowerCase().includes(lowerCaseFilter) ||
                (applicant.firstName + ' ' + applicant.lastName).toLocaleLowerCase().includes(lowerCaseFilter),
        );
    }
}
