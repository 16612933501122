<app-app-holder>
    <app-navbar></app-navbar>
    <app-content>
        <div>
            <div class="app__title">
                <h1>
                    Contacts
                    <span *ngIf="contacts$ | async as contacts">({{ contacts.length }})</span>
                </h1>
                <span>
                    <a
                        id="contacts-overview-add-contact-button"
                        class="button button--primary"
                        *appIsPermitted="{
                            module: 'CONTACTS',
                            operator: 'AND',
                            actions: ['CREATE']
                        }"
                        (click)="openAddContactPersonsModal()"
                        >New contact</a
                    >
                </span>
            </div>

            <div
                *appIsPermitted="{
                    module: 'CONTACTS',
                    operator: 'AND',
                    actions: ['READ']
                }">
                <div *ngIf="contacts$ | async as contacts; else loading">
                    <app-contacts-list *ngIf="contacts && contacts.length > 0; else empty"></app-contacts-list>
                </div>
            </div>
        </div>
    </app-content>

    <ng-template #loading>
        <app-loading-message></app-loading-message>
    </ng-template>

    <ng-template #empty>
        <app-message-empty
            [title]="'No contactpersons...'"
            [subtitle]="'Contacts are your connections within companies. You can convert them to customers later.'"
            [icon]="'../../assets/img/open-box.png'">
            <a
                (click)="openAddContactPersonsModal()"
                class="button button--primary"
                >Add a contactperson</a
            >
        </app-message-empty>
    </ng-template>
</app-app-holder>
