import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import FileSystemEntity from 'src/app/models/FileSystemEntity';
import { ConfirmationModalComponent } from 'src/app/shared/confirmation-modal/confirmation-modal.component';

import { DeleteFileSystemEntity } from '../documents.actions';

@Component({
    selector: 'app-documents-dir',
    templateUrl: './documents-dir.component.html',
    styleUrls: ['./documents-dir.component.scss'],
})
export class DocumentsDirComponent implements OnDestroy {
    private subscriptions = new Subscription();

    @Input() directory: FileSystemEntity;
    @Output() navigate = new EventEmitter<string>();

    constructor(private store: Store, private toastr: ToastrService, private modalService: NgbModal, private logger: NGXLogger) {}

    delete($event) {
        $event.stopPropagation();
        this.logger.debug('Opening confirmationModal - deleteFolder');
        const modalRef = this.modalService.open(ConfirmationModalComponent, {
            windowClass: 'modal-prompt',
        });
        modalRef.componentInstance.type = 'Delete';
        modalRef.componentInstance.title = 'Delete folder';
        modalRef.componentInstance.message =
            'Are you sure you want to delete ' + this.directory.name + '?\n All contents of this folder will be deleted along with it.';
        this.subscriptions.add(
            modalRef.componentInstance.closeEvent.subscribe((val) => {
                if (val) {
                    this.logger.debug('Attempt to delete folder');
                    this.store.dispatch(new DeleteFileSystemEntity(this.directory)).subscribe(
                        () => {
                            this.logger.debug('Succesfully deleted folder');
                            this.toastr.success('Folder deleted successfully.');
                        },
                        () => {
                            this.logger.error('Faoled to delete folder');
                            this.toastr.error('Oops, something went wrong.\nPlease try again later.');
                        },
                    );
                }
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
