import { Permissions } from '../../models/Permissions';
import { CustomRolesState } from '../../settings/roles/custom.roles.state';

export function isPermitted(permissions: Permissions): boolean {
    let allowed;
    const allowedActions = JSON.parse(sessionStorage.getItem('allowedActions'));
    if (allowedActions) {
        allowed = checkAllowed(allowedActions, permissions);
    } else {
        this.store.selectOnce(CustomRolesState.AllowedActions).subscribe((allowedActions) => {
            allowed = checkAllowed(allowedActions, permissions);
        });
    }
    return allowed;
}

export function checkAllowed(allowedActions, permissions) {
    let allowed;
    if (allowedActions['role'] == 'ROLE_SUPER_ADMIN' || allowedActions['role'] == 'ROLE_OWNER') {
        allowed = true;
    } else {
        for (let i = 0; i < permissions.actions.length; i++) {
            if (allowedActions[permissions.module] == undefined || !allowedActions[permissions.module].includes(permissions.actions[i])) {
                allowed = false;
                if (permissions.operator == 'AND') {
                    break;
                }
            } else {
                allowed = true;
                if (permissions.operator == 'OR') {
                    break;
                }
            }
        }
    }
    return allowed;
}
