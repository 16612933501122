<app-app-holder>
    <app-navbar></app-navbar>

    <app-sidebar>
        <app-velda-languages-subnav></app-velda-languages-subnav>
    </app-sidebar>

    <app-content>
        <div class="app__title">
            <h1>Countries overview</h1>
            <span>
                <a
                    (click)="openAddCountryModal()"
                    *appIsPermitted="{
                        module: 'VELDA_LANGUAGES',
                        operator: 'AND',
                        actions: ['CREATE']
                    }"
                    class="button button--primary"
                    >Add country</a
                >
            </span>
        </div>

        <app-velda-languages-countries-list
            *appIsPermitted="{
                module: 'VELDA_LANGUAGES',
                operator: 'AND',
                actions: ['READ']
            }"></app-velda-languages-countries-list>
    </app-content>
</app-app-holder>
