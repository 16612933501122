<table class="table-view">
    <tr>
        <th>Skill</th>
        <th>Experience</th>
        <th class="td-small"></th>
    </tr>
    <ng-container *ngIf="resumeVariantSkills">
        <tr *ngFor="let skill of resumeVariantSkills">
            <td><strong>{{skill.title}}</strong></td>
            <td>{{skill.years}} years</td>
            <td class="td-icon">
                <div
                    *ngIf="showActions"
                    class="dropdown"
                    ngbDropdown>
                    <button
                        class="button button--icon button--small dropdown-toggle"
                        id="dropdownMenuButton"
                        ngbDropdownToggle
                        type="button"></button>
                    <div
                        aria-labelledby="dropdownMenuButton"
                        class="dropdown-menu dropdown-menu-right"
                        ngbDropdownMenu>
                        <a
                            (click)="openUpdateResumeVariantSkillModal(skill)"
                            class="dropdown-item"
                        >Edit skill</a
                        >
                        <a
                            (click)="openDeleteResumeVariantSkillModal(skill)"
                            class="dropdown-item color--red"
                        >Delete</a
                        >
                    </div>
                </div>
            </td>
        </tr>
    </ng-container>
    <tr *ngIf="resumeVariantSkills.length === 0">
        <td colspan="3">
            No skills added yet...
        </td>
    </tr>
    <tr>
        <td (click)="openResumeVariantSkillAddModal()" class="table-view__add" colspan="3">
            <i class="las la-plus"></i>
            Add skill
        </td>
    </tr>
</table>
