import { Partner } from '../Partner';
import { VAT } from '../VAT';

export default class YukiDocument {
    uuid: string;
    yukiId: string;
    subject: string;
    documentDate: Date;
    amount: number;
    amountExcl: number;
    folderId: number;
    tab: string;
    type: number;
    typeDescription: string;
    fileName: string;
    contentType: string;
    fileSize: number;
    contactName: string;
    created: Date;
    creator: string;
    modified: Date;
    modifier: string;
    vat: VAT;
    vattext: string;
    vatamount: number;
    verified: boolean;
    flagged: boolean;
    visible: boolean;
    totalAmount: number;
    description: string;
    invoiceNumber: string;
    paid: boolean;
    linkedContact: Partner;
}
