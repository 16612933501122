<form
    [formGroup]="queryFormGroup"
    (ngSubmit)="editQuery()"
    class="modal-wrapper">
    <div class="modal-header">
        <h2
            class="modal-title"
            id="exampleModalLabel">
            Edit query
        </h2>
        <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="activeModal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <div class="form-group m-b-2 required">
            <label>Query payload</label>
            <textarea
                class="form-code resize-vertical"
                placeholder="Query"
                formControlName="query"
                trim="blur"
                #focussed
                rows="8"
                cols="80"></textarea>
            <span
                class="form-error"
                *ngIf="queryFormGroup.controls.query.touched && queryFormGroup.controls.query?.errors"
                >Query payload is required</span
            >
        </div>
    </div>

    <div class="modal-footer">
        <button
            type="button"
            class="button button--outline"
            (click)="activeModal.close()">
            Close
        </button>
        <button
            type="submit"
            class="button button--primary"
            [disabled]="!queryFormGroup.valid">
            Edit query
        </button>
    </div>
</form>
