import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';
import Comment from 'src/app/models/Comment';
import DateHelper from 'src/app/services/DateHelper';

import { ConfirmationModalComponent } from '../../confirmation-modal/confirmation-modal.component';
import { DeleteComment, UpdateComment } from '../comments.actions';

@Component({
    selector: 'app-comment-view',
    templateUrl: './comment-view.component.html',
    styleUrls: ['./comment-view.component.scss'],
})
export class CommentViewComponent implements OnInit, OnDestroy {
    @Input() comment: Comment;
    @Input() userId: string;
    editing = false;
    editable = false;
    editLoading = false;
    content = '';
    timezone = DateHelper.getLocalTimeZone();
    private subscriptions = new Subscription();

    constructor(private store: Store, private modalService: NgbModal) {}

    ngOnInit(): void {
        if (this.comment.creatorUuid === this.userId) {
            this.editable = true;
        }

        this.content = this.comment.content;
    }

    edit() {
        this.editLoading = true;
        this.subscriptions.add(
            this.store.dispatch(new UpdateComment(this.comment.commentableId, this.comment.uuid, this.content)).subscribe(() => {
                this.editing = false;
                this.editLoading = false;
            }),
        );
    }

    delete() {
        const modalRef = this.modalService.open(ConfirmationModalComponent, {
            windowClass: 'modal-prompt',
            animation: false,
        });
        modalRef.componentInstance.type = 'Delete';
        modalRef.componentInstance.title = 'Delete comment';
        modalRef.componentInstance.message = 'Are you sure you want to delete this comment?';
        this.subscriptions.add(
            modalRef.componentInstance.closeEvent.subscribe((val) => {
                if (val) {
                    this.store.dispatch(new DeleteComment(this.comment.commentableId, this.comment.uuid));
                }
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
