import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { MatTableExporterDirective } from 'mat-table-exporter';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { MailchimpService } from 'src/app/mailchimp/mailchimp.service';
import { Employee } from 'src/app/models/Employee';
import Project from 'src/app/models/Project';
import { ConfirmationModalComponent } from 'src/app/shared/confirmation-modal/confirmation-modal.component';
import AddNewRecipientModel from 'src/app/shared/mailchimp/add-recipient-modal/AddNewRecipientModel';
import { AddRecipientsToListModalComponent } from 'src/app/shared/mailchimp/add-recipients-to-list-modal/add-recipients-to-list-modal.component';

import { EmployeesArchiveModalComponent } from '../employees-archive-modal/employees-archive-modal.component';
import { EmployeesDeleteModalComponent } from '../employees-delete-modal/employees-delete-modal.component';
import { EmployeesDetailModalComponent } from '../employees-detail-modal/employees-detail-modal.component';
import { EmployeesDetailComponent } from '../employees-detail/employees-detail.component';
import { DeleteEmployee, FetchProjectList } from '../employees.actions';
import { EmployeeState } from '../employees.state';

@Component({
    selector: 'app-employees-list',
    templateUrl: './employees-list.component.html',
    styleUrls: ['./employees-list.component.scss'],
})
export class EmployeesListComponent implements OnInit, OnDestroy {
    @Select(EmployeeState.Employees) employees$: Observable<Employee[]>;
    @Select(EmployeeState.Projects) projects$: Observable<Project[]>;
    @Input() archived = false;
    filter: string;
    employees: Employee[];
    allCheck = false;
    checkBoxes: any[] = [];
    displayedColumns: string[] = [
        'checkBox',
        'firstName',
        'contactInformation.phoneNumber',
        'contactInformation.email',
        'profession',
        'hireDate',
        'birthday',
        'jiraLink',
        'edit',
    ];
    dataSource: MatTableDataSource<Employee>;
    projects: Project[];
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatTableExporterDirective, { static: true }) exporter: MatTableExporterDirective;
    private subscriptions = new Subscription();

    constructor(
        private modalService: NgbModal,
        private activeModal: NgbActiveModal,
        private logger: NGXLogger,
        private toastr: ToastrService,
        private mailchimpService: MailchimpService,
        private store: Store,
    ) {}

    ngOnInit(): void {
        this.subscriptions.add(
            this.employees$.subscribe((employees) => {
                /* setup datasource */
                const filteredEmployees: Employee[] = employees.filter((e) => {
                    if (this.archived) return e.archived;
                    return !e.archived;
                });
                this.dataSource = new MatTableDataSource(filteredEmployees);
                this.dataSource.sort = this.sort;

                /* sortingDataAccessor to sort on nested objs */
                this.dataSource.sortingDataAccessor = (item, property) => {
                    if (property.includes('.')) return property.split('.').reduce((o, i) => o[i], item);
                    return item[property];
                };
                this.checkBoxes = [];
                this.employees = filteredEmployees;
                this.employees.forEach((e) => {
                    const check = {
                        employee: e.uuid,
                        value: false,
                    };
                    this.checkBoxes.push(check);
                });
            }),
        );
        this.store.dispatch(new FetchProjectList());
        this.subscriptions.add(
            this.projects$.subscribe((projects) => {
                this.projects = projects;
            }),
        );
    }

    applyFilter(filterValue) {
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }

    getCurrentCheck(employee: Employee) {
        if (this.checkBoxes.find((check) => check.employee === employee.uuid) != undefined) {
            return this.checkBoxes.find((check) => check.employee === employee.uuid).value;
        } else {
            const check = { employee: employee.uuid, value: false };
            this.checkBoxes.push(check);
            return check.value;
        }
    }

    checkChange(employee: Employee) {
        this.checkBoxes.find((check) => check.employee === employee.uuid).value = !this.checkBoxes.find((check) => check.employee === employee.uuid).value;
    }

    checkAll() {
        this.allCheck = !this.allCheck;
        this.checkBoxes.forEach((el) => {
            el.value = this.allCheck;
        });
    }

    getAmount() {
        return this.checkBoxes.filter((e) => e.value).length;
    }

    addToMailchimp() {
        if (this.getAmount() <= 0) {
            this.toastr.warning('You have not selected any employees.');
        } else {
            this.logger.debug('Checking if Mailchimp api key exists');
            this.subscriptions.add(
                this.mailchimpService.checkIfApiKeyExists().subscribe(
                    (res) => {
                        if (res.key) {
                            this.logger.debug('Found Mailchimp key');
                            const recipients: AddNewRecipientModel[] = [];
                            this.employees.forEach((employee) => {
                                if (this.checkBoxes.find((check) => check.employee === employee.uuid).value) {
                                    const rec: AddNewRecipientModel = {
                                        firstname: employee.firstName,
                                        lastname: employee.lastName,
                                        email: employee.contactInformation.email,
                                        phone: employee.contactInformation.phoneNumber,
                                        tags: [],
                                        listId: '',
                                    };
                                    recipients.push(rec);
                                }
                            });
                            this.logger.debug('opening addRecipientsToListModalComponent');
                            const modalRef = this.modalService.open(AddRecipientsToListModalComponent, {
                                windowClass: 'modal-prompt',
                                animation: false,
                            });
                            modalRef.componentInstance.recipients = recipients;
                        } else {
                            this.logger.debug('No Mailchimp key found');
                            this.toastr.warning('Please set it in the module settings.', 'Missing mailchimp key');
                        }
                    },
                    (error) => this.logger.error('Failed to find Mailchimp key'),
                ),
            );
        }
    }

    deleteEmployees() {
        if (this.getAmount() <= 0) {
            this.toastr.warning('You have not selected any employees.');
        } else {
            const selectedEmployees = this.employees.filter((employee) => this.checkBoxes.find((check) => check.employee === employee.uuid).value);

            this.logger.debug('Opening confirmationModalComponent type:delete');
            const modalRef = this.modalService.open(ConfirmationModalComponent, {
                windowClass: 'modal-prompt',
                animation: false,
            });
            modalRef.componentInstance.type = 'Delete';
            modalRef.componentInstance.title = 'Delete employees';
            if (this.getAmount() == 1) {
                modalRef.componentInstance.message = 'Are you sure you want to delete ' + this.getAmount() + ' employee?';
            } else {
                modalRef.componentInstance.message = 'Are you sure you want to delete ' + this.getAmount() + ' employees?';
            }
            modalRef.componentInstance.closeEvent.subscribe((val) => {
                if (val) {
                    this.logger.debug('Attempt to delete employees');
                    selectedEmployees.forEach((employee) => {
                        this.store.dispatch(new DeleteEmployee(employee.uuid)).subscribe(
                            () => {
                                this.logger.debug('Succesfully deleted employee');
                            },
                            (error) => this.logger.error('Failed to delete employee with id:' + employee.uuid),
                        );
                    });
                    if (this.getAmount() == 1) {
                        this.toastr.info(this.getAmount() + ' employee deleted.');
                    } else {
                        this.toastr.info(this.getAmount() + ' employees deleted.');
                    }
                    this.checkAll();
                }
            });
        }
    }

    openUpdateEmployeeModal(employee: Employee) {
        this.logger.debug('Opening employeesDetailModal - update');
        const modalRef = this.modalService.open(EmployeesDetailModalComponent, {
            windowClass: 'modal-pane',
            animation: false,
        });
        modalRef.componentInstance.employeeUuid = employee.uuid;
        modalRef.componentInstance.inOverview = false;
    }

    openEmployeeDetailModal(employee: Employee) {
        this.logger.debug('Opening employeesDetail');
        const modalRef = this.modalService.open(EmployeesDetailComponent, {
            windowClass: 'modal-huge',
            animation: false,
        });
        modalRef.componentInstance.employeeUuid = employee.uuid;
    }

    openEmployeeArchiveModal(employee: string) {
        this.logger.debug('Opening employeesArchiveModal');
        const modalRef = this.modalService.open(EmployeesArchiveModalComponent, {
            windowClass: 'modal-prompt',
            animation: false,
        });
        modalRef.componentInstance.employee = employee;
    }

    openEmployeeDeleteModal(employee) {
        this.logger.debug('Opening employeesDeleteModal');
        const modalRef = this.modalService.open(EmployeesDeleteModalComponent, {
            windowClass: 'modal-prompt',
            animation: false,
        });
        modalRef.componentInstance.employee = employee;
        this.subscriptions.add(modalRef.componentInstance.closeEvent.subscribe(() => this.activeModal.dismiss()));
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
