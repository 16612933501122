import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';

import { PlanningTimelineComponent } from '../planning-timeline/planning-timeline.component';

@Component({
    selector: 'app-planning-overview',
    templateUrl: './planning-overview.component.html',
    styleUrls: ['./planning-overview.component.scss'],
})
export class PlanningOverviewComponent implements OnInit {
    @ViewChild(PlanningTimelineComponent)
    private planningTimelineComponent: PlanningTimelineComponent;

    view = 'timeline';

    constructor(private logger: NGXLogger, private modalService: NgbModal, private store: Store, private titleService: Title) {}

    ngOnInit(): void {
        this.titleService.setTitle('Planning overview');
    }

    calendarClick() {
        this.planningTimelineComponent.switchViewToCalendar();
    }

    timelineClick() {
        this.planningTimelineComponent.switchViewToTimeline();
    }

    switchView() {
        if (this.view === 'timeline') this.planningTimelineComponent.switchViewToTimeline();
        else this.planningTimelineComponent.switchViewToCalendar();
    }
}
