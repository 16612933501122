<form
    [formGroup]="formGroup"
    (ngSubmit)="onSubmit()"
    class="modal-wrapper">
    <div class="modal-header">
        <h2
            class="modal-title"
            id="exampleModalLabel">
            {{ title }}
        </h2>
        <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="activeModal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <div class="row">
            <div class="col">
                <div
                    class="form-group m-b-2"
                    [ngClass]="{ required: req }">
                    <label>{{ title }}</label>
                    <input
                        type="text"
                        formControlName="input"
                        trim="blur"
                        #focussed />
                </div>
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <button
            type="button"
            class="button button--outline"
            (click)="activeModal.close()">
            Close
        </button>
        <button
            type="submit"
            class="button button--primary">
            Submit
        </button>
    </div>
</form>
