import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subscription } from 'rxjs';

import { LogCategoriesComponent } from '../../logbook/log-categories/log-categories.component';
import { GetAllLogCategories } from '../../logbook/logbook.actions';
import { LogbookState } from '../../logbook/logbook.state';
import { GeneralSettings } from '../../models/GeneralSettings';
import { HolidayDay } from '../../models/HolidayDay';
import LogCategory from '../../models/Logbook/LogCategory';
import { FetchGeneralSettings } from '../settings.actions';
import { SettingsState } from '../settings.state';
import { UpdateDaysPerMonthModalComponent } from '../update-days-per-month-modal/update-days-per-month-modal.component';
import { UpdateHolidayDaysModalComponent } from '../update-holiday-days-modal/update-holiday-days-modal.component';

@Component({
    selector: 'app-general-settings',
    templateUrl: './general-settings.component.html',
    styleUrls: ['./general-settings.component.scss'],
})
export class GeneralSettingsComponent implements OnInit, OnDestroy {
    @Select(SettingsState.GeneralSettings) generalSettings$: Observable<GeneralSettings>;
    generalSettings: GeneralSettings;

    @Select(LogbookState.LogCategories) logCategories$: Observable<LogCategory[]>;
    logCategories: LogCategory[];

    private subscriptions = new Subscription();

    constructor(private modalService: NgbModal, private logger: NGXLogger, private store: Store) {}

    ngOnInit(): void {
        this.fetchData();
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    openUpdateDaysPerMonthModal() {
        const modalRef = this.modalService.open(UpdateDaysPerMonthModalComponent, { windowClass: 'modal-prompt', animation: false });
        modalRef.componentInstance.days = this.generalSettings.daysPerMonth;
    }

    openUpdateHolidayDaysModal() {
        const modalRef = this.modalService.open(UpdateHolidayDaysModalComponent, { windowClass: 'modal-pane', animation: false });
        modalRef.componentInstance.holidayDays = this.generalSettings.holidayDays.map((holidayDay) => {
            const holiday: HolidayDay = {
                description: holidayDay.description,
                localDate: new Date(holidayDay.localDate),
            };
            return holiday;
        });
    }

    openLogbookCategoriesModal() {
        const modalRef = this.modalService.open(LogCategoriesComponent, { windowClass: 'modal-pane', animation: false });
        modalRef.componentInstance.logCategories = this.logCategories;
    }

    private fetchData() {
        this.subscriptions.add(
            this.generalSettings$.subscribe((settings) => {
                if (!settings) {
                    this.logger.debug('Fetching General settings');
                    this.store.dispatch(new FetchGeneralSettings());
                    return;
                }
                this.generalSettings = settings;
            }),
        );

        this.subscriptions.add(
            this.logCategories$.subscribe((categories) => {
                if (categories === undefined) {
                    this.store.dispatch(new GetAllLogCategories());
                    return;
                }
                this.logCategories = [...categories];
            }),
        );
    }
}
