<form
    [formGroup]="relationFormGroup"
    (ngSubmit)="submit()"
    class="modal-wrapper">
    <div class="modal-header">
        <h2
            *ngIf="!relation"
            class="modal-title"
            id="exampleModalLabel">
            New relation
        </h2>
        <h2
            *ngIf="relation"
            class="modal-title"
            id="exampleModalLabel">
            Edit relation
        </h2>
        <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="activeModal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <div class="row">
            <div class="col">
                <div class="form-group required m-b-2">
                    <label>Name</label>
                    <input
                        type="text"
                        formControlName="Name"
                        trim="blur" />
                    <span
                        class="form-error"
                        *ngIf="relationFormGroup.get('Name').touched && !relationFormGroup.get('Name').valid"
                        >name required and needs to be between 1 and 50 characters long</span
                    >
                </div>
            </div>
        </div>
        <div
            class="row"
            *ngIf="!relation">
            <div class="col">
                <div class="form-group m-b-2">
                    <label for="Type">Type</label>
                    <select
                        id="Type"
                        formControlName="Type">
                        <option value="IsSales">Customer</option>
                        <option value="IsSupplier">Supplier</option>
                        <option value="IsAccountant">Accountant</option>
                        <option value="IsReseller">Reseller</option>
                        <option value="IsCompetitor">Competitor</option>
                    </select>
                </div>
            </div>
        </div>

        <h3 class="m-t-4 m-b-4">Address information</h3>

        <div class="row">
            <div class="col">
                <div class="form-group m-b-2">
                    <label>Address</label>
                    <input
                        type="text"
                        formControlName="AddressLine1"
                        trim="blur" />
                </div>
            </div>
            <div class="col">
                <div class="form-group m-b-2">
                    <label>Postal Code</label>
                    <input
                        type="text"
                        formControlName="Postcode"
                        trim="blur" />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-5">
                <div class="form-group m-b-2">
                    <label>City</label>
                    <input
                        type="text"
                        formControlName="City"
                        trim="blur" />
                </div>
            </div>
            <div class="col-5">
                <div class="form-group m-b-2">
                    <label>State</label>
                    <input
                        type="text"
                        formControlName="State"
                        trim="blur" />
                </div>
            </div>
            <div class="col-2">
                <div class="form-group m-b-2">
                    <label>Country</label>
                    <input
                        type="text"
                        placeholder="BE"
                        formControlName="Country"
                        trim="blur" />
                </div>
            </div>
        </div>
        <span
            class="row form-error"
            *ngIf="relationFormGroup.get('Country').touched && !relationFormGroup.get('Country').valid"
            >Please enter the code of your country (like BE of US)</span
        >
        <div class="row">
            <div class="col">
                <div class="form-group m-b-2">
                    <label>Phonenumber</label>
                    <input
                        type="text"
                        formControlName="Phone"
                        placeholder="Phonenumber"
                        trim="blur" />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="form-group m-b-2">
                    <label>Emailaddress</label>
                    <input
                        type="text"
                        formControlName="Email"
                        placeholder="Emailaddress"
                        trim="blur" />
                    <span
                        class="form-error"
                        *ngIf="relationFormGroup.get('Email').touched && !relationFormGroup.get('Email').valid"
                        >please enter a valid email</span
                    >
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="form-group m-b-2">
                    <label>Website</label>
                    <input
                        type="text"
                        formControlName="Website"
                        trim="blur" />
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button
            type="button"
            class="button button--outline"
            (click)="activeModal.close()">
            Close
        </button>
        <input
            *ngIf="!relation"
            [ngClass]="!relationFormGroup.valid ? 'button button--disabled' : 'button button--primary'"
            type="submit"
            value="Add relation"
            [disabled]="!relationFormGroup.valid" />
        <input
            *ngIf="relation"
            [ngClass]="!relationFormGroup.valid ? 'button button--disabled' : 'button button--primary'"
            type="submit"
            value="Edit relation"
            [disabled]="!relationFormGroup.valid" />
    </div>
</form>
