<div>
    <span>
        {{ applicant.firstName + ' ' + applicant.lastName }}

        <span [ngClass]="getBadgeClass()">{{applicant.priority}}</span>
        
        <span
            class="badge badge--danger"
            *ngIf="isOverdue(applicant.dateSinceLastMovement, lane.rottingIn, lane.type)"
            >Overdue</span
        >
        <span
            class="badge badge--warning"
            *ngIf="isWarning(applicant.dateSinceLastMovement, lane.rottingIn, lane.type)"
            >Hurry</span
        >
    </span>
    <br />
    {{ applicant.contactInformation.email }}
    
    <div *ngIf="applicant.tags && applicant.tags.length > 0">
        <br/>
        <span *ngFor="let tag of applicant.tags" class="tag"> {{ tag.title }}</span>
    </div>
</div>
