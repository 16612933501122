import { Component, OnDestroy, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';

import Project from '../../models/Project';
import { FetchProjectsForOverview } from '../../projects/project.actions';
import { ProjectState } from '../../projects/project.state';

@Component({
    selector: 'app-project-graphs',
    templateUrl: './project-graphs.component.html',
    styleUrls: ['./project-graphs.component.scss'],
})
export class ProjectGraphsComponent implements OnInit, OnDestroy {
    @Select(ProjectState.projects) projects$: Observable<Project[]>;

    private subscriptions = new Subscription();

    constructor(private store: Store) {}

    ngOnInit(): void {
        this.fetchData();
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    private fetchData() {
        this.subscriptions.add(
            this.projects$.subscribe((employees) => {
                if (!employees) this.store.dispatch(new FetchProjectsForOverview());
            }),
        );
    }
}
