<div class="module__logo">
    <img src="../../../assets/img/teamleader.png" />
</div>

<div>
    <button *ngIf="!this.loggedInTeamleader">
        <a [href]="this.teamleaderAuth">Login With Teamleader</a>
    </button>

    <div *ngIf="this.loggedInTeamleader">
        <h1>Logged In Successfully</h1>
        <button (click)="logoutTeamleader()">Logout</button>
    </div>
</div>
