<div
    *ngIf="budget"
    class="modal-header">
    <h2 class="modal-title">
        <i
            *ngIf="budget.recurring"
            [ngbTooltip]="recurringTip"
            class="las la-redo-alt m-r-2"></i>
        {{ budget.budgetType }} <span>for <app-pill-employee [employeeId]="budget.linkedEmployee.uuid" class="m-l-2"></app-pill-employee></span>
        <ng-template #recurringTip>Recurring budget</ng-template>
    </h2>

    <button
        (click)="activeModal.dismiss()"
        aria-label="Close"
        class="close"
        type="button">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<nav
    #nav="ngbNav"
    class="tabs__modal"
    ngbNav>
    <ng-container ngbNavItem>
        <a ngbNavLink>Information</a>
        <ng-template ngbNavContent>
            <div *ngIf="budget; else loading">
                <app-primary-message
                    (click)="openAddBudgetModal()"
                    *ngIf="attentionLevel === 1 || attentionLevel === 2"
                    [messageSuccess]="messageSuccess"
                    [messageUrgent]="messageUrgent"
                    [messageWarning]="messageWarning"
                    [message]="messageBody"
                    [title]="messageTitle">
                </app-primary-message>
                <ul class="list__specs">
                    <li>
                        <label>Total to spend</label>
                        <span>{{ budget.totalSpend | currency: 'EUR' }}</span>
                    </li>
                    <li *ngIf="budget.budgetExpenditures !== null && budget.budgetExpenditures.length > 0">
                        <label>Total budget spend</label>
                        <span
                            [ngClass]="{
                                overBudget: totalSpend > budget.totalSpend
                            }"
                            class="d-flex flex-column align-items-center"
                            >{{ totalSpend | currency: 'EUR' }}
                            <app-percentage-progress
                                [percentage]="getTotalSpendPercentage(budget)"
                                [showPercentage]="false"></app-percentage-progress>
                        </span>
                    </li>
                    <li *ngIf="budget.budgetExpenditures !== null && budget.budgetExpenditures.length > 0">
                        <label>Remaining budget</label>
                        <span
                            [ngClass]="{
                                overBudget: totalSpend > budget.totalSpend
                            }"
                            >{{ budget.totalSpend - totalSpend | currency: 'EUR' }}</span
                        >
                    </li>

                    <li>
                        <label>Start date</label><span>{{ budget.startDate | date }}</span>
                    </li>
                    <li>
                        <label>End date</label><span>{{ budget.endDate | date }}</span>
                    </li>

                    <li>
                        <label>Sends notification</label>
                        <span *ngIf="budget.sendNotification"
                            ><i
                                class="las la-check-circle"
                                style="color: green"></i
                        ></span>
                        <span *ngIf="!budget.sendNotification"
                            ><i
                                class="las la-times-circle"
                                style="color: red"></i
                        ></span>
                    </li>
                    <li *ngIf="budget.sendNotification">
                        <label>Notification send after</label>
                        <span>{{ budget.notificationDate | date }}</span>
                    </li>

                    <ng-container *ngIf="budget.recurring">
                        <li>
                            <label>Repeats</label>
                            <span>{{ findRepeat(budget.repeat).title }}</span>
                        </li>
                    </ng-container>

                    <li>
                        <label>Financial</label>
                        <span>
                            <ng-container *ngIf="budget.useExpendituresForFinancials">
                                Only expenditures
                            </ng-container>
                            <ng-container *ngIf="!budget.useExpendituresForFinancials">
                                Budgeted cost
                            </ng-container>
                        </span>
                    </li>
                </ul>
            </div>
        </ng-template>
    </ng-container>

    <ng-container ngbNavItem>
        <a ngbNavLink>Expenditures</a>
        <ng-template ngbNavContent>
            <div
                *ngIf="budget; else loading"
                class="table__overflow">

                <p *ngIf="budget.useExpendituresForFinancials" class="form-helptext">
                    This budget only uses expenditures for financials. When no expenditures are added, the cost for this budget will be 0.
                </p>

                <table
                    [dataSource]="budget.budgetExpenditures"
                    mat-table
                    matSort>
                    <ng-container matColumnDef="description">
                        <th
                            *matHeaderCellDef
                            mat-header-cell
                            mat-sort-header>
                            Description
                        </th>
                        <td
                            *matCellDef="let budgetExpenditure"
                            mat-cell>
                            {{ budgetExpenditure.expenditure }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="cost">
                        <th
                            *matHeaderCellDef
                            mat-header-cell
                            mat-sort-header>
                            Cost
                        </th>
                        <td
                            *matCellDef="let budgetExpenditure"
                            mat-cell>
                            {{ budgetExpenditure.cost | currency: 'EUR' }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="date">
                        <th
                            *matHeaderCellDef
                            mat-header-cell
                            mat-sort-header>
                            Date
                        </th>
                        <td
                            *matCellDef="let budgetExpenditure"
                            mat-cell>
                            {{ budgetExpenditure.date | date }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="factuur">
                        <th
                            *matHeaderCellDef
                            mat-header-cell
                            mat-sort-header>
                            Factuur
                        </th>
                        <td
                            *matCellDef="let budgetExpenditure"
                            mat-cell>
                            <app-pill-pdf
                                *ngIf="budgetExpenditure.invoiceUrl"
                                [exists]="budgetExpenditure.invoiceUrl"
                                [fileName]="getFileName(budgetExpenditure.invoiceUrl)"
                                [pdfAsBase64$]="getBase64(budgetExpenditure)"
                                [url]="budgetExpenditure.invoiceUrl"></app-pill-pdf>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="edit">
                        <th
                            *matHeaderCellDef
                            class="td-small"
                            mat-header-cell>
                            <span ngbDropdown>
                                <button
                                    (click)="$event.stopPropagation()"
                                    class="button button--icon button--dropdown button--small m-l-1"
                                    ngbDropdownToggle></button>
                                <span
                                    aria-labelledby="dropdownMenuButton"
                                    class="dropdown-menu dropdown-menu-right"
                                    ngbDropdownMenu>
                                    <app-table-settings
                                        [allColumns]="getAllColumns()"
                                        [defaultColumns]="getDefaultColumns()"
                                        [tableId]="'budget-detail'"></app-table-settings>
                                </span>
                            </span>
                        </th>
                        <td
                            *matCellDef="let budgetExpenditure"
                            class="td-small"
                            mat-cell>
                            <span
                                class="dropdown"
                                ngbDropdown>
                                <button
                                    class="button button--icon button--small dropdown-toggle"
                                    id="dropdownExpenditures"
                                    ngbDropdownToggle></button>
                                <div
                                    aria-labelledby="dropdownExpenditures"
                                    class="dropdown-menu dropdown-menu-right"
                                    ngbDropdownMenu>
                                    <a
                                        (click)="openUpdateBudgetExpenditureModal(budgetExpenditure)"
                                        *appIsPermitted="{
                                            module: 'BUDGET',
                                            operator: 'AND',
                                            actions: ['UPDATE']
                                        }"
                                        class="dropdown-item"
                                        >Edit budget expenditure</a
                                    >
                                    <a
                                        (click)="deleteBudgetExpenditure(budgetExpenditure)"
                                        *appIsPermitted="{
                                            module: 'BUDGET',
                                            operator: 'AND',
                                            actions: ['DELETE']
                                        }"
                                        class="dropdown-item color--red"
                                        >Delete</a
                                    >
                                </div>
                            </span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="add">
                        <td
                            (click)="openAddBudgetExpenditureModal()"
                            *matFooterCellDef
                            class="table-view__add"
                            colspan="5"
                            mat-footer-cell>
                            <i class="las la-plus"></i> Add budget expenditure
                        </td>
                    </ng-container>

                    <tr
                        *matHeaderRowDef="displayedColumns"
                        mat-header-row></tr>
                    <tr
                        *matRowDef="let row; columns: displayedColumns"
                        mat-row></tr>

                    <tr
                        *matFooterRowDef="['add']"
                        mat-footer-row></tr>
                </table>
            </div>
        </ng-template>
    </ng-container>
</nav>

<div class="modal-body">
    <div [ngbNavOutlet]="nav"></div>
</div>

<div class="modal-footer">
    <span
        class="dropdown"
        ngbDropdown>
        <button
            class="button dropdown-toggle"
            id="dropdownMenuButton"
            ngbDropdownToggle>
            Options
        </button>
        <div
            aria-labelledby="dropdownMenuButton"
            class="dropdown-menu dropdown-menu-right"
            ngbDropdownMenu>
            <div *ngIf="budget; else loading">
                <span *ngIf="budget.archived; else isNotArchived">
                    <span
                        *appIsPermitted="{
                            module: 'BUDGET',
                            operator: 'AND',
                            actions: ['DELETE']
                        }">
                        <a
                            (click)="openArchiveBudgetModal()"
                            class="dropdown-item color--red"
                            >Unarchive budget</a
                        >
                        <a
                            (click)="openDeleteBudgetModal()"
                            class="dropdown-item color--red"
                        >Delete budget</a
                        >
                    </span>
                </span>
                <ng-template #isNotArchived>
                    <a
                        (click)="openBudgetUpdateModal()"
                        *appIsPermitted="{
                            module: 'BUDGET',
                            operator: 'AND',
                            actions: ['UPDATE']
                        }"
                        class="dropdown-item"
                        >Edit budget</a
                    >
                    <div class="dropdown-divider"></div>
                    <a
                        (click)="openArchiveBudgetModal()"
                        class="dropdown-item color--red"
                        >Archive budget</a
                    >
                    <a
                        (click)="openDeleteBudgetModal()"
                        class="dropdown-item color--red"
                    >Delete budget</a
                    >
                </ng-template>
            </div>
        </div>
    </span>
</div>

<ng-template #loading>
    <app-loading-message></app-loading-message>
</ng-template>
