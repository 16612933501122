import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { AuthenticationService } from '../authentication/authentication.service';
import { Query } from '../models/Query';
import { QueryDTO } from '../models/QueryDTO';

@Injectable({
    providedIn: 'root',
})
export class QueryService {
    httpOptions = {};

    constructor(private httpClient: HttpClient, private authenticationService: AuthenticationService) {
        this.httpOptions = authenticationService.getHeaders();
    }

    getAllQueries(): Observable<Query[]> {
        return this.httpClient.get<Query[]>(environment.baseUrl + '/query', this.httpOptions);
    }

    getLanguages(): Observable<string> {
        const langugesQuery: QueryDTO = {
            type: 'custom',
            payload: `{
        entries(section: "languages", orderBy: "country") {
          id
          title
          sectionHandle
          slug
          ... on languages_default_Entry {
            id
            country
            languageCode
          }
        }
      }`,
        };

        return this.httpClient.post<string>(environment.baseUrl + '/query/run', langugesQuery, this.httpOptions);
    }

    addQuery(query: QueryDTO): Observable<Query> {
        return this.httpClient.post<Query>(environment.baseUrl + '/query', query, this.httpOptions);
    }

    editQuery(queryUuid: string, query: QueryDTO): Observable<Query> {
        return this.httpClient.put<Query>(`${environment.baseUrl}/query/${queryUuid}`, query, this.httpOptions);
    }

    runQueryByType(type: string): Observable<string> {
        return this.httpClient.get<string>(`${environment.baseUrl}/query/run/${type}`, this.httpOptions);
    }

    runCustomQuery(queryDTO: QueryDTO): Observable<string> {
        return this.httpClient.post<string>(`${environment.baseUrl}/query/run`, queryDTO, this.httpOptions);
    }

    deleteQueryByUuid(queryUuid: string): Observable<string> {
        return this.httpClient.delete<string>(`${environment.baseUrl}/query/${queryUuid}`, this.httpOptions);
    }
}
