import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { MONTHS } from '../../models/MONTHS';
import { capitalizeFirstLetter } from '../../shared/utils/string-utils';

@Component({
    selector: 'app-recurrence-dialog',
    templateUrl: './recurrence-dialog.component.html',
    styleUrls: ['./recurrence-dialog.component.scss'],
})
export class RecurrenceDialogComponent implements OnInit {
    @Input() rule;

    recurrenceForm: FormGroup;
    public closeEvent: EventEmitter<boolean> = new EventEmitter();

    months: MONTHS[] = [];

    constructor(public activeModal: NgbActiveModal, private fb: FormBuilder) {}

    ngOnInit(): void {
        this.createRecurrenceForm();
        this.months = Object.values(MONTHS);
    }

    addRecurrenceRule() {
        this.closeEvent.emit(this.recurrenceForm.value);
        this.activeModal.close();
    }

    repeatClick(day: string) {
        const weekDays: string = this.recurrenceForm.value.weekDays ?? '';
        const values: string[] = weekDays.split(',');
        if (this.recurrenceForm.get('weekDays').value && this.recurrenceForm.get('weekDays').value.includes(day)) {
            values.splice(
                values.findIndex((v) => v === day),
                1,
            );
        } else {
            values.push(day);
        }

        let repeat = '';
        for (let i = 0; i < values.length - 1; i++) {
            if (values[i] !== '' && values[i]) {
                repeat += `${values[i]},`;
            }
        }
        repeat += `${values[values.length - 1]}`;
        this.recurrenceForm.get('weekDays').setValue(repeat);
    }

    private createRecurrenceForm(): void {
        if (this.rule) {
            this.recurrenceForm = this.fb.group({
                repeat: this.rule.repeat ?? 'WEEKLY',
                interval: this.rule.interval ?? 1,
                day: this.rule.day,
                month: this.rule.month,
                weekDays: this.rule.weekDays,
            });
        } else {
            this.recurrenceForm = this.fb.group({
                repeat: 'WEEKLY',
                interval: 1,
                day: '',
                month: '',
                weekDays: '',
            });
        }
    }

    capitalizeFirstLetter = capitalizeFirstLetter;
}
