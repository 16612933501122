import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { AuthenticationService } from '../authentication.service';

@Component({
    selector: 'app-google-login',
    templateUrl: './google-login.component.html',
    styleUrls: ['./google-login.component.scss'],
})
export class GoogleLoginComponent {
    constructor(private router: Router, private authenticationService: AuthenticationService) {}

    // Login a user with his/her Google account
    loginWithGoogle(): void {
        /*this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
    this.authService.authState.subscribe((user) => {
      this.authenticationService.registerGoogleUser(user.email, user.photoUrl).subscribe();
      if (this.authenticationService.getToken()) {
        this.router.navigate(['/', 'resume', 'overview']);
      }
    });*/
    }
}
