import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Options } from 'sortablejs';
import { Status } from 'src/app/models/Status';
import { ProjectStatusDeleteModalComponent } from 'src/app/projects/project-status-delete-modal/project-status-delete-modal.component';
import { ProjectStatusDetailModalComponent } from 'src/app/projects/project-status-detail-modal/project-status-detail-modal.component';

@Component({
    selector: 'app-company-project-status-list',
    templateUrl: './company-project-status-list.component.html',
    styleUrls: ['./company-project-status-list.component.scss'],
})
export class CompanyProjectStatusListComponent {
    @Input() companyProjectStatusList: Status[];
    statusProjectOrder: any[] = [];
    noStatusColour = '#4834d4';
    options: Options = {
        group: 'test',
    };

    constructor(private modalService: NgbModal, private store: Store, private router: Router, private logger: NGXLogger) {
        this.options = {
            onUpdate: (event: any) => {
                this.statusProjectOrder.push(event);
                console.log(this.statusProjectOrder);
            },
        };
    }

    openProjectStatusUpdateModal(projectStatus: Status) {
        this.logger.debug('opening projectStatusDetailModalComponent');
        const modalRef = this.modalService.open(ProjectStatusDetailModalComponent, { windowClass: 'modal-prompt', animation: false });
        modalRef.componentInstance.projectStatus = projectStatus;
    }

    openProjectStatusDeleteModal(projectStatus: Status) {
        this.logger.debug('opening ProjectStatusDeleteModalComponent');
        const modalRef = this.modalService.open(ProjectStatusDeleteModalComponent, { windowClass: 'modal-prompt', animation: false });
        modalRef.componentInstance.projectStatusUuid = projectStatus.uuid;
    }
}
