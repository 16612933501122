import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-calendar-event-detail-modal',
    templateUrl: './calendar-event-detail-modal.component.html',
    styleUrls: ['./calendar-event-detail-modal.component.scss'],
})
export class CalendarEventDetailModalComponent {
    @Input() calendarEvent: any;

    constructor(public activeModal: NgbActiveModal) {}
}
