import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { AuthenticationService } from '../authentication/authentication.service';
import { GeneralSettings } from '../models/GeneralSettings';
import TableDisplayedColumns from '../models/TableDisplayedColumns';

@Injectable({
    providedIn: 'root',
})
export class SettingsService {
    httpOptions = {};

    constructor(private httpClient: HttpClient, private authenticationService: AuthenticationService) {
        this.httpOptions = authenticationService.getHeaders();
    }

    getGeneralSettings(): Observable<GeneralSettings> {
        return this.httpClient.get<GeneralSettings>(`${environment.baseUrl}/settings`, this.httpOptions);
    }

    updateDaysPerMonth(daysPerMonth: number): Observable<void> {
        return this.httpClient.put<void>(`${environment.baseUrl}/settings/days-per-month/${daysPerMonth}`, {}, this.httpOptions);
    }

    updateHolidayDays(settings: GeneralSettings): Observable<void> {
        return this.httpClient.put<void>(`${environment.baseUrl}/settings/holiday-days`, settings, this.httpOptions);
    }

    //DisplayedColumns
    getAllTableDisplayedColumns(): Observable<TableDisplayedColumns[]> {
        return this.httpClient.get<TableDisplayedColumns[]>(`${environment.baseUrl}/table-displayed-columns`, this.httpOptions);
    }

    getTableDisplayedColumns(id: string): Observable<TableDisplayedColumns> {
        return this.httpClient.get<TableDisplayedColumns>(`${environment.baseUrl}/table-displayed-columns/${id}`, this.httpOptions);
    }

    setTableDisplayedColumns(tableDisplayedColumns: TableDisplayedColumns): Observable<TableDisplayedColumns> {
        return this.httpClient.put<TableDisplayedColumns>(`${environment.baseUrl}/table-displayed-columns`, tableDisplayedColumns, this.httpOptions);
    }
}
