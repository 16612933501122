import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NGXLogger } from 'ngx-logger';
import { EmployeesDetailComponent } from 'src/app/employees/employees-detail/employees-detail.component';
import { Employee } from 'src/app/models/Employee';

import { AssignedEmployeeDeleteModalComponent } from '../assigned-employee-delete-modal/assigned-employee-delete-modal.component';

@Component({
    selector: 'app-assigned-employee-list',
    templateUrl: './assigned-employee-list.component.html',
    styleUrls: ['./assigned-employee-list.component.scss'],
})
export class AssignedEmployeeListComponent implements OnInit {
    @Input() object: any;
    @Input() assignedEmployees: Employee[];

    loading = false;

    constructor(private modalService: NgbModal, private logger: NGXLogger) {}

    ngOnInit(): void {}

    previewEmployee(employee: Employee) {
        this.logger.debug('Opening employeeDetailComponent');
        const modalRef = this.modalService.open(EmployeesDetailComponent, {
            windowClass: 'modal-huge',
            animation: false,
        });
        modalRef.componentInstance.employeeUuid = employee.uuid;
    }

    openDeleteAssignedEmployeeModal(employee: Employee) {
        this.logger.debug('Opening assignedEmployeeDeleteModal');
        const modalRef = this.modalService.open(AssignedEmployeeDeleteModalComponent, { windowClass: 'modal-prompt' });
        modalRef.componentInstance.object = this.object;
        modalRef.componentInstance.employee = employee;
    }
}
