<form
    (ngSubmit)="addRecurrenceRule()"
    [formGroup]="recurrenceForm"
    class="modal-wrapper">
    <div class="modal-header">
        <h2
            class="modal-title"
            id="exampleModalLabel">
            Custom recurrence
        </h2>
        <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="activeModal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row d-flex align-items-center m-b-2">
            <div class="col-md-5">Repeat every</div>
            <div class="col-md-2">
                <input
                    type="number"
                    formControlName="interval" />
            </div>
            <div class="col-md-5">
                <select formControlName="repeat">
                    <option value="DAILY">
                        <p *ngIf="recurrenceForm.get('interval').value <= 1; else multipleDay">day</p>
                        <ng-template #multipleDay>days</ng-template>
                    </option>
                    <option value="WEEKLY">
                        <p *ngIf="recurrenceForm.get('interval').value <= 1; else multipleWeek">week</p>
                        <ng-template #multipleWeek>weeks</ng-template>
                    </option>
                    <option value="MONTHLY">
                        <p *ngIf="recurrenceForm.get('interval').value <= 1; else multipleMonth">month</p>
                        <ng-template #multipleMonth>months</ng-template>
                    </option>
                    <option value="YEARLY">
                        <p *ngIf="recurrenceForm.get('interval').value <= 1; else multipleYear">year</p>
                        <ng-template #multipleYear>years</ng-template>
                    </option>
                </select>
            </div>
        </div>
        <div *ngIf="recurrenceForm.get('repeat').value === 'WEEKLY'">
            <div class="row">
                <div class="col">Repeat on</div>
            </div>
            <div class="row">
                <button
                    type="button"
                    (click)="repeatClick('MO')"
                    class="col button button--repeat-day"
                    [ngClass]="{
                        'button--repeat-day--selected': this.recurrenceForm.get('weekDays').value && this.recurrenceForm.get('weekDays').value.includes('MO')
                    }">
                    M
                </button>
                <button
                    type="button"
                    (click)="repeatClick('TU')"
                    class="col button button--repeat-day"
                    [ngClass]="{
                        'button--repeat-day--selected': this.recurrenceForm.get('weekDays').value && this.recurrenceForm.get('weekDays').value.includes('TU')
                    }">
                    T
                </button>
                <button
                    type="button"
                    (click)="repeatClick('WE')"
                    class="col button button--repeat-day"
                    [ngClass]="{
                        'button--repeat-day--selected': this.recurrenceForm.get('weekDays').value && this.recurrenceForm.get('weekDays').value.includes('WE')
                    }">
                    W
                </button>
                <button
                    type="button"
                    (click)="repeatClick('TH')"
                    class="col button button--repeat-day"
                    [ngClass]="{
                        'button--repeat-day--selected': this.recurrenceForm.get('weekDays').value && this.recurrenceForm.get('weekDays').value.includes('TH')
                    }">
                    T
                </button>
                <button
                    type="button"
                    (click)="repeatClick('FR')"
                    class="col button button--repeat-day"
                    [ngClass]="{
                        'button--repeat-day--selected': this.recurrenceForm.get('weekDays').value && this.recurrenceForm.get('weekDays').value.includes('FR')
                    }">
                    F
                </button>
                <button
                    type="button"
                    (click)="repeatClick('SA')"
                    class="col button button--repeat-day"
                    [ngClass]="{
                        'button--repeat-day--selected': this.recurrenceForm.get('weekDays').value && this.recurrenceForm.get('weekDays').value.includes('SA')
                    }">
                    S
                </button>
                <button
                    type="button"
                    (click)="repeatClick('SU')"
                    class="col button button--repeat-day"
                    [ngClass]="{
                        'button--repeat-day--selected': this.recurrenceForm.get('weekDays').value && this.recurrenceForm.get('weekDays').value.includes('SU')
                    }">
                    S
                </button>
            </div>
        </div>
        <div *ngIf="recurrenceForm.get('repeat').value === 'MONTHLY'">
            <div class="row d-flex align-items-center">
                <div class="col-md-2">On day</div>
                <div class="col-md-3">
                    <input
                        type="number"
                        formControlName="day" />
                </div>
            </div>
        </div>
        <div
            *ngIf="recurrenceForm.get('repeat').value === 'YEARLY'"
            class="row d-flex align-items-center">
            <div class="col-md-1">In</div>
            <div class="col-md-6">
                <select
                    name="months"
                    id="months"
                    formControlName="month">
                    <option
                        *ngFor="let month of months"
                        [value]="month.number">
                        {{ capitalizeFirstLetter(month.long) }}
                    </option>
                </select>
            </div>
            <div class="col-md-2 text-center">on day</div>
            <div class="col-md-3">
                <input
                    type="number"
                    formControlName="day" />
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <input
            class="button"
            type="submit"
            value="Done"
            [disabled]="recurrenceForm.invalid"
            [ngClass]="recurrenceForm.invalid ? 'button--disabled' : 'button--primary'" />
        <button
            class="button button--outline"
            (click)="activeModal.dismiss()">
            Cancel
        </button>
    </div>
</form>
