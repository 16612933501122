import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { format, isAfter, isBefore, startOfYear } from 'date-fns';
import { MatTableExporterDirective } from 'mat-table-exporter';
import { Observable, Subscription } from 'rxjs';
import { LinkedObjectsToFileWithId } from 'src/app/models/LinkedObjectsToFileWithId';
import YukiDocument from 'src/app/models/yuki/YukiDocument';
import YukiFolder from 'src/app/models/yuki/YukiFolder';
import { SettingsState } from 'src/app/settings/settings.state';
import { GetAllObjectsLinked } from 'src/app/shared/global-files/global-files.actions';
import { GlobalFilesState } from 'src/app/shared/global-files/global-files.state';

import { LinkedObjectToFile } from '../../models/LinkedObjectToFile';
import { roundNumber } from '../../shared/utils/number-utils';
import { YukiDetailModalComponent } from '../yuki-detail-modal/yuki-detail-modal.component';
import { FetchAllDocuments, FlagYukiDocument, UpdateVisibilityYukiDocument } from '../yuki.actions';
import { YukiState } from '../yuki.state';

@Component({
    selector: 'app-yuki-documents-list',
    templateUrl: './yuki-documents-list.component.html',
    styleUrls: ['./yuki-documents-list.component.scss'],
})
export class YukiDocumentsListComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Select(SettingsState.TableDisplayColumns('yuki-documents-list')) displayedColumns$: Observable<string[]>;
    @Select(YukiState.currentFolder) currentFolder$: Observable<YukiFolder>;
    @Select(YukiState.documentsForCurrentFolder) getDocumentsForCurrentFolder$: Observable<YukiDocument[]>;
    @Select(GlobalFilesState.LinkedObjects) linkedObjects$: Observable<LinkedObjectsToFileWithId[]>;

    filter: string;
    displayedColumns: string[] = [];
    allDisplayedColumns: string[] = [];
    dataSource: MatTableDataSource<YukiDocument> = new MatTableDataSource<YukiDocument>();

    @ViewChild(MatPaginator) paginator!: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatTableExporterDirective, { static: true })
    exporter: MatTableExporterDirective;

    totalAmount: number;
    totalAmountExcl: number;
    totalVATAmount: number;

    currentFolder: YukiFolder = null;
    documents: YukiDocument[] = null;

    private static readonly DEFAULT_LINKED_FILTER_LABEL: string = 'Filter on linked state';

    defaultFilterLabel: string;

    filterLabel: string;
    searchLabel: string;
    visibleChecked = false;
    flaggedChecked = false;
    verifiedChecked = true;
    paidChecked = true;

    startDate: string = format(startOfYear(new Date()), 'yyyy-MM-dd');
    endDate: string = format(new Date(), 'yyyy-MM-dd');
    linkedObjects: LinkedObjectsToFileWithId[];

    constructor(private store: Store, private modalService: NgbModal, private changeDetectorRef: ChangeDetectorRef) {}

    ngOnInit(): void {
        this.filterLabel = YukiDocumentsListComponent.DEFAULT_LINKED_FILTER_LABEL;
        this.defaultFilterLabel = YukiDocumentsListComponent.DEFAULT_LINKED_FILTER_LABEL;

        this.createMatTable();
        this.fetchData();
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    private fetchData() {
        this.subscriptions.add(
            this.displayedColumns$.subscribe((columns) => {
                this.allDisplayedColumns = columns ? columns : YukiDocumentsListColumns.DEFAULT;
                this.displayedColumns = columns ? columns : YukiDocumentsListColumns.DEFAULT;
                this.filterDisplayedColumns();
            }),
        );

        this.subscriptions.add(
            this.currentFolder$.subscribe((folder) => {
                this.currentFolder = folder;
            }),
        );

        this.subscriptions.add(
            this.getDocumentsForCurrentFolder$.subscribe((documents) => {
                this.documents = documents;
                this.dataSource.data = this.documents;
                this.dataSource.sort = this.sort;
                this.dataSource.paginator = this.paginator;
                this.filterDisplayedColumns();
                this.executeFilter();
            }),
        );

        this.subscriptions.add(
            this.linkedObjects$.subscribe((linkedObjects) => {
                if (!linkedObjects) {
                    this.store.dispatch(GetAllObjectsLinked);
                    return;
                }
                this.linkedObjects = linkedObjects;
            }),
        );
    }

    private filterDisplayedColumns() {
        this.displayedColumns = this.allDisplayedColumns.filter((column) => this.currentFolder?.id === 2 || column !== 'invoiceNumber');
    }

    getLinksByFile(uuid: string) {
        return this.linkedObjects?.find((lo) => lo.yukiId == uuid);
    }

    getNumberOfLinksByFile(uuid: string): number {
        const linkedObjects: LinkedObjectToFile[] = this.getLinksByFile(uuid)?.linkedObjects;
        return linkedObjects ? linkedObjects.length : 0;
    }

    getNumberOfSublinksByFile(uuid: string): number {
        const linkedObjects: LinkedObjectToFile[] = this.getLinksByFile(uuid)?.linkedObjects;
        return linkedObjects ? linkedObjects.reduce((total: number, linkedObject: LinkedObjectToFile) => total + linkedObject.childInvoiceLinks.length, 0) : 0;
    }

    isFullySublinked(uuid: string): boolean {
        const linkedObjects: LinkedObjectToFile[] = this.getLinksByFile(uuid)?.linkedObjects;
        if (!linkedObjects) return false;
        const linkedAmount: number = this.calculateLinkedAmount(linkedObjects);
        const sublinkedAmount: number = this.calculateSublinkedAmount(linkedObjects);
        return linkedAmount === sublinkedAmount;
    }

    isPartiallySublinked(uuid: string): boolean {
        const linkedObjects: LinkedObjectToFile[] = this.getLinksByFile(uuid)?.linkedObjects;
        if (!linkedObjects) return false;
        const linkedAmount: number = this.calculateLinkedAmount(linkedObjects);
        const sublinkedAmount: number = this.calculateSublinkedAmount(linkedObjects);
        return linkedAmount > sublinkedAmount && sublinkedAmount > 0;
    }

    isOverSublinked(uuid: string): boolean {
        const linkedObjects: LinkedObjectToFile[] = this.getLinksByFile(uuid)?.linkedObjects;
        if (!linkedObjects) return false;
        const linkedAmount: number = this.calculateLinkedAmount(linkedObjects);
        const sublinkedAmount: number = this.calculateSublinkedAmount(linkedObjects);
        return sublinkedAmount > linkedAmount;
    }

    private calculateLinkedAmount(linkedObjects: LinkedObjectToFile[]): number {
        return roundNumber(linkedObjects.reduce((total: number, linkedObject: LinkedObjectToFile) => total + linkedObject.price, 0));
    }

    private calculateSublinkedAmount(linkedObjects: LinkedObjectToFile[]): number {
        return roundNumber(
            linkedObjects.reduce(
                (total: number, linkedObject: LinkedObjectToFile) =>
                    total + linkedObject.childInvoiceLinks.reduce((total, childInvoiceLink) => total + childInvoiceLink.amount, 0),
                0,
            ),
        );
    }

    amountUsedByLinks(uuid: string) {
        const linkedObjectsToFile: LinkedObjectsToFileWithId = this.getLinksByFile(uuid);
        if (linkedObjectsToFile) {
            let total = 0;
            linkedObjectsToFile.linkedObjects.forEach((cil) => {
                total += cil.price;
            });
            return total;
        }
        return 0;
    }

    openLinkToObjectModal(event, yukiDocument: YukiDocument) {
        event.stopPropagation();

        const modalRef = this.modalService.open(YukiDetailModalComponent, {
            windowClass: 'modal-full-screen',
            animation: false,
        });
        modalRef.componentInstance.yukiDocument = yukiDocument;
        modalRef.componentInstance.folderId = this.currentFolder.id;
        modalRef.componentInstance.onLinkTab = true;
    }

    updateData() {
        this.store.dispatch(new FetchAllDocuments(this.currentFolder?.id));
    }

    openDetailModal(yukiDocument: YukiDocument): void {
        const modalRef = this.modalService.open(YukiDetailModalComponent, {
            windowClass: 'modal-full-screen',
            animation: false,
        });
        modalRef.componentInstance.yukiDocument = yukiDocument;
        modalRef.componentInstance.folderId = this.currentFolder.id;
    }

    applyFilter(filterValue) {
        this.searchLabel = filterValue.trim();
        this.executeFilter();
    }

    executeFilter() {
        this.dataSource.filter = JSON.stringify({
            searchResult: this.searchLabel?.trim().toLowerCase(),
            selectedLinkType: this.filterLabel.trim(),
            flaggedChecked: this.flaggedChecked,
            visibleChecked: this.visibleChecked,
            verifiedChecked: this.verifiedChecked,
            paidChecked: this.paidChecked,
            startDate: this.startDate,
            endDate: this.endDate,
        });

        this.totalAmount = this.totalAmountExcl = this.totalVATAmount = 0;
        this.dataSource.filteredData.forEach((document) => {
            this.totalAmount += document.amount;
            this.totalAmountExcl += document.amountExcl;
            this.totalVATAmount += document.vatamount;
        });
    }

    applyFilterLinked($event) {
        this.filterLabel = $event.target.title;
        this.executeFilter();
    }

    private createMatTable() {
        function roundedAmount(amount: number): number {
            return Math.round(amount * 100) / 100;
        }

        this.dataSource.filterPredicate = function (data, filter) {
            const filterObj = JSON.parse(filter);
            return (
                isAfter(new Date(data.documentDate), new Date(filterObj.startDate)) &&
                isBefore(new Date(data.documentDate), new Date(filterObj.endDate)) &&
                (filterObj.flaggedChecked ? data.flagged : true) &&
                (filterObj.visibleChecked ? true : data.visible) &&
                (filterObj.verifiedChecked ? !data.verified : true) &&
                (filterObj.paidChecked ? true : !data.paid) &&
                (!filterObj.searchResult ||
                    data.invoiceNumber?.toLowerCase().includes(filterObj.searchResult?.toLowerCase()) ||
                    data.subject?.toLowerCase().includes(filterObj.searchResult?.toLowerCase()) ||
                    data.contactName?.toLowerCase().includes(filterObj.searchResult?.toLowerCase())) &&
                ((filterObj.selectedLinkType == 'Partially linked' &&
                    roundedAmount(data.amountExcl) != roundedAmount(data.totalAmount) &&
                    roundedAmount(data.totalAmount) < roundedAmount(data.amountExcl) &&
                    roundedAmount(data.totalAmount) != 0) ||
                    (filterObj.selectedLinkType == 'Not linked' && roundedAmount(data.totalAmount) == 0) ||
                    (filterObj.selectedLinkType == 'Full linked' && roundedAmount(data.totalAmount) == roundedAmount(data.amountExcl)) ||
                    filterObj.selectedLinkType == YukiDocumentsListComponent.DEFAULT_LINKED_FILTER_LABEL ||
                    (filterObj.selectedLinkType == 'Over linked' &&
                        roundedAmount(data.totalAmount) > roundedAmount(data.amountExcl) &&
                        roundedAmount(data.amount) > 0) ||
                    (roundedAmount(data.amountExcl) < 0 && roundedAmount(data.totalAmount) < roundedAmount(data.amountExcl)))
            );
        };
    }

    flagDocument(yukiId: string) {
        this.store.dispatch(new FlagYukiDocument(yukiId));
    }

    hideDocument(yukiId: string) {
        this.store.dispatch(new UpdateVisibilityYukiDocument(yukiId));
    }

    applyFilterVisible() {
        this.visibleChecked = !this.visibleChecked;
        this.executeFilter();
    }

    applyFilterFlagged() {
        this.flaggedChecked = !this.flaggedChecked;
        this.executeFilter();
    }

    applyFilterVerified() {
        this.verifiedChecked = !this.verifiedChecked;
        this.executeFilter();
    }

    applyFilterPaid() {
        this.paidChecked = !this.paidChecked;
        this.executeFilter();
    }

    getAllColumns() {
        return YukiDocumentsListColumns.ALL;
    }

    getDefaultColumns() {
        return YukiDocumentsListColumns.DEFAULT;
    }

    roundedAmount(amount: number): number {
        return Math.round(amount * 100) / 100;
    }
}

export class YukiDocumentsListColumns {
    static readonly VERIFIED = 'verified';
    static readonly SUBJECT = 'subject';
    static readonly AMOUNT = 'amount';
    static readonly CONTACT_NAME = 'contactName';
    static readonly DOCUMENT_DATE = 'documentDate';
    static readonly SUBLINKS = 'sublinks';
    static readonly EDIT = 'edit';
    static readonly INVOICE_NUMBER = 'invoiceNumber';

    static readonly ALL = [this.VERIFIED, this.INVOICE_NUMBER, this.SUBJECT, this.AMOUNT, this.CONTACT_NAME, this.DOCUMENT_DATE, this.SUBLINKS, this.EDIT];
    static readonly DEFAULT = [this.VERIFIED, this.INVOICE_NUMBER, this.SUBJECT, this.AMOUNT, this.CONTACT_NAME, this.DOCUMENT_DATE, this.SUBLINKS, this.EDIT];
}
