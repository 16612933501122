<div class="standalone__page">
    <div class="standalone__page__content">
        <div class="standalone__page__logo">
            <img src="../../../assets/img/logo.svg" />
        </div>

        <div *ngIf="loading">
            <p>
                Fetching your product
                <img
                    width="10"
                    height="10"
                    src="../../../assets/img/loading-theme.svg"
                    alt="Loading"
                    title="Loading..." />
            </p>
        </div>
        <div *ngIf="!valueRetrieved">
            <h1>We're sorry!</h1>
            <p>The product you're looking for does not exist</p>
        </div>

        <div *ngIf="product">
            <small>Update stock of</small>
            <h1 *ngIf="product">{{ product.title }}</h1>
            <ul class="list__specs">
                <li
                    *ngIf="product?.description"
                    class="format-newlines">
                    <label>Description</label> {{ product.description }}
                </li>
                <li *ngIf="product?.inStock">
                    <label>Stock</label> {{ product?.inStock }} &nbsp;
                    <small class="color--muted">{{ product.unit?.type }}</small>
                </li>
                <li *ngIf="product?.warehouseLocationUuid">
                    <label>Location</label><app-pill-warehouse [warehouseLocationUuid]="product.warehouseLocationUuid"></app-pill-warehouse>
                    <app-pill-warehouse-location [warehouseLocationUuid]="product.warehouseLocationUuid"></app-pill-warehouse-location>
                </li>
                <li *ngIf="product?.ean"><label>EAN</label> {{ product.ean }}</li>
                <li *ngIf="product?.sku"><label>SKU</label> {{ product.sku }}</li>
            </ul>

            <div class="divider"></div>

            <div *ngIf="updateSuccess">
                <div class="message message--success">
                    <p>Stock <strong>successfully</strong> updated!</p>
                    <p>New stock: {{ stockAfterSuccess }}</p>
                </div>
                <div>
                    <button
                        class="button button--primary w-100"
                        (click)="refresh()">
                        Update again?
                    </button>
                </div>
            </div>

            <div
                class="message message--error"
                *ngIf="updateFailure">
                <p *ngIf="errorMessage">{{ errorMessage }}</p>
                <p *ngIf="!errorMessage">Oops! something went <strong>wrong</strong>!</p>
            </div>

            <form
                [formGroup]="productStockAmountUpdateForm"
                (ngSubmit)="submit($event)"
                *ngIf="!updateSuccess">
                <div class="form-group m-b-4">
                    <label>Number of products to add or take away</label>
                    <input
                        type="number"
                        placeholder="Amount"
                        formControlName="changedAmount" />
                    <span
                        class="form-error"
                        *ngIf="productStockAmountUpdateForm.controls.changedAmount.errors && !productStockAmountUpdateForm.controls.changedAmount.pristine"
                        >Please input an amount greater than 0</span
                    >
                </div>

                <div class="d-flex align-items-center">
                    <input
                        [ngClass]="!productStockAmountUpdateForm.valid ? 'button button--disabled' : 'button button--primary'"
                        class="w-100 m-r-1"
                        type="submit"
                        value="Take away"
                        alt="REMOVED"
                        [disabled]="!productStockAmountUpdateForm.valid" />
                    <input
                        [ngClass]="!productStockAmountUpdateForm.valid ? 'button button--disabled' : 'button button--success'"
                        class="w-100 m-l-1"
                        type="submit"
                        value="Add to stock"
                        alt="ADDED"
                        [disabled]="!productStockAmountUpdateForm.valid" />
                </div>
            </form>
        </div>
    </div>
</div>
