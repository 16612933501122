import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { AuthenticationService } from '../authentication/authentication.service';
import { VeldaCountry } from '../models/VeldaCountry';
import { VeldaCountryDTO } from '../models/VeldaCountryDTO';
import { VeldaLanguageDTO } from '../models/VeldaLanguageDTO';
import { VeldaLanguage } from '../models/VeldaLanuage';

@Injectable({
    providedIn: 'root',
})
export class VeldaLanguagesService {
    httpOptions = {};

    constructor(private httpClient: HttpClient, private authenticationService: AuthenticationService) {
        this.httpOptions = authenticationService.getHeaders();
    }

    getAllCountries(): Observable<VeldaCountry[]> {
        return this.httpClient.get<VeldaCountry[]>(`${environment.baseUrl}/velda-languages/countries`, this.httpOptions);
    }

    addCountry(country: VeldaCountryDTO): Observable<VeldaCountry> {
        return this.httpClient.post<VeldaCountry>(`${environment.baseUrl}/velda-languages/countries`, country, this.httpOptions);
    }

    editCountry(countryUuid: string, country: VeldaCountryDTO): Observable<VeldaCountry> {
        return this.httpClient.put<VeldaCountry>(`${environment.baseUrl}/velda-languages/countries/${countryUuid}`, country, this.httpOptions);
    }

    deleteCountry(countryUuid: string): Observable<string> {
        return this.httpClient.delete<string>(`${environment.baseUrl}/velda-languages/countries/${countryUuid}`, this.httpOptions);
    }

    getAllLanguages(): Observable<VeldaLanguage[]> {
        return this.httpClient.get<VeldaLanguage[]>(`${environment.baseUrl}/velda-languages/languages`, this.httpOptions);
    }

    addLanguage(language: VeldaLanguageDTO): Observable<VeldaLanguage> {
        return this.httpClient.post<VeldaLanguage>(`${environment.baseUrl}/velda-languages/languages`, language, this.httpOptions);
    }

    editLanguage(languageUuid: string, language: VeldaLanguageDTO): Observable<VeldaLanguage> {
        return this.httpClient.put<VeldaLanguage>(`${environment.baseUrl}/velda-languages/languages/${languageUuid}`, language, this.httpOptions);
    }

    deleteLanguage(languageUuid: string): Observable<string> {
        return this.httpClient.delete<string>(`${environment.baseUrl}/velda-languages/languages/${languageUuid}`, this.httpOptions);
    }
}
