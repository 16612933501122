import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Select } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import Project from 'src/app/models/Project';

import { ProjectState } from '../project.state';

@Component({
    selector: 'app-project-detail-info',
    templateUrl: './project-detail-info.component.html',
    styleUrls: ['./project-detail-info.component.scss'],
})
export class ProjectDetailInfoComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();
    @Select(ProjectState.projects) works$: Observable<Project[]>;
    work: Project;

    constructor(private route: ActivatedRoute) {}

    ngOnInit(): void {
        this.subscriptions.add(
            this.route.params.subscribe((params) => {
                this.works$.subscribe((works) => (this.work = works.filter((w) => w.uuid == params.id)[0]));
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
