import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { EnvironmentService } from 'src/app/services/environment.service';

import packageInfo from '../../../../package.json';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'app-app-info',
    templateUrl: './app-info.component.html',
    styleUrls: ['./app-info.component.scss'],
})
export class AppInfoComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    public version: string = packageInfo.version;
    public apiUrl: string;
    public environment: any;

    isLoading = true;

    constructor(private environmentService: EnvironmentService) {}

    ngOnInit(): void {
        this.subscriptions.add(
            this.environmentService.getCurrentEnvironment().subscribe((env) => {
                this.environment = env;
                this.apiUrl = environment.baseUrl;
                if (env != null) {
                    this.isLoading = false;
                }
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
