import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import RecipientListModel from 'src/app/models/RecipientListModel';

import AddNewRecipientModel from '../add-recipient-modal/AddNewRecipientModel';
import { RecipientService } from '../recipient.service';

@Component({
    selector: 'app-add-recipients-to-list-modal',
    templateUrl: './add-recipients-to-list-modal.component.html',
    styleUrls: ['./add-recipients-to-list-modal.component.scss'],
})
export class AddRecipientsToListModalComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();
    @Input() recipients: AddNewRecipientModel[];

    lists: RecipientListModel[];
    tags: any[] = [];
    allTags: string[] = [];
    newRecipientListFormGroup: FormGroup;
    loading = true;

    constructor(
        public activeModal: NgbActiveModal,
        private fb: FormBuilder,
        private recipientService: RecipientService,
        private toastr: ToastrService,
        private logger: NGXLogger,
    ) {}

    ngOnInit(): void {
        this.logger.debug('Fetching lists');
        this.subscriptions.add(
            this.recipientService.getLists().subscribe((l) => {
                this.lists = l;
                this.loading = false;
            }),
        );
        this.newRecipientListFormGroup = this.fb.group({
            list: ['', [Validators.required]],
            tag: [],
        });
    }

    onChange(value: any) {
        const listId = this.newRecipientListFormGroup.get('list').value;
        if (listId) {
            this.subscriptions.add(
                this.recipientService.getTags(listId).subscribe((t) =>
                    t.forEach((el) => {
                        this.allTags.push(el.name);
                    }),
                ),
            );
        }
    }

    addRecipients() {
        const t: string[] = [];
        this.tags.forEach((el) => t.push(el.value));
        this.recipients.forEach((r) => {
            r.tags = t;
            r.listId = this.newRecipientListFormGroup.get('list').value;
        });
        this.logger.debug('Attempt to add recipient to list');
        this.subscriptions.add(
            this.recipientService.addRecipientList(this.recipients).subscribe(
                (res) => {
                    if (res) {
                        this.logger.debug('Succesfully added recipient to list');
                        this.toastr.success('Recipients where successfully added.');
                        this.activeModal.close();
                    } else {
                        this.logger.debug('Failed to add recipient to list');
                        this.toastr.error('Oops, something went wrong.');
                        this.activeModal.close();
                    }
                },
                (err) => {
                    this.logger.debug('Failed to add recipient to list');
                    this.toastr.error('Oops, something went wrong.');
                    this.activeModal.close();
                },
            ),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
