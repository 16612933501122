<span>
    <p *ngIf="!exists">pdf not available</p>
    <a
        class="pill pill--pdf"
        target="_blank"
        href="{{ url }}"
        *ngIf="exists && error">
        <i class="las la-file-pdf"></i>
        {{ fileName }}
    </a>
    <a
        [ngClass]="getClass()"
        (click)="$event.stopPropagation(); openPdfDetailModal()"
        *ngIf="exists && !error">
        <i [ngClass]="getIcon()"></i>
        <app-loading-message *ngIf="isLoading"></app-loading-message>
        <span *ngIf="!isLoading">
            {{ fileName }}
        </span>
    </a>
</span>
