<div *ngIf="projects$ | async">
    <div class="d-flex align-items-center">
        <div class="w-100">
            <app-searchbar
                (valueChange)="applyFilter($event)"
                [(value)]="filter"></app-searchbar>
        </div>
    </div>
    
    <div class="m-t-4">
        <div class="table__overflow">
            <table
                #exporter="matTableExporter"
                [dataSource]="dataSource"
                mat-table
                matSort
                matSortActive="title"
                matSortDirection="asc"
                matTableExporter>
                <ng-container matColumnDef="eye">
                    <th
                        *matHeaderCellDef
                        mat-header-cell
                        width="1">
                        <i class="las la-eye"></i>
                    </th>
                    <td
                        *matCellDef="let row"
                        class="td-icon"
                        mat-cell>
                        <span
                            *ngIf="row.finished"
                            class="tag tag--green"
                            >Finished</span
                        >
                        <span
                            *ngIf="!row.finished"
                            class="tag tag--blue"
                            >Ongoing</span
                        >
                    </td>
                </ng-container>

                <ng-container matColumnDef="title">
                    <th
                        *matHeaderCellDef
                        mat-header-cell
                        mat-sort-header>
                        Title
                    </th>
                    <td
                        *matCellDef="let row"
                        mat-cell>
                        <app-pill-project [project]="row"></app-pill-project>
                    </td>
                </ng-container>


                <ng-container matColumnDef="endDate">
                    <th
                        *matHeaderCellDef
                        mat-header-cell
                        mat-sort-header>
                        Date
                    </th>
                    <td
                        *matCellDef="let row"
                        mat-cell>
                        <div *ngIf="checkDates(row) && !row.finished">OVERDUE
                            <div class="progress">
                                <mat-progress-bar
                                    [value]="row.percentageOfElapsedWorkingTime"
                                    [ngClass]="{'warning': row.percentageOfElapsedWorkingTime >= 70, 'danger': row.percentageOfElapsedWorkingTime >= 90}"
                                    mode="determinate">
                                </mat-progress-bar>
                            </div>
                        </div>
                        <div *ngIf="!checkDates(row) || row.finished">
                            {{ row.startDate | date }} <span *ngIf="!row.present">{{ row.endDate | date }}</span>
                            <div class="progress">
                                <mat-progress-bar
                                    [value]="row.percentageOfElapsedWorkingTime"
                                    [ngClass]="{'warning': row.percentageOfElapsedWorkingTime >= 70, 'danger': row.percentageOfElapsedWorkingTime >= 90}"
                                    mode="determinate">
                                </mat-progress-bar>
                            </div>
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="warranty">
                    <th
                        *matHeaderCellDef
                        mat-header-cell
                        mat-sort-header>
                        Warranty
                    </th>
                    <td
                        *matCellDef="let row"
                        mat-cell>
                        <div *ngIf="!hasWarranty(row)">
                            <i class="las la-times-circle"style="color: red">
                            </i>
                        </div>
                        <div *ngIf="!hasActiveWarrantyDates(row)"><span id="expired"><b>EXPIRED</b></span> {{row.warrantyStartDate | date}} - {{row.warrantyEndDate | date}}</div>
                        <div *ngIf="hasActiveWarrantyDates(row) && hasWarranty(row) && calculateRemainingWarrantyDays(row) == 0"><span id="expiring"><b>EXPIRING TODAY</b></span>
                            {{ row.warrantyStartDate | date }} - {{ row.warrantyEndDate | date }}
                        </div>
                        <div *ngIf="hasActiveWarrantyDates(row) && hasWarranty(row) && calculateRemainingWarrantyDays(row) > 0"><span id="active"><b>{{calculateRemainingWarrantyDays(row)}}</b><b> DAYS REMAINING</b></span>
                            {{ row.warrantyStartDate | date }} - {{ row.warrantyEndDate | date }}
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="maintenanceContract">
                    <th
                        *matHeaderCellDef
                        mat-header-cell
                        mat-sort-header>
                        Maintenance Contract
                    </th>
                    <td
                        *matCellDef="let row"
                        mat-cell>
                        <div *ngIf="!hasMaintenanceContract(row)">
                            <i class="las la-times-circle" style="color: red"></i>
                        </div>
                        <div *ngIf="hasMaintenanceContract(row)">
                            <i class="las la-check-circle" style="color: green"></i>
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="budget">
                    <th
                        *matHeaderCellDef
                        mat-header-cell
                        mat-sort-header>
                        Budget
                    </th>
                    <td
                        *matCellDef="let row"
                        mat-cell>
                        <span>{{ row.budget }}</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="partnerName">
                    <th
                        *matHeaderCellDef
                        mat-header-cell
                        mat-sort-header>
                        Company
                    </th>
                    <td
                        *matCellDef="let row"
                        mat-cell>
                        <app-pill-partner
                            *ngIf="row.partner || row.partnerUuid"
                            [partnerUuid]="row.partner ? row.partner.uuid : row.partnerUuid">
                        </app-pill-partner>
                    </td>
                </ng-container>
                <ng-container matColumnDef="projectType">
                    <th
                        *matHeaderCellDef
                        mat-header-cell
                        mat-sort-header>
                        Type
                    </th>
                    <td
                        *matCellDef="let row"
                        mat-cell>
                        <span class="tag">{{ row.projectType }}</span>
                    </td>
                </ng-container>
                <ng-container matColumnDef="jiraLink">
                    <th
                        *matHeaderCellDef
                        mat-header-cell
                        mat-sort-header>
                        Linked with Jira
                    </th>
                    <td
                        *matCellDef="let row"mat-cell>
                        <div *ngIf="!row.jiraProjectKey">
                            <i class="las la-times-circle" style="color: red"></i>
                        </div>
                        <div *ngIf="row.jiraProjectKey">
                            {{row.jiraProjectKey}}
                        </div>
                </td>
                </ng-container>
                <ng-container matColumnDef="creatorId">
                    <th
                        *matHeaderCellDef
                        mat-header-cell
                        mat-sort-header>
                        Creator
                    </th>
                    <td
                        *matCellDef="let row"
                        mat-cell>
                        <app-pill-user
                            *ngIf="row.userId"
                            [userId]="row.userId"></app-pill-user>
                    </td>
                </ng-container>

                <ng-container matColumnDef="edit">
                    <th
                        *matHeaderCellDef
                        class="td-small"
                        mat-header-cell></th>
                    <td
                        *matCellDef="let row"
                        class="td-icon"
                        mat-cell>
                        <span ngbDropdown>
                            <button
                                (click)="$event.stopPropagation()"
                                class="button button--icon button--dropdown button--small"
                                ngbDropdownToggle></button>
                            <span
                                aria-labelledby="dropdownMenuButton"
                                class="dropdown-menu dropdown-menu-right"
                                ngbDropdownMenu>
                                <a
                                    (click)="toggleFinishedProject(row); $event.stopPropagation()"
                                    *appIsPermitted="{ module: 'WORKS', operator: 'AND', actions: ['EDIT'] }"
                                    class="dropdown-item">
                                    <div *ngIf="row.finished; else elseDropDownBlock">Mark as Ongoing</div>
                                    <ng-template #elseDropDownBlock>Mark as Finished</ng-template>
                                </a>
                                <a
                                    (click)="openProjectUpdateModal(row); $event.stopPropagation()"
                                    *appIsPermitted="{ module: 'WORKS', operator: 'AND', actions: ['EDIT'] }"
                                    class="dropdown-item"
                                    >Edit project</a
                                >
                                <a
                                    (click)="downloadProjectPdf(row); $event.stopPropagation()"
                                    *appIsPermitted="{ module: 'WORKS', operator: 'AND', actions: ['READ'] }"
                                    class="dropdown-item"
                                    >Export PDF</a
                                >
                                <div class="dropdown-divider"></div>
                                <a
                                    (click)="openProjectDeleteModal(row); $event.stopPropagation()"
                                    *appIsPermitted="{ module: 'WORKS', operator: 'AND', actions: ['DELETE'] }"
                                    class="dropdown-item color--red">
                                    <ng-container *ngIf="!row.archived; else unarchive">Archive</ng-container>
                                    <ng-template #unarchive>Unarchive</ng-template>
                                </a>
                            </span>
                        </span>
                    </td>
                </ng-container>

                <tr
                    *matHeaderRowDef="displayedColumns"
                    mat-header-row></tr>
                <tr
                    (click)="openProjectInfoModal(row.uuid); $event.stopPropagation()"
                    *matRowDef="let row; columns: displayedColumns"
                    class="pointer"
                    mat-row></tr>

                <tr *matNoDataRow>
                    <td colspan="7">No projects found...</td>
                </tr>
            </table>
        </div>
    </div>
</div>
