import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenticationService } from 'src/app/authentication/authentication.service';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class ComanageLoginService {
    httpOptions = {};

    constructor(private httpClient: HttpClient, private authenticationService: AuthenticationService) {
        this.httpOptions = authenticationService.getHeaders();
    }

    checkIfApiKeyExists() {
        return this.httpClient.get<any>(environment.baseUrl + '/comanage/keyExists', this.httpOptions);
    }

    pushApiKey(apiKey: string) {
        return this.httpClient.post<boolean>(environment.baseUrl + `/comanage/addKey`, apiKey, this.httpOptions);
    }

    removeApiKey() {
        return this.httpClient.post<boolean>(environment.baseUrl + `/comanage/removeKey`, '', this.httpOptions);
    }
}
