<div *ngIf="assignedResumes?.length > 0">
    <div
        class="resume__project"
        *ngFor="let assignedResume of assignedResumes | orderBy: 'firstName':false:true">
        <div class="resume__project__title">
            <span>
                {{ assignedResume.firstName }} {{ assignedResume.lastName }}
                <h3>{{ assignedResume.jobTitle }}</h3>
                <div
                    ngbDropdown
                    class="dropdown">
                    <button
                        type="button"
                        ngbDropdownToggle
                        class="button button--icon button--small dropdown-toggle"
                        id="dropdownMenuButton"></button>
                    <div
                        ngbDropdownMenu
                        class="dropdown-menu dropdown-menu-right"
                        aria-labelledby="dropdownMenuButton">
                        <a
                            class="dropdown-item"
                            (click)="previewResume(assignedResume)"
                            >Preview</a
                        >
                        <a
                            class="dropdown-item color--red"
                            (click)="openDeleteAssignedResumeModal(assignedResume.workResumeAssignmentUuid)"
                            >Delete</a
                        >
                    </div>
                </div>
            </span>

            <form
                [formGroup]="statusFormGroup"
                class="modal-wrapper">
                <div class="form-group m-b-2">
                    <span
                        class="dot"
                        [style]="changeBackground(assignedResume.colour)"></span>
                    <label>Set a status</label>
                    <select
                        formControlName="statusUuid"
                        (change)="saveStatus(assignedResume.workResumeAssignmentUuid)">
                        <option
                            *ngFor="let status of companyWorkStatusList | orderBy: 'No Status':false:true"
                            [attr.Value]="status.uuid"
                            [selected]="status.phase === assignedResume.phase">
                            {{ status.phase }}
                        </option>
                    </select>
                </div>
            </form>
        </div>
        <div class="list__item__extra">
            <ng-template #tipTags
                ><span *ngFor="let skill of assignedResume.skills">{{ skill.title }} </span></ng-template
            >
            <a
                *ngIf="assignedResume.skills.length"
                class="m-l-2 button button--small button--icon button--outline"
                placement="top"
                [ngbTooltip]="tipTags">
                <i class="uil uil-tag"></i>
            </a>
        </div>

        <div class="resume__project__content">
            <p class="wrap-words">Allocated by: {{ assignedResume.allocator }}</p>
        </div>
    </div>
</div>
<div *ngIf="assignedResumes?.length == 0">
    <p>No resumés assigned...</p>
</div>
