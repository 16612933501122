import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-pdf-detail-modal',
    templateUrl: './pdf-detail-modal.component.html',
    styleUrls: ['./pdf-detail-modal.component.scss'],
})
export class PdfDetailModalComponent implements OnInit {
    @Input() originUrl: string;
    @Input() pdfUrl: string;
    @Input() pdfAsBase64;

    pdf: string;

    constructor(public activeModal: NgbActiveModal, private http: HttpClient) {}

    ngOnInit(): void {
        if (this.pdfAsBase64?.pdf) {
            this.pdf = `data:application/pdf;base64,${this.pdfAsBase64.pdf}`;
        }
    }

    openInNewWindow() {
        window.open(this.pdfUrl, '_blank');
    }

    export() {
        //does not work yet
        let headers = new HttpHeaders();
        headers = headers.set('Accept', 'application/pdf');
        return this.http.get(this.pdfUrl, {
            headers: headers,
            responseType: 'blob',
        });
    }
}
