import { GlobalTag } from 'src/app/models/GlobalTag';

export class FetchGlobalTags {
    static readonly type = '[GlobalTag] Get GlobalTags';
    constructor() {}
}

export class FetchGlobalTagTaggedObjects {
    static readonly type = '[any[]] Get Taggable objects';
    constructor(public tag: GlobalTag) {}
}

export class AddGlobalTagToObject {
    static readonly type = '[GlobalTag] Add globalTag to Object';
    constructor(public tag: GlobalTag, public objectUuid: string) {}
}

export class AddGlobalTagToMultipleObjects {
    static readonly type = '[GlobalTag] Add globalTag to multiple Objects';
    constructor(public tag: GlobalTag, public objectUuids: []) {}
}

export class RemoveGlobalTagFromObject {
    static readonly type = '[GlobalTag] Remove globalTag from Object';
    constructor(public tag: GlobalTag, public objectUuid: string) {}
}

export class RemoveGlobalTagFromMultipleObjects {
    static readonly type = '[GlobalTag] Remove globalTag from multiple Objects';
    constructor(public tag: GlobalTag, public objectUuids: []) {}
}

export class AddUndefinedObjectTagsToObject {
    static readonly type = '[GlobalTag] Add Tags for saved tags to object';
    constructor(public objectUuid: string) {}
}

export class AddUndefinedObjectTag {
    static readonly type = '[GlobalTag] Add Tag for undefined object';
    constructor(public tag: GlobalTag, public key: number) {}
}

export class RemoveUndefinedObjectTag {
    static readonly type = '[GlobalTag] Remove Tag for undefined object';
    constructor(public tag: GlobalTag, public key: number) {}
}

export class ResetUndefinedObjectTags {
    static readonly type = '[GlobalTag] Reset Tags for undefined objects';
    constructor() {}
}
