<app-app-holder>
    <app-navbar></app-navbar>

    <app-sidebar>
        <app-settings-subnav></app-settings-subnav>
    </app-sidebar>

    <app-content>
        <div class="app__title">
            <h1>Roles & permissions</h1>

            <button
                class="button button--primary"
                (click)="openAddCustomroleModal()">
                Add Role
            </button>
        </div>

        <table
            class="table-view"
            mat-table
            [dataSource]="allCustomRoles$ | async | orderBy: 'name':false:true"
            matSort
            *ngIf="!noRolesSet">
            <ng-container matColumnDef="role name">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header>
                    Rolename
                </th>
                <td
                    mat-cell
                    *matCellDef="let customRole">
                    {{ customRole.name }}
                </td>
            </ng-container>

            <ng-container matColumnDef="edit">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    class="td-small"></th>

                <td
                    mat-cell
                    *matCellDef="let customRole"
                    class="td-small">
                    <span
                        ngbDropdown
                        *ngIf="customRole.name !== 'ROLE_OWNER'">
                        <button
                            ngbDropdownToggle
                            class="button button--icon button--dropdown">
                            Options
                        </button>
                        <span
                            ngbDropdownMenu
                            class="dropdown-menu dropdown-menu-right"
                            aria-labelledby="dropdownMenuButton">
                            <a
                                class="dropdown-item"
                                (click)="openAddCustomroleModal(customRole)"
                                >Edit role</a
                            >
                            <a
                                class="dropdown-item color--red"
                                (click)="openRoleDeleteModal(customRole)"
                                >Delete role</a
                            >
                        </span>
                    </span>
                </td>
            </ng-container>

            <tr
                mat-header-row
                *matHeaderRowDef="displayedColumns"></tr>

            <tr
                mat-row
                *matRowDef="let row; columns: displayedColumns"></tr>
        </table>

        <div *ngIf="noRolesSet">
            <app-message-empty
                [title]="'No roles and permissions...'"
                [subtitle]="'Start adding roles to controll user permissions.'"
                [icon]="'../../assets/img/open-box.png'">
                <a
                    class="button button--primary"
                    (click)="openAddCustomroleModal()"
                    >Add Role</a
                >
            </app-message-empty>
        </div>
    </app-content>
</app-app-holder>
