<app-app-holder>
    <app-navbar></app-navbar>

    <app-content>
        <div>
            <div class="app__title">
                <h1>
                    Applicants
                    <span *ngIf="applicants$ | async as applicants"> ({{ applicants.length }}) </span>
                </h1>

                <span>
                    <span
                        ngbDropdown
                        class="m-r-1">
                        <button
                            ngbDropdownToggle
                            class="button button--icon button--dropdown">
                            Views
                        </button>

                        <span
                            ngbDropdownMenu
                            class="dropdown-menu dropdown-menu-right"
                            aria-labelledby="dropdownMenuButton">
                            <a
                                class="dropdown-item"
                                (click)="switchView('kanbanView')"
                                id="kanbanView"
                                >Kanban view</a
                            >
                            <a
                                class="dropdown-item"
                                (click)="switchView('listView')"
                                id="listView"
                                >List view</a
                            >
                            <a
                                class="dropdown-item"
                                (click)="switchView('reasonOfLossView')"
                                id="reasonOfLossView"
                                >Reasons of loss</a
                            >
                            <a
                                class="dropdown-item"
                                (click)="switchView('archived')"
                                id="archived"
                                >Archived</a
                            >
                        </span>
                    </span>
                    <span ngbDropdown>
                        <button
                            ngbDropdownToggle
                            class="button button--icon button--dropdown">
                            Options
                        </button>

                        <span
                            ngbDropdownMenu
                            class="dropdown-menu dropdown-menu-right"
                            aria-labelledby="dropdownMenuButton">
                            <a
                                *appIsPermitted="{
                                    module: 'APPLICANTS',
                                    operator: 'AND',
                                    actions: ['CREATE']
                                }"
                                (click)="openAddApplicantModal()"
                                class="dropdown-item"
                                >Add applicant</a
                            >
                            <a
                                *appIsPermitted="{
                                    module: 'APPLICANTS',
                                    operator: 'AND',
                                    actions: ['READ']
                                }"
                                (click)="openManageLanesModal()"
                                class="dropdown-item"
                                >Manage lanes</a
                            >
                        </span>
                    </span>
                </span>
            </div>

            <ng-container *ngIf="view === 'listView'">
                <span
                    *appIsPermitted="{
                        module: 'APPLICANTS',
                        operator: 'AND',
                        actions: ['READ']
                    }">
                    <div *ngIf="applicants$ | async as applicants; else loading">
                        <app-applicant-list *ngIf="applicants && applicants.length > 0; else empty"></app-applicant-list>
                    </div>
                </span>
            </ng-container>
            <ng-container *ngIf="view === 'kanbanView'">
                <span
                    *appIsPermitted="{
                        module: 'APPLICANTS',
                        operator: 'AND',
                        actions: ['READ']
                    }">
                    <div *ngIf="applicants$ | async as applicants; else loading">
                        <app-applicant-kanban></app-applicant-kanban>
                    </div>
                </span>
            </ng-container>
            <ng-container *ngIf="view === 'reasonOfLossView'">
                <span
                    *appIsPermitted="{
                        module: 'APPLICANTS',
                        operator: 'AND',
                        actions: ['READ']
                    }">
                    <div *ngIf="reasonsOfLoss$ | async as applicantReasonOfLoss; else loading">
                        <app-reason-of-loss-list
                            [reasons]="applicantReasonOfLoss"
                            [linkedObjectType]="'applicant'"
                            [linkedObjects]="(applicants$ | async)"
                        ></app-reason-of-loss-list>
                        <app-reason-of-loss-graph
                            [reasons]="applicantReasonOfLoss"
                        ></app-reason-of-loss-graph>
                    </div>
                </span>
            </ng-container>
            <ng-container *ngIf="view === 'archived'">
                <span
                    *appIsPermitted="{
                        module: 'APPLICANTS',
                        operator: 'AND',
                        actions: ['READ']
                    }">
                    <app-archived-applicant-list></app-archived-applicant-list>
                </span>
            </ng-container>
        </div>
    </app-content>

    <ng-template #loading>
        <app-loading-message></app-loading-message>
    </ng-template>

    <ng-template #empty>
        <app-message-empty
            [title]="'No applicants..'"
            [subtitle]="'Whoops, you haven\'t added any applicants yet. Start now by clicking the button below.'"
            [icon]="'../../assets/img/open-box.png'">
            <a
                *appIsPermitted="{
                    module: 'APPLICANTS',
                    operator: 'AND',
                    actions: ['CREATE']
                }"
                (click)="openAddApplicantModal()"
                class="button button--primary"
                >Add an applicant</a
            >
        </app-message-empty>
    </ng-template>
</app-app-holder>
