<div class="modal-wrapper">
    <header class="modal-header">
        <h2
            class="modal-title"
            id="exampleModalLabel">
            Add tags
        </h2>
        <button
            (click)="activeModal.dismiss()"
            aria-label="Close"
            class="close"
            type="button">
            <span aria-hidden="true">&times;</span>
        </button>
    </header>

    <main class="modal-body">
        <app-global-tags
            [isBulkAdd]="true"
            [objectUuids]="objectUuids"
            [type]="type">
        </app-global-tags>
    </main>
</div>
