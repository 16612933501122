<app-app-holder>
    <app-navbar></app-navbar>

    <app-sidebar>
        <app-teamleader-subnav></app-teamleader-subnav>
    </app-sidebar>

    <app-content>
        <app-teamleader-not-logged-in (credentialsIsset)="checkCredentials($event)"></app-teamleader-not-logged-in>

        <div *ngIf="isset">
            <div class="app__title">
                <h1>Teamleader Overview Component ...</h1>
            </div>
        </div>
    </app-content>
</app-app-holder>
