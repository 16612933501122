import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

import { AuthenticationService } from '../../authentication/authentication.service';
import { CompanyMember } from '../../models/CompanyMember';
import GoogleUserInfoModel from './googleUserInfoModel';

@Injectable({
    providedIn: 'root',
})
export class GoogleLoginService {
    httpOptions = {};

    constructor(private httpClient: HttpClient, private authenticationService: AuthenticationService) {
        this.httpOptions = authenticationService.getHeaders();
    }

    getLoggedInMembersGoogleInfo() {
        return this.httpClient.get<GoogleUserInfoModel[]>(environment.baseUrl + '/google/getLoggedInMembersGoogleInfo', this.httpOptions);
    }

    getLoggedInMembers() {
        return this.httpClient.get<CompanyMember[]>(environment.baseUrl + '/google/getLoggedInMembers', this.httpOptions);
    }

    getIsLoggedIn() {
        return this.httpClient.get<boolean>(environment.baseUrl + '/google/isLoggedIn', this.httpOptions);
    }

    loginWithGoogle(code: string) {
        return this.httpClient.post<boolean>(environment.baseUrl + '/google/login', code, this.httpOptions);
    }

    logoutWithGoogle() {
        return this.httpClient.get<boolean>(environment.baseUrl + '/google/logout', this.httpOptions);
    }
}
