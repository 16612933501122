import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Select } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subscription } from 'rxjs';
import { StockUpdateHistoryModel } from 'src/app/models/StockUpdateHistoryModel';
import { ProductState } from 'src/app/products/product.state';

@Component({
    selector: 'app-dashboard-inventory-update-history-widget',
    templateUrl: './dashboard-inventory-update-history-widget.component.html',
    styleUrls: ['./dashboard-inventory-update-history-widget.component.scss'],
})
export class DashboardInventoryUpdateHistoryWidgetComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Select(ProductState.ProductStockUpdateHistory)
    productStockUpdateHistory$: Observable<StockUpdateHistoryModel[]>;

    amountShown = 10;
    isLoading = true;
    noHistory = false;

    displayedColumns: string[] = ['updatedProductTitle', 'productAmountState', 'currentStock', 'updatedBy', 'lastModified'];
    dataSource: MatTableDataSource<StockUpdateHistoryModel>;
    @ViewChild(MatSort) sort: MatSort;

    constructor(private router: Router, private logger: NGXLogger) {}

    ngOnInit(): void {
        this.subscriptions.add(
            this.productStockUpdateHistory$.subscribe(
                (histories) => {
                    if (histories && histories.length == 0) {
                        this.logger.debug('Retrieved 0 stockUpdates from the state');
                        this.noHistory = true;
                        this.isLoading = false;
                    } else if (histories && histories.length > 0) {
                        this.logger.debug('Retrieved stockUpdates from the state');
                        const newestHistories = histories.slice(-this.amountShown);
                        this.dataSource = new MatTableDataSource(newestHistories);
                        this.dataSource.sort = this.sort;
                        this.isLoading = false;
                    }
                },
                (error) => {
                    this.logger.debug('Failed to retrieve stock updates');
                },
            ),
        );
    }
    goToStockUpdateHistory() {
        this.logger.debug('Navigating to inventory stock-history');
        this.router.navigate(['/', 'inventory', 'stock-history']);
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
