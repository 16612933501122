import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';

import { KeyService } from './mailchimp-keycheck.service';

@Component({
    selector: 'app-mailchimp-not-logged-in',
    templateUrl: './mailchimp-not-logged-in.component.html',
    styleUrls: ['./mailchimp-not-logged-in.component.scss'],
})
export class MailchimpNotLoggedInComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();
    loginUrl: string;
    isset = true;
    @Output() keyIsset = new EventEmitter<boolean>();

    constructor(public activeModal: NgbActiveModal, private keyService: KeyService, private logger: NGXLogger) {}

    ngOnInit(): void {
        this.loginUrl = environment.frontEndBaseUrl + '/settings/module';

        this.logger.debug('Checking logged in with Mailchimp');
        this.subscriptions.add(
            this.keyService.checkIfApiKeyExists().subscribe(
                (res) => {
                    this.isset = res.key != null;
                    this.logger.debug('Logged in with Mailchimp');
                    this.keyIsset.emit(this.isset);
                },
                (error) => {
                    this.logger.debug('Not logged in with Mailchimp');
                },
            ),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
