<form
    (ngSubmit)="updateDocument()"
    [formGroup]="VATForm">
    <section>
        <div class="d-flex space-between align-items-center">
            <div class="sectiontitle sectiontitle--first">Financial</div>
            <button
                [disabled]="VATForm.invalid"
                class="button button--primary">
                Save
            </button>
        </div>

        <div class="d-flex justify-content-center align-items-end m-b-2">
            <div class="form-group required w-100">
                <label for="amountExcl">Amount excl. VAT</label>
                <div class="input--currency">
                    <input
                        formControlName="amountExcl"
                        id="amountExcl"
                        step="0.01"
                        type="number" />
                </div>
            </div>
            <button
                (click)="calculateAmountExcl()"
                [ngbTooltip]="amountExclCalcBtnTooltip"
                class="button button--icon m-l-2"
                type="button">
                <i class="las la-calculator"></i>
            </button>
        </div>

        <div class="d-flex align-items-end m-b-2">
            <div class="form-group required w-100">
                <label for="vatAmount">VAT amount</label>
                <div class="input--currency">
                    <input
                        formControlName="vatAmount"
                        id="vatAmount"
                        step="0.01"
                        type="number" />
                </div>
            </div>
            <div class="form-group required m-l-2 vat-select">
                <label for="vat">VAT</label>
                <select
                    (change)="updateVATAmount()"
                    class="select"
                    formControlName="vat"
                    id="vat">
                    <option
                        *ngFor="let value of VATs"
                        [ngValue]="value">
                        <span *ngIf="value.value !== 'OTHER'; else otherPercent">{{ value.percent / 100 | percent }}</span>
                        <ng-template #otherPercent>Other</ng-template>
                    </option>
                </select>
            </div>
            <button
                (click)="calculateVatAmount()"
                [ngbTooltip]="vatAmountCalcBtnTooltip"
                class="button button--icon m-l-2"
                type="button">
                <i class="las la-calculator"></i>
            </button>
        </div>

        <div class="divider divider--small"></div>

        <div class="row">
            <div class="col">
                <div class="form-group m-b-2 required">
                    <label for="amount">Amount incl. VAT</label>
                    <div class="input--currency">
                        <input
                            formControlName="amount"
                            id="amount"
                            step="0.01"
                            type="number" />
                    </div>
                    <small
                        *ngIf="checkAmountError()"
                        class="form-error">
                        Amount excl. VAT + VAT amount and amount incl. VAT doesn't match
                    </small>
                    <small class="form-error"> This amount should not be updated, this data is from Yuki... </small>
                </div>
            </div>
        </div>
    </section>

    <section>
        <div class="sectiontitle">Information</div>

        <div class="row">
            <div class="col">
                <div class="form-group m-b-2">
                    <label for="subject">Subject</label>
                    <input
                        formControlName="subject"
                        id="subject"
                        type="text" />
                </div>
            </div>
        </div>

        <!-- Only important if Verkoop factuur -->
        <div
            *ngIf="yukiDocument.folderId === 2"
            class="row">
            <div class="col">
                <div class="form-group m-b-2 required">
                    <label for="invoiceNumber">Invoice number</label>
                    <input
                        formControlName="invoiceNumber"
                        id="invoiceNumber"
                        type="text" />
                    <small
                        *ngIf="VATForm.get('invoiceNumber').invalid && VATForm.get('invoiceNumber').touched"
                        class="form-error">
                        Invoice number is required...
                    </small>
                </div>
            </div>
        </div>


        <div class="row">

            <div class="col">
                <div class="form-group required m-b-2">
                    <ng-container *ngIf="partners$ | async; else loading">
                        <label for="linkedResumoContact">Resumo contact</label>
                        <ng-autocomplete
                            [data]="partners"
                            [itemTemplate]="partnerTemplate"
                            [notFoundTemplate]="notFoundTemplate"
                            formControlName="linkedContact"
                            id="linkedResumoContact"
                            placeholder="Choose linked contact"
                        searchKeyword="title">
                        </ng-autocomplete>

                        <ng-template #partnerTemplate let-item>
                            <div class="autocomplete--item">
                                <a [innerHTML]="item.title"></a>
                            </div>
                        </ng-template>

                        <ng-template #notFoundTemplate>
                            <div (click)="openCreateContactModal()">+ Add new contact</div>
                        </ng-template>
                    </ng-container>
                </div>
            </div>

            <div class="col">
                <div class="form-group m-b-2">
                    <label for="contactName">Yuki contact name</label>
                    <input
                        formControlName="contactName"
                        id="contactName"
                        type="text" />
                </div>
            </div>
        </div>

        <p class="form-helptext">When you select a Resumo contact, the mapping will be saved and next time this Resumo contact will automatically be selected when a mapping is detected. This can be overridden when you are verifying the invoice.</p>

        <div class="row">
            <div class="col">
                <div class="form-group m-b-2">
                    <ng-container *ngIf="partners$ | async; else loading">
                        <ng-autocomplete
                            [data]="partners"
                            [itemTemplate]="partnerTemplate"
                            [notFoundTemplate]="notFoundTemplate"
                            formControlName="linkedContact"
                            placeholder="Choose linked contact"
                            searchKeyword="title">
                        </ng-autocomplete>

                        <p class="form-helptext">When you select a contact, next time this contact will automatically be selected. This can be overridden when you are verifying the invoice.</p>

                        <ng-template #partnerTemplate let-item>
                            <div class="autocomplete--item">
                                <a [innerHTML]="item.title"></a>
                            </div>
                        </ng-template>

                        <ng-template #notFoundTemplate>
                            <div (click)="openCreateContactModal()">+ Add new contact</div>
                        </ng-template>
                    </ng-container>
                </div>
            </div>
        </div>

        <div class="row">
            <description-input [formControl]="VATForm.controls['description']"></description-input>
        </div>
    </section>
</form>

<ng-template #amountExclCalcBtnTooltip> Calculate amount excl. based on total amount and VAT.</ng-template>

<ng-template #vatAmountCalcBtnTooltip> Calculate VAT based on total and amount excl.</ng-template>

<ng-template #loading>
    <app-loading-message></app-loading-message>
</ng-template>
