<div class="modal-header">
    <h2
        class="modal-title"
        id="exampleModalLabel">
        Assign resume
    </h2>
    <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <div *ngIf="(resumes$ | async)?.length != 0">
        <app-work-assign-resume-list
            *ngIf="assignedResumes"
            [resumes]="resumes$ | async"
            [assignedResumes]="assignedResumes"
            (resumeSelected)="OnSelect($event)"></app-work-assign-resume-list>

        <div class="m-t-4">
            <small class="color--grey">When you assign a resume, it will be assigned with an initial status. You can change this later on.</small>
        </div>
        <span
            class="form-error"
            *ngIf="assignmentWentWrong"
            >{{ errorMessage }}</span
        >
    </div>
    <app-message-empty
        *ngIf="(resumes$ | async)?.length == 0"
        [title]="'You have no résumés under your company.'"
        [subtitle]="'Add résumés under your company to get started.'"
        [icon]="'../../assets/img/open-box.png'">
    </app-message-empty>
</div>

<ng-template #loading>
    <app-loading-message></app-loading-message>
</ng-template>

<div class="modal-footer">
    <button
        type="button"
        class="button button--outline"
        (click)="activeModal.close()">
        Close
    </button>
    <button
        type="button"
        class="button button--primary"
        (click)="resumeSelected ? assignResume() : false">
        Assign
    </button>
    <!-- <button type="button" class="button button--primary" (click)="refresh()">Refresh</button> -->
</div>
