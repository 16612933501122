<div class="modal-header">
    <h2
        class="modal-title"
        id="exampleModalLabel">
        Resume preview
    </h2>
    <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <app-resume-detail
        [resumeid]="resumeUuid"
        [showActions]="false"></app-resume-detail>
    <button (click)="resumeDetail(resumeUuid)">Edit</button>
</div>
