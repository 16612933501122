import { Component, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { CompanyState } from 'src/app/companies/company.state';
import { Company } from 'src/app/models/Company';

@Component({
    selector: 'app-api-settings',
    templateUrl: './api-settings.component.html',
    styleUrls: ['./api-settings.component.scss'],
})
export class ApiSettingsComponent implements OnInit {
    @Select(CompanyState.company) company$: Observable<Company>;

    constructor() {}

    ngOnInit(): void {}
}
