import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { patch, removeItem } from '@ngxs/store/operators';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { AuthenticationService } from '../authentication/authentication.service';
import { GetCompanyMembers } from '../companies/company.actions';
import { CompanyService } from '../companies/company.service';
import { CompanyInvitedMember } from '../models/CompanyInvitedMember';
import User from '../models/User';
import { Resume } from '../models/resume/Resume';
import { FetchNotifications } from '../notifications/notifications.actions';
import { FetchPartners } from '../partners/partners.actions';
import { FetchAllowedActions, FetchAllowedModules, FetchRoles } from '../settings/roles/custom.roles.actions';
import { FetchAllTableDisplayedColumns } from '../settings/settings.actions';
import {
    AcceptCompanyInvite,
    ActionsOnStartup,
    ActivateAccount,
    ClearStore,
    DisableMfaAuthentication,
    EnableMfaAuthentication,
    ForgotPassword,
    GetQrCodeAuthentication,
    GetUser,
    GetUserById,
    GetUserInvites,
    Login,
    LoginWithMFA,
    Logout,
    PostVerifyMfa,
    Register,
    ResendVerification,
    ResetPassword,
    UpdatePassword,
    UpdateUser,
} from './user.actions';
import { UserService } from './user.service';

export interface UserStateModel {
    user: User;
    qrCode: string;
    userById: User;
}

@State<UserStateModel>({
    name: 'user',
    defaults: {
        user: null,
        qrCode: null,
        userById: null,
    },
})
@Injectable()
export class UserState {
    constructor(
        private userService: UserService,
        private companyService: CompanyService,
        private authenticationService: AuthenticationService,
        private store: Store,
    ) {}

    @Selector()
    static userEmail(state: UserStateModel): string {
        return state?.user.email;
    }

    @Selector()
    static userInvites(state: UserStateModel): CompanyInvitedMember[] {
        return state?.user.invites;
    }

    @Selector()
    static userHasCompany(state: UserStateModel): boolean {
        return state?.user.company?.uuid !== '' ?? false;
    }

    @Selector()
    static userCompanyId(state: UserStateModel): string {
        return state?.user.company?.uuid;
    }

    @Selector()
    static userCompanyResumes(state: UserStateModel): Resume[] {
        return state?.user.resumes;
    }

    @Selector()
    static userHasCompanyResumes(state: UserStateModel): boolean {
        return state?.user.resumes !== null ?? false;
    }

    @Selector()
    static hasUser(state: UserStateModel): boolean {
        return state?.user.uuid !== '' ?? false;
    }

    @Selector()
    static hasUserOwnerRole(state: UserStateModel): boolean {
        return state?.user.isOwner;
    }

    @Selector()
    static userInfo(state: UserStateModel): User {
        return state?.user;
    }

    @Selector()
    static userRole(state: UserStateModel): string {
        return state?.user.role;
    }

    @Selector()
    static qrInfo(state: UserStateModel): string {
        return state?.qrCode;
    }

    @Action(ActionsOnStartup)
    actionsOnStartup() {
        this.store.dispatch(new FetchRoles());
        this.store.dispatch(new FetchAllowedModules());
        this.store.dispatch(new FetchAllowedActions());
        this.store.dispatch(new FetchAllTableDisplayedColumns());
        this.store.dispatch(new FetchPartners());
        this.store.dispatch(new GetCompanyMembers());
        this.store.dispatch(new FetchNotifications());
    }

    @Action(ActivateAccount)
    activateUser(ctx: StateContext<UserStateModel>, action: ActivateAccount): void {
        this.userService.activateAccount(action.token).subscribe();
    }

    @Action(ForgotPassword)
    forgotPassword(ctx: StateContext<UserStateModel>, action: ForgotPassword): void {
        this.userService.forgotPassword(action.email).subscribe();
    }

    @Action(ResetPassword)
    resetPassword(ctx: StateContext<UserStateModel>, action: ResetPassword) {
        return this.userService.resetPassword(action.token, action.newPassword, action.passwordRepeat);
    }

    @Action(Login)
    login(ctx: StateContext<UserStateModel>, action: Login) {
        return this.authenticationService.login(action.user).pipe(
            tap((user) => {
                ctx.setState(
                    patch<UserStateModel>({
                        user: user,
                    }),
                );
            }),
        );
    }

    @Action(LoginWithMFA)
    loginWithMfa(ctx: StateContext<UserStateModel>, action: LoginWithMFA) {
        return this.authenticationService.loginWithMfa(action.user, action.mfaCode).pipe(
            tap((user) => {
                ctx.setState(
                    patch<UserStateModel>({
                        user: user,
                    }),
                );
            }),
        );
    }

    @Action(Register)
    register(ctx: StateContext<UserStateModel>, action: Register) {
        return this.authenticationService.registerAppUser(action.user).pipe(
            tap((user) => {
                ctx.setState(
                    patch<UserStateModel>({
                        user: user,
                    }),
                );
            }),
        );
    }

    @Action(ResendVerification)
    resendVerification(ctx: StateContext<UserStateModel>, action: ResendVerification) {
        return this.userService.resendVerificationMail(action.email);
    }

    @Action(GetUser)
    getUser(ctx: StateContext<UserStateModel>): Observable<User> {
        return this.userService.getUserData().pipe(
            tap((user) => {
                ctx.setState(
                    patch<UserStateModel>({
                        user: user,
                    }),
                );
            }),
        );
    }

    @Action(GetUserById)
    getUserById(ctx: StateContext<UserStateModel>, action: GetUserById): Observable<User> {
        return this.userService.getUserById(action.userId).pipe(
            tap((user) => {
                ctx.setState(
                    patch<UserStateModel>({
                        userById: user,
                    }),
                );
            }),
        );
    }

    @Action(EnableMfaAuthentication)
    enableMfaForUser(ctx: StateContext<UserStateModel>, action: EnableMfaAuthentication) {
        return this.userService.enableMfa(action.password);
    }

    @Action(DisableMfaAuthentication)
    disableMfaForUser(ctx: StateContext<UserStateModel>, action: DisableMfaAuthentication) {
        return this.userService.disableMfa(action.password);
    }

    @Action(GetQrCodeAuthentication)
    getMfaQrCodeForUser(ctx: StateContext<UserStateModel>, action: GetQrCodeAuthentication): Observable<any> {
        return this.userService.getMfaQRCode();
    }

    @Action(PostVerifyMfa)
    verifyMfaCodeForUser(ctx: StateContext<UserStateModel>, action: PostVerifyMfa): Observable<any> {
        return this.userService.verifyMfaCode(action.userId, action.code);
    }

    @Action(AcceptCompanyInvite)
    acceptCompanyInvite(ctx: StateContext<UserStateModel>, action: AcceptCompanyInvite) {
        return this.companyService.acceptCompanyInvite(action.inviteUuid).pipe(
            tap(() => {
                ctx.setState(
                    patch<UserStateModel>({
                        user: patch<User>({
                            invites: removeItem<CompanyInvitedMember>((i) => i.uuid === action.inviteUuid),
                        }),
                    }),
                );
            }),
        );
    }

    @Action(GetUserInvites)
    getUserInvites(ctx: StateContext<UserStateModel>, action: GetUserInvites): Observable<CompanyInvitedMember[]> {
        return this.companyService.getUserInvites(action.email).pipe(
            tap((inviteList) => {
                ctx.setState(
                    patch<UserStateModel>({
                        user: patch<User>({
                            invites: inviteList,
                        }),
                    }),
                );
            }),
        );
    }

    @Action(UpdatePassword)
    updatePassword(ctx: StateContext<UserStateModel>, action: UpdatePassword) {
        return this.userService.updatePassword(action.password);
    }

    @Action(UpdateUser)
    updateUser(ctx: StateContext<UserStateModel>, action: UpdateUser) {
        return this.userService.updateUser(action.user).pipe(
            tap((user) => {
                ctx.setState(
                    patch<UserStateModel>({
                        user: user,
                    }),
                );
            }),
        );
    }

    @Action(ClearStore)
    clearStore(ctx: StateContext<UserStateModel>) {
        ctx.setState({
            user: null,
            qrCode: null,
            userById: null,
        });
    }

    @Action(Logout)
    logout(ctx: StateContext<UserStateModel>) {
        this.authenticationService.logOut().pipe(
            tap(() => {
                this.clearStore(ctx);
            }),
        );
    }
}
