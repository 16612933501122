<form
    [formGroup]="formGroup"
    (ngSubmit)="onSubmit()"
    class="modal-wrapper">
    <div class="modal-header">
        <h2
            class="modal-title"
            id="exampleModalLabel">
            Link {{ itemType }}
        </h2>
        <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="activeModal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <div class="row">
            <div class="col">
                <app-global-autocomplete
                    [parentForm]="formGroup"
                    [formControlName]="'objectToLink'"
                    [data]="data"
                    [itemType]="itemType"
                    [label]="itemType"
                    [notFoundMessage]="'Add a ' + itemType"
                    [keyword]="keyword"
                    [placeholder]="'Choose a ' + itemType"
                    [active]="!isLoading"
                    [prefilledInformation]="prefilledInformation"></app-global-autocomplete>
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <button
            type="button"
            class="button button--outline"
            (click)="activeModal.close()">
            Close
        </button>
        <button
            type="submit"
            [ngClass]="{ 'button--loading': isLoading }"
            class="button button--primary">
            Link {{ itemType }}
        </button>
    </div>
</form>
