import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';
import { Module } from 'src/app/models/Module';

import { OutlookLoginService } from './outlook-login.service';

@Component({
    selector: 'app-outlook-login',
    templateUrl: './outlook-login.component.html',
    styleUrls: ['./outlook-login.component.scss'],
})
export class OutlookLoginComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Input() module: Module;
    loggedInOutlook: boolean;
    outlookAuth: string;

    constructor(private outlookService: OutlookLoginService, private logger: NGXLogger) {}

    ngOnInit(): void {
        this.logger.debug('checking logged in with microsoft');
        this.subscriptions.add(
            this.outlookService.getLoggedIn().subscribe((loggedIn) => {
                if (loggedIn) {
                    this.logger.debug('Logged in with Microsoft');
                    this.loggedInOutlook = true;
                } else {
                    this.logger.debug('Not logged in with microsoft');
                    this.loggedInOutlook = false;
                    this.getOutlookAuthUrl();
                }
            }),
        );
    }

    getOutlookAuthUrl() {
        this.logger.debug('Fetching Microsoft auth url');
        this.subscriptions.add(this.outlookService.getAuthorisationUrl().subscribe((url: any) => (this.outlookAuth = url.authUrl)));
    }

    logoutOutlook() {
        this.logger.debug('Attempt to logout of Microsoft');
        this.subscriptions.add(
            this.outlookService.logoutOutlook().subscribe(
                (result) => {
                    if (result) {
                        this.logger.debug('Succesfully logged out of microsoft');
                        this.loggedInOutlook = false;
                        this.getOutlookAuthUrl();
                    }
                },
                (error) => this.logger.error('Failed to logout of microsoft'),
            ),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
