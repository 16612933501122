import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import MailchimpTagModel from 'src/app/models/MailchimpTagModel';
import MailchimpTemplate from 'src/app/models/MailchimpTemplate';

import { MailchimpTemplateThumbnailComponent } from '../mailchimp-template-thumbnail/mailchimp-template-thumbnail.component';
import { FetchTemplates } from '../mailchimp.actions';
import { MailchimpService } from '../mailchimp.service';
import { MailchimpState } from '../mailchimp.state';

@Component({
    selector: 'app-mailchimp-template-list',
    templateUrl: './mailchimp-template-list.component.html',
    styleUrls: ['./mailchimp-template-list.component.scss'],
})
export class MailchimpTemplateListComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Select(MailchimpState.templates) templates$: Observable<MailchimpTagModel[]>;
    templates: MailchimpTemplate[];
    filter: string;
    isset = true;
    loading = true;

    constructor(private mailchimpService: MailchimpService, private modalService: NgbModal, private store: Store) {}

    ngOnInit(): void {
        this.subscriptions.add(
            this.mailchimpService.checkIfApiKeyExists().subscribe((e) => {
                if (e.key) {
                    this.templates$.subscribe((t) => {
                        if (t.length != 0) {
                            this.loading = false;
                        }
                    });
                    this.store.dispatch(new FetchTemplates()).subscribe(() => (this.loading = false));
                }
            }),
        );
    }

    openTemplateThumbnail(template: MailchimpTemplate) {
        const modalRef = this.modalService.open(MailchimpTemplateThumbnailComponent, { windowClass: 'modal-huge', animation: false });
        modalRef.componentInstance.template = template;
    }

    checkKey(set: boolean) {
        this.isset = set;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
