import { Unit } from '../models/Unit';

export class AddUnit {
    static readonly type = '[Unit] Add Unit';
    constructor(public unit: Unit) {}
}

export class FetchUnits {
    static readonly type = '[Unit] Fetch Units';
    constructor() {}
}

export class UpdateUnit {
    static readonly type = '[Unit] Update Unit';
    constructor(public unit: Unit) {}
}

export class DeleteUnit {
    static readonly type = '[Unit] Delete Unit';
    constructor(public unitUuid: string) {}
}
