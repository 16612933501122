import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

import { AuthenticationService } from '../authentication/authentication.service';
import { CompanyMember } from '../models/CompanyMember';

@Injectable({
    providedIn: 'root',
})
export class CalendarService {
    httpOptions = {};

    constructor(private httpClient: HttpClient, private authenticationService: AuthenticationService) {
        this.httpOptions = authenticationService.getHeaders();
    }

    getGoogleCalendarIsEnabled() {
        return this.httpClient.get<boolean>(environment.baseUrl + '/google/calendar/isEnabled', this.httpOptions);
    }

    getLogedInMembers() {
        return this.httpClient.get<CompanyMember[]>(environment.baseUrl + '/google/getLoggedInMembers', this.httpOptions);
    }
}
