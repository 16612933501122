import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subscription } from 'rxjs';
import User from 'src/app/models/User';
import { UserState } from 'src/app/users/user.state';

import Comment from '../../../models/Comment';
import { AddComment, FetchComments } from '../comments.actions';
import { CommentState } from '../comments.state';

@Component({
    selector: 'app-comments-overview',
    templateUrl: './comments-overview.component.html',
    styleUrls: ['./comments-overview.component.scss'],
})
export class CommentsOverviewComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();
    @Select(UserState.userInfo) user$: Observable<User>;
    @Select(CommentState.Comments) comments$: Observable<Comment[]>;
    userId = '';
    @Input() objectId: string;

    commentForm: FormGroup;

    isLoading = true;

    constructor(private store: Store, private fb: FormBuilder, private logger: NGXLogger) {}

    ngOnInit(): void {
        this.subscriptions.add(
            this.user$.subscribe((user) => {
                this.userId = user.uuid;
            }),
        );

        this.logger.debug('Fetching comments');
        this.subscriptions.add(
            this.store.dispatch(new FetchComments(this.objectId)).subscribe((result) => {
                if (result.length >= 0) {
                    this.isLoading = false;
                }
            }),
        );
        this.subscriptions.add(
            this.comments$.subscribe((c) => {
                if (c) {
                    this.isLoading = false;
                }
            }),
        );

        this.commentForm = this.fb.group({
            content: ['', [Validators.required]],
        });
    }

    addComment() {
        this.logger.debug('Attempt to add comment');
        const content = this.commentForm.get('content').value;
        this.commentForm.get('content').setValue('');
        this.subscriptions.add(
            this.store.dispatch(new AddComment(this.objectId, content)).subscribe(
                () => {
                    this.logger.debug('Succesfully added comment');
                    this.commentForm.get('content').setValue('');
                },
                (error) => this.logger.error('Failed to add comment'),
            ),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
