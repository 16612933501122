import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subscription } from 'rxjs';
import { Employee } from 'src/app/models/Employee';

import { EmployeesAddModalComponent } from '../employees-add-modal/employees-add-modal.component';
import { FetchEmployees } from '../employees.actions';
import { EmployeeState } from '../employees.state';

@Component({
    selector: 'app-employees-overview',
    templateUrl: './employees-overview.component.html',
    styleUrls: ['./employees-overview.component.scss'],
})
export class EmployeesOverviewComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Select(EmployeeState.Employees) employees$: Observable<Employee[]>;

    constructor(private modalService: NgbModal, private store: Store, private logger: NGXLogger) {}

    ngOnInit(): void {
        this.logger.debug('Fetching Employees');
        this.subscriptions.add(this.store.dispatch(new FetchEmployees()).subscribe());
    }

    openAddEmployeesModal() {
        this.logger.debug('Opening employeesAddModal');
        const modalRef = this.modalService.open(EmployeesAddModalComponent, {
            windowClass: 'modal-pane',
            animation: false,
        });
        modalRef.componentInstance.inOverview = true;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
