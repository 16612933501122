import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { FetchContacts } from 'src/app/contacts/contacts.actions';
import { ContactState } from 'src/app/contacts/contacts.state';
import { ContactPerson } from 'src/app/models/ContactPerson';
import { Resume } from 'src/app/models/resume/Resume';

import { ResumeShareContactpersonListComponent } from '../resume-share-contactperson-list/resume-share-contactperson-list.component';

@Component({
    selector: 'app-resume-share-modal',
    templateUrl: './resume-share-modal.component.html',
    styleUrls: ['./resume-share-modal.component.scss'],
})
export class ResumeShareModalComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Input() resume: Resume;

    @Select(ContactState.Contacts) contactPersons$: Observable<ContactPerson[]>;
    @ViewChild(ResumeShareContactpersonListComponent)
    resumeShareContactPersonList: ResumeShareContactpersonListComponent;

    constructor(public activeModal: NgbActiveModal, private store: Store, private toastr: ToastrService, private logger: NGXLogger) {}

    ngOnInit(): void {
        this.logger.debug('Fetching contacts');
        this.store.dispatch(new FetchContacts());
    }

    shareResume() {
        console.log('Not yet implemented');
        this.toastr.success('Shared resume');
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
