<form
    [formGroup]="unitFormGroup"
    (ngSubmit)="addUnit()"
    class="modal-wrapper">
    <div class="modal-header">
        <h2
            class="modal-title"
            id="exampleModalLabel">
            New unit
        </h2>
        <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="activeModal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <div class="row">
            <div class="col">
                <div class="form-group required m-b-2">
                    <label>Type</label>
                    <input
                        type="text"
                        placeholder="Type"
                        formControlName="type"
                        (focus)="hideErrorMessage()"
                        trim="blur"
                        #focussed />
                    <span
                        class="form-error"
                        *ngIf="unitFormGroup.controls.type.errors && inputIsWrong"
                        >Invalid unit type</span
                    >
                </div>
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <button
            type="button"
            class="button button--outline"
            (click)="activeModal.close()">
            Close
        </button>
        <button
            type="submit"
            [ngClass]="{ 'button--loading': loading }"
            class="button button--primary">
            Create unit
        </button>
    </div>
</form>
