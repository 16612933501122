import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

import { AuthenticationService } from '../authentication/authentication.service';
import CrmDeal, { CRMDealDTO } from '../models/CrmDeal';
import CrmDealReasonOfLoss from '../models/CrmDealReasonOfLoss';
import CrmStage from '../models/CrmStage';

@Injectable({
    providedIn: 'root',
})
export class CrmService {
    httpOptions = {};

    constructor(private httpClient: HttpClient, private authenticationService: AuthenticationService) {
        this.httpOptions = authenticationService.getHeaders();
    }

    getStages() {
        return this.httpClient.get<CrmStage[]>(`${environment.baseUrl}/crm/stages`, this.httpOptions);
    }

    getDeals() {
        return this.httpClient.get<CrmDeal[]>(`${environment.baseUrl}/crm/deals`, this.httpOptions);
    }

    getArchivedDeals() {
        return this.httpClient.get<CrmDeal[]>(`${environment.baseUrl}/crm/archived`, this.httpOptions);
    }

    addStage(stage: CrmStage) {
        return this.httpClient.post<CrmStage>(`${environment.baseUrl}/crm/stage`, stage, this.httpOptions);
    }

    addDeal(deal: CrmDeal) {
        const params = this.getDealParams(deal);
        deal.partner = null;
        deal.contactPerson = null;
        return this.httpClient.post<CrmDeal>(`${environment.baseUrl}/crm/deal${params}`, deal, this.httpOptions);
    }

    moveStage(stageUuid: string, position: number) {
        return this.httpClient.put<CrmStage[]>(`${environment.baseUrl}/crm/move/stage/${stageUuid}/${position}`, '', this.httpOptions);
    }

    /**
     * reasonUuid should be provided when a already existing reason was selected
     * reason can be empty in this case
     */
    moveDeal(stageUuid: string, dealUuid: string, reason: string, reasonUuid?: string) {
        return this.httpClient.put<CrmDeal>(
            `${environment.baseUrl}/crm/move/deal/${stageUuid}/${dealUuid}${reasonUuid ? '?reasonUuid=' + reasonUuid : ''}`,
            reason ? reason : 'No reason provided',
            this.httpOptions,
        );
    }

    updateStage(stage: CrmStage) {
        return this.httpClient.put<CrmStage>(`${environment.baseUrl}/crm/stage`, stage, this.httpOptions);
    }

    updateDeal(deal: CrmDeal) {
        return this.httpClient.put<CrmDeal>(`${environment.baseUrl}/crm/deal`, new CRMDealDTO(deal), this.httpOptions);
    }

    deleteStage(stageUuid: string) {
        return this.httpClient.delete<boolean>(`${environment.baseUrl}/crm/stage/${stageUuid}`, this.httpOptions);
    }

    deleteDeal(dealUuid: string) {
        return this.httpClient.delete<boolean>(`${environment.baseUrl}/crm/deal/${dealUuid}`, this.httpOptions);
    }

    archiveDeal(dealUuid: string) {
        return this.httpClient.delete<boolean>(`${environment.baseUrl}/crm/archive/${dealUuid}`, this.httpOptions);
    }

    dearchiveDeal(dealUuid: string) {
        return this.httpClient.put<CrmDeal>(`${environment.baseUrl}/crm/dearchive/${dealUuid}`, {}, this.httpOptions);
    }

    getReasons() {
        return this.httpClient.get<CrmDealReasonOfLoss[]>(`${environment.baseUrl}/crm/reasons`, this.httpOptions);
    }

    addReason(reason: string) {
        return this.httpClient.post<CrmDealReasonOfLoss>(`${environment.baseUrl}/crm/reasons`, reason, this.httpOptions);
    }

    updateReason(reasonUuid: string, reason: string) {
        return this.httpClient.put<CrmDealReasonOfLoss>(`${environment.baseUrl}/crm/reasons/${reasonUuid}`, reason, this.httpOptions);
    }

    removeReason(reasonUuid: string) {
        return this.httpClient.delete<boolean>(`${environment.baseUrl}/crm/reasons/${reasonUuid}`, this.httpOptions);
    }

    getDealParams(deal): string {
        let params = '?';

        if (deal.partner != null && deal.partner != '') {
            params += `partnerUuid=${deal.partner.uuid}`;
            deal.partner = null;
        }
        if (deal.contactPerson != null && deal.contactPerson != '') {
            if (params != '?') {
                params += '&';
            }
            params += `contactPersonUuid=${deal.contactPerson.uuid}`;
            deal.contactPerson = null;
        }
        if (params == '?') {
            params = '';
        }
        return params;
    }
}
