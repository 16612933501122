<div class="modal-header">
    <h2 class="modal-title">Add new overhead categories</h2>
    <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <section class="table__overflow">
        <p class="sectiontitle sectiontitle--first">New categories</p>

        <table class="table-view">
            <tr>
                <th scope="col">Category name</th>
                <th class="td-small"></th>
            </tr>
            <tr *ngFor="let overheadCategory of newOverheadCategories">
                <td>{{ overheadCategory.categoryName }}</td>
                <td class="td-icon">
                    <button
                        class="button button--icon"
                        (click)="deleteNewOverheadCategory(overheadCategory)">
                        <i class="las la-trash color--red"></i>
                    </button>
                </td>
            </tr>
        </table>
    </section>

    <form
        (ngSubmit)="addNewOverheadCategory()"
        class="m-t-4">
        <div class="d-flex align-items-end">
            <div class="w-100">
                <label for="newCategoryName">New category name</label>
                <input
                    type="text"
                    [(ngModel)]="newCategoryName"
                    id="newCategoryName"
                    name="newCategoryName" />
            </div>
            <button class="button button--outline button--icon m-l-2">
                <i class="las la-plus"></i>
            </button>
        </div>
    </form>
</div>

<div class="modal-footer">
    <input
        (click)="saveOverheadCategories()"
        [disabled]="newOverheadCategories.length === 0 && !newCategoryName"
        class="button button--primary"
        type="submit"
        value="Save" />
    <button
        class="button button--outline"
        (click)="activeModal.dismiss()">
        Cancel
    </button>
</div>
