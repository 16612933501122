import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '../authentication/auth.guard';
import { CompanyGuard } from '../companies/company.guard';
import { YukiOverviewComponent } from './yuki-overview/yuki-overview.component';

export const routes: Routes = [
    { path: '', redirectTo: 'overview', pathMatch: 'full' },
    {
        path: 'overview',
        component: YukiOverviewComponent,
        canActivate: [AuthGuard, CompanyGuard],
    },
];

export const routing: ModuleWithProviders<any> = RouterModule.forChild(routes);
