import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-query-error',
    templateUrl: './query-error.component.html',
    styleUrls: ['./query-error.component.scss'],
})
export class QueryErrorComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
