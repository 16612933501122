<div class="modal-header">
    <h2
        class="modal-title"
        id="exampleModalLabel">
        {{ module.key }}
    </h2>
    <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <div *ngIf="module.key === 'EXACT'">
        <app-exact-login module="module"></app-exact-login>
    </div>

    <div *ngIf="module.key === 'MAILCHIMP'">
        <app-mailchimp-login module="module"></app-mailchimp-login>

        <div class="sectiontitle">Features</div>

        <ul>
            <li>Read campaigns</li>
            <li>Send campaigns</li>
            <li>Create campaigns</li>
            <li>Read audience (lists)</li>
            <li>Update audience</li>
            <li>Read templates</li>
        </ul>
    </div>

    <div *ngIf="module.key === 'GOOGLE'">
        <app-google-login module="module"></app-google-login>
    </div>

    <div *ngIf="module.key === 'OUTLOOK'">
        <app-outlook-login module="module"></app-outlook-login>
    </div>

    <div *ngIf="module.key === 'TEAMLEADER'">
        <app-teamleader-login module="module"></app-teamleader-login>

        <div class="sectiontitle">Features</div>

        <ul>
            <li>Read Employees</li>
            <li>Read Contacts</li>
            <li>Read Departments</li>
            <li>Read Calendar Events</li>
            <li>Create Calendar Events</li>
            <li>Read Deals</li>
        </ul>
    </div>

    <div *ngIf="module.key === 'COMANAGE'">
        <app-comanage-login module="module"></app-comanage-login>

        <div class="sectiontitle">Features</div>

        <ul>
            <li>Read Contacts</li>
            <li>Read Invoices</li>
        </ul>
    </div>

    <div *ngIf="module.key === 'YUKI'">
        <app-yuki-login module="module"></app-yuki-login>
        <app-yuki-settings></app-yuki-settings>

        <div class="sectiontitle">Features</div>

        <ul>
            <li>Read Contacts</li>
            <li>Read Invoices</li>
        </ul>
    </div>

    <div *ngIf="module.key === 'JIRA'">
        <app-jira-login module="module"></app-jira-login>

        <div class="sectiontitle">Features</div>

        <ul>
            <li>Sync Timesheets every night</li>
        </ul>
    </div>
</div>

<div class="modal-footer">
    <button
        type="button"
        class="button button--outline"
        (click)="closeModal()">
        Close
    </button>
    <button
        *ngIf="module.key !== 'JIRA'"
        type="button"
        class="button button--outline"
        (click)="openDetails()">
        <i class="las la-external-link-alt"></i>&nbsp;Open
    </button>
</div>
