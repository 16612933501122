import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { patch, updateItem } from '@ngxs/store/operators';
import { tap } from 'rxjs/operators';

import { AddRelatedEmployee, DeleteRelatedEmployee, FetchRelatedEmployees } from './related.employees.action';
import { RelatedEmployeeService } from './related.employees.service';

export interface RelatedEmployeesStateModel {
    relatedEmployeesObjects: any[];
}

@State<RelatedEmployeesStateModel>({
    name: 'LinkedEmployeeObjects',
    defaults: {
        relatedEmployeesObjects: null,
    },
})
@Injectable()
export class RelatedEmployeesState {
    @Selector()
    static RelatedEmployeeObjects(state: RelatedEmployeesStateModel): any[] {
        return state?.relatedEmployeesObjects;
    }

    constructor(private relatedEmployeeService: RelatedEmployeeService) {}

    @Action(FetchRelatedEmployees)
    fetchRelatedEmployees(ctx: StateContext<RelatedEmployeesStateModel>) {
        return this.relatedEmployeeService.getAllRelatedEmployees().pipe(
            tap((objects) => {
                ctx.setState(
                    patch<RelatedEmployeesStateModel>({
                        relatedEmployeesObjects: objects,
                    }),
                );
            }),
        );
    }

    @Action(AddRelatedEmployee)
    addEmployee(ctx: StateContext<RelatedEmployeesStateModel>, action: AddRelatedEmployee) {
        return this.relatedEmployeeService.addRelatedEmployee(action.uuid, action.employees).subscribe((object) => {
            ctx.setState(
                patch<RelatedEmployeesStateModel>({
                    relatedEmployeesObjects: updateItem((o) => o.uuid === action.uuid, object),
                }),
            );
        });
    }

    @Action(DeleteRelatedEmployee)
    removeEmployeel(ctx: StateContext<RelatedEmployeesStateModel>, action: DeleteRelatedEmployee) {
        return this.relatedEmployeeService.deleteRelatedEmployee(action.uuid, action.employeeUuid).subscribe((object) => {
            ctx.setState(
                patch<RelatedEmployeesStateModel>({
                    relatedEmployeesObjects: updateItem((o) => o.uuid === action.uuid, object),
                }),
            );
        });
    }
}
