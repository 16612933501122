<table class="table-view">
    <tr>
        <th>Course</th>
        <th>School</th>
        <th>Period</th>
        <th class="td-small"></th>
    </tr>
    <tr *ngFor="let education of educations">
        <td><strong>{{education.degree}} - {{education.studyField}}</strong></td>
        <td>{{education.institution}} ({{ education.country.iso2 }})</td>
        <td>
            {{education.startDate | date:'y'}} -
            <ng-container
                *ngIf="!education.present">{{education.endDate | date:'y'}}</ng-container>
            <ng-container *ngIf="education.present">present</ng-container>
        </td>
        <td class="td-icon">
            <div
                *ngIf="showActions"
                class="dropdown"
                ngbDropdown>
                <button
                    class="button button--icon button--small dropdown-toggle"
                    id="dropdownMenuButton"
                    ngbDropdownToggle
                    type="button"></button>
                <div
                    aria-labelledby="dropdownMenuButton"
                    class="dropdown-menu dropdown-menu-right"
                    ngbDropdownMenu>
                    <a (click)="openUpdateEducationModal(education)" class="dropdown-item">
                        Edit education
                    </a>
                    <a (click)="openDeleteEducationModal(education)" class="dropdown-item color--red">
                        Delete
                    </a>
                </div>
            </div>
        </td>
    </tr>
    <tr *ngIf="educations?.length === 0">
        <td colspan="4">
            No education added yet...
        </td>
    </tr>
    <tr>
        <td (click)="openEducationAddModal()" class="table-view__add" colspan="4">
            <i class="las la-plus"></i>
            Add education
        </td>
    </tr>
</table>
