import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTableModule } from '@angular/material/table';
import { NgbDatepicker, NgbDropdown, NgbDropdownModule, NgbModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';

import { CompaniesModule } from '../companies/companies.module';
import { SharedModule } from '../shared/shared.module';
import { AccountSettingsComponent } from './account-settings/account-settings.component';
import { ApiSettingsComponent } from './api-settings/api-settings.component';
import { AppInfoComponent } from './app-info/app-info.component';
import { CompanyMembersComponent } from './company-members/company-members.component';
import { CompanySettingsComponent } from './company-settings/company-settings.component';
import { CompanyTagsComponent } from './company-tags/company-tags.component';
import { GeneralSettingsComponent } from './general-settings/general-settings.component';
import { AddMailToMailinglistModalComponent } from './mail-setup/add-mail-to-mailinglist-modal/add-mail-to-mailinglist-modal.component';
import { MailSetupComponent } from './mail-setup/mail-setup.component';
import { ModuleSettingsComponent } from './module-settings/module-settings.component';
import { ModuleSetupComponent } from './module-setup/module-setup.component';
import { ProjectSettingsComponent } from './project-settings/project-settings.component';
import { ResumeSettingsComponent } from './resume-settings/resume-settings.component';
import { RoleAddModalComponent } from './roles/role-add-modal/role-add-modal.component';
import { RoleDeleteModalComponent } from './roles/role-delete-modal/role-delete-modal.component';
import { RoleMemberAssignModalComponent } from './roles/role-member-assign-modal/role-member-assign-modal.component';
import { RolesComponent } from './roles/roles-overview/roles.component';
import { SettingsSubnavComponent } from './settings-subnav/settings-subnav.component';
import { SettingsTokenRetrievalComponent } from './settings-token-retrieval/settings-token-retrieval.component';
import { routing } from './settings.routing';
import { UpdateDaysPerMonthModalComponent } from './update-days-per-month-modal/update-days-per-month-modal.component';
import { UpdateHolidayDaysModalComponent } from './update-holiday-days-modal/update-holiday-days-modal.component';

@NgModule({
    declarations: [
        AccountSettingsComponent,
        CompanySettingsComponent,
        ResumeSettingsComponent,
        SettingsSubnavComponent,
        CompanyTagsComponent,
        ProjectSettingsComponent,
        ModuleSettingsComponent,
        ApiSettingsComponent,
        CompanyMembersComponent,
        ModuleSetupComponent,
        RolesComponent,
        SettingsTokenRetrievalComponent,
        RoleAddModalComponent,
        RoleDeleteModalComponent,
        RoleMemberAssignModalComponent,
        AddMailToMailinglistModalComponent,
        AppInfoComponent,
        MailSetupComponent,
        GeneralSettingsComponent,
        UpdateDaysPerMonthModalComponent,
        UpdateHolidayDaysModalComponent,
    ],
    imports: [CommonModule, SharedModule, CompaniesModule, AutocompleteLibModule, routing, NgbModule, NgbDropdownModule, NgbNavModule, MatTableModule],
    providers: [NgbDropdown],
    exports: [SettingsSubnavComponent, CompanyMembersComponent],
})
export class SettingsModule {}
