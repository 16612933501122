import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { GlobalTag } from 'src/app/models/GlobalTag';
import { ResumeVariant } from 'src/app/models/resume/ResumeVariant';
import { ResumeVariantSkill } from 'src/app/models/resume/ResumeVariantSkill';
import { GlobalTagsState } from 'src/app/shared/global-tags/global-tags.state';

import { AddResumeVariantSkill, UpdateResumeVariantSkill } from '../resume.actions';

@Component({
    selector: 'app-resume-variant-skill-detail-modal',
    templateUrl: './resume-variant-skill-detail-modal.component.html',
    styleUrls: ['./resume-variant-skill-detail-modal.component.scss'],
})
export class ResumeVariantSkillDetailModalComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();
    @Select(GlobalTagsState.GlobalTags) globalTags$: Observable<GlobalTag[]>;

    @Input() resumeVariant: ResumeVariant;
    @Input() skill: ResumeVariantSkill;

    newSkill: ResumeVariantSkill;
    skillFormGroup: FormGroup;
    errorMessage: string;
    connectionError: string;
    present = false;
    isUpdate = false;
    loading = false;
    public inputIsWrong = false;
    public skillWentWrong = false;
    error: any = { isError: false, errorMessage: '' };

    allTagsString: any[] = [];
    objectTags: GlobalTag[] = [];

    isLoading = true;

    constructor(
        public activeModal: NgbActiveModal,
        private formBuilder: FormBuilder,
        private store: Store,
        private toastr: ToastrService,
        private logger: NGXLogger,
    ) {}

    ngOnInit(): void {
        if (this.skill) {
            this.isUpdate = true;
        }
        this.skillFormGroup = this.formBuilder.group({
            title: ['', [Validators.required, Validators.min(1), Validators.max(100)]],
            years: [0, [Validators.min(0.1), Validators.max(100)]],
        });

        this.subscriptions.add(
            this.globalTags$.subscribe((tags) => {
                this.objectTags = tags?.filter((tag) => tag.taggables.find((object) => object.uuid == this.resumeVariant.resumeId));
                this.allTagsString = [];
                this.objectTags.forEach((el) => {
                    this.allTagsString.push(el.title);
                });
                this.isLoading = false;

                if (this.isUpdate) {
                    this.setFormValues();
                }
            }),
        );
    }

    setFormValues() {
        this.skillFormGroup.setValue({
            title: this.skill.title,
            years: this.skill.years,
        });
    }

    saveVariant() {
        this.loading = true;

        if (!this.skillFormGroup.valid) {
            this.inputIsWrong = true;
            this.loading = false;
            return;
        }

        this.skillFormGroup.disable();

        this.newSkill = this.skillFormGroup.value;

        if (!this.isUpdate) {
            this.logger.debug('Attempt to add Skill');
            this.subscriptions.add(
                this.store.dispatch(new AddResumeVariantSkill(this.resumeVariant.uuid, this.newSkill)).subscribe({
                    next: (state) => {
                        this.logger.debug('Succesfully added skill');

                        this.toastr.success('Skill added succesfully');
                        this.activeModal.close();
                    },
                    error: (error) => {
                        this.logger.error('Failed to add skill');
                        this.errorMessage = error?.error?.message ?? 'Error message was empty';

                        this.loading = false;
                        this.skillFormGroup.enable();

                        if (!this.errorMessage) {
                            this.connectionError = 'No connection to the server';
                        }

                        this.skillWentWrong = true;
                    },
                }),
            );
        } else {
            this.logger.debug('Attempt to update skill');
            this.subscriptions.add(
                this.store.dispatch(new UpdateResumeVariantSkill(this.skill.uuid, this.newSkill)).subscribe({
                    next: () => {
                        this.logger.debug('Succesfully updated Skill');

                        this.activeModal.close();
                    },
                    error: (error) => {
                        this.logger.error('Failed to update skill');
                        this.errorMessage = error?.error?.message ?? 'Error message was empty';

                        this.loading = false;
                        this.skillFormGroup.enable();

                        if (!this.errorMessage) {
                            this.connectionError = 'No connection to the server';
                        }

                        this.skillWentWrong = true;
                    },
                }),
            );
        }
    }

    hideErrorMessage() {
        this.inputIsWrong = false;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
