import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subscription } from 'rxjs';
import CrmDealReasonOfLoss from 'src/app/models/CrmDealReasonOfLoss';

import { FetchReasons } from '../crm.actions';
import { CrmState } from '../crm.state';

@Component({
    selector: 'app-lost-deal-reason-modal',
    templateUrl: './lost-deal-reason-modal.component.html',
    styleUrls: ['./lost-deal-reason-modal.component.scss'],
})
export class LostDealReasonModalComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    public reasonEmitter: EventEmitter<any> = new EventEmitter();
    @Select(CrmState.Reasons) reasons$: Observable<CrmDealReasonOfLoss[]>;
    reason = '';
    maxLength = 250;
    currentLength = 0;
    isLoading = true;

    reasonForm: FormGroup;
    addNewReason = false;

    constructor(private store: Store, private fb: FormBuilder, public activeModal: NgbActiveModal, private logger: NGXLogger) {}

    ngOnInit(): void {
        this.logger.debug('Attempt to fetch Reasons');
        this.subscriptions.add(
            this.store.dispatch(new FetchReasons()).subscribe({
                next: () => {
                    this.logger.debug('Succesfully fetched reasons');
                    this.isLoading = false;
                },
                error: () => this.logger.error('Failed to fetch reasons'),
            }),
        );
        this.reasonForm = this.fb.group({
            reason: ['', [Validators.required]],
        });
    }

    submit() {
        if (this.addNewReason) {
            if (this.reason == '') {
                this.cancel();
            } else {
                this.reasonEmitter.emit({
                    uuid: undefined,
                    reason: this.reason.substring(0, this.maxLength),
                });
                this.activeModal.close();
            }
        } else {
            this.reasonEmitter.emit({
                uuid: (this.reasonForm.get('reason').value as unknown as CrmDealReasonOfLoss).uuid,
                reason: undefined,
            });
            this.activeModal.close();
        }
    }

    cancel() {
        this.reasonEmitter.emit('No reason was given.');
        this.activeModal.close();
    }

    onKeyUp() {
        this.currentLength = this.reason.length;
        if (this.currentLength > this.maxLength) {
            this.reason = this.reason.substring(0, this.maxLength);
            this.currentLength = this.reason.length;
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
