<table
    mat-table
    [dataSource]="product.linkedProducts | filterBy: ['childProduct']:true | filterBy: ['archived']:false"
    matSort
    *ngIf="product">
    <ng-container matColumnDef="child product">
        <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header>
            Child product
        </th>
        <td
            mat-cell
            *matCellDef="let childMap">
            <span
                placement="top"
                [ngbTooltip]="childMap.productName">
                <app-pill-product
                    [productUuid]="childMap.productUuid"
                    [productName]="childMap.productName"></app-pill-product>
            </span>
        </td>
    </ng-container>

    <ng-container matColumnDef="amount">
        <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header>
            Amount
        </th>
        <td
            mat-cell
            *matCellDef="let childMap">
            {{ childMap.amount }} {{ childUnitType(childMap.productUuid) }}
        </td>
    </ng-container>

    <ng-container matColumnDef="in stock">
        <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header>
            In stock
        </th>
        <td
            mat-cell
            *matCellDef="let childMap">
            <span
                class="badge badge--warning"
                data-toggle="tooltip"
                title="In stock: {{ childInStock(childMap.productUuid) }}"
                *ngIf="childInStock(childMap.productUuid) < childMap.amount && childInStock(childMap.productUuid) > 0">
                Not enough</span
            >
            <span
                class="badge badge--danger"
                data-toggle="tooltip"
                title="In stock: {{ childInStock(childMap.productUuid) }}"
                *ngIf="childInStock(childMap.productUuid) === 0">
                None</span
            >
            <span
                class="badge badge--success"
                data-toggle="tooltip"
                title="In stock: {{ childInStock(childMap.productUuid) }}"
                *ngIf="childInStock(childMap.productUuid) >= childMap.amount && childInStock(childMap.productUuid) !== 0">
                Enough</span
            >
        </td>
    </ng-container>

    <ng-container matColumnDef="edit">
        <th
            mat-header-cell
            *matHeaderCellDef
            class="td-small"></th>
        <td
            mat-cell
            *matCellDef="let childMap"
            class="td-small">
            <span
                ngbDropdown
                class="dropdown">
                <button
                    ngbDropdownToggle
                    class="button button--icon button--small dropdown-toggle"
                    id="dropdownMenuButton"></button>
                <div
                    ngbDropdownMenu
                    class="dropdown-menu dropdown-menu-right"
                    aria-labelledby="dropdownMenuButton">
                    <a
                        class="dropdown-item"
                        (click)="openProductEditChildModal(product, childMap.amount, childMap.productUuid)"
                        >Edit amount</a
                    >
                    <div class="dropdown-divider"></div>
                    <a
                        class="dropdown-item color--red"
                        (click)="openProductChildDeleteModal(product, childMap.productUuid)"
                        >Remove product</a
                    >
                </div>
            </span>
        </td>
    </ng-container>

    <ng-container matColumnDef="add">
        <td
            mat-footer-cell
            *matFooterCellDef
            colspan="4"
            class="table-view__add"
            (click)="openLinkProductModal()">
            <i class="las la-plus"></i> Add child products
        </td>
    </ng-container>

    <tr
        mat-header-row
        *matHeaderRowDef="displayedColumns"></tr>

    <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"></tr>

    <tr
        mat-footer-row
        *matFooterRowDef="['add']"></tr>
</table>

<table
    class="table-view"
    *ngIf="!product && selectedChildProducts">
    <tr>
        <th>Child product</th>
        <th>Amount</th>
        <th class="td-small"></th>
    </tr>
    <tr *ngFor="let child of selectedChildProducts">
        <td>
            <app-pill-product
                [productUuid]="child.uuid"
                [productName]="child.name"></app-pill-product>
        </td>
        <td>
            {{ child.amountAssigned }}
        </td>
        <td>
            <a
                type="button"
                class="las la-times"
                aria-label="Close"
                (click)="removeFromSelectedChildProducts(child)"
                style="color: red; cursor: pointer"></a>
        </td>
    </tr>

    <tr>
        <td
            colspan="3"
            class="table-view__add"
            (click)="openLinkProductModal()">
            <i class="las la-plus"></i> Add child products
        </td>
    </tr>
</table>
