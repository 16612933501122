<app-date-navigation
    (selectedDateUpdated)="updateSelectedDate()"
    [dateNavigationOptions]="dateNavigationOptions"></app-date-navigation>

<ng-container
    *ngIf="!isLoading && incomeForProject?.detailId === projectId && costsForProject?.detailId === projectId; else loading">
    <app-default-line-chart
        (clickEvent)="displayInfo($event)"
        [data]="data"
        [isFinancial]="true"
        [legendPosition]="legendPosition"
        [objectLabels]="labels"
        [options]="options"
        [xLabels]="xLabels">
    </app-default-line-chart>

    <table class="table-view m-t-4">
        <tr>
            <td>Total income</td>
            <td>{{ calculateTotalIncomeForSelectedYear() | currency: 'EUR' }}</td>
        </tr>
        <tr>
            <td>Total cost</td>
            <td>{{ calculateTotalCostForSelectedYear() | currency: 'EUR' }}</td>
        </tr>
        <tr>
            <td><strong>Total margin</strong></td>
            <td>
                <p>{{ calculateTotalMarginForSelectedYearInPct() | percent: '1.2-2' }}</p>
                <p class="form-helptext form-helptext--no-padding">{{ calculateTotalMarginForSelectedYear()  | currency: 'EUR' }}</p>
            </td>
        </tr>
    </table>
</ng-container>

<ng-template #loading>
    <app-loading-message></app-loading-message>
</ng-template>
