<nav class="app__subnav">
    <a
        routerLink="/inventory/stock-history"
        (click)="resetSelected()"
        routerLinkActive="app__subnav__active"
        >Stock History</a
    >
    <a
        routerLink="/inventory/products"
        (click)="changeCategory(null)"
        routerLinkActive="app__subnav__active"
        >Products</a
    >
    <nav
        *ngIf="(productCategories$ | async) != null && (productCategories$ | async).length > 0"
        class="app__subnav app__subnav__child">
        <a
            *ngFor="let category of productCategories$ | async"
            routerLink="/inventory/products"
            (click)="changeCategory(category)"
            [ngClass]="{ app__subnav__active: category == selected }"
            >{{ category | lowercase }}</a
        >
        <a
            routerLink="/inventory/products"
            (click)="changeCategory(null)"
            [ngClass]="{
                app__subnav__active: selected == null && selected !== ''
            }"
            >all</a
        >
    </nav>
    <a
        routerLink="/inventory/warehouses"
        (click)="resetSelected()"
        routerLinkActive="app__subnav__active"
        >Warehouses</a
    >
    <a
        routerLink="/inventory/warehouse-locations"
        (click)="resetSelected()"
        routerLinkActive="app__subnav__active"
        >Locations</a
    >
    <a
        routerLink="/inventory/units"
        (click)="resetSelected()"
        routerLinkActive="app__subnav__active"
        >Units</a
    >
</nav>
