import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subscription } from 'rxjs';
import User from 'src/app/models/User';

import { GetUser, UpdateUser } from '../user.actions';
import { UserState } from '../user.state';

@Component({
    selector: 'app-user-update-modal',
    templateUrl: './user-update-modal.component.html',
    styleUrls: ['./user-update-modal.component.scss'],
})
export class UserUpdateModalComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    user$: Observable<User>;
    user: User;
    userFormGroup: FormGroup;
    errorMessage: string;
    loading = false;
    inputIsWrong = false;
    updateUserWentWrong = false;

    constructor(public activeModal: NgbActiveModal, private formBuilder: FormBuilder, private store: Store, private logger: NGXLogger) {}

    ngOnInit(): void {
        this.user$ = this.store.select(UserState.userInfo);
        this.userFormGroup = this.formBuilder.group({
            firstName: [''],
            lastName: [''],
            email: ['', Validators.email],
        });
        this.setFormValues();
    }

    setFormValues() {
        this.subscriptions.add(
            this.user$.subscribe((user) => {
                this.userFormGroup.controls.firstName.setValue(user.firstName);
                this.userFormGroup.controls.lastName.setValue(user.lastName);
                this.userFormGroup.controls.email.setValue(user.email);
            }),
        );
    }

    updateUser() {
        this.loading = true;

        if (!this.userFormGroup.valid) {
            this.inputIsWrong = true;
            this.loading = false;
            return;
        }

        this.userFormGroup.disable();

        this.user = this.userFormGroup.value;

        this.logger.debug('Attempt to update the user');
        this.subscriptions.add(
            this.store.dispatch(new UpdateUser(this.user)).subscribe(
                (user) => {
                    this.logger.debug('Succesfully updated the user');
                    this.store.dispatch(new GetUser());
                    this.activeModal.close();
                },
                (error) => {
                    this.logger.error('Failed to update the user');
                    this.errorMessage = error?.error?.message ?? 'Error message was empty';

                    this.loading = false;
                    this.updateUserWentWrong = true;

                    this.userFormGroup.enable();
                },
            ),
        );
    }

    // Hides the error messages that appear after a user has submitted invalid data
    hideErrorMessage() {
        this.inputIsWrong = false;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
