import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { append, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { tap } from 'rxjs/operators';
import Comment from 'src/app/models/Comment';

import { CreateNotificationForComments } from '../../notifications/notifications.actions';
import { AddComment, DeleteComment, FetchComments, FetchLatestComments, UpdateComment } from './comments.actions';
import { CommentService } from './comments.service';

export interface CommentStateModel {
    currentObjectComments: Comment[];
    latestComments: Comment[];
}

@State<CommentStateModel>({
    name: 'comment',
    defaults: {
        currentObjectComments: null,
        latestComments: null,
    },
})
@Injectable()
export class CommentState {
    constructor(private commentService: CommentService, private store: Store) {}

    @Selector()
    static Comments(state: CommentStateModel): Comment[] {
        return state?.currentObjectComments;
    }

    @Selector()
    static LatestComments(state: CommentStateModel): Comment[] {
        return state?.latestComments;
    }

    @Action(FetchLatestComments)
    fetch(ctx: StateContext<CommentStateModel>) {
        return this.commentService.getLatestComments().pipe(
            tap((comments) => {
                ctx.setState(
                    patch<CommentStateModel>({
                        latestComments: comments,
                    }),
                );
            }),
        );
    }

    @Action(FetchComments)
    fetchCurrent(ctx: StateContext<CommentStateModel>, action: FetchComments) {
        if (ctx.getState().currentObjectComments?.length > 0) {
            if (action.objectUuid != ctx.getState().currentObjectComments[0].commentableId) {
                ctx.setState(
                    patch<CommentStateModel>({
                        currentObjectComments: [],
                    }),
                );
            }
        }

        return this.commentService.getComments(action.objectUuid).pipe(
            tap((comments) => {
                ctx.setState(
                    patch<CommentStateModel>({
                        currentObjectComments: comments,
                    }),
                );
            }),
        );
    }

    @Action(AddComment)
    addComment(ctx: StateContext<CommentStateModel>, action: AddComment) {
        const com: Comment = {
            uuid: '',
            createdOn: new Date(),
            creatorUuid: '',
            content: action.content,
            category: '',
            commentableId: action.objectUuid,
        };
        ctx.setState(
            patch<CommentStateModel>({
                currentObjectComments: append<Comment>([com]),
            }),
        );
        return this.commentService.addComment(action.objectUuid, action.content).pipe(
            tap((comment) => {
                ctx.setState(
                    patch<CommentStateModel>({
                        currentObjectComments: updateItem<Comment>((c) => c.uuid === '', comment),
                    }),
                );
                this.fetch(ctx).subscribe();

                this.store.dispatch(new CreateNotificationForComments(action.objectUuid, 'created'));
            }),
        );
    }

    @Action(UpdateComment)
    updateComment(ctx: StateContext<CommentStateModel>, action: UpdateComment) {
        return this.commentService.updateComment(action.commentUuid, action.content).pipe(
            tap((comment) => {
                ctx.setState(
                    patch<CommentStateModel>({
                        currentObjectComments: updateItem<Comment>((c) => c.uuid === action.commentUuid, comment),
                    }),
                );
                if (ctx.getState().latestComments && ctx.getState().latestComments.find((c) => c.uuid === action.commentUuid)) {
                    this.fetch(ctx).subscribe();
                }
                this.store.dispatch(new CreateNotificationForComments(action.objectId, 'updated'));
            }),
        );
    }

    @Action(DeleteComment)
    deleteComment(ctx: StateContext<CommentStateModel>, action: DeleteComment) {
        return this.commentService.deleteComment(action.commentUuid).pipe(
            tap(() => {
                ctx.setState(
                    patch<CommentStateModel>({
                        currentObjectComments: removeItem<Comment>((c) => c.uuid === action.commentUuid),
                    }),
                );
                if (ctx.getState().latestComments && ctx.getState().latestComments.find((c) => c.uuid === action.commentUuid)) {
                    this.fetch(ctx).subscribe();
                }
                this.store.dispatch(new CreateNotificationForComments(action.objectId, 'deleted'));
            }),
        );
    }
}
