<div *ngIf="active">
    <label>{{ label }}</label>
    <div
        class="form-group m-b-2"
        [ngClass]="{ required: required }"
        [formGroup]="parentForm">
        <app-loading-message *ngIf="!dataLoaded"></app-loading-message>
        <div
            class="d-flex"
            *ngIf="dataLoaded">
            <div class="ng-autocomplete">
                <ng-autocomplete
                    formControlName="{{ formControlName }}"
                    [data]="this.data | orderBy: keyword:false"
                    [searchKeyword]="keyword"
                    [itemTemplate]="itemTemplate"
                    autocomplete="off"
                    [notFoundTemplate]="notFoundTemplate"
                    placeholder="{{ placeholder }}">
                </ng-autocomplete>

                <ng-template
                    #itemTemplate
                    let-item>
                    <a [innerHTML]="item[keyword]"></a>
                </ng-template>

                <ng-template
                    #notFoundTemplate
                    let-notFound>
                    <div
                        *ngIf="itemType && addItemFunction !== undefined"
                        (click)="addItemFunction()">
                        + {{ notFoundMessage }}
                    </div>
                    <div *ngIf="!itemType">+ {{ notFoundMessage }}</div>
                </ng-template>
            </div>
            <button
                *ngIf="itemType && addItemFunction !== undefined && canAdd"
                type="button"
                class="button button--outline button--icon m-l-2"
                (click)="addItemFunction()">
                <i class="las la-plus"></i>
            </button>
        </div>
    </div>
</div>
