<app-app-holder>
    <app-navbar></app-navbar>

    <app-loading-message *ngIf="isLoading"></app-loading-message>
    <app-content *ngIf="!isLoading">
        <div class="app__title">
            <div class="w-100">
                <h1
                    *ngIf="!editName"
                    (dblclick)="editName = true">
                    {{ document.name }}
                </h1>
                <span
                    *appIsPermitted="{
                        module: 'DOCUMENTS',
                        operator: 'AND',
                        actions: ['EDIT']
                    }">
                    <input
                        *ngIf="editName"
                        type="text"
                        [(ngModel)]="name" />
                </span>
            </div>

            <span class="m-l-2">
                <span
                    ngbDropdown
                    class="m-l-2">
                    <button
                        ngbDropdownToggle
                        class="button button--icon button--dropdown">
                        Options
                    </button>
                    <span
                        ngbDropdownMenu
                        class="dropdown-menu dropdown-menu-right"
                        aria-labelledby="dropdownMenuButton">
                        <a
                            class="dropdown-item"
                            (click)="getDocumentPdf()"
                            >Export Pdf</a
                        >
                        <a
                            class="dropdown-item"
                            *appIsPermitted="{
                                module: 'DOCUMENTS',
                                operator: 'AND',
                                actions: ['EDIT']
                            }"
                            (click)="editName = true"
                            >Rename</a
                        >
                        <a
                            class="dropdown-item color--red"
                            *appIsPermitted="{
                                module: 'DOCUMENTS',
                                operator: 'AND',
                                actions: ['DELETE']
                            }"
                            (click)="deleteDocument()"
                            >Delete</a
                        >
                    </span>
                </span>

                <a
                    class="button button--primary m-l-2"
                    *appIsPermitted="{
                        module: 'DOCUMENTS',
                        operator: 'AND',
                        actions: ['EDIT']
                    }"
                    (click)="save()"
                    >Save</a
                >
            </span>
        </div>

        <div class="document">
            <ckeditor
                class="document__left"
                data="{{ document.content }}"
                [editor]="editor"
                (ready)="onReady($event)"></ckeditor>

            <div class="document__right">
                <div *ngIf="!loadingRelations">
                    <span
                        *appIsPermitted="{
                            module: 'PROJECTS',
                            operator: 'AND',
                            actions: ['READ']
                        }">
                        <div *ngIf="projects.length > 0">
                            <h4 class="document__right__title">Projects</h4>
                            <div *ngFor="let project of projects">
                                <app-pill-project [project]="project"></app-pill-project>
                            </div>
                        </div>
                    </span>

                    <span
                        *appIsPermitted="{
                            module: 'INVENTORY',
                            operator: 'AND',
                            actions: ['READ']
                        }">
                        <div *ngIf="products.length > 0">
                            <h4 class="document__right__title">Products</h4>
                            <div *ngFor="let product of products">
                                <app-pill-product [product]="product"></app-pill-product>
                            </div>
                        </div>
                    </span>
                </div>
            </div>
        </div>
    </app-content>

    <ng-template #loading>
        <app-loading-message></app-loading-message>
    </ng-template>
</app-app-holder>
