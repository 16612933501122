import { Applicant } from '../models/Applicants/Applicant';
import { ApplicantLane } from '../models/Applicants/ApplicantLane';

export class AddApplicant {
    static readonly type = '[Company] Add Applicant';
    constructor(public applicant: Applicant) {}
}
export class UpdateApplicant {
    static readonly type = '[Company] Update Applicant';
    constructor(public applicant: Applicant) {}
}

export class ArchiveApplicant {
    static readonly type = '[Applicant] Archive Applicant';
    constructor(public uuid: string) {}
}

export class UnArchiveApplicant {
    static readonly type = '[Applicant] UnArchive Applicant';
    constructor(public uuid: string) {}
}

export class FetchApplicants {
    static readonly type = '[Applicant] Fetch Applicant';
}

export class FetchArchivedApplicants {
    static readonly type = '[Applicant] Fetch Archived Applicant';
}

export class FetchApplicantLanes {
    static readonly type = '[ApplicantLane[]] fetch applicantLanes';
}

export class AddApplicantLane {
    static readonly type = '[ApplicantLane] add applicantLane';
    constructor(public applicantLane: ApplicantLane) {}
}

export class UpdateApplicantLane {
    static readonly type = '[ApplicantLane] update applicantLane';
    constructor(public laneUuid: string, public applicantLane: ApplicantLane) {}
}

export class MoveApplicantLaneSequence {
    static readonly type = '[ApplicantLane[]] move applicantLane sequence';
    constructor(public laneUuid: string, public sequence: number) {}
}

export class MoveApplicant {
    static readonly type = '[Applicant] move applicant';
    constructor(public laneUuid: string, public applicantUuid: string, public reason?: string, public reasonUuid?: string) {}
}

export class RemoveApplicantLane {
    static readonly type = '[void] remove applicantLane';
    constructor(public laneUuid: string) {}
}

export class FetchApplicantReasonsOfLoss {
    static readonly type = '[ApplicantReasonOfLoss[]] fetch ApplicantReasonsOfLoss';
}

export class AddApplicantReasonOfLoss {
    static readonly type = '[ApplicantReasonOfLoss] add ApplicantReasonOfLoss';
    constructor(public reason: string) {}
}

export class UpdateApplicantReasonOfLoss {
    static readonly type = '[ApplicantReasonOfLoss] update ApplicantReasonOfLoss';
    constructor(public reasonUuid: string, public reason: string) {}
}

export class RemoveApplicantReasonOfLoss {
    static readonly type = '[void] remove applicantReasonOfLoss';
    constructor(public reasonUuid: string) {}
}
