import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-financial-home',
    templateUrl: './financial-home.component.html',
    styleUrls: ['./financial-home.component.scss'],
})
export class FinancialHomeComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
