import LogCategory from '../models/Logbook/LogCategory';
import LogItem from '../models/Logbook/LogItem';

export class AddLogCategory {
    static readonly type = '[LogCategory] Add log category';
    constructor(public logCategory: LogCategory) {}
}

export class GetAllLogCategories {
    static readonly type = '[LogCategory] Get all log categories';
}

export class DeleteLogCategory {
    static readonly type = '[LogCategory] Delete category by id';
    constructor(public categoryId: string) {}
}

export class AddLogItem {
    static readonly type = '[LogItem] Add log item';
    constructor(public logItem: LogItem) {}
}

export class GetLogItemsByObjectId {
    static readonly type = '[LogItem] Get log items by object id';
    constructor(public objectId: string) {}
}

export class DeleteLogItem {
    static readonly type = '[LogItem] Delete log item';
    constructor(public logItemId: string) {}
}

export class UpdateLogItem {
    static readonly type = '[LogItem] Update log item';
    constructor(public logItemId: string, public logItem: LogItem) {}
}
