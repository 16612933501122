import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { AuthenticationService } from '../authentication/authentication.service';
import { WarehouseLocation } from '../models/WarehouseLocation';

@Injectable({
    providedIn: 'root',
})
export class WarehouseLocationService {
    httpOptions = {};

    constructor(private httpClient: HttpClient, private authenticationService: AuthenticationService) {
        this.httpOptions = authenticationService.getHeaders();
    }

    addWarehouseLocation(warehouseLocation: WarehouseLocation): Observable<WarehouseLocation> {
        return this.httpClient.post<WarehouseLocation>(environment.baseUrl + '/warehouse-location/', warehouseLocation, this.httpOptions);
    }

    getAllWarehouseLocations() {
        return this.httpClient.get<WarehouseLocation[]>(environment.baseUrl + '/warehouse-location/', this.httpOptions);
    }

    getWarehouseLocationById(uuid: string): Observable<WarehouseLocation> {
        return this.httpClient.get<WarehouseLocation>(environment.baseUrl + '/warehouse-location/' + uuid, this.httpOptions);
    }

    updateWarehouseLocation(warehouseLocation: WarehouseLocation): Observable<WarehouseLocation> {
        return this.httpClient.put<WarehouseLocation>(environment.baseUrl + '/warehouse-location/', warehouseLocation, this.httpOptions);
    }

    archiveWarehouseLocation(uuid: string) {
        return this.httpClient.put(environment.baseUrl + '/warehouse-location/' + uuid, {}, this.httpOptions);
    }
}
