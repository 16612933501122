import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { ResumeVariant } from 'src/app/models/resume/ResumeVariant';

import { AddResumeVariant, UpdateResumeVariant } from '../resume.actions';

@Component({
    selector: 'app-resume-variant-detail-modal',
    templateUrl: './resume-variant-detail-modal.component.html',
    styleUrls: ['./resume-variant-detail-modal.component.scss'],
})
export class ResumeVariantDetailModalComponent implements OnInit {
    private subscriptions = new Subscription();

    @Input() resumeVariant: ResumeVariant;
    @Input() resumeUuid: string;

    newResumeVariant: ResumeVariant;
    variantFormGroup: FormGroup;
    errorMessage: string;
    connectionError: string;
    present = false;
    isUpdate = false;
    loading = false;
    public inputIsWrong = false;
    public variantWentWrong = false;
    error: any = { isError: false, errorMessage: '' };

    isLoading = true;

    constructor(
        public activeModal: NgbActiveModal,
        private formBuilder: FormBuilder,
        private store: Store,
        private toastr: ToastrService,
        private logger: NGXLogger,
    ) {}

    ngOnInit(): void {
        if (this.resumeVariant) {
            this.isUpdate = true;
        }
        this.variantFormGroup = this.formBuilder.group({
            jobTitle: ['', [Validators.required, Validators.min(2), Validators.max(30)]],
        });

        if (this.isUpdate) {
            this.setFormValues();
        }
    }

    setFormValues() {
        this.variantFormGroup.setValue({
            jobTitle: this.resumeVariant.jobTitle,
        });
    }

    saveVariant() {
        this.loading = true;

        if (!this.variantFormGroup.valid) {
            this.inputIsWrong = true;
            this.loading = false;
            return;
        }

        this.variantFormGroup.disable();

        this.newResumeVariant = this.variantFormGroup.value;

        if (!this.isUpdate) {
            this.logger.debug('Attempt to add Resume variant');
            this.subscriptions.add(
                this.store.dispatch(new AddResumeVariant(this.resumeUuid, this.newResumeVariant)).subscribe({
                    next: (state) => {
                        this.logger.debug('Succesfully added variant');

                        this.toastr.success('Variant added succesfully');
                        this.activeModal.close();
                    },
                    error: (error) => {
                        this.logger.error('Failed to add variant');
                        this.errorMessage = error?.error?.message ?? 'Error message was empty';

                        this.loading = false;
                        this.variantFormGroup.enable();

                        if (!this.errorMessage) {
                            this.connectionError = 'No connection to the server';
                        }

                        this.variantWentWrong = true;
                    },
                }),
            );
        } else {
            this.logger.debug('Attempt to update Resume variant');
            this.subscriptions.add(
                this.store.dispatch(new UpdateResumeVariant(this.resumeVariant.uuid, this.newResumeVariant)).subscribe({
                    next: () => {
                        this.logger.debug('Succesfully updated variant');

                        this.activeModal.close();
                    },
                    error: (error) => {
                        this.logger.error('Failed to update variant');
                        this.errorMessage = error?.error?.message ?? 'Error message was empty';

                        this.loading = false;
                        this.variantFormGroup.enable();

                        if (!this.errorMessage) {
                            this.connectionError = 'No connection to the server';
                        }

                        this.variantWentWrong = true;
                    },
                }),
            );
        }
    }

    hideErrorMessage() {
        this.inputIsWrong = false;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
