export const environment = {
    production: true,
    baseUrl: location.origin + '/api',
    vatAbstractApiUrl: 'https://vat.abstractapi.com/v1',
    ceAbstractApiUrl: 'https://companyenrichment.abstractapi.com/v1',
    GOOGLE_CLIENT_ID: '864140725628-42a0t2krvc9u4blb5qtgafrembm70ke7',
    VAT_API_KEY: 'f4426bcabe93474ba3e47b7e7015fb50',
    COMPANY_ENRICHMENT_API_KEY: '4a5c950ae34e48689122ac22d2bb3541',
    frontEndBaseUrl: '/#',
};
