<header class="modal-header">
    <h2 class="modal-title">
        Edit technical skills
    </h2>
    <button
        (click)="activeModal.dismiss()"
        aria-label="Close"
        class="close"
        type="button">
        <span aria-hidden="true">&times;</span>
    </button>
</header>

<main class="modal-body">
    <app-global-tags [isEditing]="true" [objectUuid]="resumeId" [type]="'resume'"></app-global-tags>
</main>

<footer class="modal-footer">
    <button (click)="activeModal.dismiss()" class="button button--outline">Close</button>
</footer>
