<form
    (ngSubmit)="addTimeslot()"
    [formGroup]="timeslotFormgroup"
    class="modal-wrapper">
    <div class="modal-header">
        <h2
            *ngIf="timeslot === undefined"
            class="modal-title">
            Add new timeslot
        </h2>
        <h2
            *ngIf="timeslot !== undefined"
            class="modal-title">
            Edit timeslot
        </h2>
        <button
            (click)="activeModal.dismiss()"
            aria-label="Close"
            class="close"
            type="button">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <app-loading-message *ngIf="isLoading"></app-loading-message>

    <div
        *ngIf="!isLoading && (allProjects$ | async).length > 0"
        class="modal-body">
        <div class="row">
            <div class="col">
                <app-global-autocomplete
                    [canAdd]="loggedIn"
                    [dataLoaded]="!isLoading"
                    [data]="projects"
                    [formControlName]="'project'"
                    [itemType]="'projects'"
                    [keyword]="'title'"
                    [label]="'Project'"
                    [notFoundMessage]="'Add a project'"
                    [parentForm]="timeslotFormgroup"
                    [placeholder]="'Choose project'"
                    [required]="false"></app-global-autocomplete>
            </div>
            <label>Visible projects to choose from:</label>
            <div class="d-flex">

                <label class="radio-label radio-label--text" for="allProjects" >
                    <input (change)="toggleShowAllProjects()" [value]="true" class="radio-btn" formControlName="showAllActivated" id="allProjects"
                           name="showAllActivated" type="radio">
                    <div class="radio-label__content">All projects</div>
                </label>
                <label  class="radio-label radio-label--text" for="onlyLinkedProjects">
                    <input (change)="toggleShowAllProjects()" [value]="false" class="radio-btn" formControlName="showAllActivated" id="onlyLinkedProjects"
                           name="showAllActivated" type="radio">
                    <div class="radio-label__content">Only linked projects</div>
                </label>
            </div>
           <!-- <a
                (click)="toggleShowAllProjects()"
                *ngIf="!this.showAllActivated"
            >show all projects</a
            >
            <a
                (click)="toggleShowAllProjects()"
                *ngIf="this.showAllActivated"
            >show only linked projects</a
            >-->
        </div>
        <div class="divider divider--small"></div>
        <div class="form-group m-b-2">
            <textarea
                (keyup)="onDescriptionKeyUp()"
                class="resize-vertical"
                formControlName="description"
                placeholder="Description"
                trim="blur"></textarea>
            <small
            ><span
                *ngIf="currentAmountOfDescriptionCharacters === null">0</span>{{ currentAmountOfDescriptionCharacters }}
                /{{
                DESCRIPTION_MAX_LENGTH
                }}</small
            >
            <span
                *ngIf="descriptionIsTooLong"
                class="form-error"
            >Description cannot contain more than 250 characters</span
            >
        </div>
        <div class="row">
            <div class="col">
                <div class="form-group m-b-2">
                    <label for="hours">Hours</label>
                    <input
                        formControlName="hours"
                        id="hours"
                        type="number"/>
                </div>
            </div>
            <div class="col">
                <div class="form-group m-b-2">
                    <label for="minutes">Minutes</label>
                    <input
                        formControlName="minutes"
                        id="minutes"
                        type="number"/>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <label>Attachment</label>
                <span class="form-helptext">Supported file types: .pdf</span>
                <span *ngIf="timeslot">
                    <app-file-upload
                        *ngIf="timeslot.pdfUrl"
                        [existingFileUrl]="timeslot.pdfUrl"
                        formControlName="pdfUrl"
                        type="file"></app-file-upload>
                    <app-file-upload
                        *ngIf="timeslot.pdfUrl === ''"
                        formControlName="pdfUrl"
                        type="file"></app-file-upload>
                </span>
                <app-file-upload
                    *ngIf="!timeslot"
                    formControlName="pdfUrl"
                    type="file"></app-file-upload>
            </div>
        </div>
    </div>

    <div
        *ngIf="!isLoading && linkedProjects.length <= 0"
        class="modal-body">
        <p>You don't seem to be linked to any projects yet. If you think this is a mistake, please contact your
            administrator.</p>
    </div>

    <div class="modal-footer">
        <input
            [disabled]="!timeslotFormgroup.valid"
            [ngClass]="!timeslotFormgroup.valid ? 'button button--disabled w-100' : 'button button--primary w-100'"
            type="submit"
            value="Save"/>
    </div>
</form>
