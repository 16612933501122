import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import * as moment from 'moment';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { CompanyState } from 'src/app/companies/company.state';
import { FetchContacts } from 'src/app/contacts/contacts.actions';
import { ContactState } from 'src/app/contacts/contacts.state';
import { FetchCustomers } from 'src/app/customers/customers.actions';
import { CustomerState } from 'src/app/customers/customers.state';
import { AssignedResume } from 'src/app/models/AssignedResume';
import { ContactPerson } from 'src/app/models/ContactPerson';
import Customer from 'src/app/models/Customer';
import { GlobalTag } from 'src/app/models/GlobalTag';
import { JiraProjectCredential } from 'src/app/models/JiraProjectCredential';
import { ModuleEnum } from 'src/app/models/ModuleEnum';
import { Partner } from 'src/app/models/Partner';
import { Product } from 'src/app/models/Product';
import Project from 'src/app/models/Project';
import { Resume } from 'src/app/models/resume/Resume';
import { PartnerState } from 'src/app/partners/partners.state';
import { FetchResumes } from 'src/app/resumes/resume.actions';
import { ResumeState } from 'src/app/resumes/resume.state';
import { ModuleService } from 'src/app/services/module.service';
import { CountryService } from 'src/app/shared/country.service';
import { JiraLoginService } from 'src/app/shared/login-components/jira-login/jira-login.service';

import { FetchPartners, GetPartner } from '../../partners/partners.actions';
import { ProjectAddProductModalComponent } from '../project-add-product-modal/project-add-product-modal.component';
import { ProjectDeleteProductComponent } from '../project-delete-product/project-delete-product.component';
import { AddProject, AssignResumeToProject, DeleteAssignedResume, FetchProjects, UpdateProject } from '../project.actions';
import { ProjectService } from '../project.service';
import { ProjectState } from '../project.state';

@Component({
    selector: 'app-project-add-modal',
    templateUrl: './project-detail-modal.component.html',
    styleUrls: ['./project-detail-modal.component.scss'],
})
export class ProjectDetailModalComponent implements OnInit, OnDestroy {
    public emitObject: EventEmitter<Project> = new EventEmitter();
    @Input() project: Project;
    @Input() inOverview = false;
    @Input() partnerUuidInput: string;
    @Input() fromDeal: boolean;
    @Select(PartnerState.getPartners) partners$: Observable<Partner[]>;
    @Select(CompanyState.companyUuid) companyUuid$: Observable<string>;
    @Select(CustomerState.Customers) customers$: Observable<Customer[]>;
    @Select(ContactState.Contacts) contacts$: Observable<ContactPerson[]>;
    @Select(ResumeState.resumes) companyResumes$: Observable<Resume[]>;
    @Select(ProjectState.project) project$: Observable<Project>;
    allPartners: Partner[];
    allCustomers: Customer[];
    allContacts: ContactPerson[];
    newProject: Project;
    projectFormGroup: FormGroup;
    errorMessage: string;
    connectionError: string;
    present = false;
    billable = false;
    isUpdate = false;
    loading = false;
    warranty = false;
    isHiddenControl = true;
    maintenanceContract = false;
    public inputIsWrong = false;
    public projectWentWrong = false;
    isValidDate = false;
    isValidWarrantyDate = false;
    error: any = { isError: false, errorMessage: '' };
    tags: GlobalTag[] = [];
    DESCRIPTION_MAX_LENGTH = 1000;
    currentAmountOfDescriptionCharacters: number;
    descriptionIsTooLong = false;
    companyUuid: string;
    allCountries = [];
    selectedResumes: Resume[] = [];
    assignedResumes: AssignedResume[];
    resumesToRemove: AssignedResume[] = [];
    checkModule: any;
    isLoaded: boolean;
    companyResumes: Resume[];
    partner: Partner;
    isFinished: boolean;
    currentPartner: Partner;
    currentCustomer: Customer;
    currentContact: ContactPerson;
    eModule = ModuleEnum;
    jiraProjectKeyList$: JiraProjectCredential[] = [];
    jiraProjectKeysLoaded = false;
    // Used on edit when clicked on edit in overview
    projectFullyLoaded = true;
    keyword = 'firstName';
    partnerKeyword = 'title';
    private subscriptions = new Subscription();

    constructor(
        public activeModal: NgbActiveModal,
        private modalService: NgbModal,
        public moduleService: ModuleService,
        private countryService: CountryService,
        private toastr: ToastrService,
        private store: Store,
        private formBuilder: FormBuilder,
        private logger: NGXLogger,
        private projectService: ProjectService,
        private cdRef: ChangeDetectorRef,
        private jiraLoginService: JiraLoginService,
    ) {
        this.checkModule = this.moduleService.checkModuleActive(this.eModule.RESUME);
    }

    @ViewChild('focussed', { static: false })
    set input(element: ElementRef<HTMLInputElement>) {
        if (element) {
            element.nativeElement.focus();
        }
    }

    ngOnInit(): void {
        // eslint-disable-next-line @typescript-eslint/ban-types
        this.jiraLoginService.getJiraProjectKeys().subscribe((data: Object[]) => {
            for (const obj of data) {
                const name = obj['projectName'] ? obj['projectName'] : '';
                const key = obj['projectKey'] ? obj['projectKey'] : '';
                const jiraProjectCredential = new JiraProjectCredential(name, key);
                this.jiraProjectKeyList$.push(jiraProjectCredential);
            }
            this.jiraProjectKeysLoaded = true;
        });

        // this.jiraLoginService.getJiraProjectKeys().subscribe((projectKeys) => {
        //     this. jiraProjectKeys$ = projectKeys as string[];
        //     this.jiraProjectKeysLoaded = true;
        // });

        this.createProjectForm();
        this.fetchData();

        if (this.project) {
            this.tags = this.project.globalTags;
            if (this.project.warranty) {
                this.warranty = true;
            }
            if (this.project.maintenanceContract) {
                this.maintenanceContract = true;
            }
        }

        if (this.partnerUuidInput) {
            this.logger.debug('Fetching partner');
            this.subscriptions.add(
                this.store.dispatch(new GetPartner(this.partnerUuidInput)).subscribe((state) => {
                    this.currentPartner = state.partner.partners.find((partner) => partner.uuid == this.partnerUuidInput);
                }),
            );
        }

        if (this.project && !this.fromDeal) {
            this.isUpdate = true;
        }

        this.allCountries = this.countryService.getAllCountries();
        this.isFinished = this.project?.finished ? this.project.finished : false;
    }

    setFormValues() {
        if (!this.projectFullyLoaded) return;
        if (this.project.present) {
            this.present = this.project?.present;
            this.isValidDate = true;

            this.projectFormGroup.patchValue({
                jiraProjectKey: this.project.jiraProjectKey ? this.project.jiraProjectKey : '',
                startDate: moment(this.project.startDate).format('YYYY-MM-DD'),
                endDate: moment(Date.now()).format('YYYY-MM-DD'),
                present: this.project.present,
                billable: this.project.billable,
                warranty: this.project.warranty,
                title: this.project.title,
                projectType: this.project.projectType ? this.getType(this.project.projectType.toString()) : this.getType(''),
                description: this.project.description,
                location: this.project?.location,
                pdfUpload: this.project.pdfUpload,
                partner: this.partner ? this.partner : '',
                customer: this.project.customerUuid ? this.currentCustomer : '',
                contact: this.project.contactPersonUuid ? this.currentContact : '',
                budget: this.project.budget,
                budgetInManDays: this.project.budgetInManDays,
                warrantyStartDate: this.project.warrantyStartDate
                    ? moment(this.project.warrantyStartDate).format('YYYY-MM-DD')
                    : moment(Date.now()).format('YYYY-MM-DD'),
                warrantyEndDate: this.project.warrantyEndDate
                    ? moment(this.project.warrantyEndDate).format('YYYY-MM-DD')
                    : moment().add(4, 'months').format('YYYY-MM-DD'),
                maintenanceContract: this.project.maintenanceContract,
            });
            this.projectFormGroup.controls.endDate.disable();
        } else {
            this.projectFormGroup.patchValue({
                jiraProjectKey: this.project.jiraProjectKey ? this.project.jiraProjectKey : '',
                startDate: moment(this.project.startDate).format('YYYY-MM-DD'),
                endDate: this.project.endDate ? moment(this.project.endDate).format('YYYY-MM-DD') : null,
                present: this.project.present,
                warranty: this.project.warranty,
                billable: this.project.billable,
                title: this.project.title,
                description: this.project.description,
                location: this.project?.location,
                pdfUpload: this.project.pdfUpload,
                partner: this.partner ? this.partner : '',
                customer: this.project.customerUuid ? this.currentCustomer : '',
                contact: this.project.contactPersonUuid ? this.currentContact : '',
                budget: this.project.budget,
                budgetInManDays: this.project.budgetInManDays,
                projectType: this.project.projectType ? this.getType(this.project.projectType.toString()) : this.getType(''),
                warrantyStartDate: this.project.warrantyStartDate
                    ? moment(this.project.warrantyStartDate).format('YYYY-MM-DD')
                    : moment(Date.now()).format('YYYY-MM-DD'),
                warrantyEndDate: this.project.warrantyEndDate
                    ? moment(this.project.warrantyEndDate).format('YYYY-MM-DD')
                    : moment().add(4, 'months').format('YYYY-MM-DD'),
                maintenanceContract: this.project.maintenanceContract,
            });
        }
    }

    onPresentTicked() {
        this.present = this.projectFormGroup.controls.present.value;
        if (this.present) {
            this.isValidDate = true;
            this.projectFormGroup.controls.endDate.disable();
        } else {
            this.isValidDate = false;
            this.projectFormGroup.controls.endDate.enable();
        }
    }

    onWarrantyTicked() {
        if (this.warranty) {
            this.projectFormGroup.controls.warrantyStartDate.disable();
            this.projectFormGroup.controls.warrantyEndDate.disable();
        } else {
            this.projectFormGroup.controls.warrantyStartDate.enable();
            this.projectFormGroup.controls.warrantyEndDate.enable();
        }
        this.warranty = !this.warranty;
        this.isHiddenControl = !this.isHiddenControl;
        this.cdRef.detectChanges(); // Trigger change detection
    }

    onMaintenanceTicked() {
        this.maintenanceContract = !this.maintenanceContract;
    }

    onDescriptionKeyUp() {
        this.descriptionIsTooLong = false;
        this.currentAmountOfDescriptionCharacters = this.projectFormGroup.controls.description.value.length;
        if (this.currentAmountOfDescriptionCharacters > this.DESCRIPTION_MAX_LENGTH) {
            this.descriptionIsTooLong = true;
        }
    }

    saveProject() {
        this.loading = true;

        if (!this.present) {
            this.isValidDate = this.validateDates(this.projectFormGroup.value.startDate, this.projectFormGroup.value.endDate);
        }

        if (this.warranty) {
            this.isValidWarrantyDate = this.validateWarrantyDates(this.projectFormGroup.value.warrantyStartDate, this.projectFormGroup.value.warrantyEndDate);
        }

        if (!this.warranty) {
            this.isValidWarrantyDate = true;
        }

        if (this.descriptionIsTooLong) {
            this.loading = false;
            return;
        }

        if (!this.projectFormGroup.valid || !this.isValidDate || !this.isValidWarrantyDate) {
            this.inputIsWrong = true;
            this.loading = false;
            return;
        }

        this.projectFormGroup.disable();

        this.newProject = this.projectFormGroup.value;
        this.newProject.companyUuid = this.companyUuid;

        if (!this.newProject.warranty) {
            this.newProject.warrantyStartDate = null;
            this.newProject.warrantyEndDate = null;
        }

        if (this.fromDeal) {
            this.newProject.products = this.project.products;
        }

        if (this.projectFormGroup.value.partner.uuid) {
            this.newProject.partnerUuid = this.projectFormGroup.value.partner.uuid;
        }

        if (this.projectFormGroup.value.customer) {
            this.newProject.customerUuid = this.projectFormGroup.value.customer.uuid;
            this.newProject.customerFirstname = this.projectFormGroup.value.customer.firstName;
            this.newProject.customerLastname = this.projectFormGroup.value.customer.lastName;
        }

        if (this.projectFormGroup.value.contact) {
            this.newProject.contactPersonUuid = this.projectFormGroup.value.contact.uuid;
            this.newProject.contactPersonFirstname = this.projectFormGroup.value.contact.firstName;
            this.newProject.contactPersonLastname = this.projectFormGroup.value.contact.lastName;
        }

        if (!this.isUpdate) {
            this.logger.debug('Attempt to add project');
            this.subscriptions.add(
                this.store.dispatch(new AddProject(this.newProject)).subscribe({
                    next: (state) => {
                        this.emitObject.emit(state.project.projects.find((w) => this.newProject.title === w.title));
                        if (this.moduleService.checkModuleActive(this.eModule.RESUME)) {
                            this.logger.debug('Attempt to assign resume to project');
                            this.store.dispatch(new AssignResumeToProject(state.project.currentProject.uuid, this.selectedResumes)).subscribe(
                                () => {
                                    this.logger.debug('Succesfully assigned resume to project');
                                },
                                () => {
                                    this.logger.error('Failed to assign resume to project');
                                },
                            );
                        }
                        this.store.dispatch(new FetchProjects());
                        this.logger.debug('Succesfully added project');
                        this.toastr.success('project added succesfully');
                        this.activeModal.close();
                        this.tags = [];
                    },
                    error: (error) => {
                        this.logger.error('Failed to add project');
                        this.errorMessage = error?.error?.message ?? 'Error message was empty';

                        this.loading = false;
                        this.projectFormGroup.enable();

                        if (!this.errorMessage) {
                            this.connectionError = 'No connection to the server';
                        }

                        this.projectWentWrong = true;
                    },
                }),
            );
        } else {
            this.newProject.finished = this.isFinished;
            this.logger.debug('Attempt to update project');
            this.subscriptions.add(
                this.store.dispatch(new UpdateProject(this.project.uuid, this.newProject)).subscribe({
                    next: () => {
                        if (this.moduleService.checkModuleActive(this.eModule.RESUME)) {
                            this.resumesToRemove.forEach((element) => {
                                this.logger.debug('Attempt to delete assigned resume');
                                this.store.dispatch(new DeleteAssignedResume(element.projectResumeAssignmentUuid)).subscribe(
                                    () => {
                                        this.logger.debug('Succesfully deleted assigned resume');
                                    },
                                    () => {
                                        this.logger.debug('Failed to delete assigned resume');
                                    },
                                );
                            });

                            this.logger.debug('Attempt to assign resume to project');
                            this.store.dispatch(new AssignResumeToProject(this.project?.uuid, this.selectedResumes)).subscribe(
                                () => {
                                    this.logger.debug('Succesfully assigned resume to project');
                                },
                                () => {
                                    this.logger.error('Failed to assign resume to project');
                                },
                            );
                        }

                        this.logger.debug('Fetching project');
                        this.store.dispatch(new FetchProjects());

                        this.activeModal.close();
                    },
                    error: (error) => {
                        this.logger.error('Failed to update project');
                        this.errorMessage = error?.error?.message ?? 'Error message was empty';

                        this.loading = false;
                        this.projectFormGroup.enable();

                        if (!this.errorMessage) {
                            this.connectionError = 'No connection to the server';
                        }

                        this.projectWentWrong = true;
                    },
                }),
            );
        }
    }

    hideErrorMessage() {
        this.inputIsWrong = false;
    }

    validateDates(sDate: Date, eDate: Date) {
        this.isValidDate = true;
        if (sDate == null || eDate == null) {
            this.error = { isError: true, errorMessage: 'Start date and end date are required.' };
            this.isValidDate = false;
        }

        if (sDate != null && eDate != null && eDate < sDate) {
            this.error = { isError: true, errorMessage: 'End date should be greater then start date.' };
            this.isValidDate = false;
        }

        return this.isValidDate;
    }

    validateWarrantyDates(warrantyStartDate: Date, warrantyEndDate: Date) {
        if (warrantyStartDate == null || warrantyEndDate == null) {
            this.error = { isError: true, errorMessage: 'Start and end date are required.' };
        }

        if (warrantyStartDate != null && warrantyEndDate != null && warrantyEndDate < warrantyStartDate) {
            this.error = { isError: true, errorMessage: 'End date should be greater then start date.' };
        }

        if (warrantyStartDate != null && warrantyEndDate != null && warrantyStartDate < warrantyEndDate) {
            this.isValidWarrantyDate = true;
        }

        return this.isValidWarrantyDate;
    }

    selectEvent(item) {
        this.selectedResumes.push(item);
        this.companyResumes.splice(this.companyResumes.indexOf(item), 1);
        this.companyResumes = [...this.companyResumes];
    }

    addToRemoveArray(res: AssignedResume) {
        if (!this.resumesToRemove.includes(res)) {
            this.resumesToRemove.push(res);
        }
        if (this.assignedResumes.includes(res)) {
            this.assignedResumes.splice(this.assignedResumes.indexOf(res), 1);
        }
    }

    removeFromSelectedArray(res: Resume) {
        this.companyResumes.push(res);
        this.companyResumes = [...this.companyResumes];
        this.selectedResumes.splice(this.selectedResumes.indexOf(res), 1);
    }

    checkResumeModule() {
        return this.moduleService.checkModuleActive(this.eModule.RESUME);
    }

    toggleFinished() {
        this.isFinished = !this.isFinished;
    }

    openProjectAddProductModal(project: Project, product?: Product) {
        this.logger.debug('Opening projectAddProductModal');
        const modalRef = this.modalService.open(ProjectAddProductModalComponent, {
            windowClass: 'modal-prompt',
            animation: false,
        });
        modalRef.componentInstance.project = project;
        modalRef.componentInstance.product = product;
    }

    openProjectProductDeleteModal(project: Project, product: Product) {
        this.logger.debug('Opening projectDeleteProductModal');
        const modalRef = this.modalService.open(ProjectDeleteProductComponent, {
            windowClass: 'modal-prompt',
            animation: false,
        });
        modalRef.componentInstance.product = product;
        modalRef.componentInstance.project = project;
    }

    getRelevantContacts() {
        const partner = this.projectFormGroup.get('partner').value;
        if (this.allContacts) {
            return partner ? this.allContacts.filter((c) => c.partnerUuid === partner.uuid) : this.allContacts;
        } else {
            return partner;
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    private createProjectForm() {
        const uuid = this.isUpdate ? this.project.uuid : '0';

        this.projectFormGroup = this.formBuilder.group({
            title: ['', [Validators.required]],
            projectType: ['', [Validators.required]],
            jiraProjectKey: this.jiraProjectKeyList$,
            description: [''],
            pdfUpload: null,
            billable: true,
            present: false,
            startDate: ['', [Validators.required]],
            endDate: ['', [Validators.required]],
            warranty: false,
            warrantyStartDate: ['', [Validators.required]],
            warrantyEndDate: ['', [Validators.required]],
            maintenanceContract: false,
            partner: this.currentPartner ? this.currentPartner : '',
            customer: [''],
            contact: [''],
            location: this.formBuilder.group({
                street: [''],
                number: [null],
                bus: [''],
                ext: [null],
                postal: [''],
                city: [''],
                country: this.formBuilder.group({
                    iso2: [''],
                }),
            }),
            budget: [null],
            budgetInManDays: [null],
        });
        this.projectFormGroup.controls.warrantyStartDate.disable();
        this.projectFormGroup.controls.warrantyEndDate.disable();
    }

    private fetchData() {
        if (this.isUpdate) {
            this.projectFullyLoaded = false;
            this.warranty = this.project.warranty;
            this.maintenanceContract = this.project.maintenanceContract;

            if (this.warranty) {
                this.isHiddenControl = false;
                this.projectFormGroup.controls.warrantyStartDate.enable();
                this.projectFormGroup.controls.warrantyEndDate.enable();
            }

            this.setFormValues();
            this.projectFullyLoaded = true;

            //ToDo: Check if this code can be deleted permanently!!!
            // this.subscriptions.add(
            //     this.project$.subscribe((project) => {
            //         if (!project || project.uuid !== this.project.uuid) {
            //             this.store.dispatch(new FetchProjectById(this.project.uuid));
            //             return;
            //         }

            //         this.projectFullyLoaded = true;
            //         if (!this.fromDeal) this.project = project;
            //         this.setFormValues();
            //     }),
            // );
        }

        if (!this.isUpdate) {
            this.projectFormGroup.patchValue({
                warrantyStartDate: moment(Date.now()).format('YYYY-MM-DD'),
                warrantyEndDate: moment().add(4, 'months').format('YYYY-MM-DD'),
            });
        }

        this.subscriptions.add(this.companyResumes$.subscribe((resumes) => (this.companyResumes = resumes)));

        this.subscriptions.add(
            this.customers$.subscribe((customers) => {
                if (!customers) {
                    this.logger.debug('Fetching customers');
                    this.store.dispatch(new FetchCustomers());
                } else {
                    if (this.project && this.project.customerUuid) {
                        this.currentCustomer = JSON.parse(JSON.stringify(customers.find((c) => c.uuid == this.project.customerUuid)));
                    }
                    this.allCustomers = customers;
                }
            }),
        );

        this.subscriptions.add(
            this.contacts$.subscribe((contacts) => {
                if (!contacts) {
                    this.logger.debug('Fetching contacts');
                    this.store.dispatch(new FetchContacts());
                } else {
                    if (this.project && this.project.contactPersonUuid) {
                        this.currentContact = JSON.parse(JSON.stringify(contacts.find((c) => c.uuid === this.project.contactPersonUuid)));
                    }
                    this.allContacts = contacts;
                }
            }),
        );

        this.subscriptions.add(
            this.partners$.subscribe((partners) => {
                if (!partners) {
                    this.logger.debug('Fetching partners');
                    this.store.dispatch(new FetchPartners());
                } else {
                    this.allPartners = partners;
                    if (this.project?.partnerUuid) this.partner = partners.find((partner) => partner.uuid === this.project.partnerUuid);
                    if (this.isUpdate || this.fromDeal) this.setFormValues();
                    this.isLoaded = true;
                }
            }),
        );

        this.subscriptions.add(
            this.companyUuid$.subscribe((uuid) => {
                this.companyUuid = uuid;
                this.logger.debug('Fetching resumes');
                this.store.dispatch(new FetchResumes());
            }),
        );
    }

    private getType(type: string): number {
        switch (type) {
            case 'CUSTOM':
                return 0;
            case 'WEBSITE':
                return 1;
            case 'CONSULTANCY':
                return 2;
            default:
                return 3;
        }
    }
}
