<div class="d-flex nowrap">
    <app-searchbar
        class="w-100"
        [(value)]="filter"
        (valueChange)="applyFilter($event)"
        [hidden]="this.partner.amountOfContactPersons == 0"></app-searchbar>
    <span *ngIf="!this.noPartnerContacts">
        <span
            class="button button--primary m-l-2"
            *appIsPermitted="{
                module: 'CONTACTS',
                operator: 'AND',
                actions: ['CREATE']
            }"
            (click)="openAddContactPersonModal()"
            >Add contact</span
        >
    </span>
</div>

<div class="m-t-4">
    <table
        mat-table
        [dataSource]="dataSource"
        matSort
        [hidden]="this.partner.amountOfContactPersons == 0">
        <ng-container matColumnDef="firstName">
            <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header>
                Contactperson
            </th>
            <td
                mat-cell
                *matCellDef="let row">
                <app-pill-contact [contactPerson]="row"></app-pill-contact>
            </td>
        </ng-container>

        <ng-container matColumnDef="contactInformation.phoneNumber">
            <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header>
                Phone
            </th>
            <td
                mat-cell
                *matCellDef="let row">
                <app-pill-telephone
                    *ngIf="row.contactInformation.phoneNumber"
                    [contactPhonenumber]="row.contactInformation.phoneNumber"></app-pill-telephone>
            </td>
        </ng-container>

        <ng-container matColumnDef="contactInformation.email">
            <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header>
                Email
            </th>
            <td
                mat-cell
                *matCellDef="let row">
                <app-pill-mailto [mailtoEmail]="row.contactInformation.email"></app-pill-mailto>
            </td>
        </ng-container>

        <ng-container matColumnDef="edit">
            <th
                mat-header-cell
                *matHeaderCellDef
                class="td-small"></th>
            <td
                mat-cell
                *matCellDef="let row">
                <span ngbDropdown>
                    <span
                        ngbDropdown
                        class="dropdown"
                        *appIsPermitted="{
                            module: 'CONTACTS',
                            operator: 'OR',
                            actions: ['EDIT', 'DELETE']
                        }">
                        <button
                            type="button"
                            ngbDropdownToggle
                            class="button button--icon button--small dropdown-toggle"
                            id="dropdownMenuButton"></button>
                        <span
                            ngbDropdownMenu
                            class="dropdown-menu dropdown-menu-right"
                            aria-labelledby="dropdownMenuButton">
                            <a
                                class="dropdown-item"
                                *appIsPermitted="{
                                    module: 'CONTACTS',
                                    operator: 'AND',
                                    actions: ['EDIT']
                                }"
                                (click)="openUpdateContactPersonModal(row)"
                                >Edit</a
                            >
                            <div
                                class="dropdown-divider"
                                *appIsPermitted="{
                                    module: 'CONTACTS',
                                    operator: 'AND',
                                    actions: ['EDIT', 'DELETE']
                                }"></div>
                            <a
                                class="dropdown-item color--red"
                                *appIsPermitted="{
                                    module: 'CONTACTS',
                                    operator: 'AND',
                                    actions: ['DELETE']
                                }"
                                (click)="openDeleteContactPersonModal(row)"
                                >Delete</a
                            >
                        </span>
                    </span>
                </span>
            </td>
        </ng-container>

        <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns"></tr>
        <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns"></tr>

        <tr *matNoDataRow>
            <td colspan="6">No contacts matching the filter "{{ filter }}"</td>
        </tr>
    </table>

    <div *ngIf="this.isLoading">
        <app-loading-message></app-loading-message>
    </div>

    <div *ngIf="this.noPartnerContacts && !this.isLoading">
        <app-message-empty
            [title]="'No contactpersons...'"
            [subtitle]="'Contactpersons are people you can contact within companies.'"
            [icon]="'../../assets/img/open-box.png'">
            <a
                class="button button--primary"
                *appIsPermitted="{
                    module: 'CONTACTS',
                    operator: 'AND',
                    actions: ['CREATE']
                }"
                (click)="openAddContactPersonModal()"
                >Add a contactperson</a
            >
        </app-message-empty>
    </div>
</div>
