<div *ngIf="employees$ | async">
    <div class="d-flex align-items-center">
        <div class="d-flex nowrap">
            <span
                class="dropdown m-r-2"
                ngbDropdown>
                <button
                    class="button button--select"
                    ngbDropdownToggle>
                    Selected ({{ getAmount() }})
                </button>
                <span
                    class="dropdown-menu-right"
                    ngbDropdownMenu>
                    <div
                        (click)="addToMailchimp()"
                        *appIsPermitted="{
                            module: 'MAILCHIMP',
                            operator: 'AND',
                            actions: ['CREATE']
                        }"
                        ngbDropdownItem>
                        Add to Mailchimp
                    </div>
                    <div
                        (click)="deleteEmployees()"
                        *appIsPermitted="{
                            module: 'EMPLOYEES',
                            operator: 'AND',
                            actions: ['DELETE']
                        }"
                        class="color--red"
                        ngbDropdownItem>
                        Delete
                    </div>
                </span>
            </span>
        </div>
        <div class="w-100">
            <app-searchbar
                (valueChange)="applyFilter($event)"
                [(value)]="filter"></app-searchbar>
        </div>
        <div class="d-flex nowrap">
            <app-global-export-csv
                [componentName]="this.constructor.name"
                [exporter]="exporter"></app-global-export-csv>
        </div>
    </div>
    <div class="m-t-4">
        <div class="table__overflow">
            <table
                #exporter="matTableExporter"
                [dataSource]="dataSource"
                mat-table
                matSort
                matSortActive="firstName"
                matSortDirection="asc"
                matTableExporter>
                <ng-container matColumnDef="checkBox">
                    <th
                        *matHeaderCellDef
                        class="td-small"
                        mat-header-cell>
                        <input
                            (change)="checkAll()"
                            [checked]="allCheck"
                            type="checkbox" />
                    </th>
                    <td
                        *matCellDef="let row"
                        mat-cell>
                        <input
                            (change)="checkChange(row); $event.stopPropagation()"
                            (click)="$event.stopPropagation()"
                            [checked]="getCurrentCheck(row)"
                            type="checkbox" />
                    </td>
                </ng-container>

                <ng-container matColumnDef="firstName">
                    <th
                        *matHeaderCellDef
                        mat-header-cell
                        mat-sort-header>
                        Name
                    </th>
                    <td
                        *matCellDef="let row"
                        mat-cell>
                        <app-pill-employee [employee]="row"></app-pill-employee>
                    </td>
                </ng-container>

                <ng-container matColumnDef="contactInformation.phoneNumber">
                    <th
                        *matHeaderCellDef
                        mat-header-cell
                        mat-sort-header>
                        Phone
                    </th>
                    <td
                        *matCellDef="let row"
                        mat-cell>
                        <app-pill-telephone
                            *ngIf="row.contactInformation.phoneNumber"
                            [contactPhonenumber]="row.contactInformation.phoneNumber"></app-pill-telephone>
                    </td>
                </ng-container>

                <ng-container matColumnDef="contactInformation.email">
                    <th
                        *matHeaderCellDef
                        mat-header-cell
                        mat-sort-header>
                        Email
                    </th>
                    <td
                        *matCellDef="let row"
                        mat-cell>
                        <app-pill-mailto [mailtoEmail]="row.contactInformation.email"></app-pill-mailto>
                    </td>
                </ng-container>

                <ng-container matColumnDef="profession">
                    <th
                        *matHeaderCellDef
                        mat-header-cell
                        mat-sort-header>
                        Profession
                    </th>
                    <td
                        *matCellDef="let row"
                        mat-cell>
                        {{ row.profession }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="hireDate">
                    <th
                        *matHeaderCellDef
                        mat-header-cell
                        mat-sort-header>
                        Hire date
                    </th>
                    <td
                        *matCellDef="let row"
                        mat-cell>
                        <span>{{ row.hireDate | amDateFormat: 'DD/MM/YYYY' }}</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="birthday">
                    <th
                        *matHeaderCellDef
                        mat-header-cell
                        mat-sort-header>
                        Birth date
                    </th>
                    <td
                        *matCellDef="let row"
                        mat-cell>
                        <span>{{ row.birthday | amDateFormat: 'DD/MM/YYYY' }}</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="jiraLink">
                    <th
                        *matHeaderCellDef
                        mat-header-cell
                        mat-sort-header>
                        Linked with Jira
                    </th>
                    <td
                        *matCellDef="let row"mat-cell>
                        <div *ngIf="!row.jiraEmployeeId">
                            <i class="las la-times-circle" style="color: red"></i>
                        </div>
                        <div *ngIf="row.jiraEmployeeId">
                            <i class="las la-check-circle" style="color: green"></i>
                        </div>
                </td>
                </ng-container>

                <ng-container matColumnDef="project">
                    <th
                        *matHeaderCellDef
                        mat-header-cell
                        mat-sort-header>
                        Projects
                    </th>
                    <td
                        *matCellDef="let row"
                        mat-cell>
                        <div *ngFor="let project of projects">
                            <div *ngFor="let linkedEmp of project.defaultLinkedEmployees">
                                <app-pill-project
                                    *ngIf="linkedEmp.employeeId === row.uuid"
                                    [project]="project"></app-pill-project>
                            </div>
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="edit">
                    <th
                        *matHeaderCellDef
                        class="td-small"
                        mat-header-cell></th>
                    <td
                        *matCellDef="let row"
                        class="td-icon"
                        mat-cell>
                        <span
                            *appIsPermitted="{
                                module: 'EMPLOYEES',
                                operator: 'OR',
                                actions: ['EDIT', 'DELETE']
                            }"
                            class="dropdown"
                            ngbDropdown>
                            <button
                                (click)="$event.stopPropagation()"
                                class="button button--icon button--small dropdown-toggle"
                                id="dropdownMenuButton"
                                ngbDropdownToggle
                                type="button"></button>
                            <span
                                aria-labelledby="dropdownMenuButton"
                                class="dropdown-menu dropdown-menu-right"
                                ngbDropdownMenu>
                                <a
                                    (click)="openUpdateEmployeeModal(row); $event.stopPropagation()"
                                    *appIsPermitted="{
                                        module: 'EMPLOYEES',
                                        operator: 'AND',
                                        actions: ['EDIT']
                                    }"
                                    class="dropdown-item"
                                    >Edit</a
                                >
                                <div
                                    *appIsPermitted="{
                                        module: 'EMPLOYEES',
                                        operator: 'AND',
                                        actions: ['DELETE', 'EDIT']
                                    }"
                                    class="dropdown-divider"></div>
                                <a
                                    (click)="openEmployeeArchiveModal(row); $event.stopPropagation()"
                                    *appIsPermitted="{
                                        module: 'EMPLOYEES',
                                        operator: 'AND',
                                        actions: ['DELETE']
                                    }"
                                    class="dropdown-item color--red"
                                    >
                                    <ng-container *ngIf="row.archived; else archive">Unarchive</ng-container>
                                    <ng-template #archive>Archive</ng-template>
                                </a
                                >
                                <a
                                    (click)="openEmployeeDeleteModal(row); $event.stopPropagation()"
                                    *appIsPermitted="{
                                        module: 'EMPLOYEES',
                                        operator: 'AND',
                                        actions: ['DELETE']
                                    }"
                                    class="dropdown-item color--red"
                                    >Delete</a
                                >
                            </span>
                        </span>
                    </td>
                </ng-container>

                <tr
                    *matHeaderRowDef="displayedColumns"
                    mat-header-row></tr>
                <tr
                    (click)="openEmployeeDetailModal(row)"
                    *matRowDef="let row; columns: displayedColumns"
                    class="pointer"
                    mat-row></tr>

                <tr *matNoDataRow>
                    <td colspan="8">No employees found...</td>
                </tr>
            </table>
        </div>
    </div>
</div>
