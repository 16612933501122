import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-pill-whatsapp',
    templateUrl: './pill-whatsapp.component.html',
    styleUrls: ['./pill-whatsapp.component.scss'],
})
export class PillWhatsappComponent implements OnInit {
    @Input() phoneNumber: string;
    link: string;
    validPhoneNumber = false;

    ngOnInit(): void {
        this.validPhoneNumber = this.phoneNumber.startsWith('+');
        this.link = `https://wa.me/${this.phoneNumber}`;
    }
}
