import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { Module } from 'src/app/models/Module';

import { YukiCredential } from '../../../models/yuki/YukiCredential';
import { FetchCredentials, SetCredentials } from '../../../yuki/yuki.actions';
import { YukiState } from '../../../yuki/yuki.state';
import { YukiLoginService } from './yuki-login.service';

@Component({
    selector: 'app-yuki-login',
    templateUrl: './yuki-login.component.html',
    styleUrls: ['./yuki-login.component.scss'],
})
export class YukiLoginComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Select(YukiState.yukiCredential)
    yukiCredential$: Observable<YukiCredential>;

    @Input() module: Module;
    key: string;
    form: FormGroup;
    isSyncing = false;

    constructor(
        private fb: FormBuilder,
        private yukiLoginService: YukiLoginService,
        private toastr: ToastrService,
        private logger: NGXLogger,
        private store: Store,
    ) {}

    ngOnInit(): void {
        this.createCredentialForm();
        this.fetchData();
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    onSubmit(form: FormGroup) {
        this.logger.debug('Attempt to save yuki api key');
        const yukiCredentials: YukiCredential = {
            key: form.get('apiKey').value,
            name: form.get('administration').value,
        };
        this.subscriptions.add(
            this.store.dispatch(new SetCredentials(yukiCredentials)).subscribe(
                (result) => {
                    if (result) {
                        this.logger.debug('Succesfully saved yuki api key');
                        this.toastr.success('API key saved succesfully!');
                    } else {
                        this.toastr.warning('Failed to add yuki api key. Please try again later.');
                    }
                },
                (error) => this.logger.error('Failed to add yuki api key'),
            ),
        );
    }

    forceSync() {
        this.isSyncing = true;
        this.subscriptions.add(
            this.yukiLoginService.sync().subscribe(
                (result) => {
                    this.isSyncing = false;
                    if (result) {
                        this.logger.debug('Succesfully synced yuki');
                        this.toastr.success('Yuki synchronisation succesfull!');
                    } else {
                        this.toastr.warning('Failed to synchronise with yuki. Please try again later.');
                    }
                },
                (error) => {
                    this.isSyncing = false;
                    this.logger.error('Failed to synchronise with yuki.');
                },
            ),
        );
    }

    private createCredentialForm() {
        this.form = this.fb.group({
            apiKey: ['', [Validators.required, Validators.minLength(30), Validators.maxLength(36)]],
            administration: ['', [Validators.required]],
        });
    }

    private fetchData() {
        this.subscriptions.add(
            this.yukiCredential$.subscribe((credential) => {
                if (!credential) {
                    this.store.dispatch(new FetchCredentials());
                    return;
                }
                this.form.get('apiKey').setValue(credential.key);
                this.form.get('administration').setValue(credential.name);
            }),
        );
    }
}
