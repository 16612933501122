import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import CampaignModel from 'src/app/models/CampaignModel';

import { AddCampaignModalComponent } from '../add-campaign-modal/add-campaign-modal.component';
import { MailchimpCampaignReportComponent } from '../mailchimp-campaign-report/mailchimp-campaign-report.component';
import { FetchCampaigns } from '../mailchimp.actions';
import { MailchimpService } from '../mailchimp.service';
import { MailchimpState } from '../mailchimp.state';

@Component({
    selector: 'app-mailchimp-campaign-list',
    templateUrl: './mailchimp-campaign-list.component.html',
    styleUrls: ['./mailchimp-campaign-list.component.scss'],
})
export class MailchimpCampaignListComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Select(MailchimpState.campaigns) campaigns$: Observable<CampaignModel[]>;
    filter: string;
    isset = true;
    loading = true;

    constructor(private mailchimpService: MailchimpService, private modalService: NgbModal, private store: Store) {}

    ngOnInit(): void {
        this.subscriptions.add(
            this.mailchimpService.checkIfApiKeyExists().subscribe((e) => {
                if (e.key) {
                    this.campaigns$.subscribe((c) => {
                        if (c.length != 0) {
                            this.loading = false;
                        }
                    });
                    this.store.dispatch(new FetchCampaigns()).subscribe(() => (this.loading = false));
                }
            }),
        );
    }

    openCampaignReport(campaign: CampaignModel) {
        const modalRef = this.modalService.open(MailchimpCampaignReportComponent, { windowClass: 'modal-huge', animation: false });
        modalRef.componentInstance.campaign = campaign;
    }

    addCampaignModal() {
        this.modalService.open(AddCampaignModalComponent, {
            windowClass: 'modal-prompt',
        });
    }

    checkKey(set: boolean) {
        this.isset = set;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
