<div class="modal-header">
    <h2
        class="modal-title"
        id="exampleModalLabel">
        Delete contactperson
    </h2>
    <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    Are you sure you want to delete {{ contactPerson.lastName }} {{ contactPerson.firstName }}? If you want this contactperson back, you will have to re-add
    him/her.
</div>

<div class="modal-footer">
    <button
        class="button button--danger"
        (click)="deleteContactPerson()">
        Delete
    </button>
    <button
        type="button"
        class="button button--outline"
        (click)="activeModal.close()">
        Cancel
    </button>
</div>
