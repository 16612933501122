import DefaultProjectEmployeeLinkDTO from '../models/DefaultProjectEmployeeLinkDTO';
import { Employee } from '../models/Employee';
import Project from '../models/Project';
import { Status } from '../models/Status';
import { Resume } from '../models/resume/Resume';

export class AddProject {
    static readonly type = '[Company] Add Project';

    constructor(public project: Project) {}
}

export class UpdateProject {
    static readonly type = '[Company] Update Project';

    constructor(public projectUuid: string, public project: Project) {}
}

export class ArchiveProject {
    static readonly type = '[Company] Archive Project';

    constructor(public projectUuid: string) {}
}

export class AssignResumeToProject {
    static readonly type = '[Project] Assign Resume';

    constructor(public projectUuid: string, public selectedResumes: Resume[]) {}
}

export class FetchProjects {
    static readonly type = '[Project] Fetch Projects';
}

export class FetchProjectsForOverview {
    static readonly type = '[Project] Fetch Projects for overview';
}

export class FetchSimpleProjectList {
    static readonly type = '[Project] Fetch Simple Project List';
}

export class FetchProjectById {
    static readonly type = '[Project] Fetch Project By Id';

    constructor(public projectId: string) {}
}

export class GetProjectAssignedResumes {
    static readonly type = '[Project] Project Assignments';

    constructor(public projectUuid: string) {}
}

export class GetProjectAssignedResumesById {
    static readonly type = '[Project] Project Assignments';

    constructor(public projectUuid: string) {}
}

export class DeleteAssignedResume {
    static readonly type = '[Project] Delete Resume';

    constructor(public projectAssignmentUuid: string) {}
}

export class GetAllPhases {
    static readonly type = '[Project] Project Phases';
}

export class UpdateFinishedOfProject {
    static readonly type = '[Project] Update Finished';

    constructor(public projectId: string) {}
}

export class UpdateAssignmentStatus {
    static readonly type = '[Project] Update Assignment Status';

    constructor(public projectAssignmentUuid: string, public projectPhaseUuid: string) {}
}

export class AddProjectStatus {
    static readonly type = '[Project] Add Project Status';

    constructor(public companyUuid: string, public status: Status) {}
}

export class UpdateProjectStatus {
    static readonly type = '[Project] Update Project Status';

    constructor(public projectStatusUuid: string, public status: Status) {}
}

export class DeleteProjectStatus {
    static readonly type = '[Project] Delete Project Status';

    constructor(public projectStatusUuid: string) {}
}

export class AddProjectProducts {
    static readonly type = '[Project] Add products';

    constructor(public projectUuid: string, public products: string) {}
}

export class AddProjectEmployee {
    static readonly type = '[Project] Add employee';

    constructor(public projectUuid: string, public employee: Employee, public pricePerHour: number) {}
}

export class DeleteProductFromProject {
    static readonly type = '[Project] Delete related Product';

    constructor(public projectUuid: string, public productUuid: string) {}
}

export class DeleteEmployeeFromProject {
    static readonly type = '[Project] Delete related Employee';

    constructor(public projectUuid: string, public employeeUuid: string) {}
}

export class DeleteEmployeeFromProjectById {
    static readonly type = '[Project] Delete related Employee By Id';

    constructor(public linkId: string) {}
}

export class AddDefaultEmployees {
    static readonly type = '[DefaultProjectEmployeeLink] Add Default Project Employee Link';

    constructor(public defaultEmployees: DefaultProjectEmployeeLinkDTO) {}
}

export class DeleteDefaultEmployee {
    static readonly type = '[DefaultProjectEmployeeLink] Delete Default Project Employee Link';

    constructor(public uuid: string) {}
}
