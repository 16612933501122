import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, createSelector } from '@ngxs/store';
import { append, patch, updateItem } from '@ngxs/store/operators';
import { tap } from 'rxjs';

import { GeneralSettings } from '../models/GeneralSettings';
import { HolidayDay } from '../models/HolidayDay';
import TableDisplayedColumns from '../models/TableDisplayedColumns';
import {
    FetchAllTableDisplayedColumns,
    FetchGeneralSettings,
    GetTableDisplayedColumnsById,
    UpdateDaysPerMonth,
    UpdateHolidayDays,
    UpdateTableDisplayedColumns,
} from './settings.actions';
import { SettingsService } from './settings.service';

export interface SettingsStateModel {
    generalSettings: GeneralSettings;
    allTableDisplayedColumns: TableDisplayedColumns[];
}

@State<SettingsStateModel>({
    name: 'settings',
    defaults: {
        generalSettings: null,
        allTableDisplayedColumns: null,
    },
})
@Injectable()
export class SettingsState {
    constructor(private settingsService: SettingsService) {}

    @Selector()
    static DaysPerMonth(state: SettingsStateModel): number {
        return state?.generalSettings.daysPerMonth;
    }

    @Selector()
    static HolidayDays(state: SettingsStateModel): HolidayDay[] {
        return state?.generalSettings.holidayDays;
    }

    @Selector()
    static GeneralSettings(state: SettingsStateModel): GeneralSettings {
        return state?.generalSettings;
    }

    @Selector()
    static AllTableDisplayColumns(state: SettingsStateModel): TableDisplayedColumns[] {
        return state?.allTableDisplayedColumns;
    }

    static TableDisplayColumns(tableId: string) {
        return createSelector([SettingsState], (state: SettingsStateModel) => {
            const foundTable = state.allTableDisplayedColumns.find((table) => table.id === tableId);
            return foundTable?.displayedColumns;
        });
    }

    @Action(FetchGeneralSettings)
    fetchGeneralSettings(ctx: StateContext<SettingsStateModel>) {
        return this.settingsService.getGeneralSettings().pipe(
            tap((settings) => {
                ctx.setState(
                    patch<SettingsStateModel>({
                        generalSettings: settings,
                    }),
                );
            }),
        );
    }

    @Action(UpdateDaysPerMonth)
    updateDaysPerMonth(ctx: StateContext<SettingsStateModel>, action: UpdateDaysPerMonth) {
        return this.settingsService.updateDaysPerMonth(action.daysPerMonth).pipe(
            tap(() => {
                ctx.setState(
                    patch<SettingsStateModel>({
                        generalSettings: patch<GeneralSettings>({
                            daysPerMonth: action.daysPerMonth,
                        }),
                    }),
                );
            }),
        );
    }

    @Action(UpdateHolidayDays)
    updateHolidayDays(ctx: StateContext<SettingsStateModel>, action: UpdateHolidayDays) {
        return this.settingsService.updateHolidayDays(action.settings).pipe(
            tap(() => {
                ctx.setState(
                    patch<SettingsStateModel>({
                        generalSettings: patch<GeneralSettings>({
                            holidayDays: action.settings.holidayDays,
                        }),
                    }),
                );
            }),
        );
    }

    @Action(FetchAllTableDisplayedColumns)
    fetchAllTableDisplayedColumns(ctx: StateContext<SettingsStateModel>) {
        return this.settingsService.getAllTableDisplayedColumns().pipe(
            tap((allTableDisplayedColumns) => {
                ctx.setState(
                    patch<SettingsStateModel>({
                        allTableDisplayedColumns: allTableDisplayedColumns,
                    }),
                );
            }),
        );
    }

    @Action(GetTableDisplayedColumnsById)
    getTableDisplayedColumnsById(ctx: StateContext<SettingsStateModel>, action: GetTableDisplayedColumnsById) {
        return this.settingsService.getTableDisplayedColumns(action.tableId).pipe(
            tap((tableDisplayedColumns) => {
                ctx.setState(
                    patch<SettingsStateModel>({
                        allTableDisplayedColumns: updateItem<TableDisplayedColumns>((t) => t.id === action.tableId, tableDisplayedColumns),
                    }),
                );
            }),
        );
    }

    @Action(UpdateTableDisplayedColumns)
    updateTableDisplayedColumnsById(ctx: StateContext<SettingsStateModel>, action: UpdateTableDisplayedColumns) {
        const alreadyExists = ctx.getState().allTableDisplayedColumns.find((t) => t.id === action.tableDisplayedColumns.id);
        return this.settingsService.setTableDisplayedColumns(action.tableDisplayedColumns).pipe(
            tap((tableDisplayedColumns) => {
                if (alreadyExists) {
                    ctx.setState(
                        patch<SettingsStateModel>({
                            allTableDisplayedColumns: updateItem<TableDisplayedColumns>((t) => t.id === action.tableDisplayedColumns.id, tableDisplayedColumns),
                        }),
                    );
                } else {
                    ctx.setState(
                        patch<SettingsStateModel>({
                            allTableDisplayedColumns: append<TableDisplayedColumns>([tableDisplayedColumns]),
                        }),
                    );
                }
            }),
        );
    }
}
