<div class="modal-header">
    <h2
        class="modal-title"
        id="exampleModalLabel">
        Manage stages
    </h2>
    <span></span>
    <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <div class="table__overflow">
        <table
            cdkDropList
            mat-table
            [dataSource]="stages"
            matSort
            (cdkDropListDropped)="drop($event)">
            <ng-container matColumnDef="stage name">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header>
                    Stagename
                </th>
                <td
                    mat-cell
                    *matCellDef="let stage"
                    cdkDragHandle>
                    <i class="las la-braille"></i> {{ stage.name }}
                </td>
            </ng-container>

            <ng-container matColumnDef="probability">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header>
                    Probability
                </th>
                <td
                    mat-cell
                    *matCellDef="let stage">
                    {{ stage.probability }}%
                </td>
            </ng-container>

            <ng-container matColumnDef="rotting days">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header>
                    Rotting days
                </th>
                <td
                    mat-cell
                    *matCellDef="let stage">
                    {{ stage.rottingIn }} days
                </td>
            </ng-container>

            <ng-container matColumnDef="stage type">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header>
                    Stagetype
                </th>
                <td
                    mat-cell
                    *matCellDef="let stage">
                    <span
                        *ngIf="stage.type === 'NORMAL'"
                        class="tag tag--success"
                        >Normal</span
                    >
                    <span
                        *ngIf="stage.type === 'WIN'"
                        class="tag tag--green"
                        >Win</span
                    >
                    <span
                        *ngIf="stage.type === 'LOSS'"
                        class="tag tag--danger"
                        >Lost</span
                    >
                </td>
            </ng-container>

            <ng-container matColumnDef="edit">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    class="td-small"></th>
                <td
                    mat-cell
                    *matCellDef="let stage"
                    class="td-small">
                    <div
                        ngbDropdown
                        class="dropdown"
                        *appIsPermitted="{
                            module: 'CRM',
                            operator: 'OR',
                            actions: ['EDIT', 'DELETE']
                        }">
                        <button
                            ngbDropdownToggle
                            class="button button--icon button--small dropdown-toggle"
                            id="dropdownMenuButton"></button>
                        <div
                            ngbDropdownMenu
                            class="dropdown-menu dropdown-menu-right"
                            aria-labelledby="dropdownMenuButton">
                            <a
                                class="dropdown-item"
                                *appIsPermitted="{
                                    module: 'CRM',
                                    operator: 'AND',
                                    actions: ['EDIT']
                                }"
                                (click)="openUpdateStageModal(stage)"
                                >Edit stage</a
                            >
                            <a
                                class="dropdown-item color--red"
                                *appIsPermitted="{
                                    module: 'CRM',
                                    operator: 'AND',
                                    actions: ['DELETE']
                                }"
                                (click)="delete(stage.uuid)"
                                >Delete</a
                            >
                        </div>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="add">
                <ng-container
                    *appIsPermitted="{
                        module: 'CRM',
                        operator: 'AND',
                        actions: ['CREATE']
                    }">
                    <td
                        mat-footer-cell
                        *matFooterCellDef
                        colspan="5"
                        class="table-view__add"
                        (click)="openAddStageModal()">
                        <i class="las la-plus"></i> Add stage
                    </td>
                </ng-container>
            </ng-container>

            <tr
                mat-header-row
                *matHeaderRowDef="displayedColumns"></tr>

            <tr
                cdkDrag
                mat-row
                *matRowDef="let row; columns: displayedColumns"></tr>

            <tr
                mat-footer-row
                *matFooterRowDef="['add']"></tr>
        </table>
    </div>
</div>
