import { formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

import { AuthenticationService } from '../../authentication/authentication.service';
import CalendarEvent from '../../models/CalendarEvent';

@Injectable({
    providedIn: 'root',
})
export class GoogleCalendarService {
    httpOptions = {};
    formatString = 'yyyy-MM-ddTHH:mm:ss';

    constructor(private httpClient: HttpClient, private authenticationService: AuthenticationService) {
        this.httpOptions = authenticationService.getHeaders();
    }

    getIsLoggedIn() {
        return this.httpClient.get<boolean>(environment.baseUrl + '/google/isLoggedIn', this.httpOptions);
    }

    getCalendar() {
        return this.httpClient.get<CalendarEvent[]>(environment.baseUrl + '/google/calendar', this.httpOptions);
    }

    getCalendarByUserId(userId: string) {
        return this.httpClient.get<CalendarEvent[]>(environment.baseUrl + `/google/${userId}/calendar`, this.httpOptions);
    }

    getCalendarByUserAndDates(userId: string, start: Date, end: Date) {
        return this.httpClient.get<CalendarEvent[]>(
            environment.baseUrl + `/google/${userId}/${formatDate(start, this.formatString, 'en-US')}/${formatDate(end, this.formatString, 'en-US')}/calendar`,
            this.httpOptions,
        );
    }
}
