<form
    (ngSubmit)="saveVariant()"
    [formGroup]="skillFormGroup"
    class="modal-wrapper">
    <div class="modal-header">
        <h2 class="modal-title">
            <ng-container *ngIf="!isUpdate; else edit">New</ng-container> Skill
            <ng-template #edit>Edit</ng-template>
        </h2>
        <button
            (click)="activeModal.dismiss()"
            aria-label="Close"
            class="close"
            type="button">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <div class="form-group required m-b-2">
            <label>Technology</label>
            <select
                *ngIf="!isLoading && allTagsString.length > 0"
                formControlName="title">
                <option
                    *ngFor="let tag of allTagsString"
                    [value]="tag">
                    {{ tag }}
                </option>
            </select>
            <span *ngIf="allTagsString.length === 0" class="form-helptext">You need to add skills first before you can add them in the skill matrix...</span>
            <span
                *ngIf="skillFormGroup.controls.title.errors && inputIsWrong"
                class="form-error"
                >Technology is required</span
            >
        </div>
        <div class="form-group required m-b-2">
            <label>Years</label>
            <input
                (focus)="hideErrorMessage()"
                formControlName="years"
                trim="blur"
                type="text" />
            <span class="form-helptext">How many years of experience in this field of technology?</span>
            <span
                *ngIf="skillFormGroup.controls.years.errors && inputIsWrong"
                class="form-error"
                >Years of experience needs to be a valid year between 0 and 100
            </span>
        </div>
    </div>

    <div class="modal-footer">
        <button
            (click)="activeModal.close()"
            class="button button--outline"
            type="button">
            Close
        </button>
        <button
            *ngIf="!isUpdate"
            [ngClass]="{ 'button--loading': loading }"
            class="button button--primary"
            type="submit">
            Add Skill
        </button>
        <button
            *ngIf="isUpdate"
            [ngClass]="{ 'button--loading': loading }"
            class="button button--primary"
            type="submit">
            Edit Skill
        </button>
    </div>
</form>
