<div class="modal-header">
    <h2 class="modal-title">
        {{ recipient.firstName }} {{ recipient.lastName }}
        <i
            *ngFor="let star of counter(recipient.memberRating)"
            class="las la-star"></i>
    </h2>
    <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <ul>
        <li>
            <app-pill-mailto [mailtoEmail]="recipient.email"></app-pill-mailto>
        </li>
        <li *ngIf="recipient.phone">
            <app-pill-telephone [contactPhonenumber]="recipient.phone"></app-pill-telephone>
        </li>
    </ul>

    <form
        [formGroup]="recipientsFormGroup"
        (ngSubmit)="updateTags()">
        <div class="row">
            <div class="col">
                <div class="form-group m-b-2">
                    <label>Tags</label>
                    <tag-input
                        id="tag"
                        formControlName="tag"
                        [(ngModel)]="tags"
                        [addOnBlur]="true"
                        [clearOnBlur]="true">
                        <tag-input-dropdown
                            [autocompleteItems]="allTags"
                            [appendToBody]="false"
                            placeholder="">
                        </tag-input-dropdown>
                    </tag-input>
                </div>
            </div>
        </div>
        <input
            [ngClass]="!recipientsFormGroup.get('tag').dirty ? 'button button--disabled w-100' : 'button button--primary w-100'"
            type="submit"
            value="Edit"
            [disabled]="!recipientsFormGroup.get('tag').dirty" />
    </form>

    <h4>All info</h4>
    <p *ngFor="let prop of generateArray(recipient)">{{ prop.key }}: {{ prop.value }}</p>
</div>
