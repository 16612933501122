import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';

import { BudgetService } from '../../../budget/budget.service';
import { UploadedDocument } from '../../../models/UploadedDocument';
import { GlobalFilesService } from '../global-files.service';
import { UpdateDescriptionOfDocumentModalComponent } from '../update-description-of-document-modal/update-description-of-document-modal.component';

@Component({
    selector: 'app-uploaded-document-detail',
    templateUrl: './uploaded-document-detail.component.html',
    styleUrls: ['./uploaded-document-detail.component.scss'],
})
export class UploadedDocumentDetailComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Input() uploadedDocument: UploadedDocument;
    @Input() uploadedDocumentId: string;

    pdf: string;
    fileName: string;

    constructor(
        private store: Store,
        private budgetService: BudgetService,
        public activeModal: NgbActiveModal,
        private globalFilesService: GlobalFilesService,
        private modalService: NgbModal,
    ) {}

    ngOnInit(): void {
        this.fetchData();
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    private fetchData() {
        if (this.uploadedDocument) {
            this.fileName = this.getFileName(this.uploadedDocument.url);
            this.subscriptions.add(
                this.budgetService.getInvoice(this.uploadedDocument.url).subscribe({
                    next: (pdf) => {
                        this.pdf = `data:application/pdf;base64,${pdf.pdf}`;
                    },
                }),
            );
        } else {
            this.subscriptions.add(
                this.globalFilesService.fetchUploadedDocumentById(this.uploadedDocumentId).subscribe({
                    next: (uploadedDocument) => {
                        this.uploadedDocument = uploadedDocument;
                        this.fetchData();
                    },
                }),
            );
        }
    }

    private getFileName(url: string) {
        const substring = url.substr(url.indexOf('amazonaws.com/') + 14);
        return substring.substr(0, substring.indexOf('.pdf'));
    }

    openDescriptionModal() {
        const modalRef = this.modalService.open(UpdateDescriptionOfDocumentModalComponent, {
            windowClass: 'modal-prompt',
            animation: false,
        });
        modalRef.componentInstance.description = this.uploadedDocument.description;
        modalRef.componentInstance.documentId = this.uploadedDocument.documentId;
        this.subscriptions.add(
            modalRef.componentInstance.updated.subscribe((uploadedDocument) => {
                this.uploadedDocument = uploadedDocument;
            }),
        );
    }
}
