import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subscription } from 'rxjs';
import { FetchContacts } from 'src/app/contacts/contacts.actions';
import { ContactState } from 'src/app/contacts/contacts.state';
import { ContactPerson } from 'src/app/models/ContactPerson';
import { Partner } from 'src/app/models/Partner';

import { PartnerAddModalComponent } from '../partner-add-modal/partner-add-modal.component';
import { FetchPartners } from '../partners.actions';
import { PartnerState } from '../partners.state';

@Component({
    selector: 'app-partners-overview',
    templateUrl: './partners-overview.component.html',
    styleUrls: ['./partners-overview.component.scss'],
})
export class PartnersOverviewComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Select(PartnerState.getPartners) partners$: Observable<Partner[]>;
    @Select(ContactState.Contacts) contactPersons$: Observable<ContactPerson[]>;

    constructor(private modalService: NgbModal, private store: Store, private titleService: Title, private logger: NGXLogger) {
        this.titleService.setTitle('Companies overview');
    }

    ngOnInit(): void {
        this.logger.debug('Fetching partners');
        this.store.dispatch(new FetchPartners());
        this.logger.debug('Fetching contacts');
        this.store.dispatch(new FetchContacts());
    }

    openAddPartnerModal() {
        this.logger.debug('Opening partnerAddModal');
        this.modalService.open(PartnerAddModalComponent, {
            windowClass: 'modal-pane',
            animation: false,
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
