import { NotificationDTO } from './notifications.service';

export class FetchNotifications {
    static readonly type = '[Notification] Fetch Notifications';
}

export class FetchArchivedNotifications {
    static readonly type = '[Notification] Fetch Archived Notifications';
}

export class CreateNotification {
    static readonly type = '[Notification] Create Notification';

    constructor(public notificationDTO: NotificationDTO) {}
}

export class CreateNotificationForComments {
    static readonly type = '[Notification] Create Notification for comments';

    constructor(public commentObjectId: string, public message: string) {}
}

export class MarkNotificationAsRead {
    static readonly type = '[Notification] Mark Notification As Read';

    constructor(public notificationId: string) {}
}

export class ArchiveNotification {
    static readonly type = '[Notification] Archive Notification';

    constructor(public notificationId: string) {}
}

export class ArchiveAllNotifications {
    static readonly type = '[Notification] Archive All Notifications';
}

export class DeleteAllArchivedNotifications {
    static readonly type = '[Notification] Delete All Archived Notifications';
}
