import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { Module } from 'src/app/models/Module';

import { MailchimpLoginService } from './mailchimp-login.service';

@Component({
    selector: 'app-mailchimp-login',
    templateUrl: './mailchimp-login.component.html',
    styleUrls: ['./mailchimp-login.component.scss'],
})
export class MailchimpLoginComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Input() module: Module;
    key: string;
    form: FormGroup;

    constructor(private fb: FormBuilder, private mailchimpLoginService: MailchimpLoginService, private toastr: ToastrService, private logger: NGXLogger) {}

    ngOnInit(): void {
        this.logger.debug('Checking if logged in with mailchimp');
        this.subscriptions.add(
            this.mailchimpLoginService.checkIfApiKeyExists().subscribe(
                (e) => {
                    this.logger.debug('Logged in with mailchimp');
                    this.key = e.key;
                    if (this.key) {
                        this.form.get('apiKey').setValue(this.key);
                    }
                },
                (error) => this.logger.error('Not logged in with mailchimp'),
            ),
        );
        this.form = this.fb.group({
            apiKey: ['', [Validators.required, Validators.minLength(30), Validators.maxLength(50)]],
        });
    }

    onSubmit(form: FormGroup) {
        this.logger.debug('Attempt to save mailchimp api key');
        this.subscriptions.add(
            this.mailchimpLoginService.pushApiKey(form.get('apiKey').value).subscribe(
                (result) => {
                    if (result) {
                        this.logger.debug('Succesfully saved mailchimp api key');
                        this.toastr.success('API key saved succesfully!');
                    }
                },
                (error) => this.logger.error('Failed to save mailchimp api key'),
            ),
        );
    }

    removeKey() {
        this.logger.debug('Attempt to remove mailchimp api key');
        this.subscriptions.add(
            this.mailchimpLoginService.removeApiKey().subscribe(
                (result) => {
                    if (result) {
                        this.logger.debug('Succesfully removed mailchimp api key');
                        this.toastr.success('API key was removed!');
                        this.form.get('apiKey').setValue('');
                        this.form.markAsUntouched();
                    }
                },
                (error) => this.logger.error('Failed to re;ove mailchimp api key'),
            ),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
