import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { FetchCompany, GetCompanyInvitedMembers, GetCompanyMembers } from 'src/app/companies/company.actions';
import { CompanyInvitedMember } from 'src/app/models/CompanyInvitedMember';
import { CompanyMember } from 'src/app/models/CompanyMember';
import { Role } from 'src/app/models/Role';

import { AddCustomRoleToInvite, AddCustomRoleToMember, FetchRoles } from '../custom.roles.actions';
import { CustomRolesState } from '../custom.roles.state';

@Component({
    selector: 'app-role-member-assign-modal',
    templateUrl: './role-member-assign-modal.component.html',
    styleUrls: ['./role-member-assign-modal.component.scss'],
})
export class RoleMemberAssignModalComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();
    @Select(CustomRolesState.CustomRoles) customRoles$: Observable<Role[]>;
    @Input() member: CompanyMember;
    @Input() invite: CompanyInvitedMember;

    memberRoleFormGroup: FormGroup;
    allCustomRoles: Role[];
    loading = false;
    inputIsWrong = false;

    updateRoleAssignmentWentWrong = false;
    errorMessage: string;
    keyword = 'name';
    role: Role;
    roleId: number;

    constructor(
        private formBuilder: FormBuilder,
        public activeModal: NgbActiveModal,
        private store: Store,
        private toastr: ToastrService,
        private logger: NGXLogger,
    ) {}

    ngOnInit(): void {
        this.subscriptions.add(
            this.customRoles$.subscribe((customRoles) => {
                if (!customRoles) {
                    this.logger.debug('Fetching roles');
                    this.store.dispatch(new FetchRoles());
                    return;
                }

                this.allCustomRoles = customRoles;
                this.role = this.allCustomRoles.find((role) => role.name == this.member.role);
            }),
        );

        this.memberRoleFormGroup = this.formBuilder.group({
            role: '',
        });

        this.setFormValues();
    }

    setFormValues() {
        this.memberRoleFormGroup.setValue({
            role: this.role ? this.role : '',
        });
    }

    updateMemberRole() {
        this.loading = true;

        if (!this.memberRoleFormGroup.valid) {
            this.inputIsWrong = true;
            this.loading = false;
            return;
        }

        this.roleId = this.memberRoleFormGroup.value.role?.id;
        this.memberRoleFormGroup.disable();

        if (this.invite != undefined) {
            this.logger.debug('Attempt to add custom role to member');
            this.subscriptions.add(
                this.store.dispatch(new AddCustomRoleToInvite(this.roleId, this.invite.uuid)).subscribe(
                    () => {
                        this.logger.debug('Successfully added custom role to member');
                        this.activeModal.close();
                        this.logger.debug('Fetching company');
                        this.store.dispatch(new FetchCompany()).subscribe(() => {
                            this.logger.debug('Fetching company');
                            this.store.dispatch(new GetCompanyInvitedMembers());
                        });
                        this.toastr.success('Role added successfully');
                    },
                    (errorMessage) => {
                        this.logger.debug('Failed to add custom role to member');
                        this.errorMessage = errorMessage.error.message ?? 'Error message was empty';
                        this.updateRoleAssignmentWentWrong = true;
                        this.loading = false;
                        this.memberRoleFormGroup.enable();
                    },
                ),
            );
        } else {
            this.logger.debug('Attempt to add custom role to member');
            this.subscriptions.add(
                this.store.dispatch(new AddCustomRoleToMember(this.member.userUuid, this.roleId)).subscribe(
                    () => {
                        this.logger.debug('Successfully added custom role to member');
                        this.toastr.success('Role added successfully');

                        this.activeModal.close();
                    },
                    (errorMessage) => {
                        this.logger.debug('Failed to add custom role to member');
                        this.errorMessage = errorMessage.error.message ?? 'Error message was empty';
                        this.updateRoleAssignmentWentWrong = true;
                        this.loading = false;
                        this.memberRoleFormGroup.enable();
                    },
                ),
            );
        }
    }

    // Hides the error messages that appear after a user has submitted invalid data
    hideErrorMessage() {
        this.inputIsWrong = false;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
