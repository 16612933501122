<div class="modal-header">
    <h2
        class="modal-title"
        id="exampleModalLabel">
        Enter your password to continue
    </h2>
    <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <form
        #passwordForm="ngForm"
        (ngSubmit)="checkPasswordAndEnableMFA()">
        <div class="row">
            <div class="col">
                <password-input
                    [showLabel]="true"
                    [(ngModel)]="password"
                    name="password">
                </password-input>
                <small
                    class="form-error"
                    *ngIf="authErrorMessage"
                    >{{ authErrorMessage }}</small
                >
            </div>
        </div>
    </form>
</div>

<div class="modal-footer">
    <button
        class="button button--primary"
        (click)="checkPasswordAndEnableMFA()">
        Yes
    </button>
    <button
        type="button"
        class="button button--outline"
        (click)="activeModal.dismiss()">
        Cancel
    </button>
</div>
