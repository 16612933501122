<app-app-holder>
    <app-navbar></app-navbar>

    <app-sidebar>
        <app-settings-subnav></app-settings-subnav>
    </app-sidebar>

    <app-content *ngIf="company$ | async as company">
        <div class="app__title">
            <h1>Company members</h1>
            <span>
                <button
                    class="button button--primary"
                    (click)="openAddMemberModal()">
                    Invite member
                </button>
            </span>
        </div>

        <app-company-member-list></app-company-member-list>
    </app-content>
</app-app-holder>
