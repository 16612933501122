import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';

import { UploadedDocument } from '../../../models/UploadedDocument';
import { GlobalFilesService } from '../global-files.service';

@Component({
    selector: 'app-update-description-of-document-modal',
    templateUrl: './update-description-of-document-modal.component.html',
    styleUrls: ['./update-description-of-document-modal.component.scss'],
})
export class UpdateDescriptionOfDocumentModalComponent implements OnDestroy {
    private subscriptions = new Subscription();

    @Input() description: string;
    @Input() documentId: string;
    @Output() updated = new EventEmitter<UploadedDocument>();

    constructor(public activeModal: NgbActiveModal, private globalFileService: GlobalFilesService) {}

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    updateDescription() {
        this.subscriptions.add(
            this.globalFileService.updateDescription(this.documentId, this.description).subscribe({
                next: (uploadedDocument) => {
                    this.updated.emit(uploadedDocument);
                    this.activeModal.close();
                },
            }),
        );
    }
}
