import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subscription } from 'rxjs';
import Project from 'src/app/models/Project';
import { UserState } from 'src/app/users/user.state';

import { ArchiveProject } from '../project.actions';

@Component({
    selector: 'app-project-delete-modal',
    templateUrl: './project-delete-modal.component.html',
    styleUrls: ['./project-delete-modal.component.scss'],
})
export class ProjectDeleteModalComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Input() project: Project;
    @Select(UserState.userCompanyId) companyId$: Observable<string>;
    companyUuid: string;

    constructor(private store: Store, public activeModal: NgbActiveModal, private modalService: NgbModal, private logger: NGXLogger) {}

    ngOnInit(): void {
        this.subscriptions.add(this.companyId$.subscribe((id) => (this.companyUuid = id)));
    }

    archiveWork() {
        this.logger.debug('Attempt to archive work');
        this.subscriptions.add(
            this.store.dispatch(new ArchiveProject(this.project.uuid)).subscribe(
                () => {
                    this.logger.debug('Succesfully archived work');
                    this.activeModal.close();
                    this.modalService.dismissAll();
                },
                (error) => {
                    this.logger.error('Failed to archive work');
                },
            ),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
