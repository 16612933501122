import Budget from '../models/Budget';
import { Employee } from '../models/Employee';

export class FetchEmployees {
    static readonly type = '[Employee] Fetch Employee';
}

export class FetchSimpleInformationForAllEmployees {
    static readonly type = '[Employee] Fetch Simple Information for Employees';
}

export class AddEmployee {
    static readonly type = '[Employee] Add Employee';

    constructor(public employee: Employee) {}
}

export class UpdateEmployee {
    static readonly type = '[Employee] Update Employee';

    constructor(public employee: Employee) {}
}

export class GetAllYukiFileObjectsByEmployee {
    static readonly type = '[GlobalFileObjects] Fetch by EmployeeUuid';

    constructor(public employeeUuid: string) {}
}

export class GetEmployee {
    static readonly type = '[Employee] Get Employee';

    constructor(public employeeUuid: string) {}
}

export class FetchLinkedEmployees {
    static readonly type = '[LinkedEmployee] Get LinkedEmployee';
}

export class FetchLinkedEmployeesForEmployee {
    static readonly type = '[LinkedEmployee] Get LinkedEmployee by EmployeeId';

    constructor(public employeeId: string, public type?: string) {}
}

export class GetEmployeeById {
    static readonly type = '[Employee] Get Employee By Id';

    constructor(public employeeUuid: string) {}
}

export class DeleteEmployee {
    static readonly type = '[Employee] Delete Employee';

    constructor(public employeeUuid: string) {}
}

export class ArchiveEmployee {
    static readonly type = '[Employee] archive Employee';

    constructor(public employeeUuid: string) {}
}

export class FetchBudgetsForEmployee {
    static readonly type = '[Budget] Get Budgets For Employee';

    constructor(public employeeUuid: string) {}
}

export class AddBudgetToEmployee {
    static readonly type = '[Budget] Add Budget To Employee';

    constructor(public budget: Budget) {}
}

export class ArchiveBudgetForEmployee {
    static readonly type = '[Budget] Archive Budget';

    constructor(public budget: Budget) {}
}

export class FetchProjectList {
    static readonly type = '[Project] Fetch Project';
}

export class FetchProjectListByCurrentEmployee {
    static readonly type = '[Project] Fetch Project For Employee';

    constructor(public employeeUuid: string) {}
}

export class FetchProjectsForEmployee {
    static readonly type = '[Project] Fetch projects for employee';

    constructor(public employeeUuid: string) {}
}

export class FetchStockUpdateHistoryForEmployee {
    static readonly type = '[StockUpdateHistory] Fetch StockUpdateHistory for employee';

    constructor(public employeeId: string) {}
}
