<h2>Logged in users</h2>

<div *ngIf="loginPending"><app-loading-message></app-loading-message></div>
<button
    *ngIf="isLoggedIn && !loginPending"
    (click)="logoutWithGoogle()">
    Logout
</button>
<button
    *ngIf="!isLoggedIn && !loginPending"
    (click)="signInWithGoogle()">
    Login
</button>

<div
    class="list__item--user"
    *ngFor="let member of loggedInMembers">
    <div class="list__item__image">
        <img [src]="'https://eu.ui-avatars.com/api/?background=random&bold=true&name=' + member.firstName + ' ' + member.lastName" />
    </div>
    <div class="list__item__content">
        <div>
            {{ member.firstName }} {{ member.lastName }}
            <span class="badge m-l-1">{{ member.role }}</span>
        </div>
        <p *ngIf="googleUserInfo">Google mail: {{ getGmailFromMember(member.userUuid) }}</p>
        <small>Company mail: {{ member.email }}</small>
    </div>
    <div class="list__item__extra">
        <span>
            <div
                ngbDropdown
                class="dropdown">
                <button
                    ngbDropdownToggle
                    class="button button--icon button--small dropdown-toggle"
                    id="dropdownMenuButton"></button>
                <div
                    ngbDropdownMenu
                    class="dropdown-menu dropdown-menu-right"
                    aria-labelledby="dropdownMenuButton">
                    <a
                        class="dropdown-item"
                        href="#"
                        >Edit member</a
                    >
                    <a
                        class="dropdown-item color--red"
                        href="#"
                        >Delete</a
                    >
                </div>
            </div>
        </span>
    </div>
</div>
