import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { append, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { tap } from 'rxjs/operators';

import { ApplicantService } from '../applicants/applicant.service';
import { Applicant } from '../models/Applicants/Applicant';
import { Company } from '../models/Company';
import { CompanyInvitedMember } from '../models/CompanyInvitedMember';
import { CompanyMember } from '../models/CompanyMember';
import { Module } from '../models/Module';
import { ROUTES } from '../models/ROUTES';
import { Status } from '../models/Status';
import { CreateNotification } from '../notifications/notifications.actions';
import { UserService } from '../users/user.service';
import {
    AddCompany,
    ArchiveCompany,
    ClearCompany,
    DeleteMemberInvite,
    FetchCompany,
    GetAllApplicants,
    GetCompanyInvitedMembers,
    GetCompanyMemberById,
    GetCompanyMembers,
    GetCompanyProjectStatusList,
    InviteMember,
    UpdateCompany,
    UpdateRoleOfMember,
} from './company.actions';
import { CompanyService } from './company.service';

export interface CompanyStateModel {
    company: Company;
    members: CompanyMember[];
}

@State<CompanyStateModel>({
    name: 'company',
    defaults: {
        company: null,
        members: [],
    },
})
@Injectable()
export class CompanyState {
    @Selector()
    static company(state: CompanyStateModel): Company {
        return state?.company;
    }

    @Selector()
    static companyUuid(state: CompanyStateModel): string {
        return state?.company.uuid;
    }

    @Selector()
    static companyMembers(state: CompanyStateModel): CompanyMember[] {
        return state?.members;
    }

    @Selector()
    static companyInvitedMembers(state: CompanyStateModel): CompanyInvitedMember[] {
        return state?.company.invitedMembers;
    }

    @Selector()
    static companyApplicants(state: CompanyStateModel): Applicant[] {
        return state?.company.applicants;
    }

    @Selector()
    static companyStatusList(state: CompanyStateModel): Status[] {
        return state?.company.statusList;
    }

    @Selector()
    static companyModules(state: CompanyStateModel): Module[] {
        return state?.company.modules;
    }

    constructor(private companyService: CompanyService, private userService: UserService, private applicantService: ApplicantService, private store: Store) {}

    @Action(FetchCompany)
    fetchCompany(ctx: StateContext<CompanyStateModel>) {
        return this.userService.getCompanyOfUser().pipe(
            tap((company) => {
                ctx.setState(
                    patch<CompanyStateModel>({
                        company: company,
                    }),
                );
            }),
        );
    }

    @Action(AddCompany)
    addCompany(ctx: StateContext<CompanyStateModel>, action: AddCompany) {
        return this.companyService.addCompany(action.company).pipe(
            tap((c) => {
                ctx.setState(
                    patch<CompanyStateModel>({
                        company: c,
                    }),
                );
                this.store.dispatch(
                    new CreateNotification({
                        message: `A company with title <strong>${c.title}</strong> is added`,
                        route: ROUTES.COMPANIES.route,
                    }),
                );
            }),
        );
    }

    @Action(UpdateCompany)
    updateCompany(ctx: StateContext<CompanyStateModel>, action: UpdateCompany) {
        return this.companyService.updateCompany(action.company).pipe(
            tap((company) => {
                ctx.setState(
                    patch<CompanyStateModel>({
                        company: company,
                    }),
                );
                this.store.dispatch(
                    new CreateNotification({
                        message: `A company with title <strong>${company.title}</strong> is updated`,
                        route: ROUTES.COMPANIES.route,
                    }),
                );
            }),
        );
    }

    @Action(ArchiveCompany)
    archiveCompany(ctx: StateContext<CompanyStateModel>) {
        return this.companyService.archiveCompany(ctx.getState().company.uuid).pipe(
            tap(() => {
                const company: Company = ctx.getState().company;
                ctx.setState(
                    patch<CompanyStateModel>({
                        company: null,
                    }),
                );
                this.store.dispatch(
                    new CreateNotification({
                        message: `A company with title <strong>${company.title}</strong> is archived`,
                        route: ROUTES.COMPANIES.route,
                    }),
                );
            }),
        );
    }

    @Action(ClearCompany)
    clearCompany(ctx: StateContext<CompanyStateModel>) {
        ctx.setState(
            patch<CompanyStateModel>({
                company: null,
            }),
        );
    }

    @Action(GetCompanyMembers)
    getCompanyMembers(ctx: StateContext<CompanyStateModel>) {
        return this.companyService.getAllMembers().pipe(
            tap((members) => {
                ctx.setState(
                    patch<CompanyStateModel>({
                        members: members,
                    }),
                );
            }),
        );
    }

    @Action(GetCompanyMemberById)
    getCompanyMemberById(ctx: StateContext<CompanyStateModel>, action: GetCompanyMemberById) {
        return this.companyService.getCompanyMemberById(action.id).pipe(
            tap((member) => {
                ctx.setState(
                    patch<CompanyStateModel>({
                        members: append([member]),
                    }),
                );
            }),
        );
    }

    @Action(GetAllApplicants)
    getAllApplicants(ctx: StateContext<CompanyStateModel>) {
        return this.applicantService.getAllApplicants().pipe(
            tap((applicantList) => {
                ctx.setState(
                    patch<CompanyStateModel>({
                        company: patch<Company>({
                            applicants: applicantList,
                        }),
                    }),
                );
            }),
        );
    }

    @Action(GetCompanyInvitedMembers)
    getCompanyInvitedMembers(ctx: StateContext<CompanyStateModel>) {
        return this.companyService.getAllInvitedMembers(ctx.getState().company.uuid).pipe(
            tap((invitedMembersList) => {
                ctx.setState(
                    patch<CompanyStateModel>({
                        company: patch<Company>({
                            invitedMembers: invitedMembersList,
                        }),
                    }),
                );
            }),
        );
    }

    @Action(InviteMember)
    inviteCompanyMember(ctx: StateContext<CompanyStateModel>, action: InviteMember) {
        return this.companyService.inviteMember(action.email).pipe(
            tap(() => {
                const invMember: CompanyInvitedMember = {
                    uuid: '',
                    email: action.email,
                    status: 'PENDING',
                    createdOn: new Date().toUTCString(),
                    companyName: ctx.getState().company.title,
                    roleName: null,
                };
                ctx.setState(
                    patch<CompanyStateModel>({
                        company: patch<Company>({
                            invitedMembers: append([invMember]),
                        }),
                    }),
                );
            }),
        );
    }

    @Action(DeleteMemberInvite)
    deleteMemberInvite(ctx: StateContext<CompanyStateModel>, action: DeleteMemberInvite) {
        return this.companyService.deleteInvite(action.inviteUuid).pipe(
            tap(() => {
                ctx.setState(
                    patch<CompanyStateModel>({
                        company: patch<Company>({
                            invitedMembers: removeItem<CompanyInvitedMember>((m) => m.uuid === action.inviteUuid),
                        }),
                    }),
                );
            }),
        );
    }

    @Action(GetCompanyProjectStatusList)
    getCompanyProjectStatusList(ctx: StateContext<CompanyStateModel>) {
        return this.companyService.getCompanyProjectStatusList(ctx.getState().company.uuid).pipe(
            tap((projectStatusList) => {
                ctx.setState(
                    patch<CompanyStateModel>({
                        company: patch<Company>({
                            statusList: projectStatusList,
                        }),
                    }),
                );
            }),
        );
    }

    @Action(UpdateRoleOfMember)
    updateRoleOfMember(ctx: StateContext<CompanyStateModel>, action: UpdateRoleOfMember) {
        ctx.setState(
            patch<CompanyStateModel>({
                members: updateItem(
                    (member) => member.userUuid === action.memberId,
                    patch({
                        role: action.role,
                    }),
                ),
            }),
        );
        console.log(ctx.getState().members);
    }
}
