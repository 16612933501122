import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subscription } from 'rxjs';
import { Applicant } from 'src/app/models/Applicants/Applicant';
import { ApplicantLane } from 'src/app/models/Applicants/ApplicantLane';
import ApplicantReasonOfLoss from 'src/app/models/Applicants/ApplicantReasonOfLoss';

import { AddApplicantModalComponent } from '../add-applicant-modal/add-applicant-modal.component';
import { FetchApplicantLanes, FetchApplicantReasonsOfLoss, FetchApplicants } from '../applicant.actions';
import { ApplicantState } from '../applicant.state';
import { ManageApplicantLanesModalComponent } from '../manage-applicant-lanes-modal/manage-applicant-lanes-modal.component';

@Component({
    selector: 'app-applicants-overview',
    templateUrl: './applicants-overview.component.html',
    styleUrls: ['./applicants-overview.component.scss'],
})
export class ApplicantsOverviewComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Select(ApplicantState.Applicants) applicants$: Observable<Applicant[]>;
    @Select(ApplicantState.Lanes) lanes$: Observable<ApplicantLane[]>;
    @Select(ApplicantState.ReasonsOfLoss) reasonsOfLoss$: Observable<ApplicantReasonOfLoss[]>;

    lanes: ApplicantLane[];

    view = 'kanbanView';

    constructor(private modalService: NgbModal, private store: Store, private titleService: Title, private logger: NGXLogger) {
        this.titleService.setTitle('Applicants overview');
    }

    ngOnInit(): void {
        this.logger.debug('Fetching applicants');
        this.store.dispatch(new FetchApplicants());
        this.store.dispatch(new FetchApplicantLanes());
        this.store.dispatch(new FetchApplicantReasonsOfLoss());
        this.subscriptions.add(
            this.lanes$.subscribe((lanes) => {
                if (lanes) this.lanes = lanes;
            }),
        );
    }

    openAddApplicantModal() {
        this.modalService.open(AddApplicantModalComponent, {
            windowClass: 'modal-pane',
            animation: false,
        });
    }

    openManageLanesModal() {
        const modalRef = this.modalService.open(ManageApplicantLanesModalComponent, {
            windowClass: 'modal-pane',
            animation: false,
        });
        this.lanes$.subscribe((lanes) => {
            if (modalRef.componentInstance) {
                if (lanes) modalRef.componentInstance.lanes = lanes;
            }
        });
    }

    switchView(type: string) {
        this.view = type;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
