import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';
import { Product } from 'src/app/models/Product';

import { LinkProductsComponent } from '../link-products/link-products.component';
import { ProductDeleteLinkComponent } from '../product-delete-link/product-delete-link.component';

@Component({
    selector: 'app-product-parent-list',
    templateUrl: './product-parent-list.component.html',
    styleUrls: ['./product-parent-list.component.scss'],
})
export class ProductParentListComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Input() product: Product;
    @Input() allProducts: Product[];
    @Input() combinedProducts: Product[];

    @Input() selectedParentProducts: Product[] = [];
    @Output() changeParents = new EventEmitter();
    @Output() changeCombinedProducts = new EventEmitter();

    displayedColumns: string[] = ['parent product', 'edit'];
    dataSource: MatTableDataSource<Product> = new MatTableDataSource<Product>();
    @ViewChild(MatSort) sort: MatSort;

    constructor(public modalService: NgbModal, private logger: NGXLogger) {}

    ngOnInit(): void {}

    parentUnitType(productUuid: string): string {
        const unit = this.allProducts.filter((m) => m.uuid == productUuid)[0]?.unit;
        if (unit) {
            return unit.type;
        }
        return '';
    }

    openLinkProductModal() {
        this.logger.debug('Opening linkProductModal');
        const modalRef = this.modalService.open(LinkProductsComponent, {
            windowClass: 'modal-prompt',
        });
        modalRef.componentInstance.isChild = true;
        modalRef.componentInstance.combinedProducts = this.combinedProducts;
        if (this.product) {
            modalRef.componentInstance.child = this.product;
            modalRef.componentInstance.isDetailModal = true;
        } else {
            modalRef.componentInstance.addedProduct.subscribe((products) => {
                this.selectedParentProducts.push(products[0]);
                this.changeParents.emit(this.selectedParentProducts);
            });
        }
    }

    removeFromSelectedParentProducts(parent: Product) {
        this.selectedParentProducts = this.selectedParentProducts.filter((p) => p.uuid != parent.uuid);
        this.combinedProducts = this.combinedProducts.filter((p) => p.uuid != parent.uuid);
        this.changeParents.emit(this.selectedParentProducts);
        this.changeCombinedProducts.emit(this.combinedProducts);
    }

    openProductParentDeleteModal(product: Product, parentUuid: string) {
        this.logger.debug('Opening productDeleteParentModal');
        const modalRef = this.modalService.open(ProductDeleteLinkComponent, {
            windowClass: 'modal-prompt',
        });
        modalRef.componentInstance.product = product;
        modalRef.componentInstance.linkedProduct = this.allProducts.find((m) => m.uuid == parentUuid);
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
