import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subscription } from 'rxjs';
import { Partner } from 'src/app/models/Partner';
import Project from 'src/app/models/Project';
import { ProjectDetailModalComponent } from 'src/app/projects/project-detail-modal/project-detail-modal.component';
import { FetchProjects } from 'src/app/projects/project.actions';
import { ProjectState } from 'src/app/projects/project.state';

import { FetchPartners, GetPartner } from '../partners.actions';

@Component({
    selector: 'app-detail-projects',
    templateUrl: './detail-projects.component.html',
    styleUrls: ['./detail-projects.component.scss'],
})
export class DetailProjectsComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Select(ProjectState.projects) works$: Observable<Project[]>;
    works: Project[];
    partnerId: string;
    partner: Partner;

    constructor(private store: Store, private modalService: NgbModal, private route: ActivatedRoute, private titleService: Title, private logger: NGXLogger) {
        this.titleService.setTitle('Projects overview');
    }

    ngOnInit(): void {
        this.logger.debug('Fetching work');
        this.store.dispatch(new FetchProjects());
        this.subscriptions.add(this.works$.subscribe((allWork) => (this.works = allWork)));

        this.partnerId = this.route.snapshot.paramMap.get('id');

        this.logger.debug('Fetching partner');
        this.subscriptions.add(
            this.store
                .dispatch(new GetPartner(this.partnerId))
                .subscribe((state) => (this.partner = state.partner.partners.find((partner) => partner.uuid == this.partnerId))),
        );

        this.subscriptions.add(
            this.route.params.subscribe((params) => {
                this.logger.debug('Fetching partners');
                this.store.dispatch(new FetchPartners());
            }),
        );
    }

    openAddWorkModal() {
        this.logger.debug('Opening projectDetailModal');
        const modalRef = this.modalService.open(ProjectDetailModalComponent);
        modalRef.componentInstance.partnerUuidInput = this.partner.uuid;
        modalRef.componentInstance.inOverview = true;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
