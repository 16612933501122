<div class="dashboard__tile__title graph__title">
    <span>{{ happinessScoreInformation?.fullName }}</span>

    <app-date-navigation
        (selectedDateUpdated)="updateSelectedYear()"
        [dateNavigationOptions]="dateNavigationOptions"
        [isCompact]="true">
    </app-date-navigation>
</div>

<div class="dashboard__tile__content">
    <app-default-line-chart
        *ngIf="happinessScoreInformation?.happinessScores; else loading"
        [data]="data"
        [legendPosition]="legendPosition"
        [objectLabels]="labels"
        [xLabels]="xLabels"
        [ySuggestedMax]="5"
        [ySuggestedMin]="1">
    </app-default-line-chart>
</div>
<ng-template #loading>
    <app-loading-message></app-loading-message>
</ng-template>
