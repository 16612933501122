import { Component, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NGXLogger } from 'ngx-logger';
import { ResumeVariant } from 'src/app/models/resume/ResumeVariant';
import { ResumeVariantSkill } from 'src/app/models/resume/ResumeVariantSkill';

import { ResumeVariantSkillDeleteModalComponent } from '../resume-variant-skill-delete-modal/resume-variant-skill-delete-modal.component';
import { ResumeVariantSkillDetailModalComponent } from '../resume-variant-skill-detail-modal/resume-variant-skill-detail-modal.component';

@Component({
    selector: 'app-resume-variant-skill-list',
    templateUrl: './resume-variant-skill-list.component.html',
    styleUrls: ['./resume-variant-skill-list.component.scss'],
})
export class ResumeVariantSkillListComponent {
    @Input() resumeVariantSkills: ResumeVariantSkill[];
    @Input() showActions = true;
    @Input() resumeVariant: ResumeVariant;

    constructor(private modalService: NgbModal, private logger: NGXLogger) {}

    openResumeVariantSkillAddModal() {
        this.logger.debug('Opening resumeVariantSkillDetailModal');
        const modalRef = this.modalService.open(ResumeVariantSkillDetailModalComponent, { windowClass: 'modal-prompt', animation: false });
        modalRef.componentInstance.resumeVariant = this.resumeVariant;
    }

    openUpdateResumeVariantSkillModal(skill: ResumeVariantSkill) {
        this.logger.debug('Opening resumeVariantSkillDetailModal');
        const modalRef = this.modalService.open(ResumeVariantSkillDetailModalComponent, { windowClass: 'modal-prompt', animation: false });

        modalRef.componentInstance.skill = skill;
        modalRef.componentInstance.resumeVariant = this.resumeVariant;
    }

    openDeleteResumeVariantSkillModal(skill: ResumeVariantSkill) {
        this.logger.debug('Opening resumeVariantSkillDeleteModal');
        const modalRef = this.modalService.open(ResumeVariantSkillDeleteModalComponent, { windowClass: 'modal-prompt', animation: false });

        modalRef.componentInstance.skill = skill;
        modalRef.componentInstance.resumeUuid = this.resumeVariant.resumeId;
    }
}
