import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';

import { EnableMfaAuthentication } from '../../users/user.actions';
import { UserService } from '../../users/user.service';

@Component({
    selector: 'app-password-modal',
    templateUrl: './password-modal.component.html',
    styleUrls: ['./password-modal.component.scss'],
})
export class PasswordModalComponent implements OnInit {
    private subscriptions = new Subscription();

    @Output() qrCode: EventEmitter<string> = new EventEmitter<string>();

    password: string;
    authErrorMessage: string;

    constructor(public activeModal: NgbActiveModal, private store: Store, private userService: UserService) {}

    ngOnInit(): void {}

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    checkPasswordAndEnableMFA(): void {
        this.store.dispatch(new EnableMfaAuthentication(this.password)).subscribe(
            () => {
                this.subscriptions.add(
                    this.userService.getMfaQRCode().subscribe((qr) => {
                        this.qrCode.emit(qr);
                        this.activeModal.dismiss();
                    }),
                );
            },
            (error) => {
                this.authErrorMessage = 'Your password is not correct... Please try again';
            },
        );
    }
}
