import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable, of } from 'rxjs';

import { AuthenticationService } from '../authentication/authentication.service';

@Injectable({
    providedIn: 'root',
})
export class CodeGuard implements CanActivate {
    constructor(private authService: AuthenticationService, private store: Store, private router: Router, private aRoute: ActivatedRoute) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        if (location.href.includes('exact')) {
            const authcode = location.href.split('code=')[1];
            this.router.navigate(['/settings/token-retrieval'], {
                queryParams: { code: `${authcode}`, type: 'exact' },
            });
        }

        if (location.href.includes('code') && location.href.includes('state=12345')) {
            const authcode = location.href.split('code=')[1].split('&state')[0];
            this.router.navigate(['/settings/token-retrieval'], {
                queryParams: { code: `${authcode}`, type: 'outlook' },
            });
        } else if (location.href.includes('code')) {
            const authcode = location.href.split('code=')[1];
            this.router.navigate(['/settings/token-retrieval'], {
                queryParams: { code: `${authcode}`, type: 'teamleader' },
            });
        } else {
            return of(true);
        }
    }
}
