<div class="modal-header">
    <h2 class="modal-title">
        <ng-container *ngIf="overheadCategory; else loading">
            {{ overheadCategory.categoryName }}
        </ng-container>
    </h2>

    <button
        (click)="activeModal.dismiss()"
        aria-label="Close"
        class="close"
        type="button">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<nav #nav="ngbNav" [(activeId)]="activeNavTab" class="tabs__modal" ngbNav>
    <ng-container [ngbNavItem]="'information'">
        <a ngbNavLink>Information</a>
        <ng-template ngbNavContent>
            <section *ngIf="overheadCategory; else loading">
                <p class="sectiontitle sectiontitle--first">Information</p>
                <ul class="list__specs">
                    <li>
                        <span *ngIf="overheadCategory.excludeFromFinances" class="tag m-r-2">Excluded</span>
                        <span *ngIf="!overheadCategory.excludeFromFinances" class="tag">Included</span>
                        from finances
                    </li>
                </ul>
            </section>
        </ng-template>
    </ng-container>

    <ng-container [ngbNavItem]="'financial'">
        <a ngbNavLink>Financial</a>
        <ng-template ngbNavContent>
            <app-overhead-graph-widget [categoryId]="overheadCategoryId"></app-overhead-graph-widget>
        </ng-template>
    </ng-container>

    <ng-container ngbNavItem>
        <a
            *appIsPermitted="{ module: 'YUKI', operator: 'AND', actions: ['READ'] }"
            ngbNavLink
        >Linked Invoices</a
        >
        <ng-template ngbNavContent>
            <div class="d-flex m-r-2">
                <div class="form-group m-r-2">
                    <label for="startDate">Start date</label>
                    <input
                        (change)="filterDate()"
                        [(ngModel)]="startDateFilter"
                        class="input--bg-transparant"
                        id="startDate"
                        type="date"/>
                </div>

                <div class="form-group">
                    <label for="endDate">End date</label>
                    <input
                        (change)="filterDate()"
                        [(ngModel)]="endDateFilter"
                        class="input--bg-transparant"
                        id="endDate"
                        type="date"/>
                </div>
            </div>

            <details open>
                <summary>
                    <span class="sectiontitle summary__title">Sale</span>
                    <i class="las la-angle-up summary__up-arrow"></i>
                </summary>
                <div>
                    <app-linked-invoices-to-object
                        [alreadyFetchedFiles]="false"
                        [folderId]="2"
                        [objectId]="overheadCategoryId"
                        [showFiles]="false">
                    </app-linked-invoices-to-object>
                    <i class="las la-angle-down summary__down-arrow"></i>
                </div>
            </details>

            <details open>
                <summary>
                    <span class="sectiontitle summary__title">Purchase</span>
                    <i class="las la-angle-up summary__up-arrow"></i>
                </summary>
                <div>
                    <app-linked-invoices-to-object
                        [alreadyFetchedFiles]="false"
                        [folderId]="1"
                        [objectId]="overheadCategoryId"
                        [showFiles]="false">
                    </app-linked-invoices-to-object>
                    <i class="las la-angle-down summary__down-arrow"></i>
                </div>
            </details>
        </ng-template>
    </ng-container>

    <ng-container ngbNavItem>
        <a ngbNavLink>Files</a>
        <ng-template ngbNavContent>
            <app-linked-invoices-to-object
                [alreadyFetchedFiles]="false"
                [objectId]="overheadCategoryId"
                [showInvoices]="false">
            </app-linked-invoices-to-object>
        </ng-template>
    </ng-container>
</nav>


<div class="modal-body">
    <div [ngbNavOutlet]="nav"></div>
</div>

<div class="modal-footer">
    <div
        class="dropdown"
        ngbDropdown>
        <button
            class="button button--icon dropdown-toggle"
            id="dropdownMenuButton"
            ngbDropdownToggle>
            <span>Options</span>
        </button>

        <div
            aria-labelledby="dropdownMenuButton"
            class="dropdown-menu dropdown-menu-right"
            ngbDropdownMenu>
            <a
                (click)="openUpdateCategoryNameModal()"
                *appIsPermitted="{
                        module: 'PROJECTS',
                        operator: 'AND',
                        actions: ['EDIT']
                    }"
                class="dropdown-item">
                Edit overhead category
            </a>
        </div>
    </div>
</div>

<ng-template #loading>
    <app-loading-message></app-loading-message>
</ng-template>
