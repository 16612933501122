import { Component, OnDestroy, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';

import { Notification } from '../../models/Notification';
import { ArchiveAllNotifications, DeleteAllArchivedNotifications, FetchArchivedNotifications } from '../notifications.actions';
import { NotificationsState } from '../notifications.state';

@Component({
    selector: 'app-notification-modal',
    templateUrl: './notification-modal.component.html',
    styleUrls: ['./notification-modal.component.scss'],
})
export class NotificationModalComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Select(NotificationsState.notifications) notifications$: Observable<Notification[]>;
    @Select(NotificationsState.archivedNotifications) archivedNotifications$: Observable<Notification[]>;
    notifications: Notification[];
    archivedNotifications: Notification[];

    fetchedArchivedNotifications: boolean;

    constructor(private store: Store) {}

    ngOnInit(): void {
        this.fetchData();
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    private fetchData() {
        this.subscriptions.add(
            this.notifications$.subscribe((notifications) => {
                this.notifications = notifications;
                this.notifications = [...this.notifications].sort((n1: Notification, n2: Notification) => n2.created.getTime() - n1.created.getTime());
            }),
        );

        this.subscriptions.add(
            this.archivedNotifications$.subscribe((notifications) => {
                this.archivedNotifications = notifications;
                this.archivedNotifications = [...this.archivedNotifications].sort(
                    (n1: Notification, n2: Notification) => n2.created.getTime() - n1.created.getTime(),
                );
            }),
        );
    }

    navChange() {
        if (!this.fetchedArchivedNotifications) {
            this.subscriptions.add(
                this.store.dispatch(new FetchArchivedNotifications()).subscribe({
                    next: () => (this.fetchedArchivedNotifications = true),
                }),
            );
        }
    }

    archiveAllNotifications() {
        this.store.dispatch(new ArchiveAllNotifications());
    }

    deleteAllArchivedNotifications() {
        this.store.dispatch(new DeleteAllArchivedNotifications());
    }
}
