<div class="modal-wrapper" *ngIf="!isLoading && project.uuid === projectUuid">
    <div class="modal-header">
        <h2 class="modal-title">
            <ng-container>
                {{ project.title }}
            </ng-container>
        </h2>
        <button
            (click)="activeModal.dismiss()"
            aria-label="Close"
            class="close"
            type="button">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <nav
        #nav="ngbNav"
        class="tabs__modal" ngbNav>

        <ng-container ngbNavItem>
            <a ngbNavLink>Information</a>
            <ng-template ngbNavContent>
                <section>
                    <p class="sectiontitle sectiontitle--first">Project information</p>
                    <ul class="list__specs">
                        <li>
                            <label>Status</label>
                            <span
                                *ngIf="project.finished"
                                class="tag tag--green"
                            >Finished</span
                            >
                            <span
                                *ngIf="!project.finished"
                                class="tag tag--blue"
                            >Ongoing</span
                            >
                        </li>
                        <li>
                            <label>Billable</label>
                            <span *ngIf="project.billable"
                            ><i
                                class="las la-check-circle"
                                style="color: green"></i
                            ></span>
                            <span *ngIf="!project.billable"
                            ><i
                                class="las la-times-circle"
                                style="color: red"></i
                            ></span>
                        </li>
                        <li>
                            <label>Warranty</label>
                            <span *ngIf="project.warranty && projectHasActiveWarranty(project) && calculateRemainingWarrantyDays(project) > 0">
                               <span id="active"><b>{{calculateRemainingWarrantyDays(project)}}</b><b> DAYS REMAINING</b></span>
                                    {{ project.warrantyStartDate | date }} - {{ project.warrantyEndDate | date }}
                            </span>
                            <span *ngIf="project.warranty && projectHasActiveWarranty(project) && calculateRemainingWarrantyDays(project) == 0">
                                <span id="expiring"><b>EXPIRING TODAY</b></span>
                                     {{ project.warrantyStartDate | date }} - {{ project.warrantyEndDate | date }}
                             </span>
                            <span *ngIf="project.warranty && !projectHasActiveWarranty(project)">
                                <span>
                                    <span id="expired"><b>EXPIRED</b></span> {{project.warrantyStartDate | date}} - {{project.warrantyEndDate | date}}
                                </span>
                            </span>
                            <span *ngIf="!project.warranty"
                            ><i
                                class="las la-times-circle"
                                style="color: red"></i
                            >
                        </span>
                        </li>
                        <li>
                            <label>Maintenance</label>
                            <span *ngIf="project.maintenanceContract"
                            ><i
                                class="las la-check-circle"
                                style="color: green"></i
                            ></span>
                            <span *ngIf="!project.maintenanceContract"
                            ><i
                                class="las la-times-circle"
                                style="color: red"></i
                            ></span>
                        </li>
                        <li>
                            <li *ngIf="project.budgetInManDays"> 
                            <label>Man-days</label> 
                            {{ project.budgetInManDays}} days 
                        </li>  
                        <li
                            *ngIf="project.description"
                            class="format-newlines">
                            <label>Description</label> {{ project.description }}
                        </li>
                        <li>
                            <label>Startdate</label>
                            {{ project.startDate | date }}
                        </li>
                        <li>
                            <label>Enddate</label>
                            <span *ngIf="project.endDate">{{
                                project.finished
                                    ? 'FINISHED - since: ' + (project.endDate | date)
                                    : checkDates()
                                        ? 'OVERDUE - since: ' + (project.endDate | date)
                                        : (project.endDate | date)
                                }}</span>
                            <span *ngIf="!project.endDate">No enddate</span>
                        </li>
                        <li *ngIf="project?.location && project?.location?.street">
                            <label>Location</label>
                            <app-pill-address [address]="project.location"></app-pill-address>
                        </li>
                        <li *ngIf="project?.partnerUuid">
                            <label>Company</label>
                            <app-pill-partner [partnerUuid]="project.partnerUuid"></app-pill-partner>
                        </li>
                        <li *ngIf="project?.customerUuid">
                            <label>Customer</label>
                            <app-pill-customer [customerUuid]="project.customerUuid"></app-pill-customer>
                        </li>
                        <li *ngIf="project?.contactPersonUuid">
                            <label>Contact</label>
                            <app-pill-contact [contactPersonUuid]="project.contactPersonUuid"></app-pill-contact>
                        </li>

                        <li>
                            <label>Tags</label>
                            <app-global-tags
                                [objectUuid]="project.uuid"
                                [type]="'project'"></app-global-tags>
                        </li>
                        <li *ngIf="user">
                            <label>Created by</label>
                            <app-pill-user [userId]="project.userId"></app-pill-user>
                        </li>
                    </ul>
                </section>
            </ng-template>
        </ng-container>

        <ng-container
            *ngIf="this.moduleService.checkModuleActive(eModule.YUKI)"
            [ngbNavItem]="'financial'"
            ngbNavItem>
            <a
                *appIsPermitted="{ module: 'FINANCIAL', operator: 'AND', actions: ['READ'] }"
                ngbNavLink
            >Financial</a
            >
            <ng-template ngbNavContent>
                <app-project-graph
                    [projectId]="projectUuid"
                    [project]="project"></app-project-graph>
            </ng-template>
        </ng-container>

        <ng-container ngbNavItem>
            <a
                *appIsPermitted="{ module: 'YUKI', operator: 'AND', actions: ['READ'] }"
                ngbNavLink
            >Linked Invoices</a
            >
            <ng-template ngbNavContent>
                <div class="d-flex m-r-2">
                    <div class="form-group m-r-2">
                        <label for="startDate">Start date</label>
                        <input
                            (change)="filterDate()"
                            [(ngModel)]="startDateFilter"
                            class="input--bg-transparant"
                            id="startDate"
                            type="date"/>
                    </div>

                    <div class="form-group">
                        <label for="endDate">End date</label>
                        <input
                            (change)="filterDate()"
                            [(ngModel)]="endDateFilter"
                            class="input--bg-transparant"
                            id="endDate"
                            type="date"/>
                    </div>
                </div>

                <details open>
                    <summary>
                        <span class="sectiontitle summary__title">Sale</span>
                        <i class="las la-angle-up summary__up-arrow"></i>
                    </summary>
                    <div>
                        <app-linked-invoices-to-object
                            [alreadyFetchedFiles]="true"
                            [folderId]="2"
                            [objectId]="project.uuid"
                            [showFiles]="false">
                        </app-linked-invoices-to-object>
                        <i class="las la-angle-down summary__down-arrow"></i>
                    </div>
                </details>

                <details open>
                    <summary>
                        <span class="sectiontitle summary__title">Purchase</span>
                        <i class="las la-angle-up summary__up-arrow"></i>
                    </summary>
                    <div>
                        <app-linked-invoices-to-object
                            [alreadyFetchedFiles]="true"
                            [folderId]="1"
                            [objectId]="project.uuid"
                            [showFiles]="false">
                        </app-linked-invoices-to-object>
                        <i class="las la-angle-down summary__down-arrow"></i>
                    </div>
                </details>
            </ng-template>
        </ng-container>

        <ng-container ngbNavItem>
            <a ngbNavLink>Files</a>
            <ng-template ngbNavContent>
                <app-linked-invoices-to-object
                    [alreadyFetchedFiles]="true"
                    [objectId]="project.uuid"
                    [showInvoices]="false">
                </app-linked-invoices-to-object>
            </ng-template>
        </ng-container>

        <ng-container
            *ngIf="this.moduleService.checkModuleActive(eModule.INVENTORY)"
            ngbNavItem>
            <a
                *appIsPermitted="{
                    module: 'INVENTORY',
                    operator: 'AND',
                    actions: ['READ']
                }"
                ngbNavLink
            >Products</a
            >
            <ng-template ngbNavContent>
                <app-global-link-autocomplete
                    [itemLinkType]="'product'"
                    [itemType]="'project'"
                    [item]="project"></app-global-link-autocomplete>
            </ng-template>
        </ng-container>
        <ng-container
            *ngIf="this.moduleService.checkModuleActive(eModule.EMPLOYEES)"
            ngbNavItem>
            <a
                *appIsPermitted="{
                    module: 'EMPLOYEES',
                    operator: 'AND',
                    actions: ['READ']
                }"
                ngbNavLink
            >Employees</a
            >
            <ng-template ngbNavContent>
                <app-default-linked-employees [projectId]="projectUuid"></app-default-linked-employees>
            </ng-template>
        </ng-container>
        <ng-container
            *ngIf="this.moduleService.checkModuleActive(eModule.TIMESHEETS)"
            ngbNavItem>
            <a
                *appIsPermitted="{
                    module: 'PROJECTS',
                    operator: 'AND',
                    actions: ['READ']
                }"
                ngbNavLink
            >Timesheets</a
            >
            <ng-template ngbNavContent>
                <div *ngIf="timesheets$ | async; else timesheetsLoading">
                    <div *ngIf="timesheetsDataSource.data.length > 0; else noTimesheets">
                        <div class="app__title">
                            <p class="sectiontitle sectiontitle--block">
                                {{ selectedDate | date: 'MMMM yyyy' }}
                                <span *ngIf="this.timesheetsDataSource">
                                    ({{ this.timesheetsDataSource.filteredData.length }}
                                    timesheets)
                                </span>
                            </p>

                            <span>
                                <button
                                    (click)="moveDate(-1)"
                                    class="button button--icon m-r-1">
                                    <i class="las la-arrow-left"></i>
                                </button>
                                <button
                                    (click)="moveDate(0)"
                                    class="button m-r-1">
                                    This month
                                </button>
                                <button
                                    (click)="moveDate(1)"
                                    class="button button--icon">
                                    <i class="las la-arrow-right"></i>
                                </button>
                            </span>
                        </div>
                        <div class="table__overflow">
                            <table
                                [dataSource]="timesheetsDataSource"
                                mat-table
                                matSort>
                                <ng-container matColumnDef="timesheet">
                                    <th
                                        *matHeaderCellDef
                                        mat-header-cell
                                        mat-sort-header>
                                        Timesheet
                                    </th>
                                    <td
                                        *matCellDef="let row"
                                        mat-cell>
                                        <app-pill-timesheet [timesheet]="row"></app-pill-timesheet>
                                    </td>
                                    <td
                                        *matFooterCellDef
                                        [hidden]="!this.showFooter"
                                        mat-footer-cell></td>
                                </ng-container>
                                <ng-container matColumnDef="timeslot">
                                    <th
                                        *matHeaderCellDef
                                        mat-header-cell>
                                        Timeslot
                                    </th>
                                    <td
                                        *matCellDef="let row"
                                        mat-cell>
                                        <div *ngFor="let timeslot of row.timeslots">
                                            <app-pill-project
                                                *ngIf="timeslot.project.uuid == project.uuid"
                                                [project]="timeslot.project"></app-pill-project>
                                        </div>
                                    </td>
                                    <td
                                        *matFooterCellDef
                                        [hidden]="!this.showFooter"
                                        mat-footer-cell></td>
                                </ng-container>

                                <ng-container matColumnDef="totalHours">
                                    <th
                                        *matHeaderCellDef
                                        mat-header-cell
                                        mat-sort-header>
                                        Hours project
                                    </th>
                                    <td
                                        *matCellDef="let row"
                                        mat-cell>
                                        <div *ngFor="let ts of row.timeslots">
                                            <div *ngIf="ts.project.uuid == project.uuid">
                                                <i class="las la-clock"></i>
                                                {{ ts.minutes | minutesToMinutesAndHours }}
                                            </div>
                                        </div>
                                    </td>
                                </ng-container>
                                <tr
                                    *matHeaderRowDef="displayedColumns"
                                    mat-header-row></tr>
                                <tr
                                    *matRowDef="let row; columns: displayedColumns"
                                    mat-row></tr>
                                <tr *matNoDataRow>
                                    <td colspan="5">No timesheets available for current month</td>
                                </tr>
                            </table>
                            <p class="m-t-4">
                                <i class="las la-clock m-r-2"></i>Hours worked for <strong>{{ project.title }}</strong>
                                this month:
                                <strong>{{ getTotalMinutes() | minutesToMinutesAndHours }}</strong>
                            </p>
                        </div>

                        <div class="divider"></div>

                        <div class="app__title">
                            <p class="sectiontitle">
                                {{ selectedDate | date: 'yyyy' }}
                            </p>
                            <span>
                                <button
                                    (click)="moveDate(-12)"
                                    class="button button--icon m-r-1">
                                    <i class="las la-arrow-left"></i>
                                </button>
                                <button
                                    (click)="moveDate(0)"
                                    class="button m-r-1">
                                    This year
                                </button>
                                <button
                                    (click)="moveDate(12)"
                                    class="button button--icon">
                                    <i class="las la-arrow-right"></i>
                                </button>
                            </span>
                        </div>
                        <div class="table__overflow">
                            <div class="dashboard__tile__content">
                                <div class="chart_holder">
                                    <canvas
                                        [chartType]="barChartType"
                                        [datasets]="barChartData"
                                        [labels]="barChartLabels"
                                        [legend]="barChartLegend"
                                        [options]="barChartOptions"
                                        [plugins]="barChartPlugins"
                                        baseChart
                                        height="300px">
                                    </canvas>
                                </div>
                            </div>
                        </div>
                        <div class="text-center">
                            <label
                            ><input
                                (change)="hideUpcomingChartMonths($event)"
                                [(ngModel)]="barChartCheckbox"
                                type="checkbox"/>
                                Hide upcoming months</label
                            >
                        </div>
                    </div>
                </div>

                <ng-template #noTimesheets>
                    <app-message-empty
                        [htmlSubtitle]="createHtmlSubtitleForNoTimesheets()"
                        [icon]="'../../assets/img/open-box.png'">
                        <a
                            (click)="navigateToTimesheets()"
                            *appIsPermitted="{
                                module: 'TIMESHEETS',
                                operator: 'AND',
                                actions: ['CREATE']
                            }"
                            class="button button--primary">
                            Navigate to timesheets
                        </a>
                    </app-message-empty>
                </ng-template>
                <ng-template #timesheetsLoading>
                    <app-loading-message></app-loading-message>
                </ng-template>
            </ng-template>
        </ng-container>
        <ng-container
            *ngIf="this.moduleService.checkModuleActive(eModule.RESUME)"
            ngbNavItem>
            <a ngbNavLink>Resumés</a>
            <ng-template ngbNavContent>
                <app-project-assigned-resume-list
                    [assignedResumes]="project.assignedResumes"></app-project-assigned-resume-list>
            </ng-template>
        </ng-container>
        <ng-container
            *ngIf="this.moduleService.checkModuleActive(eModule.DOCUMENTS)"
            ngbNavItem>
            <a ngbNavLink>Documents</a>
            <ng-template ngbNavContent>
                <div>
                    <app-documents-list
                        [objectId]="project.uuid"
                        [objectType]="'project'"></app-documents-list>
                </div>
            </ng-template>
        </ng-container>
        <ng-container
            *ngIf="this.moduleService.checkModuleActive(eModule.COMMENTS)"
            ngbNavItem>
            <a ngbNavLink>Comments</a>
            <ng-template ngbNavContent>
                <div>
                    <app-comments-overview [objectId]="project.uuid"></app-comments-overview>
                </div>
            </ng-template>
        </ng-container>
    </nav>

    <div class="modal-body">
        <ng-container *ngIf="!isLoading && projectUuid === project.uuid; else loading">
            <div [ngbNavOutlet]="nav"></div>
        </ng-container>
    </div>

    <div class="modal-footer">
        <div
            *appIsPermitted="{
                module: 'PROJECTS',
                operator: 'OR',
                actions: ['EDIT', 'DELETE']
            }"
            class="dropdown"
            ngbDropdown>
            <button
                class="button button--icon dropdown-toggle"
                id="dropdownMenuButton"
                ngbDropdownToggle>
                <span>Options</span>
            </button>

            <div
                *ngIf="!isLoading"
                aria-labelledby="dropdownMenuButton"
                class="dropdown-menu dropdown-menu-right"
                ngbDropdownMenu>
                <a
                    (click)="openProjectDetailModalComponent()"
                    *appIsPermitted="{
                        module: 'PROJECTS',
                        operator: 'AND',
                        actions: ['EDIT']
                    }"
                    class="dropdown-item">
                    Edit
                </a>
                <a
                    (click)="toggleFinished()"
                    *appIsPermitted="{
                        module: 'PROJECTS',
                        operator: 'AND',
                        actions: ['EDIT']
                    }"
                    class="dropdown-item">
                    <div *ngIf="project.finished; else elseDropDownBlock">Mark as Ongoing</div>
                    <ng-template #elseDropDownBlock>Mark as Finished</ng-template>
                </a>
                <a
                    (click)="openProjectDeleteModal(project)"
                    *appIsPermitted="{
                        module: 'PROJECTS',
                        operator: 'AND',
                        actions: ['DELETE']
                    }"
                    class="dropdown-item color--red">
                    Archive
                </a>
            </div>
        </div>
    </div>
</div>
<ng-template #loading>
    <app-loading-message></app-loading-message>
</ng-template>
