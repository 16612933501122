<div class="d-flex align-items-center">
    <div class="w-100">
        <app-searchbar
            [(value)]="filter"
            (valueChange)="applyFilter($event)"></app-searchbar>
    </div>
    <div class="d-flex nowrap">
        <app-global-export-csv
            [exporter]="exporter"
            [componentName]="this.constructor.name"></app-global-export-csv>
        <span
            ngbDropdown
            class="dropdown m-l-2">
            <button
                ngbDropdownToggle
                class="button button--select">
                {{ consumableLabel }}
            </button>
            <span
                ngbDropdownMenu
                class="dropdown-menu-right">
                <form>
                    <div>
                        <label ngbDropdownItem
                            ><input
                                type="radio"
                                class="rdbInvisible"
                                name="consumableRadio"
                                id="all"
                                checked
                                (change)="filterOnConsumable($event)" />All</label
                        >
                    </div>
                    <div>
                        <label ngbDropdownItem
                            ><input
                                type="radio"
                                class="rdbInvisible"
                                name="consumableRadio"
                                id="consumable"
                                (change)="filterOnConsumable($event)" />Consumable</label
                        >
                    </div>
                    <div>
                        <label ngbDropdownItem
                            ><input
                                type="radio"
                                class="rdbInvisible"
                                name="consumableRadio"
                                id="nonConsumable"
                                (change)="filterOnConsumable($event)" />Non-Consumable</label
                        >
                    </div>
                </form>
            </span>
        </span>
    </div>
    <div class="d-flex nowrap">
        <span
            ngbDropdown
            class="dropdown m-l-2">
            <button
                ngbDropdownToggle
                class="button button--select">
                Categories
            </button>
            <span
                ngbDropdownMenu
                class="dropdown-menu-right">
                <form>
                    <div>
                        <label ngbDropdownItem
                            ><input
                                id="0"
                                type="radio"
                                class="rdbInvisible"
                                name="categoryRadio"
                                [(ngModel)]="categoryCheckboxAll"
                                (change)="filterOnCategory($event, 0)"
                                checked="checked" />All</label
                        >
                    </div>
                    <div *ngFor="let category of categoryList; let i = index">
                        <label
                            ngbDropdownItem
                            class="categoryCheckboxLabel"
                            ><input
                                id="{{ i + 1 }}"
                                class="categoryCheckboxInput"
                                type="checkbox"
                                name="{{ i }}"
                                [(ngModel)]="categoryCheckboxBool[i]"
                                (change)="filterOnCategory($event, i + 1)" />{{ category }}</label
                        >
                    </div>
                </form>
            </span>
        </span>
    </div>
</div>

<div>
    <div class="m-t-4">
        <table
            mat-table
            [dataSource]="dataSource"
            matSort
            matSortActive="lastModified"
            matSortDirection="desc"
            matTableExporter
            #exporter="matTableExporter"
            matSortDisableClear
            [hidden]="!(dataSource && dataSource.data.length > 0)">
            <ng-container matColumnDef="updatedProductTitle">
                <th
                    mat-header-cell
                    *matHeaderCellDef>
                    Product
                </th>
                <td
                    mat-cell
                    *matCellDef="let row">
                    <app-pill-product
                        *ngIf="row.updatedProductUuid"
                        [productUuid]="row.updatedProductUuid"
                        [productTitle]="row.updatedProductTitle"></app-pill-product>
                </td>
            </ng-container>

            <ng-container matColumnDef="productAmountState">
                <th
                    mat-header-cell
                    *matHeaderCellDef>
                    Stock Changed
                </th>
                <td
                    mat-cell
                    *matCellDef="let row">
                    <span>{{ row.productAmountState.substr(0, 1) | uppercase }}{{ row.productAmountState.substr(1) | lowercase }} {{ row.amount }}</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="currentStock">
                <th
                    mat-header-cell
                    *matHeaderCellDef>
                    New amount
                </th>
                <td
                    mat-cell
                    *matCellDef="let row">
                    <span
                        >{{ row.currentStock }}
                        <app-pill-unit [productUuid]="row.updatedProductUuid"></app-pill-unit>
                    </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="updatedBy">
                <th
                    mat-header-cell
                    *matHeaderCellDef>
                    Updated by
                </th>
                <td
                    mat-cell
                    *matCellDef="let row">
                    <app-pill-user [userId]="row.updatedByUserUuid"></app-pill-user>
                </td>
            </ng-container>

            <ng-container matColumnDef="lastModified">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header>
                    Last updated
                </th>
                <td
                    mat-cell
                    *matCellDef="let row">
                    <span>{{ row.lastModified | amTimeAgo }}</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="productConsumable">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header>
                    Consumable
                </th>
                <td
                    mat-cell
                    *matCellDef="let row">
                    <span
                        class="badge badge--success"
                        *ngIf="row.productConsumable == 1"
                        >Yes</span
                    >
                    <span
                        class="badge badge--danger"
                        *ngIf="row.productConsumable == 0"
                        >No</span
                    >
                </td>
            </ng-container>

            <ng-container matColumnDef="productCategories">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header>
                    Categories
                </th>
                <td
                    mat-cell
                    *matCellDef="let row">
                    <span
                        *ngFor="let category of row.productCategories"
                        class="tag m-r-1"
                        >{{ category }}</span
                    >
                </td>
            </ng-container>
            <ng-container matColumnDef="employeeUuid">
                <th
                    mat-header-cell
                    *matHeaderCellDef>
                    Employee
                </th>
                <td
                    mat-cell
                    *matCellDef="let row">
                    <app-pill-employee [employeeId]="row.employeeUuid"></app-pill-employee>
                </td>
            </ng-container>

            <tr
                mat-header-row
                *matHeaderRowDef="displayedColumns"></tr>
            <tr
                mat-row
                *matRowDef="let row; columns: displayedColumns"></tr>

            <tr *matNoDataRow>
                <td colspan="8">No products matching the filter "{{ filter }}"</td>
            </tr>
        </table>
    </div>
</div>
