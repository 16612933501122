<div class="dashboard__tile__title">
    <span>Newest projects</span>
    <button
        (click)="goToProjects()"
        class="button button--icon button--small"
        type="button">
        <i class="uil uil-arrow-right"></i>
    </button>
</div>
<div class="dashboard__tile__content">
    <div class="table__overflow">
        <table
            [dataSource]="dataSource"
            [hidden]="!(this.dataSource?.data?.length > 0)"
            mat-table
            matSort
            matSortActive="startDate"
            matSortDirection="desc">
            <ng-container matColumnDef="title">
                <th
                    *matHeaderCellDef
                    mat-header-cell
                    mat-sort-header>
                    Title
                </th>
                <td
                    *matCellDef="let row"
                    mat-cell>
                    <app-pill-project [project]="row"></app-pill-project>
                </td>
            </ng-container>

            <ng-container matColumnDef="partnerName">
                <th
                    *matHeaderCellDef
                    mat-header-cell
                    mat-sort-header>
                    Company
                </th>
                <td
                    *matCellDef="let row"
                    mat-cell>
                    <app-pill-partner
                        *ngIf="row.partner || row.partnerUuid"
                        [partnerUuid]="row.partner ? row.partner.uuid : row.partnerUuid">
                    </app-pill-partner>
                </td>
            </ng-container>

            <ng-container matColumnDef="startDate">
                <th
                    *matHeaderCellDef
                    mat-header-cell
                    mat-sort-header>
                    Started
                </th>
                <td
                    *matCellDef="let row"
                    mat-cell>
                    <span>{{ row.startDate | amTimeAgo }}</span>
                </td>
            </ng-container>

            <tr
                *matHeaderRowDef="displayedColumns"
                mat-header-row></tr>
            <tr
                *matRowDef="let row; columns: displayedColumns"
                mat-row></tr>
        </table>
    </div>

    <div
        *ngIf="this.noProjects"
        class="dashboard__tile__content">
        <app-message-empty
            [icon]="'../../assets/img/open-box.png'"
            [title]="'There are no projects, start by adding one!'">
            <button
                (click)="openAddProjectModalComponent()"
                class="button button&#45;&#45;outline"
                type="button">
                Add new Project
            </button>
        </app-message-empty>
    </div>

    <div *ngIf="isLoading">
        <app-loading-message></app-loading-message>
    </div>
</div>
