<div
    [ngbPopover]="popContent"
    popoverTitle="{{ budget.budgetType }} of {{ budget.linkedEmployee.firstName }} {{ budget.linkedEmployee.lastName }}"
    triggers="mouseenter:mouseleave">
    <a
        (click)="openBudgetDetailModel()"
        [ngClass]="{ archived: budget.archived }"
        class="pill"
    >
        <i class="las la-wallet"></i>
        <i
            *ngIf="budget.recurring"
            class="las la-redo-alt"></i>
        {{ budget.budgetType }}
        <i
            *ngIf="attentionLevel === 2"
            class="las la-exclamation-circle"></i>
        <i
            *ngIf="attentionLevel === 1"
            class="las la-exclamation-triangle"></i>
    </a>
</div>


<ng-template #popContent>
    <table class="table-view">
        <tr>
            <th>Budgeted cost</th>
            <th *ngIf="budget.useExpendituresForFinancials">Actual cost</th>
            <th *ngIf="!budget.recurring">Expiration date</th>
        </tr>
        <tr>
            <td>
                {{ budget.totalSpend | currency: 'EUR' }}
            </td>
            <td *ngIf="budget.useExpendituresForFinancials">
                {{ budget.totalSpend - totalSpend | currency: 'EUR' }}
            </td>
            <td *ngIf="!budget.recurring">
                {{ budget.endDate | date }}
            </td>

        </tr>
    </table>
</ng-template>
