import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import Project from 'src/app/models/Project';
import { Timeslot } from 'src/app/models/Timeslot';

import { AddTimeslot, FetchAllProjectsProtected, UpdateTimeSlot } from '../timesheets.actions';
import { TimesheetState } from '../timesheets.state';

@Component({
    selector: 'app-edit-timeslot-modal',
    templateUrl: './edit-timeslot-modal.component.html',
    styleUrls: ['./edit-timeslot-modal.component.scss'],
})
export class EditTimeslotModalComponent implements OnInit, OnDestroy {
    @Select(TimesheetState.allProjectsProtected) allProjects$: Observable<Project[]>;
    @Input() employeeId: string;
    @Input() timesheetUuid: string;
    @Input() timeslot: Timeslot;
    projects: Project[];
    allProjects: Project[];
    linkedProjects: Project[];
    timeslotFormgroup: FormGroup;
    isLoading = true;
    oldPdf = '';
    loggedIn = false;
    showAllActivated = false;
    DESCRIPTION_MAX_LENGTH = 250;
    currentAmountOfDescriptionCharacters: number;
    descriptionIsTooLong = false;
    private subscriptions = new Subscription();

    constructor(private store: Store, private fb: FormBuilder, public activeModal: NgbActiveModal, private toastr: ToastrService, private logger: NGXLogger) {}

    ngOnInit(): void {
        this.loggedIn = localStorage.getItem('token') != null;

        this.store.dispatch(new FetchAllProjectsProtected(this.employeeId));
        this.subscriptions.add(
            this.allProjects$.subscribe((allProjects) => {
                if (!allProjects) {
                    this.logger.debug('Fetching linked projects for employee');
                    return;
                }
                this.linkedProjects = allProjects.filter((p) => p.linked);
                this.allProjects = allProjects;
                this.projects = this.showAllActivated ? this.allProjects : this.linkedProjects;
                this.isLoading = false;
            }),
        );
        this.timeslotFormgroup = this.fb.group({
            project: ['', [Validators.required]],
            description: [''],
            hours: [0, [Validators.required, Validators.min(0)]],
            minutes: [0, [Validators.required, Validators.max(60), Validators.min(0)]],
            pdfUrl: [''],
            showAllActivated: false,
        });

        if (this.timeslot != undefined) {
            this.oldPdf = this.timeslot.pdfUrl;
            const actualMinutes = this.timeslot.minutes % 60;
            const actualHours = (this.timeslot.minutes - actualMinutes) / 60;
            this.timeslotFormgroup.setValue({
                project: this.timeslot.project ? this.timeslot.project : '',
                description: this.timeslot.description,
                hours: actualHours,
                minutes: actualMinutes,
                pdfUrl: this.timeslot.pdfUrl,
            });
        }
    }

    addTimeslot() {
        const minutes = this.timeslotFormgroup.get('minutes').value + this.timeslotFormgroup.get('hours').value * 60;
        if (this.timeslot == undefined) {
            this.logger.debug('Attempt to add timeslot');
            this.subscriptions.add(
                this.store
                    .dispatch(
                        new AddTimeslot(
                            this.timesheetUuid,
                            this.timeslotFormgroup.get('project').value.uuid,
                            minutes,
                            this.timeslotFormgroup.get('pdfUrl').value,
                            this.timeslotFormgroup.get('description').value,
                        ),
                    )
                    .subscribe(
                        (res) => {
                            this.logger.debug('Succesfully added timeslot');
                            this.toastr.success('Timeslot added.');
                            this.activeModal.close();
                        },
                        (err) => {
                            this.logger.error('Failed to add timeslot');
                            this.toastr.error('Oops, something went wrong. Please try again later.');
                        },
                    ),
            );
        } else {
            const newTimeslot = JSON.parse(JSON.stringify(this.timeslot));
            newTimeslot.minutes = minutes;
            newTimeslot.description = this.timeslotFormgroup.get('description').value;
            newTimeslot.pdfUrl = this.timeslotFormgroup.get('pdfUrl').value;
            if (this.timeslotFormgroup.get('project').value.uuid !== newTimeslot.project?.uuid) {
                newTimeslot.project = this.projects.find((p) => p.uuid === this.timeslotFormgroup.get('project').value.uuid);
            }
            this.logger.debug('Attempt to update timeslot info');
            this.subscriptions.add(
                this.store.dispatch(new UpdateTimeSlot(this.timesheetUuid, newTimeslot)).subscribe(
                    (res) => {
                        this.logger.debug('Succesfully updated timeslot info');
                        this.toastr.success('Timeslot updated.');
                        this.activeModal.close();
                    },
                    (err) => {
                        this.logger.error('Failed to update timeslot info');
                        this.toastr.error('Oops, something went wrong. Please try again later.');
                    },
                ),
            );
        }
    }

    toggleShowAllProjects() {
        this.showAllActivated = !this.showAllActivated;
        this.projects = this.showAllActivated ? this.allProjects : this.linkedProjects;
    }

    onDescriptionKeyUp() {
        this.descriptionIsTooLong = false;
        this.currentAmountOfDescriptionCharacters = this.timeslotFormgroup.controls.description.value.length;
        if (this.currentAmountOfDescriptionCharacters > this.DESCRIPTION_MAX_LENGTH) {
            this.descriptionIsTooLong = true;
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
