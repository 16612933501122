import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { NgChartsModule } from 'ng2-charts';
import { MomentModule } from 'ngx-moment';

import { LeadsModule } from '../leads/leads.module';
import { SharedModule } from '../shared/shared.module';
import { DashboardBudgetsWidgetComponent } from './dashboard-budgets-widget/dashboard-budgets-widget.component';
import { DashboardCommentsWidgetComponent } from './dashboard-comments-widget/dashboard-comments-widget.component';
import { DashboardContactpersonsWidgetComponent } from './dashboard-contactpersons-widget/dashboard-contactpersons-widget.component';
import { DashboardCrmWarningsWidgetComponent } from './dashboard-crm-warnings-widget/dashboard-crm-warnings-widget.component';
import { DashboardCustomersWidgetComponent } from './dashboard-customers-widget/dashboard-customers-widget.component';
import { DashboardEmployeeAnniversaryWidgetComponent } from './dashboard-employee-anniversary-widget/dashboard-employee-anniversary-widget.component';
import { DashboardEmployeeWorkAnniversaryWidgetComponent } from './dashboard-employee-work-anniversary-widget/dashboard-employee-work-anniversary-widget.component';
import { DashboardInventoryUpdateHistoryWidgetComponent } from './dashboard-inventory-update-history-widget/dashboard-inventory-update-history-widget.component';
import { DashboardLeadsWidgetComponent } from './dashboard-leads-widget/dashboard-leads-widget.component';
import { DashboardLowStockProductWidgetComponent } from './dashboard-low-stock-product-widget/dashboard-low-stock-product-widget.component';
import { DashboardProjectsWidgetComponent } from './dashboard-projects-widget/dashboard-projects-widget.component';

@NgModule({
    declarations: [
        DashboardContactpersonsWidgetComponent,
        DashboardProjectsWidgetComponent,
        DashboardLeadsWidgetComponent,
        DashboardLowStockProductWidgetComponent,
        DashboardEmployeeAnniversaryWidgetComponent,
        DashboardEmployeeWorkAnniversaryWidgetComponent,
        DashboardCommentsWidgetComponent,
        DashboardInventoryUpdateHistoryWidgetComponent,
        DashboardCustomersWidgetComponent,
        DashboardCrmWarningsWidgetComponent,
        DashboardBudgetsWidgetComponent,
    ],
    imports: [CommonModule, LeadsModule, SharedModule, NgChartsModule, CdkTableModule, MatTableModule, MatSortModule, MomentModule],
    exports: [
        DashboardContactpersonsWidgetComponent,
        DashboardProjectsWidgetComponent,
        DashboardLeadsWidgetComponent,
        DashboardLowStockProductWidgetComponent,
        DashboardEmployeeAnniversaryWidgetComponent,
        DashboardEmployeeWorkAnniversaryWidgetComponent,
        DashboardCommentsWidgetComponent,
        DashboardInventoryUpdateHistoryWidgetComponent,
        DashboardCustomersWidgetComponent,
        DashboardCrmWarningsWidgetComponent,
        DashboardBudgetsWidgetComponent,
    ],
})
export class WidgetsModule {}
