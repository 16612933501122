<app-app-holder>
    <app-navbar></app-navbar>

    <app-sidebar>
        <app-partners-subnav></app-partners-subnav>
    </app-sidebar>

    <app-content>
        <p>This is the company cvs component!</p>
    </app-content>
</app-app-holder>
