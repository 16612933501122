import { Component, OnInit, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'description-input',
    templateUrl: './description.component.html',
    styleUrls: ['./description.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DescriptionComponent),
            multi: true,
        },
    ],
})
export class DescriptionComponent implements OnInit, ControlValueAccessor {
    DESCRIPTION_MAX_LENGTH = 1000;
    currentAmountOfDescriptionCharacters: number;
    descriptionIsTooLong = false;
    inputIsWrong = false;

    value = '';

    onChange: any = () => {};
    onTouch: any = () => {};

    set description(description: string) {
        this.value = description;
        this.onChange(description);
        this.onTouch(description);
    }

    get description() {
        return this.value;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    writeValue(obj: any): void {
        this.description = obj;
    }

    setDisabledState?(isDisabled: boolean): void {}

    ngOnInit(): void {
        this.calculateDescriptionLength();
    }

    onDescriptionKeyUp() {
        this.calculateDescriptionLength();
    }

    hideErrorMessage() {
        this.inputIsWrong = false;
    }

    private calculateDescriptionLength(): void {
        this.currentAmountOfDescriptionCharacters = this.value?.length ?? 0;
        this.descriptionIsTooLong = this.currentAmountOfDescriptionCharacters > this.DESCRIPTION_MAX_LENGTH;
    }
}
