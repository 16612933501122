<app-searchbar (valueChange)="search($event)"></app-searchbar>

<div class="m-t-4">
    <app-no-search-results
        [query]="searchText"
        [noSearchResults]="noSearchResults"></app-no-search-results>

    <div
        class="list__item list__item--small pointer"
        *ngFor="let contactPerson of showContactPersons | orderBy: ['partnerName', 'firstName']:false:true"
        [class.selected]="inSelectedPersons(contactPerson)"
        (click)="onSelect(contactPerson)">
        <div class="list__item__select">
            <input
                type="checkbox"
                [checked]="inSelectedPersons(contactPerson)" />
        </div>
        <div class="list__item__content">
            <strong>{{ contactPerson.firstName }} {{ contactPerson.lastName }}</strong>
            <small class="no-wrap">{{ contactPerson.partnerName }}</small>
        </div>
        <div class="list__item__extra">
            <ng-template #tipTags
                ><span *ngFor="let tag of contactPerson.tags">{{ tag.title }} </span></ng-template
            >
            <a
                *ngIf="contactPerson.tags.length"
                class="m-l-2 button button--small button--icon button--outline"
                placement="top"
                [ngbTooltip]="tipTags">
                <i class="uil uil-tag"></i>
            </a>

            <ng-template #tipEmail>{{ contactPerson.contactInformation.email }}</ng-template>
            <a
                href="mailto:"
                class="m-l-2 button button--small button--icon button--outline"
                placement="top"
                [ngbTooltip]="tipEmail">
                <i class="uil uil-envelope"></i>
            </a>

            <ng-template #tipPhone>{{ contactPerson.contactInformation.phoneNumber }}</ng-template>
            <a
                *ngIf="contactPerson.contactInformation.phoneNumber"
                href="tel:"
                class="m-l-2 button button--small button--icon button--outline"
                placement="top"
                [ngbTooltip]="tipPhone">
                <i class="uil uil-phone"></i>
            </a>
        </div>
    </div>
</div>
