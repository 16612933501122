import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { NgbDropdownModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgChartsModule } from 'ng2-charts';
import { TagInputModule } from 'ngx-chips';
import { OrderModule } from 'ngx-order-pipe';
import { NgPipesModule } from 'ngx-pipes';

import { SharedModule } from '../shared/shared.module';
import { LeadDeleteModalComponent } from './lead-delete-modal/lead-delete-modal.component';
import { LeadDetailModalComponent } from './lead-detail-modal/lead-detail-modal.component';
import { LeadDetailComponent } from './lead-detail/lead-detail.component';
import { LeadToDealModalComponent } from './lead-to-deal-modal/lead-to-deal-modal.component';
import { LeadsListComponent } from './leads-list/leads-list.component';
import { LeadsOverviewComponent } from './leads-overview/leads-overview.component';
import { LeadsSidebarComponent } from './leads-sidebar/leads-sidebar.component';
import { routing } from './leads.routing';

@NgModule({
    declarations: [
        LeadsOverviewComponent,
        LeadDetailComponent,
        LeadDetailModalComponent,
        LeadDeleteModalComponent,
        LeadsSidebarComponent,
        LeadsListComponent,
        LeadToDealModalComponent,
    ],
    imports: [
        CommonModule,
        SharedModule,
        NgChartsModule,
        routing,
        NgbModule,
        NgbDropdownModule,
        NgPipesModule,
        OrderModule,
        TagInputModule,
        NgPipesModule,
        CdkTableModule,
        MatTableModule,
        MatSortModule,
    ],
})
export class LeadsModule {}
