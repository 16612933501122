export class DAYS {
    static readonly MONDAY = new DAYS('MON', 'monday');
    static readonly TUESDAY = new DAYS('TUE', 'tuesday');
    static readonly WEDNESDAY = new DAYS('WED', 'wednesday');
    static readonly THURSDAY = new DAYS('THU', 'thursday');
    static readonly FRIDAY = new DAYS('FRI', 'friday');
    static readonly SATURDAY = new DAYS('SAT', 'saturday');
    static readonly SUNDAY = new DAYS('SUN', 'sunday');

    // private to disallow creating other instances of this type
    private constructor(public readonly short: string, public readonly long: string) {}

    static findDay(value: string): DAYS {
        if (value.includes(DAYS.MONDAY.short) || value.includes(DAYS.MONDAY.long)) {
            return DAYS.MONDAY;
        } else if (value.includes(DAYS.TUESDAY.short) || value.includes(DAYS.TUESDAY.long)) {
            return DAYS.TUESDAY;
        } else if (value.includes(DAYS.WEDNESDAY.short) || value.includes(DAYS.WEDNESDAY.long)) {
            return DAYS.WEDNESDAY;
        } else if (value.includes(DAYS.THURSDAY.short) || value.includes(DAYS.THURSDAY.long)) {
            return DAYS.THURSDAY;
        } else if (value.includes(DAYS.FRIDAY.short) || value.includes(DAYS.FRIDAY.long)) {
            return DAYS.FRIDAY;
        } else if (value.includes(DAYS.SATURDAY.short) || value.includes(DAYS.SATURDAY.long)) {
            return DAYS.SATURDAY;
        } else if (value.includes(DAYS.SUNDAY.short) || value.includes(DAYS.SUNDAY.long)) {
            return DAYS.SUNDAY;
        }
        return null;
    }
}
