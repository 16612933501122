import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

import { AuthenticationService } from '../../../authentication/authentication.service';
import { Timesheet } from '../../../models/Timesheet';

@Injectable({
    providedIn: 'root',
})
export class TimesheetsService {
    httpOptions = {};

    constructor(private httpClient: HttpClient, private authenticationService: AuthenticationService) {
        this.httpOptions = authenticationService.getHeaders();
    }

    getTimesheet(uuid: string) {
        return this.httpClient.get<Timesheet>(`${environment.baseUrl}/timesheets/${uuid}`, this.httpOptions);
    }
}
