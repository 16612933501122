<div class="modal-header">
    <h2
        class="modal-title"
        id="exampleModalLabel">
        Update holiday days
    </h2>
    <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <app-date-navigation
        [dateNavigationOptions]="dateNavigationOptions"
        (selectedDateUpdated)="filterTable()"></app-date-navigation>

    <table
        [dataSource]="dataSource"
        class="table-view m-t-2"
        mat-table>
        <ng-container matColumnDef="date">
            <th
                *matHeaderCellDef
                mat-header-cell>
                Date
            </th>
            <td
                *matCellDef="let holidayDay"
                mat-cell>
                {{ holidayDay.localDate | date }}
            </td>
        </ng-container>
        <ng-container matColumnDef="description">
            <th
                *matHeaderCellDef
                mat-header-cell>
                Description
            </th>
            <td
                *matCellDef="let holidayDay"
                mat-cell>
                {{ holidayDay.description }}
            </td>
        </ng-container>
        <ng-container matColumnDef="delete">
            <th
                *matHeaderCellDef
                class="td-small"
                mat-header-cell></th>
            <td
                (click)="deleteHoliday(i)"
                *matCellDef="let date; let i = index"
                class="td-icon"
                mat-cell>
                <button class="button button--icon">
                    <i class="las la-trash color--red"></i>
                </button>
            </td>
        </ng-container>

        <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns"></tr>
        <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <form
        (ngSubmit)="addHoliday()"
        class="m-t-2"
        [formGroup]="holidayDaysForm">
        <div class="sectiontitle">New holiday</div>
        <div class="row">
            <div class="col">
                <div class="form-group required">
                    <label for="date">Date</label>
                    <input
                        type="date"
                        id="date"
                        formControlName="date" />
                </div>
            </div>
        </div>
        <div class="row m-t-2">
            <div class="col">
                <div class="form-group required">
                    <label for="description">Description</label>
                    <input
                        type="text"
                        id="description"
                        formControlName="description" />
                </div>
            </div>
        </div>
        <button
            class="button button--outline button--icon m-t-2"
            [disabled]="holidayDaysForm.invalid">
            <i class="las la-plus"></i>
        </button>
    </form>
</div>

<div class="modal-footer">
    <input
        class="button button--primary"
        type="submit"
        value="Update"
        (click)="submit()" />
    <button
        class="button button--outline"
        (click)="activeModal.dismiss()">
        Cancel
    </button>
</div>
