import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { parseISO } from 'date-fns';
import { Observable, map } from 'rxjs';

import { environment } from '../../environments/environment';
import { AuthenticationService } from '../authentication/authentication.service';
import { Notification } from '../models/Notification';

@Injectable({
    providedIn: 'root',
})
export class NotificationsService {
    httpOptions = {};

    constructor(private httpClient: HttpClient, private authenticationService: AuthenticationService) {
        this.httpOptions = authenticationService.getHeaders();
    }

    fetchNotifications(): Observable<Notification[]> {
        return this.httpClient.get<Notification[]>(`${environment.baseUrl}/notifications`, this.httpOptions).pipe(
            map((notifications) => {
                for (let i = 0; i < notifications.length; i++) {
                    notifications[i] = this.parseTime(notifications[i]);
                }
                return notifications;
            }),
        );
    }

    fetchArchivedNotifications() {
        return this.httpClient.get<Notification[]>(`${environment.baseUrl}/notifications/archived`, this.httpOptions).pipe(
            map((notifications) => {
                for (let i = 0; i < notifications.length; i++) {
                    notifications[i] = this.parseTime(notifications[i]);
                }
                return notifications;
            }),
        );
    }

    createNotification(notification: NotificationDTO): Observable<void> {
        return this.httpClient.post<void>(`${environment.baseUrl}/notifications`, notification, this.httpOptions);
    }

    markNotificationAsRead(notificationId: string) {
        return this.httpClient.patch<void>(`${environment.baseUrl}/notifications/set-read/${notificationId}`, {}, this.httpOptions);
    }

    archiveNotification(notificationId: string): Observable<void> {
        return this.httpClient.patch<void>(`${environment.baseUrl}/notifications/archive/${notificationId}`, {}, this.httpOptions);
    }

    archiveAllNotifications() {
        return this.httpClient.patch<void>(`${environment.baseUrl}/notifications/archive/`, {}, this.httpOptions);
    }

    deleteAllArchivedNotifications() {
        return this.httpClient.delete<void>(`${environment.baseUrl}/notifications/archived/`, this.httpOptions);
    }

    createNotificationForComments(commentObjectId: string, message: string) {
        const body = {
            commentObjectId: commentObjectId,
            message: message,
        };
        return this.httpClient.post<void>(`${environment.baseUrl}/notifications/for-comments`, body, this.httpOptions);
    }

    private parseTime(notification: Notification): Notification {
        notification.created = parseISO(notification.created.toString());
        return notification;
    }
}

export interface NotificationDTO {
    message: string;
    route: string;
}
