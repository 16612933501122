import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';

import { FetchEmployees } from '../../employees/employees.actions';
import { EmployeeState } from '../../employees/employees.state';
import { Employee } from '../../models/Employee';
import { ModuleEnum } from '../../models/ModuleEnum';
import { Product } from '../../models/Product';
import { Unit } from '../../models/Unit';
import { ModuleService } from '../../services/module.service';
import { FetchUnits } from '../../units/unit.actions';
import { UnitState } from '../../units/unit.state';
import { UpdateProduct } from '../product.actions';

@Component({
    selector: 'app-product-update-stock-modal',
    templateUrl: './product-update-stock-modal.component.html',
    styleUrls: ['./product-update-stock-modal.component.scss'],
})
export class ProductUpdateStockModalComponent implements OnInit {
    private subscriptions = new Subscription();

    @Select(EmployeeState.Employees) allEmployees$: Observable<Employee[]>;
    @Select(UnitState.Units) allUnits$: Observable<Unit[]>;
    @Input() product: Product;

    newProduct: Product;
    productFormGroup: FormGroup;
    errorMessage: string;
    loading = false;
    updateProductWentWrong = false;
    DESCRIPTION_MAX_LENGTH = 1000;
    currentAmountOfDescriptionCharacters: number;
    descriptionIsTooLong = false;
    allUnits: Unit[];
    keyword = 'title';
    unit: Unit;
    inputIsWrong = false;
    allEmployeesList: Employee[];
    eModule = ModuleEnum;

    @ViewChild('focussed', { static: false })
    set input(element: ElementRef<HTMLInputElement>) {
        if (element) {
            element.nativeElement.focus();
        }
    }

    constructor(
        public activeModal: NgbActiveModal,
        private formBuilder: FormBuilder,
        private store: Store,
        private toastr: ToastrService,
        private logger: NGXLogger,
        public moduleService: ModuleService,
    ) {}

    ngOnInit(): void {
        this.store.dispatch(new FetchUnits());
        this.store.dispatch(new FetchEmployees());
        this.subscriptions.add(
            this.allUnits$.subscribe((units) => {
                if (units) {
                    this.allUnits = units;
                    this.unit = units.find((u) => u.uuid === this.product.unit?.uuid);
                }
            }),
        );
        this.subscriptions.add(
            this.allEmployees$.subscribe((emp) => {
                this.allEmployeesList = emp;
            }),
        );
        this.currentAmountOfDescriptionCharacters = this.product.description?.length;
        this.productFormGroup = this.formBuilder.group({
            title: [this.product.title, [Validators.required]],
            reasonInfo: ['', [Validators.required]],
            inStock: [this.product.inStock ? this.product.inStock : '', [Validators.min(0)]],
            employees: [''],
        });
    }

    onDescriptionKeyUp() {
        this.descriptionIsTooLong = false;
        this.currentAmountOfDescriptionCharacters = this.productFormGroup.controls.reasonInfo.value.length;
        if (this.currentAmountOfDescriptionCharacters > this.DESCRIPTION_MAX_LENGTH) {
            this.descriptionIsTooLong = true;
        }
    }

    updateProduct() {
        this.loading = true;
        if (this.productFormGroup.controls.inStock.value === this.product.inStock) {
            this.updateProductWentWrong = true;
            this.errorMessage = 'Stock value is not changed';
            this.loading = false;
            return;
        }
        if (this.descriptionIsTooLong) {
            return;
        }
        if (!this.productFormGroup.valid) {
            this.updateProductWentWrong = true;
            this.errorMessage = 'Not all required fields are filled';
            this.inputIsWrong = true;
            this.loading = false;
            return;
        }

        this.newProduct = this.productFormGroup.value;
        this.newProduct.uuid = this.product.uuid;
        this.newProduct.employeeUuid = this.productFormGroup.get('employees').value.uuid;
        this.newProduct.employees = [];
        this.logger.debug('Attempt to update product');
        this.subscriptions.add(
            this.store.dispatch(new UpdateProduct(this.newProduct)).subscribe(
                () => {
                    this.logger.debug('Succesfully updated product');
                    this.toastr.success('Product updated succesfully');
                    this.activeModal.close();
                },
                (error) => {
                    this.logger.error('Failed to update product');
                    this.errorMessage = error?.error?.message;
                    this.loading = false;
                    this.updateProductWentWrong = true;
                },
            ),
        );
    }

    hideErrorMessage() {
        this.inputIsWrong = false;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
