<a
    class="pill pill--customer"
    *ngIf="!isLoading"
    (click)="openCustomerDetail()">
    <img src="https://eu.ui-avatars.com/api/?background=random&bold=true&name={{ customer.firstName + ' ' + customer.lastName }}" />
    {{ customer.firstName + ' ' + customer.lastName }}
</a>
<a
    class="pill pill--customer"
    *ngIf="isLoading">
    <app-loading-message></app-loading-message>
</a>
