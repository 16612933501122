import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import FileSystemEntity from 'src/app/models/FileSystemEntity';

@Component({
    selector: 'app-pill-document',
    templateUrl: './pill-document.component.html',
    styleUrls: ['./pill-document.component.scss'],
})
export class PillDocumentComponent implements OnInit {
    @Input() document: FileSystemEntity;

    constructor(private router: Router, private modalService: NgbModal) {}

    ngOnInit(): void {}

    openDocument() {
        this.modalService.dismissAll();
        this.router.navigate(['/documents', 'detail', this.document.uuid]);
    }
}
