import { Component, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NGXLogger } from 'ngx-logger';
import { Education } from 'src/app/models/resume/Education';

import { EducationDeleteModalComponent } from '../education-delete-modal/education-delete-modal.component';
import { EducationDetailModalComponent } from '../education-detail-modal/education-detail-modal.component';

@Component({
    selector: 'app-resume-education-list',
    templateUrl: './resume-education-list.component.html',
    styleUrls: ['./resume-education-list.component.scss'],
})
export class ResumeEducationListComponent {
    @Input() educations: Education[];
    @Input() showActions = true;
    @Input() resumeId: string;

    constructor(private modalService: NgbModal, private logger: NGXLogger) {}

    openEducationAddModal() {
        this.logger.debug('Opening educationDetailModal');
        const modalRef = this.modalService.open(EducationDetailModalComponent, { animation: false });
        modalRef.componentInstance.resumeUuid = this.resumeId;
    }

    openUpdateEducationModal(education: Education) {
        this.logger.debug('Opening educationDetailModal');
        const modalRef = this.modalService.open(EducationDetailModalComponent, { animation: false });

        modalRef.componentInstance.education = education;
        modalRef.componentInstance.resumeUuid = this.resumeId;
    }

    openDeleteEducationModal(education: Education) {
        this.logger.debug('Opening educationDeleteModal');
        const modalRef = this.modalService.open(EducationDeleteModalComponent, {
            windowClass: 'modal-prompt',
            animation: false,
        });
        modalRef.componentInstance.education = education;
    }
}
