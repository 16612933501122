<div class="module__logo">
    <img src="../../../assets/img/jiraTempoLogo.png" />
</div>

<div>
    <form [formGroup]="form" (ngSubmit)="onSubmit(form)">
        <div class="form-group">
            <label for="username">Jira username</label>
            <div class="row" style="margin-bottom: 10px">
                <div class="col-md-9">
                    <div class="form-group">
                        <input 
                            type="text" 
                            id="username"
                            formControlName="username" 
                            placeholder="Jira username"/>
                    </div>
                </div>
                <div class="col-md-12">
                    <small class="form-error" *ngIf="!form.get('username').valid && form.get('username').touched">
                        The username is not valid
                    </small>
                </div>
                <div class="col-md-12" style="margin-bottom: 10px">
                    <div class="form-group">
                        <small class="help-text">You can create a Jira account on the Jira website</small>
                    </div>
                </div>
            </div>
            <label for="jiraKey">Jira API key</label>
            <div class="row" style="margin-bottom: 10px">
                <div class="col-md-9">
                    <div class="form-group">
                        <password-input 
                            type="text" 
                            id="apiKey" 
                            formControlName="apiKey" 
                            placeholder="Jira API key" 
                            [showLabel]="false">
                        </password-input>
                    </div>
                </div>
                <div class="col-md-12">
                    <small class="form-error" *ngIf="!form.get('apiKey').valid && form.get('apiKey').touched">
                        The key is not valid</small>
                </div>
                <div class="col-md-12" style="margin-bottom: 10px">
                    <div class="form-group">
                        <small class="help-text">You can create your Jira API key on the Jira website</small>
                    </div>
                </div>
            </div>
            <label for="tempoKey">Tempo Bearer token</label>
            <div class="row" style="margin-bottom: 10px">
                <div class="col-md-9">
                    <div class="form-group">
                        <password-input 
                            type="text" 
                            id="tempoKey"
                            formControlName="tempoKey" 
                            placeholder="Tempo Bearer token" 
                            [showLabel]="false">
                        </password-input>
                    </div>
                </div>
                <div class="col-md-3">
                    <input type="submit" value="Save credentials" [disabled]="!form.valid"
                        class="button button--primary w-100" />
                </div>
                <div class="col-md-12">
                    <small class="form-error" *ngIf="!form.get('tempoKey').valid && form.get('tempoKey').touched">
                        The token is not valid</small>
                </div>
                <div class="col-md-12" style="margin-bottom: 10px">
                    <div class="form-group">
                        <small class="help-text">You can create your Tempo Bearer token on the Jira website</small>
                    </div>
                </div>
            </div>
            <div class="col-md-12" style="margin-bottom: 10px">
                <button type="button" (click)="removeKey()" [disabled]="form.get('apiKey').value === '' || form.get('tempoKey').value === ''"
                    class="button button--danger w-100">
                    Remove Jira and Tempo credentials
                </button>
            </div>
            <small *ngIf="!form.get('tempoKey').touched"></small>
        </div>
    </form>
</div>