<app-app-holder>
    <app-navbar></app-navbar>

    <app-sidebar>
        <app-partners-subnav></app-partners-subnav>
    </app-sidebar>

    <app-content>
        <div *ngIf="contactPersons; else loading">
            <div *ngIf="contactPersons?.length !== 0">
                <div class="app__title">
                    <h1>Contacts</h1>
                    <span>
                        <a
                            class="button button--primary"
                            (click)="openAddContactPersonsModal()"
                            >New contact</a
                        >
                    </span>
                </div>

                <div>
                    <app-partner-contactperson-list
                        *ngIf="contactPersons; else loading"
                        [contactPersons]="contactPersons"
                        [showContactPersons]="contactPersons"></app-partner-contactperson-list>
                </div>
            </div>
        </div>

        <app-comments-overview [objectId]="partnerUuid"></app-comments-overview>
    </app-content>
</app-app-holder>

<ng-template #loading>
    <app-loading-message></app-loading-message>
</ng-template>
