import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';

import { Partner } from '../../models/Partner';
import { ContactMapper } from '../../models/yuki/ContactMapper';
import { PartnerAddModalComponent } from '../../partners/partner-add-modal/partner-add-modal.component';
import { FetchPartners } from '../../partners/partners.actions';
import { PartnerState } from '../../partners/partners.state';
import { ConfirmationModalComponent } from '../../shared/confirmation-modal/confirmation-modal.component';
import { AddMappedContact, DeleteMappedContact, GetMappedContacts, MapContactsForAllExistingDocuments } from '../yuki.actions';
import { YukiState } from '../yuki.state';

@Component({
    selector: 'app-mapped-yuki-contacts',
    templateUrl: './mapped-yuki-contacts.component.html',
    styleUrls: ['./mapped-yuki-contacts.component.scss'],
})
export class MappedYukiContactsComponent implements OnInit, OnDestroy {
    @Select(YukiState.mappedContacts) mappedContacts$: Observable<ContactMapper[]>;
    mappedContacts: ContactMapper[];
    mappedContactsForm: FormGroup;

    @Select(PartnerState.getPartners) partners$: Observable<Partner[]>;
    partners: Partner[];

    private subscriptions = new Subscription();
    constructor(private store: Store, private logger: NGXLogger, private modalService: NgbModal, private fb: FormBuilder, private toastr: ToastrService) {}
    ngOnInit(): void {
        this.fetchData();
        this.createMappedContactsForm();
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    openCreateContactModal() {
        this.modalService.open(PartnerAddModalComponent, { windowClass: 'modal-pane', animation: false });
    }

    addNewMapping() {
        this.subscriptions.add(
            this.store.dispatch(new AddMappedContact(this.mappedContactsForm.value.yukiContact, this.mappedContactsForm.value.resumoContact.uuid)).subscribe({
                next: () => {
                    this.toastr.success('Mapped contact successfully saved.');
                    // form reset is not working: dropdown stays empty... Solution: rebuilding form
                    this.createMappedContactsForm();
                },
                error: () => {
                    this.toastr.error('Oops, something went wrong. Please try again later...');
                    this.logger.debug('Error while saving new mapped contact');
                },
            }),
        );
    }

    deleteMappedContact(contactMapper: ContactMapper) {
        const modalRef = this.modalService.open(ConfirmationModalComponent, { windowClass: 'modal-prompt', animation: false });
        modalRef.componentInstance.type = 'Delete';
        modalRef.componentInstance.title = 'Delete mapping';
        modalRef.componentInstance.htmlMessage = `<p>Are you sure you want to delete mapping <strong>${contactMapper.resumoContact.title}</strong> to <strong>${contactMapper.yukiContact}</strong></p>`;
        this.subscriptions.add(
            modalRef.componentInstance.closeEvent.subscribe((value) => {
                if (value) this.store.dispatch(new DeleteMappedContact(contactMapper.id));
            }),
        );
    }

    refreshMappings() {
        this.subscriptions.add(
            this.store.dispatch(new MapContactsForAllExistingDocuments()).subscribe({
                next: () => {
                    this.toastr.success('Successfully mapped contacts for all documents');
                },
            }),
        );
    }

    private fetchData() {
        this.subscriptions.add(
            this.mappedContacts$.subscribe((mappedContacts) => {
                if (mappedContacts === null) {
                    this.logger.debug('Fetching mapped contacts');
                    this.store.dispatch(GetMappedContacts);
                    return;
                }
                this.mappedContacts = mappedContacts;
            }),
        );

        this.subscriptions.add(
            this.partners$.subscribe((partners) => {
                if (partners === null) {
                    this.logger.debug('Fetching partners');
                    this.store.dispatch(new FetchPartners());
                    return;
                }
                this.partners = partners;
            }),
        );
    }

    private createMappedContactsForm() {
        this.mappedContactsForm = this.fb.group({
            yukiContact: ['', Validators.required],
            resumoContact: ['', Validators.required],
        });
    }
}
