import Budget from './Budget';

export default class EmployeeWithBudgets {
    uuid: string;
    lastName: string;
    firstName: string;
    budgets: Budget[];

    static calculateBudgetCostForEmployee(employee: EmployeeWithBudgets, selectedDate: Date, daysPerMonth: number, budget?: Budget): number {
        let total = 0;

        if (budget) {
            total = Budget.calculateBudgetCost(budget, selectedDate, daysPerMonth, total);
        } else {
            employee.budgets.forEach((budget) => {
                total = Budget.calculateBudgetCost(budget, selectedDate, daysPerMonth, total);
            });
        }
        return total;
    }

    static calculateActualCostForEmployee(employeeWithBudgets: EmployeeWithBudgets, selectedDate: Date, daysPerMonth: number, budget?: Budget): number {
        let total = 0;
        if (budget) {
            total = Budget.calculateActualCost(budget, selectedDate, daysPerMonth, total);
        } else {
            employeeWithBudgets.budgets.forEach((budget) => {
                total = Budget.calculateActualCost(budget, selectedDate, daysPerMonth, total);
            });
        }
        return total;
    }
}
