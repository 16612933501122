import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subscription } from 'rxjs';
import Lead from 'src/app/models/Lead';

import { GetArchivedLeads, fetchLeads } from '../lead.actions';
import { LeadState } from '../lead.state';

@Component({
    selector: 'app-leads-overview',
    templateUrl: './leads-overview.component.html',
    styleUrls: ['./leads-overview.component.scss'],
})
export class LeadsOverviewComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Select(LeadState.getLeads) allLeads$: Observable<Lead[]>;
    @Select(LeadState.archivedLeads) archivedLeads$: Observable<Lead[]>;

    channelFilter = '';

    constructor(private store: Store, private titleService: Title, private logger: NGXLogger) {
        this.titleService.setTitle('Leads overview');
    }

    ngOnInit(): void {
        this.logger.debug('Fetching (archived) leads');
        this.store.dispatch(new GetArchivedLeads());
        this.store.dispatch(new fetchLeads());
    }

    changeChannelFilter(channel) {
        this.channelFilter = channel;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
