<form
    [formGroup]="addRuleFormGroup"
    (ngSubmit)="submit()"
    class="modal-wrapper">
    <div class="modal-header">
        <h2
            class="modal-title"
            id="exampleModalLabel">
            Add new reminder
        </h2>
        <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="activeModal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <div class="row">
            <div class="col">
                <div class="form-group m-b-2">
                    <label for="daysBefore">Days before the end of the month</label>
                    <input
                        type="number"
                        id="daysBefore"
                        formControlName="daysBefore"
                        placeholder="Amount of days before the end of the month" />
                </div>
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <input
            [ngClass]="!addRuleFormGroup.valid ? 'button button--disabled w-100' : 'button button--primary w-100'"
            type="submit"
            value="Save"
            [disabled]="!addRuleFormGroup.valid" />
    </div>
</form>
