<div class="app__title">
    <h1>Overhead</h1>
</div>

<h2 class="modal-title m-b-4">Total for all overhead categories</h2>

<div class="dashboard__tile dashboard__tile--large dashboard__tile--no-margin">
    <div
        *ngIf="overheadCategoryWithFinancialData !== null; else loading"
        class="graph__container">
        <div class="dashboard__tile__title graph__title">
            <span>{{ overheadCategoryWithFinancialData?.categoryName }}</span>

            <app-date-navigation
                (selectedDateUpdated)="updateSelectedYear()"
                [dateNavigationOptions]="dateNavigationOptions"
                [isCompact]="true">
            </app-date-navigation>
        </div>
        <div class="dashboard__tile__content graph__chart">
            <app-default-line-chart
                (clickEvent)="displayInfo($event)"
                *ngIf="overheadCategoryWithFinancialData?.financialData; else loading"
                [data]="data"
                [legendPosition]="legendPosition"
                [objectLabels]="labels"
                [options]="options"
                [xLabels]="xLabels">
            </app-default-line-chart>

            <p>
                Average for {{ dateNavigationOptions.selectedDate | date: 'yyyy' }}:
                <b>{{ average | currency: 'EUR' }}</b>
            </p>
            <p>
                Total for {{ dateNavigationOptions.selectedDate | date: 'yyyy' }}: <b>{{ total | currency: 'EUR' }}</b>
            </p>
        </div>
    </div>
    <ng-template #loading>
        <app-loading-message class="graph__loading"></app-loading-message>
    </ng-template>
</div>

<div class="divider divider--small"></div>
<app-overhead-financial-graphs></app-overhead-financial-graphs>
