import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';

import LogCategory from '../../models/Logbook/LogCategory';
import { AddLogCategory, DeleteLogCategory } from '../logbook.actions';

@Component({
    selector: 'app-log-categories',
    templateUrl: './log-categories.component.html',
    styleUrls: ['./log-categories.component.scss'],
})
export class LogCategoriesComponent implements OnInit, OnDestroy {
    @Input() logCategories: LogCategory[];

    logCategoryForm: FormGroup;

    private subscriptions = new Subscription();

    constructor(public activeModal: NgbActiveModal, private store: Store, private fb: FormBuilder, private toastr: ToastrService) {}

    ngOnInit(): void {
        this.createLogCategoryForm();
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    deleteLogCategory(id: string) {
        this.subscriptions.add(
            this.store.dispatch(new DeleteLogCategory(id)).subscribe({
                next: () => {
                    this.toastr.success('Successfully deleted category');
                    this.logCategories.splice(
                        this.logCategories.findIndex((category) => category.id === id),
                        1,
                    );
                },
            }),
        );
    }

    addLogCategory() {
        const newCategory: LogCategory = {
            category: this.logCategoryForm.value.category,
        };
        this.subscriptions.add(
            this.store.dispatch(new AddLogCategory(newCategory)).subscribe({
                next: (store) => {
                    this.toastr.success('Successfully added new category');
                    this.logCategories = [...store.logbook.logCategories];
                    this.logCategoryForm.reset();
                },
                error: () => {
                    this.toastr.error('Oops, something went wrong, Please try again later...');
                },
            }),
        );
    }

    private createLogCategoryForm() {
        this.logCategoryForm = this.fb.group({
            category: ['', [Validators.required]],
        });
    }
}
