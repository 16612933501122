export class VAT {
    static readonly ZERO = new VAT(0, 'ZERO');
    static readonly SIX = new VAT(6, 'SIX');
    static readonly TWELVE = new VAT(12, 'TWELVE');
    static readonly TWENTY_ONE = new VAT(21, 'TWENTY_ONE');
    static readonly OTHER = new VAT(1, 'OTHER');

    private constructor(public readonly percent: number, public readonly value: string) {}

    static findVAT(value: string): VAT {
        if (value.toLocaleUpperCase().includes(VAT.ZERO.value)) return VAT.ZERO;
        else if (value.toLocaleUpperCase().includes(VAT.SIX.value)) return VAT.SIX;
        else if (value.toLocaleUpperCase().includes(VAT.TWELVE.value)) return VAT.TWELVE;
        else if (value.toLocaleUpperCase().includes(VAT.TWENTY_ONE.value)) return VAT.TWENTY_ONE;
        else if (value.toLocaleUpperCase().includes(VAT.OTHER.value)) return VAT.OTHER;
        return null;
    }
}
