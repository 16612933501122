import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subscription } from 'rxjs';
import { ModuleEnum } from 'src/app/models/ModuleEnum';
import Project from 'src/app/models/Project';
import { ModuleService } from 'src/app/services/module.service';

import { ProjectDeleteModalComponent } from '../project-delete-modal/project-delete-modal.component';
import { ProjectDetailModalComponent } from '../project-detail-modal/project-detail-modal.component';
import { FetchProjects } from '../project.actions';
import { ProjectState } from '../project.state';

@Component({
    selector: 'app-projects-subnav',
    templateUrl: './projects-subnav.component.html',
    styleUrls: ['./projects-subnav.component.scss'],
})
export class ProjectsSubnavComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();
    @Select(ProjectState.projects) projects$: Observable<Project[]>;
    projectUuid: string;
    addressNotEmpty = false;
    project: Project;

    eModule = ModuleEnum;

    constructor(
        private route: ActivatedRoute,
        private store: Store,
        private modalService: NgbModal,
        private router: Router,
        public moduleService: ModuleService,
        private logger: NGXLogger,
    ) {}

    ngOnInit(): void {
        this.subscriptions.add(
            this.store.dispatch(new FetchProjects()).subscribe(() => {
                this.route.params.subscribe((params) => {
                    this.projectUuid = params.id;
                    this.projects$.subscribe((projects) => {
                        this.project = projects.filter((w) => w.uuid == params.id)[0];
                        this.checkWorkInfo();
                    });
                });
            }),
        );
    }

    checkWorkInfo() {
        if (this.project.location) {
            if (
                this.project.location.street !== '' ||
                this.project.location.postal !== '' ||
                this.project.location.city !== '' ||
                this.project.location.country.iso2 !== ''
            ) {
                this.addressNotEmpty = true;
            } else {
                this.addressNotEmpty = false;
            }
        } else {
            this.addressNotEmpty = false;
        }
    }

    openWorkUpdateModal(project: Project) {
        this.logger.debug('Opening projectDetailModal');
        const modalRef = this.modalService.open(ProjectDetailModalComponent);
        modalRef.componentInstance.project = project;
        modalRef.componentInstance.isUpdate = true;
    }

    openWorkDeleteModal(project: Project) {
        this.logger.debug('Opening projectDeleteModal');
        const modalRef = this.modalService.open(ProjectDeleteModalComponent, {
            windowClass: 'modal-prompt',
        });
        modalRef.componentInstance.project = project;
    }

    isActive(instruction: any[]): boolean {
        return this.router.isActive(this.router.createUrlTree(instruction), false);
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
