import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store, createSelector } from '@ngxs/store';
import { append, compose, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { RepairType } from '@ngxs/store/operators/utils';
import { tap } from 'rxjs/operators';

import { BillableInformationForEmployee } from '../models/BillableInformationForEmployee';
import { ObjectWithFinancialData } from '../models/Financial/ObjectWithFinancialData';
import { TurnoverDataPerMonth } from '../models/Financial/TurnoverDataPerMonth';
import FinancialDetailInfoViewModel from '../models/FinancialDetailInfoViewModel';
import { HappinessScoreInformationForEmployee } from '../models/HappinessScoreInformationForEmployee';
import { InformationForFinancialData } from '../models/InformationForFinancialData';
import { OverheadCategoryWithFinancialData } from '../models/OverheadCategoryWithFinancialData';
import {
    FetchCostsForEmployee,
    FetchCostsForProject,
    FetchFinancialDataForOverheadCategories,
    FetchFinancialDataForOverheadCategoryByCategoryId,
    FetchIncomeForEmployee,
    FetchIncomeForProject,
    GetAllFinancialInformationForCategory,
    GetAllFinancialInformationForEmployee,
    GetAllFinancialInformationForProject,
    GetBillableInformationForEmployeeByEmployeeId,
    GetBillableInformationForEmployees,
    GetEmployeesFinancialData,
    GetHappinessScoreInformationForEmployeeByEmployeeId,
    GetHappinessScoreInformationForEmployees,
    GetOverheadCategoriesFinancialOverviewData,
    GetProjectsFinancialData,
    GetTurnoverData,
    ResetFinancialData,
} from './financial.actions';
import { FinancialService } from './financial.service';

export interface FinancialStateModel {
    incomeForEmployees: FinancialDetailInfoViewModel[];
    costsForEmployees: FinancialDetailInfoViewModel[];
    incomeForProjects: FinancialDetailInfoViewModel[];
    costsForProjects: FinancialDetailInfoViewModel[];
    overheadCategoriesWithFinancialData: OverheadCategoryWithFinancialData[];
    informationForFinancialData: InformationForFinancialData[];
    billableInformationForEmployees: BillableInformationForEmployee[];
    happinessScoreInformationForEmployee: HappinessScoreInformationForEmployee[];
    employeesFinancialData: ObjectWithFinancialData[];
    projectsFinancialData: ObjectWithFinancialData[];
    overheadCategoriesFinancialOverviewData: ObjectWithFinancialData[];
    turnoverDataPerMonth: TurnoverDataPerMonth[];
}

@State<FinancialStateModel>({
    name: 'financial',
    defaults: {
        incomeForEmployees: null,
        costsForEmployees: null,
        incomeForProjects: null,
        costsForProjects: null,
        overheadCategoriesWithFinancialData: null,
        informationForFinancialData: null,
        billableInformationForEmployees: null,
        happinessScoreInformationForEmployee: null,
        employeesFinancialData: null,
        projectsFinancialData: null,
        overheadCategoriesFinancialOverviewData: null,
        turnoverDataPerMonth: null,
    },
})
@Injectable()
export class FinancialState {
    constructor(private financialService: FinancialService, private store: Store) {}

    static incomeForEmployee(employeeId: string) {
        return createSelector([FinancialState], (state: FinancialStateModel) =>
            state?.incomeForEmployees.find((incomeForEmployee) => incomeForEmployee.detailId === employeeId),
        );
    }

    static costsForEmployee(employeeId: string) {
        return createSelector([FinancialState], (state: FinancialStateModel) =>
            state?.costsForEmployees.find((costsForEmployee) => costsForEmployee.detailId === employeeId),
        );
    }

    static incomeForProject(projectId: string) {
        return createSelector([FinancialState], (state: FinancialStateModel) =>
            state?.incomeForProjects.find((incomeForProject) => incomeForProject.detailId === projectId),
        );
    }

    static costsForProject(projectId: string) {
        return createSelector([FinancialState], (state: FinancialStateModel) =>
            state?.costsForProjects.find((costsForProject) => costsForProject.detailId === projectId),
        );
    }

    @Selector()
    static overheadCategoriesWithFinancialData(state: FinancialStateModel): OverheadCategoryWithFinancialData[] {
        return state?.overheadCategoriesWithFinancialData;
    }

    @Selector()
    static idsOfOverheadCategoriesWithFinancialData(state: FinancialStateModel): string[] {
        return state?.overheadCategoriesWithFinancialData.map((category) => category.categoryId);
    }

    static overheadCategoryByIdWithFinancialData(categoryId: string) {
        return createSelector([FinancialState], (state: FinancialStateModel) =>
            state.overheadCategoriesWithFinancialData.find((category) => category.categoryId === categoryId),
        );
    }

    @Selector()
    static informationForFinancialData(state: FinancialStateModel): InformationForFinancialData[] {
        return state?.informationForFinancialData;
    }

    @Selector()
    static billableInformationForEmployees(state: FinancialStateModel): BillableInformationForEmployee[] {
        return state?.billableInformationForEmployees;
    }

    @Selector()
    static employeeIdsOfBillableInformationForEmployee(state: FinancialStateModel): string[] {
        return state?.billableInformationForEmployees.map((information) => information.employeeId);
    }

    static billableInformationForEmployee(employeeId: string) {
        return createSelector([FinancialState], (state: FinancialStateModel) =>
            state.billableInformationForEmployees.find((billableInformation) => billableInformation.employeeId === employeeId),
        );
    }

    @Selector()
    static employeeIdsOfHappinessScoreInformationForEmployee(state: FinancialStateModel): string[] {
        return state?.happinessScoreInformationForEmployee.map((information) => information.employeeId);
    }

    static happinessScoreInformationForEmployee(employeeId: string) {
        return createSelector([FinancialState], (state: FinancialStateModel) =>
            state.happinessScoreInformationForEmployee.find((information) => information.employeeId === employeeId),
        );
    }

    static employeesFinancialDataForYear(year: number) {
        return createSelector([FinancialState], (state: FinancialStateModel) =>
            state?.employeesFinancialData.filter((financialData) => financialData.year === year),
        );
    }

    @Selector()
    static employeesFinancialData(state: FinancialStateModel): ObjectWithFinancialData[] {
        return state?.employeesFinancialData;
    }

    static projectsFinancialDataForYear(year: number) {
        return createSelector([FinancialState], (state: FinancialStateModel) =>
            state?.projectsFinancialData.filter((financialData) => financialData.year === year),
        );
    }

    static overheadCategoriesFinancialOverviewData(year: number) {
        return createSelector([FinancialState], (state: FinancialStateModel) =>
            state?.overheadCategoriesFinancialOverviewData.filter((financialData) => financialData.year === year),
        );
    }

    static turnoverData(year: number) {
        return createSelector([FinancialState], (state: FinancialStateModel) =>
            state?.turnoverDataPerMonth.filter((turnoverData) => turnoverData.year === year),
        );
    }

    @Action(FetchIncomeForEmployee)
    fetchIncomeForEmployee(ctx: StateContext<FinancialStateModel>, action: FetchIncomeForEmployee) {
        return this.financialService.fetchIncomeForEmployee(action.employeeId, action.year).pipe(
            tap((income) => {
                if (!ctx.getState().incomeForEmployees?.find((income) => income.detailId === action.employeeId)) {
                    ctx.setState(
                        patch<FinancialStateModel>({
                            incomeForEmployees: append([income]),
                        }),
                    );
                } else {
                    ctx.setState(
                        patch<FinancialStateModel>({
                            incomeForEmployees: updateItem<FinancialDetailInfoViewModel>((income) => income.detailId === action.employeeId, income),
                        }),
                    );
                }
            }),
        );
    }

    @Action(FetchCostsForEmployee)
    fetchCostsForEmployee(ctx: StateContext<FinancialStateModel>, action: FetchCostsForEmployee) {
        return this.financialService.fetchCostsForEmployee(action.employeeId, action.year).pipe(
            tap((costs) => {
                if (!ctx.getState().costsForEmployees?.find((costs) => costs.detailId === action.employeeId)) {
                    ctx.setState(
                        patch<FinancialStateModel>({
                            costsForEmployees: append([costs]),
                        }),
                    );
                } else {
                    ctx.setState(
                        patch<FinancialStateModel>({
                            costsForEmployees: updateItem<FinancialDetailInfoViewModel>((costs) => costs.detailId === action.employeeId, costs),
                        }),
                    );
                }
            }),
        );
    }

    @Action(FetchIncomeForProject)
    fetchIncomeForProject(ctx: StateContext<FinancialStateModel>, action: FetchIncomeForProject) {
        return this.financialService.fetchIncomeForProject(action.projectId, action.year).pipe(
            tap((income) => {
                if (!ctx.getState().incomeForProjects?.find((income) => income.detailId === action.projectId)) {
                    ctx.setState(
                        patch<FinancialStateModel>({
                            incomeForProjects: append([income]),
                        }),
                    );
                } else {
                    ctx.setState(
                        patch<FinancialStateModel>({
                            incomeForProjects: updateItem((income) => income.detailId === action.projectId, income),
                        }),
                    );
                }
            }),
        );
    }

    @Action(FetchCostsForProject)
    fetchCostsForProject(ctx: StateContext<FinancialStateModel>, action: FetchCostsForProject) {
        return this.financialService.fetchCostsForProject(action.projectId, action.year).pipe(
            tap((costs) => {
                if (!ctx.getState().costsForProjects?.find((costs) => costs.detailId === action.projectId)) {
                    ctx.setState(
                        patch<FinancialStateModel>({
                            costsForProjects: append([costs]),
                        }),
                    );
                } else {
                    ctx.setState(
                        patch<FinancialStateModel>({
                            costsForProjects: updateItem((costs) => costs.detailId === action.projectId, costs),
                        }),
                    );
                }
            }),
        );
    }

    // This action will be called from remove-financial-data.interceptor when necessary
    @Action(ResetFinancialData)
    resetFinancialData(ctx: StateContext<FinancialStateModel>) {
        return ctx.setState(
            patch<FinancialStateModel>({
                incomeForEmployees: null,
                costsForEmployees: null,
                incomeForProjects: null,
                costsForProjects: null,
                overheadCategoriesWithFinancialData: null,
                informationForFinancialData: null,
                billableInformationForEmployees: null,
                employeesFinancialData: null,
                projectsFinancialData: null,
                overheadCategoriesFinancialOverviewData: null,
            }),
        );
    }

    @Action(FetchFinancialDataForOverheadCategories)
    fetchFinancialDataForOverheadCategories(ctx: StateContext<FinancialStateModel>, action: FetchFinancialDataForOverheadCategories) {
        return this.financialService.fetchFinancialDataForOverheadCategories(action.year).pipe(
            tap((overheadCategoriesWithFinancialData) => {
                ctx.setState(
                    patch<FinancialStateModel>({
                        overheadCategoriesWithFinancialData: overheadCategoriesWithFinancialData,
                    }),
                );
            }),
        );
    }

    @Action(FetchFinancialDataForOverheadCategoryByCategoryId)
    fetchFinancialDataForOverheadCategoryByCategoryId(ctx: StateContext<FinancialStateModel>, action: FetchFinancialDataForOverheadCategoryByCategoryId) {
        ctx.setState(
            patch<FinancialStateModel>({
                overheadCategoriesWithFinancialData: updateItem<OverheadCategoryWithFinancialData>(
                    (category) => category.categoryId === action.categoryId,
                    patch<OverheadCategoryWithFinancialData>({
                        financialData: null,
                    }),
                ),
            }),
        );
        return this.financialService.fetchFinancialDataForOverheadCategoryByCategoryId(action.categoryId, action.year).pipe(
            tap((overheadCategoriesWithFinancialData) => {
                ctx.setState(
                    patch<FinancialStateModel>({
                        overheadCategoriesWithFinancialData: updateItem(
                            (category) => category.categoryId === action.categoryId,
                            overheadCategoriesWithFinancialData,
                        ),
                    }),
                );
            }),
        );
    }

    @Action(GetAllFinancialInformationForEmployee)
    getAllFinancialInformationForEmployee(ctx: StateContext<FinancialStateModel>, action: GetAllFinancialInformationForEmployee) {
        this.resetInformationForFinancialData(ctx);
        return this.financialService.getAllFinancialInformationForEmployee(action.employeeId, action.month, action.year).pipe(
            tap((information) => {
                ctx.setState(
                    patch<FinancialStateModel>({
                        informationForFinancialData: information,
                    }),
                );
            }),
        );
    }

    @Action(GetAllFinancialInformationForProject)
    getAllFinancialInformationForProject(ctx: StateContext<FinancialStateModel>, action: GetAllFinancialInformationForProject) {
        this.resetInformationForFinancialData(ctx);
        return this.financialService.getAllFinancialInformationForProject(action.projectId, action.month, action.year).pipe(
            tap((information) => {
                ctx.setState(
                    patch<FinancialStateModel>({
                        informationForFinancialData: information,
                    }),
                );
            }),
        );
    }

    @Action(GetAllFinancialInformationForCategory)
    getAllFinancialInformationForCategory(ctx: StateContext<FinancialStateModel>, action: GetAllFinancialInformationForCategory) {
        this.resetInformationForFinancialData(ctx);
        return this.financialService.getAllFinancialInformationForCategory(action.categoryId, action.month, action.year).pipe(
            tap((information) => {
                ctx.setState(
                    patch<FinancialStateModel>({
                        informationForFinancialData: information,
                    }),
                );
            }),
        );
    }

    @Action(GetBillableInformationForEmployees)
    getBillableInformationForEmployee(ctx: StateContext<FinancialStateModel>, action: GetBillableInformationForEmployees) {
        return this.financialService.getBillableInformationForEmployee(action.year).pipe(
            tap((billableInformation) => {
                ctx.setState(
                    patch<FinancialStateModel>({
                        billableInformationForEmployees: billableInformation,
                    }),
                );
            }),
        );
    }

    @Action(GetBillableInformationForEmployeeByEmployeeId)
    getBillableInformationForEmployeeByEmployeeId(ctx: StateContext<FinancialStateModel>, action: GetBillableInformationForEmployeeByEmployeeId) {
        ctx.setState(
            patch<FinancialStateModel>({
                billableInformationForEmployees: updateItem(
                    (info) => info.employeeId === action.employeeId,
                    patch({
                        billablePercentage: null,
                    }),
                ),
            }),
        );
        return this.financialService.getBillableInformationForEmployeeByEmployeeId(action.employeeId, action.year).pipe(
            tap((billableInformation) => {
                ctx.setState(
                    patch<FinancialStateModel>({
                        billableInformationForEmployees: updateItem((info) => info.employeeId === action.employeeId, billableInformation),
                    }),
                );
            }),
        );
    }

    @Action(GetHappinessScoreInformationForEmployees)
    getHappinessScoreInformationForEmployees(ctx: StateContext<FinancialStateModel>, action: GetHappinessScoreInformationForEmployees) {
        return this.financialService.getHappinessScoreInformationForEmployees(action.year).pipe(
            tap((happinessScoreInformation) => {
                ctx.setState(
                    patch<FinancialStateModel>({
                        happinessScoreInformationForEmployee: happinessScoreInformation,
                    }),
                );
            }),
        );
    }

    @Action(GetHappinessScoreInformationForEmployeeByEmployeeId)
    getHappinessScoreInformationForEmployeeByEmployeeId(ctx: StateContext<FinancialStateModel>, action: GetHappinessScoreInformationForEmployeeByEmployeeId) {
        if (ctx.getState().happinessScoreInformationForEmployee) {
            ctx.setState(
                patch<FinancialStateModel>({
                    happinessScoreInformationForEmployee: updateItem(
                        (happinessScoreInformation) => happinessScoreInformation.employeeId === action.employeeId,
                        patch({
                            happinessScores: null,
                        }),
                    ),
                }),
            );
        }
        return this.financialService.getHappinessScoreInformationForEmployeeByEmployeeId(action.employeeId, action.year).pipe(
            tap((happinessScoreInformation) => {
                if (
                    ctx
                        .getState()
                        .happinessScoreInformationForEmployee?.find((happinessScoreInformation) => happinessScoreInformation.employeeId === action.employeeId)
                ) {
                    ctx.setState(
                        patch<FinancialStateModel>({
                            happinessScoreInformationForEmployee: updateItem(
                                (happinessScoreInformation) => happinessScoreInformation.employeeId === action.employeeId,
                                happinessScoreInformation,
                            ),
                        }),
                    );
                } else {
                    ctx.setState(
                        patch<FinancialStateModel>({
                            happinessScoreInformationForEmployee: append([happinessScoreInformation]),
                        }),
                    );
                }
            }),
        );
    }

    @Action(GetEmployeesFinancialData)
    getEmployeesFinancialData(ctx: StateContext<FinancialStateModel>, action: GetEmployeesFinancialData) {
        if (ctx.getState().employeesFinancialData) {
            const itemsToRemove = ctx
                .getState()
                .employeesFinancialData.filter((financialData) => financialData.year === action.year)
                .map((objectWithFinancialData) => removeItem((item: RepairType<ObjectWithFinancialData>) => item.year === objectWithFinancialData.year));

            ctx.setState(
                patch({
                    employeesFinancialData: compose(...itemsToRemove),
                }),
            );
        }

        return this.financialService.getEmployeesFinancialData(action.year).pipe(
            tap((objectsWithFinancialData) => {
                ctx.setState(
                    patch<FinancialStateModel>({
                        employeesFinancialData: append<ObjectWithFinancialData>(objectsWithFinancialData),
                    }),
                );
            }),
        );
    }

    @Action(GetProjectsFinancialData)
    getProjectsFinancialData(ctx: StateContext<FinancialStateModel>, action: GetProjectsFinancialData) {
        if (ctx.getState().projectsFinancialData) {
            const itemsToRemove = ctx
                .getState()
                .projectsFinancialData.filter((financialData) => financialData.year === action.year)
                .map((objectWithFinancialData) => removeItem((item: RepairType<ObjectWithFinancialData>) => item.year === objectWithFinancialData.year));

            ctx.setState(
                patch<FinancialStateModel>({
                    projectsFinancialData: compose(...itemsToRemove),
                }),
            );
        }

        return this.financialService.getProjectsFinancialData(action.year).pipe(
            tap((objectsWithFinancialData) => {
                ctx.setState(
                    patch<FinancialStateModel>({
                        projectsFinancialData: append(objectsWithFinancialData),
                    }),
                );
            }),
        );
    }

    @Action(GetOverheadCategoriesFinancialOverviewData)
    getOverheadCategoriesFinancialOverviewData(ctx: StateContext<FinancialStateModel>, action: GetOverheadCategoriesFinancialOverviewData) {
        if (ctx.getState().overheadCategoriesFinancialOverviewData) {
            const itemsToRemove = ctx
                .getState()
                .overheadCategoriesFinancialOverviewData.filter((financialData) => financialData.year === action.year)
                .map((objectWithFinancialData) => removeItem((item: RepairType<ObjectWithFinancialData>) => item.year === objectWithFinancialData.year));

            ctx.setState(
                patch<FinancialStateModel>({
                    overheadCategoriesFinancialOverviewData: compose(...itemsToRemove),
                }),
            );
        }

        return this.financialService.getOverheadCategoriesFinancialOverviewData(action.year).pipe(
            tap((objectsWithFinancialData) => {
                ctx.setState(
                    patch<FinancialStateModel>({
                        overheadCategoriesFinancialOverviewData: append(objectsWithFinancialData),
                    }),
                );
            }),
        );
    }

    @Action(GetTurnoverData)
    getTurnoverData(ctx: StateContext<FinancialStateModel>, action: GetTurnoverData) {
        if (ctx.getState().turnoverDataPerMonth) {
            const itemsToRemove = ctx
                .getState()
                .turnoverDataPerMonth.filter((turnoverData) => turnoverData.year === action.year)
                .map((turnoverData) => removeItem((item: RepairType<TurnoverDataPerMonth>) => item.year === turnoverData.year));

            ctx.setState(
                patch<FinancialStateModel>({
                    turnoverDataPerMonth: compose(...itemsToRemove),
                }),
            );
        }

        return this.financialService.getTurnoverData(action.year).pipe(
            tap((turnoverData) => {
                turnoverData.forEach((turnoverData) => (turnoverData.year = action.year));
                ctx.setState(
                    patch<FinancialStateModel>({
                        turnoverDataPerMonth: append(turnoverData),
                    }),
                );
            }),
        );
    }

    // Previous data will not be shown and loading icon will be visible
    private resetInformationForFinancialData(ctx: StateContext<FinancialStateModel>) {
        ctx.setState(
            patch<FinancialStateModel>({
                informationForFinancialData: null,
            }),
        );
    }
}
