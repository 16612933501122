import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PasswordStrengthMeterModule } from 'angular-password-strength-meter';
import { NgxTrimDirectiveModule } from 'ngx-trim-directive';

import { SharedModule } from './../shared/shared.module';
import { ActivationComponent } from './activation/activation.component';
import { routing } from './authentication.routing';
import { ForgotpasswordComponent } from './forgotpassword/forgotpassword.component';
import { GoogleLoginComponent } from './google-login/google-login.component';
import { LoggedinComponent } from './loggedin/loggedin.component';
import { LoginAttemptsComponent } from './login-attempts/login-attempts.component';
import { LoginMfaModalComponent } from './login-mfa-modal/login-mfa-modal.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';

@NgModule({
    declarations: [
        ActivationComponent,
        ForgotpasswordComponent,
        GoogleLoginComponent,
        LoginComponent,
        LoginAttemptsComponent,
        RegisterComponent,
        ResetPasswordComponent,
        LoggedinComponent,
        LoginMfaModalComponent,
    ],
    imports: [CommonModule, SharedModule, routing, PasswordStrengthMeterModule, NgxTrimDirectiveModule, ReactiveFormsModule, FormsModule],
    exports: [],
})
export class AuthenticationModule {}
