<app-app-holder>
    <app-navbar></app-navbar>

    <app-sidebar>
        <app-mailchimp-overview></app-mailchimp-overview>
    </app-sidebar>

    <app-content
        *appIsPermitted="{
            module: 'MAILCHIMP',
            operator: 'AND',
            actions: ['READ']
        }">
        <app-mailchimp-not-logged-in (keyIsset)="checkKey($event)"></app-mailchimp-not-logged-in>
        <div *ngIf="isset">
            <div class="app__title">
                <h1>Mailchimp - Campaigns ({{ (campaigns$ | async).length }})</h1>

                <span>
                    <a
                        (click)="addCampaignModal()"
                        *appIsPermitted="{
                            module: 'MAILCHIMP',
                            operator: 'AND',
                            actions: ['CREATE']
                        }"
                        type="button"
                        class="button button--primary"
                        >Create campaign</a
                    >
                </span>
            </div>

            <app-searchbar [(value)]="filter"></app-searchbar>

            <div class="m-t-4">
                <div *ngIf="loading">
                    <app-loading-message></app-loading-message>
                </div>

                <table
                    class="table-view"
                    *ngIf="!loading">
                    <tr>
                        <th scope="col">Campaign name</th>
                        <th scope="col">Tags</th>
                        <th scope="col">Recipient count</th>
                        <th scope="col">Status</th>
                        <!--<th scope="col">Type</th>-->
                        <!--<th width="1"></th>-->
                    </tr>
                    <tr *ngFor="let campaign of campaigns$ | async | orderBy: 'name':false:true | filterBy: ['name']:filter">
                        <td
                            class="pointer"
                            *ngIf="campaign.name"
                            (click)="openCampaignReport(campaign)">
                            {{ campaign.name }}
                        </td>
                        <td>
                            <span
                                *ngIf="campaign.tagName"
                                class="tag"
                                >{{ campaign.tagName }}</span
                            >
                        </td>
                        <td>
                            <span *ngIf="campaign.recipientCount">{{ campaign.recipientCount }}</span>
                        </td>
                        <td>
                            <span *ngIf="campaign.status && campaign.status === 'sent'">
                                <i class="las la-paper-plane color--green"></i>
                                Sent
                            </span>
                            <span
                                *ngIf="campaign.status && campaign.status === 'save'"
                                class="color--grey">
                                <i class="las la-pen"></i> Draft
                            </span>
                        </td>
                        <!--<td><span *ngIf="campaign.type">{{campaign.type}}</span></td>-->
                        <!--<td>
                            <span ngbDropdown>
                                <button ngbDropdownToggle class="button button--icon button--dropdown button--small">
                                </button>
                                <span ngbDropdownMenu class="dropdown-menu dropdown-menu-right"
                                    aria-labelledby="dropdownMenuButton">
                                </span>
                            </span>
                        </td>-->
                    </tr>
                </table>
            </div>
        </div>
    </app-content>
</app-app-holder>
