import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import Address from 'src/app/models/Address';
import { Company } from 'src/app/models/Company';
import { ContactInformation } from 'src/app/models/ContactInformation';
import Country from 'src/app/models/Country';
import { CountryService } from 'src/app/shared/country.service';

import { AddCompany, UpdateCompany } from '../company.actions';
import { CompanyState } from '../company.state';

@Component({
    selector: 'app-company-detail-modal',
    templateUrl: './company-detail-modal.component.html',
    styleUrls: ['./company-detail-modal.component.scss'],
})
export class CompanyDetailModalComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    allCountries = [];

    company$: Observable<Company>;
    loading = false;
    company: Company;
    address: Address;
    country: Country;
    modules: any[];
    contactInformation: ContactInformation;
    isUpdate = false;

    companyFormGroup: FormGroup;

    inputIsWrong = false;
    saveCompanyWentWrong = false;
    errorMessage: string;

    @ViewChild('focussed', { static: false })
    set input(element: ElementRef<HTMLInputElement>) {
        if (element) {
            element.nativeElement.focus();
        }
    }

    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        public activeModal: NgbActiveModal,
        private countryService: CountryService,
        private store: Store,
        private toastr: ToastrService,
        private logger: NGXLogger,
    ) {}

    ngOnInit(): void {
        this.logger.debug('Attempt to fetch companyuuid to determine new or update');
        this.subscriptions.add(
            this.store.select(CompanyState.companyUuid).subscribe((uuid) => {
                uuid ? (this.isUpdate = true) : (this.isUpdate = false);
            }),
        );

        this.logger.debug('Fetching all countries from countryService');
        this.allCountries = this.countryService.getAllCountries();

        this.companyFormGroup = this.formBuilder.group({
            title: ['', [Validators.required]],
            logo: [''],
            vat: ['', [Validators.required]],
            address: this.formBuilder.group({
                street: ['', [Validators.required]],
                number: [null, [Validators.required]],
                bus: [''],
                ext: [null],
                postal: ['', [Validators.required]],
                city: ['', [Validators.required]],
                country: this.formBuilder.group({
                    iso2: ['', [Validators.required]],
                }),
            }),
            contactInformation: this.formBuilder.group({
                phoneNumber: ['', [Validators.required]],
                email: ['', [Validators.email, Validators.required]],
                website: [''],
            }),
        });
        if (this.isUpdate) {
            this.logger.debug('Update: fetch company and set formValues');
            this.company$ = this.store.select(CompanyState.company);
            this.setFormValues();
        }
    }

    setFormValues() {
        this.subscriptions.add(
            this.company$.subscribe((company) => {
                this.modules = company.modules;
                this.companyFormGroup.setValue({
                    title: company.title,
                    vat: company.vat,
                    logo: company.logo,
                    address: company.address,
                    contactInformation:
                        company.contactInformation !== null
                            ? {
                                  phoneNumber: company.contactInformation.phoneNumber ? company.contactInformation.phoneNumber : '',
                                  email: company.contactInformation.email ? company.contactInformation.email : '',
                                  website: company.contactInformation.website ? company.contactInformation.website : '',
                              }
                            : {
                                  phoneNumber: '',
                                  email: '',
                                  website: '',
                              },
                });
            }),
        );
    }

    saveCompany() {
        this.loading = true;

        if (!this.companyFormGroup.valid) {
            this.inputIsWrong = true;
            this.loading = false;
            return;
        }

        this.companyFormGroup.disable();

        this.company = this.companyFormGroup.value;

        if (!this.isUpdate) {
            this.logger.debug('Attempt to save company');
            this.subscriptions.add(
                this.store.dispatch(new AddCompany(this.company)).subscribe(
                    () => {
                        this.logger.debug('Company added successfully');
                        this.toastr.success('Company added succesfully');
                        this.activeModal.close();
                        this.logger.debug('Navigating to dashboard');
                        this.store.selectOnce(CompanyState.company).subscribe((company) => {
                            this.router.navigate(['/dashboard']);
                        });
                    },
                    (errorMessage) => {
                        this.logger.error('Saving company failed');
                        this.loading = false;
                        this.errorMessage = errorMessage?.error?.message ?? 'Error message was empty';
                        this.saveCompanyWentWrong = true;
                        this.companyFormGroup.enable();
                    },
                ),
            );
        } else {
            this.logger.debug('Attempt to update existing company');

            this.subscriptions.add(
                this.store.dispatch(new UpdateCompany(this.company)).subscribe(
                    () => {
                        this.logger.debug('Update company succesful');
                        this.activeModal.close();
                    },
                    (errorMessage) => {
                        this.logger.error('Updating company failed');
                        this.errorMessage = errorMessage.error.message ?? 'Error message was empty';
                        this.saveCompanyWentWrong = true;
                        this.loading = false;
                        this.companyFormGroup.enable();
                    },
                ),
            );
        }
    }

    // Hides error messages
    hideErrorMessage() {
        this.inputIsWrong = false;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
