import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import YukiDocument from 'src/app/models/yuki/YukiDocument';

import { CheckRemovedYukiDocuments, GetRemovedYukiDocuments } from '../yuki.actions';
import { YukiState } from '../yuki.state';

@Component({
    selector: 'app-invoices-settings-modal',
    templateUrl: './invoices-settings-modal.component.html',
    styleUrls: ['./invoices-settings-modal.component.scss'],
})
export class InvoicesSettingsModalComponent implements OnInit {
    @Select(YukiState.removedFiles) removedFiles$: Observable<YukiDocument[]>;
    isSyncingDocuments = false;

    constructor(public activeModal: NgbActiveModal, private store: Store, private toastr: ToastrService) {}

    ngOnInit(): void {
        this.store.dispatch(new GetRemovedYukiDocuments());
    }

    checkForDeletedDocuments() {
        this.isSyncingDocuments = true;
        this.store.dispatch(new CheckRemovedYukiDocuments()).subscribe({
            next: () => {
                this.isSyncingDocuments = false;
                this.toastr.success('Successfully synced Yuki Documents');
            },
            error: () => {
                this.isSyncingDocuments = false;
                this.toastr.error('Oops, something went wrong, Please try again later...');
            },
        });
    }
}
