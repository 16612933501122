import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';
import { Role } from 'src/app/models/Role';

import { DeleteCustomRole } from '../custom.roles.actions';

@Component({
    selector: 'app-role-delete-modal',
    templateUrl: './role-delete-modal.component.html',
    styleUrls: ['./role-delete-modal.component.scss'],
})
export class RoleDeleteModalComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Input() role: Role;

    constructor(public activeModal: NgbActiveModal, private store: Store, private logger: NGXLogger) {}

    ngOnInit(): void {}

    deleteRole() {
        this.logger.debug('Attempt to delete custom role');
        this.subscriptions.add(
            this.store.dispatch(new DeleteCustomRole(this.role.id)).subscribe(
                () => {
                    this.logger.debug('Succesfully deleted custom role');
                    this.activeModal.close();
                },
                (error) => this.logger.error('Failed to delete custom role'),
            ),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
