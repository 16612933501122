<app-app-holder>
    <app-navbar></app-navbar>

    <app-sidebar>
        <app-mailchimp-overview></app-mailchimp-overview>
    </app-sidebar>

    <app-content
        *appIsPermitted="{
            module: 'MAILCHIMP',
            operator: 'AND',
            actions: ['READ']
        }">
        <app-mailchimp-not-logged-in (keyIsset)="checkKey($event)"></app-mailchimp-not-logged-in>

        <div *ngIf="isset">
            <div class="app__title">
                <h1>Mailchimp - Audience tags ({{ (tags$ | async).length }})</h1>

                <span>
                    <a
                        (click)="addTagModal()"
                        type="button"
                        class="button button--primary"
                        >Add tag</a
                    >
                </span>
            </div>

            <app-searchbar [(value)]="filter"></app-searchbar>

            <div class="m-t-4">
                <div *ngIf="loading">
                    <app-loading-message></app-loading-message>
                </div>
                <table
                    class="table-view"
                    *ngIf="!loading">
                    <tr>
                        <th>Tag name</th>
                        <th>Recipient count</th>
                        <th class="td-small"></th>
                    </tr>
                    <tr *ngFor="let tag of tags$ | async | orderBy: 'name':false:true | filterBy: ['name']:filter">
                        <td>
                            <span *ngIf="tag.name">{{ tag.name }}</span>
                        </td>
                        <td>
                            <span *ngIf="tag.memberCount">{{ tag.memberCount }}</span>
                        </td>
                        <td>
                            <span
                                ngbDropdown
                                *appIsPermitted="{
                                    module: 'MAILCHIMP',
                                    operator: 'AND',
                                    actions: ['DELETE']
                                }">
                                <button
                                    ngbDropdownToggle
                                    class="button button--icon button--dropdown button--small"></button>
                                <span
                                    ngbDropdownMenu
                                    class="dropdown-menu dropdown-menu-right"
                                    aria-labelledby="dropdownMenuButton">
                                    <a
                                        (click)="removeTag(tag)"
                                        class="dropdown-item color--red"
                                        *appIsPermitted="{
                                            module: 'MAILCHIMP',
                                            operator: 'AND',
                                            actions: ['DELETE']
                                        }"
                                        >Remove tag</a
                                    >
                                </span>
                            </span>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </app-content>
</app-app-holder>
