import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';

import { UpdateDaysPerMonth } from '../settings.actions';

@Component({
    selector: 'app-update-days-per-month-modal',
    templateUrl: './update-days-per-month-modal.component.html',
    styleUrls: ['./update-days-per-month-modal.component.scss'],
})
export class UpdateDaysPerMonthModalComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Input() days: number;

    constructor(public activeModal: NgbActiveModal, private store: Store, private toastr: ToastrService, private logger: NGXLogger) {}

    ngOnInit(): void {}

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    submit() {
        this.subscriptions.add(
            this.store.dispatch(new UpdateDaysPerMonth(this.days)).subscribe(
                (res) => {
                    this.toastr.success('Days per month successfully updated');
                    this.activeModal.close();
                },
                (error) => {
                    this.logger.error('Failed to update days per month');
                    this.toastr.error('Oops, something went wrong, Please try again later...');
                },
            ),
        );
    }
}
