<div class="modal-header">
    <app-loading-message *ngIf="isLoading"></app-loading-message>
    <h2
        *ngIf="!isLoading"
        class="modal-title"
        id="exampleModalLabel">
        {{ warehouse.title }}
    </h2>
    <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<nav
    ngbNav
    #nav="ngbNav"
    class="tabs__modal">
    <ng-container ngbNavItem>
        <a ngbNavLink>Information</a>
        <ng-template ngbNavContent>
            <p class="format-newlines">{{ warehouse.description }}</p>

            <ul class="list__specs">
                <li *ngIf="warehouse.title"><label>Name</label> {{ warehouse.title }}</li>
                <li *ngIf="warehouse.shortName"><label>Shortname</label> {{ warehouse.shortName }}</li>
                <li *ngIf="warehouse.address">
                    <label>Address</label>
                    <app-pill-address [address]="warehouse.address"></app-pill-address>
                </li>
                <ng-template *ngIf="warehouse.warehouseLocations?.length > 0">
                    <li>
                        <label>Locations</label>
                        <app-pill-warehouse-location
                            *ngFor="let location of warehouse.warehouseLocations"
                            [warehouseLocation]="location"></app-pill-warehouse-location>
                    </li>
                </ng-template>
                <li>
                    <label>Tags</label>
                    <app-global-tags
                        [objectUuid]="warehouse.uuid"
                        [type]="'warehouse'"></app-global-tags>
                </li>
            </ul>
        </ng-template>
    </ng-container>

    <ng-container ngbNavItem>
        <a ngbNavLink>Employees</a>
        <ng-template ngbNavContent>
            <div>
                <app-related-employees-list
                    [objectId]="warehouse.uuid"
                    [objectType]="'warehouse'"></app-related-employees-list>
            </div>
        </ng-template>
    </ng-container>
</nav>

<div
    class="modal-body"
    *ngIf="isLoading">
    <app-loading-message></app-loading-message>
</div>

<div
    class="modal-body"
    *ngIf="!isLoading">
    <div [ngbNavOutlet]="nav"></div>
</div>

<div class="modal-footer">
    <button
        type="button"
        class="button button--outline"
        (click)="activeModal.dismiss()">
        Close
    </button>
</div>
