import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subscription } from 'rxjs';
import { Product } from 'src/app/models/Product';

import { LinkProductsComponent } from '../link-products/link-products.component';
import { ProductDeleteLinkComponent } from '../product-delete-link/product-delete-link.component';
import { ProductState } from '../product.state';

@Component({
    selector: 'app-product-child-list',
    templateUrl: './product-child-list.component.html',
    styleUrls: ['./product-child-list.component.scss'],
})
export class ProductChildListComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Select(ProductState.Products) products$: Observable<Product[]>;

    @Input() product: Product;
    @Input() allProducts: Product[];
    @Input() combinedProducts: Product[];

    @Input() selectedChildProducts: Product[] = [];
    @Output() changeChildren = new EventEmitter();
    @Output() changeCombinedProducts = new EventEmitter();

    displayedColumns: string[] = ['child product', 'amount', 'in stock', 'edit'];
    dataSource: MatTableDataSource<Product> = new MatTableDataSource<Product>();
    @ViewChild(MatSort) sort: MatSort;

    constructor(public modalService: NgbModal, private logger: NGXLogger) {}

    ngOnInit(): void {
        if (this.product) {
            this.subscriptions.add(
                this.products$.subscribe((products) => {
                    this.product = products.find((p) => this.product.uuid == p.uuid);
                }),
            );
        }
    }

    childUnitType(childUuid: string): string {
        const unit = this.allProducts.filter((m) => m.uuid == childUuid)[0]?.unit;
        if (unit) {
            return unit.type;
        }
        return '';
    }

    childInStock(childUuid: string): number {
        const inStock = this.allProducts.filter((m) => m.uuid == childUuid)[0]?.inStock;
        if (inStock != null) {
            return inStock;
        }
        return 0;
    }

    openLinkProductModal() {
        this.logger.debug('Opening linkProductModal');
        const modalRef = this.modalService.open(LinkProductsComponent, {
            windowClass: 'modal-prompt',
        });
        modalRef.componentInstance.isChild = false;
        modalRef.componentInstance.combinedProducts = this.combinedProducts;
        if (this.product) {
            modalRef.componentInstance.parent = this.product;
            modalRef.componentInstance.isDetailModal = true;
        } else {
            modalRef.componentInstance.addedProduct.subscribe((products) => {
                this.selectedChildProducts.push(products[0]);
                this.changeChildren.emit(this.selectedChildProducts);
            });
        }
    }

    removeFromSelectedChildProducts(child: Product) {
        this.selectedChildProducts = this.selectedChildProducts.filter((p) => p.uuid != child.uuid);
        this.combinedProducts = this.combinedProducts.filter((m) => m.uuid != child.uuid);
        this.changeChildren.emit(this.selectedChildProducts);
        this.changeCombinedProducts.emit(this.combinedProducts);
    }

    openProductEditChildModal(product: Product, amount: number, childUuid?: string) {
        this.logger.debug('Opening productEditChildModal');
        const modalRef = this.modalService.open(LinkProductsComponent, {
            windowClass: 'modal-prompt',
        });
        modalRef.componentInstance.isChild = false;
        modalRef.componentInstance.parent = product;
        modalRef.componentInstance.child = this.allProducts.find((m) => m.uuid == childUuid);
        modalRef.componentInstance.amount = amount;
        modalRef.componentInstance.isUpdate = true;
    }

    openProductChildDeleteModal(product: Product, childUuid: string) {
        this.logger.debug('Opening productDeleteChildModal');
        const modalRef = this.modalService.open(ProductDeleteLinkComponent, {
            windowClass: 'modal-prompt',
        });
        modalRef.componentInstance.product = product;
        modalRef.componentInstance.linkedProduct = this.allProducts.find((m) => m.uuid == childUuid);
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
