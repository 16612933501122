import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { Warehouse } from 'src/app/models/Warehouse';
import { WarehouseDetailModalComponent } from 'src/app/warehouses/warehouse-detail-modal/warehouse-detail-modal.component';
import { WarehouseState } from 'src/app/warehouses/warehouse.state';

@Component({
    selector: 'app-pill-warehouse',
    templateUrl: './pill-warehouse.component.html',
    styleUrls: ['./pill-warehouse.component.scss'],
})
export class PillWarehouseComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();
    @Select(WarehouseState.Warehouses) warehouses$: Observable<Warehouse[]>;
    @Input() warehouse: Warehouse;
    @Input() warehouseUuid: string;
    @Input() warehouseLocationUuid: string;
    fetchedWarehouse: Warehouse;
    isLoaded = false;

    constructor(private modalService: NgbModal) {}

    ngOnInit(): void {
        if (this.warehouseLocationUuid) {
            this.subscriptions.add(
                this.warehouses$.subscribe((warehouses) => {
                    if (warehouses != undefined) {
                        if (warehouses.length > 0) {
                            this.warehouse = warehouses.find((w) => w.warehouseLocations.find((wl) => wl.uuid == this.warehouseLocationUuid) != null);
                            this.isLoaded = true;
                        }
                    }
                }),
            );
        }
        if (this.warehouseUuid) {
            this.subscriptions.add(
                this.warehouses$.subscribe((warehouses) => {
                    if (warehouses != undefined) {
                        if (warehouses.length > 0) {
                            this.fetchedWarehouse = warehouses.find((m) => m.uuid == this.warehouseUuid);
                            this.isLoaded = true;
                        }
                    }
                }),
            );
        }
    }

    openWarehouseDetailModal() {
        const modalRef = this.modalService.open(WarehouseDetailModalComponent, {
            windowClass: 'modal-prompt',
        });
        if (this.warehouse) {
            modalRef.componentInstance.warehouseUuid = this.warehouse.uuid;
        } else if (this.fetchedWarehouse) {
            modalRef.componentInstance.warehouseUuid = this.fetchedWarehouse.uuid;
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
