import { Product } from '../models/Product';

export class FetchProducts {
    static readonly type = '[Product] fetch products';
    constructor() {}
}

export class GetProduct {
    static readonly type = '[Product] Get Product';
    constructor(public productUuid: string) {}
}

export class AddProduct {
    static readonly type = '[Product] Add Product';
    constructor(public product: Product) {}
}

export class UpdateProduct {
    static readonly type = '[Product] Update Product';
    constructor(public product: Product) {}
}

export class ArchiveProduct {
    static readonly type = '[Product] Archive Product';
    constructor(public productUuid: string) {}
}

export class LinkProducts {
    static readonly type = '[Product] Link products';
    constructor(public currentUuid: string, public parentUuid: string, public children: string, public amountAssigned: number[]) {}
}

export class UpdateLinkedAmount {
    static readonly type = '[Product] Update product linked amount';
    constructor(public parentUuid: string, public childUuid: string, public amountAssigned: number) {}
}

export class RemoveProductsLink {
    static readonly type = '[Product] Products link';
    constructor(public parentUuid: string, public childUuid: string) {}
}

//ProductCategories

export class FetchProductCategories {
    static readonly type = '[string] fetch ProductCategories';
    constructor() {}
}

export class AddProductCategory {
    static readonly type = '[string] Add ProductCategory';
    constructor(public category: string) {}
}

export class UpdateProductCategory {
    static readonly type = '[string] Update ProductCategory';
    constructor(public oldCategory: string, public newCategory: string) {}
}

export class DeleteProductCategories {
    static readonly type = '[string] Delete ProductCategory';
    constructor(public category: string) {}
}

export class LinkProductCategory {
    static readonly type = '[Product] link Product with Category';
    constructor(public productUuid: string, public category: string) {}
}

export class UnLinkProductCategory {
    static readonly type = '[Product] unlink Product with Category';
    constructor(public productUuid: string, public category: string) {}
}

export class AddUndefinedProductCategoriesToProduct {
    static readonly type = '[Product] Add ProductCategories to Product';
    constructor(public productUuid: string) {}
}

export class AddUndefinedProductCategory {
    static readonly type = '[string] Add category for a undefined product';
    constructor(public category: string, public key: number) {}
}

export class RemoveUndefinedProductCategory {
    static readonly type = '[string] Remove category for undefined product';
    constructor(public category: string, public key: number) {}
}

export class ResetUndefinedProductCategories {
    static readonly type = '[string] Reset all productCategories for undefined products';
    constructor() {}
}

export class SelectCategory {
    static readonly type = '[string] Change the selected category filter';
    constructor(public category: string) {}
}

//stock history
export class FetchProductStockUpdateHistory {
    static readonly type = '[StockUpdateHistoryModel] fetch productStockUpdateHistory';
    constructor() {}
}
//stock update

export class LinkProductToEmployee {
    static readonly type = '[string] Link product to Employee';
    constructor(public productUuid: string, public employeeUuid: string) {}
}
