<form
    (ngSubmit)="saveLanguage()"
    [formGroup]="languageFormGroup"
    class="modal-wrapper">
    <div class="modal-header">
        <h2
            *ngIf="!isUpdate"
            class="modal-title"
            id="exampleModalLabel">
            New langauge
        </h2>
        <h2
            *ngIf="isUpdate"
            class="modal-title"
            id="exampleModalLabel">
            Edit langauge
        </h2>
        <button
            (click)="activeModal.dismiss()"
            aria-label="Close"
            class="close"
            type="button">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <div class="form-group required m-b-2">
            <label>Language</label>
            <input
                (focus)="hideErrorMessage()"
                formControlName="language"
                trim="blur"
                type="text" />
            <span
                *ngIf="languageFormGroup.controls.language.errors && inputIsWrong"
                class="form-error"
                >Invalid Language</span
            >
        </div>
        <div class="form-group required m-b-2">
            <label>Language strength</label>
            <span class="form-helptext">How well is the language spoken?</span>
        </div>
        <div (focus)="hideErrorMessage()">
            <div class="form-group form-group--toggle">
                <label for="weak">
                    <input
                        #weak
                        formControlName="strength"
                        id="weak"
                        type="radio"
                        value="weak" />
                    Weak
                </label>
            </div>
            <div class="form-group form-group--toggle">
                <label for="basic">
                    <input
                        #basic
                        formControlName="strength"
                        id="basic"
                        type="radio"
                        value="basic" />
                    Basic
                </label>
            </div>
            <div class="form-group form-group--toggle">
                <label for="strong">
                    <input
                        #strong
                        formControlName="strength"
                        id="strong"
                        type="radio"
                        value="strong" />
                    Strong
                </label>
            </div>
            <div class="form-group form-group--toggle">
                <label for="mothertongue">
                    <input
                        #mothertongue
                        formControlName="strength"
                        id="mothertongue"
                        type="radio"
                        value="mothertongue" />
                    Mother tongue
                </label>
            </div>
            <span
                *ngIf="languageFormGroup.controls.strength.errors && inputIsWrong"
                class="form-error"
                >Language strength is required</span
            >
        </div>
    </div>

    <div class="modal-footer">
        <button
            (click)="activeModal.close()"
            class="button button--outline"
            type="button">
            Close
        </button>
        <button
            *ngIf="!isUpdate"
            [ngClass]="{ 'button--loading': loading }"
            class="button button--primary"
            type="submit">
            Add language
        </button>
        <button
            *ngIf="isUpdate"
            [ngClass]="{ 'button--loading': loading }"
            class="button button--primary"
            type="submit">
            Edit language
        </button>
    </div>
</form>
