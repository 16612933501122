import { Component, EventEmitter, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';
import { Applicant } from 'src/app/models/Applicants/Applicant';
import { LaneItemPriority } from 'src/app/models/LineItemPriority';
import { ModuleEnum } from 'src/app/models/ModuleEnum';
import { Partner } from 'src/app/models/Partner';
import { PartnerService } from 'src/app/partners/partner.service';
import { ModuleService } from 'src/app/services/module.service';
import { CountryService } from 'src/app/shared/country.service';

import { AddApplicant, UpdateApplicant } from '../applicant.actions';

@Component({
    selector: 'app-add-applicant-modal',
    templateUrl: './add-applicant-modal.component.html',
    styleUrls: ['./add-applicant-modal.component.scss'],
})
export class AddApplicantModalComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();
    public editor = ClassicEditor;

    @Input() applicant: Applicant;
    @Input() laneUuid: string;
    public emitObject: EventEmitter<Applicant> = new EventEmitter();

    allCountries = [];
    eModule = ModuleEnum;
    public allPartners: Partner[];

    applicantForm: FormGroup;
    currentAmountOfExtraInfoCharacters = 0;

    constructor(
        private fb: FormBuilder,
        private partnerService: PartnerService,
        public moduleService: ModuleService,
        private countryService: CountryService,
        public activeModal: NgbActiveModal,
        private store: Store,
    ) {}

    ngOnInit(): void {
        this.allCountries = this.countryService.getAllCountries();
        this.applicantForm = this.fb.group({
            firstName: [this.applicant && this.applicant.firstName ? this.applicant.firstName : '', [Validators.required]],
            lastName: [this.applicant && this.applicant.lastName ? this.applicant.lastName : '', [Validators.required]],
            description: [this.applicant && this.applicant.description ? this.applicant.description : ''],
            priority: [this.applicant && this.applicant.priority ? this.applicant.priority : 'LOW'],
            address: this.fb.group({
                street: [this.applicant?.address && this.applicant?.address.street ? this.applicant?.address.street : ''],
                number: [this.applicant?.address && this.applicant?.address.number ? this.applicant?.address.number : null],
                bus: [this.applicant?.address && this.applicant?.address.bus ? this.applicant?.address.bus : ''],
                ext: [this.applicant?.address && this.applicant?.address.ext ? this.applicant?.address.ext : null],
                postal: [this.applicant?.address && this.applicant?.address.postal ? this.applicant?.address.postal : ''],
                city: [this.applicant?.address && this.applicant?.address.city ? this.applicant?.address.city : ''],
                country: this.fb.group({
                    iso2: [
                        this.applicant?.address && this.applicant?.address.country && this.applicant?.address.country.iso2
                            ? this.applicant?.address.country.iso2
                            : '',
                    ],
                }),
            }),
            contactInformation: this.fb.group({
                phoneNumber: [
                    this.applicant?.contactInformation && this.applicant?.contactInformation.phoneNumber ? this.applicant?.contactInformation.phoneNumber : '',
                    [],
                ],
                email: [
                    this.applicant?.contactInformation && this.applicant?.contactInformation.email ? this.applicant?.contactInformation.email : '',
                    [Validators.email, Validators.required],
                ],
                website: [this.applicant?.contactInformation && this.applicant?.contactInformation.website ? this.applicant?.contactInformation.website : ''],
            }),
            partnerUuid: [''],
            files: [],
        });

        this.applicantForm.setValue({
            ...this.applicantForm.value,
            files: this.applicant?.files?.length > 0 ? this.applicant.files : null,
        });

        this.subscriptions.add(
            this.partnerService.getAllPartners().subscribe({
                next: (partners) => {
                    if (this.applicant && this.applicant.partnerUuid) {
                        this.applicantForm.get('partnerUuid').setValue(partners.find((p) => p.uuid === this.applicant.partnerUuid));
                    }
                    this.allPartners = partners;
                },
            }),
        );
    }

    submit() {
        let applicant: Applicant;
        if (this.applicant != undefined) {
            applicant = { ...this.applicant };
            applicant.firstName = this.applicantForm.get('firstName').value;
            applicant.lastName = this.applicantForm.get('lastName').value;
            applicant.description = this.applicantForm.get('description').value;
            applicant.priority = this.applicantForm.get('priority').value;
            applicant.address = applicant.address
                ? { uuid: applicant.address.uuid, ...this.applicantForm.get('address').value }
                : this.applicantForm.get('address').value;
            applicant.contactInformation = applicant.contactInformation
                ? { uuid: applicant.contactInformation.uuid, ...this.applicantForm.get('contactInformation').value }
                : this.applicantForm.get('contactInformation').value;
            applicant.partnerUuid = this.applicantForm.get('partnerUuid').value.uuid;
            applicant.files = this.applicantForm.get('files').value;

            this.store.dispatch(new UpdateApplicant(applicant)).subscribe({
                next: () => {
                    this.emitObject.emit(applicant);
                    this.activeModal.close();
                },
            });
        } else {
            applicant = {
                uuid: null,
                firstName: this.applicantForm.get('firstName').value,
                lastName: this.applicantForm.get('lastName').value,
                description: this.applicantForm.get('description').value,
                address: this.applicantForm.get('address').value,
                contactInformation: {
                    ...this.applicantForm.get('contactInformation').value,
                },
                partnerUuid: this.applicantForm.get('partnerUuid').value.uuid,
                laneUuid: this.laneUuid,

                files: this.applicantForm.get('files').value,
                createdOn: undefined,
                lastModified: undefined,
                dateSinceLastMovement: undefined,
                status: undefined,
                reason: undefined,
                priority: this.applicantForm.get('priority').value,
                displayName: undefined,
                classSimpleName: undefined,
                tags: [],
            };

            this.store.dispatch(new AddApplicant(applicant)).subscribe({
                next: () => {
                    this.emitObject.emit(applicant);
                    this.activeModal.close();
                },
            });
        }
    }

    public get laneItemPriority(): typeof LaneItemPriority {
        return LaneItemPriority;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
