import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';

import { OverheadCategory } from '../../models/OverheadCategory';
import { AddOverheadCategoriesModalComponent } from '../add-overhead-categories-modal/add-overhead-categories-modal.component';
import { OverheadCategoryDetailModalComponent } from '../overhead-category-detail-modal/overhead-category-detail-modal.component';
import { DeleteOverheadCategory, GetAllOverheadCategories } from '../yuki.actions';
import { YukiState } from '../yuki.state';

@Component({
    selector: 'app-overhead-categories',
    templateUrl: './overhead-categories.component.html',
    styleUrls: ['./overhead-categories.component.scss'],
})
export class OverheadCategoriesComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    public DEFAULT_CATEGORY = 'Default';

    @Select(YukiState.overheadCategories) overheadCategories$: Observable<OverheadCategory[]>;
    overheadCategories: OverheadCategory[];

    constructor(private store: Store, private logger: NGXLogger, private toastr: ToastrService, private modalService: NgbModal) {}

    ngOnInit(): void {
        this.fetchData();
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    private fetchData() {
        this.subscriptions.add(
            this.overheadCategories$.subscribe((overheadCategories) => {
                if (!overheadCategories) {
                    this.logger.debug('Fetching overhead categories');
                    this.store.dispatch(new GetAllOverheadCategories());
                    return;
                }
                this.overheadCategories = overheadCategories;
            }),
        );
    }

    deleteOverheadCategory(overheadCategory: OverheadCategory) {
        this.store.dispatch(new DeleteOverheadCategory(overheadCategory.id)).subscribe(
            () => {
                this.toastr.success('Successfully removed overhead category');
            },
            () => {
                this.logger.error('Failed to remove overhead category');
                this.toastr.error('Oops, something went wrong, Please try again later...');
            },
        );
    }

    openAddOverheadCategoriesModal() {
        this.logger.debug('Opening addOverheadCategoriesModal');
        this.modalService.open(AddOverheadCategoriesModalComponent, {
            windowClass: 'modal-prompt',
            animation: false,
        });
    }

    openOverheadCategoryDetailModal(overheadCategory: OverheadCategory) {
        const modalRef = this.modalService.open(OverheadCategoryDetailModalComponent, { windowClass: 'modal-pane', animation: false });
        modalRef.componentInstance.overheadCategoryId = overheadCategory.id;
    }
}
