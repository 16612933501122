import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';
import AppUserDTO from 'src/app/models/User';
import { Register } from 'src/app/users/user.actions';

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    // Declare properties
    private user: AppUserDTO;
    public loading = false;
    public hasSubmitted = false;
    public inputIsWrong = false;
    public registerWentWrong = false;
    public useremail: string;
    registerFormGroup: FormGroup;
    errorMessage: string;
    connectionError: string;
    password = '';

    // Inject necessary services/dependacies
    constructor(private store: Store, private formBuilder: FormBuilder, private logger: NGXLogger) {}

    ngOnInit() {
        // Initialize and define the form fields and validators
        this.registerFormGroup = this.formBuilder.group({
            firstName: ['', [Validators.pattern('^[a-zA-Z]+$'), Validators.required]],
            lastName: ['', [Validators.pattern('^[a-zA-Z]+$'), Validators.required]],
            email: ['', [Validators.email, Validators.required]],
            password: ['', [Validators.minLength(8), Validators.required]],
        });
    }

    // Registers the user
    register() {
        this.logger.debug('Registration attempt');
        this.loading = true;

        // Check if form data is valid
        // if (!this.registerFormGroup.valid) {
        //   this.inputIsWrong = true;
        //   this.loading = false;
        //   return;
        // }

        // Disable form fields when form is submitted so a user doesn't accidently create two identical users
        this.registerFormGroup.disable();

        // Put form data in a model
        this.user = this.registerFormGroup.value;

        // Pass the data to the service
        this.subscriptions.add(
            this.store.dispatch(new Register(this.user)).subscribe(
                () => {
                    this.logger.debug('Registration succesful, awaiting email verification');
                    this.useremail = this.user.email;
                    this.hasSubmitted = true;
                },
                (error) => {
                    this.logger.error('Registration failed');

                    this.errorMessage = error?.error?.message ?? 'Error message was empty';

                    if (!this.errorMessage) {
                        this.connectionError = 'No connection to the server';
                    }

                    this.registerWentWrong = true;
                    this.inputIsWrong = true;

                    // Re-enable the form if registering wasn't succesful
                    this.registerFormGroup.enable();

                    this.loading = false;
                },
            ),
        );
    }

    onPasswordChange() {
        this.password = this.registerFormGroup.controls.password.value;
    }

    // Hides the error messages that appear after a user has submitted invalid data
    hideErrorMessage() {
        this.inputIsWrong = false;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
