import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Company } from 'src/app/models/Company';

import { ArchiveCompany, ClearCompany } from '../company.actions';
import { CompanyState } from '../company.state';

@Component({
    selector: 'app-company-delete-modal',
    templateUrl: './company-delete-modal.component.html',
    styleUrls: ['./company-delete-modal.component.scss'],
})
export class CompanyDeleteModalComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    company$: Observable<Company>;

    constructor(public activeModal: NgbActiveModal, private store: Store, private router: Router, private logger: NGXLogger) {}

    ngOnInit(): void {
        this.company$ = this.store.selectOnce(CompanyState.company);
    }

    archiveCompany() {
        this.logger.debug('Attempt to archive CompanyMember');
        this.subscriptions.add(
            this.company$
                .pipe(switchMap((company) => this.store.dispatch(new ArchiveCompany()).pipe(switchMap(() => this.router.navigate(['/onboarding'])))))
                .subscribe(() => {
                    this.logger.debug('CompanyMember archived succesfully');
                    this.activeModal.close();
                    this.store.dispatch(new ClearCompany());
                }),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
