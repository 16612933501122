import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';
import { Product } from 'src/app/models/Product';
import Project from 'src/app/models/Project';

import { DeleteProductFromProject, FetchProjects } from '../project.actions';

@Component({
    selector: 'app-project-delete-product',
    templateUrl: './project-delete-product.component.html',
    styleUrls: ['./project-delete-product.component.scss'],
})
export class ProjectDeleteProductComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();
    @Input() work: Project;
    @Input() product: Product;

    constructor(public activeModal: NgbActiveModal, private store: Store, private logger: NGXLogger) {}

    ngOnInit(): void {}

    deleteProjectProduct() {
        this.logger.debug('Attempt to delete product from work');
        this.subscriptions.add(
            this.store.dispatch(new DeleteProductFromProject(this.work.uuid, this.product.uuid)).subscribe(
                () => {
                    this.logger.debug('Succesfully deleted project from work');
                    this.store.dispatch(new FetchProjects());
                    this.activeModal.close();
                },
                (error) => {
                    this.logger.error('Failed to delete product from work');
                },
            ),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
