import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { append, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { tap } from 'rxjs';

import PlannedEvent from '../models/PlannedEvent';
import Project from '../models/Project';
import { AddPlanning, DeletePlanning, FetchPlanning, GetProjectById, UpdatePlanning } from './planning.actions';
import { PlanningService } from './planning.service';

export interface PlanningStateModel {
    plannedEvents: PlannedEvent[];
    selectedProject: Project;
}

@State<PlanningStateModel>({
    name: 'planning',
    defaults: {
        plannedEvents: null,
        selectedProject: null,
    },
})
@Injectable()
export class PlanningState {
    constructor(private planningService: PlanningService) {}

    @Selector()
    static PlannedEvents(state: PlanningStateModel): PlannedEvent[] {
        return state?.plannedEvents;
    }

    @Selector()
    static SelectedProject(state: PlanningStateModel): Project {
        return state?.selectedProject;
    }

    @Action(FetchPlanning)
    fetchWorkEmployeeLinks(ctx: StateContext<PlanningStateModel>, action: FetchPlanning) {
        return this.planningService.fetchPlanning().pipe(
            tap((workEmployeeLinks) => {
                ctx.setState(
                    patch<PlanningStateModel>({
                        plannedEvents: workEmployeeLinks,
                    }),
                );
            }),
        );
    }

    @Action(AddPlanning)
    addPlanning(ctx: StateContext<PlanningStateModel>, action: AddPlanning) {
        return this.planningService.addPlanning(action.newPlanning).pipe(
            tap((plannedEvent) => {
                ctx.setState(
                    patch<PlanningStateModel>({
                        plannedEvents: append<PlannedEvent>([plannedEvent]),
                    }),
                );
            }),
        );
    }

    @Action(UpdatePlanning)
    updatePlanning(ctx: StateContext<PlanningStateModel>, action: UpdatePlanning) {
        return this.planningService.updatePlanning(action.newPlanning).pipe(
            tap((plannedEvent) => {
                ctx.setState(
                    patch<PlanningStateModel>({
                        plannedEvents: updateItem<PlannedEvent>((p) => p.uuid === plannedEvent.uuid, plannedEvent),
                    }),
                );
            }),
        );
    }

    @Action(DeletePlanning)
    deletePlanning(ctx: StateContext<PlanningStateModel>, action: DeletePlanning) {
        return this.planningService.deletePlanning(action.id).pipe(
            tap(() => {
                ctx.setState(
                    patch<PlanningStateModel>({
                        plannedEvents: removeItem<PlannedEvent>((e) => e.uuid === action.id),
                    }),
                );
            }),
        );
    }

    @Action(GetProjectById)
    getProjectById(ctx: StateContext<PlanningStateModel>, action: GetProjectById) {
        return this.planningService.getProjectById(action.id).pipe(
            tap((project) =>
                ctx.setState(
                    patch<PlanningStateModel>({
                        selectedProject: project,
                    }),
                ),
            ),
        );
    }
}
