<form
    [formGroup]="leadToDealForm"
    (ngSubmit)="submit()"
    class="modal-wrapper"
    *ngIf="!isLoading">
    <div class="modal-header">
        <h2 class="modal-title">{{ lead?.email }}</h2>
        <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="activeModal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <p class="m-b-4">Alright, time for the next steps!<br /><small>You can convert a lead to a deal in your CRM.</small></p>

        <div class="form-group m-b-4 required">
            <label>Deal starts in stage:</label>
            <select formControlName="stage">
                <option
                    *ngFor="let stage of this.stages$ | async"
                    [ngValue]="stage">
                    {{ stage.name }}
                </option>
            </select>
        </div>

        <div class="form-group--toggle m-b-2">
            <label (click)="setToggle('createCustomer')"
                ><span
                    class="toggle"
                    [ngClass]="getToggle('createCustomer') ? 'toggle--active' : ''"></span>
                Also convert to a customer</label
            >
        </div>
        <div class="form-group--toggle m-b-2">
            <label (click)="setToggle('archiveLead')"
                ><span
                    class="toggle"
                    [ngClass]="getToggle('archiveLead') ? 'toggle--active' : ''"></span>
                Archive this lead</label
            >
        </div>
    </div>

    <div class="modal-footer">
        <button
            type="button"
            class="button button--outline"
            (click)="activeModal.close()">
            Close
        </button>
        <input
            [disabled]="!leadToDealForm.valid"
            type="submit"
            [ngClass]="!leadToDealForm.valid ? 'button button--disabled' : 'button button--primary'"
            value="Create" />
    </div>
</form>

<div
    class="modal-body"
    *ngIf="isLoading">
    <app-loading-message></app-loading-message>
</div>
