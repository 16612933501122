<form
    [formGroup]="newRecipientFormGroup"
    (ngSubmit)="addLead($event)"
    class="modal-wrapper">
    <div class="modal-header">
        <h2 class="modal-title">
            <!--{{firstname}} {{lastname}}-->
            Add to Mailchimp Audience
        </h2>
        <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="activeModal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <div class="row">
            <div class="col">
                <div class="form-group m-b-2">
                    <label for="firstname">First name </label>
                    <small
                        class="form-error"
                        *ngIf="!newRecipientFormGroup.get('firstname').valid && newRecipientFormGroup.get('firstname').touched"
                        >please enter a name</small
                    >
                    <input
                        id="firstname"
                        formControlName="firstname"
                        type="text"
                        placeholder="First name" />
                </div>
            </div>

            <div class="col">
                <div class="form-group m-b-2">
                    <label for="lastname">Last name </label>
                    <small
                        class="form-error"
                        *ngIf="!newRecipientFormGroup.get('lastname').valid && newRecipientFormGroup.get('lastname').touched"
                        >please enter a name</small
                    >
                    <input
                        id="lastname"
                        formControlName="lastname"
                        type="text"
                        placeholder="Last name" />
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <div class="form-group m-b-2">
                    <label for="email">Email </label>
                    <small
                        class="form-error"
                        *ngIf="!newRecipientFormGroup.get('email').valid && newRecipientFormGroup.get('email').touched"
                        >please enter a valid email</small
                    >
                    <input
                        id="email"
                        formControlName="email"
                        type="email"
                        placeholder="email" />
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <div class="form-group m-b-2">
                    <label for="phone">Phonenumber </label>
                    <input
                        id="phone"
                        formControlName="phone"
                        type="tel"
                        placeholder="phone" />
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <div class="form-group m-b-2">
                    <label for="list">Mailchimp Audience</label>
                    <select
                        id="list"
                        formControlName="list"
                        (change)="onChange($event.target.value)">
                        <option
                            *ngFor="let list of lists"
                            [ngValue]="list.id">
                            {{ list.name }}
                        </option>
                    </select>
                </div>
            </div>
        </div>

        <div
            class="row"
            *ngIf="newRecipientFormGroup.get('list').valid">
            <div class="col">
                <div class="form-group m-b-2">
                    <label>Tags</label>
                    <tag-input
                        id="tag"
                        formControlName="tag"
                        [(ngModel)]="tags"
                        [addOnBlur]="true"
                        [clearOnBlur]="true">
                        <tag-input-dropdown
                            [autocompleteItems]="allTags"
                            [appendToBody]="false"
                            placeholder="">
                        </tag-input-dropdown>
                    </tag-input>
                    <span class="form-helptext">Enter the tags you want to give to the added members.</span>
                </div>
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <input
            [ngClass]="!newRecipientFormGroup.valid ? 'button button--disabled w-100' : 'button button--primary w-100'"
            type="submit"
            value="Add"
            [disabled]="!newRecipientFormGroup.valid" />
    </div>
</form>
