import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';

import LogItem from '../../models/Logbook/LogItem';
import LogItemsForObject from '../../models/Logbook/LogItemsForObject';
import { ConfirmationModalComponent } from '../../shared/confirmation-modal/confirmation-modal.component';
import { AddLogItemModalComponent } from '../add-log-item-modal/add-log-item-modal.component';
import { DeleteLogItem, GetLogItemsByObjectId } from '../logbook.actions';
import { LogbookState } from '../logbook.state';

@Component({
    selector: 'app-logbook-overview',
    templateUrl: './logbook-overview.component.html',
    styleUrls: ['./logbook-overview.component.scss'],
})
export class LogbookOverviewComponent implements OnInit, OnDestroy {
    @Input() objectId: string;

    @Select(LogbookState.LogItems) logItems$: Observable<LogItemsForObject[]>;
    logItems: LogItem[];

    private subscriptions = new Subscription();
    constructor(private modalService: NgbModal, private store: Store) {}

    ngOnInit(): void {
        this.fetchData();
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    openAddLogItemModal() {
        const modalRef = this.modalService.open(AddLogItemModalComponent, { animation: false, windowClass: 'modal-prompt' });
        modalRef.componentInstance.objectId = this.objectId;
    }

    openEditLogItemModal(logItem: LogItem) {
        const modalRef = this.modalService.open(AddLogItemModalComponent, { animation: false, windowClass: 'modal-prompt' });
        modalRef.componentInstance.objectId = this.objectId;
        modalRef.componentInstance.logItemToUpdate = logItem;
    }

    openDeleteLogItemModal(logItem: LogItem) {
        const modalRef = this.modalService.open(ConfirmationModalComponent, { windowClass: 'modal-prompt', animation: false });
        modalRef.componentInstance.type = 'Delete';
        modalRef.componentInstance.title = 'Delete logbook entry';
        modalRef.componentInstance.htmlMessage = `<p>Are you sure you want to delete this logbook entry?</p>`;

        this.subscriptions.add(
            modalRef.componentInstance.closeEvent.subscribe((value) => {
                if (value) {
                    this.store.dispatch(new DeleteLogItem(logItem.id));
                }
            }),
        );
    }

    private fetchData() {
        this.subscriptions.add(
            this.logItems$.subscribe((logItemsForObject) => {
                const logItem: LogItemsForObject = logItemsForObject?.find((logItemsForObject) => logItemsForObject.objectId === this.objectId);

                if (!logItem) {
                    this.store.dispatch(new GetLogItemsByObjectId(this.objectId));
                    return;
                }
                this.logItems = logItem.logItems;
            }),
        );
    }
}
