import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

import { SharedModule } from '../shared/shared.module';
import { AddLogItemModalComponent } from './add-log-item-modal/add-log-item-modal.component';
import { LogCategoriesComponent } from './log-categories/log-categories.component';
import { LogbookOverviewComponent } from './logbook-overview/logbook-overview.component';

@NgModule({
    declarations: [LogbookOverviewComponent, AddLogItemModalComponent, LogCategoriesComponent],
    imports: [CommonModule, ReactiveFormsModule, SharedModule, NgbDropdownModule],
    exports: [LogbookOverviewComponent],
})
export class LogbookModule {}
