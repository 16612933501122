import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { delay, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { AuthenticationService } from '../authentication/authentication.service';
import DefaultProjectEmployeeLink from '../models/DefaultProjectEmployeeLink';
import DefaultProjectEmployeeLinkDTO from '../models/DefaultProjectEmployeeLinkDTO';
import { Product } from '../models/Product';
import Project from '../models/Project';
import ProjectEmployeeLink from '../models/ProjectEmployeeLink';
import { Status } from '../models/Status';
import { Resume } from '../models/resume/Resume';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class ProjectService {
    httpOptions = {};
    private projects: Observable<Project[]>;

    constructor(private httpClient: HttpClient, private authenticationService: AuthenticationService) {
        this.httpOptions = authenticationService.getHeaders();
        this.projects = this.getAllProject();
    }

    addProject(project: Project): Observable<Project> {
        return this.httpClient.post<Project>(environment.baseUrl + '/project', project, this.httpOptions);
    }

    getAllProject(): Observable<Project[]> {
        if (this.getTokenIfExists() != '') {
            return this.httpClient.get<Project[]>(environment.baseUrl + `/timesheetsmanagement/projects${this.getTokenIfExists()}`, this.httpOptions);
        }
        return this.httpClient.get<Project[]>(environment.baseUrl + '/project', this.httpOptions);
    }

    getAllProjectsForOverview(): Observable<Project[]> {
        return this.httpClient.get<Project[]>(`${environment.baseUrl}/project/overview-list`, this.httpOptions);
    }

    getSimpleProjectList(): Observable<Project[]> {
        return this.httpClient.get<Project[]>(`${environment.baseUrl}/project/simple-list`, this.httpOptions);
    }

    getProjectDetail(uuid: string): Observable<Project> {
        return this.httpClient.get<Project>(environment.baseUrl + '/project/' + uuid + '/project-detail', this.httpOptions);
    }

    updateProject(uuid: string, project: Project): Observable<Project> {
        return this.httpClient.put<Project>(environment.baseUrl + '/project/' + uuid, project, this.httpOptions);
    }

    archiveProject(projectUuid: string) {
        return this.httpClient.put<Project>(environment.baseUrl + '/project/' + projectUuid + '/archive-project', {}, this.httpOptions);
    }

    assignResumeToProject(projectUuid: string, selectedResumes: Resume[]) {
        return this.httpClient.post<Resume[]>(environment.baseUrl + '/project/' + projectUuid, selectedResumes, this.httpOptions);
    }

    getAssignedResumeList(projectUuid: string) {
        return this.httpClient.get<Resume[]>(environment.baseUrl + '/project/' + projectUuid + '/project-assigned-resumes-list', this.httpOptions);
    }

    deleteAssignedResumeFromProject(projectAssignmentUuid: string) {
        return this.httpClient.delete<string>(environment.baseUrl + '/project/' + projectAssignmentUuid + '/delete-assigned-resume', this.httpOptions);
    }

    getAllPhases() {
        return this.httpClient.get<Status[]>(environment.baseUrl + '/project/project-status-list', this.httpOptions);
    }

    updatePhaseFromAssignedResume(projectAssignmentUuid: string, projectPhaseUuid: string) {
        return this.httpClient.put<Resume>(environment.baseUrl + '/project/' + projectAssignmentUuid + '/' + projectPhaseUuid, {}, this.httpOptions);
    }

    updateProjectStatus(projectStatusUuid: string, status: Status): Observable<Status> {
        return this.httpClient.put<Status>(environment.baseUrl + '/project/' + projectStatusUuid + '/edit-project-status', status, this.httpOptions);
    }

    addProjectStatus(companyUuid: string, status: Status): Observable<Status> {
        return this.httpClient.post<Status>(environment.baseUrl + '/project/' + companyUuid + '/add-project-status', status, this.httpOptions);
    }

    deleteProjectStatus(projectStatusUuid: string): Observable<string> {
        return this.httpClient.delete<string>(environment.baseUrl + '/project/' + projectStatusUuid + '/delete-project-status', this.httpOptions);
    }

    updateFinishedOfProject(projectUuid: string) {
        return this.httpClient.put<string>(environment.baseUrl + `/project/project-finished-update/${projectUuid}`, {}, this.httpOptions);
    }

    addProductToProject(projectUuid: string, products: string) {
        const params = new HttpParams().set('products', products);
        return this.httpClient.post<any>(environment.baseUrl + '/project/' + projectUuid + '/add-product?products=' + products, {}, this.httpOptions);
    }

    getProjectRelatedProducts(projectUuid: string): Observable<Product[]> {
        return this.httpClient.get<Product[]>(environment.baseUrl + '/project/' + projectUuid + '/product-list', this.httpOptions);
    }

    deleteProductFromProject(projectUuid: string, productUuid: string): Observable<string> {
        return this.httpClient.delete<string>(environment.baseUrl + '/project/' + projectUuid + '/' + productUuid + '/delete-product', this.httpOptions);
    }

    downloadProjectPdf(projectUuid): any {
        return this.httpClient.get<any>(environment.baseUrl + '/project/' + projectUuid + '/pdf', this.httpOptions);
    }

    addEmployeeLink(projectUuid, employeeUuid, pricePerHour) {
        return this.httpClient.put<ProjectEmployeeLink>(
            environment.baseUrl + '/project/employee/link',
            { projectUuid: projectUuid, employeeUuid: employeeUuid, pricePerHour: pricePerHour },
            this.httpOptions,
        );
    }

    removeEmployeeLink(projectUuid, employeeUuid) {
        return this.httpClient.put<void>(
            environment.baseUrl + '/project/employee/unlink',
            { projectUuid: projectUuid, employeeUuid: employeeUuid, pricePerHour: 0 },
            this.httpOptions,
        );
    }

    getTokenIfExists() {
        return sessionStorage.getItem('timesheetToken') ? `/${sessionStorage.getItem('timesheetToken')}` : ``;
    }

    removeEmployeeLinkById(linkId: string) {
        return this.httpClient.delete(`${environment.baseUrl}/project/employee/unlink/${linkId}`, this.httpOptions);
    }

    addDefaultEmployees(defaultEmployees: DefaultProjectEmployeeLinkDTO) {
        return this.httpClient.post<DefaultProjectEmployeeLink[]>(`${environment.baseUrl}/project/default-employee-link`, defaultEmployees, this.httpOptions);
    }

    deleteDefaultEmployee(uuid: string) {
        return this.httpClient.delete(`${environment.baseUrl}/project/default-employee-link/${uuid}`, this.httpOptions);
    }

    checkIfProjectTitleExists(title: string, uuid:string): Observable<boolean> {
        return this.projects.pipe(
            map((projects) => projects.some((project) => project.title.toLowerCase() === title.toLowerCase()
            && project.uuid !== uuid))
          );
    } 
}
