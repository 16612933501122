<div class="d-flex flex-row align-items-center main-container">
    <ng-container *ngIf="showPercentage">{{ percentage }}%</ng-container>
    <div class="spinner-container">
        <mat-spinner
            class="background"
            mode="determinate"
            diameter="25"
            strokeWidth="5"
            value="100">
        </mat-spinner>
        <mat-progress-spinner
            class="foreground"
            mode="determinate"
            strokeWidth="5"
            value="{{ percentage }}"
            diameter="25"></mat-progress-spinner>
    </div>
</div>
