import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { Applicant } from 'src/app/models/Applicants/Applicant';
import { ApplicantLane } from 'src/app/models/Applicants/ApplicantLane';
import ApplicantReasonOfLoss from 'src/app/models/Applicants/ApplicantReasonOfLoss';
import { getPriorityLevel } from 'src/app/models/LineItemPriority';
import { CustomRolesState } from 'src/app/settings/roles/custom.roles.state';
import { AddLaneModalComponent } from 'src/app/shared/add-lane-modal/add-lane-modal.component';
import { SubmitReasonModalComponent } from 'src/app/shared/reason-of-loss/submit-reason-modal/submit-reason-modal.component';
import { hexToRgb } from 'src/app/shared/utils/color-utils';

import { AddApplicantModalComponent } from '../add-applicant-modal/add-applicant-modal.component';
import { ApplicantDetailModalComponent } from '../applicant-detail-modal/applicant-detail-modal.component';
import { AddApplicantLane, FetchApplicantLanes, FetchApplicantReasonsOfLoss, MoveApplicant } from '../applicant.actions';
import { ApplicantState } from '../applicant.state';

@Component({
    selector: 'app-applicant-kanban',
    templateUrl: './applicant-kanban.component.html',
    styleUrls: ['./applicant-kanban.component.scss'],
})
export class ApplicantKanbanComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Select(ApplicantState.Lanes) lanes$: Observable<ApplicantLane[]>;
    @Select(ApplicantState.Applicants) applicants$: Observable<Applicant[]>;
    @Select(ApplicantState.ReasonsOfLoss) reasonsOfLoss$: Observable<ApplicantReasonOfLoss[]>;
    @Select(CustomRolesState.AllowedActions) allowedActions$: Observable<any>;

    reasonsOfLoss: ApplicantReasonOfLoss[] = [];

    stopRecursiveness = false;
    isUserInput = false;

    movementAllowed = false;
    createdOnDateFilter: Date | undefined = undefined;
    filter: string;

    constructor(private titleService: Title, private toastr: ToastrService, private store: Store, private modalService: NgbModal, private logger: NGXLogger) {
        this.titleService.setTitle('Applicants overview');
    }

    ngOnInit(): void {
        this.store.dispatch(new FetchApplicantReasonsOfLoss());
        this.store.dispatch(new FetchApplicantLanes());
        this.subscriptions.add(
            this.allowedActions$.subscribe((allowedActions) => {
                if (allowedActions['role'] == 'ROLE_SUPER_ADMIN' || allowedActions['role'] == 'ROLE_OWNER') {
                    this.movementAllowed = true;
                } else if (allowedActions['PARTNERS']) {
                    if (allowedActions['PARTNERS'].includes('EDIT')) {
                        this.movementAllowed = true;
                    }
                }
            }),
        );
        this.subscriptions.add(
            this.reasonsOfLoss$.subscribe((reasons) => {
                this.reasonsOfLoss = reasons;
            }),
        );
    }

    drop(event: CdkDragDrop<string[]>) {
        this.isUserInput = true;
        if (!this.movementAllowed) {
            this.toastr.warning('You dont have the permission to move deals.');
        }
        if (event.previousContainer !== event.container && this.movementAllowed) {
            const applicant = event.previousContainer.data[event.previousIndex] as unknown as Applicant;
            this.subscriptions.add(
                this.lanes$.subscribe((lanes) => {
                    lanes.forEach((l) => {
                        if (l.uuid == event.container.id && this.isUserInput && !this.stopRecursiveness) {
                            if (l.type.toString() == 'LOSS') {
                                this.logger.debug('Opening submit reason of loss modal');
                                const modalRef = this.modalService.open(SubmitReasonModalComponent, { windowClass: 'modal-prompt' });
                                console.log(this.reasonsOfLoss);
                                modalRef.componentInstance.reasons = this.reasonsOfLoss;
                                modalRef.componentInstance.reasonEmitter.subscribe((body) => {
                                    this.logger.debug('Moving applicant');
                                    if (typeof body == 'string') {
                                        this.store.dispatch(new MoveApplicant(l.uuid, applicant.uuid, body)).subscribe();
                                    } else {
                                        this.store.dispatch(new MoveApplicant(l.uuid, applicant.uuid, body.reason, body.uuid)).subscribe();
                                    }
                                });
                            } else {
                                if (!this.stopRecursiveness) {
                                    if (l.type.toString() == 'WIN') {
                                        console.log('won'); //TODO: should ask to create a employee
                                    }
                                    this.stopRecursiveness = true;
                                    this.logger.debug('Attempt to move applicant');
                                    this.store.dispatch(new MoveApplicant(l.uuid, applicant.uuid)).subscribe(() => {
                                        this.stopRecursiveness = false;
                                    });
                                }
                            }
                        }
                    });
                }),
            );
        }
        this.isUserInput = false;
    }

    openApplicantDetailModal(applicant: Applicant) {
        const modalRef = this.modalService.open(ApplicantDetailModalComponent, {
            windowClass: 'modal-pane',
            animation: false,
        });
        modalRef.componentInstance.applicant = applicant;
    }

    openAddLaneModal() {
        const modalRef = this.modalService.open(AddLaneModalComponent, {
            windowClass: 'modal-prompt',
        });
        this.subscriptions.add(
            modalRef.componentInstance.emitObject.subscribe((lane: ApplicantLane) => {
                this.store.dispatch(new AddApplicantLane(lane)).subscribe();
            }),
        );
    }

    openAddApplicantModal(lane?: ApplicantLane) {
        const modalRef = this.modalService.open(AddApplicantModalComponent, {
            windowClass: 'modal-pane',
            animation: false,
        });
        if (lane) modalRef.componentInstance.laneUuid = lane.uuid;
    }

    getCorrectStyling(color: string) {
        const rgb = hexToRgb(color);
        if (rgb === null) {
            return { background: color };
        }
        return { background: 'rgba(' + rgb.r + ',' + rgb.g + ',' + rgb.b + ',0.05)' };
    }

    getSortedApplicants(lane: ApplicantLane) {
        return lane.applicants.sort((a, b) => {
            return getPriorityLevel(b.priority) - getPriorityLevel(a.priority);
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
