import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { NgbDropdownModule, NgbModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { TagInputModule } from 'ngx-chips';
import { OrderModule } from 'ngx-order-pipe';
import { NgPipesModule } from 'ngx-pipes';
import { NgxTrimDirectiveModule } from 'ngx-trim-directive';

import { ContactpersonDeleteModalComponent } from '../contacts/contactperson-delete-modal/contactperson-delete-modal.component';
import { LogbookModule } from '../logbook/logbook.module';
import { SharedModule } from '../shared/shared.module';
import { PartnerAddModalComponent } from './partner-add-modal/partner-add-modal.component';
import { PartnerContactpersonListComponent } from './partner-contactperson-list/partner-contactperson-list.component';
import { PartnerDeleteModalComponent } from './partner-delete-modal/partner-delete-modal.component';
import { PartnerDetailsModalComponent } from './partner-details-modal/partner-details-modal.component';
import { PartnerListComponent } from './partner-list/partner-list.component';
import { PartnerUpdateModalComponent } from './partner-update-modal/partner-update-modal.component';
import { routing } from './partner.routes';
import { PartnersOverviewComponent } from './partners-overview/partners-overview.component';
import { PartnersSubnavComponent } from './partners-subnav/partners-subnav.component';

@NgModule({
    declarations: [
        PartnersOverviewComponent,
        PartnersSubnavComponent,
        PartnerAddModalComponent,
        PartnerListComponent,
        PartnerUpdateModalComponent,
        PartnerDeleteModalComponent,
        PartnerContactpersonListComponent,
        ContactpersonDeleteModalComponent,
        PartnerDetailsModalComponent,
    ],
    imports: [
        CommonModule,
        SharedModule,
        routing,
        ReactiveFormsModule,
        FormsModule,
        NgbModule,
        NgbDropdownModule,
        NgbNavModule,
        NgxTrimDirectiveModule,
        NgPipesModule,
        OrderModule,
        TagInputModule,
        MatTableModule,
        CdkTableModule,
        MatSortModule,
        LogbookModule,
    ],
    exports: [PartnersSubnavComponent, PartnerContactpersonListComponent, MatTableModule, CdkTableModule, MatSortModule, PartnerAddModalComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PartnersModule {}
