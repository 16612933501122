import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';

import { TurnoverData } from '../../models/Financial/TurnoverData';
import { TurnoverDataPerMonth } from '../../models/Financial/TurnoverDataPerMonth';
import { InvoiceType } from '../../models/GlobalFile';
import { InformationForFinancialData } from '../../models/InformationForFinancialData';
import { DATASETS } from '../employee-graph/employee-graph.component';
import { GetAllFinancialInformationForCategory, GetAllFinancialInformationForEmployee, GetAllFinancialInformationForProject } from '../financial.actions';
import { FinancialState } from '../financial.state';

@Component({
    selector: 'app-chart-information-modal',
    templateUrl: './chart-information-modal.component.html',
    styleUrls: ['./chart-information-modal.component.scss'],
})
export class ChartInformationModalComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input() month: number;
    @Input() year: number;
    @Input() dataset: DATASETS;
    @Input() chartInformationObjectType: ChartInformationObjectType;
    @Input() objectId: string;
    @Input() title: string;
    @Input() turnoverDataInformation: TurnoverDataPerMonth;

    saleInformationDatasource: MatTableDataSource<InformationForFinancialData> = new MatTableDataSource<InformationForFinancialData>();
    saleDisplayedColumns = ['title', 'invoiceNumber', 'amount'];

    purchaseInformationDatasource: MatTableDataSource<InformationForFinancialData> = new MatTableDataSource<InformationForFinancialData>();
    purchaseDisplayedColumns = ['title', 'amount'];

    turnoverInformationDatasource: MatTableDataSource<TurnoverData> = new MatTableDataSource<TurnoverData>();
    turnoverDisplayedColumns = ['title', 'amount'];

    @Select(FinancialState.informationForFinancialData) informationForFinancialData$: Observable<InformationForFinancialData[]>;
    date: Date;

    @Input() purchaseInformation: InformationForFinancialData[];
    @Input() saleInformation: InformationForFinancialData[];

    @ViewChild('turnoverSort') turnoverSort: MatSort = new MatSort();
    private sSort: MatSort;
    private pSort: MatSort;
    private subscriptions = new Subscription();

    constructor(public activeModal: NgbActiveModal, private store: Store) {}

    @ViewChild('saleSort') set saleSort(ms: MatSort) {
        this.sSort = ms;
        this.setDataSourceAttributesForSaleSort();
    }

    @ViewChild('purchaseSort') set purchaseSort(ms: MatSort) {
        this.pSort = ms;
        this.setDataSourceAttributesForPurchaseSort();
    }

    setDataSourceAttributesForSaleSort() {
        this.saleInformationDatasource.sort = this.sSort;
    }

    setDataSourceAttributesForPurchaseSort() {
        this.purchaseInformationDatasource.sort = this.pSort;
    }

    ngAfterViewInit() {
        this.turnoverInformationDatasource.sort = this.turnoverSort;
    }

    ngOnInit(): void {
        this.createMatTables();

        this.date = new Date(this.year, this.month);
        //When the chartInformationObjectType is TOTAL the information should be pased along
        if (this.objectId && this.chartInformationObjectType !== ChartInformationObjectType.TOTAL) {
            if (this.chartInformationObjectType === ChartInformationObjectType.EMPLOYEE)
                this.store.dispatch(new GetAllFinancialInformationForEmployee(this.objectId, this.month + 1, this.year));
            else if (this.chartInformationObjectType === ChartInformationObjectType.PROJECT)
                this.store.dispatch(new GetAllFinancialInformationForProject(this.objectId, this.month + 1, this.year));
            else if (this.chartInformationObjectType === ChartInformationObjectType.CATEGORY)
                this.store.dispatch(new GetAllFinancialInformationForCategory(this.objectId, this.month + 1, this.year));

            this.subscriptions.add(
                this.informationForFinancialData$.subscribe((information) => {
                    this.purchaseInformation = information?.filter((info) => info.invoiceType === InvoiceType[InvoiceType.PURCHASE]);
                    this.saleInformation = information?.filter((info) => info.invoiceType === InvoiceType[InvoiceType.SALE]);

                    this.purchaseInformationDatasource.data = this.purchaseInformation;
                    this.saleInformationDatasource.data = this.saleInformation;

                    this.purchaseInformationDatasource.sort = this.pSort;
                    this.saleInformationDatasource.sort = this.sSort;
                }),
            );
        }
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    calculateTotal(information: InformationForFinancialData[]): number {
        return information.reduce((total: number, information: InformationForFinancialData) => total + information.amount, 0);
    }

    calculateTotalForTurnover(): number {
        return this.turnoverDataInformation.turnoverData.reduce((total: number, turnoverData: TurnoverData) => total + turnoverData.amount, 0);
    }

    calculateMargin(): number {
        const income: number = this.calculateTotal(this.saleInformation);
        const cost: number = this.calculateTotal(this.purchaseInformation);

        return income - cost;
    }

    private createMatTables() {
        if (this.turnoverDataInformation) {
            this.turnoverInformationDatasource.data = this.turnoverDataInformation.turnoverData;
        }
    }
}

export enum ChartInformationObjectType {
    EMPLOYEE,
    PROJECT,
    CATEGORY,
    TURNOVER,
    TOTAL,
}
