import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-searchbar',
    templateUrl: './searchbar.component.html',
    styleUrls: ['./searchbar.component.scss'],
})
export class SearchbarComponent implements OnInit {
    /**
     * since we want to use the "banana in the box" syntx [(value)]="val"
     * in the host component we should have both "@Input() value" and "@Output() valueChange"
     * NOTE: the "Change" suffix for the @Output() is important.
     */
    @Input() value: string;
    @Input() placeholder = 'Search...';
    @Output() valueChange = new EventEmitter<string>();

    ngOnInit(): void {
        this.value = '';
    }

    updateValue(value) {
        this.value = value;
        this.valueChange.emit(value);
    }
}
