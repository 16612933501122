<app-app-holder>
    <app-navbar></app-navbar>

    <app-sidebar>
        <app-exact-overview></app-exact-overview>
    </app-sidebar>

    <app-content
        *appIsPermitted="{
            module: 'EXACT',
            operator: 'AND',
            actions: ['READ']
        }">
        <app-exact-not-logged-in (credentialsIsset)="checkCredentials($event)"></app-exact-not-logged-in>
        <div
            class="app__title"
            *ngIf="isset">
            <h1>Exact - Relations</h1>
            <span>
                <a
                    class="button button--primary"
                    (click)="openAddRelationModal()"
                    *appIsPermitted="{
                        module: 'EXACT',
                        operator: 'AND',
                        actions: ['CREATE']
                    }"
                    >New relation</a
                >
            </span>
        </div>

        <div *ngIf="isset && !isLoading">
            <app-searchbar [(value)]="filter"></app-searchbar>
            <nav
                ngbNav
                #nav="ngbNav"
                class="tabs__modal">
                <ng-container ngbNavItem>
                    <a ngbNavLink>All</a>
                    <ng-template ngbNavContent>
                        <app-exact-relations-list
                            [filter]="filter"
                            [relations]="relations$ | async"></app-exact-relations-list>
                    </ng-template>
                </ng-container>

                <ng-container ngbNavItem>
                    <a ngbNavLink>Suspects</a>
                    <ng-template ngbNavContent>
                        <app-exact-relations-list
                            [filter]="filter"
                            [relations]="relations$ | async | filterBy: ['Status']:'S'"></app-exact-relations-list>
                    </ng-template>
                </ng-container>

                <ng-container ngbNavItem>
                    <a ngbNavLink>Prospects</a>
                    <ng-template ngbNavContent>
                        <app-exact-relations-list
                            [filter]="filter"
                            [relations]="relations$ | async | filterBy: ['Status']:'P'"></app-exact-relations-list>
                    </ng-template>
                </ng-container>

                <ng-container ngbNavItem>
                    <a ngbNavLink>Customers</a>
                    <ng-template ngbNavContent>
                        <app-exact-relations-list
                            [filter]="filter"
                            [relations]="relations$ | async | filterBy: ['Status']:'C'"></app-exact-relations-list>
                    </ng-template>
                </ng-container>

                <ng-container ngbNavItem>
                    <a ngbNavLink>Suppliers</a>
                    <ng-template ngbNavContent>
                        <app-exact-relations-list
                            [filter]="filter"
                            [relations]="relations$ | async | filterBy: ['IsSupplier']:true"></app-exact-relations-list>
                    </ng-template>
                </ng-container>

                <ng-container ngbNavItem>
                    <a ngbNavLink>Accountants</a>
                    <ng-template ngbNavContent>
                        <app-exact-relations-list
                            [filter]="filter"
                            [relations]="relations$ | async | filterBy: ['IsAccountant']:true"></app-exact-relations-list>
                    </ng-template>
                </ng-container>

                <ng-container ngbNavItem>
                    <a ngbNavLink>Resellers</a>
                    <ng-template ngbNavContent>
                        <app-exact-relations-list
                            [filter]="filter"
                            [relations]="relations$ | async | filterBy: ['IsReseller']:true"></app-exact-relations-list>
                    </ng-template>
                </ng-container>

                <ng-container ngbNavItem>
                    <a ngbNavLink>Competitors</a>
                    <ng-template ngbNavContent>
                        <app-exact-relations-list
                            [filter]="filter"
                            [relations]="relations$ | async | filterBy: ['IsCompetitor']:true"></app-exact-relations-list>
                    </ng-template>
                </ng-container>
            </nav>

            <div>
                <div [ngbNavOutlet]="nav"></div>
            </div>
        </div>
    </app-content>
</app-app-holder>
