import CrmDeal from './CrmDeal';

/**
 * is currently not updated (should extend LaneModel interface)
 */
export default interface CrmStage {
    uuid: string;
    name: string;
    probability: number;
    rottingIn: number;
    sequence: number;
    type: CRMStageType;
    crmDeals: CrmDeal[];
    description: string;
}

export enum CRMStageType {
    WIN = 'WIN',
    LOSS = 'LOSS',
    NORMAL = 'NORMAL',
    OTHER = 'OTHER',
    ARCHIVED = 'ARCHIVED',
}
