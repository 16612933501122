<app-app-holder>
    <app-navbar></app-navbar>

    <app-content>
        <div>
            <div class="app__title">
                <h1>
                    Projects overview
                    <span *ngIf="projects$ | async as projects">({{ projects.length }})</span>
                </h1>
                <span>
                    <span ngbDropdown>
                        <button
                            class="button button--icon button--dropdown"
                            ngbDropdownToggle>
                            Options
                        </button>

                        <span
                            aria-labelledby="dropdownMenuButton"
                            class="dropdown-menu dropdown-menu-right"
                            ngbDropdownMenu>
                            <a
                                (click)="switchView('calendar')"
                                class="dropdown-item"
                                id="calendar"
                                >Planning annual</a
                            >
                            <a
                                (click)="switchView('timeline')"
                                class="dropdown-item"
                                id="timeline"
                                >Planning monthly</a
                            >
                            <a
                                (click)="switchView('list')"
                                class="dropdown-item"
                                >Project overview</a
                            >

                            <a
                                (click)="openAddProjectModal()"
                                *appIsPermitted="{
                                    module: 'PROJECTS',
                                    operator: 'AND',
                                    actions: ['CREATE']
                                }"
                                class="dropdown-item">
                                Add project
                            </a>

                            <app-global-export-csv
                                *ngIf="projects$ | async"
                                [componentName]="this.constructor.name"
                                [exporter]="exporter"
                                [type]="'dropdown-item'"></app-global-export-csv>
                        </span>
                    </span>
                </span>
                <span> </span>
            </div>

            <ng-container *ngIf="projectListShown; else showPlanning">
                <span
                    *appIsPermitted="{
                        module: 'PROJECTS',
                        operator: 'AND',
                        actions: ['READ']
                    }">
                    <div *ngIf="projects$ | async as projects; else loading">
                        <nav
                            #nav="ngbNav"
                            ngbNav>
                            <ng-container ngbNavItem>
                                <a ngbNavLink>Ongoing ({{ (projects | arrayFilter: filterActiveProjects).length }})</a>
                                <ng-template ngbNavContent>
                                    <app-project-list
                                        (infoModalClosed)="fetchProjects()"
                                        *ngIf="projects && (projects | arrayFilter: filterActiveProjects).length > 0; else empty"
                                        [type]="ongoing">
                                    </app-project-list>
                                </ng-template>
                            </ng-container>

                            <ng-container ngbNavItem>
                                <a *ngIf="projectsWithMaintenance?.length > 0" ngbNavLink>
                                    Has maintenance contract ({{ (projects | arrayFilter: filterActiveProjectsWithMaintenance).length }})
                                </a>
                                <ng-template ngbNavContent>
                                    <app-project-list [type]="haveMaintenance"></app-project-list>
                                </ng-template>
                            </ng-container>

                            <ng-container ngbNavItem>
                                <a *ngIf="finishedProjects?.length > 0" ngbNavLink>
                                    Finished ({{ (projects | arrayFilter: filterFinishedWorks).length }})
                                </a>
                                <ng-template ngbNavContent>
                                    <app-project-list [type]="finished"></app-project-list>
                                </ng-template>
                            </ng-container>

                            <ng-container ngbNavItem>
                                <a *ngIf="archivedProjects?.length > 0" ngbNavLink>Archived ({{archivedProjects.length}})</a>
                                <ng-template ngbNavContent>
                                    <app-project-list [type]="archived"></app-project-list>
                                </ng-template>
                            </ng-container>
                        </nav>
                        <div [ngbNavOutlet]="nav"></div>
                    </div>
                </span>
            </ng-container>
            <ng-template #showPlanning>
                <app-planning-timeline
                    *appIsPermitted="{
                        module: 'PROJECTS',
                        operator: 'AND',
                        actions: ['READ']
                    }"></app-planning-timeline>
            </ng-template>
        </div>
    </app-content>

    <ng-template #empty>
        <app-message-empty
            [htmlSubtitle]="'You currently have no projects.<br/>Start by adding your first project!'"
            [icon]="'../../assets/img/open-box.png'"
            [title]="'No projects..'">
            <a
                (click)="openAddProjectModal()"
                *appIsPermitted="{
                    module: 'PROJECTS',
                    operator: 'AND',
                    actions: ['CREATE']
                }"
                class="button button--primary"
                >Add project</a
            >
        </app-message-empty>
    </ng-template>

    <ng-template #loading>
        <app-loading-message></app-loading-message>
    </ng-template>
</app-app-holder>
