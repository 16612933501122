import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription, of } from 'rxjs';
import FileSystemEntity from 'src/app/models/FileSystemEntity';
import { Product } from 'src/app/models/Product';
import Project from 'src/app/models/Project';
import { FetchProducts } from 'src/app/products/product.actions';
import { ProductState } from 'src/app/products/product.state';
import { FetchProjects } from 'src/app/projects/project.actions';
import { ProjectState } from 'src/app/projects/project.state';
import { ConfirmationModalComponent } from 'src/app/shared/confirmation-modal/confirmation-modal.component';

import { DeleteFileSystemEntity, UpdateDocument } from '../documents.actions';
import { DocumentService } from '../documents.service';
import { DeactivationGuarded } from './EditorRouteGuard.guard';

@Component({
    selector: 'app-documents-doc-detail',
    templateUrl: './documents-doc-detail.component.html',
    styleUrls: ['./documents-doc-detail.component.scss'],
})
export class DocumentsDocDetailComponent implements OnInit, DeactivationGuarded, OnDestroy {
    private subscriptions = new Subscription();

    @Select(ProjectState.projects) allWorks$: Observable<Project[]>;
    @Select(ProductState.Products) allProducts$: Observable<Product[]>;

    public document: FileSystemEntity;
    isLoading = true;
    editor = ClassicEditor;
    name: string;
    editName = false;

    relations: any[];
    projects: Project[];
    products: Product[];
    loadingRelations = true;

    constructor(
        private router: Router,
        private store: Store,
        private toastr: ToastrService,
        private route: ActivatedRoute,
        private documentService: DocumentService,
        public modalService: NgbModal,
        private sanitizer: DomSanitizer,
        private logger: NGXLogger,
    ) {}

    ngOnInit(): void {
        this.subscriptions.add(
            this.route.params.subscribe((params) => {
                this.logger.debug('Attempt to fetch document and relations');
                this.documentService.getDocument(params.id).subscribe((doc) => {
                    this.document = doc;
                    this.name = doc.name;
                    this.isLoading = false;
                    this.documentService.getRelations(this.document.uuid).subscribe((res) => {
                        this.relations = res;
                        this.loadRelations();
                    });
                });
            }),
        );
    }

    save() {
        this.editName = false;
        this.document.content = this.editor.getData();
        this.document.name = this.name;
        this.logger.debug('Attempt to save document');
        this.subscriptions.add(
            this.store.dispatch(new UpdateDocument(this.document)).subscribe(
                () => {
                    this.logger.debug('Succesfully saved document');
                    this.toastr.success('File successfully updated.');
                },
                (err) => {
                    this.logger.debug('Failed to save document');
                    this.toastr.error('Oops, something went wrong.');
                },
            ),
        );
    }

    public onReady(editor) {
        this.editor = editor;
        editor.ui.getEditableElement().parentElement.insertBefore(editor.ui.view.toolbar.element, editor.ui.getEditableElement());
    }

    canDeactivate(): boolean | Observable<boolean> {
        if (this.document.content != this.editor.getData() || this.name != this.document.name) {
            this.logger.debug('Opening confirmationModal - canDeactivate');
            const modalRef = this.modalService.open(ConfirmationModalComponent, { windowClass: 'modal-prompt' });
            modalRef.componentInstance.type = 'Warning';
            modalRef.componentInstance.title = 'Warning';
            modalRef.componentInstance.message = 'Are you sure you want to leave without saving your changes first?';
            return modalRef.componentInstance.closeEvent;
        } else {
            return of(true);
        }
    }

    deleteDocument() {
        const modalRef = this.modalService.open(ConfirmationModalComponent, {
            windowClass: 'modal-prompt',
        });
        modalRef.componentInstance.type = 'Delete';
        modalRef.componentInstance.title = 'Delete document';
        modalRef.componentInstance.message = 'Are you sure you want to delete ' + this.document.name + '?';
        this.subscriptions.add(
            modalRef.componentInstance.closeEvent.subscribe((val) => {
                if (val) {
                    this.store.dispatch(new DeleteFileSystemEntity(this.document)).subscribe(
                        (res) => {
                            this.logger.debug('Succesfully removed document');
                            this.toastr.success('Document successfully removed.');
                            this.router.navigate(['/documents']);
                        },
                        (err) => {
                            this.logger.debug('Failed to remove document');
                            this.toastr.error('Removing document failed. Please try again later.');
                        },
                    );
                }
            }),
        );
    }

    loadRelations() {
        this.logger.debug('Fetching works');
        this.subscriptions.add(this.store.dispatch(new FetchProjects()).subscribe());
        this.logger.debug('Fetching products');
        this.subscriptions.add(this.store.dispatch(new FetchProducts()).subscribe());
        this.subscriptions.add(this.allWorks$.subscribe((w) => (this.projects = w.filter((wo) => this.relations.find((o) => o.uuid === wo.uuid) != null))));
        this.subscriptions.add(this.allProducts$.subscribe((p) => (this.products = p.filter((pr) => this.relations.find((o) => o.uuid === pr.uuid) != null))));
        this.loadingRelations = false;
    }

    getDocumentPdf() {
        this.logger.debug('Attempt to retrieve documentPDF');
        this.subscriptions.add(
            this.store.dispatch(new UpdateDocument(this.document)).subscribe(
                () => {
                    this.toastr.success('Saved remaining changes!');
                    this.documentService.getDocumentPdf(this.document.uuid).subscribe(
                        (pdf) => {
                            this.logger.debug('Succesfully retrieved documentPDF');
                            this.base64ToBlob(pdf.pdf);
                        },
                        (error) => this.logger.error('Failed to retrieve documentPDF'),
                    );
                },
                (err) => {
                    this.logger.error('Failed to save remaining changes');
                    this.toastr.error('Oops, something went wrong.');
                },
            ),
        );
    }

    base64ToBlob(data) {
        const byteChars = atob(data);
        const byteNumbers = new Array(byteChars.length);
        for (let i = 0; i < byteChars.length; i++) {
            byteNumbers[i] = byteChars.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);
        this.logger.debug('opening PDF in new window');
        window.open(url);
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
