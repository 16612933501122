<div class="module__logo">
    <img src="../../../assets/img/logo-outlook.png" />
</div>

<div>
    <h3 *ngIf="!this.loggedInOutlook">Currently not logged in with Outlook</h3>

    <div *ngIf="!this.loggedInOutlook">
        <a
            [href]="this.outlookAuth"
            class="button button--primary w-100"
            >Login with Outlook</a
        >
    </div>

    <div *ngIf="this.loggedInOutlook">
        <h1>Logged In Successfully</h1>
        <button
            (click)="logoutOutlook()"
            class="button button--primary w-100">
            Logout
        </button>
    </div>
</div>
