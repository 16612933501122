<form (ngSubmit)="addLogItem()"
[formGroup]="logItemForm">
    <header class="modal-header">
        <h2 class="modal-title">
            Create new logbook entry
        </h2>

        <button
            (click)="activeModal.dismiss()"
            aria-label="Close"
            class="close"
            type="button">
            <span aria-hidden="true">&times;</span>
        </button>
    </header>

    <main class="modal-body">
        <ng-container *ngIf="logCategories$ | async, else loading">
            <div class="form-group required m-b-2">
                <label class="required" for="category">Choose category</label>
                <div class="d-flex">
                    <select formControlName="category" id="category" name="category">
                        <option *ngFor="let category of logCategories" [ngValue]="category.category">{{category.category}}</option>
                    </select>

                    <button
                        (click)="openLogbookCategoriesModal()"
                        class="button button--outline button--icon m-l-2"
                        type="button">
                        <i class="las la-plus"></i>
                    </button>
                </div>

            </div>
            <div class="form-group">
                <description-input formControlName="description"></description-input>
            </div>

        </ng-container>

        <ng-template #loading>
            <app-loading-message></app-loading-message>
        </ng-template>
    </main>

    <footer class="modal-footer">
        <input
            [disabled]="logItemForm.invalid"
            [ngClass]="logItemForm.invalid ? 'button--disabled' : 'button--primary'"
            class="button"
            type="submit"
            value="Save"/>
        <button
            (click)="activeModal.dismiss()"
            class="button button--outline">
            Cancel
        </button>
    </footer>
</form>


