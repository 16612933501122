<form
    [formGroup]="applicantForm"
    (ngSubmit)="submit()"
    class="modal-wrapper">
    <div class="modal-header">
        <h2
            *ngIf="!applicant"
            class="modal-title"
            id="exampleModalLabel">
            New applicant
        </h2>
        <h2
            *ngIf="applicant"
            class="modal-title"
            id="exampleModalLabel">
            Edit applicant
        </h2>
        <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="activeModal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <div class="row">
            <div class="col-6">
                <div class="form-group required m-b-2">
                    <label>Firstname</label>
                    <input
                        type="text"
                        placeholder="firstname*"
                        formControlName="firstName"
                        trim="blur"
                        #focussed />
                    <span
                        class="form-error"
                        *ngIf="applicantForm.controls.firstName.errors"
                        >firstname required</span
                    >
                </div>
            </div>
            <div class="col-6">
                <div class="form-group required m-b-2">
                    <label>Lastname</label>
                    <input
                        type="text"
                        placeholder="lastname*"
                        formControlName="lastName"
                        trim="blur" />
                    <span
                        class="form-error"
                        *ngIf="applicantForm.controls.lastName.errors"
                        >lastname required</span
                    >
                </div>
            </div>
        </div>

        <h3 class="m-t-4 m-b-4">Address</h3>

        <div formGroupName="address">
            <div class="m-b-2">
                <div class="row">
                    <div class="col-6">
                        <div class="form-group">
                            <label>Street</label>
                            <input
                                type="text"
                                placeholder="Street*"
                                formControlName="street"
                                trim="blur" />
                        </div>
                    </div>
                    <div class="col-2">
                        <div class="form-group">
                            <label>Number</label>
                            <input
                                type="number"
                                placeholder="Number*"
                                formControlName="number" />
                        </div>
                    </div>
                    <div class="col-2">
                        <div class="form-group">
                            <label>Box</label>
                            <input
                                type="text"
                                placeholder="Box"
                                formControlName="bus"
                                trim="blur" />
                        </div>
                    </div>
                    <div class="col-2">
                        <div class="form-group">
                            <label>Ext</label>
                            <input
                                type="number"
                                placeholder="Ext"
                                formControlName="ext" />
                        </div>
                    </div>
                </div>
            </div>

            <div class="m-b-2">
                <div class="row">
                    <div class="col-3">
                        <div class="form-group">
                            <label>Zipcode</label>
                            <input
                                type="text"
                                placeholder="Zipcode*"
                                formControlName="postal"
                                trim="blur" />
                        </div>
                    </div>
                    <div class="col-9">
                        <div class="form-group">
                            <label>City</label>
                            <input
                                type="text"
                                placeholder="City*"
                                formControlName="city"
                                trim="blur" />
                        </div>
                    </div>
                </div>
            </div>

            <div
                class="form-group m-b-2"
                formGroupName="country">
                <label>Country</label>
                <select formControlName="iso2">
                    <option
                        *ngFor="let country of allCountries"
                        [ngValue]="country['alpha-2']">
                        {{ country.name }}
                    </option>
                </select>
            </div>
        </div>
            
        <div class="row">
            <div class="col">
                <div class="form-group m-b-2">
                    <label for="priority">Priority</label>
                    <select
                        id="priority"
                        formControlName="priority">
                        <option [ngValue]="laneItemPriority.LOW">Low</option>
                        <option [ngValue]="laneItemPriority.MEDIUM">Medium</option>
                        <option [ngValue]="laneItemPriority.HIGH">High</option>
                        <option [ngValue]="laneItemPriority.CRITICAL">Critical</option>
                    </select>
                </div>
            </div>
        </div>

        <h3 class="m-t-4 m-b-4">Contact information</h3>

        <div formGroupName="contactInformation">
            <div class="form-group m-b-2">
                <label>Phonenumber</label>
                <input
                    type="text"
                    placeholder="Phonenumber"
                    formControlName="phoneNumber"
                    trim="blur" />
            </div>

            <div class="form-group m-b-2 required">
                <label>Email address</label>
                <input
                    type="email"
                    placeholder="Emailaddress"
                    formControlName="email"
                    trim="blur" />
                <span
                    class="form-error"
                    *ngIf="applicantForm.get('contactInformation.email').errors"
                    >Email is required</span
                >
            </div>

            <div class="form-group m-b-2">
                <label>Website</label>
                <input
                    type="text"
                    placeholder="Website"
                    formControlName="website"
                    trim="blur" />
            </div>
        </div>

        <div class="row">
            <app-global-autocomplete
                [parentForm]="applicantForm"
                [formControlName]="'partnerUuid'"
                [data]="allPartners"
                [itemType]="'partner'"
                [label]="'Link a partner'"
                [notFoundMessage]="'Add a partner'"
                [keyword]="'title'"
                [placeholder]="'Choose a partner'"
                [active]="this.moduleService.checkModuleActive(eModule.PARTNERS)">
                [dataLoaded]="allPartners"
            </app-global-autocomplete>
        </div>

        <div class="row">
            <div class="col">
                <div class="form-group m-b-2">
                    <label>Description</label>
                    <ckeditor
                        formControlName="description"
                        [editor]="editor"
                        id="description"
                        name="description">
                    </ckeditor>
                </div>
            </div>
        </div>

        <app-file-upload
            type="file"
            formControlName="files"
            [multi]="true"
            [maxFileAmount]="10"></app-file-upload>
    </div>

    <div class="modal-footer">
        <button
            type="button"
            class="button button--outline"
            (click)="activeModal.close()">
            Close
        </button>
        <button
            *ngIf="!applicant"
            type="submit"
            class="button button--primary">
            Add applicant
        </button>
        <button
            *ngIf="applicant"
            type="submit"
            class="button button--primary">
            Edit applicant
        </button>
    </div>
</form>
<ng-template #loading>
    <app-loading-message></app-loading-message>
</ng-template>
