import { Component, Input, OnInit } from '@angular/core';
import Fields from 'src/app/models/Fields';

@Component({
    selector: 'app-configurator-preview',
    templateUrl: './configurator-preview.component.html',
    styleUrls: ['./configurator-preview.component.scss'],
})
export class ConfiguratorPreviewComponent implements OnInit {
    @Input() fields: Fields;
    constructor() {}

    ngOnInit(): void {}
}
