<nav class="app__subnav">
    <a
        routerLink="/financial/overhead"
        routerLinkActive="app__subnav__active">
        Overhead
    </a>
    <a
        routerLink="/financial/employees"
        routerLinkActive="app__subnav__active">
        Employees
    </a>
    <a
        routerLink="/financial/projects"
        routerLinkActive="app__subnav__active">
        Projects
    </a>
    <a
        routerLink="/financial/turnover"
        routerLinkActive="app__subnav__active">
        Turnover
    </a>
</nav>
