import { Component, Input, OnInit } from '@angular/core';
import { ContactPerson } from 'src/app/models/ContactPerson';

@Component({
    selector: 'app-resume-share-contactperson-list',
    templateUrl: './resume-share-contactperson-list.component.html',
    styleUrls: ['./resume-share-contactperson-list.component.scss'],
})
export class ResumeShareContactpersonListComponent implements OnInit {
    @Input() contactPersons: ContactPerson[];
    @Input() showContactPersons: ContactPerson[];
    searchText: string;
    selectedContactPersons: ContactPerson[] = [];
    noSearchResults = false;

    constructor() {}

    ngOnInit(): void {}

    search(text: string) {
        this.noSearchResults = false;
        this.searchText = text.toLowerCase();
        this.showContactPersons = this.contactPersons.filter(
            (contactPerson) =>
                `${contactPerson.firstName.toLowerCase()} ${contactPerson.lastName.toLocaleLowerCase()}`.includes(this.searchText) ||
                contactPerson.partnerName.toLowerCase().includes(this.searchText) ||
                contactPerson.contactInformation.email.toLowerCase().includes(this.searchText),
        );
        if (this.showContactPersons.length === 0) {
            this.noSearchResults = true;
        }
    }

    onSelect(contactPerson: ContactPerson) {
        if (!this.inSelectedPersons(contactPerson)) {
            this.selectedContactPersons.push(contactPerson);
        } else {
            const indexOfContactPerson = this.selectedContactPersons.indexOf(contactPerson);
            this.selectedContactPersons.splice(indexOfContactPerson, 1);
        }
    }

    inSelectedPersons(contactPerson: ContactPerson): boolean {
        return this.selectedContactPersons.filter((cp) => cp.uuid === contactPerson.uuid).length > 0 ? true : false;
    }
}
