import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { ComanageService } from 'src/app/comanage/comanage.service';

import { PdfDetailModalComponent } from '../../pdf-detail-modal/pdf-detail-modal.component';

@Component({
    selector: 'app-pill-pdf',
    templateUrl: './pill-pdf.component.html',
    styleUrls: ['./pill-pdf.component.scss'],
})
export class PillPdfComponent implements OnInit, OnDestroy {
    @Input() pdfAsBase64$: Observable<any>;
    @Input() fileName: string;
    @Input() url: string;
    @Input() type: string;
    @Input() exists = true;
    isLoading = true;
    pdfAsBase64: string;
    pdf;
    pdfUrl = '';
    error = false;
    private subscriptions = new Subscription();

    constructor(
        private sanitizer: DomSanitizer,
        private modalService: NgbModal,
        private comanageService: ComanageService,
        private toastrService: ToastrService,
    ) {}

    ngOnInit(): void {
        if (this.url && this.type === 'comanage') {
            this.getComanagePdf();
        } else if (this.url) {
            this.fileName = this.extractFileNameFromURL(this.url);
            this.isLoading = false;
        } else {
            if (this.pdfAsBase64$) {
                this.subscriptions.add(
                    this.pdfAsBase64$.subscribe((result) => {
                        this.isLoading = false;
                        this.base64ToBlob(result.pdf);
                        this.pdfAsBase64 = result;
                    }),
                );
            }
        }
    }

    getClass(): string {
        if (this.fileName.endsWith('.csv') || this.fileName.endsWith('.xlsx')) {
            return 'pill pill--xlsx';
        } else if (this.fileName.endsWith('.doc') || this.fileName.endsWith('.docx')) {
            return 'pill pill--word';
        } else if (this.url.endsWith('.jpeg')) {
            return 'pill pill--jpeg';
        }
        return 'pill pill--pdf';
    }

    getIcon(): string {
        if (this.fileName.endsWith('.csv')) return 'las la-file-csv';
        if (this.fileName.endsWith('.xlsx')) return 'las la-file-excel';
        if (this.fileName.endsWith('.doc') || this.fileName.endsWith('.docx')) return 'las la-file-word';
        if (this.url.endsWith('.jpeg')) return 'las la-file-image';
        return 'las la-file-pdf';
    }

    base64ToBlob(data) {
        const byteChars = atob(data);
        const byteNumbers = new Array(byteChars.length);
        for (let i = 0; i < byteChars.length; i++) {
            byteNumbers[i] = byteChars.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);
        this.pdf = this.sanitizer.bypassSecurityTrustUrl(url);
        this.fileName = `${this.fileName}.pdf`;
        this.pdfUrl = url;
    }

    openPdfDetailModal() {
        if (!this.pdfAsBase64 && (this.pdfUrl || this.url)) {
            window.open(this.pdfUrl ? this.pdfUrl : this.url, '_blank');
        } else {
            if (this.isLoading) {
                this.toastrService.info('Please wait until the file is loaded.', 'File not loaded yet.');
            } else {
                const modalRef = this.modalService.open(PdfDetailModalComponent, {
                    windowClass: 'modal-huge',
                    animation: false,
                });
                modalRef.componentInstance.originUrl = this.url;
                modalRef.componentInstance.pdfUrl = this.pdfUrl ? this.pdfUrl : this.url;
                modalRef.componentInstance.pdfAsBase64 = this.pdfAsBase64;
            }
        }
    }

    getComanagePdf() {
        this.subscriptions.add(
            this.comanageService.getPdf(this.url).subscribe(
                (pdfBlob) => {
                    const invoiceObjectUrl = URL.createObjectURL(pdfBlob);
                    this.pdf = this.sanitizer.bypassSecurityTrustUrl(invoiceObjectUrl);
                    this.fileName = `${this.fileName}.pdf`;
                    this.pdfUrl = invoiceObjectUrl;
                    this.isLoading = false;
                },
                (err) => {
                    this.error = true;
                },
            ),
        );
    }

    extractFileNameFromURL(url: string) {
        return url.split('/')[3].split('___')[0];
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
