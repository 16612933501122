import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalTag } from 'src/app/models/GlobalTag';
import { environment } from 'src/environments/environment';

import { AuthenticationService } from '../../authentication/authentication.service';

@Injectable({
    providedIn: 'root',
})
export class GlobalTagsService {
    httpOptions = {};

    constructor(private httpClient: HttpClient, private authenticationService: AuthenticationService) {
        this.httpOptions = authenticationService.getHeaders();
    }

    getAllGlobalTags() {
        return this.httpClient.get<GlobalTag[]>(environment.baseUrl + '/tags/all', this.httpOptions);
    }

    getLinkedTags(uuid: string) {
        return this.httpClient.get<GlobalTag[]>(environment.baseUrl + '/tags/' + uuid, this.httpOptions);
    }

    addGlobalTagToObject(tag: GlobalTag, uuid: string) {
        return this.httpClient.post<GlobalTag>(environment.baseUrl + '/tags/link/' + uuid, tag, this.httpOptions);
    }

    addGlobalTagToMultipleObjects(tag: GlobalTag, uuids: []) {
        return this.httpClient.post<GlobalTag>(environment.baseUrl + '/tags/link-multiple/' + uuids, tag, this.httpOptions);
    }

    removeGlobalTagFromObject(tag: GlobalTag, uuid: string) {
        return this.httpClient.delete<boolean>(environment.baseUrl + '/tags/unlink/' + uuid + '/' + tag.uuid, this.httpOptions);
    }

    removeGlobalTagFromMultipleObjects(tag: GlobalTag, uuids: []) {
        return this.httpClient.delete<boolean>(environment.baseUrl + '/tags/unlink-multiple/' + uuids + '/' + tag.uuid, this.httpOptions);
    }

    getGlobalTagTaggedObjects(tagId: string) {
        return this.httpClient.get<any[]>(environment.baseUrl + `/tags/tagged/${tagId}`, this.httpOptions);
    }
}
