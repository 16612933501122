<div
    *ngIf="eventView.calendar"
    class="form-error">
    This is still in development and not all functions are optimized yet...
</div>

<div class="calendar">
    <mbsc-eventcalendar
        [data]="events"
        [dragTimeStep]="60 * 24"
        [options]="calendarOptions"
        [resourceTemplate]="resourceTemplate"
        [resources]="employees"
        [scheduleEventTemplate]="eventTemplate"
        [view]="eventView"
        [extendDefaultEvent]="defaultEvent"
        theme="windows"
        themeVariant="light"
        timeFormat="HH:mm">
        <ng-template
            #resourceTemplate
            let-employee>
            <div>
                <app-pill-employee [employee]="employee"></app-pill-employee>
            </div>
        </ng-template>
        <ng-template
            #eventTemplate
            let-args>
            <div
                class="timeline-event d-flex align-items-center"
                [ngStyle]="{ background: args.color }">
                <i
                    *ngIf="args.original.plannedEvent && args.original.plannedEvent.recurring"
                    class="las la-redo-alt recurring-icon"></i>
                <div class="timeline-event-title">{{ args.title }}</div>
            </div>
        </ng-template>
    </mbsc-eventcalendar>
</div>
