<div class="form-group required m-b-2">
    <label>Select a color</label>
    <ul class="form-group--colors">
        <li
            (click)="onSelectColour(colour)"
            [class.selected]="colour === selectedColour"
            *ngFor="let colour of colours"
            [style.background-color]="colour"
            [attr.Value]="colour"></li>
    </ul>
</div>
