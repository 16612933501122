import YukiDocument from './YukiDocument';

export default class YukiFolder {
    id: number;
    description: string;
    icon: string;
    processedByYuki: boolean;
    documents: YukiDocument[] = [];
    numberOfUnverifiedDocuments: number;
}
