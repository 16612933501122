<app-app-holder>
    <app-navbar></app-navbar>
    <app-content>
        <div class="app__title">
            <h1>
                Resumés overview
                <span *ngIf="resumes$ | async as resumes">({{ resumes.length }})</span>
            </h1>

            <span>
                <a
                    class="button button--primary"
                    *appIsPermitted="{
                        module: 'RESUME',
                        operator: 'AND',
                        actions: ['CREATE']
                    }"
                    (click)="openAddResumeModal()"
                    >New resume</a
                >
            </span>
        </div>
        <app-resume-list
            *appIsPermitted="{
                module: 'RESUME',
                operator: 'AND',
                actions: ['READ']
            }"></app-resume-list>
    </app-content>
</app-app-holder>
