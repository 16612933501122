<app-searchbar
    [(value)]="filter"
    (valueChange)="applyFilter($event)"></app-searchbar>

<div class="m-t-4">
    <table
        mat-table
        [dataSource]="dataSource"
        matSort>
        <ng-container matColumnDef="title">
            <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header>
                Warehouse Location
            </th>
            <td
                mat-cell
                *matCellDef="let row">
                <app-pill-warehouse-location [warehouseLocation]="row"></app-pill-warehouse-location>
            </td>
        </ng-container>

        <ng-container matColumnDef="warehouse">
            <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header>
                Warehouse
            </th>
            <td
                mat-cell
                *matCellDef="let row">
                <app-pill-warehouse [warehouseUuid]="row.warehouseUuid"></app-pill-warehouse>
            </td>
        </ng-container>

        <ng-container matColumnDef="edit">
            <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                class="td-small"></th>
            <td
                mat-cell
                *matCellDef="let row">
                <span
                    ngbDropdown
                    *appIsPermitted="{
                        module: 'INVENTORY',
                        operator: 'OR',
                        actions: ['EDIT', 'DELETE']
                    }">
                    <button
                        ngbDropdownToggle
                        class="button button--icon button--dropdown button--small"></button>
                    <span
                        ngbDropdownMenu
                        class="dropdown-menu dropdown-menu-right"
                        aria-labelledby="dropdownMenuButton">
                        <a
                            class="dropdown-item"
                            *appIsPermitted="{
                                module: 'INVENTORY',
                                operator: 'AND',
                                actions: ['EDIT']
                            }"
                            (click)="openWarehouseLocationUpdateModal(row)"
                            >Edit warehouse location</a
                        >
                        <a
                            class="dropdown-item color--red"
                            *appIsPermitted="{
                                module: 'INVENTORY',
                                operator: 'AND',
                                actions: ['DELETE']
                            }"
                            (click)="openWarehouseLocationDeleteModal(row)"
                            >Archive warehouse location</a
                        >
                    </span>
                </span>
            </td>
        </ng-container>

        <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns"></tr>
        <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns"></tr>

        <tr *matNoDataRow>
            <td colspan="3">No warehouse locations matching the filter "{{ filter }}"</td>
        </tr>
    </table>
</div>
