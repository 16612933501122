import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { Subscription } from 'rxjs/internal/Subscription';
import { Applicant } from 'src/app/models/Applicants/Applicant';
import { ConfirmationModalComponent } from 'src/app/shared/confirmation-modal/confirmation-modal.component';

import { FetchArchivedApplicants, UnArchiveApplicant } from '../applicant.actions';
import { ApplicantState } from '../applicant.state';

@Component({
    selector: 'app-archived-applicant-list',
    templateUrl: './archived-applicant-list.component.html',
    styleUrls: ['./archived-applicant-list.component.scss'],
})
export class ArchivedApplicantListComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Select(ApplicantState.ArchivedApplicants) archivedApplicants$: Observable<Applicant[]>;
    @Select(ApplicantState.Applicants) applicants$: Observable<Applicant[]>;

    filter: string;
    isLoading = true;
    activeApplicants: Applicant[];

    displayedColumns: string[] = ['email', 'createdOn', 'edit'];
    dataSource: MatTableDataSource<Applicant>;
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    constructor(private store: Store, private modalService: NgbModal, private toastr: ToastrService) {}

    ngOnInit(): void {
        this.store.dispatch(new FetchArchivedApplicants());
        this.subscriptions.add(
            this.archivedApplicants$.subscribe((applicants) => {
                this.dataSource = new MatTableDataSource(applicants);
                this.dataSource.sort = this.sort;
                this.isLoading = applicants == null;

                this.dataSource.sortingDataAccessor = (item, property) => {
                    if (property.includes('.')) return property.split('.').reduce((o, i) => o[i], item);
                    return item[property];
                };
            }),
        );
        this.subscriptions.add(
            this.applicants$.subscribe((applicants) => {
                this.activeApplicants = applicants;
            }),
        );
    }

    applyFilter(filterValue) {
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }

    unArchiveApplicant(applicant: Applicant) {
        if (this.activeApplicants.find((a) => a.contactInformation?.email === applicant.contactInformation.email)) {
            this.toastr.error('This applicant already exists in the active applicants list.');
            return;
        }
        const modalRef = this.modalService.open(ConfirmationModalComponent, {
            windowClass: 'modal-prompt',
            animation: false,
        });
        modalRef.componentInstance.type = 'YesOrNo';
        modalRef.componentInstance.title = 'Unarchive applicant';
        modalRef.componentInstance.htmlMessage = `<p>Are you sure you want to unarchive <strong>${applicant.firstName} ${applicant.lastName}</strong>?</p>`;
        this.subscriptions.add(
            modalRef.componentInstance.closeEvent.subscribe((value) => {
                if (value) {
                    this.store.dispatch(new UnArchiveApplicant(applicant.uuid)).subscribe({
                        next: () => {
                            this.toastr.success('Applicant successfully unarchived.');
                        },
                        error: () => {
                            this.toastr.error('Oops, something went wrong, Please try again later...');
                        },
                    });
                }
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
