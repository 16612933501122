<div class="modal-header">
    <h2
        class="modal-title"
        id="exampleModalLabel">
        Edit reason
    </h2>
    <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<form
    [formGroup]="reasonForm"
    (submit)="submit()"
    class="modal-wrapper">
    <div class="modal-body">
        <div class="row">
            <div class="col">
                <div class="form-group m-b-2">
                    <label for="reason">Reason</label>
                    <small
                        class="form-error"
                        *ngIf="!reasonForm.get('reason').valid && reasonForm.get('reason').touched"
                        >please enter a reason</small
                    >
                    <input
                        id="reason"
                        formControlName="reason"
                        type="text"
                        placeholder="Enter a reason" />
                </div>
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <button
            type="button"
            class="button button--outline"
            (click)="activeModal.close()">
            Close
        </button>
        <button
            type="submit"
            [disabled]="!reasonForm.valid"
            class="button button--primary">
            Save
        </button>
    </div>
</form>
