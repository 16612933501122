<form
    [formGroup]="calendarTaskForm"
    class="modal-wrapper"
    (ngSubmit)="AddCalendarMeeting()">
    <div class="modal-header">
        <h2 class="modal-title">Add new Calendar Task</h2>
    </div>

    <div class="modal-body">
        <div class="row">
            <div class="col">
                <div class="form-group m-b-2">
                    <label for="title">Title</label>
                    <input
                        id="title"
                        formControlName="title"
                        type="text"
                        placeholder="Title*" />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="form-group m-b-2">
                    <label for="description">Description</label>
                    <input
                        id="description"
                        formControlName="description"
                        type="text"
                        placeholder="Description*" />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="form-group m-b-2">
                    <label for="activityTypeId">Work Type</label>
                    <select formControlName="workTypeId">
                        <option
                            *ngFor="let workType of worktypes"
                            [ngValue]="workType.id">
                            {{ workType.name }}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="form-group m-b-2">
                    <label for="startsAt">Start Time</label>
                    <mat-form-field>
                        <input
                            formControlName="startsAt"
                            matInput
                            type="datetime-local"
                            placeholder="Start date" />
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="form-group m-b-2">
                    <label for="endsAt">End Time</label>
                    <mat-form-field>
                        <input
                            formControlName="endsAt"
                            matInput
                            type="datetime-local"
                            placeholder="End date" />
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <input
            [ngClass]="!calendarTaskForm.valid ? 'button button--disabled w-100' : 'button button--primary w-100'"
            type="submit"
            [disabled]="!calendarTaskForm.valid"
            value="Add Event" />
    </div>
</form>
