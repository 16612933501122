import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subscription } from 'rxjs';
import Lead from 'src/app/models/Lead';
import { ModuleEnum } from 'src/app/models/ModuleEnum';
import { ModuleService } from 'src/app/services/module.service';

import { LeadDeleteModalComponent } from '../lead-delete-modal/lead-delete-modal.component';
import { LeadDetailModalComponent } from '../lead-detail-modal/lead-detail-modal.component';
import { LeadToDealModalComponent } from '../lead-to-deal-modal/lead-to-deal-modal.component';
import { UpdateReadOfLead, fetchLeads } from '../lead.actions';
import { LeadState } from '../lead.state';

@Component({
    selector: 'app-lead-detail',
    templateUrl: './lead-detail.component.html',
    styleUrls: ['./lead-detail.component.scss'],
})
export class LeadDetailComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Input() leadUuid: string;
    @Input() archived: boolean;
    @Select(LeadState.getLeads) lead$: Observable<Lead[]>;
    @Select(LeadState.archivedLeads) archivedLeads$: Observable<Lead[]>;
    lead: Lead;
    fields;
    errorMessage: string;
    updateLeadWentWrong = false;

    eModule = ModuleEnum;
    isLoading = true;

    constructor(
        public activeModal: NgbActiveModal,
        private store: Store,
        private modalService: NgbModal,
        public moduleService: ModuleService,
        private logger: NGXLogger,
    ) {}

    ngOnInit(): void {
        if (this.archived) {
            this.subscriptions.add(
                this.archivedLeads$.subscribe((leads) => {
                    this.lead = leads.filter((l) => l.uuid == this.leadUuid)[0];
                    if (this.lead && this.isLoading) {
                        if (!this.lead.read) {
                            this.toggleLeadRead(JSON.parse(JSON.stringify(this.lead)), true);
                        }
                        this.isLoading = false;
                    }
                }),
            );
        } else {
            this.subscriptions.add(
                this.lead$.subscribe((leads) => {
                    if (leads === null) {
                        this.store.dispatch(new fetchLeads());
                    } else {
                        this.lead = leads.filter((l) => l.uuid == this.leadUuid)[0];
                        if (this.lead && this.isLoading) {
                            if (!this.lead.read) {
                                this.toggleLeadRead(JSON.parse(JSON.stringify(this.lead)), true);
                            }
                            this.isLoading = false;
                        }
                    }
                }),
            );
        }
    }

    toggleLeadRead(lead: Lead, initialRead?: boolean) {
        lead.read = !lead.read;
        this.logger.debug('Attempt to update read of lead');
        this.subscriptions.add(
            this.store.dispatch(new UpdateReadOfLead(lead)).subscribe(
                () => undefined,
                (error) => {
                    this.errorMessage = error?.error?.message;
                    this.updateLeadWentWrong = true;
                },
            ),
        );
        if (!initialRead) {
            this.activeModal.dismiss();
        }
    }

    openLeadDetailModalComponent() {
        this.logger.debug('Opening leadDetailModal');
        const modalRef = this.modalService.open(LeadDetailModalComponent, {
            windowClass: 'modal-prompt',
        });
        modalRef.componentInstance.lead = this.lead;
        this.archived === true ? (modalRef.componentInstance.archived = true) : (modalRef.componentInstance.archived = false);
    }

    openLeadDeleteModalComponent() {
        this.logger.debug('Opening leadDeleteModal');
        const modalRef = this.modalService.open(LeadDeleteModalComponent, {
            windowClass: 'modal-prompt',
        });
        modalRef.componentInstance.lead = this.lead;
        this.archived === true ? (modalRef.componentInstance.archived = true) : (modalRef.componentInstance.archived = false);
    }

    openLeadToDealModal(lead: Lead) {
        this.logger.debug('opening LeadToDealModal');
        const modalRef = this.modalService.open(LeadToDealModalComponent, {
            windowClass: 'modal-prompt',
        });
        modalRef.componentInstance.lead = lead;
    }

    getFields(): string {
        let list = '';
        Object.keys(this.lead.fields).forEach((key) => {
            list += '<li><label>' + key.toString() + '</label> ' + this.lead.fields[key] + '</li>';
        });
        return list;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
