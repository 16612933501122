import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable, of } from 'rxjs';

import { CompanyState } from '../companies/company.state';
import { CompanyMember } from '../models/CompanyMember';
import { RoleEnum } from '../models/RoleEnum';
import User from '../models/User';
import { UserState } from '../users/user.state';

@Injectable({
    providedIn: 'root',
})
export class SettingsModuleSetupGuard implements CanActivate {
    eRole = RoleEnum;

    constructor(private store: Store, private router: Router) {}

    canActivate(next: ActivatedRouteSnapshot, routerState: RouterStateSnapshot): Observable<boolean> {
        let user: User, members: CompanyMember[];
        this.store.selectOnce(UserState.userInfo).subscribe((userInfo) => (user = userInfo));
        this.store.selectOnce(CompanyState.companyMembers).subscribe((companyMembers) => (members = companyMembers));

        const admin: CompanyMember = members.find((member) => member.userUuid === user.uuid);

        if (admin.role !== this.eRole.ROLE_SUPER_ADMIN) {
            this.router.navigate([`/forbidden`]);
        }

        return of(true);
    }
}
