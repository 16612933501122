import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '../authentication/auth.guard';
import { CompanyGuard } from '../companies/company.guard';
import { EmployeeGraphsComponent } from './employee-graphs/employee-graphs.component';
import { FinancialHomeComponent } from './financial-home/financial-home.component';
import { OverheadGraphsComponent } from './overhead-graphs/overhead-graphs.component';
import { ProjectGraphsComponent } from './project-graphs/project-graphs.component';
import { TurnoverGraphComponent } from './turnover-graph/turnover-graph.component';

export const routes: Routes = [
    {
        path: '',
        component: FinancialHomeComponent,
        children: [
            { path: '', redirectTo: 'overhead', pathMatch: 'full' },
            { path: 'overhead', component: OverheadGraphsComponent, canActivate: [AuthGuard, CompanyGuard] },
            { path: 'employees', component: EmployeeGraphsComponent, canActivate: [AuthGuard, CompanyGuard] },
            { path: 'projects', component: ProjectGraphsComponent, canActivate: [AuthGuard, CompanyGuard] },
            { path: 'turnover', component: TurnoverGraphComponent, canActivate: [AuthGuard, CompanyGuard] },
        ],
    },
];

export const routing: ModuleWithProviders<any> = RouterModule.forChild(routes);
