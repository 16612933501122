<app-app-holder>
    <app-navbar></app-navbar>

    <app-sidebar>
        <app-comanage-subnav></app-comanage-subnav>
    </app-sidebar>

    <app-content>
        <app-comanage-not-logged-in (credentialsIsset)="checkCredentials($event)"></app-comanage-not-logged-in>

        <div class="app__title">
            <h1>
                CoManage Customers
                <span *ngIf="this.dataSource?.data">({{ this.dataSource.data.length }})</span>
            </h1>
        </div>

        <app-searchbar
            [(value)]="filter"
            (valueChange)="applyFilter($event)"></app-searchbar>

        <div *ngIf="!this.dataSource?.data">
            <app-loading-message></app-loading-message>
        </div>

        <div>
            <div
                class="m-t-4"
                *appIsPermitted="{
                    module: 'COMANAGE',
                    operator: 'AND',
                    actions: ['READ']
                }">
                <div
                    class="table__overflow"
                    [style.display]="!this.dataSource?.data ? 'none' : 'block'">
                    <table
                        mat-table
                        [dataSource]="dataSource"
                        matSort
                        [hidden]="!this.dataSource?.data">
                        <ng-container matColumnDef="customer_number">
                            <th
                                mat-header-cell
                                *matHeaderCellDef
                                class="td-small"
                                mat-sort-header>
                                Customer number
                            </th>
                            <td
                                mat-cell
                                *matCellDef="let row">
                                <span>{{ row.customer_number }}</span>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="name">
                            <th
                                mat-header-cell
                                *matHeaderCellDef
                                mat-sort-header>
                                Name
                            </th>
                            <td
                                mat-cell
                                *matCellDef="let row">
                                <span>{{ row.name }}</span>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="email">
                            <th
                                mat-header-cell
                                *matHeaderCellDef
                                mat-sort-header>
                                Email
                            </th>
                            <td
                                mat-cell
                                *matCellDef="let row">
                                <app-pill-mailto
                                    *ngIf="row.email"
                                    [mailtoEmail]="row.email"></app-pill-mailto>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="phone">
                            <th
                                mat-header-cell
                                *matHeaderCellDef
                                mat-sort-header>
                                Phone
                            </th>
                            <td
                                mat-cell
                                *matCellDef="let row">
                                <app-pill-telephone
                                    *ngIf="row.phone"
                                    [contactPhonenumber]="row.phone"></app-pill-telephone>
                            </td>
                        </ng-container>

                        <tr
                            mat-header-row
                            *matHeaderRowDef="displayedColumns"></tr>
                        <tr
                            mat-row
                            *matRowDef="let row; columns: displayedColumns"></tr>

                        <tr *matNoDataRow>
                            <td colspan="4">No Customers matching the filter "{{ filter }}"</td>
                        </tr>
                    </table>
                </div>
            </div>
            <mat-paginator
                [pageSizeOptions]="[15]"
                showFirstLastButtons></mat-paginator>
        </div>
    </app-content>
</app-app-holder>
