import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';

import { UploadedDocument } from '../../../models/UploadedDocument';
import { GlobalFilesService } from '../../global-files/global-files.service';
import { UploadedDocumentDetailComponent } from '../../global-files/uploaded-document-detail/uploaded-document-detail.component';

@Component({
    selector: 'app-pill-uploaded-document',
    templateUrl: './pill-uploaded-document.component.html',
    styleUrls: ['./pill-uploaded-document.component.scss'],
})
export class PillUploadedDocumentComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Input() uploadedDocumentId: string;
    uploadedDocument: UploadedDocument;
    fileName: string;

    constructor(private globalFilesService: GlobalFilesService, private modalService: NgbModal) {}

    ngOnInit(): void {
        this.fetchData();
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    openUploadedDocumentDetailModal() {
        const modalRef = this.modalService.open(UploadedDocumentDetailComponent, {
            windowClass: 'modal-huge',
            animation: false,
        });
        modalRef.componentInstance.uploadedDocument = this.uploadedDocument;
    }

    private fetchData() {
        this.subscriptions.add(
            this.globalFilesService.fetchUploadedDocumentById(this.uploadedDocumentId).subscribe({
                next: (uploadedDocument) => {
                    this.uploadedDocument = uploadedDocument;
                    this.fileName = this.getFileName(uploadedDocument.url);
                },
            }),
        );
    }

    private getFileName(url: string) {
        const substring = url.substr(url.indexOf('amazonaws.com/') + 14);
        return substring.substr(0, substring.indexOf('.pdf'));
    }
}
