<div *ngIf="loggedInWithMicrosoft || loggedInWithGmail">
    <div
        class="list__item--user"
        *ngFor="let mail of mails | orderBy: 'date':false:true">
        <div class="list__item__image">
            <img
                *ngIf="mail.type === 'OUTLOOK'"
                src="../../../assets/img/logo-outlook.png" />
            <img
                *ngIf="mail.type === 'GOOGLE'"
                src="../../../assets/img/logo-google.png" />
        </div>
        <div class="list__item__content">
            <div *ngIf="mail.title">
                <h3>{{ mail.title }}</h3>
                <small> {{ mail.sender }}</small>
                <p>{{ mail.body }}</p>
                <small>{{ mail.date }}</small>
            </div>
            <div *ngIf="mail.parentFolderId">
                <h3>{{ mail.subject }}</h3>
                <small> {{ mail.sender.emailAddress.address }}</small>
                <p>{{ mail.bodyPreview }}</p>
                <small>{{ mail.sentDateTime }}</small>
            </div>
        </div>
    </div>
</div>

<div *ngIf="!loggedInWithMicrosoft || !loggedInWithGmail">
    <app-outlook-not-logged-in
        *ngIf="this.moduleService.checkModuleActive(eModule.OUTLOOK) && !loggedInWithMicrosoft"
        [loginUrl]="loginUrl"></app-outlook-not-logged-in>
    <app-gmail-not-logged-in
        *ngIf="this.moduleService.checkModuleActive(eModule.GOOGLE) && !loggedInWithGmail"
        [loginUrl]="loginUrl"></app-gmail-not-logged-in>
</div>
