<div class="modal-wrapper">
    <div class="modal-header">
        <h2>{{ calendarEvent.title }}</h2>
        <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="activeModal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <ul>
            <li *ngIf="calendarEvent.extendedProps.body">
                <strong>description:</strong>
                {{ calendarEvent.extendedProps.body }}
            </li>
            <li>
                <strong>time:</strong> {{ calendarEvent.start }} -
                {{ calendarEvent.end }}
            </li>
            <li *ngIf="calendarEvent.extendedProps.location">
                <strong>location:</strong>
                {{ calendarEvent.extendedProps.location }}
            </li>
            <li>
                <strong>Event type:</strong>
                {{ calendarEvent.extendedProps.type }}
            </li>
        </ul>
    </div>

    <div class="modal-footer">
        <button
            type="button"
            class="button button--outline"
            (click)="activeModal.dismiss()">
            Close
        </button>
    </div>
</div>
