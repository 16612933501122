import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subscription } from 'rxjs';
import { CompanyMember } from 'src/app/models/CompanyMember';
import { RoleEnum } from 'src/app/models/RoleEnum';
import User from 'src/app/models/User';
import { RoleMemberAssignModalComponent } from 'src/app/settings/roles/role-member-assign-modal/role-member-assign-modal.component';
import { UserState } from 'src/app/users/user.state';

import { CompanyState } from '../company.state';

@Component({
    selector: 'app-company-member-list',
    templateUrl: './company-member-list.component.html',
    styleUrls: ['./company-member-list.component.scss'],
})
export class CompanyMemberListComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Select(CompanyState.companyMembers) members$: Observable<CompanyMember[]>;
    @Select(UserState.hasUserOwnerRole) isOwner$: Observable<boolean>;
    @Select(UserState.userInfo) user$: Observable<User>;

    memberList: CompanyMember[];
    owner: CompanyMember;
    eRole = RoleEnum;

    displayedColumns: string[] = ['name', 'email', 'role'];
    dataSource: MatTableDataSource<CompanyMember> = new MatTableDataSource<CompanyMember>();
    @ViewChild(MatSort) sort: MatSort;

    constructor(private store: Store, private modalService: NgbModal, private logger: NGXLogger) {}

    ngOnInit(): void {
        this.subscriptions.add(
            this.members$.subscribe((memberList) => {
                this.memberList = memberList;
                this.store.select(UserState.userInfo).subscribe((user) => {
                    this.owner = memberList.find((member) => member.userUuid === user.uuid);

                    if (this.owner?.role === this.eRole.ROLE_SUPER_ADMIN || this.owner?.role === this.eRole.ROLE_OWNER) {
                        this.displayedColumns.push('edit');
                    }
                });
            }),
        );
    }

    openAddMemberRoleModal(member: CompanyMember) {
        this.logger.debug('Opening rolememberassignModalComponent');
        const modalRef = this.modalService.open(RoleMemberAssignModalComponent, { windowClass: 'modal-prompt' });
        modalRef.componentInstance.member = member;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
