export class CheckComanageApiKey {
    static readonly type = '[any] Check if Comanage API key is set';
}

export class FetchContacts {
    static readonly type = '[any] Fetch Comanage contacts';
}

export class FetchCustomers {
    static readonly type = '[any] Fetch Comanage customers';
}

export class FetchInvoices {
    static readonly type = '[any] Fetch Comanage invoices';
}

export class FetchOffers {
    static readonly type = '[any] Fetch Comanage offers';
}
