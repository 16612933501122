<div class="comments__item">
    <div
        class="comments__item__author"
        *ngIf="comment.uuid !== ''">
        <app-pill-user [userId]="comment.creatorUuid"></app-pill-user>
        <span>
            <small class="m-r-2">{{ comment.createdOn | date: 'short':timezone | amTimeAgo }}</small>
            <span
                ngbDropdown
                *ngIf="editable">
                <button
                    ngbDropdownToggle
                    class="button button--icon button--dropdown button--small"></button>
                <span
                    ngbDropdownMenu
                    class="dropdown-menu dropdown-menu-right"
                    aria-labelledby="dropdownMenuButton">
                    <button
                        class="dropdown-item"
                        (click)="editing = !editing">
                        Edit
                    </button>
                    <button
                        class="dropdown-item color--red"
                        (click)="delete()">
                        Delete
                    </button>
                </span>
            </span>
        </span>
    </div>

    <div
        class="comments__item__author"
        *ngIf="comment.uuid === ''">
        <app-loading-message></app-loading-message>
        <span>
            <small class="m-r-2">{{ comment.createdOn | date: 'short':timezone | amTimeAgo }}</small>
        </span>
    </div>

    <div
        class="comments__item__body"
        *ngIf="comment.uuid !== ''">
        <app-loading-message *ngIf="editing && editable && editLoading"></app-loading-message>
        <div
            class="form-group"
            *ngIf="editing && editable && !editLoading">
            <input
                type="text"
                (keyup.enter)="edit()"
                [(ngModel)]="content" />
            <small class="form-helptext">Hit ENTER to save</small>
        </div>
        <p
            *ngIf="!editing || (editing && !editable)"
            (dblclick)="editing = !editing">
            {{ content }}
        </p>
    </div>

    <div
        class="comments__item__body"
        *ngIf="comment.uuid === ''">
        <p class="sub-text">{{ content }}</p>
    </div>
</div>
