<div class="dashboard__tile__title">
    <span>Inventory stock below threshold</span>
    <button
        type="button"
        class="button button--icon button--small"
        (click)="goToInventory()">
        <i class="uil uil-arrow-right"></i>
    </button>
</div>
<div class="table__overflow">
    <div
        class="dashboard__tile__content"
        *ngIf="xArray?.length > 0">
        <div class="chart_holder">
            <canvas
                baseChart
                height="200px"
                [datasets]="barChartData"
                [labels]="barChartLabels"
                [options]="barChartOptions"
                [plugins]="barChartPlugins"
                [legend]="barChartLegend"
                [chartType]="barChartType">
            </canvas>
        </div>
    </div>
</div>

<div
    class="dashboard__tile__content"
    *ngIf="this.noLowStock && !this.isLoading">
    <app-message-empty
        [title]="'Your inventory is healthy'"
        [subtitle]="'Currently, there aren\'t any stocks under it\'s treshold'"
        [icon]="'../../assets/img/open-box.png'"></app-message-empty>
</div>

<div *ngIf="isLoading">
    <app-loading-message></app-loading-message>
</div>
