import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';
import { ForgotPassword } from 'src/app/users/user.actions';

@Component({
    selector: 'app-forgotpassword',
    templateUrl: './forgotpassword.component.html',
    styleUrls: ['./forgotpassword.component.scss'],
})
export class ForgotpasswordComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    useremail: string;
    hasSubmitted = false;
    inputIsWrong = false;
    loading = false;
    forgotPasswordFormGroup: FormGroup;

    constructor(private formBuilder: FormBuilder, private store: Store, private logger: NGXLogger) {}

    // Set form values and validators
    ngOnInit(): void {
        this.forgotPasswordFormGroup = this.formBuilder.group({
            email: ['', [Validators.email, Validators.required]],
        });
    }

    // Sends an email to the user that links to the reset password page
    sendEmail() {
        this.logger.debug('Attempt to send password reset mail');

        this.loading = true;
        // Check if form values fulfill the validators
        if (!this.forgotPasswordFormGroup.valid) {
            this.inputIsWrong = true;
            this.loading = false;
            return;
        }

        this.useremail = this.forgotPasswordFormGroup.controls.email.value;

        this.subscriptions.add(
            this.store.dispatch(new ForgotPassword(this.useremail)).subscribe(
                () => {
                    this.logger.debug('Password reset mail sent successfully');
                },
                (error) => {
                    this.logger.error('Failed to send password reset mail');
                },
            ),
        );

        this.hasSubmitted = true;
    }

    // Hides the error messages that appear after a user has submitted invalid data
    hideErrorMessage() {
        this.inputIsWrong = false;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
