import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { NgbDropdownModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { MatTableExporterModule } from 'mat-table-exporter';
import { TagInputModule } from 'ngx-chips';
import { MomentModule } from 'ngx-moment';
import { OrderModule } from 'ngx-order-pipe';
import { NgPipesModule } from 'ngx-pipes';

import { SharedModule } from '../shared/shared.module';
import { InventorySubnavComponent } from './inventory-subnav/inventory-subnav.component';
import { LinkProductsComponent } from './link-products/link-products.component';
import { ManageCategoriesComponent } from './manage-categories/manage-categories.component';
import { ProductAddModalComponent } from './product-add-modal/product-add-modal.component';
import { ProductCategoryLinkComponent } from './product-category-link/product-category-link.component';
import { ProductChildListComponent } from './product-child-list/product-child-list.component';
import { ProductDeleteLinkComponent } from './product-delete-link/product-delete-link.component';
import { ProductDeleteModalComponent } from './product-delete-modal/product-delete-modal.component';
import { ProductDetailModalComponent } from './product-detail-modal/product-detail-modal.component';
import { ProductListComponent } from './product-list/product-list.component';
import { ProductParentListComponent } from './product-parent-list/product-parent-list.component';
import { ProductStockHistoryListComponent } from './product-stock-history-list/product-stock-history-list.component';
import { ProductStockHistoryComponent } from './product-stock-history/product-stock-history.component';
import { ProductUpdateModalComponent } from './product-update-modal/product-update-modal.component';
import { ProductUpdateStockModalComponent } from './product-update-stock-modal/product-update-stock-modal.component';
import { ProductsOverviewComponent } from './products-overview/products-overview.component';
import { routing } from './products.routing';

@NgModule({
    declarations: [
        ProductStockHistoryComponent,
        ProductStockHistoryListComponent,
        ProductsOverviewComponent,
        InventorySubnavComponent,
        ProductListComponent,
        ProductAddModalComponent,
        ProductUpdateModalComponent,
        ProductDeleteModalComponent,
        ProductDeleteLinkComponent,
        ProductDetailModalComponent,
        LinkProductsComponent,
        ProductParentListComponent,
        ProductChildListComponent,
        ProductCategoryLinkComponent,
        ManageCategoriesComponent,
        ProductUpdateStockModalComponent,
    ],
    imports: [
        CommonModule,
        SharedModule,
        AutocompleteLibModule,
        routing,
        NgbModule,
        NgbDropdownModule,
        NgPipesModule,
        OrderModule,
        TagInputModule,
        CdkTableModule,
        MatTableModule,
        MatSortModule,
        MomentModule,
        MatCardModule,
        MatTableExporterModule,
    ],
    exports: [InventorySubnavComponent, ProductListComponent],
})
export class ProductsModule {}
