import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { Timesheet } from 'src/app/models/Timesheet';

import { TimesheetsService } from '../timesheets.service';
import { TimeslotListService } from '../timeslot-list.service';

@Component({
    selector: 'app-public-timeslot-list',
    templateUrl: './public-timeslot-list.component.html',
    styleUrls: ['./public-timeslot-list.component.scss'],
    providers: [TimeslotListService],
})
export class PublicTimeslotListComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Input() timesheet: Timesheet;
    @Input() isEditable: boolean = true;

    @Output() changeEvent: EventEmitter<void> = new EventEmitter<void>();

    constructor(
        private timeslotListService: TimeslotListService,
        // Next imports are required in timeslotService, please don't remove them
        private store: Store,
        private modalService: NgbModal,
        private logger: NGXLogger,
        private toastr: ToastrService,
        private timesheetsService: TimesheetsService, // Last import
    ) {}

    ngOnInit(): void {}

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    openAddTimeslotModal = this.timeslotListService.openAddTimeslotModal;
    openEditTimeslotModal = this.timeslotListService.openEditTimeslotModal;
    removeTimeslot = this.timeslotListService.removeTimeslot;
    getBase64 = this.timeslotListService.getBase64;
    getFileName = this.timeslotListService.getFileName;
}
