import { Component, OnDestroy, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';

import { FetchCompanies } from '../teamleader.actions';
import { TeamleaderState } from '../teamleader.state';

@Component({
    selector: 'app-teamleader-companies',
    templateUrl: './teamleader-companies.component.html',
    styleUrls: ['./teamleader-companies.component.scss'],
})
export class TeamleaderCompaniesComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Select(TeamleaderState.companies) companies$: Observable<any[]>;
    companies: any[];
    filter: string;
    isset: boolean;
    isLoading = true;

    constructor(private store: Store) {}

    ngOnInit(): void {}

    checkCredentials(set: boolean) {
        this.isset = set;
        if (this.isset) {
            this.subscriptions.add(
                this.store.dispatch(new FetchCompanies()).subscribe(() => {
                    this.companies$.subscribe((companies) => (this.companies = companies));
                    this.isLoading = false;
                }),
            );
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
