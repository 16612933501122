<form
    [formGroup]="campaignFormGroup"
    (ngSubmit)="addCampaign($event)"
    class="modal-wrapper">
    <div class="modal-header">
        <h2
            class="modal-title"
            id="exampleModalLabel">
            New campaign
        </h2>
        <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="activeModal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <div class="row">
            <div class="col">
                <div class="form-group m-b-2">
                    <label for="title">Campaign name </label>
                    <small
                        class="form-error"
                        *ngIf="!campaignFormGroup.get('title').valid && campaignFormGroup.get('title').touched"
                        >please enter a title between 5 and 20 characters</small
                    >
                    <input
                        id="title"
                        formControlName="title"
                        type="text"
                        placeholder="Enter a new campaign name" />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="form-group m-b-2">
                    <label for="subject">Subject </label>
                    <small
                        class="form-error"
                        *ngIf="!campaignFormGroup.get('subject').valid && campaignFormGroup.get('subject').touched"
                        >please enter a subject between 5 and 20 characters</small
                    >
                    <input
                        id="subject"
                        formControlName="subject"
                        type="text"
                        placeholder="Enter a new subject" />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="form-group m-b-2">
                    <label for="preview">Preview </label>
                    <small
                        class="form-error"
                        *ngIf="!campaignFormGroup.get('preview').valid && campaignFormGroup.get('preview').touched"
                        >please enter a preview between 5 and 20 characters</small
                    >
                    <input
                        id="preview"
                        formControlName="preview"
                        type="text"
                        placeholder="Enter a new preview" />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="form-group m-b-2">
                    <label for="senderName">Name of the sender </label>
                    <small
                        class="form-error"
                        *ngIf="!campaignFormGroup.get('senderName').valid && campaignFormGroup.get('senderName').touched"
                        >please enter a name between 5 and 20 characters</small
                    >
                    <input
                        id="senderName"
                        formControlName="senderName"
                        type="text"
                        placeholder="Enter the sender name" />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="form-group m-b-2">
                    <label for="senderMail">Mail of the sender </label>
                    <small
                        class="form-error"
                        *ngIf="!campaignFormGroup.get('senderMail').valid && campaignFormGroup.get('senderMail').touched"
                        >invallid email</small
                    >
                    <input
                        id="senderMail"
                        formControlName="senderMail"
                        type="email"
                        placeholder="Mail where the email wil be send from" />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="form-group m-b-2">
                    <label for="template">Template</label>
                    <select
                        id="template"
                        formControlName="template">
                        <option
                            *ngFor="let template of templates"
                            [ngValue]="template.id">
                            {{ template.name }}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="form-group m-b-2">
                    <label for="list">List</label>
                    <select
                        id="list"
                        formControlName="list"
                        (change)="onChange($event.target.value)">
                        <option
                            *ngFor="let list of lists"
                            [ngValue]="list.id">
                            {{ list.name }}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div
            class="row"
            *ngIf="campaignFormGroup.get('list').valid">
            <div class="col">
                <div class="form-group m-b-2">
                    <label for="tag">Tag</label>
                    <select
                        id="tag"
                        formControlName="tag">
                        <option
                            *ngFor="let tag of tags"
                            [ngValue]="tag.id">
                            {{ tag.name }}
                        </option>
                    </select>
                </div>
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <input
            [ngClass]="!campaignFormGroup.valid ? 'button button--disabled w-100' : 'button button--primary w-100'"
            type="submit"
            value="Save"
            alt="SAVE"
            [disabled]="!campaignFormGroup.valid" />
        <input
            [ngClass]="!campaignFormGroup.valid ? 'button button--disabled w-100' : 'button button--danger w-100'"
            type="submit"
            value="Send"
            alt="SEND"
            [disabled]="!campaignFormGroup.valid" />
    </div>
</form>
