<div *ngIf="isLoading">
    <app-loading-message></app-loading-message>
</div>

<div *ngIf="!isLoading">
    <div class="app__title">
        <div>
            <h1
                *ngIf="!editName"
                (dblclick)="editName = true">
                {{ (directory$ | async).name }}
            </h1>
            <span
                *appIsPermitted="{
                    module: 'DOCUMENTS',
                    operator: 'AND',
                    actions: ['EDIT']
                }">
                <input
                    *ngIf="editName"
                    (keyup.enter)="saveName()"
                    type="text"
                    [(ngModel)]="name" />
            </span>
        </div>

        <div class="d-flex align-items-center">
            <app-searchbar
                [(value)]="filter"
                (valueChange)="filtering()"></app-searchbar>
            <span
                class="button button--primary m-l-2"
                *appIsPermitted="{
                    module: 'DOCUMENTS',
                    operator: 'AND',
                    actions: ['CREATE']
                }"
                (click)="addDocument()"
                >New document</span
            >
            <span
                ngbDropdown
                class="m-l-2">
                <button
                    ngbDropdownToggle
                    class="button button--icon button--dropdown">
                    Options
                </button>
                <span
                    ngbDropdownMenu
                    class="dropdown-menu dropdown-menu-right"
                    *appIsPermitted="{
                        module: 'DOCUMENTS',
                        operator: 'OR',
                        actions: ['EDIT', 'DELETE']
                    }"
                    aria-labelledby="dropdownMenuButton">
                    <a
                        class="dropdown-item"
                        *appIsPermitted="{
                            module: 'DOCUMENTS',
                            operator: 'AND',
                            actions: ['CREATE']
                        }"
                        (click)="addDocument()"
                        >New document</a
                    >
                    <a
                        class="dropdown-item"
                        *appIsPermitted="{
                            module: 'DOCUMENTS',
                            operator: 'AND',
                            actions: ['CREATE']
                        }"
                        (click)="addDirectory()"
                        >New folder</a
                    >
                    <a
                        class="dropdown-item color--red"
                        *appIsPermitted="{
                            module: 'DOCUMENTS',
                            operator: 'AND',
                            actions: ['DELETE']
                        }"
                        (click)="deleteCurrentFolder()"
                        >Delete current folder</a
                    >
                </span>
            </span>
        </div>
    </div>

    <div *ngIf="searching">
        <div class="document__overview">
            <app-documents-doc
                *ngFor="let doc of searchResults | async | filterBy: ['type']:'Doc'"
                [document]="doc"></app-documents-doc>
        </div>
    </div>

    <div *ngIf="!searching">
        <app-documents-dir
            *ngFor="let dir of (directory$ | async).docs | filterBy: ['type']:'Dir' | filterBy: ['name']:filter"
            [directory]="dir"
            (navigate)="navigate(dir)"></app-documents-dir>

        <div class="document__overview">
            <app-documents-doc
                *ngFor="let document of (directory$ | async).docs | filterBy: ['type']:'Doc' | filterBy: ['name']:filter"
                [document]="document"></app-documents-doc>
        </div>

        <span *ngIf="!(directory$ | async).docs.length">
            <app-message-empty
                [title]="'No documents or folders...'"
                [subtitle]="'Start adding folders and documents.'"
                [icon]="'../../assets/img/open-box.png'">
                <div class="d-flex align-items-center">
                    <a
                        class="button button--primary"
                        *appIsPermitted="{
                            module: 'DOCUMENTS',
                            operator: 'AND',
                            actions: ['CREATE']
                        }"
                        (click)="addDocument()"
                        >Add document</a
                    >
                    <a
                        class="button button--primary m-l-2"
                        *appIsPermitted="{
                            module: 'DOCUMENTS',
                            operator: 'AND',
                            actions: ['CREATE']
                        }"
                        (click)="addDirectory()"
                        >Add folder</a
                    >
                </div>
            </app-message-empty>
        </span>
    </div>
</div>
