import { Component, EventEmitter, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import ReasonOfLossModel from 'src/app/models/ReasonOfLossModel';

@Component({
    selector: 'app-submit-reason-modal',
    templateUrl: './submit-reason-modal.component.html',
    styleUrls: ['./submit-reason-modal.component.scss'],
})
export class SubmitReasonModalComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    public reasonEmitter: EventEmitter<ReasonOfLossModel | string> = new EventEmitter();
    @Input() reasons: ReasonOfLossModel[];

    reason = '';
    maxLength = 250;
    currentLength = 0;
    isLoading = true;

    reasonForm: FormGroup;
    addNewReason = false;

    constructor(private fb: FormBuilder, public activeModal: NgbActiveModal) {}

    ngOnInit(): void {
        this.reasonForm = this.fb.group({
            reason: ['', [Validators.required]],
        });
        this.isLoading = false;
    }

    submit() {
        if (this.addNewReason) {
            if (this.reason == '') {
                this.cancel();
            } else {
                this.reasonEmitter.emit({
                    uuid: undefined,
                    reason: this.reason.substring(0, this.maxLength),
                    recurrence: 0,
                });
                this.activeModal.close();
            }
        } else {
            this.reasonEmitter.emit(this.reasonForm.get('reason').value as unknown as ReasonOfLossModel);
            this.activeModal.close();
        }
    }

    cancel() {
        this.reasonEmitter.emit('No reason was given.');
        this.activeModal.close();
    }

    onKeyUp() {
        this.currentLength = this.reason.length;
        if (this.currentLength > this.maxLength) {
            this.reason = this.reason.substring(0, this.maxLength);
            this.currentLength = this.reason.length;
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
