import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { ContactpersonDeleteModalComponent } from 'src/app/contacts/contactperson-delete-modal/contactperson-delete-modal.component';
import { ContactpersonDetailModalComponent } from 'src/app/contacts/contactperson-detail-modal/contactperson-detail-modal.component';
import { ContactpersonDetailComponent } from 'src/app/contacts/contactperson-detail/contactperson-detail.component';
import { MailchimpService } from 'src/app/mailchimp/mailchimp.service';
import { ContactPerson } from 'src/app/models/ContactPerson';
import { FetchPartners } from 'src/app/partners/partners.actions';
import { ConfirmationModalComponent } from 'src/app/shared/confirmation-modal/confirmation-modal.component';
import { BulkAddTagModalComponent } from 'src/app/shared/global-tags/bulk-add-tag-modal/bulk-add-tag-modal.component';
import AddNewRecipientModel from 'src/app/shared/mailchimp/add-recipient-modal/AddNewRecipientModel';
import { AddRecipientsToListModalComponent } from 'src/app/shared/mailchimp/add-recipients-to-list-modal/add-recipients-to-list-modal.component';
import { JSONTryParse } from 'src/app/shared/utils/string-utils';

import { DeleteContactPerson, FetchContacts } from '../contacts.actions';
import { ContactState } from '../contacts.state';

@Component({
    selector: 'app-contacts-list',
    templateUrl: './contacts-list.component.html',
    styleUrls: ['./contacts-list.component.scss'],
})
export class ContactsListComponent implements OnInit, OnDestroy {
    @Select(ContactState.Contacts) contacts$: Observable<ContactPerson[]>;
    @Input() showActions = true;
    contactPersons: ContactPerson[];
    allCheck = false;
    checkBoxes: any[] = [];
    filter: string;
    tagFilter: string;
    partnerUuid: string;
    // material table
    displayedColumns: string[] = [
        'checkBox',
        'firstName',
        'contactInformation.phoneNumber',
        'contactInformation.email',
        'partnerName',
        'creatorId',
        'profession',
        'edit',
    ];
    dataSource: MatTableDataSource<ContactPerson>;
    @ViewChild(MatSort) sort: MatSort;
    private subscriptions = new Subscription();

    constructor(
        private modalService: NgbModal,
        private store: Store,
        private mailchimpService: MailchimpService,
        private toastr: ToastrService,
        private logger: NGXLogger,
    ) {}

    ngOnInit(): void {
        /* load contacts in state and retrieve */
        this.logger.debug('Fetching Contacts');
        this.store.dispatch(new FetchContacts());
        this.store.dispatch(new FetchPartners());
        this.updateTable();
    }

    applyFilter(filterValue) {
        this.dataSource.filter = filterValue?.trim().toLowerCase();
    }

    applyTagFilter(filterValue) {
        this.dataSource.filter = filterValue;
    }

    getCurrentCheck(contactPerson: ContactPerson) {
        if (this.checkBoxes.find((check) => check.contact === contactPerson.uuid) != undefined) {
            return this.checkBoxes.find((check) => check.contact === contactPerson.uuid).value;
        } else {
            const check = { contact: contactPerson.uuid, value: false };
            this.checkBoxes.push(check);
            return check.value;
        }
    }

    checkChange(contactPerson: ContactPerson) {
        this.checkBoxes.find((check) => check.contact === contactPerson.uuid).value = !this.checkBoxes.find((check) => check.contact === contactPerson.uuid)
            .value;
    }

    checkAll() {
        this.allCheck = !this.allCheck;
        this.checkBoxes.forEach((el) => {
            el.value = this.allCheck;
        });
    }

    getAmount() {
        return this.checkBoxes.filter((c) => c.value).length;
    }

    addTagsInBulk() {
        if (this.getAmount() <= 0) {
            this.toastr.warning('You have not selected any contacts.');
        } else {
            const modalRef = this.modalService.open(BulkAddTagModalComponent, { windowClass: 'modal-prompt', animation: false });
            const contactsToBeTagged = [];
            this.contactPersons.forEach((contact) => {
                if (this.checkBoxes.find((check) => check.contact === contact.uuid).value) {
                    contactsToBeTagged.push(contact);
                }
            });
            modalRef.componentInstance.objects = contactsToBeTagged;
            modalRef.componentInstance.type = 'contact';
        }
    }

    addToMailchimp() {
        if (this.getAmount() <= 0) {
            this.toastr.warning('You have not selected any contacts.');
        } else {
            this.logger.debug('Checking if Mailchimp api key exists');
            this.subscriptions.add(
                this.mailchimpService.checkIfApiKeyExists().subscribe(
                    (res) => {
                        if (res.key) {
                            this.logger.debug('Found Mailchimp key');
                            const recipients: AddNewRecipientModel[] = [];
                            this.contactPersons.forEach((contact) => {
                                if (this.checkBoxes.find((check) => check.contact === contact.uuid).value) {
                                    const rec: AddNewRecipientModel = {
                                        firstname: contact.firstName,
                                        lastname: contact.lastName,
                                        email: contact.contactInformation.email,
                                        phone: contact.contactInformation.phoneNumber,
                                        tags: [],
                                        listId: '',
                                    };
                                    recipients.push(rec);
                                }
                            });
                            this.logger.debug('opening addRecipientsToListModalComponent');
                            const modalRef = this.modalService.open(AddRecipientsToListModalComponent, { windowClass: 'modal-prompt', animation: false });
                            modalRef.componentInstance.recipients = recipients;
                        } else {
                            this.logger.debug('No Mailchimp key found');
                            this.toastr.warning('Please set it in the module settings.', 'Missing mailchimp key');
                        }
                    },
                    (error) => this.logger.error('Failed to find Mailchimp key'),
                ),
            );
        }
    }

    deleteContacts() {
        if (this.getAmount() <= 0) {
            this.toastr.warning('You have not selected any contacts.');
        } else {
            const selectedContacts = this.contactPersons.filter((contact) => this.checkBoxes.find((check) => check.contact === contact.uuid).value);

            this.logger.debug('Opening confirmationModalComponent type:delete');
            const modalRef = this.modalService.open(ConfirmationModalComponent, { windowClass: 'modal-prompt', animation: false });
            modalRef.componentInstance.type = 'Delete';
            modalRef.componentInstance.title = 'Delete contacts';
            if (this.getAmount() == 1) {
                modalRef.componentInstance.message = 'Are you sure you want to delete ' + this.getAmount() + ' contact?';
            } else {
                modalRef.componentInstance.message = 'Are you sure you want to delete ' + this.getAmount() + ' contacts?';
            }
            this.subscriptions.add(
                modalRef.componentInstance.closeEvent.subscribe((val) => {
                    if (val) {
                        this.logger.debug('Attempt to delete contactPersons');
                        selectedContacts.forEach((contact) => {
                            this.store.dispatch(new DeleteContactPerson(contact.uuid)).subscribe(
                                () => {
                                    this.logger.debug('Succesfully deleted contactPerson');
                                },
                                (error) => this.logger.error('Failed to delete contactPerson'),
                            );
                        });
                        if (this.getAmount() == 1) {
                            this.toastr.info(this.getAmount() + ' contact deleted.');
                        } else {
                            this.toastr.info(this.getAmount() + ' contacts deleted.');
                        }
                        this.checkAll();
                    }
                }),
            );
        }
    }

    updateTable() {
        this.subscriptions.add(
            this.contacts$.subscribe(
                (contactPersons) => {
                    this.contactPersons = contactPersons;

                    /* setup mattable with contacts */
                    this.dataSource = new MatTableDataSource(contactPersons);
                    this.dataSource.sort = this.sort;
                    this.dataSource.filterPredicate = (data: ContactPerson, filter: string) => {
                        let filterObj = null;
                        if (JSONTryParse(filter)) {
                            filterObj = JSON.parse(filter);
                        }

                        return (
                            (data.firstName + ' ' + data.lastName).toLowerCase().includes(filter.toLowerCase()) ||
                            (data.contactInformation &&
                                data.contactInformation.email &&
                                data.contactInformation.email.toLowerCase().includes(filter.toLowerCase())) ||
                            (data.profession && data.profession.toLowerCase().includes(filter.toLowerCase())) ||
                            (data.partnerName && data.partnerName.toLowerCase().includes(filter.toLowerCase())) ||
                            filterObj?.objectsWithTagUuids.includes(data.uuid)
                        );
                    };

                    this.dataSource.sortingDataAccessor = (item, property) => {
                        if (property.includes('.')) return property.split('.').reduce((o, i) => o[i], item);
                        return item[property];
                    };
                    this.applyFilter(this.filter);
                    this.applyTagFilter(this.tagFilter);

                    this.checkBoxes = [];
                    this.contactPersons.forEach((c) => {
                        const check = {
                            contact: c.uuid,
                            value: false,
                        };
                        this.checkBoxes.push(check);
                    });
                },
                (error) => this.logger.error('Failed to fetch Contacts'),
            ),
        );
    }

    openUpdateContactPersonModal(contactPerson: ContactPerson) {
        this.logger.debug('opening contactpersonDetailModal - isUpdate');
        const modalRef = this.modalService.open(ContactpersonDetailModalComponent, { animation: false });
        modalRef.componentInstance.contactPersonUuid = contactPerson.uuid;
        modalRef.componentInstance.inOverview = false;
        modalRef.componentInstance.isUpdate = true;
    }

    openContactPersonDetailModal(contactPerson: ContactPerson) {
        this.logger.debug('opening contactpersonDetailModal');
        const modalRef = this.modalService.open(ContactpersonDetailComponent, {
            windowClass: 'modal-prompt',
            animation: false,
        });
        modalRef.componentInstance.contactPersonUuid = contactPerson.uuid;
    }

    openContactPersonDeleteModal(contactPerson: string) {
        this.logger.debug('opening contactpersonDeleteModal');
        const modalRef = this.modalService.open(ContactpersonDeleteModalComponent, { windowClass: 'modal-prompt', animation: false });
        modalRef.componentInstance.contactPerson = contactPerson;
    }

    openAddContactPersonsModal() {
        this.logger.debug('opening AddcontactpersonModal');
        const modalRef = this.modalService.open(ContactpersonDetailModalComponent, { animation: false });
        modalRef.componentInstance.inOverview = true;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
