import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';

import { VeldaLanguagesCountriesAddModalComponent } from '../velda-languages-countries-add-modal/velda-languages-countries-add-modal.component';
import { FetchCountries } from '../velda-languages.actions';

@Component({
    selector: 'app-velda-languages-countries-overview',
    templateUrl: './velda-languages-countries-overview.component.html',
    styleUrls: ['./velda-languages-countries-overview.component.scss'],
})
export class VeldaLanguagesCountriesOverviewComponent implements OnInit {
    constructor(private store: Store, private modalService: NgbModal, private logger: NGXLogger) {}

    ngOnInit(): void {
        this.logger.debug('Fetching Velda Countries');
        this.store.dispatch(new FetchCountries());
    }

    openAddCountryModal() {
        this.logger.debug('Opening VeldaLanguagesCountriesAddModal');
        this.modalService.open(VeldaLanguagesCountriesAddModalComponent, {
            windowClass: 'modal-prompt',
        });
    }
}
