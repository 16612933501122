import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subscription } from 'rxjs';
import { ModuleEnum } from 'src/app/models/ModuleEnum';
import { Product } from 'src/app/models/Product';
import { Warehouse } from 'src/app/models/Warehouse';
import { WarehouseLocation } from 'src/app/models/WarehouseLocation';
import { ProductAddModalComponent } from 'src/app/products/product-add-modal/product-add-modal.component';
import { FetchProducts } from 'src/app/products/product.actions';
import { ProductState } from 'src/app/products/product.state';
import { ModuleService } from 'src/app/services/module.service';
import { EmployeeAssignModalComponent } from 'src/app/shared/employee-assign-modal/employee-assign-modal.component';
import { FetchWarehouses } from 'src/app/warehouses/warehouse.actions';
import { WarehouseState } from 'src/app/warehouses/warehouse.state';

import { FetchWarehouseLocations } from '../warehouse.location.actions';
import { WarehouseLocationState } from '../warehouse.location.state';

@Component({
    selector: 'app-warehouse-location-detail-modal',
    templateUrl: './warehouse-location-detail-modal.component.html',
    styleUrls: ['./warehouse-location-detail-modal.component.scss'],
})
export class WarehouseLocationDetailModalComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Input() warehouseLocationUuid: string;
    @Select(WarehouseState.Warehouses) warehouses$: Observable<Warehouse[]>;
    @Select(WarehouseLocationState.WarehouseLocations)
    warehouseLocations$: Observable<WarehouseLocation[]>;
    @Select(ProductState.Products) products$: Observable<Product[]>;

    warehouse: Warehouse;
    warehouseLocation: WarehouseLocation;
    eModule = ModuleEnum;
    isLoading = true;

    constructor(
        private store: Store,
        private modalService: NgbModal,
        public activeModal: NgbActiveModal,
        public moduleService: ModuleService,
        private logger: NGXLogger,
    ) {}

    ngOnInit(): void {
        this.logger.debug('Fetching Products');
        this.store.dispatch(new FetchProducts());

        this.logger.debug('Fetching warehouses');
        this.store.dispatch(new FetchWarehouses());
        this.subscriptions.add(
            this.warehouseLocations$.subscribe((warehouseLocations) => {
                if (warehouseLocations === null) {
                    this.store.dispatch(new FetchWarehouseLocations());
                } else {
                    this.warehouseLocation = warehouseLocations.find((w) => w.uuid == this.warehouseLocationUuid);
                    if (this.warehouseLocation) {
                        this.isLoading = false;
                    }
                    this.warehouseLocation?.warehouseUuid
                        ? this.warehouses$.subscribe((warehouses) => {
                              this.warehouse = warehouses.find((w) => w.uuid === this.warehouseLocation.warehouseUuid);
                          })
                        : (this.warehouse = null);
                }
            }),
        );

        if (this.warehouseLocation?.warehouseUuid != null) {
            this.logger.debug('Fetching warehouses');
            this.store.dispatch(new FetchWarehouses());
        }
    }

    openEmployeeAssignModal(object: any) {
        this.logger.debug('Opening assignEmployeeModal');
        const modalRef = this.modalService.open(EmployeeAssignModalComponent);
        modalRef.componentInstance.object = object;
    }

    openProductAddModal() {
        this.logger.debug('Opening productAddModal');
        const modalRef = this.modalService.open(ProductAddModalComponent, {
            windowClass: 'modal-pane',
            animation: false,
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
