<a
    class="pill pill--lead"
    *ngIf="lead"
    (click)="openLeadDetailComponent()">
    <img src="https://eu.ui-avatars.com/api/?background=random&bold=true&name={{ lead.firstName + ' ' + lead.lastName }}" />
    {{ lead.email }}
</a>
<a
    class="pill pill--lead"
    *ngIf="!lead"
    (click)="openLeadDetailComponent()">
    <img src="https://eu.ui-avatars.com/api/?background=random&bold=true&name={{ getTitle() }}" />
    {{ getTitle() }}
</a>
