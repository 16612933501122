import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AuthenticationService } from '../authentication/authentication.service';
import { FetchCompany } from '../companies/company.actions';
import { GetUser } from '../users/user.actions';

@Injectable({
    providedIn: 'root',
})
export class HomeGuard implements CanActivate {
    constructor(private authService: AuthenticationService, private store: Store, private route: ActivatedRoute, private router: Router) {}

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        /*  
      Only redirect to home if the url is the main resumo url
      otherwise redirect to login 
    */
        if (window.location.href.includes('https://resumo.io/')) {
            if (this.authService.getToken()) {
                return this.store.dispatch(new GetUser()).pipe(
                    catchError((error) => of(true)),
                    map(() => true, this.store.dispatch(new FetchCompany())),
                );
            } else {
                return of(true);
            }
        } else {
            this.router.navigate(['/login']);
            return of(true);
        }
    }
}
