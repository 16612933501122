<app-app-holder>
    <app-navbar></app-navbar>

    <app-sidebar>
        <app-settings-subnav></app-settings-subnav>
    </app-sidebar>

    <app-content>
        <span class="sectiontitle">Mailing list</span>
        <small>
            There are three timing options: Monthly, Weekly and Daily.
            <br />
            When choosing monthly you can enter a number. This number what day of the month you will be receiving the mail. This means when you enter 5 you will
            receive a mail on the 5th day of the month. You can also enter negative numbers. This will represent the end of the month. Thus, when you enter -5
            you will receive mail five days before the end of the month.
            <br />
            When you choose weekly you are able to choose which day of the week you want to receive the mail. (Ie. Monday to Sunday)
            <br />
            Daily will do daily checks and send you mails if there is something relevant for the next day.
            <br /><br />
            Messages will contain relevant data according to the timing you choose. Thus, when you choose daily you will receive updates for the next day, but
            when choosing monthly you will receive updates that are relevant for the next 30-31 days.
        </small>
        <div class="m-t-4">
            <div *ngIf="isLoading">
                <app-loading-message></app-loading-message>
            </div>

            <div *ngIf="!isLoading">
                <div *ngFor="let module of displayedModules">
                    <span *ngIf="getMailingTypesByModule(module.key).length > 0">
                        <span
                            *appIsPermitted="{
                                module: module.key,
                                operator: 'AND',
                                actions: ['READ']
                            }">
                            <h5>{{ module.title }}</h5>
                            <table
                                mat-table
                                [dataSource]="getMailObjectsByModuleKey(module.key)"
                                matSort>
                                <ng-container matColumnDef="mail">
                                    <th
                                        mat-header-cell
                                        *matHeaderCellDef
                                        mat-sort-header>
                                        Mail
                                    </th>
                                    <td
                                        mat-cell
                                        *matCellDef="let mail">
                                        <span *ngFor="let mail of mail.emails">
                                            <app-pill-mailto [mailtoEmail]="mail"></app-pill-mailto>
                                            <br />
                                        </span>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="action">
                                    <th
                                        mat-header-cell
                                        *matHeaderCellDef
                                        mat-sort-header>
                                        Action
                                    </th>
                                    <td
                                        mat-cell
                                        *matCellDef="let mail">
                                        <p>
                                            {{ getTypeDescription(mail.mailingType) }}
                                        </p>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="timing">
                                    <th
                                        mat-header-cell
                                        *matHeaderCellDef
                                        mat-sort-header>
                                        Timing
                                    </th>
                                    <td
                                        mat-cell
                                        *matCellDef="let mail">
                                        <p *ngIf="mail.cronjobInfo">
                                            {{ getCronDescription(mail.cronjobInfo.cron) }}
                                        </p>
                                        <p *ngIf="!mail.cronjobInfo">none</p>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="edit">
                                    <th
                                        mat-header-cell
                                        *matHeaderCellDef
                                        class="td-small"></th>
                                    <td
                                        mat-cell
                                        *matCellDef="let mail"
                                        class="td-small">
                                        <span
                                            ngbDropdown
                                            *appIsPermitted="{
                                                module: module.key,
                                                operator: 'AND',
                                                actions: ['DELETE']
                                            }">
                                            <button
                                                ngbDropdownToggle
                                                class="button button--icon button--dropdown button--small"></button>
                                            <span
                                                ngbDropdownMenu
                                                class="dropdown-menu dropdown-menu-right"
                                                aria-labelledby="dropdownMenuButton">
                                                <a
                                                    class="dropdown-item color--red"
                                                    (click)="deleteMail(module, mail)"
                                                    >Delete</a
                                                >
                                            </span>
                                        </span>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="add">
                                    <ng-container
                                        *appIsPermitted="{
                                            module: module.key,
                                            operator: 'AND',
                                            actions: ['CREATE']
                                        }">
                                        <td
                                            mat-footer-cell
                                            *matFooterCellDef
                                            colspan="4"
                                            class="table-view__add"
                                            (click)="openAddMailModal(module)">
                                            <i class="las la-plus"></i> Add mail rule
                                        </td>
                                    </ng-container>
                                </ng-container>

                                <tr
                                    mat-header-row
                                    *matHeaderRowDef="displayedColumns"></tr>

                                <tr
                                    mat-row
                                    *matRowDef="let row; columns: displayedColumns"></tr>

                                <tr
                                    mat-footer-row
                                    *matFooterRowDef="['add']"></tr>
                            </table>
                            <br />
                        </span>
                    </span>
                </div>
            </div>
        </div>
    </app-content>
</app-app-holder>
