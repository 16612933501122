import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import * as moment from 'moment';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { Partner } from 'src/app/models/Partner';
import Project from 'src/app/models/Project';
import { FetchProducts } from 'src/app/products/product.actions';
import { ProjectDeleteModalComponent } from 'src/app/projects/project-delete-modal/project-delete-modal.component';
import { ProjectDetailModalComponent } from 'src/app/projects/project-detail-modal/project-detail-modal.component';
import { ProjectInfoModalComponent } from 'src/app/projects/project-info-modal/project-info-modal.component';
import { FetchProjects, UpdateFinishedOfProject } from 'src/app/projects/project.actions';
import { ProjectState } from 'src/app/projects/project.state';
import { GetUser } from 'src/app/users/user.actions';

import { GetPartner } from '../partners.actions';

@Component({
    selector: 'app-detail-projects-list',
    templateUrl: './detail-projects-list.component.html',
    styleUrls: ['./detail-projects-list.component.scss'],
})
export class DetailProjectsListComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Select(ProjectState.projects) projects$: Observable<Project[]>;
    @Input() projects: Project[];
    @Input() partnerUuid: string;
    showProjects: Project[];
    partnerId: string;
    partner: Partner;

    displayedColumns: string[] = ['eye', 'title', 'endDate', 'creator', 'edit'];
    dataSource: MatTableDataSource<Project>;
    @ViewChild(MatSort) sort: MatSort;

    constructor(
        private modalService: NgbModal,
        private store: Store,
        public activeModal: NgbActiveModal,
        private router: Router,
        private route: ActivatedRoute,
        private toastr: ToastrService,
        private logger: NGXLogger,
    ) {}

    ngOnInit(): void {
        this.store.dispatch(new FetchProducts());
        this.partnerId = this.route.snapshot.paramMap.get('id');

        this.logger.debug('Fetching partner');
        this.subscriptions.add(
            this.store
                .dispatch(new GetPartner(this.partnerId))
                .subscribe((state) => (this.partner = state.partner.partners.find((partner) => partner.uuid == this.partnerId))),
        );
        this.logger.debug('Fetching user');
        this.store.dispatch(GetUser);

        this.search();

        this.dataSource.sort = this.sort;

        this.dataSource.sortingDataAccessor = (data: object, sortHeaderId: string): string | number => {
            const propPath = sortHeaderId.split('.');
            const value: any = propPath.reduce((curObj, property) => curObj[property], data)?.toUpperCase();

            return !isNaN(value) ? Number(value) : value;
        };
    }

    search() {
        this.showProjects = null;
        if (this.partnerUuid) {
            this.subscriptions.add(
                this.projects$.subscribe((projects: Project[]) => {
                    this.showProjects = projects.filter((project) => project.partnerUuid?.toLowerCase().includes(this.partnerUuid));
                    this.dataSource = new MatTableDataSource(this.showProjects);
                }),
            );
        } else {
            this.subscriptions.add(
                this.projects$.subscribe((projects: Project[]) => {
                    this.showProjects = projects.filter((project) => project.partnerUuid?.toLowerCase().includes(this.partnerId));
                    this.dataSource = new MatTableDataSource(this.showProjects);
                }),
            );
        }
    }

    openAddProjectModal() {
        this.logger.debug('Opening projectDetailModal - add');
        const modalRef = this.modalService.open(ProjectDetailModalComponent);
        modalRef.componentInstance.inOverview = true;
        if (this.partnerUuid) {
            modalRef.componentInstance.partnerUuidInput = this.partnerUuid;
        } else {
            modalRef.componentInstance.partnerUuidInput = this.partner.uuid;
        }
    }

    openProjectUpdateModal(project: Project) {
        this.logger.debug('Opening projectDetailModal - update');
        const modalRef = this.modalService.open(ProjectDetailModalComponent);
        modalRef.componentInstance.project = project;
        modalRef.componentInstance.isUpdate = true;
    }

    openProjectDeleteModal(project: Project) {
        this.logger.debug('Opening projectDeleteModal');
        const modalRef = this.modalService.open(ProjectDeleteModalComponent, {
            windowClass: 'modal-prompt',
        });
        modalRef.componentInstance.project = project;
    }

    openProjectInfoModal(project: Project) {
        this.logger.debug('Opening projectInfoModal');
        const modalRef = this.modalService.open(ProjectInfoModalComponent);
        modalRef.componentInstance.projectUuid = project.uuid;
    }

    projectDetail(project: Project) {
        this.logger.debug('Navigating to project details');
        this.router.navigate(['/', 'projects', project.uuid, 'detail']);
    }

    toggleFinishedProject(project: Project) {
        this.logger.debug('Attempt to update finished status of project');
        this.subscriptions.add(
            this.store.dispatch(new UpdateFinishedOfProject(project.uuid)).subscribe(
                () => {
                    this.logger.debug('Fetching project');
                    this.store.dispatch(new FetchProjects());
                    this.logger.debug('Succesfully updated finished status of project');
                    this.toastr.success('Project status updated');
                },
                (error) => {
                    this.logger.debug('Failed to update finished status of project');
                    this.toastr.error('Something went wrong');
                },
            ),
        );
    }

    checkDates(project: Project): boolean {
        if (project.endDate) {
            const endDate = moment(project.endDate, 'yyyy-MM-DD hh:mm:ss');

            if (endDate < moment()) {
                return true;
            }
        }
        return false;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
