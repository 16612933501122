import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { append, compose, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { tap } from 'rxjs';

import { Notification } from '../models/Notification';
import {
    ArchiveAllNotifications,
    ArchiveNotification,
    CreateNotification,
    CreateNotificationForComments,
    DeleteAllArchivedNotifications,
    FetchArchivedNotifications,
    FetchNotifications,
    MarkNotificationAsRead,
} from './notifications.actions';
import { NotificationsService } from './notifications.service';

export interface NotificationsStateModel {
    notifications: Notification[];
    archivedNotifications: Notification[];
}

@State<NotificationsStateModel>({
    name: 'notifications',
    defaults: {
        notifications: [],
        archivedNotifications: [],
    },
})
@Injectable()
export class NotificationsState {
    constructor(private notificationsService: NotificationsService) {}

    @Selector()
    static notifications(state: NotificationsStateModel): Notification[] {
        return state?.notifications;
    }

    @Selector()
    static archivedNotifications(state: NotificationsStateModel): Notification[] {
        return state?.archivedNotifications;
    }

    @Action(FetchNotifications)
    fetchNotifications(ctx: StateContext<NotificationsStateModel>) {
        return this.notificationsService.fetchNotifications().pipe(
            tap((notifications) => {
                ctx.setState(
                    patch<NotificationsStateModel>({
                        notifications: notifications,
                    }),
                );
            }),
        );
    }

    @Action(FetchArchivedNotifications)
    fetchArchivedNotifications(ctx: StateContext<NotificationsStateModel>) {
        return this.notificationsService.fetchArchivedNotifications().pipe(
            tap((notifications) => {
                ctx.setState(
                    patch<NotificationsStateModel>({
                        archivedNotifications: notifications,
                    }),
                );
            }),
        );
    }

    @Action(CreateNotification)
    createNotification(ctx: StateContext<NotificationsStateModel>, action: CreateNotification) {
        return this.notificationsService.createNotification(action.notificationDTO);
    }

    @Action(CreateNotificationForComments)
    createNotificationForComments(ctx: StateContext<NotificationsState>, action: CreateNotificationForComments) {
        return this.notificationsService.createNotificationForComments(action.commentObjectId, action.message);
    }

    @Action(MarkNotificationAsRead)
    markNotificationAsRead(ctx: StateContext<NotificationsStateModel>, action: MarkNotificationAsRead) {
        return this.notificationsService.markNotificationAsRead(action.notificationId).pipe(
            tap(() => {
                ctx.setState(
                    patch<NotificationsStateModel>({
                        notifications: updateItem<Notification>(
                            (notification) => notification.id === action.notificationId,
                            patch<Notification>({
                                read: true,
                            }),
                        ),
                        archivedNotifications: updateItem<Notification>(
                            (notification) => notification.id === action.notificationId,
                            patch<Notification>({
                                read: true,
                            }),
                        ),
                    }),
                );
            }),
        );
    }

    @Action(ArchiveNotification)
    archiveNotification(ctx: StateContext<NotificationsStateModel>, action: ArchiveNotification) {
        return this.notificationsService.archiveNotification(action.notificationId).pipe(
            tap(() => {
                if (ctx.getState().notifications.find((notification) => notification.id === action.notificationId)) {
                    const notification: Notification = ctx.getState().notifications.find((notification) => notification.id === action.notificationId);
                    ctx.setState(
                        patch<NotificationsStateModel>({
                            notifications: removeItem<Notification>((notification) => notification.id === action.notificationId),
                        }),
                    );
                    ctx.setState(
                        patch<NotificationsStateModel>({
                            archivedNotifications: compose(
                                append<Notification>([notification]),
                                updateItem<Notification>(
                                    (notification) => notification.id === action.notificationId,
                                    patch<Notification>({
                                        archived: true,
                                    }),
                                ),
                            ),
                        }),
                    );
                } else {
                    const notification: Notification = ctx.getState().archivedNotifications.find((notification) => notification.id === action.notificationId);
                    ctx.setState(
                        patch<NotificationsStateModel>({
                            archivedNotifications: removeItem<Notification>((notification) => notification.id === action.notificationId),
                        }),
                    );
                    ctx.setState(
                        patch<NotificationsStateModel>({
                            notifications: compose(
                                append<Notification>([notification]),
                                updateItem<Notification>(
                                    (notification) => notification.id === action.notificationId,
                                    patch<Notification>({
                                        archived: false,
                                    }),
                                ),
                            ),
                        }),
                    );
                }
            }),
        );
    }

    @Action(ArchiveAllNotifications)
    archiveAllNotifications(ctx: StateContext<NotificationsStateModel>) {
        return this.notificationsService.archiveAllNotifications().pipe(
            tap(() => {
                const notifications: Notification[] = ctx.getState().notifications;
                ctx.setState(
                    patch<NotificationsStateModel>({
                        archivedNotifications: compose(
                            append<Notification>(notifications),
                            updateItem<Notification>(
                                (notification) => !notification.archived,
                                patch<Notification>({
                                    archived: true,
                                }),
                            ),
                        ),
                        notifications: [],
                    }),
                );
            }),
        );
    }

    @Action(DeleteAllArchivedNotifications)
    deleteAllArchivedNotifications(ctx: StateContext<NotificationsStateModel>) {
        return this.notificationsService.deleteAllArchivedNotifications().pipe(
            tap(() => {
                ctx.setState(
                    patch<NotificationsStateModel>({
                        archivedNotifications: [],
                    }),
                );
            }),
        );
    }
}
