import { Component, EventEmitter, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import RecipientListModel from 'src/app/models/RecipientListModel';

import { AddMailchimpTag } from '../mailchimp.actions';
import { MailchimpService } from '../mailchimp.service';

@Component({
    selector: 'app-add-tag-modal',
    templateUrl: './add-tag-modal.component.html',
    styleUrls: ['./add-tag-modal.component.scss'],
})
export class AddTagModalComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Input() lists: RecipientListModel;
    public closeEvent: EventEmitter<void> = new EventEmitter();
    tagFormGroup: FormGroup;

    constructor(
        public activeModal: NgbActiveModal,
        private formBuilder: FormBuilder,
        private mailchimpService: MailchimpService,
        private toastr: ToastrService,
        private store: Store,
    ) {}

    ngOnInit(): void {
        this.tagFormGroup = this.formBuilder.group({
            name: ['', [Validators.required]],
            list: ['', [Validators.required]],
        });
    }

    addTag() {
        this.subscriptions.add(
            this.store.dispatch(new AddMailchimpTag(this.tagFormGroup.get('list').value, this.tagFormGroup.get('name').value)).subscribe(() => {
                this.toastr.success('List updated successfully!');
                this.activeModal.close();
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
