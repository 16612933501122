import { Component, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NGXLogger } from 'ngx-logger';
import { Experience } from 'src/app/models/resume/Experience';

import { ExperienceDeleteModalComponent } from '../experience-delete-modal/experience-delete-modal.component';
import { ExperienceDetailModalComponent } from '../experience-detail-modal/experience-detail-modal.component';

@Component({
    selector: 'app-resume-experience-list',
    templateUrl: './resume-experience-list.component.html',
    styleUrls: ['./resume-experience-list.component.scss'],
})
export class ResumeExperienceListComponent {
    @Input() experiences: Experience[];
    @Input() showActions = true;
    @Input() resumeId: string;

    constructor(private modalService: NgbModal, private logger: NGXLogger) {}

    openExperienceAddModal() {
        this.logger.debug('Opening experienceDetailModal');
        const modalRef = this.modalService.open(ExperienceDetailModalComponent, { animation: false });
        modalRef.componentInstance.resumeUuid = this.resumeId;
    }

    openUpdateExperienceModal(experience: Experience) {
        this.logger.debug('Opening experienceDetailModal');
        const modalRef = this.modalService.open(ExperienceDetailModalComponent, { animation: false });

        modalRef.componentInstance.experience = experience;
        modalRef.componentInstance.resumeUuid = this.resumeId;
    }

    openDeleteExperienceModal(experience: Experience) {
        this.logger.debug('Opening experienceDetailModal');
        const modalRef = this.modalService.open(ExperienceDeleteModalComponent, { windowClass: 'modal-prompt', animation: false });
        modalRef.componentInstance.experience = experience;
    }
}
