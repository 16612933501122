import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import RecipientListModel from 'src/app/models/RecipientListModel';
import RecipientModel from 'src/app/models/RecipientModel';

import { RecipientInfoModalComponent } from '../../shared/mailchimp/recipient-info-modal/recipient-info-modal.component';
import { BatchAddLeadsComponent } from '../batch-add-leads/batch-add-leads.component';
import { MailchimpRecipientsModalComponent } from '../mailchimp-recipients-modal/mailchimp-recipients-modal.component';
import { FetchAudience } from '../mailchimp.actions';
import { MailchimpService } from '../mailchimp.service';
import { MailchimpState } from '../mailchimp.state';

@Component({
    selector: 'app-mailchimp-recipient-list-list',
    templateUrl: './mailchimp-recipient-list-list.component.html',
    styleUrls: ['./mailchimp-recipient-list-list.component.scss'],
})
export class MailchimpRecipientListListComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Select(MailchimpState.audiences) audiences$: Observable<RecipientListModel[]>;
    lists: RecipientListModel[];
    isset = true;

    filter: string;
    loading = true;

    constructor(private mailchimpService: MailchimpService, private modalService: NgbModal, private store: Store) {}

    ngOnInit(): void {
        this.subscriptions.add(
            this.mailchimpService.checkIfApiKeyExists().subscribe((e) => {
                if (e.key) {
                    this.audiences$.subscribe((a) => {
                        if (a.length == 0) {
                            this.store.dispatch(new FetchAudience()).subscribe();
                        }
                    });
                }
            }),
        );
        this.subscriptions.add(
            this.audiences$.subscribe((audiences) => {
                let audienceExists = false;
                if (audiences != undefined && audiences.length > 0) {
                    audienceExists = true;
                }
                if (audienceExists && audiences[0].recipients != undefined && audiences[0].recipients.length > 0) {
                    this.loading = false;
                }
            }),
        );
    }

    openRecipientList(list: RecipientListModel) {
        const modalRef = this.modalService.open(MailchimpRecipientsModalComponent, { windowClass: 'modal-pane', animation: false });
        modalRef.componentInstance.list = list;
    }

    openBatchAddLeads(list: RecipientListModel) {
        const modalRef = this.modalService.open(BatchAddLeadsComponent, {
            windowClass: 'modal-prompt',
        });
        modalRef.componentInstance.list = list;
    }

    checkKey(set: boolean) {
        this.isset = set;
    }

    showMemberDetails(member: RecipientModel) {
        const modalRef = this.modalService.open(RecipientInfoModalComponent, {
            windowClass: 'modal-prompt',
        });
        modalRef.componentInstance.recipient = member;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
