<div class="modal-header">
    <h2
        class="modal-title"
        id="exampleModalLabel">
        {{ campaign.name }}
    </h2>
    <span></span>
    <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <div
        *ngIf="campaign.status !== 'sent' && !loading"
        class="message message--info m-b-2">
        The campaign has not been sent yet. Please send out the campaign to get a report.
    </div>

    <ul class="list__specs">
        <li><label>Title</label> {{ campaign.name }}</li>
        <li><label>Recepients</label> {{ campaign.recipientCount }}</li>
        <li *ngIf="campaign.tagName">
            <label>Tag</label> <span class="tag">{{ campaign.tagName }}</span>
        </li>
        <li><label>Status</label> {{ campaign.status }}</li>
        <li *ngIf="campaign.status === 'sent' && !loading"><label>Opens</label> {{ report.opens }}</li>
        <li *ngIf="campaign.status === 'sent' && !loading"><label>Unique opens</label> {{ report.uniqueOpens }}</li>

        <li *ngIf="campaign.status === 'sent' && !loading">
            <label>Openrate </label>
            <app-percentage-progress [percentage]="(report.openRate * 100).toFixed(0)"></app-percentage-progress>
        </li>
        <li *ngIf="campaign.status === 'sent' && !loading"><label>Clicks</label> {{ report.clicks }}</li>
        <li *ngIf="campaign.status === 'sent' && !loading">
            <label>Clickrate </label>
            <app-percentage-progress [percentage]="(report.clickRate * 100).toFixed(0)"></app-percentage-progress>
        </li>
    </ul>

    <!--<p *ngFor="let prop of generateArray(campaign)">{{prop.key}}: {{prop.value}}</p>-->

    <!--<div style="align-self: center;">
        <div *ngIf="content">
            <div [innerHTML]="content.html"></div>
            <div *ngIf="content.html == '' || content.html == null">
                <p>Content has not been set</p>
            </div>
        </div>
    </div>-->

    <!--<div *ngIf="list">
        <p *ngFor="let prop of generateArray(list)">{{prop.key}}: {{prop.value}}</p>
    </div>-->

    <!--<p *ngFor="let prop of generateArray(report)">{{prop.key}}: {{prop.value}}</p>-->
</div>
