<div class="sidebar-spacing">
    <h3>Articles</h3>
</div>

<span class="divider divider--small"></span>

<nav class="app__subnav">
    <a
        routerLink="/velda-articles/articles"
        routerLinkActive="app__subnav__active"
        >Articles</a
    >
    <a
        routerLink="/velda-articles/articles-categories"
        routerLinkActive="app__subnav__active"
        >Articles Categories</a
    >
    <span class="divider divider--small"></span>
    <a
        routerLink="/velda-articles/collections"
        routerLinkActive="app__subnav__active"
        >Collections</a
    >
    <a
        routerLink="/velda-articles/subcollections"
        routerLinkActive="app__subnav__active"
        >Sub Collections</a
    >
    <a
        routerLink="/velda-articles/sets"
        routerLinkActive="app__subnav__active"
        >Sets</a
    >
    <span class="divider divider--small"></span>
    <a
        routerLink="/velda-articles/brands"
        routerLinkActive="app__subnav__active"
        >Brands</a
    >
    <a
        routerLink="/velda-articles/dealers"
        routerLinkActive="app__subnav__active"
        >Dealers</a
    >
</nav>
