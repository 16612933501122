import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { Product } from 'src/app/models/Product';
import { Unit } from 'src/app/models/Unit';
import { WarehouseLocation } from 'src/app/models/WarehouseLocation';
import { FetchUnits } from 'src/app/units/unit.actions';
import { UnitState } from 'src/app/units/unit.state';
import { FetchWarehouseLocations } from 'src/app/warehouse-locations/warehouse.location.actions';
import { WarehouseLocationState } from 'src/app/warehouse-locations/warehouse.location.state';

import { EmployeeState } from '../../employees/employees.state';
import { Employee } from '../../models/Employee';
import { ModuleEnum } from '../../models/ModuleEnum';
import { ModuleService } from '../../services/module.service';
import { AddProduct, FetchProducts, LinkProductToEmployee, LinkProducts } from '../product.actions';
import { ProductState } from '../product.state';

@Component({
    selector: 'app-product-add-modal',
    templateUrl: './product-add-modal.component.html',
    styleUrls: ['./product-add-modal.component.scss'],
})
export class ProductAddModalComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();
    public emitObject: EventEmitter<Product> = new EventEmitter();

    @Select(WarehouseLocationState.WarehouseLocations)
    allWarehouseLocations$: Observable<WarehouseLocation[]>;
    @Select(UnitState.Units) allUnits$: Observable<Unit[]>;
    @Select(ProductState.Products) allProducts$: Observable<Product[]>;
    @Input() linkedProduct: Product;
    @Input() product: Product;
    @Input() private duplicatedProduct: boolean;
    productFormGroup: FormGroup;
    loading = false;
    inputIsWrong = false;
    DESCRIPTION_MAX_LENGTH = 1000;
    currentAmountOfDescriptionCharacters: number;
    descriptionIsTooLong = false;
    amount: number;
    allUnits: Unit[];
    allWarehouseLocations: WarehouseLocation[];
    keyword = 'title';
    amountArray: number[] = [];
    allProducts: Product[];
    selectedChildProducts: Product[] = [];
    selectedParentProducts: Product[] = [];
    combinedProducts: Product[] = [];
    currentlyDeletedChild: Product;
    currentlyDeletedParent: Product;
    linkedProductList = '';
    amountList = '';
    productConsumable: boolean;
    productForCategories: Product;
    productForTagsUuid: string;
    allEmployeesList: Employee[];
    private selectedEmployee: Employee;
    eModule = ModuleEnum;

    @Select(EmployeeState.Employees) allEmployees$: Observable<Employee[]>;

    @ViewChild('focussed', { static: false })
    set input(element: ElementRef<HTMLInputElement>) {
        if (element) {
            element.nativeElement.focus();
        }
    }

    constructor(
        public activeModal: NgbActiveModal,
        public modal: NgbModal,
        private modalService: NgbModal,
        private formBuilder: FormBuilder,
        private store: Store,
        private toastr: ToastrService,
        private logger: NGXLogger,
        public moduleService: ModuleService,
    ) {}

    ngOnInit(): void {
        this.logger.debug('Fetching warehouseLocations');
        this.store.dispatch(new FetchWarehouseLocations());
        this.store.dispatch(new FetchUnits());
        this.subscriptions.add(
            this.allUnits$.subscribe((units) => {
                this.allUnits = units;
            }),
        );
        this.subscriptions.add(
            this.allWarehouseLocations$.subscribe((warehouseLocations) => {
                this.allWarehouseLocations = warehouseLocations;
            }),
        );
        this.subscriptions.add(
            this.allEmployees$.subscribe((emp) => {
                this.allEmployeesList = emp;
            }),
        );

        this.subscriptions.add(
            this.allProducts$.subscribe((products) => {
                this.allProducts = products;
            }),
        );
        if (this.duplicatedProduct) {
            this.productConsumable = this.product.productConsumable;
            this.productForCategories = this.product;
            this.productForTagsUuid = this.product.uuid;
            this.productFormGroup = this.formBuilder.group({
                title: [this.product.title ? this.product.title : '', [Validators.required]],
                description: [this.product.description ? this.product.description : ''],
                ean: [this.product.ean ? this.product.ean : ''],
                sku: [this.product.sku ? this.product.sku : ''],
                purchasePrice: [this.product.purchasePrice ? this.product.purchasePrice : ''],
                sellingPrice: [this.product.sellingPrice ? this.product.sellingPrice : ''],
                inStock: [this.product.inStock ? this.product.inStock : ''],
                stockNotification: [this.product.stockNotification ? this.product.stockNotification : ''],
                linkedProducts: [this.product.linkedProducts ? this.product.linkedProducts : null],
                unit: [this.product.unit ? this.product.unit : ''],
                warehouseLocation: [this.product.warehouseLocation ? this.product.warehouseLocation : ''],
                productConsumable: [this.product.productConsumable ? this.product.productConsumable : false],
                employee: [''],
            });
        } else {
            this.productFormGroup = this.formBuilder.group({
                title: ['', [Validators.required]],
                description: [''],
                ean: [''],
                sku: [''],
                purchasePrice: [''],
                sellingPrice: [''],
                inStock: [''],
                stockNotification: [''],
                linkedProducts: null,
                unit: [''],
                warehouseLocation: [''],
                productConsumable: true,
                employee: [''],
            });
        }
    }

    addProduct() {
        this.loading = true;
        if (!this.productFormGroup.valid) {
            this.inputIsWrong = true;
            this.loading = false;
            return;
        }
        let duplicateProduct = null;
        this.selectedParentProducts.forEach((p) => {
            this.selectedChildProducts.forEach((m) => {
                if (m.uuid == p.uuid) {
                    duplicateProduct = m;
                }
            });
        });
        if (duplicateProduct != null) {
            this.inputIsWrong = true;
            this.loading = false;
            this.toastr.warning('A product cannot be a child and a parent of the same product: ' + duplicateProduct.title);
            return;
        }

        this.productFormGroup.disable();

        this.product = this.productFormGroup.value;
        this.product.uuid = '';
        this.product.unitUuid = this.productFormGroup.value.unit.uuid;
        this.product.warehouseLocationUuid = this.productFormGroup.value.warehouseLocation.uuid;
        this.selectedEmployee = this.productFormGroup.value.employee;
        if (this.linkedProduct) {
            this.amountArray.push(this.amount ? this.amount : 0);
        }

        this.logger.debug('Attempt to add product');
        let productUuid;
        this.subscriptions.add(
            this.store.dispatch(new AddProduct(this.product)).subscribe(
                (state) => {
                    this.logger.debug('Succesfully added product, now attempting to add linkedProducts');
                    this.emitObject.emit(state.product.products.find((p) => p.title == this.product.title));
                    this.linkParents(state.product.currentProduct);
                    if (this.linkedProduct) {
                        this.store
                            .dispatch(
                                new LinkProducts(
                                    state.product.currentProduct.uuid,
                                    state.product.currentProduct.uuid,
                                    this.linkedProduct.uuid,
                                    this.amountArray,
                                ),
                            )
                            .subscribe(
                                () => {
                                    this.logger.debug('Succesfully added linkedProducts');
                                    this.toastr.success('Product added succesfully');
                                },
                                (error) => this.logger.error('Failed to add linkedProducts'),
                            );
                    }

                    if (this.selectedChildProducts) {
                        this.selectedChildProducts.forEach((m) => {
                            (this.linkedProductList = this.linkedProductList.concat(m.uuid) + ','), this.amountArray.push(m.amountAssigned);
                        });
                        this.linkedProductList = this.linkedProductList.slice(0, this.linkedProductList.length - 1);
                        this.logger.debug('Attempt to add linkedProduct');
                        this.subscriptions.add(
                            this.store
                                .dispatch(
                                    new LinkProducts(
                                        state.product.currentProduct.uuid,
                                        state.product.currentProduct.uuid,
                                        this.linkedProductList,
                                        this.amountArray,
                                    ),
                                )
                                .subscribe(
                                    () => {
                                        this.logger.debug('Succesfully added linkedProduct');
                                        this.toastr.success('Succesfully added linked product');
                                        if (this.selectedEmployee) {
                                            this.store
                                                .dispatch(new LinkProductToEmployee(state.product.currentProduct.uuid, this.selectedEmployee.uuid))
                                                .subscribe(
                                                    () => {
                                                        this.logger.debug('Successfully added product link to employee');
                                                        this.toastr.success('Successfully added product link to employee');
                                                    },
                                                    (error) => {
                                                        this.logger.error('Failed to link product to employee');
                                                        this.toastr.error('Product could not be linked to an employee');
                                                    },
                                                );
                                        }
                                        this.amount = null;
                                    },
                                    (error) => {
                                        this.logger.error('Failed to add linkedProduct');
                                        this.toastr.error('Linked product is already added');
                                    },
                                ),
                        );
                        this.selectedChildProducts = [];
                    }
                    productUuid = state.product.currentProduct.uuid;
                    this.store.dispatch(new FetchProducts());
                    this.activeModal.close();
                },
                (error) => {
                    this.loading = false;
                    this.productFormGroup.enable();
                },
            ),
        );
    }

    hideErrorMessage() {
        this.inputIsWrong = false;
    }
    linkParents(currentProduct: Product) {
        if (this.selectedParentProducts) {
            this.selectedParentProducts.forEach((p) => {
                this.subscriptions.add(this.store.dispatch(new LinkProducts(p.uuid, p.uuid, currentProduct.uuid, [p.amountAssigned])).subscribe());
            });
        }
    }

    onDescriptionKeyUp() {
        this.descriptionIsTooLong = false;
        this.currentAmountOfDescriptionCharacters = this.productFormGroup.controls.description.value.length;
        if (this.currentAmountOfDescriptionCharacters > this.DESCRIPTION_MAX_LENGTH) {
            this.descriptionIsTooLong = true;
        }
    }

    changeParents(parents: Product[]) {
        this.selectedParentProducts = parents;
        this.combinedProducts.push(this.selectedParentProducts[this.selectedParentProducts.length - 1]);
    }

    changeChildren(children: Product[]) {
        this.selectedChildProducts = children;
        this.combinedProducts.push(this.selectedChildProducts[this.selectedChildProducts.length - 1]);
    }

    changeCombinedProducts(products: Product[]) {
        this.combinedProducts = products;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
