import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subscription } from 'rxjs';
import { CompanyState } from 'src/app/companies/company.state';
import { Module } from 'src/app/models/Module';
import { FetchModules } from 'src/app/modules/module.actions';
import { ModuleState } from 'src/app/modules/module.state';
import { ModuleService } from 'src/app/services/module.service';
import { IntegrationDetailModalComponent } from 'src/app/shared/integration-detail-modal/integration-detail-modal.component';

@Component({
    selector: 'app-module-settings',
    templateUrl: './module-settings.component.html',
    styleUrls: ['./module-settings.component.scss'],
})
export class ModuleSettingsComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Select(ModuleState.Modules) allModules$: Observable<Module[]>;
    globalModules: Module[];
    allModules: Module[];
    allIntegrations: Module[];
    activeModules: Module[];
    activeIntegrations: Module[];

    displayedModuleColumns: string[] = ['module', 'description'];
    displayedIntegrationColumns: string[] = ['integration', 'description', 'edit'];
    @ViewChild(MatSort) sort: MatSort;

    constructor(private store: Store, private modalService: NgbModal, public moduleService: ModuleService, private logger: NGXLogger) {}

    ngOnInit(): void {
        this.logger.debug('Fetching Modules');
        this.store.dispatch(new FetchModules());

        this.subscriptions.add(
            this.allModules$.subscribe((moduleList) => {
                this.globalModules = moduleList;
                this.store.select(CompanyState.company).subscribe((company) => {
                    if (company && moduleList) {
                        this.allModules = this.globalModules.filter((module: Module) => module.moduleType === 'MODULE');
                        this.allIntegrations = this.globalModules.filter((module: Module) => module.moduleType === 'INTEGRATION');
                        this.activeModules = company.modules.filter((module: Module) => module.moduleType === 'MODULE');
                        this.activeIntegrations = company.modules.filter((module: Module) => module.moduleType === 'INTEGRATION');
                    }
                });
            }),
        );
    }

    checkActiveModule(module: Module): boolean {
        if (module.moduleType === 'MODULE') {
            return this.activeModules.filter((m: Module) => m.key === module.key).length !== 0;
        } else {
            return this.activeIntegrations.filter((m: Module) => m.key === module.key).length !== 0;
        }
        return false;
    }

    openIntegrationDetails(module: Module): void {
        this.logger.debug('Opening integrationDetailModal');
        const modalRef = this.modalService.open(IntegrationDetailModalComponent, { windowClass: 'modal-pane', animation: false });
        modalRef.componentInstance.module = module;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
