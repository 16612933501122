import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { AuthenticationService } from '../authentication/authentication.service';
import Lead from '../models/Lead';
import Mail from '../models/Mail';

@Injectable({
    providedIn: 'root',
})
export class LeadService {
    httpOptions = {};

    constructor(private httpClient: HttpClient, private authenticationService: AuthenticationService) {
        this.httpOptions = authenticationService.getHeaders();
    }

    getAllLeads() {
        return this.httpClient.get<Lead[]>(environment.baseUrl + '/lead/lead-list', this.httpOptions);
    }

    getArchivedLeads() {
        return this.httpClient.get<Lead[]>(environment.baseUrl + '/lead/archived-leads', this.httpOptions);
    }

    updateLead(lead: Lead): Observable<Lead> {
        return this.httpClient.put<Lead>(environment.baseUrl + '/lead/', lead, this.httpOptions);
    }

    addLead(lead: Lead): Observable<Lead> {
        return this.httpClient.post<Lead>(environment.baseUrl + '/lead/local', lead, this.httpOptions);
    }

    updateReadOfLead(uuid: string) {
        return this.httpClient.put<Lead>(environment.baseUrl + '/lead/readUpdate', uuid, this.httpOptions);
    }

    archiveLead(uuid: string) {
        return this.httpClient.put(environment.baseUrl + '/lead/' + uuid, {}, this.httpOptions);
    }

    getGmailByReceiver(receiver: string): Observable<Mail[]> {
        return this.httpClient.get<Mail[]>(environment.baseUrl + '/google/' + receiver + '/mail', this.httpOptions);
    }

    getOutlookMailByReceiver(receiver: string) {
        return this.httpClient.get<Mail[]>(environment.baseUrl + '/outlook/' + receiver + '/mail', this.httpOptions);
    }
}
