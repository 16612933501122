<form
    [formGroup]="memberRoleFormGroup"
    (ngSubmit)="updateMemberRole()"
    class="modal-wrapper">
    <div class="modal-header">
        <h2
            class="modal-title"
            id="exampleModalLabel">
            Assign a role
        </h2>
        <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="activeModal.close()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <div *ngIf="(customRoles$ | async)?.length != 0">
            <div class="form-group m-b-2">
                <div class="ng-autocomplete">
                    <ng-autocomplete
                        [data]="allCustomRoles"
                        [searchKeyword]="keyword"
                        formControlName="role"
                        autocomplete="off"
                        [itemTemplate]="itemTemplate"
                        [notFoundTemplate]="notFoundTemplate"
                        placeholder="Choose a role">
                    </ng-autocomplete>

                    <ng-template
                        #itemTemplate
                        let-item>
                        <a [innerHTML]="item.name"></a>
                    </ng-template>

                    <ng-template
                        #notFoundTemplate
                        let-notFound>
                        <!-- <div [innerHTML]="notFound"></div> -->
                        <div>+ Add Custom role</div>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <button
            type="button"
            class="button button--outline"
            (click)="activeModal.close()">
            Close
        </button>
        <button
            type="submit"
            [ngClass]="{ 'button--loading': loading }"
            class="button button--primary">
            Add role to member
        </button>
    </div>
</form>
