import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';
import { ClearCompany } from 'src/app/companies/company.actions';
import { ClearStore } from 'src/app/users/user.actions';
import { UserState } from 'src/app/users/user.state';

import { AuthenticationService } from '../authentication.service';

@Component({
    selector: 'app-loggedin',
    templateUrl: './loggedin.component.html',
    styleUrls: ['./loggedin.component.scss'],
})
export class LoggedinComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    userEmail: string;

    constructor(
        private authenticationService: AuthenticationService,
        private router: Router,
        private store: Store,
        private logger: NGXLogger,
        private route: ActivatedRoute,
    ) {}

    ngOnInit(): void {
        this.subscriptions.add(
            this.store.select(UserState.userInfo).subscribe((user) => {
                if (user) {
                    this.userEmail = user.email;
                }
            }),
        );
    }

    continueToResumo() {
        this.subscriptions.add(this.store.dispatch(new ClearStore()).subscribe(() => this.router.navigate(['/dashboard'])));
    }

    logOut() {
        this.subscriptions.add(
            this.authenticationService.logOut().subscribe(() => {
                localStorage.removeItem('token');
                this.store.dispatch(new ClearCompany()).subscribe(() => {
                    this.store.dispatch(new ClearStore()).subscribe(() => {
                        this.logger.debug('User logged out');
                        this.router.navigate(['/login']);
                    });
                });
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
