import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';

import { YukiSettings } from '../../models/yuki/YukiSettings';
import { MapLinksToBudgetExpenditures } from '../../shared/global-files/global-files.actions';
import { UpdateYukiSettings } from '../yuki.actions';

@Component({
    selector: 'app-budget-invoices-settings',
    templateUrl: './budget-invoices-settings.component.html',
    styleUrls: ['./budget-invoices-settings.component.scss'],
})
export class BudgetInvoicesSettingsComponent implements OnInit, OnDestroy {
    @Input() yukiSettings: YukiSettings;
    saveExpenditure: boolean;

    private subscriptions = new Subscription();
    constructor(public activeModal: NgbActiveModal, private store: Store, private toastr: ToastrService, private logger: NGXLogger) {}

    ngOnInit(): void {
        this.saveExpenditure = this.yukiSettings.saveExpenditure;
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    updateYukiSettings() {
        this.saveExpenditure = !this.saveExpenditure;
        const yukiSettings: YukiSettings = {
            saveExpenditure: this.saveExpenditure,
        };
        this.subscriptions.add(
            this.store.dispatch(new UpdateYukiSettings(this.yukiSettings.uuid, yukiSettings)).subscribe(
                (res) => {
                    this.toastr.success('Yuki settings successfully updated');
                },
                (error) => {
                    this.saveExpenditure = !this.saveExpenditure;
                    this.logger.error('Failed to update Yuki settings');
                    this.toastr.error('Oops, something went wrong, Please try again later...');
                },
            ),
        );
    }

    createExpenditures() {
        this.subscriptions.add(
            this.store.dispatch(new MapLinksToBudgetExpenditures()).subscribe({
                next: () => {
                    this.toastr.success('Successfully created new budget expenditures');
                },
                error: () => {
                    this.logger.error('Failed to create new budget expenditures');
                    this.toastr.error('Oops, something went wrong, Please try again later...');
                },
            }),
        );
    }
}
