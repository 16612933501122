import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';

import { CheckLoggedIn } from '../exact.actions';

@Component({
    selector: 'app-exact-overview',
    templateUrl: './exact-overview.component.html',
    styleUrls: ['./exact-overview.component.scss'],
})
export class ExactOverviewComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    constructor(private store: Store) {}

    ngOnInit(): void {
        this.subscriptions.add(this.store.dispatch(new CheckLoggedIn()).subscribe());
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
