<div class="modal-header">
    <h2
        class="modal-title"
        id="exampleModalLabel">
        Archive Warehouse location
    </h2>
    <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">Are you sure you want to archive {{ warehouseLocation.title }}?</div>

<div class="modal-footer">
    <button
        class="button button--danger"
        (click)="archiveWarehouseLocation()">
        Archive
    </button>
    <button
        type="button"
        class="button button--outline"
        (click)="activeModal.close()">
        Cancel
    </button>
</div>
