<div class="dashboard__tile__title">
    <span>Budgets approaching end date</span>
    <button
        type="button"
        class="button button--icon button--small"
        (click)="goToBudgets()">
        <i class="uil uil-arrow-right"></i>
    </button>
</div>

<div
    class="dashboard__tile__content"
    *ngIf="budgetsApproachingEndDate$ | async as budgets; else loading">
    <div *ngIf="budgetsApproachingEndDate.length > 0; else empty">
        <div class="table__overflow">
            <table
                mat-table
                [dataSource]="dataSource">
                <ng-container matColumnDef="name">
                    <th
                        mat-header-cell
                        *matHeaderCellDef
                        class="table-employee-column">
                        Employee
                    </th>
                    <td
                        mat-cell
                        *matCellDef="let row">
                        <app-pill-employee [employeeId]="row.linkedEmployee.uuid"></app-pill-employee>
                    </td>
                </ng-container>
                <ng-container matColumnDef="budget">
                    <th
                        mat-header-cell
                        *matHeaderCellDef>
                        Budget
                    </th>
                    <td
                        mat-cell
                        *matCellDef="let row">
                        <app-pill-budget [budget]="row"></app-pill-budget>
                    </td>
                </ng-container>
                <tr
                    mat-header-row
                    *matHeaderRowDef="displayedColumns"></tr>
                <tr
                    mat-row
                    *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
        </div>
    </div>
</div>

<ng-template #loading>
    <app-loading-message></app-loading-message>
</ng-template>
<ng-template #empty>
    <app-message-empty
        [title]="'No budgets that need your attention!'"
        [icon]="'../../assets/img/open-box.png'">
    </app-message-empty>
</ng-template>
