import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { formatDistance } from 'date-fns';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';

import { BUDGETREPEAT } from '../../models/BUDGETREPEAT';
import Budget from '../../models/Budget';
import BudgetExpenditure from '../../models/BudgetExpenditure';
import { SettingsState } from '../../settings/settings.state';
import { ConfirmationModalComponent } from '../../shared/confirmation-modal/confirmation-modal.component';
import { AddBudgetExpenditureModalComponent } from '../add-budget-expenditure-modal/add-budget-expenditure-modal.component';
import { AddBudgetModalComponent } from '../add-budget-modal/add-budget-modal.component';
import { ArchiveBudget, DeleteBudget, DeleteBudgetExpenditure, FetchBudgetById } from '../budget.actions';
import { BudgetService } from '../budget.service';
import { BudgetState } from '../budget.state';

@Component({
    selector: 'app-budget-detail-modal',
    templateUrl: './budget-detail-modal.component.html',
    styleUrls: ['./budget-detail-modal.component.scss'],
})
export class BudgetDetailModalComponent implements OnInit, OnDestroy {
    @Input() budgetId: string;

    @Select(BudgetState.Budget) budget$: Observable<Budget>;
    @Select(SettingsState.TableDisplayColumns('budget-detail')) displayedColumns$: Observable<string[]>;
    displayedColumns: string[] = ['description', 'cost', 'date', 'factuur', 'edit'];
    dataSource: MatTableDataSource<Budget> = new MatTableDataSource<Budget>();
    @ViewChild(MatSort) sort: MatSort;
    budget: Budget;
    attentionLevel: number;
    totalSpend: number;
    messageTitle: string;
    messageBody: string;
    messageSuccess = false;
    messageWarning = false;
    messageUrgent = false;
    getTotalSpend = Budget.getTotalSpend;
    getTotalSpendPercentage = Budget.getTotalSpendPercentage;
    checkAttentionLevel = Budget.checkAttentionLevel;
    private subscriptions = new Subscription();

    constructor(
        private logger: NGXLogger,
        private store: Store,
        public activeModal: NgbActiveModal,
        private modalService: NgbModal,
        private toastr: ToastrService,
        private budgetService: BudgetService,
    ) {}

    ngOnInit(): void {
        this.fetchData();
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    openBudgetUpdateModal() {
        const modalRef = this.modalService.open(AddBudgetModalComponent, {
            windowClass: 'modal-pane',
            animation: false,
        });
        modalRef.componentInstance.budget = this.budget;
        modalRef.componentInstance.type = 'Update budget';
    }

    openArchiveBudgetModal() {
        this.logger.debug('Opening confirmationModal for archive');
        const modalRef = this.modalService.open(ConfirmationModalComponent, {
            windowClass: 'modal-prompt',
            animation: false,
        });
        if (this.budget.archived) {
            modalRef.componentInstance.type = 'YesOrNo';
            modalRef.componentInstance.title = 'Unarchive budget';
            modalRef.componentInstance.htmlMessage = `<p>Are you sure you want to unarchive <strong>${this.budget.budgetType}</strong>?</p>`;
        } else {
            modalRef.componentInstance.type = 'Archive';
            modalRef.componentInstance.title = 'Archive budget';
            modalRef.componentInstance.htmlMessage = `<p>Are you sure you want to archive <strong>${this.budget.budgetType}</strong>?</p>`;
        }

        this.subscriptions.add(
            modalRef.componentInstance.closeEvent.subscribe((value) => {
                if (value) {
                    this.logger.debug('Attempt to (un)archive Budget');
                    this.store.dispatch(new ArchiveBudget(this.budget.uuid)).subscribe({
                        next: () => {
                            this.logger.debug('Successfully (un)archived budget');
                            if (this.budget.archived) {
                                this.toastr.success('Budget successfully unarchived.');
                            } else {
                                this.toastr.success('Budget successfully archived.');
                            }

                            this.activeModal.close();
                        },
                        error: () => {
                            this.logger.debug('Failed to (un)archive budget');
                            this.toastr.error('Oops, something went wrong, Please try again later...');
                        },
                    });
                }
            }),
        );
    }

    openDeleteBudgetModal() {
        this.logger.debug('Opening confirmationModal for delete');
        const modalRef = this.modalService.open(ConfirmationModalComponent, {
            windowClass: 'modal-prompt',
            animation: false,
        });

        modalRef.componentInstance.type = 'Delete';
        modalRef.componentInstance.title = 'Delete budget';
        modalRef.componentInstance.htmlMessage = `<p>Are you sure you want to delete <strong>${this.budget.budgetType}</strong>?</p>`;

        this.subscriptions.add(
            modalRef.componentInstance.closeEvent.subscribe((value) => {
                if (value) {
                    this.logger.debug('Attempt to delete Budget');
                    this.store.dispatch(new DeleteBudget(this.budget.uuid)).subscribe({
                        next: () => {
                            this.logger.debug('Successfully deleted budget');
                            this.toastr.success('Budget successfully deleted.');
                            this.activeModal.close();
                        },
                        error: () => {
                            this.logger.debug('Failed to delete budget');
                            this.toastr.error('Oops, something went wrong, Please try again later...');
                        },
                    });
                }
            }),
        );
    }

    openAddBudgetExpenditureModal() {
        const modalRef = this.modalService.open(AddBudgetExpenditureModalComponent, {
            windowClass: 'modal-prompt',
            animation: false,
        });
        modalRef.componentInstance.type = 'Create budget expenditure';
        modalRef.componentInstance.budget = this.budget;
    }

    openAddBudgetModal() {
        const modalRef = this.modalService.open(AddBudgetModalComponent, {
            windowClass: 'modal-pane',
            animation: false,
        });
        modalRef.componentInstance.type = 'Assign budget';
    }

    openUpdateBudgetExpenditureModal(budgetExpenditure: BudgetExpenditure) {
        const modalRef = this.modalService.open(AddBudgetExpenditureModalComponent, {
            windowClass: 'modal-prompt',
            animation: false,
        });
        modalRef.componentInstance.type = 'Update budget expenditure';
        modalRef.componentInstance.budget = this.budget;
        modalRef.componentInstance.budgetExpenditure = budgetExpenditure;
    }

    deleteBudgetExpenditure(budgetExpenditure: BudgetExpenditure) {
        this.logger.debug('Opening confirmationModal');
        const modalRef = this.modalService.open(ConfirmationModalComponent, {
            windowClass: 'modal-prompt',
            animation: false,
        });
        modalRef.componentInstance.type = 'Delete';
        modalRef.componentInstance.title = 'Delete budget expenditure';
        modalRef.componentInstance.htmlMessage = `<p>Are you sure you want to delete <strong>${budgetExpenditure.expenditure}</strong>?</p>`;
        this.subscriptions.add(
            modalRef.componentInstance.closeEvent.subscribe((value) => {
                if (value) {
                    this.logger.debug('Attempt to delete BudgetTemplate');
                    this.store.dispatch(new DeleteBudgetExpenditure(this.budget, budgetExpenditure)).subscribe({
                        next: () => {
                            this.logger.debug('Successfully removed budget template');
                            this.toastr.success('Budget template successfully removed.');
                        },
                        error: () => {
                            this.logger.debug('Failed to remove budget template');
                            this.toastr.error('Oops, something went wrong, Please try again later...');
                        },
                    });
                }
            }),
        );
    }

    getFileName(url: string) {
        if (url) {
            const substring = url.substr(url.indexOf('amazonaws.com/') + 14);
            return substring.substr(0, substring.indexOf('.pdf'));
        }
    }

    getBase64(budgetExpenditure: BudgetExpenditure) {
        if (budgetExpenditure.invoiceUrl) {
            return this.budgetService.getInvoice(budgetExpenditure.invoiceUrl);
        }
    }

    findRepeat(repeat: string): BUDGETREPEAT {
        if (repeat) {
            return BUDGETREPEAT.findRepeat(repeat);
        }
    }

    getAllColumns() {
        return BudgetDetailColumns.ALL;
    }

    getDefaultColumns() {
        return BudgetDetailColumns.DEFAULT;
    }

    private fetchData() {
        this.logger.debug('Fetching budget');
        this.store.dispatch(new FetchBudgetById(this.budgetId));

        this.subscriptions.add(
            this.budget$.subscribe((b) => {
                if (b) {
                    this.attentionLevel = this.checkAttentionLevel(b);
                    this.totalSpend = this.getTotalSpend(b);
                    if (this.attentionLevel === 1) {
                        this.messageTitle = "⏱️Isn't it time to contact this employee?";
                        this.messageBody = `${formatDistance(new Date(), new Date(b.endDate))} before this budget is closing in on it's end date!`;
                        this.messageWarning = true;
                        this.messageUrgent = false;
                    } else if (this.attentionLevel === 2) {
                        this.messageTitle = '⏱️Time is running out!';
                        this.messageBody = `${formatDistance(new Date(), new Date(b.endDate))} before this budget is closing in on it's end date!`;

                        this.messageWarning = false;
                        this.messageUrgent = true;
                    }

                    this.budget = b;
                    this.dataSource.data = [b];
                }
            }),
        );
    }
}

export class BudgetDetailColumns {
    static readonly DESCRIPTION = 'description';
    static readonly COST = 'cost';
    static readonly DATE = 'date';
    static readonly FACTUUR = 'factuur';

    static readonly ALL = [this.DESCRIPTION, this.COST, this.DATE, this.FACTUUR];
    static readonly DEFAULT = [this.DESCRIPTION, this.COST, this.DATE, this.FACTUUR];
}
