<div class="modal-wrapper">
    <div class="modal-header">
        <app-loading-message *ngIf="isLoading"></app-loading-message>
        <h2 class="modal-title">
            <ng-container
                *ngIf="!isLoading && employeeUuid === employee.uuid"> {{ employee.firstName }} {{ employee.lastName }} </ng-container>
        </h2>
        <button
            (click)="activeModal.dismiss()"
            aria-label="Close"
            class="close"
            type="button">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <nav
        #nav="ngbNav"
        [(activeId)]="activeNavTab"
        class="tabs__modal"
        ngbNav>
        <ng-container [ngbNavItem]="'information'">
            <a ngbNavLink>Information</a>
            <ng-template ngbNavContent>
                <blockquote>
                    <span
                        *ngIf="employee.extraInfo"
                        class="format-newlines"
                    >{{ employee.extraInfo }}</span
                    >
                    <span *ngIf="!employee.extraInfo">No description or extra information...</span>
                </blockquote>

                <div class="spacer"></div>

                <ul class="list__specs">
                    <ng-container *ngIf="employee.contactInformation">
                        <li *ngIf="employee.contactInformation.email">
                            <label>Emailaddress</label>
                            <app-pill-mailto [mailtoEmail]="employee.contactInformation.email"></app-pill-mailto>
                        </li>
                        <li *ngIf="this.moduleService.checkModuleActive(eModule.MAILCHIMP)">
                            <label>Mailchimp</label>
                            <app-pill-mailchimp
                                [email]="employee.contactInformation.email"
                                [firstname]="employee.firstName"
                                [lastname]="employee.lastName"
                                [phone]="employee.contactInformation.phoneNumber"></app-pill-mailchimp>
                        </li>
                        <li *ngIf="employee.contactInformation.phoneNumber">
                            <label>Phonenumber</label>
                            <app-pill-telephone
                                [contactPhonenumber]="employee.contactInformation.phoneNumber"></app-pill-telephone>
                        </li>
                        <li *ngIf="employee.contactInformation.phoneNumber">
                            <label>Whatsapp</label>
                            <app-pill-whatsapp
                                [phoneNumber]="employee.contactInformation.phoneNumber"></app-pill-whatsapp>
                        </li>
                        <li *ngIf="employee.contactInformation.linkedIn">
                            <label>LinkedIn</label>
                            <app-pill-linkedin [linkedinUrl]="employee.contactInformation.linkedIn"></app-pill-linkedin>
                        </li>
                    </ng-container>

                    <li *ngIf="employee.contactInformation && employee.profession">
                        <label>Profession</label>{{ employee.profession }}</li>
                    <li>
                        <label>Tags</label>
                        <app-global-tags
                            [objectUuid]="employee.uuid"
                            [type]="'employee'"></app-global-tags>
                    </li>
                    <li>
                        <label>Has account</label>
                        <app-loading-message *ngIf="linked === null"></app-loading-message>
                        <span *ngIf="linked"
                        ><i
                            class="las la-check-circle"
                            style="color: green"></i
                        ></span>
                        <span *ngIf="!linked"
                        ><i
                            class="las la-times-circle"
                            style="color: red"></i
                        ></span>
                    </li>
                    <li>
                        <label>Jira ID</label>
                        <span *ngIf="!employee.jiraEmployeeId">Not linked</span>
                        <span *ngIf="employee.jiraEmployeeId">{{employee.jiraEmployeeId}}</span>
                    </li>

                    <div class="spacer"></div>

                    <li>
                        <label>Hire date</label><span>{{ employee.hireDate | date }}</span>
                    </li>
                    <li>
                        <label>Birthday</label><span>{{ employee.birthday | date }}</span>
                    </li>
                </ul>
                <div class="spacer"></div>
            </ng-template>
        </ng-container>

        <ng-container
            *ngIf="this.moduleService.checkModuleActive(eModule.YUKI)"
            [ngbNavItem]="'financial'"
            ngbNavItem>
            <a
                *appIsPermitted="{ module: 'FINANCIAL', operator: 'AND', actions: ['READ'] }"
                ngbNavLink
            >Financial</a
            >
            <ng-template ngbNavContent>
                <app-employee-graph [employee]="employee"></app-employee-graph>
            </ng-template>
        </ng-container>

        <ng-container ngbNavItem>
            <a
                *appIsPermitted="{ module: 'YUKI', operator: 'AND', actions: ['READ'] }"
                ngbNavLink
            >Linked Invoices</a
            >
            <ng-template ngbNavContent>
                <div class="d-flex m-r-2">
                    <div class="form-group m-r-2">
                        <label for="startDate">Start date</label>
                        <input
                            (change)="filterDate()"
                            [(ngModel)]="startDateFilter"
                            class="input--bg-transparant"
                            id="startDate"
                            type="date"/>
                    </div>

                    <div class="form-group">
                        <label for="endDate">End date</label>
                        <input
                            (change)="filterDate()"
                            [(ngModel)]="endDateFilter"
                            class="input--bg-transparant"
                            id="endDate"
                            type="date"/>
                    </div>
                </div>

                <details open>
                    <summary>
                        <span class="sectiontitle summary__title">Sale</span>
                        <i class="las la-angle-up summary__up-arrow"></i>
                    </summary>
                    <div>
                        <app-linked-invoices-to-object
                            [alreadyFetchedFiles]="true"
                            [folderId]="2"
                            [objectId]="employee.uuid"
                            [showFiles]="false">
                        </app-linked-invoices-to-object>
                        <i class="las la-angle-down summary__down-arrow"></i>
                    </div>
                </details>

                <details open>
                    <summary>
                        <span class="sectiontitle summary__title">Purchase</span>
                        <i class="las la-angle-up summary__up-arrow"></i>
                    </summary>
                    <div>
                        <app-linked-invoices-to-object
                            [alreadyFetchedFiles]="true"
                            [folderId]="1"
                            [objectId]="employee.uuid"
                            [showFiles]="false">
                        </app-linked-invoices-to-object>
                        <i class="las la-angle-down summary__down-arrow"></i>
                    </div>
                </details>
            </ng-template>
        </ng-container>

        <ng-container ngbNavItem>
            <a ngbNavLink>Files</a>
            <ng-template ngbNavContent>
                <app-linked-invoices-to-object
                    [alreadyFetchedFiles]="true"
                    [objectId]="employee.uuid"
                    [showInvoices]="false">
                </app-linked-invoices-to-object>
            </ng-template>
        </ng-container>

        <ng-container ngbNavItem>
            <a ngbNavLink>Products</a>
            <ng-template ngbNavContent>
                <div
                    *ngIf="employeeStockUpdates.data.length > 0; else noStockUpdates"
                    class="table__overflow">
                    <p class="sectiontitle sectiontitle--first">Stock updates by {{ employee.firstName }}</p>
                    <div class="m-t-5">
                        <table
                            (matSortChange)="sortData($event)"
                            [dataSource]="employeeStockUpdates"
                            [hidden]="!(employeeStockUpdates && employeeStockUpdates.data.length > 0)"
                            mat-table
                            matSort>
                            <ng-container matColumnDef="updatedProductTitle">
                                <th
                                    *matHeaderCellDef
                                    mat-header-cell>
                                    Product
                                </th>
                                <td
                                    *matCellDef="let row"
                                    mat-cell>
                                    <app-pill-product
                                        *ngIf="row.updatedProductUuid"
                                        [productTitle]="row.updatedProductTitle"
                                        [productUuid]="row.updatedProductUuid"></app-pill-product>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="reasonInfo">
                                <th
                                    *matHeaderCellDef
                                    mat-header-cell>
                                    Reason
                                </th>
                                <td
                                    *matCellDef="let row"
                                    class="description"
                                    mat-cell>
                                    <span
                                        *ngIf="row.productAmountState === 'REMOVED'"
                                        class="color--red"
                                    >{{ row.reasonInfo }}</span
                                    >
                                    <span
                                        *ngIf="row.productAmountState === 'ADDED'"
                                        class="color--green"
                                    >{{ row.reasonInfo }}</span
                                    >
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="dateAmTimeAgo">
                                <th
                                    *matHeaderCellDef
                                    mat-header-cell>
                                    Updated
                                </th>
                                <td
                                    *matCellDef="let row"
                                    mat-cell>
                                    {{ row.lastModified | amTimeAgo }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="date">
                                <th
                                    *matHeaderCellDef
                                    mat-header-cell
                                    mat-sort-header>
                                    Date
                                </th>
                                <td
                                    *matCellDef="let row"
                                    mat-cell>
                                    <span>{{ row.lastModified.substr(0, row.lastModified.length - 3) }}</span>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="stockChange">
                                <th
                                    *matHeaderCellDef
                                    mat-header-cell>
                                    Stock change
                                </th>
                                <td
                                    *matCellDef="let row"
                                    class="color--red text-center"
                                    mat-cell>
                                    <span
                                        *ngIf="row.productAmountState === 'REMOVED'"
                                        class="color--red"
                                    >-{{ row.amount }}</span
                                    >
                                    <span
                                        *ngIf="row.productAmountState === 'ADDED'"
                                        class="color--green"
                                    >+{{ row.amount }}</span
                                    >
                                </td>
                            </ng-container>
                            <ng-container>
                                <td>
                                    <div
                                        class="dropdown"
                                        ngbDropdown>
                                        <button
                                            class="button button--icon button--small dropdown-toggle"
                                            id="dropdownExpenditures"
                                            ngbDropdownToggle></button>
                                        <div
                                            aria-labelledby="dropdownExpenditures"
                                            class="dropdown-menu dropdown-menu-right"
                                            ngbDropdownMenu></div>
                                    </div>
                                </td>
                            </ng-container>
                            <tr
                                *matHeaderRowDef="displayedColumns"
                                mat-header-row></tr>
                            <tr
                                *matRowDef="let row; columns: displayedColumns"
                                mat-row></tr>

                            <tr *matNoDataRow>
                                <td colspan="6">No stock updates are done</td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div *ngIf="linkedProducts.length > 0">
                    <p class="sectiontitle">Linked products</p>
                    <table class="table-view">
                        <tr>
                            <th>Product</th>
                        </tr>
                        <tr *ngFor="let linkedProduct of linkedProducts">
                            <td>
                                <app-pill-product
                                    [productTitle]="linkedProduct.title"
                                    [productUuid]="linkedProduct.uuid"></app-pill-product>
                            </td>
                        </tr>
                    </table>
                </div>
            </ng-template>
            <ng-template #noStockUpdates>
                <app-message-empty
                    [icon]="'../../assets/img/open-box.png'"
                    [title]="'No stock updates yet.'">
                </app-message-empty>
            </ng-template>
        </ng-container>
        <ng-container
            *appIsPermitted="{
                module: 'BUDGET',
                operator: 'AND',
                actions: ['READ']
            }"
            ngbNavItem>
            <a ngbNavLink>Budgets</a>
            <ng-template ngbNavContent>
                <div
                    *ngIf="budgets; else loading"
                    class="table__overflow">
                    <table
                        *ngIf="budgets.length > 0; else noBudgets"
                        class="table-view">
                        <tr>
                            <th>Budget</th>
                            <th>Amount</th>
                            <th class="td-small"></th>
                        </tr>
                        <tr *ngFor="let budget of budgets">
                            <td>
                                <app-pill-budget [budget]="budget"></app-pill-budget>
                            </td>
                            <td>
                                {{ budget.totalSpend | currency: 'EUR' }}
                            </td>
                            <td>
                                <div
                                    class="dropdown"
                                    ngbDropdown>
                                    <button
                                        class="button button--icon button--small dropdown-toggle"
                                        id="dropdownBudgetMenuButton"
                                        ngbDropdownToggle></button>
                                    <div
                                        aria-labelledby="dropdownBudgetMenuButton"
                                        class="dropdown-menu dropdown-menu-right"
                                        ngbDropdownMenu>
                                        <span *ngIf="!budget.archived; else isArchived">
                                            <a
                                                (click)="openUpdateBudgetModal(budget)"
                                                class="dropdown-item"
                                            >Edit budget</a
                                            >
                                            <a
                                                (click)="deleteBudget(budget)"
                                                class="dropdown-item color--red"
                                            >Archive</a
                                            >
                                        </span>
                                        <ng-template #isArchived>
                                            <a
                                                (click)="deleteBudget(budget)"
                                                class="dropdown-item color--red"
                                            >Unarchive</a
                                            >
                                        </ng-template>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td
                                (click)="openAddBudgetModal()"
                                class="table-view__add"
                                colspan="3">
                                <i class="las la-plus"></i> Add budget
                            </td>
                        </tr>
                    </table>

                    <ng-template #noBudgets>
                        <app-message-empty
                            [icon]="'../../assets/img/open-box.png'"
                            [title]="'No budgets linked to this employee.'">
                        </app-message-empty>
                    </ng-template>
                </div>
            </ng-template>
        </ng-container>
        <ng-container ngbNavItem>
            <a ngbNavLink>Projects</a>
            <ng-template ngbNavContent>
                <div
                    *ngIf="currentEmployeeWorksDataSource && currentEmployeeWorksDataSource.data.length > 0; else noProjects"
                    class="table__overflow">
                    <div class="m-t-5">
                        <table
                            (matSortChange)="sortData($event)"
                            [dataSource]="currentEmployeeWorksDataSource"
                            [hidden]="!(currentEmployeeWorksDataSource && currentEmployeeWorksDataSource.data.length > 0)"
                            mat-table
                            matSort>
                            <ng-container matColumnDef="title">
                                <th
                                    *matHeaderCellDef
                                    mat-header-cell>
                                    Project
                                </th>
                                <td
                                    *matCellDef="let row"
                                    mat-cell>
                                    <app-pill-project [project]="row"></app-pill-project>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="description">
                                <th
                                    *matHeaderCellDef
                                    mat-header-cell>
                                    Description
                                </th>
                                <td
                                    *matCellDef="let row"
                                    class="description"
                                    mat-cell>
                                    <span> {{ row.description }}</span>
                                </td>
                            </ng-container>

                            <tr
                                *matHeaderRowDef="displayedColumnsForProjects"
                                mat-header-row></tr>
                            <tr
                                *matRowDef="let row; columns: displayedColumnsForProjects"
                                mat-row></tr>

                            <tr *matNoDataRow>
                                <td colspan="2">No project links</td>
                            </tr>
                        </table>
                    </div>
                </div>

                <ng-template #noProjects>
                    <app-message-empty
                        [icon]="'../../assets/img/open-box.png'"
                        [title]="'No projects linked to this employee.'">
                    </app-message-empty>
                </ng-template>
            </ng-template>
        </ng-container>
        <ng-container
            *ngIf="this.moduleService.checkModuleActive(eModule.TIMESHEETS)"
            ngbNavItem>
            <a
                *appIsPermitted="{
                    module: 'WORK',
                    operator: 'AND',
                    actions: ['READ']
                }"
                ngbNavLink
            >Timesheets</a
            >
            <ng-template ngbNavContent>
                <div *ngIf="timesheets$ | async; else timesheetsLoading">
                    <div *ngIf="timesheetsDataSource.data.length > 0; else noTimesheets">
                        <div class="app__title">
                            <p class="sectiontitle sectiontitle--block">
                                {{ selectedDate | date: 'MMMM yyyy' }}
                                <span *ngIf="this.timesheetsDataSource">
                                    ({{ this.timesheetsDataSource.filteredData.length }}
                                    timesheets)
                                </span>
                            </p>

                            <span>
                                <button
                                    (click)="moveDate(-1)"
                                    class="button button--icon m-r-1">
                                    <i class="las la-arrow-left"></i>
                                </button>
                                <button
                                    (click)="moveDate(0)"
                                    class="button m-r-1">
                                    Last month
                                </button>
                                <button
                                    (click)="moveDate(1)"
                                    class="button button--icon">
                                    <i class="las la-arrow-right"></i>
                                </button>
                            </span>
                        </div>
                        <div
                            *ngIf="this.timesheetsDataSource.filteredData"
                            class="table__overflow">
                            <table
                                [dataSource]="timesheetsDataSource"
                                mat-table>
                                <ng-container matColumnDef="timesheet">
                                    <th
                                        *matHeaderCellDef
                                        mat-header-cell>
                                        Timesheet
                                    </th>
                                    <td
                                        *matCellDef="let row"
                                        mat-cell>
                                        <app-pill-timesheet [timesheet]="row"></app-pill-timesheet>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="timeslot">
                                    <th
                                        *matHeaderCellDef
                                        mat-header-cell>
                                        Timeslot
                                    </th>
                                    <td
                                        *matCellDef="let row"
                                        mat-cell>
                                        <div *ngFor="let timeslot of row.timeslots">
                                            <app-pill-project [project]="timeslot.project"></app-pill-project>
                                        </div>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="totalHours">
                                    <th
                                        *matHeaderCellDef
                                        mat-header-cell>
                                        Worked hours
                                    </th>
                                    <td
                                        *matCellDef="let row"
                                        mat-cell>
                                        <i class="las la-clock"></i>
                                        {{ getTotalMinutesForTimesheet(row) | minutesToMinutesAndHours }}
                                    </td>
                                </ng-container>

                                <tr
                                    *matHeaderRowDef="displayedColumnsTimesheet"
                                    mat-header-row></tr>
                                <tr
                                    *matRowDef="let row; columns: displayedColumnsTimesheet"
                                    mat-row></tr>
                                <tr *matNoDataRow>
                                    <td colspan="4">No timesheets available</td>
                                </tr>
                            </table>
                        </div>

                        <p class="m-t-4">
                            <i class="las la-clock m-r-2"></i>Hours worked in {{ selectedDate | date: 'MMMM y' }}:
                            <strong>{{ getTotalMinutes() | minutesToMinutesAndHours }}</strong>
                        </p>
                    </div>
                </div>
                <ng-template #noTimesheets>
                    <app-message-empty
                        [htmlSubtitle]="createHtmlSubtitleForNoTimesheets()"
                        [icon]="'../../assets/img/open-box.png'">
                        <a
                            (click)="navigateToTimesheets()"
                            *appIsPermitted="{
                                module: 'TIMESHEETS',
                                operator: 'AND',
                                actions: ['CREATE']
                            }"
                            class="button button--primary">
                            Navigate to timesheets
                        </a>
                    </app-message-empty>
                </ng-template>
                <ng-template #timesheetsLoading>
                    <app-loading-message></app-loading-message>
                </ng-template>
            </ng-template>
        </ng-container>

        <ng-container
            *ngIf="this.moduleService.checkModuleActive(eModule.TIMESHEETS)"
            ngbNavItem>
            <a
                *appIsPermitted="{
                    module: 'WORK',
                    operator: 'AND',
                    actions: ['READ']
                }"
                ngbNavLink
            >Happiness scores</a
            >
            <ng-template ngbNavContent>
                <app-happiness-score-graph-widget
                    [alreadyFetchedData]="false"
                    [employeeId]="employeeUuid"
                    class="dashboard__tile dashboard__tile--no-margin dashboard__tile--large d-block">

                </app-happiness-score-graph-widget>
            </ng-template>

        </ng-container>

        <ng-container
            *ngIf="this.moduleService.checkModuleActive(eModule.COMMENTS)"
            ngbNavItem>
            <a ngbNavLink>Comments</a>
            <ng-template ngbNavContent>
                <app-comments-overview [objectId]="employeeUuid"></app-comments-overview>
            </ng-template>
        </ng-container>
    </nav>

    <div class="modal-body">
        <ng-container *ngIf="!isLoading && employeeUuid === employee.uuid; else loading">
            <div [ngbNavOutlet]="nav"></div>
        </ng-container>
    </div>

    <div class="modal-footer">
        <span
            *appIsPermitted="{
                module: 'EMPLOYEES',
                operator: 'OR',
                actions: ['EDIT', 'DELETE']
            }"
            class="dropdown"
            ngbDropdown>
            <app-loading-message *ngIf="isLoading"></app-loading-message>
            <button
                *ngIf="!isLoading"
                class="button dropdown-toggle"
                id="dropdownMenuButton"
                ngbDropdownToggle
                type="button">
                Options
            </button>
            <div
                *ngIf="!isLoading"
                aria-labelledby="dropdownMenuButton"
                class="dropdown-menu"
                ngbDropdownMenu>
                <a
                    (click)="openEmployeeUpdateModal(employee)"
                    *appIsPermitted="{
                        module: 'EMPLOYEES',
                        operator: 'AND',
                        actions: ['EDIT']
                    }"
                    class="dropdown-item"
                >Edit employee</a
                >
                <div
                    *appIsPermitted="{
                        module: 'EMPLOYEES',
                        operator: 'AND',
                        actions: ['DELETE', 'EDIT']
                    }"
                    class="dropdown-divider"></div>
                <a
                    (click)="openEmployeeArchiveModal(employee)"
                    *appIsPermitted="{
                        module: 'EMPLOYEES',
                        operator: 'AND',
                        actions: ['DELETE']
                    }"
                    class="dropdown-item color--red"
                >Archive</a
                >
                <a
                    (click)="openEmployeeDeleteModal(employee.uuid)"
                    *appIsPermitted="{
                        module: 'EMPLOYEES',
                        operator: 'AND',
                        actions: ['DELETE']
                    }"
                    class="dropdown-item color--red"
                >Delete</a
                >
            </div>
        </span>
    </div>
</div>

<ng-template #loading>
    <app-loading-message></app-loading-message>
</ng-template>
