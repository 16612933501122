import { formatDate } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';
import { CompanyMember } from 'src/app/models/CompanyMember';
import { OutlookLoginService } from 'src/app/shared/login-components/outlook-login/outlook-login.service';

import { environment } from '../../../environments/environment';
import { GoogleCalendarService } from '../google-calendar/google-calendar.service';
import { OutlookCalendarService } from '../outlook-calendar.service';

@Component({
    selector: 'app-calendar-event-list',
    templateUrl: './calendar-event-list.component.html',
    styleUrls: ['./calendar-event-list.component.scss'],
})
export class CalendarEventListComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Input() member: CompanyMember;
    week = 0;
    calendar: any[] = [];
    start: string;
    end: string;
    formatString = 'dd/MM/yyyy';
    isLoggedIn: boolean;
    loginUrl: string;

    loggedInWithMicrosoft: boolean;
    loggedInWithGmail: boolean;

    constructor(
        private googleCalendarService: GoogleCalendarService,
        private outlookLoginService: OutlookLoginService,
        private outlookCalendarService: OutlookCalendarService,
        private logger: NGXLogger,
    ) {}

    ngOnInit(): void {
        this.subscriptions.add(
            this.googleCalendarService.getIsLoggedIn().subscribe((loggedIn) => {
                if (loggedIn) {
                    this.logger.debug('Logged in with google');
                    this.loggedInWithGmail = true;
                } else {
                    this.logger.debug('Not logged in with google');
                }
                this.outlookLoginService.getLoggedIn().subscribe((loggedIn) => {
                    if (loggedIn) {
                        this.logger.debug('Logged in with outlook');
                        this.loggedInWithMicrosoft = true;
                    } else {
                        this.logger.debug('Not logged in with Outlook');
                    }
                });
            }),
        );
        this.loginUrl = environment.frontEndBaseUrl + '/settings/module';
        if (this.loggedInWithGmail && this.loggedInWithMicrosoft) {
            this.retrieveMails();
        }
    }

    retrieveMails() {
        this.calendar = [];
        const start = this.getMonday();
        const end = this.getNextMonday();
        this.start = formatDate(start, this.formatString, 'en-US');
        this.end = formatDate(end, this.formatString, 'en-US');
        if (this.loggedInWithGmail) {
            this.getGoogleCalendarForWeek(start, end);
        }
        if (this.loggedInWithMicrosoft) {
            this.getOutlookCalendarForWeek(start, end);
        }
    }

    getGoogleCalendarForWeek(start, end) {
        this.logger.debug('retrieving calendar events Google');
        if (this.member != undefined) {
            this.googleCalendarService.getCalendarByUserAndDates(this.member.userUuid, start, end).subscribe(
                (events) => {
                    events.forEach((event) => this.calendar.push({ ...event, type: 'GOOGLE' }));
                },
                (error) => this.logger.error('Failed to retrieve calendar events from Google'),
            );
        }
    }

    getOutlookCalendarForWeek(start, end) {
        this.logger.debug('retrieving calendar events Outlook');
        this.subscriptions.add(
            this.outlookCalendarService.getCalendarEventsForWeek(start, end).subscribe(
                (events) => {
                    const outlookEvents: any[] = events.value;
                    outlookEvents.forEach((event) => this.calendar.push({ ...event, type: 'OUTLOOK' }));
                },
                (error) => this.logger.error('Failed to retrieve calendar events from Outlook'),
            ),
        );
    }

    getMonday() {
        const d = new Date();
        d.setDate(d.getDate() + 7 * this.week);
        const day = d.getDay(),
            diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
        return new Date(d.setDate(diff));
    }

    getNextMonday() {
        const d = new Date();
        d.setDate(d.getDate() + 7 * this.week + 7);
        const day = d.getDay(),
            diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
        return new Date(d.setDate(diff));
    }

    weekChange(amount: number) {
        this.week += amount;
        this.retrieveMails();
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
