<div class="list__item pointer">
    <div
        class="list__item__icon document__icon document__icon--doc"
        (click)="open()">
        <i
            *ngIf="document.fileType === 'pdf'"
            class="las la-file-pdf"></i>
        <i
            *ngIf="document.fileType === 'docx'"
            class="las la-file-word"></i>
        <i
            *ngIf="document.fileType === '' || document.fileType === null"
            class="las la-file-alt"></i>
    </div>
    <div
        class="list__item__content"
        (click)="open()">
        <strong>{{ document.name }}</strong>
        <small
            *ngIf="document.content !== null"
            [innerHTML]="document.content | stripTags | shorten: 50:'...'"></small>
    </div>
    <div
        class="list__item__extra"
        ng-click="$event.stopPropagation()">
        <span
            ngbDropdown
            *appIsPermitted="{
                module: 'DOCUMENTS',
                operator: 'AND',
                actions: ['DELETE']
            }">
            <button
                ngbDropdownToggle
                class="button button--icon button--dropdown button--small"></button>
            <span
                ngbDropdownMenu
                class="dropdown-menu dropdown-menu-right"
                aria-labelledby="dropdownMenuButton">
                <a
                    class="dropdown-item color--red"
                    (click)="delete($event)"
                    >Delete</a
                >
            </span>
        </span>
    </div>
</div>
