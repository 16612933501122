import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { append, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { Product } from '../models/Product';
import { ROUTES } from '../models/ROUTES';
import { StockUpdateHistoryModel } from '../models/StockUpdateHistoryModel';
import { CreateNotification } from '../notifications/notifications.actions';
import { AddUndefinedObjectTagsToObject } from '../shared/global-tags/global-tags.actions';
import {
    AddProduct,
    AddProductCategory,
    AddUndefinedProductCategoriesToProduct,
    AddUndefinedProductCategory,
    ArchiveProduct,
    DeleteProductCategories,
    FetchProductCategories,
    FetchProductStockUpdateHistory,
    FetchProducts,
    GetProduct,
    LinkProductCategory,
    LinkProductToEmployee,
    LinkProducts,
    RemoveProductsLink,
    RemoveUndefinedProductCategory,
    ResetUndefinedProductCategories,
    SelectCategory,
    UnLinkProductCategory,
    UpdateLinkedAmount,
    UpdateProduct,
    UpdateProductCategory,
} from './product.actions';
import { ProductService } from './product.service';

export interface ProductStateModel {
    products: Product[];
    productCategories: string[];
    undefinedProductCategories: string[][];
    currentProduct: Product;
    selectedCategory: string;
    productStockUpdateHistory: StockUpdateHistoryModel[];
}

@State<ProductStateModel>({
    name: 'product',
    defaults: {
        products: null,
        productCategories: null,
        undefinedProductCategories: [],
        currentProduct: null,
        selectedCategory: '',
        productStockUpdateHistory: null,
    },
})
@Injectable()
export class ProductState {
    @Selector()
    static Products(state: ProductStateModel): Product[] {
        return state?.products;
    }

    @Selector()
    static ProductCategories(state: ProductStateModel): string[] {
        return state?.productCategories;
    }

    @Selector()
    static UndefinedProductCategories(state: ProductStateModel): string[][] {
        return state?.undefinedProductCategories;
    }

    @Selector()
    static SelectedCategory(state: ProductStateModel): string {
        return state?.selectedCategory;
    }

    @Selector()
    static ProductStockUpdateHistory(state: ProductStateModel): StockUpdateHistoryModel[] {
        return state?.productStockUpdateHistory;
    }

    constructor(private productService: ProductService, private store: Store) {}

    @Action(FetchProducts)
    fetch(ctx: StateContext<ProductStateModel>) {
        return this.productService.getAllProducts().subscribe((products) => {
            ctx.setState(
                patch<ProductStateModel>({
                    products: products,
                }),
            );
        });
    }

    @Action(GetProduct)
    getProductById(ctx: StateContext<ProductStateModel>, action: GetProduct): Observable<Product> {
        return this.productService.getProductById(action.productUuid).pipe(
            tap((product) => {
                ctx.setState(
                    patch<ProductStateModel>({
                        products: updateItem((p) => p.uuid === product.uuid, product),
                        currentProduct: product,
                    }),
                );
            }),
        );
    }

    @Action(AddProduct)
    addProduct(ctx: StateContext<ProductStateModel>, action: AddProduct) {
        return this.productService.addProduct(action.product).pipe(
            tap((product) => {
                ctx.setState(
                    patch<ProductStateModel>({
                        products: append([product]),
                        currentProduct: product,
                    }),
                );
                this.store.dispatch(new AddUndefinedObjectTagsToObject(product.uuid));
                this.addUndefinedProductCategoriesToProduct(ctx, {
                    productUuid: product.uuid,
                });
                this.getProductById(ctx, new GetProduct(product.uuid)).subscribe();
                this.store.dispatch(
                    new CreateNotification({
                        message: `A product <strong>${product.title}</strong> is added`,
                        route: ROUTES.CONTACTS.route,
                    }),
                );
            }),
        );
    }

    @Action(UpdateProduct)
    updateProduct(ctx: StateContext<ProductStateModel>, action: UpdateProduct) {
        return this.productService.updateProduct(action.product).pipe(
            tap((product) => {
                ctx.setState(
                    patch<ProductStateModel>({
                        products: updateItem((p) => p.uuid === product.uuid, product),
                    }),
                );
                this.store.dispatch(
                    new CreateNotification({
                        message: `A product <strong>${product.title}</strong> is updated`,
                        route: ROUTES.CONTACTS.route,
                    }),
                );
            }),
        );
    }

    @Action(ArchiveProduct)
    deleteProduct(ctx: StateContext<ProductStateModel>, action: ArchiveProduct) {
        return this.productService.archiveProduct(action.productUuid).subscribe(() => {
            const product: Product = ctx.getState().products.find((product) => product.uuid === action.productUuid);
            ctx.setState(
                patch<ProductStateModel>({
                    products: removeItem<Product>((p) => p.uuid === action.productUuid),
                }),
            );
            this.fetch(ctx);
            this.store.dispatch(
                new CreateNotification({
                    message: `A product <strong>${product.title}</strong> is archived`,
                    route: ROUTES.CONTACTS.route,
                }),
            );
        });
    }

    //linked products
    @Action(LinkProducts)
    addProductsLink(ctx: StateContext<ProductStateModel>, action: LinkProducts) {
        return this.productService.addProductsLink(action.currentUuid, action.parentUuid, action.children, action.amountAssigned).subscribe((product) => {
            ctx.setState(
                patch<ProductStateModel>({
                    products: updateItem((p) => p.uuid === product.uuid, product),
                }),
            );
        });
    }

    @Action(UpdateLinkedAmount)
    updateLinkedAmount(ctx: StateContext<ProductStateModel>, action: UpdateLinkedAmount) {
        return this.productService.updateLinkedAmount(action.parentUuid, action.childUuid, action.amountAssigned).subscribe((product) => {
            ctx.setState(
                patch<ProductStateModel>({
                    products: updateItem((p) => p.uuid === product.uuid, product),
                }),
            );
        });
    }

    @Action(RemoveProductsLink)
    removeProductsLink(ctx: StateContext<ProductStateModel>, action: RemoveProductsLink) {
        return this.productService.removeProductsLink(action.parentUuid, action.childUuid).subscribe(() => {
            this.getProductById(ctx, new GetProduct(action.parentUuid)).subscribe();
        });
    }

    //productCategories

    @Action(FetchProductCategories)
    fetchCategories(ctx: StateContext<ProductStateModel>) {
        return this.productService.getAllProductCategories().subscribe((categories) => {
            ctx.setState(
                patch<ProductStateModel>({
                    productCategories: categories,
                }),
            );
        });
    }

    @Action(AddProductCategory)
    addProductCategory(ctx: StateContext<ProductStateModel>, action: AddProductCategory) {
        return this.productService.addProductCategory(action.category).pipe(
            tap((category) => {
                ctx.setState(
                    patch<ProductStateModel>({
                        productCategories: append([category.title]),
                    }),
                );
            }),
        );
    }

    @Action(UpdateProductCategory)
    updateProductCategory(ctx: StateContext<ProductStateModel>, action: UpdateProductCategory) {
        return this.productService.updateProductCategory(action.oldCategory, action.newCategory).pipe(
            tap((newCategory) => {
                ctx.setState(
                    patch<ProductStateModel>({
                        productCategories: updateItem((c) => c === action.oldCategory, newCategory.title),
                    }),
                );
            }),
        );
    }

    @Action(DeleteProductCategories)
    deleteProductCategories(ctx: StateContext<ProductStateModel>, action: DeleteProductCategories) {
        return this.productService.deleteProductCategories(action.category).pipe(
            tap(() => {
                ctx.setState(
                    patch<ProductStateModel>({
                        productCategories: removeItem<string>((c) => c === action.category),
                    }),
                );
            }),
        );
    }

    @Action(LinkProductCategory)
    linkProductCategory(ctx: StateContext<ProductStateModel>, action: LinkProductCategory) {
        return this.productService.linkProductCategory(action.productUuid, action.category).subscribe((product) => {
            ctx.setState(
                patch<ProductStateModel>({
                    products: updateItem((p) => p.uuid === product.uuid, product),
                }),
            );
            this.fetchCategories(ctx);
        });
    }

    @Action(UnLinkProductCategory)
    unLinkProductCategory(ctx: StateContext<ProductStateModel>, action: UnLinkProductCategory) {
        return this.productService.unlinkProductCategory(action.productUuid, action.category).pipe(
            tap((product) => {
                ctx.setState(
                    patch<ProductStateModel>({
                        products: updateItem((p) => p.uuid === product.uuid, product),
                    }),
                );
            }),
        );
    }

    @Action(AddUndefinedProductCategoriesToProduct)
    addUndefinedProductCategoriesToProduct(ctx: StateContext<ProductStateModel>, action: AddUndefinedProductCategoriesToProduct) {
        const categories = ctx.getState().undefinedProductCategories[ctx.getState().undefinedProductCategories.length - 1];
        if (categories) {
            categories.forEach((c) => {
                this.linkProductCategory(ctx, {
                    productUuid: action.productUuid,
                    category: c,
                });
            });
            this.resetUndefinedProductCategories(ctx);
        }
    }

    @Action(AddUndefinedProductCategory)
    addUndefinedProductCategory(ctx: StateContext<ProductStateModel>, action: AddUndefinedProductCategory) {
        const newUndefinedProductCategoriesCopy = JSON.parse(JSON.stringify(ctx.getState().undefinedProductCategories));
        if (newUndefinedProductCategoriesCopy[action.key] == undefined) {
            newUndefinedProductCategoriesCopy.push([action.category]);
        } else {
            newUndefinedProductCategoriesCopy[action.key] = [...newUndefinedProductCategoriesCopy[action.key], action.category];
        }
        return ctx.setState(
            patch<ProductStateModel>({
                undefinedProductCategories: newUndefinedProductCategoriesCopy,
            }),
        );
    }

    @Action(RemoveUndefinedProductCategory)
    removeUndefinedProductCategory(ctx: StateContext<ProductStateModel>, action: RemoveUndefinedProductCategory) {
        let newUndefinedProductCategoriesCopy = JSON.parse(JSON.stringify(ctx.getState().undefinedProductCategories));
        newUndefinedProductCategoriesCopy = newUndefinedProductCategoriesCopy[action.key].filter((o) => o.title != action.category);
        return ctx.setState(
            patch<ProductStateModel>({
                undefinedProductCategories: newUndefinedProductCategoriesCopy,
            }),
        );
    }

    @Action(ResetUndefinedProductCategories)
    resetUndefinedProductCategories(ctx: StateContext<ProductStateModel>) {
        const newUndefinedProductCategoriesCopy = JSON.parse(JSON.stringify(ctx.getState().undefinedProductCategories));
        newUndefinedProductCategoriesCopy.pop();
        return ctx.setState(
            patch<ProductStateModel>({
                undefinedProductCategories: newUndefinedProductCategoriesCopy,
            }),
        );
    }

    @Action(SelectCategory)
    selectCategory(ctx: StateContext<ProductStateModel>, action: SelectCategory) {
        return ctx.setState(
            patch<ProductStateModel>({
                selectedCategory: action.category,
            }),
        );
    }

    //stock history
    @Action(FetchProductStockUpdateHistory)
    fetchProductStockUpdateHistory(ctx: StateContext<ProductStateModel>) {
        return this.productService.getProductStockUpdateHistory().pipe(
            tap((stockUpdateHistory) => {
                ctx.setState(
                    patch<ProductStateModel>({
                        productStockUpdateHistory: stockUpdateHistory,
                    }),
                );
            }),
        );
    }

    //stock update
    @Action(LinkProductToEmployee)
    linkProductToEmployee(ctx: StateContext<ProductStateModel>, action: LinkProductToEmployee) {
        return this.productService.linkProductToEmployee(action.productUuid, action.employeeUuid).pipe();
    }
}
