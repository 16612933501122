import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import CampaignModel from 'src/app/models/CampaignModel';
import MailchimpTagModel from 'src/app/models/MailchimpTagModel';
import MailchimpTemplate from 'src/app/models/MailchimpTemplate';
import RecipientListModel from 'src/app/models/RecipientListModel';

import { AddCampaign } from '../mailchimp.actions';
import { MailchimpService } from '../mailchimp.service';
import AddCampaignModel from './add-campaing-model';

@Component({
    selector: 'app-add-campaign-modal',
    templateUrl: './add-campaign-modal.component.html',
    styleUrls: ['./add-campaign-modal.component.scss'],
})
export class AddCampaignModalComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    campaignFormGroup: FormGroup;
    campaigns: CampaignModel[];
    templates: MailchimpTemplate[];
    lists: RecipientListModel[];
    tags: MailchimpTagModel[];

    constructor(
        public activeModal: NgbActiveModal,
        private store: Store,
        private formBuilder: FormBuilder,
        private toastr: ToastrService,
        private mailchimpService: MailchimpService,
    ) {}

    ngOnInit(): void {
        this.subscriptions.add(this.mailchimpService.getCampaigns().subscribe((c) => (this.campaigns = c)));
        this.subscriptions.add(this.mailchimpService.getTemplates().subscribe((t) => (this.templates = t)));
        this.subscriptions.add(this.mailchimpService.getLists().subscribe((l) => (this.lists = l)));
        this.campaignFormGroup = this.formBuilder.group({
            title: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(30)]],
            subject: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(20)]],
            preview: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(20)]],
            senderName: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(20)]],
            senderMail: ['', [Validators.email, Validators.required]],
            template: ['', [Validators.required]],
            list: ['', [Validators.required]],
            tag: [''],
        });
    }

    addCampaign(event: any) {
        const model: AddCampaignModel = {
            title: this.campaignFormGroup.get('title').value,
            subject: this.campaignFormGroup.get('subject').value,
            preview: this.campaignFormGroup.get('preview').value,
            senderName: this.campaignFormGroup.get('senderName').value,
            senderMail: this.campaignFormGroup.get('senderMail').value,
            listId: this.campaignFormGroup.get('list').value,
            templateId: this.campaignFormGroup.get('template').value,
            method: event.submitter.alt,
            tagId: this.campaignFormGroup.get('tag').value,
        };
        this.subscriptions.add(this.store.dispatch(new AddCampaign(model)).subscribe(() => this.toastr.success('Campaign added successfully!')));
        this.activeModal.dismiss();
    }

    onChange(value: any) {
        const listId = this.campaignFormGroup.get('list').value;
        if (listId) {
            this.subscriptions.add(this.mailchimpService.getTags(listId).subscribe((t) => (this.tags = t)));
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
