import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { Language } from 'src/app/models/resume/Language';

import { AddLanguage, GetLanguages, GetResume, UpdateLanguage } from '../resume.actions';

@Component({
    selector: 'app-language-detail-modal',
    templateUrl: './language-detail-modal.component.html',
    styleUrls: ['./language-detail-modal.component.scss'],
})
export class LanguageDetailModalComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Input() resumeUuid: string;
    @Input() language: Language;

    languageFormGroup: FormGroup;
    newLanguage: Language;
    inputIsWrong = false;
    loading = false;
    isUpdate = false;

    constructor(
        public activeModal: NgbActiveModal,
        private formBuilder: FormBuilder,
        private store: Store,
        private toastr: ToastrService,
        private logger: NGXLogger,
    ) {}

    ngOnInit(): void {
        if (this.language) {
            this.isUpdate = true;
        }
        this.languageFormGroup = this.formBuilder.group({
            language: ['', [Validators.pattern('^[a-zA-Z]+$'), Validators.required]],
            strength: ['', [Validators.required]],
        });
        if (this.isUpdate) {
            this.setFormValues();
        }
    }

    setFormValues() {
        this.languageFormGroup.setValue({
            language: this.language.language,
            strength: this.language.strength,
        });
    }

    saveLanguage() {
        this.loading = true;
        if (!this.languageFormGroup.valid) {
            this.inputIsWrong = true;
            this.loading = false;
            return;
        }

        this.languageFormGroup.disable();

        this.newLanguage = this.languageFormGroup.value;

        if (!this.isUpdate) {
            this.logger.debug('Attempt to add language');
            this.subscriptions.add(
                this.store.dispatch(new AddLanguage(this.resumeUuid, this.newLanguage)).subscribe(
                    (language) => {
                        this.logger.debug('Succesfully added language');
                        this.toastr.success('Language added succesfully');
                        this.loading = false;
                        this.logger.debug('Fetching languages');
                        this.store.dispatch(new GetLanguages(this.resumeUuid)).subscribe(() => {
                            this.logger.debug('Fetching resume');
                            this.store.dispatch(new GetResume(this.resumeUuid));
                        });
                        this.languageFormGroup.setValue({
                            language: '',
                            strength: '',
                        });
                        this.languageFormGroup.enable();
                        this.hideErrorMessage();
                    },
                    (error) => {
                        this.logger.debug('Failed to add language');
                        this.loading = false;
                        this.languageFormGroup.enable();
                    },
                ),
            );
        } else {
            this.logger.debug('Attempt to update language');
            this.subscriptions.add(
                this.store.dispatch(new UpdateLanguage(this.language.uuid, this.newLanguage)).subscribe(
                    () => {
                        this.logger.debug('Succesfully updated language');
                        this.logger.debug('Fetching languages');
                        this.store.dispatch(new GetLanguages(this.language.resumeId)).subscribe(() => {
                            this.logger.debug('Fetching resume');
                            this.store.dispatch(new GetResume(this.resumeUuid));
                        });
                        this.activeModal.close();
                    },
                    (error) => {
                        this.logger.debug('Failed to update language');
                        this.loading = false;
                        this.languageFormGroup.enable();
                    },
                ),
            );
        }
    }

    // Hides error messages
    hideErrorMessage() {
        this.inputIsWrong = false;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
