import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { format } from 'date-fns';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';

import { YukiSettings } from '../../models/yuki/YukiSettings';
import { UpdateYukiSettings } from '../../yuki/yuki.actions';

@Component({
    selector: 'app-update-yuki-settings-modal',
    templateUrl: './update-yuki-settings-modal.component.html',
    styleUrls: ['./update-yuki-settings-modal.component.scss'],
})
export class UpdateYukiSettingsModalComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Input() yukiSettings: YukiSettings;

    yukiSettingsForm: FormGroup;

    constructor(private fb: FormBuilder, private toastr: ToastrService, private logger: NGXLogger, public activeModal: NgbActiveModal, private store: Store) {}

    ngOnInit(): void {
        this.createYukiSettingsForm();
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    updateYukiSettings() {
        const yukiSettings: YukiSettings = {
            syncStartDate: new Date(this.yukiSettingsForm.value.syncStartDate),
        };
        this.subscriptions.add(
            this.store.dispatch(new UpdateYukiSettings(this.yukiSettings.uuid, yukiSettings)).subscribe(
                (res) => {
                    this.toastr.success('Yuki settings successfully updated');
                    this.activeModal.close();
                },
                (error) => {
                    this.logger.error('Failed to update Yuki settings');
                    this.toastr.error('Oops, something went wrong, Please try again later...');
                },
            ),
        );
    }

    private createYukiSettingsForm() {
        this.yukiSettingsForm = this.fb.group({
            syncStartDate: [format(new Date(this.yukiSettings.syncStartDate), 'yyyy-MM-dd'), Validators.required],
        });
    }
}
