import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { AuthenticationService } from '../authentication/authentication.service';
import ApplicantReasonOfLoss from '../models/Applicants/ApplicantReasonOfLoss';

@Injectable({
    providedIn: 'root',
})
export class ApplicantReasonOfLossService {
    httpOptions = {};
    url = '/applicant/reason-of-loss';

    constructor(private httpClient: HttpClient, private authenticationService: AuthenticationService) {
        this.httpOptions = authenticationService.getHeaders();
    }

    addApplicantReasonOfLoss(reason: string): Observable<ApplicantReasonOfLoss> {
        return this.httpClient.post<ApplicantReasonOfLoss>(environment.baseUrl + this.url, reason, this.httpOptions);
    }

    updateApplicantReasonOfLoss(reasonUuid: string, reason: string): Observable<ApplicantReasonOfLoss> {
        return this.httpClient.put<ApplicantReasonOfLoss>(environment.baseUrl + this.url + '/' + reasonUuid, reason, this.httpOptions);
    }

    getAllApplicantReasonOfLoss(): Observable<ApplicantReasonOfLoss[]> {
        return this.httpClient.get<ApplicantReasonOfLoss[]>(environment.baseUrl + this.url, this.httpOptions);
    }

    removeReasonOfLoss(uuid: string) {
        return this.httpClient.delete(environment.baseUrl + this.url + '/' + uuid, this.httpOptions);
    }
}
