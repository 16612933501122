<app-app-holder>
    <app-navbar></app-navbar>

    <app-sidebar>
        <div class="sidebar-spacing">
            <h3>Inventory</h3>
            <small>Stockmanagement</small>
        </div>

        <span class="divider divider--small"></span>

        <app-inventory-subnav></app-inventory-subnav>
    </app-sidebar>

    <app-content>
        <div class="app__title">
            <h1>
                Warehouses overview
                <span *ngIf="allWarehouses$ | async as allWarehouses"> ({{ allWarehouses.length }}) </span>
            </h1>
            <span>
                <a
                    class="button button--primary"
                    *appIsPermitted="{
                        module: 'INVENTORY',
                        operator: 'AND',
                        actions: ['CREATE']
                    }"
                    (click)="openAddWarehouseModal()"
                    >New warehouse</a
                >
            </span>
        </div>

        <span
            *appIsPermitted="{
                module: 'INVENTORY',
                operator: 'AND',
                actions: ['READ']
            }">
            <div *ngIf="allWarehouses$ | async as allWarehouses; else loading">
                <div *ngIf="allWarehouses">
                    <app-warehouses-list *ngIf="allWarehouses.length > 0; else empty"></app-warehouses-list>
                </div>
            </div>
        </span>
    </app-content>

    <ng-template #loading>
        <app-loading-message></app-loading-message>
    </ng-template>

    <ng-template #empty>
        <app-message-empty
            [title]="'No warehouses...'"
            [subtitle]="'Start creating warehouses!'"
            [icon]="'../../assets/img/open-box.png'">
        </app-message-empty>
    </ng-template>
</app-app-holder>
