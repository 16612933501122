import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-global-simple-text-input-modal',
    templateUrl: './global-simple-text-input-modal.component.html',
    styleUrls: ['./global-simple-text-input-modal.component.scss'],
})
export class GlobalSimpleTextInputModalComponent implements OnInit {
    public emitObject: EventEmitter<string> = new EventEmitter();

    @Input() title: string;
    @Input() req = true;
    @Input() min = 0;
    @Input() max = 200;

    formGroup: FormGroup;
    constructor(public activeModal: NgbActiveModal, private fb: FormBuilder) {}

    ngOnInit(): void {
        this.formGroup = this.fb.group({
            input: ['', [this.req ? Validators.required : null, Validators.minLength(this.min), Validators.maxLength(this.max)]],
        });
    }

    onSubmit() {
        this.emitObject.emit(this.formGroup.get('input').value);
        this.activeModal.dismiss();
    }
}
