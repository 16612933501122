import { Component, Input, OnInit } from '@angular/core';
import Address from 'src/app/models/Address';

@Component({
    selector: 'app-pill-address',
    templateUrl: './pill-address.component.html',
    styleUrls: ['./pill-address.component.scss'],
})
export class PillAddressComponent implements OnInit {
    @Input() address: Address;
    valid = true;

    constructor() {}

    ngOnInit(): void {
        this.valid = this.validAddress();
    }

    validAddress(): boolean {
        try {
            if (
                this.address == null ||
                this.address.street == null ||
                this.address.city == null ||
                this.address.postal == null ||
                this.address.number == null ||
                this.address.number == 0
            ) {
                return false;
            }
            return true;
        } catch {
            return false;
        }
    }
}
