export class FetchPlanning {
    static readonly type = '[PlannedEvent] Fetch PlannedEvents';

    constructor() {}
}

export class AddPlanning {
    static readonly type = '[PlannedEvent] Add PlannedEvent';

    constructor(public newPlanning: any) {}
}

export class UpdatePlanning {
    static readonly type = '[PlannedEvent] Update PlannedEvent';

    constructor(public newPlanning: any) {}
}

export class DeletePlanning {
    static readonly type = '[PlannedEvent] Delete PlannedEvent';

    constructor(public id: string) {}
}

export class GetProjectById {
    static readonly type = '[Work] Get Project by id';

    constructor(public id: string) {}
}
