export enum LaneItemPriority {
    LOW = 'LOW',
    MEDIUM = 'MEDIUM',
    HIGH = 'HIGH',
    CRITICAL = 'CRITICAL',
}

export function getPriorityLevel(prio: LaneItemPriority): number {
    switch (prio) {
        case LaneItemPriority.LOW:
            return 0;
        case LaneItemPriority.MEDIUM:
            return 1;
        case LaneItemPriority.HIGH:
            return 2;
        case LaneItemPriority.CRITICAL:
            return 3;
        default:
            return 0;
    }
}

export function getBadgeClass() {
    switch (this.applicant.priority) {
        case LaneItemPriority.MEDIUM:
            return 'badge badge--yellow';
        case LaneItemPriority.HIGH:
            return 'badge badge--warning';
        case LaneItemPriority.CRITICAL:
            return 'badge badge--danger';
        default:
            return 'badge';
    }
}
