import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { Partner } from 'src/app/models/Partner';
import { CountryService } from 'src/app/shared/country.service';
import validator from 'validator';

import { UpdatePartner } from '../partners.actions';
import { VatValidationService } from '../vat.validation.service';

@Component({
    selector: 'app-partner-update-modal',
    templateUrl: './partner-update-modal.component.html',
    styleUrls: ['./partner-update-modal.component.scss'],
})
export class PartnerUpdateModalComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Input() partner: Partner;
    partnerUuid: string;

    allCountries = [];
    partnerFormGroup: FormGroup;
    loading = false;
    inputIsWrong = false;
    errorMessage: string;
    validateWebsiteWentWrong = false;
    validateVatWentWrong = false;

    @ViewChild('focussed', { static: false })
    set input(element: ElementRef<HTMLInputElement>) {
        if (element) {
            element.nativeElement.focus();
        }
    }
    constructor(
        public activeModal: NgbActiveModal,
        private countryService: CountryService,
        private formBuilder: FormBuilder,
        private store: Store,
        private vatValidationService: VatValidationService,
        private toasterService: ToastrService,
    ) {}

    ngOnInit(): void {
        this.allCountries = this.countryService.getAllCountries();
        this.partnerFormGroup = this.formBuilder.group({
            title: ['', [Validators.required]],
            vat: [''],
            yearFounded: [''],
            industry: [''],
            employeesCount: [''],
            address: this.formBuilder.group({
                street: [''],
                number: [null],
                bus: [''],
                ext: [''],
                postal: [''],
                city: [''],
                country: this.formBuilder.group({
                    iso2: [''],
                }),
            }),
            contactInformation: this.formBuilder.group({
                uuid: [''],
                phoneNumber: [null],
                email: [''],
                website: [''],
                linkedIn: [''],
            }),
        });
        this.setFormValues();
    }

    setFormValues() {
        this.partnerUuid = this.partner.uuid;
        this.partnerFormGroup.setValue({
            title: this.partner.title,
            vat: this.partner.vat !== null ? this.partner.vat : ' ',
            yearFounded: this.partner.yearFounded !== null ? this.partner.yearFounded : ' ',
            industry: this.partner.industry !== null ? this.partner.industry : ' ',
            employeesCount: this.partner.employeesCount !== null ? this.partner.employeesCount : ' ',
            address:
                this.partner.address !== null
                    ? {
                          street: this.partner.address.street ? this.partner.address.street : '',
                          number: this.partner.address.number ? this.partner.address.number : 0,
                          bus: this.partner.address.bus ? this.partner.address.bus : '',
                          ext: this.partner.address.ext ? this.partner.address.ext : '',
                          postal: this.partner.address.postal ? this.partner.address.postal : '',
                          city: this.partner.address.city ? this.partner.address.city : '',
                          country: this.partner.address.country
                              ? {
                                    iso2: this.partner.address.country.iso2,
                                }
                              : '',
                      }
                    : {
                          street: '',
                          number: 0,
                          bus: '',
                          ext: '',
                          postal: '',
                          city: '',
                          country: {
                              iso2: '',
                          },
                      },
            contactInformation:
                this.partner.contactInformation !== null
                    ? {
                          uuid: this.partner.contactInformation.uuid ? this.partner.contactInformation.uuid : '',
                          phoneNumber: this.partner.contactInformation.phoneNumber ? this.partner.contactInformation.phoneNumber : '',
                          email: this.partner.contactInformation.email ? this.partner.contactInformation.email : '',
                          website: this.partner.contactInformation.website ? this.partner.contactInformation.website : '',
                          linkedIn: this.partner.contactInformation.linkedIn ? this.partner.contactInformation.linkedIn : '',
                      }
                    : {
                          uuid: '',
                          phoneNumber: '',
                          email: '',
                          website: '',
                          linkedIn: '',
                      },
        });
    }

    updatePartner() {
        this.loading = true;

        if (!this.partnerFormGroup.valid) {
            this.inputIsWrong = true;
            this.loading = false;
            return;
        }

        this.partnerFormGroup.disable();
        this.partner = this.partnerFormGroup.value;

        this.subscriptions.add(
            this.store.dispatch(new UpdatePartner(this.partnerUuid, this.partner)).subscribe(
                () => {
                    this.activeModal.close();
                },
                (error) => {
                    this.loading = false;
                    this.partnerFormGroup.enable();
                },
            ),
        );
    }

    validateVatNumber() {
        this.validateVatWentWrong = false;

        const vatNumber = this.partnerFormGroup.get('vat').value.replace(/[ .]+/g, '');

        this.vatValidationService.getPartnerInformationFromVat(vatNumber).subscribe({
            next: (res) => {
                this.partnerFormGroup.setValue({
                    title: res.company.name,
                    vat: this.partnerFormGroup.get('vat').value,
                    address: {
                        street: res.company.address.split(' ')[0],
                        number: parseInt(res.company.address.split(' ')[1]),
                        bus: '',
                        ext: '',
                        postal: res.company.address.split('\n')[1].split(' ')[0],
                        city: res.company.address.split('\n')[1].split(' ')[1],
                        country: {
                            iso2: res.country.code,
                        },
                    },
                    yearFounded: this.partnerFormGroup.get('yearFounded')?.value,
                    industry: this.partnerFormGroup.get('industry')?.value,
                    employeesCount: this.partnerFormGroup.get('employeesCount')?.value,
                    contactInformation: this.formBuilder.group({
                        uuid: this.partner.contactInformation.uuid ? this.partner.contactInformation.uuid : '',
                        phoneNumber: this.partnerFormGroup.get('phoneNumber')?.value,
                        email: this.partnerFormGroup.get('email')?.value,
                        website: this.partnerFormGroup.get('website')?.value,
                    }),
                });
            },
            error: (error) => {
                this.validateVatWentWrong = true;
                this.errorMessage = error.error.error.message;
            },
        });
    }

    validateDomain() {
        this.validateWebsiteWentWrong = false;

        let website = this.partnerFormGroup.get('contactInformation').get('website').value;
        let nakedDomain = null;
        if (validator.isURL(website)) {
            if (!website.includes('http')) {
                website = `https://${website}`;
            }
            const domain = new URL(website);
            nakedDomain = domain.hostname.replace('www.', '');
        } else {
            this.toasterService.error('not a valid url');
        }

        this.vatValidationService.getExtraInfoInformationFromDomain(nakedDomain).subscribe({
            next: (res) => {
                this.partnerFormGroup.setValue({
                    title: this.partnerFormGroup.get('title')?.value,
                    vat: this.partnerFormGroup.get('vat')?.value,
                    address: this.partnerFormGroup.get('address')?.value,
                    yearFounded: res.year_founded,
                    industry: res.industry,
                    employeesCount: res.employees_count,
                    contactInformation: {
                        uuid: this.partner.contactInformation.uuid ? this.partner.contactInformation.uuid : '',
                        phoneNumber: this.partnerFormGroup.get('contactInformation')?.get('phoneNumber')?.value,
                        email: this.partnerFormGroup.get('contactInformation')?.get('email')?.value,
                        website: this.partnerFormGroup.get('contactInformation')?.get('website')?.value,
                        linkedIn: res.linkedin_url,
                    },
                });
            },
            error: (error) => {
                this.validateWebsiteWentWrong = true;
                this.errorMessage = error.error.error.message;
            },
        });
    }

    // Hides error messages
    hideErrorMessage() {
        this.inputIsWrong = false;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
