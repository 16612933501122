import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { VeldaCountry } from 'src/app/models/VeldaCountry';
import { VeldaCountryDTO } from 'src/app/models/VeldaCountryDTO';

import { EditCountry } from '../velda-languages.actions';

@Component({
    selector: 'app-velda-languages-countries-edit-modal',
    templateUrl: './velda-languages-countries-edit-modal.component.html',
    styleUrls: ['./velda-languages-countries-edit-modal.component.scss'],
})
export class VeldaLanguagesCountriesEditModalComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Input() country: VeldaCountry;
    countryFormGroup: FormGroup;

    constructor(
        private store: Store,
        public activeModal: NgbActiveModal,
        private formBuilder: FormBuilder,
        private toastrService: ToastrService,
        private logger: NGXLogger,
    ) {}

    ngOnInit(): void {
        this.countryFormGroup = this.formBuilder.group({
            countryName: [this.country.countryName, [Validators.required]],
            countryCode: [this.country.countryCode, [Validators.required]],
        });
    }

    editCountry() {
        const veldaCountry: VeldaCountryDTO = {
            countryName: this.countryFormGroup.value.countryName,
            countryCode: this.countryFormGroup.value.countryCode,
        };

        this.logger.debug('Attempt to edit country');

        this.subscriptions.add(
            this.store.dispatch(new EditCountry(this.country.uuid, veldaCountry)).subscribe(
                () => {
                    this.toastrService.success('Country edited successfully!');
                    this.logger.debug('Country edited successfully!');
                    this.activeModal.close();
                },
                (error) => {
                    this.toastrService.error('Failed to edit country!');
                    this.logger.error('Failed to edit country!');
                },
            ),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
