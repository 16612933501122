import { Component, OnDestroy, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { CompanyState } from 'src/app/companies/company.state';
import { Company } from 'src/app/models/Company';
import { Module } from 'src/app/models/Module';
import User from 'src/app/models/User';
import { UserState } from 'src/app/users/user.state';

@Component({
    selector: 'app-home-toolbar',
    templateUrl: './home-toolbar.component.html',
    styleUrls: ['./home-toolbar.component.scss'],
})
export class HomeToolbarComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Select(UserState.userInfo) user$: Observable<User>;
    @Select(CompanyState.company) company$: Observable<Company>;
    user: User;
    company: Company;
    modules: Module[];

    constructor() {}

    ngOnInit(): void {
        this.subscriptions.add(this.user$.subscribe((user) => (this.user = user)));

        this.subscriptions.add(this.company$.subscribe((company) => (this.company = company)));
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
