import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { append, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { Partner } from '../models/Partner';
import { ROUTES } from '../models/ROUTES';
import { CreateNotification } from '../notifications/notifications.actions';
import { AddUndefinedObjectTagsToObject } from '../shared/global-tags/global-tags.actions';
import { PartnerService } from './partner.service';
import { AddContactPersonToPartner, AddPartner, ArchivePartner, FetchPartnerById, FetchPartners, GetPartner, UpdatePartner } from './partners.actions';

export interface PartnerStateModel {
    partners: Partner[];
}

@State<PartnerStateModel>({
    name: 'partner',
    defaults: {
        partners: null,
    },
})
@Injectable()
export class PartnerState {
    constructor(private partnerService: PartnerService, private store: Store) {}

    @Selector()
    static getPartners(state: PartnerStateModel): Partner[] {
        return state?.partners;
    }

    @Action(FetchPartners)
    fetch(ctx: StateContext<PartnerStateModel>) {
        return this.partnerService.getAllPartners().pipe(
            tap((partners) => {
                ctx.setState(
                    patch<PartnerStateModel>({
                        partners: partners,
                    }),
                );
            }),
        );
    }

    @Action(FetchPartnerById)
    fetchPartnerById(ctx: StateContext<PartnerStateModel>, action: FetchPartnerById) {
        return this.partnerService.getPartnerById(action.id).pipe(
            tap((partner) => {
                ctx.setState(
                    patch<PartnerStateModel>({
                        partners: append([partner]),
                    }),
                );
            }),
        );
    }

    @Action(AddPartner)
    addPartner(ctx: StateContext<PartnerStateModel>, action: AddPartner) {
        return this.partnerService.addPartner(action.partner).pipe(
            tap((partner) => {
                ctx.setState(
                    patch<PartnerStateModel>({
                        partners: append<Partner>([partner]),
                    }),
                );
                this.store.dispatch(new AddUndefinedObjectTagsToObject(partner.uuid));
                this.store.dispatch(
                    new CreateNotification({
                        message: `A partner with name <strong>${partner.title}</strong> is added`,
                        route: ROUTES.CONTACTS.route,
                    }),
                );
            }),
        );
    }

    @Action(UpdatePartner)
    updatePartner(ctx: StateContext<PartnerStateModel>, action: UpdatePartner) {
        return this.partnerService.updatePartner(action.partnerUuid, action.partner).pipe(
            tap((partner) => {
                ctx.setState(
                    patch<PartnerStateModel>({
                        partners: updateItem<Partner>((p) => p.uuid === action.partnerUuid, partner),
                    }),
                );
                this.store.dispatch(
                    new CreateNotification({
                        message: `A partner with name <strong>${partner.title}</strong> is updated`,
                        route: ROUTES.CONTACTS.route,
                    }),
                );
            }),
        );
    }

    @Action(GetPartner)
    getPartner(ctx: StateContext<PartnerStateModel>, action: GetPartner): Partner {
        return ctx.getState().partners.find((p) => p.uuid === action.partneruuid);
    }

    @Action(ArchivePartner)
    deletePartner(ctx: StateContext<PartnerStateModel>, action: ArchivePartner) {
        return this.partnerService.archivePartner(action.partnerUuid).pipe(
            tap(() => {
                const partner: Partner = ctx.getState().partners.find((partner) => partner.uuid === action.partnerUuid);
                ctx.setState(
                    patch<PartnerStateModel>({
                        partners: removeItem<Partner>((p) => p.uuid === action.partnerUuid),
                    }),
                );
                this.store.dispatch(
                    new CreateNotification({
                        message: `A partner with name <strong>${partner.title}</strong> is archived`,
                        route: ROUTES.CONTACTS.route,
                    }),
                );
            }),
        );
    }

    @Action(AddContactPersonToPartner)
    addContactPerson(ctx: StateContext<PartnerStateModel>, action: AddContactPersonToPartner) {
        return this.partnerService.addContactPersonToPartner(action.partnerUuid, action.contactPerson).pipe(
            tap((partner) => {
                const oldContacts = ctx.getState().partners.find((p) => p.uuid == partner.uuid).contactPeople;
                const newContacts = partner.contactPeople.filter((c) => oldContacts.indexOf(c) < 0);
                ctx.setState(
                    patch<PartnerStateModel>({
                        partners: updateItem<Partner>((partner) => partner.uuid === action.partnerUuid, partner),
                    }),
                );
                this.store.dispatch(new AddUndefinedObjectTagsToObject(newContacts[0].uuid));
            }),
        );
    }
}
