import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-technical-skills-add-modal',
    templateUrl: './technical-skills-add-modal.component.html',
    styleUrls: ['./technical-skills-add-modal.component.scss'],
})
export class TechnicalSkillsAddModalComponent implements OnInit {
    @Input() resumeId: string;

    constructor(public activeModal: NgbActiveModal) {}

    ngOnInit(): void {}
}
