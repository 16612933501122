import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgbDropdownModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatTableExporterModule } from 'mat-table-exporter';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { TagInputModule } from 'ngx-chips';
import { OrderModule } from 'ngx-order-pipe';
import { NgPipesModule } from 'ngx-pipes';
import { SortablejsModule } from 'ngx-sortablejs';
import { NgxTrimDirectiveModule } from 'ngx-trim-directive';

import { SharedModule } from '../shared/shared.module';
import { AddCronjobRuleModalComponent } from './add-cronjob-rule-modal/add-cronjob-rule-modal.component';
import { AddTimesheetModalComponent } from './add-timesheet-modal/add-timesheet-modal.component';
import { CronjobRulesSettingsModalComponent } from './cronjob-rules-settings-modal/cronjob-rules-settings-modal.component';
import { EditHappinessScoreModalComponent } from './edit-happiness-score-modal/edit-happiness-score-modal.component';
import { EditTimesheetRequiredHoursComponent } from './edit-timesheet-required-hours/edit-timesheet-required-hours.component';
import { EditTimeslotModalComponent } from './edit-timeslot-modal/edit-timeslot-modal.component';
import { InvoiceDialogComponent } from './invoice-dialog/invoice-dialog.component';
import { PublicTimeslotListComponent } from './public-timeslot-list/public-timeslot-list.component';
import { TimesheetInfoModalComponent } from './timesheet-info-modal/timesheet-info-modal.component';
import { TimesheetsListComponent } from './timesheets-list/timesheets-list.component';
import { TimesheetsOverviewComponent } from './timesheets-overview/timesheets-overview.component';
import { TimesheetsProjectListComponent } from './timesheets-project-list/timesheets-project-list.component';
import { routing } from './timesheets.routes';
import { TimeslotListComponent } from './timeslot-list/timeslot-list.component';
import { UpdateTimeslotSaveModalComponent } from './update-timeslot-save-modal/update-timeslot-save-modal.component';
import { UpdateTimeslotComponent } from './update-timeslot/update-timeslot.component';

@NgModule({
    declarations: [
        TimesheetsOverviewComponent,
        TimesheetInfoModalComponent,
        TimeslotListComponent,
        AddTimesheetModalComponent,
        EditTimeslotModalComponent,
        UpdateTimeslotComponent,
        CronjobRulesSettingsModalComponent,
        AddCronjobRuleModalComponent,
        TimesheetsListComponent,
        EditTimesheetRequiredHoursComponent,
        UpdateTimeslotSaveModalComponent,
        EditHappinessScoreModalComponent,
        PublicTimeslotListComponent,
        InvoiceDialogComponent,
        TimesheetsProjectListComponent,
    ],
    imports: [
        CommonModule,
        PdfViewerModule,
        routing,
        NgbModule,
        SharedModule,
        NgbDropdownModule,
        NgxTrimDirectiveModule,
        ReactiveFormsModule,
        NgPipesModule,
        OrderModule,
        TagInputModule,
        SortablejsModule,
        CdkTableModule,
        MatTableModule,
        MatSortModule,
        MatTableExporterModule,
        MatDialogModule,
        MatIconModule,
        MatTooltipModule,
    ],
    exports: [TimesheetInfoModalComponent],
})
export class TimesheetsModule {}
