<h1 mat-dialog-title>
  {{ title }}
</h1>

<div mat-dialog-content>
  <div [innerHtml]="question" class="mb-3"></div>
</div>

<div mat-dialog-actions class="button-container">
  <button
    mat-raised-button
    mat-dialog-close
    class="button left-button"
    (click)="close(false)"
    tabindex="-1"
  >
    Cancel
  </button>
  <button
    mat-raised-button
    class="button right-button"
    (click)="close(true)"
    tabindex="1"
  >
    Yes
  </button>
</div>
