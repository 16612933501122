<label [for]="id">
    <span *ngIf="showLabel">{{ label }}</span>
</label>
<div class="d-flex">
    <input
        #passwordInput
        [(ngModel)]="password"
        [placeholder]="placeholder"
        [type]="inputType.html"
        [id]="id"
        trim="blur" />
    <button
        #visibleButton
        (click)="changeVisibility()"
        class="button button--outline button--icon m-l-2"
        type="button">
        <i
            *ngIf="inputType.html === 'password'"
            class="las la-eye"></i>
        <i
            *ngIf="inputType.html === 'text'"
            class="las la-eye-slash color--grey"></i>
    </button>
</div>
