import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { append, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { tap } from 'rxjs/operators';

import CampaignModel from '../models/CampaignModel';
import MailchimpTagModel from '../models/MailchimpTagModel';
import MailchimpTemplate from '../models/MailchimpTemplate';
import RecipientListModel from '../models/RecipientListModel';
import RecipientModel from '../models/RecipientModel';
import {
    AddCampaign,
    AddMailchimpTag,
    AddRecipient,
    FetchAudience,
    FetchCampaignReport,
    FetchCampaigns,
    FetchRecipients,
    FetchTags,
    FetchTemplates,
    RemoveTag,
} from './mailchimp.actions';
import { MailchimpService } from './mailchimp.service';

export interface MailchimpStateModel {
    campaigns: CampaignModel[];
    audiences: RecipientListModel[];
    templates: MailchimpTemplate[];
    tags: MailchimpTagModel[];
}

@State<MailchimpStateModel>({
    name: 'mailchimp',
    defaults: {
        campaigns: [],
        audiences: [],
        templates: [],
        tags: [],
    },
})
@Injectable()
export class MailchimpState {
    @Selector()
    static campaigns(state: MailchimpStateModel): CampaignModel[] {
        return state?.campaigns;
    }

    @Selector()
    static audiences(state: MailchimpStateModel): RecipientListModel[] {
        return state?.audiences;
    }

    @Selector()
    static templates(state: MailchimpStateModel): MailchimpTemplate[] {
        return state?.templates;
    }

    @Selector()
    static tags(state: MailchimpStateModel): MailchimpTagModel[] {
        return state?.tags;
    }

    constructor(private mailchimpService: MailchimpService) {}

    /**
     * fetch
     */
    @Action(FetchCampaigns)
    fetchCampaigns(ctx: StateContext<MailchimpStateModel>) {
        return this.mailchimpService.getCampaigns().pipe(
            tap((campaigns) => {
                ctx.setState(
                    patch<MailchimpStateModel>({
                        campaigns: campaigns,
                    }),
                );
            }),
        );
    }

    @Action(FetchCampaignReport)
    fetchCampaignReport(ctx: StateContext<MailchimpStateModel>, action: FetchCampaignReport) {
        return this.mailchimpService.getReport(action.campaignId).pipe(
            tap((report) => {
                ctx.setState(
                    patch<MailchimpStateModel>({
                        campaigns: updateItem<CampaignModel>(
                            (c) => c.id === action.campaignId,
                            patch<CampaignModel>({
                                report: report,
                            }),
                        ),
                    }),
                );
            }),
        );
    }

    @Action(FetchAudience)
    fetchAudience(ctx: StateContext<MailchimpStateModel>) {
        return this.mailchimpService.getLists().pipe(
            tap((lists) => {
                ctx.setState(
                    patch<MailchimpStateModel>({
                        audiences: lists,
                    }),
                );
                lists.forEach((element) => {
                    this.fetchRecipients(ctx, new FetchRecipients(element.id)).subscribe();
                });
            }),
        );
    }

    @Action(FetchTemplates)
    fetchTemplates(ctx: StateContext<MailchimpStateModel>) {
        return this.mailchimpService.getTemplates().pipe(
            tap((templates) => {
                ctx.setState(
                    patch<MailchimpStateModel>({
                        templates: templates,
                    }),
                );
            }),
        );
    }

    @Action(FetchTags)
    fetchTags(ctx: StateContext<MailchimpStateModel>, action: FetchTags) {
        return this.mailchimpService.getTags(action.listId).pipe(
            tap((t) => {
                ctx.setState(
                    patch<MailchimpStateModel>({
                        tags: t,
                    }),
                );
            }),
        );
    }

    @Action(FetchRecipients)
    fetchRecipients(ctx: StateContext<MailchimpStateModel>, action: FetchRecipients) {
        return this.mailchimpService.getRecipients(action.audienceId).pipe(
            tap((recipients) => {
                ctx.setState(
                    patch<MailchimpStateModel>({
                        audiences: updateItem<RecipientListModel>(
                            (l) => l.id === action.audienceId,
                            patch<RecipientListModel>({
                                recipients: recipients,
                            }),
                        ),
                    }),
                );
            }),
        );
    }

    /**
     * add
     */
    @Action(AddCampaign)
    addCampaign(ctx: StateContext<MailchimpStateModel>, action: AddCampaign) {
        return this.mailchimpService.addCampaign(action.campaign).pipe(
            tap((campaign) => {
                ctx.setState(
                    patch<MailchimpStateModel>({
                        campaigns: append<CampaignModel>([campaign]),
                    }),
                );
            }),
        );
    }

    @Action(AddMailchimpTag)
    addTag(ctx: StateContext<MailchimpStateModel>, action: AddMailchimpTag) {
        const body = { listId: action.listId, tagName: action.tagName };
        return this.mailchimpService.addTagToList(body).pipe(
            tap((tag) => {
                ctx.setState(
                    patch<MailchimpStateModel>({
                        tags: append<MailchimpTagModel>([tag]),
                    }),
                );
            }),
        );
    }

    @Action(AddRecipient)
    addRecipient(ctx: StateContext<MailchimpStateModel>, action: AddRecipient) {
        return this.mailchimpService.addRecipient(action.lead).pipe(
            tap((recipient) => {
                ctx.setState(
                    patch<MailchimpStateModel>({
                        audiences: updateItem<RecipientListModel>(
                            (l) => l.id === action.lead.listId,
                            patch<RecipientListModel>({
                                recipients: append<RecipientModel>([recipient]),
                            }),
                        ),
                    }),
                );
            }),
        );
    }

    /**
     * update
     */

    /**
     * delete
     */

    @Action(RemoveTag)
    removeTag(ctx: StateContext<MailchimpStateModel>, action: RemoveTag) {
        return this.mailchimpService.removeTagFromList(action.listId, action.tagId).pipe(
            tap(() => {
                ctx.setState(
                    patch<MailchimpStateModel>({
                        tags: removeItem<MailchimpTagModel>((t) => t.id === action.tagId),
                    }),
                );
            }),
        );
    }
}
