import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import CrmDeal from 'src/app/models/CrmDeal';
import { ConfirmationModalComponent } from 'src/app/shared/confirmation-modal/confirmation-modal.component';

import { DearchiveCrmDeal, DeleteCrmDeal, FetchArchivedCrmDeals } from '../crm.actions';
import { CrmState } from '../crm.state';

@Component({
    selector: 'app-archived-crmdeals',
    templateUrl: './archived-crmdeals.component.html',
    styleUrls: ['./archived-crmdeals.component.scss'],
})
export class ArchivedCrmdealsComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Select(CrmState.ArchivedCrmDeals) archivedCrmDeals$: Observable<CrmDeal[]>;
    isLoading = true;

    displayedColumns: string[] = ['title', 'linked items', 'price', 'edit'];
    dataSource: MatTableDataSource<CrmDeal> = new MatTableDataSource<CrmDeal>();
    @ViewChild(MatSort) sort: MatSort;

    constructor(
        private store: Store,
        public activeModal: NgbActiveModal,
        private modalService: NgbModal,
        private toastr: ToastrService,
        private logger: NGXLogger,
    ) {}

    ngOnInit(): void {
        this.logger.debug('Attempt to fetch archived crm deals');
        this.subscriptions.add(this.store.dispatch(new FetchArchivedCrmDeals()).subscribe());
        this.subscriptions.add(
            this.archivedCrmDeals$.subscribe((archivedCrmDeals) => {
                if (archivedCrmDeals) {
                    this.dataSource.data = archivedCrmDeals;
                    this.isLoading = false;
                }
            }),
        );
    }

    deAchiveDeal(deal: CrmDeal) {
        this.subscriptions.add(
            this.store.dispatch(new DearchiveCrmDeal(deal)).subscribe(
                (res) => {
                    if (res) {
                        this.logger.debug('Succesfully de archived crmDeal');
                        this.toastr.success('Deal was successfully dearchived.');
                    }
                },
                (err) => {
                    this.logger.error('Failed to de archive crmDeal');
                    this.toastr.error('Oops, something went wrong. Please try again later.');
                },
            ),
        );
    }

    delete(deal: CrmDeal) {
        this.logger.debug('opening ConfirmationModal');
        const modalRef = this.modalService.open(ConfirmationModalComponent, {
            windowClass: 'modal-prompt',
        });
        modalRef.componentInstance.type = 'Delete';
        modalRef.componentInstance.title = 'Delete deal';
        modalRef.componentInstance.message = 'Are you sure you want to delete ' + deal.title + '?';
        this.subscriptions.add(
            modalRef.componentInstance.closeEvent.subscribe((val) => {
                if (val) {
                    this.logger.debug('Attempt to delete crmDeal');
                    this.store.dispatch(new DeleteCrmDeal(deal)).subscribe(
                        (res) => {
                            if (res) {
                                this.logger.debug('Succesfully deleted crmDeal');
                                this.toastr.success('Deal deleted successfully.');
                            }
                        },
                        (err) => {
                            this.logger.error('Failed to delete crmDeal');
                            this.toastr.error('Oops, something went wrong. Please try again later.');
                        },
                    );
                }
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
