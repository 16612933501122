import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { EmployeesAddModalComponent } from 'src/app/employees/employees-add-modal/employees-add-modal.component';
import { FetchEmployees } from 'src/app/employees/employees.actions';
import { EmployeeState } from 'src/app/employees/employees.state';
import { Employee } from 'src/app/models/Employee';
import { AddRelatedEmployee } from 'src/app/related-employees/related.employees.action';

@Component({
    selector: 'app-employee-assign-modal',
    templateUrl: './employee-assign-modal.component.html',
    styleUrls: ['./employee-assign-modal.component.scss'],
})
export class EmployeeAssignModalComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Input() object: any;
    @Select(EmployeeState.Employees) allEmployees$: Observable<Employee[]>;
    @Input() employee: Employee;

    employeeList = '';
    selectedArray: Employee[] = [];
    allEmployees: Employee[];
    availableEmployees: Employee[] = [];
    title: string;
    amountArray: number[] = [];
    keyword = 'firstName';

    actualEmployees: Employee[];
    chosenEmployee: string;
    existingEmployee: Employee;

    constructor(
        private toastr: ToastrService,
        private store: Store,
        private modalService: NgbModal,
        public activeModal: NgbActiveModal,
        private logger: NGXLogger,
    ) {}

    ngOnInit(): void {
        this.logger.debug('Fetching employees');
        this.store.dispatch(new FetchEmployees());
        this.subscriptions.add(
            this.allEmployees$.subscribe((employees) => {
                this.allEmployees = employees;
                if (this.allEmployees && this.object.employees) {
                    this.actualEmployees = [...this.allEmployees];
                    this.object.employees.forEach((element) => {
                        this.actualEmployees.filter((el) => {
                            if (el?.uuid == element?.uuid) {
                                if (this.actualEmployees.indexOf(el) != -1) this.actualEmployees.splice(this.actualEmployees.indexOf(el), 1);
                            }
                        });
                    });
                }
            }),
        );
    }

    fullNameFilter(items: any[], query: string) {
        const results: any[] = [];

        items.forEach((item) => {
            if ((item['firstName'] + ' ' + item['lastName']).toLowerCase().includes(query.toLowerCase())) {
                results.push(item);
            }
        });

        return results;
    }

    selectEvent(item) {
        this.addToEmployeeList(item.uuid);
    }

    addToEmployeeList(uuid: string) {
        this.employeeList = '';
        this.selectedArray.length = 0;

        this.employeeList = this.employeeList.concat(uuid);
        this.selectedArray.push(this.allEmployees.filter((item: Employee) => item.uuid.toLowerCase().includes(uuid))[0]);
    }

    openAddEmployeeModal() {
        this.logger.debug('Opening employeeAddModal');
        this.modalService.open(EmployeesAddModalComponent, {
            windowClass: 'modal-prompt',
        });
    }

    addNewEmployees() {
        if (this.employeeList == '') {
            this.toastr.error('Please select an employee');
        } else {
            this.logger.debug('Attempt to add relatedEmployee');
            this.subscriptions.add(
                this.store.dispatch(new AddRelatedEmployee(this.object.uuid, this.employeeList)).subscribe(
                    () => {
                        this.logger.debug('Succesfully added relatedEmployee');
                        this.toastr.success('Employee added successfully');
                        this.chosenEmployee = '';
                    },
                    (error) => {
                        this.logger.error('Failed to add relatedEmployee');
                        this.toastr.error('Employee already added');
                    },
                ),
            );
            this.activeModal.close();
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
