import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { Timesheet } from 'src/app/models/Timesheet';
import { TimesheetInfoModalComponent } from 'src/app/timesheets/timesheet-info-modal/timesheet-info-modal.component';

import { TimesheetsService } from './timesheet.service';

@Component({
    selector: 'app-pill-timesheet',
    templateUrl: './pill-timesheet.component.html',
    styleUrls: ['./pill-timesheet.component.scss'],
})
export class PillTimesheetComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();
    @Input() timesheet: Timesheet;
    @Input() uuid: string;
    isLoading: boolean = true;

    class: string = 'pill pill-timesheet';

    constructor(private modalService: NgbModal, private timesheetService: TimesheetsService) {}

    ngOnInit(): void {
        if (this.timesheet == undefined) {
            this.subscriptions.add(
                this.timesheetService.getTimesheet(this.uuid).subscribe((t) => {
                    this.timesheet = t;
                    if (this.class.endsWith('timesheet')) {
                        this.class += this.timesheet ? '-' + this.timesheet.status.key.toLocaleLowerCase() : '';
                    }
                    this.isLoading = false;
                }),
            );
        } else {
            this.isLoading = false;
        }
        this.class += this.timesheet ? '-' + this.timesheet.status.key.toLocaleLowerCase() : '';
    }

    openTimesheetDetailModal() {
        const modalRef = this.modalService.open(TimesheetInfoModalComponent, {
            windowClass: 'modal-pane',
            animation: false,
        });
        modalRef.componentInstance.timesheet = this.timesheet;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
