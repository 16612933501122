import { Component, EventEmitter, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import RecipientListModel from 'src/app/models/RecipientListModel';

import { RecipientService } from '../recipient.service';
import AddNewRecipientModel from './AddNewRecipientModel';

@Component({
    selector: 'app-add-recipient-modal',
    templateUrl: './add-recipient-modal.component.html',
    styleUrls: ['./add-recipient-modal.component.scss'],
})
export class AddRecipientModalComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();
    public closeEvent: EventEmitter<void> = new EventEmitter();
    @Input() email: string;
    @Input() firstname: string;
    @Input() lastname: string;
    @Input() phone: string;

    newRecipientFormGroup: FormGroup;
    lists: RecipientListModel[];
    tags: any[] = [];
    allTags: string[] = [];

    constructor(
        public activeModal: NgbActiveModal,
        private formBuilder: FormBuilder,
        private recipientService: RecipientService,
        private toastr: ToastrService,
        private logger: NGXLogger,
    ) {}

    ngOnInit(): void {
        this.logger.debug('Fetching recipient lists');
        this.subscriptions.add(
            this.recipientService.getLists().subscribe((l) => {
                this.lists = l;
            }),
        );
        this.newRecipientFormGroup = this.formBuilder.group({
            firstname: [this.firstname, [Validators.required]],
            lastname: [this.lastname, [Validators.required]],
            email: [this.email, [Validators.required, Validators.email]],
            phone: [this.phone],
            list: ['', [Validators.required]],
            tag: [],
        });
    }

    addLead($event: any) {
        const t: string[] = [];
        this.tags.forEach((el) => t.push(el.value));
        const model: AddNewRecipientModel = {
            firstname: this.newRecipientFormGroup.get('firstname').value,
            lastname: this.newRecipientFormGroup.get('lastname').value,
            email: this.newRecipientFormGroup.get('email').value,
            phone: this.newRecipientFormGroup.get('phone').value,
            tags: t,
            listId: this.newRecipientFormGroup.get('list').value,
        };

        this.logger.debug('Attempt to add lead');
        this.subscriptions.add(
            this.recipientService.addLead(model).subscribe(
                (result) => {
                    if (result) {
                        this.logger.debug('Succesfully added lead');
                        this.toastr.success('List updated successfully!');
                        this.closeEvent.emit();
                        this.activeModal.dismiss();
                    }
                },
                (error) => this.logger.error('Failed to add lead'),
            ),
        );
    }

    onChange(value: any) {
        const listId = this.newRecipientFormGroup.get('list').value;
        if (listId) {
            this.subscriptions.add(this.recipientService.getTags(listId).subscribe((t) => t.forEach((el) => this.allTags.push(el.name))));
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
