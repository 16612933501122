<table class="table-view">
    <tr>
        <th>Project</th>
        <th>Description</th>
        <th>Worked hours</th>
        <th>Attachment</th>
        <th
            *ngIf="isEditable"
            class="td-small"></th>
    </tr>

    <tr *ngFor="let timeslot of timesheet.timeslots">
        <td>
            <app-pill-project [project]="timeslot.project"></app-pill-project>
        </td>
        <td style="white-space: pre-line">{{ timeslot.description }}</td>
        <td>{{ timeslot.minutes | minutesToMinutesAndHours }}</td>
        <td>
            <app-pill-pdf
                [url]="timeslot.pdfUrl"
                [exists]="timeslot.pdfUrl"
                [pdfAsBase64$]="getBase64(timeslot)"
                [fileName]="getFileName(timeslot.pdfUrl)">
            </app-pill-pdf>
        </td>
        <td
            *ngIf="isEditable"
            class="td-icon">
            <span ngbDropdown>
                <button
                    ngbDropdownToggle
                    class="button button--icon button--dropdown button--small"></button>
                <span
                    ngbDropdownMenu
                    class="dropdown-menu dropdown-menu-right"
                    aria-labelledby="dropdownMenuButton">
                    <a
                        class="dropdown-item"
                        (click)="openEditTimeslotModal(timeslot, timesheet, changeEvent)"
                        >Update timeslot</a
                    >
                    <a
                        class="dropdown-item color--red"
                        (click)="removeTimeslot(timeslot, timesheet, changeEvent)"
                        >Remove timeslot</a
                    >
                </span>
            </span>
        </td>
    </tr>
    <tr *ngIf="isEditable">
        <td
            colspan="4"
            class="table-view__add"
            (click)="openAddTimeslotModal(timesheet, changeEvent)">
            <i class="las la-plus"></i> Add new timeslot
        </td>
    </tr>
</table>
