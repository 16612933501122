import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';

import LogCategory from '../../models/Logbook/LogCategory';
import LogItem from '../../models/Logbook/LogItem';
import { LogCategoriesComponent } from '../log-categories/log-categories.component';
import { AddLogItem, GetAllLogCategories, UpdateLogItem } from '../logbook.actions';
import { LogbookState } from '../logbook.state';

@Component({
    selector: 'app-add-log-item-modal',
    templateUrl: './add-log-item-modal.component.html',
    styleUrls: ['./add-log-item-modal.component.scss'],
})
export class AddLogItemModalComponent implements OnInit, OnDestroy {
    @Input() objectId: string;
    @Input() logItemToUpdate: LogItem;

    @Select(LogbookState.LogCategories) logCategories$: Observable<LogCategory[]>;
    logCategories: LogCategory[];

    logItemForm: FormGroup;

    private subscriptions = new Subscription();

    constructor(
        public activeModal: NgbActiveModal,
        private store: Store,
        private fb: FormBuilder,
        private toastr: ToastrService,
        private modalService: NgbModal,
    ) {}

    ngOnInit(): void {
        this.fetchData();
        this.createLogItemForm();
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    addLogItem() {
        const newLogItem: LogItem = {
            objectId: this.objectId,
            category: this.logItemForm.value.category,
            description: this.logItemForm.value.description,
        };

        if (!this.logItemToUpdate) {
            this.subscriptions.add(
                this.store.dispatch(new AddLogItem(newLogItem)).subscribe({
                    next: () => {
                        this.activeModal.close();
                    },
                    error: () => {
                        this.toastr.error('Oops, something went wrong, Please try again later...');
                    },
                }),
            );
        } else {
            this.subscriptions.add(
                this.store.dispatch(new UpdateLogItem(this.logItemToUpdate.id, newLogItem)).subscribe({
                    next: () => {
                        this.activeModal.close();
                    },
                    error: () => {
                        this.toastr.error('Oops, something went wrong, Please try again later...');
                    },
                }),
            );
        }
    }

    openLogbookCategoriesModal() {
        const modalRef = this.modalService.open(LogCategoriesComponent, { windowClass: 'modal-pane', animation: false });
        modalRef.componentInstance.logCategories = this.logCategories;
    }

    private fetchData() {
        this.subscriptions.add(
            this.logCategories$.subscribe((categories) => {
                if (categories === undefined) {
                    this.store.dispatch(new GetAllLogCategories());
                    return;
                }
                this.logCategories = categories;
            }),
        );
    }

    private createLogItemForm() {
        if (!this.logItemToUpdate) {
            this.logItemForm = this.fb.group({
                category: ['', Validators.required],
                description: '',
            });
        } else {
            this.logItemForm = this.fb.group({
                category: [this.logItemToUpdate.category, Validators.required],
                description: this.logItemToUpdate.description,
            });
        }
    }
}
