import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { MatTableExporterDirective } from 'mat-table-exporter';
import * as moment from 'moment';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { CompanyState } from 'src/app/companies/company.state';
import Project from 'src/app/models/Project';
import { PartnerDetailsModalComponent } from 'src/app/partners/partner-details-modal/partner-details-modal.component';

import { ProjectDeleteModalComponent } from '../project-delete-modal/project-delete-modal.component';
import { ProjectDetailModalComponent } from '../project-detail-modal/project-detail-modal.component';
import { ProjectInfoModalComponent } from '../project-info-modal/project-info-modal.component';
import { FetchProjectsForOverview, UpdateFinishedOfProject } from '../project.actions';
import { ProjectService } from '../project.service';
import { ProjectState } from '../project.state';

@Component({
    selector: 'app-project-list',
    templateUrl: './project-list.component.html',
    styleUrls: ['./project-list.component.scss'],
})
export class ProjectListComponent implements OnInit, OnDestroy {
    @Select(CompanyState.companyUuid) companyUuid$: Observable<string>;
    @Select(ProjectState.projects) projects$: Observable<Project[]>;
    @Input() type: ProjectListType;
    @Output() infoModalClosed: EventEmitter<any> = new EventEmitter();
    companyUuid: string;
    filter = '';
    filename: string;
    projectPdf: any;
    displayedColumns: string[] = ['eye', 'title', 'endDate', 'warranty', 'maintenanceContract', 'budget', 'partnerName', 'projectType', 'jiraLink', 'creatorId', 'edit'];
    dataSource: MatTableDataSource<Project>;
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatTableExporterDirective, { static: true }) exporter: MatTableExporterDirective;
    private subscriptions = new Subscription();
    showOnlyMaintenanceContracts: boolean = false;
    maintenanceFilterBtnText: string;

    constructor(
        private modalService: NgbModal,
        private store: Store,
        public activeModal: NgbActiveModal,
        private router: Router,
        private toastr: ToastrService,
        private projectService: ProjectService,
        private sanitizer: DomSanitizer,
        private logger: NGXLogger,
    ) {}

    ngOnInit(): void {
        this.subscriptions.add(this.companyUuid$.subscribe((uuid) => (this.companyUuid = uuid)));

        this.subscriptions.add(
            this.projects$.subscribe((projects) => {
                if (!projects) {
                    this.logger.debug('Fetching user');
                    this.store.dispatch(new FetchProjectsForOverview());
                    return;
                }

                const filtered: Project[] = projects.filter((project) => {
                    if (this.type === ProjectListType.ONGOING) return !project.finished && !project.archived;
                    else if (this.type === ProjectListType.ARCHIVED) return project.archived;
                    else if (this.type === ProjectListType.FINISHED) return project.finished;
                    else if (this.type === ProjectListType.HAS_MAINTENANCE_CONTRACT) return project.maintenanceContract;
                    return true;
                });

                this.dataSource = new MatTableDataSource(filtered);
                this.dataSource.sort = this.sort;

                // Sorts project list alphabetically, independant of project.title casing
                this.dataSource.sortingDataAccessor = (data: any, sortHeaderId: string): string => {
                    if (typeof data[sortHeaderId] === 'string') {
                      return data[sortHeaderId].toLocaleLowerCase();
                    }
                  
                    return data[sortHeaderId];
                  };
                  
                this.applyFilter(this.filter);
            }),
        );

        this.maintenanceFilterBtnText = "Show projects with maintenance only";
    }

    applyFilter(filterValue) {
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }

    ngAfterViewInit() {
        this.dataSource.sort = this.sort;
    }

    openAddProjectModal() {
        this.logger.debug('Opening projectDetailModal');
        const modalRef = this.modalService.open(ProjectDetailModalComponent, {
            windowClass: 'modal-pane',
            animation: false,
        });
        modalRef.componentInstance.inOverview = true;
    }

    openProjectUpdateModal(project: Project) {
        this.logger.debug('Opening projectDetailModal - update');
        const modalRef = this.modalService.open(ProjectDetailModalComponent, {
            windowClass: 'modal-pane',
            animation: false,
        });
        modalRef.componentInstance.project = project;
        modalRef.componentInstance.isUpdate = true;
    }

    openProjectDeleteModal(project: Project) {
        this.logger.debug('Opening projectDeleteModal');
        const modalRef = this.modalService.open(ProjectDeleteModalComponent, { windowClass: 'modal-prompt', animation: false });
        modalRef.componentInstance.project = project;
    }

    openProjectInfoModal(projectUuid: string) {
        this.logger.debug('Opening projectInfoModal');
        const modalRef = this.modalService.open(ProjectInfoModalComponent, {
            windowClass: 'modal-huge',
            animation: false,
        });
        modalRef.componentInstance.projectUuid = projectUuid;
    }

    openPartnerDetailModalComponent(partnerUuid: string) {
        this.logger.debug('Opening partnerDetailModal');
        const modalRef = this.modalService.open(PartnerDetailsModalComponent, { windowClass: 'modal-prompt', animation: false });
        modalRef.componentInstance.partnerUuid = partnerUuid;
    }

    projectDetail(project: Project) {
        this.logger.debug('Navigating to projectdetail');
        this.router.navigate(['/', 'works', project.uuid, 'detail']);
    }

    toggleFinishedProject(project: Project) {
        this.logger.debug('Attempt to update finished status of project');
        this.subscriptions.add(
            this.store.dispatch(new UpdateFinishedOfProject(project.uuid)).subscribe(
                () => {
                    this.logger.debug('Successfully updated finished status of project');
                    this.toastr.success('Project status updated');
                },
                (error) => {
                    this.logger.debug('Failed to update finished status of project');
                    this.toastr.error('Something went wrong');
                },
            ),
        );
    }

    checkDates(project: Project): boolean {
        if (project.endDate) {
            const endDate = moment(project.endDate, 'yyyy-MM-DD hh:mm:ss');

            if (endDate < moment()) {
                return true;
            }
        }
        return false;
    }

    hasWarranty(project: Project): boolean {
        if (project.warranty) {
            return true;
        }
        return false;
    }

    hasMaintenanceContract(project: Project): boolean {
        if (project.maintenanceContract) {
            return true;
        }
        return false;
    }

    hasActiveWarrantyDates(project: Project): boolean {
        if (project.warrantyEndDate) {
          const warrantyEndDate = moment(project.warrantyEndDate, 'yyyy-MM-DD hh:mm:ss');
          
          if (warrantyEndDate.endOf('day').isBefore(moment().endOf('day'))) {
            return false;
          }
        }
      
        return true;
    }

    calculateRemainingWarrantyDays(project: Project): number {
        const today = moment().startOf('day');
        const warrantyEndDate = moment(project.warrantyEndDate);
        const daysRemaining = warrantyEndDate.diff(today, 'days');
        return daysRemaining;
    }

    downloadProjectPdf(project) {
        this.subscriptions.add(
            this.projectService.downloadProjectPdf(project.uuid).subscribe((pdf) => {
                this.filename = `${project.title}.pdf`;
                this.base64ToBlob(pdf.pdf);
            }),
        );
    }

    base64ToBlob(data) {
        const byteChars = atob(data);
        const byteNumbers = new Array(byteChars.length);
        for (let i = 0; i < byteChars.length; i++) {
            byteNumbers[i] = byteChars.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);
        this.projectPdf = this.sanitizer.bypassSecurityTrustUrl(url);
        this.logger.debug('Opening pdf in new window');
        window.open(url);
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}

export enum ProjectListType {
    ONGOING,
    FINISHED,
    ARCHIVED,
    HAS_MAINTENANCE_CONTRACT,
}
