import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AccountSettingsComponent } from './account-settings/account-settings.component';
import { ApiSettingsComponent } from './api-settings/api-settings.component';
import { AppInfoComponent } from './app-info/app-info.component';
import { CompanyMembersComponent } from './company-members/company-members.component';
import { CompanySettingsComponent } from './company-settings/company-settings.component';
import { CompanyTagsComponent } from './company-tags/company-tags.component';
import { GeneralSettingsComponent } from './general-settings/general-settings.component';
import { MailSetupComponent } from './mail-setup/mail-setup.component';
import { ModuleSettingsComponent } from './module-settings/module-settings.component';
import { ModuleSetupComponent } from './module-setup/module-setup.component';
import { ProjectSettingsComponent } from './project-settings/project-settings.component';
import { ResumeSettingsComponent } from './resume-settings/resume-settings.component';
import { RolesComponent } from './roles/roles-overview/roles.component';
import { SettingsTokenRetrievalComponent } from './settings-token-retrieval/settings-token-retrieval.component';
import { SettingsModuleSetupGuard } from './settings.module.setup.guard';

export const routes: Routes = [
    { path: '', redirectTo: 'account', pathMatch: 'full' },
    { path: 'account', component: AccountSettingsComponent },
    { path: 'company', component: CompanySettingsComponent },
    { path: 'members', component: CompanyMembersComponent },
    { path: 'tags', component: CompanyTagsComponent },
    { path: 'resume', component: ResumeSettingsComponent },
    { path: 'project', component: ProjectSettingsComponent },
    { path: 'module', component: ModuleSettingsComponent },
    { path: 'mailing', component: MailSetupComponent },
    {
        path: 'module-setup',
        component: ModuleSetupComponent,
        canActivate: [SettingsModuleSetupGuard],
    },
    { path: 'roles', component: RolesComponent },
    { path: 'api', component: ApiSettingsComponent },
    { path: 'token-retrieval', component: SettingsTokenRetrievalComponent },
    { path: 'app-info', component: AppInfoComponent },
    { path: 'general', component: GeneralSettingsComponent },
];

export const routing: ModuleWithProviders<any> = RouterModule.forChild(routes);
