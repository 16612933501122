<app-app-holder>
    <app-navbar></app-navbar>

    <app-sidebar>
        <app-settings-subnav></app-settings-subnav>
    </app-sidebar>

    <app-content>
        <div *ngIf="allModules$ | async; else loading">
            <div *ngIf="allModules.length">
                <div class="app__title">
                    <h1>Modules</h1>
                </div>

                <div class="sectiontitle">Resumo Modules</div>

                <table
                    mat-table
                    [dataSource]="allModules"
                    matSort>
                    <ng-container matColumnDef="module">
                        <th
                            mat-header-cell
                            *matHeaderCellDef
                            mat-sort-header>
                            Module
                        </th>
                        <td
                            mat-cell
                            *matCellDef="let module">
                            {{ module.title }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="description">
                        <th
                            mat-header-cell
                            *matHeaderCellDef
                            mat-sort-header>
                            Description
                        </th>
                        <td
                            mat-cell
                            *matCellDef="let module">
                            {{ module.description }}
                        </td>
                    </ng-container>

                    <tr
                        mat-header-row
                        *matHeaderRowDef="displayedModuleColumns"></tr>

                    <tr
                        mat-row
                        *matRowDef="let row; columns: displayedModuleColumns"
                        [hidden]="!checkActiveModule(row)"></tr>
                </table>

                <div class="sectiontitle">Integrations</div>

                <table
                    class="table-view"
                    mat-table
                    [dataSource]="allIntegrations"
                    matSort>
                    <ng-container matColumnDef="integration">
                        <th
                            mat-header-cell
                            *matHeaderCellDef
                            mat-sort-header>
                            Integration
                        </th>
                        <td
                            mat-cell
                            *matCellDef="let integration">
                            <span class="d-flex align-items-center">
                                <img
                                    src="{{ integration.image }}"
                                    class="m-r-2" />
                                {{ integration.title }}
                            </span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="description">
                        <th
                            mat-header-cell
                            *matHeaderCellDef
                            mat-sort-header>
                            Description
                        </th>
                        <td
                            mat-cell
                            *matCellDef="let integration">
                            <small> {{ integration.description }}</small>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="edit">
                        <th
                            mat-header-cell
                            *matHeaderCellDef
                            class="td-small"></th>
                        <td
                            mat-cell
                            *matCellDef="let integration"
                            class="td-small">
                            <button class="button button--small button--icon">
                                <i class="las la-cog"></i>
                            </button>
                        </td>
                    </ng-container>

                    <tr
                        mat-header-row
                        *matHeaderRowDef="displayedIntegrationColumns"></tr>

                    <tr
                        mat-row
                        *matRowDef="let row; columns: displayedIntegrationColumns"
                        (click)="openIntegrationDetails(row)"
                        [hidden]="!checkActiveModule(row)"></tr>
                </table>
            </div>
        </div>
    </app-content>

    <ng-template #loading>
        <app-loading-message></app-loading-message>
    </ng-template>
</app-app-holder>
