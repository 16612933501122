import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { ModuleEnum } from 'src/app/models/ModuleEnum';
import { Product } from 'src/app/models/Product';
import { ModuleService } from 'src/app/services/module.service';

import { ProductAddModalComponent } from '../product-add-modal/product-add-modal.component';
import { FetchProductCategories, FetchProducts } from '../product.actions';
import { ProductState } from '../product.state';

@Component({
    selector: 'app-products-overview',
    templateUrl: './products-overview.component.html',
    styleUrls: ['./products-overview.component.scss'],
})
export class ProductsOverviewComponent implements OnInit {
    @Select(ProductState.Products) allProducts$: Observable<Product[]>;
    @Select(ProductState.ProductCategories) productCategories$: Observable<string[]>;

    checkModule: any;
    eModule = ModuleEnum;
    isLoading = false;

    selectedCategory = null;

    constructor(
        private titleService: Title,
        private store: Store,
        private modalService: NgbModal,
        private moduleService: ModuleService,
        private logger: NGXLogger,
    ) {
        this.checkModule = this.moduleService.checkModuleActive;
        this.titleService.setTitle('Products overview');
    }

    ngOnInit(): void {
        this.logger.debug('Fetching products');
        this.store.dispatch(new FetchProducts());
        this.store.dispatch(new FetchProductCategories());
    }

    openAddProductModal() {
        this.logger.debug('Opening productAddModal');
        const modalRef = this.modalService.open(ProductAddModalComponent, {
            windowClass: 'modal-huge',
            animation: false,
        });

        modalRef.result.then(
            () => {
                this.isLoading = true;
                this.logger.debug('Fetching products');
                this.store.dispatch(new FetchProducts()).subscribe(() => (this.isLoading = false));
            },
            () => {
                this.isLoading = true;
                this.logger.debug('Fetching products');
                this.store.dispatch(new FetchProducts()).subscribe(() => (this.isLoading = false));
            },
        );
    }

    changeSelectedCategory($event) {
        this.selectedCategory = $event;
    }
}
