<app-app-holder>
    <app-navbar></app-navbar>

    <app-sidebar>
        <div class="sidebar-spacing">
            <h3>Financial reports</h3>
        </div>
        <span class="divider divider--small"></span>
        <app-financial-subnav></app-financial-subnav>
    </app-sidebar>

    <app-content>
        <router-outlet></router-outlet>
    </app-content>
</app-app-holder>
