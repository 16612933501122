<div class="graph__container">
    <div class="dashboard__tile__title graph__title">
        <span>{{ billableInformation?.fullName }}</span>

        <app-date-navigation
            [dateNavigationOptions]="dateNavigationOptions"
            [isCompact]="true"
            (selectedDateUpdated)="updateSelectedYear()">
        </app-date-navigation>
    </div>
    <div class="dashboard__tile__content graph__chart">
        <app-default-line-chart
            *ngIf="billableInformation.billablePercentage; else loading"
            [xLabels]="xLabels"
            [objectLabels]="labels"
            [data]="data"
            [legendPosition]="legendPosition"
            [ySuggestedMax]="1">
        </app-default-line-chart>

        <p>
            Average for {{ dateNavigationOptions.selectedDate | date: 'yyyy' }}: <b>{{ average | percent }}</b>
        </p>
    </div>
</div>
<ng-template #loading>
    <app-loading-message class="graph__loading"></app-loading-message>
</ng-template>
