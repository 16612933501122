<table
    [dataSource]="datasource"
    mat-table>
    <ng-container matColumnDef="file">
        <th
            *matHeaderCellDef
            mat-header-cell>
            File
        </th>
        <td
            *matCellDef="let linkedFile"
            mat-cell>
            <app-pill-invoice
                *ngIf="linkedFile.fileType.toLowerCase() !== 'uploadeddocument'; else pdfPill"
                [yukiDocumentId]="linkedFile.fileId"></app-pill-invoice>
            <ng-template #pdfPill>
                <app-pill-uploaded-document [uploadedDocumentId]="linkedFile.fileId"></app-pill-uploaded-document>
            </ng-template>
        </td>
        <td
            *matFooterCellDef
            mat-footer-cell></td>
    </ng-container>

    <ng-container matColumnDef="description">
        <th
            *matHeaderCellDef
            mat-header-cell>
            Description
        </th>
        <td
            *matCellDef="let linkedFile"
            mat-cell>
            {{ linkedFile.description?.length > 50 ? (linkedFile.description | slice: 0:50) + '...' : linkedFile.description }}
        </td>
        <td
            *matFooterCellDef
            mat-footer-cell></td>
    </ng-container>

    <ng-container matColumnDef="amount">
        <th
            *matHeaderCellDef
            mat-header-cell>
            Amount
        </th>
        <td
            *matCellDef="let linkedFile"
            mat-cell>
            {{ (linkedFile.folderId === 1 ? linkedFile.price * -1 : linkedFile.price) | currency: 'EUR' }}
        </td>
        <td
            *matFooterCellDef
            mat-footer-cell>
            {{ calculateTotal() | currency: 'EUR' }}
        </td>
    </ng-container>

    <ng-container matColumnDef="date">
        <th
            *matHeaderCellDef
            mat-header-cell>
            Date
        </th>
        <td
            *matCellDef="let linkedFile"
            mat-cell>
            {{ linkedFile.invoiceDate | date }}
        </td>
        <td
            *matFooterCellDef
            mat-footer-cell></td>
    </ng-container>

    <ng-container matColumnDef="invoiceNumber">
        <th
            *matHeaderCellDef
            mat-header-cell>
            Invoice number
        </th>
        <td
            *matCellDef="let linkedFile"
            mat-cell>
            {{ linkedFile.invoiceNumber }}
        </td>
        <td
            *matFooterCellDef
            mat-footer-cell></td>
    </ng-container>

    <ng-container matColumnDef="delete">
        <th
            *matHeaderCellDef
            class="td-small"
            mat-header-cell></th>
        <td
            *matCellDef="let linkedFile"
            class="td-icon"
            mat-cell>
            <button
                (click)="$event.stopPropagation(); deleteLink(linkedFile)"
                class="button button--icon button--small">
                <i class="las la-trash color--red"></i>
            </button>
        </td>
        <td
            *matFooterCellDef
            mat-footer-cell></td>
    </ng-container>

    <tr
        *matHeaderRowDef="displayedColumns"
        mat-header-row>
    </tr>
    <tr
        (click)="openInvoiceInformationModal(row)"
        *matRowDef="let row; columns: displayedColumns"
        class="pointer"
        mat-row>
    </tr>

    <tr *matNoDataRow>
        <td [attr.colspan]="displayedColumns.length" class="mat-cell">
            <ng-container
                *ngIf="linkedFilesToObjectWithId?.objectId === objectId && linkedFilesToObjectWithId$ | async; else loading">
                There are no files linked to this object...
            </ng-container>
        </td>
    </tr>

    <ng-container *ngIf="showInvoices && linkedFilesToObjectWithId?.linkedFiles?.length > 0">
        <tr
            *matFooterRowDef="displayedColumns"
            mat-footer-row></tr>
    </ng-container>

    <ng-container *ngIf="showFiles">
        <tr
            *matFooterRowDef="['add']"
            mat-footer-row></tr>
    </ng-container>

    <ng-container matColumnDef="add">
        <td
            (click)="openAddDocumentModal()"
            *matFooterCellDef
            class="table-view__add add-document-cell"
            colspan="3"
            mat-footer-cell
        >
            <i class="las la-plus"></i> Add document
        </td>
    </ng-container>
</table>


<ng-template #loading>
    <app-loading-message></app-loading-message>
</ng-template>
