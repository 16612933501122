import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subscription } from 'rxjs';
import { Employee } from 'src/app/models/Employee';
import { FetchRelatedEmployees } from 'src/app/related-employees/related.employees.action';
import { RelatedEmployeesState } from 'src/app/related-employees/related.employees.state';

import { AddEmployeeModalComponent } from './add-employee-modal/add-employee-modal.component';
import { DeleteEmployeeComponent } from './delete-employee-modal/delete-employee.component';

@Component({
    selector: 'app-related-employees-list',
    templateUrl: './related-employees-list.component.html',
    styleUrls: ['./related-employees-list.component.scss'],
})
export class RelatedEmployeesListComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Select(RelatedEmployeesState.RelatedEmployeeObjects)
    relatedEmployeeObjects$: Observable<any[]>;

    @Input() objectId: string;
    @Input() objectType: string;
    object: any = null;

    relatedEmployees: Employee[];
    isLoading = true;

    constructor(private store: Store, private modalService: NgbModal, public activeModal: NgbActiveModal, private logger: NGXLogger) {}

    ngOnInit(): void {
        this.subscriptions.add(
            this.relatedEmployeeObjects$.subscribe((objects) => {
                if (objects == null) {
                    this.logger.debug('Fetching allRelatedEmployees');
                    this.store.dispatch(new FetchRelatedEmployees());
                } else {
                    this.object = objects.find((o) => o.uuid == this.objectId);
                    this.relatedEmployees = this.object?.employees;
                    this.isLoading = false;
                }
            }),
        );
    }

    openAddEmployeeModal() {
        this.logger.debug('Opening addEmployeeModal');
        const modalRef = this.modalService.open(AddEmployeeModalComponent, {
            windowClass: 'modal-prompt',
        });
        modalRef.componentInstance.objectId = this.objectId;
        modalRef.componentInstance.relatedEmployees = this.relatedEmployees;
    }

    openEmployeeDeleteModal(employee: Employee) {
        this.logger.debug('Opening deleteEmployeeModal');
        const modalRef = this.modalService.open(DeleteEmployeeComponent, {
            windowClass: 'modal-prompt',
        });
        modalRef.componentInstance.objectId = this.objectId;
        modalRef.componentInstance.employee = employee;
        modalRef.componentInstance.relatedEmployees = this.relatedEmployees;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
