import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { CompanyState } from 'src/app/companies/company.state';
import { FetchContacts } from 'src/app/contacts/contacts.actions';
import { ContactState } from 'src/app/contacts/contacts.state';
import { Company } from 'src/app/models/Company';
import { ContactPerson } from 'src/app/models/ContactPerson';
import CrmDeal from 'src/app/models/CrmDeal';
import CrmDealReasonOfLoss from 'src/app/models/CrmDealReasonOfLoss';
import CrmStage from 'src/app/models/CrmStage';
import { Partner } from 'src/app/models/Partner';
import { Product } from 'src/app/models/Product';
import Project from 'src/app/models/Project';
import User from 'src/app/models/User';
import { FetchPartners } from 'src/app/partners/partners.actions';
import { PartnerState } from 'src/app/partners/partners.state';
import { FetchProducts } from 'src/app/products/product.actions';
import { ProductState } from 'src/app/products/product.state';
import { ProjectDetailModalComponent } from 'src/app/projects/project-detail-modal/project-detail-modal.component';
import { CustomRolesState } from 'src/app/settings/roles/custom.roles.state';
import { ConfirmationModalComponent } from 'src/app/shared/confirmation-modal/confirmation-modal.component';
import { UserState } from 'src/app/users/user.state';

import { AddCrmdealModalComponent } from '../add-crmdeal-modal/add-crmdeal-modal.component';
import { AddCrmstageModalComponent } from '../add-crmstage-modal/add-crmstage-modal.component';
import { FetchCrmDeals, FetchCrmStages, FetchReasons, MoveCrmDeal } from '../crm.actions';
import { CrmState } from '../crm.state';
import { CrmdealDetailModalComponent } from '../crmdeal-detail-modal/crmdeal-detail-modal.component';
import { LostDealReasonModalComponent } from '../lost-deal-reason-modal/lost-deal-reason-modal.component';
import { ManageCrmstagesModalComponent } from '../manage-crmstages-modal/manage-crmstages-modal.component';

@Component({
    selector: 'app-crm-overview',
    templateUrl: './crm-overview.component.html',
    styleUrls: ['./crm-overview.component.scss'],
})
export class CrmOverviewComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Select(CompanyState.company) company$: Observable<Company>;
    @Select(UserState.userInfo) user$: Observable<User>;
    @Select(PartnerState.getPartners) partners$: Observable<Partner[]>;
    @Select(ContactState.Contacts) contactPersons$: Observable<ContactPerson[]>;
    @Select(ProductState.Products) products$: Observable<Product[]>;
    @Select(CrmState.CrmStages) stages$: Observable<CrmStage[]>;
    @Select(CrmState.CrmDeals) allDeals$: Observable<CrmDeal[]>;
    @Select(CustomRolesState.AllowedActions) allowedActions$: Observable<any>;
    @Select(CrmState.Reasons) reasons$: Observable<CrmDealReasonOfLoss[]>;
    stages: CrmStage[] = null;
    isLoading = true;
    connectedTo = [];
    stopRecursiveness = false;
    isUserInput = false;

    user: User;
    company: Company;

    movementAllowed = false;
    filter: string;
    view = 'kanbanView';

    constructor(private titleService: Title, private toastr: ToastrService, private store: Store, private modalService: NgbModal, private logger: NGXLogger) {
        this.titleService.setTitle('CRM overview');
    }

    ngOnInit(): void {
        this.subscriptions.add(this.user$.subscribe((u) => (this.user = u)));
        this.subscriptions.add(this.company$.subscribe((c) => (this.company = c)));
        this.logger.debug('Fetching CrmStages');
        this.store.dispatch(new FetchCrmStages());

        this.logger.debug('Fetching Contacts');
        this.store.dispatch(new FetchContacts());

        this.logger.debug('Fetching Products');
        this.store.dispatch(new FetchProducts());

        this.logger.debug('Fetching CrmDeals');
        this.store.dispatch(new FetchCrmDeals());

        this.logger.debug('Fetching Partners');
        this.store.dispatch(new FetchPartners());

        this.logger.debug('Fetching ReasonsOfLoss');
        this.store.dispatch(new FetchReasons());

        this.logger.debug('Fetching CRM permissions');
        this.subscriptions.add(
            this.allowedActions$.subscribe((allowedActions) => {
                if (allowedActions['role'] == 'ROLE_SUPER_ADMIN' || allowedActions['role'] == 'ROLE_OWNER') {
                    this.movementAllowed = true;
                } else if (allowedActions['CRM']) {
                    if (allowedActions['CRM'].includes('EDIT')) {
                        this.movementAllowed = true;
                    }
                }
            }),
        );
    }

    getTotalValue(stage: CrmStage) {
        let total = 0;
        if (stage.crmDeals != null) {
            stage.crmDeals.forEach((deal) => {
                total += deal.price;
            });
        }
        return total;
    }

    drop(event: CdkDragDrop<string[]>) {
        this.isUserInput = true;
        if (!this.movementAllowed) {
            this.toastr.warning('You dont have the permission to move deals.');
        }
        if (event.previousContainer !== event.container && this.movementAllowed) {
            const deal = event.previousContainer.data[event.previousIndex] as unknown as CrmDeal;
            this.subscriptions.add(
                this.stages$.subscribe((stages) => {
                    stages.forEach((s) => {
                        if (s.uuid == event.container.id && this.isUserInput && !this.stopRecursiveness) {
                            if (s.type.toString() == 'LOSS') {
                                this.logger.debug('Opening lostDealReasonModalComponent');
                                const modalRef = this.modalService.open(LostDealReasonModalComponent, { windowClass: 'modal-prompt' });
                                modalRef.componentInstance.reasonEmitter.subscribe((body) => {
                                    this.logger.debug('Attempt to move crmDeal');
                                    this.store.dispatch(new MoveCrmDeal(s.uuid, deal.uuid, body.reason, body.uuid)).subscribe();
                                });
                            } else {
                                if (!this.stopRecursiveness) {
                                    if (s.type.toString() == 'WIN') {
                                        this.logger.debug('Opening wonDealConvertToProjectModal');
                                        const modalRef = this.modalService.open(ConfirmationModalComponent, { windowClass: 'modal-prompt' });
                                        modalRef.componentInstance.type = 'YesOrNo';
                                        modalRef.componentInstance.title = 'Won deal';
                                        modalRef.componentInstance.message =
                                            'Hooray! You won this deal, nice job! Do you want to convert it into a project? With a project, you can link employees, create a planning and much more.';
                                        modalRef.componentInstance.closeEvent.subscribe((res) => {
                                            if (res) {
                                                this.createProject(deal);
                                            }
                                        });
                                    }
                                    this.stopRecursiveness = true;
                                    this.logger.debug('Attempt to move crmDeal');
                                    this.store.dispatch(new MoveCrmDeal(s.uuid, deal.uuid, 'empty')).subscribe(() => {
                                        this.stopRecursiveness = false;
                                    });
                                }
                            }
                        }
                    });
                }),
            );
        }
        this.isUserInput = false;
    }

    isWarning(dateSinceLastMovement, rottingIn: number, type: string): boolean {
        if (type != 'WIN' && type != 'LOSS' && !this.isOverdue(dateSinceLastMovement, rottingIn, type)) {
            const date = new Date(dateSinceLastMovement);
            const now = new Date();
            return date.getDate() + rottingIn - 1 <= now.getDate();
        }
        return false;
    }

    isOverdue(dateSinceLastMovement, rottingIn: number, type: string): boolean {
        if (type != 'WIN' && type != 'LOSS') {
            const date = new Date(dateSinceLastMovement);
            const now = new Date();
            return date.getDate() + rottingIn <= now.getDate();
        }
        return false;
    }

    openAddStageModal() {
        this.logger.debug('Opening addCrmStageModal');
        const modalRef = this.modalService.open(AddCrmstageModalComponent, {
            windowClass: 'modal-prompt',
        });
    }

    openAddDealModal(stage) {
        this.logger.debug('Opening addCrmModal');
        const modalRef = this.modalService.open(AddCrmdealModalComponent, {
            windowClass: 'modal-pane',
            animation: false,
        });
        modalRef.componentInstance.stage = stage;
        this.subscriptions.add(
            this.partners$.subscribe((p) => {
                if (modalRef.componentInstance != undefined) {
                    modalRef.componentInstance.partners = [...p];
                }
            }),
        );
        this.subscriptions.add(
            this.contactPersons$.subscribe((c) => {
                if (modalRef.componentInstance != undefined) {
                    modalRef.componentInstance.contacts = [...c];
                }
            }),
        );
    }

    openDealDetailModal(deal) {
        this.logger.debug('Opening CrmDetailModal');
        const modalRef = this.modalService.open(CrmdealDetailModalComponent, {
            windowClass: 'modal-pane',
            animation: false,
        });
        this.subscriptions.add(
            this.allDeals$.subscribe((deals) => {
                deals.forEach((d) => {
                    if (deal.uuid === d.uuid) {
                        if (modalRef.componentInstance != undefined) {
                            modalRef.componentInstance.deal = d;
                            this.partners$.subscribe((p) => {
                                if (modalRef.componentInstance != undefined) {
                                    modalRef.componentInstance.partners = p;
                                }
                            });
                            this.contactPersons$.subscribe((c) => {
                                if (modalRef.componentInstance != undefined) {
                                    modalRef.componentInstance.contacts = c;
                                }
                            });
                        }
                    }
                });
            }),
        );
    }

    openManageStagesModal() {
        this.logger.debug('Opening ManageCrmStageModal');
        const modalRef = this.modalService.open(ManageCrmstagesModalComponent, {
            windowClass: 'modal-pane',
            animation: false,
        });
        this.subscriptions.add(
            this.stages$.subscribe((stages) => {
                if (modalRef.componentInstance != undefined) {
                    modalRef.componentInstance.stages = [...stages].sort((a, b) => {
                        return a.sequence - b.sequence;
                    });
                }
            }),
        );
    }

    createProject(deal: CrmDeal) {
        const project: Project = {
            uuid: null,
            startDate: Date(),
            endDate: null,
            present: false,
            billable: true,
            title: deal.title,
            projectType: null,
            pdfUpload: null,
            description: deal.description,
            userId: this.user.uuid,
            companyUuid: this.company.uuid,
            companyName: this.company.title,
            customerUuid: deal.customer ? deal.customer.uuid : null,
            customerFirstname: deal.customer ? deal.customer.firstName : '',
            customerLastname: deal.customer ? deal.customer.lastName : '',
            contactPersonUuid: deal.contactPerson ? deal.contactPerson.uuid : null,
            contactPersonFirstname: deal.contactPerson ? deal.contactPerson.firstName : '',
            contactPersonLastname: deal.contactPerson ? deal.contactPerson.lastName : '',
            partnerUuid: deal.partner ? deal.partner.uuid : '',
            partnerName: deal.partner ? deal.partner.title : '',
            location: {
                street: '',
                number: 0,
                bus: '',
                ext: 0,
                postal: '',
                city: '',
                country: { iso2: '' },
            },
            globalTags: [],
            finished: false,
            assignedResumes: [],
            products: [],
            linkedEmployees: [],
            budget: deal.price,
        };
        project.title = deal.title;
        this.logger.debug('Opening WorkDetailModal');
        const modalRef = this.modalService.open(ProjectDetailModalComponent, {
            windowClass: 'modal-pane',
            animation: false,
        });
        if (deal.partner) {
            project.partnerUuid = deal.partner.uuid;
            project.partnerName = deal.partner.title;
            modalRef.componentInstance.partnerUuidInput = deal.partner.uuid;
        }
        if (deal.products) {
            project.products = deal.products;
        }
        modalRef.componentInstance.project = project;
        modalRef.componentInstance.fromDeal = true;
    }

    switchView(type: string) {
        this.view = type;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
