<app-app-holder>
    <app-navbar></app-navbar>

    <app-sidebar>
        <app-comanage-subnav></app-comanage-subnav>
    </app-sidebar>

    <app-content>
        <app-comanage-not-logged-in (credentialsIsset)="checkCredentials($event)"></app-comanage-not-logged-in>

        <div class="app__title">
            <h1>
                CoManage Invoices
                <span *ngIf="this.dataSource?.data">({{ this.dataSource.data.length }})</span>
            </h1>
        </div>

        <app-searchbar
            [(value)]="filter"
            (valueChange)="applyFilter($event)"></app-searchbar>

        <div *ngIf="!this.dataSource?.data">
            <app-loading-message></app-loading-message>
        </div>

        <div>
            <div
                class="m-t-4"
                *appIsPermitted="{
                    module: 'COMANAGE',
                    operator: 'AND',
                    actions: ['READ']
                }">
                <div
                    class="table__overflow"
                    [style.display]="!this.dataSource?.data ? 'none' : 'block'">
                    <table
                        mat-table
                        [dataSource]="dataSource"
                        matSort
                        [hidden]="!this.dataSource?.data">
                        <ng-container matColumnDef="title">
                            <th
                                mat-header-cell
                                *matHeaderCellDef
                                class="td-small"
                                mat-sort-header>
                                Title
                            </th>
                            <td
                                mat-cell
                                *matCellDef="let row">
                                <span>{{ row.title }}</span>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="contact.name">
                            <th
                                mat-header-cell
                                *matHeaderCellDef
                                mat-sort-header>
                                Contact
                            </th>
                            <td
                                mat-cell
                                *matCellDef="let row">
                                <span>{{ row.contact?.name }}</span>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="totals.total">
                            <th
                                mat-header-cell
                                *matHeaderCellDef
                                mat-sort-header>
                                Amount
                            </th>
                            <td
                                mat-cell
                                *matCellDef="let row">
                                <span>{{ row.totals.total | currency: 'EUR' }}</span>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="due_date">
                            <th
                                mat-header-cell
                                *matHeaderCellDef
                                mat-sort-header>
                                Due
                            </th>
                            <td
                                mat-cell
                                *matCellDef="let row">
                                <span>{{ row.due_date | amDateFormat: 'DD/MM/YYYY' }}</span>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="status">
                            <th
                                mat-header-cell
                                *matHeaderCellDef
                                mat-sort-header>
                                Status
                            </th>
                            <td
                                mat-cell
                                *matCellDef="let row">
                                <span>{{ row.status }}</span>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="pdf">
                            <th
                                mat-header-cell
                                *matHeaderCellDef>
                                Pdf
                            </th>
                            <td
                                mat-cell
                                *matCellDef="let row">
                                <app-pill-pdf
                                    [url]="row.pdf"
                                    [fileName]="row.title"
                                    [type]="'comanage'"></app-pill-pdf>
                            </td>
                        </ng-container>

                        <tr
                            mat-header-row
                            *matHeaderRowDef="displayedColumns"></tr>
                        <tr
                            mat-row
                            *matRowDef="let row; columns: displayedColumns"></tr>

                        <tr *matNoDataRow>
                            <td colspan="6">No Invoices matching the filter "{{ filter }}"</td>
                        </tr>
                    </table>
                </div>
            </div>
            <mat-paginator
                [pageSizeOptions]="[15]"
                showFirstLastButtons></mat-paginator>
        </div>
    </app-content>
</app-app-holder>
