import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subscription } from 'rxjs';
import { Warehouse } from 'src/app/models/Warehouse';

import { WarehouseDeleteModalComponent } from '../warehouse-delete-modal/warehouse-delete-modal.component';
import { WarehouseUpdateModalComponent } from '../warehouse-update-modal/warehouse-update-modal.component';
import { FetchWarehouses } from '../warehouse.actions';
import { WarehouseState } from '../warehouse.state';

@Component({
    selector: 'app-warehouses-list',
    templateUrl: './warehouses-list.component.html',
    styleUrls: ['./warehouses-list.component.scss'],
})
export class WarehousesListComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Select(WarehouseState.Warehouses) allWarehouses$: Observable<Warehouse[]>;

    filter: string;
    displayedColumns: string[] = ['title', 'shortName', 'edit'];
    dataSource: MatTableDataSource<Warehouse>;
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    constructor(private modalService: NgbModal, private logger: NGXLogger, private store: Store) {}

    ngOnInit(): void {
        this.logger.debug('Fetching warehouses');
        this.store.dispatch(new FetchWarehouses());
        this.subscriptions.add(
            this.allWarehouses$.subscribe((allWarehouses) => {
                if (allWarehouses) {
                    this.dataSource = new MatTableDataSource(allWarehouses);
                    this.dataSource.sort = this.sort;
                }
            }),
        );
    }

    applyFilter(filterValue) {
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }

    openWarehouseUpdateModal(warehouse: Warehouse) {
        this.logger.debug('Opening warehouseUpdateModal');
        const modalRef = this.modalService.open(WarehouseUpdateModalComponent, {
            windowClass: 'modal-pane',
            animation: false,
        });
        modalRef.componentInstance.warehouse = warehouse;
    }

    openWarehouseDeleteModal(warehouse: Warehouse) {
        this.logger.debug('Opening warehouseDeleteModal');
        const modalRef = this.modalService.open(WarehouseDeleteModalComponent, {
            windowClass: 'modal-prompt',
        });
        modalRef.componentInstance.warehouse = warehouse;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
