import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';
import Lead from 'src/app/models/Lead';
import Mail from 'src/app/models/Mail';
import { ModuleEnum } from 'src/app/models/ModuleEnum';
import { ModuleService } from 'src/app/services/module.service';
import { environment } from 'src/environments/environment';

import { OutlookLoginService } from '../login-components/outlook-login/outlook-login.service';
import { GmailMailService } from './gmail-mail.service';
import { OutlookMailService } from './outlook-mail.service';

@Component({
    selector: 'app-mailing-list',
    templateUrl: './mailing-list.component.html',
    styleUrls: ['./mailing-list.component.scss'],
})
export class MailingListComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Input() lead: Lead;

    mails: Mail[];
    loginUrl: string = environment.frontEndBaseUrl + '/settings/module';
    eModule = ModuleEnum;

    loggedInWithMicrosoft: boolean;

    gmailEnabled: boolean;
    loggedInWithGmail: boolean;

    constructor(
        private outlookLoginService: OutlookLoginService,
        private outlookMailService: OutlookMailService,
        private gmailService: GmailMailService,
        private logger: NGXLogger,
        private moduleService: ModuleService,
    ) {}

    async ngOnInit() {
        this.mails = [];

        this.logger.debug('Checking logged in with Outlook');
        this.subscriptions.add(this.outlookLoginService.getLoggedIn().subscribe((result: boolean) => (this.loggedInWithMicrosoft = result)));
        if (this.moduleService.checkModuleActive(this.eModule.OUTLOOK) && this.loggedInWithMicrosoft) {
            this.logger.debug('Fetching outlook mails');
            this.getOutlookMails();
        }

        this.logger.debug('Checking logged in with google');
        if (this.moduleService.checkModuleActive(this.eModule.GOOGLE)) {
            this.subscriptions.add(
                this.gmailService.getIsLogedIn().subscribe((isLoggedIn) => {
                    this.loggedInWithGmail = isLoggedIn;
                    this.logger.debug('Fetching gmail mails');
                    this.getGmailMails();
                }),
            );
        }
    }

    getOutlookMails() {
        this.subscriptions.add(
            this.outlookMailService.getMailsForLead(this.lead).subscribe(
                (mails) => {
                    const sentmails: any[] = mails.value;
                    sentmails.forEach((mail) => {
                        const recipients: any[] = mail.toRecipients;
                        recipients.forEach((recipient) => {
                            if (recipient.emailAddress.address === this.lead.email) {
                                this.mails.push({ ...mail, type: 'OUTLOOK' });
                            }
                        });
                    });
                },
                (error) => this.logger.error('Failed to retrieve outlook mails'),
            ),
        );
    }

    getGmailMails() {
        this.subscriptions.add(
            this.gmailService.getCompanyGmailByReceiver(this.lead.email).subscribe(
                (mails) => {
                    this.mails.push(...mails);
                },
                (error) => this.logger.error('Failed to retrieve gmail mails'),
            ),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
