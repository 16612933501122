<div class="m-t-4">
    <div class="table__overflow">
        <table
            *ngIf="showProjects?.length > 0"
            mat-table
            [dataSource]="dataSource"
            matSort>
            <ng-container matColumnDef="eye">
                <th
                    width="1"
                    mat-header-cell
                    *matHeaderCellDef>
                    <i class="las la-eye"></i>
                </th>
                <td
                    mat-cell
                    *matCellDef="let row">
                    <span
                        *ngIf="row.finished"
                        class="tag tag--green"
                        >Finished</span
                    >
                    <span
                        *ngIf="!row.finished"
                        class="tag tag--blue"
                        >Ongoing</span
                    >
                </td>
            </ng-container>

            <ng-container matColumnDef="title">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header>
                    Jobtitle
                </th>
                <td
                    mat-cell
                    *matCellDef="let row">
                    <app-pill-project [project]="row"></app-pill-project>
                </td>
            </ng-container>

            <ng-container matColumnDef="endDate">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header>
                    Date
                </th>
                <td
                    mat-cell
                    *matCellDef="let row">
                    <div *ngIf="checkDates(row) && !row.finished">OVERDUE</div>
                    <div *ngIf="!checkDates(row) || row.finished">
                        {{ row.startDate | date }}
                        <span *ngIf="!row.present">- {{ row.endDate | date }}</span>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="creator">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header>
                    Creator
                </th>
                <td
                    mat-cell
                    *matCellDef="let row">
                    <app-pill-user
                        *ngIf="row.userId"
                        [userId]="row.userId"></app-pill-user>
                </td>
            </ng-container>

            <ng-container matColumnDef="edit">
                <th
                    mat-header-cell
                    *matHeaderCellDef></th>
                <td
                    mat-cell
                    *matCellDef="let row">
                    <span
                        ngbDropdown
                        *appIsPermitted="{
                            module: 'PROJECTS',
                            operator: 'OR',
                            actions: ['EDIT', 'DELETE']
                        }">
                        <button
                            ngbDropdownToggle
                            class="button button--icon button--dropdown button--small m-l-1"></button>
                        <span
                            ngbDropdownMenu
                            class="dropdown-menu dropdown-menu-right"
                            aria-labelledby="dropdownMenuButton">
                            <a
                                class="dropdown-item"
                                *appIsPermitted="{
                                    module: 'PROJECTS',
                                    operator: 'AND',
                                    actions: ['EDIT']
                                }"
                                (click)="toggleFinishedProject(row)">
                                <div *ngIf="row.finished; else elseDropDownBlock">Mark as Ongoing</div>
                                <ng-template #elseDropDownBlock>Mark as Finished</ng-template>
                            </a>
                            <a
                                class="dropdown-item"
                                *appIsPermitted="{
                                    module: 'PROJECTS',
                                    operator: 'AND',
                                    actions: ['EDIT']
                                }"
                                (click)="openProjectUpdateModal(row)"
                                >Edit project</a
                            >
                            <a
                                class="dropdown-item color--red"
                                *appIsPermitted="{
                                    module: 'PROJECTS',
                                    operator: 'AND',
                                    actions: ['DELETE']
                                }"
                                (click)="openProjectDeleteModal(row)"
                                >Archive</a
                            >
                        </span>
                    </span>
                </td>
            </ng-container>

            <tr
                mat-header-row
                *matHeaderRowDef="displayedColumns"></tr>
            <tr
                mat-row
                *matRowDef="let row; columns: displayedColumns"></tr>
        </table>

        <app-message-empty
            *ngIf="showProjects?.length === 0"
            [title]="'No Projects..'"
            [subtitle]="'Whoops, you haven\'t added any project yet. Start now by clicking the button below.'"
            [icon]="'../../assets/img/open-box.png'">
            <a
                (click)="openAddProjectModal()"
                *appIsPermitted="{
                    module: 'PROJECTS',
                    operator: 'AND',
                    actions: ['CREATE']
                }"
                class="button button--primary"
                >Add project</a
            >
        </app-message-empty>
    </div>
</div>
