<form
    [formGroup]="dealForm"
    (ngSubmit)="addDeal($event)"
    class="modal-wrapper">
    <div class="modal-header">
        <h2
            class="modal-title"
            id="exampleModalLabel">
            New deal
        </h2>
        <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="activeModal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <div class="row">
            <div class="col">
                <div class="form-group m-b-2 required">
                    <label for="title">Title</label>
                    <small
                        class="form-error"
                        *ngIf="!dealForm.get('title').valid && dealForm.get('title').touched"
                        >Please enter a title</small
                    >
                    <input
                        id="title"
                        formControlName="title"
                        type="text"
                        placeholder="Enter a deal title" />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="form-group m-b-2 required">
                    <label for="price">Price</label>
                    <small
                        class="form-error"
                        *ngIf="!dealForm.get('price').valid && dealForm.get('price').touched"
                        >Please enter price</small
                    >
                    <input
                        id="price"
                        formControlName="price"
                        type="number"
                        placeholder="Enter the price" />
                </div>
            </div>
        </div>
        <div
            class="row"
            *ngIf="this.checkModuleActive('PARTNERS')">
            <div class="col">
                <app-global-autocomplete
                    [parentForm]="dealForm"
                    [formControlName]="'company'"
                    [data]="partners"
                    [itemType]="'company'"
                    [label]="'Company'"
                    [notFoundMessage]="'Add a company'"
                    [keyword]="partnerKeyword"
                    [placeholder]="'Choose a company'"
                    [active]="this.checkModuleActive('PARTNERS')"
                    [required]="false"></app-global-autocomplete>
            </div>
        </div>
        <div
            class="row"
            *ngIf="this.checkModuleActive('CONTACTS')">
            <div class="col">
                <app-global-autocomplete
                    [parentForm]="dealForm"
                    [formControlName]="'contactPerson'"
                    [data]="filteredContacts"
                    [itemType]="'contact'"
                    [label]="'Contactperson'"
                    [notFoundMessage]="'Add a contact'"
                    [keyword]="contactKeyword"
                    [placeholder]="'Choose a contact'"
                    [active]="this.checkModuleActive('CONTACTS')"
                    [required]="false"></app-global-autocomplete>
            </div>
        </div>

        <div
            class="row"
            *ngIf="this.checkModuleActive('CUSTOMERS')">
            <div class="col">
                <app-global-autocomplete
                    [parentForm]="dealForm"
                    [formControlName]="'customer'"
                    [itemType]="'customer'"
                    [data]="customers$ | async"
                    [label]="'Customer'"
                    [notFoundMessage]="'Add a customer'"
                    [keyword]="customerKeyword"
                    [placeholder]="'Choose a customer'"
                    [active]="this.checkModuleActive('CUSTOMERS')"
                    [required]="false"></app-global-autocomplete>
            </div>
        </div>
        <div
            class="row"
            *ngIf="this.checkModuleActive('COMANAGE') && (keyExists$ | async)">
            <div class="col">
                <app-global-autocomplete
                    [parentForm]="dealForm"
                    [formControlName]="'comanageOffer'"
                    [data]="offers"
                    [label]="'Comanage Offer'"
                    [notFoundMessage]="'No offers found'"
                    [keyword]="'title'"
                    [placeholder]="'Choose a comanage offer'"
                    [active]="this.checkModuleActive('COMANAGE')"
                    [required]="false"></app-global-autocomplete>
            </div>
        </div>
        <div class="form-group m-b-2">
            <label>Tags</label>
            <app-global-tags [type]="'deal'"></app-global-tags>
        </div>

        <div class="row">
            <div class="col">
                <div class="form-group m-b-2">
                    <label>Description</label>
                    <textarea
                        class="resize-vertical"
                        formControlName="description"
                        (keyup)="onDescriptionKeyUp()"
                        trim="blur"></textarea>
                    <small> {{ currentAmountOfExtraInfoCharacters }}/{{ descriptionMaxLength }} </small>
                    <span
                        class="form-error"
                        *ngIf="descriptionTooLong"
                        >Extra info cannot contain more than 1000 characters
                    </span>
                </div>
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <input
            class="button"
            [ngClass]="!dealForm.valid ? 'button--disabled' : 'button--primary'"
            type="submit"
            value="Create deal"
            [disabled]="!dealForm.valid" />
        <button
            class="button button--outline"
            (click)="activeModal.dismiss()">
            Cancel
        </button>
    </div>
</form>
