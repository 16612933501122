<form
    [formGroup]="productFormGroup"
    (ngSubmit)="updateProduct()"
    class="modal-wrapper">
    <div class="modal-header">
        <h2
            class="modal-title"
            id="exampleModalLabel">
            Update stock
        </h2>
        <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="activeModal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col">
                <div class="form-group m-b-2">
                    <label>Product</label>
                    <input
                        type="text"
                        placeholder="Title"
                        formControlName="title"
                        trim="blur"
                        #focussed
                        readonly />
                </div>
            </div>
            <div class="col">
                <div class="form-group m-b-2 required">
                    <app-global-autocomplete
                        [parentForm]="productFormGroup"
                        [formControlName]="'employees'"
                        [data]="allEmployees$ | async"
                        [itemType]="'employee'"
                        [label]="'Link an employee'"
                        [notFoundMessage]="'Add an employee'"
                        [keyword]="'firstName'"
                        [placeholder]="'Choose employee'"
                        [active]="this.moduleService.checkModuleActive(eModule.EMPLOYEES)"
                        [required]="true"></app-global-autocomplete>
                </div>
            </div>
            <div class="col">
                <div class="form-group m-b-2 required">
                    <label>Items in stock</label>
                    <input
                        type="number"
                        placeholder="Current stock"
                        formControlName="inStock"
                        (focus)="hideErrorMessage()"
                        trim="blur" />
                    <span
                        class="form-error stockChange"
                        *ngIf="product.inStock !== productFormGroup.controls.inStock.value"
                        >Current stock:{{ product.inStock }}</span
                    >
                    <span
                        class="form-error"
                        *ngIf="productFormGroup.controls.inStock.errors && inputIsWrong"
                        >Invalid amount</span
                    >
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="form-group m-b-2 required">
                    <label>Stock change information</label>
                    <textarea
                        class="resize-vertical"
                        formControlName="reasonInfo"
                        placeholder="Reason for stock change"
                        (keyup)="onDescriptionKeyUp()"
                        trim="blur"></textarea>
                    <small
                        ><span *ngIf="currentAmountOfDescriptionCharacters == null">0</span>{{ currentAmountOfDescriptionCharacters }} /{{
                            DESCRIPTION_MAX_LENGTH
                        }}</small
                    >
                    <span
                        class="form-error"
                        *ngIf="descriptionIsTooLong"
                        >Message cannot contain more than 1000 characters</span
                    >
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <span
                    class="form-error"
                    *ngIf="updateProductWentWrong"
                    >{{ errorMessage }}</span
                >
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <button
            type="button"
            class="button button--outline"
            (click)="activeModal.close()">
            Close
        </button>
        <button
            type="submit"
            [ngClass]="{ 'button--loading': loading }"
            class="button button--primary">
            Update
        </button>
    </div>
</form>
