import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subscription } from 'rxjs';
import { FetchCustomers } from 'src/app/customers/customers.actions';
import { CustomerState } from 'src/app/customers/customers.state';
import Customer from 'src/app/models/Customer';

@Component({
    selector: 'app-dashboard-customers-widget',
    templateUrl: './dashboard-customers-widget.component.html',
    styleUrls: ['./dashboard-customers-widget.component.scss'],
})
export class DashboardCustomersWidgetComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Select(CustomerState.Customers) customers$: Observable<Customer[]>;

    amountToDisplay = 10;
    isLoading = true;
    noCustomers = false;

    displayedColumns: string[] = ['customer', 'createdOn', 'creator'];
    dataSource: MatTableDataSource<Customer>;
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    constructor(private store: Store, private modalService: NgbModal, private router: Router, private logger: NGXLogger) {}

    ngOnInit(): void {
        this.subscriptions.add(
            this.customers$.subscribe((customers) => {
                if (customers != null && customers.length == 0) {
                    this.noCustomers = true;
                    this.isLoading = false;
                } else if (customers != null && customers.length > 0) {
                    const latestCustomers = JSON.parse(JSON.stringify(customers))
                        .sort((a, b) => {
                            return new Date(a.createdOn).getTime() - new Date(b.createdOn).getTime();
                        })
                        .slice(-this.amountToDisplay);
                    this.dataSource = new MatTableDataSource(latestCustomers);
                    this.dataSource.sort = this.sort;
                    this.isLoading = false;
                } else {
                    this.store.dispatch(new FetchCustomers());
                }
            }),
        );
    }

    goToCustomers() {
        this.logger.debug('redirecting to customers');
        this.router.navigate(['/', 'customers', 'overview']);
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
