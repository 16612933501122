<div class="modal-header">
    <h2
        class="modal-title"
        id="exampleModalLabel">
        Edit happiness score
    </h2>
    <span></span>
    <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <div class="d-flex space-between">
        <span *ngFor="let emoji of emojis">
            <input
                [(ngModel)]="happinessScore"
                type="radio"
                [id]="emoji.score"
                [value]="emoji.score"
                class="radio-btn"
                name="happiness" />
            <label
                [for]="emoji.score"
                class="radio-label--icon radio-label"
                >{{ emoji.emoji }}</label
            >
        </span>
    </div>
    <span
        *ngIf="happinessScore === null || happinessScore === undefined"
        class="form-error"
        >No happiness score provided yet...</span
    >
</div>

<div class="modal-footer">
    <input
        type="button"
        class="button button--primary"
        value="Update"
        (click)="updateHappinessScore()" />
    <button
        class="button button--outline"
        (click)="activeModal.dismiss()">
        Cancel
    </button>
</div>
