import { Component, EventEmitter, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import LaneModel, { LaneType } from 'src/app/models/LaneModel';

@Component({
    selector: 'app-add-lane-modal',
    templateUrl: './add-lane-modal.component.html',
    styleUrls: ['./add-lane-modal.component.scss'],
})
export class AddLaneModalComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Input() lane: LaneModel;
    public emitObject: EventEmitter<LaneModel> = new EventEmitter();

    laneForm: FormGroup;
    descriptionTooLong = false;
    currentAmountOfExtraInfoCharacters = 0;
    descriptionMaxLength = 1000;

    constructor(private store: Store, private fb: FormBuilder, public activeModal: NgbActiveModal, private toastr: ToastrService, private logger: NGXLogger) {}

    ngOnInit(): void {
        if (this.lane != undefined) {
            this.laneForm = this.fb.group({
                name: [this.lane.name, [Validators.required]],
                probability: [this.lane.probability, [Validators.required, Validators.max(100), Validators.min(0)]],
                rottingIn: [this.lane.rottingIn, [Validators.required, Validators.min(0)]],
                type: [this.lane.type, [Validators.required]],
                description: [this.lane.description, [Validators.maxLength(this.descriptionMaxLength)]],
                color: [this.lane.color],
            });
        } else {
            this.laneForm = this.fb.group({
                name: ['', [Validators.required]],
                probability: ['', [Validators.required, Validators.max(100), Validators.min(0)]],
                rottingIn: ['', [Validators.required, Validators.min(0)]],
                type: [LaneType.NORMAL, [Validators.required]],
                description: ['', [Validators.maxLength(this.descriptionMaxLength)]],
                color: ['#ffffff'],
            });
        }
    }

    public get laneType(): typeof LaneType {
        return LaneType;
    }

    addLane($event) {
        let lane: LaneModel;
        if (this.lane != undefined) {
            lane = { ...this.lane };
            lane.name = this.laneForm.get('name').value;
            lane.probability = this.laneForm.get('probability').value;
            lane.rottingIn = this.laneForm.get('rottingIn').value;
            lane.type = this.laneForm.get('type').value;
            lane.description = this.laneForm.get('description').value;
            lane.color = this.laneForm.get('color').value;
        } else {
            lane = {
                uuid: null,
                name: this.laneForm.get('name').value,
                probability: this.laneForm.get('probability').value,
                rottingIn: this.laneForm.get('rottingIn').value,
                sequence: null,
                type: this.laneForm.get('type').value,
                description: this.laneForm.get('description').value,
                color: this.laneForm.get('color').value,
            };
        }

        this.emitObject.emit(lane);
        this.activeModal.close();
    }

    onDescriptionKeyUp() {
        this.descriptionTooLong = false;
        this.currentAmountOfExtraInfoCharacters = this.laneForm.controls.description.value.length;
        if (this.currentAmountOfExtraInfoCharacters > this.descriptionMaxLength) {
            this.descriptionTooLong = true;
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
