<form
    [formGroup]="countryFormGroup"
    (ngSubmit)="editCountry()"
    class="modal-wrapper">
    <div class="modal-header">
        <h2
            class="modal-title"
            id="exampleModalLabel">
            Edit {{ this.country.countryName }}
        </h2>
        <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="activeModal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <div class="form-group m-b-2 required">
            <label>Country name</label>
            <input
                type="text"
                placeholder="Country name"
                formControlName="countryName"
                trim="blur"
                #focussed />
            <span
                class="form-error"
                *ngIf="countryFormGroup.controls.countryName.touched && countryFormGroup.controls.countryName?.errors"
                >Country name is required</span
            >
        </div>

        <div class="form-group m-b-2 required">
            <label>Country Code</label>
            <input
                type="text"
                placeholder="Country code"
                formControlName="countryCode"
                trim="blur"
                #focussed />
            <span
                class="form-error"
                *ngIf="countryFormGroup.controls.countryCode.touched && countryFormGroup.controls.countryCode?.errors"
                >Country code is required</span
            >
        </div>
    </div>

    <div class="modal-footer">
        <button
            type="button"
            class="button button--outline"
            (click)="activeModal.close()">
            Close
        </button>
        <button
            type="submit"
            class="button button--primary"
            [disabled]="!countryFormGroup.valid">
            Edit country
        </button>
    </div>
</form>
