import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LeadDetailComponent } from 'src/app/leads/lead-detail/lead-detail.component';
import Lead from 'src/app/models/Lead';

@Component({
    selector: 'app-pill-lead',
    templateUrl: './pill-lead.component.html',
    styleUrls: ['./pill-lead.component.scss'],
})
export class PillLeadComponent implements OnInit {
    @Input() lead: Lead;
    @Input() leadUuid: string;
    @Input() title: string;
    @Input() archived = false;

    constructor(private modalService: NgbModal) {}

    ngOnInit(): void {}

    openLeadDetailComponent() {
        const modalRef = this.modalService.open(LeadDetailComponent, {
            windowClass: 'modal-pane',
            animation: false,
        });
        modalRef.componentInstance.leadUuid = this.lead ? this.lead.uuid : this.leadUuid;
        modalRef.componentInstance.archived = this.archived;
    }

    getTitle(): string {
        return this.lead ? this.lead.firstName + ' ' + this.lead.lastName : this.title;
    }
}
