export class FetchComments {
    static readonly type = '[Comment] Fetch Comments';
    constructor(public objectUuid: string) {}
}

export class FetchLatestComments {
    static readonly type = '[Comment] FetchLatest Comments';
}

export class AddComment {
    static readonly type = '[Comment] Add Comment';
    constructor(public objectUuid: string, public content: string) {}
}

export class UpdateComment {
    static readonly type = '[Comment] Update Comment';
    constructor(public objectId: string, public commentUuid: string, public content: string) {}
}

export class DeleteComment {
    static readonly type = '[Comment] Delete Comment';
    constructor(public objectId: string, public commentUuid: string) {}
}
