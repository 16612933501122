<div>
    <div class="dashboard__tile__title">
        <span>Anniversaries: next 30 days</span>
    </div>
    <div
        class="dashboard__tile__content"
        *ngIf="!isLoading">
        <div class="table__overflow">
            <table
                mat-table
                [dataSource]="dataSource"
                matSort
                [hidden]="!(this.dataSource?.data?.length > 0)"
                matSortActive="daysUntilBirthday"
                matSortDirection="asc">
                <ng-container matColumnDef="firstName">
                    <th
                        mat-header-cell
                        *matHeaderCellDef
                        mat-sort-header>
                        The lucky one
                    </th>
                    <td
                        mat-cell
                        *matCellDef="let row">
                        <app-pill-employee [employee]="row"></app-pill-employee>
                    </td>
                </ng-container>

                <ng-container matColumnDef="age">
                    <th
                        mat-header-cell
                        *matHeaderCellDef>
                        Age
                    </th>
                    <td
                        mat-cell
                        *matCellDef="let row">
                        <span>{{ getEmployeeAge(row) }}</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="daysUntilBirthday">
                    <th
                        mat-header-cell
                        *matHeaderCellDef
                        mat-sort-header>
                        Birth date
                    </th>
                    <td
                        mat-cell
                        *matCellDef="let row">
                        <span *ngIf="row.daysUntilBirthday !== 0">{{ row.birthday | amDateFormat: 'MMMM Do' }}</span>
                        <span *ngIf="row.daysUntilBirthday === 0">Today!</span>
                    </td>
                </ng-container>

                <tr
                    mat-header-row
                    *matHeaderRowDef="displayedColumns"></tr>
                <tr
                    mat-row
                    *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
        </div>
    </div>

    <div
        class="dashboard__tile__content"
        *ngIf="this.noBirthdays && !isLoading">
        <app-message-empty
            [title]="'No upcoming anniversaries in the next 30 days...'"
            [icon]="'../../assets/img/open-box.png'"></app-message-empty>
    </div>

    <div *ngIf="isLoading">
        <app-loading-message></app-loading-message>
    </div>
</div>
