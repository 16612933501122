<app-app-holder>
    <app-navbar></app-navbar>

    <app-sidebar>
        <app-teamleader-subnav></app-teamleader-subnav>
    </app-sidebar>

    <app-content>
        <app-teamleader-not-logged-in (credentialsIsset)="checkCredentials($event)"></app-teamleader-not-logged-in>

        <div *ngIf="isset">
            <div class="app__title">
                <h1>Companies Overview</h1>
            </div>

            <app-searchbar [(value)]="filter"></app-searchbar>

            <div *ngIf="this.isLoading">
                <app-loading-message></app-loading-message>
            </div>

            <div
                class="m-t-4"
                *ngIf="!this.isLoading">
                <table
                    class="table-view"
                    *appIsPermitted="{
                        module: 'TEAMLEADER',
                        operator: 'AND',
                        actions: ['READ']
                    }">
                    <thead>
                        <th>Name</th>
                        <th>Address</th>
                        <th>Emails</th>
                        <th>Open with Teamleader</th>
                    </thead>
                    <tbody>
                        <tr *ngFor="let company of this.companies | filterBy: ['name']:filter">
                            <td>{{ company.name }}</td>
                            <td>
                                <p>{{ company.primary_address.line_1 }}</p>
                                <p>
                                    {{ company.primary_address.postal_code }},
                                    {{ company.primary_address.city }}
                                    {{ company.primary_address.country }}
                                </p>
                            </td>
                            <td>
                                <p *ngFor="let email of company.emails">
                                    {{ email.email }}
                                </p>
                            </td>
                            <td>
                                <a [href]="company.web_url">View {{ company.name }}</a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </app-content>
</app-app-holder>
