<form
    (ngSubmit)="updateYukiSettings()"
    [formGroup]="yukiSettingsForm">
    <div class="modal-header">
        <h2
            class="modal-title"
            id="exampleModalLabel">
            Update synchronizing start date
        </h2>
        <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="activeModal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <input
            type="date"
            formControlName="syncStartDate" />
    </div>

    <div class="modal-footer">
        <input
            class="button button--primary"
            type="submit"
            value="Update" />
        <button
            type="button"
            class="button button--outline"
            (click)="activeModal.dismiss()">
            Cancel
        </button>
    </div>
</form>
