import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';
import { ClearCompany } from 'src/app/companies/company.actions';
import { UserChangePasswordModalComponent } from 'src/app/users/user-change-password-modal/user-change-password-modal.component';
import { UserManageTwoFactorModalComponent } from 'src/app/users/user-manage-two-factor-modal/user-manage-two-factor-modal.component';
import { UserUpdateModalComponent } from 'src/app/users/user-update-modal/user-update-modal.component';
import { ClearStore, Logout } from 'src/app/users/user.actions';

import { ClearCustomRolesState } from '../roles/custom.roles.actions';

@Component({
    selector: 'app-account-settings',
    templateUrl: './account-settings.component.html',
    styleUrls: ['./account-settings.component.scss'],
})
export class AccountSettingsComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    constructor(private modalService: NgbModal, private store: Store, private router: Router, private logger: NGXLogger) {}

    ngOnInit(): void {}

    openUpdateUserModal() {
        this.logger.debug('Opening userUpdateModal');
        this.modalService.open(UserUpdateModalComponent, {
            windowClass: 'modal-pane',
            animation: false,
        });
    }

    openChangePasswordModal() {
        this.logger.debug('Opening userChangePasswordModal');
        this.modalService.open(UserChangePasswordModalComponent, {
            windowClass: 'modal-pane',
            animation: false,
        });
    }
    openManageTwoFactorAuth() {
        this.logger.debug('Opening manageTwoFactorAuthModal');
        this.modalService.open(UserManageTwoFactorModalComponent, {
            windowClass: 'modal-pane',
            animation: false,
        });
    }

    logout() {
        this.logger.debug('Attempt to logout');
        this.subscriptions.add(
            this.store.dispatch(new Logout()).subscribe(() => {
                localStorage.removeItem('token');
                this.logger.debug('Logout succesful');
                this.logger.debug('Clearing store');
                this.store.dispatch(new ClearCustomRolesState());
                this.store.dispatch(new ClearCompany()).subscribe(() => {
                    this.store.dispatch(new ClearStore()).subscribe(() => {
                        this.logger.debug('Navigating to login');

                        this.router.navigate(['/login']);
                    });
                });
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
