import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-percentage-progress',
    templateUrl: './percentage-progress.component.html',
    styleUrls: ['./percentage-progress.component.scss'],
})
export class PercentageProgressComponent {
    @Input() percentage: number;
    @Input() showPercentage: boolean;
}
