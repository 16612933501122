import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import CronjobInfo from 'src/app/models/CronjobInfo';
import { ConfirmationModalComponent } from 'src/app/shared/confirmation-modal/confirmation-modal.component';

import { AddCronjobRuleModalComponent } from '../add-cronjob-rule-modal/add-cronjob-rule-modal.component';
import { TimesheetsService } from '../timesheets.service';

@Component({
    selector: 'app-cronjob-rules-settings-modal',
    templateUrl: './cronjob-rules-settings-modal.component.html',
    styleUrls: ['./cronjob-rules-settings-modal.component.scss'],
})
export class CronjobRulesSettingsModalComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();
    cronjobInfo: CronjobInfo[];

    constructor(
        private toastr: ToastrService,
        private modalService: NgbModal,
        private timesheetsService: TimesheetsService,
        public activeModal: NgbActiveModal,
        private logger: NGXLogger,
    ) {}

    ngOnInit(): void {
        this.subscriptions.add(
            this.timesheetsService.getReminderRule().subscribe((c) => {
                this.cronjobInfo = c;
            }),
        );
    }

    addCronjob() {
        this.logger.debug('Opening addCronjobRuleModal');
        const modalRef = this.modalService.open(AddCronjobRuleModalComponent, {
            windowClass: 'modal-prompt',
        });
        this.subscriptions.add(
            modalRef.componentInstance.closeEvent.subscribe((res) => {
                if (res != 1000) {
                    this.logger.debug('Attempt to add reminderRule');
                    this.timesheetsService.addReminderRule(res).subscribe(
                        (cron) => {
                            if (cron == null) {
                                this.logger.debug('Reminder already exists');
                                this.toastr.info('This reminder already exists.');
                            } else {
                                this.logger.debug('Succesfully added reminder');
                                this.cronjobInfo.push(cron);
                                this.toastr.success('Reminder successfully added.');
                            }
                        },
                        (err) => {
                            this.logger.debug('Failed to add reminder');
                            this.toastr.error('Oops, something went wrong. Please try again later.');
                        },
                    );
                }
            }),
        );
    }

    delCronjob(cron: CronjobInfo) {
        this.logger.debug('Opening confirmationModal');
        const modalRef = this.modalService.open(ConfirmationModalComponent, {
            windowClass: 'modal-prompt',
        });
        modalRef.componentInstance.type = 'Delete';
        modalRef.componentInstance.title = 'Remove reminder';
        modalRef.componentInstance.message = 'Are you sure you want to remove this reminder?';
        this.subscriptions.add(
            modalRef.componentInstance.closeEvent.subscribe((val) => {
                if (val) {
                    this.logger.debug('Attempt to delete Reminder');
                    this.timesheetsService.deleteReminderRule(cron).subscribe(
                        (res) => {
                            if (res) {
                                this.cronjobInfo = this.cronjobInfo.filter((c) => c != cron);
                                this.logger.debug('Succesfully deleted reminder');
                                this.toastr.success('Reminder successfully removed.');
                            }
                        },
                        (err) => {
                            this.logger.error('Failed to delete reminder');
                            this.toastr.error('Oops, something went wrong. Please try again later.');
                        },
                    );
                }
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
