import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subscription } from 'rxjs';
import { AddMemberModalComponent } from 'src/app/companies/add-member-modal/add-member-modal.component';
import { CompanyDeleteModalComponent } from 'src/app/companies/company-delete-modal/company-delete-modal.component';
import { CompanyDetailModalComponent } from 'src/app/companies/company-detail-modal/company-detail-modal.component';
import { CompanyState } from 'src/app/companies/company.state';
import { Company } from 'src/app/models/Company';
import { CompanyMember } from 'src/app/models/CompanyMember';
import { Module } from 'src/app/models/Module';
import { RoleEnum } from 'src/app/models/RoleEnum';
import { ModuleState } from 'src/app/modules/module.state';
import { UserState } from 'src/app/users/user.state';

@Component({
    selector: 'app-company-settings',
    templateUrl: './company-settings.component.html',
    styleUrls: ['./company-settings.component.scss'],
})
export class CompanySettingsComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Select(CompanyState.company) company$: Observable<Company>;
    @Select(ModuleState.Modules) modules$: Observable<Module[]>;
    @Select(UserState.hasUserOwnerRole) isOwner$: Observable<boolean>;

    constructor(private modalService: NgbModal, private store: Store, private logger: NGXLogger) {}

    displayedModules: Module[];
    sessionModules: string[];
    company: Company;
    member: CompanyMember;
    eRole = RoleEnum;

    isLoading = true;

    ngOnInit(): void {
        this.subscriptions.add(
            this.company$.subscribe((c) => {
                this.company = c;
            }),
        );

        this.subscriptions.add(
            this.store.selectOnce(CompanyState.companyMembers).subscribe((members) => {
                this.store.selectOnce(UserState.userInfo).subscribe((user) => {
                    this.member = members.find((member) => member.userUuid === user.uuid);
                });
            }),
        );
    }

    openUpdateModal() {
        this.logger.debug('Opening companyDetailModal');
        this.modalService.open(CompanyDetailModalComponent);
    }

    openDeleteModal() {
        this.logger.debug('Opening companyDeleteModal');
        this.modalService.open(CompanyDeleteModalComponent, {
            windowClass: 'modal-prompt',
        });
    }

    openAddMemberModal() {
        this.logger.debug('Opening addMemberModal');
        this.modalService.open(AddMemberModalComponent);
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
