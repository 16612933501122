import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { CalendarOptions } from '@fullcalendar/angular';
import { Calendar } from '@fullcalendar/core';
import listPlugin from '@fullcalendar/list';
import timeGridPlugin from '@fullcalendar/timegrid';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import * as moment from 'moment';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subscription } from 'rxjs';
import { GetCompanyMembers } from 'src/app/companies/company.actions';
import { CompanyState } from 'src/app/companies/company.state';
import CalendarEvent from 'src/app/models/CalendarEvent';
import { Company } from 'src/app/models/Company';
import { CompanyMember } from 'src/app/models/CompanyMember';
import User from 'src/app/models/User';
import { OutlookLoginService } from 'src/app/shared/login-components/outlook-login/outlook-login.service';
import { UserState } from 'src/app/users/user.state';

import { CalendarEventDetailModalComponent } from '../calendar-event-detail-modal/calendar-event-detail-modal.component';
import { CalendarService } from '../calendar.service';
import { GoogleCalendarService } from '../google-calendar/google-calendar.service';
import { OutlookCalendarService } from '../outlook-calendar.service';

@Component({
    selector: 'app-calendar-overview',
    templateUrl: './calendar-overview.component.html',
    styleUrls: ['./calendar-overview.component.scss'],
})
export class CalendarOverviewComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();
    @Select(CompanyState.company) company$: Observable<Company>;
    @Select(UserState.hasUserOwnerRole) isOwner$: Observable<boolean>;
    @Select(UserState.userInfo) currUser$: Observable<User>;
    currMember: CompanyMember = {
        email: '',
        firstName: '',
        lastName: '',
        uuid: '',
        userUuid: '',
        role: '',
    };
    members: CompanyMember[];
    company: Company;
    googleCalendarEnabled: boolean;
    selectedMember: CompanyMember;

    loggedInWithMicrosoft: boolean;
    loggedInWithGmail: boolean;

    googleCalendarEvents: CalendarEvent[] = [];
    outlookCalendarEvents: any[] = [];
    hardcodedEvents: CalendarEvent[] = [
        {
            title: 'test event 1',
            start: '2021-05-12T13:00:00.000+02:00',
            end: '2021-05-12T14:00:00.000+02:00',
            body: 'TEST EVENT NR1',
            location: null,
            type: 'TESTDATA',
        },
        {
            title: 'test event 2',
            start: '2021-05-07T13:00:00.000+02:00',
            end: '2021-05-07T17:00:00.000+02:00',
            body: 'TEST EVENT NR2',
            location: null,
            type: 'TESTDATA',
        },
        {
            title: 'test event 3',
            start: '2021-05-11T08:00:00.000+02:00',
            end: '2021-05-11T10:00:00.000+02:00',
            body: 'TEST EVENT NR3',
            location: null,
            type: 'TESTDATA',
        },
    ];

    allEvents: CalendarEvent[] = [];

    calendarOptions: CalendarOptions = {
        plugins: [listPlugin, timeGridPlugin],
        initialView: 'dayGridMonth',
        eventClick: this.handleEventClick.bind(this),
        weekends: true,
        selectable: true,
        editable: true,
        headerToolbar: {
            left: 'prev,next today',
            center: 'title',
            right: 'dayGridMonth,dayGridWeek,dayGridDay',
        },
        footerToolbar: {
            left: 'listDay,listWeek,listMonth,listYear',
            right: 'timeGridDay,timeGridWeek',
        },
    };

    constructor(
        private modalService: NgbModal,
        private outlookLoginService: OutlookLoginService,
        private outlookCalendarService: OutlookCalendarService,
        private googleCalendarService: GoogleCalendarService,
        private calendarService: CalendarService,
        private store: Store,
        private titleService: Title,
        private logger: NGXLogger,
    ) {
        this.titleService.setTitle('Calendar overview');
        const name = Calendar.name;
    }

    ngOnInit(): void {
        this.subscriptions.add(
            this.googleCalendarService.getIsLoggedIn().subscribe((loggedIn) => {
                if (loggedIn) {
                    this.allEvents = this.allEvents.concat(this.hardcodedEvents);
                    if (loggedIn) {
                        this.logger.debug('Attempt to fetch Google calendar-events');
                        this.loggedInWithGmail = true;
                        this.fetchGoogleEvents();
                    } else {
                        this.logger.debug('Fetching Google calendar-events failed, not logged in');
                    }
                }
            }),
        );
        this.subscriptions.add(
            this.outlookLoginService.getLoggedIn().subscribe((loggedIn) => {
                if (loggedIn) {
                    this.logger.debug('Attempt to fetch Outlook calendar-events');
                    this.loggedInWithMicrosoft = true;
                    this.fetchOutlookEvents();
                } else {
                    this.logger.debug('Fetching Outlook calendar-events failed, not logged in');
                }
            }),
        );

        this.subscriptions.add(this.calendarService.getLogedInMembers().subscribe((m) => (this.members = m)));
        this.subscriptions.add(
            this.currUser$.subscribe((u) => {
                this.currMember.email = u.email;
                this.currMember.firstName = u.firstName;
                this.currMember.lastName = u.lastName;
                this.currMember.userUuid = u.uuid;
                this.selectMember(this.currMember);
            }),
        );
        this.subscriptions.add(
            this.company$.subscribe((c) => {
                this.company = c;
            }),
        );

        this.subscriptions.add(this.calendarService.getGoogleCalendarIsEnabled().subscribe((e) => (this.googleCalendarEnabled = e)));

        this.setupCalendar();
    }

    getMembersWithoutCurrUser(): CompanyMember[] {
        if (this.members != undefined) {
            return this.members.filter((el) => el.userUuid != this.currMember.userUuid);
        }
        return [];
    }

    selectMember(member: CompanyMember) {
        this.selectedMember = member;
    }

    handleEventClick(arg) {
        this.logger.debug('opening CalendarEventDetailModalComponent');
        const modalRef = this.modalService.open(CalendarEventDetailModalComponent, { windowClass: 'modal-pane', animation: false });
        modalRef.componentInstance.calendarEvent = arg.event;
    }

    toggleWeekends() {
        this.calendarOptions.weekends = !this.calendarOptions.weekends;
    }

    fetchGoogleEvents() {
        this.subscriptions.add(
            this.currUser$.subscribe((user) => {
                this.googleCalendarService.getCalendarByUserId(user.uuid).subscribe(
                    (resultGoogle) => {
                        this.logger.debug('Fetched Google calendar-events');
                        this.googleCalendarEvents = resultGoogle;
                        this.allEvents = this.allEvents.concat(resultGoogle);
                        this.setupCalendar();
                    },
                    (error) => this.logger.error('Failed to retrieve Google calendar-events'),
                );
            }),
        );
    }

    fetchOutlookEvents() {
        const startOfMonth = moment().clone().startOf('month').format('YYYY-MM-DD hh:mm');
        const endOfNextMonth = moment().add(1, 'month').clone().endOf('month').format('YYYY-MM-DD hh:mm');
        this.subscriptions.add(
            this.outlookCalendarService.getCalendarEventsForWeek(startOfMonth, endOfNextMonth).subscribe((resultOutlook) => {
                this.logger.debug('Fetched Outlook calendar-events');
                resultOutlook.value.forEach(
                    (event) => {
                        this.outlookCalendarEvents.push({
                            title: event.subject,
                            start: event.start.dateTime,
                            end: event.end.dateTime,
                            body: event.bodyPreview,
                            location: event.location.address.street
                                ? event.location.address.street +
                                  ' ' +
                                  event.location.address.city +
                                  ' ' +
                                  event.location.address.state +
                                  ' ' +
                                  event.location.address.countryOrRegion
                                : null,
                            type: 'OUTLOOK',
                        });
                    },
                    (error) => this.logger.error('Failed to retrieve Outlook calendar-events'),
                );
                this.allEvents = this.allEvents.concat(this.outlookCalendarEvents);
                this.setupCalendar();
            }),
        );
    }

    setupCalendar() {
        this.calendarOptions.events = this.allEvents;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
