<app-app-holder>
    <app-navbar></app-navbar>
    <app-content>
        <div>
            <div class="app__title">
                <h1>
                    Employees
                    <span *ngIf="employees$ | async as employees">({{ employees.length }})</span>
                </h1>
                <span>
                    <a (click)="openAddEmployeesModal()"
                        *appIsPermitted="{
                            module: 'EMPLOYEES',
                            operator: 'AND',
                            actions: ['CREATE']
                        }"
                        class="button button--primary">
                        New employee
                    </a>
                </span>
            </div>
            <div *appIsPermitted="{
                    module: 'EMPLOYEES',
                    operator: 'AND',
                    actions: ['READ']
                }">
                <nav #nav="ngbNav" ngbNav>
                    <ng-container ngbNavItem>
                        <a ngbNavLink>Active</a>
                        <ng-template ngbNavContent>
                            <ng-container *ngIf="employees$ | async as employees; else loading">
                                <app-employees-list *ngIf="employees && employees.length > 0; else empty"></app-employees-list>
                            </ng-container>
                        </ng-template>
                    </ng-container>

                    <ng-container ngbNavItem>
                        <a ngbNavLink>Archived</a>
                        <ng-template ngbNavContent>
                            <ng-container *ngIf="employees$ | async as employees; else loading">
                                <app-employees-list *ngIf="employees && employees.length > 0; else empty" [archived]="true"></app-employees-list>
                            </ng-container>
                        </ng-template>
                    </ng-container>
                </nav>

                <div [ngbNavOutlet]="nav"></div>

            </div>
        </div>
    </app-content>
</app-app-holder>

<ng-template #loading>
    <app-loading-message></app-loading-message>
</ng-template>

<ng-template #empty>
    <app-message-empty
        [icon]="'../../assets/img/open-box.png'"
        [subtitle]="'Employees are valuable people of your company.'"
        [title]="'No employees...'">
        <a
            (click)="openAddEmployeesModal()"
            class="button button--primary"
            >Add an employee</a
        >
    </app-message-empty>
</ng-template>
