import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { NgbDropdownModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { TagInputModule } from 'ngx-chips';
import { OrderModule } from 'ngx-order-pipe';
import { NgPipesModule } from 'ngx-pipes';

import { ProductsModule } from '../products/products.module';
import { SharedModule } from '../shared/shared.module';
import { WarehouseLocationDetailModalComponent } from './warehouse-location-detail-modal/warehouse-location-detail-modal.component';
import { WarehouseLocationsAddModalComponent } from './warehouse-locations-add-modal/warehouse-locations-add-modal.component';
import { WarehouseLocationsDeleteModalComponent } from './warehouse-locations-delete-modal/warehouse-locations-delete-modal.component';
import { WarehouseLocationsListComponent } from './warehouse-locations-list/warehouse-locations-list.component';
import { WarehouseLocationsOverviewComponent } from './warehouse-locations-overview/warehouse-locations-overview.component';
import { WarehouseLocationsUpdateModalComponent } from './warehouse-locations-update-modal/warehouse-locations-update-modal.component';
import { routing } from './warehouse.locations.routing';

@NgModule({
    declarations: [
        WarehouseLocationsOverviewComponent,
        WarehouseLocationsAddModalComponent,
        WarehouseLocationsUpdateModalComponent,
        WarehouseLocationsDeleteModalComponent,
        WarehouseLocationDetailModalComponent,
        WarehouseLocationsListComponent,
    ],
    imports: [
        CommonModule,
        SharedModule,
        AutocompleteLibModule,
        routing,
        NgbModule,
        NgbDropdownModule,
        NgPipesModule,
        OrderModule,
        TagInputModule,
        ProductsModule,
        CdkTableModule,
        MatTableModule,
        MatSortModule,
    ],
    exports: [WarehouseLocationsAddModalComponent],
})
export class WarehouseLocationsModule {}
