import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';

import { OverheadCategory } from '../../../models/OverheadCategory';
import { OverheadCategoryDetailModalComponent } from '../../../yuki/overhead-category-detail-modal/overhead-category-detail-modal.component';
import { GetAllOverheadCategories } from '../../../yuki/yuki.actions';
import { YukiState } from '../../../yuki/yuki.state';

@Component({
    selector: 'app-pill-overhead-category',
    templateUrl: './pill-overhead-category.component.html',
    styleUrls: ['./pill-overhead-category.component.scss'],
})
export class PillOverheadCategoryComponent implements OnInit, OnDestroy {
    @Select(YukiState.overheadCategoryById) overheadCategory$: Observable<(id: string) => OverheadCategory>;
    @Input() overheadCategory: OverheadCategory;
    @Input() overheadCategoryId: string;
    @Input() onFinancialPage = false;
    private subscriptions = new Subscription();

    constructor(private modalService: NgbModal, private store: Store) {}

    ngOnInit(): void {
        if (this.overheadCategoryId) {
            this.store.dispatch(new GetAllOverheadCategories());

            this.subscriptions.add(
                this.overheadCategory$.subscribe((overheadCategory) => {
                    this.overheadCategory = overheadCategory(this.overheadCategoryId);
                }),
            );
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    openOverheadCategoryDetailModal() {
        const modalRef = this.modalService.open(OverheadCategoryDetailModalComponent, { windowClass: 'modal-pane', animation: false });
        modalRef.componentInstance.overheadCategoryId = this.overheadCategory.id;
        modalRef.componentInstance.openFinancialTab = this.onFinancialPage;
    }
}
