<app-searchbar [(value)]="filter"></app-searchbar>

<div class="m-t-4">
    <div></div>
    <div
        class="list__item list__item--small pointer"
        *ngFor="let resume of resumes | filterBy: ['jobTitle', 'firstName', 'lastName']:filter"
        [class.selected]="inSelectedResumes(resume)">
        <span (click)="onSelect(resume)">
            <div class="list__item__select">
                <input
                    type="checkbox"
                    [disabled]="inSelectedResumes(resume)"
                    [checked]="inSelectedResumes(resume)" />
            </div>
            <div class="list__item__content">
                <strong>{{ resume.firstName }} {{ resume.lastName }}</strong>
                <strong>{{ resume.jobTitle }}</strong>
            </div>
        </span>
        <div class="list__item__extra">
            <ng-template #tipTags
                ><span *ngFor="let skill of resume.skills">{{ skill.title }} </span></ng-template
            >
            <a
                *ngIf="resume.skills.length"
                class="m-l-2 button button--small button--icon button--outline"
                placement="top"
                [ngbTooltip]="tipTags">
                <i class="uil uil-tag"></i>
            </a>
            <button (click)="previewResume(resume)">Preview</button>
        </div>
    </div>
</div>
