import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { ChartConfiguration } from 'chart.js';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';
import FinancialDetailInfoViewModel from 'src/app/models/FinancialDetailInfoViewModel';
import { externalTooltipHandler } from 'src/app/shared/utils/customtooltip-utils';

import { MONTHS } from '../../models/MONTHS';
import Project from '../../models/Project';
import { CalendarClickEventData, LegendPosition } from '../../shared/charts/default-line-chart/default-line-chart.component';
import { DateNavigationOptions, DateNavigationType, SelectedDateClass } from '../../shared/date-navigation/date-navigation.component';
import { roundNumber } from '../../shared/utils/number-utils';
import { capitalizeFirstLetter } from '../../shared/utils/string-utils';
import { ChartInformationModalComponent, ChartInformationObjectType } from '../chart-information-modal/chart-information-modal.component';
import { DATASETS } from '../employee-graph/employee-graph.component';
import { FetchCostsForProject, FetchIncomeForProject } from '../financial.actions';
import { FinancialState } from '../financial.state';

@Component({
    selector: 'app-project-graph',
    templateUrl: './project-graph.component.html',
    styleUrls: ['./project-graph.component.scss'],
})
export class ProjectGraphComponent implements OnInit, OnDestroy {
    @Input() projectId: string;
    @Input() project: Project;

    data: any[] = undefined;
    labels: string[] = [];
    xLabels: string[];
    legendPosition: LegendPosition = LegendPosition.BOTTOM;
    options: ChartConfiguration['options'];
    incomeForProject: FinancialDetailInfoViewModel;
    costsForProject: FinancialDetailInfoViewModel;

    isLoading = false;
    selectedDate: Date = new Date();
    dateNavigationOptions: DateNavigationOptions;

    private subscriptions = new Subscription();

    constructor(public store: Store, private logger: NGXLogger, private modalService: NgbModal) {}

    ngOnInit(): void {
        this.dateNavigationOptions = {
            dateNavigationType: DateNavigationType.YEAR,
            selectedDate: this.selectedDate,
            selectedDateClass: SelectedDateClass.SMALL,
        };

        this.fetchData();
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    seedLabels() {
        return Object.values(MONTHS).map((label) => capitalizeFirstLetter(label.short));
    }

    updateSelectedDate(): void {
        this.isLoading = true;
        let isIncomeLoading = true;
        let isCostsLoading = true;
        this.subscriptions.add(
            this.store.dispatch(new FetchIncomeForProject(this.projectId, this.dateNavigationOptions.selectedDate.getFullYear())).subscribe({
                next: () => {
                    isIncomeLoading = false;
                    if (!isCostsLoading) this.isLoading = false;
                },
            }),
        );
        this.subscriptions.add(
            this.store.dispatch(new FetchCostsForProject(this.projectId, this.dateNavigationOptions.selectedDate.getFullYear())).subscribe({
                next: () => {
                    isCostsLoading = false;
                    if (!isIncomeLoading) this.isLoading = false;
                },
            }),
        );
    }

    calculateTotalMarginForSelectedYearInPct(): number {
        const totalIncome: number = this.incomeForProject.financialData
            .slice(0, new Date().getMonth())
            .reduce((total: number, financialData: number) => total + financialData, 0);

        const totalCost: number = this.costsForProject.financialData
            .slice(0, new Date().getMonth())
            .reduce((total: number, financialData: number) => total + financialData, 0);

        if (totalIncome === 0 || totalCost === 0) return NaN;

        const totalMargin: number = (totalIncome - totalCost) / totalCost;
        return roundNumber(totalMargin, 4);
    }

    calculateTotalCostForSelectedYear(): number {
        return this.costsForProject.financialData.slice(0, new Date().getMonth()).reduce((total: number, financialData: number) => total + financialData, 0);
    }

    calculateTotalIncomeForSelectedYear(): number {
        return this.incomeForProject.financialData.slice(0, new Date().getMonth()).reduce((total: number, financialData: number) => total + financialData, 0);
    }

    calculateTotalMarginForSelectedYear(): number {
        const totalIncome: number = this.incomeForProject.financialData
            .slice(0, new Date().getMonth())
            .reduce((total: number, financialData: number) => total + financialData, 0);

        const totalCost: number = this.costsForProject.financialData
            .slice(0, new Date().getMonth())
            .reduce((total: number, financialData: number) => total + financialData, 0);

        return totalIncome - totalCost;
    }

    displayInfo($event: CalendarClickEventData) {
        const modalRef = this.modalService.open(ChartInformationModalComponent, {
            windowClass: 'modal-prompt modal-prompt--min-content',
            animation: false,
        });
        modalRef.componentInstance.month = $event.month;
        modalRef.componentInstance.year = this.dateNavigationOptions.selectedDate.getFullYear();
        modalRef.componentInstance.dataset = DATASETS.findDataset($event.datasetIndex);
        modalRef.componentInstance.objectId = this.projectId;
        modalRef.componentInstance.title = `${this.project.title}`;
        modalRef.componentInstance.chartInformationObjectType = ChartInformationObjectType.PROJECT;
    }

    private fetchData() {
        this.xLabels = this.seedLabels();

        this.subscriptions.add(
            this.store.select(FinancialState.incomeForProject(this.projectId)).subscribe((income) => {
                if (income?.financialData.length !== 12 || income.detailId !== this.projectId) {
                    this.store.dispatch(new FetchIncomeForProject(this.projectId));
                    return;
                }

                this.incomeForProject = income;

                if (this.costsForProject?.financialData.length === 12) this.createChart();
            }),
        );

        this.subscriptions.add(
            this.store.select(FinancialState.costsForProject(this.projectId)).subscribe((costs) => {
                if (costs?.financialData.length !== 12 || costs.detailId !== this.projectId) {
                    this.store.dispatch(new FetchCostsForProject(this.projectId));
                    return;
                }

                this.costsForProject = costs;

                if (this.incomeForProject?.financialData.length === 12) this.createChart();
            }),
        );
    }

    private createChart() {
        const margin: number[] = [];
        const marginPercentage: number[] = [];

        for (let i = 0; i < this.costsForProject.financialData.length; i++) {
            margin.push(this.incomeForProject.financialData[i] - this.costsForProject.financialData[i]);
            marginPercentage.push((this.incomeForProject.financialData[i] - this.costsForProject.financialData[i]) / this.incomeForProject.financialData[i]);
        }

        let lastMonth: number = new Date().getMonth();
        if (this.dateNavigationOptions.selectedDate.getFullYear() !== new Date().getFullYear()) {
            lastMonth = 12;
        }

        this.xLabels = this.seedLabels();
        for (let i = 0; i < this.xLabels.length; i++) {
            if (!isNaN(marginPercentage[i]) && Number.isFinite(marginPercentage[i])) {
                this.xLabels[i] += `: ${roundNumber(marginPercentage[i] * 100)}%`;
            }
        }

        this.data = [
            this.costsForProject.financialData.slice(0, lastMonth),
            this.incomeForProject.financialData.slice(0, lastMonth),
            margin.slice(0, lastMonth),
        ];
        this.labels = ['Cost', 'Income', 'Margin'];

        this.options = {
            plugins: {
                tooltip: {
                    enabled: false,
                    position: 'nearest',
                    external: externalTooltipHandler,
                },
            },
        };
    }
}
