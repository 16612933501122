<app-app-holder>
    <app-navbar></app-navbar>

    <app-sidebar>
        <app-teamleader-subnav></app-teamleader-subnav>
    </app-sidebar>

    <app-content>
        <app-teamleader-not-logged-in (credentialsIsset)="checkCredentials($event)"></app-teamleader-not-logged-in>

        <div *ngIf="isset">
            <div class="app__title">
                <h1>Calendar Overview</h1>
                <button
                    (click)="openAddCalendarMeetingModal()"
                    *appIsPermitted="{
                        module: 'TEAMLEADER',
                        operator: 'AND',
                        actions: ['CREATE']
                    }"
                    class="button button--primary w-20">
                    Add Meeting
                </button>
                <button
                    (click)="openAddCalendarTaskModal()"
                    *appIsPermitted="{
                        module: 'TEAMLEADER',
                        operator: 'AND',
                        actions: ['CREATE']
                    }"
                    class="button button--primary w-20">
                    Add Task
                </button>
                <button
                    (click)="openAddCalendarCallModal()"
                    *appIsPermitted="{
                        module: 'TEAMLEADER',
                        operator: 'AND',
                        actions: ['CREATE']
                    }"
                    class="button button--primary w-20">
                    Add Call
                </button>
            </div>

            <app-searchbar [(value)]="filter"></app-searchbar>

            <div *ngIf="this.isLoading">
                <app-loading-message></app-loading-message>
            </div>

            <div *ngIf="!this.isLoading">
                <div
                    *appIsPermitted="{
                        module: 'TEAMLEADER',
                        operator: 'AND',
                        actions: ['READ']
                    }">
                    <div
                        class="calendar"
                        *ngFor="let event of calendarEvents$ | async | orderBy: 'starts_at':false:true | filterBy: ['title']:filter">
                        <div>
                            <div class="list__item__image">
                                <img
                                    src="../../../assets/img/teamleader.png"
                                    width="50"
                                    height="50" />
                            </div>
                            <div class="list__item__content">
                                <h3>{{ event.title }}</h3>
                                <p class="format-newlines">
                                    {{ event.description }}
                                </p>
                                <small>{{ event.starts_at | date: 'medium' }} - {{ event.ends_at | date: 'medium' }}</small>
                                <small>{{ event.location }}</small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </app-content>
</app-app-holder>
