import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

import { AuthenticationService } from '../../../authentication/authentication.service';

@Injectable({
    providedIn: 'root',
})
export class KeyService {
    httpOptions = {};

    constructor(private httpClient: HttpClient, private authenticationService: AuthenticationService) {
        this.httpOptions = authenticationService.getHeaders();
    }

    checkIfApiKeyExists() {
        return this.httpClient.get<any>(environment.baseUrl + '/mailchimp/keyExists', this.httpOptions);
    }
}
