<div *ngIf="isLoading">
    <app-loading-message></app-loading-message>
</div>
<div *ngIf="!isLoading">
    <table class="table-view">
        <tr>
            <th>Document</th>
            <th class="td-small"></th>
        </tr>

        <tr *ngFor="let document of documents">
            <td>
                <app-pill-document [document]="document"></app-pill-document>
            </td>
            <td>
                <span ngbDropdown>
                    <button
                        ngbDropdownToggle
                        class="button button--icon button--dropdown button--small"></button>
                    <span
                        ngbDropdownMenu
                        class="dropdown-menu dropdown-menu-right"
                        aria-labelledby="dropdownMenuButton">
                        <a
                            class="dropdown-item color--red"
                            (click)="remove(document.uuid, $event)"
                            >Remove document</a
                        >
                    </span>
                </span>
            </td>
        </tr>
        <tr>
            <td
                colspan="3"
                class="table-view__add"
                (click)="openAddModal()">
                <i class="las la-plus"></i> Link new document
            </td>
        </tr>
    </table>
</div>
