import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { ChartConfiguration } from 'chart.js';
import { Subscription } from 'rxjs';

import { MONTHS } from '../../models/MONTHS';
import { OverheadCategoryWithFinancialData } from '../../models/OverheadCategoryWithFinancialData';
import { CalendarClickEventData, LegendPosition } from '../../shared/charts/default-line-chart/default-line-chart.component';
import { DateNavigationOptions, DateNavigationType, SelectedDateClass } from '../../shared/date-navigation/date-navigation.component';
import { capitalizeFirstLetter } from '../../shared/utils/string-utils';
import { ChartInformationModalComponent, ChartInformationObjectType } from '../chart-information-modal/chart-information-modal.component';
import { DATASETS } from '../employee-graph/employee-graph.component';
import { FetchFinancialDataForOverheadCategoryByCategoryId } from '../financial.actions';
import { FinancialState } from '../financial.state';

@Component({
    selector: 'app-overhead-graph-widget',
    templateUrl: './overhead-graph-widget.component.html',
    styleUrls: ['./overhead-graph-widget.component.scss'],
})
export class OverheadGraphWidgetComponent implements OnInit, OnDestroy {
    @Input() categoryId: string;

    overheadCategoryWithFinancialData: OverheadCategoryWithFinancialData;

    data: any[] = undefined;
    labels: string[] = [];
    xLabels: string[];
    legendPosition: LegendPosition = LegendPosition.BOTTOM;
    options: ChartConfiguration['options'];

    dateNavigationOptions: DateNavigationOptions;

    average: number;
    total: number;

    private subscriptions = new Subscription();

    constructor(private store: Store, private modalService: NgbModal) {}

    ngOnInit(): void {
        this.dateNavigationOptions = {
            dateNavigationType: DateNavigationType.YEAR,
            selectedDate: new Date(),
            selectedDateClass: SelectedDateClass.SMALL,
        };

        this.fetchData();
        this.createChart();
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    updateSelectedYear(): void {
        this.store.dispatch(new FetchFinancialDataForOverheadCategoryByCategoryId(this.categoryId, this.dateNavigationOptions.selectedDate.getFullYear()));
    }

    displayInfo($event: CalendarClickEventData) {
        const modalRef = this.modalService.open(ChartInformationModalComponent, {
            windowClass: 'modal-prompt modal-prompt--min-content',
            animation: false,
        });
        modalRef.componentInstance.month = $event.month;
        modalRef.componentInstance.year = this.dateNavigationOptions.selectedDate.getFullYear();
        modalRef.componentInstance.dataset = DATASETS.findDataset($event.datasetIndex);
        modalRef.componentInstance.objectId = this.overheadCategoryWithFinancialData.categoryId;
        modalRef.componentInstance.title = `${this.overheadCategoryWithFinancialData.categoryName}`;
        modalRef.componentInstance.chartInformationObjectType = ChartInformationObjectType.CATEGORY;
    }

    private createChart() {
        if (!this.overheadCategoryWithFinancialData) return;

        this.xLabels = this.seedLabels();
        this.data = [this.overheadCategoryWithFinancialData.financialData];
        this.labels = ['Cost'];

        this.options = {};
    }

    private seedLabels() {
        return Object.values(MONTHS).map((label) => capitalizeFirstLetter(label.short));
    }

    private fetchData() {
        this.subscriptions.add(
            this.store.select(FinancialState.overheadCategoryByIdWithFinancialData(this.categoryId)).subscribe((overheadCategoryWithFinancialData) => {
                this.overheadCategoryWithFinancialData = overheadCategoryWithFinancialData;
                this.createChart();
                this.calculateTotalAndAverage();
            }),
        );
    }

    private calculateTotalAndAverage() {
        this.total = this.overheadCategoryWithFinancialData?.financialData.reduce((total, data) => total + data, 0);

        if (this.dateNavigationOptions.selectedDate.getFullYear() === new Date().getFullYear()) {
            const monthsPassed = this.dateNavigationOptions.selectedDate.getMonth() + 1;
            this.average = this.total / monthsPassed;
        } else {
            this.average = this.total / 12;
        }
    }
}
