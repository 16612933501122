import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { NgbDropdownModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgChartsModule } from 'ng2-charts';

import { ResumesModule } from '../resumes/resumes.module';
import { SharedModule } from '../shared/shared.module';
import { WidgetsModule } from '../widgets/widgets.module';
import { routing } from './dashboard.routing';
import { DashboardComponent } from './dashboard/dashboard.component';
import { MfaNotEnabledPopupComponent } from './mfanot-enabled-popup/mfa-not-enabled-popup.component';

@NgModule({
    declarations: [DashboardComponent, MfaNotEnabledPopupComponent],
    imports: [CommonModule, SharedModule, NgChartsModule, WidgetsModule, FormsModule, routing, NgbModule, NgbDropdownModule, ResumesModule, CKEditorModule],
})
export class DashboardModule {}
