<div class="modal-header">
    <h2
        class="modal-title"
        id="exampleModalLabel">
        Share resume of {{ resume.lastName }} {{ resume.firstName }}
    </h2>
    <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <div *ngIf="(contactPersons$ | async)?.length != 0">
        <app-resume-share-contactperson-list
            *ngIf="contactPersons$ | async; else loading"
            [contactPersons]="contactPersons$ | async"
            [showContactPersons]="contactPersons$ | async"></app-resume-share-contactperson-list>

        <div class="m-t-4">
            <small class="color--grey"
                >When you share a resume, we'll send an e-mail with the resumé attached. If they have a Resumo-account, they'll also find it in their resumé
                overview. Handy!</small
            >
        </div>
    </div>
    <app-message-empty
        *ngIf="(contactPersons$ | async)?.length == 0"
        [title]="'You have no contactpersons.'"
        [subtitle]="'Connect contactpersons with companies to get started.'"
        [icon]="'../../assets/img/open-box.png'">
    </app-message-empty>
</div>

<ng-template #loading>
    <app-loading-message></app-loading-message>
</ng-template>

<div class="modal-footer">
    <button
        type="button"
        class="button button--outline"
        (click)="activeModal.close()">
        Close
    </button>
    <button
        type="button"
        class="button button--primary"
        (click)="shareResume()">
        Share
    </button>
</div>
