import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { HttpErrorInterceptor } from './services/interceptors/httpErrorInterceptor';
import { IsAllowedInterceptor } from './services/interceptors/isAllowedInterceptor';
import { RemoveFinancialDataInterceptor } from './services/interceptors/remove-financial-data.interceptor';

export const interceptorProviders = [
    { provide: HTTP_INTERCEPTORS, useClass: IsAllowedInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: RemoveFinancialDataInterceptor, multi: true },
];
