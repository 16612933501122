import { Component, Input, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';
import Lead from 'src/app/models/Lead';

import { ArchiveLead } from '../lead.actions';

@Component({
    selector: 'app-lead-delete-modal',
    templateUrl: './lead-delete-modal.component.html',
    styleUrls: ['./lead-delete-modal.component.scss'],
})
export class LeadDeleteModalComponent implements OnDestroy {
    private subscriptions = new Subscription();

    @Input() lead: Lead;
    @Input() archived: boolean;

    constructor(private store: Store, public activeModal: NgbActiveModal) {}

    archiveLead() {
        this.subscriptions.add(this.store.dispatch(new ArchiveLead(this.lead, this.archived)).subscribe());
        this.activeModal.close();
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
