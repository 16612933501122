import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';

import { BUDGETREPEAT } from '../../models/BUDGETREPEAT';
import BudgetTemplate from '../../models/BudgetTemplate';
import { AddBudgetTemplate, UpdateBudgetTemplate } from '../budget.actions';

@Component({
    selector: 'app-add-budgettemplate-modal',
    templateUrl: './add-budgettemplate-modal.component.html',
    styleUrls: ['./add-budgettemplate-modal.component.scss'],
})
export class AddBudgettemplateModalComponent implements OnInit, OnDestroy {
    @Input() budgetTemplate: BudgetTemplate;
    @Input() type: string;
    budgetTemplateForm: FormGroup;
    budgetRepeats: BUDGETREPEAT[] = [];
    private subscriptions = new Subscription();

    constructor(public activeModal: NgbActiveModal, private fb: FormBuilder, private store: Store, private toastr: ToastrService, private logger: NGXLogger) {}

    ngOnInit(): void {
        this.createBudgetTemplateForm();
        this.seedRecurrenceRules();
    }

    addBudgetTemplate($event) {
        const leadTime = `${String(this.budgetTemplateForm.get('leadTimeYears').value).padStart(2, '0')}-${String(
            this.budgetTemplateForm.get('leadTimeMonths').value,
        ).padStart(2, '0')}-${String(this.budgetTemplateForm.get('leadTimeDays').value).padStart(2, '0')}`;
        const timeBeforeNotification = `${String(this.budgetTemplateForm.get('notificationTimeYears').value).padStart(2, '0')}-${String(
            this.budgetTemplateForm.get('notificationTimeMonths').value,
        ).padStart(2, '0')}-${String(this.budgetTemplateForm.get('notificationTimeDays').value).padStart(2, '0')}`;

        const budgetTemplate: BudgetTemplate = {
            uuid: null,
            budgetType: this.budgetTemplateForm.get('budgetType').value,
            totalSpend: this.budgetTemplateForm.get('totalSpend').value,
            leadTime: leadTime,
            recurring: this.budgetTemplateForm.get('isRecurring').value,
            repeat: this.budgetTemplateForm.get('recurrenceRule').value,
            sendNotification: this.budgetTemplateForm.get('sendNotification').value,
            timeBeforeNotification: timeBeforeNotification,
            useExpendituresForFinancials: this.budgetTemplateForm.get('useExpendituresForFinancials').value === 'expenditures',
        };

        if (!this.budgetTemplate) {
            this.subscriptions.add(
                this.store.dispatch(new AddBudgetTemplate(budgetTemplate)).subscribe(
                    () => {
                        this.toastr.success('Budget template successfully added.');
                        this.activeModal.close();
                    },
                    () => {
                        this.logger.error('Failed to add budgetTemplate');
                        this.toastr.error('Oops, something went wrong, Please try again later...');
                    },
                ),
            );
        } else {
            budgetTemplate.uuid = this.budgetTemplate.uuid;
            this.subscriptions.add(
                this.store.dispatch(new UpdateBudgetTemplate(budgetTemplate)).subscribe(
                    () => {
                        this.toastr.success('Budget template successfully updated.');
                        this.activeModal.close();
                    },
                    () => {
                        this.logger.error('Failed to update budgetTemplate');
                        this.toastr.error('Oops, something went wrong, Please try again later...');
                    },
                ),
            );
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    private createBudgetTemplateForm() {
        if (!this.budgetTemplate) {
            this.budgetTemplateForm = this.fb.group({
                budgetType: ['', [Validators.required]],
                totalSpend: ['', [Validators.required]],
                isRecurring: '',
                sendNotification: '',
                recurrenceRule: '',
                leadTimeDays: ['', [Validators.min(0), Validators.max(31)]],
                leadTimeMonths: ['', [Validators.min(0), Validators.max(12)]],
                leadTimeYears: ['', [Validators.min(0), Validators.max(99)]],
                notificationTimeDays: ['', [Validators.min(0), Validators.max(31)]],
                notificationTimeMonths: ['', [Validators.min(0), Validators.max(12)]],
                notificationTimeYears: ['', [Validators.min(0), Validators.max(99)]],
                useExpendituresForFinancials: ['', Validators.required],
            });
        } else {
            const splitLeadTime: string[] = this.budgetTemplate.leadTime.split('-');

            let splitNotificationTime: string[] = ['', '', ''];
            if (this.budgetTemplate.sendNotification) {
                splitNotificationTime = this.budgetTemplate.timeBeforeNotification.split('-');
            }

            this.budgetTemplateForm = this.fb.group({
                budgetType: [this.budgetTemplate.budgetType, [Validators.required]],
                totalSpend: [this.budgetTemplate.totalSpend, [Validators.required]],
                isRecurring: this.budgetTemplate.recurring,
                sendNotification: this.budgetTemplate.sendNotification,
                recurrenceRule: this.budgetTemplate.repeat,
                leadTimeDays: [splitLeadTime[2], [Validators.min(0), Validators.max(31)]],
                leadTimeMonths: [splitLeadTime[1], [Validators.min(0), Validators.max(12)]],
                leadTimeYears: [splitLeadTime[0], [Validators.min(0), Validators.max(99)]],
                notificationTimeDays: [splitNotificationTime[2], [Validators.min(0), Validators.max(31)]],
                notificationTimeMonths: [splitNotificationTime[1], [Validators.min(0), Validators.max(12)]],
                notificationTimeYears: [splitNotificationTime[0], [Validators.min(0), Validators.max(99)]],
                useExpendituresForFinancials: [this.budgetTemplate.useExpendituresForFinancials ? 'expenditures' : 'budgeted', Validators.required],
            });
        }
    }

    private seedRecurrenceRules() {
        this.budgetRepeats = Object.values(BUDGETREPEAT);
    }
}
