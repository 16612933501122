<div class="modal-wrapper">
    <div class="modal-body">
        <div
            cdkDropListGroup
            class="kanban">
            <div class="kanban__lane">
                <div class="kanban__lane__title">
                    <strong>Active</strong>
                </div>
                <div
                    cdkDropList
                    class="kanban__lane__items"
                    id="active"
                    [cdkDropListData]="activeColumns"
                    (cdkDropListDropped)="drop($event)">
                    <tr
                        *ngFor="let column of activeColumns"
                        cdkDrag
                        #dragItem1>
                        <td
                            *ngIf="column !== 'edit'"
                            cdkDragHandle>
                            <i class="las la-braille"></i> {{ column }}
                        </td>
                    </tr>
                </div>
            </div>

            <div class="kanban__lane">
                <div class="kanban__lane__title">
                    <strong>Inactive</strong>
                </div>
                <div
                    cdkDropList
                    class="kanban__lane__items"
                    id="inactive"
                    [cdkDropListData]="inactiveColumns"
                    (cdkDropListDropped)="drop($event)">
                    <tr
                        *ngFor="let column of inactiveColumns"
                        cdkDrag
                        #dragItem1>
                        <td
                            *ngIf="column !== 'edit'"
                            cdkDragHandle>
                            <i class="las la-braille"></i> {{ column }}
                        </td>
                    </tr>
                </div>
            </div>
        </div>
        <a
            class="button button--primary"
            (click)="save()"
            >Save configuration</a
        >
    </div>
</div>
