<form
    [formGroup]="warehouseLocationFormGroup"
    (ngSubmit)="addWarehouseLocation()"
    class="modal-wrapper">
    <div class="modal-header">
        <h2
            class="modal-title"
            id="exampleModalLabel">
            New warehouse location
        </h2>
        <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="activeModal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <div class="row">
            <div class="col">
                <div class="form-group required m-b-2">
                    <label>Title</label>
                    <input
                        type="text"
                        placeholder="Title"
                        formControlName="title"
                        (focus)="hideErrorMessage()"
                        trim="blur"
                        #focussed />
                    <span
                        class="form-error"
                        *ngIf="warehouseLocationFormGroup.controls.title.errors && inputIsWrong"
                        >Invalid warehouse title</span
                    >
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <div class="form-group m-b-2">
                    <label>Korte omschrijving</label>
                    <textarea
                        class="resize-vertical"
                        formControlName="description"
                        placeholder="Description"
                        (keyup)="onDescriptionKeyUp()"
                        trim="blur"></textarea>
                    <small
                        ><span *ngIf="currentAmountOfDescriptionCharacters == null">0</span>{{ currentAmountOfDescriptionCharacters }}/{{
                            DESCRIPTION_MAX_LENGTH
                        }}</small
                    >
                    <span
                        class="form-error"
                        *ngIf="descriptionIsTooLong"
                        >Message cannot contain more than 1000 characters</span
                    >
                </div>
            </div>
        </div>

        <div class="form-group m-b-2">
            <label>Tags</label>
            <app-global-tags [type]="'warehouselocation'"></app-global-tags>
        </div>

        <div class="row">
            <div class="col">
                <app-global-autocomplete
                    [parentForm]="warehouseLocationFormGroup"
                    [formControlName]="'warehouse'"
                    [data]="allWarehouses"
                    [itemType]="'warehouse'"
                    [label]="'Warehouse'"
                    [notFoundMessage]="'Add a warehouse'"
                    [keyword]="keyword"
                    [placeholder]="'Choose a warehouse'"
                    [active]="(allWarehouses$ | async)?.length != 0"></app-global-autocomplete>
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <button
            type="button"
            class="button button--outline"
            (click)="activeModal.close()">
            Close
        </button>
        <button
            type="submit"
            [ngClass]="{ 'button--loading': loading }"
            class="button button--primary">
            Create warehouse location
        </button>
    </div>
</form>
