import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { endOfYear, isAfter, isBefore, startOfYear } from 'date-fns';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';

import { BudgetService } from '../../../budget/budget.service';
import { LinkedFileToObject } from '../../../models/LinkedFileToObject';
import { LinkedFilesToObjectWithId } from '../../../models/LinkedFilesToObjectWithId';
import { InvoiceInformationModalComponent } from '../../../yuki/invoice-information-modal/invoice-information-modal.component';
import { AddFileToObjectModalComponent } from '../add-file-to-object-modal/add-file-to-object-modal.component';
import { DeleteLinkedObject, DeleteSubLinkToLinkedObjectToFile, GetAllFilesLinkedToObject } from '../global-files.actions';
import { GlobalFilesState } from '../global-files.state';
import { UploadedDocumentDetailComponent } from '../uploaded-document-detail/uploaded-document-detail.component';

@Component({
    selector: 'app-linked-invoices-to-object',
    templateUrl: './linked-invoices-to-object.component.html',
    styleUrls: ['./linked-invoices-to-object.component.scss'],
})
export class LinkedInvoicesToObjectComponent implements OnInit, OnDestroy {
    @Input() objectId: string;
    @Input() showFiles = true;
    @Input() showInvoices = true;
    @Input() alreadyFetchedFiles = false;
    @Input() folderId: number;
    @Select(GlobalFilesState.LinkedFilesToObjectWithId) linkedFilesToObjectWithId$: Observable<LinkedFilesToObjectWithId>;

    linkedFilesToObjectWithId: LinkedFilesToObjectWithId;
    datasource: MatTableDataSource<LinkedFileToObject> = new MatTableDataSource<LinkedFileToObject>();
    displayedColumns: string[] = ['file'];
    startDate: Date = startOfYear(new Date());
    endDate: Date = endOfYear(new Date());

    private subscriptions = new Subscription();

    constructor(
        private store: Store,
        private logger: NGXLogger,
        private toastr: ToastrService,
        private modalService: NgbModal,
        private budgetService: BudgetService,
    ) {}

    ngOnInit(): void {
        this.createMatTable();
        this.fetchData();
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    filterOnDate(startDate: Date, endDate: Date) {
        this.startDate = startDate;
        this.endDate = endDate;
        this.executeFilter();
    }

    deleteLink(linkedFile: LinkedFileToObject) {
        if (linkedFile.sublink) {
            this.subscriptions.add(
                this.store.dispatch(new DeleteSubLinkToLinkedObjectToFile(linkedFile.childLinkId)).subscribe({
                    next: () => {
                        this.toastr.success('Successfully deleted object from ' + linkedFile.title);
                        this.logger.debug('Successfully deleted object from ' + linkedFile.title);
                    },
                    error: () => {
                        this.toastr.error('Failed to link file with current object');
                        this.logger.error('Failed fetch file of Yuki document due to request error to the Yuki API.');
                    },
                }),
            );
            return;
        }
        this.subscriptions.add(
            this.store.dispatch(new DeleteLinkedObject(linkedFile.fileId, this.objectId)).subscribe({
                next: () => {
                    this.toastr.success('Successfully deleted object from ' + linkedFile.title);
                    this.logger.debug('Successfully deleted object from ' + linkedFile.title);
                },
                error: () => {
                    this.toastr.error('Failed to link file with current object');
                    this.logger.error('Failed fetch file of Yuki document due to request error to the Yuki API.');
                },
            }),
        );
    }

    openInvoiceInformationModal(linkedFile: LinkedFileToObject) {
        if (linkedFile.fileType.toLowerCase() !== 'uploadeddocument') {
            const modalRef = this.modalService.open(InvoiceInformationModalComponent, {
                windowClass: 'modal-full-screen',
                animation: false,
            });
            modalRef.componentInstance.yukiDocumentId = linkedFile.fileId;
        } else {
            const modalRef = this.modalService.open(UploadedDocumentDetailComponent, {
                windowClass: 'modal-huge',
                animation: false,
            });
            modalRef.componentInstance.uploadedDocumentId = linkedFile.fileId;
        }
    }

    openAddDocumentModal() {
        const modalRef = this.modalService.open(AddFileToObjectModalComponent, {
            windowClass: 'modal-prompt',
            animation: false,
        });
        modalRef.componentInstance.objectId = this.objectId;
    }

    calculateTotal(): number {
        return this.datasource.filteredData.reduce((total, linkedObjectToFile) => total + linkedObjectToFile.price, 0);
    }

    getBase64(url: string) {
        return this.budgetService.getInvoice(url);
    }

    private executeFilter() {
        const filter: LinkedInvoicesListFilter = {
            showFiles: this.showFiles,
            showInvoices: this.showInvoices,
            folderId: this.folderId,
            startDate: this.startDate,
            endDate: this.endDate,
        };
        this.datasource.filter = JSON.stringify(filter);
    }

    private fetchData() {
        if (!this.alreadyFetchedFiles) {
            this.logger.debug('Fetching all files linked to object');
            this.store.dispatch(new GetAllFilesLinkedToObject(this.objectId));
        }
        this.subscriptions.add(
            this.linkedFilesToObjectWithId$.subscribe((linkedFilesToObjectWithId) => {
                this.linkedFilesToObjectWithId = linkedFilesToObjectWithId;
                this.datasource.data = linkedFilesToObjectWithId?.linkedFiles;
                this.executeFilter();
            }),
        );
    }

    private createMatTable() {
        if (this.showFiles) this.displayedColumns.push('description');
        if (this.showInvoices) this.displayedColumns.push('amount', 'date');
        if (this.showInvoices && this.folderId === 2) this.displayedColumns.push('invoiceNumber');

        this.displayedColumns.push('delete');

        this.datasource.filterPredicate = function (data, filter) {
            const filterObj: LinkedInvoicesListFilter = JSON.parse(filter);
            return (
                (filterObj.showInvoices &&
                    data.fileType.toLowerCase() === 'yukidocument' &&
                    data.folderId === filterObj.folderId &&
                    isBefore(new Date(data.invoiceDate), new Date(filterObj.endDate)) &&
                    isAfter(new Date(data.invoiceDate), new Date(filterObj.startDate))) ||
                (filterObj.showFiles && data.fileType.toLowerCase() === 'uploadeddocument')
            );
        };
    }
}

class LinkedInvoicesListFilter {
    showInvoices: boolean;
    showFiles: boolean;
    folderId: number;
    startDate: Date;
    endDate: Date;
}
