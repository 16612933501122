import { ContactPerson } from '../models/ContactPerson';
import { Partner } from '../models/Partner';

export class AddPartner {
    static readonly type = '[Partner] Add Partner';

    constructor(public partner: Partner) {}
}

export class UpdatePartner {
    static readonly type = '[Partner] Update Partner';

    constructor(public partnerUuid: string, public partner: Partner) {}
}

export class GetPartner {
    static readonly type = '[Partner] Get Partner';

    constructor(public partneruuid: string) {}
}

export class ArchivePartner {
    static readonly type = '[Partner] Delete Partner';

    constructor(public partnerUuid: string) {}
}

export class FetchPartners {
    static readonly type = '[Partner] Fetch Partner';
}

export class FetchPartnerById {
    static readonly type = '[Partner] Fetch Partner by Id';

    constructor(public id: string) {}
}

export class AddContactPersonToPartner {
    static readonly type = '[Partner] Add ContactPerson to partner';

    constructor(public partnerUuid: string, public contactPerson: ContactPerson) {}
}
