import { GeneralSettings } from '../models/GeneralSettings';
import TableDisplayedColumns from '../models/TableDisplayedColumns';

export class FetchGeneralSettings {
    static readonly type = '[Number] Fetch Days Per Month';
}

export class UpdateDaysPerMonth {
    static readonly type = '[Number] Update Days Per Month';

    constructor(public daysPerMonth: number) {}
}

export class UpdateHolidayDays {
    static readonly type = '[GeneralSettings] Update Holiday days';

    constructor(public settings: GeneralSettings) {}
}

export class FetchAllTableDisplayedColumns {
    static readonly type = '[TableDisplayedColumns[]] Fetch all table displayed columns';
}

export class GetTableDisplayedColumnsById {
    static readonly type = '[TableDisplayedColumns] Fetch a table displayed columns by id';

    constructor(public tableId: string) {}
}

export class UpdateTableDisplayedColumns {
    static readonly type = '[TableDisplayedColumns] Update table displayed columns';

    constructor(public tableDisplayedColumns: TableDisplayedColumns) {}
}
