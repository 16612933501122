import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { AuthenticationService } from '../authentication/authentication.service';
import { OverheadCategory } from '../models/OverheadCategory';
import { VAT } from '../models/VAT';
import { ContactMapper } from '../models/yuki/ContactMapper';
import YukiDocument from '../models/yuki/YukiDocument';
import { YukiFile } from '../models/yuki/YukiFile';
import YukiFolder from '../models/yuki/YukiFolder';
import { YukiPage } from '../models/yuki/YukiPage';
import { YukiSettings } from '../models/yuki/YukiSettings';
import { InvoiceDTO } from './create-new-invoice-modal/create-new-invoice-modal.component';
import { YukiDocumentDTO } from './yuki-detail-modal/yuki-detail-modal.component';

@Injectable({
    providedIn: 'root',
})
export class YukiService {
    httpOptions = {};

    constructor(private httpClient: HttpClient, private authenticationService: AuthenticationService) {
        this.httpOptions = authenticationService.getHeaders();
    }

    getFolders(): Observable<YukiFolder[]> {
        return this.httpClient.get<YukiFolder[]>(environment.baseUrl + '/yuki/folders', this.httpOptions);
    }

    getDocumentsByFolder(folderId: number, pageNumber: number, recordAmount: number): Observable<YukiPage> {
        return this.httpClient
            .get<YukiPage>(environment.baseUrl + `/yuki/documents?folderId=${folderId}&pageNumber=${pageNumber}&recordAmount=${recordAmount}`, this.httpOptions)
            .pipe(
                map((page) => {
                    page.documents.forEach((document) => {
                        document.vat = VAT.findVAT(document.vattext);
                    });
                    return page;
                }),
            );
    }

    getAllDocumentsByFolder(folderId: number): Observable<YukiDocument[]> {
        return this.httpClient.get<YukiDocument[]>(environment.baseUrl + `/yuki/documents/all?folderId=${folderId}`, this.httpOptions).pipe(
            map((documents) => {
                documents.forEach((document) => {
                    document.vat = VAT.findVAT(document.vattext);
                });
                return documents;
            }),
        );
    }

    fetchDocumentById(id: string): Observable<YukiDocument> {
        return this.httpClient.get<YukiDocument>(`${environment.baseUrl}/yuki/document/${id}`, this.httpOptions).pipe(
            map((document) => {
                document.vat = VAT.findVAT(document.vattext);
                return document;
            }),
        );
    }

    fetchFile(yukiId: string): Observable<YukiFile> {
        return this.httpClient.get<YukiFile>(`${environment.baseUrl}/yuki/document/file/${yukiId}`, this.httpOptions);
    }

    refreshFile(yukiId: string) {
        return this.httpClient.patch<YukiFile>(`${environment.baseUrl}/yuki/document/file/${yukiId}`, {}, this.httpOptions);
    }

    fetchYukiSettings(): Observable<YukiSettings> {
        return this.httpClient.get<YukiSettings>(`${environment.baseUrl}/yuki/settings`, this.httpOptions);
    }

    updateYukiSettings(uuid: string, settings: YukiSettings): Observable<YukiSettings> {
        return this.httpClient.patch<YukiSettings>(`${environment.baseUrl}/yuki/settings/${uuid}`, settings, this.httpOptions);
    }

    updateVATOfYukiDocument(uuid: string, body: YukiDocumentDTO) {
        return this.httpClient.put<YukiDocument>(`${environment.baseUrl}/yuki/document/${uuid}/vat`, body, this.httpOptions).pipe(
            map((document) => {
                document.vat = VAT.findVAT(document.vattext);
                return document;
            }),
        );
    }

    verifyYukiDocument(uuid: string) {
        return this.httpClient.put(`${environment.baseUrl}/yuki/document/${uuid}/verify`, {}, this.httpOptions);
    }

    syncDocuments() {
        return this.httpClient.post<YukiDocument[]>(`${environment.baseUrl}/yuki/sync/`, {}, this.httpOptions);
    }

    updateVisibilityYukiDocument(yukiId: string) {
        return this.httpClient.patch<void>(`${environment.baseUrl}/yuki/visible/${yukiId}`, {}, this.httpOptions);
    }

    flagYukiDocument(yukiId: string) {
        return this.httpClient.patch<void>(`${environment.baseUrl}/yuki/flag/${yukiId}`, {}, this.httpOptions);
    }

    getOverheadCategoryById(id: string): Observable<OverheadCategory> {
        return this.httpClient.get<OverheadCategory>(`${environment.baseUrl}/overhead-categories/${id}`, this.httpOptions);
    }

    getAllOverheadCategories(): Observable<OverheadCategory[]> {
        return this.httpClient.get<OverheadCategory[]>(`${environment.baseUrl}/overhead-categories/all`, this.httpOptions);
    }

    addOverheadCategories(overheadCategories: OverheadCategory[]): Observable<OverheadCategory[]> {
        return this.httpClient.post<OverheadCategory[]>(`${environment.baseUrl}/overhead-categories`, overheadCategories, this.httpOptions);
    }

    deleteOverheadCategory(id: string): Observable<void> {
        return this.httpClient.delete<void>(`${environment.baseUrl}/overhead-categories/${id}`, this.httpOptions);
    }

    updateOverheadCategory(overheadCategory: OverheadCategory): Observable<OverheadCategory> {
        return this.httpClient.put<OverheadCategory>(`${environment.baseUrl}/overhead-categories/${overheadCategory.id}`, overheadCategory, this.httpOptions);
    }

    refreshDocumentFromYukiAPI(yukiId: string) {
        return this.httpClient.patch<YukiDocument>(`${environment.baseUrl}/yuki/document/${yukiId}`, {}, this.httpOptions).pipe(
            map((document) => {
                document.vat = VAT.findVAT(document.vattext);
                return document;
            }),
        );
    }

    deleteDocument(yukiId: string) {
        return this.httpClient.delete(`${environment.baseUrl}/yuki/document/${yukiId}`, this.httpOptions);
    }

    getRemovedYukiDocuments(): Observable<YukiDocument[]> {
        return this.httpClient.get<YukiDocument[]>(`${environment.baseUrl}/yuki/get-removed`, this.httpOptions);
    }

    checkRemovedYukiDocuments(): Observable<YukiDocument[]> {
        return this.httpClient.post<YukiDocument[]>(`${environment.baseUrl}/yuki/check-removed`, {}, this.httpOptions);
    }

    getMappedContacts(): Observable<ContactMapper[]> {
        return this.httpClient.get<ContactMapper[]>(`${environment.baseUrl}/yuki/map-contacts`, this.httpOptions);
    }

    addMappedContact(yukiContact: string, resumoContactId: string): Observable<ContactMapper> {
        const body = {
            yukiContact: yukiContact,
            resumoContactId: resumoContactId,
        };
        return this.httpClient.post<ContactMapper>(`${environment.baseUrl}/yuki/map-contacts`, body, this.httpOptions);
    }

    mapContactsForAllExistingDocuments(): Observable<void> {
        return this.httpClient.patch<void>(`${environment.baseUrl}/yuki/map-contacts`, {}, this.httpOptions);
    }

    deleteMappedContact(mappedContactId: string): Observable<void> {
        return this.httpClient.delete<void>(`${environment.baseUrl}/yuki/map-contacts/${mappedContactId}`, this.httpOptions);
    }

    createNewInvoice(invoiceDTO: InvoiceDTO) {
        return this.httpClient.post<YukiDocument>(`${environment.baseUrl}/yuki/create-invoice`, invoiceDTO, this.httpOptions);
    }
}
