<div class="module__logo">
    <img src="../../../assets/img/logo_comanage.svg" />
</div>

<div>
    <form
        [formGroup]="form"
        (ngSubmit)="onSubmit(form)">
        <div class="form-group">
            <label for="key">CoManage API key</label>
            <div class="row">
                <div class="col-md-9">
                    <div class="form-group">
                        <input
                            type="text"
                            id="key"
                            formControlName="apiKey"
                            placeholder="CoManage API key" />
                    </div>
                </div>
                <div class="col-md-3">
                    <input
                        type="submit"
                        value="Save key"
                        [disabled]="!form.valid"
                        class="button button--primary w-100" />
                </div>
                <div class="col-md-12">
                    <small
                        class="form-error"
                        *ngIf="!form.get('apiKey').valid && form.get('apiKey').touched"
                        >The key is not valid</small
                    >
                </div>
                <div class="col-md-12">
                    <div class="form-group">
                        <small class="help-text">You can find your key at CoManage &rsaquo; Settings &rsaquo; General &rsaquo; Integrations</small>
                    </div>
                </div>
                <div class="col-md-12">
                    <button
                        type="button"
                        (click)="removeKey()"
                        [disabled]="form.get('apiKey').value === ''"
                        class="button button--danger w-100">
                        Remove key
                    </button>
                </div>
            </div>
            <small *ngIf="!form.get('apiKey').touched"></small>
        </div>
    </form>
</div>
