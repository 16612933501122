export default class DateHelper {
    static getLocalTimeZone() {
        const offset = (new Date().getTimezoneOffset() / 60) * 100;
        const negative = offset < 0;
        let timezone = Math.abs(offset).toString();
        let addon = '';
        for (let i = 0; i < 4 - Math.abs(offset).toString().length; i++) {
            addon += '0';
        }
        timezone = (negative ? '+' : '-') + addon + timezone;
        return timezone;
    }
}
