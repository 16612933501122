<div class="modal-header">
    <h2
        class="modal-title"
        *ngIf="fileName; else loading">
        {{ fileName }}
    </h2>

    <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <div class="d-flex space-between align-items-center w-100 m-b-4">
        <blockquote>
            <span
                *ngIf="uploadedDocument.description"
                class="format-newlines">
                {{ uploadedDocument.description }}
            </span>
            <span *ngIf="!uploadedDocument.description"> No description or extra information... </span>
        </blockquote>

        <button
            type="button"
            (click)="openDescriptionModal()"
            class="button button--outline button--small button--icon">
            <i class="las la-pen"></i>
        </button>
    </div>

    <div class="w-100 h-100">
        <iframe
            *ngIf="pdf; else loading"
            [cachedSrc]="pdf"
            class="w-100 h-100">
        </iframe>
    </div>
</div>

<ng-template #loading>
    <app-loading-message></app-loading-message>
</ng-template>
