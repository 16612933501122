import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store, createSelector } from '@ngxs/store';
import { append, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { tap } from 'rxjs/operators';

import { OverheadCategory } from '../models/OverheadCategory';
import { ContactMapper } from '../models/yuki/ContactMapper';
import { YukiCredential } from '../models/yuki/YukiCredential';
import YukiDocument from '../models/yuki/YukiDocument';
import { YukiFile } from '../models/yuki/YukiFile';
import YukiFolder from '../models/yuki/YukiFolder';
import { YukiSettings } from '../models/yuki/YukiSettings';
import { YukiLoginService } from '../shared/login-components/yuki-login/yuki-login.service';
import {
    AddMappedContact,
    AddOverheadCategories,
    CheckRemovedYukiDocuments,
    CreateNewInvoice,
    DeleteDocument,
    DeleteMappedContact,
    DeleteOverheadCategory,
    FetchAllDocuments,
    FetchCredentials,
    FetchDocumentById,
    FetchDocuments,
    FetchFile,
    FetchFolders,
    FetchNextFile,
    FetchPreviousFile,
    FetchYukiSettings,
    FlagYukiDocument,
    GetAllOverheadCategories,
    GetMappedContacts,
    GetOverheadCategoryById,
    GetRemovedYukiDocuments,
    LoadDocuments,
    MapContactsForAllExistingDocuments,
    RefreshDocumentFromYukiAPI,
    RefreshFile,
    SelectFolder,
    SetCredentials,
    SwitchNextFileToFile,
    SwitchPreviousFileToFile,
    SyncDocuments,
    UpdateOverheadCategory,
    UpdateVATOfYukiDocument,
    UpdateVisibilityYukiDocument,
    UpdateYukiSettings,
    VerifyYukiDocument,
} from './yuki.actions';
import { YukiService } from './yuki.service';

export interface YukiStateModel {
    folders: YukiFolder[];
    currentFolderId: number;
    documentForInformationModal: YukiDocument;
    numberOfPages: number;
    file: YukiFile;
    nextFile: YukiFile;
    previousFile: YukiFile;
    yukiSettings: YukiSettings;
    yukiCredential: YukiCredential;
    overheadCategories: OverheadCategory[];
    overheadCategory: OverheadCategory;
    removedFiles: YukiDocument[];
    contactMappers: ContactMapper[];
}

@State<YukiStateModel>({
    name: 'yuki',
    defaults: {
        folders: null,
        currentFolderId: null,
        documentForInformationModal: null,
        numberOfPages: null,
        file: null,
        nextFile: null,
        previousFile: null,
        yukiSettings: null,
        yukiCredential: null,
        overheadCategories: null,
        overheadCategory: null,
        removedFiles: null,
        contactMappers: null,
    },
})
@Injectable()
export class YukiState {
    constructor(private yukiService: YukiService, private yukiLoginService: YukiLoginService, private store: Store) {}

    @Selector()
    static folders(state: YukiStateModel): YukiFolder[] {
        return state?.folders;
    }

    @Selector()
    static currentFolder(state: YukiStateModel): YukiFolder {
        return state?.folders.find((f) => f.id === state.currentFolderId);
    }

    static documentsByFolder(folderId: number) {
        return createSelector([YukiState], (state: YukiStateModel) => {
            const folder = state.folders.find((f) => f.id === folderId);
            return folder.documents;
        });
    }

    @Selector()
    static documentsForCurrentFolder(state: YukiStateModel): YukiDocument[] {
        return state?.folders.find((f) => f.id === state.currentFolderId).documents;
    }

    @Selector()
    static documentForInformationModal(state: YukiStateModel): YukiDocument {
        return state?.documentForInformationModal;
    }

    static documentById(documentId: string) {
        return createSelector([YukiState], (state: YukiStateModel) =>
            state?.folders
                .find((folder) => folder.documents?.find((document) => document.uuid === documentId))
                .documents.find((document) => document.uuid === documentId),
        );
    }

    @Selector()
    static numberOfPages(state: YukiStateModel): number {
        return state?.numberOfPages;
    }

    @Selector()
    static file(state: YukiStateModel): YukiFile {
        return state?.file;
    }

    @Selector()
    static yukiSettings(state: YukiStateModel): YukiSettings {
        return state?.yukiSettings;
    }

    @Selector()
    static yukiCredential(state: YukiStateModel): YukiCredential {
        return state?.yukiCredential;
    }

    @Selector()
    static overheadCategories(state: YukiStateModel): OverheadCategory[] {
        return state?.overheadCategories;
    }

    @Selector()
    static overheadCategoryById(state: YukiStateModel): (id: string) => OverheadCategory {
        return (id: string) => {
            return state?.overheadCategories?.find((overheadCategory) => overheadCategory.id === id);
        };
    }

    @Selector()
    static overheadCategory(state: YukiStateModel): OverheadCategory {
        return state?.overheadCategory;
    }

    @Selector()
    static removedFiles(state: YukiStateModel): YukiDocument[] {
        return state?.removedFiles;
    }

    @Selector()
    static mappedContacts(state: YukiStateModel): ContactMapper[] {
        return state?.contactMappers;
    }

    @Action(FetchFolders)
    fetchFolders(ctx: StateContext<YukiStateModel>) {
        return this.yukiService.getFolders().pipe(
            tap((folders) => {
                ctx.setState(
                    patch<YukiStateModel>({
                        folders: folders,
                    }),
                );
            }),
        );
    }

    @Action(SelectFolder)
    selectFolder(ctx: StateContext<YukiStateModel>, action: SelectFolder) {
        const folder = ctx.getState().folders.find((f) => f.id === action.folderId);
        if (!folder || !folder.documents || folder.documents?.length === 0) {
            this.fetchAllDocuments(ctx, new FetchAllDocuments(action.folderId)).subscribe();
        }
        ctx.setState(
            patch<YukiStateModel>({
                currentFolderId: action.folderId,
            }),
        );
    }

    @Action(FetchAllDocuments)
    fetchAllDocuments(ctx: StateContext<YukiStateModel>, action: FetchAllDocuments) {
        return this.yukiService.getAllDocumentsByFolder(action.folderId).pipe(
            tap((documents) => {
                ctx.setState(
                    patch<YukiStateModel>({
                        folders: updateItem<YukiFolder>(
                            (folder) => folder.id === action.folderId,
                            patch<YukiFolder>({
                                documents: documents,
                            }),
                        ),
                    }),
                );
            }),
        );
    }

    @Action(FetchDocuments)
    fetchDocuments(ctx: StateContext<YukiStateModel>, action: FetchDocuments) {
        return this.yukiService.getDocumentsByFolder(action.folderId, action.pageNumber, action.recordAmount).pipe(
            tap((page) => {
                ctx.setState(
                    patch<YukiStateModel>({
                        folders: updateItem<YukiFolder>(
                            (folder) => folder.id === action.folderId,
                            patch<YukiFolder>({
                                documents: page.documents,
                            }),
                        ),
                        numberOfPages: page.numberOfPages,
                    }),
                );
            }),
        );
    }

    @Action(FetchDocumentById)
    fetchDocumentById(ctx: StateContext<YukiStateModel>, action: FetchDocumentById) {
        return this.yukiService.fetchDocumentById(action.id).pipe(
            tap((document) => {
                ctx.setState(
                    patch<YukiStateModel>({
                        documentForInformationModal: document,
                    }),
                );
            }),
        );
    }

    @Action(LoadDocuments)
    loadDocuments(ctx: StateContext<YukiStateModel>, action: LoadDocuments) {
        const start = ctx.getState().folders.find((f) => f.id === action.folderId).documents.length - 1;
        return this.yukiService.getDocumentsByFolder(action.folderId, start, start + 100).pipe(
            tap((page) => {
                ctx.setState(
                    patch<YukiStateModel>({
                        folders: updateItem<YukiFolder>(
                            (folder) => folder.id === action.folderId,
                            patch<YukiFolder>({
                                documents: append<YukiDocument>([...page.documents]),
                            }),
                        ),
                        numberOfPages: page.numberOfPages,
                    }),
                );
            }),
        );
    }

    @Action(FetchFile)
    fetchFile(ctx: StateContext<YukiStateModel>, action: FetchFile) {
        return this.yukiService.fetchFile(action.yukiId).pipe(
            tap((file) => {
                ctx.setState(
                    patch<YukiStateModel>({
                        file: file,
                    }),
                );
            }),
        );
    }

    @Action(FetchNextFile)
    fetchNextFile(ctx: StateContext<YukiStateModel>, action: FetchNextFile) {
        return this.yukiService.fetchFile(action.yukiId).pipe(
            tap((file) => {
                ctx.setState(
                    patch<YukiStateModel>({
                        nextFile: file,
                    }),
                );
            }),
        );
    }

    @Action(FetchPreviousFile)
    fetchPreviousFile(ctx: StateContext<YukiStateModel>, action: FetchPreviousFile) {
        return this.yukiService.fetchFile(action.yukiId).pipe(
            tap((file) => {
                ctx.setState(
                    patch<YukiStateModel>({
                        previousFile: file,
                    }),
                );
            }),
        );
    }

    @Action(SwitchNextFileToFile)
    switchNextFileToFile(ctx: StateContext<YukiStateModel>) {
        ctx.setState(
            patch<YukiStateModel>({
                previousFile: ctx.getState().file,
            }),
        );
        ctx.setState(
            patch<YukiStateModel>({
                file: ctx.getState().nextFile,
            }),
        );
    }

    @Action(SwitchPreviousFileToFile)
    switchPreviousFileToFile(ctx: StateContext<YukiStateModel>) {
        ctx.setState(
            patch<YukiStateModel>({
                nextFile: ctx.getState().file,
            }),
        );
        ctx.setState(
            patch<YukiStateModel>({
                file: ctx.getState().previousFile,
            }),
        );
    }

    @Action(RefreshFile)
    refreshFile(ctx: StateContext<YukiStateModel>, action: RefreshFile) {
        return this.yukiService.refreshFile(action.yukiId).pipe(
            tap((file) => {
                ctx.setState(
                    patch<YukiStateModel>({
                        file: file,
                    }),
                );
            }),
        );
    }

    @Action(FetchYukiSettings)
    fetchYukiSettings(ctx: StateContext<YukiStateModel>) {
        return this.yukiService.fetchYukiSettings().pipe(
            tap((settings) => {
                ctx.setState(
                    patch<YukiStateModel>({
                        yukiSettings: settings,
                    }),
                );
            }),
        );
    }

    @Action(UpdateYukiSettings)
    updateYukiSettings(ctx: StateContext<YukiStateModel>, action: UpdateYukiSettings) {
        return this.yukiService.updateYukiSettings(action.uuid, action.settings).pipe(
            tap((settings) => {
                ctx.setState(
                    patch<YukiStateModel>({
                        yukiSettings: settings,
                    }),
                );
            }),
        );
    }

    @Action(UpdateVATOfYukiDocument)
    updateVATOfYukiDocument(ctx: StateContext<YukiStateModel>, action: UpdateVATOfYukiDocument) {
        return this.yukiService.updateVATOfYukiDocument(action.uuid, action.body).pipe(
            tap((document) => {
                ctx.setState(
                    patch<YukiStateModel>({
                        folders: updateItem<YukiFolder>(
                            (folder) => folder.id === document.folderId,
                            patch<YukiFolder>({
                                documents: updateItem<YukiDocument>((document) => document.uuid === action.uuid, document),
                            }),
                        ),
                    }),
                );
            }),
        );
    }

    @Action(VerifyYukiDocument)
    verifyYukiDocument(ctx: StateContext<YukiStateModel>, action: VerifyYukiDocument) {
        return this.yukiService.verifyYukiDocument(action.uuid).pipe(
            tap(() => {
                const document: YukiDocument = ctx
                    .getState()
                    .folders.find((folder) => {
                        if (!folder?.documents) return false;
                        return folder.documents.find((document) => document.yukiId === action.uuid);
                    })
                    .documents.find((document) => document.yukiId === action.uuid);
                ctx.setState(
                    patch<YukiStateModel>({
                        folders: updateItem<YukiFolder>(
                            (folder) => folder.id === document.folderId,
                            patch<YukiFolder>({
                                documents: updateItem<YukiDocument>(
                                    (document) => document.yukiId === action.uuid,
                                    patch<YukiDocument>({
                                        verified: !document.verified,
                                    }),
                                ),
                            }),
                        ),
                    }),
                );
            }),
        );
    }

    @Action(SyncDocuments)
    syncDocuments(ctx: StateContext<YukiStateModel>) {
        // TODO: update state (difficulties -> all freshly synced documents are passed to the frontend => update if exists and only shown documents)
        return this.yukiService.syncDocuments().pipe(
            tap(() => {
                ctx.setState(
                    patch<YukiStateModel>({
                        yukiSettings: patch<YukiSettings>({
                            lastSync: new Date(),
                        }),
                    }),
                );
                this.getRemovedYukiDocuments(ctx);
            }),
        );
    }

    @Action(SetCredentials)
    setCredentials(ctx: StateContext<YukiStateModel>, action: SetCredentials) {
        return this.yukiLoginService.authorize(action.yukiCredential).pipe(
            tap(() => {
                ctx.setState(
                    patch<YukiStateModel>({
                        yukiCredential: action.yukiCredential,
                    }),
                );
            }),
        );
    }

    @Action(FetchCredentials)
    fetchCredentials(ctx: StateContext<YukiStateModel>) {
        return this.yukiLoginService.fetchCredentials().pipe(
            tap((credentials) => {
                ctx.setState(
                    patch<YukiStateModel>({
                        yukiCredential: credentials,
                    }),
                );
            }),
        );
    }

    @Action(UpdateVisibilityYukiDocument)
    updateVisibilityYukiDocument(ctx: StateContext<YukiStateModel>, action: UpdateVisibilityYukiDocument) {
        return this.yukiService.updateVisibilityYukiDocument(action.yukiId).pipe(
            tap(() => {
                ctx.setState(
                    patch<YukiStateModel>({
                        folders: updateItem<YukiFolder>(
                            (folder) => folder.id === ctx.getState().currentFolderId,
                            patch<YukiFolder>({
                                documents: updateItem<YukiDocument>(
                                    (document) => document.yukiId === action.yukiId,
                                    patch<YukiDocument>({
                                        visible: !ctx
                                            .getState()
                                            .folders.find((f) => f.id === ctx.getState().currentFolderId)
                                            .documents.find((document) => document.yukiId === action.yukiId).visible,
                                    }),
                                ),
                            }),
                        ),
                    }),
                );
            }),
        );
    }

    @Action(FlagYukiDocument)
    flagYukiDocument(ctx: StateContext<YukiStateModel>, action: FlagYukiDocument) {
        return this.yukiService.flagYukiDocument(action.yukiId).pipe(
            tap(() => {
                ctx.setState(
                    patch<YukiStateModel>({
                        folders: updateItem<YukiFolder>(
                            (folder) => folder.id === ctx.getState().currentFolderId,
                            patch<YukiFolder>({
                                documents: updateItem<YukiDocument>(
                                    (document) => document.yukiId === action.yukiId,
                                    patch<YukiDocument>({
                                        flagged: !ctx
                                            .getState()
                                            .folders.find((f) => f.id === ctx.getState().currentFolderId)
                                            .documents.find((document) => document.yukiId === action.yukiId).flagged,
                                    }),
                                ),
                            }),
                        ),
                    }),
                );
            }),
        );
    }

    @Action(RefreshDocumentFromYukiAPI)
    refreshDocumentFromYukiAPI(ctx: StateContext<YukiStateModel>, action: RefreshDocumentFromYukiAPI) {
        return this.yukiService.refreshDocumentFromYukiAPI(action.yukiId).pipe(
            tap((yukiDocument) => {
                ctx.setState(
                    patch<YukiStateModel>({
                        folders: updateItem<YukiFolder>(
                            (folder) => folder.id === ctx.getState().currentFolderId,
                            patch<YukiFolder>({
                                documents: updateItem<YukiDocument>((document) => document.yukiId === action.yukiId, yukiDocument),
                            }),
                        ),
                    }),
                );
            }),
        );
    }

    @Action(DeleteDocument)
    deleteDocument(ctx: StateContext<YukiStateModel>, action: DeleteDocument) {
        return this.yukiService.deleteDocument(action.yukiId).pipe(
            tap(() => {
                ctx.setState(
                    patch<YukiStateModel>({
                        folders: updateItem<YukiFolder>(
                            (folder) => folder.id === ctx.getState().currentFolderId,
                            patch<YukiFolder>({
                                documents: removeItem<YukiDocument>((document) => document.yukiId === action.yukiId),
                            }),
                        ),
                    }),
                );
            }),
        );
    }

    @Action(GetOverheadCategoryById)
    getOverheadCategoryById(ctx: StateContext<YukiStateModel>, action: GetOverheadCategoryById) {
        ctx.setState(
            patch<YukiStateModel>({
                overheadCategories: removeItem((overheadCategory) => overheadCategory.id === action.id),
            }),
        );

        return this.yukiService.getOverheadCategoryById(action.id).pipe(
            tap((overheadCategory) => {
                ctx.setState(
                    patch<YukiStateModel>({
                        overheadCategory: overheadCategory,
                        overheadCategories: append([overheadCategory]),
                    }),
                );
            }),
        );
    }

    @Action(GetAllOverheadCategories)
    getAllOverheadCategories(ctx: StateContext<YukiStateModel>, action: GetAllOverheadCategories) {
        return this.yukiService.getAllOverheadCategories().pipe(
            tap((overheadCategories) => {
                ctx.setState(
                    patch<YukiStateModel>({
                        overheadCategories: overheadCategories,
                    }),
                );
            }),
        );
    }

    @Action(AddOverheadCategories)
    addOverheadCategories(ctx: StateContext<YukiStateModel>, action: AddOverheadCategories) {
        return this.yukiService.addOverheadCategories(action.overheadCategories).pipe(
            tap((overheadCategories) => {
                ctx.setState(
                    patch<YukiStateModel>({
                        overheadCategories: append<OverheadCategory>(overheadCategories),
                    }),
                );
            }),
        );
    }

    @Action(DeleteOverheadCategory)
    deleteOverheadCategory(ctx: StateContext<YukiStateModel>, action: DeleteOverheadCategory) {
        return this.yukiService.deleteOverheadCategory(action.id).pipe(
            tap(() => {
                ctx.setState(
                    patch<YukiStateModel>({
                        overheadCategories: removeItem<OverheadCategory>((overheadCategory) => overheadCategory.id === action.id),
                    }),
                );
            }),
        );
    }

    @Action(UpdateOverheadCategory)
    updateOverheadCategoryName(ctx: StateContext<YukiStateModel>, action: UpdateOverheadCategory) {
        return this.yukiService.updateOverheadCategory(action.overheadCategory).pipe(
            tap((overheadCategory) => {
                ctx.setState(
                    patch<YukiStateModel>({
                        overheadCategories: updateItem<OverheadCategory>(
                            (overheadCategory) => overheadCategory.id === action.overheadCategory.id,
                            overheadCategory,
                        ),
                    }),
                );
            }),
        );
    }

    @Action(GetRemovedYukiDocuments)
    getRemovedYukiDocuments(ctx: StateContext<YukiStateModel>) {
        return this.yukiService.getRemovedYukiDocuments().pipe(
            tap((documents) => {
                ctx.setState(
                    patch<YukiStateModel>({
                        removedFiles: documents,
                    }),
                );
            }),
        );
    }

    @Action(CheckRemovedYukiDocuments)
    checkRemovedYukiDocuments(ctx: StateContext<YukiStateModel>) {
        return this.yukiService.checkRemovedYukiDocuments().pipe(
            tap((documents) => {
                ctx.setState(
                    patch<YukiStateModel>({
                        removedFiles: documents,
                    }),
                );
            }),
        );
    }

    @Action(GetMappedContacts)
    getMappedContacts(ctx: StateContext<YukiStateModel>) {
        return this.yukiService.getMappedContacts().pipe(
            tap((mappedContacts) => {
                ctx.setState(
                    patch<YukiStateModel>({
                        contactMappers: mappedContacts,
                    }),
                );
            }),
        );
    }

    @Action(AddMappedContact)
    addMappedContact(ctx: StateContext<YukiStateModel>, action: AddMappedContact) {
        return this.yukiService.addMappedContact(action.yukiContact, action.resumoContactId).pipe(
            tap((mappedContact) => {
                ctx.setState(
                    patch<YukiStateModel>({
                        contactMappers: append([mappedContact]),
                    }),
                );
            }),
        );
    }

    @Action(MapContactsForAllExistingDocuments)
    mapContactsForAllExistingDocuments() {
        return this.yukiService.mapContactsForAllExistingDocuments();
    }

    @Action(DeleteMappedContact)
    deleteMappedContact(ctx: StateContext<YukiStateModel>, action: DeleteMappedContact) {
        return this.yukiService.deleteMappedContact(action.mappedContactId).pipe(
            tap(() => {
                ctx.setState(
                    patch<YukiStateModel>({
                        contactMappers: removeItem((contactMapper) => contactMapper.id === action.mappedContactId),
                    }),
                );
            }),
        );
    }

    @Action(CreateNewInvoice)
    createNewInvoice(ctx: StateContext<YukiStateModel>, action: CreateNewInvoice) {
        return this.yukiService.createNewInvoice(action.invoiceDTO).pipe(
            tap((document) => {
                ctx.setState(
                    patch<YukiStateModel>({
                        folders: updateItem<YukiFolder>(
                            (folder) => folder.id === action.invoiceDTO.folderId,
                            patch<YukiFolder>({
                                documents: append<YukiDocument>([document]),
                            }),
                        ),
                    }),
                );
            }),
        );
    }
}
