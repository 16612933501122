<div class="sidebar-spacing">
    <h3>Exact</h3>
</div>

<span class="divider divider--small"></span>

<nav class="app__subnav">
    <a
        [routerLink]="['/', 'exact', 'relations']"
        routerLinkActive="app__subnav__active"
        >Relations</a
    >
    <a
        [routerLink]="['/', 'exact', 'products']"
        routerLinkActive="app__subnav__active"
        >Products</a
    >
    <a
        [routerLink]="['/', 'exact', 'invoices']"
        routerLinkActive="app__subnav__active"
        >Invoices</a
    >
    <a
        [routerLink]="['/', 'exact', 'payments']"
        routerLinkActive="app__subnav__active"
        >Payments</a
    >
    <a
        [routerLink]="['/', 'exact', 'orders']"
        routerLinkActive="app__subnav__active"
        >Orders</a
    >
</nav>
