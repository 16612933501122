import { DragDropModule } from '@angular/cdk/drag-drop';
import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';
import { NgxGalleryModule } from '@kolkov/ngx-gallery';
import { NgbDropdownModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { PasswordStrengthMeterModule } from 'angular-password-strength-meter';
import { MatTableExporterModule } from 'mat-table-exporter';
import { NgChartsModule } from 'ng2-charts';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { TagInputModule } from 'ngx-chips';
import { FilterPipeModule } from 'ngx-filter-pipe';
import { MomentModule } from 'ngx-moment';
import { OrderModule } from 'ngx-order-pipe';
import { NgPipesModule } from 'ngx-pipes';
import { NgxTrimDirectiveModule } from 'ngx-trim-directive';

import { DetailProjectsListComponent } from '../partners/detail-projects-list/detail-projects-list.component';
import { AddLaneModalComponent } from './add-lane-modal/add-lane-modal.component';
import { AddQueryModalComponent } from './add-query-modal/add-query-modal.component';
import { AppHolderComponent } from './app-holder/app-holder.component';
import { AssignedEmployeeDeleteModalComponent } from './assigned-employee-delete-modal/assigned-employee-delete-modal.component';
import { AssignedEmployeeListComponent } from './assigned-employee-list/assigned-employee-list.component';
import { DefaultLineChartComponent } from './charts/default-line-chart/default-line-chart.component';
import { ColorPaletteListComponent } from './color-palette-list/color-palette-list.component';
import { CommentViewComponent } from './comments/comment-view/comment-view.component';
import { CommentsOverviewComponent } from './comments/comments-overview/comments-overview.component';
import { ConfiguratorPreviewComponent } from './configurator-preview/configurator-preview.component';
import { ConfirmDialogComponent } from './confirm-dialog/dialog.component';
import { ConfirmationModalComponent } from './confirmation-modal/confirmation-modal.component';
import { ContactInformationFieldComponent } from './contact-information-field/contact-information-field.component';
import { ContentComponent } from './content/content.component';
import { DateNavigationComponent } from './date-navigation/date-navigation.component';
import { DescriptionComponent } from './description/description.component';
import { CachedSrcDirective } from './directives/cached-src.directive';
import { DynamicMatTableComponent } from './dynamic-mat-table/dynamic-mat-table.component';
import { EditQueryModalComponent } from './edit-query-modal/edit-query-modal.component';
import { EmployeeAssignModalComponent } from './employee-assign-modal/employee-assign-modal.component';
import { DndDirective } from './file-upload/dnd.directive';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { ForbiddenPageComponent } from './forbidden-page/forbidden-page.component';
import { GlobalAutocompleteComponent } from './global-autocomplete/global-autocomplete.component';
import { GlobalExportCsvComponent } from './global-export-csv/global-export-csv.component';
import { AddChildLinkConfirmFromTimesheetsModalComponent } from './global-files/add-child-link-confirm-from-timesheets-modal/add-child-link-confirm-from-timesheets-modal.component';
import { AddChildLinkModalComponent } from './global-files/add-child-link-modal/add-child-link-modal.component';
import { AddFileToObjectModalComponent } from './global-files/add-file-to-object-modal/add-file-to-object-modal.component';
import { GlobalFilesComponent } from './global-files/global-files.component';
import { LinkGlobalFileModalComponent } from './global-files/link-global-file-modal/link-global-file-modal.component';
import { LinkObjectToFileModalComponent } from './global-files/link-object-to-file-modal/link-object-to-file-modal.component';
import { LinkedInvoicesToObjectComponent } from './global-files/linked-invoices-to-object/linked-invoices-to-object.component';
import { UpdateDescriptionOfDocumentModalComponent } from './global-files/update-description-of-document-modal/update-description-of-document-modal.component';
import { UploadedDocumentDetailComponent } from './global-files/uploaded-document-detail/uploaded-document-detail.component';
import { AddLinkModalComponent } from './global-link-autocomplete/add-link-modal/add-link-modal.component';
import { GlobalLinkAutocompleteComponent } from './global-link-autocomplete/global-link-autocomplete.component';
import { GlobalSimpleTextInputModalComponent } from './global-simple-text-input-modal/global-simple-text-input-modal.component';
import { BulkAddTagModalComponent } from './global-tags/bulk-add-tag-modal/bulk-add-tag-modal.component';
import { GlobalTagsComponent } from './global-tags/global-tags.component';
import { GoogleLoginComponent } from './google-login/google-login.component';
import { HomeToolbarComponent } from './home-toolbar/home-toolbar.component';
import { HomepageComponent } from './homepage/homepage.component';
import { ImageGalleryComponent } from './image-gallery/image-gallery.component';
import { IntegrationDetailModalComponent } from './integration-detail-modal/integration-detail-modal.component';
import { IsPermittedDirective } from './is-permitted.directive';
import { LinkContactsAutocompleteComponent } from './link-contacts-autocomplete/link-contacts-autocomplete.component';
import { LinkPartnerAutocompleteComponent } from './link-partner-autocomplete/link-partner-autocomplete.component';
import { DocumentsListComponent } from './lists/documents-list/documents-list.component';
import { LinkDocumentModalComponent } from './lists/documents-list/link-document-modal/link-document-modal.component';
import { AddEmployeeModalComponent } from './lists/related-employees-list/add-employee-modal/add-employee-modal.component';
import { DeleteEmployeeComponent } from './lists/related-employees-list/delete-employee-modal/delete-employee.component';
import { RelatedEmployeesListComponent } from './lists/related-employees-list/related-employees-list.component';
import { LoadingMessageComponent } from './loading-message/loading-message.component';
import { ComanageLoginComponent } from './login-components/comanage-login/comanage-login.component';
import { ExactLoginComponent } from './login-components/exact-login/exact-login.component';
import { JiraLoginComponent } from './login-components/jira-login/jira-login.component';
import { MailchimpLoginComponent } from './login-components/mailchimp-login/mailchimp-login.component';
import { OutlookLoginComponent } from './login-components/outlook-login/outlook-login.component';
import { TeamleaderLoginComponent } from './login-components/teamleader-login/teamleader-login.component';
import { YukiLoginComponent } from './login-components/yuki-login/yuki-login.component';
import { LogoComponent } from './logo/logo.component';
import { AddRecipientModalComponent } from './mailchimp/add-recipient-modal/add-recipient-modal.component';
import { AddRecipientsToListModalComponent } from './mailchimp/add-recipients-to-list-modal/add-recipients-to-list-modal.component';
import { RecipientInfoModalComponent } from './mailchimp/recipient-info-modal/recipient-info-modal.component';
import { MailingListComponent } from './mailing-list/mailing-list.component';
import { MessageEmptyComponent } from './message-empty/message-empty.component';
import { NavbarComponent } from './navbar/navbar.component';
import { NoSearchResultsComponent } from './no-search-results/no-search-results.component';
import { NotFoundPageComponent } from './not-found-page/not-found-page.component';
import { ComanageNotLoggedInComponent } from './not-logged-in/comanage-not-logged-in/comanage-not-logged-in.component';
import { ExactNotLoggedInComponent } from './not-logged-in/exact-not-logged-in/exact-not-logged-in.component';
import { GmailNotLoggedInComponent } from './not-logged-in/gmail-not-logged-in/gmail-not-logged-in.component';
import { MailchimpNotLoggedInComponent } from './not-logged-in/mailchimp-not-logged-in/mailchimp-not-logged-in.component';
import { OutlookNotLoggedInComponent } from './not-logged-in/outlook-not-logged-in/outlook-not-logged-in.component';
import { TeamleaderNotLoggedInComponent } from './not-logged-in/teamleader-not-logged-in/teamleader-not-logged-in.component';
import { PasswordInputComponent } from './password-input/password-input.component';
import { PasswordModalComponent } from './password-modal/password-modal.component';
import { PdfDetailModalComponent } from './pdf-detail-modal/pdf-detail-modal.component';
import { PercentageProgressComponent } from './percentage-progress/percentage-progress.component';
import { PillAddressComponent } from './pills/pill-address/pill-address.component';
import { PillApplicantComponent } from './pills/pill-applicant/pill-applicant.component';
import { PillBudgetComponent } from './pills/pill-budget/pill-budget.component';
import { PillColoredStringComponent } from './pills/pill-colored-string/pill-colored-string.component';
import { PillComanageOfferComponent } from './pills/pill-comanage-offer/pill-comanage-offer.component';
import { PillContactComponent } from './pills/pill-contact/pill-contact.component';
import { PillCrmdealComponent } from './pills/pill-crmdeal/pill-crmdeal.component';
import { PillCustomerComponent } from './pills/pill-customer/pill-customer.component';
import { PillDescriptionComponent } from './pills/pill-description/pill-description/pill-description.component';
import { PillDocumentComponent } from './pills/pill-document/pill-document.component';
import { PillEmployeeComponent } from './pills/pill-employee/pill-employee.component';
import { PillFacebookComponent } from './pills/pill-facebook/pill-facebook.component';
import { PillGithubComponent } from './pills/pill-github/pill-github.component';
import { PillGlobalFileComponent } from './pills/pill-global-file/pill-global-file.component';
import { PillGlobalTagComponent } from './pills/pill-global-tag/pill-global-tag.component';
import { PillInstagramComponent } from './pills/pill-instagram/pill-instagram.component';
import { PillInvoiceComponent } from './pills/pill-invoice/pill-invoice.component';
import { PillLeadComponent } from './pills/pill-lead/pill-lead.component';
import { PillLinkedinComponent } from './pills/pill-linkedin/pill-linkedin.component';
import { PillMailchimpComponent } from './pills/pill-mailchimp/pill-mailchimp.component';
import { PillMailtoComponent } from './pills/pill-mailto/pill-mailto.component';
import { PillMemberComponent } from './pills/pill-member/pill-member.component';
import { PillOverheadCategoryComponent } from './pills/pill-overhead-category/pill-overhead-category.component';
import { PillPartnerComponent } from './pills/pill-partner/pill-partner.component';
import { PillPdfComponent } from './pills/pill-pdf/pill-pdf.component';
import { PillProductComponent } from './pills/pill-product/pill-product.component';
import { PillProjectComponent } from './pills/pill-project/pill-project.component';
import { PillTelephoneComponent } from './pills/pill-telephone/pill-telephone.component';
import { PillTimesheetComponent } from './pills/pill-timesheet/pill-timesheet.component';
import { PillUnitComponent } from './pills/pill-unit/pill-unit.component';
import { PillUploadedDocumentComponent } from './pills/pill-uploaded-document/pill-uploaded-document.component';
import { PillUserComponent } from './pills/pill-user/pill-user.component';
import { PillWarehouseLocationComponent } from './pills/pill-warehouse-location/pill-warehouse-location.component';
import { PillWarehouseComponent } from './pills/pill-warehouse/pill-warehouse.component';
import { PillUrlComponent } from './pills/pill-website/pill-url.component';
import { PillWhatsappComponent } from './pills/pill-whatsapp/pill-whatsapp.component';
import { PillYoutubeComponent } from './pills/pill-youtube/pill-youtube.component';
import { AbbreviationPipe } from './pipes/abbreviation.pipe';
import { ArrayFilterPipe } from './pipes/array-filter.pipe';
import { MinutesToMinutesAndHoursPipe } from './pipes/minutes-to-minutes-and-hours.pipe';
import { PrimaryMessageComponent } from './primary-message/primary-message.component';
import { QueryDeleteModalComponent } from './query-delete-modal/query-delete-modal.component';
import { QueryErrorComponent } from './query-error/query-error.component';
import { EditReasonModalComponent } from './reason-of-loss/edit-reason-modal/edit-reason-modal.component';
import { ReasonOfLossGraphComponent } from './reason-of-loss/reason-of-loss-graph/reason-of-loss-graph.component';
import { ReasonOfLossListComponent } from './reason-of-loss/reason-of-loss-list/reason-of-loss-list.component';
import { SubmitReasonModalComponent } from './reason-of-loss/submit-reason-modal/submit-reason-modal.component';
import { SearchbarComponent } from './searchbar/searchbar.component';
import { SessionHeaderComponent } from './session-header/session-header.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { TableSettingsComponent } from './table-settings/table-settings.component';
import { TagSearchbarComponent } from './tag-searchbar/tag-searchbar.component';
import { UpdateYukiSettingsModalComponent } from './update-yuki-settings-modal/update-yuki-settings-modal.component';
import { YukiSettingsComponent } from './yuki-settings/yuki-settings.component';
import { YukiFileComponent } from './yuki/file/yuki-file.component';

@NgModule({
    declarations: [
        AppHolderComponent,
        ContentComponent,
        LogoComponent,
        MessageEmptyComponent,
        NavbarComponent,
        ConfirmDialogComponent,
        SessionHeaderComponent,
        SidebarComponent,
        LoadingMessageComponent,
        ForbiddenPageComponent,
        NotFoundPageComponent,
        HomepageComponent,
        HomeToolbarComponent,
        FileUploadComponent,
        SearchbarComponent,
        TagSearchbarComponent,
        NoSearchResultsComponent,
        ColorPaletteListComponent,
        IntegrationDetailModalComponent,
        PillContactComponent,
        PillYoutubeComponent,
        PillUrlComponent,
        PillWhatsappComponent,
        PillFacebookComponent,
        PillGithubComponent,
        PillLinkedinComponent,
        PillMailtoComponent,
        PillTelephoneComponent,
        PillMemberComponent,
        PillPartnerComponent,
        PillUserComponent,
        PillEmployeeComponent,
        PillAddressComponent,
        PillWarehouseComponent,
        PillWarehouseLocationComponent,
        PillProductComponent,
        PillMailchimpComponent,
        PillLeadComponent,
        MailchimpLoginComponent,
        RecipientInfoModalComponent,
        AddRecipientModalComponent,
        MailingListComponent,
        GmailNotLoggedInComponent,
        OutlookNotLoggedInComponent,
        GoogleLoginComponent,
        ConfiguratorPreviewComponent,
        ExactLoginComponent,
        MailchimpNotLoggedInComponent,
        TeamleaderNotLoggedInComponent,
        ExactNotLoggedInComponent,
        TeamleaderLoginComponent,
        AddRecipientsToListModalComponent,
        ConfirmationModalComponent,
        AssignedEmployeeListComponent,
        EmployeeAssignModalComponent,
        AssignedEmployeeDeleteModalComponent,
        DocumentsListComponent,
        LinkDocumentModalComponent,
        OutlookLoginComponent,
        CommentsOverviewComponent,
        CommentViewComponent,
        ComanageLoginComponent,
        ComanageNotLoggedInComponent,
        PillProjectComponent,
        PillDocumentComponent,
        DetailProjectsListComponent,
        GlobalTagsComponent,
        PillCrmdealComponent,
        RelatedEmployeesListComponent,
        AddEmployeeModalComponent,
        DeleteEmployeeComponent,
        PillTimesheetComponent,
        MinutesToMinutesAndHoursPipe,
        PillPdfComponent,
        PdfDetailModalComponent,
        ArrayFilterPipe,
        LinkContactsAutocompleteComponent,
        LinkPartnerAutocompleteComponent,
        AddQueryModalComponent,
        EditQueryModalComponent,
        DynamicMatTableComponent,
        QueryDeleteModalComponent,
        QueryErrorComponent,
        ImageGalleryComponent,
        IsPermittedDirective,
        GlobalAutocompleteComponent,
        PillInstagramComponent,
        PillGlobalTagComponent,
        DndDirective,
        PillCustomerComponent,
        GlobalLinkAutocompleteComponent,
        PillComanageOfferComponent,
        PillUnitComponent,
        AddLinkModalComponent,
        GlobalSimpleTextInputModalComponent,
        PillBudgetComponent,
        PrimaryMessageComponent,
        YukiLoginComponent,
        GlobalFilesComponent,
        LinkGlobalFileModalComponent,
        PillGlobalFileComponent,
        GlobalExportCsvComponent,
        LinkObjectToFileModalComponent,
        DefaultLineChartComponent,
        DescriptionComponent,
        YukiSettingsComponent,
        UpdateYukiSettingsModalComponent,
        CachedSrcDirective,
        DateNavigationComponent,
        YukiFileComponent,
        PillDescriptionComponent,
        PasswordInputComponent,
        PasswordModalComponent,
        PillOverheadCategoryComponent,
        LinkedInvoicesToObjectComponent,
        PillInvoiceComponent,
        TableSettingsComponent,
        AddChildLinkModalComponent,
        AddFileToObjectModalComponent,
        PillUploadedDocumentComponent,
        UploadedDocumentDetailComponent,
        UpdateDescriptionOfDocumentModalComponent,
        PercentageProgressComponent,
        AddChildLinkConfirmFromTimesheetsModalComponent,
        BulkAddTagModalComponent,
        TagSearchbarComponent,
        AddLaneModalComponent,
        ContactInformationFieldComponent,
        PillApplicantComponent,
        SubmitReasonModalComponent,
        EditReasonModalComponent,
        ReasonOfLossListComponent,
        ReasonOfLossGraphComponent,
        PillColoredStringComponent,
        AbbreviationPipe,
        JiraLoginComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        NgbModule,
        NgbDropdownModule,
        PasswordStrengthMeterModule,
        NgxTrimDirectiveModule,
        TagInputModule,
        NgPipesModule,
        OrderModule,
        FilterPipeModule,
        AutocompleteLibModule,
        CdkTableModule,
        MatTableModule,
        MatSortModule,
        PdfViewerModule,
        NgxGalleryModule,
        MomentModule,
        MatTableExporterModule,
        NgChartsModule,
        DragDropModule,
        MatProgressSpinnerModule,
    ],
    exports: [
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        AppHolderComponent,
        ContentComponent,
        LogoComponent,
        MessageEmptyComponent,
        NavbarComponent,
        SessionHeaderComponent,
        SidebarComponent,
        LoadingMessageComponent,
        FileUploadComponent,
        SearchbarComponent,
        TagSearchbarComponent,
        NoSearchResultsComponent,
        ColorPaletteListComponent,
        NgPipesModule,
        OrderModule,
        PillContactComponent,
        PillYoutubeComponent,
        PillUrlComponent,
        PillWhatsappComponent,
        PillFacebookComponent,
        PillGithubComponent,
        PillLinkedinComponent,
        PillMailtoComponent,
        PillTelephoneComponent,
        PillMemberComponent,
        PillPartnerComponent,
        PillMailchimpComponent,
        PillUserComponent,
        PillEmployeeComponent,
        PillAddressComponent,
        PillWarehouseComponent,
        PillWarehouseLocationComponent,
        PillProductComponent,
        PillLeadComponent,
        PillProjectComponent,
        PillTimesheetComponent,
        MailingListComponent,
        RecipientInfoModalComponent,
        AddRecipientModalComponent,
        GmailNotLoggedInComponent,
        OutlookNotLoggedInComponent,
        ConfiguratorPreviewComponent,
        MailchimpNotLoggedInComponent,
        TeamleaderNotLoggedInComponent,
        ExactNotLoggedInComponent,
        DocumentsListComponent,
        OutlookLoginComponent,
        AssignedEmployeeListComponent,
        CommentsOverviewComponent,
        ComanageNotLoggedInComponent,
        DetailProjectsListComponent,
        GlobalTagsComponent,
        PillCrmdealComponent,
        RelatedEmployeesListComponent,
        MinutesToMinutesAndHoursPipe,
        PillPdfComponent,
        ArrayFilterPipe,
        LinkContactsAutocompleteComponent,
        LinkPartnerAutocompleteComponent,
        AddQueryModalComponent,
        DynamicMatTableComponent,
        QueryDeleteModalComponent,
        QueryErrorComponent,
        ImageGalleryComponent,
        IsPermittedDirective,
        GlobalAutocompleteComponent,
        GlobalLinkAutocompleteComponent,
        PillInstagramComponent,
        PillGlobalTagComponent,
        DndDirective,
        PillCustomerComponent,
        PillComanageOfferComponent,
        PillUnitComponent,
        PillBudgetComponent,
        PrimaryMessageComponent,
        GlobalFilesComponent,
        YukiLoginComponent,
        PillGlobalFileComponent,
        GlobalExportCsvComponent,
        DefaultLineChartComponent,
        DescriptionComponent,
        YukiSettingsComponent,
        CachedSrcDirective,
        DateNavigationComponent,
        YukiFileComponent,
        PasswordInputComponent,
        LinkObjectToFileModalComponent,
        PillOverheadCategoryComponent,
        LinkedInvoicesToObjectComponent,
        TableSettingsComponent,
        PercentageProgressComponent,
        ContactInformationFieldComponent,
        PillApplicantComponent,
        EditReasonModalComponent,
        ReasonOfLossListComponent,
        ReasonOfLossGraphComponent,
        SubmitReasonModalComponent,
        PillColoredStringComponent,
        PillInvoiceComponent,
        AbbreviationPipe,
    ],
    providers: [],
})
export class SharedModule {}
