<app-searchbar
    [(value)]="filter"
    (valueChange)="applyFilter($event)"></app-searchbar>

<div class="m-t-4">
    <div
        class="table__overflow"
        *ngIf="partners$ | async">
        <table
            mat-table
            [dataSource]="dataSource"
            matSort>
            <ng-container matColumnDef="title">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header>
                    Company
                </th>
                <td
                    mat-cell
                    *matCellDef="let row">
                    <app-pill-partner [partner]="row"></app-pill-partner>
                </td>
            </ng-container>

            <ng-container matColumnDef="amountOfContactPersons">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header>
                    Contacts
                </th>
                <td
                    mat-cell
                    *matCellDef="let row">
                    {{ row.amountOfContactPersons }}
                </td>
            </ng-container>

            <ng-container matColumnDef="edit">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    class="td-small">
                    <span ngbDropdown>
                        <button
                            ngbDropdownToggle
                            class="button button--icon button--dropdown button--small m-l-1"
                            (click)="$event.stopPropagation()"></button>
                        <span
                            ngbDropdownMenu
                            class="dropdown-menu dropdown-menu-right"
                            aria-labelledby="dropdownMenuButton">
                            <app-table-settings
                                [tableId]="'partner-list'"
                                [allColumns]="getAllColumns()"
                                [defaultColumns]="getDefaultColumns()"></app-table-settings>
                        </span>
                    </span>
                </th>
                <td
                    mat-cell
                    *matCellDef="let row">
                    <span
                        ngbDropdown
                        *appIsPermitted="{
                            module: 'PARTNERS',
                            operator: 'OR',
                            actions: ['EDIT', 'DELETE']
                        }">
                        <button
                            ngbDropdownToggle
                            class="button button--icon button--dropdown button--small"></button>
                        <span
                            ngbDropdownMenu
                            class="dropdown-menu dropdown-menu-right"
                            aria-labelledby="dropdownMenuButton">
                            <a
                                class="dropdown-item"
                                *appIsPermitted="{
                                    module: 'PARTNERS',
                                    operator: 'AND',
                                    actions: ['EDIT']
                                }"
                                (click)="openUpdatePartnerModal(row)"
                                >Edit company</a
                            >
                            <a
                                class="dropdown-item"
                                *appIsPermitted="{
                                    module: 'CONTACTS',
                                    operator: 'AND',
                                    actions: ['CREATE']
                                }"
                                (click)="openAddContactPersonModal(row.uuid)"
                                >Add contact</a
                            >
                            <div
                                class="dropdown-divider"
                                *appIsPermitted="{
                                    module: 'PARTNERS',
                                    operator: 'AND',
                                    actions: ['DELETE', 'EDIT']
                                }"></div>
                            <a
                                class="dropdown-item color--red"
                                *appIsPermitted="{
                                    module: 'PARTNERS',
                                    operator: 'AND',
                                    actions: ['DELETE']
                                }"
                                (click)="openDeletePartnerModal(row)"
                                >Archive company</a
                            >
                        </span>
                    </span>
                </td>
            </ng-container>

            <tr
                mat-header-row
                *matHeaderRowDef="displayedColumns"></tr>
            <tr
                mat-row
                *matRowDef="let row; columns: displayedColumns"></tr>

            <tr *matNoDataRow>
                <td colspan="2">No companies matching the filter "{{ filter }}"</td>
            </tr>
        </table>
    </div>
</div>
