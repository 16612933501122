<div class="dashboard__tile__title">
    <span>Newest leads</span>
    <button
        type="button"
        class="button button--icon button--small"
        (click)="goToLeads()">
        <i class="uil uil-arrow-right"></i>
    </button>
</div>
<div class="dashboard__tile__content">
    <div class="table__overflow">
        <table
            mat-table
            [dataSource]="dataSource"
            matSort
            [hidden]="!(this.dataSource?.data?.length > 0)"
            matSortActive="createdOn"
            matSortDirection="desc">
            <ng-container matColumnDef="email">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header>
                    Lead
                </th>
                <td
                    mat-cell
                    *matCellDef="let lead">
                    <app-pill-lead [lead]="lead"></app-pill-lead>
                </td>
            </ng-container>

            <ng-container matColumnDef="channelKey">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header>
                    Channel
                </th>
                <td
                    mat-cell
                    *matCellDef="let lead">
                    <span>{{ lead.channelKey }}</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="createdOn">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header>
                    Added
                </th>
                <td
                    mat-cell
                    *matCellDef="let lead">
                    <span>{{ lead.createdOn | amTimeAgo }}</span>
                </td>
            </ng-container>

            <tr
                mat-header-row
                *matHeaderRowDef="displayedColumns"></tr>
            <tr
                mat-row
                *matRowDef="let lead; columns: displayedColumns"></tr>
        </table>
    </div>
    <div
        class="dashboard__tile__content"
        *ngIf="this.noLeads && !this.isLoading">
        <app-message-empty
            [title]="'No new leads'"
            [subtitle]="'Start collecting leads!'"
            [icon]="'../../assets/img/open-box.png'"></app-message-empty>
    </div>

    <div *ngIf="isLoading">
        <app-loading-message></app-loading-message>
    </div>
</div>
