import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ExactInvoicesComponent } from './exact-invoices/exact-invoices.component';
import { ExactOrdersComponent } from './exact-orders/exact-orders.component';
import { ExactPaymentsComponent } from './exact-payments/exact-payments.component';
import { ExactProductsComponent } from './exact-products/exact-products.component';
import { ExactRelationsComponent } from './exact-relations/exact-relations.component';

export const routes: Routes = [
    { path: '', redirectTo: 'relations', pathMatch: 'full' },
    { path: 'relations', component: ExactRelationsComponent },
    { path: 'products', component: ExactProductsComponent },
    { path: 'invoices', component: ExactInvoicesComponent },
    { path: 'payments', component: ExactPaymentsComponent },
    { path: 'orders', component: ExactOrdersComponent },
];

export const routing: ModuleWithProviders<any> = RouterModule.forChild(routes);
