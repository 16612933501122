import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';

import { OverheadCategory } from '../../models/OverheadCategory';
import { UpdateOverheadCategory } from '../yuki.actions';

@Component({
    selector: 'app-update-overhead-category-name-modal',
    templateUrl: './update-overhead-category-name-modal.component.html',
    styleUrls: ['./update-overhead-category-name-modal.component.scss'],
})
export class UpdateOverheadCategoryNameModalComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Input() overheadCategory: OverheadCategory;
    newCategoryName: string;
    excludeFromFinances: boolean;

    constructor(public activeModal: NgbActiveModal, private store: Store, private logger: NGXLogger, private toastr: ToastrService) {}

    ngOnInit(): void {
        this.newCategoryName = this.overheadCategory.categoryName;
        this.excludeFromFinances = this.overheadCategory.excludeFromFinances;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    updateCategoryName() {
        this.overheadCategory.categoryName = this.newCategoryName;
        this.overheadCategory.excludeFromFinances = this.excludeFromFinances;
        this.subscriptions.add(
            this.store.dispatch(new UpdateOverheadCategory(this.overheadCategory)).subscribe(
                () => {
                    this.toastr.success('Successfully added overhead categories');
                    this.activeModal.close();
                },
                () => {
                    this.logger.error('Failed to add overhead categories');
                    this.toastr.error('Oops, something went wrong, Please try again later...');
                },
            ),
        );
    }
}
