<app-app-holder>
    <app-navbar></app-navbar>

    <app-sidebar>
        <div class="sidebar-spacing">
            <h3>Inventory</h3>
            <small>Stockmanagement</small>
        </div>

        <span class="divider divider--small"></span>

        <app-inventory-subnav (selectedCategory)="changeSelectedCategory($event)"></app-inventory-subnav>
    </app-sidebar>

    <app-content>
        <div class="app__title">
            <h1>
                Products overview
                <span *ngIf="allProducts$ | async as allProducts"> ({{ allProducts.length }}) </span>
            </h1>
            <span>
                <a
                    class="button button--primary"
                    *appIsPermitted="{
                        module: 'INVENTORY',
                        operator: 'AND',
                        actions: ['CREATE']
                    }"
                    (click)="openAddProductModal()"
                    >New product</a
                >
            </span>
        </div>

        <span
            *appIsPermitted="{
                module: 'INVENTORY',
                operator: 'AND',
                actions: ['READ']
            }">
            <div *ngIf="allProducts$ | async as allProducts; else loading">
                <div *ngIf="allProducts">
                    <app-product-list
                        [selectedCategory]="this.selectedCategory"
                        *ngIf="allProducts.length > 0; else empty"></app-product-list>
                </div>

                <app-loading-message *ngIf="isLoading"></app-loading-message>
            </div>
        </span>
    </app-content>

    <ng-template #loading>
        <app-loading-message></app-loading-message>
    </ng-template>

    <ng-template #empty>
        <app-message-empty
            [title]="'No products...'"
            [subtitle]="'Start creating products!'"
            [icon]="'../../assets/img/open-box.png'">
        </app-message-empty>
    </ng-template>
</app-app-holder>
