import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subscription } from 'rxjs';
import Project from 'src/app/models/Project';
import { ProjectDetailModalComponent } from 'src/app/projects/project-detail-modal/project-detail-modal.component';
import { ProjectState } from 'src/app/projects/project.state';

@Component({
    selector: 'app-dashboard-projects-widget',
    templateUrl: './dashboard-projects-widget.component.html',
    styleUrls: ['./dashboard-projects-widget.component.scss'],
})
export class DashboardProjectsWidgetComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Select(ProjectState.projects) allProjects$: Observable<Project[]>;

    amountToDisplay = 10;
    isLoading = true;
    noProjects = false;

    displayedColumns: string[] = ['title', 'partnerName', 'startDate'];
    dataSource: MatTableDataSource<Project>;
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    constructor(private modalService: NgbModal, private router: Router, private logger: NGXLogger) {}

    ngOnInit(): void {
        this.subscriptions.add(
            this.allProjects$.subscribe(
                (allProjects) => {
                    if (allProjects && allProjects.length == 0) {
                        this.logger.debug('Retrieved 0 projects from the state');
                        this.isLoading = false;
                        this.noProjects = true;
                    } else if (allProjects && allProjects.length > 0) {
                        this.logger.debug('Retrieved projects from the state');
                        const latestProjects = allProjects.slice(-this.amountToDisplay);
                        this.dataSource = new MatTableDataSource(latestProjects);
                        this.dataSource.sort = this.sort;
                        this.isLoading = false;
                    }
                },
                (error) => {
                    this.logger.error('Failed to retrieve projects');
                },
            ),
        );
    }

    openAddProjectModalComponent() {
        this.logger.debug('Opening projectdetailmodalcomponent');
        const modalRef = this.modalService.open(ProjectDetailModalComponent, {
            windowClass: 'window-pane',
        });
    }

    goToProjects() {
        this.logger.debug('Navigating to projects overview');
        this.router.navigate(['/', 'projects', 'overview']);
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
