import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { formatDistanceToNow } from 'date-fns';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import YukiFolder from 'src/app/models/yuki/YukiFolder';

import { YukiSettings } from '../../models/yuki/YukiSettings';
import { CreateNewInvoiceModalComponent } from '../create-new-invoice-modal/create-new-invoice-modal.component';
import { InvoicesSettingsModalComponent } from '../invoices-settings-modal/invoices-settings-modal.component';
import { FetchFolders, FetchYukiSettings, SelectFolder, SyncDocuments } from '../yuki.actions';
import { YukiState } from '../yuki.state';

@Component({
    selector: 'app-yuki-overview',
    templateUrl: './yuki-overview.component.html',
    styleUrls: ['./yuki-overview.component.scss'],
})
export class YukiOverviewComponent implements OnInit, OnDestroy {
    @Select(YukiState.folders) folders$: Observable<YukiFolder[]>;
    folders: YukiFolder[] = [];
    @Select(YukiState.currentFolder) currentFolder$: Observable<YukiFolder>;
    selectedFolder: YukiFolder = null;
    @Select(YukiState.yukiSettings) yukiSettings$: Observable<YukiSettings>;
    lastYukiSync: string;
    isSyncingDocuments = false;
    private subscriptions = new Subscription();

    constructor(private store: Store, private titleService: Title, private toastr: ToastrService, private modalService: NgbModal) {}

    ngOnInit(): void {
        this.titleService.setTitle('Yuki');
        this.fetchData();
    }

    selectFolder(folder: YukiFolder) {
        this.store.dispatch(new SelectFolder(folder.id));
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    syncDocuments() {
        this.isSyncingDocuments = true;
        this.store.dispatch(new SyncDocuments()).subscribe({
            next: () => {
                this.isSyncingDocuments = false;
                this.store.dispatch(new SelectFolder(this.selectedFolder.id));
                this.toastr.success('Successfully synced Yuki Documents');
            },
            error: () => {
                this.isSyncingDocuments = false;
                this.toastr.error('Oops, something went wrong, Please try again later...');
            },
        });
    }

    openYukiSettingsModal() {
        this.modalService.open(InvoicesSettingsModalComponent, {
            windowClass: 'modal-pane',
            animation: false,
        });
    }

    openCreateInvoiceModal() {
        const modalRef = this.modalService.open(CreateNewInvoiceModalComponent, { windowClass: 'modal-pane', animation: false });
        modalRef.componentInstance.currentFolderId = this.selectedFolder.id;
    }

    private fetchData() {
        this.subscriptions.add(
            this.folders$.subscribe((folders) => {
                if (!folders) {
                    this.store.dispatch(new FetchFolders());
                    return;
                }

                if (folders) {
                    this.folders = folders;
                    if (!this.selectedFolder && this.folders.length > 0)
                        this.selectFolder(this.folders.find((folder) => folder.description.toLowerCase() === 'aankoop'));
                }
            }),
        );

        this.subscriptions.add(
            this.currentFolder$.subscribe((folder) => {
                this.selectedFolder = folder;
            }),
        );

        this.subscriptions.add(
            this.yukiSettings$.subscribe((settings) => {
                if (!settings) {
                    this.store.dispatch(new FetchYukiSettings());
                    return;
                }
                this.lastYukiSync = formatDistanceToNow(new Date(settings.lastSync));
            }),
        );
    }
}
