import FileSystemEntity from '../models/FileSystemEntity';

export class FetchRootDirectories {
    static readonly type = '[FileSystemEntity] Fetch RootDirectories';
}

export class FetchDirectoryContent {
    static readonly type = '[FileSystemEntity[]] Fetch DirectoryContent';
    constructor(public directoryId: string) {}
}

export class FetchStructure {
    static readonly type = '[any] Fetch structure';
}

export class AddRootDirectory {
    static readonly type = '[FileSystemEntity] Add RootDirectory';
    constructor(public name: string) {}
}

export class AddDirectory {
    static readonly type = '[FileSystemEntity] Add Directory';
    constructor(public parentId: string, public name: string) {}
}

export class Navigate {
    static readonly type = '[FileSystemEntity] Fetch DirectoryById';
    constructor(public uuid: string) {}
}

export class AddDocument {
    static readonly type = '[FileSystemEntity] Add Document';
    constructor(public parentId: string, public name: string) {}
}

export class UpdateDirectory {
    static readonly type = '[FileSystemEntity] Update Directory';
    constructor(public directory: FileSystemEntity) {}
}

export class UpdateDocument {
    static readonly type = '[FileSystemEntity] Update Document';
    constructor(public document: FileSystemEntity) {}
}

export class DeleteFileSystemEntity {
    static readonly type = '[FileSystemEntity] Delete FileSystemEntity';
    constructor(public entity: FileSystemEntity) {}
}
