import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subscription } from 'rxjs';
import { LeadDetailComponent } from 'src/app/leads/lead-detail/lead-detail.component';
import { UpdateReadOfLead } from 'src/app/leads/lead.actions';
import { LeadState } from 'src/app/leads/lead.state';
import Lead from 'src/app/models/Lead';

@Component({
    selector: 'app-dashboard-leads-widget',
    templateUrl: './dashboard-leads-widget.component.html',
    styleUrls: ['./dashboard-leads-widget.component.scss'],
})
export class DashboardLeadsWidgetComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Select(LeadState.getLeads) allLeads$: Observable<Lead[]>;

    amountToDisplay = 10;
    isLoading = true;
    noLeads = false;

    displayedColumns: string[] = ['email', 'channelKey', 'createdOn'];
    dataSource: MatTableDataSource<Lead>;
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    constructor(private store: Store, private modalService: NgbModal, private router: Router, private logger: NGXLogger) {}

    ngOnInit(): void {
        this.subscriptions.add(
            this.allLeads$.subscribe(
                (allLeads) => {
                    if (allLeads && allLeads.length == 0) {
                        this.logger.debug('Retrieved 0 leads from the state');
                        this.noLeads = true;
                        this.isLoading = false;
                    } else if (allLeads && allLeads.length > 0) {
                        this.logger.debug('Retrieved leads from the state');
                        const latestLeads = allLeads.slice(-this.amountToDisplay);
                        this.dataSource = new MatTableDataSource(latestLeads);
                        this.dataSource.sort = this.sort;
                        this.isLoading = false;
                    }
                },
                (error) => {
                    this.logger.error('Failed to retrieve leads');
                },
            ),
        );
    }

    openLeadInfoModal(lead: Lead) {
        if (lead.read != true) {
            lead.read = true;
            this.logger.debug('Toggling lead read');

            this.toggleLeadReadInBackEnd(lead);
        }
        this.logger.debug('Opening leaddetailcomponent');
        const modalRef = this.modalService.open(LeadDetailComponent);
        modalRef.componentInstance.leadUuid = lead.uuid;
    }

    goToLeads() {
        this.logger.debug('Navigating to leads overview');
        this.router.navigate(['/', 'leads', 'overview']);
    }

    filterLeadsRead() {
        this.logger.debug('Filtering out read leads from dataSource');
        if (this.dataSource.data) {
            this.dataSource.data = this.dataSource.data.filter((lead) => lead.read == false);
        }
    }

    toggleLeadReadInBackEnd(lead: Lead) {
        this.logger.debug('Attempt to update read of lead');
        this.subscriptions.add(
            this.store.dispatch(new UpdateReadOfLead(lead)).subscribe(
                () => {
                    this.logger.debug('Succesfully updated read of lead');
                },
                (error) => {
                    this.logger.error('Failed to update read of lead');
                },
            ),
        );
        this.filterLeadsRead();
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
