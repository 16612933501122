<form
    [formGroup]="variantFormGroup"
    (ngSubmit)="saveVariant()"
    class="modal-wrapper">
    <div class="modal-header">
        <h2
            *ngIf="!isUpdate"
            class="modal-title"
            id="exampleModalLabel">
            New Resume variant
        </h2>
        <h2
            *ngIf="isUpdate"
            class="modal-title"
            id="exampleModalLabel">
            Edit Resume variant
        </h2>
        <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="activeModal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <div class="form-group required m-b-2">
            <label>Jobtitle</label>
            <input
                type="text"
                formControlName="jobTitle"
                (focus)="hideErrorMessage()"
                trim="blur" />
            <span
                class="form-error"
                *ngIf="variantFormGroup.controls.jobTitle.errors && inputIsWrong"
                >Jobtitle is required</span
            >
        </div>
    </div>

    <div class="modal-footer">
        <button
            type="button"
            class="button button--outline"
            (click)="activeModal.close()">
            Close
        </button>
        <button
            *ngIf="!isUpdate"
            type="submit"
            [ngClass]="{ 'button--loading': loading }"
            class="button button--primary">
            Add Resume variant
        </button>
        <button
            *ngIf="isUpdate"
            type="submit"
            [ngClass]="{ 'button--loading': loading }"
            class="button button--primary">
            Edit Resume variant
        </button>
    </div>
</form>
