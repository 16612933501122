<div class="modal-header">
    <h2 class="modal-title">Planned event detail</h2>
    <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <ul class="list__specs">
        <li>
            <label>Project</label>
            <span><app-pill-project [project]="selectedProject"></app-pill-project></span>
        </li>
        <li>
            <label>Employee</label>
            <span><app-pill-employee [employeeId]="employeeId"></app-pill-employee></span>
        </li>

        <li>
            <label>Start date</label>
            <span>{{ plannedEvent.start | date }}</span>
        </li>
        <li>
            <label>End date</label>
            <span>{{ plannedEvent.end | date }}</span>
        </li>
        <li>
            <label>Is all day</label>
            <span *ngIf="plannedEvent.allDay; else notAllDayTemplate">
                <i
                    class="las la-check-circle"
                    style="color: green"></i>
            </span>
            <ng-template #notAllDayTemplate
                ><i
                    class="las la-times-circle"
                    style="color: red"></i
            ></ng-template>
        </li>
        <li>
            <blockquote>
                <span
                    class="format-newlines"
                    *ngIf="plannedEvent.description"
                    >{{ plannedEvent.description }}</span
                >
                <span *ngIf="!plannedEvent.description">No description or extra information...</span>
            </blockquote>
        </li>
    </ul>
</div>

<div
    class="modal-footer"
    *appIsPermitted="{
        module: 'PROJECTS',
        operator: 'OR',
        actions: ['UPDATE', 'DELETE']
    }">
    <span
        ngbDropdown
        class="dropdown">
        <button
            ngbDropdownToggle
            class="button dropdown-toggle"
            id="dropdownMenuButton">
            Options
        </button>
        <div
            ngbDropdownMenu
            class="dropdown-menu dropdown-menu-right"
            aria-labelledby="dropdownMenuButton">
            <a
                *appIsPermitted="{
                    module: 'PROJECTS',
                    operator: 'AND',
                    actions: ['UPDATE']
                }"
                class="dropdown-item"
                (click)="updateEvent()"
                >Edit planned event</a
            >
            <div class="dropdown-divider"></div>
            <a
                *appIsPermitted="{
                    module: 'PROJECTS',
                    operator: 'AND',
                    actions: ['DELETE']
                }"
                class="dropdown-item color--red"
                (click)="deleteEvent()"
                >Delete event</a
            >
        </div>
    </span>
</div>
