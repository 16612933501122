<div class="login">
    <div class="login__holder">
        <app-session-header [header]="hasSubmitted ? 'Reset succesful' : 'Reset password'"></app-session-header>

        <div *ngIf="!hasSubmitted">
            <p class="m-b-8">{{ email }}</p>
            <form
                [formGroup]="resetPasswordFormGroup"
                (ngSubmit)="resetPassword()">
                <div class="form-group m-b-2">
                    <password-input
                        (focus)="hideErrorMessage()"
                        (keyup)="onNewPasswordChange()"
                        [id]="'newPassword'"
                        [label]="'New password'"
                        [placeholder]="'New password'"
                        [showLabel]="true"
                        formControlName="newPassword">
                    </password-input>
                    <password-strength-meter
                        [colors]="['#E24600', 'orangered', 'orange', 'yellowgreen', '#26B305']"
                        [password]="newPassword">
                    </password-strength-meter>
                    <span
                        class="form-error"
                        *ngIf="
                            resetPasswordFormGroup.controls.newPassword.errors &&
                            inputIsWrong &&
                            (resetPasswordFormGroup.controls.newPassword.dirty || resetPasswordFormGroup.controls.newPassword.touched)
                        "
                        >The password needs to be at least 8 characters long</span
                    >
                </div>
                <div class="form-group m-b-2">
                    <password-input
                        (focus)="hideErrorMessage()"
                        (keyup)="onPasswordRepeatChange()"
                        [id]="'newPasswordRepeat'"
                        [label]="'Repeat password'"
                        [placeholder]="'Repeat password'"
                        [showLabel]="true"
                        formControlName="newPasswordRepeat">
                    </password-input>

                    <password-strength-meter
                        [colors]="['#E24600', 'orangered', 'orange', 'yellowgreen', '#26B305']"
                        [password]="passwordRepeat">
                    </password-strength-meter>
                    <span
                        class="form-error"
                        *ngIf="
                            resetPasswordFormGroup.controls.newPasswordRepeat.errors &&
                            inputIsWrong &&
                            (resetPasswordFormGroup.controls.newPasswordRepeat.dirty ||
                                resetPasswordFormGroup.controls.newPasswordRepeat.touched ||
                                resetPasswordFormGroup.controls.newPassword.pristine)
                        "
                        >The password needs to be at least 8 characters long</span
                    >
                    <span
                        class="form-error"
                        *ngIf="passwordsDontMatch"
                        >Doesn't look like a match...</span
                    >

                    <span
                        class="form-error"
                        *ngIf="resetPasswordWentWrong"
                        >{{ errorMessage }}</span
                    >
                </div>

                <div class="m-b-8">
                    <button
                        type="submit"
                        [ngClass]="{ 'button--loading': loading }"
                        class="button button--primary w-100">
                        Save new password
                    </button>
                </div>
            </form>

            <div class="divider"></div>

            <p class="text-center">
                <a routerLink="/login">Login</a> -
                <a routerLink="/register">Create an account</a>
            </p>
        </div>

        <div *ngIf="hasSubmitted">
            <p class="m-b-4">You've succesfully resetted your password. Go to the login page to continue!</p>

            <a
                routerLink="/login"
                class="button button--primary w-100"
                >Login</a
            >
        </div>
    </div>
</div>
