<div class="modal-header">
    <app-loading-message *ngIf="isLoading"></app-loading-message>
    <h2
        *ngIf="!isLoading"
        class="modal-title"
        id="exampleModalLabel">
        {{ applicant.firstName + ' ' + applicant.lastName }}
    </h2>
    <button
        *ngIf="!isLoading"
        type="button"
        class="close"
        aria-label="Close"
        (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<nav
    ngbNav
    #nav="ngbNav"
    class="tabs__modal">
    <ng-container ngbNavItem>
        <a ngbNavLink>Information</a>

        <ng-template ngbNavContent>
            <ul class="list__specs">
                <li>
                    <label>Status</label><span class="tag"> {{ applicant.status }}</span>
                </li>
                <li>
                    <label>Priority</label><span [ngClass]="getBadgeClass()"> {{ applicant.priority }}</span>
                </li>
                <li *ngIf="applicant.partnerUuid"><label>Partner</label><app-pill-partner [partnerUuid]="applicant.partnerUuid"></app-pill-partner></li>
                <li><label>Last update</label>{{ applicant.lastModified | amTimeAgo }}</li>
                <li><label>Address</label><app-pill-address [address]="applicant.address"></app-pill-address></li>
                <li *ngIf="applicant.reason">
                    <div>Reason of loss</div>
                    <p class="format-newlines">&nbsp;{{ applicant.reason.reason }}</p>
                </li>
                <li>
                    <label>Tags</label>
                    <app-global-tags
                        [objectUuid]="applicant.uuid"
                        [type]="'applicant'"></app-global-tags>
                </li>

                <div
                    *ngIf="applicant.contactInformation"
                    class="divider"></div>
                <app-contact-information-field [contactInformation]="applicant.contactInformation"></app-contact-information-field>

                <span *ngIf="applicant.description">
                    <div class="divider"></div>
                    <h3>Description</h3>
                    <span [innerHtml]="applicant.description"></span>
                </span>

                <span *ngIf="applicant.files?.length > 0">
                    <div class="divider"></div>
                    <h3>Attachment(s)</h3>
                    <li *ngFor="let file of applicant.files">
                        <app-pill-pdf [url]="file"></app-pill-pdf>
                    </li>
                </span>
            </ul>
        </ng-template>
    </ng-container>
</nav>

<div
    class="modal-body"
    *ngIf="!isLoading">
    <div [ngbNavOutlet]="nav"></div>
</div>

<div
    class="modal-body"
    *ngIf="isLoading">
    <app-loading-message></app-loading-message>
</div>

<div class="modal-footer">
    <button
        type="button"
        class="button button--outline"
        (click)="activeModal.dismiss()">
        Close
    </button>

    <span
        ngbDropdown
        class="dropdown">
        <div
            class="modal-body"
            *ngIf="isLoading">
            <app-loading-message></app-loading-message>
        </div>

        <button
            ngbDropdownToggle
            class="button dropdown-toggle"
            id="dropdownMenuButton"
            *ngIf="!isLoading">
            Options
        </button>
        <div
            ngbDropdownMenu
            class="dropdown-menu dropdown-menu-right"
            aria-labelledby="dropdownMenuButton"
            *ngIf="!isLoading">
            <ng-container *ngIf="!this.archived">
                <a
                class="dropdown-item"
                *appIsPermitted="{
                    module: 'APPLICANTS',
                    operator: 'AND',
                    actions: ['EDIT']
                }"
                (click)="editApplicant()"
                >Edit applicant</a
                >
                <ng-container *ngIf="applicant.status === 'WON'">
                    <a
                        class="dropdown-item"
                        *appIsPermitted="{
                            module: 'EMPLOYEES',
                            operator: 'AND',
                            actions: ['CREATE']
                        }"
                        (click)="createEmployee()"
                        >Create employee</a
                    >
                </ng-container>
                <a
                    class="dropdown-item"
                    *appIsPermitted="{
                        module: 'CONTACTS',
                        operator: 'AND',
                        actions: ['CREATE']
                    }"
                    (click)="createContactPerson()"
                    >Create contact</a
                >
                <a
                    class="dropdown-item color--red"
                    *appIsPermitted="{
                        module: 'APPLICANTS',
                        operator: 'AND',
                        actions: ['DELETE']
                    }"
                    (click)="archiveApplicant()"
                    >Archive applicant</a
                >
            </ng-container>
            <ng-container *ngIf="this.archived">
                <a
                    class="dropdown-item"
                    *appIsPermitted="{
                        module: 'APPLICANTS',
                        operator: 'AND',
                        actions: ['CREATE']
                    }"
                    (click)="unArchiveApplicant()"
                    >Unarchive applicant</a
                >
            </ng-container>
            
        </div>
    </span>
</div>
