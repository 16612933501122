import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { AuthenticationService } from '../authentication/authentication.service';
import { Applicant } from '../models/Applicants/Applicant';

@Injectable({
    providedIn: 'root',
})
export class ApplicantService {
    httpOptions = {};

    constructor(private httpClient: HttpClient, private authenticationService: AuthenticationService) {
        this.httpOptions = authenticationService.getHeaders();
    }

    addApplicant(applicant: Applicant): Observable<Applicant> {
        return this.httpClient.post<Applicant>(environment.baseUrl + `/applicant`, applicant, this.httpOptions);
    }

    updateApplicant(applicant: Applicant): Observable<Applicant> {
        return this.httpClient.put<Applicant>(environment.baseUrl + '/applicant/' + applicant.uuid, applicant, this.httpOptions);
    }

    getAllApplicants(): Observable<Applicant[]> {
        return this.httpClient.get<Applicant[]>(environment.baseUrl + '/applicant/applicant-list', this.httpOptions);
    }

    getArchivedApplicants(): Observable<Applicant[]> {
        return this.httpClient.get<Applicant[]>(environment.baseUrl + '/applicant/archived-applicants', this.httpOptions);
    }

    archiveApplicant(uuid: string): Observable<Applicant> {
        return this.httpClient.put<Applicant>(environment.baseUrl + '/applicant/archive-applicant/' + uuid, {}, this.httpOptions);
    }

    unArchiveApplicant(uuid: string): Observable<Applicant> {
        return this.httpClient.put<Applicant>(environment.baseUrl + '/applicant/unarchive-applicant/' + uuid, {}, this.httpOptions);
    }
}
