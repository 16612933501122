import { Component, Input } from '@angular/core';
import { ContactInformation } from 'src/app/models/ContactInformation';

@Component({
    selector: 'app-contact-information-field',
    templateUrl: './contact-information-field.component.html',
    styleUrls: ['./contact-information-field.component.scss'],
})
export class ContactInformationFieldComponent {
    @Input() contactInformation: ContactInformation;
}
