import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { FetchCustomers } from 'src/app/comanage/comanage.actions';
import { FetchContacts } from 'src/app/contacts/contacts.actions';
import { ContactState } from 'src/app/contacts/contacts.state';
import { FetchCrmDeals } from 'src/app/crm/crm.actions';
import { CrmState } from 'src/app/crm/crm.state';
import { ContactPerson } from 'src/app/models/ContactPerson';
import CrmDeal from 'src/app/models/CrmDeal';
import Customer from 'src/app/models/Customer';
import { ModuleEnum } from 'src/app/models/ModuleEnum';
import Project from 'src/app/models/Project';
import { FetchProjects } from 'src/app/projects/project.actions';
import { ProjectState } from 'src/app/projects/project.state';
import { ModuleService } from 'src/app/services/module.service';
import { ConfirmationModalComponent } from 'src/app/shared/confirmation-modal/confirmation-modal.component';

import { AddCustomerModalComponent } from '../add-customer-modal/add-customer-modal.component';
import { DeleteCustomer } from '../customers.actions';
import { CustomerState } from '../customers.state';

@Component({
    selector: 'app-customer-detail',
    templateUrl: './customer-detail.component.html',
    styleUrls: ['./customer-detail.component.scss'],
})
export class CustomerDetailComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Select(CustomerState.Customers) customers$: Observable<Customer[]>;
    @Select(CrmState.CrmDeals) crmDeals$: Observable<CrmDeal[]>;
    @Select(ContactState.Contacts) contacts$: Observable<ContactPerson[]>;
    @Select(ProjectState.projects) projects$: Observable<Project[]>;

    @Input() customerUuid: string;

    customer: Customer;
    crmDeals: CrmDeal[] = [];
    projects: Project[] = [];
    contact: ContactPerson;

    isLoading = true;
    eModule = ModuleEnum;

    constructor(
        public activeModal: NgbActiveModal,
        private modalService: NgbModal,
        public moduleService: ModuleService,
        private logger: NGXLogger,
        private store: Store,
        private toastr: ToastrService,
    ) {}

    ngOnInit(): void {
        this.subscriptions.add(
            this.customers$.subscribe((customers) => {
                if (customers == null) {
                    this.store.dispatch(new FetchCustomers());
                } else {
                    this.customer = customers.find((c) => c.uuid === this.customerUuid);
                    this.isLoading = false;
                }
            }),
        );
        this.subscriptions.add(
            this.crmDeals$.subscribe((crmDeals) => {
                if (crmDeals == null) {
                    this.store.dispatch(new FetchCrmDeals());
                } else {
                    if (this.customer) {
                        this.crmDeals = crmDeals.filter((d) => {
                            if (d.customer) {
                                if (d.customer.uuid === this.customerUuid) {
                                    return d;
                                }
                            }
                        });
                    }
                }
            }),
        );
        this.subscriptions.add(
            this.contacts$.subscribe((contacts) => {
                if (contacts == null) {
                    this.store.dispatch(new FetchContacts());
                } else {
                    if (this.customer) {
                        this.contact = contacts.find((c) => c.uuid == this.customer.contactPersonUuid);
                    }
                }
            }),
        );
        this.subscriptions.add(
            this.projects$.subscribe((projects) => {
                if (projects == null) {
                    this.store.dispatch(new FetchProjects());
                } else {
                    if (this.customer) {
                        this.projects = projects.filter((p) => p.customerUuid !== this.customerUuid);
                    }
                }
            }),
        );
    }

    openUpdateCustomerModal() {
        this.logger.debug('opening addCustomerModalComponent');
        const modalRef = this.modalService.open(AddCustomerModalComponent, {
            windowClass: 'modal-pane',
            animation: false,
        });
        modalRef.componentInstance.customer = this.customer;
    }

    deleteCustomer() {
        this.logger.debug('starting remove customer flow');
        const modalRef = this.modalService.open(ConfirmationModalComponent, {
            windowClass: 'modal-prompt',
        });
        modalRef.componentInstance.type = 'Delete';
        modalRef.componentInstance.title = 'Delete customer' + this.customer.firstName;
        modalRef.componentInstance.message = 'Are you sure you want to delete ' + this.customer.firstName + ' ' + this.customer.lastName + ' as a customer?';

        this.subscriptions.add(
            modalRef.componentInstance.closeEvent.subscribe((val) => {
                if (val) {
                    this.logger.debug('attempting to delete customer');
                    this.store.dispatch(new DeleteCustomer(this.customer.uuid)).subscribe(
                        () => {
                            this.logger.debug('succesfully removed customer');
                            this.activeModal.dismiss();
                            this.toastr.info('Customer successfully deleted.');
                        },
                        (error) => {
                            this.logger.debug('failed to remove customer');
                            this.toastr.error('Oops, something went wrong please try again later.');
                        },
                    );
                }
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
