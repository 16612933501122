<app-app-holder>
    <app-navbar></app-navbar>

    <app-sidebar>
        <div class="sidebar-spacing">
            <h3>Inventory</h3>
            <small>Stockmanagement</small>
        </div>

        <span class="divider divider--small"></span>

        <app-inventory-subnav (selectedCategory)="changeSelectedCategory($event)"></app-inventory-subnav>
    </app-sidebar>

    <app-content>
        <div class="app__title">
            <h1>
                Units overview
                <span *ngIf="allUnits$ | async as allUnits"> ({{ allUnits.length }}) </span>
            </h1>
            <span>
                <a
                    class="button button--primary"
                    (click)="openUnitAddModal()"
                    >New unit</a
                >
            </span>
        </div>

        <div *ngIf="allUnits$ | async as allUnits; else loading">
            <div *ngIf="allUnits">
                <app-units-list *ngIf="allUnits.length > 0; else empty"></app-units-list>
                <!-- <div class="m-t-4" *ngIf="allUnits">

                    <table class="table-view">
                        <tr>
                            <th><i class="las la-pallet"></i> Unit</th>
                            <th class="td-small"></th>
                        </tr>
                        <tr *ngFor="let unit of allUnits | orderBy: 'title' : false : true">
                            <td *ngIf="unit.type">{{ unit.type}}</td>
                            <td>
                                <span ngbDropdown>
                                    <button ngbDropdownToggle class="button button--icon button--dropdown button--small">
                                    </button>
                                    <span ngbDropdownMenu class="dropdown-menu dropdown-menu-right"
                                        aria-labelledby="dropdownMenuButton">
                                        <a class="dropdown-item" (click)="openUnitAddModal(unit)">Edit unit</a>
                                        <a class="dropdown-item color--red" (click)="openUnitDeleteModal(unit)">Delete unit</a>
                                    </span>
                                </span>
                            </td>
                            </tr>
                    </table>
                </div> -->
            </div>
        </div>
    </app-content>

    <ng-template #loading>
        <app-loading-message></app-loading-message>
    </ng-template>

    <ng-template #empty>
        <app-message-empty
            [title]="'No units...'"
            [subtitle]="'Start creating units!'"
            [icon]="'../../assets/img/open-box.png'">
        </app-message-empty>
    </ng-template>
</app-app-holder>
