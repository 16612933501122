import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { Product } from 'src/app/models/Product';
import { ProductDetailModalComponent } from 'src/app/products/product-detail-modal/product-detail-modal.component';
import { FetchProducts } from 'src/app/products/product.actions';
import { ProductState } from 'src/app/products/product.state';

@Component({
    selector: 'app-pill-product',
    templateUrl: './pill-product.component.html',
    styleUrls: ['./pill-product.component.scss'],
})
export class PillProductComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Select(ProductState.Products) products$: Observable<Product[]>;

    @Input() productUuid: string;
    @Input() productTitle: string;
    @Input() product: Product;

    isLoading = true;
    isDeleted = false;

    constructor(private modalService: NgbModal, private store: Store, private toastr: ToastrService) {}

    ngOnInit(): void {
        if (!this.product && this.productTitle && this.productUuid) {
            this.isLoading = false;
        } else {
            if (this.product) {
                this.productUuid = this.product.uuid;
                this.productTitle = this.product.title;
                this.isLoading = false;
            }
            this.subscriptions.add(
                this.products$.subscribe((products) => {
                    if (products == null) {
                        this.store.dispatch(new FetchProducts());
                    } else {
                        const prod = products.find((p) => p.uuid === this.productUuid);
                        if (!prod) {
                            this.isDeleted = true;
                        } else {
                            this.isDeleted = false;
                            this.product = JSON.parse(JSON.stringify(prod));
                        }
                        this.isLoading = false;
                    }
                }),
            );
        }
    }

    openProductDetailModal() {
        if (this.isDeleted) {
            this.toastr.info('This product no longer exists. It was probably deleted.');
        } else {
            const modalRef = this.modalService.open(ProductDetailModalComponent, { windowClass: 'modal-pane', animation: false });
            modalRef.componentInstance.productUuid = this.product ? this.product.uuid : this.productUuid;
        }
    }

    getTitle(): string {
        return this.product ? this.product.title : this.productTitle;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
