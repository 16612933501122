import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '../authentication/auth.guard';
import { CompanyGuard } from '../companies/company.guard';
import { ProjectDetailEmployeesComponent } from './project-detail-employees/project-detail-employees.component';
import { ProjectDetailInfoComponent } from './project-detail-info/project-detail-info.component';
import { ProjectDetailPductsComponent } from './project-detail-products/project-detail-products.component';
import { ProjectDetailResumesComponent } from './project-detail-resumes/project-detail-resumes.component';
import { ProjectsOverviewComponent } from './projects-overview/projects-overview.component';

export const routes: Routes = [
    { path: '', redirectTo: 'projects/overview', pathMatch: 'full' },
    {
        path: 'projects/overview',
        component: ProjectsOverviewComponent,
        canActivate: [AuthGuard, CompanyGuard],
    },
    {
        path: 'projects/:id/info',
        component: ProjectDetailInfoComponent,
        canActivate: [AuthGuard, CompanyGuard],
    },
    {
        path: 'projects/:id/employees',
        component: ProjectDetailEmployeesComponent,
        canActivate: [AuthGuard, CompanyGuard],
    },
    {
        path: 'projects/:id/resumes',
        component: ProjectDetailResumesComponent,
        canActivate: [AuthGuard, CompanyGuard],
    },
    {
        path: 'projects/:id/products',
        component: ProjectDetailPductsComponent,
        canActivate: [AuthGuard, CompanyGuard],
    },
];

export const routing: ModuleWithProviders<any> = RouterModule.forChild(routes);
