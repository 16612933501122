import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { ProductCategory } from 'src/app/models/ProductCategory';
import { GlobalSimpleTextInputModalComponent } from 'src/app/shared/global-simple-text-input-modal/global-simple-text-input-modal.component';

import { AddProductCategory, DeleteProductCategories, FetchProducts } from '../product.actions';
import { ProductState } from '../product.state';

@Component({
    selector: 'app-manage-categories',
    templateUrl: './manage-categories.component.html',
    styleUrls: ['./manage-categories.component.scss'],
})
export class ManageCategoriesComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Select(ProductState.ProductCategories) productCategories$: Observable<string[]>;

    displayedColumns: string[] = ['category', 'edit'];
    dataSource: MatTableDataSource<ProductCategory> = new MatTableDataSource<ProductCategory>();
    @ViewChild(MatSort) sort: MatSort;

    constructor(public activeModal: NgbActiveModal, public store: Store, private modalService: NgbModal) {}

    ngOnInit(): void {}

    addCategory() {
        const modalRef = this.modalService.open(GlobalSimpleTextInputModalComponent, { windowClass: 'modal-prompt' });
        modalRef.componentInstance.title = 'Category';
        this.subscriptions.add(
            modalRef.componentInstance.emitObject.subscribe((result) => {
                this.store.dispatch(new AddProductCategory(result));
                this.store.dispatch(new FetchProducts());
            }),
        );
    }

    deleteCategory(category: string) {
        this.store.dispatch(new DeleteProductCategories(category));
        this.store.dispatch(new FetchProducts());
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
