import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';

import YukiDocument from '../../../models/yuki/YukiDocument';
import { InvoiceInformationModalComponent } from '../../../yuki/invoice-information-modal/invoice-information-modal.component';
import { FetchDocumentById } from '../../../yuki/yuki.actions';

@Component({
    selector: 'app-pill-invoice',
    templateUrl: './pill-invoice.component.html',
    styleUrls: ['./pill-invoice.component.scss'],
})
export class PillInvoiceComponent implements OnInit, OnDestroy {
    /**
     * @deprecated
     * Use invoiceId and title if available
     */
    @Input() yukiDocumentId: string;
    @Input() invoiceId: string;
    @Input() title: string;
    yukiDocument: YukiDocument;
    private subscriptions = new Subscription();

    constructor(private store: Store, private modalService: NgbModal) {}

    ngOnInit(): void {
        if (this.yukiDocumentId) {
            this.fetchData();
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    openInvoiceInformationModal() {
        const modalRef = this.modalService.open(InvoiceInformationModalComponent, {
            windowClass: 'modal-full-screen',
            animation: false,
        });
        if (this.yukiDocumentId) {
            modalRef.componentInstance.yukiDocumentId = this.yukiDocument.uuid;
        } else {
            modalRef.componentInstance.yukiDocumentId = this.invoiceId;
        }
    }

    private fetchData() {
        this.subscriptions.add(
            this.store.dispatch(new FetchDocumentById(this.yukiDocumentId)).subscribe((state) => (this.yukiDocument = state.yuki.documentForInformationModal)),
        );
    }
}
