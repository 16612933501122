import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { parseISO } from 'date-fns';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GlobalFile } from 'src/app/models/GlobalFile';
import { GlobalFilesObject } from 'src/app/models/GlobalFilesObject';
import { LinkedObjectsToFileWithId } from 'src/app/models/LinkedObjectsToFileWithId';
import { YukiFileObject } from 'src/app/models/YukiFileObject';
import { environment } from 'src/environments/environment';

import { AuthenticationService } from '../../authentication/authentication.service';
import ChildInvoiceLink from '../../models/ChildInvoiceLink';
import { LinkFile } from '../../models/LinkFile';
import { LinkedFileToObject } from '../../models/LinkedFileToObject';
import { LinkedFilesToObjectWithId } from '../../models/LinkedFilesToObjectWithId';
import { LinkedObjectToFile } from '../../models/LinkedObjectToFile';
import { UploadedDocument } from '../../models/UploadedDocument';
import { ChildInvoiceLinkDTO } from './add-child-link-modal/add-child-link-modal.component';
import { LinkFileDTO } from './link-object-to-file-modal/link-object-to-file-modal.component';

@Injectable({
    providedIn: 'root',
})
export class GlobalFilesService {
    httpOptions = {};

    constructor(private httpClient: HttpClient, private authenticationService: AuthenticationService) {
        this.httpOptions = authenticationService.getHeaders();
    }

    getAllFiles() {
        return this.httpClient.get<GlobalFile[]>(environment.baseUrl + `/file/link/all`, this.httpOptions);
    }

    getLinkedFileObject(objectUuid: string) {
        return this.httpClient.get<GlobalFilesObject>(environment.baseUrl + `/file/link/${objectUuid}`, this.httpOptions);
    }

    getALLLinkedFileObjects() {
        return this.httpClient.get<GlobalFilesObject[]>(environment.baseUrl + `/file/link/objects/all`, this.httpOptions);
    }

    getAllLinkedOverheadFileObjects(fileUuid: string) {
        return this.httpClient.get<GlobalFilesObject[]>(environment.baseUrl + `/file/link/overhead/` + fileUuid, this.httpOptions);
    }

    getAllLinkedFilesByObjectUuid(objectUuid: string) {
        return this.httpClient.get<GlobalFilesObject[]>(environment.baseUrl + `/file/link/files/` + objectUuid, this.httpOptions);
    }

    deleteLinkedObjectFromFile(fileUuid: string, objectUuid: string) {
        return this.httpClient.delete(`${environment.baseUrl}/file/link/${fileUuid}/${objectUuid}`, this.httpOptions);
    }

    addFileToObject(fileUuid: string, linkFileDTOs: LinkFileDTO[]): Observable<LinkedObjectToFile[]> {
        return this.httpClient.post<LinkedObjectToFile[]>(`${environment.baseUrl}/file/link/${fileUuid}`, linkFileDTOs, this.httpOptions);
    }

    addFileToOverhead(fileUuid: string, price: number, yukiId: string, description: string) {
        return this.httpClient.post<GlobalFilesObject>(
            environment.baseUrl + `/file/link/overhead?fileUuid=${fileUuid}&price=${price}&yukiId=${yukiId}`,
            description,
            this.httpOptions,
        );
    }

    getAllLinkedYukiFileObject(fileUuid: string) {
        return this.httpClient.get<YukiFileObject[]>(environment.baseUrl + `/file/link/` + fileUuid, this.httpOptions);
    }

    getAllObjectsToLink(): Observable<LinkFile[]> {
        return this.httpClient.get<LinkFile[]>(`${environment.baseUrl}/file/link/all-link-files`, this.httpOptions).pipe(
            map((linkFiles) => {
                for (let i = 0; i < linkFiles.length; i++) {
                    linkFiles[i] = this.parseLinkFile(linkFiles[i]);
                }
                return linkFiles;
            }),
        );
    }

    getAllObjectsLinkedToFile(fileId: string): Observable<LinkedObjectsToFileWithId> {
        return this.httpClient.get<LinkedObjectsToFileWithId>(`${environment.baseUrl}/file/link/objects/${fileId}`, this.httpOptions);
    }

    getAllObjectsLinked(): Observable<LinkedObjectsToFileWithId[]> {
        return this.httpClient.get<LinkedObjectsToFileWithId[]>(`${environment.baseUrl}/file/link/objects/all`, this.httpOptions);
    }

    getAllFilesLinkedToObject(objectId: string): Observable<LinkedFilesToObjectWithId> {
        return this.httpClient.get<LinkedFilesToObjectWithId>(`${environment.baseUrl}/file/link/files/${objectId}`, this.httpOptions);
    }

    addSubLinkToLinkedObjectToFile(childInvoiceLinkDTOs: ChildInvoiceLinkDTO[]): Observable<ChildInvoiceLink[]> {
        return this.httpClient.post<ChildInvoiceLink[]>(`${environment.baseUrl}/file/link/child-links`, childInvoiceLinkDTOs, this.httpOptions);
    }

    deleteSubLinkToLinkedObjectToFile(linkId: string): Observable<void> {
        return this.httpClient.delete<void>(`${environment.baseUrl}/file/link/child-links/${linkId}`, this.httpOptions);
    }

    createAndLinkDocument(documentDTO: DocumentDTO): Observable<LinkedFileToObject[]> {
        return this.httpClient.post<LinkedFileToObject[]>(`${environment.baseUrl}/documents/create-and-link`, documentDTO, this.httpOptions);
    }

    fetchUploadedDocumentById(uploadedDocumentId: string): Observable<UploadedDocument> {
        return this.httpClient.get<UploadedDocument>(`${environment.baseUrl}/documents/${uploadedDocumentId}`, this.httpOptions);
    }

    updateDescription(documentId: string, description: string): Observable<UploadedDocument> {
        return this.httpClient.patch<UploadedDocument>(`${environment.baseUrl}/documents/description/${documentId}`, description, this.httpOptions);
    }

    updateLinkedObject(objectUuid: string, fileUuid: string, linkFileDTO: LinkFileDTO) {
        return this.httpClient.patch<LinkedObjectToFile>(`${environment.baseUrl}/file/link/${fileUuid}/${objectUuid}`, linkFileDTO, this.httpOptions);
    }

    updateChildInvoiceLink(childLinkId: string, childInvoiceLinkDTO: ChildInvoiceLinkDTO) {
        return this.httpClient.patch<ChildInvoiceLink>(`${environment.baseUrl}/file/link/child-links/${childLinkId}`, childInvoiceLinkDTO, this.httpOptions);
    }

    mapLinksToBudgetExpenditures(): Observable<void> {
        return this.httpClient.patch<void>(`${environment.baseUrl}/file/link/map-existing-links-to-expenditures`, {}, this.httpOptions);
    }

    private parseLinkFile(linkFile: LinkFile): LinkFile {
        if (linkFile.budgetStartDate) linkFile.budgetStartDate = parseISO(linkFile.budgetStartDate.toString());
        if (linkFile.budgetEndDate) linkFile.budgetEndDate = parseISO(linkFile.budgetEndDate.toString());
        return linkFile;
    }
}

export interface DocumentDTO {
    url: string;
    objectId: string;
    description?: string;
}
