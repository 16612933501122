import { VeldaCountryDTO } from '../models/VeldaCountryDTO';
import { VeldaLanguageDTO } from '../models/VeldaLanguageDTO';

export class FetchCountries {
    static readonly type = '[VeldaCountry] Fetch Countries';
    constructor() {}
}

export class AddCountry {
    static readonly type = '[VeldaCountry] Add Country';
    constructor(public country: VeldaCountryDTO) {}
}

export class EditCountry {
    static readonly type = '[VeldaCountry] Edit Country';
    constructor(public countryUuid: string, public country: VeldaCountryDTO) {}
}

export class DeleteCountry {
    static readonly type = '[VeldaCountry] Delete Country';
    constructor(public countryUuid: string) {}
}

export class FetchLanguages {
    static readonly type = '[VeldaLanguage] Fetch Languages';
    constructor() {}
}

export class AddLanguage {
    static readonly type = '[VeldaLanguage] Add Language';
    constructor(public language: VeldaLanguageDTO) {}
}

export class EditLanguage {
    static readonly type = '[VeldaLanguage] Edit Language';
    constructor(public languageUuid: string, public language: VeldaLanguageDTO) {}
}

export class DeleteLanguage {
    static readonly type = '[VeldaLanguage] Delete Language';
    constructor(public languageUuid: string) {}
}
