<app-app-holder>
    <app-navbar></app-navbar>

    <app-sidebar>
        <app-settings-subnav></app-settings-subnav>
    </app-sidebar>

    <app-content>
        <app-loading-message *ngIf="isLoading"></app-loading-message>
        <div *ngIf="!isLoading">
            <div *ngIf="(globalTags$ | async)?.length != 0">
                <div class="app__title">
                    <h1>Company Tags</h1>
                </div>

                <app-company-tag-list
                    *ngIf="!isLoading"
                    [tags]="globalTags$ | async"></app-company-tag-list>
            </div>

            <app-message-empty
                *ngIf="(globalTags$ | async).length == 0"
                [title]="'No tags...'"
                [subtitle]="'Tags are useful to visualize your company in just a few keywords. You can add tags to resumes, companies, projects, contacts, … '"
                [icon]="'../../assets/img/open-box.png'">
            </app-message-empty>
        </div>
    </app-content>
</app-app-holder>
