<div class="modal-header">
    <h2
        *ngIf="list"
        class="modal-title"
        id="exampleModalLabel">
        {{ list.name }}
    </h2>
    <span></span>
    <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <div *ngIf="!pending">
        <form
            [formGroup]="newRecipientFormGroup"
            (ngSubmit)="addLeadToList()">
            <select
                id="lead"
                formControlName="lead">
                <option
                    *ngFor="let lead of leads$ | async"
                    [ngValue]="lead.uuid">
                    {{ lead.email }}
                </option>
            </select>
            <input
                [ngClass]="!newRecipientFormGroup.valid ? 'button button--disabled' : 'button button--primary'"
                type="submit"
                value="Add recipient"
                [disabled]="!newRecipientFormGroup.valid" />
        </form>
    </div>

    <div *ngIf="!list"><app-loading-message></app-loading-message></div>
    <div *ngIf="list">
        <h3>Recipients</h3>

        <div
            class="list__item--user"
            *ngFor="let member of list.recipients">
            <div
                class="list__item__image"
                (click)="showMemberDetails(member)">
                <img [src]="'https://eu.ui-avatars.com/api/?background=random&bold=true&name=' + member.firstName + ' ' + member.lastName" />
            </div>
            <div
                class="list__item__content"
                (click)="showMemberDetails(member)">
                <div>
                    {{ member.email }}
                    <span class="badge m-l-1">{{ member.memberRating }}</span>
                </div>
                <div>
                    <span
                        *ngFor="let tag of member.tags"
                        class="badge m-l-1"
                        >{{ tag }}</span
                    >
                </div>
            </div>
            <div class="list__item__extra">
                <span>
                    <div
                        ngbDropdown
                        class="dropdown">
                        <button
                            ngbDropdownToggle
                            class="button button--icon button--small dropdown-toggle"
                            id="dropdownMenuButton"></button>
                        <div
                            ngbDropdownMenu
                            class="dropdown-menu dropdown-menu-right"
                            aria-labelledby="dropdownMenuButton">
                            <a
                                class="dropdown-item"
                                href="#"
                                >Edit recipient</a
                            >
                            <a
                                class="dropdown-item color--red"
                                href="#"
                                >Delete</a
                            >
                        </div>
                    </div>
                </span>
            </div>
        </div>
    </div>
</div>
