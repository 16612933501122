import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import ChangeTagModel from 'src/app/mailchimp/mailchimp-recipients-modal/change-tag-model';
import RecipientModel from 'src/app/models/RecipientModel';

import { RecipientService } from '../recipient.service';

@Component({
    selector: 'app-recipient-info-modal',
    templateUrl: './recipient-info-modal.component.html',
    styleUrls: ['./recipient-info-modal.component.scss'],
})
export class RecipientInfoModalComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();
    @Input() recipient: RecipientModel;

    recipientsFormGroup: FormGroup;
    tags: any[] = [];
    allTags: string[] = [];
    changeTagModel: ChangeTagModel = {
        listId: '',
        recipientMail: '',
        newTags: [],
        oldTags: [],
    };

    constructor(
        public activeModal: NgbActiveModal,
        private formBuilder: FormBuilder,
        private recipientService: RecipientService,
        private toastr: ToastrService,
        private logger: NGXLogger,
    ) {}

    ngOnInit(): void {
        this.changeTagModel.listId = this.recipient.listId;
        this.changeTagModel.recipientMail = this.recipient.email;
        this.recipientsFormGroup = this.formBuilder.group({
            tag: [],
        });
        this.subscriptions.add(
            this.recipientService.getTags(this.recipient.listId).subscribe((t) => {
                t.forEach((el) => this.allTags.push(el.name));
                this.recipient.tags.forEach((el) => {
                    this.tags.push(el);
                    this.changeTagModel.oldTags.push(el);
                });
            }),
        );
    }

    generateArray(obj) {
        return Object.keys(obj).map((key) => {
            return { key: key, value: obj[key] };
        });
    }

    counter(i: number) {
        return new Array(i);
    }

    updateTags() {
        this.changeTagModel.newTags = [];
        this.tags.forEach((el) => {
            if (el.value != undefined) {
                this.changeTagModel.newTags.push(el.value);
            } else {
                this.changeTagModel.newTags.push(el);
            }
        });

        this.logger.debug('Attempt to update tags');
        this.subscriptions.add(
            this.recipientService.updateTags(this.changeTagModel).subscribe(
                (result) => {
                    if (result) {
                        this.logger.debug('Succesfully updated taglist');
                        this.toastr.success('List updated successfully!');
                        this.changeTagModel.oldTags = this.changeTagModel.newTags;
                        this.recipient.tags = this.changeTagModel.newTags;
                        this.recipientsFormGroup.get('tag').markAsPristine();
                    }
                },
                (error) => this.logger.error('Failed to update taglist'),
            ),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
