<form
    (ngSubmit)="addBudgetTemplate($event)"
    [formGroup]="budgetTemplateForm"
    class="modal-wrapper">
    <div class="modal-header">
        <h2
            class="modal-title"
            id="exampleModalLabel">
            {{ type }}
        </h2>
        <button
            (click)="activeModal.dismiss()"
            aria-label="Close"
            class="close"
            type="button">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <div class="row">
            <div class="col">
                <div class="form-group m-b-2 required">
                    <label for="budgetType">Budget type</label>
                    <small
                        *ngIf="!budgetTemplateForm.get('budgetType').valid && budgetTemplateForm.get('budgetType').touched"
                        class="form-error">
                        Please enter a budget type
                    </small>
                    <input
                        formControlName="budgetType"
                        id="budgetType"
                        placeholder="Enter a budget type"
                        type="text"/>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <div class="form-group m-b-2 required">
                    <label for="totalSpend">Total to spend (€)</label>
                    <small
                        *ngIf="!budgetTemplateForm.get('totalSpend').valid && budgetTemplateForm.get('totalSpend').touched"
                        class="form-error">
                        Please enter total to spend</small
                    >
                    <input
                        formControlName="totalSpend"
                        id="totalSpend"
                        placeholder="Enter a total to spend"
                        type="text"/>
                </div>
            </div>
        </div>

        <section>
            <div class="row">
                <div class="col">
                    <p>Budget expires in</p>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <div class="form-group m-b-2 required">
                        <label for="leadTimeYears">Years</label>
                        <input
                            formControlName="leadTimeYears"
                            id="leadTimeYears"
                            type="number"/>
                    </div>
                </div>
                <div class="col">
                    <div class="form-group m-b-2 required">
                        <label for="leadTimeMonths">Months</label>
                        <input
                            formControlName="leadTimeMonths"
                            id="leadTimeMonths"
                            type="number"/>
                    </div>
                </div>
                <div class="col">
                    <div class="form-group m-b-2 required">
                        <label for="leadTimeMonths">Days</label>
                        <input
                            formControlName="leadTimeDays"
                            id="leadTimeDays"
                            type="number"/>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col">
                    <small
                        *ngIf="budgetTemplateForm.get('leadTimeYears').hasError('min') || budgetTemplateForm.get('leadTimeYears').hasError('max')"
                        class="form-error">
                        Years must be between 0 and 99
                    </small>
                    <small
                        *ngIf="budgetTemplateForm.get('leadTimeMonths').hasError('min') || budgetTemplateForm.get('leadTimeMonths').hasError('max')"
                        class="form-error">
                        Months must be between 0 and 12
                    </small>
                    <small
                        *ngIf="budgetTemplateForm.get('leadTimeDays').hasError('min') || budgetTemplateForm.get('leadTimeDays').hasError('max')"
                        class="form-error">
                        Days must be between 0 and 31
                    </small>
                </div>
            </div>
        </section>

        <section>
            <div class="sectiontitle">Recurring options</div>

            <div class="row">
                <div class="col">
                    <div class="form-group--toggle">
                        <label
                            class="pointer"
                            for="isRecurring">
                            <input
                                formControlName="isRecurring"
                                id="isRecurring"
                                type="checkbox"/>
                            <div class="w-100">
                                Recurring budget
                                <i class="las la-redo-alt m-l-2"></i>
                            </div>
                        </label>
                    </div>
                </div>
            </div>

            <div
                *ngIf="budgetTemplateForm.get('isRecurring').value"
                class="row">
                <div class="col">
                    <div class="form-group m-b-2 required">
                        <label for="recurrenceRule">Repeats</label>
                        <select
                            class="select"
                            formControlName="recurrenceRule"
                            id="recurrenceRule">
                            <option
                                *ngFor="let repeat of budgetRepeats"
                                [ngValue]="repeat.value">
                                {{ repeat.title }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
        </section>

        <section>
            <div class="sectiontitle sectiontitle--required">Financial options</div>

            <p class="form-helptext">
                This option is important for financial calculations.
                When 'only expenditures' is selected. Only expenditures linked to this budget will be used in financial calculations.
                Else the budgeted cost will be used if no expenditures are linked.
            </p>

            <div class="form-group--toggle">
                <label class="pointer" for="expenditures">
                    <input formControlName="useExpendituresForFinancials" id="expenditures" type="radio" value="expenditures">
                    <div class="w-100">
                        Only expenditures
                    </div>
                </label>

                <label class="pointer" for="budgeted">
                    <input formControlName="useExpendituresForFinancials" id="budgeted" type="radio" value="budgeted">
                    <div class="w-100">
                        Budgeted cost in combination with expenditures
                    </div>
                </label>
            </div>

        </section>

        <section>
            <div class="sectiontitle">Notification options</div>

            <div>
                <label
                    class="pointer"
                    for="sendNotification">
                    <input
                        formControlName="sendNotification"
                        id="sendNotification"
                        type="checkbox"/>
                    Send notification for this budget
                </label>
            </div>

            <div *ngIf="budgetTemplateForm.get('sendNotification').value">
                <div class="row">
                    <div class="col">
                        <p class="form-helptext">Time notification will be send before end date</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="form-group m-b-2 required">
                            <label for="notificationTimeMonths">Months</label>
                            <input
                                formControlName="notificationTimeMonths"
                                id="notificationTimeMonths"
                                type="number"/>
                        </div>
                    </div>
                    <div class="col">
                        <div class="form-group m-b-2 required">
                            <label for="notificationTimeDays">Days</label>
                            <input
                                formControlName="notificationTimeDays"
                                id="notificationTimeDays"
                                type="number"/>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col">
                        <small
                            *ngIf="budgetTemplateForm.get('leadTimeYears').hasError('min') || budgetTemplateForm.get('leadTimeYears').hasError('max')"
                            class="form-error">
                            Years must be between 0 and 99
                        </small>
                        <small
                            *ngIf="budgetTemplateForm.get('leadTimeMonths').hasError('min') || budgetTemplateForm.get('leadTimeMonths').hasError('max')"
                            class="form-error">
                            Months must be between 0 and 12
                        </small>
                        <small
                            *ngIf="budgetTemplateForm.get('leadTimeDays').hasError('min') || budgetTemplateForm.get('leadTimeDays').hasError('max')"
                            class="form-error">
                            Days must be between 0 and 31
                        </small>
                    </div>
                </div>
            </div>
        </section>
    </div>
    <div class="modal-footer">
        <input
            [disabled]="!budgetTemplateForm.valid"
            [ngClass]="!budgetTemplateForm.valid ? 'button--disabled' : 'button--primary'"
            class="button"
            type="submit"
            value="{{ type }}"/>
        <button
            (click)="activeModal.dismiss()"
            class="button button--outline">
            Cancel
        </button>
    </div>
</form>
