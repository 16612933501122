import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { WarehouseLocation } from 'src/app/models/WarehouseLocation';
import { WarehouseLocationDetailModalComponent } from 'src/app/warehouse-locations/warehouse-location-detail-modal/warehouse-location-detail-modal.component';
import { WarehouseLocationState } from 'src/app/warehouse-locations/warehouse.location.state';

@Component({
    selector: 'app-pill-warehouse-location',
    templateUrl: './pill-warehouse-location.component.html',
    styleUrls: ['./pill-warehouse-location.component.scss'],
})
export class PillWarehouseLocationComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();
    @Select(WarehouseLocationState.WarehouseLocations)
    warehouseLocations$: Observable<WarehouseLocation[]>;
    @Input() warehouseLocation: WarehouseLocation;
    @Input() warehouseLocationUuid: string;
    @Input() title: string;
    fetchedWarehouseLocation: WarehouseLocation;
    isLoaded = false;

    constructor(private modalService: NgbModal) {}

    ngOnInit(): void {
        if (this.warehouseLocationUuid) {
            this.subscriptions.add(
                this.warehouseLocations$.subscribe((locations) => {
                    if (locations != undefined) {
                        if (locations.length > 0) {
                            this.fetchedWarehouseLocation = locations.find((m) => m.uuid == this.warehouseLocationUuid);
                            this.isLoaded = true;
                        }
                    }
                }),
            );
        }
    }

    openWarehouseLocationDetailModal() {
        const modalRef = this.modalService.open(WarehouseLocationDetailModalComponent, { windowClass: 'modal-prompt' });
        if (this.warehouseLocation) {
            modalRef.componentInstance.warehouseLocationUuid = this.warehouseLocation.uuid;
        } else if (this.fetchedWarehouseLocation) {
            modalRef.componentInstance.warehouseLocationUuid = this.fetchedWarehouseLocation.uuid;
        } else {
            modalRef.componentInstance.warehouseLocationUuid = this.warehouseLocationUuid;
        }
    }

    getTitle(): string {
        return this.warehouseLocation ? this.warehouseLocation.title : this.title;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
