import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subscription } from 'rxjs';
import { GetCompanyProjectStatusList } from 'src/app/companies/company.actions';
import { CompanyState } from 'src/app/companies/company.state';
import { Status } from 'src/app/models/Status';
import { ProjectStatusDetailModalComponent } from 'src/app/projects/project-status-detail-modal/project-status-detail-modal.component';

@Component({
    selector: 'app-project-settings',
    templateUrl: './project-settings.component.html',
    styleUrls: ['./project-settings.component.scss'],
})
export class ProjectSettingsComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Select(CompanyState.companyStatusList)
    companyProjectStatusList$: Observable<Status[]>;
    projectStatusList: Status[];
    isLoading = true;

    constructor(private store: Store, private modalService: NgbModal, private logger: NGXLogger) {}

    ngOnInit(): void {
        this.logger.debug('Fetching companyProjectStatusList');
        this.store.dispatch(new GetCompanyProjectStatusList());
        this.subscriptions.add(
            this.companyProjectStatusList$.subscribe((list) => {
                if (list == undefined) {
                    this.store.dispatch(new GetCompanyProjectStatusList());
                } else {
                    if (JSON.stringify(this.projectStatusList) != JSON.stringify(list)) {
                        this.projectStatusList = list;
                        this.isLoading = false;
                    }
                }
            }),
        );
    }

    openAddStatusModal() {
        this.logger.debug('Opening projectstatusDetailModal');
        this.modalService.open(ProjectStatusDetailModalComponent, {
            windowClass: 'modal-prompt',
            animation: false,
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
