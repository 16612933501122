<form
    [formGroup]="resumeFormGroup"
    (ngSubmit)="updateResume()"
    class="modal-wrapper">
    <div class="modal-header">
        <h2
            class="modal-title"
            id="exampleModalLabel">
            Edit resume
        </h2>
        <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="activeModal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <div class="row">
            <div class="col-6">
                <div class="form-group m-b-2">
                    <label>Firstname</label>
                    <input
                        type="text"
                        placeholder="Firstname"
                        formControlName="firstName"
                        trim="blur" />
                </div>
            </div>
            <div class="col-6">
                <div class="form-group m-b-2">
                    <label>Lastname</label>
                    <input
                        type="text"
                        placeholder="Lastname"
                        formControlName="lastName"
                        trim="blur" />
                </div>
            </div>
        </div>

        <div class="form-group m-b-2">
            <label>Gender</label>
            <select formControlName="gender">
                <option value="MALE">Male</option>
                <option value="FEMALE">Female</option>
                <option value="OTHER">Other</option>
            </select>
        </div>

        <div class="col-6">
            <div>
                <label>Birthday</label>
                <input
                    type="date"
                    formControlName="birthday" />
            </div>
        </div>

        <h3 class="m-t-4 m-b-4">Address</h3>

        <div formGroupName="address">
            <div class="m-b-2">
                <div class="row">
                    <div class="col-6">
                        <div>
                            <label>Street</label>
                            <input
                                type="text"
                                placeholder="Street"
                                formControlName="street"
                                trim="blur" />
                        </div>
                    </div>
                    <div class="col-2">
                        <div>
                            <label>Number</label>
                            <input
                                type="number"
                                placeholder="Number"
                                formControlName="number" />
                        </div>
                    </div>
                    <div class="col-2">
                        <div class="form-group">
                            <label>Box</label>
                            <input
                                type="text"
                                placeholder="Box"
                                formControlName="bus"
                                trim="blur" />
                        </div>
                    </div>
                    <div class="col-2">
                        <div class="form-group">
                            <label>Ext</label>
                            <input
                                type="number"
                                placeholder="Ext"
                                formControlName="ext" />
                        </div>
                    </div>
                </div>
            </div>

            <div class="m-b-2">
                <div class="row">
                    <div class="col-3">
                        <div>
                            <label>Zipcode</label>
                            <input
                                type="text"
                                placeholder="Zipcode"
                                formControlName="postal"
                                trim="blur" />
                        </div>
                    </div>
                    <div class="col-9">
                        <div>
                            <label>City</label>
                            <input
                                type="text"
                                placeholder="City"
                                formControlName="city"
                                trim="blur" />
                        </div>
                    </div>
                </div>
            </div>

            <div formGroupName="country">
                <label>Country</label>
                <select formControlName="iso2">
                    <option
                        *ngFor="let country of allCountries"
                        [ngValue]="country['alpha-2']">
                        {{ country.name }}
                    </option>
                </select>
            </div>
        </div>

        <h3 class="m-t-4 m-b-4">Contact information</h3>

        <div formGroupName="contactInformation">
            <div class="form-group m-b-2">
                <label>Email</label>
                <input
                    type="text"
                    placeholder="Email"
                    formControlName="email"
                    trim="blur" />
            </div>

            <div class="form-group m-b-2">
                <label>Phonenumber</label>
                <input
                    type="text"
                    placeholder="Phonenumber"
                    formControlName="phoneNumber"
                    trim="blur" />
            </div>
        </div>

        <div class="form-group m-b-2">
            <textarea
                class="resize-vertical"
                formControlName="description"
                placeholder="Description"
                (keyup)="onDescriptionKeyUp()"
                trim="blur"></textarea>
            <small
                ><span *ngIf="currentAmountOfDescriptionCharacters === null">0</span>{{ currentAmountOfDescriptionCharacters }}/{{
                    DESCRIPTION_MAX_LENGTH
                }}</small
            >
            <span
                class="form-error"
                *ngIf="descriptionIsTooLong"
                >Description cannot contain more than 1000 characters</span
            >
        </div>

        <app-file-upload
            type="file"
            formControlName="pdfUrls"
            [multi]="true"></app-file-upload>
        <small> </small>
    </div>
    <span
        class="form-error"
        *ngIf="updateResumeWentWrong"
        >{{ errorMessage }}</span
    >

    <div class="modal-footer">
        <button
            type="button"
            class="button button--outline"
            (click)="activeModal.close()">
            Close
        </button>
        <button
            type="submit"
            [ngClass]="{ 'button--loading': loading }"
            class="button button--primary">
            Update
        </button>
    </div>
</form>
