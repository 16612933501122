import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { CompanyState } from 'src/app/companies/company.state';
import { Module } from 'src/app/models/Module';
import { ActivateModule, FetchModules } from 'src/app/modules/module.actions';
import { ModuleState } from 'src/app/modules/module.state';
import { ModuleService } from 'src/app/services/module.service';

import { CustomRolesState } from '../roles/custom.roles.state';

@Component({
    selector: 'app-module-setup',
    templateUrl: './module-setup.component.html',
    styleUrls: ['./module-setup.component.scss'],
})
export class ModuleSetupComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Select(CustomRolesState.AllowedModules) allowedModules$: Observable<Module[]>;
    @Select(ModuleState.Modules) allModules$: Observable<Module[]>;
    @Select(CompanyState.companyUuid) companyUuid$: Observable<string>;

    displayedModuleColumns: string[] = ['edit', 'module', 'description'];
    displayedIntegrationColumns: string[] = ['edit', 'integration', 'description'];
    @ViewChild(MatSort) sort: MatSort;

    globalModules: Module[];
    allCompanyModules: Module[];
    allModules: Module[];
    allIntegrations: Module[];
    activeModules: Module[];
    activeIntegrations: Module[];
    companyUuid: string;

    constructor(private store: Store, public moduleService: ModuleService, private toastr: ToastrService, private logger: NGXLogger) {}

    ngOnInit(): void {
        this.subscriptions.add(this.companyUuid$.subscribe((id) => (this.companyUuid = id)));

        this.subscriptions.add(
            this.allModules$.subscribe((modules) => {
                if (!modules) {
                    this.logger.debug('Fetching Modules');
                    this.store.dispatch(new FetchModules());
                    return;
                }

                this.globalModules = modules;
                this.allModules = modules.filter((module) => module.moduleType === 'MODULE');
                this.allIntegrations = modules.filter((module) => module.moduleType === 'INTEGRATION');
            }),
        );

        this.subscriptions.add(
            this.allowedModules$.subscribe((modules) => {
                this.allCompanyModules = modules;
                this.activeModules = modules.filter((module) => module.moduleType === 'MODULE');
                this.activeIntegrations = modules.filter((module) => module.moduleType === 'INTEGRATION');
            }),
        );
    }

    activateModule(canActivate: boolean, moduleUuid: string) {
        if (canActivate) {
            this.logger.debug('Attempt to (de)activate Module');
            this.subscriptions.add(
                this.store.dispatch(new ActivateModule(this.companyUuid, moduleUuid)).subscribe({
                    next: () => {
                        if (!this.allCompanyModules.find((m) => m.uuid === moduleUuid)) {
                            this.logger.debug('Successfully deactivated Module');
                        } else {
                            this.logger.debug('Successfully activated Module');
                        }
                    },
                    error: () => {
                        this.toastr.error('Could not activate module');
                    },
                }),
            );
        }
    }

    setToggleActive(module: Module): string {
        if (this.allCompanyModules.find((m: Module) => m.key === module.key) && module.canActivate) {
            return 'toggle--active';
        }

        if (!module.canActivate) {
            return 'toggle--disabled toggle--active';
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
