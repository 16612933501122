import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { ChartConfiguration } from 'chart.js';
import { Observable, Subscription } from 'rxjs';
import { InvoiceType } from 'src/app/models/GlobalFile';
import { InformationForFinancialData } from 'src/app/models/InformationForFinancialData';
import { MONTHS } from 'src/app/models/MONTHS';
import { OverheadCategoryWithFinancialData } from 'src/app/models/OverheadCategoryWithFinancialData';
import { CalendarClickEventData, LegendPosition } from 'src/app/shared/charts/default-line-chart/default-line-chart.component';
import { DateNavigationOptions, DateNavigationType, SelectedDateClass } from 'src/app/shared/date-navigation/date-navigation.component';
import { capitalizeFirstLetter } from 'src/app/shared/utils/string-utils';

import { ChartInformationModalComponent, ChartInformationObjectType } from '../chart-information-modal/chart-information-modal.component';
import { DATASETS } from '../employee-graph/employee-graph.component';
import { FetchFinancialDataForOverheadCategories } from '../financial.actions';
import { FinancialState } from '../financial.state';

@Component({
    selector: 'app-overhead-graphs',
    templateUrl: './overhead-graphs.component.html',
    styleUrls: ['./overhead-graphs.component.scss'],
})
export class OverheadGraphsComponent implements OnInit, OnDestroy {
    @Select(FinancialState.idsOfOverheadCategoriesWithFinancialData) ids$: Observable<string[]>;
    @Select(FinancialState.overheadCategoriesWithFinancialData) allOverheadData$: Observable<OverheadCategoryWithFinancialData[]>;
    allOverheadData: OverheadCategoryWithFinancialData[];
    overheadCategoryWithFinancialData: OverheadCategoryWithFinancialData = null;
    data: any[] = undefined;
    labels: string[] = [];
    xLabels: string[];
    legendPosition: LegendPosition = LegendPosition.BOTTOM;
    options: ChartConfiguration['options'];
    dateNavigationOptions: DateNavigationOptions;
    average: number;
    total: number;
    private subscriptions = new Subscription();

    constructor(public store: Store, private modalService: NgbModal) {}

    ngOnInit(): void {
        this.dateNavigationOptions = {
            dateNavigationType: DateNavigationType.YEAR,
            selectedDate: new Date(),
            selectedDateClass: SelectedDateClass.SMALL,
        };
        this.subscriptions.add(this.store.dispatch(new FetchFinancialDataForOverheadCategories()).subscribe());
        this.subscriptions.add(
            this.allOverheadData$.subscribe((allOverheadData) => {
                if (allOverheadData) {
                    this.allOverheadData = allOverheadData;
                    const allFinancialData = [];
                    for (let i = 0; i < allOverheadData[0]?.financialData.length; i++) {
                        allFinancialData[i] = 0;
                        allOverheadData.forEach((d) => (allFinancialData[i] = allFinancialData[i] + d.financialData[i]));
                    }
                    this.overheadCategoryWithFinancialData = {
                        categoryId: '',
                        categoryName: 'Total',
                        financialData: allFinancialData,
                    };

                    this.createChart();
                    this.calculateTotalAndAverage();
                }
            }),
        );
    }

    updateSelectedYear(): void {
        this.store.dispatch(new FetchFinancialDataForOverheadCategories(this.dateNavigationOptions.selectedDate.getFullYear()));
    }

    displayInfo($event: CalendarClickEventData) {
        const purch: InformationForFinancialData[] = [];
        this.allOverheadData.forEach((data) => {
            purch.push({
                objectId: data.categoryId,
                title: data.categoryName,
                amount: data.financialData[$event.month],
                invoiceType: InvoiceType[InvoiceType.PURCHASE],
                invoiceNumber: '',
            });
        });

        const modalRef = this.modalService.open(ChartInformationModalComponent, {
            windowClass: 'modal-prompt modal-prompt--min-content',
            animation: false,
        });
        modalRef.componentInstance.month = $event.month;
        modalRef.componentInstance.year = this.dateNavigationOptions.selectedDate.getFullYear();
        modalRef.componentInstance.dataset = DATASETS.findDataset($event.datasetIndex);
        modalRef.componentInstance.purchaseInformation = purch;
        modalRef.componentInstance.saleInformation = [];
        modalRef.componentInstance.title = `Total`;
        modalRef.componentInstance.chartInformationObjectType = ChartInformationObjectType.TOTAL;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    private createChart() {
        if (!this.overheadCategoryWithFinancialData) return;

        this.xLabels = this.seedLabels();
        this.data = [this.overheadCategoryWithFinancialData.financialData];
        this.labels = ['Cost'];

        this.options = {};
    }

    private seedLabels() {
        return Object.values(MONTHS).map((label) => capitalizeFirstLetter(label.short));
    }

    private calculateTotalAndAverage() {
        this.total = this.overheadCategoryWithFinancialData.financialData.reduce((total, data) => total + data, 0);

        if (this.dateNavigationOptions.selectedDate.getFullYear() === new Date().getFullYear())
            this.average = this.total / this.dateNavigationOptions.selectedDate.getMonth() + 1;
        else this.average = this.total / 12;
    }
}
