import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { Module } from 'src/app/models/Module';

import { ComanageLoginService } from './comanage-login.service';

@Component({
    selector: 'app-comanage-login',
    templateUrl: './comanage-login.component.html',
    styleUrls: ['./comanage-login.component.scss'],
})
export class ComanageLoginComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Input() module: Module;
    key: string;
    form: FormGroup;

    constructor(private fb: FormBuilder, private comanageLoginService: ComanageLoginService, private toastr: ToastrService, private logger: NGXLogger) {}

    ngOnInit(): void {
        this.logger.debug('Checking if comanage api key exists');
        this.subscriptions.add(
            this.comanageLoginService.checkIfApiKeyExists().subscribe((e) => {
                this.key = e.key;
                if (this.key) {
                    this.form.get('apiKey').setValue(this.key);
                }
            }),
        );
        this.form = this.fb.group({
            apiKey: ['', [Validators.required, Validators.minLength(30), Validators.maxLength(1200)]],
        });
    }

    onSubmit(form: FormGroup) {
        this.logger.debug('Attempt to save comanage api key');
        this.subscriptions.add(
            this.comanageLoginService.pushApiKey(form.get('apiKey').value).subscribe(
                (result) => {
                    if (result) {
                        this.logger.debug('Succesfully saved comanage api key');
                        this.toastr.success('API key saved succesfully!');
                    }
                },
                (error) => this.logger.error('Failed to add comanage api key'),
            ),
        );
    }

    removeKey() {
        this.logger.debug('Attempt to remove comanage api key');
        this.subscriptions.add(
            this.comanageLoginService.removeApiKey().subscribe(
                (result) => {
                    if (result) {
                        this.logger.debug('Succesfully removed comanage api key');
                        this.toastr.success('API key was removed!');
                        this.form.get('apiKey').setValue('');
                        this.form.markAsUntouched();
                    }
                },
                (error) => this.logger.error('Failed to remove comanage api key'),
            ),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
