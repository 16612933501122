import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';

import { DocumentsDocDetailComponent } from './documents-doc-detail.component';

export interface DeactivationGuarded {
    canDeactivate(nextState: string): Observable<boolean> | boolean;
}

@Injectable({ providedIn: 'root' })
export class CanDeactivateEditor implements CanDeactivate<DocumentsDocDetailComponent> {
    canDeactivate(component: DocumentsDocDetailComponent): Observable<boolean> | boolean {
        return component.canDeactivate ? component.canDeactivate() : true;
    }
}
