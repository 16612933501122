<form
    [formGroup]="newMailFormGroup"
    (ngSubmit)="addMail()"
    class="modal-wrapper">
    <div class="modal-header">
        <h2 class="modal-title">Add mail to mailing list</h2>
        <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="activeModal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <div class="row">
            <div class="col">
                <div class="form-group m-b-2">
                    <label for="mail">Email </label>
                    <small
                        class="form-error"
                        *ngIf="!newMailFormGroup.get('mail').valid && newMailFormGroup.get('mail').touched"
                        >please enter at least one valid email</small
                    >
                    <textarea
                        class="resize-vertical"
                        id="mail"
                        formControlName="mail"
                        type="text"
                        placeholder="email"></textarea>
                    <p class="form-helptext">You can add multiple emails by seperating them with a ";".</p>
                </div>
            </div>
        </div>
        <div
            class="row"
            *ngIf="mailingTypeInfo">
            <div class="col">
                <div class="form-group m-b-2">
                    <label for="mailingType">Action</label>
                    <select
                        id="mailingType"
                        formControlName="mailingType">
                        <option
                            *ngFor="let type of mailingTypeInfo"
                            [value]="type">
                            {{ type[1] }}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div
            class="row"
            *ngIf="getCronEnabled()">
            <div class="col">
                <div class="form-group m-b-2">
                    <label for="occurrence">Occurrence</label>
                    <select
                        id="occurrence"
                        formControlName="occurrence">
                        <option value="monthly">Monthly</option>
                        <option value="weekly">Weekly</option>
                        <option value="daily">Daily</option>
                    </select>
                </div>
            </div>

            <div
                class="col"
                *ngIf="newMailFormGroup.get('occurrence').value === 'weekly'">
                <div class="form-group m-b-2">
                    <label for="cron">Day of the week</label>
                    <select
                        id="cron"
                        formControlName="cron">
                        <option value="0 0 0 ? * MON *">Monday</option>
                        <option value="0 0 0 ? * TUE *">Tuesday</option>
                        <option value="0 0 0 ? * WED *">Wendsday</option>
                        <option value="0 0 0 ? * THU *">Thursday</option>
                        <option value="0 0 0 ? * FRI *">Friday</option>
                        <option value="0 0 0 ? * SAT *">Saturday</option>
                        <option value="0 0 0 ? * SUN *">Sunday</option>
                    </select>
                </div>
            </div>
        </div>
        <div
            class="row"
            *ngIf="newMailFormGroup.get('occurrence').value === 'monthly'">
            <div class="col">
                <div class="form-group m-b-2">
                    <label for="cron">Day of the month</label>
                    <small
                        class="form-error"
                        *ngIf="!validDayOfMonthNumber"
                        >the number of days should be lower than 31 or higher than -30</small
                    >
                    <input
                        id="cron"
                        formControlName="cron"
                        type="number"
                        placeholder="Day of month" />
                    <small class="form-helptext"
                        >1 or higher corresponds to the day of the month. 0 or lower corresponds to days before the end of the month (with 0 being the last day
                        of the month).</small
                    >
                </div>
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <input
            [ngClass]="!newMailFormGroup.valid ? 'button button--disabled w-100' : 'button button--primary w-100'"
            type="submit"
            value="Add"
            [disabled]="!newMailFormGroup.valid" />
    </div>
</form>
