import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import * as moment from 'moment';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { Experience } from 'src/app/models/resume/Experience';

import { GlobalTag } from '../../models/GlobalTag';
import { Resume } from '../../models/resume/Resume';
import { AddGlobalTagToObject } from '../../shared/global-tags/global-tags.actions';
import { AddExperience, AddTagToResume, GetExperiences, GetResume, UpdateExperience } from '../resume.actions';
import { ResumeState } from '../resume.state';

@Component({
    selector: 'app-experience-detail-modal',
    templateUrl: './experience-detail-modal.component.html',
    styleUrls: ['./experience-detail-modal.component.scss'],
})
export class ExperienceDetailModalComponent implements OnInit, OnDestroy {
    @Input() resumeUuid: string;
    @Input() experience: Experience;
    newExperience: Experience;
    experienceFormGroup: FormGroup;
    errorMessage: string;
    connectionError: string;
    present = false;
    isUpdate = false;
    loading = false;
    public inputIsWrong = false;
    public experienceWentWrong = false;
    isValidDate = false;
    error: any = { isError: false, errorMessage: '' };
    DESCRIPTION_MAX_LENGTH = 1000;
    currentAmountOfDescriptionCharacters: number;
    descriptionIsTooLong = false;
    tags: string[] = [];

    @Select(ResumeState.resumes) resumes$: Observable<Resume[]>;
    resumes: Resume[];

    private subscriptions = new Subscription();

    constructor(
        public activeModal: NgbActiveModal,
        private formBuilder: FormBuilder,
        private store: Store,
        private toastr: ToastrService,
        private logger: NGXLogger,
    ) {}

    ngOnInit(): void {
        if (this.experience) {
            this.tags = [...this.experience.tags];
            this.isUpdate = true;
            this.currentAmountOfDescriptionCharacters = this.experience.description.length;
        }
        this.experienceFormGroup = this.formBuilder.group({
            startDate: ['', [Validators.required]],
            endDate: [''],
            present: [false],
            organization: ['', [Validators.required]],
            website: [''],
            jobTitle: ['', [Validators.required]],
            description: [''],
        });

        if (this.isUpdate) {
            this.setFormValues();
        }

        this.subscriptions.add(this.resumes$.subscribe((resumes) => (this.resumes = resumes)));
    }

    setFormValues() {
        if (this.experience.present) {
            this.present = this.experience.present;
            this.isValidDate = true;

            this.experienceFormGroup.setValue({
                startDate: moment(this.experience.startDate).format('YYYY-MM-DD'),
                endDate: moment(Date.now()).format('YYYY-MM-DD'),
                present: this.experience.present,
                organization: this.experience.organization,
                website: this.experience.website,
                jobTitle: this.experience.jobTitle,
                description: this.experience.description,
            });
            this.experienceFormGroup.controls.endDate.disable();
        } else {
            this.experienceFormGroup.setValue({
                startDate: moment(this.experience.startDate).format('YYYY-MM-DD'),
                endDate: moment(this.experience.endDate).format('YYYY-MM-DD'),
                present: this.experience.present,
                organization: this.experience.organization,
                website: this.experience.website,
                jobTitle: this.experience.jobTitle,
                description: this.experience.description,
            });
        }
    }

    onPresentTicked() {
        this.present = this.experienceFormGroup.controls.present.value;
        if (this.present) {
            this.isValidDate = true;
            this.experienceFormGroup.controls.endDate.disable();
        } else {
            this.isValidDate = false;
            this.experienceFormGroup.controls.endDate.enable();
        }
    }

    onDescriptionKeyUp() {
        this.descriptionIsTooLong = false;
        this.currentAmountOfDescriptionCharacters = this.experienceFormGroup.controls.description.value.length;
        if (this.currentAmountOfDescriptionCharacters > this.DESCRIPTION_MAX_LENGTH) {
            this.descriptionIsTooLong = true;
        }
    }

    saveExperience() {
        this.loading = true;

        if (!this.present) {
            this.isValidDate = this.validateDates(this.experienceFormGroup.value.startDate, this.experienceFormGroup.value.endDate);
        }

        if (this.descriptionIsTooLong) {
            this.loading = false;
            return;
        }

        if (!this.experienceFormGroup.valid || !this.isValidDate) {
            this.inputIsWrong = true;
            this.loading = false;
            return;
        }

        this.experienceFormGroup.disable();

        this.newExperience = this.experienceFormGroup.value;
        this.newExperience.tags = this.tags;

        if (this.newExperience.website) {
            if (this.newExperience.website.substring(0, 8) === 'https://') {
            } else if (this.newExperience.website.substring(0, 7) === 'http://') {
                this.newExperience.website = 'https://' + this.newExperience.website.substring(7);
            } else {
                this.newExperience.website = 'https://' + this.newExperience.website;
            }
        }

        if (!this.isUpdate) {
            this.logger.debug('Attempt to add Experience');
            this.subscriptions.add(
                this.store.dispatch(new AddExperience(this.resumeUuid, this.newExperience)).subscribe(
                    (state) => {
                        this.logger.debug('Succesfully added experience');
                        this.logger.debug('Fetching experiences');
                        this.store.dispatch(new GetExperiences(this.resumeUuid)).subscribe(() => {
                            this.logger.debug('Fetching resume');
                            this.store.dispatch(new GetResume(this.resumeUuid));
                        });
                        this.toastr.success('Experience added succesfully');
                        this.activeModal.close();
                    },
                    (error) => {
                        this.logger.error('Failed to add experience');
                        this.errorMessage = error?.error?.message ?? 'Error message was empty';

                        this.loading = false;
                        this.experienceFormGroup.enable();

                        if (!this.errorMessage) {
                            this.connectionError = 'No connection to the server';
                        }

                        this.experienceWentWrong = true;
                    },
                ),
            );
        } else {
            this.logger.debug('Attempt to update experience');
            this.subscriptions.add(
                this.store.dispatch(new UpdateExperience(this.experience.uuid, this.newExperience)).subscribe(
                    () => {
                        this.logger.debug('Succesfully updated experience');
                        this.logger.debug('Fetching experiences');
                        this.store.dispatch(new GetExperiences(this.resumeUuid)).subscribe(
                            () => {},
                            (error) => {
                                this.logger.debug('Fetching resume');
                                this.store.dispatch(new GetResume(this.resumeUuid));
                            },
                        );
                        this.activeModal.close();
                    },
                    (error) => {
                        this.logger.error('Failed to update experience');
                        this.errorMessage = error?.error?.message ?? 'Error message was empty';

                        this.loading = false;
                        this.experienceFormGroup.enable();

                        if (!this.errorMessage) {
                            this.connectionError = 'No connection to the server';
                        }

                        this.experienceWentWrong = true;
                    },
                ),
            );
        }
    }

    hideErrorMessage() {
        this.inputIsWrong = false;
    }

    validateDates(sDate: Date, eDate: Date) {
        this.isValidDate = true;
        if (sDate == null || eDate == null) {
            this.error = {
                isError: true,
                errorMessage: 'Start date and end date are required.',
            };
            this.isValidDate = false;
        }

        if (sDate != null && eDate != null && eDate < sDate) {
            this.error = {
                isError: true,
                errorMessage: 'End date should be greater then start date.',
            };
            this.isValidDate = false;
        }

        return this.isValidDate;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    addTagToExperience(tag: GlobalTag) {
        if (!this.tags) this.tags = [];
        this.tags.push(tag.title);

        const resume = this.resumes.find((r) => r.uuid === this.resumeUuid);
        if (!resume.globalTags.find((t) => t.uuid === tag.uuid)) {
            this.store.dispatch(new AddGlobalTagToObject(tag, this.resumeUuid));
            this.store.dispatch(new AddTagToResume(tag, this.resumeUuid));
        }
    }

    removeExperienceTag(tag: GlobalTag) {
        this.tags = this.tags.filter((t) => t !== tag.title);
    }
}
