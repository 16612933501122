import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '../authentication/auth.guard';
import { CompanyGuard } from '../companies/company.guard';
import { CanDeactivateEditor } from './documents-doc-detail/EditorRouteGuard.guard';
import { DocumentsDocDetailComponent } from './documents-doc-detail/documents-doc-detail.component';
import { DocumentsOverviewComponent } from './documents-overview/documents-overview.component';

export const routes: Routes = [
    { path: '', redirectTo: 'overview', pathMatch: 'full' },
    { path: 'overview', component: DocumentsOverviewComponent },
    {
        path: 'detail/:id',
        component: DocumentsDocDetailComponent,
        canActivate: [AuthGuard, CompanyGuard],
        canDeactivate: [CanDeactivateEditor],
    },
];

export const routing: ModuleWithProviders<any> = RouterModule.forChild(routes);
