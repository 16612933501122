import { Component, OnDestroy, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subscription } from 'rxjs';
import { GlobalTag } from 'src/app/models/GlobalTag';
import { FetchGlobalTags } from 'src/app/shared/global-tags/global-tags.actions';
import { GlobalTagsState } from 'src/app/shared/global-tags/global-tags.state';

@Component({
    selector: 'app-company-tags',
    templateUrl: './company-tags.component.html',
    styleUrls: ['./company-tags.component.scss'],
})
export class CompanyTagsComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();
    @Select(GlobalTagsState.GlobalTags) globalTags$: Observable<GlobalTag[]>;

    tags: GlobalTag[] = [];
    isLoading = true;

    constructor(private store: Store, private logger: NGXLogger) {}

    ngOnInit(): void {
        this.subscriptions.add(
            this.globalTags$.subscribe((tags) => {
                if (!tags) {
                    this.logger.debug('Fetching globalTags');
                    this.store.dispatch(new FetchGlobalTags());
                } else {
                    this.isLoading = false;
                }
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
