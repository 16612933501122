import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { VeldaCountry } from 'src/app/models/VeldaCountry';

import { DeleteCountry } from '../velda-languages.actions';

@Component({
    selector: 'app-velda-languages-countries-delete-modal',
    templateUrl: './velda-languages-countries-delete-modal.component.html',
    styleUrls: ['./velda-languages-countries-delete-modal.component.scss'],
})
export class VeldaLanguagesCountriesDeleteModalComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Input() country: VeldaCountry;

    constructor(private store: Store, public activeModal: NgbActiveModal, private toastrService: ToastrService, private logger: NGXLogger) {}

    ngOnInit(): void {}

    deleteCountry() {
        this.logger.debug('Attempt to delete country');

        this.subscriptions.add(
            this.store.dispatch(new DeleteCountry(this.country.uuid)).subscribe(
                () => {
                    this.toastrService.success('Country deleted successfully!');
                    this.logger.debug('Country delete successfully!');
                    this.activeModal.close();
                },
                (error) => {
                    this.toastrService.error('Failed to delete country!');
                    this.logger.error('Failed to delete country!');
                },
            ),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
