import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Select } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subscription } from 'rxjs';
import { ContactState } from 'src/app/contacts/contacts.state';
import { ContactPerson } from 'src/app/models/ContactPerson';

@Component({
    selector: 'app-dashboard-contactpersons-widget',
    templateUrl: './dashboard-contactpersons-widget.component.html',
    styleUrls: ['./dashboard-contactpersons-widget.component.scss'],
})
export class DashboardContactpersonsWidgetComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Select(ContactState.Contacts) allContactPersons$: Observable<ContactPerson[]>;

    amountToDisplay = 10;
    noContactPersons = false;
    isLoading = true;

    displayedColumns: string[] = ['firstName', 'partnerName', 'creatorId', 'createdOn'];
    dataSource: MatTableDataSource<ContactPerson>;
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    constructor(private router: Router, private logger: NGXLogger) {}

    ngOnInit(): void {
        this.subscriptions.add(
            this.allContactPersons$.subscribe(
                (allContactPersons) => {
                    if (allContactPersons && allContactPersons.length == 0) {
                        this.logger.debug('Retrieved 0 contacts from the state');
                        this.noContactPersons = true;
                        this.isLoading = false;
                    } else if (allContactPersons && allContactPersons.length > 0) {
                        this.logger.debug('Retrieved contacts from the state');
                        const latestContacts = [...allContactPersons.slice(0, this.amountToDisplay)];
                        this.dataSource = new MatTableDataSource(latestContacts);
                        this.dataSource.sort = this.sort;
                        this.isLoading = false;
                    }
                },
                (error) => {
                    this.logger.error('Failed to retrieve contacts');
                },
            ),
        );
    }

    goToContactPersons() {
        this.logger.debug('Navigating to contacts overview');
        this.router.navigate(['/', 'contacts', 'overview']);
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
