import { Component, OnDestroy, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { MailchimpService } from 'src/app/mailchimp/mailchimp.service';
import { AddRecipientModalComponent } from 'src/app/shared/mailchimp/add-recipient-modal/add-recipient-modal.component';

import { FetchContacts } from '../comanage.actions';
import { ComanageState } from '../comanage.state';

@Component({
    selector: 'app-comanage-contacts',
    templateUrl: './comanage-contacts.component.html',
    styleUrls: ['./comanage-contacts.component.scss'],
})
export class ComanageContactsComponent implements OnDestroy {
    private subscriptions = new Subscription();

    @Select(ComanageState.contacts) coManageContacts$: Observable<any[]>;
    isset: boolean;
    filter: string;
    isLoading = true;

    // material table
    displayedColumns: string[] = ['name', 'email', 'telephone', 'type', 'number', 'edit'];
    dataSource: MatTableDataSource<any>;
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

    constructor(
        private store: Store,
        private mailchimpService: MailchimpService,
        private modalService: NgbModal,
        private toastr: ToastrService,
        private logger: NGXLogger,
    ) {}

    checkCredentials(set: boolean) {
        this.isset = set;
        this.prepareDataTable();
    }

    prepareDataTable() {
        this.subscriptions.add(
            this.coManageContacts$.subscribe((contacts) => {
                if (!contacts) {
                    this.store.dispatch(new FetchContacts()).subscribe();
                } else {
                    this.dataSource = new MatTableDataSource(contacts);
                    this.dataSource.sort = this.sort;
                    this.dataSource.paginator = this.paginator;

                    this.dataSource.sortingDataAccessor = (item, property) => {
                        if (property.includes('.')) return property.split('.').reduce((o, i) => o[i], item);
                        return item[property];
                    };

                    this.isLoading = false;
                }
            }),
        );
    }

    applyFilter(filterValue) {
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }

    addToMailchimp(contact: any) {
        this.logger.debug('Checking if Mailchimp api key exists');
        this.subscriptions.add(
            this.mailchimpService.checkIfApiKeyExists().subscribe(
                (res) => {
                    if (res.key) {
                        this.logger.debug('Found Mailchimp key');
                        this.logger.debug('opening addRecipientsToListModalComponent');
                        const modalRef = this.modalService.open(AddRecipientModalComponent, { windowClass: 'modal-prompt' });
                        modalRef.componentInstance.email = contact.email ? contact.email : '';
                        modalRef.componentInstance.phone = contact.telephone ? contact.telephone : '';
                        modalRef.componentInstance.firstname = contact.name ? contact.name : '';
                        modalRef.componentInstance.lastname = '';
                    } else {
                        this.logger.debug('No Mailchimp key found');
                        this.toastr.warning('Please set it in the module settings.', 'Missing Mailchimp key');
                    }
                },
                () => this.logger.error('Failed to find Mailchimp key'),
            ),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
