<app-app-holder>
    <app-navbar></app-navbar>

    <app-content>
        <div class="container-fluid">
            <div class="row">
                <div class="col">
                    <app-resume-detail></app-resume-detail>
                </div>
            </div>
        </div>
    </app-content>
</app-app-holder>
