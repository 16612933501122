import { TIMESHEET_STATUS, Timesheet } from '../models/Timesheet';
import { Timeslot } from '../models/Timeslot';

export class FetchTimesheets {
    static readonly type = '[Timesheet] Fetch Timesheets';
}

export class FetchTimesheetsForProject {
    static readonly type = '[Timesheet] Fetch Timesheets for project';

    constructor(public projectId: string) {}
}

export class FetchTimesheetByUserAndDate {
    static readonly type = '[Timesheet] Fetch Timesheet by user and date';

    constructor(public userId: string, public date: string) {}
}

export class ChangeSelectedDate {
    static readonly type = '[string] Change Date';

    constructor(public amount: number, public expDate?: Date) {}
}

export class AddTimesheet {
    static readonly type = '[Timesheet] Add Timesheet';

    constructor(public employeeUuid: string, public hoursRequired?: number) {}
}

export class RemoveTimesheet {
    static readonly type = '[Timesheet] Remove Timesheet';

    constructor(public timesheetUuid: string) {}
}

export class UpdateTimesheet {
    static readonly type = '[Timesheet] Update Timesheet';

    constructor(public timesheet: Timesheet) {}
}

export class UpdateTimeSlot {
    static readonly type = '[Timeslot] Update Timeslot';

    constructor(public timesheetUuid: string, public timeslot: Timeslot) {}
}

export class SetTimeslotToIsBilled {
    static readonly type = '[Timeslot] Set Timeslot to Billed';

    constructor(public timesheetId: string, public timeslotId: string, public billed: boolean) {}
}

export class RemoveTimeslot {
    static readonly type = '[Timeslot] Remove Timeslot';

    constructor(public timesheetUuid: string, public timeslotUuid: string) {}
}

export class AddTimeslot {
    static readonly type = '[Timeslot] Add Timeslot';

    constructor(public timesheetUuid: string, public projectUuid: string, public minutes: number, public pdfUrl: string, public description: string) {}
}

export class UpdateExtraInfo {
    static readonly type = '[Timesheet] Update extraInfo';

    constructor(public extraInfo: string, public timesheetUuid?: string) {}
}

export class FetchCurrentTimesheet {
    static readonly type = '[Timesheet] Fetch by ProjectUuid';

    constructor(public projectUuid: string) {}
}

export class UpdateHappinessScore {
    static readonly type = '[Timesheet] Update HappinessScore';

    constructor(public timesheetUuid: string, public happinessScore: number) {}
}

export class FetchTimesheetsForEmployee {
    static readonly type = '[Timesheet] Fetch Timesheets for employee';

    constructor(public employeeId: string) {}
}

export class ChangeTimesheetStatus {
    static readonly type = '[Timesheet] Update timesheet status';

    constructor(public timesheetId: string, public status: TIMESHEET_STATUS) {}
}

export class UpdateRequiredHours {
    static readonly type = '[Timesheet] Update required hours';

    constructor(public timesheetId: string, public hours: number) {}
}

export class FetchTimesheetsByProjectIdAndYearAndMonth {
    static readonly type = '[Timesheet] Fetch Timesheets by Project id and Year and Month';

    constructor(public projectId: string, public year: number, public month: number) {}
}

export class FetchAllProjectsProtected {
    static readonly type = '[Projects] Fetch all projects protected';

    constructor(public employeeUuid?: string) {}
}

export class ResetTimesheetsByProjectIdAndYearAndMonth {
    static readonly type = '[Timesheet] Reset Timesheets by Project id and Year and Month';
}
