<form
    [formGroup]="partnerFormGroup"
    (ngSubmit)="updatePartner()"
    class="modal-wrapper">
    <div class="modal-header">
        <h2
            class="modal-title"
            id="exampleModalLabel">
            Edit company
        </h2>
        <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="activeModal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <div class="form-group required m-b-2">
            <label>Company name</label>
            <input
                type="text"
                placeholder="Company name*"
                formControlName="title"
                trim="blur"
                #focussed />
            <span
                class="form-error"
                *ngIf="partnerFormGroup.controls.title?.errors && inputIsWrong"
                >Company name is required</span
            >
        </div>

        <div class="form-group m-b-2">
            <label>VAT number</label>
            <input
                type="text"
                placeholder="VAT number"
                formControlName="vat"
                (focus)="hideErrorMessage()"
                trim="blur" />
                <div>
                    <span *ngIf="validateVatWentWrong"
                        class="form-error"
                        >{{ errorMessage }}</span
                    >
                </div>
            <!-- <span class="form-error"
                *ngIf="partnerFormGroup.controls.vat?.errors && inputIsWrong">VAT is required</span> -->
                <button type="button" (click)="validateVatNumber()">Verify vat</button>
        </div>

        <h3 class="m-t-4 m-b-4">Address</h3>

        <div formGroupName="address">
            <div class="m-b-2">
                <div class="row">
                    <div class="col-6">
                        <div class="form-group">
                            <label>Street</label>
                            <input
                                type="text"
                                placeholder="Street"
                                formControlName="street"
                                trim="blur" />
                        </div>
                    </div>
                    <div class="col-2">
                        <div class="form-group">
                            <label>Number</label>
                            <input
                                type="number"
                                placeholder="Number"
                                formControlName="number" />
                        </div>
                    </div>
                    <div class="col-2">
                        <div class="form-group">
                            <label>Box</label>
                            <input
                                type="text"
                                placeholder="Box"
                                formControlName="bus"
                                trim="blur" />
                        </div>
                    </div>
                    <div class="col-2">
                        <div class="form-group">
                            <label>Ext</label>
                            <input
                                type="number"
                                placeholder="Ext"
                                formControlName="ext" />
                        </div>
                    </div>
                </div>
            </div>

            <div class="m-b-2">
                <div class="row">
                    <div class="col-3">
                        <div class="form-group">
                            <label>Zipcode</label>
                            <input
                                type="text"
                                placeholder="Zipcode"
                                formControlName="postal"
                                trim="blur" />
                        </div>
                    </div>
                    <div class="col-9">
                        <div class="form-group">
                            <label>City</label>
                            <input
                                type="text"
                                placeholder="City"
                                formControlName="city"
                                trim="blur" />
                        </div>
                    </div>
                </div>
            </div>

            <div class="m-b-2">
                <div class="row">
                    <div class="col">
                        <div
                            class="form-group"
                            formGroupName="country">
                            <label>Country</label>
                            <select formControlName="iso2">
                                <option
                                    *ngFor="let country of allCountries"
                                    [ngValue]="country['alpha-2']">
                                    {{ country.name }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <h3 class="m-t-4 m-b-4">Contact information</h3>

        <div formGroupName="contactInformation">
            <div class="form-group m-b-2">
                <label>Company phonenumber</label>
                <input
                    type="text"
                    placeholder="Phonenumber"
                    formControlName="phoneNumber"
                    trim="blur" />
                <span class="form-helptext">Not your personal phonenumber!</span>
            </div>

            <div class="form-group m-b-2">
                <label>Company emailaddress</label>
                <input
                    type="email"
                    placeholder="Emailaddress"
                    formControlName="email"
                    trim="blur" />
                <span class="form-helptext">Probably something like contact@mycompany.com ...</span>
                <span
                    class="form-error"
                    *ngIf="partnerFormGroup.get('contactInformation.email').errors && inputIsWrong"
                    >Invalid email</span
                >
            </div>

            <div class="form-group m-b-2">
                <label>Company website</label>
                <input
                    type="text"
                    placeholder="Website"
                    formControlName="website"
                    trim="blur" />
            </div>
            <div>
                <span
                    class="form-error"
                    *ngIf="validateWebsiteWentWrong">{{ errorMessage }}</span
                >
            </div>
            <button  type="button" (click)="validateDomain()">Verify domain</button>

            <div class="form-group m-b-2">
                <label>LinkedIn</label>
                <input
                    type="text"
                    placeholder="LinkedIn"
                    formControlName="linkedIn"
                    trim="blur" />
            </div>

            <div class="form-group m-b-2">
                <label>Tags</label>
                <app-global-tags
                    [objectUuid]="partner.uuid"
                    [type]="'partner'"></app-global-tags>
            </div>
        </div>

        <h3 class="m-t-4 m-b-4">Extra info</h3>

        <div class="form-group m-b-2">
            <label>Year Founded</label>
            <input
                type="number"
                placeholder="Year founded"
                formControlName="yearFounded"
                trim="blur" />
        </div>

        <div class="form-group m-b-2">
            <label>Industry</label>
            <input
                type="text"
                placeholder="Industry"
                formControlName="industry"
                trim="blur" />
        </div>

        <div class="form-group m-b-2">
            <label>Employees count</label>
            <input
                type="number"
                placeholder="Employees count"
                formControlName="employeesCount"
                trim="blur" />
        </div>
    </div>

    <div class="modal-footer">
        <button
            type="button"
            class="button button--outline"
            (click)="activeModal.close()">
            Close
        </button>
        <button
            type="submit"
            [ngClass]="{ 'button--loading': loading }"
            class="button button--primary">
            Save changes
        </button>
    </div>
</form>
