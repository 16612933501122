import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { AssignedResume } from 'src/app/models/AssignedResume';
import { Status } from 'src/app/models/Status';
import { Resume } from 'src/app/models/resume/Resume';
import { ResumeDetailModalComponent } from 'src/app/resumes/resume-detail-modal/resume-detail-modal.component';

import { AssignedResumeDeleteModalComponent } from '../assigned-resume-delete-modal/assigned-resume-delete-modal.component';
import { GetAllPhases, UpdateAssignmentStatus } from '../project.actions';
import { ProjectService } from '../project.service';
import { ProjectState } from '../project.state';

@Component({
    selector: 'app-project-assigned-resume-list',
    templateUrl: './project-assigned-resume-list.component.html',
    styleUrls: ['./project-assigned-resume-list.component.scss'],
})
export class ProjectAssignedResumeListComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Input() assignedResumes: AssignedResume[];
    @Select(ProjectState.status) workStatusList$: Observable<Status[]>;

    allPhases: Status[];
    companyWorkStatusList: Status[];
    statusFormGroup: FormGroup;
    loading = false;

    constructor(
        private modalService: NgbModal,
        private store: Store,
        private workService: ProjectService,
        private formBuilder: FormBuilder,
        private toastr: ToastrService,
        private logger: NGXLogger,
    ) {}

    ngOnInit(): void {
        this.logger.debug('Fetching phases');
        this.store.dispatch(new GetAllPhases());

        this.subscriptions.add(this.workService.getAllPhases().subscribe((phases) => (this.allPhases = phases)));
        this.subscriptions.add(this.workStatusList$.subscribe((phases) => (this.companyWorkStatusList = phases)));
        this.statusFormGroup = this.formBuilder.group({
            statusUuid: [''],
        });
    }

    previewResume(resume: Resume) {
        this.logger.debug('Opening resumeDetailModal');
        const modalRef = this.modalService.open(ResumeDetailModalComponent, {
            windowClass: 'modal-huge',
            animation: false,
        });
        modalRef.componentInstance.resumeUuid = resume.uuid;
    }

    openDeleteAssignedResumeModal(workAssignmentUuid: string) {
        this.logger.debug('Opening assignedResumeDeleteModal');
        const modalRef = this.modalService.open(AssignedResumeDeleteModalComponent, { windowClass: 'modal-prompt' });
        modalRef.componentInstance.workAssignmentUuid = workAssignmentUuid;
    }

    saveStatus(workAssignmentUuid: string) {
        this.loading = true;
        this.logger.debug('Attempt to update assignmentStatus');
        this.subscriptions.add(
            this.store.dispatch(new UpdateAssignmentStatus(workAssignmentUuid, this.statusFormGroup.value.statusUuid)).subscribe(
                () => {
                    this.logger.debug('Succesfully updated assignmentStatus');
                    this.toastr.success('Status edit succesfully');
                    this.loading = false;
                },
                (error) => {
                    this.logger.error('Failed to update assignmentStatus');
                },
            ),
        );
    }

    changeBackground(colour: string) {
        return 'background-color:' + colour + ';';
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
