import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { NgbDropdownModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { NgChartsModule } from 'ng2-charts';
import { TagInputModule } from 'ngx-chips';

import { ResumesModule } from '../resumes/resumes.module';
import { SharedModule } from '../shared/shared.module';
import { AddCrmdealModalComponent } from './add-crmdeal-modal/add-crmdeal-modal.component';
import { AddCrmstageModalComponent } from './add-crmstage-modal/add-crmstage-modal.component';
import { AddProductToDealModalComponent } from './add-product-to-deal-modal/add-product-to-deal-modal.component';
import { ArchivedCrmdealsComponent } from './archived-crmdeals/archived-crmdeals.component';
import { CrmOverviewComponent } from './crm-overview/crm-overview.component';
import { routing } from './crm.routing';
import { CrmdealDetailModalComponent } from './crmdeal-detail-modal/crmdeal-detail-modal.component';
import { EditCrmdealModalComponent } from './edit-crmdeal-modal/edit-crmdeal-modal.component';
import { EditReasonOffLossModalComponent } from './edit-reason-off-loss-modal/edit-reason-off-loss-modal.component';
import { FilterCrmDealsPipe } from './filter-crm-deals.pipe';
import { LostDealReasonModalComponent } from './lost-deal-reason-modal/lost-deal-reason-modal.component';
import { ManageCrmstagesModalComponent } from './manage-crmstages-modal/manage-crmstages-modal.component';

@NgModule({
    declarations: [
        CrmOverviewComponent,
        AddCrmstageModalComponent,
        AddCrmdealModalComponent,
        ManageCrmstagesModalComponent,
        CrmdealDetailModalComponent,
        AddProductToDealModalComponent,
        EditCrmdealModalComponent,
        LostDealReasonModalComponent,
        ArchivedCrmdealsComponent,
        FilterCrmDealsPipe,
        EditReasonOffLossModalComponent,
    ],
    imports: [
        CommonModule,
        SharedModule,
        AutocompleteLibModule,
        DragDropModule,
        ReactiveFormsModule,
        routing,
        NgbModule,
        NgbDropdownModule,
        TagInputModule,
        ResumesModule,
        MatTableModule,
        MatSortModule,
        MatPaginatorModule,
        NgChartsModule,
    ],
    exports: [CrmdealDetailModalComponent],
})
export class CrmModule {}
