import { Component, OnDestroy, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';

import { FetchOffers } from '../comanage.actions';
import { ComanageState } from '../comanage.state';

@Component({
    selector: 'app-comanage-offers',
    templateUrl: './comanage-offers.component.html',
    styleUrls: ['./comanage-offers.component.scss'],
})
export class ComanageOffersComponent implements OnDestroy {
    private subscriptions = new Subscription();

    @Select(ComanageState.offers) coManageOffers$: Observable<any[]>;
    isset: boolean;
    filter: string;
    isLoading = true;

    // material table
    displayedColumns: string[] = ['offer_number', 'title', 'contact.name', 'totals.total', 'due_date', 'status'];
    dataSource: MatTableDataSource<any>;

    @ViewChild(MatSort, { static: true }) sort: MatSort;
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

    constructor(private store: Store) {}

    checkCredentials(set: boolean) {
        this.isset = set;
        this.prepareDataTable();
    }

    /* setup mattable with invoices */
    prepareDataTable() {
        this.subscriptions.add(
            this.coManageOffers$.subscribe((offers) => {
                if (!offers) {
                    this.store.dispatch(new FetchOffers()).subscribe();
                } else {
                    this.dataSource = new MatTableDataSource(offers);
                    this.dataSource.sort = this.sort;
                    this.dataSource.paginator = this.paginator;

                    this.dataSource.sortingDataAccessor = (item, property) => {
                        if (property.includes('.')) return property.split('.').reduce((o, i) => o[i], item);
                        return item[property];
                    };

                    this.dataSource.filterPredicate = (data: any, filter: string) => {
                        if (data.title.toLocaleLowerCase().includes(filter.toLocaleLowerCase())) {
                            return true;
                        } else if (data.contact != undefined && data.contact.name.toLocaleLowerCase().includes(filter.toLocaleLowerCase())) {
                            return true;
                        } else if (data.offer_number != undefined && data.offer_number.toLocaleLowerCase().includes(filter.toLocaleLowerCase())) {
                            return true;
                        } else if (data.due_date != undefined && data.due_date.toLocaleLowerCase().includes(filter.toLocaleLowerCase())) {
                            return true;
                        } else if (data.totals != undefined && data.totals.total.toLocaleLowerCase().includes(filter.toLocaleLowerCase())) {
                            return true;
                        } else if (data.status != undefined && data.status.toLocaleLowerCase().includes(filter.toLocaleLowerCase())) {
                            return true;
                        } else return false;
                    };

                    this.isLoading = false;
                }
            }),
        );
    }

    applyFilter(filterValue) {
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
