<div class="modal-header">
    <h2
        class="modal-title"
        id="exampleModalLabel">
        Update settings for the link between budget expenditures and invoices
    </h2>
    <button
        (click)="activeModal.dismiss()"
        aria-label="Close"
        class="close"
        type="button">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">

    <div (click)="updateYukiSettings()" class="d-flex align-items-center">
        <span [ngClass]="{'toggle--active': saveExpenditure}" class="toggle"></span>
        <label class="m-l-4">Create budget expenditures automatically</label>
    </div>

    <div class="divider"></div>

    <div class="m-t-4">
        <p class="form-helptext m-r-4 w-100">For all existing links between an invoice and a budget, a budget expenditure will be created for the corresponding budget. The amount for the budget expenditure will be used from the link.</p>
        <button (click)="createExpenditures()" class="button">Create expenditures</button>
    </div>
</div>

<div class="modal-footer">
    <button
        (click)="activeModal.dismiss()"
        class="button button--outline"
        type="button">
        Cancel
    </button>
</div>
