import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subscription } from 'rxjs';
import { ContactsAddModalComponent } from 'src/app/contacts/contacts-add-modal/contacts-add-modal.component';
import { FetchContacts, UpdateContactPerson } from 'src/app/contacts/contacts.actions';
import { ContactState } from 'src/app/contacts/contacts.state';
import { AddCrmdealModalComponent } from 'src/app/crm/add-crmdeal-modal/add-crmdeal-modal.component';
import { FetchCrmDeals, UpdateCrmDeal } from 'src/app/crm/crm.actions';
import { CrmState } from 'src/app/crm/crm.state';
import { FetchCustomers } from 'src/app/customers/customers.actions';
import { CustomerState } from 'src/app/customers/customers.state';
import { EmployeesAddModalComponent } from 'src/app/employees/employees-add-modal/employees-add-modal.component';
import { FetchEmployees, FetchSimpleInformationForAllEmployees } from 'src/app/employees/employees.actions';
import { EmployeeState } from 'src/app/employees/employees.state';
import { ContactPerson } from 'src/app/models/ContactPerson';
import CrmDeal from 'src/app/models/CrmDeal';
import Customer from 'src/app/models/Customer';
import { Employee } from 'src/app/models/Employee';
import { Partner } from 'src/app/models/Partner';
import { Product } from 'src/app/models/Product';
import Project from 'src/app/models/Project';
import { FetchPartners } from 'src/app/partners/partners.actions';
import { PartnerState } from 'src/app/partners/partners.state';
import { ProductAddModalComponent } from 'src/app/products/product-add-modal/product-add-modal.component';
import { FetchProducts } from 'src/app/products/product.actions';
import { ProductState } from 'src/app/products/product.state';
import { ProjectDetailModalComponent } from 'src/app/projects/project-detail-modal/project-detail-modal.component';
import { AddProjectProducts, DeleteProductFromProject, FetchProjects, UpdateProject } from 'src/app/projects/project.actions';
import { ProjectState } from 'src/app/projects/project.state';
import { AddRelatedEmployee, DeleteRelatedEmployee, FetchRelatedEmployees } from 'src/app/related-employees/related.employees.action';
import { RelatedEmployeesState } from 'src/app/related-employees/related.employees.state';

import { AddLinkModalComponent } from './add-link-modal/add-link-modal.component';

@Component({
    selector: 'app-global-link-autocomplete',
    templateUrl: './global-link-autocomplete.component.html',
    styleUrls: ['./global-link-autocomplete.component.scss'],
})
export class GlobalLinkAutocompleteComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Select(PartnerState.getPartners) partners$: Observable<Partner[]>;
    @Select(CustomerState.Customers) customers$: Observable<Customer[]>;
    @Select(ContactState.Contacts) contacts$: Observable<ContactPerson[]>;
    @Select(ProductState.Products) products$: Observable<Product[]>;
    @Select(EmployeeState.EmployeesSimpleInformation) employees$: Observable<Employee[]>;
    @Select(CrmState.CrmDeals) deals$: Observable<CrmDeal[]>;

    @Select(ProjectState.projects) projects$: Observable<Project[]>;
    @Select(ProjectState.project) project$: Observable<Project>;

    @Select(RelatedEmployeesState.RelatedEmployeeObjects) relatedEmployeeObjects$: Observable<any[]>;

    //objects that can be linked
    data: any;

    //parent object that will receive the link
    @Input() itemType: string = null;
    @Input() item: any = null;

    //child objects that are linked to the parent
    @Input() itemLinkType: string = null;
    linkedObjects: any[];

    linkItemFunction = undefined;
    createItemFunction = undefined;
    unLinkItemFunction = undefined;
    searchKeyword: string;
    extraKeyword: string = undefined;
    isLoading: boolean = true;
    selectedItem: any = undefined;
    removingLink: boolean = false;

    emptyMessageTitle: string = 'No linked objects...';
    emptyMessageDescription: string = 'Link a object now.';

    prefilledInformation: any;

    constructor(private logger: NGXLogger, private store: Store, private modalService: NgbModal) {}

    ngOnInit(): void {
        switch (this.itemType) {
            case 'partner':
                this.partnerInit();
                break;
            case 'customer':
                this.customerInit();
                break;
            case 'project':
                this.projectInit();
                break;
            case 'product':
                this.productInit();
                break;
        }
    }

    productInit() {
        let product = this.item as Product;
        this.subscriptions.add(
            this.products$.subscribe((products) => {
                product = products.find((p) => p.uuid == product.uuid);
            }),
        );
        switch (this.itemLinkType) {
            case 'employee':
                this.searchKeyword = 'firstName';
                this.extraKeyword = 'lastName';
                this.emptyMessageTitle = 'No linked employees...';
                this.emptyMessageDescription = 'Start managing your employees in the employees module.';
                this.subscriptions.add(
                    this.relatedEmployeeObjects$.subscribe((objects) => {
                        if (objects == null) {
                            this.store.dispatch(new FetchRelatedEmployees());
                        } else {
                            this.linkedObjects = JSON.parse(JSON.stringify(objects.find((o) => o.uuid == product.uuid).employees));
                            this.isLoading = false;
                            this.removingLink = false;
                            this.employees$.subscribe((employees) => {
                                if (employees == null) {
                                    this.store.dispatch(new FetchSimpleInformationForAllEmployees());
                                } else {
                                    this.data = employees.filter((employee) => !this.linkedObjects.find((e) => e.uuid == employee.uuid));
                                }
                            });
                        }
                    }),
                );
                this.createItemFunction = (): void => {
                    this.logger.debug('opening addEmployeeModal');
                    const modalRef = this.modalService.open(EmployeesAddModalComponent, {
                        windowClass: 'modal-pane',
                        animation: false,
                    });
                    this.subscriptions.add(
                        modalRef.componentInstance.emitObject.subscribe((employee) => {
                            this.selectedItem = employee;
                        }),
                    );
                };

                this.linkItemFunction = (): void => {
                    if (this.selectedItem != undefined) {
                        this.logger.debug('Linking product with employee');
                        this.linkedObjects.push(this.selectedItem);
                        this.data = this.data.filter((e) => e.uuid != this.selectedItem.uuid);
                        const employee: Employee = JSON.parse(JSON.stringify(this.selectedItem));
                        this.store.dispatch(new AddRelatedEmployee(product.uuid, employee.uuid)).subscribe(() => {
                            this.store.dispatch(new FetchProducts());
                            this.store.dispatch(new FetchEmployees());
                        });
                        this.selectedItem = undefined;
                    }
                };
                this.unLinkItemFunction = (employee: Employee): void => {
                    this.removingLink = true;

                    this.subscriptions.add(
                        this.store.dispatch(new DeleteRelatedEmployee(product.uuid, employee.uuid)).subscribe(() => {
                            this.store.dispatch(new FetchProducts());
                            this.store.dispatch(new FetchEmployees());
                        }),
                    );
                };
                break;
        }
    }

    projectInit() {
        let project = this.item as Project;

        this.subscriptions.add(this.project$.subscribe((p) => (project = p)));

        switch (this.itemLinkType) {
            case 'product':
                this.searchKeyword = 'title';
                this.linkedObjects = JSON.parse(JSON.stringify(project.products));
                this.subscriptions.add(
                    this.products$.subscribe((products) => {
                        if (products == null) {
                            this.store.dispatch(new FetchProducts());
                        } else {
                            this.data = products.filter((prod) => !this.linkedObjects.find((p) => p.uuid == prod.uuid));
                            this.emptyMessageTitle = 'No linked products...';
                            this.emptyMessageDescription = 'Start managing your products in the inventory module.';
                            this.isLoading = false;
                        }
                    }),
                );
                this.createItemFunction = (): void => {
                    this.logger.debug('opening addProductModal');
                    const modalRef = this.modalService.open(ProductAddModalComponent, {
                        windowClass: 'modal-huge',
                        animation: false,
                    });
                    this.subscriptions.add(
                        modalRef.componentInstance.emitObject.subscribe((product) => {
                            this.selectedItem = product;
                        }),
                    );
                };

                this.linkItemFunction = (): void => {
                    if (this.selectedItem != undefined) {
                        this.logger.debug('Linking project with product');
                        this.linkedObjects.push(this.selectedItem);
                        this.data = this.data.filter((p) => p.uuid != this.selectedItem.uuid);
                        const product: Product = JSON.parse(JSON.stringify(this.selectedItem));
                        this.subscriptions.add(
                            this.store.dispatch(new AddProjectProducts(project.uuid, product.uuid)).subscribe(() => {
                                this.store.dispatch(new FetchProjects());
                                this.store.dispatch(new FetchProducts());
                            }),
                        );
                        this.selectedItem = undefined;
                    }
                };
                this.unLinkItemFunction = (product: Product): void => {
                    this.removingLink = true;
                    this.subscriptions.add(
                        this.store.dispatch(new DeleteProductFromProject(project.uuid, product.uuid)).subscribe(() => {
                            this.store.dispatch(new FetchProjects()).subscribe(() => {
                                this.removingLink = false;
                            });
                            this.linkedObjects = JSON.parse(JSON.stringify(project.products));
                            this.store.dispatch(new FetchProducts());
                        }),
                    );
                };
                break;
        }
    }

    customerInit() {
        let customer = this.item as Customer;
        switch (this.itemLinkType) {
            case 'deal':
                this.searchKeyword = 'title';
                this.subscriptions.add(
                    this.customers$.subscribe((customers) => {
                        customer = customers.find((c) => c.uuid == customer.uuid);
                        this.linkedObjects = JSON.parse(JSON.stringify(customer.crmDeals));
                        this.deals$.subscribe((deals) => {
                            if (deals == null) {
                                this.store.dispatch(new FetchCrmDeals());
                            } else {
                                this.data = deals.filter((d) => d.customer == null);
                                this.emptyMessageTitle = 'No linked deals...';
                                this.emptyMessageDescription = 'Deals can be managed in the CRM module.';
                                this.isLoading = false;
                            }
                        });
                    }),
                );
                this.createItemFunction = (): void => {
                    this.logger.debug('opening addDealModal');
                    const modalRef = this.modalService.open(AddCrmdealModalComponent, {
                        windowClass: 'modal-pane',
                        animation: false,
                    });
                    this.subscriptions.add(
                        modalRef.componentInstance.emitObject.subscribe((crmDeal) => {
                            this.selectedItem = crmDeal;
                        }),
                    );
                };

                this.linkItemFunction = (): void => {
                    if (this.selectedItem != undefined) {
                        this.logger.debug('Linking deal and customer');
                        this.linkedObjects.push(this.selectedItem);
                        this.data = this.data.filter((d) => d.uuid != this.selectedItem.uuid);
                        const deal: CrmDeal = JSON.parse(JSON.stringify(this.selectedItem));
                        deal.customer = customer;
                        this.subscriptions.add(
                            this.store.dispatch(new UpdateCrmDeal(deal)).subscribe(() => {
                                this.store.dispatch(new FetchCustomers());
                            }),
                        );
                        this.selectedItem = undefined;
                    }
                };
                this.unLinkItemFunction = (deal: CrmDeal): void => {
                    this.removingLink = true;
                    const updatedDeal = Object.assign({}, deal, { customer: null });
                    this.subscriptions.add(
                        this.store.dispatch(new UpdateCrmDeal(updatedDeal)).subscribe(() => {
                            this.store.dispatch(new FetchCustomers()).subscribe(() => {
                                this.removingLink = false;
                            });
                        }),
                    );
                };
                break;
            case 'project':
                this.searchKeyword = 'title';
                this.subscriptions.add(
                    this.customers$.subscribe((customers) => {
                        customer = customers.find((c) => c.uuid == customer.uuid);
                        this.linkedObjects = JSON.parse(JSON.stringify(customer.projects));
                        this.projects$.subscribe((projects) => {
                            if (projects == null) {
                                this.store.dispatch(new FetchProjects());
                            } else {
                                this.data = projects.filter((w) => w.customerUuid == null);
                                this.emptyMessageTitle = 'No linked projects...';
                                this.emptyMessageDescription = 'Link a project here.';
                                this.isLoading = false;
                            }
                        });
                    }),
                );
                this.createItemFunction = (): void => {
                    this.logger.debug('opening addProjectModal');
                    const modalRef = this.modalService.open(ProjectDetailModalComponent, {
                        windowClass: 'modal-pane',
                        animation: false,
                    });
                    this.subscriptions.add(
                        modalRef.componentInstance.emitObject.subscribe((project: Project) => {
                            this.selectedItem = project;
                        }),
                    );
                };
                this.linkItemFunction = (): void => {
                    this.logger.debug('linking customer and project');
                    if (this.selectedItem != undefined) {
                        this.linkedObjects.push(this.selectedItem);
                        this.data = this.data.filter((w) => w.uuid != this.selectedItem.uuid);
                        const project: Project = JSON.parse(JSON.stringify(this.selectedItem));
                        project.startDate = project.startDate.substr(0, 10);
                        project.endDate = project.endDate.substr(0, 10);
                        project.customerUuid = customer.uuid;
                        project.customerFirstname = customer.firstName;
                        project.customerLastname = customer.lastName;
                        this.subscriptions.add(
                            this.store.dispatch(new UpdateProject(this.selectedItem.uuid, project)).subscribe(() => {
                                this.store.dispatch(new FetchCustomers());
                            }),
                        );
                        this.selectedItem = undefined;
                    }
                };
                this.unLinkItemFunction = (object: Project): void => {
                    const project = JSON.parse(JSON.stringify(object));
                    this.removingLink = true;
                    this.linkedObjects = this.linkedObjects.filter((obj) => obj.uuid != project.uuid);
                    project.customerUuid = null;
                    project.customerFirstname = null;
                    project.customerLastname = null;
                    this.subscriptions.add(
                        this.store.dispatch(new UpdateProject(project.uuid, project)).subscribe(() => {
                            this.store.dispatch(new FetchCustomers()).subscribe(() => {
                                this.removingLink = false;
                            });
                        }),
                    );
                };
                break;
        }
    }

    partnerInit() {
        let partner = this.item as Partner;
        this.prefilledInformation = { partner: partner };
        switch (this.itemLinkType) {
            case 'contact':
                this.searchKeyword = 'firstName';
                this.extraKeyword = 'lastName';
                this.subscriptions.add(
                    this.partners$.subscribe((partners) => {
                        partner = partners.find((p) => p.uuid == partner.uuid);
                        this.linkedObjects = JSON.parse(JSON.stringify(partner.contactPeople));
                        this.contacts$.subscribe((contacts) => {
                            if (contacts == null) {
                                this.store.dispatch(new FetchContacts());
                            } else {
                                this.data = contacts.filter((contact) => contact.partnerUuid == null);
                                this.emptyMessageTitle = 'No linked contacts...';
                                this.emptyMessageDescription = 'Contacts are your way of communication with other companies.';
                                this.isLoading = false;
                            }
                        });
                    }),
                );
                this.createItemFunction = (): void => {
                    this.logger.debug('opening addContactModal');
                    const modalRef = this.modalService.open(ContactsAddModalComponent, {
                        windowClass: 'modal-pane',
                        animation: false,
                    });
                    this.subscriptions.add(
                        modalRef.componentInstance.emitObject.subscribe((contact) => {
                            this.selectedItem = contact;
                            this.linkItemFunction();
                        }),
                    );
                };

                this.linkItemFunction = (): void => {
                    if (this.selectedItem != undefined && this.selectedItem != '') {
                        this.logger.debug('Linking partner and contact');
                        this.linkedObjects.push(this.selectedItem);
                        this.data = this.data.filter((c) => c.uuid != this.selectedItem.uuid);
                        const contact: ContactPerson = JSON.parse(JSON.stringify(this.selectedItem));
                        contact.partnerUuid = partner.uuid;
                        contact.partnerName = partner.title;
                        this.subscriptions.add(
                            this.store.dispatch(new UpdateContactPerson(contact)).subscribe(() => {
                                this.store.dispatch(new FetchPartners());
                            }),
                        );
                        this.selectedItem = undefined;
                    }
                };
                this.unLinkItemFunction = (contact: ContactPerson): void => {
                    this.removingLink = true;
                    contact.partnerUuid = null;
                    contact.partnerName = null;
                    this.subscriptions.add(
                        this.store.dispatch(new UpdateContactPerson(contact)).subscribe(() => {
                            this.store.dispatch(new FetchPartners()).subscribe(() => {
                                this.removingLink = false;
                            });
                        }),
                    );
                };
                break;
            case 'project':
                this.searchKeyword = 'title';
                this.subscriptions.add(
                    this.projects$.subscribe((projects) => {
                        if (projects == null) {
                            this.store.dispatch(new FetchProjects());
                        } else {
                            this.data = projects.filter((w) => w.partnerUuid == null);
                            this.linkedObjects = projects.filter((w) => w.partnerUuid === partner.uuid);
                            this.emptyMessageTitle = 'No linked projects...';
                            this.emptyMessageDescription = 'Link a project here.';
                            this.isLoading = false;
                        }
                    }),
                );
                this.createItemFunction = (): void => {
                    this.logger.debug('opening addProjectModal');
                    const modalRef = this.modalService.open(ProjectDetailModalComponent, {
                        windowClass: 'modal-pane',
                        animation: false,
                    });
                    this.subscriptions.add(
                        modalRef.componentInstance.emitObject.subscribe((project: Project) => {
                            this.selectedItem = project;
                        }),
                    );
                };
                this.linkItemFunction = (): void => {
                    this.logger.debug('linking partner and project');
                    if (this.selectedItem != undefined) {
                        this.linkedObjects.push(this.selectedItem);
                        this.selectedItem.partnerUuid == partner.uuid;
                        const project: Project = JSON.parse(JSON.stringify(this.selectedItem));
                        project.startDate = project.startDate.substr(0, 10);
                        project.endDate = project.endDate?.substr(0, 10);
                        project.partnerUuid = partner.uuid;
                        project.partnerName = partner.title;
                        this.subscriptions.add(
                            this.store.dispatch(new UpdateProject(this.selectedItem.uuid, project)).subscribe(() => {
                                this.store.dispatch(new FetchPartners());
                            }),
                        );
                        this.selectedItem = undefined;
                    }
                };
                this.unLinkItemFunction = (project: Project): void => {
                    this.removingLink = true;
                    const projectToUnlink: Project = JSON.parse(JSON.stringify(project));
                    projectToUnlink.partnerUuid = null;
                    projectToUnlink.partnerName = null;
                    this.subscriptions.add(
                        this.store.dispatch(new UpdateProject(project.uuid, projectToUnlink)).subscribe(() => {
                            this.store.dispatch(new FetchPartners()).subscribe(() => {
                                this.removingLink = false;
                            });
                        }),
                    );
                };
                break;
        }
    }

    openGlobalAutocomplete() {
        const modalRef = this.modalService.open(AddLinkModalComponent, { windowClass: 'modal-prompt' });
        modalRef.componentInstance.data = this.data;
        modalRef.componentInstance.isLoading = this.isLoading;
        modalRef.componentInstance.addItemFunction = this.createItemFunction;
        modalRef.componentInstance.itemType = this.itemLinkType;
        modalRef.componentInstance.keyword = this.searchKeyword;
        this.prefilledInformation ? (modalRef.componentInstance.prefilledInformation = this.prefilledInformation) : undefined;
        this.subscriptions.add(
            modalRef.componentInstance.emitObject.subscribe((emitObject) => {
                this.selectedItem = emitObject;
                this.linkItemFunction();
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
