import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TeamleaderCalendarCall } from 'src/app/models/TeamleaderCalendarCall';
import { TeamleaderCalendarMeeting } from 'src/app/models/TeamleaderCalendarMeeting';
import { TeamleaderCalendarTask } from 'src/app/models/TeamleaderCalendarTask';
import { TeamleaderContact } from 'src/app/models/TeamleaderContact';
import { TeamleaderUser } from 'src/app/models/TeamleaderUser';
import { environment } from 'src/environments/environment';

import { AuthenticationService } from '../authentication/authentication.service';

@Injectable({
    providedIn: 'root',
})
export class TeamleaderService {
    httpOptions = {};

    constructor(private httpClient: HttpClient, private authenticationService: AuthenticationService) {
        this.httpOptions = authenticationService.getHeaders();
    }

    getExistingCredentials() {
        return this.httpClient.get(`${environment.baseUrl}/teamleader/getCredentials`, this.httpOptions);
    }

    getAuthorizationUrl(): any {
        return this.httpClient.get(`${environment.baseUrl}/teamleader/authorize`, this.httpOptions);
    }

    logoutTeamleader(companyUuid: string) {
        return this.httpClient.delete(`${environment.baseUrl}/teamleader/logout/${companyUuid}`, this.httpOptions);
    }

    saveAccessTokenByCode(code: string) {
        return this.httpClient.get(`${environment.baseUrl}/teamleader/code-to-token/${code}`, this.httpOptions);
    }

    saveCredentials(credentials) {
        return this.httpClient.post(`${environment.baseUrl}/teamleader/addCredentials`, credentials, this.httpOptions);
    }

    getUsers(): Observable<any> {
        return this.httpClient.get<any>(`${environment.baseUrl}/teamleader/users`, this.httpOptions);
    }

    getUserById(id: string): Observable<TeamleaderUser> {
        return this.httpClient.get<TeamleaderUser>(`${environment.baseUrl}/teamleader/users/${id}`, this.httpOptions);
    }

    getContacts(): Observable<any> {
        return this.httpClient.get<any>(`${environment.baseUrl}/teamleader/contacts`, this.httpOptions);
    }

    getContactById(id: string): Observable<TeamleaderContact> {
        return this.httpClient.get<TeamleaderContact>(`${environment.baseUrl}/teamleader/contacts/${id}`, this.httpOptions);
    }

    getCalendarEvents(): Observable<any> {
        return this.httpClient.get<any>(`${environment.baseUrl}/teamleader/calendar`, this.httpOptions);
    }

    getCalendarEventById(calendarEventId: string): Observable<any> {
        return this.httpClient.get<any>(`${environment.baseUrl}/teamleader/calendar/${calendarEventId}`, this.httpOptions);
    }

    getActivityTypes(): Observable<any> {
        return this.httpClient.get<any>(`${environment.baseUrl}/teamleader/activity-types`, this.httpOptions);
    }

    getDepartments(): Observable<any> {
        return this.httpClient.get<any>(`${environment.baseUrl}/teamleader/departments`, this.httpOptions);
    }

    getDepartmentInfo(id: string): Observable<any> {
        return this.httpClient.get<any>(`${environment.baseUrl}/teamleader/departments/${id}`, this.httpOptions);
    }

    addCalendarMeeting(meeting: TeamleaderCalendarMeeting): any {
        return this.httpClient.post(`${environment.baseUrl}/teamleader/calendar-meeting`, meeting, this.httpOptions);
    }

    addCalendarTask(task: TeamleaderCalendarTask): any {
        return this.httpClient.post(`${environment.baseUrl}/teamleader/calendar-task`, task, this.httpOptions);
    }

    addCalendarCall(call: TeamleaderCalendarCall): any {
        return this.httpClient.post(`${environment.baseUrl}/teamleader/calendar-call`, call, this.httpOptions);
    }

    getWorkTypes(): Observable<any> {
        return this.httpClient.get<any>(`${environment.baseUrl}/teamleader/work-types`, this.httpOptions);
    }

    getCompanies(): Observable<any> {
        return this.httpClient.get<any>(`${environment.baseUrl}/teamleader/companies`, this.httpOptions);
    }

    getDeals(): Observable<any> {
        return this.httpClient.get<any>(`${environment.baseUrl}/teamleader/deals`, this.httpOptions);
    }

    getDealPhases(): Observable<any> {
        return this.httpClient.get<any>(`${environment.baseUrl}/teamleader/deals/phases`, this.httpOptions);
    }

    addDeal(deal: any): Observable<any> {
        return this.httpClient.post<any>(`${environment.baseUrl}/teamleader/deals`, deal, this.httpOptions);
    }
}
