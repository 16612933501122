import { Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { VeldaEntities } from 'src/app/models/VeldaEntities';
import { VeldaArticlesArticlesDetailModalComponent } from 'src/app/velda-articles/velda-articles-articles/velda-articles-articles-detail-modal/velda-articles-articles-detail-modal.component';

@Component({
    selector: 'app-dynamic-mat-table',
    templateUrl: './dynamic-mat-table.component.html',
    styleUrls: ['./dynamic-mat-table.component.scss'],
})
export class DynamicMatTableComponent implements OnInit, OnChanges {
    @Input() data: any[];
    @Input() entity: string;

    urlRegex = new RegExp(
        '^(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$',
        'i',
    );

    displayedColumns: string[];
    exceptionColumnsWithValue: Map<string, string>;

    dataSource: MatTableDataSource<any>;
    filter: string;
    @ViewChild(MatSort) sort: MatSort;

    loading = true;
    exceptionTableData: Map<string, string> = new Map();

    constructor(private modalService: NgbModal) {}

    ngOnInit(): void {}

    ngOnChanges(): void {
        this.prepareTable(this.data);
    }

    prepareTable(data: any[]) {
        if (!data) return;

        this.displayedColumns = [];
        this.exceptionColumnsWithValue = new Map();

        /* Get keys of returned data obj */
        const keys = Object.keys(data[0]);

        /* 
      Iterate and check if the value associated with the key
      is an array of data. If so, we don't use it as a displayedCollumn
      except if we add an exception for it
    */
        for (const key in keys) {
            if (!Array.isArray(data[0][keys[key]])) {
                this.displayedColumns.push(keys[key]);
            } else {
                const map = new Map<string, string>();
                map.set('mainimage', 'resumoThumbnail');
                this.addArrayExceptions(keys[key], map);
            }
        }

        /* setup datasource */
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.sort = this.sort;

        /* sortingDataAccessor to sort on nested objs */
        this.dataSource.sortingDataAccessor = (item, property) => {
            if (property.includes('.')) return property.split('.').reduce((o, i) => o[i], item);
            return item[property];
        };

        this.loading = false;
    }

    applyFilter(filterValue) {
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }

    /* 
    We pass a map with key-value pairs where the key is the collumnname
    and the value is the property that needs to get displayed.
    
    If the passed key of the data is included in the map it gets
    added to the displayed collumns. the map entry is also pushed to
    another map used to retrieve the value in the html template.
  */
    addArrayExceptions(key: string, map: Map<string, string>) {
        if (Array.from(map.keys()).includes(key)) {
            this.exceptionColumnsWithValue.set(key, map.get(key));
            this.displayedColumns.push(key);
        }
    }

    openDetailModal(row: any) {
        const modalRef = this.modalService.open(VeldaArticlesArticlesDetailModalComponent, { windowClass: 'modal-huge', animation: false });
        modalRef.componentInstance.data = row;
        modalRef.componentInstance.entity = Object.keys(VeldaEntities).find((key) => VeldaEntities[key] === this.entity);
    }
}
