<a
    (click)="openModal()"
    class="pill pill--mailchimp"
    *ngIf="mailchimpEnabled">
    <i class="lab la-mailchimp"></i>
    <app-loading-message *ngIf="pending"></app-loading-message>
    <div *ngIf="accountLinked && !pending && keyIsset">
        In audience
        <span
            *ngFor="let tag of recipient.tags"
            class="badge m-l-1"
            >{{ tag }}</span
        >
    </div>
    <div *ngIf="!accountLinked && !pending && keyIsset">
        Not added
        <span class="badge m-l-1">ADD</span>
    </div>
    <div *ngIf="!keyIsset && !pending">Key has not been set</div>
</a>
