import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';
import CampaignModel from 'src/app/models/CampaignModel';
import CampaignReportModel from 'src/app/models/CampaignReportModel';
import MailchimpContentInfo from 'src/app/models/MailchimpContentInfo';
import RecipientListModel from 'src/app/models/RecipientListModel';

import { MailchimpService } from '../mailchimp.service';

@Component({
    selector: 'app-mailchimp-campaign-report',
    templateUrl: './mailchimp-campaign-report.component.html',
    styleUrls: ['./mailchimp-campaign-report.component.scss'],
})
export class MailchimpCampaignReportComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Input() campaign: CampaignModel;

    report: CampaignReportModel;
    loading = true;
    list: RecipientListModel;
    content: MailchimpContentInfo;

    constructor(public activeModal: NgbActiveModal, private mailchimpService: MailchimpService, private store: Store) {}

    ngOnInit(): void {
        this.subscriptions.add(this.mailchimpService.getCampaingContent(this.campaign.id).subscribe((c) => (this.content = c)));
        this.subscriptions.add(this.mailchimpService.getListById(this.campaign.recipientListId).subscribe((l) => (this.list = l)));
        if (this.campaign.status == 'sent') {
            this.subscriptions.add(
                this.mailchimpService.getReport(this.campaign.id).subscribe((r) => {
                    this.report = r;
                    this.loading = false;
                }),
            );
        }
    }

    generateArray(obj) {
        return Object.keys(obj).map((key) => {
            return { key: key, value: obj[key] };
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
