import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { AuthenticationService } from '../authentication/authentication.service';
import { ContactPerson } from '../models/ContactPerson';

@Injectable({
    providedIn: 'root',
})
export class ContactsService {
    httpOptions = {};

    constructor(private httpClient: HttpClient, private authenticationService: AuthenticationService) {
        this.httpOptions = authenticationService.getHeaders();
    }

    getAllContactPersons(): Observable<ContactPerson[]> {
        return this.httpClient.get<ContactPerson[]>(environment.baseUrl + '/contact/company-contact-people-list', this.httpOptions);
    }

    addContact(contactPerson: ContactPerson): Observable<ContactPerson> {
        return this.httpClient.post<ContactPerson>(environment.baseUrl + `/contact`, contactPerson, this.httpOptions);
    }

    getContactPersonById(contactPersonUuid: string): Observable<ContactPerson> {
        return this.httpClient.get<ContactPerson>(environment.baseUrl + '/contact/' + contactPersonUuid, this.httpOptions);
    }

    updateContactPerson(contactPersonToUpdate: ContactPerson): Observable<ContactPerson> {
        return this.httpClient.put<ContactPerson>(environment.baseUrl + '/contact/', contactPersonToUpdate, this.httpOptions);
    }

    deleteContactPerson(contactPersonUuid: string) {
        return this.httpClient.delete<string>(environment.baseUrl + '/contact/' + contactPersonUuid, this.httpOptions);
    }
}
