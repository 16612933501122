import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subscription } from 'rxjs';

import YukiDocument from '../../models/yuki/YukiDocument';
import { FetchDocumentById } from '../yuki.actions';
import { YukiState } from '../yuki.state';

@Component({
    selector: 'app-invoice-information-modal',
    templateUrl: './invoice-information-modal.component.html',
    styleUrls: ['./invoice-information-modal.component.scss'],
})
export class InvoiceInformationModalComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Input() yukiDocumentId: string;

    @Select(YukiState.documentForInformationModal)
    yukiDocument$: Observable<YukiDocument>;
    yukiDocument: YukiDocument;

    constructor(public activeModal: NgbActiveModal, private store: Store, private logger: NGXLogger) {}

    ngOnInit(): void {
        this.fetchData();
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    private fetchData() {
        this.subscriptions.add(
            this.yukiDocument$.subscribe((yukiDocument) => {
                if (!yukiDocument || yukiDocument.uuid !== this.yukiDocumentId) {
                    this.logger.debug('Fetching Yuki document by id');
                    this.store.dispatch(new FetchDocumentById(this.yukiDocumentId));
                    return;
                }
                this.yukiDocument = yukiDocument;
            }),
        );
    }
}
