<div class="modal-header">
    <h2
        class="modal-title"
        id="exampleModalLabel">
        {{ template.name }}
    </h2>
    <span></span>
    <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <div style="align-self: center">
        <img
            style="width: 100%; height: 100%"
            src="{{ template.thumbnail }}" />
    </div>
</div>
