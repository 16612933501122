<div class="modal-wrapper">
    <div class="modal-header">
        <h2
            class="modal-title"
            id="exampleModalLabel">
            New company member
        </h2>
        <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="activeModal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <form
            [formGroup]="memberFormGroup"
            (ngSubmit)="addMember()">
            <div class="row align-items-end">
                <div class="col-md-8">
                    <div class="form-group m-b-2">
                        <label>Invite new member by emailaddress</label>
                        <input
                            type="email"
                            placeholder="Emailaddress"
                            formControlName="email"
                            name="email"
                            autocomplete="off"
                            (focus)="hideErrorMessage()"
                            #focussed />
                        <span
                            class="form-error"
                            *ngIf="
                                memberFormGroup.controls.email.errors &&
                                inputIsWrong &&
                                (memberFormGroup.controls.email.dirty || memberFormGroup.controls.email.touched)
                            "
                            >This email is invalid</span
                        >
                    </div>
                </div>
                <div class="col-md-4">
                    <button
                        type="submit"
                        [ngClass]="{ 'button--loading': loading }"
                        class="button button--primary m-b-2 w-100">
                        Invite
                    </button>
                </div>
            </div>
        </form>

        <div class="divider"></div>

        <app-company-invited-members-list [companyUuid]="companyUuid$ | async"></app-company-invited-members-list>
    </div>

    <div class="modal-footer">
        <button
            type="button"
            class="button button--outline"
            (click)="activeModal.close()">
            Close
        </button>
        <span
            class="form-error"
            *ngIf="addMemberWentWrong"
            >{{ errorMessage }}</span
        >
    </div>
</div>
