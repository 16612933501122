<div class="modal-header">
    <h2
        class="modal-title"
        id="exampleModalLabel">
        Manage categories
    </h2>
    <span></span>
    <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <div class="table__overflow">
        <table
            mat-table
            [dataSource]="productCategories$ | async"
            matSort>
            <ng-container matColumnDef="category">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header>
                    Category
                </th>
                <td
                    mat-cell
                    *matCellDef="let category">
                    {{ category }}
                </td>
            </ng-container>

            <ng-container matColumnDef="edit">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    class="td-small"></th>
                <td
                    mat-cell
                    *matCellDef="let category"
                    class="td-small">
                    <div
                        ngbDropdown
                        class="dropdown"
                        *appIsPermitted="{
                            module: 'INVENTORY',
                            operator: 'OR',
                            actions: ['EDIT', 'DELETE']
                        }">
                        <button
                            ngbDropdownToggle
                            class="button button--icon button--small dropdown-toggle"
                            id="dropdownMenuButton"></button>
                        <div
                            ngbDropdownMenu
                            class="dropdown-menu dropdown-menu-right"
                            aria-labelledby="dropdownMenuButton">
                            <!--
                            <a class="dropdown-item" *appIsPermitted="{module:'INVENTORY', operator:'AND', actions:['EDIT']}">Edit category</a>
                            -->
                            <a
                                class="dropdown-item color--red"
                                *appIsPermitted="{
                                    module: 'INVENTORY',
                                    operator: 'AND',
                                    actions: ['DELETE']
                                }"
                                (click)="deleteCategory(category)"
                                >Delete</a
                            >
                        </div>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="add">
                <ng-container
                    *appIsPermitted="{
                        module: 'INVENTORY',
                        operator: 'AND',
                        actions: ['CREATE']
                    }">
                    <td
                        mat-footer-cell
                        *matFooterCellDef
                        colspan="5"
                        class="table-view__add"
                        (click)="addCategory()">
                        <i class="las la-plus"></i> Add category
                    </td>
                </ng-container>
            </ng-container>

            <tr
                mat-header-row
                *matHeaderRowDef="displayedColumns"></tr>

            <tr
                mat-row
                *matRowDef="let row; columns: displayedColumns"></tr>

            <tr
                mat-footer-row
                *matFooterRowDef="['add']"></tr>
        </table>
    </div>
</div>
