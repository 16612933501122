<form
    (ngSubmit)="saveEducation()"
    [formGroup]="educationFormGroup"
    class="modal-wrapper">
    <header class="modal-header">
        <h2 class="modal-title">
            <ng-container *ngIf="!isUpdate">New</ng-container>
            <ng-container *ngIf="isUpdate">Edit</ng-container>
            education
        </h2>
        <button
            (click)="activeModal.dismiss()"
            aria-label="Close"
            class="close"
            type="button">
            <span aria-hidden="true">&times;</span>
        </button>
    </header>

    <main class="modal-body">
        <section>
            <p class="sectiontitle sectiontitle--first">Information</p>

            <div class="form-group required m-b-2">
                <label>Institution (school)</label>
                <input
                    (focus)="hideErrorMessage()"
                    formControlName="institution"
                    trim="blur"
                    type="text" />
                <span
                    *ngIf="educationFormGroup.controls.institution.errors && inputIsWrong"
                    class="form-error"
                >Institution is required</span
                >
            </div>
            <div class="form-group m-b-2 required">
                <label>Field of study</label>
                <input
                    (focus)="hideErrorMessage()"
                    formControlName="studyField"
                    trim="blur"
                    type="text" />
                <span
                    *ngIf="educationFormGroup.controls.studyField.errors && inputIsWrong"
                    class="form-error"
                >Field of study is required</span
                >
            </div>
            <div class="form-group m-b-2 required">
                <label>Degree</label>
                <input
                    (focus)="hideErrorMessage()"
                    formControlName="degree"
                    trim="blur"
                    type="text" />
                <span class="form-helptext form-helptext--no-padding m-t-2">Bachelor, Master, ...</span>
                <span
                    *ngIf="educationFormGroup.controls.degree.errors && inputIsWrong"
                    class="form-error"
                >Degree is required</span
                >
                <span
                    *ngIf="educationWentWrong"
                    class="form-error"
                >{{ errorMessage }}</span
                >
            </div>
            <div
                (focus)="hideErrorMessage()"
                class="form-group m-b-2 required"
                formGroupName="country">
                <label>Country</label>
                <select formControlName="iso2">
                    <option
                        *ngFor="let country of allCountries"
                        [ngValue]="country['alpha-2']">
                        {{ country.name }}
                    </option>
                </select>
                <span
                    *ngIf="educationFormGroup.get('country.iso2').errors && inputIsWrong"
                    class="form-error"
                >Country is required</span
                >
            </div>
        </section>

        <section>
            <p class="sectiontitle sectiontitle--no-bottom-margin">Period</p>

            <p class="form-helptext">End date is only required when person isn't studying there anymore.</p>

            <div class="row">
                <div class="col">
                    <div class="form-group m-b-2 required">
                        <label>Start Date</label>
                        <input
                            (focus)="hideErrorMessage()"
                            formControlName="startDate"
                            type="date" />
                        <span
                            *ngIf="!isValidDate && inputIsWrong"
                            class="form-error"
                        >Startdate must start before the enddate</span
                        >
                        <span
                            *ngIf="educationFormGroup.controls.startDate.errors && inputIsWrong"
                            class="form-error"
                        >Start date is required</span
                        >
                    </div>
                </div>
                <div class="col">
                    <div
                        [class.required]="!present"
                        class="form-group m-b-2">
                        <label>End Date</label>
                        <input
                            (focus)="hideErrorMessage()"
                            formControlName="endDate"
                            type="date" />
                        <span
                            *ngIf="!isValidDate && inputIsWrong"
                            class="form-error"
                        >Enddate must start after the startdate</span
                        >
                        <span
                            *ngIf="educationFormGroup.controls.endDate.errors && inputIsWrong"
                            class="form-error"
                        >End date is required</span
                        >
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <div class="form-group m-b-2 d-flex">
                        <input
                            (change)="onPresentTicked()"
                            class="m-r-2"
                            formControlName="present"
                            id="present"
                            type="checkbox" />
                        <label for="present">Still studying on this school.</label>
                    </div>
                </div>
            </div>
        </section>

        <section>
            <p class="sectiontitle">Other</p>

            <div class="form-group m-b-2">
                <label>Description</label>
                <textarea
                    (focus)="hideErrorMessage()"
                    (keyup)="onDescriptionKeyUp()"
                    class="resize-vertical"
                    formControlName="description"
                    trim="blur"></textarea>
                <span
                    *ngIf="
                    educationFormGroup.controls.description.errors &&
                    inputIsWrong &&
                    (educationFormGroup.controls.description.dirty || educationFormGroup.controls.description.touched)
                "
                    class="form-error"
                >
                    description required
                </span>
                <small
                ><span *ngIf="currentAmountOfDescriptionCharacters == null">0</span>{{ currentAmountOfDescriptionCharacters }}/{{
                    DESCRIPTION_MAX_LENGTH
                    }}</small
                >
                <span
                    *ngIf="descriptionIsTooLong"
                    class="form-error"
                >Description cannot contain more than 1000 characters</span
                >
            </div>
        </section>
    </main>

    <div class="modal-footer">
        <button
            (click)="activeModal.close()"
            class="button button--outline"
            type="button">
            Close
        </button>
        <button
            *ngIf="!isUpdate"
            [ngClass]="{ 'button--loading': loading }"
            class="button button--primary"
            type="submit">
            Add education
        </button>
        <button
            *ngIf="isUpdate"
            [ngClass]="{ 'button--loading': loading }"
            class="button button--primary"
            type="submit">
            Edit education
        </button>
    </div>
</form>
