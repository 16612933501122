<app-app-holder>
    <app-navbar></app-navbar>

    <app-sidebar>
        <app-settings-subnav></app-settings-subnav>
    </app-sidebar>

    <app-content>
        <div class="app__title">
            <h1>General settings</h1>
        </div>

        <div *ngIf="generalSettings$ | async; else loading">
            <div
                (click)="openUpdateDaysPerMonthModal()"
                class="list__item pointer">
                <div class="list__item__icon">
                    <i class="las la-calendar-day color--grey"></i>
                </div>
                <div class="list__item__content">
                    <strong>Change default days per month</strong>
                    <small>This will be used in financial calculations</small>
                </div>
            </div>

            <div
                (click)="openUpdateHolidayDaysModal()"
                class="list__item pointer">
                <div class="list__item__icon">
                    <i class="las la-business-time color--grey"></i>
                </div>
                <div class="list__item__content">
                    <strong>Change holiday days this for this year</strong>
                    <small>These days will be used in for example the planning</small>
                </div>
            </div>

            <div
                (click)="openLogbookCategoriesModal()"
                class="list__item pointer">
                <ng-container *ngIf="logCategories$ | async; else loading">
                    <div class="list__item__icon">
                        <i class="las la-book color--grey"></i>
                    </div>
                    <div class="list__item__content">
                        <strong>Change categories for the logbook</strong>
                    </div>
                </ng-container>
            </div>
        </div>

        <ng-template #loading>
            <app-loading-message></app-loading-message>
        </ng-template>
    </app-content>
</app-app-holder>
