import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { patch, removeItem } from '@ngxs/store/operators';
import { tap } from 'rxjs/operators';

import {
    AddRelation,
    CheckLoggedIn,
    DeleteRelation,
    FetchInvoices,
    FetchItems,
    FetchOrders,
    FetchPayments,
    FetchRelations,
    UpdateRelation,
} from './exact.actions';
import { ExactService } from './exact.service';

export interface ExactStateModel {
    relations: any[];
    items: any[];
    invoices: any[];
    payments: any[];
    orders: [];
    loggedIn: boolean;
}

@State<ExactStateModel>({
    name: 'exact',
    defaults: {
        relations: null,
        items: [],
        invoices: [],
        payments: [],
        orders: [],
        loggedIn: null,
    },
})
@Injectable()
export class ExactState {
    @Selector()
    static loggedIn(state: ExactStateModel): boolean {
        return state?.loggedIn;
    }

    @Selector()
    static relations(state: ExactStateModel): any[] {
        return state?.relations;
    }

    @Selector()
    static items(state: ExactStateModel): any[] {
        return state?.items;
    }

    @Selector()
    static invoices(state: ExactStateModel): any[] {
        return state?.invoices;
    }

    @Selector()
    static payments(state: ExactStateModel): any[] {
        return state?.payments;
    }

    @Selector()
    static orders(state: ExactStateModel): any[] {
        return state?.orders;
    }

    constructor(private exactService: ExactService) {}

    @Action(FetchRelations)
    fetchRelations(ctx: StateContext<ExactStateModel>) {
        return this.exactService.getAccounts().pipe(
            tap((relations) => {
                ctx.setState(
                    patch<ExactStateModel>({
                        relations: relations.d.results,
                    }),
                );
            }),
        );
    }

    @Action(CheckLoggedIn)
    checkLoggedIn(ctx: StateContext<ExactStateModel>) {
        return this.exactService.checkLoggedIn().pipe(
            tap((loggedIn) => {
                ctx.setState(
                    patch<ExactStateModel>({
                        loggedIn: loggedIn,
                    }),
                );
            }),
        );
    }

    @Action(FetchItems)
    fetchItems(ctx: StateContext<ExactStateModel>) {
        return this.exactService.getItems().pipe(
            tap((items) => {
                ctx.setState(
                    patch<ExactStateModel>({
                        items: items.d.results,
                    }),
                );
            }),
        );
    }

    @Action(FetchInvoices)
    fetchInvoices(ctx: StateContext<ExactStateModel>) {
        return this.exactService.getInvoices().pipe(
            tap((invoices) => {
                ctx.setState(
                    patch<ExactStateModel>({
                        invoices: invoices.d.results,
                    }),
                );
            }),
        );
    }

    @Action(FetchPayments)
    fetchPayments(ctx: StateContext<ExactStateModel>) {
        return this.exactService.getPayments().pipe(
            tap((payments) => {
                ctx.setState(
                    patch<ExactStateModel>({
                        payments: payments.d.results,
                    }),
                );
            }),
        );
    }

    @Action(FetchOrders)
    fetchOrders(ctx: StateContext<ExactStateModel>) {
        return this.exactService.getOrders().pipe(
            tap((orders) => {
                ctx.setState(
                    patch<ExactStateModel>({
                        orders: orders.d.results,
                    }),
                );
            }),
        );
    }

    @Action(AddRelation)
    addRelation(ctx: StateContext<ExactStateModel>, action: AddRelation) {
        return this.exactService.addAccount(action.relation).pipe(
            tap((relation) => {
                if (relation) {
                    this.fetchRelations(ctx).subscribe();
                }
            }),
        );
    }

    @Action(UpdateRelation)
    updateRelation(ctx: StateContext<ExactStateModel>, action: UpdateRelation) {
        return this.exactService.updateAccount(action.relation).pipe(
            tap((relation) => {
                if (relation) {
                    this.fetchRelations(ctx).subscribe();
                }
            }),
        );
    }

    @Action(DeleteRelation)
    deleteRelation(ctx: StateContext<ExactStateModel>, action: DeleteRelation) {
        return this.exactService.deleteAccount(action.id).pipe(
            tap((relation) => {
                if (relation) {
                    ctx.setState(
                        patch<ExactStateModel>({
                            relations: removeItem((r) => r.ID === action.id),
                        }),
                    );
                }
            }),
        );
    }
}
