import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import CrmDeal from 'src/app/models/CrmDeal';
import { Product } from 'src/app/models/Product';
import { FetchProducts } from 'src/app/products/product.actions';
import { ProductState } from 'src/app/products/product.state';

import { UpdateCrmDeal } from '../crm.actions';

@Component({
    selector: 'app-add-product-to-deal-modal',
    templateUrl: './add-product-to-deal-modal.component.html',
    styleUrls: ['./add-product-to-deal-modal.component.scss'],
})
export class AddProductToDealModalComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Select(ProductState.Products) products$: Observable<Product[]>;

    @Input() deal: CrmDeal;

    allProducts: Product[];
    availableProducts: Product[] = [];
    selectedProduct: Product;
    keyword = 'title';

    constructor(private toastr: ToastrService, private store: Store, public activeModal: NgbActiveModal, private logger: NGXLogger) {}

    ngOnInit(): void {
        this.logger.debug('Attempt fetch Products');
        this.subscriptions.add(
            this.store.dispatch(new FetchProducts()).subscribe(
                () => {
                    this.logger.debug('Succesfully fetched products');
                },
                (error) => this.logger.error('Failed to fetch products'),
            ),
        );
        this.subscriptions.add(
            this.products$.subscribe((p) => {
                this.allProducts = p;
                if (p != undefined && p != null) {
                    p.forEach((element) => {
                        const validateProduct = this.deal.products.find((e) => e.uuid === element.uuid);
                        if (validateProduct == null) {
                            this.availableProducts.push(element);
                        }
                    });
                }
            }),
        );
    }

    linkProduct() {
        const updatedDeal = JSON.parse(JSON.stringify(this.deal));
        const products = JSON.parse(JSON.stringify(this.deal.products));
        products.push(this.selectedProduct);
        updatedDeal.products = products;
        this.logger.debug('Attempt to add product to crm deal');
        this.subscriptions.add(
            this.store.dispatch(new UpdateCrmDeal(updatedDeal)).subscribe(
                (res) => {
                    this.logger.debug('Succesfully added product to crm deal');
                    this.toastr.success(this.selectedProduct.title + ' was successfully added.');
                    this.activeModal.close();
                },
                (err) => {
                    this.logger.error('Failed to add product to crm deal');
                    this.toastr.error('Oops, something went wrong. Please try again later.');
                },
            ),
        );
    }

    selectEvent(item) {
        this.selectedProduct = this.allProducts.find((p: Product) => p.uuid == item.uuid);
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
