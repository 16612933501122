<div *ngIf="dataLoaded && chartLoaded; else loading">
    <div *ngIf="checkValidInput(); else invalidInput">
        <canvas
            (chartClick)="click($event)"
            [data]="chartData"
            [options]="chartOptions"
            [type]="chartType"
            baseChart>
        </canvas>
    </div>
</div>

<ng-template #invalidInput>
    <p>Something went wrong!</p>
</ng-template>
<ng-template #loading>
    <app-loading-message></app-loading-message>
</ng-template>
