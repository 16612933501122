import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { append, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import DefaultProjectEmployeeLink from '../models/DefaultProjectEmployeeLink';
import { Employee } from '../models/Employee';
import Project from '../models/Project';
import { ROUTES } from '../models/ROUTES';
import { Status } from '../models/Status';
import { Timesheet } from '../models/Timesheet';
import { Resume } from '../models/resume/Resume';
import { CreateNotification } from '../notifications/notifications.actions';
import { AddUndefinedObjectTagsToObject } from '../shared/global-tags/global-tags.actions';
import {
    AddDefaultEmployees,
    AddProject,
    AddProjectEmployee,
    AddProjectProducts,
    AddProjectStatus,
    ArchiveProject,
    AssignResumeToProject,
    DeleteAssignedResume,
    DeleteDefaultEmployee,
    DeleteEmployeeFromProject,
    DeleteEmployeeFromProjectById,
    DeleteProductFromProject,
    DeleteProjectStatus,
    FetchProjectById,
    FetchProjects,
    FetchProjectsForOverview,
    FetchSimpleProjectList,
    GetAllPhases,
    GetProjectAssignedResumes,
    UpdateAssignmentStatus,
    UpdateFinishedOfProject,
    UpdateProject,
    UpdateProjectStatus,
} from './project.actions';
import { ProjectService } from './project.service';

export interface ProjectStateModel {
    projects: Project[];
    simpleProjectList: Project[];
    status: Status[];
    currentProject: Project;
    currentTimesheets: Timesheet[];
    project: Project;
}

@State<ProjectStateModel>({
    name: 'project',
    defaults: {
        projects: null,
        simpleProjectList: null,
        status: [],
        currentProject: null,
        currentTimesheets: null,
        project: null,
    },
})
@Injectable()
export class ProjectState {
    constructor(private projectService: ProjectService, private store: Store) {}

    @Selector()
    static projects(state: ProjectStateModel): Project[] {
        return state?.projects;
    }

    @Selector()
    static status(state: ProjectStateModel): Status[] {
        return state?.status;
    }

    @Selector()
    static timesheets(state: ProjectStateModel): Timesheet[] {
        return state?.currentTimesheets;
    }

    @Selector()
    static currentProject(state: ProjectStateModel): Project {
        return state?.currentProject;
    }

    @Selector()
    static project(state: ProjectStateModel): Project {
        return state?.project;
    }

    @Action(FetchProjects)
    fetchProject(ctx: StateContext<ProjectStateModel>): Observable<Project[]> {
        return this.projectService.getAllProject().pipe(
            tap((projects) => {
                const state = ctx.getState();
                ctx.setState({
                    ...state,
                    projects: [...projects],
                });
            }),
        );
    }

    @Action(FetchProjectsForOverview)
    fetchProjectsForOverview(ctx: StateContext<ProjectStateModel>): Observable<Project[]> {
        return this.projectService.getAllProjectsForOverview().pipe(
            tap((projects) => {
                ctx.setState(
                    patch<ProjectStateModel>({
                        projects: projects,
                    }),
                );
            }),
        );
    }

    @Action(FetchSimpleProjectList)
    fetchSimpleProjectList(ctx: StateContext<ProjectStateModel>): Observable<Project[]> {
        return this.projectService.getSimpleProjectList().pipe(
            tap((projects) => {
                ctx.setState(
                    patch<ProjectStateModel>({
                        simpleProjectList: projects,
                    }),
                );
            }),
        );
    }

    @Action(FetchProjectById)
    fetchProjectById(ctx: StateContext<ProjectStateModel>, action: FetchProjectById): Observable<Project> {
        return this.projectService.getProjectDetail(action.projectId).pipe(
            tap((project) => {
                ctx.setState(
                    patch<ProjectStateModel>({
                        project: project,
                    }),
                );
            }),
        );
    }

    @Action(AddProject)
    addProject(ctx: StateContext<ProjectStateModel>, action: AddProject): Observable<Project> {
        return this.projectService.addProject(action.project).pipe(
            tap((project) => {
                ctx.setState(
                    patch<ProjectStateModel>({
                        projects: append<Project>([project]),
                        currentProject: project,
                    }),
                );
                this.store.dispatch(new AddUndefinedObjectTagsToObject(project.uuid));
                this.store.dispatch(
                    new CreateNotification({
                        message: `A project <strong>${project.title}</strong> is added`,
                        route: ROUTES.PROJECTS.route,
                    }),
                );
            }),
        );
    }

    @Action(UpdateProject)
    updateProject(ctx: StateContext<ProjectStateModel>, action: UpdateProject) {
        return this.projectService.updateProject(action.projectUuid, action.project).pipe(
            tap((project) => {
                ctx.setState(
                    patch<ProjectStateModel>({
                        projects: updateItem<Project>((w) => w.uuid === project.uuid, project),
                        project: project
                    }),
                );
                this.store.dispatch(
                    new CreateNotification({
                        message: `A project <strong>${project.title}</strong> is updated`,
                        route: ROUTES.PROJECTS.route,
                    }),
                );
            }),
        );
    }

    @Action(ArchiveProject)
    archiveProject(ctx: StateContext<ProjectStateModel>, action: ArchiveProject) {
        return this.projectService.archiveProject(action.projectUuid).pipe(
            tap(() => {
                const project: Project = ctx.getState().projects.find((project) => project.uuid === action.projectUuid);
                ctx.setState(
                    patch<ProjectStateModel>({
                        projects: updateItem<Project>(
                            (project) => project.uuid === action.projectUuid,
                            patch<Project>({
                                archived: !project.archived,
                            }),
                        ),
                    }),
                );
                this.store.dispatch(
                    new CreateNotification({
                        message: `A project <strong>${project.title}</strong> is archived`,
                        route: ROUTES.PROJECTS.route,
                    }),
                );
            }),
        );
    }

    @Action(AssignResumeToProject)
    assignResumeToProject(ctx: StateContext<ProjectStateModel>, action: AssignResumeToProject) {
        return this.projectService.assignResumeToProject(action.projectUuid, action.selectedResumes).pipe(
            tap(() => {
                const project = JSON.parse(JSON.stringify(ctx.getState().projects.find((w) => w.uuid === action.projectUuid)));
                this.projectService.getAssignedResumeList(project.uuid).subscribe((assignedResumes) => {
                    project.assignedResumes = assignedResumes;
                    ctx.setState(
                        patch<ProjectStateModel>({
                            projects: updateItem<Project>((w) => w.uuid === action.projectUuid, project),
                        }),
                    );
                });
            }),
        );
    }

    @Action(GetProjectAssignedResumes)
    getProjects(ctx: StateContext<ProjectStateModel>, action: GetProjectAssignedResumes): Observable<Resume[]> {
        return this.projectService.getAssignedResumeList(action.projectUuid).pipe(
            tap((assignedResumeList) => {
                ctx.setState(
                    patch<ProjectStateModel>({
                        projects: updateItem<Project>(
                            (w) => w.uuid === action.projectUuid,
                            patch<Project>({
                                assignedResumes: assignedResumeList,
                            }),
                        ),
                    }),
                );
            }),
        );
    }

    @Action(DeleteAssignedResume)
    deleteProject(ctx: StateContext<ProjectStateModel>, action: DeleteAssignedResume): Observable<string> {
        return this.projectService.deleteAssignedResumeFromProject(action.projectAssignmentUuid).pipe(
            tap(() => {
                ctx.setState(
                    patch<ProjectStateModel>({
                        projects: updateItem<Project>(
                            (w) => w.assignedResumes.includes(w.assignedResumes.find((r) => r.uuid === action.projectAssignmentUuid)),
                            patch<Project>({
                                assignedResumes: removeItem<Resume>((r) => r.uuid === action.projectAssignmentUuid),
                            }),
                        ),
                    }),
                );
            }),
        );
    }

    @Action(GetAllPhases)
    getAllPhases(ctx: StateContext<ProjectStateModel>, action: GetAllPhases): Observable<Status[]> {
        return this.projectService.getAllPhases().pipe(
            tap((phases) => {
                ctx.setState(
                    patch<ProjectStateModel>({
                        status: phases,
                    }),
                );
            }),
        );
    }

    @Action(UpdateAssignmentStatus)
    updateAssignmentStatus(ctx: StateContext<ProjectStateModel>, action: UpdateAssignmentStatus) {
        return this.projectService.updatePhaseFromAssignedResume(action.projectAssignmentUuid, action.projectPhaseUuid);
    }

    @Action(AddProjectStatus)
    addProjectStatus(ctx: StateContext<ProjectStateModel>, action: AddProjectStatus): Observable<Status> {
        return this.projectService.addProjectStatus(action.companyUuid, action.status);
    }

    @Action(UpdateProjectStatus)
    updateProjectStatus(ctx: StateContext<ProjectStateModel>, action: UpdateProjectStatus): Observable<Status> {
        return this.projectService.updateProjectStatus(action.projectStatusUuid, action.status);
    }

    @Action(DeleteProjectStatus)
    deleteProjectStatus(ctx: StateContext<ProjectStateModel>, action: DeleteProjectStatus): Observable<string> {
        return this.projectService.deleteProjectStatus(action.projectStatusUuid);
    }

    @Action(UpdateFinishedOfProject)
    updateFinishedOfProject(ctx: StateContext<ProjectStateModel>, action: UpdateFinishedOfProject) {
        return this.projectService.updateFinishedOfProject(action.projectId).pipe(
            tap(() => {
                const project: Project = ctx.getState().projects.find((project) => project.uuid === action.projectId);
                const message: string = project.finished ? 'ongoing' : 'finished';
                ctx.setState(
                    patch<ProjectStateModel>({
                        projects: updateItem<Project>(
                            (project) => project.uuid === action.projectId,
                            patch<Project>({
                                finished: !project.finished,
                            }),
                        ),
                    }),
                );

                if (ctx.getState().project) {
                    patch<ProjectStateModel>({
                        project: patch<Project>({
                            finished: !project.finished,
                        }),
                    });
                }

                this.store.dispatch(
                    new CreateNotification({
                        message: `A project <strong>${project.title}</strong> is marked as <strong>${message}</strong>`,
                        route: ROUTES.PROJECTS.route,
                    }),
                );
            }),
        );
    }

    @Action(AddProjectProducts)
    addProducts(ctx: StateContext<ProjectStateModel>, action: AddProjectProducts) {
        return this.projectService.addProductToProject(action.projectUuid, action.products).pipe(
            tap(() => {
                const project = JSON.parse(JSON.stringify(ctx.getState().projects.find((p) => p.uuid === action.projectUuid)));
                this.projectService.getProjectRelatedProducts(project.uuid).subscribe((products) => {
                    project.products = products;
                    ctx.setState(
                        patch<ProjectStateModel>({
                            projects: updateItem<Project>((w) => w.uuid === action.projectUuid, project),
                        }),
                    );
                });
            }),
        );
    }

    @Action(DeleteProductFromProject)
    deleteProductFromProject(ctx: StateContext<ProjectStateModel>, action: DeleteProductFromProject): Observable<string> {
        return this.projectService.deleteProductFromProject(action.projectUuid, action.productUuid).pipe(
            tap(() => {
                const project = JSON.parse(JSON.stringify(ctx.getState().projects.find((w) => w.uuid === action.projectUuid)));
                project.products = project.products?.filter((p) => p.uuid !== action.productUuid);
                ctx.setState(
                    patch<ProjectStateModel>({
                        projects: updateItem<Project>((w) => w.uuid === action.projectUuid, project),
                    }),
                );
            }),
        );
    }

    @Action(AddProjectEmployee)
    addEmployee(ctx: StateContext<ProjectStateModel>, action: AddProjectEmployee) {
        return this.projectService.addEmployeeLink(action.projectUuid, action.employee.uuid, action.pricePerHour).pipe(
            tap((linkedEmployee) => {
                const project: Project = JSON.parse(JSON.stringify(ctx.getState().projects.find((w) => w.uuid === action.projectUuid)));
                project.linkedEmployees.push(linkedEmployee);
                ctx.setState(
                    patch<ProjectStateModel>({
                        projects: updateItem<Project>((w) => w.uuid === action.projectUuid, project),
                    }),
                );
                this.store.dispatch(
                    new CreateNotification({
                        message: `An employee with name <strong>${linkedEmployee.employee.firstName} ${linkedEmployee.employee.lastName}</strong> is linked to project <strong>${project.title}</strong>`,
                        route: ROUTES.PROJECTS.route,
                    }),
                );
            }),
        );
    }

    @Action(DeleteEmployeeFromProject)
    deleteEmployeeFromProject(ctx: StateContext<ProjectStateModel>, action: DeleteEmployeeFromProject) {
        return this.projectService.removeEmployeeLink(action.projectUuid, action.employeeUuid).pipe(
            tap(() => {
                const project: Project = JSON.parse(JSON.stringify(ctx.getState().projects.find((w) => w.uuid === action.projectUuid)));
                const employee: Employee = project.linkedEmployees.find((l) => l.employee.uuid === action.employeeUuid).employee;
                project.linkedEmployees = project.linkedEmployees.filter((l) => l.employee.uuid != action.employeeUuid);
                ctx.setState(
                    patch<ProjectStateModel>({
                        projects: updateItem<Project>((w) => w.uuid === action.projectUuid, project),
                    }),
                );
                this.store.dispatch(
                    new CreateNotification({
                        message: `An employee with name <strong>${employee.firstName} ${employee.lastName}</strong> is unlinked from project <strong>${project.title}</strong>`,
                        route: ROUTES.PROJECTS.route,
                    }),
                );
            }),
        );
    }

    @Action(DeleteEmployeeFromProjectById)
    deleteEmployeeFromProjectById(ctx: StateContext<ProjectStateModel>, action: DeleteEmployeeFromProjectById) {
        return this.projectService.removeEmployeeLinkById(action.linkId);
    }

    @Action(AddDefaultEmployees)
    addDefaultEmployees(ctx: StateContext<ProjectStateModel>, action: AddDefaultEmployees) {
        return this.projectService.addDefaultEmployees(action.defaultEmployees).pipe(
            tap((defaultLinkedEmployees) => {
                ctx.setState(
                    patch<ProjectStateModel>({
                        project: patch<Project>({
                            defaultLinkedEmployees: defaultLinkedEmployees,
                        }),
                        projects: updateItem<Project>(
                            (project) => project.uuid === action.defaultEmployees.projectId,
                            patch({
                                defaultLinkedEmployees: defaultLinkedEmployees,
                            }),
                        ),
                    }),
                );
            }),
        );
    }

    @Action(DeleteDefaultEmployee)
    deleteDefaultEmployee(ctx: StateContext<ProjectStateModel>, action: DeleteDefaultEmployee) {
        return this.projectService.deleteDefaultEmployee(action.uuid).pipe(
            tap(() => {
                ctx.setState(
                    patch<ProjectStateModel>({
                        project: patch({
                            defaultLinkedEmployees: removeItem<DefaultProjectEmployeeLink>((e) => e.uuid === action.uuid),
                        }),
                    }),
                );
            }),
        );
    }
}
