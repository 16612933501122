import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import * as moment from 'moment';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { Education } from 'src/app/models/resume/Education';
import { CountryService } from 'src/app/shared/country.service';

import { AddEducation, GetEducations, GetResume, UpdateEducation } from '../resume.actions';

@Component({
    selector: 'app-education-detail-modal',
    templateUrl: './education-detail-modal.component.html',
    styleUrls: ['./education-detail-modal.component.scss'],
})
export class EducationDetailModalComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Input() resumeUuid: string;
    @Input() education: Education;

    allCountries = [];

    newEducation: Education;
    educationFormGroup: FormGroup;
    isUpdate = false;
    loading = false;
    present = false;
    inputIsWrong = false;
    errorMessage: string;
    educationWentWrong = false;
    isValidDate: boolean;
    error: any = { isError: false, errorMessage: '' };
    DESCRIPTION_MAX_LENGTH = 1000;
    currentAmountOfDescriptionCharacters: number;
    descriptionIsTooLong = false;

    constructor(
        public activeModal: NgbActiveModal,
        private formBuilder: FormBuilder,
        private countryService: CountryService,
        private store: Store,
        private toastr: ToastrService,
        private logger: NGXLogger,
    ) {}

    ngOnInit(): void {
        if (this.education) {
            this.isUpdate = true;
            this.currentAmountOfDescriptionCharacters = this.education.description.length;
        }
        this.allCountries = this.countryService.getAllCountries();
        this.educationFormGroup = this.formBuilder.group({
            startDate: ['', [Validators.required]],
            endDate: [''],
            present: [false],
            institution: ['', [Validators.required]],
            studyField: ['', [Validators.required]],
            degree: ['', [Validators.required]],
            description: [''],
            country: this.formBuilder.group({
                iso2: ['', Validators.required],
            }),
        });
        if (this.isUpdate) {
            this.setFormValues();
        }
    }

    setFormValues() {
        if (this.education.present) {
            this.present = this.education.present;
            this.isValidDate = true;
            this.educationFormGroup.setValue({
                startDate: moment(this.education.startDate).format('YYYY-MM-DD'),
                endDate: moment(Date.now()).format('YYYY-MM-DD'),
                present: this.education.present,
                institution: this.education.institution,
                studyField: this.education.studyField,
                degree: this.education.degree,
                description: this.education.description,
                country: this.education.country,
            });
            this.educationFormGroup.controls.endDate.disable();
        } else {
            this.educationFormGroup.setValue({
                startDate: moment(this.education.startDate).format('YYYY-MM-DD'),
                endDate: moment(this.education.endDate).format('YYYY-MM-DD'),
                present: this.education.present,
                institution: this.education.institution,
                studyField: this.education.studyField,
                degree: this.education.degree,
                description: this.education.description,
                country: this.education.country,
            });
        }
    }

    onPresentTicked() {
        this.present = this.educationFormGroup.controls.present.value;
        if (this.present) {
            this.isValidDate = true;
            this.educationFormGroup.controls.endDate.disable();
        } else {
            this.isValidDate = false;
            this.educationFormGroup.controls.endDate.enable();
        }
    }

    onDescriptionKeyUp() {
        this.descriptionIsTooLong = false;
        this.currentAmountOfDescriptionCharacters = this.educationFormGroup.controls.description.value.length;
        if (this.currentAmountOfDescriptionCharacters > this.DESCRIPTION_MAX_LENGTH) {
            this.descriptionIsTooLong = true;
        }
    }

    saveEducation() {
        this.loading = true;

        if (!this.present) {
            this.isValidDate = this.validateDates(this.educationFormGroup.value.startDate, this.educationFormGroup.value.endDate);
        }

        if (this.descriptionIsTooLong) {
            this.loading = false;
            return;
        }

        if (!this.educationFormGroup.valid || !this.isValidDate) {
            this.inputIsWrong = true;
            this.loading = false;
            return;
        }

        this.educationFormGroup.disable();

        this.newEducation = this.educationFormGroup.value;

        if (!this.isUpdate) {
            this.logger.debug('Attempt to add education');
            this.subscriptions.add(
                this.store.dispatch(new AddEducation(this.resumeUuid, this.newEducation)).subscribe(
                    () => {
                        this.logger.debug('Succesfully added education');
                        this.toastr.success('Edcuation added succesfully');
                        this.logger.debug('Fetching educations');
                        this.store.dispatch(new GetEducations(this.resumeUuid)).subscribe(() => {
                            this.logger.debug('Fetching resume');
                            this.store.dispatch(new GetResume(this.resumeUuid));
                        });
                        this.activeModal.close();
                    },
                    (error) => {
                        this.errorMessage = error?.error?.message ?? 'Error message was empty';
                        this.educationWentWrong = true;
                        this.loading = false;
                        this.educationFormGroup.enable();
                    },
                ),
            );
        } else {
            this.logger.debug('Attempt to update education');
            this.subscriptions.add(
                this.store.dispatch(new UpdateEducation(this.education.uuid, this.newEducation)).subscribe(
                    () => {
                        this.logger.debug('Succesfully updated education');
                        this.logger.debug('Fetching educations');
                        this.store.dispatch(new GetEducations(this.resumeUuid)).subscribe(() => {
                            this.logger.debug('Fetching resume');
                            this.store.dispatch(new GetResume(this.resumeUuid));
                        });
                        this.activeModal.close();
                    },
                    (error) => {
                        this.logger.error('Failed to update education');
                        this.errorMessage = error?.error?.message ?? 'Error message was empty';
                        this.educationWentWrong = true;
                        this.loading = false;
                        this.educationFormGroup.enable();
                    },
                ),
            );
        }
    }

    // Hides error messages
    hideErrorMessage() {
        this.inputIsWrong = false;
    }

    validateDates(sDate: Date, eDate: Date) {
        this.isValidDate = true;
        if (sDate == null || eDate == null) {
            this.error = {
                isError: true,
                errorMessage: 'Start date and end date are required.',
            };
            this.isValidDate = false;
        }

        if (sDate != null && eDate != null && eDate < sDate) {
            this.error = {
                isError: true,
                errorMessage: 'End date should be greater then start date.',
            };
            this.isValidDate = false;
        }

        return this.isValidDate;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
