<div class="modal-header">
    <h2
        class="modal-title"
        id="exampleModalLabel">
        Disable two-factor authentication
    </h2>
    <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <div class="row">
        <div class="col">
            <p>Are you sure you want to remove your two-factor authentication?</p>
            <p>Please enter your password:</p>
        </div>
    </div>

    <div class="divider divider--small"></div>

    <form
        #disableMFAForm="ngForm"
        (ngSubmit)="disable2FA()">
        <div class="row">
            <div class="col">
                <password-input
                    [(ngModel)]="password"
                    name="password">
                </password-input>
                <small
                    class="form-error"
                    *ngIf="authenticationError"
                    >{{ authenticationError }}</small
                >
            </div>
        </div>
    </form>
</div>

<div class="modal-footer">
    <button
        class="button button--danger"
        (click)="disable2FA()">
        Yes
    </button>
    <button
        type="button"
        class="button button--outline"
        (click)="activeModal.close()">
        Cancel
    </button>
</div>
