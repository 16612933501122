import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MbscModule } from '@mobiscroll/angular';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsModule } from '@ngxs/store';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { SocialLoginModule } from 'angularx-social-login';
import { MatTableExporterModule } from 'mat-table-exporter';
import { PdfViewerModule } from 'ng2-pdf-viewer';
// google login
import { GoogleApiModule, NG_GAPI_CONFIG, NgGapiClientConfig } from 'ng-gapi';
import { TagInputModule } from 'ngx-chips';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { NgPipesModule } from 'ngx-pipes';
import { ToastrModule } from 'ngx-toastr';

import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
// import components
import { AppComponent } from './app.component';
import { ApplicantState } from './applicants/applicant.state';
import { ApplicantsModule } from './applicants/applicants.module';
// import created modules
import { AuthenticationModule } from './authentication/authentication.module';
import { BudgetModule } from './budget/budget.module';
import { BudgetState } from './budget/budget.state';
import { CalendarModule } from './calendar/calendar.module';
import { ComanageModule } from './comanage/comanage.module';
import { ComanageState } from './comanage/comanage.state';
import { CompaniesModule } from './companies/companies.module';
import { CompanyState } from './companies/company.state';
import { ContactsModule } from './contacts/contacts.module';
import { ContactState } from './contacts/contacts.state';
import { CrmModule } from './crm/crm.module';
import { CrmState } from './crm/crm.state';
import { CustomersModule } from './customers/customer.module';
import { CustomerState } from './customers/customers.state';
import { DashboardModule } from './dashboard/dashboard.module';
import { DocumentsModule } from './documents/documents.module';
import { DocumentState } from './documents/documents.state';
import { EmployeesModule } from './employees/employees.module';
import { EmployeeState } from './employees/employees.state';
import { ExactModule } from './exact/exact.module';
import { ExactState } from './exact/exact.state';
import { FinancialModule } from './financial/financial.module';
import { FinancialState } from './financial/financial.state';
import { interceptorProviders } from './interceptors';
import { JiraModule } from './jira/jira.module';
import { LeadState } from './leads/lead.state';
import { LeadsModule } from './leads/leads.module';
import { LogbookModule } from './logbook/logbook.module';
import { LogbookState } from './logbook/logbook.state';
import { MailchimpModule } from './mailchimp/mailchimp.module';
import { MailchimpState } from './mailchimp/mailchimp.state';
import { ModuleState } from './modules/module.state';
import { NotificationsModule } from './notifications/notifications.module';
import { NotificationsState } from './notifications/notifications.state';
import { PartnerDetailsModule } from './partners/partnerdetails.module';
import { PartnersModule } from './partners/partners.module';
import { PartnerState } from './partners/partners.state';
import { PlanningModule } from './planning/planning.module';
import { PlanningState } from './planning/planning.state';
import { ProductStockUpdateComponent } from './products/product-stock-update/product-stock-update.component';
import { ProductState } from './products/product.state';
import { ProductsModule } from './products/products.module';
import { ProjectState } from './projects/project.state';
import { ProjectsModule } from './projects/projects.module';
import { RelatedEmployeesState } from './related-employees/related.employees.state';
import { ResumeState } from './resumes/resume.state';
import { ResumesModule } from './resumes/resumes.module';
import { CustomRolesState } from './settings/roles/custom.roles.state';
import { SettingsModule } from './settings/settings.module';
import { SettingsState } from './settings/settings.state';
import { CommentState } from './shared/comments/comments.state';
import { GlobalFilesState } from './shared/global-files/global-files.state';
import { GlobalTagsState } from './shared/global-tags/global-tags.state';
import { MinutesToMinutesAndHoursPipe } from './shared/pipes/minutes-to-minutes-and-hours.pipe';
import { SharedModule } from './shared/shared.module';
import { TeamleaderModule } from './teamleader/teamleader.module';
import { TeamleaderState } from './teamleader/teamleader.state';
import { ThermostarConfiguratorModule } from './thermostar-configurator/thermostar.configurator.module';
import { TimesheetsModule } from './timesheets/timesheets.module';
import { TimesheetState } from './timesheets/timesheets.state';
import { UnitState } from './units/unit.state';
import { UnitsModule } from './units/units.module';
// import ngrx components
import { UserState } from './users/user.state';
import { UsersModule } from './users/users.module';
import { VeldaArticlesModule } from './velda-articles/velda-articles.module';
import { VeldaArticlesState } from './velda-articles/velda-articles.state';
import { VeldaLanguagesModule } from './velda-languages/velda-languages.module';
import { VeldaLanguagesState } from './velda-languages/velda-languages.state';
import { WarehouseLocationState } from './warehouse-locations/warehouse.location.state';
import { WarehouseLocationsModule } from './warehouse-locations/warehouse.locations.module';
import { WarehouseState } from './warehouses/warehouse.state';
import { WarehousesModule } from './warehouses/warehouses.module';
import { YukiModule } from './yuki/yuki.module';
import { YukiState } from './yuki/yuki.state';

const gapiClientConfig: NgGapiClientConfig = {
    client_id: '283754219711-fri0fs3bgknn0isuhgdijote6ktlbo3p.apps.googleusercontent.com',
    discoveryDocs: ['https://analyticsreporting.googleapis.com/$discovery/rest?version=v4'],
    scope: ['https://www.googleapis.com/auth/calendar', 'https://mail.google.com/'].join(' '),
};

@NgModule({
    declarations: [AppComponent, ProductStockUpdateComponent],
    imports: [
        MbscModule,
        CommonModule,
        PdfViewerModule,
        AppRoutingModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        JiraModule,
        ApplicantsModule,
        ProjectsModule,
        LeadsModule,
        DashboardModule,
        YukiModule,
        CrmModule,
        ThermostarConfiguratorModule,
        NgxGoogleAnalyticsModule.forRoot('G-2SN6LEL057'),
        NgxGoogleAnalyticsRouterModule,
        DragDropModule,
        AutocompleteLibModule,
        ProductsModule,
        WarehousesModule,
        WarehouseLocationsModule,
        UnitsModule,
        AuthenticationModule,
        CompaniesModule,
        ResumesModule,
        PartnersModule,
        PartnerDetailsModule,
        SettingsModule,
        SharedModule,
        UsersModule,
        NgbModule,
        SocialLoginModule,
        TagInputModule,
        CalendarModule,
        ReactiveFormsModule,
        MailchimpModule,
        NgPipesModule,
        EmployeesModule,
        TeamleaderModule,
        ExactModule,
        DocumentsModule,
        TimesheetsModule,
        ContactsModule,
        ComanageModule,
        VeldaLanguagesModule,
        VeldaArticlesModule,
        CustomersModule,
        MatTreeModule,
        MatTooltipModule,
        MatIconModule,
        PlanningModule,
        MatTableExporterModule,
        FinancialModule,
        NotificationsModule,
        LogbookModule,
        ToastrModule.forRoot({
            positionClass: 'toast-bottom-left',
        }),
        NgxsModule.forRoot(
            [
                UserState,
                MailchimpState,
                ContactState,
                CompanyState,
                CustomRolesState,
                ResumeState,
                PartnerState,
                ApplicantState,
                ProjectState,
                LeadState,
                ProductState,
                WarehouseState,
                WarehouseLocationState,
                UnitState,
                ModuleState,
                ExactState,
                EmployeeState,
                TeamleaderState,
                DocumentState,
                VeldaLanguagesState,
                VeldaArticlesState,
                TimesheetState,
                CrmState,
                CommentState,
                ComanageState,
                GlobalTagsState,
                CustomerState,
                RelatedEmployeesState,
                BudgetState,
                YukiState,
                PlanningState,
                GlobalFilesState,
                SettingsState,
                NotificationsState,
                FinancialState,
                LogbookState,
            ],
            { developmentMode: !environment.production },
        ),
        NgxsReduxDevtoolsPluginModule.forRoot({
            name: 'Resume App',
            maxAge: 10,
        }),
        GoogleApiModule.forRoot({
            provide: NG_GAPI_CONFIG,
            useValue: gapiClientConfig,
        }),
        LoggerModule.forRoot({
            serverLoggingUrl: `${environment.baseUrl}/logs`,
            level: NgxLoggerLevel.DEBUG,
            serverLogLevel: NgxLoggerLevel.ERROR,
            enableSourceMaps: true,
        }),
    ],
    providers: [Title, MinutesToMinutesAndHoursPipe, ...interceptorProviders],
    bootstrap: [AppComponent],
})
export class AppModule {}
