import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { append, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { tap } from 'rxjs/operators';

import MailingObject from '../models/MailingObject';
import { Module } from '../models/Module';
import { UpdateModulesOnSetupChange } from '../settings/roles/custom.roles.actions';
import { ActivateModule, AddMailToModule, FetchMailingObjects, FetchModules, RemoveMailFromModule } from './module.actions';
import { ModuleMaintenanceService } from './module.maintenance.service';

export interface MailingObjectsPerModule {
    module: string;
    mailingObjects: MailingObject[];
}

export interface ModuleStateModel {
    modules: Module[];
    mailingObjectsPerModule: MailingObjectsPerModule[];
}

@State<ModuleStateModel>({
    name: 'module',
    defaults: {
        modules: null,
        mailingObjectsPerModule: null,
    },
})
@Injectable()
export class ModuleState {
    @Selector()
    static Modules(state: ModuleStateModel): Module[] {
        return state?.modules;
    }

    @Selector()
    static MailingObjectsPerModule(state: ModuleStateModel): MailingObjectsPerModule[] {
        return state?.mailingObjectsPerModule;
    }

    constructor(private moduleService: ModuleMaintenanceService, private store: Store) {}

    @Action(FetchModules)
    fetchModules(ctx: StateContext<ModuleStateModel>) {
        return this.moduleService.getAllModules().pipe(
            tap((moduleList) => {
                const list: Module[] = moduleList.map((module) => {
                    const mod = module;
                    const info = this.information(mod.key);
                    mod.title = info.title;
                    mod.image = info.image;
                    mod.description = info.description;
                    return mod;
                });

                const state = ctx.getState();
                ctx.setState({
                    ...state,
                    modules: [...list],
                });
            }),
        );
    }

    @Action(FetchMailingObjects)
    fetchMailingObjects(ctx: StateContext<ModuleStateModel>) {
        if (ctx.getState().modules != null) {
            ctx.getState().modules.forEach((module) => {
                this.moduleService.getMailingObjectsByModule(module.key).subscribe((obj) => {
                    ctx.setState(
                        patch<ModuleStateModel>({
                            mailingObjectsPerModule: append([{ module: module.key, mailingObjects: obj }]),
                        }),
                    );
                });
            });
        }
    }

    @Action(AddMailToModule)
    addMailToModule(ctx: StateContext<ModuleStateModel>, action: AddMailToModule) {
        const module = ctx.getState().modules.find((m) => m.uuid == action.moduleUuid);
        return this.moduleService.addMailToModule(action.moduleUuid, action.mailingObject).pipe(
            tap((mailingObject) => {
                ctx.setState(
                    patch<ModuleStateModel>({
                        mailingObjectsPerModule: updateItem<MailingObjectsPerModule>(
                            (m) => m.module == module.key,
                            patch<MailingObjectsPerModule>({
                                mailingObjects: append([mailingObject]),
                            }),
                        ),
                    }),
                );
            }),
        );
    }

    @Action(RemoveMailFromModule)
    removeMailFromModule(ctx: StateContext<ModuleStateModel>, action: RemoveMailFromModule) {
        const module = ctx.getState().modules.find((m) => m.uuid == action.moduleUuid);
        return this.moduleService.removeMailFromModule(action.mailingObjectUuid).pipe(
            tap((result) => {
                ctx.setState(
                    patch<ModuleStateModel>({
                        mailingObjectsPerModule: updateItem<MailingObjectsPerModule>(
                            (m) => m.module == module.key,
                            patch<MailingObjectsPerModule>({
                                mailingObjects: removeItem<MailingObject>((mailingObject) => mailingObject.uuid == action.mailingObjectUuid),
                            }),
                        ),
                    }),
                );
            }),
        );
    }

    @Action(ActivateModule)
    activateModule(ctx: StateContext<ModuleStateModel>, action: ActivateModule) {
        return this.moduleService.activateModule(action.companyUuid, action.moduleUuid).pipe(
            tap((module) => {
                this.store.dispatch(new UpdateModulesOnSetupChange(module));
            }),
        );
    }

    information(key: string) {
        const infoDefault = {
            title: key,
            description: 'No description available...',
            icon: 'las la-wave-square',
            color: 'blue',
        };

        const info = {
            RESUME: {
                title: 'CV management',
                description: 'Manage resumés of your employees or partners',
            },
            DOCUMENTS: {
                title: 'Documents',
                description: 'Write documents and link them to other modules',
            },
            PROJECTS: {
                title: 'Project management',
                description: 'Manage all your projects',
            },
            PARTNERS: {
                title: 'Companies (Partners)',
                description: 'Manage your business partners, suppliers, ...',
            },
            DASHBOARD: {
                title: 'Dashboard',
                description: 'An overview of modulewidgets',
            },
            SETTINGS: {
                title: 'Settings',
                description: 'Manage the settings of your Resumo environment',
            },
            APPLICANTS: {
                title: 'Applicants',
                description: 'Manage applicatants for your vacancies',
            },
            CRAFT_APPLICANTS: {
                title: 'Craft Applicants',
                description: 'Manage vacancies from your Craft Website',
            },
            INVENTORY: {
                title: 'Inventory',
                description: 'Warehouse and inventory management',
            },
            LEADS: {
                title: 'Leads',
                description: 'Manage all your leads in Resumo',
            },
            THERMOSTAR_CONFIGURATOR_PREVIEW: {
                title: 'Thermostar - Configuration preview',
                description: 'Preview a pool configuration',
            },
            THERMOSTAR_CONFIGURATOR: {
                title: 'Thermostar - Configuration',
                description: 'Configure a thermostar pool',
            },
            CONTACTS: {
                title: 'Contact management',
                description: 'Manage all your contacts',
            },
            CUSTOMERS: {
                title: 'Customer management',
                description: 'Manage all your customers',
            },
            EMPLOYEES: {
                title: 'Employee management',
                description: 'Manage all your employees, birthdays, work anniversaries, ...',
            },
            TIMESHEETS: {
                title: 'Timesheets management',
                description: 'Manage all your employees timesheets',
            },
            OUTLOOK: {
                title: 'Outlook',
                description: 'Connect Resumo with your Outlook account',
                image: '../../../assets/img/logo-outlook.png',
            },
            GOOGLE: {
                title: 'Google',
                description: 'Connect Resumo with your Google account',
                image: '../../../assets/img/logo-google.png',
            },
            MAILCHIMP: {
                title: 'Mailchimp',
                description: 'Connect Resumo with Mailchimp',
                image: '../../../assets/img/logo-mailchimp.png',
            },
            TEAMLEADER: {
                title: 'Teamleader',
                description: 'Connect Resumo with Teamleader',
                image: '../../../assets/img/teamleader.png',
            },
            EXACT: {
                title: 'Exact',
                description: 'Connect Resumo with Exact',
                image: '../../../assets/img/logo-exact.png',
            },
            COMANAGE: {
                title: 'CoManage',
                description: 'Connect Resumo with CoManage',
                image: '../../../assets/img/logo_comanage_small.svg',
            },
            COMMENTS: {
                title: 'Comments',
                description: 'Enable commenting in resumo',
                image: '../../../assets/img/comanage.jpg',
            },
            VELDA_LANGUAGES: {
                title: 'Languages',
                description: 'Manage languages',
                image: '../../../assets/img/velda.jpg',
            },
            VELDA_ARTICLES: {
                title: 'Articles',
                description: 'Connect resumo with the velda catalogue',
                image: '../../../assets/img/velda.jpg',
            },
            YUKI: {
                title: 'Yuki',
                description: 'Connect resumo with yuki',
                image: '../../../assets/img/yuki.png',
            },
            BUDGET: {
                title: 'Budget',
                description: 'Manage budgets',
            },
            FINANCIAL: {
                title: 'Financial',
                description: 'Financial reporting based on information retrieved from other modules',
            },
            JIRA: {
                title: 'Jira/Tempo',
                description: 'Connect resumo with Jira & Tempo',
                image: '../../../assets/img/jiraLogo.png',
            },
        };

        return info[key] ? info[key] : infoDefault;
    }
}
