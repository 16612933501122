import { Component, Input, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import FileSystemEntity from 'src/app/models/FileSystemEntity';
import { ConfirmationModalComponent } from 'src/app/shared/confirmation-modal/confirmation-modal.component';

import { DeleteFileSystemEntity } from '../documents.actions';

@Component({
    selector: 'app-documents-doc',
    templateUrl: './documents-doc.component.html',
    styleUrls: ['./documents-doc.component.scss'],
})
export class DocumentsDocComponent implements OnDestroy {
    private subscriptions = new Subscription();

    @Input() document: FileSystemEntity;

    constructor(private router: Router, private store: Store, private toastr: ToastrService, private modalService: NgbModal, private logger: NGXLogger) {}

    open() {
        this.logger.debug('Navigating to documentDetails');
        this.router.navigate(['/documents', 'detail', this.document.uuid]);
    }

    delete($event) {
        $event.stopPropagation();
        this.logger.debug('Opening confirmationModal - deleteDocument');
        const modalRef = this.modalService.open(ConfirmationModalComponent, {
            windowClass: 'modal-prompt',
        });
        modalRef.componentInstance.type = 'Delete';
        modalRef.componentInstance.title = 'Delete document';
        modalRef.componentInstance.message = 'Are you sure you want to delete ' + this.document.name + '?';
        this.subscriptions.add(
            modalRef.componentInstance.closeEvent.subscribe((val) => {
                if (val) {
                    this.logger.debug('Attempt to delete document');
                    this.store.dispatch(new DeleteFileSystemEntity(this.document)).subscribe(
                        () => {
                            this.logger.debug('Succesfully deleted document');
                            this.toastr.success('File deleted successfully.');
                        },
                        () => {
                            this.logger.debug('Failed to delete document');
                            this.toastr.error('Oops, something went wrong.\nPlease try again later.');
                        },
                    );
                }
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
