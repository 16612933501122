import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { Timesheet } from 'src/app/models/Timesheet';

import { UpdateRequiredHours } from '../timesheets.actions';

@Component({
    selector: 'app-edit-timesheet-required-hours',
    templateUrl: './edit-timesheet-required-hours.component.html',
    styleUrls: ['./edit-timesheet-required-hours.component.scss'],
})
export class EditTimesheetRequiredHoursComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Input() timesheet: Timesheet;
    isLoading = true;
    hoursRequired: number;

    constructor(private store: Store, public activeModal: NgbActiveModal, private toastr: ToastrService, private logger: NGXLogger) {}

    ngOnInit(): void {
        this.hoursRequired = this.timesheet.hoursRequired ? this.timesheet.hoursRequired : 0;
        this.isLoading = false;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    editTimesheet() {
        this.logger.debug('Attempt to update a timesheet');
        this.subscriptions.add(
            this.store.dispatch(new UpdateRequiredHours(this.timesheet.uuid, this.hoursRequired)).subscribe((res) => {
                this.logger.debug('Succesfully added hoursRequired');
                this.toastr.success('Successfully updated required hours.');
                this.activeModal.close();
            }),
        );
    }
}
