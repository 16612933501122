import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { AuthenticationService } from '../authentication/authentication.service';

@Injectable({
    providedIn: 'root',
})
export class ComanageService {
    private httpOptions = {};

    constructor(private httpClient: HttpClient, private authenticationService: AuthenticationService) {
        this.httpOptions = authenticationService.getHeaders();
    }

    getKeyExists(): Observable<any> {
        return this.httpClient.get<any>(`${environment.baseUrl}/comanage/keyExists`, this.httpOptions);
    }

    getContacts(): any {
        return this.httpClient.get<any>(`${environment.baseUrl}/comanage/contacts`, this.httpOptions);
    }

    getCustomers(): any {
        return this.httpClient.get<any>(`${environment.baseUrl}/comanage/customers`, this.httpOptions);
    }

    getInvoices(): any {
        return this.httpClient.get<any>(`${environment.baseUrl}/comanage/invoices`, this.httpOptions);
    }

    getOffers(): any {
        return this.httpClient.get<any>(`${environment.baseUrl}/comanage/offers`, this.httpOptions);
    }

    getPdf(url: string): any {
        let headers = new HttpHeaders();
        headers = headers.set('Accept', 'application/pdf');
        return this.httpClient.get(url, {
            headers: headers,
            responseType: 'blob',
        });
    }
}
