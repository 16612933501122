import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { ContactPerson } from 'src/app/models/ContactPerson';
import { ModuleEnum } from 'src/app/models/ModuleEnum';
import { Partner } from 'src/app/models/Partner';
import { PartnerService } from 'src/app/partners/partner.service';
import { ModuleService } from 'src/app/services/module.service';

import { AddContactPerson, UpdateContactPerson } from '../contacts.actions';

@Component({
    selector: 'app-contacts-add-modal',
    templateUrl: './contacts-add-modal.component.html',
    styleUrls: ['./contacts-add-modal.component.scss'],
})
export class ContactsAddModalComponent implements OnInit, OnDestroy {
    public emitObject: EventEmitter<ContactPerson> = new EventEmitter();
    public editor = ClassicEditor;
    @Input() contactPerson: ContactPerson;
    @Input() inOverview: boolean;
    @Input() linkContactModal: boolean;
    @Input() prefilledPartner: Partner;
    @Output() fullNameFilter: EventEmitter<string> = new EventEmitter<string>();
    eModule = ModuleEnum;
    partnerUuid: string;
    public allPartners: Partner[];
    public newContactPerson: ContactPerson;
    public contactPersonFormGroup: FormGroup;
    public errorMessage: string;
    public connectionError: string;
    public isUpdate = false;
    public loading = false;
    public inputIsWrong = false;
    public contactPersonWentWrong = false;
    public error: any = { isError: false, errorMessage: '' };
    private subscriptions = new Subscription();

    constructor(
        public activeModal: NgbActiveModal,
        private formBuilder: FormBuilder,
        private store: Store,
        private toastr: ToastrService,
        private partnerService: PartnerService,
        private logger: NGXLogger,
        public moduleService: ModuleService,
    ) {}

    @ViewChild('focussed', { static: false })
    set input(element: ElementRef<HTMLInputElement>) {
        if (element) {
            element.nativeElement.focus();
        }
    }

    ngOnInit(): void {
        this.subscriptions.add(this.partnerService.getAllPartners().subscribe((partners) => (this.allPartners = partners)));
        if (this.contactPerson?.uuid) this.isUpdate = true;
        this.contactPersonFormGroup = this.formBuilder.group({
            firstName: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(30)]],
            lastName: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(30)]],
            company: [this.prefilledPartner ? this.prefilledPartner : ''],
            extraInfo: [''],
            profession: [''],
            contactInformation: this.formBuilder.group({
                phoneNumber: [''],
                email: ['', [Validators.email, Validators.required]],
                website: [''],
                linkedIn: [''],
            }),
        });
        if (this.contactPerson) {
            this.setFormValues();
        }
    }

    setFormValues() {
        let company;
        if (this.allPartners && this.contactPerson.partnerUuid) company = this.allPartners.find((p) => p.uuid == this.contactPerson.partnerUuid);
        this.contactPersonFormGroup.setValue({
            firstName: this.contactPerson.firstName,
            lastName: this.contactPerson.lastName,
            company: company ? company : '',
            extraInfo: this.contactPerson.extraInfo ? this.contactPerson.extraInfo : '',
            profession: this.contactPerson.profession ? this.contactPerson.profession : '',
            contactInformation:
                this.contactPerson.contactInformation !== null
                    ? {
                          phoneNumber: this.contactPerson.contactInformation.phoneNumber ? this.contactPerson.contactInformation.phoneNumber : '',
                          email: this.contactPerson.contactInformation.email ? this.contactPerson.contactInformation.email : '',
                          website: this.contactPerson.contactInformation.website ? this.contactPerson.contactInformation.website : '',
                          linkedIn: this.contactPerson.contactInformation.linkedIn ? this.contactPerson.contactInformation.linkedIn : '',
                      }
                    : {
                          phoneNumber: '',
                          email: '',
                          website: '',
                          linkedIn: '',
                      },
        });
    }

    saveContactPerson() {
        this.loading = true;

        if (!this.contactPersonFormGroup.valid) {
            this.inputIsWrong = true;
            this.loading = false;
            return;
        }

        this.contactPersonFormGroup.disable();

        this.newContactPerson = this.contactPersonFormGroup.value;
        this.newContactPerson.partnerUuid = this.contactPersonFormGroup.get('company').value.uuid;
        this.newContactPerson.contactInformation.email = this.newContactPerson.contactInformation.email.toLowerCase();
        if (!this.isUpdate) {
            this.logger.debug('Attemt to add contact to company');
            this.subscriptions.add(
                this.store.dispatch(new AddContactPerson(this.newContactPerson)).subscribe({
                    next: (state) => {
                        this.emitObject.emit(state.contact.contacts.find((c) => c.contactInformation.email == this.newContactPerson.contactInformation.email));
                        if (this.linkContactModal) {
                            this.fullNameFilter.emit(`${this.newContactPerson.firstName} ${this.newContactPerson.lastName}`);
                        }
                        this.logger.debug('SuccesFully added contact to company');
                        this.toastr.success('Contactperson added successfully');
                        this.activeModal.close();
                    },
                    error: (error) => {
                        this.logger.error('Failed to added contact to company');
                        this.contactPersonWentWrong = true;
                        this.errorMessage = error?.error?.message ?? 'Error message was empty';

                        this.loading = false;
                        this.contactPersonFormGroup.enable();

                        if (!this.errorMessage) {
                            this.connectionError = 'No connection to the server';
                        }
                    },
                }),
            );
        } else {
            this.logger.debug('Attempt to update contactPerson');
            this.subscriptions.add(
                this.store.dispatch(new UpdateContactPerson(this.newContactPerson)).subscribe(
                    () => {
                        this.emitObject.emit(this.newContactPerson);
                        this.logger.debug('SuccesFully updated contactPerson');
                        this.toastr.success('Contactperson updated successfully');
                        this.activeModal.close();
                    },
                    (error) => {
                        this.logger.error('Failed to updated contactPerson');
                        this.contactPersonWentWrong = true;
                        this.errorMessage = error?.error?.message ?? 'Error message was empty';
                        this.loading = false;
                        this.contactPersonFormGroup.enable();
                        if (!this.errorMessage) {
                            this.connectionError = 'No connection to the server';
                        }
                    },
                ),
            );
        }
    }

    hideErrorMessage() {
        this.inputIsWrong = false;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
