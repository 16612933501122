import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { ContactPerson } from 'src/app/models/ContactPerson';
import { ModuleEnum } from 'src/app/models/ModuleEnum';
import { Partner } from 'src/app/models/Partner';
import { FetchPartners, GetPartner } from 'src/app/partners/partners.actions';
import { PartnerState } from 'src/app/partners/partners.state';
import { ModuleService } from 'src/app/services/module.service';

import { AddContactPerson, FetchContacts, UpdateContactPerson } from '../contacts.actions';
import { ContactState } from '../contacts.state';

@Component({
    selector: 'app-contactperson-detail-modal',
    templateUrl: './contactperson-detail-modal.component.html',
    styleUrls: ['./contactperson-detail-modal.component.scss'],
})
export class ContactpersonDetailModalComponent implements OnInit, OnDestroy {
    public editor = ClassicEditor;
    @Select(PartnerState.getPartners) partners$: Observable<Partner[]>;
    @Select(ContactState.Contacts) contacts$: Observable<ContactPerson[]>;
    @Input() partnerUuid: string;
    @Input() contactPersonUuid: string;
    @Input() inOverview: boolean;
    contactPerson: ContactPerson;
    allContacts: ContactPerson[];
    allPartners: Partner[];
    newContactPerson: ContactPerson;
    contactPersonFormGroup: FormGroup;
    errorMessage: string;
    connectionError: string;
    isUpdate = false;
    loading = false;
    error: any = { isError: false, errorMessage: '' };
    contactPersonWentWrong: boolean;
    partnerKeyword = 'title';
    isLoaded = false;
    currentPartner: Partner;
    eModule = ModuleEnum;
    private subscriptions = new Subscription();
    private NAME_MIN_LENGTH = 2;
    private NAME_MAX_LENGTH = 30;

    constructor(
        public activeModal: NgbActiveModal,
        private formBuilder: FormBuilder,
        private store: Store,
        private toastr: ToastrService,
        private logger: NGXLogger,
        public moduleService: ModuleService,
    ) {}

    @ViewChild('focussed', { static: false })
    set input(element: ElementRef<HTMLInputElement>) {
        if (element) {
            element.nativeElement.focus();
        }
    }

    get firstName() {
        return this.contactPersonFormGroup.get('firstName');
    }

    get lastName() {
        return this.contactPersonFormGroup.get('lastName');
    }

    get extraInfo() {
        return this.contactPersonFormGroup.get('extraInfo');
    }

    get phoneNumber() {
        return this.contactPersonFormGroup.get('phoneNumber');
    }

    get email() {
        return this.contactPersonFormGroup.get('contactInformation').get('email');
    }

    get website() {
        return this.contactPersonFormGroup.get('contactInformation').get('website');
    }

    get linkedIn() {
        return this.contactPersonFormGroup.get('contactInformation').get('linkedIn');
    }

    ngOnInit(): void {
        this.subscriptions.add(
            this.contacts$.subscribe((contacts) => {
                this.contactPerson = contacts.find((c) => c.uuid == this.contactPersonUuid);
                this.allContacts = contacts;
            }),
        );

        this.logger.debug('Attempt to fetch Partners');
        this.store.dispatch(new FetchPartners());
        this.subscriptions.add(
            this.partners$.subscribe(
                (partners) => {
                    this.logger.debug('SuccesFully fetched partners');
                    this.allPartners = partners;
                    this.isLoaded = true;
                    if (this.partnerUuid) {
                        this.currentPartner = partners.find((p) => p.uuid == this.partnerUuid);
                    }
                },
                (error) => this.logger.error('Failed to fetch partners'),
            ),
        );
        if (this.contactPerson) {
            this.isUpdate = true;
        }

        if (this.partnerUuid) {
            this.subscriptions.add(
                this.store.dispatch(new GetPartner(this.partnerUuid)).subscribe((state) => {
                    this.currentPartner = state.partner.partners.find((partner) => partner.uuid == this.partnerUuid);
                }),
            );
        }

        this.contactPersonFormGroup = this.formBuilder.group({
            firstName: ['', [Validators.maxLength(this.NAME_MAX_LENGTH), Validators.minLength(this.NAME_MIN_LENGTH), Validators.required]],
            lastName: ['', [Validators.maxLength(this.NAME_MAX_LENGTH), Validators.minLength(this.NAME_MIN_LENGTH), Validators.required]],
            company: [this.currentPartner ? this.currentPartner : ''],
            extraInfo: [''],
            profession: [''],
            contactInformation: this.formBuilder.group({
                phoneNumber: [''],
                email: ['', [Validators.email]],
                website: [''],
                linkedIn: [''],
            }),
        });
        if (this.isUpdate) {
            this.setFormValues();
        }
    }

    setFormValues() {
        this.subscriptions.add(
            this.partners$.subscribe((partners) => {
                this.isLoaded = true;
                if (this.contactPerson) {
                    this.currentPartner = partners.find((p) => p.uuid == this.contactPerson.partnerUuid);
                }
            }),
        );

        this.contactPersonFormGroup.setValue({
            firstName: this.contactPerson.firstName,
            lastName: this.contactPerson.lastName,
            company: this.currentPartner ? this.currentPartner : '',
            extraInfo: this.contactPerson.extraInfo ? this.contactPerson.extraInfo : '',
            profession: this.contactPerson.profession ? this.contactPerson.profession : '',
            contactInformation:
                this.contactPerson.contactInformation !== null
                    ? {
                          phoneNumber: this.contactPerson.contactInformation.phoneNumber ? this.contactPerson.contactInformation.phoneNumber : '',
                          email: this.contactPerson.contactInformation.email ? this.contactPerson.contactInformation.email : '',
                          website: this.contactPerson.contactInformation.website ? this.contactPerson.contactInformation.website : '',
                          linkedIn: this.contactPerson.contactInformation.linkedIn ? this.contactPerson.contactInformation.linkedIn : '',
                      }
                    : {
                          phoneNumber: '',
                          email: '',
                          website: '',
                          linkedIn: '',
                      },
        });
    }

    saveContactPerson() {
        this.loading = true;

        if (!this.contactPersonFormGroup.valid) {
            this.loading = false;
            return;
        }

        this.contactPersonFormGroup.disable();

        this.newContactPerson = this.contactPersonFormGroup.value;
        this.newContactPerson.partnerUuid = this.contactPersonFormGroup.value.company.uuid;
        this.newContactPerson.contactInformation.email = this.newContactPerson.contactInformation.email.toLowerCase();

        if (!this.isUpdate) {
            this.logger.debug('Attempt to add contactperson to partner');
            this.subscriptions.add(
                this.store.dispatch(new AddContactPerson(this.newContactPerson)).subscribe(
                    (state) => {
                        this.logger.debug('SuccesFully added contactPerson to partner');
                        this.toastr.success('Contactperson added successfully');
                        this.store.dispatch(new FetchContacts());
                        this.activeModal.close();
                    },
                    (error) => {
                        this.logger.error('Failed to add contactPerson to partner');
                        this.errorMessage = error?.error?.message ?? 'Error message was empty';
                        if (this.errorMessage != 'Error message was empty') {
                            this.errorMessage = this.errorMessage.split('"')[2];
                            this.toastr.error(this.errorMessage);
                        } else if (this.allContacts.find((c) => c.contactInformation.email == this.newContactPerson.contactInformation.email)) {
                            this.toastr.error('This emailaddress is already in use');
                        } else if (this.allContacts.find((c) => c.contactInformation.phoneNumber == this.newContactPerson.contactInformation.phoneNumber)) {
                            this.toastr.error('This phone number is already in use');
                        } else if (/\d/.test(this.newContactPerson.firstName + this.newContactPerson.lastName)) {
                            this.toastr.error('Your name cannot contain numbers');
                        }

                        this.loading = false;
                        this.contactPersonFormGroup.enable();

                        if (!this.errorMessage) {
                            this.connectionError = 'No connection to the server';
                        }
                    },
                ),
            );
        } else {
            this.newContactPerson.uuid = this.contactPerson.uuid;
            this.logger.debug('Attempt to update contactPerson');
            this.newContactPerson.contactInformation.uuid = this.contactPerson.contactInformation.uuid;
            this.subscriptions.add(
                this.store.dispatch(new UpdateContactPerson(this.newContactPerson)).subscribe(
                    () => {
                        this.logger.debug('Contactperson updated successfully');
                        this.toastr.success('Contactperson updated successfully');
                        this.activeModal.close();
                    },
                    (error) => {
                        this.logger.debug('Failed to update contactPerson');
                        this.errorMessage = error?.error?.message ?? 'Error message was empty';
                        this.loading = false;
                        this.contactPersonFormGroup.enable();
                        if (!this.errorMessage) {
                            this.connectionError = 'No connection to the server';
                        }
                        this.contactPersonWentWrong = true;
                    },
                ),
            );
        }
        this.logger.debug('Attempt to fetch Partners');
        this.store.dispatch(new FetchPartners());
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
