import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { append, patch } from '@ngxs/store/operators';
import { tap } from 'rxjs/operators';

import { TeamleaderContact } from '../models/TeamleaderContact';
import { TeamleaderDepartment } from '../models/TeamleaderDepartment';
import {
    AddCalendarEvent,
    AddDeal,
    FetchActivityTypes,
    FetchCalendarEvents,
    FetchCompanies,
    FetchContacts,
    FetchDealPhases,
    FetchDeals,
    FetchDepartments,
    FetchEmployees,
} from './teamleader.actions';
import { TeamleaderService } from './teamleader.service';

export interface TeamleaderStateModel {
    calendarEvents: any[];
    activityTypes: any[];
    departments: TeamleaderDepartment[];
    employees: any[];
    contacts: TeamleaderContact[];
    deals: any[];
    dealPhases: any[];
    companies: any[];
}

@State<TeamleaderStateModel>({
    name: 'teamleader',
    defaults: {
        calendarEvents: [],
        activityTypes: [],
        departments: [],
        employees: [],
        contacts: [],
        deals: [],
        dealPhases: [],
        companies: [],
    },
})
@Injectable()
export class TeamleaderState {
    @Selector()
    static calendarEvents(state: TeamleaderStateModel): any[] {
        return state?.calendarEvents;
    }

    @Selector()
    static activityTypes(state: TeamleaderStateModel): any[] {
        return state?.activityTypes;
    }

    @Selector()
    static departments(state: TeamleaderStateModel): any[] {
        return state?.departments;
    }

    @Selector()
    static employees(state: TeamleaderStateModel): any[] {
        return state?.employees;
    }

    @Selector()
    static contacts(state: TeamleaderStateModel): any[] {
        return state?.contacts;
    }

    @Selector()
    static deals(state: TeamleaderStateModel): any[] {
        return state?.deals;
    }

    @Selector()
    static dealPhases(state: TeamleaderStateModel): any[] {
        return state?.dealPhases;
    }

    @Selector()
    static companies(state: TeamleaderStateModel): any[] {
        return state?.companies;
    }

    constructor(private teamleaderService: TeamleaderService) {}

    @Action(FetchCalendarEvents)
    fetchCalendarEvents(ctx: StateContext<TeamleaderStateModel>) {
        return this.teamleaderService.getCalendarEvents().pipe(
            tap((calendarEvents) => {
                ctx.setState(
                    patch<TeamleaderStateModel>({
                        calendarEvents: calendarEvents.data,
                    }),
                );
            }),
        );
    }

    @Action(FetchActivityTypes)
    fetchActivityTypes(ctx: StateContext<TeamleaderStateModel>) {
        return this.teamleaderService.getActivityTypes().pipe(
            tap((activityTypes) => {
                ctx.setState(
                    patch<TeamleaderStateModel>({
                        activityTypes: activityTypes.data,
                    }),
                );
            }),
        );
    }

    @Action(FetchDepartments)
    fetchDepartments(ctx: StateContext<TeamleaderStateModel>) {
        return this.teamleaderService.getDepartments().pipe(
            tap((departments) => {
                ctx.setState(
                    patch<TeamleaderStateModel>({
                        departments: departments.data,
                    }),
                );
            }),
        );
    }

    @Action(FetchEmployees)
    fetchEmployees(ctx: StateContext<TeamleaderStateModel>) {
        return this.teamleaderService.getUsers().pipe(
            tap((employees) => {
                ctx.setState(
                    patch<TeamleaderStateModel>({
                        employees: employees.data,
                    }),
                );
            }),
        );
    }

    @Action(FetchContacts)
    fetchContacts(ctx: StateContext<TeamleaderStateModel>) {
        return this.teamleaderService.getContacts().pipe(
            tap((contacts) => {
                ctx.setState(
                    patch<TeamleaderStateModel>({
                        contacts: contacts.data,
                    }),
                );
            }),
        );
    }

    @Action(FetchDeals)
    fetchDeals(ctx: StateContext<TeamleaderStateModel>) {
        return this.teamleaderService.getDeals().pipe(
            tap((deals) => {
                ctx.setState(
                    patch<TeamleaderStateModel>({
                        deals: deals.data,
                    }),
                );
            }),
        );
    }

    @Action(FetchDealPhases)
    fetchDealPhases(ctx: StateContext<TeamleaderStateModel>) {
        return this.teamleaderService.getDealPhases().pipe(
            tap((dealPhases) => {
                ctx.setState(
                    patch<TeamleaderStateModel>({
                        dealPhases: dealPhases.data,
                    }),
                );
            }),
        );
    }

    @Action(FetchCompanies)
    fetchCompanies(ctx: StateContext<TeamleaderStateModel>) {
        return this.teamleaderService.getCompanies().pipe(
            tap((companies) => {
                ctx.setState(
                    patch<TeamleaderStateModel>({
                        companies: companies.data,
                    }),
                );
            }),
        );
    }

    @Action(AddCalendarEvent)
    addCalendarEvent(ctx: StateContext<TeamleaderStateModel>, action: AddCalendarEvent) {
        if (action.eventType === 'meeting') {
            return this.teamleaderService.addCalendarMeeting(action.calendarEvent).pipe(
                tap((calendarEvent: any) => {
                    ctx.setState(
                        patch<TeamleaderStateModel>({
                            calendarEvents: append([calendarEvent.data]),
                        }),
                    );
                }),
            );
        } else if (action.eventType === 'call') {
            return this.teamleaderService.addCalendarCall(action.calendarEvent).pipe(
                tap((calendarEvent: any) => {
                    ctx.setState(
                        patch<TeamleaderStateModel>({
                            calendarEvents: append([calendarEvent.data]),
                        }),
                    );
                }),
            );
        } else if (action.eventType === 'task') {
            return this.teamleaderService.addCalendarTask(action.calendarEvent).pipe(
                tap((calendarEvent: any) => {
                    ctx.setState(
                        patch<TeamleaderStateModel>({
                            calendarEvents: append([calendarEvent.data]),
                        }),
                    );
                }),
            );
        }
    }

    @Action(AddDeal)
    addDeal(ctx: StateContext<TeamleaderStateModel>, action: AddDeal) {
        return this.teamleaderService.addDeal(action.deal).pipe(
            tap((deal: any) => {
                ctx.setState(
                    patch<TeamleaderStateModel>({
                        deals: append([deal.data]),
                    }),
                );
            }),
        );
    }
}
