<div class="login">
    <div class="login__holder">
        <app-session-header [header]="hasSubmitted ? 'Welcome on board!' : 'Create an account'"></app-session-header>

        <div *ngIf="hasSubmitted">
            <p class="m-b-8">
                We've send an email to <strong>{{ useremail }}</strong> to confirm your account. Please confirm to continue!
            </p>
            <a
                routerLink="/login"
                class="button button--primary w-100"
                >Login</a
            >
        </div>

        <div *ngIf="!hasSubmitted">
            <form
                [formGroup]="registerFormGroup"
                (ngSubmit)="register()">
                <div class="m-b-2 d-flex space-between">
                    <div class="form-group m-r-1">
                        <label>First name</label>
                        <input
                            type="text"
                            placeholder="First name"
                            formControlName="firstName"
                            name="firstName"
                            autocomplete="off"
                            trim="blur" />
                        <span
                            class="form-error"
                            *ngIf="registerFormGroup.controls.firstName.touched && registerFormGroup.controls.firstName.errors">
                            Invalid first name
                        </span>
                    </div>

                    <div class="form-group m-l-1">
                        <label>Last name</label>
                        <input
                            type="text"
                            placeholder="Last name"
                            formControlName="lastName"
                            name="lastName"
                            autocomplete="off"
                            trim="blur" />
                        <span
                            class="form-error"
                            *ngIf="registerFormGroup.controls.lastName.touched && registerFormGroup.controls.lastName.errors">
                            Invalid last name
                        </span>
                    </div>
                </div>

                <div class="form-group m-b-2">
                    <label>Emailaddress</label>
                    <input
                        type="email"
                        placeholder="Emailaddress"
                        formControlName="email"
                        name="email"
                        autocomplete="off"
                        trim="blur" />
                    <span
                        class="form-error"
                        *ngIf="registerFormGroup.controls.email.errors && registerFormGroup.controls.email.touched">
                        This email is invalid
                    </span>
                </div>

                <div class="form-group m-b-4">
                    <password-input
                        (focus)="hideErrorMessage()"
                        (keyup)="onPasswordChange()"
                        [placeholder]="'Choose a password'"
                        [showLabel]="true"
                        formControlName="password">
                    </password-input>
                    <password-strength-meter
                        [colors]="['#E24600', 'orangered', 'orange', 'yellowgreen', '#26B305']"
                        [password]="password">
                    </password-strength-meter>
                </div>

                <div class="m-b-4">
                    <button
                        [ngClass]="{ 'button--loading': loading }"
                        class="button button--primary w-100">
                        Create account
                    </button>
                </div>

                <div *ngIf="registerWentWrong">
                    <span
                        class="form-error"
                        *ngIf="this.errorMessage"
                        >{{ errorMessage }}</span
                    >
                    <span
                        class="form-error"
                        *ngIf="this.connectionError"
                        >{{ connectionError }}</span
                    >
                </div>
            </form>

            <div class="divider"></div>

            <app-google-login></app-google-login>

            <p class="text-center">Already registered? <a routerLink="/login">Login</a></p>
        </div>
    </div>
</div>
