<app-app-holder>
    <app-navbar></app-navbar>

    <app-leads-sidebar (channelFilterChangedEvent)="changeChannelFilter($event)"></app-leads-sidebar>

    <app-content>
        <span
            *appIsPermitted="{
                module: 'LEADS',
                operator: 'AND',
                actions: ['READ']
            }">
            <div *ngIf="allLeads$ | async as allLeads; else loading">
                <div *ngIf="archivedLeads$ | async as archivedLeads; else loading">
                    <app-leads-list [channel]="channelFilter"></app-leads-list>
                </div>
            </div>
        </span>
    </app-content>

    <ng-template #loading>
        <app-loading-message></app-loading-message>
    </ng-template>

    <ng-template #empty>
        <app-message-empty
            [title]="'No leads...'"
            [subtitle]="'Start generating leads!'"
            [icon]="'../../assets/img/open-box.png'">
        </app-message-empty>
    </ng-template>
</app-app-holder>
