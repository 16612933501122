import { Component, Input, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { addMonths, endOfYear, format, isSameMonth, startOfYear } from 'date-fns';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { Employee } from 'src/app/models/Employee';
import EmployeeWithBudgets from 'src/app/models/EmployeeWithBudgets';
import { ModuleEnum } from 'src/app/models/ModuleEnum';
import Project from 'src/app/models/Project';
import { Timesheet } from 'src/app/models/Timesheet';
import { ModuleService } from 'src/app/services/module.service';
import { FetchTimesheetsForEmployee } from 'src/app/timesheets/timesheets.actions';
import { TimesheetState } from 'src/app/timesheets/timesheets.state';

import { AddBudgetModalComponent } from '../../budget/add-budget-modal/add-budget-modal.component';
import { ArchiveBudget } from '../../budget/budget.actions';
import Budget from '../../models/Budget';
import LinkedEmployee from '../../models/LinkedEmployee';
import { LinkedEmployeeViewModel } from '../../models/LinkedEmployeeViewModel';
import { StockUpdateHistoryModel } from '../../models/StockUpdateHistoryModel';
import { ConfirmationModalComponent } from '../../shared/confirmation-modal/confirmation-modal.component';
import { GetAllFilesLinkedToObject } from '../../shared/global-files/global-files.actions';
import { LinkedInvoicesToObjectComponent } from '../../shared/global-files/linked-invoices-to-object/linked-invoices-to-object.component';
import { isPermitted } from '../../shared/utils/authorization-utils';
import { EmployeesArchiveModalComponent } from '../employees-archive-modal/employees-archive-modal.component';
import { EmployeesDeleteModalComponent } from '../employees-delete-modal/employees-delete-modal.component';
import { EmployeesDetailModalComponent } from '../employees-detail-modal/employees-detail-modal.component';
import {
    FetchBudgetsForEmployee,
    FetchLinkedEmployeesForEmployee,
    FetchProjectListByCurrentEmployee,
    FetchStockUpdateHistoryForEmployee,
    GetEmployeeById,
} from '../employees.actions';
import { EmployeeService } from '../employees.service';
import { EmployeeState } from '../employees.state';

@Component({
    selector: 'app-employees-detail',
    templateUrl: './employees-detail.component.html',
    styleUrls: ['./employees-detail.component.scss'],
})
export class EmployeesDetailComponent implements OnInit, OnDestroy {
    @Select(EmployeeState.BudgetsForEmployee) budgets$: Observable<Budget[]>;
    @Select(EmployeeState.StockUpdateHistoryForEmployee) stockUpdateForEmployee$: Observable<StockUpdateHistoryModel[]>;
    @Select(EmployeeState.CurrentEmployeeProjects) currentEmployeeProjects$: Observable<Project[]>;
    @Select(TimesheetState.timesheets) timesheets$: Observable<Timesheet[]>;
    timesheets: Timesheet[];
    @Select(EmployeeState.CurrentEmployee) currentEmployee$: Observable<Employee>;
    @Select(EmployeeState.LinkedObjectsToEmployee) linkedProducts$: Observable<LinkedEmployeeViewModel[]>;
    linkedProducts: LinkedEmployeeViewModel[];
    @Input() employeeUuid: string;
    @Input() openFinancialTab = false;
    selectedDate: Date;
    isLoading = true;
    timesheetsDataSource: MatTableDataSource<Timesheet> = new MatTableDataSource<Timesheet>();
    employee: Employee;
    eModule = ModuleEnum;
    budgets: Budget[] = [];
    linked: boolean = null;
    currentEmployeeWorksDataSource: MatTableDataSource<Project> = new MatTableDataSource<Project>();
    @ViewChild(MatSort, { static: false }) sort: MatSort;
    employeeStockUpdates: MatTableDataSource<StockUpdateHistoryModel>;
    linkedEmployeeProducts: LinkedEmployee[];
    currentLinkedProductsUuid: string[] = [];
    displayedColumnsTimesheet: string[] = ['timesheet', 'timeslot', 'totalHours'];
    displayedColumns: string[] = ['updatedProductTitle', 'reasonInfo', 'dateAmTimeAgo', 'date', 'stockChange'];
    displayedColumnsForProjects: string[] = ['title', 'description'];
    startDateFilter: string = format(startOfYear(new Date()), 'yyyy-MM-dd');
    endDateFilter: string = format(endOfYear(new Date()), 'yyyy-MM-dd');
    @ViewChildren(LinkedInvoicesToObjectComponent) linkedInvoicesToObjectComponents: QueryList<LinkedInvoicesToObjectComponent>;
    budgetsLoaded = false;
    calculateActualCostForEmployee = EmployeeWithBudgets.calculateActualCostForEmployee;
    calculateBudgetCostForEmployee = EmployeeWithBudgets.calculateBudgetCostForEmployee;

    activeNavTab = 'information';

    private subscriptions = new Subscription();

    constructor(
        public activeModal: NgbActiveModal,
        private modalService: NgbModal,
        private store: Store,
        public moduleService: ModuleService,
        private logger: NGXLogger,
        private employeeService: EmployeeService,
        private toastr: ToastrService,
        private router: Router,
    ) {}

    ngOnInit(): void {
        if (this.openFinancialTab) this.activeNavTab = 'financial';

        this.logger.debug('Fetching all files linked to object');
        this.store.dispatch(new GetAllFilesLinkedToObject(this.employeeUuid));

        this.selectedDate = addMonths(new Date(), -1);

        this.logger.debug('Fetching employee by id');
        this.store.dispatch(new GetEmployeeById(this.employeeUuid));

        this.subscriptions.add(
            this.currentEmployee$.subscribe((employee) => {
                this.employee = employee;
                this.logger.debug('Fetching project for employee');
                if (employee) {
                    this.subscriptions.add(
                        this.employeeService.checkIfUserIsLinked(employee.uuid).subscribe((res) => {
                            this.linked = res;
                        }),
                    );
                    this.isLoading = false;
                }
            }),
        );

        this.store.dispatch(new FetchProjectListByCurrentEmployee(this.employeeUuid));
        this.subscriptions.add(
            this.currentEmployeeProjects$.subscribe((projects) => {
                this.currentEmployeeWorksDataSource = new MatTableDataSource<Project>(projects);
            }),
        );

        this.store.dispatch(new FetchStockUpdateHistoryForEmployee(this.employeeUuid));
        this.subscriptions.add(
            this.stockUpdateForEmployee$.subscribe((stockHistoryUpdate) => {
                if (stockHistoryUpdate) {
                    this.employeeStockUpdates = new MatTableDataSource(stockHistoryUpdate);
                    this.employeeStockUpdates.sort = this.sort;
                    this.employeeStockUpdates.filterPredicate = (data, filter) => {
                        return data.employeeUuid === this.employeeUuid;
                    };
                    this.employeeStockUpdates.filter = JSON.stringify({
                        employeeUuid: this.employeeUuid,
                    });
                }
            }),
        );

        // TODO: create enum for types
        this.store.dispatch(new FetchLinkedEmployeesForEmployee(this.employeeUuid, 'product'));
        this.subscriptions.add(
            this.linkedProducts$.subscribe((linkedProducts) => {
                if (!linkedProducts) return;

                this.linkedProducts = linkedProducts;
            }),
        );
        if (isPermitted({ module: 'BUDGET', operator: 'AND', actions: ['READ'] })) {
            this.logger.debug('Fetching budgets for employee');
            this.store.dispatch(new FetchBudgetsForEmployee(this.employeeUuid));
        }

        this.subscriptions.add(
            this.budgets$.subscribe((budgets) => {
                this.budgets = budgets;
            }),
        );

        this.logger.debug('Fetching timesheets for employee');
        this.store.dispatch(new FetchTimesheetsForEmployee(this.employeeUuid));
        this.subscriptions.add(
            this.timesheets$.subscribe((timesheets) => {
                this.timesheets = timesheets;
                this.timesheetsDataSource.data = timesheets;

                this.timesheetsDataSource.filterPredicate = (data, filter) => {
                    const employeeTimesheetFilter: EmployeeTimesheetFilter = JSON.parse(filter);
                    return (
                        isSameMonth(new Date(data.expirationDate), new Date(employeeTimesheetFilter.date)) &&
                        employeeTimesheetFilter.employeeId === data.employee.uuid
                    );
                };
                this.filterTimesheetsForEmployee();
            }),
        );
    }

    openEmployeeUpdateModal(employee: Employee) {
        this.logger.debug('Opening employeesDetailModal - update');
        const modalRef = this.modalService.open(EmployeesDetailModalComponent);
        modalRef.componentInstance.employeeUuid = employee.uuid;
        modalRef.componentInstance.inOverview = false;
    }

    openEmployeeDeleteModal(employeeUuid: string) {
        this.logger.debug('Opening employeesDeleteModal');
        const modalRef = this.modalService.open(EmployeesDeleteModalComponent, {
            windowClass: 'modal-prompt',
        });
        modalRef.componentInstance.employee = this.employee;
        this.subscriptions.add(modalRef.componentInstance.closeEvent.subscribe(() => this.activeModal.dismiss()));
    }

    openEmployeeArchiveModal(employee: Employee) {
        this.logger.debug('Opening employeesArchiveModal');
        const modalRef = this.modalService.open(EmployeesArchiveModalComponent, { windowClass: 'modal-prompt' });
        modalRef.componentInstance.employee = employee;
        this.subscriptions.add(modalRef.componentInstance.closeEvent.subscribe(() => this.activeModal.dismiss()));
    }

    openEmployeeDetail() {
        this.logger.debug('Opening employeesDetail');
        const modalRef = this.modalService.open(EmployeesDetailComponent, {
            windowClass: 'modal-prompt',
        });
        modalRef.componentInstance.employee = this.employee;
        modalRef.componentInstance.inOverview = false;
    }

    updateCurrentLinkedProducts() {
        this.currentLinkedProductsUuid = [];
        this.linkedEmployeeProducts.forEach((product) => {
            product.employees.forEach((employee) => {
                if (employee.uuid === this.employee.uuid) {
                    this.currentLinkedProductsUuid.push(product.uuid);
                }
            });
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    deleteBudget(budget: Budget) {
        this.logger.debug('Opening confirmationModal');
        const modalRef = this.modalService.open(ConfirmationModalComponent, {
            windowClass: 'modal-prompt',
            animation: false,
        });
        if (budget.archived) {
            modalRef.componentInstance.type = 'YesOrNo';
            modalRef.componentInstance.title = 'Unarchive budget';
            modalRef.componentInstance.htmlMessage = `<p>Are you sure you want to unarchive <strong>${budget.budgetType}</strong>?</p>`;
        } else {
            modalRef.componentInstance.type = 'Archive';
            modalRef.componentInstance.title = 'Archive budget';
            modalRef.componentInstance.htmlMessage = `<p>Are you sure you want to archive <strong>${budget.budgetType}</strong>?</p>`;
        }
        this.subscriptions.add(
            modalRef.componentInstance.closeEvent.subscribe((value) => {
                if (value) {
                    this.logger.debug('Attempt to (un)archive Budget');
                    this.store.dispatch(new ArchiveBudget(budget.uuid)).subscribe(
                        (res) => {
                            this.logger.debug('Successfully (un)archived budget');
                            if (budget.archived) {
                                this.toastr.success('Budget successfully unarchived.');
                            } else {
                                this.toastr.success('Budget successfully archived.');
                            }
                        },
                        (err) => {
                            this.logger.debug('Failed to (un)archive budget');
                            this.toastr.error('Oops, something went wrong, Please try again later...');
                        },
                    );
                }
            }),
        );
    }

    openUpdateBudgetModal(budget) {
        const modalRef = this.modalService.open(AddBudgetModalComponent, {
            windowClass: 'modal-pane',
            animation: false,
        });
        modalRef.componentInstance.budget = budget;
        modalRef.componentInstance.type = 'Update budget';
        modalRef.componentInstance.linkedEmployee = this.employee;
    }

    openAddBudgetModal() {
        this.logger.debug('Opening addBudgetModal');
        const modalRef = this.modalService.open(AddBudgetModalComponent, {
            windowClass: 'modal-pane',
            animation: false,
        });
        modalRef.componentInstance.type = 'Assign budget';
        modalRef.componentInstance.linkedEmployee = this.employee;
    }

    sortData(sort: Sort) {
        this.employeeStockUpdates.sort = this.sort;
    }

    getTotalMinutes(): number {
        let totalMinutes = 0;
        this.timesheetsDataSource.filteredData.forEach((ts) => {
            totalMinutes += this.getTotalMinutesForTimesheet(ts);
        });
        return totalMinutes;
    }

    moveDate(amount: number) {
        if (amount === 0) {
            this.selectedDate = addMonths(new Date(), -1);
        } else {
            this.selectedDate = addMonths(this.selectedDate, amount);
        }
        this.filterTimesheetsForEmployee();
    }

    calculateFirstDayOfGivenMonth(startDate: Date): Date {
        return new Date(startDate.getFullYear(), startDate.getMonth(), 1);
    }

    filterTimesheetsForEmployee() {
        const filter: EmployeeTimesheetFilter = {
            employeeId: this.employeeUuid,
            date: this.selectedDate.toString(),
        };
        this.timesheetsDataSource.filter = JSON.stringify(filter);
    }

    getTotalMinutesForTimesheet(timesheet: Timesheet): number {
        let totalMinutes = 0;
        timesheet.timeslots.forEach((el) => {
            totalMinutes += el.minutes;
        });
        return totalMinutes;
    }

    getRequiredHoursDiff(timesheet: Timesheet) {
        return Math.round(Math.abs(Timesheet.getTotalMinutes(timesheet) / 60 - timesheet.hoursRequired) * 60);
    }

    getRequiredHoursDiffPercentage(timesheet: Timesheet) {
        return Math.round((Timesheet.getTotalMinutes(timesheet) / 60 / timesheet.hoursRequired) * 100);
    }

    navigateToTimesheets(): void {
        this.logger.debug('Navigating to timesheets');
        this.activeModal.dismiss();
        this.router.navigate(['/timesheets']);
    }

    createHtmlSubtitleForNoTimesheets(): string {
        return `No timesheets for <strong>${this.employee.firstName} ${this.employee.lastName}</strong>`;
    }

    filterDate() {
        this.linkedInvoicesToObjectComponents.forEach((linkedInvoicesToObjectComponent) =>
            linkedInvoicesToObjectComponent.filterOnDate(new Date(this.startDateFilter), new Date(this.endDateFilter)),
        );
    }
}

export interface EmployeeTimesheetFilter {
    date: string;
    employeeId: string;
}
