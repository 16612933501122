import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { CompanyState } from 'src/app/companies/company.state';
import { CompanyMember } from 'src/app/models/CompanyMember';
import { UserDetailComponent } from 'src/app/users/user-detail/user-detail.component';

import { GetCompanyMemberById } from '../../../companies/company.actions';

@Component({
    selector: 'app-pill-user',
    templateUrl: './pill-user.component.html',
    styleUrls: ['./pill-user.component.scss'],
})
export class PillUserComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();
    @Select(CompanyState.companyMembers) companyMembers$: Observable<CompanyMember[]>;
    @Input() userId: string;
    isLoaded = false;
    selectedUser: CompanyMember;

    constructor(private modalService: NgbModal, private store: Store) {}

    ngOnInit(): void {
        this.subscriptions.add(
            this.companyMembers$.subscribe((members) => {
                if (!members || !members.find((m) => m.userUuid == this.userId)) {
                    this.store.dispatch(new GetCompanyMemberById(this.userId));
                    return;
                }

                this.selectedUser = members.find((m) => m.userUuid == this.userId);
                this.isLoaded = true;
            }),
        );
    }

    openUserDetail() {
        const modalRef = this.modalService.open(UserDetailComponent, {
            windowClass: 'modal-prompt',
            animation: false,
        });
        modalRef.componentInstance.user = this.selectedUser;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
