import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-pill-facebook',
    templateUrl: './pill-facebook.component.html',
    styleUrls: ['./pill-facebook.component.scss'],
})
export class PillFacebookComponent implements OnInit {
    @Input() facebookUrl: string;

    constructor() {}

    ngOnInit(): void {}
}
