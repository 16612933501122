<div class="modal-header">
    <h2
        class="modal-title"
        id="exampleModalLabel">
        Link new document
    </h2>
    <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modalService.close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <div *ngIf="isLoading">
        <app-loading-message></app-loading-message>
    </div>

    <div *ngIf="!isLoading">
        <app-searchbar [(value)]="filter"></app-searchbar>
        <div *ngFor="let document of filteredArray()">
            <div (click)="submit(document.uuid)">
                <i class="las la-file-alt"></i
                ><span
                    placement="top"
                    [ngbTooltip]="document.content.substr(0, 20) + ' ...'"
                    >{{ document.name }}</span
                >
            </div>
        </div>
    </div>
</div>

<div class="modal-footer">
    <button
        type="button"
        class="button button--outline"
        (click)="modalService.close()">
        Cancel
    </button>
</div>
