<app-app-holder>
    <app-navbar></app-navbar>

    <app-sidebar>
        <app-mailchimp-overview></app-mailchimp-overview>
    </app-sidebar>

    <app-content
        *appIsPermitted="{
            module: 'MAILCHIMP',
            operator: 'AND',
            actions: ['READ']
        }">
        <app-mailchimp-not-logged-in (keyIsset)="checkKey($event)"></app-mailchimp-not-logged-in>

        <div
            class="app__title"
            *ngIf="isset">
            <h1>Mailchimp - Audience ({{ (audiences$ | async).length }})</h1>
        </div>

        <app-loading-message *ngIf="loading"></app-loading-message>
        <div *ngIf="isset && !loading">
            <nav
                ngbNav
                #nav="ngbNav"
                class="tabs__modal">
                <ng-container
                    ngbNavItem
                    *ngFor="let audience of audiences$ | async">
                    <a ngbNavLink>{{ audience.name }}</a>
                    <ng-template ngbNavContent>
                        <div class="m-t-4">
                            <app-searchbar [(value)]="filter"></app-searchbar>
                            <div *ngIf="loading && audience.recipients === undefined">
                                <app-loading-message></app-loading-message>
                            </div>
                            <table
                                class="table-view m-t-4"
                                *ngIf="audience.recipients !== undefined">
                                <tr>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Rating</th>
                                    <th>Tags</th>
                                    <th class="td-small"></th>
                                </tr>
                                <tr *ngFor="let recipient of audience.recipients | orderBy: 'firstName':false:true | filterBy: ['firstName']:filter">
                                    <td (click)="showMemberDetails(recipient)">
                                        <div class="list__item__image">
                                            <img
                                                [src]="
                                                    'https://eu.ui-avatars.com/api/?background=random&bold=true&name=' +
                                                    recipient.firstName +
                                                    ' ' +
                                                    recipient.lastName
                                                " />
                                            {{ recipient.firstName }}
                                            {{ recipient.lastName }}
                                        </div>
                                    </td>
                                    <td>
                                        {{ recipient.email }}
                                    </td>
                                    <td>
                                        <span class="badge m-l-1">{{ recipient.memberRating }}</span>
                                    </td>
                                    <td>
                                        <span
                                            *ngFor="let tag of recipient.tags"
                                            class="badge m-l-1"
                                            >{{ tag }}</span
                                        >
                                    </td>
                                    <td>
                                        <span ngbDropdown>
                                            <button
                                                ngbDropdownToggle
                                                class="button button--icon button--dropdown button--small"></button>
                                            <span
                                                ngbDropdownMenu
                                                class="dropdown-menu dropdown-menu-right"
                                                aria-labelledby="dropdownMenuButton">
                                                <a
                                                    class="dropdown-item"
                                                    *appIsPermitted="{
                                                        module: 'MAILCHIMP',
                                                        operator: 'AND',
                                                        actions: ['CREATE']
                                                    }"
                                                    (click)="openBatchAddLeads(audience.recipients)"
                                                    >Add leads</a
                                                >
                                            </span>
                                        </span>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </ng-template>
                </ng-container>
            </nav>

            <div>
                <div [ngbNavOutlet]="nav"></div>
            </div>
        </div>
    </app-content>
</app-app-holder>
