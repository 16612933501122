import { Component, Input, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';
import { Product } from 'src/app/models/Product';

import { ArchiveProduct } from '../product.actions';

@Component({
    selector: 'app-product-delete-modal',
    templateUrl: './product-delete-modal.component.html',
    styleUrls: ['./product-delete-modal.component.scss'],
})
export class ProductDeleteModalComponent implements OnDestroy {
    private subscriptions = new Subscription();

    @Input() product: Product;

    constructor(public activeModal: NgbActiveModal, private store: Store, private logger: NGXLogger) {}

    archiveProduct() {
        this.logger.debug('Attempt to archive Product');
        this.subscriptions.add(
            this.store.dispatch(new ArchiveProduct(this.product.uuid)).subscribe(
                () => {
                    this.logger.debug('Succesfully archived product');
                    this.activeModal.close();
                },
                () => this.logger.error('Failed to archive product'),
            ),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
