import { Component, ElementRef, Input, Renderer2, ViewChild, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'password-input',
    templateUrl: './password-input.component.html',
    styleUrls: ['./password-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => PasswordInputComponent),
            multi: true,
        },
    ],
})
export class PasswordInputComponent implements ControlValueAccessor {
    @Input() placeholder = 'Password';
    @Input() label = 'Password';
    @Input() showLabel = true;
    @Input() id = 'password';

    value = '';
    inputType: INPUT_TYPE = INPUT_TYPE.PASSWORD;

    @ViewChild('passwordInput') passwordInput: ElementRef;
    @ViewChild('visibleButton') visibleButton: ElementRef;

    constructor(private renderer: Renderer2) {}

    onChange: any = () => {};
    onTouch: any = () => {};

    set password(password) {
        this.value = password;
        this.onChange(password);
        this.onTouch(password);
    }

    get password() {
        return this.value;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    writeValue(obj: any): void {
        this.password = obj;
    }

    setDisabledState(isDisabled: boolean) {
        this.renderer.setProperty(this.passwordInput.nativeElement, 'disabled', isDisabled);
        this.renderer.setProperty(this.visibleButton.nativeElement, 'disabled', isDisabled);
    }

    changeVisibility() {
        if (this.inputType === INPUT_TYPE.PASSWORD) this.inputType = INPUT_TYPE.TEXT;
        else if (this.inputType === INPUT_TYPE.TEXT) this.inputType = INPUT_TYPE.PASSWORD;
    }
}

class INPUT_TYPE {
    static readonly TEXT = new INPUT_TYPE('text');
    static readonly PASSWORD = new INPUT_TYPE('password');

    constructor(public readonly html: string) {}
}
