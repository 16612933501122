<app-searchbar
    [(value)]="filter"
    (valueChange)="applyFilter($event)"></app-searchbar>

<div class="m-t-4">
    <table
        mat-table
        [dataSource]="dataSource"
        matSort>
        <ng-container matColumnDef="type">
            <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header>
                Type
            </th>
            <td
                mat-cell
                *matCellDef="let row">
                {{ row.type }}
            </td>
        </ng-container>

        <ng-container matColumnDef="edit">
            <th
                mat-header-cell
                *matHeaderCellDef
                class="td-small"></th>
            <td
                mat-cell
                *matCellDef="let row">
                <span ngbDropdown>
                    <button
                        ngbDropdownToggle
                        class="button button--icon button--dropdown button--small"></button>
                    <span
                        ngbDropdownMenu
                        class="dropdown-menu dropdown-menu-right"
                        aria-labelledby="dropdownMenuButton">
                        <a
                            class="dropdown-item"
                            (click)="openUnitAddModal(row)"
                            >Edit unit</a
                        >
                        <a
                            class="dropdown-item color--red"
                            (click)="openUnitDeleteModal(row)"
                            >Delete unit</a
                        >
                    </span>
                </span>
            </td>
        </ng-container>

        <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns"></tr>
        <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns"></tr>

        <tr *matNoDataRow>
            <td colspan="2">No units matching the filter "{{ filter }}"</td>
        </tr>
    </table>
</div>
