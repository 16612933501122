<div class="dashboard__tile__title">
    <span>Deals with warning status</span>
    <button
        type="button"
        class="button button--icon button--small"
        (click)="goToCRM()">
        <i class="uil uil-arrow-right"></i>
    </button>
</div>
<div class="dashboard__tile__content">
    <div class="table__overflow">
        <table
            mat-table
            [dataSource]="dataSource"
            matSort
            [hidden]="!(this.dataSource?.data?.length > 0)"
            matSortActive="dateSinceLastMovement"
            matSortDirection="desc">
            <ng-container matColumnDef="deal">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header>
                    Deal
                </th>
                <td
                    mat-cell
                    *matCellDef="let deal">
                    <app-pill-crmdeal [deal]="deal"></app-pill-crmdeal>
                </td>
            </ng-container>

            <ng-container matColumnDef="price">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header>
                    Price
                </th>
                <td
                    mat-cell
                    *matCellDef="let deal">
                    {{ deal.price }}
                </td>
            </ng-container>

            <ng-container matColumnDef="dateSinceLastMovement">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header>
                    Last movement
                </th>
                <td
                    mat-cell
                    *matCellDef="let deal">
                    <span>{{ deal.dateSinceLastMovement | amTimeAgo }}</span>
                </td>
            </ng-container>

            <tr
                mat-header-row
                *matHeaderRowDef="displayedColumns"></tr>
            <tr
                mat-row
                *matRowDef="let customer; columns: displayedColumns"></tr>
        </table>
    </div>
    <div
        class="dashboard__tile__content"
        *ngIf="this.dealsInWarningStatus.length === 0 && !this.isLoading">
        <app-message-empty
            [title]="'No deals that need your attention'"
            [subtitle]="'Deals that transition to the warning status will be displayed here.'"
            [icon]="'../../assets/img/open-box.png'"></app-message-empty>
    </div>

    <div *ngIf="isLoading">
        <app-loading-message></app-loading-message>
    </div>
</div>
