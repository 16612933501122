import { Password } from '../models/Password';
import User from '../models/User';

export class ActionsOnStartup {
    static readonly type = '[] Actions on Startup';
}

export class ActivateAccount {
    static readonly type = '[User] Activate account';

    constructor(public token: string) {}
}

export class ForgotPassword {
    static readonly type = '[User] Forgot password';

    constructor(public email: string) {}
}

export class ResetPassword {
    static readonly type = '[User] Reset password';

    constructor(public token: string, public newPassword: string, public passwordRepeat: string) {}
}

export class Login {
    static readonly type = '[User] Login';

    constructor(public user: User) {}
}

export class LoginWithMFA {
    static readonly type = '[User] Login With MFA';

    constructor(public user: User, public mfaCode: string) {}
}

export class Register {
    static readonly type = '[User] Register';

    constructor(public user: User) {}
}

export class ResendVerification {
    static readonly type = '[User] Resend Verfication';

    constructor(public email: string) {}
}

export class GetUser {
    static readonly type = '[User] Get User';
}

export class GetUserById {
    static readonly type = '[User] Get User By Id';

    constructor(public userId: string) {}
}

export class AcceptCompanyInvite {
    static readonly type = '[User] Accept Company Invite';

    constructor(public inviteUuid: string) {}
}

export class GetUserInvites {
    static readonly type = '[User] Get Company Invites';

    constructor(public email: string) {}
}

export class UpdatePassword {
    static readonly type = '[User] Update Password';

    constructor(public password: Password) {}
}

export class EnableMfaAuthentication {
    static readonly type = '[User] Enable MFA';

    constructor(public password: string) {}
}

export class DisableMfaAuthentication {
    static readonly type = '[User] Disable MFA';

    constructor(public password: string) {}
}

export class GetQrCodeAuthentication {
    static readonly type = '[User] Get MFA QR Code';
}

export class PostVerifyMfa {
    static readonly type = '[User] Verify Mfa Authentication';

    constructor(public userId: string, public code: string) {}
}

export class UpdateUser {
    static readonly type = '[User] Update User';

    constructor(public user: User) {}
}

export class ClearStore {
    static readonly type = '[User] Clear Store';
}

export class Logout {
    static readonly type = '[User] Logout';
}
