<app-app-holder>
    <app-navbar></app-navbar>

    <app-sidebar>
        <div class="sidebar-spacing">
            <h3>Inventory</h3>
            <small>Stockmanagement</small>
        </div>

        <span class="divider divider--small"></span>

        <app-inventory-subnav (selectedCategory)="changeSelectedCategory($event)"></app-inventory-subnav>
    </app-sidebar>

    <app-content>
        <div class="app__title">
            <h1>
                Locations
                <span *ngIf="allWarehouseLocations$ | async as allWarehouseLocations"> ({{ allWarehouseLocations.length }}) </span>
            </h1>
            <span>
                <a
                    class="button button--primary"
                    *appIsPermitted="{
                        module: 'INVENTORY',
                        operator: 'AND',
                        actions: ['CREATE']
                    }"
                    (click)="openAddWarehouseLocationModal()"
                    >New location</a
                >
            </span>
        </div>
        <span
            *appIsPermitted="{
                module: 'INVENTORY',
                operator: 'AND',
                actions: ['READ']
            }">
            <div *ngIf="allWarehouseLocations$ | async as allWarehouseLocations; else loading">
                <div *ngIf="allWarehouseLocations">
                    <app-warehouse-locations-list *ngIf="allWarehouseLocations.length > 0; else empty"></app-warehouse-locations-list>
                </div>
            </div>
        </span>
    </app-content>

    <ng-template #loading>
        <app-loading-message></app-loading-message>
    </ng-template>

    <ng-template #empty>
        <app-message-empty
            *ngIf="(allWarehouseLocations$ | async)?.length == 0"
            [title]="'No warehouse locations...'"
            [subtitle]="'Start creating warehouse locations!'"
            [icon]="'../../assets/img/open-box.png'">
        </app-message-empty>
    </ng-template>
</app-app-holder>
