import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import MailingObject, { MailingTypes, getMailingTypeInfo, getMailingTypesByModule } from 'src/app/models/MailingObject';
import { Module } from 'src/app/models/Module';
import { AddMailToModule } from 'src/app/modules/module.actions';

@Component({
    selector: 'app-add-mail-to-mailinglist-modal',
    templateUrl: './add-mail-to-mailinglist-modal.component.html',
    styleUrls: ['./add-mail-to-mailinglist-modal.component.scss'],
})
export class AddMailToMailinglistModalComponent implements OnInit, OnDestroy {
    newMailFormGroup: FormGroup;
    @Input() module: Module;
    mailingTypes: MailingTypes[];
    mailingTypeInfo: any[] = [];
    validDayOfMonthNumber = true;
    private subscriptions = new Subscription();

    constructor(
        public activeModal: NgbActiveModal,
        private formBuilder: FormBuilder,
        private store: Store,
        private toastr: ToastrService,
        private logger: NGXLogger,
    ) {}

    ngOnInit(): void {
        this.mailingTypes = getMailingTypesByModule(this.module.key);
        this.mailingTypes.forEach((t) => {
            this.mailingTypeInfo.push(getMailingTypeInfo(t));
        });
        this.newMailFormGroup = this.formBuilder.group({
            mail: ['', [Validators.required]],
            mailingType: ['', [Validators.required]],
            occurrence: [''],
            cron: [''],
        });
    }

    addMail() {
        let cron = null;
        if (this.newMailFormGroup.get('occurrence').value == 'monthly') {
            let days = this.newMailFormGroup.get('cron').value;
            if (days > 31 || days < -30) {
                this.validDayOfMonthNumber = false;
                return;
            } else {
                this.validDayOfMonthNumber = true;
            }
            if (days > 0) {
                cron = '0 0 0 ' + days + ' * ? *';
            } else {
                days *= -1; //make days positive
                cron = '0 0 0 L-' + days + ' * ? *';
            }
        } else if (this.newMailFormGroup.get('occurrence').value == 'weekly') {
            cron = this.newMailFormGroup.get('cron').value;
        } else if (this.newMailFormGroup.get('occurrence').value == 'daily') {
            cron = '0 0 0 1/1 * ? *';
        }

        const mailingObject: MailingObject = {
            mailingType: this.newMailFormGroup.get('mailingType').value.split(',')[0],
            uuid: null,
            emails: this.newMailFormGroup.get('mail').value.split(';'),
            cron: cron,
        };

        this.logger.debug('Attempt to add mail to mailing list');
        this.subscriptions.add(
            this.store.dispatch(new AddMailToModule(this.module.uuid, mailingObject)).subscribe(
                () => {
                    this.logger.debug('Added mail');
                    this.toastr.success('Mail added!');
                    this.activeModal.dismiss();
                },
                (err) => {
                    this.logger.debug(err);
                    this.toastr.info('Mail was not added. This mail is probably already used with this action and timing.');
                },
            ),
        );
    }

    getCronEnabled(): boolean {
        const type = this.newMailFormGroup.get('mailingType').value.split(',');
        if (type[2] == undefined) return undefined;
        return JSON.parse(type[2]);
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
