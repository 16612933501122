import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { AuthenticationService } from '../authentication/authentication.service';
import { Product } from '../models/Product';
import { ProductStockUpdate } from '../models/ProductStockUpdate';
import { StockUpdateHistoryModel } from '../models/StockUpdateHistoryModel';

@Injectable({
    providedIn: 'root',
})
export class ProductService {
    httpOptions = {};

    constructor(private httpClient: HttpClient, private authenticationService: AuthenticationService) {
        this.httpOptions = authenticationService.getHeaders();
    }

    addProduct(product: Product): Observable<Product> {
        return this.httpClient.post<Product>(environment.baseUrl + '/product/', product, this.httpOptions);
    }

    getAllProducts() {
        return this.httpClient.get<Product[]>(environment.baseUrl + '/product/product-list', this.httpOptions);
    }

    getProductById(uuid: string): Observable<Product> {
        return this.httpClient.get<Product>(environment.baseUrl + '/product/' + uuid, this.httpOptions);
    }

    updateProduct(product: Product): Observable<Product> {
        return this.httpClient.put<Product>(environment.baseUrl + '/product/', product, this.httpOptions);
    }

    archiveProduct(uuid: string) {
        return this.httpClient.put(environment.baseUrl + '/product/' + uuid, {}, this.httpOptions);
    }

    //Linked products
    addProductsLink(currentUuid: string, parentUuid: string, children: string, amountNeeded: number[]) {
        return this.httpClient.post<Product>(
            environment.baseUrl + '/product/' + currentUuid + '/' + parentUuid + '/link?children=' + children + '&amountNeeded=' + amountNeeded,
            {},
            this.httpOptions,
        );
    }

    updateLinkedAmount(parentUuid: string, childUuid: string, amountNeeded: number) {
        return this.httpClient.put<Product>(
            environment.baseUrl + '/product/' + parentUuid + '/update-linked-amount?childUuid=' + childUuid + '&amountNeeded=' + amountNeeded,
            {},
            this.httpOptions,
        );
    }

    removeProductsLink(parentUuid: string, childUuid: string) {
        return this.httpClient.delete(environment.baseUrl + '/product/' + parentUuid + '/' + childUuid + '/remove-link', this.httpOptions);
    }

    //productCategories
    getAllProductCategories() {
        return this.httpClient.get<string[]>(environment.baseUrl + '/product/category', this.httpOptions);
    }

    addProductCategory(category: string) {
        return this.httpClient.post<any>(environment.baseUrl + '/product/category', category, this.httpOptions);
    }

    updateProductCategory(oldCategory: string, newCategory: string) {
        return this.httpClient.put<any>(environment.baseUrl + '/product/category/' + oldCategory, newCategory, this.httpOptions);
    }

    deleteProductCategories(category: string) {
        return this.httpClient.delete(environment.baseUrl + '/product/category/' + category, this.httpOptions);
    }

    linkProductCategory(productUuid: string, category: string) {
        return this.httpClient.put<Product>(environment.baseUrl + '/product/category/link/' + productUuid, category, this.httpOptions);
    }

    unlinkProductCategory(productUuid: string, category: string) {
        return this.httpClient.put<Product>(environment.baseUrl + '/product/category/unlink/' + productUuid, category, this.httpOptions);
    }

    //extra
    getAllProductLinks(productUuid: string) {
        return this.httpClient.get<Product[]>(environment.baseUrl + '/product/' + productUuid + '/product-child-list', this.httpOptions);
    }

    updateStock(companyUuid: string, childUuid: string, linkedProductStockUpdate: ProductStockUpdate) {
        return this.httpClient.post<ProductStockUpdate>(
            environment.baseUrl + '/product/' + companyUuid + '/' + childUuid,
            linkedProductStockUpdate,
            this.httpOptions,
        );
    }

    getProductDetailPdf(productUuid: string) {
        return this.httpClient.get<any>(environment.baseUrl + '/product/' + productUuid + '/pdf', this.httpOptions);
    }

    getProductsPdf(companyUuid: string, childUuid: string): Observable<any> {
        return this.httpClient.get<any>(environment.baseUrl + '/qrcodes/' + companyUuid + '/' + childUuid, this.httpOptions);
    }

    getAllProductsPdf(companyUuid: string): Observable<any> {
        return this.httpClient.get<any>(environment.baseUrl + '/qrcodes/' + companyUuid, this.httpOptions);
    }

    //stock history
    getProductStockUpdateHistory(): Observable<StockUpdateHistoryModel[]> {
        return this.httpClient.get<StockUpdateHistoryModel[]>(environment.baseUrl + '/product/history', this.httpOptions);
    }
    //stock update
    linkProductToEmployee(productUuid: string, employeeUuid: string): Observable<any> {
        return this.httpClient.post<any>(environment.baseUrl + '/product/linked-to-employee/' + productUuid + '/' + employeeUuid, {}, this.httpOptions);
    }
}
