import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';

import { TeamleaderAddCalendarCallComponent } from '../teamleader-add-calendar-call/teamleader-add-calendar-call.component';
import { TeamleaderAddCalendarMeetingComponent } from '../teamleader-add-calendar-meeting/teamleader-add-calendar-meeting.component';
import { TeamleaderAddCalendarTaskComponent } from '../teamleader-add-calendar-task/teamleader-add-calendar-task.component';
import { FetchActivityTypes, FetchCalendarEvents } from '../teamleader.actions';
import { TeamleaderState } from '../teamleader.state';

@Component({
    selector: 'app-teamleader-agenda',
    templateUrl: './teamleader-agenda.component.html',
    styleUrls: ['./teamleader-agenda.component.scss'],
})
export class TeamleaderAgendaComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Select(TeamleaderState.calendarEvents) calendarEvents$: Observable<any[]>;
    @Select(TeamleaderState.activityTypes) activityTypes$: Observable<any[]>;
    calendarEvents: any[];
    activityTypes: any[];
    isset: boolean;
    isLoading = true;
    filter: string;

    constructor(private modalService: NgbModal, private store: Store) {}

    ngOnInit() {}

    openAddCalendarMeetingModal() {
        const modalRef = this.modalService.open(TeamleaderAddCalendarMeetingComponent, { windowClass: 'modal-prompt' });
        modalRef.componentInstance.activityType = this.activityTypes.find((a) => a.name === 'meeting');
    }

    openAddCalendarTaskModal() {
        const modalRef = this.modalService.open(TeamleaderAddCalendarTaskComponent, { windowClass: 'modal-prompt' });
        modalRef.componentInstance.activityType = this.activityTypes.find((a) => a.name === 'task');
    }

    openAddCalendarCallModal() {
        const modalRef = this.modalService.open(TeamleaderAddCalendarCallComponent, { windowClass: 'modal-prompt' });
        modalRef.componentInstance.activityType = this.activityTypes.find((a) => a.name === 'call');
    }

    checkCredentials(set: boolean) {
        this.isset = set;
        if (this.isset) {
            this.subscriptions.add(
                this.store.dispatch(new FetchCalendarEvents()).subscribe(() => {
                    this.calendarEvents$.subscribe((calendarEvents) => (this.calendarEvents = calendarEvents));
                }),
            );
            this.subscriptions.add(
                this.store.dispatch(new FetchActivityTypes()).subscribe(() => {
                    this.activityTypes$.subscribe((activityTypes) => (this.activityTypes = activityTypes));
                    this.isLoading = false;
                }),
            );
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
