<app-app-holder>
    <app-navbar></app-navbar>

    <app-content>
        <app-message-empty
            *ngIf="this.type === 'outlook'"
            [title]="'No leads...'"
            [subtitle]="authenticationResult"
            [icon]="'../../../assets/img/logo-outlook.png'">
            <app-loading-message *ngIf="!authenticationResult"></app-loading-message>
        </app-message-empty>

        <app-message-empty
            *ngIf="this.type === 'teamleader'"
            [title]="'No leads...'"
            [subtitle]="authenticationResult"
            [icon]="'../../../assets/img/teamleader.png'">
            <app-loading-message *ngIf="!authenticationResult"></app-loading-message>
        </app-message-empty>

        <app-message-empty
            *ngIf="this.type === 'exact'"
            [title]="title"
            [subtitle]="authenticationResult"
            [icon]="'../../../assets/img/logo-exact.png'">
            <app-loading-message *ngIf="!authenticationResult"></app-loading-message>
        </app-message-empty>
    </app-content>
</app-app-holder>
