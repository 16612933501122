<app-app-holder>
    <app-navbar></app-navbar>

    <app-content>
        <div class="app__title">
            <h1>CRM</h1>
            <div class="d-flex align-items-center">
                <span>
                    <span
                        ngbDropdown
                        class="m-r-1">
                        <button
                            ngbDropdownToggle
                            class="button button--icon button--dropdown">
                            Views
                        </button>

                        <span
                            ngbDropdownMenu
                            class="dropdown-menu dropdown-menu-right"
                            aria-labelledby="dropdownMenuButton">
                            <a
                                class="dropdown-item"
                                (click)="switchView('kanbanView')"
                                id="kanbanView"
                                >Kanban view</a
                            >
                            <a
                                class="dropdown-item"
                                (click)="switchView('reasonOfLossView')"
                                id="reasonOfLossView"
                                >Reasons of loss</a
                            >
                            <a
                                class="dropdown-item"
                                (click)="switchView('archived')"
                                id="archived"
                                >Archived</a
                            >
                        </span>
                    </span>

                    <span
                        ngbDropdown
                        class="dropdown">
                        <button
                            ngbDropdownToggle
                            class="button dropdown-toggle"
                            id="dropdownMenuButton">
                            Options
                        </button>
                        <div
                            ngbDropdownMenu
                            class="dropdown-menu dropdown-menu-right"
                            aria-labelledby="dropdownMenuButton">
                            <a
                                class="dropdown-item"
                                *appIsPermitted="{
                                    module: 'CRM',
                                    operator: 'AND',
                                    actions: ['CREATE']
                                }"
                                (click)="openAddStageModal()"
                                >Add stage</a
                            >
                            <a
                                class="dropdown-item"
                                *appIsPermitted="{
                                    module: 'CRM',
                                    operator: 'AND',
                                    actions: ['READ']
                                }"
                                (click)="openManageStagesModal()"
                                >Manage stages</a
                            >
                        </div>
                    </span>
                </span>
                

                <button
                    class="button button--primary m-l-2"
                    *appIsPermitted="{
                        module: 'CRM',
                        operator: 'AND',
                        actions: ['CREATE']
                    }"
                    (click)="openAddDealModal(null)">
                    New deal
                </button>
            </div>
        </div>

        <ng-container>

        </ng-container>

        <div
            *appIsPermitted="{
                module: 'CRM',
                operator: 'AND',
                actions: ['READ']
            }">
            <ng-container  *ngIf="view === 'kanbanView'">
                <div class="m-t-4">
                    <app-searchbar (valueChange)="filter = $event"></app-searchbar>
                    <div *ngIf="stages$ | async as stages; else loading">
                        <div
                            class="kanban m-t-4"
                            cdkDropListGroup
                            *ngIf="stages.length > 0; else empty">
                            <div
                                class="kanban__lane"
                                *ngFor="let stage of stages | orderBy: 'sequence':false:true">
                                <div class="kanban__lane__title">
                                    <strong>{{ stage.name }}</strong>
                                    <small data-toggle="tooltip"
                                        >Total:
                                        {{ getTotalValue(stage) | currency: 'EUR' }}
                                        ({{ stage.probability }}% chance)</small
                                    >
                                </div>

                                <div
                                    class="kanban__lane__items"
                                    cdkDropList
                                    id="{{ stage.uuid }}"
                                    [cdkDropListData]="stage.crmDeals | filterCrmDeals: filter"
                                    (cdkDropListDropped)="drop($event)">
                                    <div
                                        (click)="openDealDetailModal(deal)"
                                        class="kanban__item"
                                        cdkDrag
                                        *ngFor="let deal of stage.crmDeals | filterCrmDeals: filter"
                                        #dragItem1>
                                        {{ deal.title }}<br />
                                        <small>{{ deal.price | currency: 'EUR' }}</small>
                                        <span
                                            class="badge badge--danger"
                                            *ngIf="isOverdue(deal.dateSinceLastMovement, stage.rottingIn, stage.type)"
                                            >Overdue</span
                                        >
                                        <span
                                            class="badge badge--warning"
                                            *ngIf="isWarning(deal.dateSinceLastMovement, stage.rottingIn, stage.type)"
                                            >Hurry</span
                                        >
                                    </div>
                                </div>

                                <div
                                    class="kanban__add"
                                    *appIsPermitted="{
                                        module: 'CRM',
                                        operator: 'AND',
                                        actions: ['CREATE']
                                    }"
                                    (click)="openAddDealModal(stage)">
                                    <i class="las la-plus"></i> New deal
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="view === 'reasonOfLossView'">
                <div class="m-t-4" *ngIf="reasons$ | async as reasonsOfLoss; else loading">
                    <app-reason-of-loss-list
                            [reasons]="reasonsOfLoss"
                            [linkedObjectType]="'deal'"
                            [linkedObjects]="(allDeals$ | async)">
                        </app-reason-of-loss-list>
                    <app-reason-of-loss-graph
                            [reasons]="reasonsOfLoss"
                    ></app-reason-of-loss-graph>
                </div>
            </ng-container>

            <ng-container *ngIf="view === 'archived'">
                <div class="m-t-4">
                    <app-archived-crmdeals></app-archived-crmdeals>
                </div>
            </ng-container>

        </div>
    </app-content>

    <ng-template #empty>
        <app-message-empty
            [title]="'No CRM stages...'"
            [subtitle]="'Start adding stages to manage your deals. Stages are the status a deal is in.'"
            [icon]="'../../assets/img/open-box.png'">
            <a
                class="button button--primary"
                *appIsPermitted="{
                    module: 'CRM',
                    operator: 'AND',
                    actions: ['CREATE']
                }"
                (click)="openAddStageModal()"
                >Add first stage</a
            >
        </app-message-empty>
    </ng-template>

    <ng-template #loading>
        <app-loading-message></app-loading-message>
    </ng-template>
</app-app-holder>
