<div class="modal-header">
    <div class="icons-list m-r-2">
        <div
            *ngIf="yukiDocument.verified"
            [ngbTooltip]="verifiedTip"
            class="button button--icon">
            <i class="lar la-check-circle"></i>
        </div>
        <app-yuki-icons
            [showPaidIcon]="true"
            [yukiDocument]="yukiDocument"></app-yuki-icons>

        <ng-template #verifiedTip>Document is verified</ng-template>
    </div>
    <h2 class="modal-title">
        {{ yukiDocument.subject }}
    </h2>

    <button
        (click)="previousDocument()"
        [disabled]="currentIndex < 1"
        class="button button--outline m-r-4 m-l-4"
        type="button">
        <i class="las la-angle-left"></i>
    </button>
    <button
        (click)="nextDocument()"
        class="button button--outline m-r-4"
        type="button">
        <i class="las la-angle-right"></i>
    </button>

    <button
        (click)="closeModal()"
        aria-label="Close"
        class="close"
        type="button">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="h-100 overflow-y-scroll">
    <div class="two-columns">
        <app-yuki-file
            [fileIsFetched]="false"
            [yukiDocument]="yukiDocument" class="column-left"></app-yuki-file>

        <section>
            <nav
                #detailTabs="ngbNav"
                class="tabs__modal"
                ngbNav>
                <ng-container [ngbNavItem]="1">
                    <a ngbNavLink>Document</a>
                    <ng-template ngbNavContent>
                        <app-yuki-update-information
                            (emitDocumentVerified)="switchToLinkTab()"
                            [yukiDocument]="yukiDocument"></app-yuki-update-information>
                    </ng-template>
                </ng-container>

                <ng-container [ngbNavItem]="2">
                    <a ngbNavLink>Linking</a>
                    <ng-template ngbNavContent>
                        <app-link-object-to-file-modal [yukiDocument]="yukiDocument"></app-link-object-to-file-modal>
                    </ng-template>
                </ng-container>
            </nav>

            <div
                [ngbNavOutlet]="detailTabs"
                class="modal-body column-right h-auto detail-body"></div>
        </section>
    </div>
</div>

<div class="modal-footer">
    <button
        (click)="activeModal.close()"
        class="button button--outline">
        Close
    </button>
    <div class="d-flex align-items-center">
        <p class="form-helptext m-r-2">Document's financial information is checked and invoice is linked.</p>
        <button
            (click)="verifyDocument()"
            class="button button--primary">
            <ng-container *ngIf="!yukiDocument.verified; else unverified">Verify</ng-container>
            <ng-template #unverified>Remove verification</ng-template>
        </button>
    </div>
</div>
