<a
    class="pill pill--user"
    *ngIf="selectedUser"
    (click)="openUserDetail(); $event.stopPropagation()">
    <div *ngIf="isLoaded; else loading">
        <img src="https://eu.ui-avatars.com/api/?background=random&bold=true&name={{ selectedUser.firstName + ' ' + selectedUser.lastName }}" />
        {{ selectedUser.firstName + ' ' + selectedUser.lastName }}
    </div>
    <ng-template #loading>
        <app-loading-message></app-loading-message>
    </ng-template>
</a>
