import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { TeamleaderDepartment } from 'src/app/models/TeamleaderDepartment';

import { TeamleaderDepartmentInfoComponent } from '../teamleader-department-info/teamleader-department-info.component';
import { FetchDepartments } from '../teamleader.actions';
import { TeamleaderState } from '../teamleader.state';

@Component({
    selector: 'app-teamleader-departments',
    templateUrl: './teamleader-departments.component.html',
    styleUrls: ['./teamleader-departments.component.scss'],
})
export class TeamleaderDepartmentsComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Select(TeamleaderState.departments) departments$: Observable<TeamleaderDepartment[]>;
    filter: string;
    isLoading = true;
    isset = true;
    departmentInfo;

    constructor(public modalService: NgbModal, private store: Store) {}

    ngOnInit(): void {}

    getDepartmentInfo(department: TeamleaderDepartment) {
        this.subscriptions.add(
            this.departments$.subscribe((departments) => {
                this.departmentInfo = departments.find((d) => d.id === department.id);
            }),
        );
        const modalRef = this.modalService.open(TeamleaderDepartmentInfoComponent, { windowClass: 'modal-pane', animation: false });
        modalRef.componentInstance.department = this.departmentInfo;
    }

    checkCredentials(set: boolean) {
        this.isset = set;
        if (this.isset) {
            this.store.dispatch(new FetchDepartments());
            this.isLoading = false;
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
