import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TeamleaderDepartment } from 'src/app/models/TeamleaderDepartment';

@Component({
    selector: 'app-teamleader-department-info',
    templateUrl: './teamleader-department-info.component.html',
    styleUrls: ['./teamleader-department-info.component.scss'],
})
export class TeamleaderDepartmentInfoComponent implements OnInit {
    @Input() department: TeamleaderDepartment;

    constructor(public activeModal: NgbActiveModal, public modalService: NgbModal) {}

    ngOnInit(): void {}

    generateArray(obj) {
        return Object.keys(obj).map((key) => {
            return { key: key, value: obj[key] };
        });
    }
}
