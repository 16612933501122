import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';

import { HappinessScoreInformationForEmployee } from '../../models/HappinessScoreInformationForEmployee';
import { MONTHS } from '../../models/MONTHS';
import { LegendPosition } from '../../shared/charts/default-line-chart/default-line-chart.component';
import { DateNavigationOptions, DateNavigationType, SelectedDateClass } from '../../shared/date-navigation/date-navigation.component';
import { capitalizeFirstLetter } from '../../shared/utils/string-utils';
import { GetHappinessScoreInformationForEmployeeByEmployeeId } from '../financial.actions';
import { FinancialState } from '../financial.state';

@Component({
    selector: 'app-happiness-score-graph-widget',
    templateUrl: './happiness-score-graph-widget.component.html',
    styleUrls: ['./happiness-score-graph-widget.component.scss'],
})
export class HappinessScoreGraphWidgetComponent implements OnInit {
    @Input() employeeId: string;
    @Input() alreadyFetchedData = true;

    happinessScoreInformation: HappinessScoreInformationForEmployee;

    data: any[] = undefined;
    labels: string[] = [];
    xLabels: string[];
    legendPosition: LegendPosition = LegendPosition.BOTTOM;

    dateNavigationOptions: DateNavigationOptions;

    private subscriptions = new Subscription();

    constructor(private store: Store) {}

    ngOnInit(): void {
        this.dateNavigationOptions = {
            dateNavigationType: DateNavigationType.YEAR,
            selectedDate: new Date(),
            selectedDateClass: SelectedDateClass.SMALL,
        };

        this.fetchData();
        this.createChart();
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    updateSelectedYear() {
        this.store.dispatch(new GetHappinessScoreInformationForEmployeeByEmployeeId(this.employeeId, this.dateNavigationOptions.selectedDate.getFullYear()));
    }

    private createChart() {
        if (!this.happinessScoreInformation) return;

        this.xLabels = this.seedLabels();
        this.data = [this.happinessScoreInformation.happinessScores];
        this.labels = ['Happiness Score'];
    }

    private seedLabels() {
        return Object.values(MONTHS).map((label) => capitalizeFirstLetter(label.short));
    }

    private fetchData() {
        if (!this.alreadyFetchedData) this.store.dispatch(new GetHappinessScoreInformationForEmployeeByEmployeeId(this.employeeId));

        this.subscriptions.add(
            this.store.select(FinancialState.happinessScoreInformationForEmployee(this.employeeId)).subscribe((happinessScoreInformation) => {
                this.happinessScoreInformation = happinessScoreInformation;
                this.createChart();
            }),
        );
    }
}
