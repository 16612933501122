<app-app-holder>
    <app-navbar></app-navbar>

    <app-content>
        <div class="app__title">
            <h1>Dashboard</h1>
        </div>

        <div class="dashboard">
            <app-dashboard-budgets-widget
                class="dashboard__tile"
                *appIsPermitted="{
                    module: 'BUDGET',
                    operator: 'AND',
                    actions: ['READ']
                }"></app-dashboard-budgets-widget>
            <app-dashboard-contactpersons-widget
                class="dashboard__tile"
                *appIsPermitted="{
                    module: 'CONTACTS',
                    operator: 'AND',
                    actions: ['READ']
                }"></app-dashboard-contactpersons-widget>
            <app-dashboard-projects-widget
                class="dashboard__tile"
                *appIsPermitted="{
                    module: 'PROJECTS',
                    operator: 'AND',
                    actions: ['READ']
                }"></app-dashboard-projects-widget>
            <app-dashboard-leads-widget
                class="dashboard__tile"
                *appIsPermitted="{
                    module: 'LEADS',
                    operator: 'AND',
                    actions: ['READ']
                }"></app-dashboard-leads-widget>
            <app-dashboard-crm-warnings-widget
                class="dashboard__tile"
                *appIsPermitted="{
                    module: 'CRM',
                    operator: 'AND',
                    actions: ['READ']
                }"></app-dashboard-crm-warnings-widget>
            <app-dashboard-low-stock-product-widget
                class="dashboard__tile"
                *appIsPermitted="{
                    module: 'INVENTORY',
                    operator: 'AND',
                    actions: ['READ']
                }"></app-dashboard-low-stock-product-widget>
            <app-dashboard-inventory-update-history-widget
                class="dashboard__tile"
                *appIsPermitted="{
                    module: 'INVENTORY',
                    operator: 'AND',
                    actions: ['READ']
                }"></app-dashboard-inventory-update-history-widget>
            <app-dashboard-employee-anniversary-widget
                class="dashboard__tile"
                *appIsPermitted="{
                    module: 'EMPLOYEES',
                    operator: 'AND',
                    actions: ['READ']
                }"></app-dashboard-employee-anniversary-widget>
            <app-dashboard-employee-work-anniversary-widget
                class="dashboard__tile"
                *appIsPermitted="{
                    module: 'EMPLOYEES',
                    operator: 'AND',
                    actions: ['READ']
                }"></app-dashboard-employee-work-anniversary-widget>
            <app-dashboard-comments-widget
                class="dashboard__tile"
                *appIsPermitted="{
                    module: 'COMMENTS',
                    operator: 'AND',
                    actions: ['READ']
                }"></app-dashboard-comments-widget>
            <app-dashboard-customers-widget
                class="dashboard__tile"
                *appIsPermitted="{
                    module: 'CUSTOMERS',
                    operator: 'AND',
                    actions: ['READ']
                }"></app-dashboard-customers-widget>
        </div>

        <app-message-empty
            *ngIf="noActiveWidgets"
            [title]="'No widgets to display'"
            [subtitle]="'There are no active modules with dashboard widgets.'"
            [icon]="'../../assets/img/open-box.png'"></app-message-empty>
    </app-content>

    <ng-template #loading>
        <app-loading-message></app-loading-message>
    </ng-template>
</app-app-holder>
