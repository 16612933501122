import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';

import YukiDocument from '../../../models/yuki/YukiDocument';
import { YukiFile } from '../../../models/yuki/YukiFile';
import { FetchFile, RefreshFile } from '../../../yuki/yuki.actions';
import { YukiState } from '../../../yuki/yuki.state';

@Component({
    selector: 'app-yuki-file',
    templateUrl: './yuki-file.component.html',
    styleUrls: ['./yuki-file.component.scss'],
})
export class YukiFileComponent implements OnInit, OnDestroy, OnChanges {
    @Input() yukiDocument: YukiDocument;
    @Input() fileIsFetched = true;
    @Select(YukiState.file) file$: Observable<YukiFile>;
    file: YukiFile;
    html: string;
    url: string;
    correctFileIsLoaded = false;
    isSyncingFile = false;
    private subscriptions = new Subscription();

    constructor(private store: Store, private logger: NGXLogger, private sanitizer: DomSanitizer, private toastr: ToastrService) {}

    ngOnInit(): void {
        this.fetchData();
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (!this.file) return;
        this.updateFile(this.file);
    }

    createImgSrc() {
        return `data:image/png;base64,${this.file.fileAsBase64}`;
    }

    refreshFile() {
        this.isSyncingFile = true;
        this.logger.debug('Refreshing file from Yuki API');
        this.subscriptions.add(
            this.store.dispatch(new RefreshFile(this.yukiDocument.yukiId)).subscribe(
                (res) => {
                    this.isSyncingFile = false;
                    this.toastr.success('File for Yuki document successfully updated.');
                },
                (err) => {
                    this.isSyncingFile = false;
                    this.logger.error('Failed to update file for Yuki document');
                    this.toastr.error('Oops, something went wrong, Please try again later...');
                },
            ),
        );
    }

    private fetchData() {
        if (!this.fileIsFetched && this.yukiDocument.fileName) {
            this.logger.debug('Fetching file for YukiDocument');
            this.store.dispatch(new FetchFile(this.yukiDocument.uuid));
        }

        this.subscriptions.add(
            this.file$.subscribe((file) => {
                if (file) {
                    this.file = file;
                    this.updateFile(file);
                    if (file.fileName == this.yukiDocument.fileName) {
                        this.correctFileIsLoaded = true;
                    }
                }
            }),
        );
    }

    private updateFile(file: YukiFile) {
        if (!this.yukiDocument.contentType) return;
        if (this.yukiDocument.contentType.includes('html')) {
            this.html = atob(file.fileAsBase64);
        } else if (this.yukiDocument.contentType.includes('pdf')) {
            this.url = `data:application/pdf;base64,${this.file.fileAsBase64}`;
        }
    }
}
