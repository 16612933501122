<div *ngIf="assignedEmployees?.length > 0">
    <div
        class="resume__project"
        *ngFor="let assignedEmployee of assignedEmployees | orderBy: 'firstName':false:true">
        <div class="resume__project__title">
            <span>
                {{ assignedEmployee.firstName }} {{ assignedEmployee.lastName }}
                <div
                    ngbDropdown
                    class="dropdown">
                    <button
                        type="button"
                        ngbDropdownToggle
                        class="button button--icon button--small dropdown-toggle"
                        id="dropdownMenuButton"></button>
                    <div
                        ngbDropdownMenu
                        class="dropdown-menu dropdown-menu-right"
                        aria-labelledby="dropdownMenuButton">
                        <a
                            class="dropdown-item"
                            (click)="previewEmployee(assignedEmployee)"
                            >Preview</a
                        >
                        <a
                            class="dropdown-item color--red"
                            (click)="openDeleteAssignedEmployeeModal(assignedEmployee)"
                            >Delete</a
                        >
                    </div>
                </div>
            </span>
        </div>

        <div class="list__item__extra">
            <ng-template #tipTags
                ><span *ngFor="let category of assignedEmployee.categories">{{ category.title }} </span></ng-template
            >
            <a
                *ngIf="assignedEmployee.categories.length"
                class="m-l-2 button button--small button--icon button--outline"
                placement="top"
                [ngbTooltip]="tipTags">
                <i class="uil uil-tag"></i>
            </a>
        </div>
    </div>
</div>
<div *ngIf="assignedEmployees?.length == 0">
    <p>No employees assigned...</p>
</div>
