import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subscription } from 'rxjs';
import { CompanyState } from 'src/app/companies/company.state';
import { Module } from 'src/app/models/Module';
import { Resume } from 'src/app/models/resume/Resume';

import { ResumeAddModalComponent } from '../resume-add-modal/resume-add-modal.component';
import { FetchResumes } from '../resume.actions';
import { ResumeState } from '../resume.state';

@Component({
    selector: 'app-resume-index',
    templateUrl: './overview.component.html',
    styleUrls: ['./overview.component.scss'],
})
export class OverviewComponent implements OnInit, OnDestroy {
    @Select(CompanyState.companyUuid) companyUuid$: Observable<string>;
    @Select(ResumeState.resumes) resumes$: Observable<Resume[]>;
    modules: Module[];
    private subscriptions = new Subscription();

    constructor(private router: Router, private store: Store, private modalService: NgbModal, private titleService: Title, private logger: NGXLogger) {
        this.titleService.setTitle('Resumes overview');
    }

    ngOnInit(): void {
        this.subscriptions.add(
            this.store.select(CompanyState.company).subscribe((company) => {
                this.logger.debug('Fetching resumes');
                this.store.dispatch(new FetchResumes());
                company?.modules ? (this.modules = [...company.modules]) : (this.modules = null);
            }),
        );

        if (this.modules.length != 0) {
            if (this.modules.filter((module: Module) => module.key.toLowerCase() === 'resume').length === 0) {
                this.logger.debug('Navigating to forbidden');
                this.router.navigate(['/forbidden']);
            }
        }
    }

    openAddResumeModal() {
        this.logger.debug('Opening resumeAddModal');
        this.modalService.open(ResumeAddModalComponent, {
            windowClass: 'modal-prompt',
            animation: false,
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
