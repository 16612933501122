<form
    [formGroup]="budgetExpenditureForm"
    (ngSubmit)="addBudgetExpenditure($event)"
    class="modal-wrapper">
    <div class="modal-header">
        <h2
            class="modal-title"
            id="exampleModalLabel">
            {{ type }}
        </h2>
        <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="activeModal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <div class="row">
            <div class="col">
                <div class="form-group m-b-2 required">
                    <label for="expenditure">Description</label>
                    <small
                        class="form-error"
                        *ngIf="budgetExpenditureForm.get('expenditure').invalid && budgetExpenditureForm.get('expenditure').touched">
                        Description is required...
                    </small>
                    <input
                        type="text"
                        id="expenditure"
                        formControlName="expenditure" />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="form-group m-b-2 required">
                    <label
                        *ngIf="!budgetExpenditure; else template"
                        for="cost"
                        >Cost (budget remaining: {{ budget.totalSpend - getTotalSpend() }})</label
                    >
                    <ng-template #template
                        ><label for="cost"
                            >Cost (budget remaining: {{ budget.totalSpend + budgetExpenditureForm.get('cost').value - getTotalSpend() }})</label
                        ></ng-template
                    >
                    <small
                        class="form-error"
                        *ngIf="budgetExpenditureForm.get('cost').invalid && budgetExpenditureForm.get('cost').touched">
                        Cost is required...
                    </small>
                    <small
                        class="form-error"
                        *ngIf="
                            (!budgetExpenditure && getTotalSpend() > budget.totalSpend) ||
                            (budgetExpenditure && getTotalSpend() - budgetExpenditureForm.get('cost').value > budget.totalSpend)
                        "
                        >You are over budget</small
                    >
                    <input
                        [ngClass]="{
                            overBudget:
                                (!budgetExpenditure && getTotalSpend() > budget.totalSpend) ||
                                (budgetExpenditure && getTotalSpend() - budgetExpenditureForm.get('cost').value > budget.totalSpend)
                        }"
                        type="number"
                        id="cost"
                        formControlName="cost" />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="form-group m-b-2 required">
                    <label for="date">Date</label>
                    <small
                        class="form-error"
                        *ngIf="budgetExpenditureForm.get('date').invalid && budgetExpenditureForm.get('date').touched">
                        Date is required...
                    </small>
                    <input
                        type="date"
                        id="date"
                        formControlName="date" />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <label for="invoiceUrl">Attachment</label>
                <span class="form-helptext">Supported file types: .pdf</span>
                <span *ngIf="budgetExpenditure && budgetExpenditure.invoiceUrl">
                    <app-file-upload
                        *ngIf="budgetExpenditure && budgetExpenditure.invoiceUrl"
                        type="file"
                        formControlName="invoiceUrl"
                        [existingFileUrl]="budgetExpenditure.invoiceUrl"></app-file-upload>
                    <app-file-upload
                        *ngIf="budgetExpenditure && budgetExpenditure.invoiceUrl === ''"
                        type="file"
                        formControlName="invoiceUrl"></app-file-upload>
                </span>
                <app-file-upload
                    *ngIf="!budgetExpenditure || !budgetExpenditure.invoiceUrl"
                    type="file"
                    formControlName="invoiceUrl"></app-file-upload>
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <input
            class="button"
            [ngClass]="budgetExpenditureForm.invalid ? 'button--disabled' : 'button--primary'"
            type="submit"
            value="{{ type }}"
            [disabled]="budgetExpenditureForm.invalid" />
        <button
            class="button button--outline"
            (click)="activeModal.dismiss()">
            Cancel
        </button>
    </div>
</form>
