import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import * as moment from 'moment';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { Employee } from 'src/app/models/Employee';
import { JiraEmployeeCredential } from 'src/app/models/jiraEmployeeCredential';
import { JiraLoginService } from 'src/app/shared/login-components/jira-login/jira-login.service';

import { FetchEmployees, UpdateEmployee } from '../employees.actions';
import { EmployeeState } from '../employees.state';

@Component({
    selector: 'app-employees-detail-modal',
    templateUrl: './employees-detail-modal.component.html',
    styleUrls: ['./employees-detail-modal.component.scss'],
})
export class EmployeesDetailModalComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Select(EmployeeState.Employees) employees$: Observable<Employee[]>;

    @Input() employeeUuid: string;
    @Input() inOverview: boolean;
    employee: Employee;
    EXTRAINFO_MAX_LENGTH = 1000;
    currentAmountOfExtraInfoCharacters: number;
    private NAME_MIN_LENGTH = 2;
    private NAME_MAX_LENGTH = 30;
    employeeFormGroup: FormGroup;
    isValidDate = false;
    public inputIsWrong = false;
    loading: boolean;
    errorMessage: string;
    newEmployee: Employee;
    employeeWentWrong: boolean;
    connectionError: string;
    jiraEmployeeIdList$: JiraEmployeeCredential[] = [];
    jiraEmployeeIdListLoaded = false;

    @ViewChild('focussed', { static: false })
    set input(element: ElementRef<HTMLInputElement>) {
        if (element) {
            element.nativeElement.focus();
        }
    }

    constructor(
        public activeModal: NgbActiveModal,
        private formBuilder: FormBuilder,
        private store: Store,
        private toastr: ToastrService,
        private logger: NGXLogger,
        private jiraLoginService: JiraLoginService,
    ) {}

    ngOnInit(): void {
        // eslint-disable-next-line @typescript-eslint/ban-types
        this.jiraLoginService.getJiraEmployeeIDs().subscribe((data: Object[]) => {
            for (const obj of data) {
                const name = obj['employeeName'] ? obj['employeeName'] : '';
                const id = obj['employeeId'] ? obj['employeeId'] : '';
                const jiraEmployeeCredential = new JiraEmployeeCredential(name, id);
                this.jiraEmployeeIdList$.push(jiraEmployeeCredential);
            }
            this.jiraEmployeeIdListLoaded = true;
        });

        this.subscriptions.add(
            this.employees$.subscribe((employees) => {
                if (!employees) {
                    this.store.dispatch(new FetchEmployees()).subscribe();
                }
                this.employee = employees.filter((e) => e.uuid == this.employeeUuid)[0];
            }),
        );
        if (this.employee) {
            this.employee.extraInfo
                ? (this.currentAmountOfExtraInfoCharacters = this.employee.extraInfo.length)
                : (this.currentAmountOfExtraInfoCharacters = 0);
        }

        this.employeeFormGroup = this.formBuilder.group({
            firstName: ['', [Validators.maxLength(this.NAME_MAX_LENGTH), Validators.minLength(this.NAME_MIN_LENGTH)]],
            lastName: ['', [Validators.maxLength(this.NAME_MAX_LENGTH), Validators.minLength(this.NAME_MIN_LENGTH)]],
            extraInfo: ['', [Validators.maxLength(this.EXTRAINFO_MAX_LENGTH)]],
            hireDate: [null, Validators.required],
            birthday: [null, Validators.required],
            profession: [''],
            jiraEmployeeId: this.jiraEmployeeIdList$,
            contactInformation: this.formBuilder.group({
                phoneNumber: [''],
                email: ['', [Validators.email]],
                website: [''],
                linkedIn: [''],
            }),
        });
        this.setFormValues();
    }

    setFormValues() {
        this.isValidDate = true;
        this.employeeFormGroup.setValue({
            firstName: this.employee.firstName,
            lastName: this.employee.lastName,
            extraInfo: this.employee.extraInfo ? this.employee.extraInfo : '',
            hireDate: moment(this.employee.hireDate).format('YYYY-MM-DD'),
            birthday: moment(this.employee.birthday).format('YYYY-MM-DD'),
            profession: this.employee.profession ? this.employee.profession : '',
            jiraEmployeeId: this.employee.jiraEmployeeId ? this.employee.jiraEmployeeId : '',
            contactInformation:
                this.employee.contactInformation !== null
                    ? {
                          phoneNumber: this.employee.contactInformation.phoneNumber ? this.employee.contactInformation.phoneNumber : '',
                          email: this.employee.contactInformation.email ? this.employee.contactInformation.email : '',
                          website: this.employee.contactInformation.website ? this.employee.contactInformation.website : '',
                          linkedIn: this.employee.contactInformation.linkedIn ? this.employee.contactInformation.linkedIn : '',
                      }
                    : {
                          phoneNumber: '',
                          email: '',
                          website: '',
                          linkedIn: '',
                      },
        });
    }

    saveEmployee() {
        this.loading = true;

        const hireDate = new Date(this.employeeFormGroup?.get('hireDate').value);
        const birthdayDate = new Date(this.employeeFormGroup?.get('birthday').value);
        if (!this.employeeFormGroup.valid || hireDate <= birthdayDate) {
            this.loading = false;
            this.inputIsWrong = true;
            return;
        }

        this.employeeFormGroup.disable();

        this.newEmployee = this.employeeFormGroup.value;
        this.newEmployee.contactInformation.uuid = this.employee.contactInformation.uuid;

        this.newEmployee.uuid = this.employee.uuid;
        this.logger.debug('Attempt to update employee');
        this.subscriptions.add(
            this.store.dispatch(new UpdateEmployee(this.newEmployee)).subscribe(
                () => {
                    this.logger.debug('Succesfully updated employee');
                    this.toastr.success('Employee updated successfully');
                    this.activeModal.close();
                },
                (error) => {
                    this.logger.error('Failed to update employee');
                    this.errorMessage = error?.error?.message ?? 'Error message was empty';
                    this.loading = false;
                    this.employeeFormGroup.enable();
                    if (!this.errorMessage) {
                        this.connectionError = 'No connection to the server';
                    }

                    this.employeeWentWrong = true;
                },
            ),
        );
    }

    hideErrorMessage() {
        this.inputIsWrong = false;
    }

    get firstName() {
        return this.employeeFormGroup?.get('firstName');
    }
    get lastName() {
        return this.employeeFormGroup?.get('lastName');
    }
    get extraInfo() {
        return this.employeeFormGroup?.get('extraInfo');
    }
    get phoneNumber() {
        return this.employeeFormGroup?.get('phoneNumber');
    }
    get email() {
        return this.employeeFormGroup?.get('contactInformation').get('email');
    }
    get website() {
        return this.employeeFormGroup?.get('contactInformation').get('website');
    }
    get linkedIn() {
        return this.employeeFormGroup?.get('contactInformation').get('linkedIn');
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
