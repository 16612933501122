<form
    [formGroup]="contactPersonFormGroup"
    (ngSubmit)="saveContactPerson()"
    class="modal-wrapper">
    <div class="modal-header">
        <h2
            *ngIf="!isUpdate"
            class="modal-title"
            id="exampleModalLabel">
            New contact
        </h2>
        <h2
            *ngIf="isUpdate"
            class="modal-title"
            id="exampleModalLabel">
            Edit contact
        </h2>
        <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="activeModal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <div class="row">
            <div class="col-6">
                <div class="form-group required m-b-2">
                    <label>Firstname</label>
                    <input
                        type="text"
                        formControlName="firstName"
                        trim="blur"
                        #focussed />

                    <span
                        class="form-error"
                        *ngIf="firstName.errors?.required && firstName.touched">
                        First name is required
                    </span>
                    <span
                        class="form-error"
                        *ngIf="firstName.errors?.minlength && firstName.touched">
                        First name required and needs to be at least 2 characters long
                    </span>
                    <span
                        class="form-error"
                        *ngIf="firstName.errors?.maxlength && firstName.touched">
                        First name required and can not be longer then 30 characters
                    </span>
                </div>
            </div>
            <div class="col-6">
                <div class="form-group required m-b-2">
                    <label>Lastname</label>
                    <input
                        type="text"
                        formControlName="lastName"
                        trim="blur" />

                    <span
                        class="form-error"
                        *ngIf="lastName.errors?.required && lastName.touched">
                        Last name is required
                    </span>
                    <span
                        class="form-error"
                        *ngIf="lastName.errors?.minlength && lastName.touched">
                        Last name needs to be at least 2 characters long
                    </span>
                    <span
                        class="form-error"
                        *ngIf="lastName.errors?.maxlength && lastName.touched">
                        Last name can not be longer then 30 characters
                    </span>
                </div>
            </div>
        </div>

        <div class="form-group m-b-2">
            <label>Profession</label>
            <input
                type="text"
                placeholder="profession"
                formControlName="profession" />
        </div>

        <h3 class="m-t-4 m-b-4">Contact information</h3>

        <div formGroupName="contactInformation">
            <div class="form-group m-b-2">
                <label>Phonenumber</label>
                <input
                    type="text"
                    placeholder="Phonenumber"
                    formControlName="phoneNumber"
                    trim="blur" />
                <span class="form-helptext">Not your personal phonenumber!</span>
            </div>

            <div class="form-group m-b-2 required">
                <label>Emailaddress</label>
                <input
                    type="email"
                    placeholder="Emailaddress"
                    formControlName="email"
                    trim="blur" />

                <span
                    class="form-error"
                    *ngIf="email.errors?.email && email.touched"
                    >Enter a valid email</span
                >

                <span class="form-helptext">Probably something like contact@mycompany.com ...</span>
            </div>

            <div class="form-group m-b-2">
                <label>LinkedIn</label>
                <input
                    type="text"
                    placeholder="LinkedIn"
                    formControlName="linkedIn" />
            </div>

            <div class="form-group m-b-2">
                <label>Tags</label>
                <app-global-tags
                    *ngIf="!isUpdate"
                    [type]="'contact'"></app-global-tags>
            </div>
        </div>

        <app-global-autocomplete
            [parentForm]="contactPersonFormGroup"
            [formControlName]="'company'"
            [data]="allPartners"
            [itemType]="'company'"
            [label]="'Link a company'"
            [notFoundMessage]="'Add a company'"
            [keyword]="'title'"
            [placeholder]="'Choose a company'"
            [dataLoaded]="isLoaded"
            [active]="this.moduleService.checkModuleActive(eModule.PARTNERS)"
            [required]="false">
        </app-global-autocomplete>

        <div class="form-group m-b-2">
            <label>Description</label>
            <ckeditor
                formControlName="extraInfo"
                [editor]="editor"
                id="extraInfo"
                name="extraInfo">
            </ckeditor>
        </div>

        <div>
            <span
                class="form-error"
                *ngIf="contactPersonWentWrong"
                >{{ errorMessage }}</span
            >
        </div>
    </div>

    <div class="modal-footer">
        <button
            type="button"
            class="button button--outline"
            (click)="activeModal.close()">
            Close
        </button>
        <button
            *ngIf="!isUpdate"
            type="submit"
            [ngClass]="{ 'button--loading': loading }"
            class="button button--primary">
            Add contact
        </button>
        <button
            *ngIf="isUpdate"
            type="submit"
            [ngClass]="{ 'button--loading': loading }"
            class="button button--primary">
            Save changes
        </button>
    </div>
</form>
