<div class="login">
    <div class="login__holder">
        <app-session-header [header]="'Activated'"></app-session-header>

        <p class="m-b-4">You've succesfully activated your account. Now let's keep going!</p>

        <a
            routerLink="/login"
            class="button button--primary w-100"
            >Login</a
        >
    </div>
</div>
