<app-app-holder>
    <app-navbar></app-navbar>

    <app-sidebar>
        <app-settings-subnav></app-settings-subnav>
    </app-sidebar>

    <app-content>
        <div class="app__title">
            <h1>Account settings</h1>
        </div>

        <!-- List item-->
        <div
            class="list__item pointer"
            (click)="openUpdateUserModal()">
            <div class="list__item__icon">
                <i class="uil uil-cog color--grey"></i>
            </div>
            <div class="list__item__content">
                <strong>Change account settings</strong>
                <small>Emailaddress & information</small>
            </div>
        </div>

        <!-- List item-->
        <div
            class="list__item pointer"
            (click)="openManageTwoFactorAuth()">
            <div class="list__item__icon">
                <i class="las la-key color--grey"></i>
            </div>
            <div class="list__item__content">
                <strong>Manage two-factor authentication</strong>
                <small>Recommended</small>
            </div>
        </div>

        <!-- List item-->
        <div
            class="list__item pointer"
            (click)="openChangePasswordModal()">
            <div class="list__item__icon">
                <i class="uil uil-lock color--grey"></i>
            </div>
            <div class="list__item__content">
                <strong>Change password</strong>
                <small>Better safe than sorry</small>
            </div>
        </div>

        <div
            class="list__item pointer"
            (click)="logout()">
            <div class="list__item__icon color--grey">
                <i class="uil uil-sign-out-alt"></i>
            </div>
            <div class="list__item__content">
                <strong>Logout</strong>
                <small>See you soon!</small>
            </div>
        </div>
    </app-content>
</app-app-holder>
