<div class="sidebar-spacing">
    <h3>Mailchimp</h3>
</div>

<span class="divider divider--small"></span>

<nav class="app__subnav">
    <a
        [routerLink]="['/', 'mailchimp', 'campaigns']"
        routerLinkActive="app__subnav__active"
        >Campaigns</a
    >
    <a
        [routerLink]="['/', 'mailchimp', 'lists']"
        routerLinkActive="app__subnav__active"
        >Audience</a
    >
    <a
        [routerLink]="['/', 'mailchimp', 'templates']"
        routerLinkActive="app__subnav__active"
        >Templates</a
    >
    <a
        [routerLink]="['/', 'mailchimp', 'tags']"
        routerLinkActive="app__subnav__active"
        >Tags</a
    >
</nav>
