<div class="sidebar-spacing">
    <h3>Teamleader</h3>
    <small>list of available modules</small>
</div>

<span class="divider divider--small"></span>

<nav class="app__subnav">
    <a
        routerLink="/teamleader/overview"
        routerLinkActive="app__subnav__active"
        >Overview</a
    >
    <a
        routerLink="/teamleader/contacts"
        routerLinkActive="app__subnav__active"
        >Contacts</a
    >
    <a
        routerLink="/teamleader/companies"
        routerLinkActive="app__subnav__active"
        >Companies</a
    >
    <a
        routerLink="/teamleader/departments"
        routerLinkActive="app__subnav__active"
        >Departments</a
    >
    <a
        routerLink="/teamleader/employees"
        routerLinkActive="app__subnav__active"
        >Employees</a
    >
    <a
        routerLink="/teamleader/agenda"
        routerLinkActive="app__subnav__active"
        >Agenda</a
    >
    <a
        routerLink="/teamleader/deals"
        routerLinkActive="app__subnav__active"
        >Deals</a
    >
</nav>
