<form
    [formGroup]="userFormGroup"
    (ngSubmit)="updateUser()"
    class="modal-wrapper">
    <div class="modal-header">
        <h2
            class="modal-title"
            id="exampleModalLabel">
            Update userinfo
        </h2>
        <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="activeModal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <div class="row">
            <div class="col-6">
                <div class="form-group required">
                    <label>First name</label>
                    <input
                        type="text"
                        placeholder="First name"
                        formControlName="firstName"
                        name="firstName"
                        autocomplete="off"
                        (focus)="hideErrorMessage()"
                        trim="blur" />
                    <span
                        class="form-error"
                        *ngIf="
                            userFormGroup.controls.firstName.errors &&
                            inputIsWrong &&
                            (userFormGroup.controls.firstName.dirty || userFormGroup.controls.firstName.touched)
                        "
                        >Invalid first name</span
                    >
                </div>
            </div>
            <div class="col-6">
                <div class="form-group required">
                    <label>Last name</label>
                    <input
                        type="text"
                        placeholder="Last name"
                        formControlName="lastName"
                        name="lastName"
                        autocomplete="off"
                        (focus)="hideErrorMessage()"
                        trim="blur" />
                    <span
                        class="form-error"
                        *ngIf="
                            userFormGroup.controls.lastName.errors &&
                            inputIsWrong &&
                            (userFormGroup.controls.lastName.dirty || userFormGroup.controls.lastName.touched)
                        "
                        >Invalid last name</span
                    >
                </div>
            </div>
        </div>
        <div class="form-group m-b-2">
            <label>Emailaddress</label>
            <input
                disabled="disabled"
                type="email"
                placeholder="Emailaddress"
                formControlName="email"
                name="email"
                autocomplete="off"
                (focus)="hideErrorMessage()"
                trim="blur" />
            <span class="form-helptext">Changing your email will change your login</span>
            <span
                class="form-error"
                *ngIf="userFormGroup.controls.email.errors && inputIsWrong && (userFormGroup.controls.email.dirty || userFormGroup.controls.email.touched)"
                >This email is invalid</span
            >
        </div>
    </div>

    <div class="modal-footer">
        <button
            type="button"
            class="button button--outline"
            (click)="activeModal.close()">
            Close
        </button>
        <button
            type="submit"
            class="button button--primary">
            Update
        </button>
        <span
            class="form-error"
            *ngIf="updateUserWentWrong"
            >{{ errorMessage }}</span
        >
    </div>
</form>
