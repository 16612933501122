import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { NgbDropdownModule, NgbModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { MatTableExporterModule } from 'mat-table-exporter';
import { NgChartsModule } from 'ng2-charts';
import { TagInputModule } from 'ngx-chips';
import { MomentModule } from 'ngx-moment';
import { NgxTrimDirectiveModule } from 'ngx-trim-directive';

import { FinancialModule } from '../financial/financial.module';
import { SharedModule } from '../shared/shared.module';
import { EmployeesAddModalComponent } from './employees-add-modal/employees-add-modal.component';
import { EmployeesArchiveModalComponent } from './employees-archive-modal/employees-archive-modal.component';
import { EmployeesDeleteModalComponent } from './employees-delete-modal/employees-delete-modal.component';
import { EmployeesDetailModalComponent } from './employees-detail-modal/employees-detail-modal.component';
import { EmployeesDetailComponent } from './employees-detail/employees-detail.component';
import { EmployeesListComponent } from './employees-list/employees-list.component';
import { EmployeesOverviewComponent } from './employees-overview/employees-overview.component';
import { routing } from './employees.routing';

@NgModule({
    declarations: [
        EmployeesOverviewComponent,
        EmployeesListComponent,
        EmployeesDetailComponent,
        EmployeesDetailModalComponent,
        EmployeesDeleteModalComponent,
        EmployeesAddModalComponent,
        EmployeesArchiveModalComponent,
    ],
    imports: [
        CommonModule,
        SharedModule,
        routing,
        NgbModule,
        NgbDropdownModule,
        NgbNavModule,
        NgxTrimDirectiveModule,
        TagInputModule,
        CdkTableModule,
        MatTableModule,
        MatSortModule,
        MomentModule,
        MatTableExporterModule,
        NgChartsModule,
        FinancialModule,
    ],
})
export class EmployeesModule {}
