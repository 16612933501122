<span>
    <ng-template #popContent>
        <span *ngIf="tag.taggables?.length">
            <span
                class="tag m-r-1 m-b-1"
                *ngFor="let taggable of tag.taggables">
                <app-pill-applicant
                    *ngIf="taggable.type === 'applicant'"
                    [applicantUuid]="taggable.uuid"
                    [title]="taggable.title"></app-pill-applicant>
                <app-pill-contact
                    *ngIf="taggable.type === 'contactperson'"
                    [contactPersonUuid]="taggable.uuid"
                    [title]="taggable.title"></app-pill-contact>
                <app-pill-employee
                    *ngIf="taggable.type === 'employee'"
                    [employeeId]="taggable.uuid"></app-pill-employee>
                <app-pill-project
                    *ngIf="taggable.type === 'project'"
                    [projectUuid]="taggable.uuid"
                    [title]="taggable.title"></app-pill-project>
                <app-pill-product
                    *ngIf="taggable.type === 'product'"
                    [productUuid]="taggable.uuid"
                    [productTitle]="taggable.title"></app-pill-product>
                <app-pill-partner
                    *ngIf="taggable.type === 'partner'"
                    [partnerUuid]="taggable.uuid"
                    [title]="taggable.title"></app-pill-partner>
                <app-pill-lead
                    *ngIf="taggable.type === 'lead'"
                    [leadUuid]="taggable.uuid"
                    [title]="taggable.title"></app-pill-lead>
                <app-pill-crmdeal
                    *ngIf="taggable.type === 'crmdeal'"
                    [dealUuid]="taggable.uuid"
                    [title]="taggable.title"></app-pill-crmdeal>
                <app-pill-warehouse
                    *ngIf="taggable.type === 'warehouse'"
                    [warehouseUuid]="taggable.uuid"></app-pill-warehouse>
                <app-pill-warehouse-location
                    *ngIf="taggable.type === 'warehouselocation'"
                    [warehouseLocationUuid]="taggable.uuid"
                    [title]="taggable.title"></app-pill-warehouse-location>
                <span *ngIf="taggable.type === 'resume'">
                    <!-- TODO create resume pill? -->
                    {{ taggable.title }}
                </span>
            </span>
        </span>
        <small *ngIf="!tag.taggables?.length > 0">No linked objects</small>
    </ng-template>
    <span
        class="button button--small button--icon m-r-2"
        [ngbPopover]="popContent"
        popoverTitle="Tags"
        ><i class="las la-tag m-r-1"></i>
        <p class="m-r-1">{{ tag.title }} {{ getLength(tag) }}</p>
    </span>
</span>
