import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { Product } from 'src/app/models/Product';
import { Unit } from 'src/app/models/Unit';
import { WarehouseLocation } from 'src/app/models/WarehouseLocation';
import { FetchUnits } from 'src/app/units/unit.actions';
import { UnitState } from 'src/app/units/unit.state';
import { FetchWarehouseLocations } from 'src/app/warehouse-locations/warehouse.location.actions';
import { WarehouseLocationState } from 'src/app/warehouse-locations/warehouse.location.state';

import { UpdateProduct } from '../product.actions';

@Component({
    selector: 'app-product-update-modal',
    templateUrl: './product-update-modal.component.html',
    styleUrls: ['./product-update-modal.component.scss'],
})
export class ProductUpdateModalComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Select(WarehouseLocationState.WarehouseLocations)
    allWarehouseLocations$: Observable<WarehouseLocation[]>;
    @Select(UnitState.Units) allUnits$: Observable<Unit[]>;
    @Input() product: Product;
    newProduct: Product;
    productFormGroup: FormGroup;
    errorMessage: string;
    loading = false;
    updateProductWentWrong = false;
    DESCRIPTION_MAX_LENGTH = 1000;
    currentAmountOfDescriptionCharacters: number;
    descriptionIsTooLong = false;
    allWarehouseLocations: WarehouseLocation[];
    allUnits: Unit[];
    keyword = 'title';
    unit: Unit;
    warehouseLocation: WarehouseLocation;
    inputIsWrong = false;

    @ViewChild('focussed', { static: false })
    set input(element: ElementRef<HTMLInputElement>) {
        if (element) {
            element.nativeElement.focus();
        }
    }

    constructor(
        public activeModal: NgbActiveModal,
        private formBuilder: FormBuilder,
        private store: Store,
        private toastr: ToastrService,
        private logger: NGXLogger,
    ) {}

    ngOnInit(): void {
        this.logger.debug('Fetching WarehouseLocations');
        this.store.dispatch(new FetchWarehouseLocations());
        this.store.dispatch(new FetchUnits());
        this.subscriptions.add(
            this.allUnits$.subscribe((units) => {
                if (units) {
                    this.allUnits = units;
                    this.unit = units.find((u) => u.uuid === this.product.unit?.uuid);
                }
            }),
        );
        this.subscriptions.add(
            this.allWarehouseLocations$.subscribe((warehouseLocations) => {
                if (warehouseLocations) {
                    this.allWarehouseLocations = warehouseLocations;
                    this.warehouseLocation = warehouseLocations.find((w) => w.uuid === this.product.warehouseLocationUuid);
                }
            }),
        );

        this.currentAmountOfDescriptionCharacters = this.product.description?.length;
        this.productFormGroup = this.formBuilder.group({
            title: [this.product.title, [Validators.required]],
            description: [this.product.description],
            ean: [this.product.ean ? this.product.ean : ''],
            sku: [this.product.sku ? this.product.sku : ''],
            purchasePrice: [this.product.purchasePrice ? this.product.purchasePrice : ''],
            sellingPrice: [this.product.sellingPrice ? this.product.sellingPrice : ''],
            inStock: [this.product.inStock ? this.product.inStock : ''],
            stockNotification: [this.product.stockNotification ? this.product.stockNotification : ''],
            unit: [this.unit ? this.unit : ''],
            warehouseLocation: [this.warehouseLocation ? this.warehouseLocation : ''],
            productConsumable: [this.product.productConsumable ? this.product.productConsumable : false],
            productCategories: [this.product.productCategories ? this.product.productCategories : null],
        });
    }

    onDescriptionKeyUp() {
        this.descriptionIsTooLong = false;
        this.currentAmountOfDescriptionCharacters = this.productFormGroup.controls.description.value.length;
        if (this.currentAmountOfDescriptionCharacters > this.DESCRIPTION_MAX_LENGTH) {
            this.descriptionIsTooLong = true;
        }
    }

    updateProduct() {
        this.loading = true;

        if (this.descriptionIsTooLong) {
            return;
        }

        if (!this.productFormGroup.valid) {
            this.inputIsWrong = true;
            this.loading = false;
            return;
        }

        this.newProduct = this.productFormGroup.value;
        this.newProduct.uuid = this.product.uuid;
        this.newProduct.unitUuid = this.productFormGroup.value.unit.uuid;
        this.newProduct.warehouseLocationUuid = this.productFormGroup.value.warehouseLocation.uuid;

        this.logger.debug('Attempt to update product');
        this.subscriptions.add(
            this.store.dispatch(new UpdateProduct(this.newProduct)).subscribe(
                () => {
                    this.logger.debug('Succesfully updated product');
                    this.toastr.success('Product updated succesfully');
                    this.activeModal.close();
                },
                (error) => {
                    this.logger.error('Failed to update product');
                    this.errorMessage = error?.error?.message;
                    this.loading = false;
                    this.updateProductWentWrong = true;
                },
            ),
        );
    }

    hideErrorMessage() {
        this.inputIsWrong = false;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
