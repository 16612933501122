import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';

import { Partner } from '../../models/Partner';
import { VAT } from '../../models/VAT';
import YukiFolder from '../../models/yuki/YukiFolder';
import { PartnerAddModalComponent } from '../../partners/partner-add-modal/partner-add-modal.component';
import { FetchPartners } from '../../partners/partners.actions';
import { PartnerState } from '../../partners/partners.state';
import { roundNumber } from '../../shared/utils/number-utils';
import { CreateNewInvoice, FetchFolders } from '../yuki.actions';
import { YukiState } from '../yuki.state';

@Component({
    selector: 'app-create-new-invoice-modal',
    templateUrl: './create-new-invoice-modal.component.html',
    styleUrls: ['./create-new-invoice-modal.component.scss'],
})
export class CreateNewInvoiceModalComponent implements OnInit, OnDestroy {
    VATs: VAT[] = [];

    @Input() currentFolderId: number;
    @Select(YukiState.folders) folders$: Observable<YukiFolder[]>;
    folders: YukiFolder[];

    @Select(PartnerState.getPartners) partners$: Observable<Partner[]>;
    partners: Partner[];

    invoiceForm: FormGroup;

    private subscriptions = new Subscription();

    constructor(
        private store: Store,
        private toastr: ToastrService,
        private logger: NGXLogger,
        public activeModal: NgbActiveModal,
        private fb: FormBuilder,
        private modalService: NgbModal,
    ) {}

    ngOnInit(): void {
        this.createInvoiceForm();
        this.fetchData();
        this.seedVATs();
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    openCreateContactModal() {
        this.modalService.open(PartnerAddModalComponent, { windowClass: 'modal-pane', animation: false });
    }

    calculateAmounts(): boolean {
        const vat = (100 + this.invoiceForm.value.vat.percent) / 100 ?? 1.21;
        if (this.invoiceForm.value.amount && !this.invoiceForm.value.vatAmount && !this.invoiceForm.value.amountExcl) {
            const amountExcl = this.invoiceForm.value.amount / vat;
            const vatAmount = this.invoiceForm.value.amount - amountExcl;

            this.invoiceForm.get('vatAmount').setValue(`${vatAmount.toFixed(2)}`);
            this.invoiceForm.get('amountExcl').setValue(`${amountExcl.toFixed(2)}`);
            return true;
        } else if (!this.invoiceForm.value.amount && this.invoiceForm.value.vatAmount && !this.invoiceForm.value.amountExcl) {
            const amount = (vat / (vat - 1)) * this.invoiceForm.value.vatAmount;
            const amountExcl = amount - this.invoiceForm.value.vatAmount;

            this.invoiceForm.get('amount').setValue(`${amount.toFixed(2)}`);
            this.invoiceForm.get('amountExcl').setValue(`${amountExcl.toFixed(2)}`);
            return true;
        } else if (!this.invoiceForm.value.amount && !this.invoiceForm.value.vatAmount && this.invoiceForm.value.amountExcl) {
            const amount = vat * this.invoiceForm.value.amountExcl;
            const vatAmount = amount - this.invoiceForm.value.amountExcl;

            this.invoiceForm.get('amount').setValue(`${amount.toFixed(2)}`);
            this.invoiceForm.get('vatAmount').setValue(`${vatAmount.toFixed(2)}`);
            return true;
        }
        return false;
    }

    calculateVatAmount(): void {
        if (!this.invoiceForm.value.amount && !this.invoiceForm.value.vatAmount && !this.invoiceForm.value.amountExcl) return;

        this.calculateAmounts();

        const vatAmount: number = this.invoiceForm.value.amount - this.invoiceForm.value.amountExcl;
        this.invoiceForm.get('vatAmount').setValue(`${vatAmount.toFixed(2)}`);

        this.calculateVat();
    }

    calculateAmountExcl() {
        if (!this.invoiceForm.value.amount && !this.invoiceForm.value.vatAmount && !this.invoiceForm.value.amountExcl) return;

        this.calculateAmounts();

        const amountExcl: number = this.invoiceForm.value.amount - this.invoiceForm.value.vatAmount;
        this.invoiceForm.get('amountExcl').setValue(`${amountExcl.toFixed(2)}`);

        this.calculateVat();
    }

    updateVATAmount() {
        if (this.calculateAmounts()) return;
        if (!this.invoiceForm.value.amount && !this.invoiceForm.value.vatAmount && !this.invoiceForm.value.amountExcl) return;
        if (this.invoiceForm.value.vat === VAT.OTHER) return;

        const amount: number = this.invoiceForm.value.amount;
        const percent: number = this.invoiceForm.value.vat.percent;
        const vat: number = amount - (amount * 100) / (percent + 100);
        const amountExcl: number = amount - vat;
        this.invoiceForm.get('vatAmount').setValue(`${vat.toFixed(2)}`);
        this.invoiceForm.get('amountExcl').setValue(`${amountExcl.toFixed(2)}`);
    }

    checkAmountError(): boolean {
        const amount: number = parseFloat(this.invoiceForm.value.amount);
        const amountExcl: number = parseFloat(this.invoiceForm.value.amountExcl);
        const vatAmount: number = parseFloat(this.invoiceForm.value.vatAmount);
        return (vatAmount + amountExcl).toFixed(2) !== amount.toFixed(2);
    }

    saveInvoice() {
        this.logger.debug('Saving new invoice');
        this.subscriptions.add(
            this.store.dispatch(new CreateNewInvoice(new InvoiceDTO(this.invoiceForm.value))).subscribe({
                next: () => {
                    this.toastr.success('New invoice saved successfully');
                    this.activeModal.close();
                },
                error: () => {
                    this.logger.debug('Failed to save new invoice...');
                    this.toastr.error('Oops, something went wrong, Please try again later...');
                },
            }),
        );
    }

    private seedVATs() {
        this.VATs = Object.values(VAT);
    }

    private fetchData() {
        this.subscriptions.add(
            this.folders$.subscribe((folders) => {
                if (folders === null) {
                    this.logger.debug('Fetching Yuki folders');
                    this.store.dispatch(new FetchFolders());
                    return;
                }
                this.folders = folders;
                this.invoiceForm.controls['folder'].setValue(this.folders.find((folder) => folder.id === this.currentFolderId));
            }),
        );

        this.subscriptions.add(
            this.partners$.subscribe((partners) => {
                if (partners === null) {
                    this.logger.debug('Fetching partners');
                    this.store.dispatch(new FetchPartners());
                    return;
                }
                this.partners = partners;
            }),
        );
    }

    private createInvoiceForm() {
        this.invoiceForm = this.fb.group({
            vat: [VAT.TWENTY_ONE, Validators.required],
            vatAmount: ['', Validators.required],
            amount: ['', Validators.required],
            amountExcl: ['', Validators.required],
            subject: ['', Validators.required],
            description: '',
            invoiceNumber: '',
            linkedContact: ['', Validators.required],
            invoiceUrl: '',
            folder: '',
            documentDate: ['', Validators.required],
        });
    }

    private calculateVat() {
        const percentage = roundNumber(this.invoiceForm.value.vatAmount / this.invoiceForm.value.amountExcl);

        if (VAT.ZERO.percent - 0.2 < percentage * 100 && VAT.ZERO.percent + 0.2 > percentage * 100) this.invoiceForm.get('vat').setValue(VAT.ZERO);
        else if (VAT.SIX.percent - 0.2 < percentage * 100 && VAT.SIX.percent + 0.2 > percentage * 100) this.invoiceForm.get('vat').setValue(VAT.SIX);
        else if (VAT.TWELVE.percent - 0.2 < percentage * 100 && VAT.TWELVE.percent + 0.2 > percentage * 100) this.invoiceForm.get('vat').setValue(VAT.TWELVE);
        else if (VAT.TWENTY_ONE.percent - 0.2 < percentage * 100 && VAT.TWENTY_ONE.percent + 0.2 > percentage * 100)
            this.invoiceForm.get('vat').setValue(VAT.TWENTY_ONE);
        else this.invoiceForm.get('vat').setValue(VAT.OTHER);
    }
}

export class InvoiceDTO {
    vatPercent: string;
    vatAmount: number;
    amount: number;
    amountExcl: number;
    subject: string;
    description: string;
    invoiceNumber: string;
    linkedContactId: string;
    invoiceUrl: string;
    folderId: number;
    documentDate: string;

    constructor(formValues: any) {
        this.vatPercent = formValues.vat.value;
        this.vatAmount = formValues.vatAmount;
        this.amount = formValues.amount;
        this.amountExcl = formValues.amountExcl;
        this.subject = formValues.subject;
        this.description = formValues.description;
        this.invoiceNumber = formValues.invoiceNumber;
        this.linkedContactId = formValues.linkedContact.uuid;
        this.invoiceUrl = formValues.invoiceUrl;
        this.folderId = formValues.folder.id;
        this.documentDate = formValues.documentDate;
    }
}
