<form
    (ngSubmit)="addDefaultLinkedEmployee()"
    [formGroup]="defaultLinkedEmployeeForm"
    class="modal-wrapper">
    <div class="modal-header">
        <h2
            class="modal-title"
            id="exampleModalLabel">
            Add default price for employees
        </h2>
        <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="activeModal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col table__overflow">
                <table class="table-view">
                    <tr>
                        <th
                            class="td-small pointer"
                            (click)="checkAll()">
                            <input
                                class="pointer"
                                type="checkbox"
                                [checked]="allCheck" />
                        </th>
                        <th>Employee</th>
                    </tr>
                    <tr
                        *ngFor="let employee of employees"
                        class="pointer"
                        (click)="check(employee)">
                        <td>
                            <input
                                type="checkbox"
                                [checked]="isChecked(employee)" />
                        </td>
                        <td>
                            <span>{{ employee.firstName }} {{ employee.lastName }}</span>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="row">
            <div class="form-group m-b-2 required">
                <label for="pricePerHour">Price per hour</label>
                <small
                    class="form-error"
                    *ngIf="defaultLinkedEmployeeForm.get('pricePerHour').invalid && defaultLinkedEmployeeForm.get('pricePerHour').touched">
                    Price per hour is required...
                </small>
                <input
                    type="number"
                    id="pricePerHour"
                    formControlName="pricePerHour" />
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <input
            class="button"
            [ngClass]="defaultLinkedEmployeeForm.invalid ? 'button--disabled' : 'button--primary'"
            type="submit"
            value="Add"
            [disabled]="defaultLinkedEmployeeForm.invalid" />
        <button
            class="button button--outline"
            (click)="activeModal.dismiss()">
            Cancel
        </button>
    </div>
</form>
