<div class="modal-header">
    <h2 class="modal-title">Add new timesheet</h2>
    <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<app-loading-message *ngIf="isLoading"></app-loading-message>

<div
    class="modal-body"
    *ngIf="!isLoading && availableEmployees.length > 0">
    <form
        (ngSubmit)="addTimesheet()"
        class="p-b-2">
        <label for="hoursRequired">Required hours</label>
        <input
            id="hoursRequired"
            type="number"
            min="0"
            max="744"
            [(ngModel)]="hoursRequired"
            name="hoursRequired" />
        <span class="form-helptext">This will be added to all timesheets but can be edited individually later.</span>
    </form>

    <table class="table-view">
        <tr>
            <th class="td-small">
                <input
                    type="checkbox"
                    (change)="checkAll()"
                    [checked]="allCheck" />
            </th>
            <th>Employee</th>
        </tr>
        <tr
            *ngFor="let employee of availableEmployees"
            class="pointer"
            (click)="check(employee)">
            <td>
                <input
                    type="checkbox"
                    [checked]="isChecked(employee)" />
            </td>
            <td>
                <span *ngIf="employee.firstName">{{ employee.firstName }} {{ employee.lastName }}</span>
            </td>
        </tr>
    </table>
</div>

<div
    *ngIf="!isLoading && availableEmployees.length <= 0"
    class="modal-body">
    <p>all employees are already linked to a timesheet</p>
</div>

<div class="modal-footer">
    <button
        [ngClass]="checkedEmployees.length == 0 ? 'button button--disabled w-100' : 'button button--primary w-100'"
        (click)="addTimesheet()"
        [disabled]="checkedEmployees.length == 0">
        Save
    </button>
</div>
