import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subscription } from 'rxjs';
import { Applicant } from 'src/app/models/Applicants/Applicant';
import { ConfirmationModalComponent } from 'src/app/shared/confirmation-modal/confirmation-modal.component';

import { AddApplicantModalComponent } from '../add-applicant-modal/add-applicant-modal.component';
import { ArchiveApplicant } from '../applicant.actions';
import { ApplicantState } from '../applicant.state';

@Component({
    selector: 'app-applicant-list',
    templateUrl: './applicant-list.component.html',
    styleUrls: ['./applicant-list.component.scss'],
})
export class ApplicantListComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Select(ApplicantState.Applicants) applicants$: Observable<Applicant[]>;

    filter: string;
    currentDate: Date;

    displayedColumns: string[] = ['email', 'createdOn', 'lastMailSent', 'status', 'edit'];
    dataSource: MatTableDataSource<Applicant>;
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    constructor(private modalService: NgbModal, public activeModal: NgbActiveModal, private router: Router, private store: Store, private logger: NGXLogger) {}

    ngOnInit(): void {
        this.currentDate = new Date();
        this.logger.debug('Fetching applicants from state');
        this.subscriptions.add(
            this.applicants$.subscribe((applicants) => {
                /* setup datasource */
                this.dataSource = new MatTableDataSource(applicants);
                this.dataSource.sort = this.sort;

                /* sortingDataAccessor to sort on nested objs */
                this.dataSource.sortingDataAccessor = (item, property) => {
                    if (property.includes('.')) return property.split('.').reduce((o, i) => o[i], item);
                    return item[property];
                };
            }),
        );
    }

    applyFilter(filterValue) {
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }

    openAddApplicantModal() {
        this.modalService.open(AddApplicantModalComponent, {
            windowClass: 'modal-pane',
            animation: false,
        });
    }

    editApplicant(applicant: Applicant) {
        const modalRef = this.modalService.open(AddApplicantModalComponent, {
            windowClass: 'modal-pane',
            animation: false,
        });
        modalRef.componentInstance.applicant = applicant;
    }

    archiveApplicant(applicant: Applicant) {
        const modalRef = this.modalService.open(ConfirmationModalComponent, {
            windowClass: 'modal-prompt',
        });
        modalRef.componentInstance.type = 'YesOrNo';
        modalRef.componentInstance.title = 'Archive applicant';
        modalRef.componentInstance.htmlMessage = `<p>Are you sure you want to archive <strong>${applicant.firstName.trim()} ${applicant.lastName.trim()}</strong>?</p>`;
        this.subscriptions.add(
            modalRef.componentInstance.closeEvent.subscribe((result) => {
                if (result) {
                    this.store.dispatch(new ArchiveApplicant(applicant.uuid));
                    this.activeModal.close();
                }
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
