<a
    class="pill pill--partner"
    *ngIf="isLoaded"
    (click)="openPartnerDetailsModal(); $event.stopPropagation()">
    <i class="uil uil-building"></i>
    {{ getTitle() }}
</a>

<a
    class="pill pill--partner"
    *ngIf="!isLoaded">
    <app-loading-message></app-loading-message>
</a>
