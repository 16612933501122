import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs/internal/Subscription';
import { ContactsAddModalComponent } from 'src/app/contacts/contacts-add-modal/contacts-add-modal.component';
import { AddCustomerModalComponent } from 'src/app/customers/add-customer-modal/add-customer-modal.component';
import { EmployeesAddModalComponent } from 'src/app/employees/employees-add-modal/employees-add-modal.component';
import { ContactPerson } from 'src/app/models/ContactPerson';
import Customer from 'src/app/models/Customer';
import { Employee } from 'src/app/models/Employee';
import { PartnerAddModalComponent } from 'src/app/partners/partner-add-modal/partner-add-modal.component';
import { ProjectDetailModalComponent } from 'src/app/projects/project-detail-modal/project-detail-modal.component';
import { UnitAddModalComponent } from 'src/app/units/unit-add-modal/unit-add-modal.component';
import { WarehouseLocationsAddModalComponent } from 'src/app/warehouse-locations/warehouse-locations-add-modal/warehouse-locations-add-modal.component';
import { WarehouseAddModalComponent } from 'src/app/warehouses/warehouse-add-modal/warehouse-add-modal.component';

@Component({
    selector: 'app-global-autocomplete',
    templateUrl: './global-autocomplete.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => GlobalAutocompleteComponent),
            multi: true,
        },
    ],
    styleUrls: ['./global-autocomplete.component.scss'],
})
export class GlobalAutocompleteComponent implements OnInit, ControlValueAccessor, OnDestroy, OnChanges {
    private subscriptions = new Subscription();
    @Input() formControlName;
    @Input() data;
    @Input() addItemFunction = undefined;
    @Input() itemType: string = null;
    @Input() parentForm;
    @Input() label: string;
    @Input() notFoundMessage = 'No item was found matching this filter.';
    @Input() keyword: string;
    @Input() placeholder = '';
    @Input() dataLoaded = true;
    @Input() active = true;
    @Input() required = false;
    @Input() canAdd = true;

    @Input() prefilledInformation: any;

    constructor(private logger: NGXLogger, private modalService: NgbModal) {}
    writeValue(obj: any): void {}
    registerOnChange(fn: any): void {}
    registerOnTouched(fn: any): void {}
    setDisabledState?(isDisabled: boolean): void {}

    ngOnInit(): void {
        switch (this.itemType) {
            case 'project':
                this.addItemFunction = (args: any): void => {
                    this.logger.debug('opening addProjectModal');
                    const modalRef = this.modalService.open(ProjectDetailModalComponent, { windowClass: 'modal-pane', animation: false });
                    this.subscriptions.add(
                        modalRef.componentInstance.emitObject.subscribe((project) => {
                            this.parentForm.get('project').setValue(project);
                        }),
                    );
                };
                break;
            case 'company':
                this.addItemFunction = (args: any): void => {
                    this.logger.debug('opening addPartnerModal');
                    const modalRef = this.modalService.open(PartnerAddModalComponent, { windowClass: 'modal-pane', animation: false });
                    this.subscriptions.add(
                        modalRef.componentInstance.emitObject.subscribe((partner) => {
                            this.parentForm.get('company').setValue(partner);
                        }),
                    );
                };
                break;
            case 'contact':
                this.addItemFunction = (args: any): void => {
                    this.logger.debug('opening addContactModal');
                    const modalRef = this.modalService.open(ContactsAddModalComponent, { windowClass: 'modal-pane', animation: false });
                    this.prefilledInformation.partner ? (modalRef.componentInstance.prefilledPartner = this.prefilledInformation.partner) : undefined;
                    this.subscriptions.add(
                        modalRef.componentInstance.emitObject.subscribe((contact) => {
                            this.parentForm.get('objectToLink')?.setValue(contact);
                            this.parentForm.get('contact')?.setValue(contact);
                            this.parentForm.get('contactPerson')?.setValue(contact);
                        }),
                    );
                };
                break;
            case 'customer':
                this.addItemFunction = (args: any): void => {
                    this.logger.debug('opening addCustomerModal');
                    const modalRef = this.modalService.open(AddCustomerModalComponent, { windowClass: 'modal-pane', animation: false });
                    this.subscriptions.add(
                        modalRef.componentInstance.emitObject.subscribe((customer) => {
                            this.parentForm.get('customer').setValue(customer);
                        }),
                    );
                };
                break;
            case 'unit':
                this.addItemFunction = (args: any): void => {
                    this.logger.debug('Opening unitAddModal');
                    const modalRef = this.modalService.open(UnitAddModalComponent, { windowClass: 'modal-prompt' });
                    this.subscriptions.add(
                        modalRef.componentInstance.emitObject.subscribe((unit) => {
                            this.parentForm.get('unit').setValue(unit);
                        }),
                    );
                };
                break;
            case 'warehouseLocation':
                this.addItemFunction = (args: any): void => {
                    this.logger.debug('opening warehouseLocationAddModal');
                    const modalRef = this.modalService.open(WarehouseLocationsAddModalComponent, { windowClass: 'modal-pane', animation: false });
                    this.subscriptions.add(
                        modalRef.componentInstance.emitObject.subscribe((warehouseLocation) => {
                            this.parentForm.get('warehouseLocation').setValue(warehouseLocation);
                        }),
                    );
                };
                break;
            case 'warehouse':
                this.addItemFunction = (args: any): void => {
                    this.logger.debug('opening addWarehouseModal');
                    const modalRef = this.modalService.open(WarehouseAddModalComponent, { windowClass: 'modal-pane', animation: false });
                    this.subscriptions.add(
                        modalRef.componentInstance.emitObject.subscribe((warehouse) => {
                            this.parentForm.get('warehouse').setValue(warehouse);
                        }),
                    );
                };
                break;
            case 'employee':
                this.addItemFunction = (args: any): void => {
                    this.logger.debug('opening addEmployeeModal');
                    const modalRef = this.modalService.open(EmployeesAddModalComponent, { windowClass: 'modal-pane', animation: false });
                    this.subscriptions.add(
                        modalRef.componentInstance.emitObject.subscribe((employee) => {
                            if (this.parentForm.get('employee') == undefined) {
                                this.parentForm.get('objectToLink').setValue(employee);
                            } else {
                                this.parentForm.get('employee').setValue(employee);
                            }
                        }),
                    );
                };
                break;
        }
        if (!this.canAdd) {
            this.addItemFunction = undefined;
        }
    }

    mapDataSearchForSearch() {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.data !== undefined && changes.data.currentValue !== changes.data.previousValue && this.data !== undefined) {
            switch (this.itemType) {
                case 'contact':
                    if (this.data[0] && !this.data[0].firstName.includes(this.data[0].lastName)) {
                        this.data = JSON.parse(JSON.stringify(this.data));
                        this.data.map((contact: ContactPerson) => (contact.firstName = `${contact.firstName} ${contact.lastName}`));
                    }
                    break;
                case 'customer':
                    if (this.data[0] && !this.data[0].firstName.includes(this.data[0].lastName)) {
                        this.data = JSON.parse(JSON.stringify(this.data));
                        this.data.map((customer: Customer) => (customer.firstName = `${customer.firstName} ${customer.lastName}`));
                    }
                    break;
                case 'employee':
                    if (this.data[0] && !this.data[0].firstName.includes(this.data[0].lastName)) {
                        this.data = JSON.parse(JSON.stringify(this.data));
                        this.data.map((employee: Employee) => (employee.firstName = `${employee.firstName} ${employee.lastName}`));
                    }
                    break;
            }
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
