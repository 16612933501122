<app-app-holder>
    <app-navbar></app-navbar>

    <app-sidebar>
        <app-settings-subnav></app-settings-subnav>
    </app-sidebar>

    <app-content *ngIf="company$ | async as company">
        <div class="app__title m-t-4">
            <h1>{{ company.title }}</h1>

            <div>
                <div
                    ngbDropdown
                    class="dropdown">
                    <button
                        ngbDropdownToggle
                        class="button button--icon button--small dropdown-toggle"
                        id="dropdownMenuButton">
                        <span>Actions</span>
                    </button>
                    <div
                        ngbDropdownMenu
                        class="dropdown-menu dropdown-menu-right"
                        aria-labelledby="dropdownMenuButton">
                        <a
                            class="dropdown-item"
                            (click)="openAddMemberModal()"
                            >Invite member</a
                        >
                        <!-- <a *ngIf="isOwner | async" class="dropdown-item" (click)="openUpdateModal()">Edit company</a> -->
                        <a
                            class="dropdown-item"
                            (click)="openUpdateModal()"
                            >Edit company</a
                        >
                        <a
                            *ngIf="member?.role == eRole.ROLE_OWNER"
                            class="dropdown-item color--red"
                            (click)="openDeleteModal()"
                            >Archive company</a
                        >
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <span class="sectiontitle">Company settings</span>
                <ul class="list__specs">
                    <li><label>Company</label> {{ company.title }}</li>
                    <li><label>VAT</label> {{ company.vat }}</li>
                </ul>

                <span class="sectiontitle">Contact settings</span>
                <ul class="list__specs">
                    <li *ngIf="company.address && company.address.street">
                        <label>Address</label>
                        <app-pill-address [address]="company.address"></app-pill-address>
                    </li>
                    <li><label>Phonenumber</label><app-pill-telephone [contactPhonenumber]="company.contactInformation.phoneNumber"></app-pill-telephone></li>
                    <li><label>Emailaddress</label><app-pill-mailto [mailtoEmail]="company.contactInformation.email"></app-pill-mailto></li>
                    <li *ngIf="company.contactInformation.website">
                        <label>Website</label><app-pill-url [websiteUrl]="company.contactInformation.website"></app-pill-url>
                    </li>
                </ul>
            </div>
        </div>
    </app-content>
</app-app-holder>
