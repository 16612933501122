<div
    class="table__overflow"
    *ngIf="project; else loading">
    <table
        mat-table
        [dataSource]="project.defaultLinkedEmployees"
        matSort>
        <ng-container matColumnDef="employees">
            <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header>
                Employees
            </th>
            <td
                mat-cell
                *matCellDef="let linkedEmployee">
                <app-pill-employee [employeeId]="linkedEmployee.employeeId"></app-pill-employee>
            </td>
        </ng-container>

        <ng-container matColumnDef="price per hour">
            <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header>
                Price/Hour
            </th>
            <td
                mat-cell
                *matCellDef="let linkedEmployee">
                {{ linkedEmployee.pricePerHour }}
            </td>
        </ng-container>

        <ng-container matColumnDef="edit">
            <th
                mat-header-cell
                *matHeaderCellDef
                class="td-small"></th>
            <td
                mat-cell
                *matCellDef="let linkedEmployee"
                class="td-small">
                <a (click)="deleteDefaultEmployee(linkedEmployee)">
                    <p style="color: red; cursor: pointer">
                        <i class="las la-trash"></i>
                    </p>
                </a>
            </td>
        </ng-container>

        <ng-container matColumnDef="add">
            <td
                mat-footer-cell
                *matFooterCellDef
                colspan="3"
                class="table-view__add"
                (click)="openAddDefaultEmployee()">
                <i class="las la-plus"></i> New employee
            </td>
        </ng-container>

        <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns"></tr>

        <tr
            cdkDrag
            mat-row
            *matRowDef="let row; columns: displayedColumns"></tr>

        <tr
            mat-footer-row
            *matFooterRowDef="['add']"></tr>
    </table>
</div>

<ng-template #loading>
    <app-loading-message></app-loading-message>
</ng-template>
