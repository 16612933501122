import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '../authentication/auth.guard';
import { CompanyGuard } from '../companies/company.guard';
import { ProductStockHistoryComponent } from './product-stock-history/product-stock-history.component';
import { ProductsOverviewComponent } from './products-overview/products-overview.component';

export const routes: Routes = [
    {
        path: 'inventory',
        redirectTo: 'inventory/stock-history',
        pathMatch: 'full',
    },
    {
        path: 'inventory/products',
        component: ProductsOverviewComponent,
        canActivate: [AuthGuard, CompanyGuard],
    },
    {
        path: 'inventory/stock-history',
        component: ProductStockHistoryComponent,
        canActivate: [AuthGuard, CompanyGuard],
    },
];

export const routing: ModuleWithProviders<any> = RouterModule.forChild(routes);
