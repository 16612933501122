import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';

import { AddRelation, UpdateRelation } from '../exact.actions';

@Component({
    selector: 'app-exact-edit-relation-modal',
    templateUrl: './exact-edit-relation-modal.component.html',
    styleUrls: ['./exact-edit-relation-modal.component.scss'],
})
export class ExactEditRelationModalComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Input() relation: any;

    relationFormGroup: FormGroup;

    constructor(public activeModal: NgbActiveModal, private formBuilder: FormBuilder, private store: Store, private toastr: ToastrService) {}

    ngOnInit(): void {
        if (this.relation) {
            this.relationFormGroup = this.formBuilder.group({
                Name: [this.relation.Name, [Validators.required, Validators.minLength(1), Validators.maxLength(50)]],
                AddressLine1: [this.relation.AddressLine1],
                Postcode: [this.relation.Postcode],
                City: [this.relation.City],
                State: [this.relation.State],
                Country: [this.relation.Country],
                Phone: [this.relation.Phone],
                Email: [this.relation.Email, [Validators.email]],
                Website: [this.relation.Website],
                Type: ['IsSales'],
            });
        } else {
            this.relationFormGroup = this.formBuilder.group({
                Name: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(50)]],
                AddressLine1: [''],
                Postcode: [''],
                City: [''],
                State: [''],
                Country: ['BE', [Validators.minLength(2), Validators.maxLength(2)]],
                Phone: [''],
                Email: ['', [Validators.email]],
                Website: [''],
                Type: ['IsSales'],
            });
        }
    }

    submit() {
        if (!this.relation) {
            this.subscriptions.add(
                this.store.dispatch(new AddRelation(this.relationFormGroup.value)).subscribe(
                    () => {
                        this.toastr.success('Successfully added new relation.');
                    },
                    (err) => {
                        this.toastr.error('Oops something went wrong.');
                    },
                ),
            );
        } else {
            this.relation.Name = this.relationFormGroup.value.Name;
            this.relation.AddressLine1 = this.relationFormGroup.value.AddressLine1;
            this.relation.Postcode = this.relationFormGroup.value.Postcode;
            this.relation.City = this.relationFormGroup.value.City;
            this.relation.State = this.relationFormGroup.value.State;
            this.relation.Country = this.relationFormGroup.value.Country;
            this.relation.Phone = this.relationFormGroup.value.Phone;
            this.relation.Email = this.relationFormGroup.value.Email;
            this.relation.Website = this.relationFormGroup.value.Website;
            this.subscriptions.add(
                this.store.dispatch(new UpdateRelation(this.relation)).subscribe(
                    () => {
                        this.toastr.success('Successfully updated relation.');
                    },
                    (err) => {
                        this.toastr.error('Oops something went wrong.');
                    },
                ),
            );
        }
        this.activeModal.close();
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
