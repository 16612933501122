import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { append, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { tap } from 'rxjs/operators';

import Lead from '../models/Lead';
import { AddUndefinedObjectTagsToObject } from '../shared/global-tags/global-tags.actions';
import { AddLead, ArchiveLead, GetArchivedLeads, UpdateArchivedLead, UpdateLead, UpdateReadOfLead, fetchLeads } from './lead.actions';
import { LeadService } from './lead.service';

export interface LeadStateModel {
    leads: Lead[];
    archivedLeads: Lead[];
}

@State<LeadStateModel>({
    name: 'lead',
    defaults: {
        leads: null,
        archivedLeads: null,
    },
})
@Injectable()
export class LeadState {
    constructor(private leadService: LeadService, private store: Store) {}

    @Selector()
    static getLeads(state: LeadStateModel): Lead[] {
        return state?.leads;
    }

    @Selector()
    static archivedLeads(state: LeadStateModel): Lead[] {
        return state?.archivedLeads;
    }

    @Action(fetchLeads)
    fetch(ctx: StateContext<LeadStateModel>) {
        this.leadService.getAllLeads().subscribe((leads) => {
            ctx.setState(
                patch<LeadStateModel>({
                    leads: leads,
                }),
            );
        });
    }

    @Action(UpdateLead)
    update(ctx: StateContext<LeadStateModel>, action: UpdateLead) {
        this.leadService
            .updateLead(action.lead)
            .toPromise()
            .then((result) => {
                ctx.setState(
                    patch({
                        // documentation: https://www.ngxs.io/advanced/operators
                        leads: updateItem<Lead>((lead) => lead.uuid === action.lead.uuid, result),
                    }),
                );
            });
    }

    @Action(UpdateArchivedLead)
    updateArchivedLead(ctx: StateContext<LeadStateModel>, action: UpdateLead) {
        this.leadService
            .updateLead(action.lead)
            .toPromise()
            .then((result) => {
                ctx.setState(
                    patch({
                        // documentation: https://www.ngxs.io/advanced/operators
                        archivedLeads: updateItem<Lead>((lead) => lead.uuid === action.lead.uuid, result),
                    }),
                );
            });
    }

    @Action(UpdateReadOfLead)
    updateRead(ctx: StateContext<LeadStateModel>, action: UpdateReadOfLead) {
        this.leadService
            .updateReadOfLead(action.lead.uuid)
            .toPromise()
            .then(() => {
                ctx.setState(
                    patch({
                        // documentation: https://www.ngxs.io/advanced/operators
                        leads: updateItem<Lead>((lead) => lead.uuid === action.lead.uuid, action.lead),
                    }),
                );
            });
    }

    @Action(ArchiveLead)
    archiveLead(ctx: StateContext<LeadStateModel>, action: ArchiveLead) {
        this.leadService
            .archiveLead(action.lead.uuid)
            .toPromise()
            .then(() => {
                if (action.archived) {
                    ctx.setState(
                        patch({
                            archivedLeads: removeItem<Lead>((lead) => lead.uuid === action.lead.uuid),
                            leads: append([action.lead]),
                        }),
                    );
                } else {
                    ctx.setState(
                        patch({
                            leads: removeItem<Lead>((lead) => lead.uuid === action.lead.uuid),
                            archivedLeads: append([action.lead]),
                        }),
                    );
                }
            });
    }

    @Action(AddLead)
    add(ctx: StateContext<LeadStateModel>, { lead }: AddLead) {
        this.leadService.addLead(lead).subscribe((newLead) => {
            ctx.setState(
                patch({
                    leads: append([newLead]),
                }),
            );
            this.store.dispatch(new AddUndefinedObjectTagsToObject(newLead.uuid));
        });
    }

    @Action(GetArchivedLeads)
    getArchivedLeads(ctx: StateContext<LeadStateModel>) {
        return this.leadService.getArchivedLeads().pipe(
            tap((archLeads) => {
                ctx.setState(
                    patch<LeadStateModel>({
                        archivedLeads: archLeads,
                    }),
                );
            }),
        );
    }
}
