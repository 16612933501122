<div class="sidebar-spacing">
    <h3>CoManage</h3>
    <small>list of available modules</small>
</div>

<span class="divider divider--small"></span>

<nav class="app__subnav">
    <a
        routerLink="/comanage/invoices"
        routerLinkActive="app__subnav__active"
        >Invoices</a
    >
    <a
        routerLink="/comanage/offers"
        routerLinkActive="app__subnav__active"
        >Offers</a
    >
    <a
        routerLink="/comanage/contacts"
        routerLinkActive="app__subnav__active"
        >Contacts</a
    >
    <a
        routerLink="/comanage/customers"
        routerLinkActive="app__subnav__active"
        >Customers</a
    >
</nav>
