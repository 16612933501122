import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { AuthenticationService } from '../authentication/authentication.service';
import { Warehouse } from '../models/Warehouse';

@Injectable({
    providedIn: 'root',
})
export class WarehouseService {
    httpOptions = {};

    constructor(private httpClient: HttpClient, private authenticationService: AuthenticationService) {
        this.httpOptions = authenticationService.getHeaders();
    }

    addWarehouse(warehouse: Warehouse): Observable<Warehouse> {
        return this.httpClient.post<Warehouse>(environment.baseUrl + '/warehouse/', warehouse, this.httpOptions);
    }

    getAllWarehouses() {
        return this.httpClient.get<Warehouse[]>(environment.baseUrl + '/warehouse/', this.httpOptions);
    }

    getWarehouseById(uuid: string): Observable<Warehouse> {
        return this.httpClient.get<Warehouse>(environment.baseUrl + '/warehouse/' + uuid, this.httpOptions);
    }

    updateWarehouse(warehouse: Warehouse): Observable<Warehouse> {
        return this.httpClient.put<Warehouse>(environment.baseUrl + '/warehouse/', warehouse, this.httpOptions);
    }

    archiveWarehouse(uuid: string) {
        return this.httpClient.put(environment.baseUrl + '/warehouse/' + uuid, {}, this.httpOptions);
    }
}
