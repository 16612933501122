<div class="modal-header">
    <h2
        class="modal-title"
        id="exampleModalLabel">
        Delete {{ this.language.languageName }}
    </h2>
    <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">Are you sure you want to delete {{ this.language.languageName }} ?</div>

<div class="modal-footer">
    <button
        class="button button--danger"
        (click)="deleteLanguage()">
        Delete
    </button>
    <button
        type="button"
        class="button button--outline"
        (click)="activeModal.dismiss()">
        Cancel
    </button>
</div>
