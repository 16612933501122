import { Component, Input, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import Project from 'src/app/models/Project';
import { ProjectInfoModalComponent } from 'src/app/projects/project-info-modal/project-info-modal.component';
import { ProjectState } from 'src/app/projects/project.state';

import { FetchProjectsForOverview } from '../../../projects/project.actions';

@Component({
    selector: 'app-pill-project',
    templateUrl: './pill-project.component.html',
    styleUrls: ['./pill-project.component.scss'],
})
export class PillProjectComponent implements OnInit, OnDestroy {
    @Select(ProjectState.projects) projects$: Observable<Project[]>;
    projects: Project[] = [];
    @Input() project: Project;
    @Input() projectUuid: string;
    @Input() title: string;
    @Input() onFinancialPage = false;

    loggedIn = false;
    isLoading = true;
    private subscriptions = new Subscription();

    constructor(private modalService: NgbModal, private store: Store) {}

    ngOnInit(): void {
        this.loggedIn = localStorage.getItem('token') != null;
        if (!this.project && this.projectUuid && this.title) {
            this.isLoading = false;
        } else {
            if (this.project && this.loggedIn) {
                this.isLoading = false;
            } else if (this.projectUuid && this.loggedIn) {
                this.subscriptions.add(
                    this.projects$.subscribe((projects) => {
                        if (!projects) {
                            this.store.dispatch(new FetchProjectsForOverview());
                        }
                        this.projects = projects;
                        if (this.projects && this.projects.length > 0) {
                            this.project = projects.find((p) => p.uuid == this.projectUuid);
                            this.isLoading = false;
                        }
                    }),
                );
            }
        }
    }

    openProjectInfoModal() {
        if (this.loggedIn) {
            const modalRef = this.modalService.open(ProjectInfoModalComponent, {
                windowClass: 'modal-huge',
                animation: false,
            });
            modalRef.componentInstance.projectUuid = this.project ? this.project.uuid : this.projectUuid;
            modalRef.componentInstance.openFinancialTab = this.onFinancialPage;
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
    ngOnChanges(changes: SimpleChanges): void {
        if (this.projectUuid) {
            this.project = this.projects.find((p) => p.uuid == this.projectUuid);
        }
    }

    getTitle(): string {
        return this.projectUuid && this.title && this.project == undefined ? this.title : this.project.title;
    }
}
