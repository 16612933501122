import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { MatTableExporterDirective } from 'mat-table-exporter';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subscription } from 'rxjs';
import { CompanyState } from 'src/app/companies/company.state';
import { Product } from 'src/app/models/Product';
import { Warehouse } from 'src/app/models/Warehouse';
import { FetchWarehouses } from 'src/app/warehouses/warehouse.actions';
import { WarehouseState } from 'src/app/warehouses/warehouse.state';

import { ManageCategoriesComponent } from '../manage-categories/manage-categories.component';
import { ProductAddModalComponent } from '../product-add-modal/product-add-modal.component';
import { ProductDeleteModalComponent } from '../product-delete-modal/product-delete-modal.component';
import { ProductDetailModalComponent } from '../product-detail-modal/product-detail-modal.component';
import { ProductUpdateModalComponent } from '../product-update-modal/product-update-modal.component';
import { ProductUpdateStockModalComponent } from '../product-update-stock-modal/product-update-stock-modal.component';
import { ProductService } from '../product.service';
import { ProductState } from '../product.state';

@Component({
    selector: 'app-product-list',
    templateUrl: './product-list.component.html',
    styleUrls: ['./product-list.component.scss'],
})
export class ProductListComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Select(ProductState.Products) allProducts$: Observable<Product[]>;
    @Select(WarehouseState.Warehouses) allWarehouses$: Observable<Warehouse[]>;
    @Select(CompanyState.companyUuid) companyUuid$: Observable<string>;
    @Select(ProductState.SelectedCategory)
    selectedCategory$: Observable<string>;

    productQrCodePDF;
    filter = '';
    displayedColumns: string[] = [
        'title',
        'inStock',
        'stockNotification',
        'lastModified',
        'warehouseLocation.title',
        'productCategories',
        'productConsumable',
        'edit',
    ];
    dataSource: MatTableDataSource<Product>;
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    @ViewChild(MatTableExporterDirective, { static: true })
    exporter: MatTableExporterDirective;
    @Input() selectedCategory: string = null;
    allProducts: Product[] = [];

    constructor(
        private router: Router,
        private modalService: NgbModal,
        private productService: ProductService,
        private store: Store,
        private logger: NGXLogger,
        private sanitizer: DomSanitizer,
    ) {}

    ngOnInit(): void {
        this.subscriptions.add(
            this.selectedCategory$.subscribe((selectedCategory) => {
                this.selectedCategory = selectedCategory;
                if (this.selectedCategory == null) {
                    this.updateTable(this.allProducts);
                } else if (this.selectedCategory) {
                    this.updateTable(this.allProducts.filter((p) => p.productCategories.includes(this.selectedCategory)));
                }
            }),
        );

        this.logger.debug('Fetching warehouses');
        this.store.dispatch(new FetchWarehouses());
        this.subscriptions.add(
            this.allProducts$.subscribe((allProducts) => {
                this.allProducts = allProducts;

                if (this.selectedCategory == null) {
                    this.updateTable(this.allProducts);
                } else if (this.selectedCategory) {
                    this.updateTable(this.allProducts.filter((p) => p.productCategories.includes(this.selectedCategory)));
                }
            }),
        );

        this.fetchQrCodesPDF();
    }

    applyFilter(filterValue) {
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }

    productDetail(id: string) {
        this.logger.debug('Navigating to productdetails');
        this.router.navigate(['/', 'products', id, 'detail']);
    }

    openProductDetailModal(product: Product) {
        this.logger.debug('Opening productDetailModal');
        const modalRef = this.modalService.open(ProductDetailModalComponent, {
            windowClass: 'modal-pane',
            animation: false,
        });
        modalRef.componentInstance.productUuid = product.uuid;
    }

    openProductUpdateModal(product: Product) {
        this.logger.debug('Opening productUpdateModal');
        const modalRef = this.modalService.open(ProductUpdateModalComponent, {
            windowClass: 'modal-huge',
            animation: false,
        });
        modalRef.componentInstance.product = product;
    }

    openDuplicateProductAddModal(product: Product) {
        this.logger.debug('Opening duplicateProductUpdateModal');
        const modalRef = this.modalService.open(ProductAddModalComponent, {
            windowClass: 'modal-huge',
            animation: false,
        });
        modalRef.componentInstance.product = product;
        modalRef.componentInstance.duplicatedProduct = true;
    }

    openStockUpdateModal(product: Product) {
        this.logger.debug('Opening stockUpdateModal');
        const modalRef = this.modalService.open(ProductUpdateStockModalComponent, { windowClass: 'modal-huge', animation: false });
        modalRef.componentInstance.product = product;
    }

    openProductDeleteModal(product: Product) {
        this.logger.debug('Opening productDeleteModal');
        const modalRef = this.modalService.open(ProductDeleteModalComponent, {
            windowClass: 'modal-prompt',
        });
        modalRef.componentInstance.product = product;
    }

    openManageCategories() {
        this.logger.debug('Opening product categories');
        const modalRef = this.modalService.open(ManageCategoriesComponent, {
            windowClass: 'modal-pane',
        });
    }

    getProductDetailPdf(product: Product) {
        this.subscriptions.add(this.productService.getProductDetailPdf(product.uuid).subscribe((pdf) => this.base64ToBlob(pdf.pdf, product)));
    }

    base64ToBlob(data, product) {
        const byteChars = atob(data);
        const byteNumbers = new Array(byteChars.length);
        for (let i = 0; i < byteChars.length; i++) {
            byteNumbers[i] = byteChars.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);
        if (product != null) {
            this.logger.debug('Opening pdf in new window');
            window.open(url);
        } else {
            return this.sanitizer.bypassSecurityTrustUrl(url);
        }
    }

    updateTable(allProducts: Product[]) {
        this.dataSource = new MatTableDataSource(allProducts);
        this.dataSource.sort = this.sort;
        this.dataSource.sortingDataAccessor = (item, property) => {
            if (property.includes('.')) return property.split('.').reduce((o, i) => o[i], item);
            return item[property];
        };
        this.dataSource.filterPredicate = (data: Product, filter) => {
            if (filter != '') {
                return (
                    data.sku.toLowerCase().includes(filter.toLowerCase()) ||
                    data.ean.toLowerCase().includes(filter.toLowerCase()) ||
                    data.warehouseLocationTitle?.toLowerCase().includes(filter.toLowerCase()) ||
                    data.title.toLowerCase().includes(filter.toLowerCase())
                );
            } else {
                return true;
            }
        };
    }

    fetchQrCodesPDF() {
        this.subscriptions.add(
            this.companyUuid$.subscribe((cid) => {
                this.productService.getAllProductsPdf(cid).subscribe((pdf) => {
                    this.productQrCodePDF = this.base64ToBlob(pdf.pdf, null);
                });
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
