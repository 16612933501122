<div *ngIf="!loggedInWithMicrosoft || !loggedInWithGmail">
    <app-outlook-not-logged-in
        *ngIf="!loggedInWithMicrosoft"
        [loginUrl]="loginUrl"></app-outlook-not-logged-in>
    <app-gmail-not-logged-in
        *ngIf="!loggedInWithGmail"
        [loginUrl]="loginUrl"></app-gmail-not-logged-in>
</div>

<div *ngIf="loggedInWithMicrosoft || loggedInWithGmail">
    <div class="app__title">
        <h4>{{ start }} - {{ end }}</h4>
        <span>
            <a
                (click)="weekChange(-1)"
                type="button"
                class="button button--primary"
                ><i class="las la-angle-double-left"></i
            ></a>
            <a
                (click)="weekChange(1)"
                type="button"
                class="button button--primary"
                ><i class="las la-angle-double-right"></i
            ></a>
        </span>
    </div>

    <div
        class="calendar"
        *ngFor="let event of calendar | orderBy: 'start':false:true">
        <div>
            <div class="list__item__image">
                <img
                    *ngIf="event.type === 'GOOGLE'"
                    src="../../../assets/img/google-calendar.png"
                    width="50"
                    height="50" />
                <img
                    *ngIf="event.type === 'OUTLOOK'"
                    src="../../../assets/img/logo-outlook-calendar.png"
                    width="50"
                    height="50" />
            </div>

            <div class="list__item__content">
                <div *ngIf="event.type === 'OUTLOOK'">
                    <h3>{{ event.subject }}</h3>
                    <p>{{ event.bodypreview }}</p>
                    <small>{{ event.start.dateTime | date: 'medium' }} - {{ event.end.dateTime | date: 'medium' }}</small>
                    <small
                        >{{ event.location.address.street }},
                        {{ event.location.address.city }}
                        {{ event.location.address.state }}
                        {{ event.location.address.countryOrRegion }}</small
                    >
                </div>
                <div *ngIf="event.type === 'GOOGLE'">
                    <h3>{{ event.title }}</h3>
                    <p>{{ event.body }}</p>
                    <small>{{ event.start }} - {{ event.end }}</small>
                    <small>{{ event.location }}</small>
                </div>
            </div>
        </div>
    </div>
</div>
