import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { CustomerDetailComponent } from 'src/app/customers/customer-detail/customer-detail.component';
import { FetchCustomers } from 'src/app/customers/customers.actions';
import { CustomerState } from 'src/app/customers/customers.state';
import Customer from 'src/app/models/Customer';

@Component({
    selector: 'app-pill-customer',
    templateUrl: './pill-customer.component.html',
    styleUrls: ['./pill-customer.component.scss'],
})
export class PillCustomerComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();
    @Select(CustomerState.Customers) customers$: Observable<Customer[]>;

    @Input() customerUuid: string;
    @Input() customer: Customer;

    isLoading = true;

    constructor(private store: Store, private modalService: NgbModal) {}

    ngOnInit(): void {
        if (this.customer) {
            this.isLoading = false;
        }
        this.subscriptions.add(
            this.customers$.subscribe((customers) => {
                if (customers == null) {
                    this.store.dispatch(new FetchCustomers());
                } else if (this.customer == undefined) {
                    this.customer = JSON.parse(JSON.stringify(customers.find((c) => c.uuid == this.customerUuid)));
                    this.isLoading = false;
                }
            }),
        );
    }

    openCustomerDetail() {
        const modalRef = this.modalService.open(CustomerDetailComponent, {
            windowClass: 'modal-pane',
            animation: false,
        });
        modalRef.componentInstance.customerUuid = this.customer.uuid;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
