<app-app-holder>
    <app-navbar></app-navbar>
    <app-content>
        <div>
            <div class="app__title">
                <h1>
                    Customers
                    <span *ngIf="customers$ | async as customers">({{ customers.length }})</span>
                </h1>
                <span>
                    <a
                        class="button button--primary"
                        *appIsPermitted="{
                            module: 'CUSTOMERS',
                            operator: 'AND',
                            actions: ['CREATE']
                        }"
                        (click)="openAddCustomerModal()"
                        >New customer</a
                    >
                </span>
            </div>

            <div
                *appIsPermitted="{
                    module: 'CUSTOMERS',
                    operator: 'AND',
                    actions: ['READ']
                }">
                <div *ngIf="customers$ | async as customers; else loading">
                    <app-customers-list *ngIf="customers && customers.length > 0; else empty"></app-customers-list>
                </div>
            </div>
        </div>
    </app-content>

    <ng-template #loading>
        <app-loading-message></app-loading-message>
    </ng-template>

    <ng-template #empty>
        <app-message-empty
            [title]="'No customers...'"
            [icon]="'../../assets/img/open-box.png'">
            <a
                (click)="openAddCustomerModal()"
                class="button button--primary"
                >Add a customer</a
            >
        </app-message-empty>
    </ng-template>
</app-app-holder>
