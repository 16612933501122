<div class="modal-header">
    <h2
        class="modal-title"
        id="exampleModalLabel">
        Two-factor authentication
    </h2>
    <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <form
        [formGroup]="mfaLoginFormGroup"
        (ngSubmit)="loginWithMFA()"
        class="modal-wrapper">
        <div class="row">
            <div class="col">
                <div class="form-group m-b-2">
                    <label>Two-factor authentication code</label>
                    <input
                        type="text"
                        pattern="[0-9]*"
                        formControlName="verificationCode"
                        trim="blur" />
                    <p class="form-helptext">Enter the code from your authenticator app</p>
                    <small
                        class="form-error"
                        *ngIf="mfaLoginFormGroup.controls.verificationCode.errors && mfaLoginFormGroup.touched"
                        >The authentication code should be 6 figures</small
                    >
                    <small
                        class="form-error"
                        *ngIf="inputIsWrong"
                        >The code is invalid, please try again...</small
                    >
                    <small
                        class="badge badge--success"
                        *ngIf="inputIsVerified"
                        >Successfully enabled 2FA</small
                    >
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <button
                    type="submit"
                    [ngClass]="{ 'button--loading': loading }"
                    class="button button--primary w-100"
                    [disabled]="!mfaLoginFormGroup.valid">
                    Verify code
                </button>
            </div>
        </div>
    </form>
</div>
