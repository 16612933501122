<div class="modal-header">
    <h2
        class="modal-title"
        id="exampleModalLabel">
        Delete language
    </h2>
    <button
        (click)="activeModal.dismiss()"
        aria-label="Close"
        class="close"
        type="button">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">Are you sure you want to delete <strong>{{ language.language }}</strong>?</div>

<div class="modal-footer">
    <button
        (click)="deleteLanguage()"
        class="button button--danger">
        Delete
    </button>
    <button
        (click)="activeModal.close()"
        class="button button--outline"
        type="button">
        Cancel
    </button>
</div>
