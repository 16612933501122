import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import Customer from 'src/app/models/Customer';
import { ConfirmationModalComponent } from 'src/app/shared/confirmation-modal/confirmation-modal.component';

import { AddCustomerModalComponent } from '../add-customer-modal/add-customer-modal.component';
import { DeleteCustomer, FetchCustomers } from '../customers.actions';
import { CustomerState } from '../customers.state';

@Component({
    selector: 'app-customers-list',
    templateUrl: './customers-list.component.html',
    styleUrls: ['./customers-list.component.scss'],
})
export class CustomersListComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();
    @Select(CustomerState.Customers) customers$: Observable<Customer[]>;

    // material table
    displayedColumns: string[] = ['firstName', 'contactInformation.phoneNumber', 'contactInformation.email', 'creatorId', 'edit'];
    dataSource: MatTableDataSource<Customer>;
    @ViewChild(MatSort) sort: MatSort;

    filter: string;
    isLoading = true;

    constructor(private logger: NGXLogger, private store: Store, private toastr: ToastrService, private modalService: NgbModal) {}

    ngOnInit(): void {
        this.logger.debug('fetching customers');
        this.store.dispatch(new FetchCustomers());

        this.subscriptions.add(
            this.customers$.subscribe((customers) => {
                if (customers != null) {
                    this.updateTable(customers);
                    this.isLoading = false;
                }
            }),
        );
    }

    applyFilter(filterValue) {
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }

    updateTable(customers: Customer[]) {
        this.dataSource = new MatTableDataSource(customers);
        this.dataSource.sort = this.sort;

        this.dataSource.filterPredicate = this.dataSource.filterPredicate = (data: any, filter) => {
            const dataStr = JSON.stringify(data).toLowerCase();
            return dataStr.indexOf(filter) != -1;
        };

        this.dataSource.sortingDataAccessor = (item, property) => {
            if (property.includes('.')) return property.split('.').reduce((o, i) => o[i], item);
            return item[property];
        };
    }

    openUpdateCustomerModal(customer: Customer) {
        this.logger.debug('opening addCustomerModalComponent');
        const modalRef = this.modalService.open(AddCustomerModalComponent, {
            windowClass: 'modal-pane',
            animation: false,
        });
        modalRef.componentInstance.customer = customer;
    }

    deleteCustomer(customer: Customer) {
        this.logger.debug('starting remove customer flow');
        const modalRef = this.modalService.open(ConfirmationModalComponent, {
            windowClass: 'modal-prompt',
        });
        modalRef.componentInstance.type = 'Delete';
        modalRef.componentInstance.title = 'Delete customer' + customer.firstName;
        modalRef.componentInstance.message = 'Are you sure you want to delete ' + customer.firstName + ' ' + customer.lastName + ' as a customer?';

        this.subscriptions.add(
            modalRef.componentInstance.closeEvent.subscribe((val) => {
                if (val) {
                    this.logger.debug('attempting to delete customer');
                    this.store.dispatch(new DeleteCustomer(customer.uuid)).subscribe(
                        () => {
                            this.logger.debug('succesfully removed customer');
                            this.toastr.info('Customer successfully deleted.');
                        },
                        (error) => {
                            this.logger.debug('failed to remove customer');
                            this.toastr.error('Oops, something went wrong please try again later.');
                        },
                    );
                }
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
