import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-primary-message',
    templateUrl: './primary-message.component.html',
    styleUrls: ['./primary-message.component.scss'],
})
export class PrimaryMessageComponent implements OnInit {
    @Input() title: string;
    @Input() message: string;

    @Input() messageSuccess = false;
    @Input() messageWarning = false;
    @Input() messageUrgent = false;
    @Input() messageInfo = false;

    constructor() {}

    ngOnInit(): void {}
}
