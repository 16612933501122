import AddCampaignModel from './add-campaign-modal/add-campaing-model';
import UpdateLeadModel from './mailchimp-recipients-modal/update-lead-model';

export class FetchCampaigns {
    static readonly type = '[Campaign] Fetch campaign';
}

export class FetchAudience {
    static readonly type = '[Audience] Fetch audience';
}

export class FetchTemplates {
    static readonly type = '[Templates] Fetch templates';
}

export class FetchTags {
    static readonly type = '[Tags] Fetch tags';
    constructor(public listId: string) {}
}

export class FetchRecipients {
    static readonly type = '[Recipients] Fetch recipients';
    constructor(public audienceId: string) {}
}

export class FetchCampaignReport {
    static readonly type = '[Report] Fetch report';
    constructor(public campaignId: string) {}
}

export class AddCampaign {
    static readonly type = '[Campaign] Add campaign';
    constructor(public campaign: AddCampaignModel) {}
}

export class AddRecipient {
    static readonly type = '[Recipient] Add recipient';
    constructor(public lead: UpdateLeadModel) {}
}

export class AddMailchimpTag {
    static readonly type = '[Tags] Add mailchimp Tag';
    constructor(public listId: string, public tagName: string) {}
}

export class RemoveTag {
    static readonly type = '[Tags] remove tag';
    constructor(public listId: string, public tagId: string) {}
}
