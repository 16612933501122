<div class="page page__notfound">
    <div class="page__content">
        <h3>🕵️‍♂️ 404</h3>
        <p>Oops... the page you are looking for, could not be found.</p>
        <a
            routerLink="/dashboard"
            class="button button--primary"
            >To dashboard</a
        >
    </div>
</div>
