import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { AuthenticationService } from '../authentication/authentication.service';
import { ContactPerson } from '../models/ContactPerson';
import { Partner } from '../models/Partner';

@Injectable({
    providedIn: 'root',
})
export class PartnerService {
    httpOptions = {};

    constructor(private httpClient: HttpClient, private authenticationService: AuthenticationService) {
        this.httpOptions = authenticationService.getHeaders();
    }

    getAllPartners(): Observable<Partner[]> {
        return this.httpClient.get<Partner[]>(environment.baseUrl + `/partner`, this.httpOptions);
    }

    getPartnerById(partnerUuid: string): Observable<Partner> {
        return this.httpClient.get<Partner>(environment.baseUrl + '/partner/' + partnerUuid, this.httpOptions);
    }

    addPartner(partner: Partner): Observable<Partner> {
        return this.httpClient.post<Partner>(environment.baseUrl + '/partner', partner, this.httpOptions);
    }

    updatePartner(partnerUuid: string, partner: Partner): Observable<Partner> {
        return this.httpClient.put<Partner>(environment.baseUrl + '/partner/' + partnerUuid, partner, this.httpOptions);
    }

    archivePartner(partnerUuid: string): Observable<string> {
        return this.httpClient.put<string>(environment.baseUrl + '/partner/' + partnerUuid + '/archive-partner', {}, this.httpOptions);
    }

    addContactPersonToPartner(partnerUuid: string, contactPerson: ContactPerson): Observable<Partner> {
        return this.httpClient.post<Partner>(environment.baseUrl + '/partner/' + partnerUuid, contactPerson, this.httpOptions);
    }

    getAllContactPersonsOfPartner(partnerUuid: string) {
        return this.httpClient.get<ContactPerson[]>(environment.baseUrl + '/contact/' + partnerUuid + '/contact-people-list', this.httpOptions);
    }

    getAllContactPersons(companyUuid: string): Observable<ContactPerson[]> {
        return this.httpClient.get<ContactPerson[]>(environment.baseUrl + '/contact/' + companyUuid + '/company-contact-people-list', this.httpOptions);
    }

    getContactPersonById(contactPersonUuid: string): Observable<ContactPerson> {
        return this.httpClient.get<ContactPerson>(environment.baseUrl + '/contact/' + contactPersonUuid, this.httpOptions);
    }

    updateContactPerson(contactPersonUuid: string, contactPersonToUpdate: ContactPerson): Observable<ContactPerson> {
        return this.httpClient.put<ContactPerson>(environment.baseUrl + '/contact/' + contactPersonUuid, contactPersonToUpdate, this.httpOptions);
    }

    deleteContactPerson(contactPersonUuid: string) {
        return this.httpClient.delete<string>(environment.baseUrl + '/contact/' + contactPersonUuid, this.httpOptions);
    }
}
