import { GlobalTag } from '../models/GlobalTag';
import { Education } from '../models/resume/Education';
import { Experience } from '../models/resume/Experience';
import { Language } from '../models/resume/Language';
import { Resume } from '../models/resume/Resume';
import { ResumeVariant } from '../models/resume/ResumeVariant';
import { ResumeVariantSkill } from '../models/resume/ResumeVariantSkill';

export class FetchResumes {
    static readonly type = '[Resume] Fetch Resumes';
    constructor() {}
}

export class SetResume {
    static readonly type = '[Resume] Set Resume';
    constructor(public resume: Resume) {}
}

export class AddResume {
    static readonly type = '[Resume] Add Resume';
    constructor(public resume: Resume) {}
}

export class UpdateResume {
    static readonly type = '[Resume] Update Resume';
    constructor(public resume: Resume) {}
}

export class UpdateResumeDescription {
    static readonly type = '[Resume] Update Description';
    constructor(public resumeUuid, public resume: Resume) {}
}

export class ArchiveResume {
    static readonly type = '[Resume] Archive Resume';
    constructor(public resumeUuid: string) {}
}

export class GetResume {
    static readonly type = '[Resume] Get Resume';
    constructor(public resumeUuid: string) {}
}

export class AddEducation {
    static readonly type = '[Resume] Add Education';
    constructor(public resumeUuid, public education: Education) {}
}

export class UpdateEducation {
    static readonly type = '[Resume] Update Education';
    constructor(public educationUuid: string, public education: Education) {}
}

export class DeleteEducation {
    static readonly type = '[Resume] Delete Education';
    constructor(public educationUuid: string) {}
}

export class GetEducations {
    static readonly type = '[Resume] Get Educations';
    constructor(public resumeUuid: string) {}
}

export class AddExperience {
    static readonly type = '[Resume] Add Experience';
    constructor(public resumeUuid: string, public experience: Experience) {}
}

export class UpdateExperience {
    static readonly type = '[Resume] Update Experience';
    constructor(public experienceUuid: string, public experience: Experience) {}
}

export class DeleteExperience {
    static readonly type = '[Resume] Delete Experience';
    constructor(public experienceUuid: string) {}
}

export class GetExperiences {
    static readonly type = '[Resume] Get Experiences';
    constructor(public resumeUuid: string) {}
}

export class AddLanguage {
    static readonly type = '[Resume] Add Language';
    constructor(public resumeUuid: string, public language: Language) {}
}

export class UpdateLanguage {
    static readonly type = '[Resume] Update Language';
    constructor(public languageUuid: string, public language: Language) {}
}

export class DeleteLanguage {
    static readonly type = '[Resume] Delete Language';
    constructor(public languageUuid: string) {}
}

export class GetLanguages {
    static readonly type = '[Resume] Get Languages';
    constructor(public resumeUuid: string) {}
}

export class AddResumeVariant {
    static readonly type = '[Resume] Add Resume Variant';
    constructor(public resumeUuid: string, public resumeVariant: ResumeVariant) {}
}

export class AddResumeVariantSkill {
    static readonly type = '[Resume] Add Resume Variant Skill';
    constructor(public resumeVariantUuid: string, public skill: ResumeVariantSkill) {}
}

export class GetResumeVariants {
    static readonly type = '[Resume] Get Resume Variants';
    constructor(public resumeUuid: string) {}
}

export class UpdateResumeVariant {
    static readonly type = '[Resume] Update Resume Variant';
    constructor(public resumeVariantUuid: string, public resumeVariant: ResumeVariant) {}
}

export class UpdateResumeVariantSkill {
    static readonly type = '[Resume] Update Resume Variant Skill';
    constructor(public skillUuid: string, public skill: ResumeVariantSkill) {}
}

export class DeleteResumeVariant {
    static readonly type = '[Resume] Delete Resume Variant';
    constructor(public resumeVariantUuid: string) {}
}

export class DeleteResumeVariantSkill {
    static readonly type = '[Resume] Delete Resume Variant Skill';
    constructor(public skillUuid: string) {}
}

export class AddTagToResume {
    static readonly type = '[Resume] Add tag to resume';
    constructor(public tag: GlobalTag, public resumeId: string) {}
}

export class RemoveTagFromResume {
    static readonly type = '[Resume] Remove tag from resume';
    constructor(public tagId: string, public resumeId: string) {}
}
