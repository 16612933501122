export interface GlobalFile {
    yukiId: string;
    type: number;
    uuid: string;
    title: string;
    price: number;
    subject: string;
    invoiceType: InvoiceType;
}

export enum InvoiceType {
    PURCHASE,
    SALE,
}
