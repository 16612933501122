import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

import { AuthenticationService } from '../../authentication/authentication.service';
import Comment from '../../models/Comment';

@Injectable({
    providedIn: 'root',
})
export class CommentService {
    httpOptions = {};

    constructor(private httpClient: HttpClient, private authenticationService: AuthenticationService) {
        this.httpOptions = authenticationService.getHeaders();
    }

    getComments(uuid: string) {
        return this.httpClient.get<Comment[]>(`${environment.baseUrl}/comment/object/${uuid}`, this.httpOptions);
    }

    addComment(uuid: string, content: string) {
        return this.httpClient.post<Comment>(`${environment.baseUrl}/comment/object/${uuid}`, content, this.httpOptions);
    }

    updateComment(uuid: string, content: string) {
        return this.httpClient.put<Comment>(`${environment.baseUrl}/comment/${uuid}`, content, this.httpOptions);
    }

    deleteComment(uuid: string) {
        return this.httpClient.delete(`${environment.baseUrl}/comment/${uuid}`, this.httpOptions);
    }

    getLatestComments() {
        return this.httpClient.get<Comment[]>(`${environment.baseUrl}/comment/latest`, this.httpOptions);
    }

    getObjectId(uuid: string) {
        return this.httpClient.get<string>(`${environment.baseUrl}/comment/link/${uuid}`, this.httpOptions);
    }
}
