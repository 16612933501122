<a
    class="pill pill--warehouse"
    *ngIf="warehouse"
    (click)="openWarehouseDetailModal()">
    <i class="las la-warehouse"></i>
    {{ warehouse.title }}
</a>

<a
    class="pill pill--warehouse"
    *ngIf="warehouseUuid && fetchedWarehouse"
    (click)="openWarehouseDetailModal()">
    <i class="las la-warehouse"></i>
    {{ fetchedWarehouse.title }}
</a>
