import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

import { AuthenticationService } from '../authentication/authentication.service';

@Injectable({
    providedIn: 'root',
})
export class RelatedEmployeeService {
    httpOptions = {};

    constructor(private httpClient: HttpClient, private authenticationService: AuthenticationService) {
        this.httpOptions = authenticationService.getHeaders();
    }

    addRelatedEmployee(uuid: string, employees: string) {
        return this.httpClient.post<any>(environment.baseUrl + `/related-employees/add-employee/${uuid}?employees=${employees}`, {}, this.httpOptions);
    }

    getAllRelatedEmployees() {
        return this.httpClient.get<any[]>(environment.baseUrl + `/related-employees`, this.httpOptions);
    }

    deleteRelatedEmployee(uuid: string, employeeUuid: string) {
        return this.httpClient.delete<any>(environment.baseUrl + `/related-employees/${uuid}/${employeeUuid}/delete-employee`, this.httpOptions);
    }
}
