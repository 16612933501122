import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { GetCompanyMembers } from 'src/app/companies/company.actions';
import { CompanyState } from 'src/app/companies/company.state';
import { FetchContacts } from 'src/app/contacts/contacts.actions';
import { ContactState } from 'src/app/contacts/contacts.state';
import { CompanyMember } from 'src/app/models/CompanyMember';
import { Partner } from 'src/app/models/Partner';
import User from 'src/app/models/User';
import { FetchPartners } from 'src/app/partners/partners.actions';
import { PartnerState } from 'src/app/partners/partners.state';

@Component({
    selector: 'app-user-detail',
    templateUrl: './user-detail.component.html',
    styleUrls: ['./user-detail.component.scss'],
})
export class UserDetailComponent {
    @Input() user: User;

    constructor(public activeModal: NgbActiveModal) {}
}
