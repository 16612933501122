import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subscription } from 'rxjs';
import FileSystemEntity from 'src/app/models/FileSystemEntity';

import { DocumentsAddModalComponent } from '../documents-add-modal/documents-add-modal.component';
import { FetchRootDirectories, FetchStructure, Navigate } from '../documents.actions';
import { DocumentState } from '../documents.state';

interface Dir {
    uuid: string;
    name: string;
    children?: Dir[];
}
interface FlatDir {
    expandable: boolean;
    uuid: string;
    name: string;
    level: number;
}

@Component({
    selector: 'app-documents-overview',
    templateUrl: './documents-overview.component.html',
    styleUrls: ['./documents-overview.component.scss'],
})
export class DocumentsOverviewComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();
    private _transformer = (dir: Dir, level: number) => {
        return {
            expandable: !!dir.children && dir.children.length > 0,
            name: dir.name,
            uuid: dir.uuid,
            level: level,
        };
    };
    hasChild = (_: number, dir: FlatDir) => dir.expandable;
    treeControl = new FlatTreeControl<FlatDir>(
        (dir) => dir.level,
        (dir) => dir.expandable,
    );
    treeFlattener = new MatTreeFlattener(
        this._transformer,
        (dir) => dir.level,
        (dir) => dir.expandable,
        (dir) => dir.children,
    );
    dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    @Select(DocumentState.rootDirectories) roots$: Observable<FileSystemEntity[]>;
    @Select(DocumentState.currentPathName) pathNames$: Observable<string[]>;
    @Select(DocumentState.structure) structure$: Observable<any>;
    @Select(DocumentState.currentDirectory) currentDirectory$: Observable<any>;

    roots: FileSystemEntity[];
    selectedDirectory: any;
    isLoading = true;

    addFolderInput = '';

    constructor(private store: Store, private modalService: NgbModal, private logger: NGXLogger) {
        this.store.dispatch(new FetchStructure()).subscribe();
    }

    ngOnInit(): void {
        this.subscriptions.add(
            this.structure$.subscribe((structure) => {
                if (structure != null) {
                    this.dataSource.data = structure;
                    if (this.dataSource != null && this.dataSource.data[0] != undefined) {
                        if (structure[0] != undefined) {
                            this.selectDirectory(structure[0]);
                        }
                    }
                    this.isLoading = false;
                }
            }),
        );
        this.subscriptions.add(
            this.currentDirectory$.subscribe((dir) => {
                this.selectedDirectory = dir;
            }),
        );
        this.logger.debug('Attempt to fetch root directories');
        this.subscriptions.add(
            this.store.dispatch(new FetchRootDirectories()).subscribe(
                () => {
                    this.logger.debug('Succesfully fetched root directories');
                },
                (error) => this.logger.error('Failed to fetch root directories'),
            ),
        );
        this.subscriptions.add(
            this.roots$.subscribe((d) => {
                if (d != null && d != undefined) {
                    this.roots = d;
                }
            }),
        );
    }

    selectDirectory(dir: any) {
        this.selectedDirectory = dir;
        this.logger.debug('Navigate to other directory');
        this.subscriptions.add(this.store.dispatch(new Navigate(dir.uuid)).subscribe());
    }

    addRoot() {
        this.logger.debug('Opening documentsAddModal - directory');
        const modalRef = this.modalService.open(DocumentsAddModalComponent, {
            windowClass: 'modal-prompt',
        });
        modalRef.componentInstance.type = 'Dir';
        modalRef.componentInstance.content = this.roots;
        modalRef.componentInstance.currentDirId = '';
    }

    onDelete() {
        this.subscriptions.add(
            this.roots$.subscribe((d) => {
                if (d != null && d != undefined && d[0] != undefined) {
                    this.roots = d;
                    this.selectDirectory(d[0]);
                    this.isLoading = false;
                }
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
