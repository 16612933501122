<div class="modal-header">
    <h2
        class="modal-title"
        id="exampleModalLabel">
        <ng-container *ngIf="project.archived; else archive">Unarchive</ng-container>
        <ng-template #archive>Archive</ng-template>
        project
    </h2>
    <button
        (click)="activeModal.dismiss()"
        aria-label="Close"
        class="close"
        type="button">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    Are you sure you want to
    <ng-container *ngIf="project.archived; else archiveSmall">unarchive</ng-container>
    <ng-template #archiveSmall>archive</ng-template>
    <strong> {{ project.title }}</strong>?
    <ng-container *ngIf="!project.archived">
        All assigned resumes will be archived as well. It is possible to unarchive this project at a later date.
    </ng-container>
</div>

<div class="modal-footer">
    <button
        (click)="archiveWork()"
        class="button button--danger">
        <ng-container *ngIf="project.archived; else archive">Unarchive</ng-container>
        <ng-template #archive>Archive</ng-template>
    </button>
    <button
        (click)="activeModal.close()"
        class="button button--outline"
        type="button">
        Cancel
    </button>
</div>
