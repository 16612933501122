import { Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { Warehouse } from 'src/app/models/Warehouse';
import { CountryService } from 'src/app/shared/country.service';

import { AddWarehouse } from '../warehouse.actions';

@Component({
    selector: 'app-warehouse-add-modal',
    templateUrl: './warehouse-add-modal.component.html',
    styleUrls: ['./warehouse-add-modal.component.scss'],
})
export class WarehouseAddModalComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();
    public emitObject: EventEmitter<Warehouse> = new EventEmitter();
    allCountries = [];

    warehouseFormGroup: FormGroup;
    loading = false;
    inputIsWrong = false;
    warehouse: Warehouse;

    saveWarehouseWentWrong = false;
    errorMessage: string;
    DESCRIPTION_MAX_LENGTH = 1000;
    currentAmountOfDescriptionCharacters: number;
    descriptionIsTooLong = false;

    constructor(
        private formBuilder: FormBuilder,
        public activeModal: NgbActiveModal,
        private countryService: CountryService,
        private store: Store,
        private toastr: ToastrService,
        private logger: NGXLogger,
    ) {}

    ngOnInit(): void {
        this.allCountries = this.countryService.getAllCountries();
        this.warehouseFormGroup = this.formBuilder.group({
            title: ['', [Validators.required]],
            shortName: [''],
            description: [''],
            address: this.formBuilder.group({
                street: ['', [Validators.required]],
                number: [null, [Validators.required]],
                bus: [''],
                ext: [null],
                postal: ['', [Validators.required]],
                city: ['', [Validators.required]],
                country: this.formBuilder.group({
                    iso2: ['', [Validators.required]],
                }),
            }),
        });
    }

    addWarehouse() {
        this.loading = true;

        if (!this.warehouseFormGroup.valid) {
            this.inputIsWrong = true;
            this.loading = false;
            return;
        }

        this.warehouse = this.warehouseFormGroup.value;
        this.warehouseFormGroup.disable();

        this.logger.debug('Attempt to add warehouse');
        this.subscriptions.add(
            this.store.dispatch(new AddWarehouse(this.warehouse)).subscribe(
                (store) => {
                    this.logger.debug('Succesfully added Warehouse');
                    this.toastr.success('Warehouse added succesfully');
                    this.emitObject.emit(store.warehouse.warehouses.find((w) => w.title == this.warehouse.title));
                    this.activeModal.close();
                },
                (errorMessage) => {
                    this.logger.debug('Failed to add warehouse');
                    this.loading = false;
                    this.errorMessage = errorMessage?.error?.message ?? 'Error message was empty';
                    this.saveWarehouseWentWrong = true;
                    this.warehouseFormGroup.enable();
                },
            ),
        );
    }

    // Hides the error messages that appear after a user has submitted invalid data
    hideErrorMessage() {
        this.inputIsWrong = false;
    }

    onDescriptionKeyUp() {
        this.descriptionIsTooLong = false;
        this.currentAmountOfDescriptionCharacters = this.warehouseFormGroup.controls.description.value.length;
        if (this.currentAmountOfDescriptionCharacters > this.DESCRIPTION_MAX_LENGTH) {
            this.descriptionIsTooLong = true;
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
