import { Component, OnDestroy, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { Query } from 'src/app/models/Query';
import { QueryService } from 'src/app/queries/queries.service';

import { VeldaArticlesState } from '../../velda-articles.state';

@Component({
    selector: 'app-velda-articles-articles-list',
    templateUrl: './velda-articles-articles-list.component.html',
    styleUrls: ['./velda-articles-articles-list.component.scss'],
})
export class VeldaArticlesArticlesListComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Select(VeldaArticlesState.queries) queries$: Observable<Query[]>;
    query: Query;
    data: any[];
    loading = true;
    queryError = false;

    constructor(private queryService: QueryService) {}

    ngOnInit(): void {
        this.subscriptions.add(
            this.queries$.subscribe((queries) => {
                this.query = queries?.find((q) => q?.type === 'ARTICLES');
                this.queryService.runQueryByType('ARTICLES').subscribe((res) => {
                    if (JSON.parse(JSON.stringify(res)).errors) {
                        this.queryError = true;
                    } else {
                        this.queryError = false;
                        this.data = JSON.parse(JSON.stringify(res)).data.entries;
                    }
                });
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
