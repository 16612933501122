import {
    differenceInCalendarDays,
    differenceInDays,
    differenceInMonths,
    endOfMonth,
    getDaysInMonth,
    isAfter,
    isBefore,
    isSameMonth,
    startOfMonth,
    sub,
} from 'date-fns';

import { BUDGETREPEAT } from './BUDGETREPEAT';
import BudgetExpenditure from './BudgetExpenditure';
import { Employee } from './Employee';

export default class Budget {
    uuid: string;
    budgetType: string;
    totalSpend: number;
    startDate: string;
    endDate: string;
    archived: boolean;
    budgetExpenditures: BudgetExpenditure[];
    linkedEmployee: Employee;
    sendNotification?: boolean;
    notificationDate: string;
    recurring: boolean;
    repeat?: string;
    useExpendituresForFinancials?: boolean;

    /**
     * @deprecated use endpoints /api/financial/budget/budgeted-costs and /api/financial/budget/actual-costs
     */
    static getTotalSpend(budget: Budget): number {
        if (budget && budget.budgetExpenditures && budget.budgetExpenditures.length > 0) {
            return budget.budgetExpenditures.reduce((previousValue, currentValue) => previousValue + currentValue.cost, 0);
        }
        return budget.totalSpend;
    }

    static getTotalSpendPercentage(budget: Budget): number {
        return Math.round((this.getTotalSpend(budget) / budget.totalSpend) * 100);
    }

    /**
     * Function that checks the attention level of budget based on notification date
     * @param budget
     * @return number
     *      0: no attention
     *      1: attention
     *      2: urgent (1/4 of notification date)
     */
    static checkAttentionLevel(budget: Budget): number {
        if (!budget.sendNotification) {
            return 0;
        }
        const urgentDate: Date = sub(new Date(budget.endDate), {
            days: differenceInDays(new Date(budget.endDate), new Date(budget.notificationDate)) / 4,
        });
        if (isBefore(urgentDate, new Date())) {
            return 2;
        } else if (isBefore(new Date(budget.notificationDate), new Date())) {
            return 1;
        }
        return 0;
    }

    /**
     * @deprecated use endpoints /api/financial/budget/budgeted-costs and /api/financial/budget/actual-costs
     */
    static calculateBudgetCost(budget: Budget, selectedDate: Date, daysPerMonth: number, total: number): number {
        if (budget.archived) return total;
        const startDate: Date = new Date(budget.startDate);
        const endDate: Date = new Date(budget.endDate);

        if (isAfter(selectedDate, startDate) && isBefore(selectedDate, endDate)) {
            const monthsBetween: number = Math.abs(differenceInMonths(startDate, endDate));
            let sum = 0;

            if (budget.recurring) {
                sum = this.calculateRecurringCost(budget, total, daysPerMonth);
            } else {
                sum = budget.totalSpend / monthsBetween;
            }
            total = this.calculateSumAccountingStartAndEndDate(startDate, sum, endDate, total, selectedDate);
        }
        return total;
    }

    /**
     * @deprecated use endpoints /api/financial/budget/budgeted-costs and /api/financial/budget/actual-costs
     */
    static calculateActualCost(budget: Budget, selectedDate: Date, daysPerMonth: number, total: number): number {
        if (budget.archived) return total;

        const startDate: Date = new Date(budget.startDate);
        const endDate: Date = new Date(budget.endDate);

        if (isAfter(selectedDate, startDate) && isBefore(selectedDate, endDate)) {
            const monthsBetween: number = Math.abs(differenceInMonths(startDate, endDate));
            let sum = 0;

            if (budget.recurring) {
                sum = this.calculateRecurringCost(budget, total, daysPerMonth);
            } else {
                if (budget.budgetExpenditures && budget.budgetExpenditures.length > 0) {
                    budget.budgetExpenditures.forEach((expenditure) => {
                        sum = expenditure.cost / monthsBetween;
                    });
                } else {
                    sum = budget.totalSpend / monthsBetween;
                }
            }
            total = this.calculateSumAccountingStartAndEndDate(startDate, sum, endDate, total, selectedDate);
        }
        return total;
    }

    /**
     * @deprecated use endpoints /api/financial/budget/budgeted-costs and /api/financial/budget/actual-costs
     */
    public static getExpiredTimePercentage(budget: Budget): number {
        const endEpoch = new Date(budget.endDate).getTime();
        const startEpoch = new Date(budget.startDate).getTime();
        const nowEpoch = new Date().getTime();

        const total = endEpoch - startEpoch;
        return Math.round(((nowEpoch - startEpoch) / total) * 100);
    }

    /**
     * @deprecated use endpoints /api/financial/budget/budgeted-costs and /api/financial/budget/actual-costs
     */
    private static calculateSumAccountingStartAndEndDate(startDate: Date, sum: number, endDate: Date, total: number, selectedDate: Date) {
        if (isSameMonth(startDate, selectedDate)) {
            sum *= (Math.abs(differenceInCalendarDays(startDate, endOfMonth(startDate))) + 1) / getDaysInMonth(selectedDate);
        } else if (isSameMonth(endDate, selectedDate)) {
            sum *= (Math.abs(differenceInCalendarDays(endDate, startOfMonth(endDate))) + 1) / getDaysInMonth(selectedDate);
        }
        total += sum;
        return total;
    }

    /**
     * @deprecated use endpoints /api/financial/budget/budgeted-costs and /api/financial/budget/actual-costs
     */
    private static calculateRecurringCost(budget: Budget, total: number, daysPerMonth: number) {
        const repeat: BUDGETREPEAT = BUDGETREPEAT.findRepeat(budget.repeat);
        switch (repeat) {
            case BUDGETREPEAT.DAILY:
                total += budget.totalSpend * daysPerMonth;
                break;
            case BUDGETREPEAT.WEEKLY:
                total += budget.totalSpend * 4;
                break;
            case BUDGETREPEAT.MONTHLY:
                total += budget.totalSpend;
                break;
            case BUDGETREPEAT.SIX_MONTHS:
                total += budget.totalSpend / 6;
                break;
            case BUDGETREPEAT.YEARLY:
                total += budget.totalSpend / 12;
                break;
        }
        return total;
    }
}
