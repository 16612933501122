<div class="modal-wrapper">
    <header class="modal-header">
        <h2
            class="modal-title"
            id="exampleModalLabel">
            Manage resume variants for {{resume.firstName}} {{resume.lastName}}
        </h2>
        <button
            (click)="activeModal.dismiss()"
            aria-label="Close"
            class="close"
            type="button">
            <span aria-hidden="true">&times;</span>
        </button>
    </header>
    <main class="modal-body">
        <form (ngSubmit)="addVariant()" [formGroup]="variantsForm">
            <p class="sectiontitle sectiontitle--first">New variant</p>

            <div class="d-flex align-items-end">
                <div class="form-group required w-100 m-r-2">
                    <label for="variant">Variant</label>
                    <input formControlName="variant" id="variant" type="text">
                </div>
                <button
                    [disabled]="variantsForm.invalid"
                    class="button button--primary button--icon m-t-2">
                    <i class="las la-plus"></i>
                </button>
            </div>
        </form>

        <section>
            <p class="sectiontitle">Existing variants</p>
            <table class="table-view m-t-2">
                <tr>
                    <th>Variant</th>
                    <th class="td-small"></th>
                </tr>
                <tr *ngFor="let variant of variants">
                    <td>
                        <ng-container *ngIf="editingVariant?.uuid !== variant.uuid; else edit">
                            {{variant.jobTitle}}
                        </ng-container>
                        <ng-template #edit>
                            <form (ngSubmit)="updateVariant()" class="d-flex">
                                <input [(ngModel)]="editingVariantTitle" name="variantTitle" type="text">
                                <button class="button button--outline button--icon m-l-2">
                                    <i class="las la-pen"></i>
                                </button>
                            </form>
                        </ng-template>
                    </td>
                    <td class="td-icon">
                        <div class="dropdown" ngbDropdown>
                            <button
                                class="button button--icon button--small dropdown-toggle"
                                id="dropdownMenuButton"
                                ngbDropdownToggle
                                type="button"></button>
                            <div aria-labelledby="dropdownMenuButton" class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
                                <a (click)="editVariant(variant)" class="dropdown-item">
                                    Edit variant
                                </a>
                                <a (click)="openDeleteVariantModal(variant)" class="dropdown-item color--red">
                                    Delete
                                </a>
                            </div>
                        </div>
                    </td>
                </tr>
            </table>
        </section>

    </main>
    <footer class="modal-footer"></footer>
</div>
