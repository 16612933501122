import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subscription } from 'rxjs';
import { ModuleEnum } from 'src/app/models/ModuleEnum';
import Project from 'src/app/models/Project';
import User from 'src/app/models/User';
import { ModuleService } from 'src/app/services/module.service';
import { EmployeeAssignModalComponent } from 'src/app/shared/employee-assign-modal/employee-assign-modal.component';
import { GetUserById } from 'src/app/users/user.actions';

import { ProjectDeleteModalComponent } from '../project-delete-modal/project-delete-modal.component';
import { ProjectDetailModalComponent } from '../project-detail-modal/project-detail-modal.component';
import { FetchProjects } from '../project.actions';
import { ProjectState } from '../project.state';

@Component({
    selector: 'app-project-detail-employees',
    templateUrl: './project-detail-employees.component.html',
    styleUrls: ['./project-detail-employees.component.scss'],
})
export class ProjectDetailEmployeesComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Select(ProjectState.projects) projects$: Observable<Project[]>;
    @Input() project: Project;
    user: User;
    eModule = ModuleEnum;

    constructor(
        private route: ActivatedRoute,
        private store: Store,
        private modalService: NgbModal,
        public moduleService: ModuleService,
        private logger: NGXLogger,
    ) {}

    ngOnInit(): void {
        this.store.dispatch(new FetchProjects());
        this.subscriptions.add(
            this.route.params.subscribe((params) => {
                this.projects$.subscribe((projects) => (this.project = projects.filter((w) => w.uuid == params.id)[0]));
            }),
        );
        this.logger.debug('Fetching user');
        this.subscriptions.add(this.store.dispatch(new GetUserById(this.project?.userId)).subscribe((state) => (this.user = state.user.userById)));
    }

    openWorkUpdateModal(project: Project) {
        this.logger.debug('Opening projectDetailModal');
        const modalRef = this.modalService.open(ProjectDetailModalComponent);
        modalRef.componentInstance.project = project;
        modalRef.componentInstance.isUpdate = true;
    }

    openWorkDeleteModal(project: string) {
        this.logger.debug('Opening projectDeleteModal');
        const modalRef = this.modalService.open(ProjectDeleteModalComponent, {
            windowClass: 'modal-prompt',
        });
        modalRef.componentInstance.project = project;
    }

    openEmployeeAssignModal(object: any) {
        this.logger.debug('Opening assignEmployeeModal');
        const modalRef = this.modalService.open(EmployeeAssignModalComponent);
        modalRef.componentInstance.object = object;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
