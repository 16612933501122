import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subscription } from 'rxjs';
import Project from 'src/app/models/Project';
import { PlanningTimelineComponent } from 'src/app/planning/planning-timeline/planning-timeline.component';
import { FetchProducts } from 'src/app/products/product.actions';

import { ProjectDetailModalComponent } from '../project-detail-modal/project-detail-modal.component';
import { ProjectListType } from '../project-list/project-list.component';
import { FetchProjects, FetchProjectsForOverview } from '../project.actions';
import { ProjectState } from '../project.state';

@Component({
    selector: 'app-projects-overview',
    templateUrl: './projects-overview.component.html',
    styleUrls: ['./projects-overview.component.scss'],
})
export class ProjectsOverviewComponent implements OnInit, OnDestroy {
    view = 'timeline';
    projectListShown = true;
    archived: ProjectListType = ProjectListType.ARCHIVED;
    ongoing: ProjectListType = ProjectListType.ONGOING;
    finished: ProjectListType = ProjectListType.FINISHED;
    haveMaintenance: ProjectListType = ProjectListType.HAS_MAINTENANCE_CONTRACT;

    @Select(ProjectState.projects) projects$: Observable<Project[]>;
    finishedProjects: Project[] = [];
    archivedProjects: Project[] = [];
    projectsWithMaintenance: Project[] = [];
    @ViewChild(PlanningTimelineComponent)
    private planningTimelineComponent: PlanningTimelineComponent;
    private subscriptions = new Subscription();

    constructor(private store: Store, private modalService: NgbModal, private titleService: Title, private logger: NGXLogger) {
        this.titleService.setTitle('Projects overview');
    }

    ngOnInit(): void {
        this.subscriptions.add(
            this.projects$.subscribe((projects) => {
                if (!projects) {
                    this.logger.debug('Fetching user');
                    this.store.dispatch(new FetchProjectsForOverview());
                    return;
                }

                this.finishedProjects = projects.filter((project) => project.finished);
                this.archivedProjects = projects.filter((project) => project.archived);
                this.projectsWithMaintenance = projects.filter((project) => !project.archived && project.maintenanceContract);
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    openAddProjectModal() {
        const modalRef = this.modalService.open(ProjectDetailModalComponent, {
            windowClass: 'modal-pane',
            animation: false,
        });
        modalRef.componentInstance.inOverview = true;
    }

    fetchProjects() {
        this.store.dispatch(new FetchProjects());
        this.store.dispatch(new FetchProducts());
    }

    filterFinishedWorks(project): boolean {
        return project.finished;
    }

    filterOngoingWorks(project): boolean {
        return !project.finished;
    }

    filterActiveProjects(project): boolean {
        return !project.archived && !project.finished;
    }

    filterActiveProjectsWithMaintenance(project): boolean {
        return !project.archived && !project.finished && project.maintenanceContract;
    }

    calendarClick() {
        this.planningTimelineComponent.switchViewToCalendar();
    }

    timelineClick() {
        this.planningTimelineComponent.switchViewToTimeline();
    }

    switchView(type: string) {
        this.projectListShown = type === 'list';
        if (type === 'timeline') this.planningTimelineComponent.switchViewToTimeline();
        else if (type === 'calendar') this.planningTimelineComponent.switchViewToCalendar();
    }
}
