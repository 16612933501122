<div class="dashboard__tile__title">
    <span>Newest customers</span>
    <button
        type="button"
        class="button button--icon button--small"
        (click)="goToCustomers()">
        <i class="uil uil-arrow-right"></i>
    </button>
</div>
<div class="dashboard__tile__content">
    <div class="table__overflow">
        <table
            mat-table
            [dataSource]="dataSource"
            matSort
            [hidden]="!(this.dataSource?.data?.length > 0)"
            matSortActive="createdOn"
            matSortDirection="desc">
            <ng-container matColumnDef="customer">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header>
                    Customer
                </th>
                <td
                    mat-cell
                    *matCellDef="let customer">
                    <app-pill-customer [customer]="customer"></app-pill-customer>
                </td>
            </ng-container>

            <ng-container matColumnDef="createdOn">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header>
                    Added
                </th>
                <td
                    mat-cell
                    *matCellDef="let customer">
                    <span>{{ customer.createdOn | amTimeAgo }}</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="creator">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header>
                    Creator
                </th>
                <td
                    mat-cell
                    *matCellDef="let customer">
                    <app-pill-user [userId]="customer.creatorId"></app-pill-user>
                </td>
            </ng-container>

            <tr
                mat-header-row
                *matHeaderRowDef="displayedColumns"></tr>
            <tr
                mat-row
                *matRowDef="let customer; columns: displayedColumns"></tr>
        </table>
    </div>
    <div
        class="dashboard__tile__content"
        *ngIf="this.noCustomers && !this.isLoading">
        <app-message-empty
            [title]="'No new customers'"
            [subtitle]="'Start collecting customers!'"
            [icon]="'../../assets/img/open-box.png'"></app-message-empty>
    </div>

    <div *ngIf="isLoading">
        <app-loading-message></app-loading-message>
    </div>
</div>
