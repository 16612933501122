<app-app-holder>
    <app-navbar></app-navbar>

    <app-sidebar>
        <app-teamleader-subnav></app-teamleader-subnav>
    </app-sidebar>

    <app-content>
        <app-teamleader-not-logged-in (credentialsIsset)="checkCredentials($event)"></app-teamleader-not-logged-in>

        <div *ngIf="isset">
            <div class="app__title">
                <h1>Deals Overview</h1>
                <button
                    (click)="openAddDealModal()"
                    *appIsPermitted="{
                        module: 'TEAMLEADER',
                        operator: 'AND',
                        actions: ['CREATE']
                    }"
                    class="button button--primary w-100">
                    Add Deal
                </button>
            </div>

            <app-searchbar [(value)]="filter"></app-searchbar>

            <div
                class="m-t-4"
                *ngIf="this.isLoading">
                <app-loading-message></app-loading-message>
            </div>

            <div
                class="m-t-4"
                *ngIf="!this.isLoading">
                <table
                    class="table-view"
                    *appIsPermitted="{
                        module: 'TEAMLEADER',
                        operator: 'AND',
                        actions: ['READ']
                    }">
                    <thead>
                        <th>Title</th>
                        <th>Person Responsible</th>
                        <th>Lead</th>
                        <th>Value</th>
                        <th>Probability</th>
                        <th>Phase</th>
                        <th>Status</th>
                    </thead>
                    <tbody>
                        <tr *ngFor="let deal of this.deals | filterBy: ['title']:filter">
                            <td>{{ deal.title }}</td>
                            <td>
                                {{ getResponsibleUser(deal.responsible_user.id) }}
                            </td>
                            <td>
                                <p *ngIf="deal.lead.customer">
                                    <b>{{ tryGetNameFromId(deal.lead.customer) }} </b>
                                </p>
                                <p *ngIf="deal.lead.contact_person">
                                    {{ tryGetNameFromId(deal.lead.contact_person) }}
                                </p>
                            </td>
                            <td>
                                {{ deal.estimated_value.amount | currency: 'EUR' }}
                            </td>
                            <td>{{ deal.estimated_probability | percent }}</td>
                            <td>{{ getDealPhase(deal.current_phase.id) }}</td>
                            <td>{{ deal.status }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </app-content>
</app-app-holder>
