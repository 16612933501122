import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';

import { TeamleaderService } from '../../../teamleader/teamleader.service';

@Component({
    selector: 'app-teamleader-not-logged-in',
    templateUrl: './teamleader-not-logged-in.component.html',
    styleUrls: ['./teamleader-not-logged-in.component.scss'],
})
export class TeamleaderNotLoggedInComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();
    loginUrl: string;
    isset = true;
    @Output() credentialsIsset = new EventEmitter<boolean>();

    constructor(public activeModal: NgbActiveModal, private teamleaderService: TeamleaderService, private logger: NGXLogger) {}

    ngOnInit(): void {
        this.loginUrl = environment.frontEndBaseUrl + '/settings/module';

        this.logger.debug('Checking logged in with Teamleader');
        this.subscriptions.add(
            this.teamleaderService.getExistingCredentials().subscribe(
                (cred) => {
                    this.isset = true;
                    this.logger.debug('logged in with Teamleader');
                    this.credentialsIsset.emit(this.isset);
                },
                (err) => {
                    this.isset = false;
                    this.logger.debug('Not logged in with Teamleader');
                    this.credentialsIsset.emit(this.isset);
                },
            ),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
