import { Component, Input, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';
import { CompanyInvitedMember } from 'src/app/models/CompanyInvitedMember';

import { DeleteMemberInvite } from '../company.actions';

@Component({
    selector: 'app-company-invited-member-delete-modal',
    templateUrl: './company-invited-member-delete-modal.component.html',
    styleUrls: ['./company-invited-member-delete-modal.component.scss'],
})
export class CompanyInvitedMemberDeleteModalComponent implements OnDestroy {
    private subscriptions = new Subscription();

    @Input() inviteMember: CompanyInvitedMember;
    @Input() companyUuid: string;

    constructor(public activeModal: NgbActiveModal, private store: Store, private logger: NGXLogger) {}

    deleteInvite() {
        this.logger.debug('Attempt to Delete memberInvite');
        this.subscriptions.add(
            this.store.dispatch(new DeleteMemberInvite(this.inviteMember.uuid)).subscribe(
                () => {
                    this.logger.debug('Succesfully delete memberInvite');
                    this.activeModal.close();
                },
                () => this.logger.error('Failed to delete memberInvite'),
            ),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
