import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-message-empty',
    templateUrl: './message-empty.component.html',
    styleUrls: ['./message-empty.component.scss'],
})
export class MessageEmptyComponent {
    @Input() title: string;
    @Input() subtitle: string;
    @Input() htmlSubtitle: string;
    @Input() icon: string;
}
