import Budget from '../models/Budget';
import BudgetExpenditure from '../models/BudgetExpenditure';
import BudgetTemplate from '../models/BudgetTemplate';

export class AddBudgetTemplate {
    static readonly type = '[BudgetTemplate] Add BudgetTemplate';

    constructor(public budgetTemplate: BudgetTemplate) {}
}

export class AddBudget {
    static readonly type = '[Budget] Add Budget';

    constructor(public budget: Budget, public linkedEmployees: string[]) {}
}

export class AddBudgetExpenditure {
    static readonly type = '[BudgetExpenditure] Add BudgetExpenditure';

    constructor(public budgetId: string, public budgetExpenditure: BudgetExpenditure) {}
}

export class FetchAllBudgets {
    static readonly type = '[Budget] Fetch All';
}

export class FetchBudgetTemplates {
    static readonly type = '[BudgetTemplate] Fetch BudgetTemplates';
}

export class FetchEmployeesWithBudgets {
    static readonly type = '[EmployeesWithBudgets] Fetch Employees with budgets';
}

export class FetchEmployeeWithBudgets {
    static readonly type = '[EmployeeWithBudgets] Fetch Employee with budgets';

    constructor(public employeeId: string) {}
}

export class FetchArchivedBudgets {
    static readonly type = '[Budget] Fetch archived Budgets';
}

export class FetchBudgetsApproachingEndDate {
    static readonly type = '[Budget] Fetch Budgets approaching end date';
}

export class FetchBudgetById {
    static readonly type = '[Budget] Fetch Budget by Id';

    constructor(public uuid: string) {}
}

export class DeleteBudgetTemplate {
    static readonly type = '[BudgetTemplate] Delete BudgetTemplate';

    constructor(public uuid: string) {}
}

export class ArchiveBudget {
    static readonly type = '[Budget] Archive Budget';

    constructor(public uuid: string) {}
}

export class UpdateBudgetTemplate {
    static readonly type = '[BudgetTemplate] Update BudgetTemplate';

    constructor(public budgetTemplate: BudgetTemplate) {}
}

export class UpdateBudget {
    static readonly type = '[Budget] Update Budget';

    constructor(public budget: Budget, public linkedEmployees: string[]) {}
}

export class DeleteBudget {
    static readonly type = '[Budget] Delete Budget';
    constructor(public id: string) {}
}

export class UpdateBudgetExpenditure {
    static readonly type = '[BudgetExpenditure] Update BudgetExpenditure';

    constructor(public budgetExpenditure: BudgetExpenditure) {}
}

export class DeleteBudgetExpenditure {
    static readonly type = '[BudgetExpenditure] Delete BudgetExpenditure';

    constructor(public budget: Budget, public budgetExpenditure: BudgetExpenditure) {}
}

export class GetActualCostsForYear {
    static readonly type = '[Financial] Get actual costs';

    constructor(public year: number) {}
}

export class GetBudgetedCostsForYear {
    static readonly type = '[Financial] Get budgeted costs';

    constructor(public year: number) {}
}

// This action will be called from remove-financial-data.interceptor when necessary
export class RemoveCosts {
    static readonly type = '[Financial] Remove costs from state';
}
