import { Warehouse } from '../models/Warehouse';

export class FetchWarehouses {
    static readonly type = '[Warehouse] Fetch Warehouses';
}

export class AddWarehouse {
    static readonly type = '[Warehouse] Add Warehouse';
    constructor(public warehouse: Warehouse) {}
}

export class UpdateWarehouse {
    static readonly type = '[Warehouse] Update Warehouse';
    constructor(public warehouse: Warehouse) {}
}

export class ArchiveWarehouse {
    static readonly type = '[Warehouse] Archive Warehouse';
    constructor(public warehouseUuid: string) {}
}
