import { DragDropModule } from '@angular/cdk/drag-drop';
import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { NgbDropdownModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { TagInputModule } from 'ngx-chips';

import { SharedModule } from '../shared/shared.module';
import { AddCustomerModalComponent } from './add-customer-modal/add-customer-modal.component';
import { CustomerDetailComponent } from './customer-detail/customer-detail.component';
import { CustomersListComponent } from './customers-list/customers-list.component';
import { CustomersOverviewComponent } from './customers-overview/customers-overview.component';
import { routing } from './customers.routing';

@NgModule({
    declarations: [CustomersOverviewComponent, CustomersListComponent, CustomerDetailComponent, AddCustomerModalComponent],
    imports: [
        CommonModule,
        SharedModule,
        AutocompleteLibModule,
        DragDropModule,
        ReactiveFormsModule,
        routing,
        NgbModule,
        NgbDropdownModule,
        MatTableModule,
        TagInputModule,
        CdkTableModule,
        MatSortModule,
    ],
    exports: [CustomerDetailComponent, AddCustomerModalComponent],
})
export class CustomersModule {}
