import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { UpdateApplicantReasonOfLoss } from 'src/app/applicants/applicant.actions';
import ReasonOfLossModel from 'src/app/models/ReasonOfLossModel';

@Component({
    selector: 'app-edit-reason-modal',
    templateUrl: './edit-reason-modal.component.html',
    styleUrls: ['./edit-reason-modal.component.scss'],
})
export class EditReasonModalComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();
    @Input() reason: ReasonOfLossModel;
    @Input() type: string;

    reasonForm: FormGroup;
    updateFunction;

    constructor(private fb: FormBuilder, private store: Store, private toastr: ToastrService, public activeModal: NgbActiveModal, private logger: NGXLogger) { }

    ngOnInit(): void {
        this.reasonForm = this.fb.group({
            reason: [this.reason.reason, [Validators.required]],
        });
        switch (this.type) {
            case 'applicant':
                this.updateFunction = UpdateApplicantReasonOfLoss;
                break;
        }
    }

    submit() {
        this.store.dispatch(new this.updateFunction(this.reason.uuid, this.reasonForm.get('reason').value)).subscribe({
            next: () => {
                this.toastr.success('Reason successfully updated.');
                this.activeModal.close();
            },
            error: (error) => {
                this.logger.error(error);
                this.toastr.error('Oops, something went wrong. Please try again later.');
            },
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
