<form
    [formGroup]="statusFormGroup"
    (ngSubmit)="saveProject()"
    class="modal-wrapper">
    <div class="modal-header">
        <h2
            *ngIf="!isUpdate"
            class="modal-title"
            id="exampleModalLabel">
            New project status
        </h2>
        <h2
            *ngIf="isUpdate"
            class="modal-title"
            id="exampleModalLabel">
            Edit project status
        </h2>
        <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="activeModal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <div class="form-group required m-b-2">
            <label>Status title</label>
            <input
                type="text"
                formControlName="phase"
                (focus)="hideErrorMessage()"
                trim="blur"
                #focussed />
            <span
                class="form-error"
                *ngIf="statusFormGroup.controls.phase.errors && inputIsWrong"
                >status required</span
            >
            <span
                class="form-error"
                *ngIf="projectStatusWentWrong"
                >{{ errorMessage }}</span
            >
        </div>

        <app-color-palette-list
            (colour)="getColour($event)"
            [selectedColour]="selectedColour"></app-color-palette-list>
        <span
            class="form-error"
            *ngIf="colourWentWrong"
            >colour is required</span
        >
        <!-- <div class="form-group required m-b-2" >
            <label>Select a color</label>
            <ul class="form-group--colors">
                <li (click) = "onSelectColour(colour)" [class.selected] = "colour === selectedColour" *ngFor= "let colour of colours" [style.background-color]="colour"  [attr.Value]='colour'></li>
            </ul>
        </div> -->
    </div>

    <div class="modal-footer">
        <button
            type="button"
            class="button button--outline"
            (click)="activeModal.close()">
            Close
        </button>
        <button
            *ngIf="!isUpdate"
            type="submit"
            [ngClass]="{ 'button--loading': loading }"
            class="button button--primary">
            Add Status
        </button>
        <button
            *ngIf="isUpdate"
            type="submit"
            [ngClass]="{ 'button--loading': loading }"
            class="button button--primary">
            Edit Status
        </button>
    </div>
</form>
