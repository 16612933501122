<div class="d-flex nowrap">
    <div class="w-100">
        <app-searchbar
            [(value)]="filter"
            (valueChange)="applyFilter($event)"></app-searchbar>
    </div>
    <app-global-export-csv
        [exporter]="exporter"
        [componentName]="this.constructor.name"></app-global-export-csv>
    <span
        ngbDropdown
        class="dropdown m-l-2">
        <button
            ngbDropdownToggle
            class="button button--select">
            Options
        </button>
        <span
            ngbDropdownMenu
            class="dropdown-menu-right">
            <form>
                <a
                    ngbDropdownItem
                    [href]="productQrCodePDF"
                    download="qrcodes.pdf"
                    >Download QR codes</a
                >
                <a
                    ngbDropdownItem
                    (click)="openManageCategories()"
                    >Manage categories</a
                >
            </form>
        </span>
    </span>
</div>

<div class="m-t-4">
    <table
        mat-table
        [dataSource]="dataSource"
        matTableExporter
        #exporter="matTableExporter"
        matSort
        matSortActive="title"
        matSortDirection="asc">
        <ng-container matColumnDef="title">
            <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header>
                Title
            </th>
            <td
                mat-cell
                *matCellDef="let row">
                <app-pill-product [product]="row"></app-pill-product>
            </td>
        </ng-container>

        <ng-container matColumnDef="inStock">
            <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header>
                Stock
            </th>
            <td
                mat-cell
                *matCellDef="let row">
                <span
                    >{{ row.inStock }} <small class="color--muted">{{ row.unit?.type }}</small></span
                >
            </td>
        </ng-container>

        <ng-container matColumnDef="stockNotification">
            <th
                mat-header-cell
                *matHeaderCellDef>
                Health
            </th>
            <td
                mat-cell
                *matCellDef="let row">
                <span
                    class="badge badge--warning"
                    *ngIf="row.inStock < row.stockNotification && row.inStock > 0"
                    >Low</span
                >
                <span
                    class="badge badge--danger"
                    *ngIf="row.inStock === 0"
                    >Out of stock</span
                >
                <span
                    class="badge badge--success"
                    *ngIf="row.inStock >= row.stockNotification && row.inStock !== 0"
                    >Healthy</span
                >
            </td>
        </ng-container>

        <ng-container matColumnDef="lastModified">
            <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header>
                Created
            </th>
            <td
                mat-cell
                *matCellDef="let row">
                {{ row.lastModified | amTimeAgo }}
            </td>
        </ng-container>

        <ng-container matColumnDef="warehouseLocation.title">
            <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header>
                Warehouse
            </th>
            <td
                mat-cell
                *matCellDef="let row">
                <app-pill-warehouse-location
                    *ngIf="row.warehouseLocationUuid"
                    [warehouseLocationUuid]="row.warehouseLocationUuid"></app-pill-warehouse-location>
            </td>
        </ng-container>

        <ng-container matColumnDef="productCategories">
            <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header>
                Categories
            </th>
            <td
                mat-cell
                *matCellDef="let row">
                <span
                    *ngFor="let category of row.productCategories"
                    class="tag m-r-1"
                    >{{ category }}</span
                >
            </td>
        </ng-container>
        <ng-container matColumnDef="productConsumable">
            <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header>
                Consumable
            </th>
            <td
                mat-cell
                *matCellDef="let row">
                <span
                    class="badge badge--success"
                    *ngIf="row.productConsumable == 1"
                    >Yes</span
                >
                <span
                    class="badge badge--danger"
                    *ngIf="row.productConsumable == 0"
                    >No</span
                >
            </td>
        </ng-container>
        <ng-container matColumnDef="edit">
            <th
                mat-header-cell
                *matHeaderCellDef
                class="td-small"></th>
            <td
                mat-cell
                *matCellDef="let row">
                <span ngbDropdown>
                    <button
                        ngbDropdownToggle
                        class="button button--icon button--dropdown button--small"
                        (click)="$event.stopPropagation()"></button>
                    <span
                        ngbDropdownMenu
                        class="dropdown-menu dropdown-menu-right"
                        aria-labelledby="dropdownMenuButton">
                        <a
                            class="dropdown-item"
                            *ngIf="!row.productConsumable"
                            (click)="openStockUpdateModal(row); $event.stopPropagation()"
                            >Update stock</a
                        >
                        <a
                            class="dropdown-item"
                            *appIsPermitted="{
                                module: 'INVENTORY',
                                operator: 'AND',
                                actions: ['EDIT']
                            }"
                            (click)="openProductUpdateModal(row); $event.stopPropagation()"
                            >Edit product</a
                        >
                        <a
                            class="dropdown-item"
                            *appIsPermitted="{
                                module: 'INVENTORY',
                                operator: 'AND',
                                actions: ['ADD']
                            }"
                            (click)="openDuplicateProductAddModal(row); $event.stopPropagation()"
                            >Duplicate product</a
                        >
                        <a
                            class="dropdown-item"
                            (click)="getProductDetailPdf(row)"
                            >Export PDF</a
                        >
                        <a
                            class="dropdown-item color--red"
                            *appIsPermitted="{
                                module: 'INVENTORY',
                                operator: 'AND',
                                actions: ['DELETE']
                            }"
                            (click)="openProductDeleteModal(row); $event.stopPropagation()"
                            >Archive product</a
                        >
                    </span>
                </span>
            </td>
        </ng-container>

        <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns"></tr>
        <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns"
            class="pointer"
            (click)="openProductDetailModal(row); $event.stopPropagation()"></tr>

        <tr *matNoDataRow>
            <td colspan="7">No products matching the filter "{{ filter }}"</td>
        </tr>
    </table>
</div>
