export class ROUTES {
    static readonly EMPLOYEES = new ROUTES('/employees');
    static readonly PROJECTS = new ROUTES('/projects');
    static readonly BUDGETS = new ROUTES('/budget');
    static readonly BUDGET_TEMPLATES = new ROUTES('/budget/templates');
    static readonly CONTACTS = new ROUTES('/contacts');
    static readonly COMPANIES = new ROUTES('/partners');
    static readonly CRM = new ROUTES('/crm');
    static readonly CUSTOMERS = new ROUTES('/customers');
    static readonly PARTNERS = new ROUTES('/partners');
    static readonly INVENTORY = new ROUTES('/inventory');
    static readonly TIMESHEETS = new ROUTES('/timesheets');

    private constructor(public readonly route: string) {}
}
