import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { append, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { tap } from 'rxjs/operators';

import Customer from '../models/Customer';
import { ROUTES } from '../models/ROUTES';
import { CreateNotification } from '../notifications/notifications.actions';
import { AddUndefinedObjectTagsToObject } from '../shared/global-tags/global-tags.actions';
import { AddCustomer, DeleteCustomer, FetchCustomers, UpdateCustomer } from './customers.actions';
import { CustomerService } from './customers.service';

export interface CustomerStateModel {
    customers: Customer[];
}

@State<CustomerStateModel>({
    name: 'customers',
    defaults: {
        customers: null,
    },
})
@Injectable()
export class CustomerState {
    @Selector()
    static Customers(state: CustomerStateModel): Customer[] {
        return state?.customers;
    }

    constructor(private customerService: CustomerService, private store: Store) {}

    @Action(FetchCustomers)
    fetchCustomers(ctx: StateContext<CustomerStateModel>) {
        return this.customerService.getCustomers().subscribe((customers) => {
            ctx.setState(
                patch<CustomerStateModel>({
                    customers: customers,
                }),
            );
        });
    }

    @Action(AddCustomer)
    addCustomer(ctx: StateContext<CustomerStateModel>, action: AddCustomer) {
        return this.customerService.addCustomer(action.customer).pipe(
            tap((customer) => {
                ctx.setState(
                    patch<CustomerStateModel>({
                        customers: append([customer]),
                    }),
                );
                this.store.dispatch(new AddUndefinedObjectTagsToObject(customer.uuid));
                this.store.dispatch(
                    new CreateNotification({
                        message: `A customer with name <strong>${customer.firstName} ${customer.lastName}</strong> is added`,
                        route: ROUTES.CONTACTS.route,
                    }),
                );
            }),
        );
    }

    @Action(UpdateCustomer)
    updateCustomer(ctx: StateContext<CustomerStateModel>, action: UpdateCustomer) {
        return this.customerService.updateCustomers(action.customer).pipe(
            tap((customer) => {
                ctx.setState(
                    patch<CustomerStateModel>({
                        customers: updateItem((c) => c.uuid === customer.uuid, customer),
                    }),
                );
                this.store.dispatch(
                    new CreateNotification({
                        message: `A customer with name <strong>${customer.firstName} ${customer.lastName}</strong> is updated`,
                        route: ROUTES.CONTACTS.route,
                    }),
                );
            }),
        );
    }

    @Action(DeleteCustomer)
    deleteCustomer(ctx: StateContext<CustomerStateModel>, action: DeleteCustomer) {
        return this.customerService.deleteCustomer(action.customerUuid).pipe(
            tap((result) => {
                if (result) {
                    const customer: Customer = ctx.getState().customers.find((customer) => customer.uuid === action.customerUuid);
                    ctx.setState(
                        patch<CustomerStateModel>({
                            customers: removeItem((c) => c.uuid === action.customerUuid),
                        }),
                    );
                    this.store.dispatch(
                        new CreateNotification({
                            message: `A customer with name <strong>${customer.firstName} ${customer.lastName}</strong> is deleted`,
                            route: ROUTES.CONTACTS.route,
                        }),
                    );
                }
            }),
        );
    }
}
