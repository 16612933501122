import { Component, Input, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { endOfYear, format, startOfYear } from 'date-fns';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';

import { OverheadCategory } from '../../models/OverheadCategory';
import { LinkedInvoicesToObjectComponent } from '../../shared/global-files/linked-invoices-to-object/linked-invoices-to-object.component';
import { UpdateOverheadCategoryNameModalComponent } from '../update-overhead-category-name-modal/update-overhead-category-name-modal.component';
import { GetOverheadCategoryById } from '../yuki.actions';
import { YukiState } from '../yuki.state';

@Component({
    selector: 'app-overhead-category-detail-modal',
    templateUrl: './overhead-category-detail-modal.component.html',
    styleUrls: ['./overhead-category-detail-modal.component.scss'],
})
export class OverheadCategoryDetailModalComponent implements OnInit, OnDestroy {
    @Input() overheadCategoryId: string;
    @Input() openFinancialTab = false;
    @Select(YukiState.overheadCategoryById) overheadCategory$: Observable<(id: string) => OverheadCategory>;
    overheadCategory: OverheadCategory;
    startDateFilter: string = format(startOfYear(new Date()), 'yyyy-MM-dd');
    endDateFilter: string = format(endOfYear(new Date()), 'yyyy-MM-dd');

    @ViewChildren(LinkedInvoicesToObjectComponent) linkedInvoicesToObjectComponents: QueryList<LinkedInvoicesToObjectComponent>;

    activeNavTab = 'information';

    private subscriptions = new Subscription();

    constructor(public activeModal: NgbActiveModal, private modalService: NgbModal, private toastr: ToastrService, private store: Store) {}

    ngOnInit(): void {
        if (this.openFinancialTab) this.activeNavTab = 'financial';

        this.subscriptions.add(
            this.overheadCategory$.subscribe((overheadCategory) => {
                this.overheadCategory = overheadCategory(this.overheadCategoryId);

                if (!this.overheadCategory) {
                    this.store.dispatch(new GetOverheadCategoryById(this.overheadCategoryId));
                }
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    openUpdateCategoryNameModal() {
        const modalService = this.modalService.open(UpdateOverheadCategoryNameModalComponent, {
            windowClass: 'modal-prompt',
            animation: false,
        });
        modalService.componentInstance.overheadCategory = JSON.parse(JSON.stringify(this.overheadCategory));
    }

    filterDate() {
        this.linkedInvoicesToObjectComponents.forEach((linkedInvoicesToObjectComponent) =>
            linkedInvoicesToObjectComponent.filterOnDate(new Date(this.startDateFilter), new Date(this.endDateFilter)),
        );
    }
}
