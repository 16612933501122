import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import Lead from 'src/app/models/Lead';
import RecipientListModel from 'src/app/models/RecipientListModel';
import { UserState } from 'src/app/users/user.state';

import BatchAddLeadsModel from '../batch-add-leads/batchAddLeadsModel';
import { FetchRecipients } from '../mailchimp.actions';
import { MailchimpService } from '../mailchimp.service';

@Component({
    selector: 'app-batch-add-leads',
    templateUrl: './batch-add-leads.component.html',
    styleUrls: ['./batch-add-leads.component.scss'],
})
export class BatchAddLeadsComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();
    @Select(UserState.userCompanyId) companyUuid$: Observable<string>;

    @Input() private list: RecipientListModel;

    private leads: Lead[];

    channels = [];
    mailchimpTags: string[] = [];

    constructor(public activeModal: NgbActiveModal, private mailchimpService: MailchimpService, private store: Store, private toastr: ToastrService) {}

    ngOnInit(): void {
        this.getInfo();
    }

    async getInfo() {
        this.subscriptions.add(
            this.mailchimpService.getTags(this.list.id).subscribe((t) => {
                t.forEach((element) => {
                    this.mailchimpTags.push(element.name);
                });
            }),
        );
        this.subscriptions.add(
            this.companyUuid$.subscribe((id: any) => {
                this.mailchimpService.getLeads(id).subscribe((leads) => {
                    this.leads = leads;
                    this.leads.forEach((element) => {
                        if (this.channels.filter((channel) => channel === element.channelKey).length === 0) this.channels.push(element.channelKey);
                    });
                });
            }),
        );
    }

    addLeads() {
        const model: BatchAddLeadsModel = {
            listId: this.list.id,
            channels: this.channels,
            tags: this.mailchimpTags,
        };
        this.subscriptions.add(
            this.mailchimpService.batchAddLeads(model).subscribe((result) => {
                if (result) {
                    this.store.dispatch(new FetchRecipients(this.list.id)).subscribe();
                    this.toastr.success('List updated successfully!');
                    this.activeModal.dismiss();
                }
            }),
        );
    }

    onAddChannel(channel: any) {
        this.channels.push(channel.value);
    }

    onRemovingChannel(channel: any) {
        const index = this.channels.indexOf(channel);
        this.channels.splice(index, 1);
    }

    onAddTag(tag: any) {
        this.mailchimpTags.push(tag.value);
    }

    onRemovingTag(tag: any) {
        const index = this.mailchimpTags.indexOf(tag);
        this.mailchimpTags.splice(index, 1);
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
