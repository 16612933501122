<span>CONFIGURATION</span>
<ul>
    <li>City: {{ fields?.zipCode }} {{ fields?.city }}</li>
    <li>Pool Type: {{ fields?.poolType }}</li>
    <li>Measures: {{ fields?.height }} x {{ fields?.width }}</li>
    <li>Pool Stairs: {{ fields?.poolStairs }}</li>
    <li>Pool Color: {{ fields?.poolColor }}</li>
    <li>Finish: {{ fields?.finish }}</li>
    <li>Pool Lighting: {{ fields?.poolLighting }}</li>
    <li>Cover: {{ fields?.cover }}</li>
    <li>Heating: {{ fields?.heating }}</li>
    <li>Cleaning: {{ fields?.cleaning }}</li>
    <li>Maintenanceplan: {{ fields?.maintenancePlan }}</li>
</ul>
