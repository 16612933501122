<span *ngIf="product">
    <app-loading-message *ngIf="isLoading"></app-loading-message>
    <span *ngIf="!isEditing && !isLoading">
        <p
            *ngFor="let productCategory of productCategories"
            class="tag m-r-1">
            {{ productCategory }}
        </p>
        <button
            type="button"
            class="button button--outline button--small button--icon"
            (click)="switchEdit()">
            <i class="las la-plus"></i>
        </button>
    </span>
    <span *ngIf="isEditing && !isLoading">
        <div class="d-flex">
            <tag-input
                [(ngModel)]="productCategories"
                (onAdd)="add($event)"
                (onRemove)="remove($event)"
                [clearOnBlur]="true"
                placeholder="Enter a category name">
                <tag-input-dropdown
                    [autocompleteItems]="allCategories"
                    [appendToBody]="false">
                </tag-input-dropdown>
            </tag-input>
            <button
                type="button"
                class="button button--success button--icon m-l-1"
                (click)="switchEdit()">
                <i class="las la-check"></i>
            </button>
        </div>
        <span class="form-helptext">Hit ENTER to add categories.</span>
    </span>
</span>

<span *ngIf="!product">
    <app-loading-message *ngIf="isLoading"></app-loading-message>
    <span *ngIf="!isLoading">
        <tag-input
            [(ngModel)]="undefinedProductCategories"
            (onAdd)="add($event)"
            (onRemove)="remove($event)"
            [clearOnBlur]="true"
            placeholder="Enter a category name">
            <tag-input-dropdown
                [autocompleteItems]="allCategories"
                [appendToBody]="false">
            </tag-input-dropdown>
        </tag-input>
        <span class="form-helptext">Hit ENTER to add categories.</span>
    </span>
</span>
