export class BUDGETREPEAT {
    static readonly DAILY = new BUDGETREPEAT('Daily', 'DAILY');
    static readonly WEEKLY = new BUDGETREPEAT('Weekly', 'WEEKLY');
    static readonly MONTHLY = new BUDGETREPEAT('Monthly', 'MONTHLY');
    static readonly SIX_MONTHS = new BUDGETREPEAT('Every six months', 'SIX_MONTHS');
    static readonly YEARLY = new BUDGETREPEAT('Yearly', 'YEARLY');

    private constructor(public readonly title: string, public readonly value: string) {}

    static findRepeat(value: string): BUDGETREPEAT {
        if (value.includes(BUDGETREPEAT.DAILY.value)) {
            return BUDGETREPEAT.DAILY;
        } else if (value.includes(BUDGETREPEAT.WEEKLY.value)) {
            return BUDGETREPEAT.WEEKLY;
        } else if (value.includes(BUDGETREPEAT.MONTHLY.value)) {
            return BUDGETREPEAT.MONTHLY;
        } else if (value.includes(BUDGETREPEAT.SIX_MONTHS.value)) {
            return BUDGETREPEAT.SIX_MONTHS;
        } else if (value.includes(BUDGETREPEAT.YEARLY.value)) {
            return BUDGETREPEAT.YEARLY;
        }
    }
}
