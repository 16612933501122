import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Subscription, of } from 'rxjs';
import User from 'src/app/models/User';
import { EnvironmentService } from 'src/app/services/environment.service';
import { ActionsOnStartup, ClearStore, Login, ResendVerification } from 'src/app/users/user.actions';
import { UserService } from 'src/app/users/user.service';

import { LoginMfaModalComponent } from '../login-mfa-modal/login-mfa-modal.component';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    providers: [UserService],
})
export class LoginComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    // Declare properties
    user: User;
    loading = false;
    inputIsWrong = false;
    loginWentWrong = false;
    loginCount = 0;
    LOGIN_COUNT_MAX = 5;
    loginFormGroup: FormGroup;
    errorMessage: string;
    connectionError: string;
    verificationWentWrong = false;
    redirectUrl: string;
    environment: any;

    constructor(
        private router: Router,
        private formBuilder: FormBuilder,
        private toastr: ToastrService,
        private store: Store,
        private route: ActivatedRoute,
        private environmentService: EnvironmentService,
        private logger: NGXLogger,
        private modalService: NgbModal,
    ) {}

    // Declare form values and validators
    ngOnInit() {
        this.redirectUrl = this.route.snapshot.queryParams['redirectURL'] || '/';

        this.logger.log(this.redirectUrl);
        this.loginFormGroup = this.formBuilder.group({
            email: ['', [Validators.email, Validators.required]],
            password: ['', [Validators.minLength(8), Validators.required]],
        });
        this.subscriptions.add(
            this.environmentService.getCurrentEnvironment().subscribe((env) => {
                this.environment = env;
            }),
        );
    }

    // Logs the user in
    login() {
        this.logger.debug('Login attempt');
        this.loading = true;
        this.redirectUrl = this.route.snapshot.queryParams['redirectURL'];

        // Check if form data fulfills the validators
        if (!this.loginFormGroup.valid) {
            this.inputIsWrong = true;
            this.loading = false;
            return;
        }

        // Check how times the user has tried to log in with a wrong password
        if (this.loginCount === this.LOGIN_COUNT_MAX) {
            this.logger.debug(`User tried logging in ${this.LOGIN_COUNT_MAX} times with wrong password`);
            this.router.navigate(['/', 'loginattemptfailed']);
        }

        // Disable all form fields
        this.loginFormGroup.disable();

        this.user = this.loginFormGroup.value;

        this.store.dispatch(new ClearStore());

        this.subscriptions.add(
            this.store.dispatch(new Login(this.user)).subscribe(
                (data) => {
                    if (data.user.user.authenticated) {
                        this.logger.debug('Opening mfa login modal');
                        const modalRef = this.modalService.open(LoginMfaModalComponent, {
                            windowClass: 'modal-prompt',
                            animation: false,
                        });
                        modalRef.componentInstance.user = this.user;
                        modalRef.componentInstance.redirectUrl = this.redirectUrl;
                        modalRef.componentInstance.router = this.router;
                        this.loginFormGroup.enable();
                        this.loading = false;
                    } else {
                        this.logger.debug('Login succesful');

                        this.store.dispatch(new ActionsOnStartup());

                        // Check for redirect url otherwise go to resume overview
                        if (this.redirectUrl) {
                            if (this.redirectUrl.includes('mfaEnabled')) {
                                this.redirectUrl = '/dashboard';
                            }
                            this.router
                                .navigate([this.redirectUrl])
                                .catch(() => this.router.navigate(['/dashboard'], { queryParams: { mfaEnabled: 'false' } }));
                        } else {
                            this.router.navigate(['/dashboard'], { queryParams: { mfaEnabled: 'false' } });
                        }
                    }
                },
                (error) => {
                    this.logger.error('Login failed');

                    this.errorMessage = error?.error?.message ?? 'Error message was empty';

                    this.loginCount++;
                    this.loginWentWrong = true;
                    this.loading = false;

                    if (this.errorMessage == 'please verify your email') {
                        this.verificationWentWrong = true;
                    }
                    this.loginFormGroup.controls.password.reset();

                    this.loginFormGroup.enable();

                    return of(null);
                },
            ),
        );
    }

    resendVerification(email: string) {
        this.loading = true;
        this.subscriptions.add(
            this.store.dispatch(new ResendVerification(email)).subscribe(
                () => {
                    this.logger.debug('Resent verification email successfully');
                    this.toastr.success('Mail resend succesfully');
                },
                (error) => {
                    this.logger.error('Failed to resend verification email');
                    this.errorMessage = error?.error?.message ?? 'Error message was empty';
                    this.loginWentWrong = true;
                    this.loading = false;
                    this.loginFormGroup.controls.password.reset();
                    this.loginFormGroup.enable();
                },
            ),
        );
    }

    // Hides error messages
    hideErrorMessage() {
        this.inputIsWrong = false;
        this.verificationWentWrong = false;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
