<div class="modal-header">
    <h2 class="modal-title">Invoices settings</h2>

    <button
        (click)="activeModal.dismiss()"
        aria-label="Close"
        class="close"
        type="button">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<nav
    #invoiceSettingsTabs="ngbNav"
    class="tabs__modal"
    ngbNav>
    <ng-container ngbNavItem>
        <a ngbNavLink>Yuki settings</a>
        <ng-template ngbNavContent>
            <app-yuki-login></app-yuki-login>
            <app-yuki-settings></app-yuki-settings>
        </ng-template>
    </ng-container>

    <ng-container ngbNavItem>
        <a ngbNavLink>Removed documents</a>
        <ng-template ngbNavContent>
            <button
                (click)="checkForDeletedDocuments()"
                [ngClass]="{ 'button--refresh': isSyncingDocuments }"
                class="button button--icon m-r-2"
                id="refresh-btn"
                type="button">
                <i class="las la-sync"></i>
            </button>
            <app-simple-document-list [documents]="removedFiles$ | async"></app-simple-document-list>
        </ng-template>
    </ng-container>

    <ng-container
        *ngIf="false"
        ngbNavItem>
        <a ngbNavLink>Automatic rules</a>
        <ng-template ngbNavContent> </ng-template>
    </ng-container>

    <ng-container ngbNavItem>
        <a ngbNavLink>Overhead categories</a>
        <ng-template ngbNavContent>
            <app-overhead-categories></app-overhead-categories>
        </ng-template>
    </ng-container>

    <ng-container ngbNavItem>
        <a ngbNavLink>Mapped contacts</a>
        <ng-template ngbNavContent>
            <app-mapped-yuki-contacts></app-mapped-yuki-contacts>
        </ng-template>
    </ng-container>
</nav>

<div class="modal-body">
    <div [ngbNavOutlet]="invoiceSettingsTabs"></div>
</div>
