<div class="m-t-4">
    <div class="d-flex space-between align-items-end">
        <div class="d-flex m-r-2">
            <div class="form-group m-r-2">
                <label for="createdOn">Created on</label>
                <input
                    [(ngModel)]="createdOnDateFilter"
                    class="input--bg-transparant"
                    id="createdOn"
                    type="date" />
            </div>
        </div>
        <div class="w-100 m-r-2">
            <app-searchbar class="col-10" (valueChange)="filter = $event"></app-searchbar>
        </div>
    </div>

    <div *ngIf="lanes$ | async as lanes; else loading">
        <div
            class="kanban m-t-4"
            cdkDropListGroup
            *ngIf="lanes.length > 0; else empty">
            <div

                [ngStyle]="getCorrectStyling(lane.color)"
                class="kanban__lane"
                *ngFor="let lane of lanes | orderBy: 'sequence':false:true">
                <div class="kanban__lane__title">
                    <span>
                        <strong><app-pill-colored-string [label]="lane.name" [color]="lane.color"></app-pill-colored-string> </strong>
                        <span>{{lane.applicants.length}}</span>
                    </span>
                    <small data-toggle="tooltip"> {{ lane.probability }}% chance </small>
                </div>

                <div
                    class="kanban__lane__items"
                    cdkDropList
                    id="{{ lane.uuid }}"
                    [cdkDropListData]="lane.applicants"
                    (cdkDropListDropped)="drop($event)">
                    <div
                        (click)="openApplicantDetailModal(applicant)"
                        class="kanban__item"
                        cdkDrag
                        *ngFor="let applicant of getSortedApplicants(lane) | filterApplicants: filter | filterApplicantsByCreatedDate: createdOnDateFilter"
                        #dragItem1>
                        <app-applicant-kanban-item
                            [applicant]="applicant"
                            [lane]="lane">
                        </app-applicant-kanban-item>
                    </div>
                </div>

                <div
                    class="kanban__add"
                    *appIsPermitted="{
                        module: 'PARTNERS',
                        operator: 'AND',
                        actions: ['CREATE']
                    }"
                    (click)="openAddApplicantModal(lane)">
                    <i class="las la-plus"></i> New applicant
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #empty>
    <app-message-empty
        [title]="'No applicant lanes...'"
        [subtitle]="'Start adding lanes to manage your applicants. Lanes are the status a applicant is in.'"
        [icon]="'../../assets/img/open-box.png'">
        <a
            class="button button--primary"
            *appIsPermitted="{
                module: 'PARTNERS',
                operator: 'AND',
                actions: ['CREATE']
            }"
            (click)="openAddLaneModal()"
            >Add first lane</a
        >
    </app-message-empty>
</ng-template>

<ng-template #loading>
    <app-loading-message></app-loading-message>
</ng-template>
