import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { ExactLoginService } from 'src/app/shared/login-components/exact-login/exact-login.service';
import { OutlookLoginService } from 'src/app/shared/login-components/outlook-login/outlook-login.service';
import { TeamleaderService } from 'src/app/teamleader/teamleader.service';

@Component({
    selector: 'app-settings-token-retrieval',
    templateUrl: './settings-token-retrieval.component.html',
    styleUrls: ['./settings-token-retrieval.component.scss'],
})
export class SettingsTokenRetrievalComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    type: string;
    authenticationResult: string = null;
    title = '';

    constructor(
        private teamleaderService: TeamleaderService,
        private route: ActivatedRoute,
        private outlookService: OutlookLoginService,
        private exactService: ExactLoginService,
        private logger: NGXLogger,
        private router: Router,
        private toastr: ToastrService,
    ) {}

    ngOnInit(): void {
        const code = this.route.snapshot.queryParamMap.get('code');
        this.type = this.route.snapshot.queryParamMap.get('type');

        this.title = `${this.type} authentication `;

        if (this.type === 'exact') {
            this.logger.debug('Saving exact accessToken');
            this.subscriptions.add(
                this.exactService.saveAccesToken(code).subscribe(
                    (res) => {
                        this.loginSuccessFull();
                    },
                    (err) => {
                        this.authenticationResult = 'Failed';
                        console.log(err);
                    },
                ),
            );
        }

        if (this.type === 'teamleader') {
            this.logger.debug('Saving teamleader accessToken');
            this.subscriptions.add(
                this.teamleaderService.saveAccessTokenByCode(code).subscribe(
                    (res) => {
                        this.loginSuccessFull();
                    },
                    (err) => (this.authenticationResult = 'Failed'),
                ),
            );
        }

        if (this.type === 'outlook') {
            this.logger.debug('Saving outlook accessToken');
            this.subscriptions.add(
                this.outlookService.saveAccessTokenByCode(code).subscribe(
                    (res) => {
                        if (res) {
                            this.loginSuccessFull();
                        } else {
                            this.authenticationResult = 'Failed';
                        }
                    },
                    (err) => (this.authenticationResult = 'Failed'),
                ),
            );
        }
    }

    loginSuccessFull() {
        this.authenticationResult = 'Successful';
        this.toastr.success('Login successfull');
        this.router.navigate(['/settings/module']);
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
