import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { AuthenticationService } from '../authentication/authentication.service';
import MailingObject from '../models/MailingObject';
import { Module } from '../models/Module';

@Injectable({
    providedIn: 'root',
})
export class ModuleMaintenanceService {
    httpOptions = {};

    constructor(private httpClient: HttpClient, private authenticationService: AuthenticationService) {
        this.httpOptions = authenticationService.getHeaders();
    }

    getAllModules() {
        return this.httpClient.get<Module[]>(environment.baseUrl + '/module', this.httpOptions);
    }

    activateModule(companyUuid: string, moduleUuid: string): Observable<Module> {
        return this.httpClient.put<Module>(environment.baseUrl + `/module/${companyUuid}/${moduleUuid}/activate-module`, {}, this.httpOptions);
    }

    getMailingObjectsByModule(moduleName: string): Observable<MailingObject[]> {
        return this.httpClient.get<MailingObject[]>(environment.baseUrl + `/module/${moduleName}/mailObjects`, this.httpOptions);
    }

    addMailToModule(moduleUuid: string, mail: MailingObject): Observable<MailingObject> {
        let emailsString = '';
        mail.emails.forEach((mail) => (emailsString += mail + ';'));
        emailsString = emailsString.slice(0, -1);
        const json = {
            uuid: mail.uuid,
            emails: emailsString,
            mailingType: mail.mailingType,
            cron: mail.cron,
        };
        return this.httpClient.put<MailingObject>(environment.baseUrl + `/module/${moduleUuid}/addMail`, json, this.httpOptions);
    }

    removeMailFromModule(mailingObjectUuid: string): Observable<boolean> {
        return this.httpClient.delete<boolean>(environment.baseUrl + `/module/${mailingObjectUuid}/removeMail`, this.httpOptions);
    }
}
