import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ChartDataset, ChartOptions, ChartType } from 'chart.js';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { BaseChartDirective } from 'ng2-charts';
import { Subscription } from 'rxjs';
import ReasonOfLossModel from 'src/app/models/ReasonOfLossModel';

@Component({
    selector: 'app-reason-of-loss-graph',
    templateUrl: './reason-of-loss-graph.component.html',
    styleUrls: ['./reason-of-loss-graph.component.scss'],
})
export class ReasonOfLossGraphComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();
    @ViewChild(BaseChartDirective) baseChart: BaseChartDirective;

    @Input() reasons: ReasonOfLossModel[];

    isLoading = true;

    xArray: string[] = [];
    yArray: number[] = [];
    public barChartOptions: ChartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            datalabels: {
                anchor: 'end',
                align: 'end',
            },
        },
    };
    public barChartLabels: string[] = this.xArray;
    public barChartType: ChartType = 'bar';
    public barChartLegend = false;
    public barChartPlugins = [pluginDataLabels];
    public barChartData: ChartDataset[] = [
        {
            data: this.yArray,
            borderColor: '#006EFF',
            borderWidth: 1,
            backgroundColor: 'rgba(0,110,255,.25)',
            hoverBackgroundColor: 'rgba(0,110,255,.75)',
            hoverBorderColor: 'rgba(0,110,255,1)',
            label: 'recurrence',
        },
    ];

    ngOnInit(): void {
        this.barChartData = [
            {
                data: this.reasons.map((r) => r.recurrence),
                borderColor: '#006EFF',
                borderWidth: 1,
                backgroundColor: 'rgba(0,110,255,.25)',
                hoverBackgroundColor: 'rgba(0,110,255,.75)',
                hoverBorderColor: 'rgba(0,110,255,1)',
                label: 'recurrence',
            },
        ];
        this.barChartLabels = this.reasons.map((r) => r.reason);
        this.isLoading = false;
        this.baseChart?.chart.update();
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
