import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Select } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subscription } from 'rxjs';
import DateHelper from 'src/app/services/DateHelper';
import { CommentState } from 'src/app/shared/comments/comments.state';

import Comment from '../../models/Comment';

@Component({
    selector: 'app-dashboard-comments-widget',
    templateUrl: './dashboard-comments-widget.component.html',
    styleUrls: ['./dashboard-comments-widget.component.scss'],
})
export class DashboardCommentsWidgetComponent implements OnInit, OnDestroy {
    @Select(CommentState.LatestComments) comments$: Observable<Comment[]>;
    isLoading = true;
    noComments = false;
    timezone = DateHelper.getLocalTimeZone();
    displayedColumns: string[] = ['creator.firstName', 'content', 'category', 'commentable', 'createdOn'];
    dataSource: MatTableDataSource<Comment>;
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    private subscriptions = new Subscription();

    constructor(private logger: NGXLogger) {}

    ngOnInit(): void {
        this.subscriptions.add(
            this.comments$.subscribe(
                (latestComments: Comment[]) => {
                    if (latestComments && latestComments.length == 0) {
                        this.logger.debug('Retrieved 0 comments from the state');
                        this.noComments = true;
                        this.isLoading = false;
                    } else if (latestComments && latestComments.length > 0) {
                        this.logger.debug('Retrieved comments from the state');
                        this.dataSource = new MatTableDataSource(latestComments);
                        this.dataSource.sort = this.sort;
                        this.isLoading = false;
                    }
                },
                (error) => {
                    this.logger.error('Failed to retrieve latest comments');
                },
            ),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
