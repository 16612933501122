import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbNavModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { SharedModule } from '../shared/shared.module';
import { NotificationMessageComponent } from './notification-message/notification-message.component';
import { NotificationModalComponent } from './notification-modal/notification-modal.component';

@NgModule({
    declarations: [NotificationModalComponent, NotificationMessageComponent],
    imports: [CommonModule, SharedModule, NgbTooltipModule, NgbNavModule],
})
export class NotificationsModule {}
