import { Component, Input } from '@angular/core';
import { GlobalTag } from 'src/app/models/GlobalTag';

@Component({
    selector: 'app-pill-global-tag',
    templateUrl: './pill-global-tag.component.html',
    styleUrls: ['./pill-global-tag.component.scss'],
})
export class PillGlobalTagComponent {
    @Input() tag: GlobalTag;

    getLength(tag: GlobalTag): string | null {
        if (tag.taggables && tag.taggables.length > 0) {
            return '(' + tag.taggables.length + ')';
        }
    }
}
