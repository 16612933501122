import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';
import { Resume } from 'src/app/models/resume/Resume';

import { ArchiveResume } from '../resume.actions';

@Component({
    selector: 'app-resume-delete-modal',
    templateUrl: './resume-delete-modal.component.html',
    styleUrls: ['./resume-delete-modal.component.scss'],
})
export class ResumeDeleteModalComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Input() resume: Resume;

    constructor(public activeModal: NgbActiveModal, private store: Store, private router: Router, private logger: NGXLogger) {}

    ngOnInit(): void {}

    archiveResume() {
        this.logger.debug('Attempt to archive resume');
        this.subscriptions.add(
            this.store.dispatch(new ArchiveResume(this.resume.uuid)).subscribe(
                () => {
                    this.logger.debug('Succesfully archived resume');
                    this.activeModal.close();
                    this.logger.debug('Navigating to resume overview');
                    this.router.navigate(['/resume/overview']);
                },
                (error) => this.logger.error('Failed to archive resume'),
            ),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
