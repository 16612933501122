import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import ChangeTagModel from 'src/app/mailchimp/mailchimp-recipients-modal/change-tag-model';
import MailchimpTagModel from 'src/app/models/MailchimpTagModel';
import RecipientListModel from 'src/app/models/RecipientListModel';
import { environment } from 'src/environments/environment';

import { AuthenticationService } from '../../authentication/authentication.service';
import AddNewRecipientModel from './add-recipient-modal/AddNewRecipientModel';

@Injectable({
    providedIn: 'root',
})
export class RecipientService {
    httpOptions = {};

    constructor(private httpClient: HttpClient, private authenticationService: AuthenticationService) {
        this.httpOptions = authenticationService.getHeaders();
    }

    addLead(body: AddNewRecipientModel) {
        return this.httpClient.post<boolean>(environment.baseUrl + `/mailchimp/addNewRecipient`, JSON.parse(JSON.stringify(body)), this.httpOptions);
    }

    getLists() {
        return this.httpClient.get<RecipientListModel[]>(environment.baseUrl + '/mailchimp/lists', this.httpOptions);
    }

    getTags(listId: any) {
        return this.httpClient.get<MailchimpTagModel[]>(environment.baseUrl + `/mailchimp/${listId}/tags`, this.httpOptions);
    }

    updateTags(body: ChangeTagModel) {
        return this.httpClient.put<boolean>(environment.baseUrl + `/mailchimp/changeTag`, JSON.parse(JSON.stringify(body)), this.httpOptions);
    }

    addRecipientList(body: AddNewRecipientModel[]) {
        return this.httpClient.post<boolean>(`${environment.baseUrl}/mailchimp/addRecipientList`, JSON.parse(JSON.stringify(body)), this.httpOptions);
    }
}
