import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';

import { roundNumber } from '../../utils/number-utils';
import { ChildInvoiceLinkDTO } from '../add-child-link-modal/add-child-link-modal.component';
import { AddSubLinkToLinkedObjectToFile } from '../global-files.actions';

@Component({
    selector: 'app-add-child-link-confirm-from-timesheets-modal',
    templateUrl: './add-child-link-confirm-from-timesheets-modal.component.html',
    styleUrls: ['./add-child-link-confirm-from-timesheets-modal.component.scss'],
})
export class AddChildLinkConfirmFromTimesheetsModalComponent implements OnInit, OnDestroy {
    @Input() newChildLinks: ChildInvoiceLinkDTO[];
    @Input() employeeWorkedMinutesForProjectMap: Map<string, number>;
    @Input() totalHoursForProject: number;
    @Input() linkPrice: number;
    @Input() alreadyLinkedPrice: number;

    childLinks: ChildInvoiceLinkDTO[];
    amountOverflowMessage: string;

    displayedColumnsNewLinks: string[] = ['object', 'amount', 'percentage', 'remove'];
    newLinksDatasource: MatTableDataSource<ChildInvoiceLinkDTO> = new MatTableDataSource<ChildInvoiceLinkDTO>();

    private subscriptions = new Subscription();

    constructor(public activeModal: NgbActiveModal, private store: Store) {}

    ngOnInit(): void {
        this.childLinks = [...this.newChildLinks];
        this.createMatTable();
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    getPercentageForLink(row: ChildInvoiceLinkDTO): string {
        const minutes: number = this.employeeWorkedMinutesForProjectMap.get(row.newObjectLinkId);
        if (minutes) return `${roundNumber((minutes / this.totalHoursForProject) * 100)}%`;
        return '...';
    }

    deleteNewChildLink(i: number, row: ChildInvoiceLinkDTO): void {
        this.childLinks.splice(i, 1);
        this.employeeWorkedMinutesForProjectMap.delete(row.newObjectLinkId);
        this.newLinksDatasource.data = this.childLinks;
    }

    checkAmountOverflow(): void {
        if (this.calculateLinkedAmount() > this.linkPrice) {
            this.amountOverflowMessage = `Linked amount is over total amount of parent by `;
        } else if (this.calculateLinkedAmount() < this.linkPrice) {
            this.amountOverflowMessage = `Linked amount is under total amount of parent by `;
        } else {
            this.amountOverflowMessage = null;
        }
    }

    saveLinks() {
        this.subscriptions.add(
            this.store.dispatch(new AddSubLinkToLinkedObjectToFile(this.newChildLinks)).subscribe({
                next: () => {
                    this.activeModal.close();
                },
            }),
        );
    }

    private calculateLinkedAmount(): number {
        const totalAssignedAmount =
            this.alreadyLinkedPrice +
            this.childLinks.reduce((previousValue: number, currentValue: ChildInvoiceLinkDTO) => previousValue + currentValue.amount, 0);
        return roundNumber(totalAssignedAmount);
    }

    private createMatTable() {
        this.newChildLinks.forEach((newChildLink) => this.newLinksDatasource.data.push(newChildLink));
    }
}
