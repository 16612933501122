<div *ngIf="!this.dataSource?.data">
    <app-loading-message></app-loading-message>
</div>

<div *ngIf="this.dataSource?.data?.length == 0">
    <app-message-empty
        [title]="'No languages...'"
        [icon]="'../../assets/img/open-box.png'">
    </app-message-empty>
</div>

<div [hidden]="!this.dataSource?.data?.length">
    <app-searchbar
        [(value)]="filter"
        (valueChange)="applyFilter($event)"></app-searchbar>

    <div class="m-t-4">
        <table
            mat-table
            [dataSource]="dataSource"
            matSort>
            <ng-container matColumnDef="languageName">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header>
                    Language name
                </th>
                <td
                    mat-cell
                    *matCellDef="let row">
                    <span>{{ row.languageName }}</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="languageCode">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header>
                    Language Code
                </th>
                <td
                    mat-cell
                    *matCellDef="let row">
                    <span>{{ row.languageCode }}</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="country.countryName">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header>
                    Country Name
                </th>
                <td
                    mat-cell
                    *matCellDef="let row">
                    <span>{{ row.country.countryName }}</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="edit">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    class="td-small"></th>
                <td
                    mat-cell
                    *matCellDef="let row">
                    <span
                        ngbDropdown
                        class="dropdown"
                        *appIsPermitted="{
                            module: 'VELDA_LANGUAGES',
                            operator: 'OR',
                            actions: ['EDIT', 'DELETE']
                        }">
                        <button
                            type="button"
                            ngbDropdownToggle
                            class="button button--icon button--small dropdown-toggle"
                            id="dropdownMenuButton"></button>
                        <span
                            ngbDropdownMenu
                            class="dropdown-menu dropdown-menu-right"
                            aria-labelledby="dropdownMenuButton">
                            <a
                                class="dropdown-item"
                                (click)="openEditVeldaLanguageModal(row)"
                                *appIsPermitted="{
                                    module: 'VELDA_LANGUAGES',
                                    operator: 'AND',
                                    actions: ['EDIT']
                                }"
                                >Edit</a
                            >
                            <div
                                class="dropdown-divider"
                                *appIsPermitted="{
                                    module: 'VELDA_LANGUAGES',
                                    operator: 'AND',
                                    actions: ['CREATE', 'DELETE']
                                }"></div>
                            <a
                                class="dropdown-item color--red"
                                (click)="openDeleteVeldaLanguageModal(row)"
                                *appIsPermitted="{
                                    module: 'VELDA_LANGUAGES',
                                    operator: 'AND',
                                    actions: ['DELETE']
                                }"
                                >Delete</a
                            >
                        </span>
                    </span>
                </td>
            </ng-container>

            <tr
                mat-header-row
                *matHeaderRowDef="displayedColumns"></tr>
            <tr
                mat-row
                *matRowDef="let row; columns: displayedColumns"></tr>

            <tr *matNoDataRow>
                <td colspan="4">No languages matching the filter "{{ filter }}"</td>
            </tr>
        </table>
    </div>
</div>
