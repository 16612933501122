import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { Query } from 'src/app/models/Query';
import { QueryDTO } from 'src/app/models/QueryDTO';
import { EditQuery } from 'src/app/velda-articles/velda-articles.actions';

@Component({
    selector: 'app-edit-query-modal',
    templateUrl: './edit-query-modal.component.html',
    styleUrls: ['./edit-query-modal.component.scss'],
})
export class EditQueryModalComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Input() query: Query;
    queryFormGroup: FormGroup;

    constructor(
        public activeModal: NgbActiveModal,
        private formBuilder: FormBuilder,
        private logger: NGXLogger,
        private store: Store,
        private toastrService: ToastrService,
    ) {}

    ngOnInit(): void {
        this.queryFormGroup = this.formBuilder.group({
            query: [this.query.payload, [Validators.required]],
        });
    }

    editQuery() {
        const query: QueryDTO = {
            type: this.query.type,
            payload: this.queryFormGroup.value.query,
        };

        this.logger.debug('Attempt to edit query');
        this.subscriptions.add(
            this.store.dispatch(new EditQuery(this.query.uuid, query)).subscribe(
                () => {
                    this.logger.debug('Query edited successfully');
                    this.toastrService.success('Query edited successfully');
                    this.activeModal.close();
                },
                (error) => {
                    this.logger.error('Failed to edit query');
                    this.toastrService.error('Failed to edit query');
                },
            ),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
