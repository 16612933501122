import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-pill-url',
    templateUrl: './pill-url.component.html',
    styleUrls: ['./pill-url.component.scss'],
})
export class PillUrlComponent implements OnInit {
    @Input() websiteUrl: string;
    @Input() showUrl = true;
    actualUrl: string;

    constructor() {}

    ngOnInit(): void {
        if (this.websiteUrl.substr(0, 8).includes('https://')) {
            this.actualUrl = this.websiteUrl;
        } else if (this.websiteUrl.substr(0, 7).includes('http://')) {
            this.actualUrl = 'https://' + this.websiteUrl.substring(7);
        } else {
            this.actualUrl = 'https://' + this.websiteUrl;
        }
    }

    ngOnChanges() {
        if (this.websiteUrl.substr(0, 8).includes('https://')) {
            this.actualUrl = this.websiteUrl;
        } else if (this.websiteUrl.substr(0, 7).includes('http://')) {
            this.actualUrl = 'https://' + this.websiteUrl.substring(7);
        } else {
            this.actualUrl = 'https://' + this.websiteUrl;
        }
    }
}
