import { Component, OnDestroy, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';

import { FetchInvoices } from '../exact.actions';
import { ExactState } from '../exact.state';

@Component({
    selector: 'app-exact-invoices',
    templateUrl: './exact-invoices.component.html',
    styleUrls: ['./exact-invoices.component.scss'],
})
export class ExactInvoicesComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();
    @Select(ExactState.loggedIn) loggedIn$: Observable<boolean>;
    @Select(ExactState.invoices) invoices$: Observable<any[]>;
    filter: string;
    isset = true;

    constructor(private store: Store) {}

    ngOnInit(): void {
        this.subscriptions.add(
            this.loggedIn$.subscribe((loggedIn) => {
                if (loggedIn) {
                    this.store.dispatch(new FetchInvoices()).subscribe();
                }
            }),
        );
    }

    checkCredentials(set: boolean) {
        this.isset = set;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
