import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { FetchAllBudgets } from 'src/app/budget/budget.actions';
import ChildInvoiceLink from 'src/app/models/ChildInvoiceLink';
import { LinkedObjectsToFileWithId } from 'src/app/models/LinkedObjectsToFileWithId';
import YukiDocument from 'src/app/models/yuki/YukiDocument';
import YukiLinkObject from 'src/app/models/yuki/YukiLinkObject';

import { LinkFile } from '../../../models/LinkFile';
import { LinkedObjectToFile } from '../../../models/LinkedObjectToFile';
import { roundNumber } from '../../utils/number-utils';
import { AddChildLinkModalComponent } from '../add-child-link-modal/add-child-link-modal.component';
import { DeleteLinkedObject, GetAllObjectsLinkedToFile, GetAllObjectsToLink, LinkFileToObject, UpdateLinkedObject } from '../global-files.actions';
import { GlobalFilesState } from '../global-files.state';

@Component({
    selector: 'app-link-object-to-file-modal',
    templateUrl: './link-object-to-file-modal.component.html',
    styleUrls: ['./link-object-to-file-modal.component.scss'],
})
export class LinkObjectToFileModalComponent implements OnInit, OnDestroy, OnChanges {
    @Select(GlobalFilesState.LinkedObjectsToFileWithId) linkedObjectsToFile$: Observable<LinkedObjectsToFileWithId>;
    @Select(GlobalFilesState.LinkFiles) linkFiles$: Observable<LinkFile[]>;

    @Input() yukiDocument: YukiDocument;

    linkedObjectsToFile: LinkedObjectsToFileWithId;
    linkedObjectsToFileDatasource: MatTableDataSource<LinkedObjectToFile> = new MatTableDataSource<LinkedObjectToFile>();
    linkFiles: LinkFile[] = [];
    showedLinkFiles: LinkFile[] = [];
    totalPriceForAddedFiles = 0;
    linkFileForm: FormGroup;
    isLoading = false;
    radio = 'project';
    amountExcl: number;
    totalPriceAboveInvoiceAmount: boolean;
    DESCRIPTION_MAX_LENGTH = 1000;
    currentAmountOfDescriptionCharacters: number;
    descriptionIsTooLong = false;
    linkedObjectsAreLoaded = false;
    addedFileObjects: MatTableDataSource<YukiLinkObject> = new MatTableDataSource<YukiLinkObject>();
    displayedColumns: string[] = ['fileName', 'description', 'linkedObject', 'sublinks', 'actions'];

    unsavedChanges = false;
    isUpdating = false;
    isSaving = false;
    isDeleting = false;
    today: Date = new Date();

    private subscriptions = new Subscription();

    constructor(
        private store: Store,
        private fb: FormBuilder,
        public activeModal: NgbActiveModal,
        private toastr: ToastrService,
        private modalService: NgbModal,
        private logger: NGXLogger,
    ) {}

    ngOnInit(): void {
        this.fetchData();
        this.createLinkFileForm();

        if (this.yukiDocument.type == 2) {
            this.linkFileForm.controls['type'].setValue('purchase');
        } else {
            this.linkFileForm.controls['type'].setValue('sale');
        }
        this.totalPriceAboveInvoiceAmount = this.amountExcl < this.calculateTotalPrice();

        //needed to load budget pills
        // TODO: remove this
        this.store.dispatch(new FetchAllBudgets());
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    ngOnChanges(changes: SimpleChanges) {
        this.linkedObjectsAreLoaded = false;
        this.createLinkFileForm();
        this.fetchLinkedObjects();
    }

    changeValue($event) {
        this.linkFileForm.controls['object'].reset();
        this.radio = $event.target.value;
        this.filterShownObjectsToLink();
    }

    calculateDifferenceBetweenTotalPriceAndInvoicePrice() {
        const amount = this.calculateTotalPrice();
        return this.amountExcl - amount;
    }

    calculateTotalPrice() {
        if (this.linkedObjectsToFileDatasource) {
            let total = 0;
            this.linkedObjectsToFileDatasource.data.forEach((obj) => {
                total += obj.price;
            });
            return total;
        }
        return 0;
    }

    fetchLinkedObjects() {
        this.logger.debug(`Fetching linked objects for file ${this.yukiDocument.uuid}`);
        this.store.dispatch(new GetAllObjectsLinkedToFile(this.yukiDocument.uuid));
        this.subscriptions.add(
            this.linkedObjectsToFile$.subscribe((linkedObjectsToFile) => {
                if (linkedObjectsToFile && linkedObjectsToFile.yukiId == this.yukiDocument.uuid) {
                    this.linkedObjectsToFile = linkedObjectsToFile;
                    this.linkedObjectsToFileDatasource.data = linkedObjectsToFile.linkedObjects;
                    this.linkedObjectsAreLoaded = true;
                }
            }),
        );
    }

    deleteLinkedObject(row: LinkedObjectToFile) {
        this.isLoading = true;
        this.isDeleting = true;
        this.subscriptions.add(
            this.store.dispatch(new DeleteLinkedObject(this.yukiDocument.uuid, row.objectId)).subscribe({
                next: () => {
                    this.toastr.success('Successfully deleted object from ' + this.yukiDocument.subject);
                    this.logger.debug('Successfully deleted object from ' + this.yukiDocument.subject);
                    this.isLoading = false;
                    this.isDeleting = false;
                },
                error: () => {
                    this.toastr.error('Failed to link file with current object');
                    this.logger.error('Failed fetch file of Yuki document due to request error to the Yuki API.');
                    this.isLoading = false;
                    this.isDeleting = false;
                },
            }),
        );
    }

    addLinkFile($event) {
        this.linkFileForm.controls['object'].setValue($event);
        this.updateTotalForAddedFileTable();

        const linkFileDTO: LinkFileDTO = {
            description: this.linkFileForm.value.description,
            price: this.calculateRemainingAmount(),
            objectId: this.linkFileForm.value.object.uuid,
            invoiceType: this.linkFileForm.value.type,
        };

        this.sendNewLinksToBackend([linkFileDTO]);

        this.linkFileForm.controls['object'].reset();
        this.linkFileForm.controls['description'].reset();
        this.totalPriceForAddedFiles += linkFileDTO.price;
    }

    totalLinkedAmountIsOverAmountExcl(): boolean {
        return this.calculateTotalLinkedAmount() > roundNumber(this.yukiDocument.amountExcl);
    }

    amountUsedByLinks(childInvoiceLinks: ChildInvoiceLink[]) {
        if (childInvoiceLinks) {
            let total = 0;
            childInvoiceLinks.forEach((cil) => {
                total += cil.amount;
            });
            return total;
        }
        return 0;
    }

    onDescriptionKeyUp() {
        this.descriptionIsTooLong = false;
        this.currentAmountOfDescriptionCharacters = this.linkFileForm.controls.description.value.length;
        if (this.currentAmountOfDescriptionCharacters > this.DESCRIPTION_MAX_LENGTH) {
            this.descriptionIsTooLong = true;
        }
    }

    updateTotalForAddedFileTable() {
        this.totalPriceForAddedFiles = 0;
        this.addedFileObjects.data.forEach((file) => {
            this.totalPriceForAddedFiles += file.amount;
        });
    }

    openAddChildLinkModal(link: LinkedObjectToFile) {
        const modalRef = this.modalService.open(AddChildLinkModalComponent, {
            windowClass: 'modal-prompt modal-prompt--min-content',
            animation: false,
        });
        modalRef.componentInstance.link = link;
        modalRef.componentInstance.file = this.yukiDocument;
    }

    updateLinkAmount(row: LinkedObjectToFile, newPrice: number): void {
        const linkFileDTO: LinkFileDTO = {
            description: row.description,
            price: newPrice,
            objectId: row.objectId,
        };

        this.updateLink(row, linkFileDTO);
    }

    updateLinkDescription(row: LinkedObjectToFile, newDescription: string): void {
        const linkFileDTO: LinkFileDTO = {
            description: newDescription,
            price: row.price,
            objectId: row.objectId,
        };

        this.updateLink(row, linkFileDTO);
    }

    inputEvent() {
        this.unsavedChanges = true;
    }

    private createLinkFileForm() {
        this.amountExcl = (this.yukiDocument as any).amount ? (this.yukiDocument as any).amountExcl : 0;
        this.amountExcl = Math.round(this.amountExcl * 100) / 100;

        this.linkFileForm = this.fb.group({
            object: [null, Validators.required],
            amount: [`${this.amountExcl}`, Validators.required],
            radio: [this.radio, Validators.required],
            type: [null, Validators.required],
            description: '',
        });
    }

    private filterShownObjectsToLink() {
        this.showedLinkFiles = this.linkFiles.filter((linkFile) => linkFile.type.toLowerCase() === this.radio.toLowerCase());
    }

    private fetchData() {
        this.subscriptions.add(
            this.linkFiles$.subscribe((linkFiles) => {
                if (!linkFiles) {
                    this.store.dispatch(new GetAllObjectsToLink());
                    return;
                }
                this.linkFiles = linkFiles;
                this.filterShownObjectsToLink();
            }),
        );
        this.fetchLinkedObjects();
    }

    private calculateTotalLinkedAmount(): number {
        const alreadyLinkedAmount: number = this.linkedObjectsToFile.linkedObjects.reduce(
            (linkedAmount: number, value: LinkedObjectToFile) => linkedAmount + value.price,
            0,
        );
        const linkedAmount: number = this.addedFileObjects.data.reduce((linkedAmount: number, value: YukiLinkObject) => linkedAmount + value.amount, 0);
        return linkedAmount + alreadyLinkedAmount;
    }

    private calculateRemainingAmount(): number {
        return roundNumber(roundNumber(this.yukiDocument.amountExcl) - this.calculateTotalLinkedAmount());
    }

    private sendNewLinksToBackend(objectsToLink: LinkFileDTO[]): void {
        this.isSaving = true;
        this.store.dispatch(new LinkFileToObject(this.yukiDocument.uuid, objectsToLink)).subscribe(
            () => {
                this.toastr.success('Successfully linked file');
                this.logger.debug('Successfully linked file');
                this.isLoading = false;
                this.isSaving = false;
            },
            () => {
                this.isLoading = false;
                this.isSaving = false;
                this.logger.error('Failed to link yuki document');
                this.toastr.error('Oops, something went wrong, Please try again later...');
            },
        );
    }

    private updateLink(row: LinkedObjectToFile, linkFileDTO: LinkFileDTO) {
        this.isUpdating = true;

        this.store.dispatch(new UpdateLinkedObject(this.yukiDocument.uuid, row.objectId, linkFileDTO)).subscribe({
            next: () => {
                this.toastr.success('Successfully updated amount');
                this.logger.debug('Successfully updated amount');
                this.isUpdating = false;
                this.unsavedChanges = false;
            },
            error: () => {
                this.logger.error('Failed to update amount');
                this.toastr.error('Oops, something went wrong, Please try again later...');
                this.isUpdating = false;
            },
        });
    }
}

export class LinkFileDTO {
    overhead?: boolean = false;
    objectId?: string;
    price?: number;
    description?: string;
    invoiceType?: string;
}
