import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';

import { InviteMember } from '../company.actions';
import { CompanyState } from '../company.state';

@Component({
    selector: 'app-add-member-modal',
    templateUrl: './add-member-modal.component.html',
    styleUrls: ['./add-member-modal.component.scss'],
})
export class AddMemberModalComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Select(CompanyState.companyUuid) companyUuid$: Observable<string>;

    email: string;
    memberFormGroup: FormGroup;

    loading = false;
    inputIsWrong = false;
    addMemberWentWrong = false;
    errorMessage: string;

    @ViewChild('focussed', { static: false })
    set input(element: ElementRef<HTMLInputElement>) {
        if (element) {
            element.nativeElement.focus();
        }
    }

    constructor(
        public activeModal: NgbActiveModal,
        private formBuilder: FormBuilder,
        private store: Store,
        private toastr: ToastrService,
        private logger: NGXLogger,
    ) {}

    ngOnInit(): void {
        this.memberFormGroup = this.formBuilder.group({
            email: ['', [Validators.email, Validators.required]],
        });
    }

    addMember(): void {
        this.loading = true;
        if (!this.memberFormGroup.valid) {
            this.inputIsWrong = true;
            this.loading = false;
            return;
        }

        this.memberFormGroup.disable();

        this.email = this.memberFormGroup.controls.email.value;
        this.logger.debug('Attempt to invite companyMember');
        this.subscriptions.add(
            this.store.dispatch(new InviteMember(this.email)).subscribe(
                () => {
                    this.logger.debug('Succesfully invited companyMember');
                    this.memberFormGroup.controls.email.setValue('');
                    this.loading = false;
                    this.memberFormGroup.enable();
                    this.toastr.success('A company invitation has been sent to ' + this.email);
                },
                (error) => {
                    this.logger.debug('Failed to invite companyMember');
                    this.errorMessage = error?.error?.message ?? 'Error message was empty';
                    this.loading = false;
                    this.addMemberWentWrong = true;
                    this.memberFormGroup.enable();
                },
            ),
        );
    }

    // Hides the error messages that appear after a user has submitted invalid data
    hideErrorMessage() {
        this.inputIsWrong = false;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
