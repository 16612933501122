import { Component, Input, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { ChartDataset, ChartOptions, ChartType } from 'chart.js';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { addMonths, endOfYear, format, isSameMonth, setMonth, startOfYear } from 'date-fns';
import * as moment from 'moment';
import { BaseChartDirective } from 'ng2-charts';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { ModuleEnum } from 'src/app/models/ModuleEnum';
import { Product } from 'src/app/models/Product';
import Project from 'src/app/models/Project';
import { Timesheet } from 'src/app/models/Timesheet';
import User from 'src/app/models/User';
import { Resume } from 'src/app/models/resume/Resume';
import { ModuleService } from 'src/app/services/module.service';
import { FetchTimesheetsForProject } from 'src/app/timesheets/timesheets.actions';
import { TimesheetState } from 'src/app/timesheets/timesheets.state';
import { GetUserById } from 'src/app/users/user.actions';

import { GetAllFilesLinkedToObject } from '../../shared/global-files/global-files.actions';
import { LinkedInvoicesToObjectComponent } from '../../shared/global-files/linked-invoices-to-object/linked-invoices-to-object.component';
import { ProjectAddProductModalComponent } from '../project-add-product-modal/project-add-product-modal.component';
import { ProjectDeleteModalComponent } from '../project-delete-modal/project-delete-modal.component';
import { ProjectDeleteProductComponent } from '../project-delete-product/project-delete-product.component';
import { ProjectDetailModalComponent } from '../project-detail-modal/project-detail-modal.component';
import { FetchProjectById, GetProjectAssignedResumes, UpdateFinishedOfProject } from '../project.actions';
import { ProjectState } from '../project.state';

@Component({
    selector: 'app-project-info-modal',
    templateUrl: './project-info-modal.component.html',
    styleUrls: ['./project-info-modal.component.scss'],
})
export class ProjectInfoModalComponent implements OnInit, OnDestroy {
    //chart
    xArray: string[] = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    chartData: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    chartYear: number;
    @ViewChild(BaseChartDirective) chart?: BaseChartDirective;
    public barChartLabels: string[] = this.xArray;
    public barChartType: ChartType = 'bar';
    public barChartLegend = false;
    public barChartCheckbox = false;
    public barChartPlugins = [pluginDataLabels];
    public barChartOptions: ChartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {},
        plugins: {
            datalabels: {
                anchor: 'end',
                align: 'end',
            },
        },
    };
    public barChartData: ChartDataset[] = [
        {
            data: this.chartData,
            borderColor: '#006EFF',
            borderWidth: 1,
            backgroundColor: 'rgba(0,110,255,.25)',
            hoverBackgroundColor: 'rgba(0,110,255,.75)',
            hoverBorderColor: 'rgba(0,110,255,1)',
            label: 'Worked hours',
        },
    ];
    @Select(ProjectState.project) project$: Observable<Project>;
    @Select(ProjectState.projects) projects$: Observable<Project[]>;
    @Select(TimesheetState.timesheetsForProject) timesheets$: Observable<Timesheet[]>;
    timesheets: Timesheet[] = [];
    timesheetsDataSource: MatTableDataSource<Timesheet> = new MatTableDataSource<Timesheet>();
    selectedDate: Date = new Date();
    showFooter = true;
    @Input() projectUuid: string;
    @Input() openFinancialTab = false;
    assignedResumes: Resume[];
    @Input() project: Project;
    user: User;
    isLoading = true;
    eModule = ModuleEnum;
    displayedColumns: string[] = ['timesheet', 'timeslot', 'totalHours'];
    startDateFilter: string = format(startOfYear(new Date()), 'yyyy-MM-dd');
    endDateFilter: string = format(endOfYear(new Date()), 'yyyy-MM-dd');
    @ViewChildren(LinkedInvoicesToObjectComponent) linkedInvoicesToObjectComponents: QueryList<LinkedInvoicesToObjectComponent>;
    activeNavTab = 'information';

    private subscriptions = new Subscription();

    constructor(
        private store: Store,
        private router: Router,
        private toastr: ToastrService,
        private modalService: NgbModal,
        public activeModal: NgbActiveModal,
        public moduleService: ModuleService,
        private logger: NGXLogger,
    ) {}

    ngOnInit(): void {
        if (this.openFinancialTab) this.activeNavTab = 'financial';

        this.logger.debug('Fetching all files linked to object');
        this.store.dispatch(new GetAllFilesLinkedToObject(this.projectUuid));

        // this.subscriptions.add(
        //     this.projects$.subscribe((projects) => {
        //         if (projects && projects.length > 0) {
        //         const proj = projects.find((p) => p.uuid === (this.project ? this.project.uuid : this.projectUuid));

        //         this.logger.debug('Fetching workAssignedResumes');
        //         this.store.dispatch(new GetProjectAssignedResumes(this.projectUuid));

        //         if (proj) {
        //             this.project = proj;
        //             this.isLoading = false;                  
        //         }}
        //     }),
        // );

        // if (this.project) {
        //     this.store.dispatch(new GetUserById(this.project.userId)).subscribe((state) => (this.user = state.user.userById));
        // }
        
        this.subscriptions.add(
            this.project$.subscribe((project) => {
                if (!project || project.uuid != this.projectUuid) {
                    this.store.dispatch(new FetchProjectById(this.projectUuid));
                    return;
                }

                this.logger.debug('Fetching workAssignedResumes');
                this.store.dispatch(new GetProjectAssignedResumes(this.projectUuid));

                this.project = project;
                this.isLoading = false;

                if (this.project) {
                    this.store.dispatch(new GetUserById(this.project.userId)).subscribe((state) => (this.user = state.user.userById));
                }
            }),
        );

        this.logger.debug('Fetching timesheets for project');
        this.store.dispatch(new FetchTimesheetsForProject(this.projectUuid));
        this.subscriptions.add(
            this.timesheets$.subscribe((timesheets) => {
                if (timesheets) {
                    this.timesheets = timesheets;
                    this.timesheetsDataSource.data = timesheets;
                    this.timesheetsDataSource.filterPredicate = (data, selectedDate) => {
                        return isSameMonth(new Date(data.expirationDate), new Date(selectedDate));
                    };
                    this.applyFilter();
                }
            }),
        );

        this.chart?.update();
    }

    workDetail(project: Project) {
        this.logger.debug('Navigating to project resumes');
        this.router.navigate(['/', 'projects', project.uuid, 'resumes']);
    }

    openProjectDetailModalComponent() {
        this.logger.debug('Opening projectDetailModal');
        const modalRef = this.modalService.open(ProjectDetailModalComponent, {
            windowClass: 'modal-pane',
            animation: false,
        });

        modalRef.componentInstance.inOverview = true;
        modalRef.componentInstance.project = this.project;
        modalRef.componentInstance.isUpdate = true;
    }

    openProjectDeleteModal(project: Project) {
        this.logger.debug('Opening projectDeleteModal');
        const modalRef = this.modalService.open(ProjectDeleteModalComponent, {
            windowClass: 'modal-prompt',
            animation: false,
        });
        modalRef.componentInstance.project = project;
    }

    openWorkAddProductModal(project: Project, product?: Product) {
        this.logger.debug('Opening projectAddProductModal');
        const modalRef = this.modalService.open(ProjectAddProductModalComponent, {
            windowClass: 'modal-prompt',
            animation: false,
        });
        modalRef.componentInstance.project = project;
        modalRef.componentInstance.product = product;
    }

    openWorkProductDeleteModal(project: Project, product: Product) {
        this.logger.debug('Opening projectDeleteModal');
        const modalRef = this.modalService.open(ProjectDeleteProductComponent, {
            windowClass: 'modal-prompt',
            animation: false,
        });
        modalRef.componentInstance.product = product;
        modalRef.componentInstance.project = project;
    }

    toggleFinished() {
        this.logger.debug('Attempt to update finished status of project');
        this.subscriptions.add(
            this.store.dispatch(new UpdateFinishedOfProject(this.project.uuid)).subscribe({
                next: () => {
                    this.logger.debug('Successfully updated finished status of project');
                    this.toastr.success('Project status updated');
                },
                error: () => {
                    this.logger.debug('Failed to update finished status of project');
                    this.toastr.error('Something went wrong');
                },
            }),
        );
    }

    checkDates(): boolean {
        if (this.project.endDate) {
            const endDate = moment(this.project.endDate, 'yyyy-MM-DD hh:mm:ss');
            if (endDate < moment()) {
                return true;
            }
        }
        return false;
    }

    getTotalMinutesForTimesheet(timesheet: Timesheet): number {
        let totalMinutes = 0;
        timesheet.timeslots.forEach((el) => {
            if (el.project.uuid === this.projectUuid) {
                totalMinutes += el.minutes;
            }
        });
        return totalMinutes;
    }

    getTotalMinutesForAllTimesheets(): number {
        const totalMinutes = 0;

        for (const sheet in this.timesheets) return totalMinutes;
    }

    getRequiredHoursDiff(timesheet: Timesheet) {
        return Math.round(Math.abs(Timesheet.getTotalMinutes(timesheet) / 60 - timesheet.hoursRequired) * 60);
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    getRequiredHoursDiffPercentage(timesheet: Timesheet) {
        return Math.round((Timesheet.getTotalMinutes(timesheet) / 60 / timesheet.hoursRequired) * 100);
    }

    getTotalMinutes(): number {
        let totalMinutes = 0;

        this.timesheetsDataSource.filteredData.forEach((ts) => {
            totalMinutes += this.getTotalMinutesForTimesheet(ts);
        });
        return totalMinutes;
    }

    getTotalMinutesForProject(): number {
        let totalMinutes = 0;
        this.timesheetsDataSource.data.forEach((ts) => {
            totalMinutes += this.getTotalMinutesForTimesheet(ts);
        });
        return totalMinutes;
    }

    getTotalPriceForProject(): number {
        let totalPrice = 0;
        this.timesheetsDataSource.filteredData.forEach((ts) => {
            this.project.defaultLinkedEmployees.forEach((el) => {
                if (el.employeeId === ts.employee.uuid) {
                    totalPrice += (this.getTotalMinutesForTimesheet(ts) / 60) * el.pricePerHour;
                }
            });
        });
        return totalPrice;
    }

    moveDate(amount: number) {
        if (amount === 0) {
            this.selectedDate = new Date();
        } else {
            this.selectedDate = addMonths(this.selectedDate, amount);
        }
        this.filterTimesheetsForProject();
    }

    calculateFirstDayOfGivenMonth(startDate: Date): Date {
        return new Date(startDate.getFullYear(), startDate.getMonth(), 1);
    }

    calculateYearOfGivenMonth(startDate: Date): Date {
        return new Date(startDate.getFullYear(), 0, 1);
    }

    calculateChartData() {
        this.xArray.forEach((m, i) => {
            const month = setMonth(new Date(this.selectedDate), i);
            const timesheets = this.timesheets.filter((timesheet) => isSameMonth(new Date(timesheet.expirationDate), month));
            let total = 0;
            timesheets.forEach((timesheet) => {
                timesheet.timeslots.forEach((timeslot) => {
                    if (timeslot.project.uuid === this.projectUuid) {
                        total += timeslot.minutes;
                    }
                });
            });

            this.chartData[i] = total / 60;
        });
        this.chart?.update();
    }

    filterTimesheetsForProject() {
        this.timesheetsDataSource.filter = this.selectedDate.toString();
    }

    applyFilter() {
        if (new Date(this.selectedDate).getFullYear() !== this.chartYear) {
            this.calculateChartData();
        }
        this.chartYear = new Date(this.selectedDate).getFullYear();
        this.timesheetsDataSource.filter = this.selectedDate.toString();

        this.showFooter = false;
        if (this.timesheetsDataSource.filteredData.length != 0) {
            this.showFooter = true;
        }
    }

    resetChartFilter() {
        this.xArray = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        this.chartData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        this.barChartData[0].data = this.chartData;
        this.barChartLabels = this.xArray;
        this.calculateChartData();
    }

    hideUpcomingChartMonths(values: any) {
        if (values.currentTarget.checked == true) {
            if (new Date(this.selectedDate).getFullYear() == new Date().getFullYear()) {
                const monthNumber = new Date(this.selectedDate).getMonth();
                this.xArray = this.xArray.splice(0, monthNumber + 1);
                this.chartData = this.chartData.splice(0, monthNumber + 1);
            } else if (new Date(this.selectedDate).getFullYear() > new Date().getFullYear()) {
                this.xArray = this.xArray.splice(12, 12);
                this.chartData = this.chartData.splice(12, 12);
            }
            this.barChartData[0].data = this.chartData;
            this.barChartLabels = this.xArray;
        } else {
            this.resetChartFilter();
        }
        this.chart?.update();
    }

    navigateToTimesheets(): void {
        this.logger.debug('Navigating to timesheets');
        this.activeModal.dismiss();
        this.router.navigate(['/timesheets']);
    }

    createHtmlSubtitleForNoTimesheets(): string {
        return `No timesheets for <strong>${this.project.title}</strong>`;
    }

    filterDate() {
        this.linkedInvoicesToObjectComponents.forEach((linkedInvoicesToObjectComponent) =>
            linkedInvoicesToObjectComponent.filterOnDate(new Date(this.startDateFilter), new Date(this.endDateFilter)),
        );
    }

    projectHasActiveWarranty(project: Project): boolean {
        if (project.warrantyEndDate) {
            const warrantyEndDate = moment(project.warrantyEndDate, 'yyyy-MM-DD hh:mm:ss');

            if (warrantyEndDate.endOf('day').isBefore(moment().endOf('day'))) {
                return false;
            }
        }

        return true;
    }

    calculateRemainingWarrantyDays(project: Project): number {
        const today = moment().startOf('day');
        const warrantyEndDate = moment(project.warrantyEndDate);
        const daysRemaining = warrantyEndDate.diff(today, 'days');
        return daysRemaining;
    }
}
