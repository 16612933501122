import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';
import { Unit } from 'src/app/models/Unit';

import { DeleteUnit, FetchUnits } from '../unit.actions';

@Component({
    selector: 'app-unit-delete-modal',
    templateUrl: './unit-delete-modal.component.html',
    styleUrls: ['./unit-delete-modal.component.scss'],
})
export class UnitDeleteModalComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Input() unit: Unit;

    constructor(public activeModal: NgbActiveModal, private store: Store, private router: Router, private logger: NGXLogger) {}

    ngOnInit(): void {}

    deleteUnit() {
        this.logger.debug('Attempt to remove unit');
        this.subscriptions.add(
            this.store.dispatch(new DeleteUnit(this.unit.uuid)).subscribe(
                () => {
                    this.logger.debug('Succesfully removed unit');
                    this.store.dispatch(new FetchUnits());
                    this.activeModal.close();
                    this.logger.debug('Navigating to inventory units');
                    this.router.navigate(['/inventory/units']);
                },
                (error) => {
                    this.logger.debug('Failed to remove unit');
                },
            ),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
