import { Component, EventEmitter, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-add-link-modal',
    templateUrl: './add-link-modal.component.html',
    styleUrls: ['./add-link-modal.component.scss'],
})
export class AddLinkModalComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    public emitObject: EventEmitter<any> = new EventEmitter();

    @Input() data;
    @Input() isLoading = false;
    @Input() addItemFunction = undefined;
    @Input() itemType: string = null;
    @Input() keyword: string;

    @Input() prefilledInformation: any;

    formGroup: FormGroup;

    constructor(public activeModal: NgbActiveModal, private formBuilder: FormBuilder) {}

    ngOnInit(): void {
        this.formGroup = this.formBuilder.group({
            objectToLink: '',
        });
    }

    onSubmit() {
        this.emitObject.emit(this.formGroup.get('objectToLink').value);
        this.activeModal.close();
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
