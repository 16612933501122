import { DAYS } from './DAYS';

export default interface MailingObject {
    uuid: string;
    emails: string[];
    mailingType: string;
    cron: string;
}

export function getMailingTypeInfo(type: MailingTypes): [MailingTypes, string, boolean] {
    switch (type) {
        case MailingTypes.EMPLOYEE_BIRTHDAY_REMINDER:
            return [MailingTypes.EMPLOYEE_BIRTHDAY_REMINDER, 'Employee birthday reminders', true];
        case MailingTypes.EMPLOYEE_WORK_ANNIVERSARY_REMINDER:
            return [MailingTypes.EMPLOYEE_WORK_ANNIVERSARY_REMINDER, 'Employee work anniversary reminders', true];
        case MailingTypes.TIMESHEET_READY_FOR_REVIEW:
            return [MailingTypes.TIMESHEET_READY_FOR_REVIEW, 'Receive a reminder whenever a timesheet has been put op for review', false];
        case MailingTypes.TIMESHEETS_SHOULD_GET_UPLOADED_REMINDER:
            return [MailingTypes.TIMESHEETS_SHOULD_GET_UPLOADED_REMINDER, 'Receive a reminder to tell your employees to upload their timesheets', true];
        case MailingTypes.DEAL_ABOUT_TO_ROT_REMINDER:
            return [MailingTypes.DEAL_ABOUT_TO_ROT_REMINDER, 'Receive a reminder whenever a deal is about to rot', true];
        case MailingTypes.INVENTORY_LOW_STOCK_REMINDER:
            return [MailingTypes.INVENTORY_LOW_STOCK_REMINDER, 'Receive a list of all products that are low or out of stock', true];
        case MailingTypes.BUDGET_DUE_REMINDER:
            return [MailingTypes.BUDGET_DUE_REMINDER, 'Receive a list of all budgets that are almost over time', true];
        case MailingTypes.PROJECT_WARRANTY_RUN_OUT_REMINDER:
            return [MailingTypes.PROJECT_WARRANTY_RUN_OUT_REMINDER, 'Receive a list of all projects with a warranty running out soon', true];
        case MailingTypes.PROJECT_WARRANTY_EXPIRES_REMINDER:
            return [MailingTypes.PROJECT_WARRANTY_EXPIRES_REMINDER, 'Receive a list of all projects with an expiring warranty', true];
        case MailingTypes.PROJECT_BUDGET_LIMIT_APPROACHING_REMINDER:
            return [MailingTypes.PROJECT_BUDGET_LIMIT_APPROACHING_REMINDER, 'Receive a list of all projects that are approaching the budget limit', true];
    }
}

export function getDescription(cron: string): string {
    let description = '';
    if (cron == '0 0 0 1/1 * ? *') {
        description = 'Daily';
    } else if (cron.includes('0 0 0 ? * ')) {
        description += 'Every ';
        description += DAYS.findDay(cron).long;
    } else if (cron.includes('0 0 0 ') && cron.includes(' * ? *')) {
        if (cron.includes('L-')) {
            const days = cron.slice(8, 9);
            if (days == '0') {
                description = 'The last day of the month.';
            } else if (days == '1') {
                description = 'One day before the end of the month.';
            } else {
                description = days + ' days before the end of the month.';
            }
        } else {
            const days = cron.slice(6, 7);
            if (days == '1') {
                description = 'First day of the month.';
            } else {
                description = 'Day ' + days + ' of the month.';
            }
        }
    }

    return description;
}

export enum MailingTypes {
    EMPLOYEE_BIRTHDAY_REMINDER = 'EMPLOYEE_BIRTHDAY_REMINDER',
    EMPLOYEE_WORK_ANNIVERSARY_REMINDER = 'EMPLOYEE_WORK_ANNIVERSARY_REMINDER',
    TIMESHEET_READY_FOR_REVIEW = 'TIMESHEET_READY_FOR_REVIEW',
    TIMESHEETS_SHOULD_GET_UPLOADED_REMINDER = 'TIMESHEETS_SHOULD_GET_UPLOADED_REMINDER',
    DEAL_ABOUT_TO_ROT_REMINDER = 'DEAL_ABOUT_TO_ROT_REMINDER',
    INVENTORY_LOW_STOCK_REMINDER = 'INVENTORY_LOW_STOCK_REMINDER',
    BUDGET_DUE_REMINDER = 'BUDGET_DUE_REMINDER',
    PROJECT_WARRANTY_RUN_OUT_REMINDER = 'PROJECT_WARRANTY_RUN_OUT_REMINDER',
    PROJECT_WARRANTY_EXPIRES_REMINDER = 'PROJECT_WARRANTY_EXPIRES_REMINDER',
    PROJECT_BUDGET_LIMIT_APPROACHING_REMINDER = 'PROJECT_BUDGET_LIMIT_APPROACHING_REMINDER',
}

export function getMailingTypesByModule(moduleKey: string): MailingTypes[] {
    switch (moduleKey) {
        case 'EMPLOYEES':
            return [MailingTypes.EMPLOYEE_BIRTHDAY_REMINDER, MailingTypes.EMPLOYEE_WORK_ANNIVERSARY_REMINDER];
        case 'TIMESHEETS':
            return [MailingTypes.TIMESHEET_READY_FOR_REVIEW, MailingTypes.TIMESHEETS_SHOULD_GET_UPLOADED_REMINDER];
        case 'CRM':
            return [MailingTypes.DEAL_ABOUT_TO_ROT_REMINDER];
        case 'INVENTORY':
            return [MailingTypes.INVENTORY_LOW_STOCK_REMINDER];
        case 'BUDGET':
            return [MailingTypes.BUDGET_DUE_REMINDER];
        case 'PROJECTS':
            return [
                MailingTypes.PROJECT_WARRANTY_RUN_OUT_REMINDER,
                MailingTypes.PROJECT_WARRANTY_EXPIRES_REMINDER,
                MailingTypes.PROJECT_BUDGET_LIMIT_APPROACHING_REMINDER,
            ];
        default:
            return [];
    }
}
