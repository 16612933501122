import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';

import { ObjectWithFinancialData } from '../../models/Financial/ObjectWithFinancialData';
import { DateNavigationOptions, DateNavigationType, SelectedDateClass } from '../../shared/date-navigation/date-navigation.component';
import { OverheadCategoryDetailModalComponent } from '../../yuki/overhead-category-detail-modal/overhead-category-detail-modal.component';
import { GetOverheadCategoriesFinancialOverviewData } from '../financial.actions';
import { FinancialState } from '../financial.state';

@Component({
    selector: 'app-overhead-financial-graphs',
    templateUrl: './overhead-financial-graphs.component.html',
    styleUrls: ['./overhead-financial-graphs.component.scss'],
})
export class OverheadFinancialGraphsComponent implements OnInit, OnDestroy {
    overheadCategoriesWithFinancialData: ObjectWithFinancialData[];
    datasource: MatTableDataSource<ObjectWithFinancialData>;
    displayedColumns: string[] = ['overheadCategory', 'total', 'average'];

    selectedDate: Date = new Date();
    dateNavigationOptions: DateNavigationOptions;
    fetchedDataForCurrentYear = false;
    loadingData = false;

    private subscriptions = new Subscription();

    constructor(private store: Store, private modalService: NgbModal) {}

    ngOnInit(): void {
        this.dateNavigationOptions = {
            dateNavigationType: DateNavigationType.YEAR,
            selectedDate: this.selectedDate,
            selectedDateClass: SelectedDateClass.SMALL,
        };

        this.createMatTable();
        this.fetchData();
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    updateSelectedDate(): void {
        this.fetchedDataForCurrentYear = false;
        this.fetchData();
    }

    openOverheadCategoryModal(overheadCategoryWithFinancialOverviewData: ObjectWithFinancialData): void {
        const modalRef = this.modalService.open(OverheadCategoryDetailModalComponent, {
            windowClass: 'modal-huge',
            animation: false,
        });
        modalRef.componentInstance.overheadCategoryId = overheadCategoryWithFinancialOverviewData.objectId;
        modalRef.componentInstance.openFinancialTab = true;
    }

    private fetchData(): void {
        const year: number = this.dateNavigationOptions.selectedDate.getFullYear();

        this.subscriptions.add(
            this.store.select(FinancialState.overheadCategoriesFinancialOverviewData(year)).subscribe((overheadCategoriesWithFinancialOverviewData) => {
                if (
                    !this.fetchedDataForCurrentYear &&
                    (!overheadCategoriesWithFinancialOverviewData || overheadCategoriesWithFinancialOverviewData.length < 1)
                ) {
                    this.store.dispatch(new GetOverheadCategoriesFinancialOverviewData(year));
                    this.fetchedDataForCurrentYear = true;
                    this.loadingData = true;
                }

                this.loadingData = false;
                this.overheadCategoriesWithFinancialData = overheadCategoriesWithFinancialOverviewData;
                this.datasource.data = overheadCategoriesWithFinancialOverviewData;
            }),
        );
    }

    private createMatTable(): void {
        this.datasource = new MatTableDataSource<ObjectWithFinancialData>();
    }

    onSortChange(event) {
        const value = event.active;
        const direction = event.direction;
        if (!value) {
            return;
        }
        const sortedData = this.datasource.data.slice().sort((a, b) => {
            const aValue = a[value];
            const bValue = b[value];
            return (aValue < bValue ? -1 : 1) * (direction === 'asc' ? 1 : -1);
        });
        this.datasource.data = sortedData;
    }
}
