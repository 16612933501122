import { Component, Input, OnInit } from '@angular/core';
import { ExportType, MatTableExporterDirective } from 'mat-table-exporter';

@Component({
    selector: 'app-global-export-csv',
    templateUrl: './global-export-csv.component.html',
    styleUrls: ['./global-export-csv.component.scss'],
})
export class GlobalExportCsvComponent implements OnInit {
    @Input() componentName: string;
    @Input() exporter: MatTableExporterDirective;

    @Input() type: string;

    ngOnInit(): void {
        if (this.type === undefined) {
            this.type === 'button';
        }
    }

    exportCsv() {
        this.exporter.exportTable(ExportType.CSV, {
            fileName: this.componentName + Date.now(),
            Props: {
                Author: 'Resumo',
            },
        });
    }
}
