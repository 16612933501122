<h3 class="m-b-4">Contact information</h3>

<li *ngIf="contactInformation.phoneNumber">
    <app-pill-whatsapp [phoneNumber]="contactInformation.phoneNumber"></app-pill-whatsapp>
</li>
<li *ngIf="contactInformation.phoneNumber">
    <app-pill-telephone [contactPhonenumber]="contactInformation.phoneNumber"></app-pill-telephone>
</li>
<li *ngIf="contactInformation.email">
    <app-pill-mailto [mailtoEmail]="contactInformation.email"></app-pill-mailto>
</li>
<li *ngIf="contactInformation.linkedIn">
    <app-pill-linkedin [linkedinUrl]="contactInformation.linkedIn"></app-pill-linkedin>
</li>
<li *ngIf="contactInformation.website">
    <app-pill-url [websiteUrl]="contactInformation.website"></app-pill-url>
</li>
<li *ngIf="contactInformation.facebook">
    <app-pill-facebook [facebookUrl]="contactInformation.facebook"></app-pill-facebook>
</li>
<li *ngIf="contactInformation.github">
    <app-pill-github [githubUrl]="contactInformation.github"></app-pill-github>
</li>
