<div class="modal-header">
    <h2
        class="modal-title"
        id="exampleModalLabel">
        <ng-container *ngIf="employee.archived; else archive">Unarchive</ng-container>
        <ng-template #archive>Archive</ng-template>
        employee
    </h2>
    <button
        (click)="activeModal.dismiss()"
        aria-label="Close"
        class="close"
        type="button">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    Are you sure you want to
    <ng-container *ngIf="employee.archived; else archiveSmall">unarchive</ng-container>
    <ng-template #archiveSmall>archive</ng-template>
    <strong> {{ employee.firstName }} {{ employee.lastName }}</strong>?
    <ng-container *ngIf="!employee.archived">
        If you archive your employee, you won't be able to access any resumes, companies or work that you have added. It
        is possible to unarchive this employee at a later date.
    </ng-container>
</div>

<div class="modal-footer">
    <button
        (click)="archiveEmployee()"
        class="button button--danger">
        <ng-container *ngIf="employee.archived; else archive">Unarchive</ng-container>
        <ng-template #archive>Archive</ng-template>
    </button>
    <button
        (click)="activeModal.close()"
        class="button button--outline"
        type="button">
        Cancel
    </button>
</div>
