<div class="login">
    <div class="login__holder">
        <app-session-header [header]="hasSubmitted ? 'Instructions send' : 'Lost password'"></app-session-header>

        <div *ngIf="!hasSubmitted">
            <p class="m-b-4">Leave your emailaddress and we'll send a link to reset your password.</p>

            <form
                [formGroup]="forgotPasswordFormGroup"
                (ngSubmit)="sendEmail()">
                <div class="form-group m-b-2">
                    <label>Emailaddress</label>
                    <input
                        type="email"
                        placeholder="Emailaddress"
                        formControlName="email"
                        (focus)="hideErrorMessage()" />
                    <span
                        class="form-error"
                        *ngIf="
                            forgotPasswordFormGroup.controls.email.errors &&
                            inputIsWrong &&
                            (forgotPasswordFormGroup.controls.email.dirty || forgotPasswordFormGroup.controls.email.touched)
                        "
                        >This email is invalid</span
                    >
                </div>

                <div class="m-b-4">
                    <button type="submit">
                        <span class="button button--primary w-100">Send instructions</span>
                    </button>
                </div>
            </form>

            <div class="divider"></div>

            <p class="text-center">
                <a routerLink="/login">Login</a> -
                <a routerLink="/register">Create an account</a>
            </p>
        </div>

        <div *ngIf="hasSubmitted">
            <p class="m-b-8">
                We've send an email to <strong>{{ useremail }}</strong>
            </p>

            <a
                routerLink="/login"
                class="button button--primary w-100"
                >Login</a
            >
        </div>
    </div>
</div>
