<div class="app__title">
    <h1>Employees</h1>
</div>

<nav
    #nav="ngbNav"
    (navChange)="fetchNecessaryInfo($event)"
    ngbNav>
    <ng-container
        domId="incomeCosts"
        ngbNavItem>
        <a ngbNavLink>Income and costs</a>
        <ng-template ngbNavContent>
            <app-employee-financial-graphs></app-employee-financial-graphs>
        </ng-template>
    </ng-container>

    <ng-container
        [domId]="BILLABLE_NAV_ID"
        ngbNavItem>
        <a ngbNavLink>Billable</a>
        <ng-template ngbNavContent>
            <div class="dashboard m-t-4">
                <ng-container *ngIf="idsForBillable$ | async; else loading">
                    <app-billable-graph-widget
                        *ngFor="let id of idsForBillable$ | async"
                        [employeeId]="id"
                        class="dashboard__tile">
                    </app-billable-graph-widget>
                </ng-container>
            </div>
        </ng-template>
    </ng-container>
</nav>

<div [ngbNavOutlet]="nav"></div>

<ng-template #loading>
    <app-loading-message></app-loading-message>
</ng-template>
