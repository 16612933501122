<h1 mat-dialog-title>Invoice</h1>
<div *ngIf="!isLoaded">
  <p>Loading data ...</p>
</div>
<div mat-dialog-content *ngIf="isLoaded && timesheets.length > 0">
  <p class="inputLine ">
    <span>Send invoice to:</span>
    <input class="mediumLength" type="text" [(ngModel)]="partnerName">
  </p>
  <p class="inputLine ">
    <span>Date:</span>
    <input class="mediumLength" type="date" [(ngModel)]="invoiceDate">
  </p>
  <p class="inputLine ">
    <span>Subject:</span>
    <input class="mediumLength" type="text" [(ngModel)]="subjectWithDate">
  </p>
  <p class="invoiceLines">Invoice lines:</p>
  <div class="indent" *ngIf="checkIfAllInvoiceLinesHaveBeenBilled()">
    <span>All invoice lines have been billed</span>
  </div>
  <div *ngFor="let timeslot of timeslots">
    <p class="indent invoiceLine" *ngIf="!timeslot.billed">
      <input type="checkbox" [value]="timeslot" (change)="toggleTimeslotSelection($event.target.checked, timeslot)">
      <span>{{ getEmployeeByTimeslot(timeslot).firstName }} {{ getEmployeeByTimeslot(timeslot).lastName }}</span>
      <input class="smallLength" type="number" [ngModel]="calculateWorkedHoursForTimeslot(timeslot)">hour(s)
    </p>
  </div>
</div>
<mat-dialog-actions class="buttons">
  <button class="button m-r-1" mat-button (click)="closeDialog()">Cancel</button>
  <button class="button m-r-1" mat-button [mat-dialog-close]="selectedTimeslots" [disabled]="isDisabled">Send
    Invoice</button>
</mat-dialog-actions>