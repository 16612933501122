import { Component, EventEmitter, Input, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { Employee } from 'src/app/models/Employee';

import { DeleteEmployee } from '../employees.actions';

@Component({
    selector: 'app-employees-delete-modal',
    templateUrl: './employees-delete-modal.component.html',
    styleUrls: ['./employees-delete-modal.component.scss'],
})
export class EmployeesDeleteModalComponent implements OnDestroy {
    private subscriptions = new Subscription();

    @Input() employee: Employee;
    public closeEvent: EventEmitter<void> = new EventEmitter();

    constructor(public activeModal: NgbActiveModal, private store: Store, private toastr: ToastrService, private logger: NGXLogger) {}

    deleteEmployee() {
        this.logger.debug('Attempt to delete employee');
        this.subscriptions.add(
            this.store.dispatch(new DeleteEmployee(this.employee.uuid)).subscribe(
                () => {
                    this.logger.debug('Succesfully deleted employee');
                    this.toastr.success('Employee removed successfully');
                    this.closeEvent.emit();
                    this.activeModal.close();
                },
                () => {
                    this.logger.error('Failed to delete employee');
                    console.log('employee could not be deleted');
                },
            ),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
