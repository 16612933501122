import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';

import { CompanyState } from '../companies/company.state';
import { Module } from '../models/Module';
import { Role } from '../models/Role';

@Injectable({
    providedIn: 'root',
})
export class ModuleService {
    modules: Module[];
    currentUserEmail: string;
    userRoleName: string;
    userRole: Role;

    constructor(private store: Store) {}

    checkModuleActive(eModule: string): boolean {
        //TODO: deze subscribe zou nog eens naar gekeken moeten worden
        this.store.selectOnce(CompanyState.company).subscribe((company) => {
            company?.modules ? (this.modules = company.modules) : (this.modules = null);
        });
        return this.modules?.filter((module: Module) => module.key.toLowerCase() === eModule.toLowerCase()).length > 0;
    }
}
