<app-sidebar>
    <div class="sidebar-spacing">
        <h3>Lead channels</h3>
        <small>Filter by channel</small>
    </div>

    <span class="divider divider--small"></span>

    <div
        class="app__subnav"
        *ngIf="allLeads$ | async as leads; else loading">
        <a
            (click)="filterByChannel('')"
            [ngClass]="{ app__subnav__active: channelFilter === '' }">
            <span class="app__subnav__content"> All channels </span>
        </a>
        <a
            (click)="filterByChannel(lead.channelKey)"
            *ngFor="let lead of leads | unique: 'channelKey'"
            [ngClass]="{
                app__subnav__active: channelFilter === lead.channelKey
            }">
            <span class="app__subnav__content">
                {{ lead.channelKey }}
            </span>
        </a>
        <a
            *ngIf="archivedLeads$ | async as archivedLeads; else loading"
            (click)="filterByChannel('archived')"
            [ngClass]="{ app__subnav__active: channelFilter === 'archived' }">
            <span class="app__subnav__content"> Archived </span>
        </a>
    </div>
</app-sidebar>

<ng-template #loading>
    <app-loading-message></app-loading-message>
</ng-template>
