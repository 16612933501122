<details open>
    <summary>
        <span class="sectiontitle summary__title">
            Logbook
            <button (click)="openAddLogItemModal()" class="button button--primary">Add entry</button>
        </span>
        <i class="las la-angle-up summary__up-arrow" style="top: 1.5rem"></i>
    </summary>

    <div>
        <table class="table-view">
            <tr>
                <th>Category</th>
                <th>Description</th>
                <th>Last modified on</th>
                <th class="td-small"></th>
            </tr>
            <tr *ngFor="let logItem of logItems">
                <td>{{logItem.category}}</td>
                <td>{{logItem.description}}</td>
                <td>{{logItem.lastModified | date}}</td>
                <td class="td-icon">
                    <span ngbDropdown>
                        <button class="button button--icon button--dropdown button--small" ngbDropdownToggle></button>

                        <span class="dropdown-menu" ngbDropdownMenu>
                            <a (click)="openEditLogItemModal(logItem)" class="dropdown-item">Edit</a>
                            <div class="dropdown-divider"></div>
                            <a (click)="openDeleteLogItemModal(logItem)" class="dropdown-item color--red">Delete</a>
                        </span>
                    </span>
                </td>
            </tr>
        </table>
        <i class="las la-angle-down summary__down-arrow" style="top: 1.5rem"></i>
    </div>

</details>
