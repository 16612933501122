import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';

import { ExactEditRelationModalComponent } from '../exact-edit-relation-modal/exact-edit-relation-modal.component';
import { FetchRelations } from '../exact.actions';
import { ExactState } from '../exact.state';

@Component({
    selector: 'app-exact-relations',
    templateUrl: './exact-relations.component.html',
    styleUrls: ['./exact-relations.component.scss'],
})
export class ExactRelationsComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();
    @Select(ExactState.loggedIn) loggedIn$: Observable<boolean>;
    @Select(ExactState.relations) relations$: Observable<any[]>;
    filter: string;
    isset = true;
    type = 'Name';
    isLoading = true;

    allNotSelected: any = '';
    constructor(private modalService: NgbModal, private store: Store, private toastr: ToastrService) {}

    ngOnInit(): void {
        this.allNotSelected = this.filter;
        this.subscriptions.add(
            this.loggedIn$.subscribe((loggedIn) => {
                if (loggedIn) {
                    this.relations$.subscribe((relations) => {
                        if (relations) {
                            this.isLoading = false;
                        } else {
                            this.store.dispatch(new FetchRelations()).subscribe();
                        }
                    });
                }
            }),
        );
    }

    openAddRelationModal() {
        const modalRef = this.modalService.open(ExactEditRelationModalComponent);
    }

    checkCredentials(set: boolean) {
        this.isset = set;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
