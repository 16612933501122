import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subscription } from 'rxjs';
import { VeldaLanguage } from 'src/app/models/VeldaLanuage';

import { VeldaLanguagesLanguagesDeleteModalComponent } from '../velda-languages-languages-delete-modal/velda-languages-languages-delete-modal.component';
import { VeldaLanguagesLanguagesEditModalComponent } from '../velda-languages-languages-edit-modal/velda-languages-languages-edit-modal.component';
import { VeldaLanguagesState } from '../velda-languages.state';

@Component({
    selector: 'app-velda-languages-languages-list',
    templateUrl: './velda-languages-languages-list.component.html',
    styleUrls: ['./velda-languages-languages-list.component.scss'],
})
export class VeldaLanguagesLanguagesListComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Select(VeldaLanguagesState.languages) languages$: Observable<VeldaLanguage[]>;

    displayedColumns: string[] = ['languageName', 'languageCode', 'country.countryName', 'edit'];
    dataSource: MatTableDataSource<VeldaLanguage>;
    filter: string;
    @ViewChild(MatSort) sort: MatSort;

    constructor(private modalService: NgbModal, private logger: NGXLogger) {}

    ngOnInit(): void {
        this.subscriptions.add(
            this.languages$.subscribe((languages) => {
                /* setup datasource */
                this.dataSource = new MatTableDataSource(languages);
                this.dataSource.sort = this.sort;

                /* sortingDataAccessor to sort on nested objs */
                this.dataSource.sortingDataAccessor = (item, property) => {
                    if (property.includes('.')) return property.split('.').reduce((o, i) => o[i], item);
                    return item[property];
                };
            }),
        );
    }

    applyFilter(filterValue) {
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }

    openEditVeldaLanguageModal(language: VeldaLanguage) {
        this.logger.debug('Opening VeldaLanguagesLanguagesEditModal');
        const modalref = this.modalService.open(VeldaLanguagesLanguagesEditModalComponent, { windowClass: 'modal-prompt' });
        modalref.componentInstance.language = language;
    }

    openDeleteVeldaLanguageModal(language: VeldaLanguage) {
        this.logger.debug('Opening VeldaLanguagesLanguagesDeleteModal');
        const modalref = this.modalService.open(VeldaLanguagesLanguagesDeleteModalComponent, { windowClass: 'modal-prompt' });
        modalref.componentInstance.language = language;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
