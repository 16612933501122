import { Component, EventEmitter, Output } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import Lead from 'src/app/models/Lead';

import { LeadState } from '../lead.state';

@Component({
    selector: 'app-leads-sidebar',
    templateUrl: './leads-sidebar.component.html',
    styleUrls: ['./leads-sidebar.component.scss'],
})
export class LeadsSidebarComponent {
    @Select(LeadState.getLeads) allLeads$: Observable<Lead[]>;
    @Select(LeadState.archivedLeads) archivedLeads$: Observable<Lead[]>;

    @Output() channelFilterChangedEvent = new EventEmitter<string>();

    channelFilter = '';

    filterByChannel(channel) {
        this.channelFilter = channel;
        this.channelFilterChangedEvent.emit(channel);
    }
}
