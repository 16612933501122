<div
    class="comments"
    *appIsPermitted="{ module: 'COMMENTS', operator: 'AND', actions: ['READ'] }">
    <app-loading-message *ngIf="isLoading"></app-loading-message>
    <div
        *ngIf="!isLoading"
        class="comments__overview">
        <app-comment-view
            *ngFor="let comment of comments$ | async | orderBy: 'createdOn':false:true"
            [userId]="userId"
            [comment]="comment"></app-comment-view>

        <div
            class="comments__form"
            *appIsPermitted="{
                module: 'COMMENTS',
                operator: 'AND',
                actions: ['CREATE']
            }">
            <form
                [formGroup]="commentForm"
                (ngSubmit)="addComment()">
                <div class="form-group">
                    <div class="d-flex align-items-center">
                        <input
                            type="text"
                            class="w-100"
                            id="content"
                            formControlName="content"
                            placeholder="Type comment..." />
                        <input
                            type="submit"
                            value="Comment"
                            [disabled]="!commentForm.valid"
                            class="button button--primary m-l-2" />
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
