<a
    class="pill pill--product"
    (click)="openProductDetailModal(); $event.stopPropagation()"
    *ngIf="!isLoading && !isDeleted">
    <i class="las la-box-open"></i>
    {{ getTitle() }}
</a>
<a
    class="pill pill--error"
    (click)="openProductDetailModal(); $event.stopPropagation()"
    *ngIf="productTitle && !isLoading && isDeleted">
    <i class="las la-box-open"></i>
    {{ getTitle() }}
</a>
<a
    class="pill pill--product"
    *ngIf="isLoading">
    <app-loading-message></app-loading-message>
</a>
