import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { ChartConfiguration } from 'chart.js';
import { Subscription } from 'rxjs';

import { TurnoverDataPerMonth } from '../../models/Financial/TurnoverDataPerMonth';
import { MONTHS } from '../../models/MONTHS';
import { CalendarClickEventData, LegendPosition } from '../../shared/charts/default-line-chart/default-line-chart.component';
import { DateNavigationOptions, DateNavigationType, SelectedDateClass } from '../../shared/date-navigation/date-navigation.component';
import { capitalizeFirstLetter } from '../../shared/utils/string-utils';
import { ChartInformationModalComponent, ChartInformationObjectType } from '../chart-information-modal/chart-information-modal.component';
import { GetTurnoverData } from '../financial.actions';
import { FinancialState } from '../financial.state';

@Component({
    selector: 'app-turnover-graph',
    templateUrl: './turnover-graph.component.html',
    styleUrls: ['./turnover-graph.component.scss'],
})
export class TurnoverGraphComponent implements OnInit, OnDestroy {
    xLabels: string[];
    data: any[] = undefined;
    labels: string[] = [];
    legendPosition: LegendPosition = LegendPosition.BOTTOM;
    options: ChartConfiguration['options'];
    turnoverDataPerMonthForCurrentYear: TurnoverDataPerMonth[];
    turnoverDataPerMonthForPreviousYear: TurnoverDataPerMonth[];
    fetchedDataForCurrentYear = false;
    fetchedDataForPreviousYear = false;
    loadingDataCurrentYear = false;
    loadingDataPreviousYear = false;

    selectedDate: Date = new Date();
    dateNavigationOptions: DateNavigationOptions;
    previousYearVisible = false;
    private subscriptions = new Subscription();

    constructor(private store: Store, private modalService: NgbModal) {}

    ngOnInit(): void {
        this.dateNavigationOptions = {
            dateNavigationType: DateNavigationType.YEAR,
            selectedDate: this.selectedDate,
            selectedDateClass: SelectedDateClass.SMALL,
        };

        this.fetchData();
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    updateSelectedDate(): void {
        this.fetchedDataForCurrentYear = false;
        this.fetchedDataForPreviousYear = false;
        this.fetchData();
    }

    seedLabels() {
        return Object.values(MONTHS).map((label) => capitalizeFirstLetter(label.short));
    }

    displayInfo($event: CalendarClickEventData) {
        const modalRef = this.modalService.open(ChartInformationModalComponent, {
            windowClass: 'modal-prompt modal-prompt--min-content',
            animation: false,
        });

        modalRef.componentInstance.month = $event.month;
        if ($event.datasetIndex === 0) {
            modalRef.componentInstance.year = this.dateNavigationOptions.selectedDate.getFullYear();
            modalRef.componentInstance.turnoverDataInformation = this.turnoverDataPerMonthForCurrentYear[$event.month];
        } else {
            modalRef.componentInstance.year = this.dateNavigationOptions.selectedDate.getFullYear() - 1;
            modalRef.componentInstance.turnoverDataInformation = this.turnoverDataPerMonthForPreviousYear[$event.month];
        }
        modalRef.componentInstance.title = `Turnover data`;
        modalRef.componentInstance.chartInformationObjectType = ChartInformationObjectType.TURNOVER;
    }

    togglePreviousYearVisible() {
        this.previousYearVisible = !this.previousYearVisible;
        this.createChart();
    }

    private fetchData() {
        this.xLabels = this.seedLabels();

        const year: number = this.dateNavigationOptions.selectedDate.getFullYear();

        this.subscriptions.add(
            this.store.select(FinancialState.turnoverData(year)).subscribe((turnoverData) => {
                if (!this.fetchedDataForCurrentYear && (!turnoverData || turnoverData.length < 1)) {
                    this.store.dispatch(new GetTurnoverData(year));
                    this.fetchedDataForCurrentYear = true;
                    this.loadingDataCurrentYear = true;
                    return;
                }

                this.loadingDataCurrentYear = false;
                this.turnoverDataPerMonthForCurrentYear = turnoverData;
                if (!this.loadingDataPreviousYear) this.createChart();
            }),
        );

        this.subscriptions.add(
            this.store.select(FinancialState.turnoverData(year - 1)).subscribe((turnoverData) => {
                if (!this.fetchedDataForPreviousYear && (!turnoverData || turnoverData.length < 1)) {
                    this.store.dispatch(new GetTurnoverData(year - 1));
                    this.fetchedDataForPreviousYear = true;
                    this.loadingDataPreviousYear = true;
                    return;
                }

                this.loadingDataPreviousYear = false;
                this.turnoverDataPerMonthForPreviousYear = turnoverData;
                if (!this.loadingDataCurrentYear) this.createChart();
            }),
        );
    }

    private createChart() {
        const dataCurrentYear: number[] = [];
        const dataPreviousYear: number[] = [];

        for (let i = 0; i < this.turnoverDataPerMonthForCurrentYear.length; i++) {
            dataCurrentYear.push(this.turnoverDataPerMonthForCurrentYear[i].turnoverData.reduce((total, turnoverData) => total + turnoverData.amount, 0.0));
            if (this.previousYearVisible) {
                dataPreviousYear.push(
                    this.turnoverDataPerMonthForPreviousYear[i].turnoverData.reduce((total, turnoverData) => total + turnoverData.amount, 0.0),
                );
            }
        }
        this.data = [dataCurrentYear];
        this.labels = [`${this.dateNavigationOptions.selectedDate.getFullYear()}`];
        if (this.previousYearVisible) {
            this.labels.push(`${this.dateNavigationOptions.selectedDate.getFullYear() - 1}`);
            this.data.push(dataPreviousYear);
        }
    }
}
