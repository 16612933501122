import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subscription } from 'rxjs';
import LinkedEmployee from 'src/app/models/LinkedEmployee';

import DefaultProjectEmployeeLink from '../../models/DefaultProjectEmployeeLink';
import Project from '../../models/Project';
import { AddDefaultLinkedEmployeeModalComponent } from '../add-default-linked-employee-modal/add-default-linked-employee-modal.component';
import { DeleteDefaultEmployee, FetchProjectById } from '../project.actions';
import { ProjectState } from '../project.state';

@Component({
    selector: 'app-default-linked-employees',
    templateUrl: './default-linked-employees.component.html',
    styleUrls: ['./default-linked-employees.component.scss'],
})
export class DefaultLinkedEmployeesComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Select(ProjectState.project) project$: Observable<Project>;
    project: Project;

    @Input() projectId: string;

    displayedColumns: string[] = ['employees', 'price per hour', 'edit'];
    dataSource: MatTableDataSource<LinkedEmployee> = new MatTableDataSource<LinkedEmployee>();
    @ViewChild(MatSort) sort: MatSort;

    constructor(private store: Store, private logger: NGXLogger, private modalService: NgbModal) {}

    ngOnInit(): void {
        this.fetchData();
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    private fetchData() {
        this.logger.debug('Fetching project by id');
        this.store.dispatch(new FetchProjectById(this.projectId));
        this.subscriptions.add(
            this.project$.subscribe((project) => {
                this.project = project;
            }),
        );
    }

    openAddDefaultEmployee() {
        const modalRef = this.modalService.open(AddDefaultLinkedEmployeeModalComponent, {
            windowClass: 'modal-prompt',
            animation: false,
        });
        modalRef.componentInstance.project = this.project;
    }

    deleteDefaultEmployee(employee: DefaultProjectEmployeeLink) {
        this.subscriptions.add(this.store.dispatch(new DeleteDefaultEmployee(employee.uuid)).subscribe());
    }
}
