import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { Product } from 'src/app/models/Product';
import Project from 'src/app/models/Project';
import { ProductAddModalComponent } from 'src/app/products/product-add-modal/product-add-modal.component';
import { ProductState } from 'src/app/products/product.state';

import { AddProjectProducts, FetchProjects } from '../project.actions';

@Component({
    selector: 'app-project-add-product-modal',
    templateUrl: './project-add-product-modal.component.html',
    styleUrls: ['./project-add-product-modal.component.scss'],
})
export class ProjectAddProductModalComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();
    @Select(ProductState.Products) allProducts$: Observable<Product[]>;
    @Input() project: Project;
    @Input() product: Product;
    @Input() isUpdate: boolean;

    productList = '';
    selectedArray: Product[] = [];
    allProducts: Product[];
    availableProducts: Product[] = [];
    title: string;
    amountArray: number[] = [];
    keyword = 'title';

    actualProducts: Product[];
    chosenProduct: string;
    existingProduct: Product;

    constructor(
        private toastr: ToastrService,
        private store: Store,
        private modalService: NgbModal,
        public activeModal: NgbActiveModal,
        private logger: NGXLogger,
    ) {}

    ngOnInit(): void {
        this.subscriptions.add(
            this.allProducts$.subscribe((products) => {
                this.allProducts = products;
            }),
        );

        if (this.allProducts && this.project.products) {
            this.actualProducts = [...this.allProducts];
            this.project.products.forEach((element) => {
                this.actualProducts.filter((el) => {
                    if (el?.uuid == element?.uuid) {
                        if (this.actualProducts.indexOf(el) != -1) this.actualProducts.splice(this.actualProducts.indexOf(el), 1);
                    }
                });
            });
        }
    }

    selectEvent(item) {
        this.addToProductList(item.uuid);
    }

    addToProductList(uuid: string) {
        this.productList = '';
        this.selectedArray.length = 0;

        this.productList = this.productList.concat(uuid);
        this.selectedArray.push(this.allProducts.filter((item: Product) => item.uuid.toLowerCase().includes(uuid))[0]);
    }

    openAddProductModal() {
        this.logger.debug('Opening addProductModal');
        this.modalService.open(ProductAddModalComponent, {
            windowClass: 'modal-prompt',
        });
    }

    addNewProducts() {
        if (this.productList == '') {
            alert('Please select a product');
        } else {
            this.logger.debug('Attempt to add projectProduct');
            this.subscriptions.add(
                this.store.dispatch(new AddProjectProducts(this.project.uuid, this.productList)).subscribe(
                    () => {
                        this.store.dispatch(new FetchProjects());
                        this.logger.debug('Succesfully added product to project');
                        this.toastr.success('Product added successfully');
                        this.chosenProduct = '';
                    },
                    (error) => {
                        this.logger.debug('Failed to add product to project');
                        this.toastr.error('Product already added');
                    },
                ),
            );
            this.activeModal.close();
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
