<div *ngIf="objectUuid">
    <app-loading-message *ngIf="isLoading"></app-loading-message>
    <div *ngIf="!isEditing && !isLoading" class="tag__list">
            <app-pill-global-tag
                *ngFor="let tag of objectTags"
                [tag]="tag"></app-pill-global-tag>
        <button
            (click)="switchEdit()"
            class="button button--outline button--small button--icon"
            type="button">
            <i class="las la-pen"></i>
        </button>
    </div>
    <div *ngIf="isEditing && !isLoading">
        <div
            *ngIf="tagSuggestions.length !== 0"
            class="tag__list align-items-center m-b-2">
            <p>Maybe you would like to add these skills?</p>
            <p
                (click)="addSuggestion($event)"
                *ngFor="let suggestion of tagSuggestions"
                class="pill">
                {{ suggestion }}
            </p>
        </div>
        <div class="d-flex">
            <tag-input
                (onAdd)="add($event)"
                (onRemove)="remove($event)"
                [(ngModel)]="objectTagsString"
                [clearOnBlur]="true"
                class="w-100" id="global-tag-input">
                <tag-input-dropdown
                    [appendToBody]="false"
                    [autocompleteItems]="allTagsString">
                </tag-input-dropdown>
            </tag-input>
            <button
                (click)="switchEdit()"
                class="button button--primary button--small button--icon  m-l-2"
                type="button">
                <i class="las la-check"></i>
            </button>
        </div>
        <span class="form-helptext">Hit ENTER to add tags.</span>
    </div>
</div>

<div *ngIf="!objectUuid">
    <app-loading-message *ngIf="isLoading"></app-loading-message>
    <div *ngIf="!isLoading">
        <tag-input
            (onAdd)="add($event)"
            (onRemove)="remove($event)"
            [(ngModel)]="objectTagsString"
            [clearOnBlur]="true">
            <tag-input-dropdown
                [appendToBody]="false"
                [autocompleteItems]="allTagsString"
                placeholder="">
            </tag-input-dropdown>
        </tag-input>
        <span class="form-helptext">Hit ENTER to add tags.</span>
    </div>
</div>
