<app-searchbar
    [(value)]="filter"
    (valueChange)="applyFilter($event)"></app-searchbar>

<div class="m-t-4">
    <table
        *ngIf="!isLoading; else loading"
        mat-table
        [dataSource]="dataSource"
        matSort
        matSortActive="email"
        matSortDirection="asc">
        <ng-container matColumnDef="email">
            <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header>
                <i class="las la-envelope"></i>Applicant
            </th>
            <td
                mat-cell
                *matCellDef="let row">
                <app-pill-applicant [applicant]="row"></app-pill-applicant>
            </td>
        </ng-container>

        <ng-container matColumnDef="createdOn">
            <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header>
                <i class="las la-calendar"></i>
                created on
            </th>
            <td
                mat-cell
                *matCellDef="let row">
                {{ row.createdOn | date }}
            </td>
        </ng-container>

        <ng-container matColumnDef="edit">
            <th
                mat-header-cell
                *matHeaderCellDef
                class="td-small"></th>
            <td
                mat-cell
                *matCellDef="let row">
                <span ngbDropdown>
                    <button
                        ngbDropdownToggle
                        class="button button--icon button--dropdown button--small"></button>
                    <span
                        ngbDropdownMenu
                        class="dropdown-menu dropdown-menu-right"
                        aria-labelledby="dropdownMenuButton">
                        <a
                            class="dropdown-item color--red"
                            *appIsPermitted="{
                                module: 'APPLICANTS',
                                operator: 'AND',
                                actions: ['CREATE']
                            }"
                            (click)="unArchiveApplicant(row)"
                            >Unarchive</a
                        >
                    </span>
                </span>
            </td>
        </ng-container>

        <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns"></tr>
        <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns"></tr>

        <tr *matNoDataRow>
            <td colspan="6">No applicants matching the filter "{{ filter }}"</td>
        </tr>
    </table>

    <ng-template #loading>
        <app-loading-message></app-loading-message>
    </ng-template>
</div>
