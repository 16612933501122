<div class="m-t-4">
    <table class="table-view m-t-4">
        <tr>
            <th scope="col">Naam</th>
            <th scope="col">Email</th>
            <th scope="col">Address</th>
            <th scope="col">City</th>
            <th scope="col">Currency</th>
            <th scope="col">Division number</th>
            <th width="1"></th>
        </tr>
        <tr *ngFor="let relation of relations | orderBy: 'Name':false:true | filterBy: ['Name', 'Email']:filter">
            <td
                class="pointer"
                (click)="updateRelation(relation)">
                <span>{{ relation.Name }}</span>
            </td>
            <td>
                {{ relation.Email }}
            </td>
            <td>
                <span *ngIf="relation.AddressLine1">{{ relation.AddressLine1 }}</span>
            </td>
            <td>
                <span *ngIf="relation.City">{{ relation.City }}</span>
            </td>
            <td>
                <span
                    *ngIf="relation.PurchaseCurrencyDescription"
                    class="tag"
                    >{{ relation.PurchaseCurrencyDescription }}</span
                >
            </td>
            <td>
                <span *ngIf="relation.division">{{ relation.division }}</span>
            </td>
            <td>
                <span
                    ngbDropdown
                    class="dropdown">
                    <span
                        *appIsPermitted="{
                            module: 'EXACT',
                            operator: 'OR',
                            actions: ['EDIT', 'DELETE']
                        }">
                        <button
                            type="button"
                            ngbDropdownToggle
                            class="button button--icon button--small dropdown-toggle"
                            id="dropdownMenuButton"></button>
                        <span
                            ngbDropdownMenu
                            class="dropdown-menu dropdown-menu-right"
                            aria-labelledby="dropdownMenuButton">
                            <a
                                class="dropdown-item"
                                *appIsPermitted="{
                                    module: 'EXACT',
                                    operator: 'AND',
                                    actions: ['EDIT']
                                }"
                                (click)="updateRelation(relation)"
                                >Edit</a
                            >
                            <div
                                class="dropdown-divider"
                                *appIsPermitted="{
                                    module: 'EXACT',
                                    operator: 'AND',
                                    actions: ['EDIT', 'DELETE']
                                }"></div>
                            <a
                                class="dropdown-item color--red"
                                (click)="deleteRelation(relation)"
                                *appIsPermitted="{
                                    module: 'EXACT',
                                    operator: 'AND',
                                    actions: ['DELETE']
                                }"
                                >Delete</a
                            >
                        </span>
                    </span>
                </span>
            </td>
        </tr>
    </table>
</div>
