import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subscription } from 'rxjs';
import { FetchContacts } from 'src/app/contacts/contacts.actions';
import { ContactState } from 'src/app/contacts/contacts.state';
import { ContactPerson } from 'src/app/models/ContactPerson';
import { Partner } from 'src/app/models/Partner';

import { ContactpersonDetailModalComponent } from '../../contacts/contactperson-detail-modal/contactperson-detail-modal.component';
import { PartnerState } from '../partners.state';

@Component({
    selector: 'app-detail-contactpersons',
    templateUrl: './detail-contactpersons.component.html',
    styleUrls: ['./detail-contactpersons.component.scss'],
})
export class DetailContactpersonsComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Select(PartnerState.getPartners) partners$: Observable<Partner[]>;
    @Select(ContactState.Contacts) contactPersons$: Observable<ContactPerson[]>;

    contactPersons: ContactPerson[];
    partnerUuid: string;

    constructor(private route: ActivatedRoute, private modalService: NgbModal, private store: Store, private logger: NGXLogger) {}

    ngOnInit(): void {
        this.logger.debug('Fetching contacts');
        this.store.dispatch(new FetchContacts());
        this.subscriptions.add(
            this.route.params.subscribe((params) => {
                this.partnerUuid = params.id;
                this.contactPersons$.subscribe((contacts) => (this.contactPersons = contacts));
            }),
        );
    }

    openAddContactPersonsModal() {
        this.logger.debug('Opening contactPersonDetailModal');
        const modalRef = this.modalService.open(ContactpersonDetailModalComponent);
        modalRef.componentInstance.partnerUuid = this.partnerUuid;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
