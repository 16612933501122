import { Clipboard } from '@angular/cdk/clipboard';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { TIMESHEET_STATUS, Timesheet } from 'src/app/models/Timesheet';
import { Timeslot } from 'src/app/models/Timeslot';
import { FetchProjects } from 'src/app/projects/project.actions';
import { ProjectState } from 'src/app/projects/project.state';
import { ConfirmationModalComponent } from 'src/app/shared/confirmation-modal/confirmation-modal.component';

import { EMOJIS } from '../../models/EMOJIS';
import Project from '../../models/Project';
import { EditHappinessScoreModalComponent } from '../edit-happiness-score-modal/edit-happiness-score-modal.component';
import { EditTimesheetRequiredHoursComponent } from '../edit-timesheet-required-hours/edit-timesheet-required-hours.component';
import { ChangeTimesheetStatus, RemoveTimesheet } from '../timesheets.actions';
import { TimesheetsService } from '../timesheets.service';
import { TimesheetState } from '../timesheets.state';

@Component({
    selector: 'app-timesheet-info-modal',
    templateUrl: './timesheet-info-modal.component.html',
    styleUrls: ['./timesheet-info-modal.component.scss'],
})
export class TimesheetInfoModalComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Input() timesheet: Timesheet;
    @Select(TimesheetState.timesheets) timesheets$: Observable<Timesheet[]>;
    @Select(ProjectState.projects) projects$: Observable<Project[]>;
    projects: Project[] = [];

    isLoading = true;

    timesheetPdf;
    filename;
    relevantProjects: Project[] = [];
    notLinkedProjects = '';
    actualIncome: number;
    billablePercentage: number;
    pdfUrl = '';
    pdfIsset = false;
    emojis: EMOJIS[] = [];

    getTotalMinutes = Timesheet.getTotalMinutes;

    getTotalDaysFromTimesheet = Timesheet.getTotalDaysFromTimesheet;
    getTotalDaysFromMinutes = Timesheet.getTotalDaysFromMinutes;
    getRequiredHoursDiff = Timesheet.getRequiredHoursDiff;
    getRequiredHoursDiffPercentage = Timesheet.getRequiredHoursDiffPercentage;

    constructor(
        private sanitizer: DomSanitizer,
        private store: Store,
        public activeModal: NgbActiveModal,
        private timesheetsService: TimesheetsService,
        private logger: NGXLogger,
        private modalService: NgbModal,
        private toastr: ToastrService,
        private clipboard: Clipboard,
    ) {}

    ngOnInit(): void {
        this.fetchData();
        if (this.timesheet.timeslots.length > 0) {
            this.getPdf(this.timesheet.timeslots[0]);
        }
        this.emojis = Object.values(EMOJIS);
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    private fetchData() {
        this.subscriptions.add(
            this.timesheets$.subscribe((timesheets) => {
                if (timesheets) this.timesheet = timesheets.find((timesheet) => timesheet.uuid === this.timesheet.uuid);
            }),
        );

        this.subscriptions.add(
            this.projects$.subscribe((projects) => {
                this.projects = projects;
                if (!projects) {
                    this.logger.debug('Fetching project');
                    this.store.dispatch(new FetchProjects());
                    return;
                }
                this.createNotLinkedMessage(projects);
                this.actualIncome = Timesheet.getActualIncome(this.timesheet, this.relevantProjects);
                this.getBillablePercentage();
                this.isLoading = false;
            }),
        );
    }

    private createNotLinkedMessage(projects: Project[]) {
        this.notLinkedProjects = '';
        this.relevantProjects = [];

        if (this.timesheet) {
            this.timesheet.timeslots.forEach((slot) => {
                const relevantProject = projects.find((project) => project.uuid == slot.project.uuid);
                if (relevantProject && relevantProject.defaultLinkedEmployees?.length > 0) {
                    this.relevantProjects.push(relevantProject);
                    const employee = relevantProject.defaultLinkedEmployees?.find((link) => link.employeeId == this.timesheet.employee.uuid);
                    if (!employee) {
                        this.notLinkedProjects += relevantProject.title + ', ';
                    }
                }
            });
        }

        if (this.notLinkedProjects.endsWith(', ')) {
            this.notLinkedProjects = this.notLinkedProjects.slice(0, -2);
        }
    }

    changeStatus(status: string) {
        this.subscriptions.add(
            this.store.dispatch(new ChangeTimesheetStatus(this.timesheet.uuid, TIMESHEET_STATUS.getStatus(status))).subscribe(
                () => {
                    this.logger.debug('Succesfully updated timesheet');
                },
                (error) => {
                    this.logger.error('Failed to update timesheet');
                },
            ),
        );
    }

    getPdf(timeslot: Timeslot) {
        this.pdfUrl = '';
        this.pdfIsset = timeslot.pdfUrl != '';

        if (timeslot.pdfUrl) {
            this.subscriptions.add(
                this.timesheetsService.getPdf(timeslot.uuid).subscribe((pdf) => {
                    this.filename = `${this.timesheet.uuid}.pdf`;
                    this.base64ToBlob(pdf.pdf);
                }),
            );
        }
    }

    base64ToBlob(data) {
        const byteChars = atob(data);
        const byteNumbers = new Array(byteChars.length);
        for (let i = 0; i < byteChars.length; i++) {
            byteNumbers[i] = byteChars.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);
        this.timesheetPdf = this.sanitizer.bypassSecurityTrustUrl(url);
        this.filename = `${this.timesheet.uuid}.pdf`;
        this.pdfUrl = url;
    }

    deleteTimesheet() {
        this.logger.debug('starting remove timesheet flow');
        const modalRef = this.modalService.open(ConfirmationModalComponent, {
            windowClass: 'modal-prompt',
            animation: false,
        });
        modalRef.componentInstance.type = 'Delete';
        modalRef.componentInstance.title = 'Delete timesheet';
        modalRef.componentInstance.message =
            'Are you sure you want to delete ' + this.timesheet.employee.firstName + "'s timesheet? \nAll linked timeslots will be removed allong with it.";

        this.subscriptions.add(
            modalRef.componentInstance.closeEvent.subscribe((val) => {
                if (val) {
                    this.logger.debug('attempting to remove timesheet');
                    this.store.dispatch(new RemoveTimesheet(this.timesheet.uuid)).subscribe(
                        () => {
                            this.logger.debug('successfully removed timesheet');
                            this.activeModal.dismiss();
                            this.toastr.info('Timesheet was removed successfully.');
                        },
                        (error) => {
                            this.logger.error(error);
                            this.toastr.error('Oops, something went wrong please try again later.');
                        },
                    );
                }
            }),
        );
    }

    getActualIncome() {
        let total = 0;
        this.timesheet.timeslots.forEach((slot) => {
            if (slot.project.billable) {
                const project = this.relevantProjects.find((w) => w.uuid == slot.project.uuid);
                if (project && project.linkedEmployees) {
                    const link = project.linkedEmployees.find((link) => link.employee.uuid == this.timesheet.employee.uuid);
                    if (link) {
                        const price = link.pricePerHour;
                        total += (slot.minutes / 60) * price;
                    }
                }
            }
        });
        this.actualIncome = total;
    }

    getBillablePercentage() {
        let billableMinutes = 0;
        let nonBillableMinutes = 0;
        this.timesheet.timeslots.forEach((slot) => (slot.project.billable ? (billableMinutes += slot.minutes) : (nonBillableMinutes += slot.minutes)));
        const total = billableMinutes + nonBillableMinutes;
        this.billablePercentage = Math.round((billableMinutes / total) * 100);
    }

    editHoursRequired() {
        this.logger.debug('Opening EditTimesheetRequiredHoursComponent');
        const modalRef = this.modalService.open(EditTimesheetRequiredHoursComponent, {
            windowClass: 'modal-prompt',
            animation: false,
        });
        modalRef.componentInstance.timesheet = this.timesheet;
    }

    sendReminder() {
        this.subscriptions.add(this.timesheetsService.sendReminder(this.timesheet.uuid).subscribe());
    }

    openHappinessScoreModal() {
        const modalRef = this.modalService.open(EditHappinessScoreModalComponent, {
            windowClass: 'modal-prompt',
            animation: false,
        });
        modalRef.componentInstance.happinessScore = this.timesheet.happyScore;
        modalRef.componentInstance.timesheetId = this.timesheet.uuid;
    }

    copyUrlToClipboard() {
        this.clipboard.copy(this.timesheet.url);
    }

    timeslotUpdated() {
        this.createNotLinkedMessage(this.projects);
    }
}
