import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';
import { ResumeVariantSkill } from 'src/app/models/resume/ResumeVariantSkill';

import { DeleteResumeVariantSkill, FetchResumes } from '../resume.actions';

@Component({
    selector: 'app-resume-variant-skill-delete-modal',
    templateUrl: './resume-variant-skill-delete-modal.component.html',
    styleUrls: ['./resume-variant-skill-delete-modal.component.scss'],
})
export class ResumeVariantSkillDeleteModalComponent implements OnInit {
    private subscriptions = new Subscription();

    @Input() skill: ResumeVariantSkill;
    @Input() resumeUuid: string;

    constructor(public activeModal: NgbActiveModal, private store: Store, private logger: NGXLogger) {}

    ngOnInit(): void {}

    deleteSkill() {
        this.logger.debug('Attempt to delete skill');
        this.subscriptions.add(
            this.store.dispatch(new DeleteResumeVariantSkill(this.skill.uuid)).subscribe({
                next: () => {
                    this.logger.debug('Succesfully deleted skill');

                    this.activeModal.close();
                },
                error: (error) => this.logger.error('Failed to delete skill'),
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
