<a
    class="pill"
    (click)="$event.stopPropagation(); openUploadedDocumentDetailModal()">
    <i class="las la-file-alt"></i>
    <ng-container *ngIf="uploadedDocument; else loading">
        {{ fileName.length > 50 ? (fileName | slice: 0:50) + '...' : fileName }}
    </ng-container>
</a>

<ng-template #loading>
    <app-loading-message></app-loading-message>
</ng-template>
