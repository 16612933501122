import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { NgbDropdownModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { TagInputModule } from 'ngx-chips';
import { OrderModule } from 'ngx-order-pipe';

import { ProductsModule } from '../products/products.module';
import { SharedModule } from '../shared/shared.module';
import { UnitAddModalComponent } from './unit-add-modal/unit-add-modal.component';
import { UnitDeleteModalComponent } from './unit-delete-modal/unit-delete-modal.component';
import { UnitsListComponent } from './units-list/units-list.component';
import { UnitsOverviewComponent } from './units-overview/units-overview.component';
import { routing } from './units.routing';

@NgModule({
    declarations: [UnitsOverviewComponent, UnitAddModalComponent, UnitDeleteModalComponent, UnitsListComponent],
    imports: [
        CommonModule,
        SharedModule,
        AutocompleteLibModule,
        routing,
        NgbModule,
        NgbDropdownModule,
        OrderModule,
        TagInputModule,
        ProductsModule,
        CdkTableModule,
        MatTableModule,
        MatSortModule,
    ],
})
export class UnitsModule {}
