import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { CheckLoggedIn } from 'src/app/exact/exact.actions';
import { ExactState } from 'src/app/exact/exact.state';
import { Module } from 'src/app/models/Module';

import { ExactLoginService } from './exact-login.service';

@Component({
    selector: 'app-exact-login',
    templateUrl: './exact-login.component.html',
    styleUrls: ['./exact-login.component.scss'],
})
export class ExactLoginComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();
    @Select(ExactState.loggedIn) loggedIn$: Observable<boolean>;

    @Input() module: Module;
    authUrl: string;
    isLoading = true;

    constructor(private exactLoginService: ExactLoginService, private toastr: ToastrService, private logger: NGXLogger, private store: Store) {}

    ngOnInit(): void {
        this.subscriptions.add(
            this.loggedIn$.subscribe((loggedIn) => {
                if (loggedIn != null) {
                    if (!loggedIn) {
                        this.logger.debug('Not logged in with exact');
                        this.exactLoginService.getAuthorizationUrl().subscribe((url) => {
                            this.authUrl = url.authUrl;
                        });
                    } else {
                        this.logger.debug('Logged in with exact');
                    }
                    this.isLoading = false;
                } else {
                    this.logger.debug('Checking if logged in with exact');
                    this.store.dispatch(new CheckLoggedIn()).subscribe();
                }
            }),
        );
    }

    logout() {
        this.logger.debug('Attempt to logout from exact');
        this.subscriptions.add(
            this.exactLoginService.logout().subscribe(
                (response) => {
                    if (response) {
                        this.store.dispatch(new CheckLoggedIn()).subscribe();
                        this.logger.debug('Logged out from exact');
                        this.toastr.info('Successfully logged out.');
                    }
                },
                (error) => this.logger.error('Failed to logout from exact'),
            ),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
