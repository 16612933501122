import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { addDays, format, formatISO } from 'date-fns';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';

import { EmployeesAddModalComponent } from '../../employees/employees-add-modal/employees-add-modal.component';
import { FetchEmployees } from '../../employees/employees.actions';
import { EmployeeState } from '../../employees/employees.state';
import { Employee } from '../../models/Employee';
import PlannedEvent from '../../models/PlannedEvent';
import PlanningRecurrenceRule from '../../models/PlanningRecurrenceRule';
import Project from '../../models/Project';
import { ProjectDetailModalComponent } from '../../projects/project-detail-modal/project-detail-modal.component';
import { FetchProjects } from '../../projects/project.actions';
import { ProjectState } from '../../projects/project.state';
import { createColor } from '../../shared/utils/color-utils';
import { DateModalComponent } from '../date-modal/date-modal.component';
import { AddPlanning, UpdatePlanning } from '../planning.actions';
import { RecurrenceDialogComponent } from '../recurrence-dialog/recurrence-dialog.component';

@Component({
    selector: 'app-add-planning-modal',
    templateUrl: './add-planning-modal.component.html',
    styleUrls: ['./add-planning-modal.component.scss'],
})
export class AddPlanningModalComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Select(ProjectState.projects) projects$: Observable<Project[]>;
    @Select(EmployeeState.Employees) employees$: Observable<Employee[]>;
    projects: Project[] = [];
    employees: Employee[] = [];

    @Input() args;
    @Input() plannedEvent: PlannedEvent;
    @Input() projectId: string;
    @Input() type: string;

    planningForm: FormGroup;

    isAllDay = true;
    recurrenceRules: PlanningRecurrenceRule[] = [];
    recurringExceptions: Date[] = [];

    constructor(
        public activeModal: NgbActiveModal,
        private fb: FormBuilder,
        private store: Store,
        private toastr: ToastrService,
        private logger: NGXLogger,
        private modalService: NgbModal,
    ) {}

    ngOnInit(): void {
        this.seedRecurrenceRules();
        this.createPlanningForm();
        this.fetchData();
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    addPlanning() {
        const newPlanning: any = {
            workId: this.planningForm.get('project').value.uuid,
            employeeId: this.planningForm.get('employee').value.uuid,
            description: this.planningForm.get('description').value,
            startMoment: formatISO(new Date(`${this.planningForm.get('startDate').value}T${this.planningForm.get('startTime').value}`)),
            endMoment: formatISO(new Date(`${this.planningForm.get('endDate').value}T${this.planningForm.get('endTime').value}`)),
            timelineColor: createColor(this.planningForm.get('project').value.title),
            allDay: this.planningForm.get('allDay').value,
            recurringException: this.recurringExceptions,
        };
        if (this.planningForm.get('recurrenceRule').value.title != "Doesn't repeat") {
            newPlanning.recurring = {
                ...this.planningForm.get('recurrenceRule').value,
                ...this.planningForm.get('recurring').value,
            };
        }
        if (!this.plannedEvent) {
            this.store.dispatch(new AddPlanning(newPlanning)).subscribe(
                (res) => {
                    this.toastr.success('Planned event successfully added');
                    this.activeModal.close();
                },
                (error) => {
                    this.logger.error('Failed to add planned event');
                    this.toastr.error('Oops, something went wrong, Please try again later...');
                },
            );
        } else {
            console.log(newPlanning);
            newPlanning.uuid = this.plannedEvent.uuid;
            this.store.dispatch(new UpdatePlanning(newPlanning)).subscribe(
                (res) => {
                    this.toastr.success('Planned event successfully updated');
                    this.activeModal.close();
                },
                (error) => {
                    this.logger.error('Failed to update planned event');
                    this.toastr.error('Oops, something went wrong, Please try again later...');
                },
            );
        }
    }

    openCreateProjectModal() {
        this.modalService.open(ProjectDetailModalComponent, {
            windowClass: 'modal-pane',
            animation: false,
        });
    }

    openAddEmployeeModal() {
        this.modalService.open(EmployeesAddModalComponent, {
            windowClass: 'modal-pane',
            animation: false,
        });
    }

    openCreateCustomRecurrenceRule($event: any) {
        if (this.planningForm.get('recurrenceRule').value.title === 'Custom...') {
            const modalRef = this.modalService.open(RecurrenceDialogComponent, {
                windowClass: 'modal-prompt',
                animation: false,
            });
            modalRef.componentInstance.rule = this.planningForm.get('recurrenceRule').value;
            this.subscriptions.add(
                modalRef.componentInstance.closeEvent.subscribe((value) => {
                    const indexRuleToUpdate = this.recurrenceRules.findIndex((rule) => rule.title === 'Custom...');
                    this.recurrenceRules[indexRuleToUpdate] = {
                        ...value,
                        title: 'Custom...',
                    };
                    this.planningForm.get('recurrenceRule').setValue({ ...value, title: 'Custom...' });
                }),
            );
        }
    }

    setAllDay() {
        let endDate: Date;
        if (this.planningForm.value.allDay) {
            endDate = addDays(new Date(this.planningForm.value.endDate), 1);
        } else {
            endDate = addDays(new Date(this.planningForm.value.endDate), -1);
        }
        this.planningForm.get('endDate').setValue(format(endDate, 'yyyy-MM-dd'));
        this.planningForm.get('allDay').setValue(!this.planningForm.get('allDay').value);
    }

    deleteException(i: number) {
        this.recurringExceptions.splice(i, 1);
    }

    addException() {
        const modalRef = this.modalService.open(DateModalComponent, {
            windowClass: 'modal-prompt',
            animation: false,
        });
        this.subscriptions.add(
            modalRef.componentInstance.closeEvent.subscribe((date) => {
                this.recurringExceptions.push(date);
            }),
        );
    }

    updateEndsType() {
        if (this.planningForm.get('recurring.endsType').value) {
            this.planningForm.get('recurring.until').setValue(null);
            this.planningForm.get('recurring.count').setValue(null);
        } else if (this.planningForm.get('recurring.endsType').value) {
            this.planningForm.get('recurring.count').setValue(null);
        } else {
            this.planningForm.get('recurring.until').setValue(null);
        }
    }

    private seedRecurrenceRules(): void {
        this.recurrenceRules = [
            {
                title: "Doesn't repeat",
            },
            {
                repeat: 'WEEKLY',
                interval: 1,
                weekDays: 'MO, TU, WE, TH, FR',
                title: 'Every weekday',
            },
            {
                repeat: 'WEEKLY',
                interval: 1,
                title: 'Weekly',
            },
            {
                repeat: 'MONTHLY',
                interval: 1,
                day: 1,
                title: 'Monthly on day 1',
            },
            { title: 'Custom...' },
        ];
    }

    private createPlanningForm(): void {
        if (!this.plannedEvent) {
            this.planningForm = this.fb.group({
                project: ['', [Validators.required]],
                allDay: false,
                startDate: [format(this.args.event.start, 'yyyy-MM-dd'), [Validators.required]],
                startTime: [format(this.args.event.start, 'HH:mm'), [Validators.required]],
                endDate: [format(this.args.event.end, 'yyyy-MM-dd'), [Validators.required]],
                endTime: [format(this.args.event.end, 'HH:mm'), [Validators.required]],
                employee: ['', [Validators.required]],
                description: '',
                recurrenceRule: '',
                recurring: this.fb.group({
                    until: null,
                    count: null,
                    endsType: 'never',
                }),
            });
        } else {
            /* The old values of start and end are used
             * When dragging, old values will still be prefilled because when selecting and updating, the previous events are deleted
             *  */
            this.planningForm = this.fb.group({
                project: ['', [Validators.required]],
                startDate: [format(this.plannedEvent.start, 'yyyy-MM-dd'), [Validators.required]],
                startTime: [format(this.plannedEvent.start, 'HH:mm'), [Validators.required]],
                endDate: [format(this.plannedEvent.end, 'yyyy-MM-dd'), [Validators.required]],
                endTime: [format(this.plannedEvent.end, 'HH:mm'), [Validators.required]],
                employee: ['', [Validators.required]],
                description: this.plannedEvent.description,
                allDay: this.plannedEvent.allDay,
                recurrenceRule: '',
                recurring: this.fb.group({
                    until: this.plannedEvent.recurring ? this.plannedEvent.recurring.until : '',
                    count: this.plannedEvent.recurring ? this.plannedEvent.recurring.count : '',
                    endsType: this.plannedEvent.recurring ? this.getEndsType(this.plannedEvent.recurring) : '',
                }),
            });
            this.findRecurrenceRule();
        }
        if (!this.plannedEvent || !this.plannedEvent.recurring) {
            this.planningForm.get('recurrenceRule').setValue(this.recurrenceRules[0]);
        }

        if (this.plannedEvent && this.plannedEvent.recurringException) {
            this.recurringExceptions = [...this.plannedEvent.recurringException];
        }
    }

    private findRecurrenceRule(): void {
        if (!this.plannedEvent.recurring) {
            // Event is not recurring
            this.planningForm.get('recurrenceRule').setValue(this.recurrenceRules[0]);
            return;
        }
        // Event is recurring
        if (this.plannedEvent.recurring.repeat.toLowerCase() === 'weekly' && this.plannedEvent.recurring.interval === 1) {
            // Weekly recurring event
            if (!this.plannedEvent.recurring.weekDays) {
                // Weekly
                this.planningForm.get('recurrenceRule').setValue(this.recurrenceRules[2]);
                return;
            }
            if (this.plannedEvent.recurring.weekDays === 'MO, TU, WE, TH, FR') {
                // Every weekday
                this.planningForm.get('recurrenceRule').setValue(this.recurrenceRules[1]);
                return;
            }
            // Custom weekly recurring event
            const rule = { ...this.plannedEvent.recurring, title: 'Custom...' };
            this.recurrenceRules[4] = rule;
            this.planningForm.get('recurrenceRule').setValue(rule);
            return;
        }
        if (
            this.plannedEvent.recurring.repeat.toLowerCase() === 'monthly' &&
            this.plannedEvent.recurring.interval === 1 &&
            this.plannedEvent.recurring.day === 1
        ) {
            // Monthly recurring event
            this.planningForm.get('recurrenceRule').setValue(this.recurrenceRules[3]);
            return;
        }
        // Custom recurring event
        const rule = { ...this.plannedEvent.recurring, title: 'Custom...' };
        this.recurrenceRules[4] = rule;
        this.planningForm.get('recurrenceRule').setValue(rule);
        return;
    }

    private fetchData(): void {
        this.subscriptions.add(
            this.projects$.subscribe((projects) => {
                if (!projects) {
                    this.logger.debug('Fetching Projects');
                    this.store.dispatch(new FetchProjects());
                    return;
                }
                this.projects = projects;
                if (this.plannedEvent && this.projects && this.projects.length > 0) {
                    this.planningForm.get('project').setValue(this.projects.find((p) => p.uuid === this.plannedEvent.projectId));
                } else if (this.projectId && this.projects && this.projects.length > 0) {
                    this.planningForm.get('project').setValue(this.projects.find((p) => p.uuid === this.projectId));
                }
            }),
        );

        this.subscriptions.add(
            this.employees$.subscribe((employees) => {
                if (!employees) {
                    this.logger.debug('Fetching Employees');
                    this.store.dispatch(new FetchEmployees());
                    return;
                }
                this.employees = employees;
                if (this.employees && this.employees.length > 0) {
                    this.planningForm.get('employee').setValue(this.employees.find((e) => e.uuid === this.args.event.resource));
                }
            }),
        );
    }

    private getEndsType(rule): string {
        if (!rule.until && !rule.count) {
            return 'never';
        } else if (!rule.until) {
            return 'after';
        } else {
            return 'on';
        }
    }
}
