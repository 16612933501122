import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Params, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import * as moment from 'moment';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subscription } from 'rxjs';
import { CompanyState } from 'src/app/companies/company.state';
import { Resume } from 'src/app/models/resume/Resume';
import { JSONTryParse } from 'src/app/shared/utils/string-utils';

import { ResumeVariant } from '../../models/resume/ResumeVariant';
import { ResumeAddModalComponent } from '../resume-add-modal/resume-add-modal.component';
import { ResumeDeleteModalComponent } from '../resume-delete-modal/resume-delete-modal.component';
import { ResumePersoninfoUpdateModalComponent } from '../resume-personinfo-update-modal/resume-personinfo-update-modal.component';
import { ResumeShareModalComponent } from '../resume-share-modal/resume-share-modal.component';
import { FetchResumes, GetEducations, GetResume } from '../resume.actions';
import { ResumeState } from '../resume.state';

@Component({
    selector: 'app-resume-list',
    templateUrl: './resume-list.component.html',
    styleUrls: ['./resume-list.component.scss'],
})
export class ResumeListComponent implements OnInit, OnDestroy {
    @Select(ResumeState.resumes) resumes$: Observable<Resume[]>;
    @Select(CompanyState.companyUuid) companyUuid$: Observable<string>;
    filter: string;
    currentDate: Date;
    properties;
    isLoading = true;
    tagFilter: string;
    displayedColumns: string[] = ['lastName', 'jobTitle', 'lastModified', 'menu'];
    dataSource: MatTableDataSource<Resume>;
    @ViewChild(MatSort) sort: MatSort;
    private subscriptions = new Subscription();

    constructor(private modalService: NgbModal, private store: Store, private router: Router, private logger: NGXLogger) {}

    ngOnInit(): void {
        this.logger.debug('Fetching resumes');
        this.store.dispatch(new FetchResumes());
        this.updateTable();

        this.calculateDaysSinceInvite();
        this.properties = {
            jobTitle: true,
            createdOn: false,
            lastModified: true,
        };
        this.currentDate = new Date();
    }

    applyFilter(filterValue) {
        this.dataSource.filter = filterValue?.trim().toLowerCase();
    }

    applyTagFilter(filterValue) {
        this.dataSource.filter = filterValue;
    }

    toggleProperty(prop: string) {
        console.log(this.properties);
        this.properties[prop] = !this.properties[prop];
    }

    calculateDaysSinceInvite() {
        this.subscriptions.add(
            this.companyUuid$.subscribe((companyUuid) => {
                this.logger.debug('Fetching resumes');
                this.store.dispatch(new FetchResumes()).subscribe(() => {
                    this.resumes$.subscribe((resumes) => {
                        resumes.forEach((resume) => {
                            // this.store.dispatch(new GetResumeVariants(resume.uuid));

                            const createdOnDate = resume.createdOn;
                            Object.assign({}, resume, {
                                daysSinceCreation: Math.abs(moment(this.currentDate).diff(moment(createdOnDate), 'days')),
                            });
                        });
                    });
                });
            }),
        );
    }

    updateTable() {
        this.subscriptions.add(
            this.resumes$.subscribe(
                (resumes) => {
                    /* setup mattable with contacts */
                    this.dataSource = new MatTableDataSource(resumes);
                    this.dataSource.sort = this.sort;
                    this.dataSource.filterPredicate = (data: Resume, filter: string) => {
                        let filterObj = null;
                        if (JSONTryParse(filter)) {
                            filterObj = JSON.parse(filter);
                        }

                        return (
                            (data.firstName + ' ' + data.lastName).toLowerCase().includes(filter.toLowerCase()) ||
                            (data.contactInformation &&
                                data.contactInformation.email &&
                                data.contactInformation.email.toLowerCase().includes(filter.toLowerCase())) ||
                            data.resumeVariants.some((rv) => rv.jobTitle.toLowerCase().includes(filter.toLowerCase())) ||
                            filterObj?.objectsWithTagUuids.includes(data.uuid)
                        );
                    };

                    this.dataSource.sortingDataAccessor = (item, property) => {
                        if (property.includes('.')) return property.split('.').reduce((o, i) => o[i], item);
                        return item[property];
                    };
                    this.applyFilter(this.filter);
                    this.applyTagFilter(this.tagFilter);
                },
                (error) => this.logger.error('Failed to fetch Resumes'),
            ),
        );
    }

    openAddResumeModal() {
        this.logger.debug('Opening resumeAddModal');
        this.modalService.open(ResumeAddModalComponent, {
            windowClass: 'modal-prompt',
            animation: false,
        });
    }

    openEditResumePersonInfoUpdateModal(resume: Resume) {
        this.logger.debug('Opening resumePersonInfoUpdateModal');
        const modalRef = this.modalService.open(ResumePersoninfoUpdateModalComponent, { animation: false });
        modalRef.componentInstance.resume = resume;
    }

    openDeleteResumeModal(resume: Resume) {
        this.logger.debug('Opening resumeDeleteModal');
        const modalRef = this.modalService.open(ResumeDeleteModalComponent, {
            windowClass: 'modal-prompt',
            animation: false,
        });
        modalRef.componentInstance.resume = resume;
    }

    resumeDetail(resume: Resume, variant?: ResumeVariant) {
        this.logger.debug('Fetching resume');
        this.subscriptions.add(
            this.store.dispatch(new GetResume(resume.uuid)).subscribe(() => {
                this.logger.debug('Fetching educations');
                this.store.dispatch(new GetEducations(resume.uuid));
                this.logger.debug('Navigating to resume details');
                const params: Params = { variant: variant ? variant.uuid : resume.resumeVariants[0].uuid };
                this.router.navigate(['/', 'resume', resume.uuid, 'detail'], { queryParams: params });
            }),
        );
    }

    openResumeShareModal(resume: Resume) {
        this.logger.debug('Opening resumeShareModal');
        const modalRef = this.modalService.open(ResumeShareModalComponent, { animation: false });
        modalRef.componentInstance.resume = resume;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
