import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenticationService } from 'src/app/authentication/authentication.service';
import { environment } from 'src/environments/environment';

import { YukiCredential } from '../../../models/yuki/YukiCredential';

@Injectable({
    providedIn: 'root',
})
export class YukiLoginService {
    httpOptions = {};

    constructor(private httpClient: HttpClient, private authenticationService: AuthenticationService) {
        this.httpOptions = authenticationService.getHeaders();
    }

    authorize(yukiCredential: YukiCredential) {
        return this.httpClient.post<boolean>(
            environment.baseUrl + `/yuki/authorize?apiKey=${yukiCredential.key}&administration=${yukiCredential.name}`,
            {},
            this.httpOptions,
        );
    }

    sync() {
        return this.httpClient.post<boolean>(environment.baseUrl + `/yuki/sync`, {}, this.httpOptions);
    }

    fetchCredentials() {
        return this.httpClient.get<YukiCredential>(`${environment.baseUrl}/yuki/api-key`, this.httpOptions);
    }
}
