import { QueryDTO } from '../models/QueryDTO';
import { VeldaBrandDTO } from '../models/VeldaBrandDTO';

export class FetchBrands {
    static readonly type = '[VeldaBrand] Fetch Brands';
    constructor() {}
}

export class AddBrand {
    static readonly type = '[VeldaBrand] Add Brand';
    constructor(public brand: VeldaBrandDTO) {}
}

export class EditBrand {
    static readonly type = '[VeldaBrand] Edit Brand';
    constructor(public brandUuid: string, public brand: VeldaBrandDTO) {}
}

export class DeleteBrand {
    static readonly type = '[VeldaBrand] Delete Brand';
    constructor(public brandUuid: string) {}
}

export class FetchQueries {
    static readonly type = '[Query] Fetch Queries';
    constructor() {}
}

export class FetchQueryByType {
    static readonly type = '[Query] Fetch Query By Type';
    constructor(public type: string) {}
}

export class AddQuery {
    static readonly type = '[Query] Add Query';
    constructor(public query: QueryDTO) {}
}

export class EditQuery {
    static readonly type = '[Query] Edit Query';
    constructor(public queryUuid: string, public query: QueryDTO) {}
}

export class DeleteQuery {
    static readonly type = '[Query] Delete Query';
    constructor(public queryUuid: string) {}
}
