<div class="marketing">
    <app-home-toolbar></app-home-toolbar>

    <section class="marketing__hero">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-7">
                    <h1>Software op maat van je onderneming</h1>
                    <p>
                        ERP, CRM, lead-generation of koppeling van je huidige softwarepakketen in 1 centrale omgeving. Met ruimte voor maatwerk voor elke
                        proces.
                    </p>
                    <a
                        routerLink="/register"
                        class="button button--big button--danger"
                        >Get started</a
                    >
                    <span class="button button--big button--outline m-l-2">Learn more</span>
                </div>
            </div>
        </div>
    </section>

    <div class="spacer"></div>
    <div class="spacer"></div>

    <div class="container-fluid">
        <div class="row">
            <div class="col">
                <h2>Wat kan je doen met Resumo?</h2>
                <p>Resumo komt met een groot aantal standaard modules.</p>
            </div>
        </div>

        <div class="spacer"></div>

        <div class="row">
            <div class="col-md-3 m-b-2">
                <div class="marketing__features__item">
                    <div class="marketing__features__item__icon">
                        <i class="las la-mail-bulk"></i>
                    </div>
                    <h4 class="marketing__features__item__title">Leads & channels</h4>
                    <div class="marketing__features__item__description">Verzamel leads via verschillende kanalen. Zet ze om naar klanten.</div>
                </div>
            </div>
            <div class="col-md-3 m-b-2">
                <div class="marketing__features__item">
                    <div class="marketing__features__item__icon">
                        <i class="las la-boxes"></i>
                    </div>
                    <h4 class="marketing__features__item__title">Products, warehouses & stock</h4>
                    <div class="marketing__features__item__description">Makkelijk je voorraad en product(fiches) beheren.</div>
                </div>
            </div>
            <div class="col-md-3 m-b-2">
                <div class="marketing__features__item">
                    <div class="marketing__features__item__icon">
                        <i class="lar la-address-book"></i>
                    </div>
                    <h4 class="marketing__features__item__title">CRM</h4>
                    <div class="marketing__features__item__description">Beheer je klanten en contacten. Sell like a boss!</div>
                </div>
            </div>
            <div class="col-md-3 m-b-2">
                <div class="marketing__features__item">
                    <div class="marketing__features__item__icon">
                        <i class="las la-inbox"></i>
                    </div>
                    <h4 class="marketing__features__item__title">Vacature management</h4>
                    <div class="marketing__features__item__description">Stel vacatures op en ontvang CV's door 1 klik.</div>
                </div>
            </div>
        </div>

        <div class="spacer"></div>

        <div class="row">
            <div class="col">
                <h2>Je huidige software behouden!</h2>
                <p>Wij connecteren je huidige software.</p>
            </div>
        </div>

        <div class="spacer"></div>

        <div class="row">
            <div class="col-md-3 m-b-2">
                <div class="marketing__features__item">
                    <div class="marketing__features__item__image">
                        <img src="/assets/img/homepage/logo-microsoft.png" />
                    </div>
                    <h4 class="marketing__features__item__title">Microsoft</h4>
                    <div class="marketing__features__item__description">Koppel je Outlook e-mails en kalender.</div>
                </div>
            </div>
            <div class="col-md-3 m-b-2">
                <div class="marketing__features__item">
                    <div class="marketing__features__item__image">
                        <img src="/assets/img/homepage/logo-google.png" />
                    </div>
                    <h4 class="marketing__features__item__title">Google</h4>
                    <div class="marketing__features__item__description">Koppel Gmail en Google Calendar.</div>
                </div>
            </div>
            <div class="col-md-3 m-b-2">
                <div class="marketing__features__item">
                    <div class="marketing__features__item__image">
                        <img src="/assets/img/homepage/logo-mailchimp.png" />
                    </div>
                    <h4 class="marketing__features__item__title">Mailchimp</h4>
                    <div class="marketing__features__item__description">E-mails verzenden en resultaten opvragen.</div>
                </div>
            </div>
            <div class="col-md-3 m-b-2">
                <div class="marketing__features__item">
                    <div class="marketing__features__item__image">
                        <img src="/assets/img/homepage/logo-adsolut.png" />
                    </div>
                    <h4 class="marketing__features__item__title">Adsolut</h4>
                    <div class="marketing__features__item__description">Doe zoveel meer met je Adsolut (Cloud) data.</div>
                </div>
            </div>
        </div>

        <div class="spacer"></div>
    </div>

    <div class="spacer"></div>
    <div class="spacer"></div>
</div>
