<app-app-holder>
    <app-navbar></app-navbar>

    <app-sidebar>
        <app-velda-articles-subnav></app-velda-articles-subnav>
    </app-sidebar>

    <app-content>
        <div class="app__title">
            <h1>Subcollections overview</h1>
            <span
                ngbDropdown
                class="dropdown m-l-2">
                <button
                    ngbDropdownToggle
                    class="button button--select">
                    Options
                </button>
                <span
                    ngbDropdownMenu
                    class="dropdown-menu-right">
                    <form>
                        <span
                            *appIsPermitted="{
                                module: 'VELDA_ARTICLES',
                                operator: 'AND',
                                actions: ['CREATE']
                            }">
                            <label
                                ngbDropdownItem
                                (click)="openAddSubCollectionQueryModal()"
                                *ngIf="!this.query && !this.loading"
                                >Add subcollections query</label
                            >
                        </span>
                        <span
                            *appIsPermitted="{
                                module: 'VELDA_ARTICLES',
                                operator: 'AND',
                                actions: ['EDIT']
                            }">
                            <label
                                ngbDropdownItem
                                (click)="openEditSubCollectionQueryModal()"
                                *ngIf="this.query && !this.loading"
                                >Edit subcollections query</label
                            >
                        </span>
                        <span
                            *appIsPermitted="{
                                module: 'VELDA_ARTICLES',
                                operator: 'AND',
                                actions: ['DELETE']
                            }">
                            <label
                                ngbDropdownItem
                                (click)="openDeleteSubCollectionQueryModal()"
                                *ngIf="this.query && !this.loading"
                                >Delete subcollections query</label
                            >
                        </span>
                    </form>
                </span>
            </span>
        </div>

        <span
            *appIsPermitted="{
                module: 'VELDA_ARTICLES',
                operator: 'AND',
                actions: ['READ']
            }">
            <app-velda-articles-subcollections-list *ngIf="this.query && !this.loading"></app-velda-articles-subcollections-list>
        </span>

        <div *ngIf="!this.query && !this.loading">
            <app-message-empty
                [title]="'No Query set...'"
                [icon]="'../../assets/img/open-box.png'"></app-message-empty>
        </div>

        <div *ngIf="this.loading">
            <app-loading-message></app-loading-message>
        </div>
    </app-content>
</app-app-holder>
