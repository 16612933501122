import { Pipe, PipeTransform } from '@angular/core';

import { Applicant } from '../models/Applicants/Applicant';

@Pipe({
    name: 'filterApplicantsByCreatedDate',
})
export class FilterApplicantsByCreatedDate implements PipeTransform {
    transform(applicants: Applicant[] | undefined, date?: Date) {
        if (date === undefined || date.toString() == '') return applicants;
        return applicants.filter((applicant) => new Date(applicant.createdOn).getTime() > new Date(date).getTime());
    }
}
