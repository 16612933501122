import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';
import { Language } from 'src/app/models/resume/Language';

import { DeleteLanguage, GetLanguages } from '../resume.actions';

@Component({
    selector: 'app-language-delete-modal',
    templateUrl: './language-delete-modal.component.html',
    styleUrls: ['./language-delete-modal.component.scss'],
})
export class LanguageDeleteModalComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Input() language: Language;

    constructor(public activeModal: NgbActiveModal, private store: Store, private logger: NGXLogger) {}

    ngOnInit(): void {}

    deleteLanguage() {
        this.logger.debug('Attempt to delete Language');
        this.subscriptions.add(
            this.store.dispatch(new DeleteLanguage(this.language.uuid)).subscribe(
                () => {
                    this.logger.debug('Succesfully deleted language');
                    this.logger.debug('Fetching languages');
                    this.store.dispatch(new GetLanguages(this.language.resumeId));
                    this.activeModal.close();
                },
                (error) => this.logger.error('Failed to delete language'),
            ),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
