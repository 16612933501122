<div class="app__navigation app__navigation--alt">
    <div class="app__navigation__logo">
        <a [routerLink]="['/', 'dashboard']">
            <img [src]="logo" />
        </a>
    </div>
    <nav class="h-auto overflow--hidden pointer">
        <ul>
            <li>
                <a
                    (click)="showNotifications()"
                    class="notification">
                    <i class="las la-bell">
                        <p
                            *ngIf="numberOfUnreadNotifications && numberOfUnreadNotifications > 0"
                            class="notification__number">
                            {{ numberOfUnreadNotifications }}
                        </p>
                    </i>
                    <span>Notifications</span>
                </a>
            </li>
        </ul>
    </nav>

    <div class="divider divider--nav"></div>

    <nav>
        <ul>
            <li
                *ngIf="checkActiveAndPermitted(eModule.DASHBOARD) && company !== null"
                routerLinkActive="active">
                <a routerLink="/dashboard"><i class="las la-tachometer-alt"></i> <span>Dashboard</span></a>
            </li>
            <li
                *ngIf="checkActiveAndPermitted(eModule.RESUME)"
                routerLinkActive="active">
                <a routerLink="/resume/overview"><i class="las la-id-card"></i> <span>Resumés</span></a>
            </li>
            <li
                *ngIf="checkActiveAndPermitted(eModule.PROJECTS)"
                routerLinkActive="active">
                <a routerLink="/projects/overview"><i class="las la-stream"></i> <span>Projects</span></a>
            </li>
            <li
                *ngIf="checkActiveAndPermitted(eModule.PROJECTS)"
                routerLinkActive="active">
                <a routerLink="/planning/overview"><i class="las la-calendar"></i><span>Planning</span></a>
            </li>
            <li
                *ngIf="checkActiveAndPermitted(eModule.PARTNERS)"
                routerLinkActive="active">
                <a routerLink="/partners"
                    ><i class="las la-building"></i> <span>Companies<!--Partners--></span></a
                >
            </li>
            <li
                *ngIf="checkActiveAndPermitted(eModule.CONTACTS)"
                routerLinkActive="active">
                <a routerLink="/contacts/overview"><i class="las la-address-book"></i> <span>Contacts</span></a>
            </li>
            <li
                *ngIf="checkActiveAndPermitted(eModule.CUSTOMERS)"
                routerLinkActive="active">
                <a routerLink="/customers/overview"><i class="las la-hand-holding-usd"></i> <span>Customers</span></a>
            </li>
            <li
                *ngIf="checkActiveAndPermitted(eModule.TIMESHEETS)"
                routerLinkActive="active">
                <a routerLink="/timesheets/overview"><i class="las la-business-time"></i> <span>Timesheets</span></a>
            </li>
            <li
                *ngIf="checkActiveAndPermitted(eModule.EMPLOYEES)"
                routerLinkActive="active">
                <a routerLink="/employees/overview"><i class="las la-user-friends"></i> <span>Employees</span></a>
            </li>
            <li
                *ngIf="checkActiveAndPermitted(eModule.APPLICANTS)"
                routerLinkActive="active">
                <a routerLink="/applicants/overview"><i class="las la-inbox"></i> <span>Applicants</span></a>
            </li>
            <li
                *ngIf="checkActiveAndPermitted(eModule.LEADS)"
                routerLinkActive="active">
                <a routerLink="/leads/overview"><i class="las la-mail-bulk"></i><span>Leads</span></a>
            </li>
            <li
                *ngIf="checkActiveAndPermitted(eModule.CRM)"
                routerLinkActive="active">
                <a routerLink="/crm"><i class="las la-chalkboard"></i><span>CRM</span></a>
            </li>
            <li
                *ngIf="checkActiveAndPermitted(eModule.INVENTORY)"
                [routerLinkActiveOptions]="{ exact: false }"
                routerLinkActive="active">
                <a routerLink="/inventory"><i class="las la-box"></i><span>Inventory</span></a>
            </li>
            <li
                *ngIf="checkActiveAndPermitted(eModule.THERMOSTAR_CONFIGURATOR)"
                [routerLinkActiveOptions]="{ exact: false }"
                routerLinkActive="active">
                <a routerLink="/thermostar-configurator"><i class="las la-tools"></i><span>Configurator</span></a>
            </li>
            <li
                *ngIf="checkActiveAndPermitted(eModule.VELDA_LANGUAGES)"
                routerLinkActive="active">
                <a routerLink="/velda-languages"><i class="las la-globe"></i><span>Languages</span></a>
            </li>
            <li
                *ngIf="checkActiveAndPermitted(eModule.VELDA_ARTICLES)"
                routerLinkActive="active">
                <a routerLink="/velda-articles"><i class="las la-boxes"></i><span>Articles</span></a>
            </li>
            <li
                *ngIf="checkActiveAndPermitted(eModule.YUKI)"
                routerLinkActive="active">
                <a routerLink="/invoices/overview"> <i class="las la-file-invoice-dollar"></i><span>Invoices</span></a>
            </li>
            <li
                *ngIf="checkActiveAndPermitted(eModule.FINANCIAL)"
                routerLinkActive="active">
                <a routerLink="/financial"> <i class="las la-chart-bar"></i><span>Financial reports</span></a>
            </li>
            <li
                *ngIf="checkActiveAndPermitted(eModule.DOCUMENTS)"
                routerLinkActive="active">
                <a routerLink="/documents"><i class="las la-folder-open"></i> <span>Documents</span></a>
            </li>
            <li
                *ngIf="checkActiveAndPermitted(eModule.BUDGET)"
                routerLinkActive="active">
                <a routerLink="/budget"><i class="las la-wallet"></i><span>Budget</span> </a>
            </li>
            <li
                *ngIf="checkActiveAndPermitted(eModule.SETTINGS) && company !== null"
                routerLinkActive="active">
                <a routerLink="/settings/account"><i class="las la-cog"></i> <span>Settings</span></a>
            </li>
        </ul>
    </nav>
</div>
