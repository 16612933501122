export class OverheadCategory {
    id?: string;
    categoryName: string;
    excludeFromFinances: boolean;

    constructor(categoryName: string, excludeFromFinances?: boolean) {
        this.categoryName = categoryName;
        this.excludeFromFinances = excludeFromFinances !== undefined ? excludeFromFinances : false;
    }
}
