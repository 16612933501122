import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { NgbDropdownModule, NgbModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { TagInputModule } from 'ngx-chips';
import { NgxTrimDirectiveModule } from 'ngx-trim-directive';

import { SharedModule } from '../shared/shared.module';
import { ContactpersonDetailModalComponent } from './contactperson-detail-modal/contactperson-detail-modal.component';
import { ContactpersonDetailComponent } from './contactperson-detail/contactperson-detail.component';
import { ContactsAddModalComponent } from './contacts-add-modal/contacts-add-modal.component';
import { ContactsListComponent } from './contacts-list/contacts-list.component';
import { ContactsOverviewComponent } from './contacts-overview/contacts-overview.component';
import { routing } from './contacts.routes';

@NgModule({
    declarations: [
        ContactsOverviewComponent,
        ContactsListComponent,
        ContactsAddModalComponent,
        ContactpersonDetailModalComponent,
        ContactpersonDetailComponent,
    ],
    imports: [
        CommonModule,
        SharedModule,
        routing,
        NgbModule,
        ReactiveFormsModule,
        FormsModule,
        NgbDropdownModule,
        NgbNavModule,
        NgxTrimDirectiveModule,
        TagInputModule,
        MatTableModule,
        CdkTableModule,
        MatSortModule,
        CKEditorModule,
    ],
})
export class ContactsModule {}
