import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-gmail-not-logged-in',
    templateUrl: './gmail-not-logged-in.component.html',
    styleUrls: ['./gmail-not-logged-in.component.scss'],
})
export class GmailNotLoggedInComponent implements OnInit {
    @Input() loginUrl: string;

    constructor(public activeModal: NgbActiveModal) {}

    ngOnInit(): void {}
}
