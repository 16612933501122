<div class="app__title">
    <h1>Turnover</h1>

    <div class="d-flex align-items-center">
        <p class="m-r-2">Show previous year</p>
        <span (click)="togglePreviousYearVisible()" [ngClass]="{'toggle--active': previousYearVisible}"
              class="toggle"></span>
    </div>
</div>

<div class="dashboard__tile dashboard__tile--large dashboard__tile--no-margin">
    <app-date-navigation
        (selectedDateUpdated)="updateSelectedDate()"
        [dateNavigationOptions]="dateNavigationOptions"></app-date-navigation>

    <ng-container *ngIf="!loadingDataCurrentYear && !loadingDataPreviousYear; else loading">
        <app-default-line-chart
            (clickEvent)="displayInfo($event)"
            [data]="data"
            [isTurnover]="true"
            [legendPosition]="legendPosition"
            [objectLabels]="labels"
            [options]="options"
            [xLabels]="xLabels"
        >
        </app-default-line-chart>
    </ng-container>
</div>

<ng-template #loading>
    <app-loading-message></app-loading-message>
</ng-template>
