<div class="modal-header">
    <h2
        class="modal-title"
        id="exampleModalLabel">
        {{ department?.name }}
    </h2>
    <span></span>
    <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="this.modalService.dismissAll()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <h1>department</h1>
    <p *ngFor="let prop of generateArray(department)">{{ prop.key }}: {{ prop.value }}</p>
</div>
