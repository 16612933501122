<app-app-holder>
    <app-navbar></app-navbar>

    <app-sidebar>
        <app-settings-subnav></app-settings-subnav>
    </app-sidebar>

    <app-content>
        <div class="app__title">
            <h1>Module setup</h1>
        </div>

        <div class="sectiontitle">Resumo Modules</div>
        <table
            mat-table
            [dataSource]="allModules$ | async | filterBy: ['moduleType']:'MODULE' | orderBy: 'title'"
            matSort>
            <ng-container matColumnDef="edit">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    class="td-small"></th>
                <td
                    mat-cell
                    *matCellDef="let module"
                    class="td-small">
                    <span
                        (click)="activateModule(module.canActivate, module.uuid)"
                        class="toggle"
                        [ngClass]="setToggleActive(module)"></span>
                </td>
            </ng-container>

            <ng-container matColumnDef="module">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header>
                    Module
                </th>
                <td
                    mat-cell
                    *matCellDef="let module">
                    {{ module.title }}
                </td>
            </ng-container>

            <ng-container matColumnDef="description">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header>
                    Description
                </th>
                <td
                    mat-cell
                    *matCellDef="let module">
                    {{ module.description }}
                </td>
            </ng-container>

            <tr
                mat-header-row
                *matHeaderRowDef="displayedModuleColumns"></tr>

            <tr
                mat-row
                *matRowDef="let row; columns: displayedModuleColumns"></tr>
        </table>

        <div class="sectiontitle">Integrations</div>
        <table
            class="table-view"
            mat-table
            [dataSource]="allModules$ | async | filterBy: ['moduleType']:'INTEGRATION' | orderBy: 'title'"
            matSort>
            <ng-container matColumnDef="edit">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    class="td-small"></th>
                <td
                    mat-cell
                    *matCellDef="let integration"
                    class="td-small">
                    <span
                        (click)="activateModule(integration.canActivate, integration.uuid)"
                        class="toggle"
                        [ngClass]="setToggleActive(integration)"></span>
                </td>
            </ng-container>

            <ng-container matColumnDef="integration">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header>
                    Integration
                </th>
                <td
                    mat-cell
                    *matCellDef="let integration">
                    <span class="d-flex align-items-center">
                        <img
                            src="{{ integration.image }}"
                            class="m-r-2" />
                        {{ integration.title }}
                    </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="description">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header>
                    Description
                </th>
                <td
                    mat-cell
                    *matCellDef="let integration">
                    <small>{{ integration.description }}</small>
                </td>
            </ng-container>

            <tr
                mat-header-row
                *matHeaderRowDef="displayedIntegrationColumns"></tr>

            <tr
                mat-row
                *matRowDef="let row; columns: displayedIntegrationColumns"></tr>
        </table>
    </app-content>
</app-app-holder>
