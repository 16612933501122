<form
    [formGroup]="employeeFormGroup"
    (ngSubmit)="saveEmployee()"
    class="modal-wrapper">
    <div class="modal-header">
        <h2
            class="modal-title"
            id="exampleModalLabel">
            New employee
        </h2>
        <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="activeModal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <div class="row">
            <div class="col-6">
                <div class="form-group required m-b-2">
                    <label>Firstname</label>
                    <input
                        type="text"
                        formControlName="firstName"
                        (focus)="hideErrorMessage()"
                        trim="blur"
                        #focussed />
                    <span
                        class="form-error"
                        *ngIf="employeeFormGroup.controls?.firstName.errors && inputIsWrong"
                        >firstname required and needs to be between 2 and 30 characters long</span
                    >
                </div>
            </div>
            <div class="col-6">
                <div class="form-group required m-b-2">
                    <label>Lastname</label>
                    <input
                        type="text"
                        formControlName="lastName"
                        (focus)="hideErrorMessage()"
                        trim="blur" />
                    <span
                        class="form-error"
                        *ngIf="employeeFormGroup.controls.lastName.errors && inputIsWrong"
                        >lastname required and needs to be between 2 and 30 characters long</span
                    >
                </div>
            </div>
        </div>

        <h3 class="m-t-4 m-b-4">Employee information</h3>

        <div formGroupName="contactInformation">
            <div class="form-group m-b-2">
                <label>Phonenumber</label>
                <input
                    type="text"
                    placeholder="Phonenumber"
                    formControlName="phoneNumber"
                    (focus)="hideErrorMessage()"
                    trim="blur" />
                <span class="form-helptext">Not your personal phonenumber!</span>
            </div>

            <div class="form-group m-b-2 required">
                <label>Emailaddress</label>
                <input
                    type="email"
                    placeholder="Emailaddress"
                    formControlName="email"
                    (focus)="hideErrorMessage()"
                    trim="blur" />
                <span class="form-helptext">Probably something like employee@thiscompany.com ...</span>
                <span
                    class="form-error"
                    *ngIf="employeeFormGroup.get('contactInformation.email').errors && inputIsWrong"
                    >Email is required</span
                >
            </div>

            <div class="form-group m-b-2">
                <label>LinkedIn</label>
                <input
                    type="text"
                    placeholder="LinkedIn"
                    formControlName="linkedIn" />
            </div>

            <div class="form-group m-b-2">
                <label>Tags</label>
                <app-global-tags [type]="'employee'"></app-global-tags>
            </div>
        </div>
        <div class="row">
            <div class="col-6">
                <div class="form-group required m-b-2">
                    <label>Birthday</label>
                    <input
                        type="date"
                        formControlName="birthday" />
                    <span
                        class="form-error"
                        *ngIf="!isValidDate && inputIsWrong"
                        >Birthday must be before the hire date</span
                    >
                    <span
                        class="form-error"
                        *ngIf="employeeFormGroup.controls.birthday.errors && inputIsWrong"
                        >Birthday required</span
                    >
                    <span
                        class="form-error"
                        *ngIf="employeeFormGroup.get('birthday').hasError('required') && employeeFormGroup.get('birthday').touched"
                        >Birthday is required</span
                    >
                </div>
            </div>
            <div class="col-6">
                <div class="form-group required m-b-2">
                    <label>Hire Date</label>
                    <input
                        type="date"
                        formControlName="hireDate" />
                    <span
                        class="form-error"
                        *ngIf="!isValidDate && inputIsWrong"
                        >Hire date can not be before the birthday</span
                    >
                    <span
                        class="form-error"
                        *ngIf="employeeFormGroup.controls.hireDate.errors && inputIsWrong"
                        >Hire date required</span
                    >
                </div>
            </div>
        </div>
        <div class="form-group m-b-2">
            <label>Profession</label>
            <input
                type="text"
                placeholder="profession"
                formControlName="profession" />
        </div>
        <div class="form-group m-b-2">
            <label>Extra info</label>
            <textarea
                class="resize-vertical"
                formControlName="extraInfo"
                (keyup)="onDescriptionKeyUp()"
                (focus)="hideErrorMessage()"
                trim="blur"></textarea>
            <small><span *ngIf="!currentAmountOfExtraInfoCharacters">0</span>{{ currentAmountOfExtraInfoCharacters }}/{{ extraInfoMaxLength }}</small>
            <span
                class="form-error"
                *ngIf="extraInfoIsTooLong"
                >Extra info cannot contain more than 1000 characters</span
            >
        </div>
        <div>
            <div *ngIf="!jiraEmployeeIdListLoaded">Loading Jira Id list...</div>
            <div class="form-group m-b-2" *ngIf="jiraEmployeeIdListLoaded">
                <label>Jira Employee ID</label>
                <select formControlName="jiraEmployeeId">
                    <option value="">Choose the corresponding Jira Employee Id</option>
                    <option *ngFor="let credential of jiraEmployeeIdList$" [value]="credential.jiraEmployeeId">
                        {{credential.employeeName}} - {{credential.jiraEmployeeId}}
                    </option>
                </select>
            </div>
        </div>
        <div>
            <span
                class="form-error"
                *ngIf="employeeWentWrong"
                >{{ errorMessage }}</span
            >
        </div>
    </div>

    <div class="modal-footer">
        <button
            type="button"
            class="button button--outline"
            (click)="activeModal.close()">
            Close
        </button>
        <button
            type="submit"
            [ngClass]="{ 'button--loading': loading }"
            class="button button--primary">
            Add Employee
        </button>
    </div>
</form>
