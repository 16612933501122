<div
    class="modal-wrapper"
    *ngIf="contactPerson">
    <div class="modal-header">
        <h2
            class="modal-title"
            id="exampleModalLabel">
            {{ contactPerson.firstName }} {{ contactPerson.lastName }}
        </h2>
        <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="activeModal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <nav
        ngbNav
        #nav="ngbNav"
        class="tabs__modal">
        <ng-container ngbNavItem>
            <a ngbNavLink>Information</a>
            <ng-template ngbNavContent>
                <ul class="list__specs">

                    <li *ngIf="partner"><label>Company</label><app-pill-partner [partner]="partner"></app-pill-partner></li>
                    <li *ngIf="contactPerson.creatorId"><label>Creator</label><app-pill-user [userId]="contactPerson.creatorId"></app-pill-user></li>
                        
                    <li *ngIf="contactPerson.profession">
                        <label>Profession</label>
                        {{ contactPerson.profession }}
                    </li>
                    <li>
                        <label>Tags</label
                        ><app-global-tags
                            [objectUuid]="contactPerson.uuid"
                            [type]="'contact'"></app-global-tags>
                    </li>

                    <div
                        *ngIf="contactPerson.contactInformation"
                        class="divider"></div>
                    <app-contact-information-field [contactInformation]="contactPerson.contactInformation"></app-contact-information-field>

                    <li *ngIf="this.moduleService.checkModuleActive(eModule.MAILCHIMP)">
                        <label>Mailchimp</label
                        ><app-pill-mailchimp
                            [email]="contactPerson.contactInformation.email"
                            [firstname]="contactPerson.firstName"
                            [lastname]="contactPerson.lastName"
                            [phone]="contactPerson.contactInformation.phoneNumber"></app-pill-mailchimp>
                    </li>

                    <span *ngIf="contactPerson.extraInfo">
                        <div class="divider"></div>
                        <h3>Extra info</h3>
                        <span [innerHtml]="contactPerson.extraInfo"></span>
                    </span>
                </ul>
            </ng-template>
        </ng-container>

        <ng-container
            ngbNavItem
            *ngIf="this.moduleService.checkModuleActive(eModule.COMMENTS)">
            <a ngbNavLink>Comments</a>
            <ng-template ngbNavContent>
                <app-comments-overview [objectId]="contactPersonUuid"></app-comments-overview>
            </ng-template>
        </ng-container>
    </nav>

    <div
        class="modal-body"
        *ngIf="isLoading">
        <app-loading-message></app-loading-message>
    </div>
    <div
        class="modal-body"
        *ngIf="!isLoading">
        <div [ngbNavOutlet]="nav"></div>
    </div>

    <div class="modal-footer">
        <button
            type="button"
            class="button button--outline"
            (click)="activeModal.close()">
            Close
        </button>
        <div
            ngbDropdown
            placement="top-right"
            class="dropdown"
            *appIsPermitted="{
                module: 'CONTACTS',
                operator: 'OR',
                actions: ['EDIT', 'DELETE']
            }">
            <button
                type="button"
                ngbDropdownToggle
                class="button dropdown-toggle"
                id="dropdownMenuButton">
                Actions
            </button>
            <div
                ngbDropdownMenu
                class="dropdown-menu"
                aria-labelledby="dropdownMenuButton">
                <a
                    class="dropdown-item"
                    *appIsPermitted="{
                        module: 'CONTACTS',
                        operator: 'AND',
                        actions: ['EDIT']
                    }"
                    (click)="openContactPersonUpdateModal()"
                    >Edit</a
                >
                <a
                    class="dropdown-item color--red"
                    *appIsPermitted="{
                        module: 'CONTACTS',
                        operator: 'AND',
                        actions: ['DELETE']
                    }"
                    (click)="openContactPersonDeleteModal(contactPerson.uuid)"
                    >Delete</a
                >
            </div>
        </div>
    </div>
</div>
