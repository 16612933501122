import { Component, OnDestroy, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';

import { FetchCustomers } from '../comanage.actions';
import { ComanageState } from '../comanage.state';

@Component({
    selector: 'app-comanage-customers',
    templateUrl: './comanage-customers.component.html',
    styleUrls: ['./comanage-customers.component.scss'],
})
export class ComanageCustomersComponent implements OnDestroy {
    private subscriptions = new Subscription();

    @Select(ComanageState.customers) coManageCustomers$: Observable<any[]>;
    isset: boolean;
    filter: string;
    isLoading = true;

    // material table
    displayedColumns: string[] = ['customer_number', 'name', 'email', 'phone'];
    dataSource: MatTableDataSource<any>;

    @ViewChild(MatSort, { static: true }) sort: MatSort;
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

    constructor(private store: Store) {}

    checkCredentials(set: boolean) {
        this.isset = set;
        this.prepareDataTable();
    }

    /* setup mattable with invoices */
    prepareDataTable() {
        this.subscriptions.add(
            this.coManageCustomers$.subscribe((customers) => {
                if (!customers) {
                    this.store.dispatch(new FetchCustomers()).subscribe();
                } else {
                    this.dataSource = new MatTableDataSource(customers);
                    this.dataSource.sort = this.sort;
                    this.dataSource.paginator = this.paginator;

                    this.dataSource.sortingDataAccessor = (item, property) => {
                        if (property.includes('.')) return property.split('.').reduce((o, i) => o[i], item);
                        return item[property];
                    };

                    this.isLoading = false;
                }
            }),
        );
    }

    applyFilter(filterValue) {
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
