<app-app-holder>
    <app-navbar></app-navbar>

    <app-content>
        <div>
            <p>thermostar-configurator works!</p>
        </div>
    </app-content>

    <ng-template #loading>
        <app-loading-message></app-loading-message>
    </ng-template>
</app-app-holder>
