import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { FetchEmployees } from 'src/app/employees/employees.actions';
import { EmployeeState } from 'src/app/employees/employees.state';
import { Employee } from 'src/app/models/Employee';
import { AddRelatedEmployee } from 'src/app/related-employees/related.employees.action';

@Component({
    selector: 'app-add-employee-modal',
    templateUrl: './add-employee-modal.component.html',
    styleUrls: ['./add-employee-modal.component.scss'],
})
export class AddEmployeeModalComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Input() objectId: string;
    @Input() relatedEmployees: Employee[];
    @Output() addEvent = new EventEmitter();
    @Select(EmployeeState.Employees) allEmployees$: Observable<Employee[]>;

    actualEmployees: Employee[] = [];
    keyword = 'firstName';

    chosenEmployee: Employee;
    selectedEmployee: Employee;
    isUpdate: boolean;

    constructor(public activeModal: NgbActiveModal, private store: Store, private toastr: ToastrService, private logger: NGXLogger) {}

    ngOnInit(): void {
        this.subscriptions.add(
            this.allEmployees$.subscribe((employees) => {
                if (!employees) {
                    this.store.dispatch(new FetchEmployees());
                } else {
                    this.actualEmployees = employees.filter(this.comparer(this.relatedEmployees));
                }
            }),
        );
    }

    selectEvent(employee) {
        this.selectedEmployee = employee;
    }

    addNewEmployee() {
        this.logger.debug('Attempt to add related employee');
        this.subscriptions.add(
            this.store.dispatch(new AddRelatedEmployee(this.objectId, this.selectedEmployee.uuid)).subscribe(
                () => {
                    this.logger.debug('Succesfully added related employee');
                    this.toastr.success('Employee added successfully');
                },
                (error) => {
                    this.logger.debug('Failed to delete related employee');
                    this.toastr.error('Employee already added');
                },
            ),
        );
        this.activeModal.close();
    }

    getFullName(employee) {
        return `${employee.firstName} ${employee.lastName}`;
    }

    comparer(otherArray) {
        return function (current) {
            return (
                otherArray.filter(function (other) {
                    return other.uuid == current.uuid;
                }).length == 0
            );
        };
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
