import { Component, Input, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { EmployeesDetailComponent } from 'src/app/employees/employees-detail/employees-detail.component';
import { EmployeeState } from 'src/app/employees/employees.state';
import { Employee } from 'src/app/models/Employee';

import { FetchSimpleInformationForAllEmployees } from '../../../employees/employees.actions';

@Component({
    selector: 'app-pill-employee',
    templateUrl: './pill-employee.component.html',
    styleUrls: ['./pill-employee.component.scss'],
})
export class PillEmployeeComponent implements OnInit, OnDestroy {
    @Select(EmployeeState.EmployeesSimpleInformation) employees$: Observable<Employee[]>;
    employees: Employee[] = [];
    @Input() employeeId: string;
    @Input() employee: Employee;
    @Input() onFinancialPage = false;
    employeeFetched: Employee;
    isLoaded = false;
    private subscriptions = new Subscription();

    constructor(private modalService: NgbModal, private store: Store) {}

    ngOnInit(): void {
        if (this.employeeId) {
            this.subscriptions.add(
                this.employees$.subscribe((employees) => {
                    if (!employees) {
                        this.store.dispatch(new FetchSimpleInformationForAllEmployees());
                        return;
                    }
                    this.employees = employees;
                    if (employees && employees.length > 0) {
                        this.employeeFetched = employees.find((m) => m.uuid == this.employeeId);
                        this.isLoaded = true;
                    }
                }),
            );
        } else if (this.employee) {
            this.employeeFetched = this.employee;
        }
    }

    openEmployeeDetailModal() {
        const modalRef = this.modalService.open(EmployeesDetailComponent, {
            windowClass: 'modal-huge',
            animation: false,
        });
        modalRef.componentInstance.employeeUuid = this.employeeFetched.uuid;
        modalRef.componentInstance.openFinancialTab = this.onFinancialPage;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.employeeId) {
            this.employeeFetched = this.employees.find((m) => m.uuid == this.employeeId);
        }
    }
}
