import Customer from '../models/Customer';

export class FetchCustomers {
    static readonly type = '[Customer[]] Fetch Customers';
}

export class AddCustomer {
    static readonly type = '[Customer] Add Customer';
    constructor(public customer: Customer) {}
}

export class UpdateCustomer {
    static readonly type = '[Customer] Update customer';
    constructor(public customer: Customer) {}
}

export class DeleteCustomer {
    static readonly type = '[Customer] Delete customer';
    constructor(public customerUuid: string) {}
}
