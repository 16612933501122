import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';

import { FetchSimpleInformationForAllEmployees } from '../../employees/employees.actions';
import { EmployeeState } from '../../employees/employees.state';
import DefaultProjectEmployeeLinkDTO from '../../models/DefaultProjectEmployeeLinkDTO';
import { Employee } from '../../models/Employee';
import Project from '../../models/Project';
import { AddDefaultEmployees } from '../project.actions';

@Component({
    selector: 'app-add-default-linked-employee-modal',
    templateUrl: './add-default-linked-employee-modal.component.html',
    styleUrls: ['./add-default-linked-employee-modal.component.scss'],
})
export class AddDefaultLinkedEmployeeModalComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Input() project: Project;
    defaultLinkedEmployeeForm: FormGroup;

    @Select(EmployeeState.EmployeesSimpleInformation) employees$: Observable<Employee[]>;
    employees: Employee[];
    checkedEmployees: Employee[] = [];
    allCheck = false;

    constructor(public activeModal: NgbActiveModal, private fb: FormBuilder, private store: Store, private logger: NGXLogger, private toastr: ToastrService) {}

    ngOnInit(): void {
        this.fetchData();
        this.createForm();
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    private createForm() {
        this.defaultLinkedEmployeeForm = this.fb.group({
            pricePerHour: ['', Validators.required],
        });
    }

    private fetchData(): void {
        this.subscriptions.add(
            this.employees$.subscribe((employees) => {
                if (!employees) {
                    this.logger.debug('Fetching Employees');
                    this.store.dispatch(new FetchSimpleInformationForAllEmployees());
                    return;
                }

                this.employees = employees.filter(
                    (employee) => !this.project.defaultLinkedEmployees.some((defaultLinkedEmployee) => defaultLinkedEmployee.employeeId === employee.uuid),
                );
            }),
        );
    }

    checkAll(): void {
        if (this.checkedEmployees.length === this.employees.length && this.allCheck) {
            this.checkedEmployees = [];
            this.allCheck = false;
        } else {
            this.checkedEmployees = this.employees;
            this.allCheck = true;
        }
    }

    isChecked(employee: Employee): boolean {
        return this.checkedEmployees.includes(employee);
    }

    check(employee: Employee): void {
        if (this.isChecked(employee)) {
            this.checkedEmployees = this.checkedEmployees.filter((e) => e.uuid !== employee.uuid);
            this.allCheck = false;
        } else {
            this.checkedEmployees.push(employee);
        }
        if (this.checkedEmployees.length === this.employees.length) {
            this.allCheck = true;
        }
    }

    addDefaultLinkedEmployee() {
        const defaultProjectEmployeeLinkDTO: DefaultProjectEmployeeLinkDTO = {
            employeeIds: this.checkedEmployees.map((e) => e.uuid),
            projectId: this.project.uuid,
            pricePerHour: this.defaultLinkedEmployeeForm.value?.pricePerHour,
        };
        this.subscriptions.add(
            this.store.dispatch(new AddDefaultEmployees(defaultProjectEmployeeLinkDTO)).subscribe(
                () => {
                    this.toastr.success('Default price per hour for employee successfully added');
                    this.activeModal.close();
                },
                () => {
                    this.logger.error('Failed to add default price per hour for employee');
                    this.toastr.error('Oops, something went wrong, Please try again later...');
                },
            ),
        );
    }
}
