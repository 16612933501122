import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import RecipientModel from 'src/app/models/RecipientModel';

import { AddRecipientModalComponent } from '../../mailchimp/add-recipient-modal/add-recipient-modal.component';
import { RecipientInfoModalComponent } from '../../mailchimp/recipient-info-modal/recipient-info-modal.component';
import { PillMailchimpService } from './pill-mailchimp.service';

@Component({
    selector: 'app-pill-mailchimp',
    templateUrl: './pill-mailchimp.component.html',
    styleUrls: ['./pill-mailchimp.component.scss'],
})
export class PillMailchimpComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();
    @Input() email: string;
    @Input() firstname: string;
    @Input() lastname: string;
    @Input() phone: string;

    public pending = true;
    public accountLinked: boolean;
    public recipient: RecipientModel;
    public mailchimpEnabled: boolean;
    public keyIsset: boolean;

    constructor(private pillMailchimpService: PillMailchimpService, private modalService: NgbModal, private router: Router) {}

    ngOnInit(): void {
        this.subscriptions.add(this.pillMailchimpService.getMailchimpIsEnabled().subscribe((result) => (this.mailchimpEnabled = result)));
        this.subscriptions.add(
            this.pillMailchimpService.checkIfApiKeyExists().subscribe((res) => {
                if (res.key) {
                    this.keyIsset = true;
                    this.refresh();
                } else {
                    this.keyIsset = false;
                    this.pending = false;
                }
            }),
        );
    }

    refresh() {
        this.subscriptions.add(
            this.pillMailchimpService.getAccountIsLinked(this.email).subscribe((r) => {
                this.recipient = r;
                this.accountLinked = r != null;
                this.pending = false;
            }),
        );
    }

    openModal() {
        if (!this.keyIsset) {
            this.router.navigate(['/settings/module']);
            this.modalService.dismissAll();
        } else if (this.accountLinked) {
            const modalRef = this.modalService.open(RecipientInfoModalComponent, { windowClass: 'modal-prompt' });
            modalRef.componentInstance.recipient = this.recipient;
        } else {
            const modalRef = this.modalService.open(AddRecipientModalComponent, { windowClass: 'modal-prompt' });
            modalRef.componentInstance.email = this.email;
            modalRef.componentInstance.firstname = this.firstname;
            modalRef.componentInstance.lastname = this.lastname;
            modalRef.componentInstance.phone = this.phone;
            this.subscriptions.add(
                modalRef.componentInstance.closeEvent.subscribe(() => {
                    this.refresh();
                }),
            );
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
