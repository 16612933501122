import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NGXLogger } from 'ngx-logger';
import { AssignedResume } from 'src/app/models/AssignedResume';
import { Resume } from 'src/app/models/resume/Resume';
import { ResumeDetailModalComponent } from 'src/app/resumes/resume-detail-modal/resume-detail-modal.component';

@Component({
    selector: 'app-project-assign-resume-list',
    templateUrl: './project-assign-resume-list.component.html',
    styleUrls: ['./project-assign-resume-list.component.scss'],
})
export class ProjectAssignResumeListComponent implements OnInit {
    @Input() resumes: Resume[];
    @Input() assignedResumes: AssignedResume[];
    filter: string;
    selectedResumes: Resume[] = [];

    @Output() resumeSelected = new EventEmitter<boolean>();
    selectedResumesCount = 0;

    constructor(private modalService: NgbModal, private logger: NGXLogger) {}

    ngOnInit(): void {}

    onSelect(resume: Resume) {
        if (!this.inSelectedResumes(resume)) {
            this.selectedResumes.push(resume);
            this.selectedResumesCount++;
        } else {
            const indexOfResume = this.selectedResumes.indexOf(resume);
            this.selectedResumes.splice(indexOfResume, 1);
            this.selectedResumesCount--;
        }

        this.selectedResumesCount > 0 ? this.resumeSelected.emit(true) : this.resumeSelected.emit(false);
    }

    inSelectedResumes(resume: Resume): boolean {
        // Check if selectedresume is present in the assignedresumelist and doesn't already exist in the selectedresumelist
        this.assignedResumes.find((ar) => ar.uuid === resume.uuid) && !this.selectedResumes.find((sr) => sr.uuid === resume.uuid)
            ? this.selectedResumes.push(resume)
            : null;

        return this.selectedResumes.filter((r) => r.uuid === resume.uuid).length > 0 ? true : false;
    }

    previewResume(resume: Resume) {
        this.logger.debug('Opening resumeDetailModal');
        const modalRef = this.modalService.open(ResumeDetailModalComponent, {
            windowClass: 'modal-huge',
            animation: false,
        });
        modalRef.componentInstance.resumeUuid = resume.uuid;
    }
}
