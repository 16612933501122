import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { AuthenticationService } from '../../../authentication/authentication.service';

@Injectable({
    providedIn: 'root',
})
export class OutlookLoginService {
    httpOptions = {};

    constructor(private http: HttpClient, private authenticationService: AuthenticationService) {
        this.httpOptions = authenticationService.getHeaders();
    }

    getAuthorisationUrl() {
        return this.http.get(`${environment.baseUrl}/outlook/authurl`, this.httpOptions);
    }

    saveAccessTokenByCode(code) {
        return this.http.post(`${environment.baseUrl}/outlook/auth`, code, this.httpOptions);
    }

    getLoggedIn() {
        return this.http.get(`${environment.baseUrl}/outlook/auth`, this.httpOptions);
    }

    logoutOutlook() {
        return this.http.delete(`${environment.baseUrl}/outlook/auth`, this.httpOptions);
    }
}
