import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';

import { FetchProductCategories, SelectCategory } from '../product.actions';
import { ProductState } from '../product.state';

@Component({
    selector: 'app-inventory-subnav',
    templateUrl: './inventory-subnav.component.html',
    styleUrls: ['./inventory-subnav.component.scss'],
})
export class InventorySubnavComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Select(ProductState.ProductCategories) productCategories$: Observable<string[]>;
    @Select(ProductState.SelectedCategory)
    selectedCategroy$: Observable<string>;

    @Output() selectedCategory = new EventEmitter<string>();

    selected = '';

    constructor(private store: Store) {}

    ngOnInit(): void {
        this.store.dispatch(new FetchProductCategories());
        this.subscriptions.add(
            this.selectedCategroy$.subscribe((selectedCategory) => {
                this.selected = selectedCategory;
            }),
        );
        if (window.location.toString().includes('/inventory/products')) {
            this.changeCategory(null);
        }
    }

    changeCategory(category: string) {
        this.subscriptions.add(this.store.dispatch(new SelectCategory(category)).subscribe());
        this.selectedCategory.emit(category);
    }

    resetSelected() {
        this.subscriptions.add(this.store.dispatch(new SelectCategory('')).subscribe());
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
