import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subscription } from 'rxjs';
import { CheckLoggedIn } from 'src/app/exact/exact.actions';
import { ExactState } from 'src/app/exact/exact.state';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-exact-not-logged-in',
    templateUrl: './exact-not-logged-in.component.html',
    styleUrls: ['./exact-not-logged-in.component.scss'],
})
export class ExactNotLoggedInComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();
    @Select(ExactState.loggedIn) loggedIn$: Observable<boolean>;
    loginUrl: string;
    isLoading = true;
    @Output() credentialsIsset = new EventEmitter<boolean>();

    constructor(public activeModal: NgbActiveModal, private logger: NGXLogger, private store: Store) {}

    ngOnInit(): void {
        this.loginUrl = environment.frontEndBaseUrl + '/settings/module';
        this.subscriptions.add(
            this.loggedIn$.subscribe((loggedIn) => {
                if (loggedIn != null) {
                    this.credentialsIsset.emit(loggedIn);
                    this.isLoading = false;
                } else {
                    this.logger.debug('Checking logged in with Exact');
                    this.store.dispatch(new CheckLoggedIn());
                }
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
