import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { Partner } from 'src/app/models/Partner';
import { CountryService } from 'src/app/shared/country.service';

import { AddPartner, FetchPartners } from '../partners.actions';
import { VatValidationService } from '../vat.validation.service';

@Component({
    selector: 'app-partner-add-modal',
    templateUrl: './partner-add-modal.component.html',
    styleUrls: ['./partner-add-modal.component.scss'],
})
export class PartnerAddModalComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    public emitObject: EventEmitter<Partner> = new EventEmitter();

    allCountries = [];
    loading = false;
    inputIsWrong = false;
    partner: Partner;
    partnerFormGroup: FormGroup;

    validateVatWentWrong = false;
    validatingVAT = false;
    vatErrorMessage: string;

    validateWebsiteWentWrong = false;
    validatingWebsite = false;
    websiteErrorMessage: string;

    @ViewChild('focussed', { static: false })
    set input(element: ElementRef<HTMLInputElement>) {
        if (element) {
            element.nativeElement.focus();
        }
    }

    constructor(
        public activeModal: NgbActiveModal,
        private formBuilder: FormBuilder,
        private countryService: CountryService,
        private store: Store,
        private toastr: ToastrService,
        private logger: NGXLogger,
        private vatValidationService: VatValidationService,
    ) {}

    ngOnInit(): void {
        this.allCountries = this.countryService.getAllCountries();
        this.partnerFormGroup = this.formBuilder.group({
            title: ['', [Validators.required]],
            vat: [''],
            yearFounded: [''],
            industry: [''],
            employeesCount: [''],
            address: this.formBuilder.group({
                street: [''],
                number: [null],
                bus: [''],
                ext: [''],
                postal: [''],
                city: [''],
                country: this.formBuilder.group({
                    iso2: [''],
                }),
            }),
            contactInformation: this.formBuilder.group({
                phoneNumber: [null],
                email: [''],
                website: [''],
                linkedIn: [''],
            }),
        });
    }

    addPartner() {
        this.loading = true;

        if (!this.partnerFormGroup.valid) {
            this.inputIsWrong = true;
            this.loading = false;
            return;
        }

        this.partnerFormGroup.disable();

        this.partner = this.partnerFormGroup.value;

        this.logger.debug('Attempt to add partner');
        this.subscriptions.add(
            this.store.dispatch(new AddPartner(this.partner)).subscribe({
                next: (store) => {
                    this.logger.debug('Succesfully added partner');
                    this.toastr.success('Partner added successfully');
                    this.store.dispatch(new FetchPartners());
                    this.emitObject.emit(store.partner.partners.find((p) => p.title == this.partner.title));
                    this.activeModal.close();
                },
                error: (error) => {
                    this.logger.debug('Failed to add partner');
                    this.loading = false;
                    this.partnerFormGroup.enable();
                },
            }),
        );
    }

    validateVatNumber() {
        this.validatingVAT = true;
        this.validateVatWentWrong = false;
        if (this.partnerFormGroup.get('vat').value == '') {
            this.validateVatWentWrong = true;
            this.vatErrorMessage = 'Cannot lookup an empty VAT number.';
            this.validatingVAT = false;
            return;
        }
        if (this.partnerFormGroup.get('vat').value.match(/^\d/)) {
            this.validateVatWentWrong = true;
            this.vatErrorMessage = 'Make sure you include the country code inside the VAT number. (ex.: BE0000.000.000)';
            this.validatingVAT = false;
            return;
        }
        const vatNumber = this.partnerFormGroup.get('vat').value.replace(/[ .]+/g, '');

        this.vatValidationService.getPartnerInformationFromVat(vatNumber).subscribe({
            next: (res) => {
                this.partnerFormGroup.setValue({
                    title: res.company.name,
                    vat: this.partnerFormGroup.get('vat').value,
                    yearFounded: this.partnerFormGroup.get('yearFounded')?.value,
                    industry: this.partnerFormGroup.get('industry')?.value,
                    employeesCount: this.partnerFormGroup.get('employeesCount')?.value,
                    address: {
                        street: res.company.address.split(' ')[0],
                        number: parseInt(res.company.address.split(' ')[1]),
                        bus: '',
                        ext: '',
                        postal: res.company.address.split('\n')[1].split(' ')[0],
                        city: res.company.address.split('\n')[1].split(' ')[1],
                        country: {
                            iso2: res.country.code,
                        },
                    },
                    contactInformation: this.partnerFormGroup.get('contactInformation').value,
                });
                this.validatingVAT = false;
            },
            error: (error) => {
                this.validateVatWentWrong = true;
                this.vatErrorMessage = error.error.error.message;
                this.validatingVAT = false;
            },
        });
    }

    validateDomain() {
        this.validatingWebsite = true;
        this.validateWebsiteWentWrong = false;

        if (this.partnerFormGroup.get('contactInformation').get('website').value == '') {
            this.websiteErrorMessage = 'Cannot lookup an empty value.';
            this.validateWebsiteWentWrong = true;
            this.validatingWebsite = false;
            return;
        }

        let website = this.partnerFormGroup.get('contactInformation').get('website').value as string;

        if (website.includes('www')) website = website.replace('www.', '');
        if (!website.includes('http')) website = 'https://' + website;
        let nakedDomain;
        try {
            const domain = new URL(website);
            nakedDomain = domain.hostname.replace('www.', '');
        } catch (error) {
            this.websiteErrorMessage = 'This is an invalid url.';
            this.validateWebsiteWentWrong = true;
            this.validatingWebsite = false;
            return;
        }

        this.vatValidationService.getExtraInfoInformationFromDomain(nakedDomain).subscribe({
            next: (res) => {
                this.partnerFormGroup.setValue({
                    title: this.partnerFormGroup.get('title')?.value,
                    vat: this.partnerFormGroup.get('vat')?.value,
                    address: this.partnerFormGroup.get('address')?.value,
                    yearFounded: res.year_founded,
                    industry: res.industry,
                    employeesCount: res.employees_count,
                    contactInformation: {
                        phoneNumber: this.partnerFormGroup.get('contactInformation')?.get('phoneNumber')?.value,
                        email: this.partnerFormGroup.get('contactInformation')?.get('email')?.value,
                        website: this.partnerFormGroup.get('contactInformation')?.get('website')?.value,
                        linkedIn: res.linkedin_url,
                    },
                });
                this.validatingWebsite = false;
            },
            error: (error) => {
                this.validateWebsiteWentWrong = true;
                this.websiteErrorMessage = error.error.error.message;
                this.validatingWebsite = false;
            },
        });
    }

    // Hides error messages
    hideErrorMessage() {
        this.inputIsWrong = false;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
