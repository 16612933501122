import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { append, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { tap } from 'rxjs/operators';

import CrmDeal, { CRMDealStatus } from '../models/CrmDeal';
import CrmDealReasonOfLoss from '../models/CrmDealReasonOfLoss';
import CrmStage from '../models/CrmStage';
import { ROUTES } from '../models/ROUTES';
import { CreateNotification } from '../notifications/notifications.actions';
import { AddUndefinedObjectTagsToObject } from '../shared/global-tags/global-tags.actions';
import {
    AddCrmDeal,
    AddCrmStage,
    AddReason,
    ArchiveCrmDeal,
    DearchiveCrmDeal,
    DeleteCrmDeal,
    DeleteCrmStage,
    FetchArchivedCrmDeals,
    FetchCrmDeals,
    FetchCrmStages,
    FetchReasons,
    MoveCrmDeal,
    MoveCrmStage,
    RemoveDealReasonOfLoss,
    UpdateCrmDeal,
    UpdateCrmStage,
    UpdateDealReasonOfLoss,
} from './crm.actions';
import { CrmService } from './crm.service';

export interface CrmStateModel {
    crmStages: CrmStage[];
    crmDeals: CrmDeal[];
    archivedCrmDeals: CrmDeal[];
    reasonsOfLoss: CrmDealReasonOfLoss[];
}

@State<CrmStateModel>({
    name: 'crm',
    defaults: {
        crmStages: null,
        crmDeals: null,
        archivedCrmDeals: null,
        reasonsOfLoss: null,
    },
})
@Injectable()
export class CrmState {
    constructor(private crmService: CrmService, private store: Store) {}

    @Selector()
    static CrmStages(state: CrmStateModel): CrmStage[] {
        return state?.crmStages;
    }

    @Selector()
    static CrmDeals(state: CrmStateModel): CrmDeal[] {
        return state?.crmDeals;
    }

    @Selector()
    static ArchivedCrmDeals(state: CrmStateModel): CrmDeal[] {
        return state?.archivedCrmDeals;
    }

    @Selector()
    static Reasons(state: CrmStateModel): CrmDealReasonOfLoss[] {
        return state?.reasonsOfLoss;
    }

    @Action(FetchCrmStages)
    fetchCrmStages(ctx: StateContext<CrmStateModel>) {
        return this.crmService.getStages().pipe(
            tap((stages) => {
                this.fetchCrmDeals(ctx).subscribe((deals) => {
                    stages.forEach((stage) => {
                        stage.crmDeals = [];
                        deals.forEach((deal) => {
                            if (deal.crmStage.uuid === stage.uuid) {
                                stage.crmDeals.push(deal);
                            }
                        });
                    });
                    ctx.setState(
                        patch<CrmStateModel>({
                            crmStages: stages,
                        }),
                    );
                });
            }),
        );
    }

    @Action(FetchCrmDeals)
    fetchCrmDeals(ctx: StateContext<CrmStateModel>) {
        return this.crmService.getDeals().pipe(
            tap((deals) => {
                deals.forEach((d) => {
                    if (d.contactPerson) {
                        d.contactPerson.fullName = `${d.contactPerson.firstName} ${d.contactPerson.lastName}`;
                    }
                });
                ctx.setState(
                    patch<CrmStateModel>({
                        crmDeals: deals,
                    }),
                );
            }),
        );
    }

    @Action(FetchArchivedCrmDeals)
    fetchArchivedCrmDeals(ctx: StateContext<CrmStateModel>) {
        return this.crmService.getArchivedDeals().pipe(
            tap((deals) => {
                ctx.setState(
                    patch<CrmStateModel>({
                        archivedCrmDeals: deals,
                    }),
                );
            }),
        );
    }

    @Action(FetchReasons)
    fetchReasons(ctx: StateContext<CrmStateModel>) {
        return this.crmService.getReasons().pipe(
            tap((reasons) => {
                ctx.setState(
                    patch<CrmStateModel>({
                        reasonsOfLoss: reasons,
                    }),
                );
            }),
        );
    }

    @Action(AddReason)
    addReason(ctx: StateContext<CrmStateModel>, action: AddReason) {
        return this.crmService.addReason(action.reason).pipe(
            tap((reason) => {
                ctx.setState(
                    patch<CrmStateModel>({
                        reasonsOfLoss: append<CrmDealReasonOfLoss>([reason]),
                    }),
                );
            }),
        );
    }

    @Action(UpdateDealReasonOfLoss)
    updateReasons(ctx: StateContext<CrmStateModel>, action: UpdateDealReasonOfLoss) {
        return this.crmService.updateReason(action.reasonUuid, action.reason).pipe(
            tap((reason) => {
                ctx.setState(
                    patch<CrmStateModel>({
                        reasonsOfLoss: updateItem<CrmDealReasonOfLoss>((reasonOfLoss) => reasonOfLoss.uuid === action.reasonUuid, reason),
                    }),
                );
            }),
        );
    }

    @Action(RemoveDealReasonOfLoss)
    removeReasons(ctx: StateContext<CrmStateModel>, action: RemoveDealReasonOfLoss) {
        return this.crmService.removeReason(action.reasonUuid).pipe(
            tap((result) => {
                if (result) {
                    ctx.setState(
                        patch<CrmStateModel>({
                            reasonsOfLoss: removeItem<CrmDealReasonOfLoss>((reasonOfLoss) => reasonOfLoss.uuid === action.reasonUuid),
                        }),
                    );
                }
            }),
        );
    }

    @Action(AddCrmStage)
    addCrmStage(ctx: StateContext<CrmStateModel>, action: AddCrmStage) {
        return this.crmService.addStage(action.stage).pipe(
            tap((stage) => {
                this.fetchCrmStages(ctx).subscribe();
                this.store.dispatch(
                    new CreateNotification({
                        message: `A crm-stage with name <strong>${stage.name}</strong> is created`,
                        route: ROUTES.CRM.route,
                    }),
                );
            }),
        );
    }

    @Action(AddCrmDeal)
    addCrmDeal(ctx: StateContext<CrmStateModel>, action: AddCrmDeal) {
        return this.crmService.addDeal(action.deal).pipe(
            tap((deal) => {
                ctx.setState(
                    patch<CrmStateModel>({
                        crmDeals: append<CrmDeal>([deal]),
                    }),
                );
                this.store.dispatch(new AddUndefinedObjectTagsToObject(deal.uuid));
                this.store.dispatch(
                    new CreateNotification({
                        message: `A crm-deal with title <strong>${deal.title}</strong> is created for stage <strong>${deal.crmStage.name}</strong>`,
                        route: ROUTES.CRM.route,
                    }),
                );
                this.fetchCrmStages(ctx).subscribe();
            }),
        );
    }

    @Action(MoveCrmStage)
    moveCrmStage(ctx: StateContext<CrmStateModel>, action: MoveCrmStage) {
        return this.crmService.moveStage(action.stageUuid, action.position).pipe(
            tap(() => {
                const stage: CrmStage = ctx.getState().crmStages.find((stage) => stage.uuid === action.stageUuid);
                this.fetchCrmStages(ctx).subscribe();
                this.store.dispatch(
                    new CreateNotification({
                        message: `A crm-stage with name <strong>${stage.name}</strong> is moved to position <strong>${action.position}</strong>`,
                        route: ROUTES.CRM.route,
                    }),
                );
            }),
        );
    }

    @Action(MoveCrmDeal)
    moveCrmDeal(ctx: StateContext<CrmStateModel>, action: MoveCrmDeal) {
        const oldDeal = ctx.getState().crmDeals.find((d) => d.uuid === action.dealUuid);
        const oldStage = ctx.getState().crmStages.find((s) => s.uuid === oldDeal.crmStage.uuid);
        const newStage = ctx.getState().crmStages.find((s) => s.uuid === action.stageUuid);

        ctx.setState(
            patch<CrmStateModel>({
                crmStages: updateItem<CrmStage>(
                    (s) => s.uuid === oldStage.uuid,
                    patch<CrmStage>({
                        crmDeals: removeItem<CrmDeal>((d) => d.uuid === oldDeal.uuid),
                    }),
                ),
                crmDeals: updateItem<CrmDeal>(
                    (d) => d.uuid === action.dealUuid,
                    patch<CrmDeal>({
                        crmStage: newStage,
                    }),
                ),
            }),
        );
        const newDeal = ctx.getState().crmDeals.find((d) => d.uuid === action.dealUuid);
        ctx.setState(
            patch<CrmStateModel>({
                crmStages: updateItem<CrmStage>(
                    (s) => s.uuid === action.stageUuid,
                    patch<CrmStage>({
                        crmDeals: append<CrmDeal>([newDeal]),
                    }),
                ),
            }),
        );
        return this.crmService.moveDeal(action.stageUuid, action.dealUuid, action.reason, action.reasonUuid).pipe(
            tap((deal) => {
                ctx.setState(
                    patch<CrmStateModel>({
                        crmDeals: updateItem<CrmDeal>((d) => d.uuid === action.dealUuid, deal),
                    }),
                );
                ctx.setState(
                    patch<CrmStateModel>({
                        crmStages: updateItem<CrmStage>(
                            (s) => s.uuid === action.stageUuid,
                            patch<CrmStage>({
                                crmDeals: updateItem<CrmDeal>((d) => d.uuid === deal.uuid, deal),
                            }),
                        ),
                    }),
                );
                this.store.dispatch(
                    new CreateNotification({
                        message: `A crm-deal with title <strong>${deal.title}</strong> is moved to stage <strong>${newStage.name}</strong>`,
                        route: ROUTES.CRM.route,
                    }),
                );
            }),
        );
    }

    @Action(UpdateCrmStage)
    updateCrmStage(ctx: StateContext<CrmStateModel>, action: UpdateCrmStage) {
        return this.crmService.updateStage(action.stage).pipe(
            tap(() => {
                ctx.setState(
                    patch<CrmStateModel>({
                        crmStages: updateItem<CrmStage>((stage) => stage.uuid === action.stage.uuid, action.stage),
                    }),
                );
                this.fetchCrmStages(ctx).subscribe();
                this.store.dispatch(
                    new CreateNotification({
                        message: `A crm-stage with name <strong>${action.stage.name}</strong> is updated`,
                        route: ROUTES.CRM.route,
                    }),
                );
            }),
        );
    }

    @Action(UpdateCrmDeal)
    updateCrmDeal(ctx: StateContext<CrmStateModel>, action: UpdateCrmDeal) {
        return this.crmService.updateDeal(action.deal).pipe(
            tap(() => {
                ctx.setState(
                    patch<CrmStateModel>({
                        crmDeals: updateItem<CrmDeal>((deal) => deal.uuid === action.deal.uuid, action.deal),
                    }),
                );
                this.fetchCrmStages(ctx).subscribe();
                this.store.dispatch(
                    new CreateNotification({
                        message: `A crm-deal with title <strong>${action.deal.title}</strong> is updated`,
                        route: ROUTES.CRM.route,
                    }),
                );
            }),
        );
    }

    @Action(DeleteCrmStage)
    deleteCrmStage(ctx: StateContext<CrmStateModel>, action: DeleteCrmStage) {
        return this.crmService.deleteStage(action.stageUuid).pipe(
            tap((result) => {
                if (result) {
                    const stage: CrmStage = ctx.getState().crmStages.find((stage) => stage.uuid === action.stageUuid);
                    ctx.setState(
                        patch<CrmStateModel>({
                            crmStages: removeItem<CrmStage>((s) => s.uuid === action.stageUuid),
                        }),
                    );
                    this.store.dispatch(
                        new CreateNotification({
                            message: `A crm-stage with name <strong>${stage.name}</strong> is deleted`,
                            route: ROUTES.CRM.route,
                        }),
                    );
                }
            }),
        );
    }

    @Action(DeleteCrmDeal)
    deleteCrmDeal(ctx: StateContext<CrmStateModel>, action: DeleteCrmDeal) {
        return this.crmService.deleteDeal(action.deal.uuid).pipe(
            tap((result) => {
                if (result) {
                    ctx.setState(
                        patch<CrmStateModel>({
                            archivedCrmDeals: removeItem<CrmDeal>((d) => d.uuid === action.deal.uuid),
                        }),
                    );
                    this.store.dispatch(
                        new CreateNotification({
                            message: `A crm-deal with title <strong>${action.deal.title}</strong> is deleted`,
                            route: ROUTES.CRM.route,
                        }),
                    );
                }
            }),
        );
    }

    @Action(ArchiveCrmDeal)
    archiveCrmDeal(ctx: StateContext<CrmStateModel>, action: ArchiveCrmDeal) {
        return this.crmService.archiveDeal(action.deal.uuid).pipe(
            tap((result) => {
                if (result) {
                    const deal = JSON.parse(JSON.stringify(action.deal));
                    deal.status = CRMDealStatus.ARCHIVED;
                    ctx.setState(
                        patch<CrmStateModel>({
                            crmStages: updateItem<CrmStage>(
                                (s) => s.uuid === action.deal.crmStage.uuid,
                                patch<CrmStage>({
                                    crmDeals: removeItem<CrmDeal>((d) => d.uuid === action.deal.uuid),
                                }),
                            ),
                            archivedCrmDeals: append<CrmDeal>([deal]),
                        }),
                    );
                    this.store.dispatch(
                        new CreateNotification({
                            message: `A crm-deal with title <strong>${action.deal.title}</strong> is archived`,
                            route: ROUTES.CRM.route,
                        }),
                    );
                }
            }),
        );
    }

    @Action(DearchiveCrmDeal)
    DearchiveCrmDeal(ctx: StateContext<CrmStateModel>, action: DearchiveCrmDeal) {
        return this.crmService.dearchiveDeal(action.deal.uuid).pipe(
            tap((deal) => {
                ctx.setState(
                    patch<CrmStateModel>({
                        crmStages: updateItem<CrmStage>(
                            (s) => s.uuid === action.deal.crmStage.uuid,
                            patch<CrmStage>({
                                crmDeals: append<CrmDeal>([deal]),
                            }),
                        ),
                        archivedCrmDeals: removeItem<CrmDeal>((d) => d.uuid == action.deal.uuid),
                        crmDeals: append<CrmDeal>([deal]),
                    }),
                );
                this.store.dispatch(
                    new CreateNotification({
                        message: `A crm-deal with title <strong>${action.deal.title}</strong> is dearchived`,
                        route: ROUTES.CRM.route,
                    }),
                );
            }),
        );
    }
}
