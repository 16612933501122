import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subscription } from 'rxjs';
import { CompanyState } from 'src/app/companies/company.state';
import { Module } from 'src/app/models/Module';

import { TeamleaderService } from '../../../teamleader/teamleader.service';

@Component({
    selector: 'app-teamleader-login',
    templateUrl: './teamleader-login.component.html',
    styleUrls: ['./teamleader-login.component.scss'],
})
export class TeamleaderLoginComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Select(CompanyState.companyUuid) companyUuid$: Observable<string>;
    @Input() module: Module;
    loggedInTeamleader: boolean;
    teamleaderAuth: string;

    constructor(private teamleaderService: TeamleaderService, private logger: NGXLogger) {}

    ngOnInit(): void {
        this.logger.debug('Checking logged in with teamleader');
        this.subscriptions.add(
            this.teamleaderService.getExistingCredentials().subscribe(
                () => {
                    this.logger.debug('Logged in with teamleader');
                    this.loggedInTeamleader = true;
                },
                (err) => {
                    this.logger.debug('Not logged in with teamleader');
                    this.loggedInTeamleader = false;
                    this.getTeamleaderAuthUrl();
                },
            ),
        );
    }

    getTeamleaderAuthUrl() {
        this.logger.debug('Fetching teamleader authurl');
        this.subscriptions.add(this.teamleaderService.getAuthorizationUrl().subscribe((url) => (this.teamleaderAuth = url.authUrl)));
    }

    logoutTeamleader() {
        this.logger.debug('Attempt to logout of teamleader');
        this.subscriptions.add(
            this.companyUuid$.subscribe((cid) => {
                this.teamleaderService.logoutTeamleader(cid).subscribe(
                    (success) => {
                        if (success) {
                            this.logger.debug('Succesfully logged out of teamleader');
                            this.loggedInTeamleader = false;
                            this.getTeamleaderAuthUrl();
                        }
                    },
                    (err) => this.logger.error('Failed to logout of teamleader'),
                );
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
