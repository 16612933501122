import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { ContactsAddModalComponent } from 'src/app/contacts/contacts-add-modal/contacts-add-modal.component';
import { EmployeesAddModalComponent } from 'src/app/employees/employees-add-modal/employees-add-modal.component';
import { Applicant } from 'src/app/models/Applicants/Applicant';
import { ContactPerson } from 'src/app/models/ContactPerson';
import { Employee } from 'src/app/models/Employee';
import { LaneItemStatus } from 'src/app/models/LaneModel';
import { getBadgeClass } from 'src/app/models/LineItemPriority';
import { ConfirmationModalComponent } from 'src/app/shared/confirmation-modal/confirmation-modal.component';

import { AddApplicantModalComponent } from '../add-applicant-modal/add-applicant-modal.component';
import { ArchiveApplicant, FetchApplicants, UnArchiveApplicant } from '../applicant.actions';
import { ApplicantState } from '../applicant.state';

@Component({
    selector: 'app-applicant-detail-modal',
    templateUrl: './applicant-detail-modal.component.html',
    styleUrls: ['./applicant-detail-modal.component.scss'],
})
export class ApplicantDetailModalComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Input() applicantUuid: string;
    @Input() applicant: Applicant;

    @Select(ApplicantState.Applicants) applicants$: Observable<Applicant[]>;

    isLoading = true;
    archived: boolean;
    applicants: Applicant[];

    constructor(public activeModal: NgbActiveModal, private store: Store, private modalService: NgbModal, private toastr: ToastrService) {}

    ngOnInit(): void {
        this.archived = this.applicant?.status === LaneItemStatus.ARCHIVED;
        if (this.applicantUuid || (this.applicant?.uuid && !this.archived)) {
            this.subscriptions.add(
                this.applicants$.subscribe((applicants) => {
                    if (applicants === null) {
                        this.store.dispatch(new FetchApplicants());
                    } else {
                        if (this.applicantUuid) this.applicant = applicants.find((a) => a.uuid === this.applicantUuid);
                        else this.applicant = applicants.find((a) => a.uuid === this.applicant.uuid);

                        this.isLoading = false;
                    }
                }),
            );
        }
        if (this.archived) {
            this.subscriptions.add(
                this.applicants$.subscribe((applicants) => {
                    if (applicants === null) {
                        this.store.dispatch(new FetchApplicants());
                    } else {
                        this.applicants = applicants;
                    }
                }),
            );
        }
        if (this.applicant) {
            this.isLoading = false;
        }
    }

    editApplicant() {
        const modalRef = this.modalService.open(AddApplicantModalComponent, {
            windowClass: 'modal-pane',
            animation: false,
        });
        modalRef.componentInstance.applicant = this.applicant;
    }

    createContactPerson() {
        const modalRef = this.modalService.open(ContactsAddModalComponent, {
            windowClass: 'modal-pane',
            animation: false,
        });
        modalRef.componentInstance.contactPerson = {
            uuid: undefined,
            lastName: this.applicant.lastName,
            firstName: this.applicant.firstName,
            fullName: undefined,
            extraInfo: this.applicant.description.substring(0, 1000),
            profession: undefined,
            contactInformation: this.applicant.contactInformation,
            creatorId: undefined,
            partnerUuid: this.applicant.partnerUuid,
            partnerName: undefined,
            customerUuid: undefined,
            customerLastname: undefined,
            customerFirstname: undefined,
            tags: this.applicant.tags,
            createdOn: undefined,
            lastModified: undefined,
            projects: [],
        } as ContactPerson;
    }

    createEmployee() {
        const modalRef = this.modalService.open(EmployeesAddModalComponent, {
            windowClass: 'modal-pane',
            animation: false,
        });
        modalRef.componentInstance.employee = {
            lastName: this.applicant.lastName,
            firstName: this.applicant.firstName,
            extraInfo: this.applicant.description.substring(0, 1000),
            contactInformation: this.applicant.contactInformation,
            globalTags: this.applicant.tags,
        } as Employee;
    }

    archiveApplicant() {
        const modalRef = this.modalService.open(ConfirmationModalComponent, {
            windowClass: 'modal-prompt',
        });
        modalRef.componentInstance.type = 'YesOrNo';
        modalRef.componentInstance.title = 'Archive applicant';
        modalRef.componentInstance.htmlMessage = `<p>Are you sure you want to archive <strong>${this.applicant.firstName.trim()} ${this.applicant.lastName.trim()}</strong>?</p>`;
        this.subscriptions.add(
            modalRef.componentInstance.closeEvent.subscribe((result) => {
                if (result) {
                    this.store.dispatch(new ArchiveApplicant(this.applicant.uuid));
                    this.activeModal.close();
                }
            }),
        );
    }

    unArchiveApplicant() {
        if (this.applicants.find((a) => a.contactInformation?.email === this.applicant.contactInformation.email)) {
            this.toastr.error('This applicant already exists in the active applicants list.');
            return;
        }
        const modalRef = this.modalService.open(ConfirmationModalComponent, {
            windowClass: 'modal-prompt',
            animation: false,
        });
        modalRef.componentInstance.type = 'YesOrNo';
        modalRef.componentInstance.title = 'Unarchive applicant';
        modalRef.componentInstance.htmlMessage = `<p>Are you sure you want to unarchive <strong>${this.applicant.firstName} ${this.applicant.lastName}</strong>?</p>`;
        this.subscriptions.add(
            modalRef.componentInstance.closeEvent.subscribe((value) => {
                if (value) {
                    this.store.dispatch(new UnArchiveApplicant(this.applicant.uuid)).subscribe({
                        next: () => {
                            this.toastr.success('Applicant successfully unarchived.');
                        },
                        error: () => {
                            this.toastr.error('Oops, something went wrong, Please try again later...');
                        },
                    });
                }
            }),
        );
    }

    getBadgeClass = getBadgeClass;

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
