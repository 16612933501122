<div class="modal-header">
    <h2
        class="modal-title"
        id="exampleModalLabel">
        Reminder Settings
    </h2>
    <span></span>
    <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <app-loading-message *ngIf="!cronjobInfo"></app-loading-message>
    <div *ngIf="cronjobInfo">
        <table class="table-view">
            <tr>
                <th>Timing</th>
                <th class="td-small"></th>
            </tr>

            <tr *ngFor="let cron of cronjobInfo">
                <td>{{ cron.cronDescription }}</td>
                <td>
                    <span ngbDropdown>
                        <button
                            ngbDropdownToggle
                            class="button button--icon button--dropdown button--small"></button>
                        <span
                            ngbDropdownMenu
                            class="dropdown-menu dropdown-menu-right"
                            aria-labelledby="dropdownMenuButton">
                            <a
                                class="dropdown-item color--red"
                                (click)="delCronjob(cron)"
                                >Remove reminder</a
                            >
                        </span>
                    </span>
                </td>
            </tr>
            <tr>
                <td
                    colspan="4"
                    class="table-view__add"
                    (click)="addCronjob()">
                    <i class="las la-plus"></i> Add new reminder
                </td>
            </tr>
        </table>
    </div>
</div>

<div class="modal-footer">
    <button
        type="button"
        class="button button--outline"
        (click)="activeModal.dismiss()">
        Close
    </button>
</div>
