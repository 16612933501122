import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subscription } from 'rxjs';
import { CompanyState } from 'src/app/companies/company.state';
import { ModuleEnum } from 'src/app/models/ModuleEnum';
import { Product } from 'src/app/models/Product';
import { Unit } from 'src/app/models/Unit';
import { ModuleService } from 'src/app/services/module.service';

import { LinkProductsComponent } from '../link-products/link-products.component';
import { ProductDeleteModalComponent } from '../product-delete-modal/product-delete-modal.component';
import { ProductUpdateModalComponent } from '../product-update-modal/product-update-modal.component';
import { FetchProducts } from '../product.actions';
import { ProductService } from '../product.service';
import { ProductState } from '../product.state';

@Component({
    selector: 'app-product-detail-modal',
    templateUrl: './product-detail-modal.component.html',
    styleUrls: ['./product-detail-modal.component.scss'],
})
export class ProductDetailModalComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Select(ProductState.Products) products$: Observable<Product[]>;
    @Select(CompanyState.companyUuid) companyUuid$: Observable<string>;
    @Input() productUuid: string;
    product: Product;
    unit: Unit;
    allProducts: Product[];
    eModule = ModuleEnum;
    productQrCodePDF;
    filename: string;

    isLoading = true;

    constructor(
        private store: Store,
        public modalService: NgbModal,
        public moduleService: ModuleService,
        public activeModal: NgbActiveModal,
        private logger: NGXLogger,
        private sanitizer: DomSanitizer,
        private productService: ProductService,
    ) {}

    ngOnInit(): void {
        this.subscriptions.add(
            this.products$.subscribe((products) => {
                if (products) {
                    this.allProducts = products;
                    this.product = products.find((p) => p.uuid == this.productUuid);
                    this.isLoading = false;
                } else {
                    this.logger.debug('Fetching products');
                    this.store.dispatch(new FetchProducts());
                }

                this.companyUuid$.subscribe((cid) => {
                    this.productService.getProductsPdf(cid, this.productUuid).subscribe((pdf) => {
                        this.base64ToBlob(pdf.pdf);
                    });
                });
            }),
        );
    }

    openLinkProductModal(isChild: boolean) {
        this.logger.debug('Opening linkProductModal');
        const modalRef = this.modalService.open(LinkProductsComponent, {
            windowClass: 'modal-prompt',
        });
        if (isChild) {
            modalRef.componentInstance.child = this.product;
        } else {
            modalRef.componentInstance.parent = this.product;
        }
        modalRef.componentInstance.isChild = isChild;
        modalRef.componentInstance.isDetailModal = true;
    }

    openProductUpdateModal(product: Product) {
        this.logger.debug('Opening productUpdateModal');
        const modalRef = this.modalService.open(ProductUpdateModalComponent, {
            windowClass: 'modal-huge',
            animation: false,
        });
        modalRef.componentInstance.product = product;
    }

    openDeleteProductModal(product: Product) {
        this.logger.debug('Opening productDeleteModal');
        const modalRef = this.modalService.open(ProductDeleteModalComponent, {
            windowClass: 'modal-prompt',
        });
        modalRef.componentInstance.product = product;
    }

    productUnitType(productUuid: string): string {
        this.unit = this.allProducts.filter((p) => p.uuid == productUuid)[0]?.unit;
        return this.unit?.type;
    }

    base64ToBlob(data) {
        const byteChars = atob(data);
        const byteNumbers = new Array(byteChars.length);
        for (let i = 0; i < byteChars.length; i++) {
            byteNumbers[i] = byteChars.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);
        this.productQrCodePDF = this.sanitizer.bypassSecurityTrustUrl(url);
        this.filename = `${this.product.title}.pdf`;
    }

    format() {
        return 'data:image/png;base64,' + this.product.qrcode;
    }

    filter(itemList) {
        let result: Product[] = [];
        result = itemList.filter((p) => p.linkedProducts.filter((l) => l.archived == false));
        return result;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
