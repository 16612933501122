import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-pill-github',
    templateUrl: './pill-github.component.html',
    styleUrls: ['./pill-github.component.scss'],
})
export class PillGithubComponent implements OnInit {
    @Input() githubUrl: string;

    constructor() {}

    ngOnInit(): void {}
}
