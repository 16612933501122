import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';

import { ComanageLoginService } from '../../login-components/comanage-login/comanage-login.service';

@Component({
    selector: 'app-comanage-not-logged-in',
    templateUrl: './comanage-not-logged-in.component.html',
    styleUrls: ['./comanage-not-logged-in.component.scss'],
})
export class ComanageNotLoggedInComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    loginUrl: string;
    isset = true;
    @Output() credentialsIsset = new EventEmitter<boolean>();

    constructor(public activeModal: NgbActiveModal, private comanageLoginService: ComanageLoginService, private logger: NGXLogger) {}

    ngOnInit(): void {
        this.loginUrl = environment.frontEndBaseUrl + '/settings/module';

        this.logger.debug('Checking logged in with Comanage');
        this.subscriptions.add(
            this.comanageLoginService.checkIfApiKeyExists().subscribe(
                (cred) => {
                    if (cred.key != null) {
                        this.isset = true;
                        this.logger.debug('Logged in with Comanage');
                        this.credentialsIsset.emit(this.isset);
                    } else {
                        this.logger.debug('Not logged in with Comanage');
                        this.isset = false;
                        this.credentialsIsset.emit(this.isset);
                    }
                },
                (err) => {
                    this.logger.error('Not logged in with Comanage');
                    this.isset = false;
                    this.credentialsIsset.emit(this.isset);
                },
            ),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
