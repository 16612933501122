import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

import { AuthenticationService } from '../../authentication/authentication.service';
import Mail from '../../models/Mail';

@Injectable({
    providedIn: 'root',
})
export class GmailMailService {
    httpOptions = {};

    constructor(private httpClient: HttpClient, private authenticationService: AuthenticationService) {
        this.httpOptions = authenticationService.getHeaders();
    }

    getIsLogedIn() {
        return this.httpClient.get<boolean>(environment.baseUrl + '/google/isLoggedIn', this.httpOptions);
    }

    getGmailIsEnabled() {
        return this.httpClient.get<boolean>(environment.baseUrl + '/google/gmail/isEnabled', this.httpOptions);
    }

    getGmailByReceiver(receiver: string) {
        return this.httpClient.get<Mail[]>(environment.baseUrl + '/google/' + receiver + '/mail', this.httpOptions);
    }

    getCompanyGmailByReceiver(receiver: string) {
        return this.httpClient.get<Mail[]>(environment.baseUrl + '/google/' + receiver + '/allcompanymail', this.httpOptions);
    }
}
