import { Pipe, PipeTransform } from '@angular/core';

import CrmDeal from '../models/CrmDeal';

@Pipe({
    name: 'filterCrmDeals',
})
export class FilterCrmDealsPipe implements PipeTransform {
    transform(deals: CrmDeal[], filter: string): unknown {
        return deals?.filter(
            (deal) =>
                !filter ||
                deal.title.toLowerCase().includes(filter.toLowerCase()) ||
                deal.contactPerson?.firstName.toLowerCase().includes(filter.toLowerCase()) ||
                deal.contactPerson?.lastName.toLowerCase().includes(filter.toLowerCase()) ||
                deal.partner?.title.toLowerCase().includes(filter.toLowerCase()),
        );
    }
}
