import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';
import { UpdateTableDisplayedColumns } from 'src/app/settings/settings.actions';
import { SettingsState } from 'src/app/settings/settings.state';

@Component({
    selector: 'app-table-settings',
    templateUrl: './table-settings.component.html',
    styleUrls: ['./table-settings.component.scss'],
})
export class TableSettingsComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Input() tableId: string;
    @Input() allColumns: string[];
    @Input() defaultColumns: string[];

    activeColumns: string[];
    inactiveColumns: string[];

    constructor(private store: Store) {}

    ngOnInit(): void {
        this.subscriptions.add(
            this.store.select(SettingsState.TableDisplayColumns(this.tableId)).subscribe((columns) => {
                this.activeColumns = columns ? [...columns] : [...this.defaultColumns];
                this.inactiveColumns = columns
                    ? this.allColumns.filter((c) => !columns.includes(c))
                    : this.allColumns.filter((c) => !this.defaultColumns.includes(c));
            }),
        );
    }

    drop(event: CdkDragDrop<string[]>) {
        const column = event.previousContainer.data[event.previousIndex];
        if (event.container !== event.previousContainer) {
            if (event.container.id === 'active') {
                this.activeColumns.splice(event.currentIndex, 0, column);
                this.inactiveColumns.splice(this.inactiveColumns.indexOf(column), 1);
            } else {
                this.activeColumns.splice(this.activeColumns.indexOf(column), 1);
                this.inactiveColumns.splice(event.currentIndex, 0, column);
            }
        } else {
            if (event.container.id === 'active') {
                this.activeColumns.splice(this.activeColumns.indexOf(column), 1);
                this.activeColumns.splice(event.currentIndex, 0, column);
            } else {
                this.inactiveColumns.splice(this.inactiveColumns.indexOf(column), 1);
                this.inactiveColumns.splice(event.currentIndex, 0, column);
            }
        }
    }

    save() {
        this.store.dispatch(new UpdateTableDisplayedColumns({ id: this.tableId, displayedColumns: this.activeColumns }));
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
