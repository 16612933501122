import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class EnvironmentService {
    httpOptions = {};

    constructor(private httpClient: HttpClient) {}

    getCurrentEnvironment() {
        return this.httpClient.get<string>(environment.baseUrl + '/environment');
    }
}
