import { Component, OnDestroy, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subscription } from 'rxjs';
import { CompanyMember } from 'src/app/models/CompanyMember';
import { Module } from 'src/app/models/Module';
import { ModuleEnum } from 'src/app/models/ModuleEnum';
import { RoleEnum } from 'src/app/models/RoleEnum';
import User from 'src/app/models/User';
import { ModuleService } from 'src/app/services/module.service';
import { UserState } from 'src/app/users/user.state';

import { CompanyState } from '../../companies/company.state';

@Component({
    selector: 'app-settings-subnav',
    templateUrl: './settings-subnav.component.html',
    styleUrls: ['./settings-subnav.component.scss'],
})
export class SettingsSubnavComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Select(UserState.userInfo) user$: Observable<User>;
    user: User;

    @Select(CompanyState.companyMembers) companyMembers$: Observable<CompanyMember[]>;
    modules: Module[];

    eModule = ModuleEnum;
    eRole = RoleEnum;

    constructor(public moduleService: ModuleService) {}

    ngOnInit(): void {
        this.subscriptions.add(
            this.user$.subscribe((user) => {
                this.user = user;
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
