import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';

import YukiDocument from '../../models/yuki/YukiDocument';
import { ConfirmationModalComponent } from '../../shared/confirmation-modal/confirmation-modal.component';
import { DeleteDocument, FlagYukiDocument, RefreshDocumentFromYukiAPI, UpdateVisibilityYukiDocument } from '../yuki.actions';

@Component({
    selector: 'app-yuki-icons',
    templateUrl: './yuki-icons.component.html',
    styleUrls: ['./yuki-icons.component.scss'],
})
export class YukiIconsComponent implements OnDestroy {
    private subscriptions = new Subscription();

    @Input() yukiDocument: YukiDocument;
    @Input() showPaidIcon: boolean;

    flagLoading: boolean;
    visibleLoading: boolean;
    syncLoading: boolean;

    constructor(private store: Store, private toastr: ToastrService, private modalService: NgbModal) {}

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    flagDocument(yukiId: string) {
        this.flagLoading = true;
        this.subscriptions.add(this.store.dispatch(new FlagYukiDocument(yukiId)).subscribe(() => (this.flagLoading = false)));
    }

    hideDocument(yukiId: string) {
        this.visibleLoading = true;
        this.subscriptions.add(this.store.dispatch(new UpdateVisibilityYukiDocument(yukiId)).subscribe(() => (this.visibleLoading = false)));
    }

    refreshDocument(yukiId: string) {
        this.syncLoading = true;
        this.subscriptions.add(
            this.store.dispatch(new RefreshDocumentFromYukiAPI(yukiId)).subscribe(
                () => {
                    this.syncLoading = false;
                    this.toastr.success('Document was successfully refreshed');
                },
                (error) => {
                    this.syncLoading = false;

                    if (error.status == 422) {
                        const modalRef = this.modalService.open(ConfirmationModalComponent, {
                            windowClass: 'modal-prompt',
                            animation: false,
                        });
                        modalRef.componentInstance.type = 'Delete';
                        modalRef.componentInstance.title = 'Delete Yuki document';
                        modalRef.componentInstance.htmlMessage = `Invoice <strong>${this.yukiDocument.subject}</strong> does not exist anymore in Yuki. <br/> Do you want to delete this invoice?`;
                        this.subscriptions.add(
                            modalRef.componentInstance.closeEvent.subscribe((value) => {
                                if (value) {
                                    this.store.dispatch(new DeleteDocument(yukiId));
                                }
                            }),
                        );
                    } else {
                        this.toastr.error('Oops, something went wrong, Please try again later...');
                    }
                },
            ),
        );
    }
}
