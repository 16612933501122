<header class="modal-header">
    <h2 class="modal-title">
        Logbook categories
    </h2>

    <button
        (click)="activeModal.dismiss()"
        aria-label="Close"
        class="close"
        type="button">
        <span aria-hidden="true">&times;</span>
    </button>
</header>

<main class="modal-body">
    <form (ngSubmit)="addLogCategory()" [formGroup]="logCategoryForm">
        <p class="sectiontitle sectiontitle--first">New category for the logbook</p>
        <div class="d-flex align-items-end">
            <div class="form-group required w-100 m-r-2">
                <label for="category">Category</label>
                <input formControlName="category" id="category" type="text">
            </div>
            <button
                [disabled]="logCategoryForm.invalid"
                class="button button--primary button--icon m-t-2">
                <i class="las la-plus"></i>
            </button>
        </div>
    </form>

    <div>
        <p class="sectiontitle">Existing categories</p>
        <table class="table-view m-t-2">
            <tr>
                <th>Category</th>
                <th class="td-small"></th>
            </tr>
            <ng-container *ngIf="logCategories.length > 0; else noCategories">
                <tr *ngFor="let logCategory of logCategories">
                    <td>{{logCategory.category}}</td>
                    <td (click)="deleteLogCategory(logCategory.id)" class="td-icon">
                        <button class="button button--icon">
                            <i class="las la-trash color--red"></i>
                        </button>
                    </td>
                </tr>
            </ng-container>
            <ng-template #noCategories>
                <tr>
                    <td colspan="2">There are no saved categories yet...</td>
                </tr>
            </ng-template>
        </table>
    </div>
</main>
