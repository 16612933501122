<form
    [formGroup]="companyFormGroup"
    (ngSubmit)="saveCompany()"
    class="modal-wrapper">
    <div class="modal-header">
        <h2
            *ngIf="!isUpdate"
            class="modal-title"
            id="exampleModalLabel">
            New company
        </h2>
        <h2
            *ngIf="isUpdate"
            class="modal-title"
            id="exampleModalLabel">
            Edit company
        </h2>
        <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="activeModal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <div class="form-group required m-b-2">
            <label>Company name</label>
            <input
                type="text"
                placeholder="Company name*"
                formControlName="title"
                (focus)="hideErrorMessage()"
                trim="blur"
                #focussed />
            <span
                class="form-error"
                *ngIf="companyFormGroup.controls.title?.errors && inputIsWrong"
                >Title is required</span
            >
        </div>

        <div class="form-group m-b-2">
            <label>VAT number</label>
            <input
                type="text"
                placeholder="VAT number"
                formControlName="vat"
                (focus)="hideErrorMessage()"
                trim="blur" />
            <span
                class="form-error"
                *ngIf="companyFormGroup.controls.vat?.errors && inputIsWrong"
                >VAT is required</span
            >
        </div>

        <h3 class="m-t-4 m-b-4">Address</h3>

        <div formGroupName="address">
            <div class="m-b-2">
                <div class="row">
                    <div class="col-6">
                        <div class="form-group required">
                            <label>Street</label>
                            <input
                                type="text"
                                placeholder="Street*"
                                formControlName="street"
                                (focus)="hideErrorMessage()"
                                trim="blur" />
                            <span
                                class="form-error"
                                *ngIf="companyFormGroup.get('address.street').errors && inputIsWrong"
                                >Street is required</span
                            >
                        </div>
                    </div>
                    <div class="col-2">
                        <div class="form-group required">
                            <label>Number</label>
                            <input
                                type="number"
                                placeholder="Number*"
                                formControlName="number"
                                (focus)="hideErrorMessage()" />
                            <span
                                class="form-error"
                                *ngIf="companyFormGroup.get('address.number').errors && inputIsWrong"
                                >Number is required</span
                            >
                        </div>
                    </div>
                    <div class="col-2">
                        <div class="form-group">
                            <label>Box</label>
                            <input
                                type="text"
                                placeholder="Box"
                                formControlName="bus"
                                trim="blur" />
                        </div>
                    </div>
                    <div class="col-2">
                        <div class="form-group">
                            <label>Ext</label>
                            <input
                                type="number"
                                placeholder="Ext"
                                formControlName="ext" />
                        </div>
                    </div>
                </div>
            </div>

            <div class="m-b-2">
                <div class="row">
                    <div class="col-3">
                        <div class="form-group required">
                            <label>Zipcode</label>
                            <input
                                type="text"
                                placeholder="Zipcode*"
                                formControlName="postal"
                                (focus)="hideErrorMessage()"
                                trim="blur" />
                            <span
                                class="form-error"
                                *ngIf="companyFormGroup.get('address.postal').errors && inputIsWrong"
                                >Zipcode is required</span
                            >
                        </div>
                    </div>
                    <div class="col-9">
                        <div class="form-group required">
                            <label>City</label>
                            <input
                                type="text"
                                placeholder="City*"
                                formControlName="city"
                                (focus)="hideErrorMessage()"
                                trim="blur" />
                            <span
                                class="form-error"
                                *ngIf="companyFormGroup.get('address.city').errors && inputIsWrong"
                                >City is required</span
                            >
                        </div>
                    </div>
                </div>
            </div>

            <div
                class="form-group m-b-2 required"
                formGroupName="country">
                <label>Country</label>
                <select
                    formControlName="iso2"
                    (focus)="hideErrorMessage()">
                    <option
                        *ngFor="let country of allCountries"
                        [ngValue]="country['alpha-2']">
                        {{ country.name }}
                    </option>
                </select>
                <span
                    class="form-error"
                    *ngIf="companyFormGroup.get('address.country.iso2').errors && inputIsWrong"
                    >Country is required</span
                >
            </div>
        </div>

        <h3 class="m-t-4 m-b-4">Contact information</h3>

        <div formGroupName="contactInformation">
            <div class="form-group m-b-2 required">
                <label>Company phonenumber</label>
                <input
                    type="text"
                    placeholder="Phonenumber"
                    formControlName="phoneNumber"
                    trim="blur" />
                <span class="form-helptext">Not your personal phonenumber!</span>
                <span
                    class="form-error"
                    *ngIf="companyFormGroup.get('contactInformation.phoneNumber').errors && inputIsWrong"
                    >Phonenumber is required</span
                >
            </div>

            <div class="form-group m-b-2 required">
                <label>Company emailaddress</label>
                <input
                    type="email"
                    placeholder="Emailaddress"
                    formControlName="email"
                    trim="blur" />
                <span class="form-helptext">Probably something like contact@mycompany.com ...</span>
                <span
                    class="form-error"
                    *ngIf="companyFormGroup.get('contactInformation.email').errors && inputIsWrong"
                    >Email is required</span
                >
            </div>

            <div class="form-group m-b-2">
                <label>Company website</label>
                <input
                    type="text"
                    placeholder="Website"
                    formControlName="website"
                    trim="blur" />
            </div>
        </div>

        <span
            class="form-error"
            *ngIf="saveCompanyWentWrong"
            >{{ errorMessage }}</span
        >
    </div>

    <div class="modal-footer">
        <button
            type="button"
            class="button button--outline"
            (click)="activeModal.close()">
            Close
        </button>
        <button
            *ngIf="!isUpdate"
            type="submit"
            [ngClass]="{ 'button--loading': loading }"
            class="button button--primary">
            Add company
        </button>
        <button
            *ngIf="isUpdate"
            type="submit"
            [ngClass]="{ 'button--loading': loading }"
            class="button button--primary">
            Edit company
        </button>
    </div>
</form>
