import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';

import { DeleteAssignedResume } from '../project.actions';

@Component({
    selector: 'app-assigned-resume-delete-modal',
    templateUrl: './assigned-resume-delete-modal.component.html',
    styleUrls: ['./assigned-resume-delete-modal.component.scss'],
})
export class AssignedResumeDeleteModalComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();
    @Input() workAssignmentUuid: string;

    constructor(public activeModal: NgbActiveModal, private store: Store, private logger: NGXLogger) {}

    ngOnInit(): void {}

    deleteAssignedResume() {
        this.logger.debug('attempt to delete assignedResume');
        this.subscriptions.add(
            this.store.dispatch(new DeleteAssignedResume(this.workAssignmentUuid)).subscribe(
                () => {
                    this.logger.debug('Succesfully deleted assigned resume');
                    this.activeModal.close();
                },
                (error) => {
                    this.logger.error('Failed to delete assigned resume');
                },
            ),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
