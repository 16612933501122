<app-app-holder>
    <app-navbar></app-navbar>

    <app-content>
        <div>
            <div class="app__title">
                <h1>
                    Companies
                    <span *ngIf="partners$ | async as partners">({{ partners.length }})</span>
                </h1>
                <span>
                    <a
                        (click)="openAddPartnerModal()"
                        *appIsPermitted="{
                            module: 'PARTNERS',
                            operator: 'AND',
                            actions: ['CREATE']
                        }"
                        class="button button--primary"
                        >New company</a
                    >
                </span>
            </div>
            <div
                *appIsPermitted="{
                    module: 'PARTNERS',
                    operator: 'AND',
                    actions: ['READ']
                }">
                <div *ngIf="partners$ | async as partners; else loading">
                    <app-partner-list *ngIf="partners.length > 0; else empty"></app-partner-list>
                </div>
            </div>
        </div>
    </app-content>

    <ng-template #loading>
        <app-loading-message></app-loading-message>
    </ng-template>

    <ng-template #empty>
        <app-message-empty
            [title]="'No companies...'"
            [subtitle]="'Companies are partners, suppliers, ... Start adding companies to organize your business.'"
            [icon]="'../../assets/img/open-box.png'">
            <a
                (click)="openAddPartnerModal()"
                class="button button--primary"
                >Add first company</a
            >
        </app-message-empty>
    </ng-template>
</app-app-holder>
