import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { CompanyMember } from 'src/app/models/CompanyMember';
import { Module } from 'src/app/models/Module';

import { IntegrationDetailsService } from './integrationDetails.service';

@Component({
    selector: 'app-integration-detail-modal',
    templateUrl: './integration-detail-modal.component.html',
    styleUrls: ['./integration-detail-modal.component.scss'],
})
export class IntegrationDetailModalComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();
    @Input() module: Module;

    loggedInMembers: CompanyMember[];
    isLoggedIn: boolean;
    operationSucceeded: boolean;

    constructor(private integrationDetailsService: IntegrationDetailsService, public activeModal: NgbActiveModal, private router: Router) {}

    ngOnInit(): void {
        this.subscriptions.add(this.integrationDetailsService.getLogedInMembers().subscribe((m) => (this.loggedInMembers = m)));
        this.subscriptions.add(this.integrationDetailsService.getIsLogedIn().subscribe((l) => (this.isLoggedIn = l)));
    }

    closeModal() {
        this.activeModal.close();
    }

    openDetails() {
        switch (this.module.key) {
            case 'MAILCHIMP':
                this.router.navigate(['/mailchimp']);
                break;
            case 'YUKI':
                this.router.navigate(['/invoices']);
                break;
            case 'COMANAGE':
                this.router.navigate(['/comanage']);
                break;
            case 'TEAMLEADER':
                this.router.navigate(['/teamleader']);
                break;
            case 'EXACT':
                this.router.navigate(['/exact']);
                break;
            case 'GOOGLE':
                this.router.navigate(['/calendar']);
                break;
            case 'OUTLOOK':
                this.router.navigate(['/calendar']);
                break;
            case 'JIRA':
                this.router.navigate(['/jira']);
                break;
        }

        this.activeModal.close();
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
