import { Component, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CrmdealDetailModalComponent } from 'src/app/crm/crmdeal-detail-modal/crmdeal-detail-modal.component';
import CrmDeal from 'src/app/models/CrmDeal';

@Component({
    selector: 'app-pill-crmdeal',
    templateUrl: './pill-crmdeal.component.html',
    styleUrls: ['./pill-crmdeal.component.scss'],
})
export class PillCrmdealComponent {
    @Input() deal: CrmDeal;
    @Input() dealUuid: string;
    @Input() title: string;

    constructor(private modalService: NgbModal) {}

    openDeal() {
        const modalRef = this.modalService.open(CrmdealDetailModalComponent, {
            windowClass: 'modal-pane',
            animation: false,
        });
        this.deal ? (modalRef.componentInstance.deal = this.deal) : (modalRef.componentInstance.dealUuid = this.dealUuid);
    }

    getTitle(): string {
        return this.deal ? this.deal.title : this.title;
    }
}
