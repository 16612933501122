import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-thermostar-configurator',
    templateUrl: './thermostar-configurator.component.html',
    styleUrls: ['./thermostar-configurator.component.scss'],
})
export class ThermostarConfiguratorComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
