<app-app-holder>
    <app-navbar></app-navbar>

    <app-sidebar>
        <app-settings-subnav></app-settings-subnav>
    </app-sidebar>

    <app-content>
        <div>
            <div class="app__title">
                <h1>API Settings</h1>
            </div>
            <p><strong>General</strong></p>

            <p><strong>Leads API</strong></p>
            <span>API endpoints: </span>
            <ul>
                <li>[POST] <a>https://resumo.io/api/leads</a> - Posts a new lead</li>
                <!-- <li>[GET] <a>https://resumo.io/api/leads/&#123;API identifier&#125;/lead-list</a> - Get all leads</li>
                <li>[PUT] <a>https://resumo.io/api/leads</a> - Update an existing lead</li> -->
                <span
                    >Example: <br />
                    &#x7B; <br />
                    "firstName" (String) <br />
                    "lastName" (String) <br />
                    "email" (String) <br />
                    "phoneNumber" (String) <br />
                    "message" (String) <br />
                    "channelKey" (String) <br />
                    "dateCreated" (String) <br />
                    "secret" (UUID) <br />
                    "fields" (Serializable) <br />
                    &#x7D;
                </span>
            </ul>
        </div>
    </app-content>

    <ng-template #loading>
        <app-loading-message></app-loading-message>
    </ng-template>
</app-app-holder>
