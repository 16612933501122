import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { OverviewComponent } from './overview/overview.component';
import { ResumeDetailPageComponent } from './resume-detail-page/resume-detail-page.component';

export const routes: Routes = [
    { path: '', redirectTo: 'overview', pathMatch: 'full' },
    { path: 'overview', component: OverviewComponent },
    { path: ':id/detail', component: ResumeDetailPageComponent },
];

export const routing: ModuleWithProviders<any> = RouterModule.forChild(routes);
