import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { TeamleaderContact } from 'src/app/models/TeamleaderContact';

import { FetchContacts } from '../teamleader.actions';
import { TeamleaderState } from '../teamleader.state';

@Component({
    selector: 'app-teamleader-contacts',
    templateUrl: './teamleader-contacts.component.html',
    styleUrls: ['./teamleader-contacts.component.scss'],
})
export class TeamleaderContactsComponent implements OnInit {
    @Select(TeamleaderState.contacts) teamleaderContacts$: Observable<TeamleaderContact[]>;
    isset = true;
    isLoading = true;
    filter: string;

    constructor(private store: Store) {}

    ngOnInit(): void {}

    checkCredentials(set: boolean) {
        this.isset = set;
        if (this.isset) {
            this.store.dispatch(new FetchContacts());
            this.isLoading = false;
        }
    }
}
