import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subscription } from 'rxjs';
import { ModuleEnum } from 'src/app/models/ModuleEnum';
import { Product } from 'src/app/models/Product';
import Project from 'src/app/models/Project';
import { Resume } from 'src/app/models/resume/Resume';
import { FetchProducts } from 'src/app/products/product.actions';
import { ModuleService } from 'src/app/services/module.service';

import { ProjectAddProductModalComponent } from '../project-add-product-modal/project-add-product-modal.component';
import { ProjectDeleteModalComponent } from '../project-delete-modal/project-delete-modal.component';
import { ProjectDeleteProductComponent } from '../project-delete-product/project-delete-product.component';
import { ProjectDetailModalComponent } from '../project-detail-modal/project-detail-modal.component';
import { GetProjectAssignedResumes } from '../project.actions';
import { ProjectState } from '../project.state';

@Component({
    selector: 'app-project-detail-products',
    templateUrl: './project-detail-products.component.html',
    styleUrls: ['./project-detail-products.component.scss'],
})
export class ProjectDetailPductsComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();
    @Select(ProjectState.projects) projects$: Observable<Project[]>;
    assignedResumes: Resume[];
    project: Project;

    eModule = ModuleEnum;

    constructor(
        private route: ActivatedRoute,
        private store: Store,
        private router: Router,
        private modalService: NgbModal,
        public activeModal: NgbActiveModal,
        public moduleService: ModuleService,
        private logger: NGXLogger,
    ) {}

    ngOnInit(): void {
        this.logger.debug('Fetching products');
        this.store.dispatch(new FetchProducts());
        this.subscriptions.add(
            this.route.params.subscribe((params) => {
                this.projects$.subscribe((projects) => (this.project = projects.filter((w) => w.uuid == params.id)[0]));
                this.logger.debug('Fetching workAssignedResumes');
                this.store.dispatch(new GetProjectAssignedResumes(params.id));
            }),
        );
    }

    workDetail(project: Project) {
        this.logger.debug('Navigating to project employees');
        this.router.navigate(['/', 'projects', project.uuid, 'employees']);
    }

    openProjectDetailModalComponent() {
        this.logger.debug('Opening projectDetailModal');
        const modalRef = this.modalService.open(ProjectDetailModalComponent);
        modalRef.componentInstance.inOverview = true;
        modalRef.componentInstance.project = this.project;
        modalRef.componentInstance.isUpdate = true;
    }

    openWorkDeleteModal(project: Project) {
        this.logger.debug('Opening workDeleteModal');
        const modalRef = this.modalService.open(ProjectDeleteModalComponent, {
            windowClass: 'modal-prompt',
        });
        modalRef.componentInstance.project = project;
    }

    openWorkAddProductModal(project: Project, product?: Product) {
        this.logger.debug('Opening workAddProductModal');
        const modalRef = this.modalService.open(ProjectAddProductModalComponent, { windowClass: 'modal-prompt' });
        modalRef.componentInstance.project = project;
        modalRef.componentInstance.product = product;
    }

    openWorkProductDeleteModal(project: Project, product: Product) {
        this.logger.debug('Opening workDeleteProductModal');
        const modalRef = this.modalService.open(ProjectDeleteProductComponent, {
            windowClass: 'modal-prompt',
        });
        modalRef.componentInstance.product = product;
        modalRef.componentInstance.project = project;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
