<div class="dashboard__tile__title">
    <span>Newest contacts</span>
    <button
        type="button"
        class="button button--icon button--small"
        (click)="goToContactPersons()">
        <i class="uil uil-arrow-right"></i>
    </button>
</div>
<div class="dashboard__tile__content">
    <div class="table__overflow">
        <table
            mat-table
            [dataSource]="dataSource"
            matSort
            matSort
            matSortActive="createdOn"
            matSortDirection="desc"
            [hidden]="!(this.dataSource?.data?.length > 0)">
            <ng-container matColumnDef="firstName">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header>
                    Contact
                </th>
                <td
                    mat-cell
                    *matCellDef="let row">
                    <app-pill-contact [contactPerson]="row"></app-pill-contact>
                </td>
            </ng-container>

            <ng-container matColumnDef="partnerName">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header>
                    Company
                </th>
                <td
                    mat-cell
                    *matCellDef="let row">
                    <app-pill-partner
                        *ngIf="row.partnerUuid"
                        [partnerUuid]="row.partnerUuid"></app-pill-partner>
                </td>
            </ng-container>

            <ng-container matColumnDef="creatorId">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header>
                    Added By
                </th>
                <td
                    mat-cell
                    *matCellDef="let row">
                    <app-pill-user [userId]="row.creatorId"></app-pill-user>
                </td>
            </ng-container>

            <ng-container matColumnDef="createdOn">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header>
                    Added
                </th>
                <td
                    mat-cell
                    *matCellDef="let row">
                    {{ row.createdOn | amTimeAgo }}
                </td>
            </ng-container>

            <tr
                mat-header-row
                *matHeaderRowDef="displayedColumns"></tr>
            <tr
                mat-row
                *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
    </div>
    <div
        class="dashboard__tile__content"
        *ngIf="this.noContactPersons">
        <app-message-empty
            [title]="'You have no new contacts...'"
            [icon]="'../../assets/img/open-box.png'"></app-message-empty>
    </div>

    <div *ngIf="isLoading">
        <app-loading-message></app-loading-message>
    </div>
</div>
