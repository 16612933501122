import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TeamleaderService } from 'src/app/teamleader/teamleader.service';

@Component({
    selector: 'app-teamleader-overview',
    templateUrl: './teamleader-overview.component.html',
    styleUrls: ['./teamleader-overview.component.scss'],
})
export class TeamleaderOverviewComponent implements OnInit {
    isset = true;

    constructor(private teamleader: TeamleaderService, private titleService: Title) {
        this.titleService.setTitle('Teamleader overview');
    }

    ngOnInit(): void {}

    checkCredentials(set: boolean) {
        this.isset = set;
    }
}
