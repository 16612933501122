import { Component, Input, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { Product } from 'src/app/models/Product';
import { FetchProducts } from 'src/app/products/product.actions';
import { ProductState } from 'src/app/products/product.state';

@Component({
    selector: 'app-pill-unit',
    templateUrl: './pill-unit.component.html',
    styleUrls: ['./pill-unit.component.scss'],
})
export class PillUnitComponent implements OnInit {
    private subscriptions = new Subscription();

    @Select(ProductState.Products) products$: Observable<Product[]>;

    @Input() productUuid: string;
    product: Product;

    isLoading = true;

    constructor(private store: Store) {}

    ngOnInit(): void {
        this.subscriptions.add(
            this.products$.subscribe((products) => {
                if (products == null) {
                    this.store.dispatch(new FetchProducts());
                } else {
                    const prod = products.find((p) => p.uuid === this.productUuid);
                    if (prod) {
                        this.product = JSON.parse(JSON.stringify(prod));
                    }
                    this.isLoading = false;
                }
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
