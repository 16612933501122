import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';

import { EMOJIS } from '../../models/EMOJIS';
import { UpdateHappinessScore } from '../timesheets.actions';

@Component({
    selector: 'app-edit-happiness-score-modal',
    templateUrl: './edit-happiness-score-modal.component.html',
    styleUrls: ['./edit-happiness-score-modal.component.scss'],
})
export class EditHappinessScoreModalComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Input() happinessScore: number;
    @Input() timesheetId: string;

    emojis: EMOJIS[] = [];
    selectedEmoji: number;

    constructor(public activeModal: NgbActiveModal, private store: Store, private toastr: ToastrService, private logger: NGXLogger) {}

    ngOnInit(): void {
        this.emojis = Object.values(EMOJIS);
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    updateHappinessScore() {
        this.subscriptions.add(
            this.store.dispatch(new UpdateHappinessScore(this.timesheetId, this.happinessScore)).subscribe(
                (res) => {
                    this.toastr.success('Happiness score successfully updated');
                    this.activeModal.close();
                },
                (error) => {
                    this.logger.error('Failed to update happiness score');
                    this.toastr.error('Oops, something went wrong, Please try again later...');
                },
            ),
        );
    }
}
