<app-app-holder>
    <app-navbar></app-navbar>

    <app-sidebar>
        <app-projects-subnav></app-projects-subnav>
    </app-sidebar>

    <app-content>
        <div *ngIf="work">
            {{ work | json }}
        </div>
    </app-content>

    <ng-template #loading>
        <app-loading-message></app-loading-message>
    </ng-template>
</app-app-holder>
