import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import * as moment from 'moment';
import { TeamleaderCalendarMeeting } from 'src/app/models/TeamleaderCalendarMeeting';

import { AddCalendarEvent } from '../teamleader.actions';

@Component({
    selector: 'app-teamleader-add-calendar-meeting',
    templateUrl: './teamleader-add-calendar-meeting.component.html',
    styleUrls: ['./teamleader-add-calendar-meeting.component.scss'],
})
export class TeamleaderAddCalendarMeetingComponent implements OnInit {
    @ViewChild('startpicker') startPicker: any;
    @ViewChild('endpicker') endPicker: any;
    @Input() activityType: any;
    calendarMeetingForm: FormGroup;
    public color: ThemePalette = 'primary';

    constructor(private formBuilder: FormBuilder, public activeModal: NgbActiveModal, private store: Store) {}

    ngOnInit(): void {
        this.calendarMeetingForm = this.formBuilder.group({
            title: ['', [Validators.required]],
            description: ['', [Validators.required]],
            activityTypeId: [this.activityType.id, [Validators.required]],
            startsAt: ['', [Validators.required]],
            endsAt: ['', [Validators.required]],
            location: ['', [Validators.required]],
        });
    }

    AddCalendarMeeting() {
        if (this.calendarMeetingForm.valid) {
            const formValue = this.calendarMeetingForm.value;
            const meeting: TeamleaderCalendarMeeting = {
                title: formValue.title,
                description: formValue.description,
                activity_type_id: formValue.activityTypeId,
                starts_at: moment(formValue.startsAt).format(),
                ends_at: moment(formValue.endsAt).format(),
                location: formValue.location,
            };
            this.store.dispatch(new AddCalendarEvent(meeting, 'meeting'));
            this.activeModal.close();
        }
    }
}
