import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-pill-mailto',
    templateUrl: './pill-mailto.component.html',
    styleUrls: ['./pill-mailto.component.scss'],
})
export class PillMailtoComponent {
    @Input() mailtoEmail: string;
}
