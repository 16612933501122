import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { AuthenticationService } from '../authentication/authentication.service';
import { Company } from '../models/Company';
import { CompanyInvitedMember } from '../models/CompanyInvitedMember';
import { CompanyMember } from '../models/CompanyMember';
import { Status } from '../models/Status';
import User from '../models/User';

@Injectable({
    providedIn: 'root',
})
export class CompanyService {
    private httpOptions = {};

    constructor(private httpClient: HttpClient, private authenticationService: AuthenticationService) {
        this.httpOptions = authenticationService.getHeaders();
    }

    addCompany(company: Company): Observable<Company> {
        return this.httpClient.post<Company>(environment.baseUrl + '/company', company, this.httpOptions);
    }

    updateCompany(company: Company): Observable<Company> {
        return this.httpClient.put<Company>(environment.baseUrl + '/company', company, this.httpOptions);
    }

    archiveCompany(uuid: string): Observable<string> {
        return this.httpClient.put<string>(environment.baseUrl + '/company/' + uuid, {}, this.httpOptions);
    }

    getAllMembers(): Observable<CompanyMember[]> {
        return this.httpClient.get<CompanyMember[]>(`${environment.baseUrl}/company/company-members/`, this.httpOptions);
    }

    getAllInvitedMembers(uuid: string): Observable<CompanyInvitedMember[]> {
        return this.httpClient.get<CompanyInvitedMember[]>(environment.baseUrl + '/company/' + uuid + '/invited-users-list', this.httpOptions);
    }

    getCompanyProjectStatusList(companyUuid: string): Observable<Status[]> {
        return this.httpClient.get<Status[]>(environment.baseUrl + '/project/' + companyUuid + '/project-status-list', this.httpOptions);
    }

    deleteInvite(inviteUuid: string) {
        return this.httpClient.delete(environment.baseUrl + '/company/delete-invite/' + inviteUuid, this.httpOptions);
    }

    inviteMember(email: string) {
        return this.httpClient.post<User>(environment.baseUrl + '/company/add-company-member', email, this.httpOptions);
    }

    acceptCompanyInvite(inviteUuid: string) {
        return this.httpClient.post(environment.baseUrl + '/company/' + inviteUuid + '/accept-invite', {}, this.httpOptions);
    }

    getUserInvites(email: string): Observable<CompanyInvitedMember[]> {
        return this.httpClient.post<CompanyInvitedMember[]>(environment.baseUrl + '/company/invited-users-list', email, this.httpOptions);
    }

    getCompanyMemberById(id: string): Observable<CompanyMember> {
        return this.httpClient.get<CompanyMember>(`${environment.baseUrl}/company/company-members/${id}`, this.httpOptions);
    }
}
