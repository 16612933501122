import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import * as moment from 'moment';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subscription } from 'rxjs';
import { CompanyState } from 'src/app/companies/company.state';
import { Company } from 'src/app/models/Company';
import Project from 'src/app/models/Project';
import { Resume } from 'src/app/models/resume/Resume';
import { ResumeVariant } from 'src/app/models/resume/ResumeVariant';

import { EducationDetailModalComponent } from '../education-detail-modal/education-detail-modal.component';
import { ExperienceDetailModalComponent } from '../experience-detail-modal/experience-detail-modal.component';
import { ManageVariantsComponent } from '../manage-variants/manage-variants.component';
import { ResumeDeleteModalComponent } from '../resume-delete-modal/resume-delete-modal.component';
import { ResumePersoninfoUpdateModalComponent } from '../resume-personinfo-update-modal/resume-personinfo-update-modal.component';
import { ResumeShareModalComponent } from '../resume-share-modal/resume-share-modal.component';
import { ResumeVariantDeleteModalComponent } from '../resume-variant-delete-modal/resume-variant-delete-modal.component';
import { ResumeVariantDetailModalComponent } from '../resume-variant-detail-modal/resume-variant-detail-modal.component';
import { ResumeVariantSkillDetailModalComponent } from '../resume-variant-skill-detail-modal/resume-variant-skill-detail-modal.component';
import { FetchResumes, GetEducations, GetExperiences, GetLanguages, GetResume, GetResumeVariants, UpdateResumeDescription } from '../resume.actions';
import { ResumeService } from '../resume.service';
import { ResumeState } from '../resume.state';

@Component({
    selector: 'app-resume-detail',
    templateUrl: './resume-detail.component.html',
    styleUrls: ['./resume-detail.component.scss'],
})
export class ResumeDetailComponent implements OnInit, OnDestroy {
    @Input() resumeid: string;
    @Input() showActions = true;
    @Select(ResumeState.resumes) resumes$: Observable<Resume[]>;
    @Select(CompanyState.company) company$: Observable<Company>;
    resumeUuid: string;
    description: string;
    resumeCompany: Company;
    descriptionFormGroup: FormGroup;
    resume: Resume;
    currentDate: Date;
    inApplicants = false;
    resumeDetail: Resume;
    loading: boolean;
    resumePdf;
    filename;
    selectedResumeVariantUuid: string;
    selectedResumeVariant: ResumeVariant;
    techSkillSuggestions: string[] = [];
    private subscriptions = new Subscription();

    constructor(
        private route: ActivatedRoute,
        private modalService: NgbModal,
        private store: Store,
        private router: Router,
        private resumeService: ResumeService,
        private sanitizer: DomSanitizer,
        private logger: NGXLogger,
    ) {}

    ngOnInit(): void {
        this.currentDate = new Date();

        this.subscriptions.add(
            this.route.queryParams.subscribe((params) => {
                if (params.variant) {
                    this.route.queryParams.subscribe((params) => {
                        this.selectedResumeVariantUuid = params.variant;
                    });
                }
            }),
        );

        this.subscriptions.add(
            this.route.params.subscribe((params) => {
                if (params.id) {
                    this.resumeUuid = params.id;

                    this.logger.debug('Fetching resume detail');
                    this.store.dispatch(new FetchResumes()).subscribe(() => {
                        this.resumes$.subscribe((resumes) => {
                            this.resume = resumes?.find((r) => r.uuid === this.resumeUuid);
                            this.calculateDaysSinceInvite();

                            this.resumeDetail = this.resume;
                        }),
                            this.refresh(params.id);
                    });
                }
            }),
        );
    }

    refresh(id: string) {
        this.logger.debug('fetching resume -skills, -educations, -experiences, -languages');
        this.store.dispatch(new GetEducations(id));
        this.store.dispatch(new GetLanguages(id));
        this.store.dispatch(new GetExperiences(id));
        this.store.dispatch(new GetResumeVariants(id)).subscribe(() => {
            if (this.selectedResumeVariantUuid == null) {
                this.selectedResumeVariantUuid = this.resume.resumeVariants[0].uuid;
            }
            this.store.select(ResumeState.selectedResumeVariant(this.resumeUuid, this.selectedResumeVariantUuid)).subscribe((variant) => {
                this.selectedResumeVariant = variant;
            });

            this.changeQueryParam(this.selectedResumeVariantUuid);
        });
    }

    calculateDaysSinceInvite() {
        const createdOnDate = this.resume.createdOn;
        Object.assign({}, this.resume, {
            daysSinceCreation: Math.abs(moment(this.currentDate).diff(moment(createdOnDate), 'days')),
        });
    }

    openEducationAddModal() {
        this.logger.debug('Opening educationDetailModal');
        const modalRef = this.modalService.open(EducationDetailModalComponent, { animation: false });
        modalRef.componentInstance.resumeUuid = this.resume.uuid;
    }

    openExperienceAddModal() {
        this.logger.debug('Opening experienceDetailModal');
        const modalRef = this.modalService.open(ExperienceDetailModalComponent, { animation: false });
        modalRef.componentInstance.resumeUuid = this.resume.uuid;
    }

    openResumeVariantAddModal() {
        this.logger.debug('Opening resumeVariantSkillDetailModal');
        const modalRef = this.modalService.open(ResumeVariantDetailModalComponent, { windowClass: 'modal-prompt' });

        modalRef.componentInstance.resumeUuid = this.resume.uuid;

        modalRef.closed.subscribe(() => {
            this.selectedResumeVariantUuid = this.resume.resumeVariants[this.resume.resumeVariants.length - 1].uuid;
            this.store.select(ResumeState.selectedResumeVariant(this.resumeUuid, this.selectedResumeVariantUuid)).subscribe((variant) => {
                this.selectedResumeVariant = variant;
            });
            this.changeQueryParam(this.selectedResumeVariantUuid);
        });
    }

    openUpdateResumeVariantModal() {
        this.logger.debug('Opening resumeVariantSkillDetailModal');
        const modalRef = this.modalService.open(ResumeVariantDetailModalComponent, { windowClass: 'modal-prompt' });

        modalRef.componentInstance.resumeVariant = this.selectedResumeVariant;
    }

    openDeleteResumeVariantModal() {
        this.logger.debug('Opening resumeVariantSkillDeleteModal');
        const modalRef = this.modalService.open(ResumeVariantDeleteModalComponent, { windowClass: 'modal-prompt' });
        modalRef.componentInstance.resumeVariant = this.selectedResumeVariant;

        const uuid = this.selectedResumeVariant.uuid;
        modalRef.closed.subscribe(() => {
            if (this.resume.resumeVariants.find((rv) => rv.uuid == uuid) == null) {
                this.selectedResumeVariantUuid = this.resume.resumeVariants[0].uuid;
                this.store.select(ResumeState.selectedResumeVariant(this.resumeUuid, this.selectedResumeVariantUuid)).subscribe((variant) => {
                    this.selectedResumeVariant = variant;
                });
                this.changeQueryParam(this.selectedResumeVariantUuid);
            }
        });
    }

    openResumeVariantSkillAddModal() {
        this.logger.debug('Opening resumeVariantSkillDetailModal');
        const modalRef = this.modalService.open(ResumeVariantSkillDetailModalComponent, { windowClass: 'modal-prompt', animation: false });
        modalRef.componentInstance.resumeVariant = this.selectedResumeVariant;
    }

    openEditResumePersonInfoUpdateModal() {
        this.logger.debug('Opening resumePersonInfoUpdateModal');
        const modalRef = this.modalService.open(ResumePersoninfoUpdateModalComponent, { animation: false });
        modalRef.componentInstance.resume = this.resume;
    }

    openResumeShareModal() {
        this.logger.debug('Opening resumeShareModal');
        const modalRef = this.modalService.open(ResumeShareModalComponent);
        modalRef.componentInstance.resume = this.resume;
    }

    openResumeDeleteModal() {
        this.logger.debug('Opening resumeDeleteModal');
        const modalRef = this.modalService.open(ResumeDeleteModalComponent, {
            windowClass: 'modal-prompt',
            animation: false,
        });
        modalRef.componentInstance.resume = this.resume;
    }

    applySelected(event) {
        console.log(event.value);
    }

    saveDescriptionTest(item) {
        this.logger.debug('Attempt ot update resumeDescription');
        this.subscriptions.add(
            this.store.dispatch(new UpdateResumeDescription(this.resumeUuid, item)).subscribe({
                next: () => {
                    this.logger.debug('Succesfully updated resumeDescription');
                },
                error: (error) => this.logger.error('Failed to update resumeDescription'),
            }),
        );
    }

    saveDescription() {
        this.resume = this.descriptionFormGroup.value;
        this.logger.debug('Attempt ot update resumeDescription');
        this.subscriptions.add(
            this.store.dispatch(new UpdateResumeDescription(this.resumeUuid, this.resume)).subscribe({
                next: () => {
                    this.logger.debug('Succesfully updated resumeDescription');
                    this.store.dispatch(new GetResume(this.resumeUuid));
                },
                error: (error) => this.logger.error('Failed to update resumeDescription'),
            }),
        );
    }

    workDetail(project: Project) {
        this.logger.debug('Navigating to projectdetail');
        this.router.navigate(['/', 'project', project.uuid, 'detail']);
        this.modalService.dismissAll();
    }

    downloadResumePdf() {
        this.subscriptions.add(
            this.resumeService.downloadResumePdf(this.resume.uuid, this.selectedResumeVariantUuid).subscribe((pdf) => {
                this.filename = `${this.resume.uuid}.pdf`;
                this.base64ToBlob(pdf.pdf);
            }),
        );
    }

    trackByVariantId(index, item) {
        return item.uuid;
    }

    base64ToBlob(data) {
        const byteChars = atob(data);
        const byteNumbers = new Array(byteChars.length);
        for (let i = 0; i < byteChars.length; i++) {
            byteNumbers[i] = byteChars.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);
        this.resumePdf = this.sanitizer.bypassSecurityTrustUrl(url);
        this.filename = `${this.resume.uuid}.pdf`;
        this.logger.debug('Opening pdf in new window');
        window.open(url);
    }

    isDefaultResumeVariant() {
        const defaultVariant = this.resume.resumeVariants[0];
        return defaultVariant.uuid === this.selectedResumeVariantUuid;
    }

    onChange(uuid) {
        this.store.select(ResumeState.selectedResumeVariant(this.resumeUuid, uuid)).subscribe((variant) => {
            this.selectedResumeVariant = variant;
        });

        this.changeQueryParam(uuid);
    }

    changeQueryParam(uuid) {
        this.router.navigate(['.'], { relativeTo: this.route, queryParams: { variant: uuid } });
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    openManageVariantsModal() {
        const modalRef = this.modalService.open(ManageVariantsComponent, { animation: false });
        modalRef.componentInstance.resume = this.resume;
    }
}
