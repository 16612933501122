import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, createSelector } from '@ngxs/store';
import { append, insertItem, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { tap } from 'rxjs/operators';

import LogCategory from '../models/Logbook/LogCategory';
import LogItem from '../models/Logbook/LogItem';
import LogItemsForObject from '../models/Logbook/LogItemsForObject';
import { AddLogCategory, AddLogItem, DeleteLogCategory, DeleteLogItem, GetAllLogCategories, GetLogItemsByObjectId, UpdateLogItem } from './logbook.actions';
import { LogbookService } from './logbook.service';

export interface LogbookStateModel {
    logCategories: LogCategory[];
    logItems: LogItemsForObject[];
}

@State<LogbookStateModel>({
    name: 'logbook',
    defaults: {
        logCategories: undefined,
        logItems: undefined,
    },
})
@Injectable()
export class LogbookState {
    constructor(private logbookService: LogbookService) {}

    @Selector()
    static LogCategories(state: LogbookStateModel): LogCategory[] {
        return state?.logCategories;
    }

    @Selector()
    static LogItemsByObjectId(objectId: string) {
        return createSelector([LogbookState], (state: LogbookStateModel) =>
            state.logItems.find((logItemsForObject) => logItemsForObject.objectId === objectId),
        );
    }

    @Selector()
    static LogItems(state: LogbookStateModel): LogItemsForObject[] {
        return state?.logItems;
    }

    @Action(GetAllLogCategories)
    getAllLogCategories(ctx: StateContext<LogbookStateModel>) {
        return this.logbookService.getAllLogCategories().pipe(
            tap((logCategories) => {
                ctx.setState(
                    patch<LogbookStateModel>({
                        logCategories: logCategories,
                    }),
                );
            }),
        );
    }

    @Action(AddLogCategory)
    addLogCategory(ctx: StateContext<LogbookStateModel>, action: AddLogCategory) {
        return this.logbookService.addLogCategory(action.logCategory).pipe(
            tap((logCategory) => {
                ctx.setState(
                    patch<LogbookStateModel>({
                        logCategories: append([logCategory]),
                    }),
                );
            }),
        );
    }

    @Action(DeleteLogCategory)
    deleteLogCategory(ctx: StateContext<LogbookStateModel>, action: DeleteLogCategory) {
        return this.logbookService.deleteLogCategory(action.categoryId).pipe(
            tap(() => {
                ctx.setState(
                    patch<LogbookStateModel>({
                        logCategories: removeItem((category) => category.id === action.categoryId),
                    }),
                );
            }),
        );
    }

    @Action(AddLogItem)
    addLogItem(ctx: StateContext<LogbookStateModel>, action: AddLogItem) {
        return this.logbookService.addLogItem(action.logItem).pipe(
            tap((logItem) => {
                if (ctx.getState().logItems && ctx.getState().logItems.find((logItemsForObject) => logItemsForObject.objectId === action.logItem.objectId)) {
                    ctx.setState(
                        patch<LogbookStateModel>({
                            logItems: updateItem<LogItemsForObject>(
                                (logItem) => logItem.objectId === action.logItem.objectId,
                                patch<LogItemsForObject>({
                                    logItems: insertItem<LogItem>(logItem),
                                }),
                            ),
                        }),
                    );
                } else {
                    ctx.setState(
                        patch<LogbookStateModel>({
                            logItems: insertItem<LogItemsForObject>({
                                objectId: action.logItem.objectId,
                                logItems: [logItem],
                            }),
                        }),
                    );
                }
            }),
        );
    }

    @Action(GetLogItemsByObjectId)
    getLogItemsByObjectId(ctx: StateContext<LogbookStateModel>, action: GetLogItemsByObjectId) {
        return this.logbookService.getLogItemsByObjectId(action.objectId).pipe(
            tap((logItems) => {
                if (ctx.getState().logItems && ctx.getState().logItems.find((logItemsForObject) => logItemsForObject.objectId === action.objectId)) {
                    ctx.setState(
                        patch<LogbookStateModel>({
                            logItems: updateItem<LogItemsForObject>(
                                (logItem) => logItem.objectId === action.objectId,
                                patch<LogItemsForObject>({
                                    logItems: logItems,
                                }),
                            ),
                        }),
                    );
                } else {
                    ctx.setState(
                        patch<LogbookStateModel>({
                            logItems: insertItem<LogItemsForObject>({
                                objectId: action.objectId,
                                logItems: logItems,
                            }),
                        }),
                    );
                }
            }),
        );
    }

    @Action(DeleteLogItem)
    deleteLogItem(ctx: StateContext<LogbookStateModel>, action: DeleteLogItem) {
        return this.logbookService.deleteLogItem(action.logItemId).pipe(
            tap(() => {
                ctx.setState(
                    patch<LogbookStateModel>({
                        logItems: updateItem<LogItemsForObject>(
                            (logItemsForObject) => logItemsForObject.logItems.some((logItem) => logItem.id === action.logItemId),
                            patch<LogItemsForObject>({
                                logItems: removeItem((logItem) => logItem.id === action.logItemId),
                            }),
                        ),
                    }),
                );
            }),
        );
    }

    @Action(UpdateLogItem)
    updateLogItem(ctx: StateContext<LogbookStateModel>, action: UpdateLogItem) {
        return this.logbookService.updateLogItem(action.logItemId, action.logItem).pipe(
            tap((logItem) => {
                ctx.setState(
                    patch<LogbookStateModel>({
                        logItems: updateItem<LogItemsForObject>(
                            (logItemsForObject) => logItemsForObject.logItems.some((logItem) => logItem.id === action.logItemId),
                            patch<LogItemsForObject>({
                                logItems: updateItem((logItem) => logItem.id === action.logItemId, logItem),
                            }),
                        ),
                    }),
                );
            }),
        );
    }
}
