import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { append, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { tap } from 'rxjs/operators';

import { ContactPerson } from '../models/ContactPerson';
import { ROUTES } from '../models/ROUTES';
import { CreateNotification } from '../notifications/notifications.actions';
import { AddUndefinedObjectTagsToObject } from '../shared/global-tags/global-tags.actions';
import { AddContactPerson, DeleteContactPerson, FetchContacts, UpdateContactPerson } from './contacts.actions';
import { ContactsService } from './contacts.service';

export interface ContactStateModel {
    contacts: ContactPerson[];
    currentContactPerson: ContactPerson;
}

@State<ContactStateModel>({
    name: 'contact',
    defaults: {
        contacts: null,
        currentContactPerson: null,
    },
})
@Injectable()
export class ContactState {
    constructor(private contactsService: ContactsService, private store: Store) {}

    @Selector()
    static Contacts(state: ContactStateModel): ContactPerson[] {
        return state?.contacts;
    }

    @Action(FetchContacts)
    fetchContacts(ctx: StateContext<ContactStateModel>) {
        return this.contactsService.getAllContactPersons().pipe(
            tap((contacts) => {
                contacts.forEach((c) => (c.fullName = `${c.firstName} ${c.lastName}`));
                ctx.setState(
                    patch<ContactStateModel>({
                        contacts: contacts,
                    }),
                );
            }),
        );
    }

    @Action(AddContactPerson)
    addContactPerson(ctx: StateContext<ContactStateModel>, action: AddContactPerson) {
        return this.contactsService.addContact(action.contactperson).pipe(
            tap((contact) => {
                contact.fullName = `${contact.firstName} ${contact.lastName}`;
                ctx.setState(
                    patch<ContactStateModel>({
                        contacts: append([contact]),
                        currentContactPerson: contact,
                    }),
                );
                this.store.dispatch(new AddUndefinedObjectTagsToObject(contact.uuid));
                this.store.dispatch(
                    new CreateNotification({
                        message: `A contact with name <strong>${contact.firstName} ${contact.lastName}</strong> is added`,
                        route: ROUTES.CONTACTS.route,
                    }),
                );
            }),
        );
    }

    @Action(UpdateContactPerson)
    updateContactPerson(ctx: StateContext<ContactStateModel>, action: UpdateContactPerson) {
        return this.contactsService.updateContactPerson(action.contactperson).pipe(
            tap((contact) => {
                ctx.setState(
                    patch<ContactStateModel>({
                        contacts: updateItem<ContactPerson>((c) => c.uuid === contact.uuid, contact),
                    }),
                );
                this.store.dispatch(
                    new CreateNotification({
                        message: `A contact with name <strong>${contact.firstName} ${contact.lastName}</strong> is updated`,
                        route: ROUTES.CONTACTS.route,
                    }),
                );
            }),
        );
    }

    @Action(DeleteContactPerson)
    deleteContactPerson(ctx: StateContext<ContactStateModel>, action: DeleteContactPerson) {
        return this.contactsService.deleteContactPerson(action.contactPersonUuid).pipe(
            tap(() => {
                const contact: ContactPerson = ctx.getState().contacts.find((contact) => contact.uuid === action.contactPersonUuid);
                ctx.setState(
                    patch({
                        contacts: removeItem<ContactPerson>((c) => c.uuid === action.contactPersonUuid),
                    }),
                );
                this.store.dispatch(
                    new CreateNotification({
                        message: `A contact with name <strong>${contact.firstName} ${contact.lastName}</strong> is deleted`,
                        route: ROUTES.CONTACTS.route,
                    }),
                );
            }),
        );
    }
}
