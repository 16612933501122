import { Component, Input, OnDestroy } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';

import { ExactEditRelationModalComponent } from '../exact-edit-relation-modal/exact-edit-relation-modal.component';
import { DeleteRelation } from '../exact.actions';

@Component({
    selector: 'app-exact-relations-list',
    templateUrl: './exact-relations-list.component.html',
    styleUrls: ['./exact-relations-list.component.scss'],
})
export class ExactRelationsListComponent implements OnDestroy {
    private subscriptions = new Subscription();

    @Input() relations: any[];
    @Input() filter: string;

    constructor(private modalService: NgbModal, private store: Store, private toastr: ToastrService) {}

    updateRelation(relation: any) {
        const modalRef = this.modalService.open(ExactEditRelationModalComponent);
        modalRef.componentInstance.relation = relation;
    }

    deleteRelation(relation: any) {
        this.subscriptions.add(
            this.store.dispatch(new DeleteRelation(relation.ID)).subscribe((res) => {
                if (res) {
                    this.toastr.success('Successfully added new relation.');
                } else {
                    this.toastr.error('Oops, something went wrong!');
                }
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
