import { OverheadCategory } from '../models/OverheadCategory';
import { YukiCredential } from '../models/yuki/YukiCredential';
import { YukiSettings } from '../models/yuki/YukiSettings';
import { InvoiceDTO } from './create-new-invoice-modal/create-new-invoice-modal.component';
import { YukiDocumentDTO } from './yuki-detail-modal/yuki-detail-modal.component';

export class FetchFolders {
    static readonly type = '[YukiFolders] fetch yukiFolders';
}

export class SelectFolder {
    static readonly type = 'YukiFolder select yukiFolder';

    constructor(public folderId: number) {}
}

export class FetchAllDocuments {
    static readonly type = '[YukiDocuments] fetchall yukiDocuments';

    constructor(public folderId: number) {}
}

export class FetchDocuments {
    static readonly type = '[YukiDocuments] fetch yukiDocuments';

    constructor(public folderId: number, public pageNumber: number = 0, public recordAmount: number = 10) {}
}

export class FetchDocumentById {
    static readonly type = '[YukiDocument] fetch yukiDocument by id';

    constructor(public id: string) {}
}

export class LoadDocuments {
    static readonly type = '[YukiDocuments] load yukiDocuments';

    constructor(public folderId: number) {}
}

export class FetchFile {
    static readonly type = '[YukiFile] Fetch YukiFile for YukiDocument';

    constructor(public yukiId: string) {}
}

export class FetchNextFile {
    static readonly type = '[YukiFile] Fetch Next YukiFile for YukiDocument';

    constructor(public yukiId: string) {}
}

export class FetchPreviousFile {
    static readonly type = '[YukiFile] Fetch Previous YukiFile for YukiDocument';

    constructor(public yukiId: string) {}
}

export class SwitchNextFileToFile {
    static readonly type = '[YukiFile] Switch Next YukiFile to File';
}

export class SwitchPreviousFileToFile {
    static readonly type = '[YukiFile] Switch Previous YukiFile to File';
}

export class RefreshFile {
    static readonly type = '[YukiFile] Refresh YukiFile for YukiDocument';

    constructor(public yukiId: string) {}
}

export class FetchYukiSettings {
    static readonly type = '[YukiSettings] Fetch YukiSettings';
}

export class UpdateYukiSettings {
    static readonly type = '[YukiSettings] Update YukiSettings';

    constructor(public uuid: string, public settings: YukiSettings) {}
}

export class UpdateVATOfYukiDocument {
    static readonly type = '[YukiDocument] Update VAT of YukiDocument';

    constructor(public uuid: string, public body: YukiDocumentDTO) {}
}

export class VerifyYukiDocument {
    static readonly type = '[void] Verify YukiDocument';

    constructor(public uuid: string) {}
}

export class SyncDocuments {
    static readonly type = '[YukiDocument] sync YukiDocuments';
}

export class SetCredentials {
    static readonly type = '[YukiCredentials] set Credentials';

    constructor(public yukiCredential: YukiCredential) {}
}

export class FetchCredentials {
    static readonly type = '[YukiCredentials] fetch Credentials';
}

export class UpdateVisibilityYukiDocument {
    static readonly type = '[YukiDocument] update visibility Yuki Document';

    constructor(public yukiId: string) {}
}

export class FlagYukiDocument {
    static readonly type = '[YukiDocument] flag Yuki Document';

    constructor(public yukiId: string) {}
}

export class RefreshDocumentFromYukiAPI {
    static readonly type = '[YukiDocument] refresh document from Yuki API';

    constructor(public yukiId: string) {}
}

export class DeleteDocument {
    static readonly type = '[YukiDocumet] delete Yuki Document';

    constructor(public yukiId: string) {}
}

export class GetOverheadCategoryById {
    static readonly type = '[OverheadCategory] fetch OverheadCategory by id';

    constructor(public id: string) {}
}

export class GetAllOverheadCategories {
    static readonly type = '[OverheadCategory] fetch OverheadCategories';
}

export class AddOverheadCategories {
    static readonly type = '[OverheadCategory] add OverheadCategories';

    constructor(public overheadCategories: OverheadCategory[]) {}
}

export class DeleteOverheadCategory {
    static readonly type = '[OverheadCategory] delete OverheadCategories';

    constructor(public id: string) {}
}

export class UpdateOverheadCategory {
    static readonly type = '[OverheadCategory] update OverheadCategory';

    constructor(public overheadCategory: OverheadCategory) {}
}

export class GetRemovedYukiDocuments {
    static readonly type = '[YukiDocument[]] get all yuki documents that are no longer in yuki itself';
}

export class CheckRemovedYukiDocuments {
    static readonly type = '[YukiDocument[]] check all yuki documents and archive the docs that are no longer available';
}

export class GetMappedContacts {
    static readonly type = '[ContactMapper] get all mapped contacts';
}

export class AddMappedContact {
    static readonly type = '[ContactMapper] add new mapped contact';

    constructor(public yukiContact: string, public resumoContactId: string) {}
}

export class MapContactsForAllExistingDocuments {
    static readonly type = '[ContactMapper] map contacts for all existing documents';
}

export class DeleteMappedContact {
    static readonly type = '[ContactMapper] delete mapped contact';
    constructor(public mappedContactId: string) {}
}

export class CreateNewInvoice {
    static readonly type = '[Invoice] create new invoice';
    constructor(public invoiceDTO: InvoiceDTO) {}
}
