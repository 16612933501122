import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { AuthenticationService } from '../authentication/authentication.service';
import { Applicant } from '../models/Applicants/Applicant';
import { ApplicantLane } from '../models/Applicants/ApplicantLane';

@Injectable({
    providedIn: 'root',
})
export class ApplicantLaneService {
    baseUrl: string = environment.baseUrl + '/applicant/lanes';
    httpOptions = {};

    constructor(private httpClient: HttpClient, private authenticationService: AuthenticationService) {
        this.httpOptions = authenticationService.getHeaders();
    }

    getAllApplicantLanes(): Observable<ApplicantLane[]> {
        return this.httpClient.get<ApplicantLane[]>(this.baseUrl, this.httpOptions);
    }

    addLane(applicantLane: ApplicantLane): Observable<ApplicantLane> {
        return this.httpClient.post<ApplicantLane>(this.baseUrl, applicantLane, this.httpOptions);
    }

    updateLane(laneUuid: string, applicantLane: ApplicantLane): Observable<ApplicantLane> {
        return this.httpClient.put<ApplicantLane>(this.baseUrl + '/' + laneUuid, applicantLane, this.httpOptions);
    }

    moveLaneSequence(laneUuid: string, position: number): Observable<ApplicantLane[]> {
        return this.httpClient.put<ApplicantLane[]>(this.baseUrl + `/move/lane/${laneUuid}/${position}`, {}, this.httpOptions);
    }

    moveApplicant(laneUuid: string, applicantUuid: string, reason?: string, reasonUuid?: string) {
        return this.httpClient.put<Applicant>(
            this.baseUrl + `/move/applicant/${laneUuid}/${applicantUuid}${reasonUuid ? '?reasonUuid=' + reasonUuid : ''}`,
            reason ? reason : undefined,
            this.httpOptions,
        );
    }

    removeLane(laneUuid: string): Observable<void> {
        return this.httpClient.delete<void>(this.baseUrl + `/${laneUuid}`, this.httpOptions);
    }
}
