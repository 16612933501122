import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { CompanyState } from 'src/app/companies/company.state';
import { Unit } from 'src/app/models/Unit';

import { UnitAddModalComponent } from '../unit-add-modal/unit-add-modal.component';
import { FetchUnits } from '../unit.actions';
import { UnitService } from '../unit.service';
import { UnitState } from '../unit.state';

@Component({
    selector: 'app-units-overview',
    templateUrl: './units-overview.component.html',
    styleUrls: ['./units-overview.component.scss'],
})
export class UnitsOverviewComponent implements OnInit {
    @Select(UnitState.Units) allUnits$: Observable<Unit[]>;
    @Select(CompanyState.companyUuid) companyUuid$: Observable<string>;

    constructor(
        private unitService: UnitService,
        private store: Store,
        private modalService: NgbModal,
        private titleService: Title,
        private logger: NGXLogger,
    ) {
        this.titleService.setTitle('Units overview');
    }

    ngOnInit(): void {
        this.logger.debug('Fetching units');
        this.store.dispatch(new FetchUnits());
    }

    openUnitAddModal(unit?: Unit) {
        this.logger.debug('opening unitAddModal');
        const modalRef = this.modalService.open(UnitAddModalComponent, {
            windowClass: 'modal-prompt',
        });
        modalRef.componentInstance.unit = unit;
        unit ? (modalRef.componentInstance.isUpdate = true) : (modalRef.componentInstance.isUpdate = false);
    }
    changeSelectedCategory($event) {}
}
