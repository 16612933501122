import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '../authentication/auth.guard';
import { CompanyGuard } from '../companies/company.guard';
import { UnitsOverviewComponent } from './units-overview/units-overview.component';

export const routes: Routes = [
    { path: '', redirectTo: 'inventory/units', pathMatch: 'full' },
    {
        path: 'inventory/units',
        component: UnitsOverviewComponent,
        canActivate: [AuthGuard, CompanyGuard],
    },
];

export const routing: ModuleWithProviders<any> = RouterModule.forChild(routes);
