<div class="module__logo">
    <img src="../../../assets/img/visma-yuki-logo.png" />
</div>

<div>
    <form
        [formGroup]="form"
        (ngSubmit)="onSubmit(form)">
        <div class="form-group">
            <div class="row">
                <div class="col-md-5">
                    <label for="administration">Administration name</label>
                </div>
                <div class="col-md-5">
                    <label for="key">API key</label>
                </div>
                <div class="col-md-2"></div>
                <div class="col-md-5">
                    <div class="form-group">
                        <input
                            type="text"
                            id="administration"
                            formControlName="administration"
                            placeholder="Administration name" />
                    </div>
                </div>
                <div class="col-md-5">
                    <div class="form-group">
                        <password-input
                            [placeholder]="'Yuki API key'"
                            [showLabel]="false"
                            formControlName="apiKey">
                        </password-input>
                    </div>
                    <small
                        class="form-error"
                        *ngIf="!form.get('apiKey').valid && form.get('apiKey').touched"
                        >The key is not valid</small
                    >
                </div>
                <div class="col-md-2">
                    <input
                        type="submit"
                        value="Save key"
                        [disabled]="!form.valid"
                        class="button button--primary w-100" />
                </div>
                <div class="col-md-12">
                    <div class="form-group">
                        <small class="help-text">You can find your key at Yuki &rsaquo; Settings &rsaquo; General &rsaquo; Integrations</small>
                    </div>
                </div>
                <div class="col-md-6">
                    <button
                        type="button"
                        [disabled]="form.get('apiKey').value === ''"
                        class="button button--danger w-100">
                        Remove key
                    </button>
                </div>
                <div class="col-md-6">
                    <button
                        type="button"
                        (click)="forceSync()"
                        class="button button--primary w-100">
                        <span *ngIf="!isSyncing; else loading">Synchronize since start date</span>
                        <ng-template #loading><app-loading-message></app-loading-message></ng-template>
                    </button>
                </div>
            </div>
            <small *ngIf="!form.get('apiKey').touched"></small>
        </div>
    </form>
</div>
