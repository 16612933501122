import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { differenceInDays, differenceInMonths, format, sub } from 'date-fns';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';

import { EmployeesAddModalComponent } from '../../employees/employees-add-modal/employees-add-modal.component';
import { FetchSimpleInformationForAllEmployees } from '../../employees/employees.actions';
import { EmployeeState } from '../../employees/employees.state';
import { BUDGETREPEAT } from '../../models/BUDGETREPEAT';
import Budget from '../../models/Budget';
import BudgetTemplate from '../../models/BudgetTemplate';
import { Employee } from '../../models/Employee';
import EmployeeWithBudgets from '../../models/EmployeeWithBudgets';
import { ConfirmationModalComponent } from '../../shared/confirmation-modal/confirmation-modal.component';
import { AddBudgettemplateModalComponent } from '../add-budgettemplate-modal/add-budgettemplate-modal.component';
import { AddBudget, ArchiveBudget, FetchBudgetTemplates, FetchEmployeesWithBudgets, UpdateBudget } from '../budget.actions';
import { BudgetState } from '../budget.state';

@Component({
    selector: 'app-add-budget-modal',
    templateUrl: './add-budget-modal.component.html',
    styleUrls: ['./add-budget-modal.component.scss'],
})
export class AddBudgetModalComponent implements OnInit, OnDestroy {
    @Select(BudgetState.BudgetTemplates) budgetTemplates$: Observable<BudgetTemplate[]>;
    @Select(EmployeeState.EmployeesSimpleInformation) employees$: Observable<Employee[]>;
    @Select(BudgetState.EmployeesWithBudgets) employeesWithBudgets$: Observable<EmployeeWithBudgets[]>;
    @Input() budget;
    @Input() type: string;
    @Input() linkedEmployee: Employee;
    budgetTemplates: BudgetTemplate[] = [];
    employees: Employee[] = [];
    employeesWithBudgets: EmployeeWithBudgets[] = [];
    filteredEmployees: Employee[] = [];
    allCheck = false;
    checkBoxes: any[] = [];
    budgetForm: FormGroup;
    displayedColumns: string[] = ['checkbox', 'firstName', 'lastName'];
    dataSource: MatTableDataSource<Employee>;
    @ViewChild(MatPaginator) paginator!: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    nameFilter = '';
    selectedBudgetTemplate: BudgetTemplate;
    budgetRepeats: BUDGETREPEAT[] = [];
    private subscriptions = new Subscription();

    constructor(
        public activeModal: NgbActiveModal,
        private fb: FormBuilder,
        private store: Store,
        private toastr: ToastrService,
        private logger: NGXLogger,
        private modalService: NgbModal,
        private changeDetectorRef: ChangeDetectorRef,
    ) {}

    ngOnInit(): void {
        this.createBudgetForm();
        this.createMatTable();
        this.fetchData();
        this.seedRecurrenceRules();
    }

    updateEndPeriod(selectedBudgetTemplate: BudgetTemplate = this.budgetForm.get('budgetTemplate').value) {
        const endDate: string = this.budgetForm.get('startDate').value;
        const splitEndDate: string[] = endDate.split('-');
        const splitLeadTime: string[] = selectedBudgetTemplate.leadTime.split('-');
        for (let i = 0; i < splitEndDate.length; i++) {
            splitEndDate[i] = `${parseInt(splitEndDate[i]) + parseInt(splitLeadTime[i])}`;
        }
        this.budgetForm
            .get('endDate')
            .setValue(`${String(splitEndDate[0]).padStart(4, '0')}-${String(splitEndDate[1]).padStart(2, '0')}-${String(splitEndDate[2]).padStart(2, '0')}`);
    }

    addBudget() {
        const budgetTemplate: BudgetTemplate = this.budgetForm.get('budgetTemplate').value;

        const notificationDate = sub(new Date(this.budgetForm.get('endDate').value), {
            months: this.budgetForm.get('notificationDateMonths').value,
            days: this.budgetForm.get('notificationDateDays').value,
        });

        const budget: Budget = {
            uuid: null,
            budgetType: budgetTemplate.budgetType,
            totalSpend: this.budgetForm.get('totalSpend').value,
            startDate: this.budgetForm.get('startDate').value,
            endDate: this.budgetForm.get('endDate').value,
            archived: false,
            budgetExpenditures: [],
            linkedEmployee: this.linkedEmployee,
            notificationDate: format(notificationDate, 'yyyy-MM-dd'),
            sendNotification: this.budgetForm.get('sendNotification').value,
            recurring: this.budgetForm.value.recurring,
            repeat: this.budgetForm.value.repeat,
            useExpendituresForFinancials: this.budgetForm.get('useExpendituresForFinancials').value === 'expenditures',
        };
        const linkedEmployees: string[] = this.checkBoxes.filter((cb) => cb.value).map((cb) => cb.employee);

        if (this.budget) {
            budget.uuid = this.budget.uuid;
            this.subscriptions.add(
                this.store.dispatch(new UpdateBudget(budget, linkedEmployees)).subscribe(
                    (res) => {
                        this.toastr.success('Budget successfully updated');
                        this.activeModal.close();
                    },
                    (error) => {
                        this.logger.error('Failed to update budget');
                        this.toastr.error('Oops, something went wrong, Please try again later...');
                    },
                ),
            );
        } else {
            this.subscriptions.add(
                this.store.dispatch(new AddBudget(budget, linkedEmployees)).subscribe(
                    (res) => {
                        this.toastr.success('Budget successfully assigned');
                        this.activeModal.close();
                    },
                    (error) => {
                        this.logger.error('Failed to assign budget');
                        this.toastr.error('Oops, something went wrong, Please try again later...');
                    },
                ),
            );
        }
    }

    checkAll() {
        this.allCheck = !this.allCheck;
        this.checkBoxes.forEach((el) => {
            el.value = this.allCheck;
        });
    }

    noCheckedEmployees() {
        for (let i = 0; i < this.checkBoxes.length; i++) {
            if (this.checkBoxes[i].value) {
                return false;
            }
        }
        return true;
    }

    getCurrentCheck(employee: Employee) {
        if (this.checkBoxes.find((check) => check.employee === employee.uuid) != undefined) {
            return this.checkBoxes.find((check) => check.employee === employee.uuid).value;
        } else {
            const check = { employee: employee.uuid, value: false };
            this.checkBoxes.push(check);
            return check.value;
        }
    }

    checkChange(employee: Employee) {
        this.checkBoxes.find((check) => check.employee === employee.uuid).value = !this.checkBoxes.find((check) => check.employee === employee.uuid).value;
        if (this.noCheckedEmployees()) {
            this.allCheck = false;
        }

        const foundEmployee: EmployeeWithBudgets = this.employeesWithBudgets.find((e) => employee.uuid === e.uuid);

        if (foundEmployee && foundEmployee.budgets && foundEmployee.budgets.length > 0) {
            foundEmployee.budgets.forEach((budget) => {
                if (
                    this.selectedBudgetTemplate &&
                    budget.budgetType === this.selectedBudgetTemplate.budgetType &&
                    !budget.archived &&
                    this.checkBoxes.find((cb) => cb.employee === employee.uuid).value
                ) {
                    this.showArchiveModal(employee, budget);
                }
            });
        }
    }

    openCreateBudgetTemplateModal() {
        this.logger.debug('Opening addBudgetTemplateModal');
        const modalRef = this.modalService.open(AddBudgettemplateModalComponent, {
            windowClass: 'modal-prompt',
            animation: false,
        });
        modalRef.componentInstance.type = 'Create budget template';
    }

    filterEmployees(value: string) {
        this.nameFilter = value;
        this.dataSource.filter = JSON.stringify({
            name: value,
            selectedBudgetTemplate: this.selectedBudgetTemplate,
            employeesWithBudgets: this.employeesWithBudgets,
        });
    }

    selectEvent($event: BudgetTemplate) {
        this.budgetForm.get('totalSpend').setValue($event.totalSpend);
        this.updateEndPeriod($event);

        this.budgetForm.get('recurring').setValue($event.recurring);
        this.budgetForm.get('repeat').setValue($event.repeat);
        this.budgetForm.get('sendNotification').setValue($event.sendNotification);
        this.budgetForm.get('useExpendituresForFinancials').setValue($event.useExpendituresForFinancials ? 'expenditures' : 'budgeted');

        this.selectedBudgetTemplate = $event;
        if (!this.budget) {
            this.checkBoxes.forEach((cb) => {
                this.employeesWithBudgets.forEach((employee) => {
                    if (
                        employee.uuid === cb.employee &&
                        employee.budgets.find((budget) => budget.budgetType === this.selectedBudgetTemplate.budgetType) &&
                        !employee.budgets.find((budget) => budget.budgetType === this.selectedBudgetTemplate.budgetType).archived
                    ) {
                        cb.value = false;
                    }
                });
            });
            this.employeesWithBudgets.forEach((employee) => {
                employee.budgets.forEach((budget) => {
                    if (
                        budget.budgetType === this.selectedBudgetTemplate.budgetType &&
                        !budget.archived &&
                        this.checkBoxes.find((cb) => cb.employee === employee.uuid).value
                    ) {
                        this.showArchiveModal(employee, budget);
                    }
                });
            });
        }

        if (this.selectedBudgetTemplate.timeBeforeNotification) {
            const splitNotificationTime = this.selectedBudgetTemplate.timeBeforeNotification.split('-');
            this.budgetForm.get('notificationDateMonths').setValue(splitNotificationTime[1]);
            this.budgetForm.get('notificationDateDays').setValue(splitNotificationTime[2]);
        }

        this.dataSource.filter = JSON.stringify({
            name: this.nameFilter,
            selectedBudgetTemplate: this.selectedBudgetTemplate,
            employeesWithBudgets: this.employeesWithBudgets,
        });
    }

    openAddEmployeeModal() {
        this.modalService.open(EmployeesAddModalComponent, { windowClass: 'modal-pane', animation: false });
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    private showArchiveModal(employee: any, budget: Budget): void {
        const modalRef = this.modalService.open(ConfirmationModalComponent, {
            windowClass: 'modal-prompt',
            animation: false,
        });
        modalRef.componentInstance.type = 'YesOrNo';
        modalRef.componentInstance.title = 'Archive running budget';
        modalRef.componentInstance.htmlMessage = `<p><strong>${employee.firstName.trim()} ${employee.lastName.trim()}</strong> already contains a running budget of type <strong>${
            this.selectedBudgetTemplate.budgetType
        }</strong>.</p><p>Do you want to archive the previous budget?</p>`;

        this.subscriptions.add(
            modalRef.componentInstance.closeEvent.subscribe((value) => {
                if (value) {
                    this.subscriptions.add(this.store.dispatch(new ArchiveBudget(budget.uuid)).subscribe());
                }
            }),
        );
    }

    private fetchData() {
        this.subscriptions.add(
            this.budgetTemplates$.subscribe((b) => {
                if (!b) {
                    this.logger.debug('Fetching BudgetTemplates');
                    this.store.dispatch(new FetchBudgetTemplates());
                    return;
                }

                this.budgetTemplates = b;
                if (this.budget) {
                    const difInMonths = differenceInMonths(new Date(this.budget.endDate), new Date(this.budget.notificationDate));
                    const difInDays = differenceInDays(sub(new Date(this.budget.endDate), { months: difInMonths }), new Date(this.budget.notificationDate));
                    let budgetTemplate: BudgetTemplate = this.budgetTemplates.find((bt) => bt.budgetType == this.budget.budgetType);
                    if (!budgetTemplate) {
                        budgetTemplate = {
                            budgetType: this.budget.budgetType,
                            totalSpend: this.budget.totalSpend,
                            recurring: this.budget.recurring,
                            sendNotification: this.budget.sendNotification,
                            leadTime: '01-00-00',
                            timeBeforeNotification: '00-06-00',
                        };
                    }
                    this.budgetForm = this.fb.group({
                        budgetTemplate: [budgetTemplate, [Validators.required]],
                        totalSpend: [this.budget.totalSpend, [Validators.required, Validators.min(0)]],
                        startDate: [`${format(new Date(this.budget.startDate), 'yyyy-MM-dd')}`, [Validators.required]],
                        endDate: [`${format(new Date(this.budget.endDate), 'yyyy-MM-dd')}`, [Validators.required]],
                        notificationDateMonths: [difInMonths],
                        notificationDateDays: [difInDays],
                        repeat: this.budget.repeat,
                        sendNotification: this.budget.sendNotification,
                        recurring: this.budget.recurring,
                        useExpendituresForFinancials: this.budget.useExpendituresForFinancials ? 'expenditures' : 'budgeted',
                    });
                }
            }),
        );
        this.subscriptions.add(
            this.employees$.subscribe((e) => {
                if (!e) {
                    this.logger.debug('Fetching Employees');
                    this.store.dispatch(new FetchSimpleInformationForAllEmployees());
                    return;
                }

                this.employees = e;
                this.changeDetectorRef.detectChanges();

                this.dataSource.data = e;
                this.dataSource.sort = this.sort;
                this.dataSource.paginator = this.paginator;

                this.checkBoxes = [];
                this.employees = e;
                this.filteredEmployees = e;

                e.forEach((e) => {
                    const check = {
                        employee: e.uuid,
                        value: false,
                    };
                    this.checkBoxes.push(check);
                });
                if (this.budget && this.linkedEmployee) {
                    this.checkBoxes.forEach((cb) => {
                        if (this.linkedEmployee.uuid == cb.employee) {
                            cb.value = true;
                        }
                    });
                } else if (this.budget) {
                    this.checkBoxes.forEach((cb) => {
                        if (this.budget.linkedEmployee.uuid == cb.employee) {
                            cb.value = true;
                        }
                    });
                } else if (this.linkedEmployee) {
                    this.checkBoxes.forEach((cb) => {
                        if (this.linkedEmployee.uuid == cb.employee) {
                            cb.value = true;
                        }
                    });
                }
            }),
        );
        this.subscriptions.add(
            this.employeesWithBudgets$.subscribe((e) => {
                if (!e) {
                    this.logger.debug('Fetching EmployeesWithBudgets');
                    this.store.dispatch(new FetchEmployeesWithBudgets());
                    return;
                }

                this.employeesWithBudgets = e;
                this.dataSource.filter = JSON.stringify({
                    name: this.nameFilter,
                    selectedBudgetTemplate: this.selectedBudgetTemplate,
                    employeesWithBudgets: this.employeesWithBudgets,
                });
            }),
        );
    }

    private createMatTable() {
        this.dataSource = new MatTableDataSource<Employee>();

        this.dataSource.paginator = this.paginator;
        this.dataSource.filterPredicate = function (data, filter) {
            const filterObj = JSON.parse(filter);

            if (filterObj.selectedBudgetTemplate) {
                filterObj.employeesWithBudgets.forEach((employee) => {
                    if (employee.uuid === data.uuid) {
                        employee.budgets.forEach((budget) => {
                            if (budget.budgetType === filterObj.selectedBudgetTemplate.budgetType && !budget.archived) {
                                return;
                            }
                        });
                    }
                });
            }
            return data.firstName.toLowerCase().includes(filterObj.name) || data.lastName.toLowerCase().includes(filterObj.name);
        };
    }

    private createBudgetForm(): void {
        const today: Date = new Date();
        if (!this.budget) {
            this.budgetForm = this.fb.group({
                budgetTemplate: ['', [Validators.required]],
                totalSpend: ['', [Validators.required, Validators.min(0)]],
                startDate: [
                    `${String(today.getFullYear()).padStart(4, '0')}-${String(today.getMonth() + 1).padStart(2, '0')}-${String(today.getDate()).padStart(
                        2,
                        '0',
                    )}`,
                    [Validators.required],
                ],
                endDate: [
                    `${String(today.getFullYear()).padStart(4, '0')}-${String(today.getMonth() + 1).padStart(2, '0')}-${String(today.getDate()).padStart(
                        2,
                        '0',
                    )}`,
                    [Validators.required],
                ],
                notificationDateMonths: ['6'],
                notificationDateDays: ['0'],
                repeat: '',
                recurring: '',
                sendNotification: '',
                useExpendituresForFinancials: ['', [Validators.required]],
            });
        }
    }

    private seedRecurrenceRules() {
        this.budgetRepeats = Object.values(BUDGETREPEAT);
    }
}
