<app-app-holder>
    <app-navbar></app-navbar>

    <app-sidebar>
        <app-settings-subnav></app-settings-subnav>
    </app-sidebar>

    <app-content>
        <div *ngIf="!isLoading">
            <div *ngIf="projectStatusList?.length != 0">
                <div class="app__title">
                    <h1>Project Status</h1>
                    <span>
                        <a
                            (click)="openAddStatusModal()"
                            class="button button--primary"
                            >Add status</a
                        >
                    </span>
                </div>

                <app-company-project-status-list
                    *ngIf="projectStatusList"
                    [companyProjectStatusList]="projectStatusList">
                </app-company-project-status-list>
            </div>

            <app-message-empty
                *ngIf="projectStatusList?.length == 0"
                [title]="'No status added..'"
                [subtitle]="'Whoops, you haven\'t added any status yet. Start now by clicking the button below.'"
                [icon]="'../../assets/img/open-box.png'">
                <a
                    (click)="openAddStatusModal()"
                    class="button button--primary"
                    >Add status</a
                >
            </app-message-empty>
        </div>
    </app-content>

    <ng-template *ngIf="isLoading">
        <app-loading-message></app-loading-message>
    </ng-template>
</app-app-holder>
