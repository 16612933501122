<form
    [formGroup]="customRoleFormGroup"
    (ngSubmit)="addCustomRole()"
    class="modal-wrapper">
    <div class="modal-header">
        <h2
            *ngIf="!update"
            class="modal-title">
            New Role
        </h2>
        <h2
            *ngIf="update"
            class="modal-title">
            Update Role
        </h2>
        <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="activeModal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div
        class="modal-body"
        *ngIf="loading">
        <app-loading-message></app-loading-message>
    </div>

    <div
        class="modal-body"
        *ngIf="!loading">
        <div class="row">
            <div class="col">
                <div class="form-group required m-b-2">
                    <input
                        type="text"
                        placeholder="Rolename"
                        formControlName="name"
                        (focus)="hideErrorMessage()"
                        trim="blur"
                        #focussed />
                    <span
                        class="form-error"
                        *ngIf="customRoleFormGroup.controls.name.errors && inputIsWrong"
                        >Invalid custom role</span
                    >
                </div>
            </div>
        </div>

        <span
            class="form-error"
            *ngIf="inputIsWrong"
            >{{ errorMessage }}</span
        >

        <tr
            *ngFor="let module of allCompanyModules | filterBy: ['canActivate']:'true' | filterBy: ['moduleType']:'MODULE'"
            class="list__item">
            <span class="list__item__icon"
                ><span
                    (click)="addModuleToRole(module)"
                    class="toggle"
                    [ngClass]="setToggleActive(module)"></span
            ></span>
            <span class="list__item__content m-l-2">
                <span class="row">
                    <td>{{ getTitle(module) }}</td>
                    <div class="w-100"></div>
                    <small>{{ getDescription(module) }}</small>
                </span>

                <span
                    class="row"
                    *ngIf="setToggleActive(module) === 'toggle--active'">
                    <label
                        *ngFor="let permission of permissions"
                        class="col-6">
                        <input
                            type="checkbox"
                            [checked]="checkIfPermissionPresent(module, permission)"
                            (click)="addPermissionToModule(module, permission)" />
                        {{ permission.name }}
                    </label>
                </span>
            </span>
        </tr>

        <tr
            *ngFor="let integration of allCompanyModules | filterBy: ['canActivate']:'true' | filterBy: ['moduleType']:'INTEGRATION'"
            class="list__item">
            <span class="list__item__icon"
                ><span
                    (click)="addModuleToRole(integration)"
                    class="toggle"
                    [ngClass]="setToggleActive(integration)"></span
            ></span>
            <span class="list__item__content m-l-2">
                <span class="row">
                    <td>{{ getTitle(integration) }}</td>
                    <div class="w-100"></div>
                    <small>{{ getDescription(integration) }}</small>
                </span>

                <span
                    class="row"
                    *ngIf="setToggleActive(integration) === 'toggle--active'">
                    <label
                        *ngFor="let permission of permissions"
                        class="col-6">
                        <input
                            type="checkbox"
                            [checked]="checkIfPermissionPresent(integration, permission)"
                            (click)="addPermissionToModule(integration, permission)" />
                        {{ permission.name }}
                    </label>
                </span>
            </span>
        </tr>
    </div>

    <div class="modal-footer">
        <button
            type="button"
            class="button button--outline"
            (click)="activeModal.close()">
            Close
        </button>
        <button
            type="submit"
            *ngIf="!update"
            [ngClass]="{ 'button--loading': loading }"
            class="button button--primary">
            Add role
        </button>
        <button
            type="submit"
            *ngIf="update"
            [ngClass]="{ 'button--loading': loading }"
            class="button button--primary">
            Update role
        </button>
    </div>
</form>
