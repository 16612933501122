import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';
import { FetchCompany } from 'src/app/companies/company.actions';
import { CompanyState } from 'src/app/companies/company.state';
import { Module } from 'src/app/models/Module';

@Component({
    selector: 'app-forbidden-page',
    templateUrl: './forbidden-page.component.html',
    styleUrls: ['./forbidden-page.component.scss'],
})
export class ForbiddenPageComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();
    modules: Module[];
    constructor(private store: Store) {}

    ngOnInit(): void {
        this.store.dispatch(new FetchCompany());
        this.subscriptions.add(
            this.store.select(CompanyState.company).subscribe((company) => {
                company?.modules ? (this.modules = company.modules) : (this.modules = null);
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
