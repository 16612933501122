<div class="modal-header">
    <h2
        *ngIf="partner"
        class="modal-title"
        id="exampleModalLabel">
        {{ partner.title }}
    </h2>
    <button
        (click)="activeModal.dismiss()"
        aria-label="Close"
        class="close"
        type="button">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<nav
    #nav="ngbNav"
    class="tabs__modal"
    ngbNav>
    <ng-container ngbNavItem>
        <a ngbNavLink>Information</a>
        <ng-template ngbNavContent>
            <div *ngIf="!partnerHasInformation()">
                <app-message-empty
                    [icon]="'../../assets/img/open-box.png'"
                    [subtitle]="'Start adding information here.'"
                    [title]="'This company has no information yet...'">
                    <a
                        (click)="openPartnerUpdateModal()"
                        *appIsPermitted="{ module: 'PARTNERS', operator: 'AND', actions: ['EDIT'] }"
                        class="button button--primary"
                        >Add company information</a
                    >
                </app-message-empty>
            </div>
            <ul
                *ngIf="partnerHasInformation()"
                class="list__specs">
                <ng-container *ngIf="partner.contactInformation">
                    <li *ngIf="partner.contactInformation.phoneNumber">
                        <label>Phonenumber</label><app-pill-telephone [contactPhonenumber]="partner.contactInformation.phoneNumber"></app-pill-telephone>
                    </li>
                    <li *ngIf="partner.contactInformation.email">
                        <label>Emailaddress</label><app-pill-mailto [mailtoEmail]="partner.contactInformation.email"></app-pill-mailto>
                    </li>
                    <li *ngIf="partner.contactInformation.website">
                        <label>Website</label><app-pill-url [websiteUrl]="partner.contactInformation.website"></app-pill-url>
                    </li>
                    <li *ngIf="partner.contactInformation.linkedIn">
                        <label>LinkedIn</label><app-pill-linkedin [linkedinUrl]="partner.contactInformation.linkedIn"></app-pill-linkedin>
                    </li>
                    <li *ngIf="partner.contactInformation.facebook">
                        <label>Facebook</label><app-pill-facebook [facebookUrl]="partner.contactInformation.facebook"></app-pill-facebook>
                    </li>
                    <li *ngIf="partner.contactInformation.github">
                        <label>Github</label><app-pill-github [githubUrl]="partner.contactInformation.github"></app-pill-github>
                    </li>
                    <li *ngIf="partner.contactInformation.email && this.moduleService.checkModuleActive(eModule.MAILCHIMP)">
                        <label>Mailchimp</label
                        ><app-pill-mailchimp
                            [email]="partner.contactInformation.email"
                            [phone]="partner.contactInformation.phoneNumber"></app-pill-mailchimp>
                    </li>
                </ng-container>
                <li *ngIf="partner.address"><label>Address</label><app-pill-address [address]="partner.address"></app-pill-address></li>
                <li *ngIf="partner.vat"><label>VAT</label>{{ partner.vat }}</li>
                <li>
                    <label>Tags</label
                    ><app-global-tags
                        [objectUuid]="partner.uuid"
                        [type]="'partner'"></app-global-tags>
                </li>
            </ul>

            <app-logbook-overview [objectId]="partner.uuid"></app-logbook-overview>
        </ng-template>
    </ng-container>

    <ng-container
        *appIsPermitted="{ module: 'CONTACTS', operator: 'AND', actions: ['READ'] }"
        ngbNavItem>
        <a ngbNavLink>Contacts</a>
        <ng-template ngbNavContent>
            <app-global-link-autocomplete
                [itemLinkType]="'contact'"
                [itemType]="'partner'"
                [item]="partner"></app-global-link-autocomplete>
        </ng-template>
    </ng-container>

    <ng-container
        *ngIf="this.moduleService.checkModuleActive(eModule.COMMENTS)"
        ngbNavItem>
        <a ngbNavLink>Comments</a>

        <ng-template ngbNavContent>
            <app-comments-overview [objectId]="this.partner.uuid"></app-comments-overview>
        </ng-template>
    </ng-container>

    <ng-container
        *ngIf="this.moduleService.checkModuleActive(eModule.PROJECTS)"
        ngbNavItem>
        <a
            *appIsPermitted="{ module: 'PROJECTS', operator: 'AND', actions: ['READ'] }"
            ngbNavLink
            >Projects</a
        >
        <ng-template ngbNavContent>
            <app-global-link-autocomplete
                [itemLinkType]="'project'"
                [itemType]="'partner'"
                [item]="partner"></app-global-link-autocomplete>
        </ng-template>
    </ng-container>

    <ng-container ngbNavItem>
        <a ngbNavLink>Files</a>
        <ng-template ngbNavContent>
            <app-linked-invoices-to-object
                [alreadyFetchedFiles]="true"
                [objectId]="partner.uuid"
                [showInvoices]="false">
            </app-linked-invoices-to-object>
        </ng-template>
    </ng-container>
</nav>

<div class="modal-body">
    <div [ngbNavOutlet]="nav"></div>
</div>

<div class="modal-footer">
    <button
        (click)="activeModal.close()"
        class="button button--outline"
        type="button">
        Close
    </button>
    <div
        *appIsPermitted="{ module: 'CONTACTS', operator: 'OR', actions: ['EDIT', 'DELETE'] }"
        class="dropdown"
        ngbDropdown
        placement="top-right">
        <button
            class="button dropdown-toggle"
            id="dropdownMenuButton"
            ngbDropdownToggle
            type="button">
            Actions
        </button>
        <div
            aria-labelledby="dropdownMenuButton"
            class="dropdown-menu"
            ngbDropdownMenu>
            <a
                (click)="openPartnerUpdateModal()"
                *appIsPermitted="{ module: 'PARTNERS', operator: 'AND', actions: ['EDIT'] }"
                class="dropdown-item"
                >Edit company</a
            >
            <a
                (click)="openDeletePartnerModal()"
                *appIsPermitted="{ module: 'PARTNERS', operator: 'AND', actions: ['DELETE'] }"
                class="dropdown-item color--red"
                >Delete</a
            >
        </div>
    </div>
</div>
