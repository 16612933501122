import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';

import { AuthenticationService } from './authentication/authentication.service';
import { FetchNotifications } from './notifications/notifications.actions';
import { ActionsOnStartup } from './users/user.actions';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();
    title = 'resume-app-frontend';
    redirecting: boolean;

    constructor(private store: Store, private logger: NGXLogger, private authenticationService: AuthenticationService) {}

    ngOnInit(): void {
        if (!this.authenticationService.getToken()) return;

        this.store.dispatch(new ActionsOnStartup());
        // Call every minute the database for new notifications
        setInterval(() => {
            this.store.dispatch(new FetchNotifications());
        }, 60000);
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
