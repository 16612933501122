import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { CompanyState } from 'src/app/companies/company.state';
import { Module } from 'src/app/models/Module';
import { Permission } from 'src/app/models/Permission';
import { Role } from 'src/app/models/Role';
import { FetchModules } from 'src/app/modules/module.actions';
import { ModuleState } from 'src/app/modules/module.state';

import { AddCustomRole, UpdateCustomRole } from '../custom.roles.actions';
import { CustomRolesState } from '../custom.roles.state';

@Component({
    selector: 'app-role-add-modal',
    templateUrl: './role-add-modal.component.html',
    styleUrls: ['./role-add-modal.component.scss'],
})
export class RoleAddModalComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();
    @Select(ModuleState.Modules) allModules$: Observable<Module[]>;
    @Select(CustomRolesState.CustomRoles) allCustomRoles$: Observable<Role[]>;
    @Input()
    existingRole: Role;

    allCompanyModules: Module[];
    customRoleFormGroup: FormGroup;
    loading = true;
    inputIsWrong = false;
    update = false;
    checked = false;

    selectedModules: Module[] = [];
    role: Role;
    permissions: Permission[];
    errorMessage: string;

    constructor(
        public activeModal: NgbActiveModal,
        private store: Store,
        private formBuilder: FormBuilder,
        private toastr: ToastrService,
        private logger: NGXLogger,
    ) {}

    ngOnInit(): void {
        this.customRoleFormGroup = this.formBuilder.group({
            name: ['', [Validators.required]],
            modules: [[]],
        });

        this.logger.debug('Fetching Modules');
        this.store.dispatch(new FetchModules());

        this.subscriptions.add(
            this.store.select(CompanyState.company).subscribe((company) => {
                this.allCompanyModules = company.modules;
                this.loading = false;
            }),
        );

        this.permissions = [
            { id: 6, name: ' CREATE' },
            { id: 1, name: 'READ' },
            { id: 3, name: 'UPDATE' },
            { id: 4, name: 'DELETE' },
            { id: 5, name: 'FULL_CONTROL' },
        ];

        if (this.update) {
            this.customRoleFormGroup.setValue({
                name: this.existingRole.name,
                modules: this.existingRole.modules,
            });
            this.selectedModules = JSON.parse(JSON.stringify(this.existingRole.modules));
        }
    }

    setToggleActive(module: Module): string {
        if (this.customRoleFormGroup.value.modules.filter((m: Module) => m.key === module.key).length !== 0 && module.canActivate) {
            return 'toggle--active';
        }
        if (!module.canActivate) {
            return 'toggle--disabled toggle--active';
        }
    }

    addCustomRole() {
        this.loading = true;

        if (!this.customRoleFormGroup.valid) {
            this.inputIsWrong = true;
            this.loading = false;
            return;
        }

        this.role = this.customRoleFormGroup.value;
        this.role.modules = this.customRoleFormGroup.value.modules;

        if (this.role.modules.filter((m) => m.permissions == null).length != 0) {
            this.toastr.error('Please select at least one permission per module');
            this.inputIsWrong = true;
            this.loading = false;
            return;
        }

        this.customRoleFormGroup.disable();

        if (!this.update) {
            this.logger.debug('Attempt to add custom role');
            this.subscriptions.add(
                this.store.dispatch(new AddCustomRole(this.role)).subscribe(
                    (state) => {
                        this.logger.debug('Succesfully added custom role');
                        this.toastr.success('Role added succesfully');
                        this.activeModal.close();
                    },
                    (error) => {
                        this.logger.debug('Failed to add custom role');
                        this.loading = false;
                        this.inputIsWrong = true;
                        this.errorMessage = error.error.message;
                        this.customRoleFormGroup.enable();
                    },
                ),
            );
        } else {
            this.role.id = this.existingRole.id;

            this.logger.debug('Attempt to update customRole');
            this.subscriptions.add(
                this.store.dispatch(new UpdateCustomRole(this.role)).subscribe(
                    (state) => {
                        this.logger.debug('Succesfully updated custom role');
                        this.toastr.success('Role updated succesfully');
                        this.activeModal.close();
                    },
                    (error) => {
                        this.logger.debug('Failed to update custom role');
                        this.loading = false;
                        this.inputIsWrong = true;
                        this.errorMessage = error.error.message;
                        this.customRoleFormGroup.enable();
                    },
                ),
            );
        }
    }

    addModuleToRole(module: Module) {
        if (this.selectedModules.find((m) => m.uuid == module.uuid)) {
            this.selectedModules = this.selectedModules.filter((m) => m.uuid != module.uuid);
        } else {
            this.selectedModules.push(module);
            this.addPermissionToModule(
                module,
                this.permissions.find((p) => p.id == 5),
            );
        }

        this.customRoleFormGroup.value.modules = this.selectedModules;
    }

    addPermissionToModule(module: Module, permission: Permission) {
        const mod = JSON.parse(JSON.stringify(this.selectedModules.find((m) => m.uuid == module.uuid)));

        if (typeof mod == 'undefined') {
            this.toastr.error('Please select a module first');
            throw Error('No module selected');
        }

        let modulePermissions = [];
        if (typeof mod.permissions == 'undefined') {
            mod.permissions = [];
        } else {
            modulePermissions = mod.permissions;
        }

        if (permission.name == 'FULL_CONTROL') {
            if (mod.permissions.find((p) => p.id == permission.id)) {
                this.setModules(mod, []);
            } else {
                this.setModules(mod, this.permissions);
            }
        } else {
            if (mod.permissions.find((p) => p.id == permission.id)) {
                modulePermissions = mod.permissions.filter((p) => p.id != permission.id);
                this.setModules(mod, modulePermissions);
            } else {
                modulePermissions.push(permission);
                this.setModules(mod, modulePermissions);
            }
        }

        this.customRoleFormGroup.value.modules = this.selectedModules;
    }

    setModules(module: Module, modulePermissions: any[]) {
        module.permissions = modulePermissions;
        this.selectedModules = this.selectedModules.filter((m) => m.uuid != module.uuid);
        this.selectedModules.push(module);
    }

    checkIfPermissionPresent(module: Module, permission: Permission) {
        const foundModule = this.selectedModules.find((m) => m.uuid == module.uuid);
        const foundPermission = foundModule?.permissions?.find((p) => p.id == permission.id);
        if (foundPermission != null) {
            return true;
        }
        return false;
    }

    hideErrorMessage() {
        this.inputIsWrong = false;
    }

    getTitle(module: Module): string {
        let title = '';
        this.subscriptions.add(
            this.allModules$.subscribe((modules) => {
                title = modules.find((m) => m.key == module.key)?.title;
            }),
        );
        return title;
    }

    getDescription(module: Module): string {
        let description = '';
        this.subscriptions.add(this.allModules$.subscribe((modules) => (description = modules.find((m) => m.key == module.key)?.description)));
        return description;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
