<a
    class="pill pill--warehouse"
    *ngIf="fetchedWarehouseLocation"
    (click)="openWarehouseLocationDetailModal(); $event.stopPropagation()">
    <i class="las la-pallet"></i>
    {{ fetchedWarehouseLocation.title }}
</a>

<a
    class="pill pill--warehouse"
    *ngIf="!fetchedWarehouseLocation"
    (click)="openWarehouseLocationDetailModal(); $event.stopPropagation()">
    <i class="las la-pallet"></i>
    {{ getTitle() }}
</a>
