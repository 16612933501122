import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { CustomRolesState } from '../settings/roles/custom.roles.state';

@Injectable({
    providedIn: 'root',
})
export class CompaniesModulesGuard implements CanActivate {
    constructor(private store: Store, private router: Router) {}

    canActivate(next: ActivatedRouteSnapshot, routerState: RouterStateSnapshot): Observable<boolean> {
        if (routerState.url.includes('dashboard') || routerState.url.includes('settings')) {
            return of(true);
        }

        const sessionModules = JSON.parse(sessionStorage.getItem('allowedModules'));
        let currentModule = '';
        let nextIsModule = false;
        routerState.url.split('/').forEach((el) => {
            if (nextIsModule) {
                currentModule = el;
            }
            nextIsModule = el == '';
        });
        currentModule = currentModule.replace('-', '_');
        if (sessionModules.length > 2) {
            if (sessionModules.includes('PROJECTS') && currentModule.toLowerCase() === 'planning') {
                return of(true);
            } else if (currentModule.toLowerCase() === 'invoices' && sessionModules.includes('YUKI')) {
                return of(true);
            } else if (sessionModules.filter((m) => m.toLowerCase() === currentModule.toLowerCase()).length <= 0) {
                this.router.navigate([`/forbidden`]);
                return of(true);
            } else {
                return of(true);
            }
        }
        return this.store.selectOnce(CustomRolesState.AllowedModuleKeys).pipe(
            tap((allowedModules) => {
                if (allowedModules.length == 2) {
                    this.router.navigate([`/dashboard`]);
                    return of(true);
                }

                if (allowedModules.filter((m) => m.toLowerCase() === currentModule.toLowerCase()).length <= 0) {
                    this.router.navigate([`/forbidden`]);
                }
                return of(true);
            }),
            catchError(() => {
                return of(true);
            }),
            map(() => true),
        );
    }
}
