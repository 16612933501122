<div>
    <div class="d-flex align-items-center">
        <div class="w-100 d-flex">
            <app-searchbar
                (valueChange)="applyFilter($event)"
                [(value)]="filter"
                class="w-100 m-r-2"></app-searchbar>
            <app-tag-searchbar
                (valueChange)="applyTagFilter($event)"
                [(value)]="tagFilter"
                [type]="'resume'"
                class="w-100">
            </app-tag-searchbar>
        </div>
        <!--<div class="d-flex nowrap">
            <span
                class="dropdown m-l-2"
                ngbDropdown>
                <button
                    class="button button&#45;&#45;select"
                    ngbDropdownToggle>
                    Properties
                </button>
                <span
                    class="dropdown-menu-right"
                    ngbDropdownMenu>
                    <form>
                        <label
                            (click)="toggleProperty('jobTitle')"
                            ngbDropdownItem
                        ><input
                            [checked]="properties.jobTitle"
                            type="checkbox"/>
                            Title</label
                        >
                        <label
                            (click)="toggleProperty('createdOn')"
                            ngbDropdownItem
                        ><input
                            [checked]="properties.createdOn"
                            type="checkbox"/>
                            Created</label
                        >
                        <label
                            (click)="toggleProperty('lastModified')"
                            ngbDropdownItem
                        ><input
                            [checked]="properties.lastModified"
                            type="checkbox"/>
                            Last modified</label
                        >
                    </form>
                </span>
            </span>
        </div>-->
    </div>

    <div
        *ngIf="resumes$ | async; else loading"
        class="m-t-4">
        <div class="table__overflow">
            <table
                *ngIf="dataSource.data.length > 0; else empty"
                [dataSource]="dataSource"
                mat-table
                matSort>
                <ng-container matColumnDef="lastName">
                    <th
                        *matHeaderCellDef
                        mat-header-cell
                        mat-sort-header>
                        Person
                    </th>
                    <td
                        *matCellDef="let row"
                        mat-cell>
                        <div class="d-flex align-items-center">
                            <img [src]="'https://eu.ui-avatars.com/api/?background=random&bold=true&name=' + row.firstName + '%20' + row.lastName"
                                class="avatar"/>
                            <p>{{ row.firstName }} {{ row.lastName }}</p>
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="jobTitle">
                    <th
                        *matHeaderCellDef
                        mat-header-cell
                        mat-sort-header>
                        Job titles
                    </th>
                    <td *matCellDef="let row" mxat-cell>
                        <span (click)="$event.stopPropagation(); resumeDetail(row, variant)" *ngFor="let variant of row.resumeVariants | orderBy: 'jobTitle':false:true" class="tag tag--hover m-r-1">
                            {{variant.jobTitle}}
                        </span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="lastModified">
                    <th
                        *matHeaderCellDef
                        mat-header-cell
                        mat-sort-header>
                        Updated At
                    </th>
                    <td
                        *matCellDef="let row"
                        mat-cell>
                        {{ row.lastModified | date }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="menu">
                    <th
                        *matHeaderCellDef
                        class="td-small"
                        mat-header-cell></th>
                    <td
                        *matCellDef="let row"
                        class="td-icon"
                        mat-cell>
                        <ng-template #popContent>
                            <span *ngIf="row.globalTags.length">
                                <span
                                    *ngFor="let tag of row.globalTags | orderBy: 'title':false:true"
                                    class="tag m-r-1 m-b-1"
                                >{{ tag.title }}</span
                                >
                            </span>
                            <small *ngIf="row.globalTags.length === 0">No technical skills added...</small>
                        </ng-template>

                        <span
                            (click)="$event.stopPropagation()"
                            [ngbPopover]="popContent"
                            class="m-r-1 button button--icon button--small"
                            popoverTitle="Technical skills"
                        ><i class="las la-tag"></i
                        ></span>

                        <span
                            class="dropdown"
                            ngbDropdown>
                            <button
                                (click)="$event.stopPropagation()"
                                class="button button--icon button--small dropdown-toggle"
                                id="dropdownMenuButton"
                                ngbDropdownToggle></button>
                            <div
                                aria-labelledby="dropdownMenuButton"
                                class="dropdown-menu dropdown-menu-right"
                                ngbDropdownMenu>
                                <a
                                    (click)="$event.stopPropagation(); openResumeShareModal(row)"
                                    class="dropdown-item"
                                >Share resume</a
                                >
                                <a
                                    (click)="$event.stopPropagation(); openEditResumePersonInfoUpdateModal(row)"
                                    *appIsPermitted="{
                                        module: 'RESUME',
                                        operator: 'AND',
                                        actions: ['EDIT']
                                    }"
                                    class="dropdown-item"
                                >Edit resume</a
                                >
                                <div
                                    *appIsPermitted="{
                                        module: 'RESUME',
                                        operator: 'AND',
                                        actions: ['DELETE']
                                    }"
                                    class="dropdown-divider"></div>
                                <a
                                    (click)="$event.stopPropagation(); openDeleteResumeModal(row)"
                                    *appIsPermitted="{
                                        module: 'RESUME',
                                        operator: 'AND',
                                        actions: ['DELETE']
                                    }"
                                    class="dropdown-item color--red"
                                >Archive resume</a
                                >
                            </div>
                        </span>
                    </td>
                </ng-container>

                <tr
                    *matHeaderRowDef="displayedColumns"
                    mat-header-row></tr>
                <tr
                    (click)="resumeDetail(row)" *matRowDef="let row; columns: displayedColumns" class="pointer"
                    mat-row></tr>

                <tr *matNoDataRow>
                    <td colspan="4">No Resumés matching the filter "{{ filter }}"</td>
                </tr>
            </table>
        </div>
    </div>

    <ng-template #loading>
        <app-loading-message></app-loading-message>
    </ng-template>

    <ng-template #empty>
        <app-message-empty
            [icon]="'../../assets/img/open-box.png'"
            [subtitle]="'Start adding resumés to manage employees and projects. You can exchange these resumes with contacts.'"
            [title]="'No resumes...'">
            <a
                (click)="openAddResumeModal()"
                *appIsPermitted="{
                    module: 'RESUME',
                    operator: 'AND',
                    actions: ['CREATE']
                }"
                class="button button--primary"
            >Add a resumé</a
            >
        </app-message-empty>
    </ng-template>
</div>

<ng-template #loading>
    <app-loading-message></app-loading-message>
</ng-template>
