import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenticationService } from 'src/app/authentication/authentication.service';
import FileSystemEntity from 'src/app/models/FileSystemEntity';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class DocumentsListService {
    httpOptions = {};

    constructor(private httpClient: HttpClient, private authenticationService: AuthenticationService) {
        this.httpOptions = authenticationService.getHeaders();
    }

    getProductDocs(productId: string) {
        return this.httpClient.get<FileSystemEntity[]>(`${environment.baseUrl}/product/documents/${productId}`, this.httpOptions);
    }

    getProjectDocs(projectId: string) {
        return this.httpClient.get<FileSystemEntity[]>(`${environment.baseUrl}/project/documents/${projectId}`, this.httpOptions);
    }

    addProductDocs(docId: string, productId: string) {
        return this.httpClient.post<boolean>(`${environment.baseUrl}/product/documents/${docId}/${productId}`, '', this.httpOptions);
    }

    addProjectDocs(docId: string, projectId: string) {
        return this.httpClient.post<boolean>(`${environment.baseUrl}/project/documents/${docId}/${projectId}`, '', this.httpOptions);
    }

    deleteProductDocs(docId: string, productId: string) {
        return this.httpClient.delete<boolean>(`${environment.baseUrl}/product/documents/${docId}/${productId}`, this.httpOptions);
    }

    deleteProjectDocs(docId: string, projectId: string) {
        return this.httpClient.delete<boolean>(`${environment.baseUrl}/project/documents/${docId}/${projectId}`, this.httpOptions);
    }

    getAllFiles() {
        return this.httpClient.get<FileSystemEntity[]>(`${environment.baseUrl}/fileSystem/documents`, this.httpOptions);
    }
}
