import { Company } from '../models/Company';

export class FetchCompany {
    static readonly type = '[Company] Fetch company';
}

export class AddCompany {
    static readonly type = '[Company] Add Company';

    constructor(public company: Company) {}
}

export class UpdateCompany {
    static readonly type = '[Company] Update Company';

    constructor(public company: Company) {}
}

export class ArchiveCompany {
    static readonly type = '[Company] Archive Company';
}

export class ClearCompany {
    static readonly type = '[Company] Clear Company';
}

export class GetCompanyMembers {
    static readonly type = '[CompanyMembers] Get company members';
}

export class GetCompanyMemberById {
    static readonly type = '[CompanyMembers] Get company member by id';

    constructor(public id: string) {}
}

export class GetAllApplicants {
    static readonly type = '[User] Get All Applicants';
}

export class InviteMember {
    static readonly type = '[Company] Invite Member';

    constructor(public email: string) {}
}

export class DeleteMemberInvite {
    static readonly type = '[Company] Delete Invite';

    constructor(public inviteUuid: string) {}
}

export class GetCompanyInvitedMembers {
    static readonly type = '[Company] Get Invited Members';
}

export class GetCompanyProjectStatusList {
    static readonly type = '[Company] Get Work Status List';
}

export class UpdateRoleOfMember {
    static readonly type = '[CompanyMember] Update role';

    constructor(public memberId: string, public role: string) {}
}
