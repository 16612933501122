<form *ngIf="isLoaded" [formGroup]="projectFormGroup" (ngSubmit)="saveProject()" class="modal-wrapper">
    <div class="modal-header">
        <h2 *ngIf="!isUpdate" class="modal-title" id="exampleModalLabel">
            New project
        </h2>
        <h2 *ngIf="isUpdate" class="modal-title" id="exampleModalLabel">
            Edit project
        </h2>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <section>
            <div class="sectiontitle sectiontitle--first">Information</div>

            <div class="form-group required m-b-2">
                <label>Title</label>
                <input type="text" formControlName="title" (focus)="hideErrorMessage()" trim="blur" #focussed />
                <mat-error class="error-message"
                    *ngIf="projectFormGroup.get('title').hasError('projectTitleAlreadyExists')">
                    Another project with this title already <strong>exists</strong>
                </mat-error>
                <label>Project type</label>
                <select formControlName="projectType" (focus)="hideErrorMessage()">
                    <option [ngValue]="0">Custom</option>
                    <option [ngValue]="1">Website</option>
                    <option [ngValue]="2">Consultancy</option>
                    <option [ngValue]="3">Other</option>
                </select>
                <span class="form-error"
                    *ngIf="!isUpdate && projectFormGroup.controls.title.errors && inputIsWrong">Title is required</span>
                <span class="form-error" *ngIf="projectFormGroup.controls.projectType.errors && inputIsWrong">Project
                    type is required</span>
                <span class="form-error"
                    *ngIf="projectFormGroup.get('title').hasError('required') && projectFormGroup.get('title').touched">Title
                    is required</span>
            </div>
        </section>

        <section>
            <div class="m-t-4 m-b-4 sectiontitle">Location</div>

            <div formGroupName="location">
                <div class="m-b-2">
                    <div class="row">
                        <div class="col-6">
                            <div class="form-group">
                                <label>Street</label>
                                <input type="text" placeholder="Street" formControlName="street"
                                    (focus)="hideErrorMessage()" trim="blur" />
                                <span class="form-error"
                                    *ngIf="projectFormGroup.get('location?.street')?.errors && inputIsWrong">Street is
                                    required</span>
                            </div>
                        </div>
                        <div class="col-2">
                            <div class="form-group">
                                <label>Number</label>
                                <input type="number" placeholder="Number" formControlName="number"
                                    (focus)="hideErrorMessage()" />
                                <span class="form-error"
                                    *ngIf="projectFormGroup.get('location?.number')?.errors && inputIsWrong">Number is
                                    required</span>
                            </div>
                        </div>
                        <div class="col-2">
                            <div class="form-group">
                                <label>Box</label>
                                <input type="text" placeholder="Box" formControlName="bus" trim="blur" />
                            </div>
                        </div>
                        <div class="col-2">
                            <div class="form-group">
                                <label>Ext</label>
                                <input type="number" placeholder="Ext" formControlName="ext" />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="m-b-2">
                    <div class="row">
                        <div class="col-3">
                            <div class="form-group">
                                <label>Zipcode</label>
                                <input type="text" placeholder="Zipcode" formControlName="postal"
                                    (focus)="hideErrorMessage()" trim="blur" />
                                <span class="form-error"
                                    *ngIf="projectFormGroup.get('location?.postal')?.errors && inputIsWrong">Zipcode is
                                    required</span>
                            </div>
                        </div>
                        <div class="col-9">
                            <div class="form-group">
                                <label>City</label>
                                <input type="text" placeholder="City" formControlName="city"
                                    (focus)="hideErrorMessage()" trim="blur" />
                                <span class="form-error"
                                    *ngIf="projectFormGroup.get('location?.city')?.errors && inputIsWrong">City is
                                    required</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form-group m-b-2" formGroupName="country">
                    <label>Country</label>
                    <select formControlName="iso2" (focus)="hideErrorMessage()">
                        <option *ngFor="let country of allCountries" [ngValue]="country['alpha-2']">
                            {{ country.name }}
                        </option>
                    </select>
                    <span class="form-error"
                        *ngIf="projectFormGroup.get('location?.country.iso2')?.errors && inputIsWrong">Country is
                        required</span>
                </div>
            </div>
        </section>

        <div class="form-group--toggle m-b-2" [class.required]="billable">
            <label><input type="checkbox" formControlName="billable" />
                Billable</label>
        </div>

        <div class="form-group--toggle m-b-2" [class.required]="present">
            <label><input type="checkbox" formControlName="present" (change)="onPresentTicked()" />
                No enddate</label>
        </div>
        <div class="row">
            <div class="col-6">
                <div class="form-group required m-b-2">
                    <label>Start Date</label>
                    <input type="date" formControlName="startDate" />
                    <span class="form-error" *ngIf="!isValidDate && inputIsWrong">Startdate must start before the
                        enddate</span>
                    <span class="form-error" *ngIf="projectFormGroup.controls.startDate.errors && inputIsWrong">Start
                        date required</span>
                    <span class="form-error"
                        *ngIf="projectFormGroup.get('startDate').hasError('required') && projectFormGroup.get('startDate').touched">Start
                        date is required</span>
                </div>
            </div>
            <div class="col-6">
                <div class="form-group m-b-2" [class.required]="!present">
                    <label>End Date</label>
                    <input type="date" formControlName="endDate" />
                    <span class="form-error" *ngIf="!isValidDate && inputIsWrong">Enddate must start after the
                        startdate</span>
                    <span class="form-error"
                        *ngIf="projectFormGroup.get('endDate').hasError('required') && projectFormGroup.get('endDate').touched">End
                        date required</span>
                </div>
            </div>
        </div>
        <div class="form-group--toggle m-b-2" [class.required]="warranty">
            <label>
                <input type="checkbox" formControlName="warranty" (change)="onWarrantyTicked()" />
                Warranty
            </label>
        </div>
        <div class="row">
            <div class="col-6">
                <div class="form-group m-b-2" [class.required]="warranty" *ngIf="!isHiddenControl">
                    <label>Warranty Start Date</label>
                    <input type="date" formControlName="warrantyStartDate" />
                    <span class="form-error"
                        *ngIf="projectFormGroup.controls.warrantyStartDate.errors && inputIsWrong">Start
                        date required</span>
                    <span class="form-error"
                        *ngIf="projectFormGroup.get('warrantyStartDate').hasError('required') && projectFormGroup.get('warrantyStartDate').touched">Start
                        date is required</span>
                </div>
            </div>
            <div class="col-6">
                <div class="form-group m-b-2" [class.required]="warranty" *ngIf="!isHiddenControl">
                    <label>Warranty End Date</label>
                    <input type="date" formControlName="warrantyEndDate" />
                    <span class="form-error" *ngIf="!isValidWarrantyDate && inputIsWrong">Enddate must start after the
                        startdate</span>
                    <span class="form-error"
                        *ngIf="projectFormGroup.get('warrantyEndDate').hasError('required') && projectFormGroup.get('warrantyEndDate').touched">End
                        date required</span>
                </div>
            </div>
        </div>
        <div class="form-group--toggle m-b-2" [class.required]="maintenanceContract">
            <label><input type="checkbox" formControlName="maintenanceContract" (change)="onMaintenanceTicked()" />
                Maintenance contract</label>
        </div>
        <div class="form-group m-b-2">
            <app-global-autocomplete [parentForm]="projectFormGroup" [formControlName]="'partner'" [data]="allPartners"
                [itemType]="'company'" [label]="'Link a company'" [notFoundMessage]="'Add a company'"
                [keyword]="partnerKeyword" [placeholder]="'Choose company'" [dataLoaded]="isLoaded"
                [active]="moduleService.checkModuleActive(eModule.PARTNERS)"
                [required]="false"></app-global-autocomplete>
        </div>

        <div class="form-group m-b-2">
            <app-global-autocomplete [parentForm]="projectFormGroup" [formControlName]="'customer'"
                [data]="allCustomers" [itemType]="'customer'" [label]="'Link a customer'"
                [notFoundMessage]="'Add a customer'" [keyword]="'firstName'" [placeholder]="'Choose customer'"
                [dataLoaded]="isLoaded" [active]="this.moduleService.checkModuleActive(eModule.CUSTOMERS)"
                [required]="false"></app-global-autocomplete>
        </div>

        <div class="form-group m-b-2">
            <app-global-autocomplete [parentForm]="projectFormGroup" [formControlName]="'contact'"
                [data]="getRelevantContacts()" [itemType]="'contact'" [label]="'Link a contact'"
                [notFoundMessage]="'Add a contact'" [keyword]="'firstName'" [placeholder]="'Choose contact'"
                [dataLoaded]="isLoaded" [active]="this.moduleService.checkModuleActive(eModule.CONTACTS)"
                [required]="false"></app-global-autocomplete>
        </div>

        <div class="form-group m-b-2">
            <label>Budget</label>
            <input type="number" placeholder="Budget" formControlName="budget" (focus)="hideErrorMessage()"
                trim="blur" />
        </div>

        <div class="form-group m-b-2">
            <label>Budget in man days</label>
            <input type="number" placeholder="Budget in man-days" formControlName="budgetInManDays"
                (focus)="hideErrorMessage()" trim="blur" />
        </div>

        <div>
            <div *ngIf="!jiraProjectKeysLoaded">Loading project keys...</div>
            <div class="form-group m-b-2" *ngIf="jiraProjectKeysLoaded">
                <label>Jira Project Key</label>
                <select formControlName="jiraProjectKey">
                    <option value="">Choose the corresponding Jira Project Key</option>
                    <option *ngFor="let credential of jiraProjectKeyList$" [value]="credential.jiraKey">
                        {{credential.projectName}} - {{credential.jiraKey}}
                    </option>
                </select>
            </div>
        </div>

        <div class="form-group m-b-2">
            <label>Description</label>
            <textarea class="resize-vertical" formControlName="description" (keyup)="onDescriptionKeyUp()"
                (focus)="hideErrorMessage()" trim="blur"></textarea>
            <small><span *ngIf="currentAmountOfDescriptionCharacters == null">0</span>{{
                currentAmountOfDescriptionCharacters }} /{{
                DESCRIPTION_MAX_LENGTH
                }}</small>
            <span class="form-error" *ngIf="descriptionIsTooLong">Description cannot contain more than 1000
                characters</span>
        </div>

        <!-- <label>Upload a PDF</label>
    <app-file-upload type="file" formControlName="pdfUpload"></app-file-upload> -->

        <!-- <div *ngIf="checkResumeModule()">
            <div *ngIf="assignedResumes && isUpdate">
                <label><strong>Assigned resumes</strong></label>
                <div *ngFor="let res of assignedResumes">
                    {{ res.firstName + ' ' + res.lastName }}
                    <button type="button" class="close" (click)="addToRemoveArray(res)">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </div>

            <div *ngIf="selectedResumes">
                <label><strong>Resumes selected to assign</strong></label>
                <div *ngFor="let res of selectedResumes">
                    {{ res.firstName + ' ' + res.lastName }}
                    <button type="button" class="close" (click)="removeFromSelectedArray(res)">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </div>

            <div>
                <label>Choose resume</label>
                <div *ngIf="companyResumes?.length!=0">
                    <div class="form-group m-b-2">
                        <div class="d-flex">
                            <div class="ng-autocomplete">
                                <ng-autocomplete [data]="companyResumes" [searchKeyword]="keyword" (selected)='selectEvent($event)'
                                    autocomplete="off"
                                    [itemTemplate]="itemTemplate"
                                    [notFoundTemplate]="notFoundTemplate" placeholder="Choose resume">
                                </ng-autocomplete>

                                <ng-template #itemTemplate let-item>
                                    <a [innerHTML]="item.firstName + ' ' + item.lastName"></a>
                                </ng-template>

                                <ng-template #notFoundTemplate let-notFound>
                                    No resumes found matching this value
                                </ng-template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->

        <div class="form-group m-b-2" *ngIf="isUpdate">
            <label>Project is finished</label>
            <input type="checkbox" [checked]="isFinished" (change)="toggleFinished()" />
            {{ isFinished }}
        </div>

        <div class="form-group m-b-2">
            <label>Tags</label>
            <app-global-tags *ngIf="!isUpdate" [type]="'project'"></app-global-tags>
            <app-global-tags *ngIf="isUpdate" [objectUuid]="this.project.uuid" [type]="'project'"></app-global-tags>
        </div>
    </div>
    <!--
    <div *ngIf="project.products && this.moduleService.checkModuleActive('PRODUCTS')">
        <div class="sectiontitle">
            Related products
        </div>
        <table class="table-view">
            <tr>
                <th>Product</th>
                <th class="td-small"></th>
            </tr>

            <tr *ngFor="let product of project.products">
                <td>
                    <span placement="top" [ngbTooltip]="product.description">{{product.title}}</span>
                </td>
                <td>
                    <span ngbDropdown class="dropdown">
                        <button ngbDropdownToggle class="button button--icon button--small dropdown-toggle" id="dropdownMenuButton">
                        </button>
                        <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right"
                            aria-labelledby="dropdownMenuButton">
                            <div class="dropdown-divider"></div>
                            <a class="dropdown-item color--red" (click)="openprojectProductDeleteModal(project, product)">Remove product</a>
                        </div>
                    </span>
                </td>
            </tr>
            <tr>
                <td colspan="3" class="table-view__add" (click)="openprojectAddProductModal(project)">
                    <i class="las la-plus"></i> Add new product
                </td>
            </tr>
        </table>
    </div>-->

    <div class="modal-footer">
        <button type="button" class="button button--outline" (click)="activeModal.close()">
            Close
        </button>
        <button *ngIf="!isUpdate" type="submit" [ngClass]="{ 'button--loading': loading }"
            class="button button--primary" [disabled]="!projectFormGroup.valid">
            Add project
        </button>
        <button *ngIf="isUpdate" type="submit" [ngClass]="{ 'button--loading': loading }" class="button button--primary"
            [disabled]="!projectFormGroup.valid">
            Edit project
        </button>
    </div>
</form>

<app-loading-message *ngIf="!isLoaded"></app-loading-message>