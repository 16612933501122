<!--<div class="d-flex nowrap">
    <span ngbDropdown class="dropdown m-l-2">
        <button ngbDropdownToggle class="button button&#45;&#45;select">
            Download
        </button>
        <span ngbDropdownMenu class="dropdown-menu-right">
            <form>
                <a ngbDropdownItem (click)="exportCsv()">Download CSV</a>
            </form>
        </span>
    </span>
</div>-->

<a
    *ngIf="type === 'dropdown-item'"
    class="dropdown-item"
    (click)="exportCsv()"
    >Download</a
>
<button
    *ngIf="type === 'button'"
    class="button button--primary"
    (click)="exportCsv()">
    Download
</button>
