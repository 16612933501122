import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-app-holder',
    templateUrl: './app-holder.component.html',
    styleUrls: ['./app-holder.component.scss'],
})
export class AppHolderComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
