import { Component, Input, OnInit } from '@angular/core';
import { NgxGalleryOptions } from '@kolkov/ngx-gallery';
import { NgxGalleryImage } from '@kolkov/ngx-gallery';
import { NgxGalleryAnimation } from '@kolkov/ngx-gallery';

@Component({
    selector: 'app-image-gallery',
    templateUrl: './image-gallery.component.html',
    styleUrls: ['./image-gallery.component.scss'],
})
export class ImageGalleryComponent implements OnInit {
    @Input() small: string[]; //optional
    @Input() medium: string[]; //optional
    @Input() big: string[]; //required

    galleryOptions: NgxGalleryOptions[];
    galleryImages: NgxGalleryImage[] = [];

    constructor() {}

    ngOnInit(): void {
        this.galleryOptions = [
            {
                width: '600px',
                height: '400px',
                thumbnailsColumns: 4,
                arrowPrevIcon: 'las la-chevron-circle-left',
                arrowNextIcon: 'las la-chevron-circle-right',
                closeIcon: 'las la-times-circle',
                imageAnimation: NgxGalleryAnimation.Slide,
                /*Ander animations kunnen ook
        Slide, Rotate, Fade en Zoom
        */
            },
            // max-width 800
            {
                breakpoint: 800,
                width: '100%',
                height: '600px',
                imagePercent: 80,
                thumbnailsPercent: 20,
                thumbnailsMargin: 20,
                thumbnailMargin: 20,
            },
            // max-width 400
            {
                breakpoint: 400,
                preview: false,
            },
        ];
        if (this.small == undefined || this.small.length <= 0) {
            this.small = this.big;
        }
        if (this.medium == undefined || this.medium.length <= 0) {
            this.medium = this.big;
        }

        let counter = 0;
        this.big.forEach((bigImage) => {
            this.galleryImages.push({
                small: this.small[counter],
                medium: this.medium[counter],
                big: bigImage,
            });
            counter++;
        });
    }
}
