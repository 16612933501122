<div class="modal-header">
    <h2
        class="modal-title"
        id="exampleModalLabel">
        Add recurrence exception
    </h2>
    <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <input
        type="date"
        [(ngModel)]="date" />
</div>

<div class="modal-footer">
    <input
        class="button button--primary"
        type="submit"
        value="Add exception"
        (click)="close()" />
    <button
        class="button button--outline"
        (click)="activeModal.dismiss()">
        Cancel
    </button>
</div>
