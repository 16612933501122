import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { FetchOffers } from 'src/app/comanage/comanage.actions';
import { ComanageState } from 'src/app/comanage/comanage.state';
import { CompanyState } from 'src/app/companies/company.state';
import { FetchContacts } from 'src/app/contacts/contacts.actions';
import { ContactState } from 'src/app/contacts/contacts.state';
import { CustomerState } from 'src/app/customers/customers.state';
import { Company } from 'src/app/models/Company';
import { ContactPerson } from 'src/app/models/ContactPerson';
import CrmDeal from 'src/app/models/CrmDeal';
import Customer from 'src/app/models/Customer';
import { ModuleEnum } from 'src/app/models/ModuleEnum';
import { Partner } from 'src/app/models/Partner';
import { Product } from 'src/app/models/Product';
import Project from 'src/app/models/Project';
import User from 'src/app/models/User';
import { FetchPartners } from 'src/app/partners/partners.actions';
import { PartnerState } from 'src/app/partners/partners.state';
import { ProjectDetailModalComponent } from 'src/app/projects/project-detail-modal/project-detail-modal.component';
import { ModuleService } from 'src/app/services/module.service';
import { ConfirmationModalComponent } from 'src/app/shared/confirmation-modal/confirmation-modal.component';
import { UserState } from 'src/app/users/user.state';

import { AddProductToDealModalComponent } from '../add-product-to-deal-modal/add-product-to-deal-modal.component';
import { ArchiveCrmDeal, FetchCrmDeals, UpdateCrmDeal } from '../crm.actions';
import { CrmState } from '../crm.state';
import { EditCrmdealModalComponent } from '../edit-crmdeal-modal/edit-crmdeal-modal.component';

@Component({
    selector: 'app-crmdeal-detail-modal',
    templateUrl: './crmdeal-detail-modal.component.html',
    styleUrls: ['./crmdeal-detail-modal.component.scss'],
})
export class CrmdealDetailModalComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Select(CrmState.CrmDeals) deals$: Observable<CrmDeal[]>;
    @Select(CompanyState.company) company$: Observable<Company>;
    @Select(UserState.userInfo) user$: Observable<User>;
    @Select(ComanageState.offers) coManageOffers$: Observable<any[]>;

    @Select(PartnerState.getPartners) partners$: Observable<Partner[]>;
    @Select(ContactState.Contacts) contacts$: Observable<ContactPerson[]>;
    @Select(CustomerState.Customers) customers$: Observable<Customer[]>;

    @Input() partners: Partner[];
    @Input() contacts: ContactPerson[];
    @Input() customers: Customer[];
    @Input() deal: CrmDeal;
    @Input() dealUuid: string;
    addedProducts: Product[] = [];

    company: Company;
    user: User;
    eModule = ModuleEnum;
    comanageOffer: any;

    isLoading = true;

    displayedColumns: string[] = ['product title', 'edit'];
    dataSource: MatTableDataSource<Product> = new MatTableDataSource<Product>();
    @ViewChild(MatSort) sort: MatSort;

    constructor(
        public activeModal: NgbActiveModal,
        public moduleService: ModuleService,
        private store: Store,
        private toastr: ToastrService,
        private modalService: NgbModal,
        private logger: NGXLogger,
    ) {}

    ngOnInit(): void {
        if (this.deal === undefined) {
            this.subscriptions.add(
                this.deals$.subscribe((deals) => {
                    if (deals === null) {
                        this.store.dispatch(new FetchCrmDeals()).subscribe();
                    } else {
                        this.deal = deals.find((d) => d.uuid === this.dealUuid);
                        this.isLoading = false;
                        this.getComanageOffers();
                    }
                }),
            );
        } else {
            this.isLoading = false;
            this.getComanageOffers();
        }
        this.logger.debug('Fetching partners');
        this.store.dispatch(new FetchPartners());
        this.logger.debug('Fetching contacts');
        this.store.dispatch(new FetchContacts());
        this.subscriptions.add(this.company$.subscribe((c) => (this.company = c)));
        this.subscriptions.add(this.partners$.subscribe((p) => (this.partners = p)));
        this.subscriptions.add(this.contacts$.subscribe((c) => (this.contacts = c)));
        this.subscriptions.add(this.customers$.subscribe((c) => (this.customers = c)));
        this.subscriptions.add(this.user$.subscribe((u) => (this.user = u)));
    }

    getComanageOffers() {
        if (this.deal.comanageOffer) {
            this.subscriptions.add(
                this.coManageOffers$.subscribe((offers) => {
                    if (!offers) {
                        this.store.dispatch(new FetchOffers());
                    } else {
                        this.comanageOffer = offers.find((o) => o.offer_number == this.deal.comanageOffer);
                    }
                }),
            );
        }
    }

    update() {
        this.logger.debug('opening editCrmdealModal');
        const modalRef = this.modalService.open(EditCrmdealModalComponent, {
            windowClass: 'modal-pane',
            animation: false,
        });
        modalRef.componentInstance.deal = this.deal;
        modalRef.componentInstance.partners = this.partners;
        modalRef.componentInstance.contacts = this.contacts;
        modalRef.componentInstance.customers = this.customers;
    }

    archive() {
        this.logger.debug('opening ConfirmationModal');
        const modalRef = this.modalService.open(ConfirmationModalComponent, {
            windowClass: 'modal-prompt',
        });
        modalRef.componentInstance.type = 'Archive';
        modalRef.componentInstance.title = 'Archive deal';
        modalRef.componentInstance.message = 'Are you sure you want to Archive ' + this.deal.title + '?';
        this.subscriptions.add(
            modalRef.componentInstance.closeEvent.subscribe((val) => {
                if (val) {
                    this.logger.debug('Attempt to archive crmDeal');
                    this.store.dispatch(new ArchiveCrmDeal(this.deal)).subscribe(
                        (res) => {
                            if (res) {
                                this.logger.debug('Succesfully archived crmDeal');
                                this.toastr.success('Deal archived successfully.');
                                this.activeModal.close();
                            }
                        },
                        (err) => {
                            this.logger.error('Failed to archive crmDeal');
                            this.toastr.error('Oops, something went wrong. Please try again later.');
                        },
                    );
                }
            }),
        );
    }

    openProductAddModal() {
        this.logger.debug('Opening addProductToDealModal');
        const modalRef = this.modalService.open(AddProductToDealModalComponent, { windowClass: 'modal-prompt' });
        modalRef.componentInstance.deal = this.deal;
    }

    removeProduct(product: Product) {
        this.logger.debug('Opening confirmationModal');
        const modalRef = this.modalService.open(ConfirmationModalComponent, {
            windowClass: 'modal-prompt',
        });
        modalRef.componentInstance.type = 'Warning';
        modalRef.componentInstance.title = 'Unlink product';
        modalRef.componentInstance.message = 'Are you sure you want to remove ' + product.title + '?\n the link between this deal and product will be removed.';
        this.subscriptions.add(
            modalRef.componentInstance.closeEvent.subscribe((val) => {
                if (val) {
                    const updatedDeal = JSON.parse(JSON.stringify(this.deal));
                    let products = JSON.parse(JSON.stringify(this.deal.products));
                    products = products.filter((p) => p.uuid != product.uuid);
                    updatedDeal.products = products;
                    this.logger.debug('Attempt to remove product from crmDeal');
                    this.store.dispatch(new UpdateCrmDeal(updatedDeal)).subscribe(
                        (res) => {
                            this.logger.debug('Succesfully removed product from crmDeal');
                            this.toastr.success(product.title + ' was successfully removed.');
                        },
                        (err) => {
                            this.logger.error('Failed to remove product from crmDeal');
                            this.toastr.error('Oops, something went wrong. Please try again later.');
                        },
                    );
                }
            }),
        );
    }

    createProject() {
        const project: Project = {
            uuid: null,
            startDate: Date(),
            endDate: null,
            present: false,
            billable: true,
            title: this.deal.title,
            projectType: null,
            pdfUpload: null,
            description: this.deal.description,
            userId: this.user.uuid,
            companyUuid: this.company.uuid,
            companyName: this.company.title,
            customerUuid: this.deal.customer ? this.deal.customer.uuid : null,
            customerFirstname: this.deal.customer ? this.deal.customer.firstName : '',
            customerLastname: this.deal.customer ? this.deal.customer.lastName : '',
            contactPersonUuid: this.deal.contactPerson ? this.deal.contactPerson.uuid : null,
            contactPersonFirstname: this.deal.contactPerson ? this.deal.contactPerson.firstName : '',
            contactPersonLastname: this.deal.contactPerson ? this.deal.contactPerson.lastName : '',
            partnerUuid: this.deal.partner ? this.deal.partner.uuid : '',
            partnerName: this.deal.partner ? this.deal.partner.title : '',
            location: {
                street: '',
                number: 0,
                bus: '',
                ext: 0,
                postal: '',
                city: '',
                country: { iso2: '' },
            },
            globalTags: [],
            finished: false,
            assignedResumes: [],
            products: [],
            linkedEmployees: [],
            budget: this.deal.price,
        };
        project.title = this.deal.title;
        this.logger.debug('Opening WorkDetailModal');
        const modalRef = this.modalService.open(ProjectDetailModalComponent, {
            windowClass: 'modal-pane',
            animation: false,
        });
        if (this.deal.partner) {
            project.partnerUuid = this.deal.partner.uuid;
            project.partnerName = this.deal.partner.title;
            modalRef.componentInstance.partnerUuidInput = this.deal.partner.uuid;
        }
        if (this.deal.products) {
            project.products = this.deal.products;
        }
        modalRef.componentInstance.project = project;
        modalRef.componentInstance.fromDeal = true;
    }

    getRottingIn(): string {
        const rottingIn = new Date(this.deal.dateSinceLastMovement).getDate() + this.deal.crmStage.rottingIn - new Date().getDate();
        if (rottingIn <= 0) {
            return `${rottingIn} days <span class="badge badge--danger">Overdue</span>`;
        } else if (rottingIn == 1) {
            return `${rottingIn} day <span class="badge badge--warning">Hurry</span>`;
        } else {
            return `${rottingIn} days`;
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
