import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { FetchSimpleInformationForAllEmployees } from 'src/app/employees/employees.actions';
import { EmployeeState } from 'src/app/employees/employees.state';
import { Employee } from 'src/app/models/Employee';

import { AddTimesheet } from '../timesheets.actions';

@Component({
    selector: 'app-add-timesheet-modal',
    templateUrl: './add-timesheet-modal.component.html',
    styleUrls: ['./add-timesheet-modal.component.scss'],
})
export class AddTimesheetModalComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();
    @Select(EmployeeState.EmployeesSimpleInformation) employees$: Observable<Employee[]>;
    @Input() currentEmployees: Employee[];

    checkedEmployees: Employee[] = [];
    allCheck: boolean = false;
    hoursRequired: number = 0;

    isLoading: boolean = true;
    availableEmployees: Employee[];

    constructor(private store: Store, public activeModal: NgbActiveModal, private toastr: ToastrService, private logger: NGXLogger) {}

    ngOnInit(): void {
        this.subscriptions.add(
            this.employees$.subscribe((employees) => {
                if (!employees) {
                    this.logger.debug('Fetching employees');
                    this.store.dispatch(new FetchSimpleInformationForAllEmployees());
                    return;
                }

                this.availableEmployees = employees.filter((e) => !this.currentEmployees.find((em) => em.uuid == e.uuid)).filter((e) => !e.archived);
                this.isLoading = false;
            }),
        );
    }

    addTimesheet() {
        if (this.checkedEmployees.length > 0) {
            const lastEmployee = this.checkedEmployees[this.checkedEmployees.length - 1];
            this.logger.debug('Attempt to add a timesheet');
            this.checkedEmployees.forEach((e) => {
                this.subscriptions.add(
                    this.store.dispatch(new AddTimesheet(e.uuid, this.hoursRequired)).subscribe((res) => {
                        if (e.uuid == lastEmployee.uuid) {
                            this.logger.debug('Succesfully added timesheet');
                            this.toastr.success('Successfully created ' + this.checkedEmployees.length + ' timesheets.');
                            this.activeModal.close();
                        }
                    }),
                );
            });
        }
    }

    checkAll() {
        if (this.checkedEmployees.length == this.availableEmployees.length && this.allCheck) {
            this.checkedEmployees = [];
            this.allCheck = false;
        } else {
            this.checkedEmployees = this.availableEmployees;
            this.allCheck = true;
        }
    }

    check(employee: Employee) {
        if (this.isChecked(employee)) {
            this.checkedEmployees = this.checkedEmployees.filter((e) => e.uuid != employee.uuid);
            this.allCheck = false;
        } else {
            this.checkedEmployees.push(employee);
        }
    }

    isChecked(employee: Employee): boolean {
        return this.checkedEmployees.includes(employee);
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
