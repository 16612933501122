<app-app-holder>
    <app-navbar></app-navbar>

    <app-sidebar>
        <div class="sidebar-spacing">
            <h3>Yuki</h3>
            <small>Folders</small>
        </div>

        <span class="divider divider--small"></span>
        <nav
            *ngIf="folders$ && currentFolder$ | async; else loading"
            class="app__subnav">
            <a
                (click)="selectFolder(folder)"
                *ngFor="let folder of folders"
                [ngClass]="{ app__subnav__active: folder?.id === selectedFolder?.id }">
                <span class="w-100">
                    {{ folder.description }}
                    <div
                        *ngIf="folder.numberOfUnverifiedDocuments && folder.numberOfUnverifiedDocuments > 0"
                        [ngbTooltip]="unverifiedDocumentsTip"
                        class="tag tag--badge m-l-2">
                        {{ folder.numberOfUnverifiedDocuments }}
                    </div>
                </span>
                <ng-template #unverifiedDocumentsTip> Number of unverified invoices in this folder </ng-template>
            </a>
        </nav>
    </app-sidebar>

    <app-content class="overflow-y-scroll">
        <div class="app__title">
            <h1>Invoices</h1>
            <div class="d-flex align-items-center">
                <label
                    class="m-r-2 form-helptext"
                    for="refresh-btn"
                    >Last sync: {{ lastYukiSync }} ago</label
                >
                <button
                    (click)="syncDocuments()"
                    [ngClass]="{ 'button--refresh': isSyncingDocuments }"
                    class="button button--icon m-r-2"
                    id="refresh-btn"
                    type="button">
                    <i class="las la-sync"></i>
                </button>
                <button
                    (click)="openYukiSettingsModal()"
                    class="button button--icon">
                    <i class="las la-cog"></i>
                </button>
                <button (click)="openCreateInvoiceModal()" class="button button--primary m-l-2">Create invoice</button>
            </div>
        </div>

        <span *appIsPermitted="{ module: 'YUKI', operator: 'AND', actions: ['READ'] }">
            <div *ngIf="folders$ | async as folders; else loading">
                <app-yuki-documents-list *ngIf="folders.length > 0; else empty"></app-yuki-documents-list>
            </div>
        </span>
    </app-content>

    <ng-template #loading>
        <app-loading-message></app-loading-message>
    </ng-template>

    <ng-template #empty>
        <app-message-empty
            [icon]="'../../assets/img/open-box.png'"
            [subtitle]="'Start creating folders!'"
            [title]="'No Yuki folders...'">
        </app-message-empty>
    </ng-template>
</app-app-holder>
