<div class="modal-header">
    <h2
        class="modal-title"
        id="exampleModalLabel">
        Submit for review
    </h2>
    <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="decline()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    By submitting this timesheet, your timesheet will be reviewed and cannot be changed anymore. To make changes, please contact your administrator.
</div>

<div class="modal-footer">
    <button
        type="button"
        class="button button--outline"
        (click)="decline()">
        Save draft
    </button>
    <button
        type="button"
        class="button button--primary"
        (click)="confirm()">
        Submit for review
    </button>
</div>
