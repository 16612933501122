<a
    class="pill pill--contact"
    *ngIf="!isLoading"
    (click)="openContactpersonDetail()">
    <img src="https://eu.ui-avatars.com/api/?background=random&bold=true&name={{ getTitle() }}" />
    {{ getTitle() }}
</a>

<a
    class="pill pill--contact"
    *ngIf="isLoading">
    <app-loading-message></app-loading-message>
</a>
