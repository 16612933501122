<div class="modal-header">
    <h2
        class="modal-title"
        id="exampleModalLabel">
        {{ timesheet.employee.firstName }} {{ timesheet.employee.lastName }} - {{ timesheet.expirationDate | date: 'MMMM, yyyy' }}
    </h2>
    <span></span>
    <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <ul
        class="list__specs"
        *ngIf="!isLoading">
        <li>
            <label>Employee</label>
            <app-pill-employee [employeeId]="timesheet.employee.uuid"></app-pill-employee>
        </li>
        <li>
            <label>Happiness</label>
            <div class="d-flex space-between align-items-center w-100">
                <div class="d-flex align-items-center">
                    <span *ngIf="timesheet.happyScore !== null; else noHappyScore">
                        <span class="happiness-emoji m-r-2">{{ emojis[timesheet.happyScore].emoji }}</span>
                        (score: {{ timesheet.happyScore + 1 }}/{{ this.emojis.length }})
                    </span>
                    <ng-template #noHappyScore>
                        <span class="happiness-emoji happiness-emoji--gray">🙂</span>
                    </ng-template>
                </div>

                <button
                    type="button"
                    (click)="openHappinessScoreModal()"
                    class="button button--outline button--small button--icon">
                    <i class="las la-pen"></i>
                </button>
            </div>
        </li>
        <li>
            <label>Current status</label>
            <span class="{{ timesheet.status.cssClass }}">{{ timesheet.status.displayName }}</span>
        </li>
        <li><label>Expiration date</label>{{ timesheet.expirationDate | date: 'MMMM d, y, h:mm' }}</li>
        <li *ngIf="timesheet.hoursRequired">
            <label>Required hours</label>{{ timesheet.hoursRequired }} ({{ getTotalDaysFromMinutes(timesheet.hoursRequired * 60) }} days)
        </li>
        <li>
            <label>Total hours</label> {{ getTotalMinutes(timesheet) | minutesToMinutesAndHours }} ({{ getTotalDaysFromTimesheet(timesheet) }} days)
            <app-percentage-progress
                [showPercentage]="false"
                [percentage]="getRequiredHoursDiffPercentage(timesheet)"></app-percentage-progress>
        </li>
        <li>
            <label>Hour difference</label>
            <span
                *ngIf="timesheet.hoursRequired > getTotalMinutes(timesheet) / 60"
                class="tag tag--danger m-l-2"
                ><i class="las la-angle-double-down"></i> {{ getRequiredHoursDiff(timesheet) | minutesToMinutesAndHours }} needed ({{
                    getRequiredHoursDiffPercentage(timesheet)
                }}%)</span
            >
            <span
                *ngIf="timesheet.hoursRequired === getTotalMinutes(timesheet) / 60"
                class="tag tag--green m-l-2"
                ><i class="las la-check-circle"></i> hours match</span
            >
            <span
                *ngIf="timesheet.hoursRequired < getTotalMinutes(timesheet) / 60"
                class="tag tag--danger m-l-2"
                ><i class="las la-angle-double-up"></i> {{ getRequiredHoursDiff(timesheet) | minutesToMinutesAndHours }} to much ({{
                    getRequiredHoursDiffPercentage(timesheet)
                }}
                %)</span
            >
        </li>
        <li *ngIf="billablePercentage"><label>Billable</label>{{ billablePercentage }}%</li>
        <li *ngIf="actualIncome"><label>Actual income</label>{{ actualIncome | currency: 'EUR' }}</li>
        <li *ngIf="timesheet.extraInfo">
            <div class="message message--info m-t-2 m-b-2 w-100">
                <p><strong>Extra info</strong></p>
                <p>{{ timesheet.extraInfo }}</p>
            </div>
        </li>
    </ul>
    <app-loading-message *ngIf="isLoading"></app-loading-message>
    <app-timeslot-list
        (changeEvent)="timeslotUpdated()"
        [timesheet]="timesheet"></app-timeslot-list>
    <div *ngIf="notLinkedProjects !== '' && !isLoading">
        <div class="message message--error m-t-2 m-b-2">
            <p><strong>Not all projects are linked to this employee!</strong></p>
            <p>{{ notLinkedProjects }}</p>
        </div>
    </div>
</div>

<div class="modal-footer">
    <button
        type="button"
        class="button button--outline"
        (click)="activeModal.dismiss()">
        Close
    </button>

    <span
        ngbDropdown
        class="dropdown"
        *appIsPermitted="{ module: 'TIMESHEETS', operator: 'OR', actions: ['EDIT', 'DELETE'] }">
        <button
            ngbDropdownToggle
            class="button dropdown-toggle"
            id="dropdownMenuButton">
            Options
        </button>
        <div
            ngbDropdownMenu
            class="dropdown-menu dropdown-menu-right"
            aria-labelledby="dropdownMenuButton">
            <a
                *appIsPermitted="{ module: 'TIMESHEETS', operator: 'AND', actions: ['UPDATE'] }"
                class="dropdown-item"
                (click)="editHoursRequired()"
                >Edit required hours</a
            >
            <a
                *appIsPermitted="{ module: 'TIMESHEETS', operator: 'AND', actions: ['READ'] }"
                class="dropdown-item"
                (click)="copyUrlToClipboard()"
                >Copy url to clipboard</a
            >
            <a
                *appIsPermitted="{ module: 'TIMESHEETS', operator: 'AND', actions: ['READ'] }"
                class="dropdown-item"
                (click)="sendReminder()"
                >Send reminder</a
            >
            <span *appIsPermitted="{ module: 'TIMESHEETS', operator: 'AND', actions: ['UPDATE'] }">
                <a
                    *ngIf="timesheet.status.key !== 'DONE'"
                    class="dropdown-item"
                    (click)="changeStatus('DONE')"
                    >Approve</a
                >
            </span>
            <span *appIsPermitted="{ module: 'TIMESHEETS', operator: 'AND', actions: ['UPDATE'] }">
                <a
                    *ngIf="timesheet.status.key !== 'REVIEW'"
                    class="dropdown-item"
                    (click)="changeStatus('REVIEW')"
                    >Mark as review</a
                >
            </span>
            <span *appIsPermitted="{ module: 'TIMESHEETS', operator: 'AND', actions: ['UPDATE'] }">
                <a
                    *ngIf="timesheet.status.key !== 'EDIT'"
                    class="dropdown-item"
                    (click)="changeStatus('EDIT')"
                    >Reject</a
                >
            </span>

            <a
                class="dropdown-item color--red"
                *appIsPermitted="{ module: 'TIMESHEETS', operator: 'AND', actions: ['DELETE'] }"
                (click)="deleteTimesheet()"
                >Delete</a
            >
        </div>
    </span>
</div>
