import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { AuthenticationService } from '../authentication/authentication.service';
import LogCategory from '../models/Logbook/LogCategory';
import LogItem from '../models/Logbook/LogItem';

@Injectable({
    providedIn: 'root',
})
export class LogbookService {
    httpOptions = {};

    constructor(private httpClient: HttpClient, private authenticationService: AuthenticationService) {
        this.httpOptions = authenticationService.getHeaders();
    }

    getAllLogCategories(): Observable<LogCategory[]> {
        return this.httpClient.get<LogCategory[]>(`${environment.baseUrl}/logbook/category`, this.httpOptions);
    }

    addLogCategory(logCategory: LogCategory): Observable<LogCategory> {
        return this.httpClient.post<LogCategory>(`${environment.baseUrl}/logbook/category`, logCategory, this.httpOptions);
    }

    deleteLogCategory(categoryId: string): Observable<void> {
        return this.httpClient.delete<void>(`${environment.baseUrl}/logbook/category/${categoryId}`, this.httpOptions);
    }

    addLogItem(logItem: LogItem): Observable<LogItem> {
        return this.httpClient.post<LogItem>(`${environment.baseUrl}/logbook`, logItem, this.httpOptions);
    }

    getLogItemsByObjectId(objectId: string): Observable<LogItem[]> {
        return this.httpClient.get<LogItem[]>(`${environment.baseUrl}/logbook/${objectId}`, this.httpOptions);
    }

    deleteLogItem(logItemId: string): Observable<void> {
        return this.httpClient.delete<void>(`${environment.baseUrl}/logbook/${logItemId}`, this.httpOptions);
    }

    updateLogItem(logItemId: string, logItem: LogItem): Observable<LogItem> {
        return this.httpClient.put<LogItem>(`${environment.baseUrl}/logbook/${logItemId}`, logItem, this.httpOptions);
    }
}
