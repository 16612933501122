<div class="modal-header">
    <app-loading-message *ngIf="isLoading"></app-loading-message>
    <h2
        *ngIf="!isLoading"
        class="modal-title"
        id="exampleModalLabel">
        {{ deal.title }}
    </h2>
    <span></span>
    <button
        *ngIf="!isLoading"
        type="button"
        class="close"
        aria-label="Close"
        (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<nav
    ngbNav
    #nav="ngbNav"
    class="tabs__modal">
    <ng-container ngbNavItem>
        <a ngbNavLink>Deal details</a>
        <ng-template ngbNavContent>
            <div *ngIf="deal.status.toString() === 'WON'">
                <app-primary-message
                    *appIsPermitted="{
                        module: 'PROJECT',
                        operator: 'AND',
                        actions: ['CREATE']
                    }"
                    [title]="'🥳 You won this deal!'"
                    [message]="'Nice job! You can now convert this deal to a project. Click here to do this.'"
                    (click)="createProject()"
                    [messageSuccess]="true">
                </app-primary-message>
            </div>

            <ul class="list__specs">
                <li><label>Current stage</label> {{ deal.crmStage?.name }}</li>
                <li><label>Price</label> &euro;{{ deal.price }}</li>
                <li><label>Probability</label> {{ deal.crmStage?.probability }}%</li>
                <li>
                    <label>Status</label>
                    <span class="tag"> {{ deal.status }}</span>
                </li>
                <li>
                    <label>Last moved</label>
                    {{ deal.dateSinceLastMovement | date: 'medium' }}
                </li>
                <li *ngIf="deal.status.toString() === 'ONGOING'">
                    <label>Rotting in</label>
                    <span [innerHTML]="getRottingIn()"></span>
                </li>
                <li *ngIf="deal.partnerUuid">
                    <label>Company</label>
                    <app-pill-partner [partnerUuid]="deal.partnerUuid"></app-pill-partner>
                </li>
                <li *ngIf="deal.contactPersonUuid">
                    <label>Contact</label>
                    <app-pill-contact [contactPersonUuid]="deal.contactPersonUuid"></app-pill-contact>
                </li>
                <li *ngIf="deal.customerUuid">
                    <label>Customer</label>
                    <app-pill-customer [customerUuid]="deal.customerUuid"></app-pill-customer>
                </li>
                <li *ngIf="deal.reason && deal.reason !== ''"><label>Reason</label> {{ deal.reason.reason }}</li>
                <li *ngIf="comanageOffer">
                    <label>Offer</label>
                    <app-pill-comanage-offer [offer]="comanageOffer"></app-pill-comanage-offer>
                </li>
                <li>
                    <label>Tags</label>
                    <app-global-tags
                        [objectUuid]="deal.uuid"
                        [type]="'deal'"></app-global-tags>
                </li>
                <li *ngIf="deal.description">
                    <div>Description</div>
                </li>
                <div *ngIf="deal.description">
                    <div class="message message--info m-b-2">
                        <p class="format-newlines">{{ deal.description }}</p>
                    </div>
                </div>
            </ul>
        </ng-template>
    </ng-container>
    <ng-container
        ngbNavItem
        *ngIf="this.moduleService.checkModuleActive(eModule.INVENTORY)">
        <a ngbNavLink>Products linked</a>
        <ng-template ngbNavContent>
            <table
                *ngIf="deal.products"
                mat-table
                [dataSource]="deal.products"
                matSort>
                <ng-container matColumnDef="product title">
                    <th
                        mat-header-cell
                        *matHeaderCellDef
                        mat-sort-header>
                        Product title
                    </th>
                    <td
                        mat-cell
                        *matCellDef="let product">
                        <app-pill-product [product]="product"></app-pill-product>
                    </td>
                </ng-container>

                <ng-container matColumnDef="edit">
                    <th
                        mat-header-cell
                        *matHeaderCellDef
                        class="td-small"></th>
                    <td
                        mat-cell
                        *matCellDef="let product"
                        class="td-small">
                        <span
                            ngbDropdown
                            *appIsPermitted="{
                                module: 'CRM',
                                operator: 'AND',
                                actions: ['EDIT']
                            }">
                            <button
                                ngbDropdownToggle
                                class="button button--icon button--dropdown button--small"></button>
                            <span
                                ngbDropdownMenu
                                class="dropdown-menu dropdown-menu-right"
                                aria-labelledby="dropdownMenuButton">
                                <a
                                    class="dropdown-item color--red"
                                    *appIsPermitted="{
                                        module: 'CRM',
                                        operator: 'AND',
                                        actions: ['EDIT']
                                    }"
                                    (click)="removeProduct(product)"
                                    >Remove product</a
                                >
                            </span>
                        </span>
                    </td>
                </ng-container>

                <tr
                    mat-header-row
                    *matHeaderRowDef="displayedColumns"></tr>
                <tr
                    mat-row
                    *matRowDef="let row; columns: displayedColumns"></tr>
            </table>

            <table>
                <tr>
                    <td
                        colspan="3"
                        class="table-view__add"
                        *appIsPermitted="{
                            module: 'CRM',
                            operator: 'AND',
                            actions: ['EDIT']
                        }"
                        (click)="openProductAddModal()">
                        <i class="las la-plus"></i> Add product
                    </td>
                </tr>
            </table>
        </ng-template>
    </ng-container>
    <ng-container
        ngbNavItem
        *ngIf="this.moduleService.checkModuleActive(eModule.COMMENTS)">
        <a ngbNavLink>Comments</a>
        <ng-template ngbNavContent>
            <div>
                <app-comments-overview [objectId]="deal.uuid"></app-comments-overview>
            </div>
        </ng-template>
    </ng-container>
</nav>

<div
    class="modal-body"
    *ngIf="!isLoading">
    <div [ngbNavOutlet]="nav"></div>
</div>

<div
    class="modal-body"
    *ngIf="isLoading">
    <app-loading-message></app-loading-message>
</div>

<div class="modal-footer">
    <button
        type="button"
        class="button button--outline"
        (click)="activeModal.dismiss()">
        Close
    </button>

    <span
        ngbDropdown
        class="dropdown">
        <div
            class="modal-body"
            *ngIf="isLoading">
            <app-loading-message></app-loading-message>
        </div>
        <button
            ngbDropdownToggle
            class="button dropdown-toggle"
            id="dropdownMenuButton"
            *ngIf="!isLoading">
            Options
        </button>
        <div
            ngbDropdownMenu
            class="dropdown-menu dropdown-menu-right"
            aria-labelledby="dropdownMenuButton"
            *ngIf="!isLoading">
            <span
                *appIsPermitted="{
                    module: 'PROJECT',
                    operator: 'AND',
                    actions: ['CREATE']
                }">
                <a
                    *ngIf="deal.status.toString() === 'WON'"
                    class="dropdown-item"
                    (click)="createProject()"
                    >Convert to project</a
                >
            </span>
            <a
                class="dropdown-item"
                *appIsPermitted="{
                    module: 'CRM',
                    operator: 'AND',
                    actions: ['EDIT']
                }"
                (click)="update()"
                >Edit deal</a
            >
            <div
                class="dropdown-divider"
                *appIsPermitted="{
                    module: 'CRM',
                    operator: 'AND',
                    actions: ['EDIT', 'DELETE']
                }"></div>
            <a
                class="dropdown-item color--red"
                *appIsPermitted="{
                    module: 'CRM',
                    operator: 'AND',
                    actions: ['DELETE']
                }"
                (click)="archive()"
                >Archive deal</a
            >
        </div>
    </span>
</div>
