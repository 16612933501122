import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ComanageInvoice } from '../models/ComanageInvoice';
import { CheckComanageApiKey, FetchContacts, FetchCustomers, FetchInvoices, FetchOffers } from './comanage.actions';
import { ComanageService } from './comanage.service';

export interface ComanageStateModel {
    apiKeyExists: boolean | null;
    contacts: any[];
    customers: any[];
    invoices: ComanageInvoice[];
    offers: any[];
}

@State<ComanageStateModel>({
    name: 'comanage',
    defaults: {
        apiKeyExists: null,
        contacts: null,
        customers: null,
        invoices: null,
        offers: null,
    },
})
@Injectable()
export class ComanageState {
    @Selector()
    static apiKeyExists(state: ComanageStateModel): boolean {
        return state?.apiKeyExists;
    }
    @Selector()
    static contacts(state: ComanageStateModel): any[] {
        return state?.contacts;
    }

    @Selector()
    static customers(state: ComanageStateModel): any[] {
        return state?.customers;
    }

    @Selector()
    static invoices(state: ComanageStateModel): any[] {
        return state?.invoices;
    }

    @Selector()
    static offers(state: ComanageStateModel): any[] {
        return state?.offers;
    }

    constructor(private comanageService: ComanageService, private toastr: ToastrService) {}

    @Action(CheckComanageApiKey)
    getComanageApiKeyExists(ctx: StateContext<ComanageStateModel>): Observable<boolean> {
        if (ctx.getState().apiKeyExists === null) {
            return this.comanageService.getKeyExists().pipe(
                tap((result) => {
                    ctx.setState(
                        patch<ComanageStateModel>({
                            apiKeyExists: result.key !== null,
                        }),
                    );
                    if (result.key === null) this.showErrorMessage();
                    return result.key !== null;
                }),
            );
        } else {
            if (!ctx.getState().apiKeyExists) this.showErrorMessage();
            new Observable((subscriber) => subscriber.next(ctx.getState().apiKeyExists === true));
        }
    }

    @Action(FetchContacts)
    fetchContacts(ctx: StateContext<ComanageStateModel>) {
        this.getComanageApiKeyExists(ctx);
        if (ctx.getState().apiKeyExists) {
            return this.comanageService.getContacts().pipe(
                tap((contacts: any) => {
                    ctx.setState(
                        patch<ComanageStateModel>({
                            contacts: contacts,
                        }),
                    );
                }),
            );
        }
    }

    @Action(FetchCustomers)
    fetchCustomers(ctx: StateContext<ComanageStateModel>) {
        this.getComanageApiKeyExists(ctx);
        if (ctx.getState().apiKeyExists) {
            return this.comanageService.getCustomers().pipe(
                tap((customers: any) => {
                    ctx.setState(
                        patch<ComanageStateModel>({
                            customers: customers,
                        }),
                    );
                }),
            );
        }
    }

    @Action(FetchInvoices)
    fetchInvoices(ctx: StateContext<ComanageStateModel>) {
        this.getComanageApiKeyExists(ctx);
        if (ctx.getState().apiKeyExists) {
            return this.comanageService.getInvoices().pipe(
                tap((invoices: ComanageInvoice[]) => {
                    ctx.setState(
                        patch<ComanageStateModel>({
                            invoices: invoices,
                        }),
                    );
                }),
            );
        }
    }

    @Action(FetchOffers)
    fetchOffers(ctx: StateContext<ComanageStateModel>) {
        this.getComanageApiKeyExists(ctx);
        if (ctx.getState().apiKeyExists) {
            return this.comanageService.getOffers().pipe(
                tap((offers: any) => {
                    ctx.setState(
                        patch<ComanageStateModel>({
                            offers: offers,
                        }),
                    );
                }),
            );
        }
    }

    showErrorMessage() {
        this.toastr.error('The Comanage API is not set. To allow full functionality make sure to add it inside your Comanage settings.', 'API Key missing');
    }
}
