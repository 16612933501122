<div class="app__title">
    <h1>
        Leads
        <span *ngIf="dataSource.data">({{ dataSource.data.length }})</span>
    </h1>

    <span>
        <!-- TODO: Enkel indien channel geselecteerd is -->
        <ng-template #popContent> Hier komt de description van deze channel </ng-template>
        <span
            class="button button--icon button--outline m-r-2"
            [ngbPopover]="popContent"
            popoverTitle="About channeltitle"
            ><i class="uil uil-info-circle"></i
        ></span>

        <!-- TODO: Enkel indien channel geselecteerd is -->
        <span ngbDropdown>
            <button
                ngbDropdownToggle
                class="button button--icon button--dropdown">
                Options
            </button>
            <span
                ngbDropdownMenu
                class="dropdown-menu dropdown-menu-right"
                aria-labelledby="dropdownMenuButton">
                <a
                    (click)="openLeadAddModal()"
                    *appIsPermitted="{
                        module: 'LEADS',
                        operator: 'AND',
                        actions: ['CREATE']
                    }"
                    class="dropdown-item"
                    >Add lead</a
                >
                <a class="dropdown-item">Edit channel</a>
                <a class="dropdown-item color--red">Archive channel</a>
            </span>
        </span>
    </span>
</div>

<div>
    <div class="d-flex nowrap">
        <span
            ngbDropdown
            class="dropdown m-r-2">
            <button
                ngbDropdownToggle
                class="button button--select">
                Selected ({{ getAmount() }})
            </button>
            <span
                ngbDropdownMenu
                class="dropdown-menu-right">
                <div
                    ngbDropdownItem
                    *appIsPermitted="{
                        module: 'MAILCHIMP',
                        operator: 'AND',
                        actions: ['CREATE']
                    }"
                    (click)="addToMailchimp()">
                    Add to Mailchimp
                </div>
                <div
                    ngbDropdownItem
                    class="color--red"
                    *appIsPermitted="{
                        module: 'LEADS',
                        operator: 'AND',
                        actions: ['DELETE']
                    }"
                    (click)="archiveLeads()">
                    Archive
                </div>
            </span>
        </span>
        <div class="w-100">
            <app-searchbar
                [(value)]="filter"
                (valueChange)="applyFilter($event)"></app-searchbar>
        </div>
    </div>

    <div *ngIf="dataSource.data && dataSource.data.length === 0; then empty"></div>

    <div
        class="m-t-4 table__overflow"
        [hidden]="!(dataSource.data.length > 0)">
        <table
            mat-table
            [dataSource]="dataSource"
            matSort
            matSortActive="email"
            matSortDirection="asc">
            <ng-container matColumnDef="checkbox">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    class="td-small">
                    <input
                        type="checkbox"
                        (change)="checkAll()"
                        [checked]="allCheck" />
                </th>
                <td
                    mat-cell
                    *matCellDef="let row">
                    <input
                        type="checkbox"
                        (change)="checkChange(row)"
                        [checked]="getCurrentCheck(row)" />
                </td>
            </ng-container>

            <ng-container matColumnDef="read">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                    class="td-small">
                    Read
                </th>
                <td
                    mat-cell
                    *matCellDef="let row">
                    <span
                        class="badge"
                        *ngIf="row.read"
                        >READ</span
                    >
                    <span
                        class="badge badge--success"
                        *ngIf="!row.read"
                        >NEW</span
                    >
                </td>
            </ng-container>

            <ng-container matColumnDef="email">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header>
                    Lead
                </th>
                <td
                    mat-cell
                    *matCellDef="let row">
                    <app-pill-lead
                        [lead]="row"
                        [archived]="dataSourceIsOfTypeArchived"></app-pill-lead>
                </td>
            </ng-container>

            <ng-container matColumnDef="fullname">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                    class="td-small">
                    Full name
                </th>
                <td
                    mat-cell
                    *matCellDef="let row">
                    {{ row.firstName }} {{ row.lastName }}
                </td>
            </ng-container>

            <ng-container matColumnDef="createdOn">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header>
                    Created
                </th>
                <td
                    mat-cell
                    *matCellDef="let row">
                    {{ row.createdOn }}
                </td>
            </ng-container>

            <ng-container matColumnDef="channelKey">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header>
                    Channel
                </th>
                <td
                    mat-cell
                    *matCellDef="let row">
                    {{ row.channelKey }}
                </td>
            </ng-container>

            <ng-container matColumnDef="edit">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    class="td-small"></th>
                <td
                    mat-cell
                    *matCellDef="let row">
                    <span ngbDropdown>
                        <button
                            ngbDropdownToggle
                            class="button button--icon button--dropdown button--small"></button>
                        <span
                            ngbDropdownMenu
                            class="dropdown-menu dropdown-menu-right"
                            aria-labelledby="dropdownMenuButton">
                            <a
                                class="dropdown-item"
                                (click)="toggleLeadRead(row)"
                                *appIsPermitted="{
                                    module: 'LEADS',
                                    operator: 'AND',
                                    actions: ['READ']
                                }">
                                <div *ngIf="row.read; else elseBlock">Mark as unread</div>
                                <ng-template #elseBlock>Mark as read</ng-template>
                            </a>
                            <a
                                class="dropdown-item"
                                *appIsPermitted="{
                                    module: 'LEADS',
                                    operator: 'AND',
                                    actions: ['EDIT']
                                }"
                                (click)="openLeadUpdateModal(row, true)"
                                >Edit lead</a
                            >
                            <a
                                class="dropdown-item"
                                *appIsPermitted="{
                                    module: 'CRM',
                                    operator: 'AND',
                                    actions: ['CREATE']
                                }"
                                (click)="openLeadToDealModal(row)"
                                >Create deal</a
                            >
                            <span
                                *appIsPermitted="{
                                    module: 'LEADS',
                                    operator: 'AND',
                                    actions: ['DELETE']
                                }">
                                <a
                                    *ngIf="channel === 'archived'"
                                    class="dropdown-item color--red"
                                    (click)="openLeadDeleteModal(row, true)"
                                    >Unarchive</a
                                >
                            </span>
                            <span
                                *appIsPermitted="{
                                    module: 'LEADS',
                                    operator: 'AND',
                                    actions: ['DELETE']
                                }">
                                <a
                                    *ngIf="channel !== 'archived'"
                                    class="dropdown-item color--red"
                                    (click)="openLeadDeleteModal(row)"
                                    >Archive</a
                                >
                            </span>
                        </span>
                    </span>
                </td>
            </ng-container>

            <tr
                mat-header-row
                *matHeaderRowDef="displayedColumns"></tr>
            <tr
                mat-row
                *matRowDef="let row; columns: displayedColumns"></tr>

            <tr *matNoDataRow>
                <td colspan="6">No leads matching the filter "{{ filter }}"</td>
            </tr>
        </table>
    </div>

    <ng-template #empty>
        <app-message-empty
            *ngIf="channel === ''"
            [title]="'No leads...'"
            [subtitle]="'Start generating leads!'"
            [icon]="'../../assets/img/open-box.png'">
        </app-message-empty>
        <app-message-empty
            *ngIf="channel === 'archived'"
            [title]="'No archived leads...'"
            [icon]="'../../assets/img/open-box.png'">
        </app-message-empty>
    </ng-template>
</div>
