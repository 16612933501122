import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';

import { UploadFileService } from './file-upload.service';

@Component({
    selector: 'app-file-upload',
    templateUrl: './file-upload.component.html',
    styleUrls: ['./file-upload.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => FileUploadComponent),
        },
    ],
})
export class FileUploadComponent implements ControlValueAccessor, OnInit, OnDestroy {
    selectedFiles: any[] = [];
    currentFileUpload: File;
    selectedFile = null;
    changeImage = false;
    file: string;
    message = '';
    @ViewChild('fileDropRef') fileInputElement: ElementRef;
    @Input() model;
    @Input() existingFileName: string;
    @Input() existingFileUrl: string;
    @Input() multi = false;
    @Input() maxFileAmount = 3;
    @Output()
    modelChange: EventEmitter<string> = new EventEmitter<string>();
    @Input() // file or image
    type = '';
    accept = '';
    fileName = '';
    defaultImage = 'http://placehold.it/100x100';
    imageAccept = 'image/x-png,image/gif,image/jpeg"';
    defaultFile = '/assets/img/pdf.png';
    fileAccept = 'application/pdf;application/doc;application/docx';
    onTouched: () => void;
    private subscriptions = new Subscription();

    constructor(private uploadService: UploadFileService, private toastr: ToastrService) {}

    propagateChange = (_: any) => undefined;

    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    ngOnInit() {
        if (!this.existingFileName && this.existingFileUrl) {
            this.existingFileName = this.existingFileUrl.split('.com/')[1].split('__')[0];
        }

        if (this.type == 'file') {
            this.accept = this.fileAccept;
        }
        if (this.type == 'image') {
            this.accept = this.imageAccept;
        }

        if (!this.multi) {
            this.model = '';
        }
        if (this.multi) {
            this.model = [];
        }
    }

    writeValue(value) {
        if (value) {
            this.model = value;

            if (this.multi) {
                this.selectedFiles = this.model;
            } else {
                this.extractFileNameFromURL(this.model);
            }
        }
    }

    change(event) {
        this.changeImage = true;
    }

    changedImage(event) {
        this.selectedFile = event.target.files[0];
    }

    upload(files: any) {
        for (let index = 0; index < files.length; index++) {
            this.currentFileUpload = files.item(index);

            this.subscriptions.add(
                this.uploadService.pushFileToStorage(this.currentFileUpload).subscribe(
                    (result) => {
                        if (result['ok'] && result['body']) {
                            if (!this.multi) {
                                this.model = result['body'].url;
                            }
                            if (this.multi) {
                                this.model.push(result['body'].url);
                            }

                            this.modelChange.emit(result['body'].url);
                            this.extractFileNameFromURL(result['body'].url);

                            this.propagateChange(this.model);
                            this.message = '';
                        }
                    },
                    (error) => {
                        this.message = error.error.message;
                    },
                ),
            );
        }
    }

    selectFile(files) {
        if (this.multi && files.length + this.selectedFiles.length > this.maxFileAmount) {
            this.toastr.error('Only ' + this.maxFileAmount + ' files accepted');
            return;
        }
        this.selectedFiles.push(...files);
        this.upload(files);
    }

    deleteImage(index?) {
        if (this.existingFileUrl) {
            this.subscriptions.add(
                this.uploadService.deleteFile(this.existingFileUrl).subscribe((event) => {
                    if (event['success'] == true) {
                        this.model = '';
                        this.fileInputElement.nativeElement.value = null;
                        this.currentFileUpload = undefined;
                        this.extractFileNameFromURL(undefined);
                        this.selectedFiles = undefined;
                        this.existingFileUrl = null;
                        this.existingFileName = null;
                        this.modelChange.emit(null);
                        this.propagateChange(this.model);
                    }
                }),
            );
        } else {
            if (!this.multi) {
                this.subscriptions.add(
                    this.uploadService.deleteFile(this.model).subscribe((event) => {
                        if (event['success'] == true) {
                            this.model = '';
                            this.fileInputElement.nativeElement.value = null;
                            this.currentFileUpload = undefined;
                            this.extractFileNameFromURL(undefined);
                            this.selectedFiles = undefined;
                            this.modelChange.emit(null);
                            this.propagateChange(this.model);
                        }
                    }),
                );
            } else {
                this.subscriptions.add(
                    this.uploadService.deleteFile(this.model[index]).subscribe((event) => {
                        if (event['success'] == true) {
                            const modelListArr = Array.from(this.model);
                            modelListArr.splice(index, 1);
                            this.model = modelListArr;

                            const fileListArr = Array.from(this.selectedFiles);
                            fileListArr.splice(index, 1);
                            this.selectedFiles = fileListArr;

                            this.modelChange.emit(null);
                            this.propagateChange(this.model);
                        }
                    }),
                );
            }
        }
    }

    extractFileNameFromURL(fileName) {
        if (fileName) {
            if (!this.multi) {
                this.fileName = fileName.split('/')[3].split('___')[0];
            }
        } else {
            this.fileName = '';
        }
    }

    extractFileNameFromFile(file) {
        return file.split('/')[3].split('___')[0];
    }

    /**
     * on file drop handler
     */
    onFileDropped($event) {
        const files = Array.from($event, (a, i) => $event.item(i));
        if (files.every((file) => this.accept.includes(file.type))) {
            this.selectFile($event);
        } else {
            this.toastr.warning('unsupported file type');
        }
    }

    /**
     * handle file from browsing
     */
    fileBrowseHandler(files) {
        this.selectFile(files);
    }

    /**
     * format bytes
     * @param bytes (File size in bytes)
     * @param decimals (Decimals point)
     */
    formatBytes(bytes, decimals) {
        if (bytes === 0) {
            return '0 Bytes';
        }
        const k = 1024;
        const dm = decimals <= 0 ? 0 : decimals || 2;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
