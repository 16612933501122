import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { GoogleApiService, GoogleAuthService } from 'ng-gapi';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { CompanyMember } from 'src/app/models/CompanyMember';
import { Module } from 'src/app/models/Module';

import { GoogleLoginService } from './google-login.service';
import GoogleUserInfoModel from './googleUserInfoModel';

@Component({
    selector: 'app-google-login',
    templateUrl: './google-login.component.html',
    styleUrls: ['./google-login.component.scss'],
})
export class GoogleLoginComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Input() module: Module;
    googleUserInfo: GoogleUserInfoModel[];
    loggedInMembers: CompanyMember[];
    isLoggedIn: boolean;
    operationSucceeded: boolean;
    loginPending = false;

    constructor(
        private googleLoginService: GoogleLoginService,
        private gapiService: GoogleApiService,
        private googleAuth: GoogleAuthService,
        private toastr: ToastrService,
        private logger: NGXLogger,
    ) {}

    ngOnInit(): void {
        this.subscriptions.add(this.googleLoginService.getLoggedInMembers().subscribe((m) => (this.loggedInMembers = m)));
        this.subscriptions.add(this.googleLoginService.getIsLoggedIn().subscribe((l) => (this.isLoggedIn = l)));
        this.subscriptions.add(this.googleLoginService.getLoggedInMembersGoogleInfo().subscribe((info) => (this.googleUserInfo = info)));
    }

    signInWithGoogle(): void {
        this.loginPending = true;
        this.subscriptions.add(
            this.gapiService.onLoad().subscribe(() => {
                this.googleAuth.getAuth().subscribe((auth) => {
                    auth.grantOfflineAccess().then(
                        (res) => {
                            this.logger.debug('Logged in with Google');
                            this.signInSuccessHandler(res);
                        },
                        (err) => {
                            this.loginPending = false;
                            this.logger.debug('Failed to login with Google');
                            this.toastr.error('Login failed!');
                        },
                    );
                });
            }),
        );
    }

    getGmailFromMember(uuid: string) {
        let mail = '';
        this.googleUserInfo.forEach((info) => {
            if (info.uuid == uuid) {
                mail = info.gmail;
            }
        });
        return mail;
    }

    logoutWithGoogle() {
        this.subscriptions.add(
            this.googleLoginService.logoutWithGoogle().subscribe((ops) => {
                this.operationSucceeded = ops;
                if (ops) {
                    this.isLoggedIn = !this.isLoggedIn;
                }
                this.logger.debug('Loggin out with Google');
                this.googleLoginService.getLoggedInMembers().subscribe((m) => (this.loggedInMembers = m));
            }),
        );
    }

    private signInSuccessHandler(res) {
        this.subscriptions.add(
            this.googleLoginService.loginWithGoogle(res.code).subscribe((result) => {
                if (result) {
                    this.googleLoginService.getLoggedInMembers().subscribe((m) => (this.loggedInMembers = m));
                    this.toastr.success('Logged in successfully!');
                    this.isLoggedIn = !this.isLoggedIn;
                } else {
                    this.toastr.error('Loggin failed!');
                }
                this.loginPending = false;
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
