import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { NgChartsModule } from 'ng2-charts';

import { SharedModule } from '../shared/shared.module';
import { BillableGraphWidgetComponent } from './billable-graph-widget/billable-graph-widget.component';
import { ChartInformationModalComponent } from './chart-information-modal/chart-information-modal.component';
import { EmployeeFinancialGraphsComponent } from './employee-financial-graphs/employee-financial-graphs.component';
import { EmployeeGraphComponent } from './employee-graph/employee-graph.component';
import { EmployeeGraphsComponent } from './employee-graphs/employee-graphs.component';
import { FinancialHomeComponent } from './financial-home/financial-home.component';
import { FinancialSubnavComponent } from './financial-subnav/financial-subnav.component';
import { routing } from './financial.routes';
import { GraphWidgetComponent } from './graph-widget/graph-widget.component';
import { HappinessScoreGraphWidgetComponent } from './happiness-score-graph-widget/happiness-score-graph-widget.component';
import { OverheadFinancialGraphsComponent } from './overhead-financial-graphs/overhead-financial-graphs.component';
import { OverheadGraphWidgetComponent } from './overhead-graph-widget/overhead-graph-widget.component';
import { OverheadGraphsComponent } from './overhead-graphs/overhead-graphs.component';
import { ProjectFinancialGraphsComponent } from './project-financial-graphs/project-financial-graphs.component';
import { ProjectGraphComponent } from './project-graph/project-graph.component';
import { ProjectGraphsComponent } from './project-graphs/project-graphs.component';
import { TurnoverGraphComponent } from './turnover-graph/turnover-graph.component';

@NgModule({
    declarations: [
        EmployeeGraphComponent,
        EmployeeGraphsComponent,
        ProjectGraphComponent,
        FinancialHomeComponent,
        FinancialSubnavComponent,
        OverheadGraphsComponent,
        OverheadGraphWidgetComponent,
        ChartInformationModalComponent,
        ProjectGraphsComponent,
        GraphWidgetComponent,
        BillableGraphWidgetComponent,
        HappinessScoreGraphWidgetComponent,
        EmployeeFinancialGraphsComponent,
        ProjectFinancialGraphsComponent,
        OverheadFinancialGraphsComponent,
        TurnoverGraphComponent,
    ],
    exports: [EmployeeGraphComponent, ProjectGraphComponent, OverheadGraphWidgetComponent, HappinessScoreGraphWidgetComponent],
    imports: [routing, NgChartsModule, SharedModule, CommonModule, NgbNavModule, MatTableModule, MatSortModule],
})
export class FinancialModule {}
