<div class="modal-header">
    <h2
        class="modal-title"
        id="exampleModalLabel">
        Archive company
    </h2>
    <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <strong>Are you sure you want to archive {{ partner.title }}?</strong>
    <p>Companies contain a lot of information that will be archived as well, such as contactpersons. An archived company can be unarchived at a later date.</p>
</div>

<div class="modal-footer">
    <button
        class="button button--danger"
        (click)="archivePartner()">
        Archive
    </button>
    <button
        type="button"
        class="button button--outline"
        (click)="activeModal.close()">
        Cancel
    </button>
</div>
