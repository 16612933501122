<form [formGroup]="linkFileForm">
    <div class="row">
        <div class="col">
            <span class="sectiontitle sectiontitle--first">Create new link</span>

            <ul class="list__specs">
                <li>
                    <label class="li--extra-width"><b>Amount excl. VAT</b></label>
                    <span>{{ amountExcl | currency: 'EUR':'symbol':'1.2-2' }}</span>
                </li>
            </ul>

            <div class="form-group required m-b-2">
                <label>Choose link type</label>
            </div>
            <div>
                <div
                    class="button-group form-group m-b-2"
                    role="group">
                    <div class="form-group m-b-2 button--flex">
                        <input
                            (click)="changeValue($event)"
                            autocomplete="off"
                            class="button--check"
                            formControlName="radio"
                            hidden
                            id="project-btn"
                            name="radio"
                            type="radio"
                            value="project" />
                        <label
                            class="button button--primary button--outline button--margin group-btn-label"
                            for="project-btn">
                            Project
                        </label>

                        <input
                            (click)="changeValue($event)"
                            autocomplete="off"
                            class="button--check"
                            formControlName="radio"
                            hidden
                            id="employee-btn"
                            name="radio"
                            type="radio"
                            value="employee" />
                        <label
                            class="button button--primary button--outline button--margin group-btn-label"
                            for="employee-btn">
                            Employee
                        </label>

                        <input
                            (click)="changeValue($event)"
                            autocomplete="off"
                            class="button--check"
                            formControlName="radio"
                            hidden
                            id="budget-btn"
                            name="radio"
                            type="radio"
                            value="budget" />
                        <label
                            class="button button--primary button--outline button--margin group-btn-label"
                            for="budget-btn">
                            Budget
                        </label>

                        <input
                            (click)="changeValue($event)"
                            autocomplete="off"
                            class="button--check"
                            formControlName="radio"
                            hidden
                            id="overhead-btn"
                            name="radio"
                            type="radio"
                            value="overheadCategory" />
                        <label
                            class="button button--primary button--outline button--margin group-btn-label"
                            for="overhead-btn">
                            Overhead
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div
        *ngIf="this.radio"
        class="row">
        <div class="col">
            <div
                [formGroup]="linkFileForm"
                class="form-group required m-b-2">
                <label>{{ radio | titlecase }} to link</label>
                <div class="d-flex">
                    <div class="ng-autocomplete">
                        <ng-autocomplete
                            (selected)="addLinkFile($event)"
                            [data]="showedLinkFiles | orderBy: 'displayName':false"
                            [itemTemplate]="itemTemplate"
                            [notFoundTemplate]="notFoundTemplate"
                            [searchKeyword]="'displayName'"
                            autocomplete="off"
                            formControlName="object">
                        </ng-autocomplete>

                        <ng-template
                            #itemTemplate
                            let-item>
                            <ng-container *ngIf="radio !== 'budget'; else budget">
                                <a [innerHTML]="item['displayName']"></a>
                            </ng-container>
                            <ng-template #budget>
                                <a [ngClass]="{'budget--archived': item['budgetArchived'] || item['budgetStartDate'] > today || item['budgetEndDate'] < today}">
                                    <p>
                                        <span [innerHTML]="item['displayName']"></span>
                                        <span *ngIf="item['budgetArchived'] && item['budgetStartDate'] < today && item['budgetEndDate'] > today" class="tag tag--badge m-l-2">Archived</span>
                                        <span *ngIf="!item['budgetArchived'] && (item['budgetStartDate'] > today || item['budgetEndDate'] < today)" class="tag tag--badge m-l-2">Not active</span>
                                        <span *ngIf="item['budgetArchived'] && (item['budgetStartDate'] > today || item['budgetEndDate'] < today)" class="tag tag--badge m-l-2">Archived & not active</span>
                                    </p>
                                    <p class="form-helptext form-helptext--no-padding">{{item['budgetStartDate'] | date}} - {{item['budgetEndDate'] | date}}</p>
                                </a>
                            </ng-template>
                        </ng-template>

                        <ng-template #notFoundTemplate>
                            <div>No objects found...</div>
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <div class="form-group required m-b-2">
                <label for="type">Invoice type</label>
                <select
                    formControlName="type"
                    id="type">
                    <option [ngValue]="'purchase'">Purchase</option>
                    <option [ngValue]="'sale'">Sale</option>
                </select>
            </div>
        </div>
    </div>
</form>
<div
    *ngIf="isSaving || isDeleting"
    class="row">
    <div class="row d-flex align-items-center">
        <app-loading-message class="w-auto"></app-loading-message>
        <p
            *ngIf="isSaving"
            class="form-helptext w-auto m-l-2">
            Linking in process...
        </p>
        <p
            *ngIf="isDeleting"
            class="form-helptext w-auto m-l-2">
            Deleting link in process...
        </p>
    </div>
</div>
<div
    *ngIf="linkedObjectsAreLoaded; else loading"
    class="row">
    <div *ngIf="linkedObjectsToFileDatasource.data.length > 0">
        <span
            [ngClass]="{ 'sectiontitle--first': isSaving || isDeleting }"
            class="sectiontitle"
            >Already created links</span
        >
        <table
            [dataSource]="linkedObjectsToFileDatasource"
            mat-table
            matSort
            matSortDirection="desc"
            matSortDisableClear
            matTableExporter>
            <ng-container matColumnDef="fileName">
                <th
                    *matHeaderCellDef
                    mat-header-cell>
                    File name
                </th>
                <td
                    *matCellDef="let row"
                    mat-cell>
                    <app-pill-employee
                        *ngIf="row.objectType.toLowerCase() === 'employee'"
                        [employeeId]="row.objectId">
                    </app-pill-employee>
                    <app-pill-project
                        *ngIf="row.objectType.toLowerCase() === 'project'"
                        [projectUuid]="row.objectId">
                    </app-pill-project>
                    <app-pill-budget
                        *ngIf="row.objectType.toLowerCase() === 'budget'"
                        [budgetUuid]="row.objectId">
                    </app-pill-budget>
                    <app-pill-overhead-category
                        *ngIf="row.objectType.toLowerCase() === 'overheadcategory'"
                        [overheadCategoryId]="row.objectId">
                    </app-pill-overhead-category>
                </td>
                <td
                    *matFooterCellDef
                    mat-footer-cell></td>
            </ng-container>

            <ng-container matColumnDef="description">
                <th
                    *matHeaderCellDef
                    mat-header-cell>
                    Info
                </th>
                <td
                    *matCellDef="let row"
                    class="td-icon"
                    mat-cell>
                    <input
                        (click)="$event.stopPropagation()"
                        (focusout)="updateLinkDescription(row, $event.target.value)"
                        (input)="inputEvent()"
                        [value]="row.description"
                        placeholder="Description"
                        type="text" />
                </td>
                <td
                    *matFooterCellDef
                    mat-footer-cell></td>
            </ng-container>

            <ng-container matColumnDef="linkedObject">
                <th
                    *matHeaderCellDef
                    mat-header-cell>
                    Amount
                </th>
                <td
                    *matCellDef="let row"
                    mat-cell>
                    <div class="input--currency">
                        <input
                            (click)="$event.stopPropagation()"
                            (focusout)="updateLinkAmount(row, $event.target.value)"
                            (input)="inputEvent()"
                            [value]="row.price"
                            type="number" />
                    </div>
                </td>
                <td
                    *matFooterCellDef
                    mat-footer-cell>
                    <b>{{ calculateTotalPrice() | currency: 'EUR' }}</b>
                </td>
            </ng-container>

            <ng-container matColumnDef="sublinks">
                <th
                    *matHeaderCellDef
                    mat-header-cell>
                    Sublinks
                </th>
                <td
                    *matCellDef="let row"
                    mat-cell>
                    <button
                        [ngbPopover]="popContent"
                        class="button button--icon button--small"
                        triggers="mouseenter:mouseleave">
                        <span
                            [ngClass]="{
                                'color--green': amountUsedByLinks(row.childInvoiceLinks) === 0,
                                'color--yellow': row.price > amountUsedByLinks(row.childInvoiceLinks) && amountUsedByLinks(row.childInvoiceLinks) !== 0,
                                'color--red': amountUsedByLinks(row.childInvoiceLinks) >= row.price
                            }"
                            >{{ row.childInvoiceLinks?.length ? row.childInvoiceLinks?.length : 0 }}</span
                        >
                    </button>

                    <ng-template #popContent>
                        <table
                            *ngIf="row.childInvoiceLinks?.length > 0"
                            class="table-view">
                            <tr>
                                <th>Object</th>
                                <th>Amount</th>
                            </tr>
                            <tr *ngFor="let sublink of row.childInvoiceLinks">
                                <td>
                                    {{ sublink.displayName }}
                                </td>
                                <td>
                                    {{ sublink.amount }}
                                </td>
                            </tr>
                        </table>
                    </ng-template>
                </td>
                <td
                    *matFooterCellDef
                    mat-footer-cell></td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th
                    *matHeaderCellDef
                    class="td-small"
                    mat-header-cell></th>
                <td
                    *matCellDef="let row"
                    class="td-icon"
                    mat-cell>
                    <span>
                        <span
                            (click)="$event.stopPropagation(); deleteLinkedObject(row)"
                            [disabled]="isLoading"
                            class="button button--icon button--small">
                            <i class="las la-trash color--red"></i>
                        </span>
                    </span>
                </td>
                <td
                    *matFooterCellDef
                    mat-footer-cell></td>
            </ng-container>
            <tr
                *matHeaderRowDef="displayedColumns"
                mat-header-row></tr>
            <tr
                (click)="$event.stopPropagation(); openAddChildLinkModal(row)"
                *matRowDef="let row; columns: displayedColumns"
                class="pointer"
                mat-row></tr>
            <tr
                *matFooterRowDef="displayedColumns"
                mat-footer-row></tr>
        </table>
        <div *ngIf="linkedObjectsToFileDatasource.data.length === 0">
            <p>There are no linked objects found.</p>
        </div>

        <div class="m-t-4">
            <p
                *ngIf="unsavedChanges"
                class="form-error">
                There are unsaved changes...
            </p>

            <!--
            Button with no event to remove focus from input fields when user clicks
            focusout event will be fired and changes will be saved
            -->
            <div class="d-flex align-items-center">
                <button
                    class="button button--primary m-r-4"
                    type="button">
                    Save changes
                </button>
                <app-loading-message *ngIf="isUpdating"></app-loading-message>
            </div>
        </div>
    </div>
</div>

<ng-template #loading>
    <app-loading-message></app-loading-message>
</ng-template>
