<a
    class="notification__message-wrapper"
    (click)="notificationClick()"
    [ngClass]="{ read: notification.read }">
    <p [innerHTML]="notification.message"></p>

    <div class="notification__details">
        <p class="form-helptext m-r-4">by</p>
        <app-pill-user
            *ngIf="notification.creatorId"
            [userId]="notification.creatorId"></app-pill-user>
        <p class="form-helptext">on {{ notification.created | date: 'medium' }}</p>
    </div>

    <div
        (click)="$event.stopPropagation(); archiveNotification()"
        *ngIf="!notification.archived; else archived"
        [ngbTooltip]="archiveMessageTooltip"
        class="notification__archive">
        &times;
    </div>
</a>

<ng-template #archiveMessageTooltip>Archive this budget</ng-template>

<ng-template #archived>
    <button
        (click)="$event.stopPropagation(); archiveNotification()"
        class="button button--notifications-unarchive m-t-2">
        Unarchive
    </button>
</ng-template>
