import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';
import { Employee } from 'src/app/models/Employee';
import { DeleteRelatedEmployee } from 'src/app/related-employees/related.employees.action';

@Component({
    selector: 'app-delete-employee',
    templateUrl: './delete-employee.component.html',
    styleUrls: ['./delete-employee.component.scss'],
})
export class DeleteEmployeeComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Input() objectId: string;
    @Input() employee: Employee;
    @Input() relatedEmployees: Employee[];
    @Output() deleteEvent = new EventEmitter();
    constructor(public activeModal: NgbActiveModal, private store: Store, private logger: NGXLogger) {}

    ngOnInit(): void {}

    deleteEmployee() {
        this.logger.debug('Attempt to delete related employee');
        this.subscriptions.add(
            this.store.dispatch(new DeleteRelatedEmployee(this.objectId, this.employee.uuid)).subscribe(
                () => {
                    this.logger.debug('Succesfully deleted related employee');
                    this.relatedEmployees = this.relatedEmployees.filter((e) => e.uuid !== this.employee.uuid);
                    this.activeModal.close();
                },
                (error) => this.logger.error('Failed to delete related employees'),
            ),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
