import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';

import { EmployeesDetailComponent } from '../../employees/employees-detail/employees-detail.component';
import { ObjectWithFinancialData } from '../../models/Financial/ObjectWithFinancialData';
import { DateNavigationOptions, DateNavigationType, SelectedDateClass } from '../../shared/date-navigation/date-navigation.component';
import { GetEmployeesFinancialData } from '../financial.actions';
import { FinancialState } from '../financial.state';

@Component({
    selector: 'app-employee-financial-graphs',
    templateUrl: './employee-financial-graphs.component.html',
    styleUrls: ['./employee-financial-graphs.component.scss'],
})
export class EmployeeFinancialGraphsComponent implements OnInit, OnDestroy {
    employeesWithFinancialData: ObjectWithFinancialData[];
    datasource: MatTableDataSource<ObjectWithFinancialData>;
    displayedColumns: string[] = ['employee', 'margin', 'totalCosts', 'totalIncome'];

    selectedDate: Date = new Date();
    dateNavigationOptions: DateNavigationOptions;
    fetchedDataForCurrentYear = false;
    loadingData = false;
    showArchived = false;

    private sSort: MatSort;
    private subscriptions = new Subscription();

    constructor(private store: Store, private modalService: NgbModal) {}

    @ViewChild('sort') set sort(ms: MatSort) {
        this.sSort = ms;
        this.setDataSourceAttributes();
    }

    setDataSourceAttributes(): void {
        this.datasource.sort = this.sSort;
    }

    ngOnInit(): void {
        this.dateNavigationOptions = {
            dateNavigationType: DateNavigationType.YEAR,
            selectedDate: this.selectedDate,
            selectedDateClass: SelectedDateClass.SMALL,
        };

        this.createMatTable();
        this.fetchData();
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    openEmployeeDetailModal(employeeWithFinancialData: ObjectWithFinancialData): void {
        const modalRef = this.modalService.open(EmployeesDetailComponent, {
            windowClass: 'modal-huge',
            animation: false,
        });
        modalRef.componentInstance.employeeUuid = employeeWithFinancialData.objectId;
        modalRef.componentInstance.openFinancialTab = true;
    }

    updateSelectedDate(): void {
        this.fetchedDataForCurrentYear = false;
        this.fetchData();
    }

    filterData() {
        this.datasource.filter = `${this.showArchived}`;
    }

    private fetchData(): void {
        const year: number = this.dateNavigationOptions.selectedDate.getFullYear();

        this.subscriptions.add(
            this.store.select(FinancialState.employeesFinancialDataForYear(year)).subscribe((employeesFinancialData) => {
                if (!this.fetchedDataForCurrentYear && (!employeesFinancialData || employeesFinancialData.length < 1)) {
                    this.store.dispatch(new GetEmployeesFinancialData(year));
                    this.fetchedDataForCurrentYear = true;
                    this.loadingData = true;
                }

                this.loadingData = false;
                this.employeesWithFinancialData = employeesFinancialData;
                this.datasource.data = employeesFinancialData;
                this.datasource.sort = this.sSort;
                
                this.datasource.filterPredicate = (data: ObjectWithFinancialData, filter) => {
                    if (this.showArchived) return true;
                    return !data.archived;
                };
                this.datasource.filter = `${this.showArchived}`;
            }),
        );
    }

    private createMatTable(): void {
        this.datasource = new MatTableDataSource<ObjectWithFinancialData>();
    }
}
