<div *ngIf="loading"><app-loading-message></app-loading-message></div>
<span>hello world</span>
<div class="table__overflow text-wrap">
    <table
        *ngIf="!loading && reasons.length > 0"
        mat-table
        [dataSource]="dataSource"
        matSort>
        <ng-container matColumnDef="reason">
            <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header>
                Reason
            </th>
            <td
                mat-cell
                *matCellDef="let reason"
                class="col-8 text-wrap">
                {{ reason.reason }}
            </td>
        </ng-container>

        <ng-container matColumnDef="recurrence">
            <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header>
                Recurrence
            </th>
            <td
                mat-cell
                *matCellDef="let reason">
                {{ reason.recurrence }}
            </td>
        </ng-container>

        <ng-container matColumnDef="references">
            <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header>
                Reference
            </th>
            <td
                mat-cell
                *matCellDef="let reason">
                <ul>
                    <li *ngFor="let obj of getLinkedObjectsByReason(reason.uuid)">
                        <app-pill-applicant *ngIf="this.linkedObjectType === 'applicant'" [applicant]="obj"></app-pill-applicant>
                        <app-pill-crmdeal *ngIf="this.linkedObjectType === 'deal'" [deal]="obj"></app-pill-crmdeal>
                    </li>
                </ul>
            </td>
        </ng-container>

        <ng-container matColumnDef="edit">
            <th
                mat-header-cell
                *matHeaderCellDef
                class="td-small"></th>
            <td
                mat-cell
                *matCellDef="let reason"
                class="td-small">
                <div
                    ngbDropdown
                    class="dropdown"
                    *appIsPermitted="{
                        module: 'CRM',
                        operator: 'OR',
                        actions: ['EDIT', 'DELETE']
                    }">
                    <button
                        ngbDropdownToggle
                        class="button button--icon button--small dropdown-toggle"
                        id="dropdownMenuButton"></button>
                    <div
                        ngbDropdownMenu
                        class="dropdown-menu dropdown-menu-right"
                        aria-labelledby="dropdownMenuButton">
                        <a
                            class="dropdown-item"
                            *appIsPermitted="{
                                module: 'CRM',
                                operator: 'AND',
                                actions: ['EDIT']
                            }"
                            (click)="updateReason(reason)"
                            >Edit reason</a
                        >
                        <a
                            class="dropdown-item color--red"
                            *appIsPermitted="{
                                module: 'CRM',
                                operator: 'AND',
                                actions: ['DELETE']
                            }"
                            (click)="deleteReason(reason.uuid)"
                            >Delete</a
                        >
                    </div>
                </div>
            </td>
        </ng-container>

        <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns"></tr>

        <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
</div>
<p *ngIf="!loading && reasons.length <= 0">There are no lost reasons yet.</p>
