import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { Query } from 'src/app/models/Query';
import { AddQueryModalComponent } from 'src/app/shared/add-query-modal/add-query-modal.component';
import { EditQueryModalComponent } from 'src/app/shared/edit-query-modal/edit-query-modal.component';
import { QueryDeleteModalComponent } from 'src/app/shared/query-delete-modal/query-delete-modal.component';

import { FetchQueries } from '../../velda-articles.actions';
import { VeldaArticlesState } from '../../velda-articles.state';

@Component({
    selector: 'app-velda-articles-collections-overview',
    templateUrl: './velda-articles-collections-overview.component.html',
    styleUrls: ['./velda-articles-collections-overview.component.scss'],
})
export class VeldaArticlesCollectionsOverviewComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Select(VeldaArticlesState.queries) queries$: Observable<Query[]>;
    query: Query;
    loading = true;

    constructor(private modalService: NgbModal, private store: Store) {}

    ngOnInit(): void {
        this.subscriptions.add(
            this.store.dispatch(new FetchQueries()).subscribe(() => {
                this.queries$.subscribe((queries) => {
                    this.query = queries?.find((q) => q?.type === 'COLLECTIONS');
                    this.loading = false;
                });
            }),
        );
    }

    openAddCollectionQueryModal() {
        const modalRef = this.modalService.open(AddQueryModalComponent, {
            windowClass: 'modal-prompt',
        });
        modalRef.componentInstance.type = 'COLLECTIONS';
    }

    openEditCollectionQueryModal() {
        const modalRef = this.modalService.open(EditQueryModalComponent, {
            windowClass: 'modal-prompt',
        });
        modalRef.componentInstance.query = this.query;
    }

    openDeleteCollectionQueryModal() {
        const modalRef = this.modalService.open(QueryDeleteModalComponent, {
            windowClass: 'modal-prompt',
        });
        modalRef.componentInstance.query = this.query;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
