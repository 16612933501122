import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { append, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { tap } from 'rxjs/internal/operators/tap';

import Budget from '../models/Budget';
import { Employee } from '../models/Employee';
import LinkedEmployee from '../models/LinkedEmployee';
import { LinkedEmployeeViewModel } from '../models/LinkedEmployeeViewModel';
import Project from '../models/Project';
import { ROUTES } from '../models/ROUTES';
import { StockUpdateHistoryModel } from '../models/StockUpdateHistoryModel';
import { YukiFileObject } from '../models/YukiFileObject';
import { CreateNotification } from '../notifications/notifications.actions';
import { AddUndefinedObjectTagsToObject } from '../shared/global-tags/global-tags.actions';
import {
    AddBudgetToEmployee,
    AddEmployee,
    ArchiveBudgetForEmployee,
    ArchiveEmployee,
    DeleteEmployee,
    FetchBudgetsForEmployee,
    FetchEmployees,
    FetchLinkedEmployees,
    FetchLinkedEmployeesForEmployee,
    FetchProjectList,
    FetchProjectListByCurrentEmployee,
    FetchProjectsForEmployee,
    FetchSimpleInformationForAllEmployees,
    FetchStockUpdateHistoryForEmployee,
    GetAllYukiFileObjectsByEmployee,
    GetEmployee,
    GetEmployeeById,
    UpdateEmployee,
} from './employees.actions';
import { EmployeeService } from './employees.service';

export interface EmployeeStateModel {
    employees: Employee[];
    employeesSimpleInformation: Employee[];
    currentEmployee: Employee;
    budgets: Budget[];
    linkedEmployees: LinkedEmployee[];
    linkedObjectsToEmployee: LinkedEmployeeViewModel[];
    projects: Project[];
    currentEmployeeProjects: Project[];
    currentEmployeeLinkedFiles: YukiFileObject[];
    linkedProjects: Project[];
    stockUpdateHistoryForEmployee: StockUpdateHistoryModel[];
}

@State<EmployeeStateModel>({
    name: 'employee',
    defaults: {
        employees: null,
        employeesSimpleInformation: null,
        currentEmployee: null,
        linkedEmployees: null,
        budgets: null,
        projects: null,
        currentEmployeeProjects: null,
        currentEmployeeLinkedFiles: null,
        linkedProjects: null,
        linkedObjectsToEmployee: [],
        stockUpdateHistoryForEmployee: [],
    },
})
@Injectable()
export class EmployeeState {
    constructor(private employeeService: EmployeeService, private store: Store) {}

    @Selector()
    static Employees(state: EmployeeStateModel): Employee[] {
        return state?.employees;
    }

    @Selector()
    static EmployeesSimpleInformation(state: EmployeeStateModel): Employee[] {
        return state?.employeesSimpleInformation;
    }

    @Selector()
    static LinkedEmployees(state: EmployeeStateModel): LinkedEmployee[] {
        return state?.linkedEmployees;
    }

    @Selector()
    static BirthdayEmployees(state: EmployeeStateModel): Employee[] {
        return state?.employees?.filter((employee) => employee.daysUntilBirthday >= 0 && employee.daysUntilBirthday <= 30);
    }

    @Selector()
    static WorkAnniversaryEmployees(state: EmployeeStateModel): Employee[] {
        return state?.employees?.filter((employee) => employee.daysUntilHired >= 0 && employee.daysUntilHired <= 30);
    }

    @Selector()
    static BudgetsForEmployee(state: EmployeeStateModel): Budget[] {
        return state?.budgets;
    }

    @Selector()
    static CurrentEmployee(state: EmployeeStateModel): Employee {
        return state?.currentEmployee;
    }

    @Selector()
    static CurrentEmployeeLinkedFilesObject(state: EmployeeStateModel): YukiFileObject[] {
        return state?.currentEmployeeLinkedFiles;
    }

    @Selector()
    static Projects(state: EmployeeStateModel): Project[] {
        return state?.projects;
    }

    @Selector()
    static CurrentEmployeeProjects(state: EmployeeStateModel): Project[] {
        return state?.currentEmployeeProjects;
    }

    @Selector()
    static LinkedProjects(state: EmployeeStateModel): Project[] {
        return state?.linkedProjects;
    }

    @Selector()
    static LinkedObjectsToEmployee(state: EmployeeStateModel): LinkedEmployeeViewModel[] {
        return state?.linkedObjectsToEmployee;
    }

    @Selector()
    static StockUpdateHistoryForEmployee(state: EmployeeStateModel): StockUpdateHistoryModel[] {
        return state?.stockUpdateHistoryForEmployee;
    }

    @Action(FetchEmployees)
    fetch(ctx: StateContext<EmployeeStateModel>) {
        return this.employeeService.getAllEmployees().pipe(
            tap((employees) => {
                ctx.setState(
                    patch<EmployeeStateModel>({
                        employees: employees,
                    }),
                );
            }),
        );
    }

    @Action(FetchSimpleInformationForAllEmployees)
    fetchSimpleInformationForAllEmployees(ctx: StateContext<EmployeeStateModel>) {
        return this.employeeService.getSimpleInformationForAllEmployees().pipe(
            tap((employees) => {
                ctx.setState(
                    patch<EmployeeStateModel>({
                        employeesSimpleInformation: employees,
                    }),
                );
            }),
        );
    }

    @Action(FetchLinkedEmployees)
    getLinkedEmployees(ctx: StateContext<EmployeeStateModel>, action: FetchLinkedEmployees) {
        return this.employeeService.getAllLinkedEmployees().pipe(
            tap((linkedEmp) => {
                ctx.setState(
                    patch<EmployeeStateModel>({
                        linkedEmployees: linkedEmp,
                    }),
                );
            }),
        );
    }

    @Action(FetchLinkedEmployeesForEmployee)
    fetchLinkedEmployeesForEmployee(ctx: StateContext<EmployeeStateModel>, action: FetchLinkedEmployeesForEmployee) {
        return this.employeeService.getAllLinkedEmployeesForEmployee(action.employeeId, action.type).pipe(
            tap((linkedEmployees) => {
                ctx.setState(
                    patch<EmployeeStateModel>({
                        linkedObjectsToEmployee: linkedEmployees,
                    }),
                );
            }),
        );
    }

    @Action(AddEmployee)
    addEmployee(ctx: StateContext<EmployeeStateModel>, action: AddEmployee) {
        return this.employeeService.addEmployee(action.employee).pipe(
            tap((employee) => {
                ctx.setState(
                    patch<EmployeeStateModel>({
                        employees: append<Employee>([employee]),
                        currentEmployee: employee,
                        employeesSimpleInformation: append<Employee>([employee]),
                    }),
                );
                this.store.dispatch(new AddUndefinedObjectTagsToObject(employee.uuid));
                this.store.dispatch(
                    new CreateNotification({
                        message: `A new employee with name <strong>${employee.firstName} ${employee.lastName}</strong> is created`,
                        route: ROUTES.EMPLOYEES.route,
                    }),
                );
            }),
        );
    }

    @Action(UpdateEmployee)
    updateEmployee(ctx: StateContext<EmployeeStateModel>, action: UpdateEmployee) {
        return this.employeeService.updateEmployee(action.employee).pipe(
            tap((employee) => {
                if (ctx.getState().employees) {
                    ctx.setState(
                        patch<EmployeeStateModel>({
                            employees: updateItem<Employee>((e) => e.uuid === action.employee.uuid, employee),
                        }),
                    );
                }
                if (ctx.getState().employeesSimpleInformation) {
                    ctx.setState(
                        patch<EmployeeStateModel>({
                            employeesSimpleInformation: updateItem<Employee>((e) => e.uuid === action.employee.uuid, employee),
                        }),
                    );
                }
                this.store.dispatch(
                    new CreateNotification({
                        message: `An employee with name <strong>${employee.firstName} ${employee.lastName}</strong> is updated`,
                        route: ROUTES.EMPLOYEES.route,
                    }),
                );
            }),
        );
    }

    @Action(GetAllYukiFileObjectsByEmployee)
    getAllYukiFileObjectsByEmployee(ctx: StateContext<EmployeeStateModel>, action: GetAllYukiFileObjectsByEmployee) {
        return this.employeeService.getAllYukiFileObjectsByEmployee(action.employeeUuid).pipe(
            tap((files) => {
                ctx.setState(
                    patch<EmployeeStateModel>({
                        currentEmployeeLinkedFiles: files,
                    }),
                );
            }),
        );
    }

    @Action(GetEmployee)
    getEmployee(ctx: StateContext<EmployeeStateModel>, action: GetEmployee): Employee {
        return ctx.getState().employees.find((employee) => employee.uuid === action.employeeUuid);
    }

    @Action(GetEmployeeById)
    getEmployeeById(ctx: StateContext<EmployeeStateModel>, action: GetEmployee) {
        return this.employeeService.getEmployeeById(action.employeeUuid).subscribe((e) => {
            ctx.setState(
                patch<EmployeeStateModel>({
                    currentEmployee: e,
                }),
            );
        });
    }

    @Action(DeleteEmployee)
    deleteEmployee(ctx: StateContext<EmployeeStateModel>, action: DeleteEmployee) {
        return this.employeeService.deleteEmployee(action.employeeUuid).pipe(
            tap(() => {
                const employee: Employee = ctx.getState().employees.find((employee) => employee.uuid === action.employeeUuid);
                if (ctx.getState().employees) {
                    ctx.setState(
                        patch<EmployeeStateModel>({
                            employees: removeItem<Employee>((employee) => employee.uuid === action.employeeUuid),
                        }),
                    );
                }
                if (ctx.getState().employeesSimpleInformation) {
                    ctx.setState(
                        patch<EmployeeStateModel>({
                            employeesSimpleInformation: removeItem<Employee>((employee) => employee.uuid === action.employeeUuid),
                        }),
                    );
                }
                this.store.dispatch(
                    new CreateNotification({
                        message: `An employee with name <strong>${employee.firstName} ${employee.lastName}</strong> is deleted`,
                        route: ROUTES.EMPLOYEES.route,
                    }),
                );
            }),
        );
    }

    @Action(ArchiveEmployee)
    archiveEmployee(ctx: StateContext<EmployeeStateModel>, action: DeleteEmployee) {
        return this.employeeService.archiveEmployee(action.employeeUuid).pipe(
            tap(() => {
                const employee: Employee = ctx.getState().employees.find((employee) => employee.uuid === action.employeeUuid);
                if (ctx.getState().employees) {
                    ctx.setState(
                        patch<EmployeeStateModel>({
                            employees: updateItem<Employee>(
                                (employee) => employee.uuid === action.employeeUuid,
                                patch<Employee>({
                                    archived: !employee.archived,
                                }),
                            ),
                        }),
                    );
                }
                if (ctx.getState().employeesSimpleInformation) {
                    ctx.setState(
                        patch<EmployeeStateModel>({
                            employeesSimpleInformation: removeItem<Employee>((employee) => employee.uuid === action.employeeUuid),
                        }),
                    );
                }
                this.store.dispatch(
                    new CreateNotification({
                        message: `An employee with name <strong>${employee.firstName} ${employee.lastName}</strong> is archived`,
                        route: ROUTES.EMPLOYEES.route,
                    }),
                );
            }),
        );
    }

    @Action(FetchBudgetsForEmployee)
    getBudgetsForEmployee(ctx: StateContext<EmployeeStateModel>, action: FetchBudgetsForEmployee) {
        return this.employeeService.getBudgetsForEmployee(action.employeeUuid).pipe(
            tap((budgets) => {
                ctx.setState(
                    patch<EmployeeStateModel>({
                        budgets: budgets,
                    }),
                );
            }),
        );
    }

    @Action(AddBudgetToEmployee)
    addBudgetToEmployee(ctx: StateContext<EmployeeStateModel>, action: AddBudgetToEmployee) {
        ctx.setState(
            patch<EmployeeStateModel>({
                budgets: append([action.budget]),
            }),
        );
    }

    @Action(ArchiveBudgetForEmployee)
    archiveBudgetForEmployee(ctx: StateContext<EmployeeStateModel>, action: ArchiveBudgetForEmployee) {
        if (ctx.getState().budgets) {
            ctx.setState(
                patch<EmployeeStateModel>({
                    budgets: updateItem<Budget>((b) => b.uuid === action.budget.uuid, action.budget),
                }),
            );
        }
    }

    @Action(FetchProjectList)
    fetchWorkList(ctx: StateContext<EmployeeStateModel>) {
        return this.employeeService.getAllProjects().pipe(
            tap((projects) => {
                ctx.setState(
                    patch<EmployeeStateModel>({
                        projects: projects,
                    }),
                );
            }),
        );
    }

    @Action(FetchProjectListByCurrentEmployee)
    fetchWorkListByEmployee(ctx: StateContext<EmployeeStateModel>, action: FetchProjectListByCurrentEmployee) {
        return this.employeeService.getProjectListByEmployee(action.employeeUuid).pipe(
            tap((projects) => {
                ctx.setState(
                    patch<EmployeeStateModel>({
                        currentEmployeeProjects: projects,
                    }),
                );
            }),
        );
    }

    @Action(FetchProjectsForEmployee)
    fetchProjectsForEmployee(ctx: StateContext<EmployeeStateModel>, action: FetchProjectsForEmployee) {
        ctx.setState(
            patch<EmployeeStateModel>({
                linkedProjects: null,
            }),
        );
        return this.employeeService.getAllLinkedProjects(action.employeeUuid).pipe(
            tap((projects) => {
                ctx.setState(
                    patch<EmployeeStateModel>({
                        linkedProjects: projects,
                    }),
                );
            }),
        );
    }

    @Action(FetchStockUpdateHistoryForEmployee)
    fetchStockUpdateHistoryForEmployee(ctx: StateContext<EmployeeStateModel>, action: FetchStockUpdateHistoryForEmployee) {
        return this.employeeService.getStockUpdateHistoryForEmployee(action.employeeId).pipe(
            tap((stockUpdateHistory) => {
                ctx.setState(
                    patch<EmployeeStateModel>({
                        stockUpdateHistoryForEmployee: stockUpdateHistory,
                    }),
                );
            }),
        );
    }
}
