import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'minutesToMinutesAndHours',
})
export class MinutesToMinutesAndHoursPipe implements PipeTransform {
    transform(minutes: number): string {
        if (minutes == 0) {
            return '0';
        }
        const actualMinutes = minutes % 60;
        const actualHours = (minutes - actualMinutes) / 60;
        let smallMinute = '';
        if (actualMinutes < 10) {
            smallMinute = '0';
        }
        return actualHours + ':' + smallMinute + actualMinutes;
    }
}
