<a
    *ngIf="timesheet"
    (click)="openTimesheetDetailModal(); $event.stopPropagation()"
    class="pill"
    [ngbTooltip]="tipContent"
    [ngClass]="
        timesheet.status.key === 'DONE'
            ? 'pill pill--timesheet-done'
            : timesheet.status.key === 'REVIEW'
            ? 'pill pill--timesheet-review'
            : 'pill pill--timesheet-edit'
    ">
    <ng-template #tipContent> Timesheet {{ timesheet.expirationDate | date: 'MMMM, yyyy' }} </ng-template>
    <app-loading-message *ngIf="isLoading"></app-loading-message>
    <span *ngIf="!isLoading">
        <i
            *ngIf="timesheet.status.key === 'EDIT'"
            class="las la-clock"></i>
        <i
            *ngIf="timesheet.status.key === 'REVIEW'"
            class="las la-exclamation-triangle"></i>
        <i
            *ngIf="timesheet.status.key === 'DONE'"
            class="las la-check"></i>
        {{ timesheet.employee.firstName }} {{ timesheet.employee.lastName }} -
        {{ timesheet.expirationDate | date: 'MMMM, yyyy' }}
    </span>
</a>
