import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subscription } from 'rxjs';

import PlannedEvent from '../../models/PlannedEvent';
import Project from '../../models/Project';
import { ConfirmationModalComponent } from '../../shared/confirmation-modal/confirmation-modal.component';
import { AddPlanningModalComponent } from '../add-planning-modal/add-planning-modal.component';
import { DeletePlanning, GetProjectById } from '../planning.actions';
import { PlanningState } from '../planning.state';

@Component({
    selector: 'app-planning-detail',
    templateUrl: './planning-detail.component.html',
    styleUrls: ['./planning-detail.component.scss'],
})
export class PlanningDetailComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Input() plannedEvent: PlannedEvent;
    @Input() selectedProjectId: string;
    @Input() employeeId: string;
    @Input() args;

    @Select(PlanningState.SelectedProject)
    selectedProject$: Observable<Project>;

    selectedProject?: Project;

    constructor(public activeModal: NgbActiveModal, private modalService: NgbModal, private store: Store, private logger: NGXLogger) {}

    ngOnInit(): void {
        this.fetchData();
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    private fetchData() {
        this.subscriptions.add(
            this.selectedProject$.subscribe((selectedProject) => {
                if (selectedProject) {
                    this.selectedProject = selectedProject;
                }
            }),
        );
        this.store.dispatch(new GetProjectById(this.selectedProjectId));
    }

    updateEvent() {
        const modalRef = this.modalService.open(AddPlanningModalComponent, {
            windowClass: 'modal-pane',
            animation: false,
        });
        modalRef.componentInstance.args = this.args;
        modalRef.componentInstance.plannedEvent = this.plannedEvent;
        modalRef.componentInstance.type = 'Update';
    }

    deleteEvent() {
        const modalRef = this.modalService.open(ConfirmationModalComponent, {
            windowClass: 'modal-prompt',
            animation: false,
        });
        modalRef.componentInstance.type = 'YesOrNo';
        modalRef.componentInstance.title = 'Delete event';
        modalRef.componentInstance.htmlMessage = `<p>Are you sure you want to delete <strong>${this.args.event.title}</strong>?</p>`;
        this.subscriptions.add(
            modalRef.componentInstance.closeEvent.subscribe((value) => {
                if (value) {
                    this.logger.debug('Attempt to delete event');
                    this.store.dispatch(new DeletePlanning(this.plannedEvent.uuid)).subscribe(() => {
                        this.activeModal.close();
                    });
                }
            }),
        );
    }
}
