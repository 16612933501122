<div class="modal-header">
    <h2 class="modal-title">
        <ng-container *ngIf="yukiDocument; else loading">
            {{ yukiDocument.subject }}
        </ng-container>
    </h2>

    <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<ng-container *ngIf="yukiDocument; else loading">
    <div class="h-100 overflow-y-scroll">
        <div class="two-columns">
            <app-yuki-file
                class="column-left"
                [yukiDocument]="yukiDocument"
                [fileIsFetched]="false"></app-yuki-file>

            <div class="modal-body column-right h-auto">
                <section>
                    <p class="sectiontitle sectiontitle--first">Information</p>
                    <ul class="list__specs">
                        <li>
                            <label>Subject</label>
                            <span>{{ yukiDocument.subject }}</span>
                        </li>
                        <li>
                            <label>Contact name</label>
                            <span>{{ yukiDocument.contactName }}</span>
                        </li>
                        <li>
                            <label>Type</label>
                            <span>{{ yukiDocument.typeDescription }}</span>
                        </li>
                        <li>
                            <label>Date</label>
                            <span>{{ yukiDocument.documentDate | date }}</span>
                        </li>
                    </ul>
                </section>
                <section>
                    <p class="sectiontitle">Financial information</p>
                    <ul class="list__specs">
                        <li>
                            <label>Amount excl.</label>
                            <span>{{ yukiDocument.amountExcl | currency: 'EUR' }}</span>
                        </li>
                        <li>
                            <label>Amount incl.</label>
                            <span>{{ yukiDocument.amount | currency: 'EUR' }}</span>
                        </li>
                        <li>
                            <label>VAT amount</label>
                            <span>{{ yukiDocument.vatamount | currency: 'EUR' }}</span>
                        </li>
                        <li>
                            <label>VAT</label>
                            <span *ngIf="yukiDocument.vat.value !== 'OTHER'; else otherVat">
                                {{ yukiDocument.vat.percent / 100 | percent }}
                            </span>
                            <ng-template #otherVat>Other</ng-template>
                        </li>
                    </ul>
                </section>
            </div>
        </div>
    </div>
</ng-container>

<ng-template #loading>
    <app-loading-message></app-loading-message>
</ng-template>
