import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subscription } from 'rxjs';
import { Unit } from 'src/app/models/Unit';

import { UnitAddModalComponent } from '../unit-add-modal/unit-add-modal.component';
import { UnitDeleteModalComponent } from '../unit-delete-modal/unit-delete-modal.component';
import { UnitState } from '../unit.state';

@Component({
    selector: 'app-units-list',
    templateUrl: './units-list.component.html',
    styleUrls: ['./units-list.component.scss'],
})
export class UnitsListComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Select(UnitState.Units) allUnits$: Observable<Unit[]>;

    filter: string;
    displayedColumns: string[] = ['type', 'edit'];
    dataSource: MatTableDataSource<Unit>;
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    constructor(private modalService: NgbModal, private logger: NGXLogger) {}

    ngOnInit(): void {
        this.subscriptions.add(
            this.allUnits$.subscribe((allUnits) => {
                this.dataSource = new MatTableDataSource(allUnits);
                this.dataSource.sort = this.sort;
            }),
        );
    }

    applyFilter(filterValue) {
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }

    openUnitAddModal(unit?: Unit) {
        this.logger.debug('Opening addUnitModal');
        const modalRef = this.modalService.open(UnitAddModalComponent, {
            windowClass: 'modal-prompt',
        });
        modalRef.componentInstance.unit = unit;
        unit ? (modalRef.componentInstance.isUpdate = true) : (modalRef.componentInstance.isUpdate = false);
    }

    openUnitDeleteModal(unit: Unit) {
        this.logger.debug('Opening deleteUnitModal');
        const modalRef = this.modalService.open(UnitDeleteModalComponent, {
            windowClass: 'modal-prompt',
        });
        modalRef.componentInstance.unit = unit;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
