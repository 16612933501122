<div class="login">
    <div class="login__holder">
        <app-session-header [header]="'Having troubles?'"></app-session-header>

        <p class="m-b-8">We notice you're having troubles with your login. Maybe you forgot your password or don't have an account? No problem!</p>

        <div class="m-b-6">
            <a
                routerLink="/register"
                class="button m-b-4 button--primary w-100"
                >Create account</a
            >
            <a
                routerLink="/forgotpassword"
                class="button button--outline w-100"
                >Forgot password</a
            >
        </div>

        <p class="text-center">Remember again? <a routerLink="/login">Login</a></p>
    </div>
</div>
