<div class="d-flex space-between align-items-center">
    <div
        *ngIf="!isCompact"
        [ngClass]="{
            'selected-date--header': dateNavigationOptions.selectedDateClass === 'header',
            'selected-date--small': dateNavigationOptions.selectedDateClass === 'small'
        }"
        class="selected-date">
        <p *ngIf="dateNavigationOptions.dateNavigationType.toLowerCase() === 'month'">
            {{ dateNavigationOptions.selectedDate | date: 'MMMM yyyy' }}
        </p>
        <p *ngIf="dateNavigationOptions.dateNavigationType.toLowerCase() === 'year'">
            {{ dateNavigationOptions.selectedDate | date: 'yyyy' }}
        </p>
    </div>
    <span>
        <button
            (click)="moveDate(-1)"
            class="button button--icon m-r-1">
            <i class="las la-arrow-left"></i>
        </button>
        <button
            (click)="moveDate(0)"
            class="button m-r-1">
            <ng-container *ngIf="isCompact; else fullText">{{ dateNavigationOptions.selectedDate | date: 'yyyy' }}</ng-container>
            <ng-template #fullText> This {{ dateNavigationOptions.dateNavigationType.toLowerCase() }} </ng-template>
        </button>
        <button
            (click)="moveDate(1)"
            class="button button--icon">
            <i class="las la-arrow-right"></i>
        </button>
    </span>
</div>
