import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'abbreviation',
})
export class AbbreviationPipe implements PipeTransform {
    transform(value: string): string {
        if (value) return value.length > 50 ? value.slice(0, 50) + '...' : value;
        else return value;
    }
}
