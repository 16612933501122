import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ActiveElement, Chart, ChartConfiguration, ChartDataset, ChartEvent, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';

import { createColor, createRGBAColor, hslToHex, hslToRgb } from '../../utils/color-utils';

@Component({
    selector: 'app-default-line-chart',
    templateUrl: './default-line-chart.component.html',
    styleUrls: ['./default-line-chart.component.scss'],
})
export class DefaultLineChartComponent implements OnInit, OnChanges, OnDestroy {
    @Input() xLabels: string[];
    @Input() objectLabels: string[];
    @Input() data: any[][];
    @Input() displayLegend = true;
    @Input() tension = false;
    @Input() legendPosition: LegendPosition = LegendPosition.LEFT;
    @Input() options: ChartConfiguration['options'];
    @Input() isFinancial: boolean;
    @Input() isTurnover: boolean;
    @Input() chartType: ChartType = 'line';
    @Input() ySuggestedMax: number;
    @Input() ySuggestedMin: number;
    @Output() clickEvent = new EventEmitter<CalendarClickEventData>();
    dataLoaded = false;
    chartLoaded = false;
    public chartData: ChartConfiguration['data'] = {
        datasets: [],
        labels: [],
    };
    public chartOptions: ChartConfiguration['options'] = {
        onHover(event: any, elements: ActiveElement[], chart: Chart) {
            if (elements.length === 1) event.native.target.style.cursor = 'pointer';
            if (elements.length === 0) event.native.target.style.cursor = 'default';
        },
        responsive: true,
        plugins: {
            legend: {
                display: this.displayLegend,
            },
        },
        scales: {
            y: {
                grid: {
                    color: (ctx) => (ctx.tick.value === 0 ? '#e5e5e5' : 'rgba(229,229,229,0.4)'),
                    lineWidth: (ctx) => (ctx.tick.value === 0 ? 3 : 1),
                },
            },
        },
    };
    @ViewChild(BaseChartDirective) chart?: BaseChartDirective;
    private subscriptions = new Subscription();

    constructor(private logger: NGXLogger) {}

    ngOnInit(): void {
        this.renderChart();
        this.chart?.update();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.renderChart(changes);
        this.setOptions();

        this.chart?.update();
    }

    renderChart(changes?: SimpleChanges) {
        this.chartData.labels = this.xLabels;

        if (this.checkValidInput()) {
            if (changes) {
                if (changes.xLabels?.currentValue !== changes.xLabels?.previousValue) {
                    this.chartData.labels = this.xLabels;
                }
                if (changes.objectLabels?.currentValue !== changes.objectLabels?.previousValue && changes.data?.currentValue !== changes.data?.previousValue) {
                    this.chartData.datasets = this.updateDataSets();
                } else {
                    const datasets: ChartDataset[] = this.chartData.datasets;
                    if (changes.data?.currentValue !== changes.data?.previousValue) {
                        datasets.forEach((dataset, index) => {
                            dataset.data = this.data[index];
                        });
                    } else {
                        datasets.forEach((dataset, index) => {
                            dataset.label = this.objectLabels[index];
                        });
                    }
                    this.chartData.datasets = datasets;
                }
                if (this.dataLoaded) {
                    this.chartLoaded = true;
                }
            } else {
                this.chartData.datasets = this.updateDataSets();
            }
        }

        this.addDatasetsStyling();
    }

    addDatasetsStyling() {
        this.chartData.datasets.forEach((dataset, index) => {
            let rgbaColor = createRGBAColor(dataset.label);
            let color = createColor(dataset.label);

            if (this.isFinancial) {
                const colors = [
                    { h: 359, s: 48, l: 47 },
                    { h: 123, s: 38, l: 46 },
                    { h: 27, s: 82, l: 62 },
                ];
                rgbaColor = hslToRgb(colors[index].h / 360, colors[index].s / 100, colors[index].l / 100);
                color = hslToHex(colors[index].h, colors[index].s, colors[index].l);
            }

            if (this.isTurnover) {
                let turnoverColor = { h: 195, s: 70, l: 81 };
                if (index > 0) turnoverColor = { h: 195, s: 50, l: 88 };
                rgbaColor = hslToRgb(turnoverColor.h / 360, turnoverColor.s / 100, turnoverColor.l / 100);
                color = hslToHex(turnoverColor.h, turnoverColor.s, turnoverColor.l);
            }

            const css = {
                backgroundColor: rgbaColor,
                borderColor: color,
                pointBackgroundColor: color,
                pointBorderColor: color,
                pointHoverBackgroundColor: color,
                pointHoverBorderColor: rgbaColor,
                hoverBackgroundColor: color,
                borderWidth: 2,
                hoverBorderColor: color,
                fill: '',
                pointRadius: 5,
                pointHoverRadius: 8,
                borderDash: [],
            };

            if (!this.isFinancial && !this.isTurnover) {
                css.fill = 'origin';
            }

            if (this.isTurnover && index > 0) {
                css.borderDash = [10, 5];
            }

            this.chartData.datasets[index] = { ...dataset, ...css };
        });
    }

    setOptions() {
        this.chartOptions.plugins.legend.position = this.legendPosition;
        this.chartOptions.plugins.tooltip = this.options?.plugins?.tooltip;
        if (this.ySuggestedMax && !this.ySuggestedMin)
            this.chartOptions.scales = {
                y: {
                    suggestedMax: this.ySuggestedMax,
                },
            };
        else if (this.ySuggestedMin && !this.ySuggestedMax)
            this.chartOptions.scales = {
                y: {
                    suggestedMin: this.ySuggestedMin,
                },
            };
        else if (this.ySuggestedMax && this.ySuggestedMin) {
            this.chartOptions.scales = {
                y: {
                    suggestedMax: this.ySuggestedMax,
                    suggestedMin: this.ySuggestedMin,
                },
            };
        }
    }

    updateDataSets(): ChartDataset[] {
        const datasets: ChartDataset[] = [];
        this.data.forEach((value, index) => {
            datasets.push({
                data: value,
                label: this.objectLabels[index],
            });
        });
        return datasets;
    }

    checkValidInput(): boolean {
        let valid = this.xLabels !== undefined && this.objectLabels !== undefined && this.data !== undefined;
        if (!valid) return valid;
        this.dataLoaded = true;
        valid = this.objectLabels.length === this.data.length;
        if (!valid) {
            this.logger.debug('invalid data given to chart (objectLabel and data are not the same size)');
        }
        return valid;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    click($event: { event?: ChartEvent; active?: any[] }) {
        if ($event.active.length > 0) {
            this.clickEvent.emit({
                month: $event.active[0].index,
                datasetIndex: $event.active[0].datasetIndex,
            });
        }
    }
}

export enum LegendPosition {
    LEFT = 'left',
    TOP = 'top',
    RIGHT = 'right',
    BOTTOM = 'bottom',
    CENTER = 'center',
    CHARTAREA = 'chartArea',
}

export class CalendarClickEventData {
    month: number;
    datasetIndex: number;
}
