import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';

import { GetResume } from '../resume.actions';

@Component({
    selector: 'app-resume-detail-modal',
    templateUrl: './resume-detail-modal.component.html',
    styleUrls: ['./resume-detail-modal.component.scss'],
})
export class ResumeDetailModalComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Input() resumeUuid: string;

    constructor(private store: Store, public activeModal: NgbActiveModal, private router: Router, private modalService: NgbModal, private logger: NGXLogger) {}

    ngOnInit(): void {}

    resumeDetail(resumeUuid: string) {
        this.logger.debug('Fetching resume');
        this.subscriptions.add(
            this.store.dispatch(new GetResume(resumeUuid)).subscribe(() => {
                this.logger.debug('Navigating to resume details');
                this.router.navigate(['/', 'resume', resumeUuid, 'detail']);
            }),
        );
        this.activeModal.close();
        this.modalService.dismissAll();
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
