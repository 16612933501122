import { WarehouseLocation } from '../models/WarehouseLocation';

export class AddWarehouseLocation {
    static readonly type = '[WarehouseLocation] Add WarehouseLocation';
    constructor(public warehouseLocation: WarehouseLocation) {}
}

export class FetchWarehouseLocations {
    static readonly type = '[WarehouseLocation] Fetch WarehouseLocations';
    constructor() {}
}

export class UpdateWarehouseLocation {
    static readonly type = '[WarehouseLocation] Update WarehouseLocation';
    constructor(public warehouseLocation: WarehouseLocation) {}
}

export class ArchiveWarehouseLocation {
    static readonly type = '[WarehouseLocation] Archive WarehouseLocation';
    constructor(public warehouseLocationUuid: string) {}
}
