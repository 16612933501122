import { Role } from 'src/app/models/Role';

import { Module } from '../../models/Module';

export class FetchRoles {
    static readonly type = '[Role] Fetch Roles';
}

export class FetchAllowedModules {
    static readonly type = '[AllowedModule] Fetch Allowed Modules';
}

export class FetchAllowedActions {
    static readonly type = '[AllowedAction] Fetch Allowed Actions';
}

export class UpdateModulesOnSetupChange {
    static readonly type = '[Module] Update Module on setup change';

    constructor(public module: Module) {}
}

export class AddCustomRole {
    static readonly type = '[Role] Add Custom Role';

    constructor(public role: Role) {}
}

export class UpdateCustomRole {
    static readonly type = '[Role] Update Custom Role';

    constructor(public role: Role) {}
}

export class DeleteCustomRole {
    static readonly type = '[Role] Delete Custom Role';

    constructor(public roleId: number) {}
}

export class AddCustomRoleToMember {
    static readonly type = '[Role] Add Custom Role to Member';

    constructor(public userUuid: string, public roleId: number) {}
}

export class AddCustomRoleToInvite {
    static readonly type = '[Role] Add Custom Role to Invite';

    constructor(public roleId: number, public inviteUuid: string) {}
}

export class ClearCustomRolesState {
    static readonly type = '[CustomRoleState] Clear the CustomRoleState';
}

export class RemoveModuleFromAllowedModules {
    static readonly type = '[AllowedModule] Remove allowed module';

    constructor(public moduleName: string) {}
}
