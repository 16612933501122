import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { VeldaCountryDTO } from 'src/app/models/VeldaCountryDTO';

import { AddCountry } from '../velda-languages.actions';

@Component({
    selector: 'app-velda-languages-countries-add-modal',
    templateUrl: './velda-languages-countries-add-modal.component.html',
    styleUrls: ['./velda-languages-countries-add-modal.component.scss'],
})
export class VeldaLanguagesCountriesAddModalComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    countryFormGroup: FormGroup;
    loading = true;

    constructor(
        private store: Store,
        public activeModal: NgbActiveModal,
        private formBuilder: FormBuilder,
        private toastrService: ToastrService,
        private logger: NGXLogger,
    ) {}

    ngOnInit(): void {
        this.countryFormGroup = this.formBuilder.group({
            countryName: ['', [Validators.required]],
            countryCode: ['', [Validators.required]],
        });
    }

    addCountry() {
        const veldaCountry: VeldaCountryDTO = {
            countryName: this.countryFormGroup.value.countryName,
            countryCode: this.countryFormGroup.value.countryCode,
        };

        this.logger.debug('Attempt to add country');

        this.subscriptions.add(
            this.store.dispatch(new AddCountry(veldaCountry)).subscribe(
                () => {
                    this.toastrService.success('Country added successfully!');
                    this.logger.debug('Country added successfully!');
                    this.activeModal.close();
                },
                (error) => {
                    this.toastrService.error('Failed to add country!');
                    this.logger.error('Failed to add country!');
                },
            ),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
