import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { TeamleaderAgendaComponent } from './teamleader-agenda/teamleader-agenda.component';
import { TeamleaderCompaniesComponent } from './teamleader-companies/teamleader-companies.component';
import { TeamleaderContactsComponent } from './teamleader-contacts/teamleader-contacts.component';
import { TeamleaderDealsComponent } from './teamleader-deals/teamleader-deals.component';
import { TeamleaderDepartmentsComponent } from './teamleader-departments/teamleader-departments.component';
import { TeamleaderEmployeesComponent } from './teamleader-employees/teamleader-employees.component';
import { TeamleaderOverviewComponent } from './teamleader-overview/teamleader-overview.component';

export const routes: Routes = [
    { path: '', redirectTo: 'overview', pathMatch: 'full' },
    { path: 'overview', component: TeamleaderOverviewComponent },
    { path: 'employees', component: TeamleaderEmployeesComponent },
    { path: 'contacts', component: TeamleaderContactsComponent },
    { path: 'agenda', component: TeamleaderAgendaComponent },
    { path: 'departments', component: TeamleaderDepartmentsComponent },
    { path: 'deals', component: TeamleaderDealsComponent },
    { path: 'companies', component: TeamleaderCompaniesComponent },
];

export const routing: ModuleWithProviders<any> = RouterModule.forChild(routes);
