<div class="d-flex align-items-center">
    <div class="d-flex nowrap"></div>
    <div class="w-100">
        <app-searchbar
            [(value)]="filter"
            (valueChange)="applyFilter($event)"></app-searchbar>
    </div>
</div>

<div
    class="m-t-4"
    *ngIf="!isLoading">
    <div
        class="viw-overview"
        *ngIf="customers$ | async">
        <table
            mat-table
            [dataSource]="dataSource"
            matSort
            matSortActive="firstName"
            matSortDirection="asc">
            <ng-container matColumnDef="firstName">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header>
                    Customer
                </th>
                <td
                    mat-cell
                    *matCellDef="let row">
                    <app-pill-customer [customer]="row"></app-pill-customer>
                </td>
            </ng-container>

            <ng-container matColumnDef="contactInformation.phoneNumber">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header>
                    Phone
                </th>
                <td
                    mat-cell
                    *matCellDef="let row">
                    <app-pill-telephone
                        *ngIf="row.contactInformation.phoneNumber"
                        [contactPhonenumber]="row.contactInformation.phoneNumber"></app-pill-telephone>
                </td>
            </ng-container>

            <ng-container matColumnDef="contactInformation.email">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header>
                    Email
                </th>
                <td
                    mat-cell
                    *matCellDef="let row">
                    <app-pill-mailto [mailtoEmail]="row.contactInformation.email"></app-pill-mailto>
                </td>
            </ng-container>

            <ng-container matColumnDef="creatorId">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header>
                    Creator
                </th>
                <td
                    mat-cell
                    *matCellDef="let row">
                    <app-pill-user [userId]="row.creatorId"></app-pill-user>
                </td>
            </ng-container>

            <ng-container matColumnDef="edit">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    class="td-small"></th>
                <td
                    mat-cell
                    *matCellDef="let row">
                    <span
                        ngbDropdown
                        *appIsPermitted="{
                            module: 'CUSTOMERS',
                            operator: 'OR',
                            actions: ['EDIT', 'DELETE']
                        }">
                        <span
                            ngbDropdown
                            class="dropdown">
                            <button
                                type="button"
                                ngbDropdownToggle
                                class="button button--icon button--small dropdown-toggle"
                                id="dropdownMenuButton"></button>
                            <span
                                ngbDropdownMenu
                                class="dropdown-menu dropdown-menu-right"
                                aria-labelledby="dropdownMenuButton">
                                <a
                                    class="dropdown-item"
                                    *appIsPermitted="{
                                        module: 'CUSTOMERS',
                                        operator: 'AND',
                                        actions: ['EDIT']
                                    }"
                                    (click)="openUpdateCustomerModal(row)"
                                    >Edit</a
                                >
                                <div
                                    class="dropdown-divider"
                                    *appIsPermitted="{
                                        module: 'CUSTOMERS',
                                        operator: 'AND',
                                        actions: ['EDIT', 'DELETE']
                                    }"></div>
                                <a
                                    class="dropdown-item color--red"
                                    *appIsPermitted="{
                                        module: 'CUSTOMERS',
                                        operator: 'AND',
                                        actions: ['DELETE']
                                    }"
                                    (click)="deleteCustomer(row)"
                                    >Delete</a
                                >
                            </span>
                        </span>
                    </span>
                </td>
            </ng-container>

            <tr
                mat-header-row
                *matHeaderRowDef="displayedColumns"></tr>
            <tr
                mat-row
                *matRowDef="let row; columns: displayedColumns"></tr>
            <tr *matNoDataRow>
                <td colspan="5">No customers matching the filter "{{ filter }}"</td>
            </tr>
        </table>
    </div>
</div>

<div
    class="m-t-4"
    *ngIf="isLoading">
    <app-loading-message></app-loading-message>
</div>
