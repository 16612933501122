import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { VeldaArticlesArticlesCategoriesOverviewComponent } from './velda-articles-articles-categories/velda-articles-articles-categories-overview/velda-articles-articles-categories-overview.component';
import { VeldaArticlesArticlesOverviewComponent } from './velda-articles-articles/velda-articles-articles-overview/velda-articles-articles-overview.component';
import { VeldaArticlesBrandsOverviewComponent } from './velda-articles-brands/velda-articles-brands-overview/velda-articles-brands-overview.component';
import { VeldaArticlesCollectionsOverviewComponent } from './velda-articles-collections/velda-articles-collections-overview/velda-articles-collections-overview.component';
import { VeldaArticlesDealersOverviewComponent } from './velda-articles-dealers/velda-articles-dealers-overview/velda-articles-dealers-overview.component';
import { VeldaArticlesSetsOverviewComponent } from './velda-articles-sets/velda-articles-sets-overview/velda-articles-sets-overview.component';
import { VeldaArticlesSubcollectionsOverviewComponent } from './velda-articles-subcollections/velda-articles-subcollections-overview/velda-articles-subcollections-overview.component';

export const routes: Routes = [
    { path: '', redirectTo: 'articles', pathMatch: 'full' },
    { path: 'brands', component: VeldaArticlesBrandsOverviewComponent },
    {
        path: 'collections',
        component: VeldaArticlesCollectionsOverviewComponent,
    },
    { path: 'sets', component: VeldaArticlesSetsOverviewComponent },
    {
        path: 'subcollections',
        component: VeldaArticlesSubcollectionsOverviewComponent,
    },
    { path: 'articles', component: VeldaArticlesArticlesOverviewComponent },
    {
        path: 'articles-categories',
        component: VeldaArticlesArticlesCategoriesOverviewComponent,
    },
    { path: 'dealers', component: VeldaArticlesDealersOverviewComponent },
];

export const routing: ModuleWithProviders<any> = RouterModule.forChild(routes);
