import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';

import { Resume } from '../../models/resume/Resume';
import { ResumeVariant } from '../../models/resume/ResumeVariant';
import { ConfirmationModalComponent } from '../../shared/confirmation-modal/confirmation-modal.component';
import { AddResumeVariant, DeleteResumeVariant, UpdateResumeVariant } from '../resume.actions';
import { ResumeState } from '../resume.state';

@Component({
    selector: 'app-manage-variants',
    templateUrl: './manage-variants.component.html',
    styleUrls: ['./manage-variants.component.scss'],
})
export class ManageVariantsComponent implements OnInit, OnDestroy {
    @Input() resume: Resume;

    @Select(ResumeState.resumes) resumes$: Observable<Resume[]>;
    variants: ResumeVariant[];
    variantsForm: FormGroup;

    editingVariant: ResumeVariant;
    editingVariantTitle: string;

    private subscriptions = new Subscription();

    constructor(
        private logger: NGXLogger,
        public activeModal: NgbActiveModal,
        private fb: FormBuilder,
        private store: Store,
        private modalService: NgbModal,
        private toastr: ToastrService,
    ) {}

    ngOnInit(): void {
        this.createVariantsForm();
        this.fetchData();
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    addVariant() {
        const newVariant: ResumeVariant = { jobTitle: this.variantsForm.value.variant, resumeId: this.resume.uuid };
        this.subscriptions.add(
            this.store.dispatch(new AddResumeVariant(this.resume.uuid, newVariant)).subscribe({
                next: () => {
                    this.toastr.success('Variant successfully added.');
                },
                error: () => {
                    this.logger.debug('Failed to add resume variant');
                    this.toastr.error('Oops, something went wrong, Please try again later...');
                },
            }),
        );
        this.variantsForm.reset();
    }

    openDeleteVariantModal(variant: ResumeVariant) {
        const modalRef = this.modalService.open(ConfirmationModalComponent, { windowClass: 'modal-prompt', animation: false });
        modalRef.componentInstance.type = 'Delete';
        modalRef.componentInstance.title = 'Delete resume variant';
        modalRef.componentInstance.htmlMessage = `<p>Are you sure you want to delete variant <strong>${variant.jobTitle}</strong>?</p>`;
        this.subscriptions.add(
            modalRef.componentInstance.closeEvent.subscribe((value) => {
                if (value) {
                    this.subscriptions.add(
                        this.store.dispatch(new DeleteResumeVariant(variant.uuid)).subscribe({
                            next: () => {
                                this.toastr.success('Variant successfully deleted.');
                            },
                            error: () => {
                                this.logger.debug('Failed to delete resume variant');
                                this.toastr.error('Oops, something went wrong, Please try again later...');
                            },
                        }),
                    );
                }
            }),
        );
    }

    editVariant(variant: ResumeVariant) {
        this.editingVariant = { ...variant };
        this.editingVariantTitle = variant.jobTitle;
    }

    updateVariant() {
        this.editingVariant.jobTitle = this.editingVariantTitle;
        this.subscriptions.add(
            this.store.dispatch(new UpdateResumeVariant(this.editingVariant.uuid, this.editingVariant)).subscribe({
                next: () => {
                    this.toastr.success('Variant successfully updated.');
                    this.editingVariant = undefined;
                },
                error: () => {
                    this.logger.debug('Failed to update resume variant');
                    this.toastr.error('Oops, something went wrong, Please try again later...');
                },
            }),
        );
    }

    private createVariantsForm() {
        this.variantsForm = this.fb.group({
            variant: ['', [Validators.required]],
        });
    }

    private fetchData() {
        this.subscriptions.add(
            this.resumes$.subscribe((resumes) => {
                this.variants = resumes.find((r) => r.uuid === this.resume.uuid).resumeVariants;
            }),
        );
    }
}
