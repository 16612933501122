import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { CompanyState } from 'src/app/companies/company.state';
import { Status } from 'src/app/models/Status';

import { AddProjectStatus, UpdateProjectStatus } from '../project.actions';

@Component({
    selector: 'app-project-status-detail-modal',
    templateUrl: './project-status-detail-modal.component.html',
    styleUrls: ['./project-status-detail-modal.component.scss'],
})
export class ProjectStatusDetailModalComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Input() projectStatus: Status;
    @Select(CompanyState.companyUuid) companyUuid$: Observable<string>;
    newProjectStatus: Status;
    statusFormGroup: FormGroup;
    errorMessage: string;
    isUpdate = false;
    loading = false;
    public inputIsWrong = false;
    public projectStatusWentWrong = false;
    public colourWentWrong = false;
    companyUuid: string;
    selectedColour: string;
    colour: string;

    @ViewChild('focussed', { static: false })
    set input(element: ElementRef<HTMLInputElement>) {
        if (element) {
            element.nativeElement.focus();
        }
    }

    constructor(
        public activeModal: NgbActiveModal,
        private toastr: ToastrService,
        private store: Store,
        private formBuilder: FormBuilder,
        private logger: NGXLogger,
    ) {}

    ngOnInit(): void {
        this.subscriptions.add(this.companyUuid$.subscribe((uuid) => (this.companyUuid = uuid)));

        if (this.projectStatus) {
            this.isUpdate = true;
        }
        this.statusFormGroup = this.formBuilder.group({
            phase: ['', [Validators.required]],
            colour: ['', [Validators.required]],
        });

        if (this.isUpdate) {
            this.onSelectColour(this.projectStatus.colour);
            this.setFormValues();
        }

        this.colour = this.statusFormGroup.value.colour;
    }

    setFormValues() {
        this.statusFormGroup.setValue({
            phase: this.projectStatus.phase,
            colour: this.projectStatus.colour,
        });
    }

    saveProject() {
        this.loading = true;

        this.statusFormGroup.disable();

        if (!this.colour) {
            this.colourWentWrong = true;
        }

        this.statusFormGroup.setValue({
            phase: this.statusFormGroup.value.phase,
            colour: this.colour,
        });

        this.newProjectStatus = this.statusFormGroup.value;

        if (!this.isUpdate) {
            this.statusFormGroup.setValue({
                phase: this.statusFormGroup.value.phase,
                colour: this.colour,
            });

            this.newProjectStatus = this.statusFormGroup.value;

            this.logger.debug('Attempt to add project status');
            this.subscriptions.add(
                this.store.dispatch(new AddProjectStatus(this.companyUuid, this.newProjectStatus)).subscribe(
                    () => {
                        this.logger.debug('Succesfully added project status');
                        this.toastr.success('Project Status added succesfully');
                        this.activeModal.close();
                    },
                    (error) => {
                        this.logger.error('Failed to add project status');

                        this.errorMessage = error?.error?.message ?? 'Error message was empty';

                        this.loading = false;
                        this.statusFormGroup.enable();

                        this.projectStatusWentWrong = true;
                    },
                ),
            );
        } else {
            this.logger.debug('Attempt to update projectStatus');
            this.subscriptions.add(
                this.store.dispatch(new UpdateProjectStatus(this.projectStatus.uuid, this.newProjectStatus)).subscribe(
                    () => {
                        this.logger.debug('Succesfully updated project status');
                        this.activeModal.close();
                    },
                    (error) => {
                        this.logger.error('Failed to update projectstatus');

                        this.errorMessage = error?.error?.message ?? 'Error message was empty';

                        this.loading = false;
                        this.statusFormGroup.enable();

                        this.projectStatusWentWrong = true;
                    },
                ),
            );
        }
    }

    hideErrorMessage() {
        this.inputIsWrong = false;
        this.colourWentWrong = false;
    }

    getColour(colour: string) {
        this.colour = colour;
    }

    onSelectColour(colour: string) {
        this.selectedColour = colour;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
