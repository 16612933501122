import { Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { Warehouse } from 'src/app/models/Warehouse';
import { WarehouseLocation } from 'src/app/models/WarehouseLocation';
import { ModuleService } from 'src/app/services/module.service';
import { FetchWarehouses } from 'src/app/warehouses/warehouse.actions';
import { WarehouseState } from 'src/app/warehouses/warehouse.state';

import { AddWarehouseLocation } from '../warehouse.location.actions';

@Component({
    selector: 'app-warehouse-locations-add-modal',
    templateUrl: './warehouse-locations-add-modal.component.html',
    styleUrls: ['./warehouse-locations-add-modal.component.scss'],
})
export class WarehouseLocationsAddModalComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Select(WarehouseState.Warehouses) allWarehouses$: Observable<Warehouse[]>;

    public emitObject: EventEmitter<WarehouseLocation> = new EventEmitter();

    warehouseLocationFormGroup: FormGroup;
    loading = false;
    inputIsWrong = false;
    warehouseLocation: WarehouseLocation;

    saveWarehouseWentWrong = false;
    errorMessage: string;
    DESCRIPTION_MAX_LENGTH = 1000;
    currentAmountOfDescriptionCharacters: number;
    descriptionIsTooLong = false;

    keyword = 'title';
    chosenWarehouse: string;
    allWarehouses: Warehouse[];

    constructor(
        private formBuilder: FormBuilder,
        public activeModal: NgbActiveModal,
        private store: Store,
        private toastr: ToastrService,
        public moduleService: ModuleService,
        private logger: NGXLogger,
    ) {}

    ngOnInit(): void {
        this.warehouseLocationFormGroup = this.formBuilder.group({
            title: ['', [Validators.required]],
            description: [''],
            warehouse: '',
        });

        this.logger.debug('Fetching warehouses');
        this.store.dispatch(new FetchWarehouses());
        this.subscriptions.add(this.allWarehouses$.subscribe((warehouses) => (this.allWarehouses = warehouses)));
    }

    addWarehouseLocation() {
        this.loading = true;

        if (!this.warehouseLocationFormGroup.valid) {
            this.inputIsWrong = true;
            this.loading = false;
            return;
        }

        this.warehouseLocation = this.warehouseLocationFormGroup.value;
        this.warehouseLocation.warehouseUuid = this.warehouseLocationFormGroup.value.warehouse.uuid;
        this.warehouseLocationFormGroup.disable();

        this.logger.debug('Attempt to add warehouseLocation');
        this.subscriptions.add(
            this.store.dispatch(new AddWarehouseLocation(this.warehouseLocation)).subscribe(
                (store) => {
                    this.logger.debug('Succesfully added warehouseLocation');
                    this.toastr.success('Warehouse added succesfully');
                    this.emitObject.emit(store.warehouseLocation.warehouseLocations.find((wl) => wl.title == this.warehouseLocation.title));
                    this.activeModal.close();
                },
                (errorMessage) => {
                    this.logger.error('Failed to add warehouseLocation');
                    this.loading = false;
                    this.errorMessage = errorMessage?.error?.message ?? 'Error message was empty';
                    this.saveWarehouseWentWrong = true;
                    this.warehouseLocationFormGroup.enable();
                },
            ),
        );
    }

    // Hides the error messages that appear after a user has submitted invalid data
    hideErrorMessage() {
        this.inputIsWrong = false;
    }

    onDescriptionKeyUp() {
        this.descriptionIsTooLong = false;
        this.currentAmountOfDescriptionCharacters = this.warehouseLocationFormGroup.controls.description.value.length;
        if (this.currentAmountOfDescriptionCharacters > this.DESCRIPTION_MAX_LENGTH) {
            this.descriptionIsTooLong = true;
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
