<p class="sectiontitle">Yuki settings</p>

<div
    (click)="openUpdateYukiSettingsModal()"
    class="list__item pointer m-t-2">
    <div class="list__item__icon">
        <i class="las la-calendar-day color--grey"></i>
    </div>
    <div
        *ngIf="yukiSettings$ | async; else loading"
        class="list__item__content">
        <strong>Change default start date to synchronize Yuki documents.</strong>
        <small>This is now {{ yukiSettings.syncStartDate | date }}.</small>
    </div>
</div>

<div
    (click)="openUpdateInvoicesBudgetExpenditureLinkModal()"
    class="list__item pointer">
    <div class="list__item__icon">
        <i class="las la-wallet color--grey"></i>
    </div>
    <div
        *ngIf="yukiSettings$ | async; else loading"
        class="list__item__content">
        <strong>Change settings for the link between budget expenditures and invoices.</strong>
        <small>Budget expenditures can be automatically created when you link an invoice to a budget. This feature can be turned off here. It is also possible to create new budget expenditures for all existing invoice links when this feature was turned off in the past.</small>
    </div>
</div>

<ng-template #loading>
    <app-loading-message></app-loading-message>
</ng-template>
