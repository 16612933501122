
<label class="m-t-4 m-b-4">Attachment(s)</label>
<span class="form-helptext">Supported file types: .pdf, .doc, .docx, .csv, .xlsx, .jpeg</span>
<span class="form-helptext" *ngIf="multi">{{maxFileAmount}} uploads max</span>
<div
    class="container"
    appDnd
    (fileDropped)="onFileDropped($event)">
    <input
        *ngIf="multi"
        accept="{{ accept }}"
        type="file"
        #fileDropRef
        id="fileDropRef"
        multiple
        (change)="fileBrowseHandler($event.target.files)" />
    <input
        *ngIf="!multi"
        accept="{{ accept }}"
        type="file"
        #fileDropRef
        id="fileDropRef"
        (change)="fileBrowseHandler($event.target.files)" />
    <img
        src="https://cdn-icons-png.flaticon.com/512/568/568717.png"
        width="63"
        height="64"
        viewBox="0 0 63 64" />
    <h2>Drag and drop file here</h2>
    <small>Or click to select and upload a file</small>
</div>
<div class="files-list">
    <span *ngIf="existingFileUrl && existingFileName">
        <div class="single-file">
            <div
                class="file-icon"
                style="width: 50px">
                <svg
                    version="1.1"
                    id="Capa_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 58 58"
                    style="enable-background: new 0 0 58 58"
                    xml:space="preserve">
                    <polygon
                        style="fill: #edeada"
                        points="51.5,14 37.5,0 6.5,0 6.5,58 51.5,58 " />
                    <g>
                        <path
                            style="fill: #cec9ae"
                            d="M16.5,23h25c0.552,0,1-0.447,1-1s-0.448-1-1-1h-25c-0.552,0-1,0.447-1,1S15.948,23,16.5,23z" />
                        <path
                            style="fill: #cec9ae"
                            d="M16.5,15h10c0.552,0,1-0.447,1-1s-0.448-1-1-1h-10c-0.552,0-1,0.447-1,1S15.948,15,16.5,15z" />
                        <path
                            style="fill: #cec9ae"
                            d="M41.5,29h-25c-0.552,0-1,0.447-1,1s0.448,1,1,1h25c0.552,0,1-0.447,1-1S42.052,29,41.5,29z" />
                        <path
                            style="fill: #cec9ae"
                            d="M41.5,37h-25c-0.552,0-1,0.447-1,1s0.448,1,1,1h25c0.552,0,1-0.447,1-1S42.052,37,41.5,37z" />
                        <path
                            style="fill: #cec9ae"
                            d="M41.5,45h-25c-0.552,0-1,0.447-1,1s0.448,1,1,1h25c0.552,0,1-0.447,1-1S42.052,45,41.5,45z" />
                    </g>
                    <polygon
                        style="fill: #cec9ae"
                        points="37.5,0 37.5,14 51.5,14 " />
                </svg>
            </div>
            <div class="info">
                <h4 class="name">
                    {{ existingFileName }}
                </h4>
            </div>

            <div
                class="delete"
                (click)="deleteImage()">
                <i class="las la-times"></i>
            </div>
        </div>
    </span>

    <span *ngIf="!existingFileUrl && !existingFileName">
        <div
            class="single-file"
            *ngFor="let file of selectedFiles; let i = index">
            <div
                class="file-icon"
                style="width: 50px">
                <svg
                    version="1.1"
                    id="Capa_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 58 58"
                    style="enable-background: new 0 0 58 58"
                    xml:space="preserve">
                    <polygon
                        style="fill: #edeada"
                        points="51.5,14 37.5,0 6.5,0 6.5,58 51.5,58 " />
                    <g>
                        <path
                            style="fill: #cec9ae"
                            d="M16.5,23h25c0.552,0,1-0.447,1-1s-0.448-1-1-1h-25c-0.552,0-1,0.447-1,1S15.948,23,16.5,23z" />
                        <path
                            style="fill: #cec9ae"
                            d="M16.5,15h10c0.552,0,1-0.447,1-1s-0.448-1-1-1h-10c-0.552,0-1,0.447-1,1S15.948,15,16.5,15z" />
                        <path
                            style="fill: #cec9ae"
                            d="M41.5,29h-25c-0.552,0-1,0.447-1,1s0.448,1,1,1h25c0.552,0,1-0.447,1-1S42.052,29,41.5,29z" />
                        <path
                            style="fill: #cec9ae"
                            d="M41.5,37h-25c-0.552,0-1,0.447-1,1s0.448,1,1,1h25c0.552,0,1-0.447,1-1S42.052,37,41.5,37z" />
                        <path
                            style="fill: #cec9ae"
                            d="M41.5,45h-25c-0.552,0-1,0.447-1,1s0.448,1,1,1h25c0.552,0,1-0.447,1-1S42.052,45,41.5,45z" />
                    </g>
                    <polygon
                        style="fill: #cec9ae"
                        points="37.5,0 37.5,14 51.5,14 " />
                </svg>
            </div>
            <div class="info">
                <h4 class="name">
                    {{ file?.name ? file?.name : extractFileNameFromFile(file) }}
                </h4>
                <p class="size">
                    {{ formatBytes(file?.size) !== 'NaN undefined' ? formatBytes(file?.size) : ' ' }}
                </p>
            </div>

            <div
                class="delete"
                (click)="deleteImage(i)">
                <i class="las la-times"></i>
            </div>
        </div>
    </span>
</div>
