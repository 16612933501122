<div
    [sortablejs]="companyProjectStatusList"
    [sortablejsOptions]="options">
    <div *ngFor="let workStatus of companyProjectStatusList | orderBy: 'No Status':false:true">
        <a class="list__item">
            <div class="list__item__icon icon--grey">
                <i class="uil uil-file"></i>
            </div>

            <div class="list__item__content">
                <strong>{{ workStatus.phase }}</strong>
            </div>
            <div class="list__item__content">
                <ul class="form-group--colors">
                    <li [style.background-color]="workStatus.colour"></li>
                </ul>
            </div>
            <div class="list__item__extra">
                <span>
                    <button
                        (click)="openProjectStatusUpdateModal(workStatus)"
                        class="button button--icon button--small m-r-2">
                        <i class="las la-pen"></i>
                    </button>
                    <button
                        (click)="openProjectStatusDeleteModal(workStatus)"
                        class="button button--icon button--small">
                        <i class="las la-trash color--red"></i>
                    </button>
                </span>
            </div>
        </a>
    </div>
</div>
