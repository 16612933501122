<div>
    <div
        class="sidebar-spacing"
        *ngIf="partner as partner">
        <div class="d-flex space-between">
            <h3>{{ partner.title }}</h3>
            <div
                ngbDropdown
                placement="bottom-right"
                class="dropdown">
                <button
                    type="button"
                    ngbDropdownToggle
                    class="button button--small button--icon dropdown-toggle"
                    id="dropdownMenuButton"></button>
                <div
                    ngbDropdownMenu
                    class="dropdown-menu"
                    aria-labelledby="dropdownMenuButton">
                    <a
                        class="dropdown-item"
                        (click)="openPartnerUpdateModal()"
                        >Edit company</a
                    >
                    <a
                        class="dropdown-item color--red"
                        (click)="openPartnerDeleteModal()"
                        >Delete</a
                    >
                </div>
            </div>
        </div>
        <small *ngFor="let tag of partner.tags">
            {{ tag.title }}
        </small>

        <ul class="list__specs">
            <li *ngIf="partner?.address && partner?.address?.street">
                <app-pill-address [address]="partner.address"></app-pill-address>
            </li>
            <li *ngIf="partner?.contactInformation?.phoneNumber">
                <app-pill-telephone
                    *ngIf="partner?.contactInformation?.phoneNumber"
                    [contactPhonenumber]="partner.contactInformation.phoneNumber"></app-pill-telephone>
            </li>
            <li *ngIf="partner?.contactInformation?.email">
                <app-pill-mailto
                    *ngIf="partner?.contactInformation?.email"
                    [mailtoEmail]="partner.contactInformation.email"></app-pill-mailto>
            </li>
            <li *ngIf="partner?.contactInformation?.website">
                <app-pill-url
                    *ngIf="partner?.contactInformation?.website"
                    [websiteUrl]="partner.contactInformation.website"
                    #reload></app-pill-url>
            </li>
        </ul>
    </div>

    <span class="divider"></span>

    <nav class="app__subnav">
        <a
            [routerLink]="['/', 'partners', partnerUuid, 'contactpersons']"
            *ngIf="moduleService.checkModuleActive(eModule.CONTACTS)"
            [class.app__subnav__active]="isActive(['/', 'partners', partnerUuid, 'contactpersons'])"
            >Contacts</a
        >
        <a
            [routerLink]="['/', 'partners', partnerUuid, 'activity']"
            [class.app__subnav__active]="isActive(['/', 'partners', partnerUuid, 'activity'])"
            >Activity</a
        >
        <a
            [routerLink]="['/', 'partners', partnerUuid, 'projects']"
            [class.app__subnav__active]="isActive(['/', 'partners', partnerUuid, 'projects'])"
            >Projects</a
        >
        <a
            [routerLink]="['/', 'partners', partnerUuid, 'cvs']"
            [class.app__subnav__active]="isActive(['/', 'partners', partnerUuid, 'cvs'])"
            >Resumés send</a
        >
        <span class="app__subnav__divider"></span>
        <a
            [routerLink]="['/', 'partners', partnerUuid, 'settings']"
            [class.app__subnav__active]="isActive(['/', 'partners', partnerUuid, 'settings'])"
            >Settings</a
        >
    </nav>
</div>
