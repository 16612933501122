import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subscription } from 'rxjs';
import { FetchContacts } from 'src/app/contacts/contacts.actions';
import { FetchCrmDeals, FetchCrmStages } from 'src/app/crm/crm.actions';
import { FetchEmployees } from 'src/app/employees/employees.actions';
import { fetchLeads } from 'src/app/leads/lead.actions';
import { ModuleEnum } from 'src/app/models/ModuleEnum';
import { FetchProductStockUpdateHistory } from 'src/app/products/product.actions';
import { FetchProjectsForOverview } from 'src/app/projects/project.actions';
import { ModuleService } from 'src/app/services/module.service';
import { FetchLatestComments } from 'src/app/shared/comments/comments.actions';

import { CustomRolesState } from '../../settings/roles/custom.roles.state';
import { MfaNotEnabledPopupComponent } from '../mfanot-enabled-popup/mfa-not-enabled-popup.component';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Select(CustomRolesState.AllowedModuleKeys) allowedModules$: Observable<string[]>;
    checkedModules: string[] = [];

    eModule = ModuleEnum;

    noActiveWidgets: boolean = false;

    constructor(
        private store: Store,
        private titleService: Title,
        public moduleService: ModuleService,
        private logger: NGXLogger,
        private route: ActivatedRoute,
        private modalService: NgbModal,
        private router: Router,
    ) {
        this.titleService.setTitle('Dashboard');
    }

    ngOnInit(): void {
        this.showMFANotEnabledPopup();

        this.logger.debug('Fetching Contacts');
        this.store.dispatch(new FetchContacts());

        this.logger.debug('Fetching Employees');
        this.store.dispatch(new FetchEmployees());

        this.logger.debug('Fetching Projects');
        this.store.dispatch(new FetchProjectsForOverview());

        this.logger.debug('Fetching Leads');
        this.store.dispatch(new fetchLeads());

        this.logger.debug('Fetching latest Comments');
        this.store.dispatch(new FetchLatestComments());

        this.logger.debug('Fetching StockUpdate history');
        this.store.dispatch(new FetchProductStockUpdateHistory());

        this.logger.debug('Fetching CRM');
        this.store.dispatch(new FetchCrmStages());
        this.store.dispatch(new FetchCrmDeals());

        if (
            !this.moduleService.checkModuleActive(this.eModule.CONTACTS) &&
            !this.moduleService.checkModuleActive(this.eModule.PROJECTS) &&
            !this.moduleService.checkModuleActive(this.eModule.LEADS) &&
            !this.moduleService.checkModuleActive(this.eModule.INVENTORY) &&
            !this.moduleService.checkModuleActive(this.eModule.EMPLOYEES) &&
            !this.moduleService.checkModuleActive(this.eModule.COMMENTS) &&
            !this.moduleService.checkModuleActive(this.eModule.CRM)
        ) {
            this.noActiveWidgets = true;
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    private showMFANotEnabledPopup() {
        this.route.queryParams.subscribe((params) => {
            if (params.mfaEnabled === 'false' && !window.location.host.includes('localhost')) {
                this.modalService.open(MfaNotEnabledPopupComponent, { windowClass: 'modal-prompt', animation: false });
            }
        });
    }
}
