import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { CompanyState } from 'src/app/companies/company.state';
import Project from 'src/app/models/Project';
import { Resume } from 'src/app/models/resume/Resume';
import { FetchResumes } from 'src/app/resumes/resume.actions';
import { ResumeState } from 'src/app/resumes/resume.state';

import { ProjectAssignResumeListComponent } from '../project-assign-resume-list/project-assign-resume-list.component';
import { AssignResumeToProject, GetProjectAssignedResumes } from '../project.actions';
import { ProjectState } from '../project.state';

@Component({
    selector: 'app-project-assign-resume-modal',
    templateUrl: './project-assign-resume-modal.component.html',
    styleUrls: ['./project-assign-resume-modal.component.scss'],
})
export class ProjectAssignResumeModalComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();
    @Input() projectUuid: string;
    @Select(ProjectState.projects) projects$: Observable<Project[]>;
    @Select(ResumeState.resumes) resumes$: Observable<Resume[]>;
    @Select(CompanyState.companyUuid) companyUuid$: Observable<string>;
    @ViewChild(ProjectAssignResumeListComponent)
    projectAssignResumeList: ProjectAssignResumeListComponent;
    project: Project;
    assignedResumeUuidList: string[] = [];
    assignedResumes: any[];

    assignmentWentWrong = false;
    errorMessage: string;
    resumeSelected = false;

    constructor(public activeModal: NgbActiveModal, private toastr: ToastrService, private store: Store, private logger: NGXLogger) {}

    ngOnInit(): void {
        this.logger.debug('Fetching workAssignedResumes');
        this.store.dispatch(new GetProjectAssignedResumes(this.projectUuid));
        this.subscriptions.add(this.projects$.subscribe((projects) => (this.project = projects.filter((w) => w.uuid == this.projectUuid)[0])));
        this.assignedResumes = this.project.assignedResumes;

        this.logger.debug('Fetching resumes');
        this.store.dispatch(new FetchResumes());
        this.hideErrorMessage();
    }

    OnSelect(resumeSelected: boolean) {
        this.resumeSelected = resumeSelected;
    }

    refresh() {
        this.ngOnInit();
    }

    assignResume() {
        this.logger.debug('Attempt to assign resume to project');
        this.subscriptions.add(
            this.store.dispatch(new AssignResumeToProject(this.projectUuid, this.projectAssignResumeList.selectedResumes)).subscribe(
                (resumes) => {
                    this.logger.debug('Succesfully assigned resume to project');
                    this.toastr.success('Assigned resumé(s)');
                    this.hideErrorMessage();
                    this.activeModal.close();
                },
                (error) => {
                    this.logger.error('Failed to assign resume to project');
                    this.assignmentWentWrong = true;
                    this.errorMessage = error?.error?.message ?? 'Error message was empty';
                },
            ),
        );
    }

    // Hides error messages
    hideErrorMessage() {
        this.assignmentWentWrong = false;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
