import { EventEmitter } from '@angular/core';
import { Component, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-add-cronjob-rule-modal',
    templateUrl: './add-cronjob-rule-modal.component.html',
    styleUrls: ['./add-cronjob-rule-modal.component.scss'],
})
export class AddCronjobRuleModalComponent implements OnInit {
    addRuleFormGroup: FormGroup;
    @Output() closeEvent = new EventEmitter<number>();

    constructor(private fb: FormBuilder, public activeModal: NgbActiveModal) {}

    ngOnInit(): void {
        this.addRuleFormGroup = this.fb.group({
            daysBefore: [0, [Validators.max(31), Validators.required, Validators.min(0)]],
        });
    }

    submit() {
        this.closeEvent.emit(this.addRuleFormGroup.get('daysBefore').value);
        this.activeModal.close();
    }
}
