import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { NgbDropdownModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TagInputModule } from 'ngx-chips';
import { OrderModule } from 'ngx-order-pipe';
import { NgPipesModule } from 'ngx-pipes';
import { SortablejsModule } from 'ngx-sortablejs';
import { NgxTrimDirectiveModule } from 'ngx-trim-directive';

import { SharedModule } from '../shared/shared.module';
import { ExactEditRelationModalComponent } from './exact-edit-relation-modal/exact-edit-relation-modal.component';
import { ExactInvoicesComponent } from './exact-invoices/exact-invoices.component';
import { ExactOrdersComponent } from './exact-orders/exact-orders.component';
import { ExactOverviewComponent } from './exact-overview/exact-overview.component';
import { ExactPaymentsComponent } from './exact-payments/exact-payments.component';
import { ExactProductsComponent } from './exact-products/exact-products.component';
import { ExactRelationsListComponent } from './exact-relations-list/exact-relations-list.component';
import { ExactRelationsComponent } from './exact-relations/exact-relations.component';
import { routing } from './exact.routing';

@NgModule({
    declarations: [
        ExactOverviewComponent,
        ExactRelationsComponent,
        ExactProductsComponent,
        ExactInvoicesComponent,
        ExactPaymentsComponent,
        ExactOrdersComponent,
        ExactEditRelationModalComponent,
        ExactRelationsListComponent,
    ],
    imports: [
        CommonModule,
        SharedModule,
        routing,
        NgbModule,
        NgbDropdownModule,
        NgxTrimDirectiveModule,
        ReactiveFormsModule,
        NgPipesModule,
        OrderModule,
        TagInputModule,
        SortablejsModule,
        MatButtonToggleModule,
    ],
    exports: [ExactOverviewComponent],
})
export class ExactModule {}
