import { ContactPerson } from '../models/ContactPerson';

export class AddContactPerson {
    static readonly type = '[ContactPerson] Add Contactperson';
    constructor(public contactperson: ContactPerson) {}
}

export class UpdateContactPerson {
    static readonly type = '[ContactPerson] Update Contactperson';
    constructor(public contactperson: ContactPerson) {}
}

export class DeleteContactPerson {
    static readonly type = '[ContactPerson] Delete ContactPerson';
    constructor(public contactPersonUuid: string) {}
}

export class FetchContacts {
    static readonly type = '[ContactPerson] Fetch ContactPerson';
}
