import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';
import Lead from 'src/app/models/Lead';
import { CountryService } from 'src/app/shared/country.service';

import { AddLead, UpdateArchivedLead, UpdateLead } from '../lead.actions';

@Component({
    selector: 'app-lead-detail-modal',
    templateUrl: './lead-detail-modal.component.html',
    styleUrls: ['./lead-detail-modal.component.scss'],
})
export class LeadDetailModalComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Input() lead: Lead;
    @Input() archived: boolean;
    newLead: Lead;
    DESCRIPTION_MIN_LENGTH = 10;
    DESCRIPTION_MAX_LENGTH = 1000;
    leadFormGroup: FormGroup;
    errorMessage: string;
    loading = false;
    updateLeadWentWrong = false;
    currentAmountOfDescriptionCharacters: number;
    allCountries = [];

    constructor(
        public activeModal: NgbActiveModal,
        private countryService: CountryService,
        private formBuilder: FormBuilder,
        private store: Store,
        private logger: NGXLogger,
    ) {}

    ngOnInit(): void {
        this.allCountries = this.countryService.getAllCountries();

        this.leadFormGroup = this.formBuilder.group({
            firstName: [this.lead?.firstName],
            lastName: [this.lead?.lastName],
            email: [this.lead?.email, [Validators.email, Validators.required]],
            phoneNumber: [this.lead?.phoneNumber],
            address: this.formBuilder.group({
                street: [this.lead?.address?.street, Validators.required],
                number: [this.lead?.address?.number, Validators.required],
                bus: [this.lead?.address?.bus],
                ext: [this.lead?.address?.ext],
                postal: [this.lead?.address?.postal, Validators.required],
                city: [this.lead?.address?.city, Validators.required],
                country: this.formBuilder.group({
                    iso2: [this.lead?.address?.country?.iso2, Validators.required],
                }),
            }),
        });
    }

    submit() {
        if (this.lead) {
            this.updateLead();
        } else {
            this.addLead();
        }
    }

    addLead() {
        this.newLead = this.leadFormGroup.value;
        this.newLead.read = false;
        this.newLead.channelKey = 'resumo';
        this.subscriptions.add(
            this.store.dispatch(new AddLead(this.newLead)).subscribe(
                () => {
                    this.logger.debug('Succesfully added lead');
                    this.activeModal.close();
                },
                (error) => {
                    this.logger.error('Failed to add lead');
                    this.errorMessage = error?.error?.message;
                    this.loading = false;
                    this.updateLeadWentWrong = true;
                },
            ),
        );
    }

    updateLead() {
        if (!this.leadFormGroup.valid) {
            return;
        }
        this.loading = true;

        this.newLead = this.leadFormGroup.value;
        this.newLead.uuid = this.lead.uuid;
        this.newLead.read = true;

        if (this.archived) {
            this.logger.debug('Attempt to update archived lead');
            this.subscriptions.add(
                this.store.dispatch(new UpdateArchivedLead(this.newLead)).subscribe(
                    () => {
                        this.logger.debug('Succesfully updated archived lead');
                        this.activeModal.close();
                    },
                    (error) => {
                        this.logger.error('Failed to update archived lead');
                        this.errorMessage = error?.error?.message;
                        this.loading = false;
                        this.updateLeadWentWrong = true;
                    },
                ),
            );
        } else {
            this.logger.debug('Attempt to update lead');
            this.subscriptions.add(
                this.store.dispatch(new UpdateLead(this.newLead)).subscribe(
                    () => {
                        this.logger.debug('Succesfully updated lead');
                        this.activeModal.close();
                    },
                    (error) => {
                        this.logger.error('Failed to update lead');
                        this.errorMessage = error?.error?.message;
                        this.loading = false;
                        this.updateLeadWentWrong = true;
                    },
                ),
            );
        }
    }

    get firstName() {
        return this.leadFormGroup.get('firstName');
    }
    get lastName() {
        return this.leadFormGroup.get('lastName');
    }
    get email() {
        return this.leadFormGroup.get('email');
    }
    get phoneNumber() {
        return this.leadFormGroup.get('phoneNumber');
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
