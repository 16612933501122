<app-app-holder>
    <app-navbar></app-navbar>
    <app-content>
        <div
            class="onboarding__holder"
            *ngIf="invites$ | async; else loading">
            <div *ngIf="invites$ | async as invites">
                <div
                    class="message__company-invite"
                    *ngFor="let invite of invites">
                    <div class="message__company-invite__image">
                        <img
                            src="../../../assets/img/onboarding-company.png"
                            class="w-100" />
                    </div>
                    <div class="message__company-invite__content">
                        <small>You've been invited to the company</small>
                        <h2 class="m-b-8">
                            <span *ngIf="invite.companyName">{{ invite.companyName }}</span
                            ><span *ngIf="!invite.companyName">This company has been archived. This invite is currently invalid</span>
                        </h2>
                        <p class="m-b-4">By accepting this invitation, you will be able to manage resumes, companies, contacts and more.</p>
                        <a
                            (click)="invite.companyName ? acceptInvite(invite.uuid) : false"
                            class="button button--success m-b-4"
                            >Accept invitation</a
                        >
                        <br />Or decline &
                        <a (click)="openModal()">create a new company</a>
                    </div>
                </div>
            </div>

            <div
                class="message__company-invite"
                *ngIf="(invites$ | async)?.length == 0">
                <div class="message__company-invite__image">
                    <img
                        src="../../../assets/img/onboarding-company.png"
                        class="w-100" />
                </div>
                <div class="message__company-invite__content">
                    <small>Let's get started</small>
                    <h2 class="m-b-8">Add your company</h2>
                    <p class="m-b-4">If you are working in a larger organisation, please consult your manager before adding your company.</p>
                    <!--(click)="openModal(content)"-->
                    <a
                        class="button button--primary"
                        (click)="openModal()"
                        >Add my company</a
                    >
                </div>
            </div>
        </div>
    </app-content>
</app-app-holder>

<ng-template #loading>
    <app-loading-message></app-loading-message>
</ng-template>
