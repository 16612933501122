import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subscription } from 'rxjs';

import { FetchBudgetsApproachingEndDate } from '../../budget/budget.actions';
import { BudgetState } from '../../budget/budget.state';
import Budget from '../../models/Budget';

@Component({
    selector: 'app-dashboard-budgets-widget',
    templateUrl: './dashboard-budgets-widget.component.html',
    styleUrls: ['./dashboard-budgets-widget.component.scss'],
})
export class DashboardBudgetsWidgetComponent implements OnInit {
    private subscriptions = new Subscription();

    @Select(BudgetState.BudgetsApproachingEndDate)
    budgetsApproachingEndDate$: Observable<Budget[]>;
    budgetsApproachingEndDate: Budget[] = null;

    displayedColumns: string[] = ['name', 'budget'];
    dataSource: MatTableDataSource<Budget> = new MatTableDataSource<Budget>();

    constructor(private router: Router, private logger: NGXLogger, private store: Store, private modalService: NgbModal) {}

    ngOnInit(): void {
        this.store.dispatch(new FetchBudgetsApproachingEndDate());
        this.subscriptions.add(
            this.budgetsApproachingEndDate$.subscribe((b) => {
                this.budgetsApproachingEndDate = b;
                this.dataSource.data = b;
            }),
        );
    }

    goToBudgets() {
        this.logger.debug('Navigating to budgets overview');
        this.router.navigate(['/', 'budget', 'overview']);
    }
}
