import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';

import { VeldaLanguagesLanguagesAddModalComponent } from '../velda-languages-languages-add-modal/velda-languages-languages-add-modal.component';
import { FetchCountries, FetchLanguages } from '../velda-languages.actions';

@Component({
    selector: 'app-velda-languages-languages-overview',
    templateUrl: './velda-languages-languages-overview.component.html',
    styleUrls: ['./velda-languages-languages-overview.component.scss'],
})
export class VeldaLanguagesLanguagesOverviewComponent implements OnInit {
    constructor(private store: Store, private modalService: NgbModal, private logger: NGXLogger) {}

    ngOnInit(): void {
        this.logger.debug('Fetching Velda Languages');
        this.store.dispatch(new FetchLanguages());

        this.logger.debug('Fetching Velda Countries');
        this.store.dispatch(new FetchCountries());
    }

    openAddLanguageModal() {
        this.logger.debug('Opening VeldaLanguagesLanguagesAddModal');
        this.modalService.open(VeldaLanguagesLanguagesAddModalComponent, {
            windowClass: 'modal-prompt',
        });
    }
}
