import { Component, OnDestroy, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subscription } from 'rxjs';
import { StockUpdateHistoryModel } from 'src/app/models/StockUpdateHistoryModel';
import { FetchProductStockUpdateHistory, FetchProducts } from 'src/app/products/product.actions';

import { Product } from '../../models/Product';
import { ProductState } from '../product.state';

@Component({
    selector: 'app-product-stock-history',
    templateUrl: './product-stock-history.component.html',
    styleUrls: ['./product-stock-history.component.scss'],
})
export class ProductStockHistoryComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Select(ProductState.ProductStockUpdateHistory)
    productStockUpdateHistory$: Observable<StockUpdateHistoryModel>;
    @Select(ProductState.Products) allProducts$: Observable<Product[]>;

    constructor(private store: Store, private logger: NGXLogger) {}

    ngOnInit(): void {
        this.logger.debug('Fetching products and stockUpdateHistory');
        this.store.dispatch(new FetchProductStockUpdateHistory());
        this.store.dispatch(new FetchProducts());
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
