<form
    (ngSubmit)="addBudget()"
    [formGroup]="budgetForm"
    class="modal-wrapper">
    <div class="modal-header">
        <h2
            class="modal-title"
            id="exampleModalLabel">
            {{ type }}
        </h2>
        <button
            (click)="activeModal.dismiss()"
            aria-label="Close"
            class="close"
            type="button">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <div class="sectiontitle sectiontitle--first">Information</div>
        <div class="row">
            <div class="col">
                <div class="form-group m-b-2 required">
                    <label>Choose budget template</label>
                    <div class="d-flex">
                        <div class="ng-autocomplete">
                            <ng-autocomplete
                                (selected)="selectEvent($event)"
                                [data]="budgetTemplates"
                                [itemTemplate]="budgetTemplateTemplate"
                                [notFoundTemplate]="budgetTemplateNotFoundTemplate"
                                formControlName="budgetTemplate"
                                placeholder="Choose budget template"
                                searchKeyword="budgetType"></ng-autocomplete>

                            <ng-template
                                #budgetTemplateTemplate
                                let-item>
                                <div class="autocomplete--item">
                                    <a [innerHTML]="item.budgetType"></a>
                                    <i
                                        *ngIf="item.recurring"
                                        class="las la-redo-alt"></i>
                                </div>
                            </ng-template>

                            <ng-template
                                #budgetTemplateNotFoundTemplate
                                let-notFound>
                                <div (click)="openCreateBudgetTemplateModal()">+ Add new budget template</div>
                            </ng-template>
                        </div>
                        <button
                            (click)="openCreateBudgetTemplateModal()"
                            class="button button--outline button--icon m-l-2"
                            type="button">
                            <i class="las la-plus"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="form-group m-b-2 required">
                    <label for="startDate">Total spend</label>
                    <small
                        *ngIf="budgetForm.get('totalSpend').invalid && budgetForm.get('totalSpend').touched"
                        class="form-error">
                        Total spend is required and must be bigger than 0...
                    </small>
                    <input
                        formControlName="totalSpend"
                        id="totalSpend"
                        type="number" />
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <div class="form-group">
                    <p>Period</p>
                    <p class="form-helptext">The end date will be calculated using the lead time and the start date. But it can also be set manually.</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="form-group m-b-2 required">
                    <label for="startDate">Start date</label>
                    <input
                        (change)="updateEndPeriod()"
                        formControlName="startDate"
                        id="startDate"
                        type="date" />
                </div>
            </div>
            <div class="col">
                <div class="form-group m-b-2 required">
                    <label for="endDate">End date</label>
                    <input
                        formControlName="endDate"
                        id="endDate"
                        type="date" />
                </div>
            </div>
        </div>

        <section>
            <div class="sectiontitle">Recurring options</div>

            <div class="m-b-2">
                <label
                    class="pointer"
                    for="recurring">
                    <input
                        formControlName="recurring"
                        id="recurring"
                        type="checkbox" />
                    Recurring budget
                </label>
            </div>

            <div *ngIf="budgetForm.get('recurring').value">
                <div class="row">
                    <div class="col">
                        <div class="form-group m-b-2 required">
                            <label for="recurrenceRule">Repeats</label>
                            <select
                                class="select"
                                formControlName="repeat"
                                id="recurrenceRule">
                                <option
                                    *ngFor="let repeat of budgetRepeats"
                                    [ngValue]="repeat.value">
                                    {{ repeat.title }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section>
            <div class="sectiontitle sectiontitle--required">Financial options</div>

            <p class="form-helptext">
                This option is important for financial calculations.
                When 'only expenditures' is selected. Only expenditures linked to this budget will be used in financial calculations.
                Else the budgeted cost will be used if no expenditures are linked.
            </p>

            <div class="form-group--toggle">
                <label class="pointer" for="expenditures">
                    <input formControlName="useExpendituresForFinancials" id="expenditures" type="radio" value="expenditures">
                    <div class="w-100">
                        Only expenditures
                    </div>
                </label>

                <label class="pointer" for="budgeted">
                    <input formControlName="useExpendituresForFinancials" id="budgeted" type="radio" value="budgeted">
                    <div class="w-100">
                        Budgeted cost in combination with expenditures
                    </div>
                </label>
            </div>

        </section>

        <section>
            <div class="sectiontitle">Notification options</div>

            <div class="m-b-2">
                <label
                    class="pointer"
                    for="sendNotification">
                    <input
                        formControlName="sendNotification"
                        id="sendNotification"
                        type="checkbox" />
                    Send notification for this budget
                </label>
            </div>

            <div *ngIf="budgetForm.get('sendNotification').value">
                <div class="row">
                    <div class="col">
                        <div class="form-group">
                            <p>Notification time</p>
                            <p class="form-helptext">
                                The notification time of a budget is the time before the end date that the user will receive a notification.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="form-group m-b-2 required">
                            <label for="notificationDateMonths">Months</label>
                            <input
                                formControlName="notificationDateMonths"
                                id="notificationDateMonths"
                                type="number" />
                        </div>
                    </div>
                    <div class="col">
                        <div class="form-group m-b-2 required">
                            <label for="notificationDateDays">Days</label>
                            <input
                                formControlName="notificationDateDays"
                                id="notificationDateDays"
                                type="number" />
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <div *ngIf="!budget">
            <div class="sectiontitle">Employees</div>
            <div class="row">
                <div class="col">
                    <div class="form-group m-b-2 required">
                        <label>Link employees to the budget</label>
                        <div *ngIf="employees$ | async; else loading">
                            <small
                                *ngIf="noCheckedEmployees()"
                                class="form-error">
                                Please select one or more employees
                            </small>
                            <div class="d-flex">
                                <input
                                    (input)="filterEmployees($event.target.value)"
                                    class="m-b-2"
                                    placeholder="Firstname or lastname employee"
                                    type="text" />

                                <button
                                    (click)="openAddEmployeeModal()"
                                    class="button button--outline button--icon m-l-2"
                                    type="button">
                                    <i class="las la-plus"></i>
                                </button>
                            </div>

                            <div class="table__overflow">
                                <table
                                    [dataSource]="dataSource"
                                    mat-table
                                    matSort>
                                    <ng-container matColumnDef="checkbox">
                                        <th
                                            *matHeaderCellDef
                                            class="td-small"
                                            mat-header-cell>
                                            <input
                                                (change)="checkAll()"
                                                [checked]="allCheck"
                                                type="checkbox" />
                                        </th>
                                        <td
                                            *matCellDef="let row"
                                            mat-cell>
                                            <input
                                                [checked]="getCurrentCheck(row)"
                                                type="checkbox" />
                                        </td>
                                    </ng-container>
                                    <ng-container>
                                        <ng-container matColumnDef="firstName">
                                            <th
                                                *matHeaderCellDef
                                                mat-header-cell
                                                mat-sort-header>
                                                Firstname
                                            </th>
                                            <td
                                                *matCellDef="let row"
                                                mat-cell>
                                                {{ row.firstName }}
                                            </td>
                                        </ng-container>
                                    </ng-container>
                                    <ng-container>
                                        <ng-container matColumnDef="lastName">
                                            <th
                                                *matHeaderCellDef
                                                mat-header-cell
                                                mat-sort-header>
                                                Lastname
                                            </th>
                                            <td
                                                *matCellDef="let row"
                                                mat-cell>
                                                {{ row.lastName }}
                                            </td>
                                        </ng-container>
                                    </ng-container>

                                    <tr
                                        *matHeaderRowDef="displayedColumns"
                                        mat-header-row></tr>
                                    <tr
                                        (click)="checkChange(row)"
                                        *matRowDef="let row; columns: displayedColumns"
                                        mat-row></tr>
                                </table>
                                <mat-paginator
                                    [pageSizeOptions]="[20]"
                                    aria-label="Select page of employees"
                                    class="table__paginator table__paginator--bottom"
                                    showFirstLastButtons>
                                </mat-paginator>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <input
            [disabled]="budgetForm.invalid || noCheckedEmployees()"
            [ngClass]="budgetForm.invalid || noCheckedEmployees() ? 'button--disabled' : 'button--primary'"
            class="button"
            type="submit"
            value="{{ type }}" />
        <button
            (click)="activeModal.dismiss()"
            class="button button--outline">
            Cancel
        </button>
    </div>
</form>

<ng-template #loading>
    <app-loading-message></app-loading-message>
</ng-template>
