<div class="modal-header">
    <app-loading-message *ngIf="isLoading"></app-loading-message>
    <h2
        *ngIf="!isLoading"
        class="modal-title"
        id="exampleModalLabel">
        {{ product.title }}
    </h2>
    <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<nav
    ngbNav
    #nav="ngbNav"
    class="tabs__modal">
    <ng-container ngbNavItem>
        <a ngbNavLink>Information</a>
        <ng-template ngbNavContent>
            <ul class="list__specs">
                <li
                    *ngIf="product?.description"
                    class="format-newlines">
                    <label>Description</label> {{ product.description }}
                </li>
                <li *ngIf="product?.inStock">
                    <label>Stock</label> {{ product?.inStock }} &nbsp;
                    <small
                        class="color--muted"
                        *ngIf="product?.unit"
                        >{{ product?.unit.type }}</small
                    >
                </li>
                <li *ngIf="product?.stockNotification">
                    <label>Notification</label> Stock
                    <span class="badge badge--operator"><=</span>
                    {{ product?.stockNotification }} &nbsp;
                    <small
                        class="color--muted"
                        *ngIf="product?.unit"
                        >{{ product?.unit.type }}</small
                    >
                </li>
                <li *ngIf="product?.lastModified">
                    <label>Last updated</label>
                    {{ product?.lastModified | amTimeAgo }}
                </li>
                <li *ngIf="product?.lastModified"><label></label>{{ product?.lastModified | date: 'medium' }}</li>
                <li *ngIf="product?.warehouseLocationUuid">
                    <label>Location</label><app-pill-warehouse [warehouseLocationUuid]="product.warehouseLocationUuid"></app-pill-warehouse>
                    <app-pill-warehouse-location [warehouseLocationUuid]="product.warehouseLocationUuid"></app-pill-warehouse-location>
                </li>
                <li *ngIf="product?.ean"><label>EAN</label> {{ product.ean }}</li>
                <li *ngIf="product?.sku"><label>SKU</label> {{ product.sku }}</li>
                <li>
                    <label>Health</label>
                    <span
                        class="badge badge--warning"
                        *ngIf="product?.inStock < product?.stockNotification && product?.inStock > 0"
                        >Low</span
                    >
                    <span
                        class="badge badge--danger"
                        *ngIf="product?.inStock === 0"
                        >Out of Stock</span
                    >
                    <span
                        class="badge badge--success"
                        *ngIf="product?.inStock >= product?.stockNotification && product?.inStock !== 0"
                        >Healthy</span
                    >
                </li>
                <li *ngIf="product?.purchasePrice">
                    <label>Purchase price</label>{{ product.purchasePrice | currency: 'EUR' }}<small class="color--muted">&nbsp;(Excl. VAT)</small>
                </li>
                <li *ngIf="product?.sellingPrice">
                    <label>Selling price</label>{{ product.sellingPrice | currency: 'EUR' }}<small class="color--muted">&nbsp;(Excl. VAT)</small>
                </li>
                <li>
                    <label>Tags</label
                    ><app-global-tags
                        [objectUuid]="product.uuid"
                        [type]="'product'"></app-global-tags>
                </li>
                <li><label>Categories</label><app-product-category-link [product]="product"></app-product-category-link></li>
            </ul>
        </ng-template>
    </ng-container>

    <ng-container ngbNavItem>
        <a ngbNavLink>Child products</a>
        <ng-template ngbNavContent>
            <app-product-child-list
                [product]="product"
                [allProducts]="allProducts"></app-product-child-list>
        </ng-template>
    </ng-container>

    <ng-container ngbNavItem>
        <a ngbNavLink>Parent products</a>
        <ng-template ngbNavContent>
            <app-product-parent-list
                [product]="product"
                [allProducts]="allProducts"></app-product-parent-list>
        </ng-template>
    </ng-container>

    <ng-container ngbNavItem>
        <a ngbNavLink>QR Code</a>
        <ng-template ngbNavContent>
            <div class="row align-items-center">
                <div class="col-5">
                    <img
                        [src]="format()"
                        class="w-100" />
                </div>
                <div class="col-7">
                    <p>Print this QR code and put it next to the product in question. Scan this QR code when adding or removing from the product's stock.</p>
                    <br />
                    <a
                        class="button button--light"
                        [href]="productQrCodePDF"
                        [download]="filename"
                        >Download QR code</a
                    >
                </div>
            </div>
        </ng-template>
    </ng-container>

    <ng-container ngbNavItem>
        <a ngbNavLink>Employees</a>
        <ng-template ngbNavContent>
            <app-global-link-autocomplete
                [itemType]="'product'"
                [item]="product"
                [itemLinkType]="'employee'"></app-global-link-autocomplete>
        </ng-template>
    </ng-container>

    <ng-container
        ngbNavItem
        *ngIf="this.moduleService.checkModuleActive(eModule.DOCUMENTS)">
        <a ngbNavLink>Documents</a>
        <ng-template ngbNavContent>
            <div>
                <app-documents-list
                    [objectId]="product.uuid"
                    [objectType]="'product'"></app-documents-list>
            </div>
        </ng-template>
    </ng-container>

    <ng-container
        ngbNavItem
        *ngIf="this.moduleService.checkModuleActive(eModule.COMMENTS)">
        <a ngbNavLink>Comments</a>
        <ng-template ngbNavContent>
            <div>
                <app-comments-overview [objectId]="product.uuid"></app-comments-overview>
            </div>
        </ng-template>
    </ng-container>
</nav>

<div
    class="modal-body"
    *ngIf="isLoading">
    <app-loading-message></app-loading-message>
</div>

<div
    class="modal-body"
    *ngIf="!isLoading">
    <div
        [ngbNavOutlet]="nav"
        *ngIf="product; else archivedOrNonExistent"></div>

    <ng-template
        class="modal-wrapper"
        #archivedOrNonExistent>
        The selected product has been archived or deleted.
    </ng-template>
</div>

<div class="modal-footer">
    <button
        type="button"
        class="button button--outline"
        (click)="activeModal.dismiss()">
        Close
    </button>
    <!--
    <button type="button" class="button button--outline" (click)="productDetail(product.uuid); activeModal.dismiss()">
        <span *ngIf="product.title">Go to {{product.title}} detailpage</span>
    </button>
-->
    <span
        ngbDropdown
        class="dropdown">
        <app-loading-message *ngIf="isLoading"></app-loading-message>
        <button
            ngbDropdownToggle
            class="button dropdown-toggle"
            id="dropdownMenuButton"
            *ngIf="!isLoading">
            Options
        </button>
        <div
            ngbDropdownMenu
            class="dropdown-menu dropdown-menu-right"
            *ngIf="!isLoading"
            aria-labelledby="dropdownMenuButton">
            <a
                class="dropdown-item"
                (click)="openProductUpdateModal(product)"
                >Edit product</a
            >
            <div class="dropdown-divider"></div>
            <a
                class="dropdown-item color--red"
                (click)="openDeleteProductModal(product)"
                >Archive product</a
            >
        </div>
    </span>
</div>
