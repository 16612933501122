import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subscription } from 'rxjs';
import { GlobalTag } from 'src/app/models/GlobalTag';

import { FetchGlobalTags } from '../global-tags/global-tags.actions';
import { GlobalTagsState } from '../global-tags/global-tags.state';

@Component({
    selector: 'app-tag-searchbar',
    templateUrl: './tag-searchbar.component.html',
    styleUrls: ['./tag-searchbar.component.scss'],
})
export class TagSearchbarComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();
    @Select(GlobalTagsState.GlobalTags) globalTags$: Observable<GlobalTag[]>;

    private selected_tags: GlobalTag[] = [];
    private tags: GlobalTag[] = [];
    private objectsWithTags: string[] = [];
    private allTagsString: any[] = [];

    searchText = '';
    selected_count = 0;
    isLoading = true;

    @Input() value: string;
    @Input() type: string;
    @Output() valueChange = new EventEmitter<string>();

    constructor(private store: Store, private logger: NGXLogger) {}

    ngOnInit(): void {
        this.subscriptions.add(
            this.globalTags$.subscribe((tags) => {
                if (!tags) {
                    this.logger.debug('Fetching globalTags');
                    this.store.dispatch(new FetchGlobalTags());
                } else {
                    this.isLoading = false;
                }

                this.allTagsString = [];
                tags?.forEach((t) => {
                    if (t.title != null) {
                        this.allTagsString.push(t.title);
                    }
                });

                this.tags = tags;
            }),
        );
    }

    addToSelected(event) {
        if (event != undefined) {
            const selectedTag = this.tags.find((t) => t.title === event?.value);
            this.selected_tags.push(selectedTag);
        }

        this.getSelected();
    }

    deleteFromSelected(event) {
        this.selected_tags = this.selected_tags.filter((t) => {
            return t.title != event?.value;
        });

        this.getSelected();
    }

    clearSelection() {
        this.searchText = '';
        this.selected_tags = [];

        this.getSelected();
    }

    getSelected() {
        this.objectsWithTags = [];

        this.selected_count = this.selected_tags.length;

        this.selected_tags?.forEach((tag) => {
            tag?.taggables.forEach((taggable) => {
                if (taggable.type == this.type) {
                    this.objectsWithTags.push(taggable.uuid);
                }
            });
        });

        if (this.selected_tags.length > 1) {
            this.objectsWithTags = this.checkOccurrence(this.objectsWithTags);
        }

        if (this.objectsWithTags.length <= 0 && this.selected_tags.length <= 0) {
            this.value = null;
        } else if (this.objectsWithTags.length <= 0 && this.selected_tags.length > 0) this.value = 'for given tags';
        else {
            this.value = JSON.stringify({
                objectsWithTagUuids: this.objectsWithTags,
            });
        }

        this.valueChange.emit(this.value);
    }

    // Filter uuids which occur under all selected tagFilters
    private checkOccurrence(array) {
        const unique = [...new Set(array)];
        const occurringInAll = [];

        unique.forEach((o) => {
            let occurred = false;

            for (let index = 0; index < this.selected_tags?.length; index++) {
                const found = this.selected_tags[index]?.taggables.filter((t) => t.uuid == o);
                if (found.length > 0) {
                    occurred = true;
                } else {
                    occurred = false;
                    break;
                }
            }

            if (occurred) {
                occurringInAll.push(o);
            }
        });

        return occurringInAll;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
