import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { AuthenticationService } from '../authentication/authentication.service';
import { BillableInformationForEmployee } from '../models/BillableInformationForEmployee';
import { ObjectWithFinancialData } from '../models/Financial/ObjectWithFinancialData';
import { TurnoverDataPerMonth } from '../models/Financial/TurnoverDataPerMonth';
import FinancialDetailInfoViewModel from '../models/FinancialDetailInfoViewModel';
import { HappinessScoreInformationForEmployee } from '../models/HappinessScoreInformationForEmployee';
import { InformationForFinancialData } from '../models/InformationForFinancialData';
import { OverheadCategoryWithFinancialData } from '../models/OverheadCategoryWithFinancialData';
import { ProjectService } from '../projects/project.service';

@Injectable({
    providedIn: 'root',
})
export class FinancialService {
    httpOptions = {};

    constructor(private httpClient: HttpClient, private authenticationService: AuthenticationService, private projectService: ProjectService) {
        this.httpOptions = authenticationService.getHeaders();
    }

    fetchIncomeForEmployee(employeeId: string, year: number): Observable<FinancialDetailInfoViewModel> {
        return this.httpClient.get<FinancialDetailInfoViewModel>(
            `${environment.baseUrl}/financial/income/employee/${employeeId}?year=${year}`,
            this.httpOptions,
        );
    }

    fetchCostsForEmployee(employeeId: string, year: number): Observable<FinancialDetailInfoViewModel> {
        return this.httpClient.get<FinancialDetailInfoViewModel>(
            `${environment.baseUrl}/financial/costs/employee/${employeeId}?year=${year}`,
            this.httpOptions,
        );
    }

    fetchIncomeForProject(projectId: string, year: number): Observable<FinancialDetailInfoViewModel> {
        return this.httpClient.get<FinancialDetailInfoViewModel>(`${environment.baseUrl}/financial/income/project/${projectId}?year=${year}`, this.httpOptions);
    }

    fetchCostsForProject(projectId: string, year: number): Observable<FinancialDetailInfoViewModel> {
        return this.httpClient.get<FinancialDetailInfoViewModel>(`${environment.baseUrl}/financial/costs/project/${projectId}?year=${year}`, this.httpOptions);
    }

    fetchFinancialDataForOverheadCategories(year: number): Observable<OverheadCategoryWithFinancialData[]> {
        return this.httpClient.get<OverheadCategoryWithFinancialData[]>(`${environment.baseUrl}/financial/overhead?year=${year}`, this.httpOptions);
    }

    fetchFinancialDataForOverheadCategoryByCategoryId(categoryId: string, year: number): Observable<OverheadCategoryWithFinancialData> {
        return this.httpClient.get<OverheadCategoryWithFinancialData>(`${environment.baseUrl}/financial/overhead/${categoryId}?year=${year}`, this.httpOptions);
    }

    getAllFinancialInformationForEmployee(employeeId: string, month: number, year: number): Observable<InformationForFinancialData[]> {
        return this.httpClient.get<InformationForFinancialData[]>(
            `${environment.baseUrl}/financial/information/employee/${employeeId}?month=${month}&year=${year}`,
            this.httpOptions,
        );
    }

    getAllFinancialInformationForProject(projectId: string, month: number, year: number): Observable<InformationForFinancialData[]> {
        return this.httpClient.get<InformationForFinancialData[]>(
            `${environment.baseUrl}/financial/information/project/${projectId}?month=${month}&year=${year}`,
            this.httpOptions,
        );
    }

    getAllFinancialInformationForCategory(categoryId: string, month: number, year: number): Observable<InformationForFinancialData[]> {
        return this.httpClient.get<InformationForFinancialData[]>(
            `${environment.baseUrl}/financial/information/category/${categoryId}?month=${month}&year=${year}`,
            this.httpOptions,
        );
    }

    getBillableInformationForEmployee(year: number): Observable<BillableInformationForEmployee[]> {
        return this.httpClient.get<BillableInformationForEmployee[]>(
            `${environment.baseUrl}/financial/information/employees/billable?year=${year}`,
            this.httpOptions,
        );
    }

    getBillableInformationForEmployeeByEmployeeId(employeeId: string, year: number): Observable<BillableInformationForEmployee> {
        return this.httpClient.get<BillableInformationForEmployee>(
            `${environment.baseUrl}/financial/information/employee/${employeeId}/billable?year=${year}`,
            this.httpOptions,
        );
    }

    getHappinessScoreInformationForEmployees(year: number): Observable<HappinessScoreInformationForEmployee[]> {
        return this.httpClient.get<HappinessScoreInformationForEmployee[]>(
            `${environment.baseUrl}/financial/information/employees/happiness-score?year=${year}`,
            this.httpOptions,
        );
    }

    getHappinessScoreInformationForEmployeeByEmployeeId(employeeId: string, year: number): Observable<HappinessScoreInformationForEmployee> {
        return this.httpClient.get<HappinessScoreInformationForEmployee>(
            `${environment.baseUrl}/financial/information/employee/${employeeId}/happiness-score?year=${year}`,
            this.httpOptions,
        );
    }

    getEmployeesFinancialData(year: number) {
        return this.httpClient.get<ObjectWithFinancialData[]>(`${environment.baseUrl}/financial/employees/financial-data?year=${year}`, this.httpOptions);
    }

    getProjectsFinancialData(year: number) {
        return this.httpClient.get<ObjectWithFinancialData[]>(`${environment.baseUrl}/financial/projects/financial-data?year=${year}`, this.httpOptions);
    }

    getOverheadCategoriesFinancialOverviewData(year: number) {
        return this.httpClient.get<ObjectWithFinancialData[]>(
            `${environment.baseUrl}/financial/overhead-categories/financial-data?year=${year}`,
            this.httpOptions,
        );
    }

    getTurnoverData(year: number): Observable<TurnoverDataPerMonth[]> {
        return this.httpClient.get<TurnoverDataPerMonth[]>(`${environment.baseUrl}/financial/turnover?year=${year}`, this.httpOptions);
    }
}
