import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { TIMESHEET_STATUS, Timesheet } from 'src/app/models/Timesheet';

import { EMOJIS } from '../../models/EMOJIS';
import { ChangeSelectedDate, FetchTimesheetByUserAndDate, UpdateTimesheet } from '../timesheets.actions';
import { TimesheetsService } from '../timesheets.service';
import { TimesheetState } from '../timesheets.state';
import { UpdateTimeslotSaveModalComponent } from '../update-timeslot-save-modal/update-timeslot-save-modal.component';

@Component({
    selector: 'app-update-timeslot',
    templateUrl: './update-timeslot.component.html',
    styleUrls: ['./update-timeslot.component.scss'],
})
export class UpdateTimeslotComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    //@Select(TimesheetState.timesheets) timesheets$: Observable<Timesheet[]>;
    @Select(TimesheetState.publicTimesheet) timesheet$: Observable<Timesheet>;

    description: string = '';
    descriptionTooLong: boolean = false;
    currentAmountOfExtraInfoCharacters: number = 0;
    descriptionMaxLength: number = 1000;

    happy?: number;
    emojis: EMOJIS[] = [];

    timesheet: Timesheet;
    isLoading: boolean = true;
    error: string = '';

    constructor(
        private store: Store,
        private route: ActivatedRoute,
        private timesheetsService: TimesheetsService,
        private modalService: NgbModal,
        private toastr: ToastrService,
        private logger: NGXLogger,
    ) {}

    ngOnInit(): void {
        let userId = this.route.snapshot.paramMap.get('userUuid');
        let date = this.route.snapshot.paramMap.get('date');
        let token = this.route.snapshot.paramMap.get('token');
        sessionStorage.setItem('timesheetToken', token);

        this.logger.debug('Fetching timesheets');
        this.subscriptions.add(
            this.store.dispatch(new FetchTimesheetByUserAndDate(userId, date)).subscribe(
                () => {},
                () => {
                    this.isLoading = false;
                    this.error = 'This timesheet is no longer available. It was probably deleted. Contact your administrator if you think this is a mistake.';
                },
            ),
        );

        this.subscriptions.add(
            this.timesheet$.subscribe((timesheet) => {
                if (!timesheet) return;

                this.timesheet = timesheet;
                this.description = this.timesheet.extraInfo ? this.timesheet.extraInfo : '';
                this.currentAmountOfExtraInfoCharacters = this.description.length;
                if (this.timesheet.happyScore) this.happy = this.timesheet.happyScore;
                this.isLoading = false;
            }),
        );

        this.emojis = Object.values(EMOJIS);
    }

    openReadyForReviewModal() {
        this.logger.debug('Opening confirmationModal');
        const modalRef = this.modalService.open(UpdateTimeslotSaveModalComponent, {
            windowClass: 'modal-prompt',
            animation: false,
        });
        this.subscriptions.add(
            modalRef.componentInstance.closeEvent.subscribe((val) => {
                let updatedTimesheet = JSON.parse(JSON.stringify(this.timesheet));
                updatedTimesheet.extraInfo = this.description;
                updatedTimesheet.happyScore = this.happy;
                if (val) {
                    updatedTimesheet.status = TIMESHEET_STATUS.REVIEW;
                } else {
                    updatedTimesheet.status = TIMESHEET_STATUS.EDIT;
                }
                this.logger.debug('Attempt to update timesheet');
                this.store.dispatch(new UpdateTimesheet(updatedTimesheet)).subscribe(
                    (res) => {
                        this.logger.debug('Succesfully updated timesheet');
                        if (updatedTimesheet.status === TIMESHEET_STATUS.REVIEW) {
                            this.toastr.success('Timesheet submitted for review.');
                        } else {
                            this.toastr.success('Timesheet saved as draft.');
                        }
                    },
                    (err) => {
                        this.logger.debug('Failed to update timesheet');
                        this.toastr.error('Oops, something went wrong. Please try again later.');
                    },
                );
            }),
        );
    }

    onDescriptionKeyUp() {
        this.descriptionTooLong = false;
        this.currentAmountOfExtraInfoCharacters = this.description.length;
        if (this.currentAmountOfExtraInfoCharacters > this.descriptionMaxLength) {
            this.descriptionTooLong = true;
        }
    }

    isLoggedIn(): boolean {
        return localStorage.getItem('token') != undefined && localStorage.getItem('token') != null;
    }

    getTotalMinutes = Timesheet.getTotalMinutes;
    getTotalDaysFromTimesheet = Timesheet.getTotalDaysFromTimesheet;
    getTotalDaysFromMinutes = Timesheet.getTotalDaysFromMinutes;
    getRequiredHoursDiff = Timesheet.getRequiredHoursDiff;
    getRequiredHoursDiffPercentage = Timesheet.getRequiredHoursDiffPercentage;

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
