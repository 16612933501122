import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '../authentication/auth.guard';
import { CompanyGuard } from '../companies/company.guard';
import { WarehousesOverviewComponent } from './warehouses-overview/warehouses-overview.component';

export const routes: Routes = [
    { path: '', redirectTo: 'inventory/warehouses', pathMatch: 'full' },
    {
        path: 'inventory/warehouses',
        component: WarehousesOverviewComponent,
        canActivate: [AuthGuard, CompanyGuard],
    },
];

export const routing: ModuleWithProviders<any> = RouterModule.forChild(routes);
