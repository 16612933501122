import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { NgbDropdownModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { MatTableExporterModule } from 'mat-table-exporter';
import { TagInputModule } from 'ngx-chips';
import { OrderModule } from 'ngx-order-pipe';
import { NgPipesModule } from 'ngx-pipes';

import { FinancialModule } from '../financial/financial.module';
import { SharedModule } from '../shared/shared.module';
import { AddOverheadCategoriesModalComponent } from './add-overhead-categories-modal/add-overhead-categories-modal.component';
import { BudgetInvoicesSettingsComponent } from './budget-invoices-settings/budget-invoices-settings.component';
import { CreateNewInvoiceModalComponent } from './create-new-invoice-modal/create-new-invoice-modal.component';
import { InvoiceInformationModalComponent } from './invoice-information-modal/invoice-information-modal.component';
import { InvoicesSettingsModalComponent } from './invoices-settings-modal/invoices-settings-modal.component';
import { MappedYukiContactsComponent } from './mapped-yuki-contacts/mapped-yuki-contacts.component';
import { OverheadCategoriesComponent } from './overhead-categories/overhead-categories.component';
import { OverheadCategoryDetailModalComponent } from './overhead-category-detail-modal/overhead-category-detail-modal.component';
import { SimpleDocumentListComponent } from './simple-document-list/simple-document-list.component';
import { UpdateOverheadCategoryNameModalComponent } from './update-overhead-category-name-modal/update-overhead-category-name-modal.component';
import { YukiDetailModalComponent } from './yuki-detail-modal/yuki-detail-modal.component';
import { YukiDocumentsListComponent } from './yuki-documents-list/yuki-documents-list.component';
import { YukiIconsComponent } from './yuki-icons/yuki-icons.component';
import { YukiOverviewComponent } from './yuki-overview/yuki-overview.component';
import { YukiUpdateInformationComponent } from './yuki-update-information/yuki-update-information.component';
import { routing } from './yuki.routing';

@NgModule({
    declarations: [
        YukiOverviewComponent,
        YukiDocumentsListComponent,
        YukiDetailModalComponent,
        InvoicesSettingsModalComponent,
        YukiUpdateInformationComponent,
        YukiIconsComponent,
        OverheadCategoriesComponent,
        AddOverheadCategoriesModalComponent,
        OverheadCategoryDetailModalComponent,
        UpdateOverheadCategoryNameModalComponent,
        InvoiceInformationModalComponent,
        SimpleDocumentListComponent,
        MappedYukiContactsComponent,
        CreateNewInvoiceModalComponent,
        BudgetInvoicesSettingsComponent,
    ],

    imports: [
        CommonModule,
        AutocompleteLibModule,
        NgbModule,
        NgbDropdownModule,
        NgPipesModule,
        OrderModule,
        TagInputModule,
        CdkTableModule,
        MatTableModule,
        MatSortModule,
        routing,
        SharedModule,
        MatPaginatorModule,
        MatTableExporterModule,
        FinancialModule,
    ],
})
export class YukiModule {}
