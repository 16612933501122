import { EventEmitter, Injectable, OnDestroy } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';

import { Timesheet } from '../models/Timesheet';
import { Timeslot } from '../models/Timeslot';
import { ConfirmationModalComponent } from '../shared/confirmation-modal/confirmation-modal.component';
import { EditTimeslotModalComponent } from './edit-timeslot-modal/edit-timeslot-modal.component';
import { RemoveTimeslot } from './timesheets.actions';
import { TimesheetsService } from './timesheets.service';

@Injectable()
export class TimeslotListService implements OnDestroy {
    private subscriptions = new Subscription();

    constructor(
        private store: Store,
        private modalService: NgbModal,
        private logger: NGXLogger,
        private toastr: ToastrService,
        private timesheetsService: TimesheetsService,
    ) {}

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    openAddTimeslotModal(timesheet: Timesheet, changeEvent: EventEmitter<void>) {
        this.logger.debug('Opening editTimeSlotModal');
        const modalRef = this.modalService.open(EditTimeslotModalComponent, {
            windowClass: 'modal-prompt',
            animation: false,
        });
        modalRef.componentInstance.timesheetUuid = timesheet.uuid;
        modalRef.componentInstance.employeeId = timesheet.employee.uuid;
        this.subscriptions.add(
            modalRef.closed.subscribe(() => {
                changeEvent.emit();
            }),
        );
    }

    openEditTimeslotModal(timeslot: Timeslot, timesheet: Timesheet, changeEvent: EventEmitter<void>) {
        this.logger.debug('Opening editTimeslotModal');
        const modalRef = this.modalService.open(EditTimeslotModalComponent, {
            windowClass: 'modal-prompt',
            animation: false,
        });
        modalRef.componentInstance.timesheetUuid = timesheet.uuid;
        modalRef.componentInstance.timeslot = timeslot;
        modalRef.componentInstance.employeeId = timesheet.employee.uuid;

        this.subscriptions.add(
            modalRef.closed.subscribe(() => {
                changeEvent.emit();
            }),
        );
    }

    removeTimeslot(timeslot: Timeslot, timesheet: Timesheet, changeEvent: EventEmitter<void>) {
        this.logger.debug('Opening confirmationModal');
        const modalRef = this.modalService.open(ConfirmationModalComponent, {
            windowClass: 'modal-prompt',
            animation: false,
        });
        modalRef.componentInstance.type = 'Delete';
        modalRef.componentInstance.title = 'Remove timeslot';
        modalRef.componentInstance.message = 'Are you sure you want to remove this timeslot?';
        this.subscriptions.add(
            modalRef.componentInstance.closeEvent.subscribe((val) => {
                if (val) {
                    this.logger.debug('Attempt to remove timeslot');
                    this.store.dispatch(new RemoveTimeslot(timesheet.uuid, timeslot.uuid)).subscribe(
                        (res) => {
                            this.logger.debug('Succesfully removed timeslot');
                            this.toastr.success('Timeslot successfully removed.');
                            changeEvent.emit();
                        },
                        (err) => {
                            this.logger.error('Failed to remove timeslot');
                            this.toastr.error('Oops, something went wrong. Please try again later.');
                        },
                    );
                }
            }),
        );
    }

    getBase64(timeslot: Timeslot): Observable<any> {
        if (timeslot.pdfUrl) {
            return this.timesheetsService.getPdf(timeslot.uuid);
        }
    }

    getFileName(url: string) {
        let fileName = '';
        if (url != null) {
            const substring = url?.substr(url.indexOf('amazonaws.com/') + 14);
            fileName = substring?.substr(0, substring.indexOf('.pdf'));
        }

        return fileName;
    }
}
