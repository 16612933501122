import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '../authentication/auth.guard';
import { CompanyGuard } from '../companies/company.guard';
import { WarehouseLocationsOverviewComponent } from './warehouse-locations-overview/warehouse-locations-overview.component';

export const routes: Routes = [
    {
        path: '',
        redirectTo: 'inventory/warehouse-locations',
        pathMatch: 'full',
    },
    {
        path: 'inventory/warehouse-locations',
        component: WarehouseLocationsOverviewComponent,
        canActivate: [AuthGuard, CompanyGuard],
    },
];

export const routing: ModuleWithProviders<any> = RouterModule.forChild(routes);
