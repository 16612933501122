<ng-container *ngIf="overheadCategories$ | async; else loading">
    <section class="table__overflow">
        <p class="sectiontitle sectiontitle--first">Saved categories</p>

        <table class="table-view">
            <tr>
                <th scope="col">Category name</th>
                <th scope="col">Exclude from finances</th>
                <th class="td-small"></th>
            </tr>
            <tr
                (click)="openOverheadCategoryDetailModal(overheadCategory)"
                *ngFor="let overheadCategory of overheadCategories">
                <td>
                    <app-pill-overhead-category [overheadCategory]="overheadCategory"></app-pill-overhead-category>
                </td>
                <td>
                    <span *ngIf="overheadCategory.excludeFromFinances; else included"><i class="las la-check-circle color--green"></i></span>
                    <ng-template #included>
                        <i class="las la-times-circle color--red"></i>
                    </ng-template>
                </td>
                <td class="td-icon">
                    <button
                        [disabled]="overheadCategory.categoryName === DEFAULT_CATEGORY"
                        class="button button--icon"
                        (click)="$event.stopPropagation(); deleteOverheadCategory(overheadCategory)">
                        <i class="las la-trash color--red"></i>
                    </button>
                </td>
            </tr>
            <tr>
                <td
                    class="table-view__add"
                    colspan="3"
                    (click)="openAddOverheadCategoriesModal()">
                    <i class="las la-plus"></i> Add overhead category
                </td>
            </tr>
        </table>
    </section>
</ng-container>

<ng-template #loading>
    <app-loading-message></app-loading-message>
</ng-template>
