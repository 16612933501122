import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store, createSelector } from '@ngxs/store';
import { append, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { GlobalTag } from '../models/GlobalTag';
import { Education } from '../models/resume/Education';
import { Experience } from '../models/resume/Experience';
import { Language } from '../models/resume/Language';
import { Resume } from '../models/resume/Resume';
import { ResumeVariant } from '../models/resume/ResumeVariant';
import { AddUndefinedObjectTagsToObject } from '../shared/global-tags/global-tags.actions';
import {
    AddEducation,
    AddExperience,
    AddLanguage,
    AddResume,
    AddResumeVariant,
    AddResumeVariantSkill,
    AddTagToResume,
    ArchiveResume,
    DeleteEducation,
    DeleteExperience,
    DeleteLanguage,
    DeleteResumeVariant,
    DeleteResumeVariantSkill,
    FetchResumes,
    GetEducations,
    GetExperiences,
    GetLanguages,
    GetResume,
    GetResumeVariants,
    RemoveTagFromResume,
    UpdateEducation,
    UpdateExperience,
    UpdateLanguage,
    UpdateResume,
    UpdateResumeVariant,
    UpdateResumeVariantSkill,
} from './resume.actions';
import { ResumeService } from './resume.service';

export interface ResumeStateModel {
    resumes: Resume[];
    currentResume: Resume;
}

@State<ResumeStateModel>({
    name: 'resume',
    defaults: {
        resumes: null,
        currentResume: null,
    },
})
@Injectable()
export class ResumeState {
    constructor(private resumeService: ResumeService, private store: Store) {}

    @Selector()
    static resumes(state: ResumeStateModel): Resume[] {
        return state?.resumes;
    }

    @Selector()
    static currentResume(state: ResumeStateModel): Resume {
        return state?.currentResume;
    }

    static selectedResumeVariant(resumeId: string, variantId: string) {
        return createSelector([ResumeState], (state: ResumeStateModel) => {
            const resumeVariant = state.resumes.find((r) => r.uuid == resumeId).resumeVariants.find((rv) => rv.uuid == variantId);
            return resumeVariant;
        });
    }

    @Action(FetchResumes)
    getAllResumes(ctx: StateContext<ResumeStateModel>): Observable<Resume[]> {
        return this.resumeService.getAllResumes().pipe(
            tap((resumes) => {
                ctx.setState(
                    patch<ResumeStateModel>({
                        resumes: resumes,
                    }),
                );
            }),
        );
    }

    @Action(AddResume)
    addResume(ctx: StateContext<ResumeStateModel>, action: AddResume): Observable<Resume> {
        return this.resumeService.addResume(action.resume).pipe(
            tap((resume) => {
                ctx.setState(
                    patch<ResumeStateModel>({
                        resumes: append<Resume>([resume]),
                        currentResume: resume,
                    }),
                );
            }),
        );
    }

    @Action(UpdateResume)
    updateResume(ctx: StateContext<ResumeStateModel>, action: UpdateResume): Observable<Resume> {
        return this.resumeService.updateResume(action.resume).pipe(
            tap((resume) => {
                ctx.setState(
                    patch<ResumeStateModel>({
                        resumes: updateItem<Resume>((r) => r.uuid === action.resume.uuid, resume),
                    }),
                );
            }),
        );
    }

    @Action(GetResume)
    getResume(ctx: StateContext<ResumeStateModel>, action: GetResume): Observable<Resume> {
        return this.resumeService.getResumeById(action.resumeUuid).pipe(
            tap((resume) => {
                ctx.setState(
                    patch<ResumeStateModel>({
                        currentResume: resume,
                    }),
                );
            }),
        );
    }

    @Action(ArchiveResume)
    archiveResume(ctx: StateContext<ResumeStateModel>, action: ArchiveResume) {
        return this.resumeService.archiveResume(action.resumeUuid).pipe(
            tap((resume) => {
                ctx.setState(
                    patch<ResumeStateModel>({
                        resumes: removeItem((r) => r.uuid === action.resumeUuid),
                    }),
                );
            }),
        );
    }

    @Action(AddEducation)
    addEducation(ctx: StateContext<ResumeStateModel>, action: AddEducation): Observable<Education> {
        return this.resumeService.addEducation(action.resumeUuid, action.education).pipe(
            tap((education) => {
                ctx.setState(
                    patch<ResumeStateModel>({
                        resumes: updateItem<Resume>(
                            (r) => r.uuid === action.resumeUuid,
                            patch({
                                educations: append([education]),
                            }),
                        ),
                    }),
                );
            }),
        );
    }

    @Action(UpdateEducation)
    updateEducation(ctx: StateContext<ResumeStateModel>, action: UpdateEducation): Observable<Education> {
        return this.resumeService.updateEducation(action.educationUuid, action.education).pipe(
            tap((education) => {
                ctx.setState(
                    patch<ResumeStateModel>({
                        resumes: updateItem<Resume>(
                            (r) => r.educations.includes(r.educations.find((e) => e.uuid === education.uuid)),
                            patch<Resume>({
                                educations: updateItem((e) => e.uuid === education.uuid, education),
                            }),
                        ),
                    }),
                );
            }),
        );
    }

    @Action(DeleteEducation)
    deleteEducation(ctx: StateContext<ResumeStateModel>, action: DeleteEducation) {
        return this.resumeService.deleteEducation(action.educationUuid).pipe(
            tap(() => {
                ctx.setState(
                    patch<ResumeStateModel>({
                        resumes: updateItem<Resume>(
                            (r) => r.educations.includes(r.educations.find((e) => e.uuid === action.educationUuid)),
                            patch<Resume>({
                                educations: removeItem((e) => e.uuid === action.educationUuid),
                            }),
                        ),
                    }),
                );
            }),
        );
    }

    @Action(GetEducations)
    getEducations(ctx: StateContext<ResumeStateModel>, action: GetEducations): Observable<Education[]> {
        return this.resumeService.getAllEducationsOfResume(action.resumeUuid).pipe(
            tap((educationList) => {
                ctx.setState(
                    patch<ResumeStateModel>({
                        resumes: updateItem<Resume>(
                            (r) => r.uuid === action.resumeUuid,
                            patch<Resume>({
                                educations: educationList,
                            }),
                        ),
                    }),
                );
            }),
        );
    }

    @Action(AddExperience)
    addExperience(ctx: StateContext<ResumeStateModel>, action: AddExperience): Observable<Experience> {
        return this.resumeService.addExperience(action.resumeUuid, action.experience).pipe(
            tap((experience) => {
                ctx.setState(
                    patch<ResumeStateModel>({
                        resumes: updateItem<Resume>(
                            (r) => r.uuid === action.resumeUuid,
                            patch({
                                experiences: append([experience]),
                            }),
                        ),
                    }),
                );
                this.store.dispatch(new AddUndefinedObjectTagsToObject(experience.uuid));
            }),
        );
    }

    @Action(UpdateExperience)
    updateExperience(ctx: StateContext<ResumeStateModel>, action: UpdateExperience): Observable<Experience> {
        return this.resumeService.updateExperience(action.experienceUuid, action.experience).pipe(
            tap((experience) => {
                ctx.setState(
                    patch<ResumeStateModel>({
                        resumes: updateItem<Resume>(
                            (r) => r.experiences?.includes(r.experiences.find((e) => e.uuid === action.experienceUuid)),
                            patch<Resume>({
                                experiences: updateItem((e) => e.uuid === action.experienceUuid, experience),
                            }),
                        ),
                    }),
                );
            }),
        );
    }

    @Action(DeleteExperience)
    deleteExperience(ctx: StateContext<ResumeStateModel>, action: DeleteExperience) {
        return this.resumeService.deleteExperience(action.experienceUuid).pipe(
            tap(() => {
                ctx.setState(
                    patch<ResumeStateModel>({
                        resumes: updateItem<Resume>(
                            (r) => r.experiences?.includes(r.experiences.find((e) => e.uuid === action.experienceUuid)),
                            patch<Resume>({
                                experiences: removeItem((e) => e.uuid === action.experienceUuid),
                            }),
                        ),
                    }),
                );
            }),
        );
    }

    @Action(GetExperiences)
    getExperiences(ctx: StateContext<ResumeStateModel>, action: GetExperiences): Observable<Experience[]> {
        return this.resumeService.getAllExperiencesOfResume(action.resumeUuid).pipe(
            tap((experienceList) => {
                ctx.setState(
                    patch<ResumeStateModel>({
                        resumes: updateItem<Resume>(
                            (r) => r.uuid === action.resumeUuid,
                            patch<Resume>({
                                experiences: experienceList,
                            }),
                        ),
                    }),
                );
                if (ctx.getState().currentResume) {
                    ctx.setState(
                        patch<ResumeStateModel>({
                            currentResume: patch<Resume>({
                                experiences: experienceList,
                            }),
                        }),
                    );
                }
            }),
        );
    }

    @Action(AddLanguage)
    addLanguage(ctx: StateContext<ResumeStateModel>, action: AddLanguage): Observable<Language> {
        return this.resumeService.addLanguage(action.resumeUuid, action.language).pipe(
            tap((language) => {
                ctx.setState(
                    patch<ResumeStateModel>({
                        resumes: updateItem<Resume>(
                            (r) => r.uuid === action.resumeUuid,
                            patch<Resume>({
                                languages: append([language]),
                            }),
                        ),
                    }),
                );
            }),
        );
    }

    @Action(UpdateLanguage)
    updateLanguage(ctx: StateContext<ResumeStateModel>, action: UpdateLanguage): Observable<Language> {
        return this.resumeService.updateLanguage(action.languageUuid, action.language).pipe(
            tap((language) => {
                ctx.setState(
                    patch<ResumeStateModel>({
                        resumes: updateItem<Resume>(
                            (r) => r.languages.includes(r.languages.find((l) => l.uuid === action.languageUuid)),
                            patch<Resume>({
                                languages: updateItem((l) => l.uuid === action.languageUuid, language),
                            }),
                        ),
                    }),
                );
            }),
        );
    }

    @Action(DeleteLanguage)
    deleteLanguage(ctx: StateContext<ResumeStateModel>, action: DeleteLanguage) {
        return this.resumeService.deleteLanguage(action.languageUuid).pipe(
            tap(() => {
                ctx.setState(
                    patch<ResumeStateModel>({
                        resumes: updateItem<Resume>(
                            (r) => r.languages.includes(r.languages.find((e) => e.uuid === action.languageUuid)),
                            patch<Resume>({
                                languages: removeItem((l) => l.uuid === action.languageUuid),
                            }),
                        ),
                    }),
                );
            }),
        );
    }

    @Action(GetLanguages)
    getLanguages(ctx: StateContext<ResumeStateModel>, action: GetLanguages): Observable<Language[]> {
        return this.resumeService.getAllLanguagesOfResume(action.resumeUuid).pipe(
            tap((languageList) => {
                ctx.setState(
                    patch<ResumeStateModel>({
                        resumes: updateItem<Resume>(
                            (r) => r.uuid === action.resumeUuid,
                            patch<Resume>({
                                languages: languageList,
                            }),
                        ),
                    }),
                );
            }),
        );
    }

    @Action(AddResumeVariant)
    addResumeVariant(ctx: StateContext<ResumeStateModel>, action: AddResumeVariant): Observable<ResumeVariant> {
        return this.resumeService.addResumeVariant(action.resumeUuid, action.resumeVariant).pipe(
            tap((resumeVariant) => {
                ctx.setState(
                    patch<ResumeStateModel>({
                        resumes: updateItem<Resume>(
                            (r) => r.uuid === action.resumeUuid,
                            patch<Resume>({
                                resumeVariants: append([resumeVariant]),
                            }),
                        ),
                    }),
                );
            }),
        );
    }

    @Action(AddResumeVariantSkill)
    addResumeVariantSkill(ctx: StateContext<ResumeStateModel>, action: AddResumeVariantSkill): Observable<any> {
        return this.resumeService.addResumeVariantSkill(action.resumeVariantUuid, action.skill).pipe(
            tap((resumeVariant) => {
                ctx.setState(
                    patch<ResumeStateModel>({
                        resumes: updateItem<Resume>(
                            (r) => r.resumeVariants?.includes(r.resumeVariants.find((rv) => rv.uuid === action.resumeVariantUuid)),
                            patch<Resume>({
                                resumeVariants: updateItem((rv) => rv.uuid === action.resumeVariantUuid, resumeVariant),
                            }),
                        ),
                    }),
                );
            }),
        );
    }

    @Action(GetResumeVariants)
    getResumeVariants(ctx: StateContext<ResumeStateModel>, action: GetResumeVariants): Observable<ResumeVariant[]> {
        return this.resumeService.getResumeVariants(action.resumeUuid).pipe(
            tap((resumeVariantList) => {
                ctx.setState(
                    patch<ResumeStateModel>({
                        resumes: updateItem<Resume>(
                            (r) => r.uuid === action.resumeUuid,
                            patch<Resume>({
                                resumeVariants: resumeVariantList,
                            }),
                        ),
                    }),
                );
            }),
        );
    }

    @Action(UpdateResumeVariant)
    updateResumeVariant(ctx: StateContext<ResumeStateModel>, action: UpdateResumeVariant): Observable<ResumeVariant> {
        return this.resumeService.updateResumeVariant(action.resumeVariantUuid, action.resumeVariant).pipe(
            tap((resumeVariant) => {
                ctx.setState(
                    patch<ResumeStateModel>({
                        resumes: updateItem<Resume>(
                            (r) => r.resumeVariants?.includes(r.resumeVariants.find((rv) => rv.uuid === action.resumeVariantUuid)),
                            patch<Resume>({
                                resumeVariants: updateItem((rv) => rv.uuid === action.resumeVariantUuid, resumeVariant),
                            }),
                        ),
                    }),
                );
            }),
        );
    }

    @Action(UpdateResumeVariantSkill)
    updateResumeVariantSkill(ctx: StateContext<ResumeStateModel>, action: UpdateResumeVariantSkill): Observable<any> {
        return this.resumeService.updateResumeVariantSkill(action.skillUuid, action.skill).pipe(
            tap((resumeVariant) => {
                ctx.setState(
                    patch<ResumeStateModel>({
                        resumes: updateItem<Resume>(
                            (r) => r.resumeVariants?.includes(r.resumeVariants.find((rv) => rv.uuid === resumeVariant.uuid)),
                            patch<Resume>({
                                resumeVariants: updateItem<ResumeVariant>(
                                    (rv) => rv.uuid === resumeVariant.uuid,
                                    patch<ResumeVariant>({
                                        skills: updateItem(
                                            (s) => s.uuid === action.skillUuid,
                                            resumeVariant.skills.find((s) => s.uuid === action.skillUuid),
                                        ),
                                    }),
                                ),
                            }),
                        ),
                    }),
                );
            }),
        );
    }

    @Action(DeleteResumeVariant)
    deleteResumeVariant(ctx: StateContext<ResumeStateModel>, action: DeleteResumeVariant) {
        return this.resumeService.deleteResumeVariant(action.resumeVariantUuid).pipe(
            tap(() => {
                ctx.setState(
                    patch<ResumeStateModel>({
                        resumes: updateItem<Resume>(
                            (r) => r.resumeVariants?.includes(r.resumeVariants.find((rv) => rv.uuid === action.resumeVariantUuid)),
                            patch<Resume>({
                                resumeVariants: removeItem((l) => l.uuid === action.resumeVariantUuid),
                            }),
                        ),
                    }),
                );
            }),
        );
    }

    @Action(DeleteResumeVariantSkill)
    deleteResumeVariantSkill(ctx: StateContext<ResumeStateModel>, action: DeleteResumeVariantSkill): Observable<any> {
        return this.resumeService.deleteResumeVariantSkill(action.skillUuid).pipe(
            tap((resumeVariant) => {
                ctx.setState(
                    patch<ResumeStateModel>({
                        resumes: updateItem<Resume>(
                            (r) => r.resumeVariants?.includes(r.resumeVariants.find((rv) => rv.uuid === resumeVariant.uuid)),
                            patch<Resume>({
                                resumeVariants: updateItem((rv) => rv.uuid === resumeVariant.uuid, resumeVariant),
                            }),
                        ),
                    }),
                );
            }),
        );
    }

    @Action(AddTagToResume)
    addTagToResume(ctx: StateContext<ResumeStateModel>, action: AddTagToResume) {
        ctx.setState(
            patch<ResumeStateModel>({
                resumes: updateItem<Resume>(
                    (r) => r.uuid === action.resumeId,
                    patch<Resume>({
                        globalTags: append([action.tag]),
                    }),
                ),
            }),
        );
        if (ctx.getState().currentResume) {
            ctx.setState(
                patch<ResumeStateModel>({
                    currentResume: patch<Resume>({
                        globalTags: append([action.tag]),
                    }),
                }),
            );
        }
    }

    @Action(RemoveTagFromResume)
    removeTagFromResume(ctx: StateContext<ResumeStateModel>, action: RemoveTagFromResume) {
        ctx.setState(
            patch<ResumeStateModel>({
                resumes: updateItem<Resume>(
                    (r) => r.uuid === action.resumeId,
                    patch<Resume>({
                        globalTags: removeItem<GlobalTag>((tag) => tag.uuid === action.tagId),
                    }),
                ),
            }),
        );
        if (ctx.getState().currentResume) {
            ctx.setState(
                patch<ResumeStateModel>({
                    currentResume: patch<Resume>({
                        globalTags: removeItem<GlobalTag>((tag) => tag.uuid === action.tagId),
                    }),
                }),
            );
        }
    }
}
