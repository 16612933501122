import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import {
    MbscButtonModule,
    MbscDatepickerModule,
    MbscEventcalendarModule,
    MbscInputModule,
    MbscPopupModule,
    MbscSegmentedModule,
    MbscSelectModule,
    MbscSwitchModule,
} from '@mobiscroll/angular';
import { NgbDropdownModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';

import { SharedModule } from '../shared/shared.module';
import { AddPlanningModalComponent } from './add-planning-modal/add-planning-modal.component';
import { DateModalComponent } from './date-modal/date-modal.component';
import { PlanningDetailComponent } from './planning-detail/planning-detail.component';
import { PlanningOverviewComponent } from './planning-overview/planning-overview.component';
import { PlanningTimelineComponent } from './planning-timeline/planning-timeline.component';
import { routing } from './planning.routes';
import { RecurrenceDialogComponent } from './recurrence-dialog/recurrence-dialog.component';

@NgModule({
    declarations: [
        PlanningOverviewComponent,
        PlanningTimelineComponent,
        AddPlanningModalComponent,
        RecurrenceDialogComponent,
        PlanningDetailComponent,
        DateModalComponent,
    ],
    imports: [
        routing,
        SharedModule,
        MbscEventcalendarModule,
        MbscPopupModule,
        MbscInputModule,
        MbscSwitchModule,
        MbscDatepickerModule,
        MbscSegmentedModule,
        MbscButtonModule,
        AutocompleteLibModule,
        CommonModule,
        MbscSelectModule,
        NgbDropdownModule,
        MatInputModule,
        MatSelectModule,
        NgbTooltipModule,
    ],
    exports: [PlanningTimelineComponent],
})
export class PlanningModule {}
