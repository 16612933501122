import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-confirmation-modal',
    templateUrl: './confirmation-modal.component.html',
    styleUrls: ['./confirmation-modal.component.scss'],
})
export class ConfirmationModalComponent {
    @Input() type: string;
    @Input() title: string;
    @Input() message: string;
    @Input() htmlMessage: string;
    @Input() messageObject: string;
    public closeEvent: EventEmitter<boolean> = new EventEmitter();

    constructor(public activeModal: NgbActiveModal) {}

    confirm() {
        this.closeEvent.emit(true);
        this.activeModal.close();
    }

    decline() {
        this.closeEvent.emit(false);
        this.activeModal.close();
    }
}
