<app-app-holder>
    <app-navbar></app-navbar>

    <app-sidebar>
        <app-teamleader-subnav></app-teamleader-subnav>
    </app-sidebar>

    <app-content>
        <app-teamleader-not-logged-in (credentialsIsset)="checkCredentials($event)"></app-teamleader-not-logged-in>

        <div *ngIf="isset">
            <div class="app__title">
                <h1>Departments Overview</h1>
            </div>

            <app-searchbar [(value)]="filter"></app-searchbar>

            <div *ngIf="this.isLoading">
                <app-loading-message></app-loading-message>
            </div>

            <div
                class="m-t-4"
                *ngIf="!this.isLoading">
                <table
                    class="table-view"
                    *appIsPermitted="{
                        module: 'TEAMLEADER',
                        operator: 'AND',
                        actions: ['READ']
                    }">
                    <tr>
                        <th>Name</th>
                        <th>VAT number</th>
                        <th>Currency</th>
                        <th>Status</th>
                    </tr>

                    <tr *ngFor="let department of this.departments$ | async | filterBy: ['name']:filter">
                        <td (click)="getDepartmentInfo(department)">
                            {{ department.name }}
                        </td>
                        <td>{{ department.vat_number }}</td>
                        <td>{{ department.currency }}</td>
                        <td>{{ department.status }}</td>
                    </tr>
                </table>
            </div>
        </div>
    </app-content>
</app-app-holder>
