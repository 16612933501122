export function capitalizeFirstLetter([first, ...rest], locale = navigator.language) {
    return first.toLocaleUpperCase(locale) + rest.join('').toLocaleLowerCase();
}

export function JSONTryParse(input: string) {
    try {
        //check if the string exists
        if (input) {
            const o = JSON.parse(input);

            //validate the result too
            if (o && o.constructor === Object) {
                return o;
            }
        }
    } catch (e: unknown) {
        //
    }

    return false;
}
