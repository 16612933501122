<div class="table__overflow">
    <table
        class="table-view"
        mat-table
        [dataSource]="memberList"
        matSort>
        <ng-container matColumnDef="name">
            <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header>
                Name
            </th>
            <td
                mat-cell
                *matCellDef="let member">
                <span class="d-flex align-items-center">
                    <img
                        [src]="'https://eu.ui-avatars.com/api/?background=random&bold=true&name=' + member.firstName + ' ' + member.lastName"
                        class="m-r-2" />
                    {{ member.firstName }} {{ member.lastName }}
                </span>
            </td>
        </ng-container>

        <ng-container matColumnDef="email">
            <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header>
                Email
            </th>
            <td
                mat-cell
                *matCellDef="let member">
                <app-pill-mailto [mailtoEmail]="member.email"></app-pill-mailto>
            </td>
        </ng-container>

        <ng-container matColumnDef="role">
            <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header>
                Role
            </th>
            <td
                mat-cell
                *matCellDef="let member">
                {{ member.role }}
            </td>
        </ng-container>

        <ng-container matColumnDef="edit">
            <th
                mat-header-cell
                *matHeaderCellDef
                class="td-small"></th>
            <td
                mat-cell
                *matCellDef="let row"
                class="td-small">
                <span ngbDropdown>
                    <button
                        ngbDropdownToggle
                        class="button button--icon button--small dropdown-toggle"
                        id="dropdownMenuButton"
                        (click)="$event.stopPropagation()"></button>
                    <span
                        ngbDropdownMenu
                        class="dropdown-menu dropdown-menu-right"
                        aria-labelledby="dropdownMenuButton">
                        <a
                            class="dropdown-item"
                            *ngIf="row.role !== 'ROLE_OWNER' && row.role !== 'ROLE_SUPER_ADMIN'"
                            (click)="openAddMemberRoleModal(row)"
                            >Edit member</a
                        >
                        <a
                            class="dropdown-item color--red"
                            href="#"
                            >Delete</a
                        >
                    </span>
                </span>
            </td>
        </ng-container>

        <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns"></tr>
        <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
</div>
