import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { AuthenticationService } from './authentication.service';

@Injectable({
    providedIn: 'root',
})
export class AlreadyLoggedInGuard implements CanActivate {
    constructor(private router: Router, private authService: AuthenticationService) {}

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (this.authService.getToken()) {
            if (state.url == '/login') {
                this.router.navigate(['/loggedin']);
            } else {
                const url: string = state.url.replace(/%2F/g, '/');
                if (url.split('=').length > 1) {
                    this.router.navigate([url.slice(url.indexOf('=') + 1)]);
                }
                return true;
            }
            return false;
        }
        return true;
    }
}
