import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthenticationService } from 'src/app/authentication/authentication.service';
import Lead from 'src/app/models/Lead';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class OutlookMailService {
    httpOptions = {};

    constructor(private http: HttpClient, private authenticationService: AuthenticationService) {
        this.httpOptions = authenticationService.getHeaders();
    }

    getMailsForLead(lead: Lead): Observable<any> {
        return this.http.get(`${environment.baseUrl}/outlook/sentmails-lead?leadmail=${lead.email}`, this.httpOptions);
    }
}
