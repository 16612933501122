import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { addMonths, addYears } from 'date-fns';

@Component({
    selector: 'app-date-navigation',
    templateUrl: './date-navigation.component.html',
    styleUrls: ['./date-navigation.component.scss'],
})
export class DateNavigationComponent {
    @Input() dateNavigationOptions: DateNavigationOptions;
    @Input() isCompact: boolean = false;

    @Output() selectedDateUpdated = new EventEmitter<Date>();

    moveDate(amount: number) {
        if (amount === 0) {
            this.dateNavigationOptions.selectedDate = new Date();
        } else if (this.dateNavigationOptions.dateNavigationType === DateNavigationType.YEAR) {
            this.dateNavigationOptions.selectedDate = addYears(this.dateNavigationOptions.selectedDate, amount);
        } else if (this.dateNavigationOptions.dateNavigationType === DateNavigationType.MONTH) {
            this.dateNavigationOptions.selectedDate = addMonths(this.dateNavigationOptions.selectedDate, amount);
        }
        this.selectedDateUpdated.emit();
    }
}

export class DateNavigationOptions {
    dateNavigationType: DateNavigationType = DateNavigationType.MONTH;
    selectedDate: Date;
    selectedDateClass: SelectedDateClass = SelectedDateClass.SMALL;
}

export enum DateNavigationType {
    MONTH = 'month',
    YEAR = 'year',
}

export enum SelectedDateClass {
    HEADER = 'header',
    SMALL = 'small',
}
