export enum ModuleEnum {
    RESUME = 'RESUME',
    PROJECTS = 'PROJECTS',
    PARTNERS = 'PARTNERS',
    APPLICANTS = 'APPLICANTS',
    LEADS = 'LEADS',
    INVENTORY = 'INVENTORY',
    SETTINGS = 'SETTINGS',
    API_SETTINGS = 'API_SETTINGS',
    DASHBOARD = 'DASHBOARD',
    CONTACTS = 'CONTACTS',
    CUSTOMERS = 'CUSTOMERS',
    THERMOSTAR_CONFIGURATOR_PREVIEW = 'THERMOSTAR_CONFIGURATOR_PREVIEW',
    THERMOSTAR_CONFIGURATOR = 'THERMOSTAR_CONFIGURATOR',
    CRM = 'CRM',
    CRAFT_APPLICANTS = 'CRAFT_APPLICANTS',
    OUTLOOK = 'OUTLOOK',
    MAILCHIMP = 'MAILCHIMP',
    EXACT = 'EXACT',
    TEAMLEADER = 'TEAMLEADER',
    EMPLOYEES = 'EMPLOYEES',
    TIMESHEETS = 'TIMESHEETS',
    GOOGLE = 'GOOGLE',
    DOCUMENTS = 'DOCUMENTS',
    COMANAGE = 'COMANAGE',
    COMMENTS = 'COMMENTS',
    VELDA_LANGUAGES = 'VELDA_LANGUAGES',
    VELDA_ARTICLES = 'VELDA_ARTICLES',
    BUDGET = 'BUDGET',
    YUKI = 'YUKI',
    FINANCIAL = 'FINANCIAL',
}
