<div class="login">
    <div class="login__holder">
        <app-session-header [header]="'Log in'"></app-session-header>
        <p>{{ environment?.environment }}</p>
        <form
            [formGroup]="loginFormGroup"
            (ngSubmit)="login()">
            <div class="form-group required m-b-2">
                <label>Emailaddress</label>
                <input
                    type="email"
                    placeholder="Emailaddress"
                    formControlName="email"
                    (focus)="hideErrorMessage()"
                    trim="blur" />
                <span
                    class="form-error"
                    *ngIf="
                        loginFormGroup.controls.email.errors && inputIsWrong && (loginFormGroup.controls.email.dirty || loginFormGroup.controls.email.touched)
                    ">
                    This email is invalid
                </span>
                <span
                    class="form-error"
                    (focus)="hideErrorMessage()"
                    *ngIf="loginWentWrong">
                    {{ errorMessage }}
                </span>
                <button
                    (click)="resendVerification(loginFormGroup.value.email)"
                    *ngIf="verificationWentWrong"
                    [ngClass]="{ 'button--loading': loading }"
                    class="button button--primary w-100">
                    Resend Verification email?
                </button>
            </div>

            <div class="form-group required m-b-4">
                <password-input
                    formControlName="password"
                    (focus)="hideErrorMessage()"></password-input>
                <span
                    class="form-error"
                    *ngIf="
                        loginFormGroup.controls.password.errors &&
                        inputIsWrong &&
                        (loginFormGroup.controls.password.dirty || loginFormGroup.controls.password.touched)
                    ">
                    The password needs to be at least 8 characters
                </span>
                <span
                    class="form-error"
                    *ngIf="loginWentWrong">
                    {{ connectionError }}
                </span>
            </div>

            <div class="m-b-4">
                <button
                    type="submit"
                    [ngClass]="{ 'button--loading': loading }"
                    class="button button--primary w-100">
                    Login
                </button>
            </div>
        </form>

        <div class="divider"></div>

        <!-- <app-google-login></app-google-login> -->

        <p class="text-center">
            <a routerLink="/forgotpassword">Forgot password?</a> -
            <a routerLink="/register">Create an account</a>
        </p>
    </div>
</div>
