import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { ApplicantDetailModalComponent } from 'src/app/applicants/applicant-detail-modal/applicant-detail-modal.component';
import { FetchApplicants } from 'src/app/applicants/applicant.actions';
import { ApplicantState } from 'src/app/applicants/applicant.state';
import { Applicant } from 'src/app/models/Applicants/Applicant';
import { LaneItemStatus } from 'src/app/models/LaneModel';

@Component({
    selector: 'app-pill-applicant',
    templateUrl: './pill-applicant.component.html',
    styleUrls: ['./pill-applicant.component.scss'],
})
export class PillApplicantComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Input() applicantUuid: string;
    @Input() title: string;
    @Input() applicant: Applicant;

    @Select(ApplicantState.Applicants) applicants$: Observable<Applicant[]>;

    isLoading = true;

    constructor(public activeModal: NgbActiveModal, private store: Store, private modalService: NgbModal) {}

    ngOnInit(): void {
        if (!this.applicant && this.applicantUuid && this.title) {
            this.isLoading = false;
        } else if (this.applicantUuid || (this.applicant?.uuid && this.applicant?.status !== LaneItemStatus.ARCHIVED)) {
            this.subscriptions.add(
                this.applicants$.subscribe((applicants) => {
                    if (applicants === null) {
                        this.store.dispatch(new FetchApplicants());
                    } else {
                        if (this.applicantUuid) this.applicant = applicants.find((a) => a.uuid === this.applicantUuid);
                        else this.applicant = applicants.find((a) => a.uuid === this.applicant.uuid);

                        this.isLoading = false;
                    }
                }),
            );
        }
        if (this.applicant) {
            this.isLoading = false;
        }
    }

    openApplicantDetailModal() {
        const modalRef = this.modalService.open(ApplicantDetailModalComponent, {
            windowClass: 'modal-pane',
            animation: false,
        });
        if (this.applicant) {
            modalRef.componentInstance.applicant = this.applicant;
        } else {
            modalRef.componentInstance.applicantUuid = this.applicantUuid;
        }
    }

    getTitle(): string {
        return this.applicant ? this.applicant.firstName + ' ' + this.applicant.lastName : this.title;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
