import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';

import { TeamleaderAddDealComponent } from '../teamleader-add-deal/teamleader-add-deal.component';
import { FetchCompanies, FetchContacts, FetchDealPhases, FetchDeals, FetchDepartments, FetchEmployees } from '../teamleader.actions';
import { TeamleaderState } from '../teamleader.state';

@Component({
    selector: 'app-teamleader-deals',
    templateUrl: './teamleader-deals.component.html',
    styleUrls: ['./teamleader-deals.component.scss'],
})
export class TeamleaderDealsComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Select(TeamleaderState.dealPhases) phases$: Observable<any[]>;
    @Select(TeamleaderState.deals) deals$: Observable<any[]>;
    @Select(TeamleaderState.employees) employees$: Observable<any[]>;
    @Select(TeamleaderState.contacts) contacts$: Observable<any[]>;
    @Select(TeamleaderState.companies) companies$: Observable<any[]>;
    @Select(TeamleaderState.departments) departments$: Observable<any[]>;

    deals: any[];
    phases: any[];
    employees: any[];
    contacts: any[];
    companies: any[];
    departments: any[];

    filter: string;
    isset: boolean;
    isLoading = true;

    constructor(private store: Store, private modalService: NgbModal) {}

    ngOnInit(): void {}

    checkCredentials(set: boolean) {
        this.isset = set;
        if (this.isset) {
            this.store.dispatch(new FetchDealPhases());
            this.store.dispatch(new FetchDeals());
            this.store.dispatch(new FetchEmployees());
            this.store.dispatch(new FetchContacts());
            this.store.dispatch(new FetchCompanies());
            this.store.dispatch(new FetchDepartments());

            this.subscriptions.add(this.phases$.subscribe((phases) => (this.phases = phases)));
            this.subscriptions.add(this.deals$.subscribe((deals) => (this.deals = deals)));
            this.subscriptions.add(this.employees$.subscribe((employees) => (this.employees = employees)));
            this.subscriptions.add(this.contacts$.subscribe((contacts) => (this.contacts = contacts)));
            this.subscriptions.add(this.companies$.subscribe((companies) => (this.companies = companies)));
            this.subscriptions.add(
                this.departments$.subscribe((departments) => {
                    this.departments = departments;
                    this.isLoading = false;
                }),
            );
        }
    }

    openAddDealModal() {
        const modalRef = this.modalService.open(TeamleaderAddDealComponent, {
            windowClass: 'modal-prompt',
        });
    }

    getResponsibleUser(userId: string) {
        const user = this.employees.find((e) => e.id === userId);
        return `${user.first_name} ${user.last_name}`;
    }

    getDealPhase(dealPhaseId: string) {
        const dealPhase = this.phases.find((dp) => dp.id === dealPhaseId);
        return dealPhase ? dealPhase.name : 'Unknown';
    }

    tryGetNameFromId(idModel: any) {
        let entity = null;

        if (idModel.type === 'contact') {
            entity = this.contacts.find((c) => c.id === idModel.id);
            return entity != null ? `${entity.first_name} ${entity.last_name}` : 'Unknown';
        } else if (idModel.type === 'company') {
            entity = this.companies.find((c) => c.id === idModel.id);
            return entity != null ? entity.name : 'Unknown';
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
