<a
    class="pill pill--partner"
    *ngIf="!isLoading"
    (click)="openProjectInfoModal(); $event.stopPropagation()">
    <i class="las la-stream"></i>
    {{ getTitle() }}
</a>

<a
    class="pill pill--customer"
    *ngIf="isLoading">
    <app-loading-message></app-loading-message>
</a>
