import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subscription } from 'rxjs';
import { ContactPerson } from 'src/app/models/ContactPerson';
import { Partner } from 'src/app/models/Partner';

import { ContactpersonDeleteModalComponent } from '../../contacts/contactperson-delete-modal/contactperson-delete-modal.component';
import { ContactpersonDetailModalComponent } from '../../contacts/contactperson-detail-modal/contactperson-detail-modal.component';
import { PartnerState } from '../partners.state';

@Component({
    selector: 'app-partner-contactperson-list',
    templateUrl: './partner-contactperson-list.component.html',
    styleUrls: ['./partner-contactperson-list.component.scss'],
})
export class PartnerContactpersonListComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Select(PartnerState.getPartners) allPartners$: Observable<Partner[]>;
    @Input() partner: Partner;

    isLoading = true;
    noPartnerContacts = false;
    filter: string;

    // mat table resources
    displayedColumns: string[] = ['firstName', 'contactInformation.phoneNumber', 'contactInformation.email', 'edit'];
    dataSource: MatTableDataSource<ContactPerson>;
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    constructor(private modalService: NgbModal, private logger: NGXLogger) {}

    ngOnInit(): void {
        this.subscriptions.add(
            this.allPartners$.subscribe((allPartners) => {
                const partner = allPartners.find((partner) => partner.uuid === this.partner.uuid);
                if (partner.contactPeople.length > 0) {
                    this.dataSource = new MatTableDataSource(partner.contactPeople);
                    this.dataSource.sort = this.sort;
                    this.dataSource.sortingDataAccessor = (item, property) => {
                        if (property.includes('.')) return property.split('.').reduce((o, i) => o[i], item);
                        return item[property];
                    };
                    this.isLoading = false;
                    this.noPartnerContacts = false;
                } else {
                    this.noPartnerContacts = true;
                    this.isLoading = false;
                }
            }),
        );
    }

    applyFilter(filterValue) {
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }

    openUpdateContactPersonModal(contactPerson: ContactPerson) {
        this.logger.debug('Opening contactPersonDetailModal - update');
        const modalRef = this.modalService.open(ContactpersonDetailModalComponent);
        modalRef.componentInstance.contactPersonUuid = contactPerson.uuid;
        modalRef.componentInstance.isUpdate = true;
    }

    openDeleteContactPersonModal(contactPerson: ContactPerson) {
        this.logger.debug('Opening contactPersonDeleteModal');
        const modalRef = this.modalService.open(ContactpersonDeleteModalComponent, { windowClass: 'modal-prompt' });
        modalRef.componentInstance.contactPerson = contactPerson;
    }

    openAddContactPersonModal() {
        this.logger.debug('Opening contactPersonDetailModal - add');
        const modalRef = this.modalService.open(ContactpersonDetailModalComponent);
        modalRef.componentInstance.partnerUuid = this.partner.uuid;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
