import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '../authentication/auth.guard';
import { CompanyGuard } from '../companies/company.guard';
import { LeadDetailComponent } from './lead-detail/lead-detail.component';
import { LeadsOverviewComponent } from './leads-overview/leads-overview.component';

export const routes: Routes = [
    { path: '', redirectTo: 'leads/overview', pathMatch: 'full' },
    {
        path: 'leads/overview',
        component: LeadsOverviewComponent,
        canActivate: [AuthGuard, CompanyGuard],
    },
    {
        path: 'lead/:id/detail',
        component: LeadDetailComponent,
        canActivate: [AuthGuard, CompanyGuard],
    },
];

export const routing: ModuleWithProviders<any> = RouterModule.forChild(routes);
