<form
    [formGroup]="languageFormGroup"
    (ngSubmit)="editLanguage()"
    class="modal-wrapper">
    <div class="modal-header">
        <h2
            class="modal-title"
            id="exampleModalLabel">
            Edit {{ this.language.languageName }}
        </h2>
        <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="activeModal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div *ngIf="this.loading">
        <app-loading-message></app-loading-message>
    </div>

    <div
        class="modal-body"
        *ngIf="!this.loading">
        <div class="form-group m-b-2 required">
            <label>Language name</label>
            <input
                type="text"
                placeholder="Language name"
                formControlName="languageName"
                trim="blur"
                #focussed />
            <span
                class="form-error"
                *ngIf="languageFormGroup.controls.languageName.touched && languageFormGroup.controls.languageName?.errors"
                >Language name is required</span
            >
        </div>

        <div class="form-group m-b-2 required">
            <label>Language Code</label>
            <input
                type="text"
                placeholder="Language code"
                formControlName="languageCode"
                trim="blur"
                #focussed />
            <span
                class="form-error"
                *ngIf="languageFormGroup.controls.languageCode.touched && languageFormGroup.controls.languageCode?.errors"
                >Language code is required</span
            >
        </div>

        <div class="form-group m-b-2 required">
            <label>Link a Country</label>
            <div class="form-group m-b-2">
                <div class="d-flex">
                    <div class="ng-autocomplete">
                        <ng-autocomplete
                            formControlName="country"
                            [data]="this.countries"
                            [searchKeyword]="countryKeyword"
                            [itemTemplate]="itemTemplate"
                            autocomplete="off"
                            [notFoundTemplate]="notFoundTemplate"
                            placeholder="Choose country">
                        </ng-autocomplete>

                        <ng-template
                            #itemTemplate
                            let-item>
                            <a [innerHTML]="item.countryName"></a>
                        </ng-template>

                        <ng-template
                            #notFoundTemplate
                            let-notFound>
                            No countries found matching this value
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <button
            type="button"
            class="button button--outline"
            (click)="activeModal.close()">
            Close
        </button>
        <button
            type="submit"
            class="button button--primary"
            [disabled]="!languageFormGroup.valid">
            Edit language
        </button>
    </div>
</form>
