<div class="modal-header">
    <h2
        *ngIf="!archived"
        class="modal-title"
        id="exampleModalLabel">
        Archive Lead
    </h2>
    <h2
        *ngIf="archived"
        class="modal-title"
        id="exampleModalLabel">
        Unarchive Lead
    </h2>
    <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div
    *ngIf="!archived"
    class="modal-body">
    Are you sure you want to archive this lead?
</div>
<div
    *ngIf="archived"
    class="modal-body">
    Are you sure you want to unarchive this lead?
</div>

<div class="modal-footer">
    <button
        class="button button--danger"
        (click)="archiveLead()">
        <span *ngIf="!archived">Archive</span>
        <span *ngIf="archived">Unarchive</span>
    </button>
    <button
        type="button"
        class="button button--outline"
        (click)="activeModal.close()">
        Cancel
    </button>
</div>
