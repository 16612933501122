import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Select, Store } from '@ngxs/store';
import { MatTableExporterDirective } from 'mat-table-exporter';
import { Observable, Subscription } from 'rxjs';
import { CompanyState } from 'src/app/companies/company.state';
import { StockUpdateHistoryModel } from 'src/app/models/StockUpdateHistoryModel';

import { Product } from '../../models/Product';
import { FetchProductStockUpdateHistory } from '../product.actions';
import { ProductState } from '../product.state';

@Component({
    selector: 'app-product-stock-history-list',
    templateUrl: './product-stock-history-list.component.html',
    styleUrls: ['./product-stock-history-list.component.scss'],
})
export class ProductStockHistoryListComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Select(CompanyState.companyUuid) companyUuid$: Observable<string>;
    @Select(ProductState.ProductStockUpdateHistory)
    productStockUpdateHistory$: Observable<StockUpdateHistoryModel[]>;
    @Select(ProductState.ProductCategories) productCategory$: Observable<[]>;
    @Select(ProductState.Products) products$: Observable<[]>;

    filter: string;
    displayedColumns: string[] = [
        'updatedProductTitle',
        'productAmountState',
        'currentStock',
        'updatedBy',
        'lastModified',
        'productConsumable',
        'productCategories',
        'employeeUuid',
    ];
    dataSource: MatTableDataSource<StockUpdateHistoryModel>;
    categoryList: string[] = [];
    productsList: Product[];
    properties;
    nameFilter = '';
    selectedCategories: string[] = [];
    selectedConsumable: boolean[] = [true, false];
    consumableLabel = 'All';
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    categoryCheckboxes: boolean[];
    categoryCheckboxAll: boolean;
    categoryCheckboxBool: boolean[] = [];
    @ViewChild(MatTableExporterDirective, { static: true })
    exporter: MatTableExporterDirective;

    constructor(private store: Store) {}

    ngOnInit(): void {
        this.store.dispatch(new FetchProductStockUpdateHistory());
        this.subscriptions.add(
            this.productCategory$.subscribe((categories) => {
                this.categoryList = categories;
                this.categoryCheckboxes = [];
            }),
        );
        this.subscriptions.add(
            this.products$.subscribe((products) => {
                this.productsList = products;
            }),
        );
        this.subscriptions.add(
            this.productStockUpdateHistory$.subscribe((histories) => {
                this.dataSource = new MatTableDataSource(histories);
                this.dataSource.sort = this.sort;
                this.dataSource.filterPredicate = this.createFilter();
            }),
        );
    }

    applyFilter(filterValue: string) {
        this.nameFilter = filterValue;
        this.executeFilter();
    }

    filterOnCategory($event, i) {
        if (i === 0) {
            this.categoryList.forEach((category, index) => {
                this.categoryCheckboxBool[index] = false;
            });
            this.selectedCategories = [];
        } else {
            this.categoryCheckboxAll = false;
            if ($event.target.checked) {
                this.selectedCategories.push(this.categoryList[i - 1]);
            } else {
                this.selectedCategories.splice(
                    this.selectedCategories.findIndex((category) => category === this.categoryList[i - 1]),
                    1,
                );
            }
        }
        this.executeFilter();
    }

    filterOnConsumable($event) {
        this.selectedConsumable = [true, false];
        this.consumableLabel = 'All';
        if ($event.target.id === 'consumable') {
            this.selectedConsumable = [true];
            this.consumableLabel = 'Consumable';
        } else if ($event.target.id === 'nonConsumable') {
            this.selectedConsumable = [false];
            this.consumableLabel = 'Non-Consumable';
        }
        this.executeFilter();
    }

    executeFilter() {
        this.dataSource.filter = JSON.stringify({
            searchResult: this.nameFilter.trim().toLowerCase(),
            selectedCategories: this.selectedCategories,
            selectedConsumable: this.selectedConsumable,
        });
    }

    createFilter() {
        return (data, filter) => {
            const filterObj = JSON.parse(filter);
            let showRow = false;
            // if all filters are reset
            if (data.productCategories.length === 0 && filterObj.selectedCategories.length === 0 && filterObj.selectedConsumable.length === 2) {
                showRow = true;
            } else {
                if (filterObj.selectedCategories.length === 0) {
                    showRow = true;
                } else {
                    showRow = data.productCategories.some((category) => {
                        return filterObj.selectedCategories.includes(category);
                    });
                }
            }
            if (filterObj.selectedConsumable.length === 2) {
                return (
                    (data.updatedProductTitle.trim().toLowerCase().includes(filterObj.searchResult) ||
                        data.updatedBy.trim().toLowerCase().includes(filterObj.searchResult)) &&
                    (data.productConsumable === true || data.productConsumable === false) &&
                    showRow
                );
            }
            return (
                (data.updatedProductTitle.trim().toLowerCase().includes(filterObj.searchResult) ||
                    data.updatedBy.trim().toLowerCase().includes(filterObj.searchResult)) &&
                data.productConsumable === filterObj.selectedConsumable[0] &&
                showRow
            );
        };
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
