<div class="d-flex">
    <div class="m-r-4">
        <p class="form-helptext">All documents without a mapped contact will be checked. When the Yuki contact name, found in the list below, is found in the contact name from the Yuki API, the corresponding Resumo contact will be saved to the invoice.</p>
        <p class="form-helptext">New mappings will be created automatically when you verify the document and link a Resumo contact.</p>
    </div>
    <button (click)="refreshMappings()" class="button overflow--visible">Refresh mappings</button>
</div>

<form (ngSubmit)="addNewMapping()" *ngIf="partners$ | async; else loading" [formGroup]="mappedContactsForm">
    <p class="sectiontitle">Add new mapping</p>
    <div class="d-flex">
        <input class="m-r-2" formControlName="yukiContact" placeholder="Yuki contact" type="text">

        <ng-autocomplete
            [data]="partners"
            [itemTemplate]="partnerTemplate"
            [notFoundTemplate]="notFoundTemplate"
            formControlName="resumoContact"
            placeholder="Resumo contact"
            searchKeyword="title"
        >
        </ng-autocomplete>
        <ng-template #partnerTemplate let-item>
            <div class="autocomplete--item">
                <a [innerHTML]="item.title"></a>
            </div>
        </ng-template>

        <ng-template #notFoundTemplate>
            <div (click)="openCreateContactModal()">+ Add new contact</div>
        </ng-template>

        <button [disabled]="mappedContactsForm.invalid" class="button button--icon button--primary m-l-2">
            +
        </button>
    </div>

</form>

<p class="sectiontitle">Existing mappings</p>

<table class="table-view">
    <tr>
        <th>Contact name in Yuki</th>
        <th>Contact in Resumo</th>
        <th></th>
    </tr>
    <ng-container *ngIf="partners$ | async; else loadingRow">
        <tr *ngFor="let mappedContact of mappedContacts">
            <td>{{mappedContact.yukiContact}}</td>
            <td>
                <app-pill-partner [partner]="mappedContact.resumoContact"></app-pill-partner>
            </td>
            <td (click)="deleteMappedContact(mappedContact)" class="td-small td-icon">
                <button class="button button--icon">
                    <i class="las la-trash color--red"></i>
                </button>
            </td>
        </tr>
    </ng-container>
</table>

<ng-template #loading>
    <app-loading-message></app-loading-message>
</ng-template>

<ng-template #loadingRow>
    <tr>
        <td><app-loading-message></app-loading-message></td>
    </tr>
</ng-template>
