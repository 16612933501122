<div class="modal-header">
    <h2 class="modal-title">You didn't enable two-factor authentication yet...</h2>

    <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <div class="mfa-content">
        <p class="m-b-6">Increase your account's security by enabling two-factor authentication (2FA)</p>
        <a
            class="button button--primary w-100"
            [routerLink]="'/settings/account'"
            (click)="activeModal.dismiss()"
            >Navigate to settings</a
        >
    </div>
</div>
