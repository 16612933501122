import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatSortModule } from '@angular/material/sort';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTableModule } from '@angular/material/table';
import { NgbDropdownModule, NgbModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { MatTableExporterModule } from 'mat-table-exporter';
import { NgChartsModule } from 'ng2-charts';
import { TagInputModule } from 'ngx-chips';
import { OrderModule } from 'ngx-order-pipe';
import { NgPipesModule } from 'ngx-pipes';
import { NgxTrimDirectiveModule } from 'ngx-trim-directive';

import { FinancialModule } from '../financial/financial.module';
import { PlanningModule } from '../planning/planning.module';
import { SharedModule } from '../shared/shared.module';
import { AddDefaultLinkedEmployeeModalComponent } from './add-default-linked-employee-modal/add-default-linked-employee-modal.component';
import { AssignedResumeDeleteModalComponent } from './assigned-resume-delete-modal/assigned-resume-delete-modal.component';
import { DefaultLinkedEmployeesComponent } from './default-linked-employees/default-linked-employees.component';
import { EmployeeLinkModalComponent } from './employee-link/employee-link-modal/employee-link-modal.component';
import { EmployeeLinkComponent } from './employee-link/employee-link.component';
import { ProjectAddProductModalComponent } from './project-add-product-modal/project-add-product-modal.component';
import { ProjectAssignResumeListComponent } from './project-assign-resume-list/project-assign-resume-list.component';
import { ProjectAssignResumeModalComponent } from './project-assign-resume-modal/project-assign-resume-modal.component';
import { ProjectAssignedResumeListComponent } from './project-assigned-resume-list/project-assigned-resume-list.component';
import { ProjectDeleteModalComponent } from './project-delete-modal/project-delete-modal.component';
import { ProjectDeleteProductComponent } from './project-delete-product/project-delete-product.component';
import { ProjectDetailEmployeesComponent } from './project-detail-employees/project-detail-employees.component';
import { ProjectDetailInfoComponent } from './project-detail-info/project-detail-info.component';
import { ProjectDetailModalComponent } from './project-detail-modal/project-detail-modal.component';
import { ProjectDetailPductsComponent } from './project-detail-products/project-detail-products.component';
import { ProjectDetailResumesComponent } from './project-detail-resumes/project-detail-resumes.component';
import { ProjectInfoModalComponent } from './project-info-modal/project-info-modal.component';
import { ProjectListComponent } from './project-list/project-list.component';
import { ProjectStatusDeleteModalComponent } from './project-status-delete-modal/project-status-delete-modal.component';
import { ProjectStatusDetailModalComponent } from './project-status-detail-modal/project-status-detail-modal.component';
import { ProjectsOverviewComponent } from './projects-overview/projects-overview.component';
import { ProjectsSubnavComponent } from './projects-subnav/projects-subnav.component';
import { routing } from './projects.routing';
import { TimesheetsListComponent } from './timesheets-list/timesheets-list.component';

@NgModule({
    declarations: [
        ProjectsOverviewComponent,
        ProjectDetailModalComponent,
        ProjectListComponent,
        ProjectDeleteModalComponent,
        ProjectAssignResumeModalComponent,
        ProjectAssignedResumeListComponent,
        ProjectAssignResumeListComponent,
        AssignedResumeDeleteModalComponent,
        ProjectStatusDetailModalComponent,
        ProjectStatusDeleteModalComponent,
        ProjectInfoModalComponent,
        ProjectDeleteProductComponent,
        ProjectAddProductModalComponent,
        ProjectsSubnavComponent,
        ProjectDetailInfoComponent,
        ProjectDetailResumesComponent,
        ProjectDetailPductsComponent,
        ProjectDetailEmployeesComponent,
        EmployeeLinkComponent,
        EmployeeLinkModalComponent,
        TimesheetsListComponent,
        DefaultLinkedEmployeesComponent,
        AddDefaultLinkedEmployeeModalComponent,
    ],
    imports: [
        CommonModule,
        SharedModule,
        AutocompleteLibModule,
        routing,
        NgbModule,
        NgbDropdownModule,
        NgbNavModule,
        NgxTrimDirectiveModule,
        NgPipesModule,
        OrderModule,
        TagInputModule,
        CdkTableModule,
        MatTableModule,
        MatSortModule,
        TagInputModule,
        MatTableExporterModule,
        NgChartsModule,
        FinancialModule,
        PlanningModule,
        MatProgressBarModule,
    ],
    exports: [ProjectDetailModalComponent],
})
export class ProjectsModule {}
