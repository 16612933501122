import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { AuthenticationService } from '../authentication/authentication.service';
import Customer from '../models/Customer';

@Injectable({
    providedIn: 'root',
})
export class CustomerService {
    private httpOptions = {};

    constructor(private httpClient: HttpClient, private authenticationService: AuthenticationService) {
        this.httpOptions = authenticationService.getHeaders();
    }

    getCustomers(): Observable<Customer[]> {
        return this.httpClient.get<Customer[]>(environment.baseUrl + '/customer', this.httpOptions);
    }

    addCustomer(customer: Customer): Observable<Customer> {
        return this.httpClient.post<Customer>(environment.baseUrl + '/customer', customer, this.httpOptions);
    }

    updateCustomers(customer: Customer): Observable<Customer> {
        return this.httpClient.put<Customer>(environment.baseUrl + '/customer', customer, this.httpOptions);
    }

    deleteCustomer(customerUuid: string): Observable<boolean> {
        return this.httpClient.delete<boolean>(environment.baseUrl + `/customer/${customerUuid}`, this.httpOptions);
    }
}
