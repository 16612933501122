import { NgxMatDatetimePickerModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { NgbDropdownModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TagInputModule } from 'ngx-chips';
import { OrderModule } from 'ngx-order-pipe';
import { SortablejsModule } from 'ngx-sortablejs';
import { NgxTrimDirectiveModule } from 'ngx-trim-directive';

import { SharedModule } from '../shared/shared.module';
import { TeamleaderAddCalendarCallComponent } from './teamleader-add-calendar-call/teamleader-add-calendar-call.component';
import { TeamleaderAddCalendarMeetingComponent } from './teamleader-add-calendar-meeting/teamleader-add-calendar-meeting.component';
import { TeamleaderAddCalendarTaskComponent } from './teamleader-add-calendar-task/teamleader-add-calendar-task.component';
import { TeamleaderAddDealComponent } from './teamleader-add-deal/teamleader-add-deal.component';
import { TeamleaderAgendaComponent } from './teamleader-agenda/teamleader-agenda.component';
import { TeamleaderCompaniesComponent } from './teamleader-companies/teamleader-companies.component';
import { TeamleaderContactsComponent } from './teamleader-contacts/teamleader-contacts.component';
import { TeamleaderDealsComponent } from './teamleader-deals/teamleader-deals.component';
import { TeamleaderDepartmentInfoComponent } from './teamleader-department-info/teamleader-department-info.component';
import { TeamleaderDepartmentsComponent } from './teamleader-departments/teamleader-departments.component';
import { TeamleaderEmployeesComponent } from './teamleader-employees/teamleader-employees.component';
import { TeamleaderOverviewComponent } from './teamleader-overview/teamleader-overview.component';
import { TeamleaderSubnavComponent } from './teamleader-subnav/teamleader-subnav.component';
import { routing } from './teamleader.routing';

@NgModule({
    declarations: [
        TeamleaderOverviewComponent,
        TeamleaderEmployeesComponent,
        TeamleaderContactsComponent,
        TeamleaderAgendaComponent,
        TeamleaderDepartmentsComponent,
        TeamleaderSubnavComponent,
        TeamleaderDepartmentInfoComponent,
        TeamleaderAddCalendarMeetingComponent,
        TeamleaderAddCalendarTaskComponent,
        TeamleaderAddCalendarCallComponent,
        TeamleaderDealsComponent,
        TeamleaderAddDealComponent,
        TeamleaderCompaniesComponent,
    ],
    imports: [
        CommonModule,
        SharedModule,
        routing,
        NgbModule,
        NgbDropdownModule,
        NgxTrimDirectiveModule,
        OrderModule,
        TagInputModule,
        SortablejsModule,
        FormsModule,
        ReactiveFormsModule,
        MatDatepickerModule,
        MatInputModule,
        NgxMatDatetimePickerModule,
        NgxMatTimepickerModule,
        MatButtonModule,
        NgxMatMomentModule,
        MatRadioModule,
        MatSelectModule,
        MatCheckboxModule,
    ],
    exports: [TeamleaderOverviewComponent],
})
export class TeamleaderModule {}
