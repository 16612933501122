import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-resume-detail-page',
    templateUrl: './resume-detail-page.component.html',
    styleUrls: ['./resume-detail-page.component.scss'],
})
export class ResumeDetailPageComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
