<div *ngIf="resume && selectedResumeVariant; else loading" class="resume-v2">
    <header class="resume-v2__header">
        <div class="resume-v2__header-info">
            <img
                [src]="'https://eu.ui-avatars.com/api/?background=random&bold=true&name=' + resume.firstName + '%20' + resume.lastName"
                class="resume-v2__avatar"/>
            <div>
                <h2 class="resume-v2__title">{{ resume.firstName }} {{ resume.lastName }}</h2>
                <p class="resume-v2__info">Last edited: {{resume.lastModified}}</p>
            </div>
        </div>
        <div class="resume-v2__header-actions">
            <select #variation
                    (change)="onChange(variation.value)"
                    [(ngModel)]="selectedResumeVariantUuid"
                    class="resume-v2__variant-select">
                <option
                    *ngFor="let variant of resume.resumeVariants; trackBy: trackByVariantId"
                    [value]="variant.uuid">
                    {{ variant.jobTitle }}
                </option>
            </select>

            <div
                *ngIf="showActions && !inApplicants"
                class="resume__actions dropdown m-l-2"
                ngbDropdown>
                <button
                    class="button button--icon dropdown-toggle"
                    id="dropdownMenuButton"
                    ngbDropdownToggle>
                    <span>Actions</span>
                </button>
                <div
                    aria-labelledby="dropdownMenuButton"
                    class="dropdown-menu dropdown-menu-right"
                    ngbDropdownMenu>

                    <a (click)="openManageVariantsModal()" class="dropdown-item">
                        Manage variants
                    </a>

                    <a (click)="downloadResumePdf()" class="dropdown-item">
                        Download as PDF
                    </a>

                    <a (click)="openEditResumePersonInfoUpdateModal()" class="dropdown-item">
                        Edit personal info
                    </a>

                    <div class="dropdown-divider"></div>

                    <a (click)="openResumeDeleteModal()" class="dropdown-item color--red">
                        Archive
                    </a>
                </div>
            </div>
        </div>

    </header>
    <main>
        <div class="divider"></div>

        <section class="resume-v2__section d-flex">
            <div class="w-100">
                <h3 class="resume-v2__section-title">General information</h3>
                <p>
                    <ng-container *ngIf="resume.gender">{{ resume.gender }}</ng-container>
                    <ng-container *ngIf="resume.gender && resume.birthday"> - </ng-container>
                    <ng-container *ngIf="resume.birthday">{{resume.birthday | date}}</ng-container>
                </p>
                <app-pill-address *ngIf="resume.address" [address]="resume.address"></app-pill-address>
            </div>
            <div class="w-100">
                <h3 class="resume-v2__section-title">Contact information</h3>
                <div *ngIf="resume.contactInformation?.email">
                    <app-pill-mailto [mailtoEmail]="resume.contactInformation.email"></app-pill-mailto>
                </div>
                <div *ngIf="resume.contactInformation?.phoneNumber">
                    <app-pill-telephone
                        [contactPhonenumber]="resume.contactInformation.phoneNumber"></app-pill-telephone>
                </div>
            </div>
        </section>

        <div class="divider"></div>

        <section class="resume-v2__section">
            <h3 class="resume-v2__section-title">About {{resume.firstName}}</h3>
            <p *ngIf="resume.description; else noDescription">{{resume.description}}</p>
            <ng-template #noDescription>There is no extra information about {{resume.firstName}}...</ng-template>
        </section>

        <div class="divider"></div>

        <section class="resume-v2__section m-b-8">
            <h3 class="resume-v2__section-title">Skill matrix</h3>

            <app-resume-variant-skill-list [resumeVariantSkills]="selectedResumeVariant.skills" [resumeVariant]="selectedResumeVariant"></app-resume-variant-skill-list>
        </section>

        <section class="resume-v2__section m-b-8">
            <h3 class="resume-v2__section-title">Work experience</h3>

            <app-resume-experience-list [experiences]="resume.experiences" [resumeId]="resume.uuid"></app-resume-experience-list>
        </section>

        <section class="resume-v2__section m-b-8">
            <h3 class="resume-v2__section-title">Technical skills</h3>

            <app-resume-technical-skills-list [resumeId]="resume.uuid" [tags]="resume.globalTags"></app-resume-technical-skills-list>
        </section>

        <section class="resume-v2__section m-b-8">
            <h3 class="resume-v2__section-title">Education</h3>

            <app-resume-education-list [educations]="resume.educations" [resumeId]="resume.uuid"></app-resume-education-list>
        </section>

        <section class="resume-v2__section m-b-8">
            <h3 class="resume-v2__section-title">Languages</h3>

            <app-resume-language-list [languages]="resume.languages" [resumeId]="resume.uuid"></app-resume-language-list>
        </section>
    </main>
</div>

<ng-template #loading>
    <app-loading-message></app-loading-message>
</ng-template>
