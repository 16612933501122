import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';
import { Product } from 'src/app/models/Product';

import { ProductService } from '../product.service';

@Component({
    selector: 'app-product-stock-update',
    templateUrl: './product-stock-update.component.html',
    styleUrls: ['./product-stock-update.component.scss'],
})
export class ProductStockUpdateComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    productStockAmountUpdateForm: FormGroup;
    product: Product;

    updateSuccess = false;
    stockAfterSuccess: number;

    updateFailure = false;
    errorMessage: string;

    loading = true;
    valueRetrieved = true;

    constructor(private formBuilder: FormBuilder, private productService: ProductService, private route: ActivatedRoute, private logger: NGXLogger) {}

    ngOnInit(): void {
        const productUuid = this.route.snapshot.paramMap.get('productUuid');

        this.subscriptions.add(
            this.productService.getProductById(productUuid).subscribe(
                (product) => {
                    this.product = product;
                    if (this.product != null) {
                        this.loading = false;
                    } else {
                        this.valueRetrieved = false;
                    }
                },
                (failure: any) => {
                    this.loading = false;
                    this.valueRetrieved = false;
                },
            ),
        );

        this.productStockAmountUpdateForm = this.formBuilder.group({
            changedAmount: ['', [Validators.required, Validators.min(1)]],
            amountState: [''],
        });
    }

    submit(event: any): void {
        event.preventDefault();

        const companyUuid = this.route.snapshot.paramMap.get('companyUuid');
        const productUuid = this.route.snapshot.paramMap.get('productUuid');

        this.productStockAmountUpdateForm.value.amountState = event.submitter.alt;

        this.logger.debug('Attempt to update stock of product');
        this.subscriptions.add(
            this.productService.updateStock(companyUuid, productUuid, this.productStockAmountUpdateForm.value).subscribe(
                (success: any) => {
                    this.logger.debug('Succesfully updated stock of product');
                    this.updateFailure = false;
                    this.updateSuccess = true;
                    this.stockAfterSuccess = this.product.inStock;
                    event.submitter.alt == 'ADDED'
                        ? (this.stockAfterSuccess += this.productStockAmountUpdateForm.value.changedAmount)
                        : (this.stockAfterSuccess -= this.productStockAmountUpdateForm.value.changedAmount);
                },
                (failure: any) => {
                    this.logger.error('Faied to update stock of product');
                    this.updateFailure = true;
                    this.errorMessage = failure.error.message;
                },
            ),
        );
    }

    refresh(): void {
        window.location.reload();
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
