import { Component, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { GlobalTag } from '../../models/GlobalTag';
import { TechnicalSkillsAddModalComponent } from '../technical-skills-add-modal/technical-skills-add-modal.component';

@Component({
    selector: 'app-resume-technical-skills-list',
    templateUrl: './resume-technical-skills-list.component.html',
    styleUrls: ['./resume-technical-skills-list.component.scss'],
})
export class ResumeTechnicalSkillsListComponent {
    @Input() tags: GlobalTag[];
    @Input() resumeId: string;

    constructor(private modalService: NgbModal) {}

    openEditSkillsModal() {
        const modalRef = this.modalService.open(TechnicalSkillsAddModalComponent, { windowClass: 'modal-prompt', animation: false });
        modalRef.componentInstance.resumeId = this.resumeId;
    }
}
