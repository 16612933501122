import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { AuthenticationService } from '../authentication/authentication.service';
import { Education } from '../models/resume/Education';
import { Experience } from '../models/resume/Experience';
import { Language } from '../models/resume/Language';
import { Resume } from '../models/resume/Resume';
import { ResumeVariant } from '../models/resume/ResumeVariant';
import { ResumeVariantSkill } from '../models/resume/ResumeVariantSkill';

@Injectable({
    providedIn: 'root',
})
export class ResumeService {
    httpOptions = {};

    constructor(private httpClient: HttpClient, private authenticationService: AuthenticationService) {
        this.httpOptions = authenticationService.getHeaders();
    }

    getAllResumes(): Observable<Resume[]> {
        return this.httpClient.get<Resume[]>(environment.baseUrl + '/resume/resume-list', this.httpOptions);
    }

    getResumeById(uuid: string): Observable<Resume> {
        return this.httpClient.get<Resume>(environment.baseUrl + '/resume/' + uuid, this.httpOptions);
    }

    addResume(resume: Resume): Observable<Resume> {
        return this.httpClient.post<Resume>(environment.baseUrl + '/resume', resume, this.httpOptions);
    }

    updateResume(resume: Resume): Observable<Resume> {
        return this.httpClient.put<Resume>(environment.baseUrl + '/resume/' + resume.uuid, resume, this.httpOptions);
    }

    archiveResume(uuid: string): Observable<Resume> {
        return this.httpClient.put<Resume>(environment.baseUrl + '/resume/' + uuid + '/archive-resume', {}, this.httpOptions);
    }

    addEducation(resumeUuid: string, education: Education): Observable<Education> {
        return this.httpClient.post<Education>(environment.baseUrl + '/resume/' + resumeUuid + '/education', education, this.httpOptions);
    }

    updateEducation(educationUuid: string, education: Education): Observable<Education> {
        return this.httpClient.put<Education>(environment.baseUrl + '/resume/education/' + educationUuid, education, this.httpOptions);
    }

    deleteEducation(educationUuid: string): Observable<string> {
        return this.httpClient.delete<string>(environment.baseUrl + '/resume/education/' + educationUuid, this.httpOptions);
    }

    getAllEducationsOfResume(resumeUuid: string): Observable<Education[]> {
        return this.httpClient.get<Education[]>(environment.baseUrl + '/resume/' + resumeUuid + '/education-list', this.httpOptions);
    }

    addExperience(resumeUuid: string, experience: Experience): Observable<Experience> {
        return this.httpClient.post<Experience>(environment.baseUrl + '/experience/' + resumeUuid, experience, this.httpOptions);
    }

    updateExperience(experienceUuid: string, experience: Experience) {
        return this.httpClient.put<Experience>(environment.baseUrl + '/experience/' + experienceUuid, experience, this.httpOptions);
    }

    deleteExperience(experienceUuid: string) {
        return this.httpClient.delete<string>(environment.baseUrl + '/experience/' + experienceUuid, this.httpOptions);
    }

    getAllExperiencesOfResume(resumeUuid: string): Observable<Experience[]> {
        return this.httpClient.get<Experience[]>(environment.baseUrl + '/experience/' + resumeUuid + '/experience-list', this.httpOptions);
    }

    addLanguage(resumeUuid: string, language: Language): Observable<Language> {
        return this.httpClient.post<Language>(environment.baseUrl + '/language/' + resumeUuid, language, this.httpOptions);
    }

    updateLanguage(languageUuid: string, language: Language): Observable<Language> {
        return this.httpClient.put<Language>(environment.baseUrl + '/language/' + languageUuid, language, this.httpOptions);
    }

    deleteLanguage(languageUuid: string) {
        return this.httpClient.delete(environment.baseUrl + '/language/' + languageUuid, this.httpOptions);
    }

    getAllLanguagesOfResume(resumeUuid: string): Observable<Language[]> {
        return this.httpClient.get<Language[]>(environment.baseUrl + '/language/' + resumeUuid + '/language-list', this.httpOptions);
    }

    addDescriptionToResume(resumeUuid: string, resume: Resume): Observable<Resume> {
        return this.httpClient.put<Resume>(environment.baseUrl + '/resume/' + resumeUuid + '/edit-description', resume, this.httpOptions);
    }

    addResumeVariant(resumeUuid: string, resumeVariant: ResumeVariant): Observable<ResumeVariant> {
        return this.httpClient.post<ResumeVariant>(`${environment.baseUrl}/resume-variant/${resumeUuid}`, resumeVariant, this.httpOptions);
    }

    addResumeVariantSkill(resumeVariantUuid: string, skill: ResumeVariantSkill): Observable<unknown> {
        return this.httpClient.post<ResumeVariantSkill>(`${environment.baseUrl}/resume-variant/${resumeVariantUuid}/add-skill`, skill, this.httpOptions);
    }

    getResumeVariants(resumeUuid: string): Observable<ResumeVariant[]> {
        return this.httpClient.get<ResumeVariant[]>(`${environment.baseUrl}/resume-variant/${resumeUuid}`, this.httpOptions);
    }

    updateResumeVariant(resumeVariantUuid: string, resumeVariant: ResumeVariant): Observable<ResumeVariant> {
        return this.httpClient.put<ResumeVariant>(`${environment.baseUrl}/resume-variant/${resumeVariantUuid}`, resumeVariant, this.httpOptions);
    }

    updateResumeVariantSkill(skillUuid: string, skill: ResumeVariantSkill): Observable<unknown> {
        return this.httpClient.put<ResumeVariantSkill>(`${environment.baseUrl}/resume-variant/${skillUuid}/update-skill`, skill, this.httpOptions);
    }

    deleteResumeVariant(resumeVariantUuid: string) {
        return this.httpClient.delete(`${environment.baseUrl}/resume-variant/${resumeVariantUuid}`, this.httpOptions);
    }

    deleteResumeVariantSkill(skillUuid: string): Observable<unknown> {
        return this.httpClient.delete(`${environment.baseUrl}/resume-variant/${skillUuid}/delete-skill`, this.httpOptions);
    }

    downloadResumePdf(resumeUuid, variantIndex): any {
        return this.httpClient.get<any>(`${environment.baseUrl}/resume/${resumeUuid}/${variantIndex}/pdf`, this.httpOptions);
    }
}
