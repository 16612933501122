<div class="modal-header">
    <h2 class="modal-title">Edit required hours</h2>
    <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<app-loading-message *ngIf="isLoading"></app-loading-message>

<div class="modal-body">
    <div class="p-b-2">
        <label for="hoursRequired">Required hours</label>
        <input
            id="hoursRequired"
            type="number"
            min="0"
            max="744"
            [(ngModel)]="hoursRequired" />
    </div>
</div>

<div class="modal-footer">
    <button
        class="button button--primary w-100"
        (click)="editTimesheet()">
        Save
    </button>
</div>
