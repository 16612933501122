import { Component, Input } from '@angular/core';
import { GlobalTag } from 'src/app/models/GlobalTag';

@Component({
    selector: 'app-company-tag-list',
    templateUrl: './company-tag-list.component.html',
    styleUrls: ['./company-tag-list.component.scss'],
})
export class CompanyTagListComponent {
    @Input() tags: GlobalTag[];

    filter: string;
}
