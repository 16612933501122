import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-teamleader-subnav',
    templateUrl: './teamleader-subnav.component.html',
    styleUrls: ['./teamleader-subnav.component.scss'],
})
export class TeamleaderSubnavComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
