import { Component, Input } from '@angular/core';
import { Applicant } from 'src/app/models/Applicants/Applicant';
import { ApplicantLane } from 'src/app/models/Applicants/ApplicantLane';
import { getBadgeClass } from 'src/app/models/LineItemPriority';

@Component({
    selector: 'app-applicant-kanban-item',
    templateUrl: './applicant-kanban-item.component.html',
    styleUrls: ['./applicant-kanban-item.component.scss'],
})
export class ApplicantKanbanItemComponent {
    @Input() applicant: Applicant;
    @Input() lane: ApplicantLane;

    isWarning(dateSinceLastMovement, rottingIn: number, type: string): boolean {
        if (type != 'WIN' && type != 'LOSS' && !this.isOverdue(dateSinceLastMovement, rottingIn, type)) {
            const date = new Date(dateSinceLastMovement);
            const now = new Date();
            return date.getDate() + rottingIn - 1 <= now.getDate();
        }
        return false;
    }

    isOverdue(dateSinceLastMovement, rottingIn: number, type: string): boolean {
        if (type != 'WIN' && type != 'LOSS') {
            const date = new Date(dateSinceLastMovement);
            const now = new Date();
            return date.getDate() + rottingIn <= now.getDate();
        }
        return false;
    }

    getBadgeClass = getBadgeClass;
}
