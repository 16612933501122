import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

import { AuthenticationService } from '../../../authentication/authentication.service';

@Injectable({
    providedIn: 'root',
})
export class ExactLoginService {
    httpOptions = {};

    constructor(private httpClient: HttpClient, private authenticationService: AuthenticationService) {
        this.httpOptions = authenticationService.getHeaders();
    }

    getAuthorizationUrl() {
        return this.httpClient.get<any>(environment.baseUrl + '/exact/authorize', this.httpOptions);
    }

    logout() {
        return this.httpClient.post<boolean>(environment.baseUrl + '/exact/logout', '', this.httpOptions);
    }

    checkLoggedIn() {
        return this.httpClient.get<boolean>(environment.baseUrl + '/exact/isLoggedIn', this.httpOptions);
    }

    saveAccesToken(code: string) {
        return this.httpClient.get<boolean>(environment.baseUrl + '/exact/callback?code=' + code, this.httpOptions);
    }
}
