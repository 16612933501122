<div
    class="modal-wrapper"
    *ngIf="user">
    <div class="modal-header">
        <h2
            class="modal-title"
            id="exampleModalLabel">
            {{ user.firstName }} {{ user.lastName }} details
        </h2>
        <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="activeModal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <span *ngIf="user?.email">{{ user.email }}</span>
    </div>

    <div class="modal-footer">
        <button
            type="button"
            class="button button--outline"
            (click)="activeModal.close()">
            Close
        </button>
    </div>
</div>
