<form
    [formGroup]="stageForm"
    (ngSubmit)="addStage($event)"
    class="modal-wrapper">
    <div class="modal-header">
        <h2
            *ngIf="stage === undefined"
            class="modal-title"
            id="exampleModalLabel">
            New stage
        </h2>
        <h2
            *ngIf="stage !== undefined"
            class="modal-title"
            id="exampleModalLabel">
            Update stage
        </h2>
        <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="activeModal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <div class="row">
            <div class="col">
                <div class="form-group m-b-2 required">
                    <label for="name">Name</label>
                    <small
                        class="form-error"
                        *ngIf="!stageForm.get('name').valid && stageForm.get('name').touched"
                        >Please enter a name</small
                    >
                    <input
                        id="name"
                        formControlName="name"
                        type="text"
                        placeholder="Enter a stage name" />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="form-group m-b-2 required">
                    <label for="probability">Probability (in percentage)</label>
                    <small
                        class="form-error"
                        *ngIf="!stageForm.get('probability').valid && stageForm.get('probability').touched"
                        >Please enter number between 0 and 100</small
                    >
                    <input
                        id="probability"
                        formControlName="probability"
                        type="number"
                        placeholder="Enter the probability as a percentage" />
                    <small class="form-helptext">Probability is the chance that a deal will succeed.</small>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="form-group m-b-2 required">
                    <label for="rottingIn">Rotting in (days)</label>
                    <small
                        class="form-error"
                        *ngIf="!stageForm.get('rottingIn').valid && stageForm.get('rottingIn').touched"
                        >Please enter number</small
                    >
                    <input
                        id="rottingIn"
                        formControlName="rottingIn"
                        type="number"
                        placeholder="Enter the amount of days it takes to rot" />
                    <small class="form-helptext">Rotting is the number of days when a deal will expire.</small>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="form-group m-b-2 required">
                    <label for="type">Type</label>
                    <select
                        id="type"
                        formControlName="type">
                        <option [ngValue]="crmStageType.NORMAL">Normal</option>
                        <option [ngValue]="crmStageType.WIN">Win</option>
                        <option [ngValue]="crmStageType.LOSS">Loss</option>
                        <!--<option [ngValue]="crmStageType.OTHER">Other</option>-->
                    </select>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="form-group m-b-2">
                    <label>Description</label>
                    <textarea
                        class="resize-vertical"
                        formControlName="description"
                        (keyup)="onDescriptionKeyUp()"
                        trim="blur"></textarea>
                    <small> {{ currentAmountOfExtraInfoCharacters }}/{{ descriptionMaxLength }} </small>
                    <span
                        class="form-error"
                        *ngIf="descriptionTooLong"
                        >Extra info cannot contain more than 1000 characters
                    </span>
                </div>
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <input
            *ngIf="stage === undefined"
            class="button"
            [ngClass]="!stageForm.valid ? 'button--disabled' : 'button--primary'"
            type="submit"
            value="Add stage"
            [disabled]="!stageForm.valid" />
        <input
            *ngIf="stage !== undefined"
            class="button"
            [ngClass]="!stageForm.valid ? 'button--disabled' : 'button--primary'"
            type="submit"
            value="Edit stage"
            [disabled]="!stageForm.valid" />
        <button
            class="button button--outline"
            (click)="activeModal.dismiss()">
            Cancel
        </button>
    </div>
</form>
