<div class="modal-wrapper">
    <div class="modal-header">
        <h2 class="modal-title">{{ title }} - {{ date | date: 'MMMM y' }}</h2>
        <button
            (click)="activeModal.dismiss()"
            aria-label="Close"
            class="close"
            type="button">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <ng-container *ngIf="turnoverDataInformation; else purchaseAndSale">
            <table
                #turnoverSort="matSort"
                [dataSource]="turnoverInformationDatasource"
                class="table-view" mat-table matSort>
                <ng-container matColumnDef="title">
                    <th
                        *matHeaderCellDef
                        mat-header-cell mat-sort-header>
                        Title
                    </th>
                    <td *matCellDef="let info" mat-cell>
                        <app-pill-invoice
                            [invoiceId]="info.documentId"
                            [title]="info.title"
                        >
                        </app-pill-invoice>
                    </td>
                    <td *matFooterCellDef class="text-align--right" mat-footer-cell>
                        Total:
                    </td>
                </ng-container>

                <ng-container matColumnDef="amount">
                    <th
                        *matHeaderCellDef
                        mat-header-cell mat-sort-header>
                        Amount
                    </th>
                    <td *matCellDef="let info" mat-cell>
                        {{ info.amount | currency: 'EUR' }}
                    </td>
                    <td *matFooterCellDef mat-footer-cell>
                        <b>{{ calculateTotalForTurnover() | currency: 'EUR' }}</b>
                    </td>
                </ng-container>

                <tr
                    *matHeaderRowDef="turnoverDisplayedColumns"
                    mat-header-row></tr>
                <tr
                    *matRowDef="let row; columns: turnoverDisplayedColumns"
                    mat-row></tr>
                <tr
                    *matFooterRowDef="turnoverDisplayedColumns"
                    mat-footer-row></tr>
            </table>
        </ng-container>

        <ng-template #purchaseAndSale>
            <ng-container *ngIf="purchaseInformation !== undefined || saleInformation !== undefined; else loading">
                <p class="m-b-2"><b>Margin</b>: {{calculateMargin() | currency: 'EUR'}}</p>
                <details open>
                    <summary>
                        <span class="sectiontitle sectiontitle--first summary__title">Income</span>
                        <i class="las la-angle-up summary__up-arrow"></i>
                    </summary>
                    <div>
                        <table
                            #saleSort="matSort"
                            [dataSource]="saleInformationDatasource"
                            class="table-view"
                            mat-table matSort>
                            <ng-container matColumnDef="title">
                                <th
                                    *matHeaderCellDef
                                    mat-header-cell mat-sort-header>
                                    Title
                                </th>
                                <td *matCellDef="let info" mat-cell>
                                    <app-pill-invoice
                                        [invoiceId]="info.objectId"
                                        [title]="info.title"
                                    >
                                    </app-pill-invoice>
                                </td>
                                <td *matFooterCellDef mat-footer-cell>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="invoiceNumber">
                                <th
                                    *matHeaderCellDef
                                    mat-header-cell mat-sort-header>
                                    Invoice number
                                </th>
                                <td *matCellDef="let info" mat-cell>
                                    {{ info.invoiceNumber }}
                                </td>
                                <td *matFooterCellDef class="text-align--right" mat-footer-cell>
                                    Total:
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="amount">
                                <th
                                    *matHeaderCellDef
                                    mat-header-cell mat-sort-header>
                                    Amount
                                </th>
                                <td *matCellDef="let info" mat-cell>
                                    {{ info.amount | currency: 'EUR' }}
                                </td>
                                <td *matFooterCellDef mat-footer-cell>
                                    <b>{{ calculateTotal(saleInformation) | currency: 'EUR' }}</b>
                                </td>
                            </ng-container>

                            <tr
                                *matHeaderRowDef="saleDisplayedColumns"
                                mat-header-row></tr>
                            <tr
                                *matRowDef="let row; columns: saleDisplayedColumns"
                                mat-row></tr>
                            <tr
                                *matFooterRowDef="saleDisplayedColumns"
                                mat-footer-row></tr>
                        </table>

                        <i class="las la-angle-down summary__down-arrow"></i>
                    </div>
                </details>

                <details open>
                    <summary>
                        <span class="sectiontitle summary__title">Cost</span>
                        <i class="las la-angle-up summary__up-arrow"></i>
                    </summary>
                    <div>
                        <table
                            #purchaseSort="matSort"
                            [dataSource]="purchaseInformationDatasource"
                            class="table-view"
                            mat-table matSort>
                            <ng-container matColumnDef="title">
                                <th
                                    *matHeaderCellDef
                                    mat-header-cell mat-sort-header>
                                    Title
                                </th>
                                <td *matCellDef="let info" mat-cell>
                                    {{ info.title | abbreviation }}
                                </td>
                                <td *matFooterCellDef class="text-align--right" mat-footer-cell>
                                    Total:
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="amount">
                                <th
                                    *matHeaderCellDef
                                    mat-header-cell mat-sort-header>
                                    Amount
                                </th>
                                <td *matCellDef="let info" mat-cell>
                                    {{ info.amount | currency: 'EUR' }}
                                </td>
                                <td *matFooterCellDef mat-footer-cell>
                                    <b>{{ calculateTotal(purchaseInformation) | currency: 'EUR' }}</b>
                                </td>
                            </ng-container>

                            <tr
                                *matHeaderRowDef="purchaseDisplayedColumns"
                                mat-header-row></tr>
                            <tr
                                *matRowDef="let row; columns: purchaseDisplayedColumns"
                                mat-row></tr>
                            <tr
                                *matFooterRowDef="purchaseDisplayedColumns"
                                mat-footer-row></tr>
                        </table>
                        <i class="las la-angle-down summary__down-arrow"></i>
                    </div>
                </details>
            </ng-container>
        </ng-template>
    </div>
</div>

<ng-template #loading>
    <app-loading-message></app-loading-message>
</ng-template>
