<form class="modal-wrapper">
    <div class="modal-header">
        <h2 class="modal-title">Manage two-factor authentication</h2>
        <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="activeModal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <div class="row m-b-2">
            <div class="col d-flex space-between">
                <p class="sectiontitle sectiontitle--no-margin">Two-factor authentication</p>

                <div
                    class="form-check form-switch"
                    fxLayout="row">
                    <label class="switch">
                        <input
                            type="checkbox"
                            [checked]="isActivated"
                            [disabled]="inputIsVerified"
                            (change)="toggleAuth()" />
                        <span class="slider round"></span>
                    </label>
                </div>
            </div>
        </div>

        <div *ngIf="!isAlreadyActivated && isActivated && qrCode">
            <div class="row">
                <div class="col">
                    <p>Scan the QR code with your authenticator app and verify the code</p>
                </div>
            </div>

            <div class="flex-center">
                <img
                    *ngIf="qrCode"
                    [src]="format()" />
            </div>

            <div class="row">
                <form
                    class="col"
                    [formGroup]="mfaFormGroup"
                    (ngSubmit)="verifyCode()">
                    <div class="form-group m-b-2">
                        <label>Code from your authenticator app</label>
                        <input
                            type="text"
                            pattern="[0-9]*"
                            formControlName="verificationCode"
                            trim="blur" />

                        <span
                            class="form-error"
                            *ngIf="mfaFormGroup.controls.verificationCode.errors && mfaFormGroup.touched">
                            The authentication code should be 6 figures
                        </span>
                        <span
                            class="form-error"
                            *ngIf="inputIsWrong"
                            >The code is invalid, try again</span
                        >
                    </div>

                    <input
                        type="submit"
                        [ngClass]="{ 'button--loading': loading }"
                        class="button button--primary w-100"
                        [disabled]="!mfaFormGroup.valid"
                        value="Verify code" />
                </form>
            </div>
        </div>
    </div>
</form>
