<div
    *ngIf="!isLoading"
    class="d-flex">
    <div class="filter-wrap w-100">
        <span>
            <div class="d-flex">
                <tag-input
                    (onAdd)="addToSelected($event)"
                    (onRemove)="deleteFromSelected($event)"
                    [(ngModel)]="searchText"
                    [onlyFromAutocomplete]="true"
                    class="w-100"
                    placeholder="Add more tags..."
                    secondaryPlaceholder="Filter by tags...">
                    <tag-input-dropdown [autocompleteItems]="allTagsString"> </tag-input-dropdown>
                </tag-input>
            </div>
        </span>
    </div>
</div>

<app-loading-message *ngIf="isLoading"></app-loading-message>

