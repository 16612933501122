import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { VeldaLanguagesCountriesOverviewComponent } from './velda-languages-countries-overview/velda-languages-countries-overview.component';
import { VeldaLanguagesLanguagesOverviewComponent } from './velda-languages-languages-overview/velda-languages-languages-overview.component';

export const routes: Routes = [
    { path: '', redirectTo: 'countries', pathMatch: 'full' },
    { path: 'countries', component: VeldaLanguagesCountriesOverviewComponent },
    { path: 'languages', component: VeldaLanguagesLanguagesOverviewComponent },
];

export const routing: ModuleWithProviders<any> = RouterModule.forChild(routes);
