import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';

import { Partner } from '../../models/Partner';
import { VAT } from '../../models/VAT';
import YukiDocument from '../../models/yuki/YukiDocument';
import { PartnerAddModalComponent } from '../../partners/partner-add-modal/partner-add-modal.component';
import { FetchPartners } from '../../partners/partners.actions';
import { PartnerState } from '../../partners/partners.state';
import { roundNumber } from '../../shared/utils/number-utils';
import { YukiDocumentDTO } from '../yuki-detail-modal/yuki-detail-modal.component';
import { UpdateVATOfYukiDocument } from '../yuki.actions';
import { YukiState } from '../yuki.state';

@Component({
    selector: 'app-yuki-update-information',
    templateUrl: './yuki-update-information.component.html',
    styleUrls: ['./yuki-update-information.component.scss'],
})
export class YukiUpdateInformationComponent implements OnInit, OnChanges {
    VATForm: FormGroup;
    VATs: VAT[] = [];
    @Input() yukiDocument: YukiDocument;
    @Output() emitDocumentVerified = new EventEmitter<void>();

    @Select(PartnerState.getPartners) partners$: Observable<Partner[]>;
    partners: Partner[];

    private subscriptions = new Subscription();

    constructor(private fb: FormBuilder, private store: Store, private logger: NGXLogger, private toastr: ToastrService, private modalService: NgbModal) {}

    ngOnInit(): void {
        this.createVATForm();
        this.seedVATs();
        this.fetchData();
    }

    ngOnChanges() {
        this.createVATForm();
    }

    updateVATAmount() {
        if (this.VATForm.value.vat === VAT.OTHER) return;

        const amount: number = this.VATForm.value.amount;
        const percent: number = this.VATForm.value.vat.percent;
        const vat: number = amount - (amount * 100) / (percent + 100);
        const amountExcl: number = amount - vat;
        this.VATForm.get('vatAmount').setValue(`${vat.toFixed(2)}`);
        this.VATForm.get('amountExcl').setValue(`${amountExcl.toFixed(2)}`);
    }

    checkAmountError(): boolean {
        const amount: number = parseFloat(this.VATForm.value.amount);
        const amountExcl: number = parseFloat(this.VATForm.value.amountExcl);
        const vatAmount: number = parseFloat(this.VATForm.value.vatAmount);
        return (vatAmount + amountExcl).toFixed(2) !== amount.toFixed(2);
    }

    updateDocument() {
        this.subscriptions.add(
            this.store.dispatch(new UpdateVATOfYukiDocument(this.yukiDocument.uuid, this.createBodyToVerifyDocument())).subscribe({
                next: () => {
                    this.toastr.success('Yuki document successfully updated.');
                    this.emitDocumentVerified.emit();
                },
                error: () => {
                    this.logger.error('Failed to update Yuki document.');
                    this.toastr.error('Oops, something went wrong, Please try again later...');
                },
            }),
        );
    }

    calculateVatAmount(): void {
        const vatAmount: number = this.VATForm.value.amount - this.VATForm.value.amountExcl;
        this.VATForm.get('vatAmount').setValue(`${vatAmount.toFixed(2)}`);
        this.calculateVat();
    }

    calculateAmountExcl() {
        const amountExcl: number = this.VATForm.value.amount - this.VATForm.value.vatAmount;
        this.VATForm.get('amountExcl').setValue(`${amountExcl.toFixed(2)}`);
        this.calculateVat();
    }

    openCreateContactModal() {
        this.modalService.open(PartnerAddModalComponent, { windowClass: 'modal-pane', animation: false });
    }

    private createVATForm() {
        this.VATForm = this.fb.group({
            vat: [this.yukiDocument.vat, Validators.required],
            vatAmount: [`${this.yukiDocument.vatamount.toFixed(2)}`, Validators.required],
            amount: [`${this.yukiDocument.amount.toFixed(2)}`, Validators.required],
            amountExcl: [`${this.yukiDocument.amountExcl.toFixed(2)}`, Validators.required],
            description: this.yukiDocument.description,
            subject: this.yukiDocument.subject,
            contactName: this.yukiDocument.contactName,
            invoiceNumber: [this.yukiDocument.invoiceNumber, invoiceNumberValidator(this.yukiDocument.folderId)],
            linkedContact: [this.yukiDocument.linkedContact ?? '', Validators.required],
        });
    }

    private seedVATs() {
        this.VATs = Object.values(VAT);
    }

    private createBodyToVerifyDocument(): YukiDocumentDTO {
        return {
            vatPercent: this.VATForm.value.vat.value,
            vatAmount: this.VATForm.value.vatAmount,
            amount: this.VATForm.value.amount,
            amountExcl: this.VATForm.value.amountExcl,
            description: this.VATForm.value.description,
            subject: this.VATForm.value.subject,
            contactName: this.VATForm.value.contactName,
            invoiceNumber: this.VATForm.value.invoiceNumber,
            linkedContactId: this.VATForm.value.linkedContact?.uuid,
        };
    }

    private calculateVat() {
        const percentage = roundNumber(this.VATForm.value.vatAmount / this.VATForm.value.amountExcl);

        if (VAT.ZERO.percent - 0.2 < percentage * 100 && VAT.ZERO.percent + 0.2 > percentage * 100) this.VATForm.get('vat').setValue(VAT.ZERO);
        else if (VAT.SIX.percent - 0.2 < percentage * 100 && VAT.SIX.percent + 0.2 > percentage * 100) this.VATForm.get('vat').setValue(VAT.SIX);
        else if (VAT.TWELVE.percent - 0.2 < percentage * 100 && VAT.TWELVE.percent + 0.2 > percentage * 100) this.VATForm.get('vat').setValue(VAT.TWELVE);
        else if (VAT.TWENTY_ONE.percent - 0.2 < percentage * 100 && VAT.TWENTY_ONE.percent + 0.2 > percentage * 100)
            this.VATForm.get('vat').setValue(VAT.TWENTY_ONE);
        else this.VATForm.get('vat').setValue(VAT.OTHER);
    }

    private fetchData() {
        this.subscriptions.add(
            this.partners$.subscribe((partners) => {
                if (partners === null) {
                    this.logger.debug('Fetching partners');
                    this.store.dispatch(new FetchPartners());
                    return;
                }
                this.partners = partners;
            }),
        );
        this.subscriptions.add(
            this.store.select(YukiState.documentById(this.yukiDocument.uuid)).subscribe((document) => {
                this.yukiDocument = document;
                this.createVATForm();
            }),
        );
    }
}

export function invoiceNumberValidator(folderId: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => (!control.value && folderId === 2 ? { invoiceNumber: { value: control.value } } : null);
}
