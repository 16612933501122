import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { NgbDropdownModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MomentModule } from 'ngx-moment';
import { OrderModule } from 'ngx-order-pipe';
import { NgPipesModule } from 'ngx-pipes';
import { SortablejsModule } from 'ngx-sortablejs';
import { NgxTrimDirectiveModule } from 'ngx-trim-directive';

import { SharedModule } from './../shared/shared.module';
import { AddMemberModalComponent } from './add-member-modal/add-member-modal.component';
import { routing } from './companies.routing';
import { CompanyDeleteModalComponent } from './company-delete-modal/company-delete-modal.component';
import { CompanyDetailModalComponent } from './company-detail-modal/company-detail-modal.component';
import { CompanyInvitedMemberDeleteModalComponent } from './company-invited-member-delete-modal/company-invited-member-delete-modal.component';
import { CompanyInvitedMembersListComponent } from './company-invited-members-list/company-invited-members-list.component';
import { CompanyMemberListComponent } from './company-member-list/company-member-list.component';
import { CompanyTagListComponent } from './company-tag-list/company-tag-list.component';
import { CompanyProjectStatusListComponent } from './company-work-status-list/company-project-status-list.component';

@NgModule({
    declarations: [
        CompanyMemberListComponent,
        CompanyDeleteModalComponent,
        AddMemberModalComponent,
        CompanyInvitedMembersListComponent,
        CompanyInvitedMemberDeleteModalComponent,
        CompanyDetailModalComponent,
        CompanyTagListComponent,
        CompanyProjectStatusListComponent,
    ],
    imports: [
        CommonModule,
        SharedModule,
        routing,
        NgbModule,
        MomentModule,
        NgbDropdownModule,
        NgxTrimDirectiveModule,
        NgPipesModule,
        OrderModule,
        SortablejsModule,
        MatTableModule,
        MatSortModule,
        MatPaginatorModule,
    ],
    exports: [CompanyMemberListComponent, CompanyTagListComponent, CompanyProjectStatusListComponent],
})
export class CompaniesModule {}
