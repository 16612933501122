<div class="modal-header">
    <h2
        class="modal-title"
        id="exampleModalLabel">
        Delete unit
    </h2>
    <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">Are you sure you want to delete {{ unit.type }}?</div>

<div class="modal-footer">
    <button
        class="button button--danger"
        (click)="deleteUnit()">
        Delete unit
    </button>
    <button
        type="button"
        class="button button--outline"
        (click)="activeModal.close()">
        Cancel
    </button>
</div>
