import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { Product } from 'src/app/models/Product';
import { Unit } from 'src/app/models/Unit';
import { UnitState } from 'src/app/units/unit.state';

import { ProductAddModalComponent } from '../product-add-modal/product-add-modal.component';
import { FetchProducts, LinkProducts, UpdateLinkedAmount } from '../product.actions';
import { ProductState } from '../product.state';

@Component({
    selector: 'app-link-products',
    templateUrl: './link-products.component.html',
    styleUrls: ['./link-products.component.scss'],
})
export class LinkProductsComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Select(UnitState.Units) units$: Observable<Unit>;
    @Select(ProductState.Products) allProducts$: Observable<Product[]>;
    @Input() parent: Product;
    @Input() child: Product;
    @Input() amount: number;
    @Input() isUpdate: number;

    @Input() isDetailModal = false;
    @Input() isChild = false;
    @Input() embeded = false;
    @Input() deletedProduct: Product;
    @Output() addEvent = new EventEmitter();
    @Output() addedProduct = new EventEmitter();

    selectedProductName: string;
    selectedProduct: Product;
    allProducts: Product[] = [];
    actualProducts: Product[] = [];
    amountAssigned: number;
    combinedProducts: Product[] = [];
    unit: Unit;
    unitType: string;
    keyword = 'title';

    productUuidList = '';
    productList: Product[] = [];
    childrenToBeAdded: Product[] = [];
    amountArray: number[] = [];

    constructor(
        private toastr: ToastrService,
        private store: Store,
        private modalService: NgbModal,
        public activeModal: NgbActiveModal,
        private logger: NGXLogger,
    ) {}

    ngOnInit(): void {
        this.logger.debug('Fetching products');
        this.store.dispatch(new FetchProducts());

        if (this.isUpdate) {
            this.subscriptions.add(
                this.allProducts$.subscribe((products) => {
                    this.unit = products.filter((m) => m.uuid == this.child.uuid)[0].unit;
                }),
            );
            this.selectedProductName = this.child.title;
            this.amountAssigned = this.amount;
            this.unitType = this.unit?.type;
        }

        this.subscriptions.add(
            this.allProducts$.subscribe((products) => {
                this.allProducts = products;

                this.actualProducts = [...this.allProducts];

                if (this.allProducts && this.parent?.linkedProducts && !this.isChild) {
                    this.parent.linkedProducts.forEach((element) => {
                        this.actualProducts.filter((el) => {
                            if (el?.uuid == element?.productUuid || el?.uuid == this.parent.uuid) {
                                if (this.actualProducts.indexOf(el) != -1) this.actualProducts.splice(this.actualProducts.indexOf(el), 1);
                            }
                        });
                    });
                } else if (this.child?.linkedProducts && this.isChild) {
                    this.child.linkedProducts.forEach((element) => {
                        this.actualProducts.filter((el) => {
                            if (el?.uuid == element?.productUuid || el?.uuid == this.child.uuid) {
                                if (this.actualProducts.indexOf(el) != -1) this.actualProducts.splice(this.actualProducts.indexOf(el), 1);
                            }
                        });
                    });
                }

                this.actualProducts.filter((el) => {
                    if (el?.uuid == this.parent?.uuid) {
                        if (this.actualProducts.indexOf(el) != -1) this.actualProducts.splice(this.actualProducts.indexOf(el), 1);
                    }
                });

                this.combinedProducts?.forEach((element) => {
                    this.actualProducts.filter((el) => {
                        if (el?.uuid == element?.uuid) {
                            if (this.actualProducts.indexOf(el) != -1) this.actualProducts.splice(this.actualProducts.indexOf(el), 1);
                        }
                    });
                });
            }),
        );
    }

    selectEvent(item) {
        this.selectedProduct = JSON.parse(JSON.stringify(item));
        this.addToProductList(item.uuid);
        this.unitType = this.selectedProduct.unit?.type;
    }

    add() {
        if (this.isDetailModal) {
            if (this.amount == null) {
                this.amount = 0;
            }
            if (this.isChild) {
                this.logger.debug('Attempt to add parent product');
                this.subscriptions.add(
                    this.store.dispatch(new LinkProducts(this.child.uuid, this.selectedProduct.uuid, this.child.uuid, [this.amount])).subscribe(
                        () => {
                            this.logger.debug('Succesfully added parent Product');
                            this.toastr.success('Parent product added successfully');
                            this.amount = null;
                            this.selectedProduct = null;
                            this.selectedProductName = '';
                        },
                        (error) => {
                            this.logger.error('Failed to add parent product');
                            this.toastr.error('Parent product already added');
                        },
                    ),
                );

                this.amountArray.length = 0;
            } else {
                this.logger.debug('Attempt to add child product');
                this.subscriptions.add(
                    this.store.dispatch(new LinkProducts(this.parent.uuid, this.parent.uuid, this.selectedProduct.uuid, [this.amount])).subscribe(
                        () => {
                            this.logger.debug('Succesfully added child Product');
                            this.toastr.success('Child product added successfully');
                            this.amount = null;
                            this.selectedProduct = null;
                            this.selectedProductName = '';
                        },
                        (error) => {
                            this.logger.error('Failed to add child product');
                            this.toastr.error('Child product already added');
                        },
                    ),
                );

                this.amountArray.length = 0;
            }
        } else if (this.parent == null && this.child == null) {
            this.addToNewProduct();
        } else {
            this.addNewProduct();
        }
        this.embeded ? () => {} : this.activeModal.close();
    }

    addToProductList(uuid: string) {
        this.productUuidList = '';
        this.productList.length = 0;

        this.productUuidList = this.productUuidList.concat(uuid);
        this.productList.push(this.allProducts.filter((item: Product) => item.uuid.toLowerCase().includes(uuid))[0]);
    }

    addToNewProduct() {
        if (this.selectedProduct != null) {
            if (!this.selectedProduct.amountAssigned) {
                this.selectedProduct = {
                    amountAssigned: 0,
                    ...this.selectedProduct,
                };
            }

            this.selectedProduct.amountAssigned = this.amount != null ? this.amount : 0;
            this.childrenToBeAdded.push(this.selectedProduct);
            this.actualProducts.filter((el) => {
                if (el?.uuid == this.selectedProduct?.uuid) {
                    if (this.actualProducts.indexOf(el) != -1) this.actualProducts.splice(this.actualProducts.indexOf(el), 1);
                }
            });

            this.addedProduct.emit(this.childrenToBeAdded);
        }

        this.selectedProduct = null;
        this.selectedProductName = '';
        this.amount = null;
    }

    openCreateProductModal() {
        this.logger.debug('Opening productAddModal');
        const modalRef = this.modalService.open(ProductAddModalComponent, {
            windowClass: 'modal-huge',
            animation: false,
        });
        modalRef.componentInstance.linkedProduct = this.selectedProductName;

        modalRef.result.then(
            () => {
                this.logger.debug('Fetching products');
                this.store.dispatch(new FetchProducts());
            },
            () => {},
        );
    }

    addNewProduct() {
        if (this.productUuidList == '' && !this.isUpdate) {
            alert('Please select a product');
        }

        this.amountArray.push(this.amount ? this.amount : 0);

        if (!this.isUpdate) {
            this.logger.debug('Attempt to add child product');
            this.subscriptions.add(
                this.store.dispatch(new LinkProducts(this.parent.uuid, this.parent.uuid, this.productUuidList, this.amountArray)).subscribe(
                    () => {
                        this.logger.debug('Succesfully added childProduct');
                        this.toastr.success('Child product added successfully');
                        this.amount = null;
                        this.addEvent.emit(this.selectedProduct);
                        this.selectedProductName = '';
                    },
                    (error) => {
                        this.logger.error('Failed to add  child product');
                        this.toastr.error('Child product already added');
                    },
                ),
            );

            this.amountArray.length = 0;
        } else {
            this.logger.debug('Attempt to update  child product');
            this.subscriptions.add(
                this.store.dispatch(new UpdateLinkedAmount(this.parent.uuid, this.child.uuid, this.amountArray[0])).subscribe(
                    () => {
                        this.logger.debug('Succesfully updated child product');
                        this.toastr.success('Child product amount update successfully');
                        this.amount = null;
                        this.selectedProductName = '';
                    },
                    (error) => {
                        this.logger.error('Failed to update child product');
                        this.toastr.error('Something went wrong while processing your request');
                    },
                ),
            );
        }
        this.store.dispatch(new FetchProducts());
    }

    ngOnChanges(): void {
        if (this.deletedProduct != null && this.parent == null) {
            this.actualProducts.push(this.deletedProduct);
            this.childrenToBeAdded = this.childrenToBeAdded.filter((m) => m.uuid != this.deletedProduct.uuid);
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
