import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { EmployeesDetailComponent } from './employees-detail/employees-detail.component';
import { EmployeesOverviewComponent } from './employees-overview/employees-overview.component';

export const routes: Routes = [
    { path: '', redirectTo: 'overview', pathMatch: 'full' },
    { path: 'overview', component: EmployeesOverviewComponent },
    { path: ':id/detail', component: EmployeesDetailComponent },
];

export const routing: ModuleWithProviders<any> = RouterModule.forChild(routes);
