import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subscription } from 'rxjs';
import { Role } from 'src/app/models/Role';
import { FetchModules } from 'src/app/modules/module.actions';

import { FetchRoles } from '../custom.roles.actions';
import { CustomRolesState } from '../custom.roles.state';
import { RoleAddModalComponent } from '../role-add-modal/role-add-modal.component';
import { RoleDeleteModalComponent } from '../role-delete-modal/role-delete-modal.component';

@Component({
    selector: 'app-roles',
    templateUrl: './roles.component.html',
    styleUrls: ['./roles.component.scss'],
})
export class RolesComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();
    @Select(CustomRolesState.CustomRoles) allCustomRoles$: Observable<Role[]>;

    noRolesSet = false;

    displayedColumns: string[] = ['role name', 'edit'];
    @ViewChild(MatSort) sort: MatSort;

    constructor(private store: Store, private modalService: NgbModal, private logger: NGXLogger) {}

    ngOnInit(): void {
        this.logger.debug('Fetching roles');
        this.store.dispatch(new FetchRoles());
        this.store.dispatch(new FetchModules());

        this.subscriptions.add(
            this.allCustomRoles$.subscribe((roles) => {
                if (roles != undefined && roles.length > 0) {
                    this.noRolesSet = false;
                } else {
                    this.noRolesSet = true;
                }
            }),
        );
    }

    openAddCustomroleModal(role?: Role) {
        if (role != null) {
            this.logger.debug('Opening roleAddModal - update');
            const modalRef = this.modalService.open(RoleAddModalComponent, {
                windowClass: 'modal-pane',
                animation: false,
            });
            modalRef.componentInstance.update = true;
            modalRef.componentInstance.existingRole = role;
        } else {
            this.logger.debug('Opening roleAddModal');
            this.modalService.open(RoleAddModalComponent, {
                windowClass: 'modal-pane',
                animation: false,
            });
        }
    }

    openRoleDeleteModal(role: Role) {
        this.logger.debug('Opening roleDeleteModal');
        const modalRef = this.modalService.open(RoleDeleteModalComponent, {
            windowClass: 'modal-prompt',
        });
        modalRef.componentInstance.role = role;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
