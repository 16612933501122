<div class="modal-header">
    <app-loading-message *ngIf="isLoading"></app-loading-message>
    <h2
        class="modal-title"
        id="exampleModalLabel"
        *ngIf="!isLoading">
        {{ warehouseLocation.title }}
    </h2>
    <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<nav
    ngbNav
    #nav="ngbNav"
    class="tabs__modal">
    <ng-container ngbNavItem>
        <a ngbNavLink>Information</a>
        <ng-template ngbNavContent>
            <ul class="list__specs">
                <li *ngIf="warehouseLocation.description"><label>Description</label>{{ warehouseLocation.description }}</li>
                <li *ngIf="warehouse">
                    <label>Related warehouse</label>
                    <app-pill-warehouse [warehouse]="warehouse"></app-pill-warehouse>
                </li>
                <li *ngIf="warehouseLocation.products?.length > 0">
                    <label>Linked products</label>
                    <app-pill-product
                        *ngFor="let product of warehouseLocation.products | filterBy: ['archived']:false"
                        [product]="product"></app-pill-product>
                </li>
                <li>
                    <label>Tags</label
                    ><app-global-tags
                        [objectUuid]="warehouseLocation.uuid"
                        [type]="'warehouselocation'"></app-global-tags>
                </li>
            </ul>
        </ng-template>
    </ng-container>

    <ng-container ngbNavItem>
        <a ngbNavLink>Employees</a>
        <ng-template ngbNavContent>
            <div>
                <app-related-employees-list
                    [objectId]="warehouseLocation.uuid"
                    [objectType]="'warehouselocation'"></app-related-employees-list>
            </div>
        </ng-template>
    </ng-container>
</nav>

<div
    class="modal-body"
    *ngIf="isLoading">
    <app-loading-message></app-loading-message>
</div>

<div
    class="modal-body"
    *ngIf="!isLoading">
    <div [ngbNavOutlet]="nav"></div>
</div>

<div class="modal-footer">
    <button
        type="button"
        class="button button--outline"
        (click)="activeModal.dismiss()">
        Close
    </button>
</div>
