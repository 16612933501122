import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';

import { MailchimpService } from '../mailchimp.service';

@Component({
    selector: 'app-mailchimp-overview',
    templateUrl: './mailchimp-overview.component.html',
    styleUrls: ['./mailchimp-overview.component.scss'],
})
export class MailchimpOverviewComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    keyIsset: boolean;

    constructor(private mailchimpService: MailchimpService, private titleService: Title) {
        this.titleService.setTitle('Mailchimp overview');
    }

    ngOnInit(): void {
        this.subscriptions.add(
            this.mailchimpService.checkIfApiKeyExists().subscribe((e) => {
                if (e.key) {
                    this.keyIsset = true;
                }
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
