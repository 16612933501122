<div class="d-flex w-100">

    <app-date-navigation
        (selectedDateUpdated)="updateSelectedDate()"
        [dateNavigationOptions]="dateNavigationOptions"
        class="w-100 m-r-4"></app-date-navigation>
    <label class="d-flex align-items-center nowrap" for="archived">
        <p class="m-r-2">Show archived</p>
        <input (change)="filterData()" [(ngModel)]="showArchived" id="archived" type="checkbox">
    </label>
</div>

<table #sort="matSort"
       [dataSource]="datasource"
       class="m-t-4"
       mat-table
       matSort>

    <ng-container matColumnDef="employee">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>
            Employee
        </th>
        <td *matCellDef="let financialData" mat-cell>
            <app-pill-employee [employeeId]="financialData.objectId" [onFinancialPage]="true"></app-pill-employee>
        </td>
    </ng-container>

    <ng-container matColumnDef="margin">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>
            Margin
        </th>
        <td *matCellDef="let financialData" mat-cell>
            {{financialData.margin / 100 | percent}}
        </td>
    </ng-container>

    <ng-container matColumnDef="totalCosts">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>
            Total costs
        </th>
        <td *matCellDef="let financialData" mat-cell>
            {{financialData.totalCosts | currency: 'EUR'}}
        </td>
    </ng-container>

    <ng-container matColumnDef="totalIncome">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>
            Total income
        </th>
        <td *matCellDef="let financialData" mat-cell>
            {{financialData.totalIncome | currency: 'EUR'}}
        </td>
    </ng-container>

    <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
    <tr (click)="openEmployeeDetailModal(row)" *matRowDef="let row; columns: displayedColumns" class="pointer"
        mat-row></tr>

    <tr *matNoDataRow>
        <td
            [attr.colspan]="displayedColumns.length"
            class="mat-cell">
            <ng-container *ngIf="loadingData; else loading">
                There are no employees with financial data found for this year...
            </ng-container>
        </td>
    </tr>
</table>

<ng-template #loading>
    <app-loading-message></app-loading-message>
</ng-template>
