import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import MailchimpTemplate from 'src/app/models/MailchimpTemplate';

@Component({
    selector: 'app-mailchimp-template-thumbnail',
    templateUrl: './mailchimp-template-thumbnail.component.html',
    styleUrls: ['./mailchimp-template-thumbnail.component.scss'],
})
export class MailchimpTemplateThumbnailComponent {
    @Input() template: MailchimpTemplate;

    constructor(public activeModal: NgbActiveModal) {}
}
