import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ActivationComponent } from './activation/activation.component';
import { AlreadyLoggedInGuard } from './alreadyLoggedIn.guard';
import { AuthGuard } from './auth.guard';
import { ForgotpasswordComponent } from './forgotpassword/forgotpassword.component';
import { LoggedinComponent } from './loggedin/loggedin.component';
import { LoginAttemptsComponent } from './login-attempts/login-attempts.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';

export const routes: Routes = [
    // { path: '', redirectTo: 'login', pathMatch: 'full'},
    {
        path: 'login/:redirectURL',
        component: LoginComponent,
        canActivate: [AlreadyLoggedInGuard],
    },
    {
        path: 'login',
        component: LoginComponent,
        canActivate: [AlreadyLoggedInGuard],
    },
    {
        path: 'loggedin',
        component: LoggedinComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'loginattemptfailed',
        component: LoginAttemptsComponent,
        canActivate: [AlreadyLoggedInGuard],
    },
    {
        path: 'register',
        component: RegisterComponent,
        canActivate: [AlreadyLoggedInGuard],
    },
    {
        path: 'forgotpassword',
        component: ForgotpasswordComponent,
        canActivate: [AlreadyLoggedInGuard],
    },
    { path: 'resetpassword', component: ResetPasswordComponent },
    { path: 'activation', component: ActivationComponent },
];

export const routing: ModuleWithProviders<any> = RouterModule.forChild(routes);
