import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { QueryDTO } from 'src/app/models/QueryDTO';
import { AddQuery } from 'src/app/velda-articles/velda-articles.actions';

@Component({
    selector: 'app-add-query-modal',
    templateUrl: './add-query-modal.component.html',
    styleUrls: ['./add-query-modal.component.scss'],
})
export class AddQueryModalComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Input() type: string;
    queryFormGroup: FormGroup;

    constructor(
        public activeModal: NgbActiveModal,
        private formBuilder: FormBuilder,
        private logger: NGXLogger,
        private store: Store,
        private toastrService: ToastrService,
    ) {}

    ngOnInit(): void {
        this.queryFormGroup = this.formBuilder.group({
            query: ['', [Validators.required]],
        });
    }

    addQuery() {
        const query: QueryDTO = {
            type: this.type,
            payload: this.queryFormGroup.value.query,
        };

        this.logger.debug('Attempt to add query');
        this.subscriptions.add(
            this.store.dispatch(new AddQuery(query)).subscribe(
                () => {
                    this.logger.debug('Query added successfully');
                    this.toastrService.success('Query added successfully');
                    this.activeModal.close();
                },
                (error) => {
                    this.logger.error('Failed to add query');
                    this.toastrService.error('Failed to add query');
                },
            ),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
