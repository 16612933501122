export class CheckLoggedIn {
    static readonly type = '[Boolean] Fetch exactLogin';
}

export class FetchRelations {
    static readonly type = '[any] Fetch relations';
}

export class FetchItems {
    static readonly type = '[any] Fetch items';
}

export class FetchInvoices {
    static readonly type = '[any] Fetch Exact invoices';
}

export class FetchPayments {
    static readonly type = '[any] Fetch payments';
}

export class FetchOrders {
    static readonly type = '[any] Fetch orders';
}

export class AddRelation {
    static readonly type = '[any] Add relation';
    constructor(public relation: any) {}
}

export class UpdateRelation {
    static readonly type = '[any] Update relation';
    constructor(public relation: any) {}
}

export class DeleteRelation {
    static readonly type = '[any] Delete relation';
    constructor(public id: string) {}
}
