import { Component, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';

import { OverheadCategory } from '../../models/OverheadCategory';
import { AddOverheadCategories } from '../yuki.actions';

@Component({
    selector: 'app-add-overhead-categories-modal',
    templateUrl: './add-overhead-categories-modal.component.html',
    styleUrls: ['./add-overhead-categories-modal.component.scss'],
})
export class AddOverheadCategoriesModalComponent implements OnDestroy {
    private subscriptions = new Subscription();

    newOverheadCategories: OverheadCategory[] = [];
    newCategoryName: string;

    constructor(public activeModal: NgbActiveModal, private store: Store, private logger: NGXLogger, private toastr: ToastrService) {}

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    saveOverheadCategories() {
        if (this.newCategoryName) this.newOverheadCategories.push(new OverheadCategory(this.newCategoryName));
        this.subscriptions.add(
            this.store.dispatch(new AddOverheadCategories(this.newOverheadCategories)).subscribe(
                () => {
                    this.toastr.success('Successfully added overhead categories');
                    this.activeModal.close();
                },
                () => {
                    this.logger.error('Failed to add overhead categories');
                    this.toastr.error('Oops, something went wrong, Please try again later...');
                },
            ),
        );
    }

    addNewOverheadCategory() {
        if (!this.newCategoryName) return;

        this.newOverheadCategories.push(new OverheadCategory(this.newCategoryName));
        this.newCategoryName = '';
    }

    deleteNewOverheadCategory(overheadCategoryToRemove: OverheadCategory) {
        const index: number = this.newOverheadCategories.findIndex(
            (overheadCategory) => overheadCategory.categoryName === overheadCategoryToRemove.categoryName,
        );
        this.newOverheadCategories.splice(index, 1);
    }
}
