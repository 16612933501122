import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-velda-languages-subnav',
    templateUrl: './velda-languages-subnav.component.html',
    styleUrls: ['./velda-languages-subnav.component.scss'],
})
export class VeldaLanguagesSubnavComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
