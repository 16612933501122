import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { AuthenticationService } from '../authentication/authentication.service';
import { Unit } from '../models/Unit';

@Injectable({
    providedIn: 'root',
})
export class UnitService {
    httpOptions = {};

    constructor(private httpClient: HttpClient, private authenticationService: AuthenticationService) {
        this.httpOptions = authenticationService.getHeaders();
    }

    addUnit(unit: Unit): Observable<Unit> {
        return this.httpClient.post<Unit>(environment.baseUrl + '/unit/', unit, this.httpOptions);
    }

    getAllUnits() {
        return this.httpClient.get<Unit[]>(environment.baseUrl + '/unit/', this.httpOptions);
    }

    getUnitById(uuid: string): Observable<Unit> {
        return this.httpClient.get<Unit>(environment.baseUrl + '/unit/' + uuid, this.httpOptions);
    }

    updateUnit(unit: Unit): Observable<Unit> {
        return this.httpClient.put<Unit>(environment.baseUrl + '/unit/', unit, this.httpOptions);
    }

    deleteUnit(uuid: string) {
        return this.httpClient.delete(environment.baseUrl + '/unit/' + uuid, this.httpOptions);
    }
}
