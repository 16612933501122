<form (ngSubmit)="updateDescription()">
    <div class="modal-header">
        <h2 class="modal-title">Update description</h2>

        <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="activeModal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <description-input
            [(ngModel)]="description"
            name="description"></description-input>
    </div>

    <div class="modal-footer">
        <input
            class="button button--primary"
            type="submit"
            value="Save" />
        <button
            class="button button--outline"
            (click)="activeModal.dismiss()">
            Cancel
        </button>
    </div>
</form>
