<div *ngIf="!redirecting">
    <ng-container>
        <router-outlet></router-outlet>
    </ng-container>

    <ng-template #loading>
        <div class="loading__page">
            <div class="loading__page__content">
                <img
                    src="../../../assets/img/loading-theme.svg"
                    alt="Loading"
                    title="Loading..." />
                <strong>Loading Resumo...</strong>
            </div>
        </div>
    </ng-template>
</div>

<div *ngIf="redirecting">redirecting to secure environment...</div>
