<div class="modal-header">
    <h2
        class="modal-title"
        id="exampleModalLabel">
        Archive resume
    </h2>
    <button
        (click)="activeModal.dismiss()"
        aria-label="Close"
        class="close"
        type="button">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <p>
        Are you sure you want to archive the resume of <strong>{{ resume.firstName }} {{ resume.lastName }}</strong>?
    </p>
    <p>All projects, experiences, educations,... will be archived as well. It is possible to unarchive this resume at a later date.</p>
</div>

<div class="modal-footer">
    <button
        (click)="archiveResume()"
        class="button button--danger">
        Archive
    </button>
    <button
        (click)="activeModal.close()"
        class="button button--outline"
        type="button">
        Cancel
    </button>
</div>
