<div class="modal-header">
    <h2
        class="modal-title"
        id="exampleModalLabel">
        {{ title }}
    </h2>
    <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="decline()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div
    class="modal-body"
    *ngIf="this.message"
    style="white-space: pre-wrap">
    <p>{{ message }}</p>
    <strong *ngIf="messageObject">{{ messageObject }}</strong>
</div>

<div
    class="modal-body"
    *ngIf="this.htmlMessage"
    [innerHTML]="htmlMessage"></div>

<div class="modal-footer">
    <button
        *ngIf="type === 'Warning'"
        class="button button--danger"
        (click)="confirm()">
        Confirm
    </button>
    <button
        *ngIf="type === 'Delete'"
        class="button button--danger"
        (click)="confirm()">
        Delete
    </button>
    <button
        *ngIf="type === 'Archive'"
        class="button button--danger"
        (click)="confirm()">
        Archive
    </button>
    <button
        *ngIf="type === 'Add'"
        class="button button--primary"
        (click)="confirm()">
        Add
    </button>
    <button
        *ngIf="type === 'YesOrNo'"
        type="button"
        class="button button--outline"
        (click)="decline()">
        No
    </button>
    <button
        *ngIf="type === 'YesOrNo'"
        class="button button--success"
        (click)="confirm()">
        Yes
    </button>
    <button
        *ngIf="type !== 'YesOrNo'"
        type="button"
        class="button button--outline"
        (click)="decline()">
        Cancel
    </button>
</div>
