import { moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input, OnDestroy, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import CrmStage from 'src/app/models/CrmStage';
import { ConfirmationModalComponent } from 'src/app/shared/confirmation-modal/confirmation-modal.component';

import { AddCrmstageModalComponent } from '../add-crmstage-modal/add-crmstage-modal.component';
import { DeleteCrmStage, MoveCrmStage } from '../crm.actions';

@Component({
    selector: 'app-manage-crmstages-modal',
    templateUrl: './manage-crmstages-modal.component.html',
    styleUrls: ['./manage-crmstages-modal.component.scss'],
})
export class ManageCrmstagesModalComponent implements OnDestroy {
    private subscriptions = new Subscription();

    @Input() stages: CrmStage[];

    displayedColumns: string[] = ['stage name', 'probability', 'rotting days', 'stage type', 'edit'];
    dataSource: MatTableDataSource<CrmStage> = new MatTableDataSource<CrmStage>();
    @ViewChild(MatSort) sort: MatSort;

    constructor(
        public activeModal: NgbActiveModal,
        private store: Store,
        private toastr: ToastrService,
        private modalService: NgbModal,
        private logger: NGXLogger,
    ) {}

    drop(event) {
        this.logger.debug('Attempt to move CrmStage');
        this.subscriptions.add(this.store.dispatch(new MoveCrmStage(this.stages[event.previousIndex].uuid, event.currentIndex)).subscribe());
        moveItemInArray(this.stages, event.previousIndex, event.currentIndex);
    }

    delete(uuid: string) {
        this.logger.debug('Opening confirmationModal');
        const modalRef = this.modalService.open(ConfirmationModalComponent, {
            windowClass: 'modal-prompt',
        });
        modalRef.componentInstance.type = 'Delete';
        modalRef.componentInstance.title = 'Delete deal';
        modalRef.componentInstance.message =
            'Are you sure you want to delete ' + this.stages.find((s) => s.uuid === uuid).name + '?\n Also make sure the stage does not contain any deals.';
        this.subscriptions.add(
            modalRef.componentInstance.closeEvent.subscribe((val) => {
                if (val) {
                    this.logger.debug('Attempt to delete crmstage');
                    this.store.dispatch(new DeleteCrmStage(uuid)).subscribe(
                        () => {
                            this.logger.debug('Succesfully removed crmStage');
                            this.toastr.success('Stage successfully removed.');
                        },
                        () => {
                            this.logger.debug('Failed to remove crmDeal');
                            this.toastr.error('Removing stage failed. Please check if the stage still contains deals.');
                        },
                    );
                }
            }),
        );
    }

    openAddStageModal() {
        this.logger.debug('Opening addCrmStageModal');
        this.modalService.open(AddCrmstageModalComponent, {
            windowClass: 'modal-prompt',
        });
    }

    openUpdateStageModal(stage: CrmStage) {
        this.logger.debug('Opening addCrmStageModal - update');
        const modalRef = this.modalService.open(AddCrmstageModalComponent, {
            windowClass: 'modal-prompt',
        });
        modalRef.componentInstance.stage = stage;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
