import { Employee } from './Employee';
import Project from './Project';
import { Timeslot } from './Timeslot';

export class Timesheet {
    uuid?: string;
    createdOn?: string;
    lastModified?: string;
    displayName?: string;
    employee?: Employee;
    timeslots?: Timeslot[];
    expirationDate?: Date;
    status?: TIMESHEET_STATUS;
    totalHours?: string;
    hoursRequired?: number;
    extraInfo?: string;
    happyScore?: number;
    url: string;

    static getTotalMinutes(timesheet: Timesheet): number {
        let totalMinutes = 0;
        timesheet.timeslots.forEach((el) => {
            totalMinutes += el.minutes;
        });
        return totalMinutes;
    }

    static getProjects(timesheet: Timesheet): Project[] {
        const projects: Project[] = [];
        timesheet.timeslots.forEach((el) => {
            projects.push(el.project);
        });
        return projects;
    }

    static getTimeslots(timesheet: Timesheet): Timeslot[] {
        const timeslots: Timeslot[] = [];
        timesheet.timeslots.forEach((el) => {
            timeslots.push(el);
        });
        return timeslots;
    }

    static getTotalDaysFromTimesheet(timesheet: Timesheet): number {
        return Math.round((Timesheet.getTotalMinutes(timesheet) / 60 / 8) * 10) / 10;
    }

    static getTotalDaysFromMinutes(minutes: number): number {
        return Math.round((minutes / 60 / 8) * 10) / 10;
    }

    static getRequiredHoursDiff(timesheet: Timesheet) {
        return Math.round(Math.abs(Timesheet.getTotalMinutes(timesheet) / 60 - timesheet.hoursRequired) * 60);
    }

    static getRequiredHoursDiffPercentage(timesheet: Timesheet) {
        return Math.round((Timesheet.getTotalMinutes(timesheet) / 60 / timesheet.hoursRequired) * 100);
    }

    static getActualIncome(timesheet: Timesheet, projects: Project[]) {
        let total = 0;
        timesheet.timeslots.forEach((slot) => {
            if (slot.project.billable) {
                let project = projects.find((w) => w.uuid == slot.project.uuid);
                if (project && project.linkedEmployees) {
                    let link = project.linkedEmployees.find((link) => link.employee.uuid == timesheet.employee.uuid);
                    if (link) {
                        let price = link.pricePerHour;
                        total += (slot.minutes / 60) * price;
                    }
                }
            }
        });
        return total;
    }
}

export class TIMESHEET_STATUS {
    static readonly EDIT = new TIMESHEET_STATUS('EDIT', 'tag', 'EDIT');
    static readonly REVIEW = new TIMESHEET_STATUS('REVIEW', 'tag tag--danger', 'REVIEW');
    static readonly DONE = new TIMESHEET_STATUS('DONE', 'tag tag--green', 'APPROVED');

    private constructor(public readonly key: string, public readonly cssClass: string, public readonly displayName: string) {}

    static getStatus(value: string): TIMESHEET_STATUS {
        switch (value) {
            case this.EDIT.key:
                return this.EDIT;
            case this.REVIEW.key:
                return this.REVIEW;
            case this.DONE.key:
                return this.DONE;
        }
        return null;
    }
}
