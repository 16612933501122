<form
    (ngSubmit)="saveExperience()"
    [formGroup]="experienceFormGroup"
    class="modal-wrapper">
    <div class="modal-header">
        <h2 class="modal-title">
            <ng-container *ngIf="!isUpdate">New</ng-container>
            <ng-container *ngIf="isUpdate">Edit</ng-container>
            experience
        </h2>
        <button
            (click)="activeModal.dismiss()"
            aria-label="Close"
            class="close"
            type="button">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <section>
            <p class="sectiontitle sectiontitle--first">
                Information
            </p>

            <div class="form-group required m-b-2">
                <label>Jobtitle</label>
                <input
                    (focus)="hideErrorMessage()"
                    formControlName="jobTitle"
                    trim="blur"
                    type="text"/>
                <span
                    *ngIf="experienceFormGroup.controls.jobTitle.errors && inputIsWrong"
                    class="form-error"
                >job title required</span
                >
            </div>
            <div class="form-group required m-b-2">
                <label>Company or organization</label>
                <input
                    (focus)="hideErrorMessage()"
                    formControlName="organization"
                    trim="blur"
                    type="text"/>
                <span
                    *ngIf="experienceFormGroup.controls.organization.errors && inputIsWrong"
                    class="form-error"
                >organization required</span
                >
            </div>
            <div class="form-group m-b-2">
                <label>Website</label>
                <input
                    formControlName="website"
                    trim="blur"
                    type="text"/>
            </div>
        </section>
        <section>
            <p class="sectiontitle sectiontitle--no-bottom-margin">
                Period
            </p>

            <p class="form-helptext">End date is only required when person isn't working there anymore.</p>

            <div class="row">
                <div class="col">
                    <div class="form-group required m-b-2">
                        <label>Start date</label>
                        <input
                            (focus)="hideErrorMessage()"
                            formControlName="startDate"
                            type="date"/>
                        <span *ngIf="!isValidDate && inputIsWrong" class="form-error">
                        Start date must start before the end date
                    </span>
                        <span *ngIf="experienceFormGroup.controls.startDate.errors && inputIsWrong" class="form-error">
                        Start date required
                    </span>
                    </div>
                </div>
                <div class="col">
                    <div
                        [class.required]="!present"
                        class="form-group m-b-2">
                        <label>End date</label>
                        <input
                            (focus)="hideErrorMessage()"
                            formControlName="endDate"
                            type="date"/>
                        <span
                            *ngIf="!isValidDate && inputIsWrong"
                            class="form-error"
                        >Enddate must start after the startdate</span
                        >
                        <span
                            *ngIf="experienceFormGroup.controls.endDate.errors && inputIsWrong"
                            class="form-error"
                        >End date required</span
                        >
                    </div>
                </div>

            </div>
            <div class="row">
                <div class="col">
                    <div class="form-group m-b-2 d-flex">
                        <input
                            (change)="onPresentTicked()"
                            class="m-r-2"
                            formControlName="present"
                            id="present" type="checkbox"/>
                        <label for="present">Still working for this organisation</label>
                    </div>
                </div>
            </div>
        </section>

        <section>
            <p class="sectiontitle">Other</p>

            <div class="form-group m-b-2">
                <label>Description</label>
                <textarea
                    (focus)="hideErrorMessage()"
                    (keyup)="onDescriptionKeyUp()"
                    class="resize-vertical"
                    formControlName="description"
                    trim="blur"></textarea>
                <span
                    *ngIf="
                    experienceFormGroup.controls.description.errors &&
                    inputIsWrong &&
                    (experienceFormGroup.controls.description.dirty || experienceFormGroup.controls.description.touched)
                "
                    class="form-error"
                >description required</span
                >
                <small
                ><span
                    *ngIf="currentAmountOfDescriptionCharacters == null">0</span>{{ currentAmountOfDescriptionCharacters }}
                    /{{
                    DESCRIPTION_MAX_LENGTH
                    }}</small
                >
                <span
                    *ngIf="descriptionIsTooLong"
                    class="form-error"
                >Description cannot contain more than 1000 characters</span
                >
            </div>

            <div class="form-group m-b-2">
                <label>Tags</label>
                <app-global-tags
                    (experienceTagAdd)="addTagToExperience($event)"
                    (experienceTagRemove)="removeExperienceTag($event)"
                    *ngIf="!isUpdate"
                    [experienceTags]="tags"
                    [resumeUuid]="resumeUuid"
                    [type]="'experience'"></app-global-tags>
                <app-global-tags
                    (experienceTagAdd)="addTagToExperience($event)"
                    (experienceTagRemove)="removeExperienceTag($event)"
                    *ngIf="isUpdate"
                    [experienceTags]="tags"
                    [objectUuid]="experience.uuid"
                    [resumeUuid]="resumeUuid"
                    [type]="'experience'"></app-global-tags>
            </div>
        </section>
    </div>

    <div class="modal-footer">
        <button
            (click)="activeModal.close()"
            class="button button--outline"
            type="button">
            Close
        </button>
        <button
            *ngIf="!isUpdate"
            [ngClass]="{ 'button--loading': loading }"
            class="button button--primary"
            type="submit">
            Add Experience
        </button>
        <button
            *ngIf="isUpdate"
            [ngClass]="{ 'button--loading': loading }"
            class="button button--primary"
            type="submit">
            Edit Experience
        </button>
    </div>
</form>
