import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { AuthenticationService } from '../authentication/authentication.service';
import { Company } from '../models/Company';
import { Password } from '../models/Password';
import User from '../models/User';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    private httpOptions = {};
    private user: User;
    private company: Company;

    constructor(private httpClient: HttpClient, private authenticationService: AuthenticationService) {
        this.httpOptions = authenticationService.getHeaders();
    }

    forgotPassword(email: string) {
        return this.httpClient.post<any>(environment.baseUrl + '/auth/password-reset-request-mail', { email });
    }

    activateAccount(token: string) {
        const params = new HttpParams().set('token', token);
        return this.httpClient.get<any>(environment.baseUrl + '/auth/email-verification', { params });
    }

    resendVerificationMail(email: string) {
        return this.httpClient.post<any>(environment.baseUrl + '/auth/resend-verification-mail', email);
    }

    updateUser(user: User): Observable<User> {
        return this.httpClient.put<User>(environment.baseUrl + '/account', user, this.httpOptions);
    }

    updatePassword(password: Password) {
        return this.httpClient.post<Password>(environment.baseUrl + '/auth/update-password', password, this.httpOptions);
    }

    resetPassword(token: string, newPassword: string, checkNewPassword: string) {
        return this.httpClient.post<any>(environment.baseUrl + '/auth/password-reset', { token, newPassword, checkNewPassword });
    }

    enableMfa(password: string) {
        return this.httpClient.post<User>(environment.baseUrl + '/auth/mfa/enable-mfa/', password, this.httpOptions);
    }

    disableMfa(password: string) {
        return this.httpClient.post<User>(environment.baseUrl + '/auth/mfa/disable-mfa/', password, this.httpOptions);
    }

    getMfaQRCode() {
        return this.httpClient.get<string>(environment.baseUrl + '/auth/mfa/qr-code/', this.httpOptions);
    }

    verifyMfaCode(userId: string, code: string) {
        return this.httpClient.post<string>(environment.baseUrl + '/auth/mfa/verify/' + userId + '/' + code, {}, this.httpOptions);
    }

    getUserData(): Observable<User> {
        return this.httpClient.get<User>(environment.baseUrl + '/account', this.httpOptions);
    }

    getUserById(userId: string): Observable<User> {
        return this.httpClient.get<User>(environment.baseUrl + '/account/' + userId, this.httpOptions);
    }

    getCompanyOfUser(): Observable<Company> {
        return this.httpClient.get<Company>(environment.baseUrl + '/company', this.httpOptions);
    }

    whoami() {
        return this.user;
    }

    getMyCompany() {
        return this.company;
    }
}
