import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { ModuleEnum } from 'src/app/models/ModuleEnum';
import { Partner } from 'src/app/models/Partner';
import { ModuleService } from 'src/app/services/module.service';

import { PartnerDeleteModalComponent } from '../partner-delete-modal/partner-delete-modal.component';
import { PartnerUpdateModalComponent } from '../partner-update-modal/partner-update-modal.component';
import { FetchPartners, GetPartner } from '../partners.actions';
import { PartnerState } from '../partners.state';

@Component({
    selector: 'app-partners-subnav',
    templateUrl: './partners-subnav.component.html',
    styleUrls: ['./partners-subnav.component.scss'],
})
export class PartnersSubnavComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Select(PartnerState.getPartners) partners$: Observable<Partner[]>;
    partnerUuid: string;
    addressNotEmpty = false;
    contactInformationNotEmpty = false;
    partner: Partner;

    eModule = ModuleEnum;

    constructor(
        private route: ActivatedRoute,
        private store: Store,
        private modalService: NgbModal,
        private router: Router,
        public moduleService: ModuleService,
    ) {}

    ngOnInit(): void {
        this.subscriptions.add(
            this.store.dispatch(new FetchPartners()).subscribe(() => {
                this.route.params.subscribe((params) => {
                    this.partnerUuid = params.id;
                    this.partners$.subscribe((partners) => (this.partner = partners.filter((p) => p.uuid == this.partnerUuid)[0]));
                });
            }),
        );
    }

    fetchPartner(): Observable<Partner> {
        return this.store.dispatch(new GetPartner(this.partnerUuid));
    }

    openPartnerUpdateModal() {
        const modalRef = this.modalService.open(PartnerUpdateModalComponent);
        modalRef.componentInstance.partner = this.partner;
    }

    openPartnerDeleteModal() {
        const modalRef = this.modalService.open(PartnerDeleteModalComponent, {
            windowClass: 'modal-prompt',
        });
        modalRef.componentInstance.partner = this.partner;
    }

    isActive(instruction: any[]): boolean {
        return this.router.isActive(this.router.createUrlTree(instruction), false);
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
