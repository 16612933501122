import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { TeamleaderCalendarCall } from 'src/app/models/TeamleaderCalendarCall';
import { TeamleaderService } from 'src/app/teamleader/teamleader.service';

import { AddCalendarEvent } from '../teamleader.actions';

@Component({
    selector: 'app-teamleader-add-calendar-call',
    templateUrl: './teamleader-add-calendar-call.component.html',
    styleUrls: ['./teamleader-add-calendar-call.component.scss'],
})
export class TeamleaderAddCalendarCallComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Input() activityType: any;
    companies: any[];
    contacts: any[];
    calendarCallForm: FormGroup;

    constructor(private formBuilder: FormBuilder, private teamleader: TeamleaderService, public activeModal: NgbActiveModal, private store: Store) {}

    ngOnInit(): void {
        this.subscriptions.add(this.teamleader.getCompanies().subscribe((companies) => (this.companies = companies.data)));
        this.subscriptions.add(this.teamleader.getContacts().subscribe((contacts) => (this.contacts = contacts.data)));
        this.calendarCallForm = this.formBuilder.group({
            title: ['', [Validators.required]],
            description: ['', [Validators.required]],
            activityTypeId: [this.activityType.id, [Validators.required]],
            startsAt: ['', [Validators.required]],
            endsAt: ['', [Validators.required]],
            company: ['', [Validators.required]],
            contact: ['', [Validators.required]],
        });
    }

    AddCalendarCall() {
        if (this.calendarCallForm.valid) {
            const formValue = this.calendarCallForm.value;
            const call: TeamleaderCalendarCall = {
                title: formValue.title,
                description: formValue.description,
                activity_type_id: formValue.activityTypeId,
                starts_at: moment(formValue.startsAt).format(),
                ends_at: moment(formValue.endsAt).format(),
                links: [formValue.company, formValue.contact],
            };
            this.store.dispatch(new AddCalendarEvent(call, 'call'));
            this.activeModal.close();
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
