import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

import { AuthenticationService } from '../authentication/authentication.service';

@Injectable({
    providedIn: 'root',
})
export class ExactService {
    httpOptions = {};

    constructor(private httpClient: HttpClient, private authenticationService: AuthenticationService) {
        this.httpOptions = authenticationService.getHeaders();
    }

    //accounts
    getAccounts() {
        return this.httpClient.get<any>(environment.baseUrl + `/exact/accounts`, this.httpOptions);
    }

    addAccount(relation: any) {
        return this.httpClient.post<any>(environment.baseUrl + `/exact/addAccount`, relation, this.httpOptions);
    }

    updateAccount(relation: any) {
        return this.httpClient.put<boolean>(environment.baseUrl + `/exact/updateAccount`, relation, this.httpOptions);
    }

    deleteAccount(id: string) {
        return this.httpClient.delete<boolean>(environment.baseUrl + `/exact/deleteAccount/${id}`, this.httpOptions);
    }

    //items
    getItems() {
        return this.httpClient.get<any>(environment.baseUrl + `/exact/items`, this.httpOptions);
    }

    //invoices
    getInvoices() {
        return this.httpClient.get<any>(environment.baseUrl + `/exact/invoices`, this.httpOptions);
    }

    //payments
    getPayments() {
        return this.httpClient.get<any>(environment.baseUrl + `/exact/payments`, this.httpOptions);
    }

    //orders
    getOrders() {
        return this.httpClient.get<any>(environment.baseUrl + `/exact/orders`, this.httpOptions);
    }

    checkLoggedIn() {
        return this.httpClient.get<boolean>(environment.baseUrl + '/exact/isLoggedIn', this.httpOptions);
    }
}
