<form class="modal-wrapper">
    <div class="modal-header">
        <h2
            class="modal-title"
            id="exampleModalLabel">
            Add leads by tag
        </h2>
        <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="activeModal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col">
                <div class="form-group m-b-2">
                    <label>Channels</label>
                    <tag-input
                        [ngModel]="channels"
                        [ngModelOptions]="{ standalone: true }"
                        (onAdd)="onAddChannel($event)"
                        (onRemove)="onRemovingChannel($event)">
                    </tag-input>
                    <span class="form-helptext">Enter the channels you want to add to the list.</span>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="form-group m-b-2">
                    <label>Tags</label>
                    <tag-input
                        [ngModel]="mailchimpTags"
                        [ngModelOptions]="{ standalone: true }"
                        (onAdd)="onAddTag($event)"
                        (onRemove)="onRemovingTag($event)">
                    </tag-input>
                    <span class="form-helptext">Enter the tags you want to give to the added members.</span>
                </div>
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <input
            [ngClass]="'button button--danger w-100'"
            type="button"
            (click)="addLeads()"
            value="Add leads" />
    </div>
</form>
