import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';
import { ResumeVariant } from 'src/app/models/resume/ResumeVariant';

import { DeleteResumeVariant } from '../resume.actions';

@Component({
    selector: 'app-resume-variant-delete-modal',
    templateUrl: './resume-variant-delete-modal.component.html',
    styleUrls: ['./resume-variant-delete-modal.component.scss'],
})
export class ResumeVariantDeleteModalComponent implements OnInit, OnDestroy {
    @Input() resumeVariant: ResumeVariant;
    private subscriptions = new Subscription();

    constructor(public activeModal: NgbActiveModal, private store: Store, private logger: NGXLogger) {}

    ngOnInit(): void {}

    deleteVariant() {
        this.logger.debug('Attempt to delete skill');
        this.subscriptions.add(
            this.store.dispatch(new DeleteResumeVariant(this.resumeVariant.uuid)).subscribe({
                next: () => {
                    this.logger.debug('Successfully deleted variant');
                    this.activeModal.close();
                },
                error: (error) => this.logger.error('Failed to delete variant'),
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
