import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { parseISO } from 'date-fns';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { AuthenticationService } from '../authentication/authentication.service';
import PlannedEvent from '../models/PlannedEvent';
import Project from '../models/Project';

@Injectable({
    providedIn: 'root',
})
export class PlanningService {
    httpOptions = {};

    constructor(private httpClient: HttpClient, private authenticationService: AuthenticationService) {
        this.httpOptions = authenticationService.getHeaders();
    }

    fetchPlanning() {
        return this.httpClient.get<PlannedEvent[]>(`${environment.baseUrl}/planning`, this.httpOptions).pipe(
            map((events) => {
                for (let i = 0; i < events.length; i++) {
                    events[i] = this.parseEvent(events[i]);
                }
                return events;
            }),
        );
    }

    addPlanning(body: PlannedEvent) {
        return this.httpClient.post<PlannedEvent>(`${environment.baseUrl}/planning`, body, this.httpOptions).pipe(map(this.parseEvent));
    }

    updatePlanning(body: PlannedEvent) {
        return this.httpClient.put<PlannedEvent>(`${environment.baseUrl}/planning/${body.uuid}`, body, this.httpOptions).pipe(map(this.parseEvent));
    }

    deletePlanning(id: string) {
        return this.httpClient.delete(`${environment.baseUrl}/planning/${id}`, this.httpOptions);
    }

    getProjectById(id: string): Observable<Project> {
        return this.httpClient.get<Project>(`${environment.baseUrl}/project/${id}/project-detail`, this.httpOptions);
    }

    private parseEvent(event: PlannedEvent): PlannedEvent {
        if (event.start && event.end) {
            event.start = parseISO(event.start.toString());
            event.end = parseISO(event.end.toString());
        }
        return event;
    }
}
