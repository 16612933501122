import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Select } from '@ngxs/store';
import * as moment from 'moment';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subscription } from 'rxjs';
import { EmployeeState } from 'src/app/employees/employees.state';
import { Employee } from 'src/app/models/Employee';

@Component({
    selector: 'app-dashboard-employee-anniversary-widget',
    templateUrl: './dashboard-employee-anniversary-widget.component.html',
    styleUrls: ['./dashboard-employee-anniversary-widget.component.scss'],
})
export class DashboardEmployeeAnniversaryWidgetComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Select(EmployeeState.BirthdayEmployees) birthdayEmployees$: Observable<Employee[]>;
    noBirthdays = false;

    displayedColumns: string[] = ['firstName', 'age', 'daysUntilBirthday'];
    dataSource: MatTableDataSource<Employee> = new MatTableDataSource();
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    isLoading = true;

    constructor(private logger: NGXLogger) {}

    ngOnInit(): void {
        this.subscriptions.add(
            this.birthdayEmployees$.subscribe(
                (employees) => {
                    if (employees && employees.length == 0) {
                        this.logger.debug('Retrieved 0 employees from the state');
                        this.noBirthdays = true;
                    } else if (employees && employees.length > 0) {
                        this.logger.debug('Retrieved employees from the state, filtering with birthday within 30 days');
                        this.dataSource.data = employees;
                        this.dataSource.sort = this.sort;
                        this.dataSource.sortingDataAccessor = (data, attribute) => data[attribute];
                    }
                    if (employees) {
                        this.isLoading = false;
                    }
                },
                () => {
                    this.logger.error('Failed to retrieve birthday-employees');
                },
            ),
        );
    }

    getEmployeeAge(employee: Employee) {
        return moment().diff(employee.birthday, 'years') + 1;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
