import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subscription } from 'rxjs';
import { CompanyState } from 'src/app/companies/company.state';
import { ModuleEnum } from 'src/app/models/ModuleEnum';
import { Partner } from 'src/app/models/Partner';
import Project from 'src/app/models/Project';
import { ProjectState } from 'src/app/projects/project.state';
import { ModuleService } from 'src/app/services/module.service';

import { GetAllFilesLinkedToObject } from '../../shared/global-files/global-files.actions';
import { PartnerDeleteModalComponent } from '../partner-delete-modal/partner-delete-modal.component';
import { PartnerUpdateModalComponent } from '../partner-update-modal/partner-update-modal.component';
import { PartnerState } from '../partners.state';
import { FetchProjects } from 'src/app/projects/project.actions';

@Component({
    selector: 'app-partner-details-modal',
    templateUrl: './partner-details-modal.component.html',
    styleUrls: ['./partner-details-modal.component.scss'],
})
export class PartnerDetailsModalComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Select(CompanyState.companyUuid) companyUuid$: Observable<string>;
    @Select(PartnerState.getPartners) partners$: Observable<Partner[]>;
    @Select(ProjectState.projects) works$: Observable<Project[]>;
    @Input() partner: Partner;
    @Input() partnerUuid: string;

    checkModule: any;
    works: Project[];
    eModule = ModuleEnum;

    constructor(
        public activeModal: NgbActiveModal,
        public moduleService: ModuleService,
        private logger: NGXLogger,
        private modalService: NgbModal,
        private store: Store,
    ) {
        this.checkModule = this.moduleService.checkModuleActive;
    }

    ngOnInit(): void {
        this.subscriptions.add(
            this.partners$.subscribe((partners) => {
                if (partners && partners.length > 0) {
                    const part = partners.find((p) => p.uuid === (this.partner ? this.partner.uuid : this.partnerUuid));
                    if (part) {
                        this.partner = part;
                    }
                }
            }),
        );
        this.subscriptions.add(this.store.dispatch(new FetchProjects()).subscribe());
        this.logger.debug('Fetching all files linked to object');
        // this.subscriptions.add(this.store.dispatch(new GetAllFilesLinkedToObject(this.partner.uuid)).subscribe());
        this.store.dispatch(new GetAllFilesLinkedToObject(this.partner.uuid)).subscribe();
    }

    openPartnerUpdateModal() {
        this.logger.debug('opening partnerUpdateModalComponent');
        const modalRef = this.modalService.open(PartnerUpdateModalComponent, {
            windowClass: 'modal-pane',
            animation: false,
        });
        modalRef.componentInstance.partner = this.partner;
    }

    openDeletePartnerModal() {
        this.logger.debug('Opening partnerDeleteModal');
        const modalRef = this.modalService.open(PartnerDeleteModalComponent, {
            windowClass: 'modal-prompt',
        });
        modalRef.componentInstance.partnerDetailModal = this.activeModal;
        modalRef.componentInstance.partner = this.partner;
    }

    partnerHasInformation(): boolean {
        if (!this.partner) return false;
        const info = this.partner.contactInformation;
        if (info) {
            if (info.phoneNumber || info.email || info.website || info.linkedIn || info.facebook || info.github) return true;
        }
        if (this.partner.globalTags) {
            if (this.partner.globalTags.length > 0) return true;
        }
        if (this.partner.vat || this.partner.address) return true;
        return false;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
