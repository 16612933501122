import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import User from 'src/app/models/User';
import { ActionsOnStartup, ClearStore, LoginWithMFA } from 'src/app/users/user.actions';

@Component({
    selector: 'app-login-mfa-modal',
    templateUrl: './login-mfa-modal.component.html',
    styleUrls: ['./login-mfa-modal.component.scss'],
})
export class LoginMfaModalComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    constructor(
        public activeModal: NgbActiveModal,
        private store: Store,
        private logger: NGXLogger,
        private toastr: ToastrService,
        private formBuilder: FormBuilder,
    ) {}

    loading = false;
    inputIsWrong = false;
    inputIsVerified = false;
    errorMessage = false;

    @Input() user: User;
    @Input() redirectUrl: string;
    @Input() router: Router;
    mfaLoginFormGroup: FormGroup;

    ngOnInit() {
        this.mfaLoginFormGroup = this.formBuilder.group({
            verificationCode: ['', Validators.compose([Validators.required, Validators.minLength(6), Validators.maxLength(6)])],
        });
    }

    loginWithMFA() {
        this.subscriptions.add(
            this.store.dispatch(new LoginWithMFA(this.user, this.mfaLoginFormGroup.value.verificationCode)).subscribe({
                next: () => {
                    this.toastr.success('Succesfully logged in with MFA');
                    this.logger.debug('Login succesful');
                    this.store.dispatch(new ClearStore()).subscribe(() => {
                        this.store.dispatch(new ActionsOnStartup());
                        this.router.navigate(['/dashboard']);
                    });
                    this.activeModal.close();
                },
                error: () => {
                    this.logger.error('Failed to login');
                    this.toastr.error('Failed to login with MFA for ' + this.user.firstName);
                },
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
