import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { CheckComanageApiKey, FetchOffers } from 'src/app/comanage/comanage.actions';
import { ComanageState } from 'src/app/comanage/comanage.state';
import { CompanyState } from 'src/app/companies/company.state';
import { ContactState } from 'src/app/contacts/contacts.state';
import { CustomerState } from 'src/app/customers/customers.state';
import { Company } from 'src/app/models/Company';
import { ContactPerson } from 'src/app/models/ContactPerson';
import CrmDeal from 'src/app/models/CrmDeal';
import Customer from 'src/app/models/Customer';
import { Module } from 'src/app/models/Module';
import { Partner } from 'src/app/models/Partner';
import { PartnerState } from 'src/app/partners/partners.state';

import { UpdateCrmDeal } from '../crm.actions';

@Component({
    selector: 'app-edit-crmdeal-modal',
    templateUrl: './edit-crmdeal-modal.component.html',
    styleUrls: ['./edit-crmdeal-modal.component.scss'],
})
export class EditCrmdealModalComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Select(CompanyState.company) company$: Observable<Company>;
    @Select(ContactState.Contacts) contacts$: Observable<ContactPerson[]>;
    @Select(PartnerState.getPartners) partners$: Observable<Partner[]>;
    @Select(ComanageState.offers) coManageOffers$: Observable<any[]>;
    @Select(CustomerState.Customers) customers$: Observable<Customer[]>;
    @Select(ComanageState.apiKeyExists) keyExists$: Observable<boolean>;

    @Input() partners: Partner[];
    @Input() contacts: ContactPerson[];
    @Input() customers: ContactPerson[];
    filteredContacts: ContactPerson[];
    @Input() deal: CrmDeal;
    offers: any;
    isLoading = true;

    dealForm: FormGroup;
    activeModules: Module[];
    descriptionTooLong = false;
    currentAmountOfExtraInfoCharacters = 0;
    descriptionMaxLength = 1000;

    constructor(private fb: FormBuilder, private store: Store, private toastr: ToastrService, public activeModal: NgbActiveModal, private logger: NGXLogger) {}

    ngOnInit(): void {
        this.filteredContacts = this.contacts;

        this.subscriptions.add(
            this.company$.subscribe((company) => {
                this.activeModules = company.modules;
                this.store.dispatch(new CheckComanageApiKey()).subscribe(() => {
                    this.keyExists$.subscribe((exists) => {
                        if (this.checkModuleActive('COMANAGE') && exists) {
                            this.coManageOffers$.subscribe((offers) => {
                                if (!offers) {
                                    this.store.dispatch(new FetchOffers()).subscribe({
                                        next: () => undefined,
                                        error: () => {
                                            this.toastr.warning(
                                                "We weren't able to get your comanage offers. Try updating the API key in the comanage settings.",
                                                'Offers unavailable',
                                            );
                                            this.setFrom();
                                        },
                                    });
                                } else {
                                    this.offers = offers.filter((o) => o.status != 'draft');
                                    const currentOffer = this.offers.find((o) => o.offer_number == this.deal.comanageOffer);
                                    this.dealForm = this.fb.group({
                                        title: [this.deal.title, [Validators.required]],
                                        price: [this.deal.price, [Validators.required, Validators.min(0)]],
                                        comanageOffer: [currentOffer ? currentOffer : '', []],
                                        company: [this.deal.partnerUuid ? this.partners.find((p) => p.uuid === this.deal.partnerUuid) : '', []],
                                        contactPerson: [
                                            this.deal.contactPersonUuid ? this.contacts.find((c) => c.uuid === this.deal.contactPersonUuid) : '',
                                            [],
                                        ],
                                        customer: [this.deal.customerUuid ? this.customers.find((c) => c.uuid === this.deal.customerUuid) : ''],
                                        description: [this.deal.description ? this.deal.description : '', [Validators.maxLength(this.descriptionMaxLength)]],
                                    });
                                    this.dealForm.get('company').valueChanges.subscribe((p) => {
                                        this.filteredContacts = this.contacts.filter((c) => c.partnerUuid == p.uuid);
                                    });
                                    this.isLoading = false;
                                }
                            });
                        } else {
                            this.setFrom();
                        }
                    });
                });
            }),
        );
    }

    setFrom() {
        this.dealForm = this.fb.group({
            title: [this.deal.title, [Validators.required]],
            price: [this.deal.price, [Validators.required, Validators.min(0)]],
            company: [this.deal.partnerUuid ? this.partners.find((p) => p.uuid === this.deal.partnerUuid) : '', []],
            contactPerson: [this.deal.contactPersonUuid ? this.contacts.find((c) => c.uuid === this.deal.contactPersonUuid) : '', []],
            customer: [this.deal.customerUuid ? this.customers.find((c) => c.uuid === this.deal.customerUuid) : ''],
            description: [this.deal.description ? this.deal.description : '', [Validators.maxLength(this.descriptionMaxLength)]],
        });
        this.dealForm.get('company').valueChanges.subscribe((p) => {
            this.filteredContacts = this.contacts.filter((c) => c.partnerUuid == p.uuid);
        });
        this.isLoading = false;
    }

    getCurrPartner() {
        if (this.deal.partner != null) {
            return this.partners.find((p) => p.uuid == this.deal.partner.uuid);
        }
        return null;
    }

    getCurrContact() {
        if (this.deal.contactPerson != null) {
            return this.contacts.find((c) => c.uuid == this.deal.contactPerson.uuid);
        }
        return null;
    }

    update() {
        const updatedDeal = JSON.parse(JSON.stringify(this.deal));
        updatedDeal.title = this.dealForm.get('title').value;
        updatedDeal.price = this.dealForm.get('price').value;
        updatedDeal.partner = this.dealForm.get('company').value !== '' ? this.dealForm.get('company').value : this.deal.partner;
        updatedDeal.contactPerson = this.dealForm.get('contactPerson').value !== '' ? this.dealForm.get('contactPerson').value : this.deal.contactPerson;
        updatedDeal.description = this.dealForm.get('description').value;
        updatedDeal.customer = this.dealForm.get('customer').value != '' ? this.dealForm.get('customer').value : this.deal.customer;
        updatedDeal.comanageOffer = this.dealForm.get('comanageOffer')?.value.offer_number;

        this.logger.debug('Attempt to update crmDeal');

        this.subscriptions.add(
            this.store.dispatch(new UpdateCrmDeal(updatedDeal)).subscribe({
                next: () => {
                    this.logger.debug('Succesfully updated crmDeal');
                    this.toastr.success('Deal successfully updated.');
                    this.activeModal.close();
                },
                error: () => {
                    this.logger.error('Failed to updated crmDeal');
                    this.toastr.error('Oops, something went wrong. Please try again later.');
                },
            }),
        );
    }

    checkModuleActive(moduleKey: string): boolean {
        return this.activeModules.find((module) => module.key === moduleKey) ? true : false;
    }

    onDescriptionKeyUp() {
        this.descriptionTooLong = false;
        this.currentAmountOfExtraInfoCharacters = this.dealForm.controls.description.value.length;
        if (this.currentAmountOfExtraInfoCharacters > this.descriptionMaxLength) {
            this.descriptionTooLong = true;
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
