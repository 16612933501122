<form
    [formGroup]="dealForm"
    class="modal-wrapper"
    (ngSubmit)="addDeal()">
    <div class="modal-header">
        <h2 class="modal-title">Add new Deal</h2>
        <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="this.modalService.dismissAll()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div *ngIf="isLoading">
        <app-loading-message></app-loading-message>
    </div>

    <div
        class="modal-body"
        *ngIf="!isLoading">
        <div class="row">
            <div class="col">
                <div class="form-group m-b-2">
                    <label for="title">Title</label>
                    <input
                        id="title"
                        formControlName="title"
                        type="text"
                        placeholder="Title*" />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="form-group m-b-2">
                    <label for="summary">Summary</label>
                    <input
                        id="summary"
                        formControlName="summary"
                        type="text"
                        placeholder="Summary*" />
                </div>
            </div>
        </div>
        <hr />
        <div class="row">
            <div class="col">
                <div class="form-group m-b-2">
                    <label for="leadId">Link A Lead</label>

                    <input
                        type="radio"
                        id="contact"
                        value="contact"
                        style="display: inline"
                        formControlName="leadType" />
                    <label
                        for="contact"
                        style="display: inline"
                        >Contact </label
                    ><br />

                    <input
                        type="radio"
                        id="company"
                        value="company"
                        style="display: inline"
                        formControlName="leadType" />
                    <label
                        for="company"
                        style="display: inline"
                        >Company</label
                    ><br />

                    <select
                        formControlName="leadId"
                        *ngIf="this.dealForm.value.leadType === 'contact'">
                        <option
                            *ngFor="let contact of contacts"
                            [ngValue]="contact.id">
                            {{ contact.first_name }} {{ contact.last_name }}
                        </option>
                    </select>

                    <select
                        formControlName="leadId"
                        *ngIf="this.dealForm.value.leadType === 'company'">
                        <option
                            *ngFor="let company of companies"
                            [ngValue]="company.id">
                            {{ company.name }}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="form-group m-b-2">
                    <label for="contactpersonId">Link A Contactperson for the lead</label>
                    <select formControlName="contactpersonId">
                        <option
                            *ngFor="let contact of contacts"
                            [ngValue]="contact.id">
                            {{ contact.first_name }} {{ contact.last_name }}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <hr />
        <div class="row">
            <div class="col">
                <div class="form-group m-b-2">
                    <label for="departmentId">Link A Department</label>
                    <select formControlName="departmentId">
                        <option
                            *ngFor="let department of departments"
                            [ngValue]="department.id">
                            {{ department.name }}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="form-group m-b-2">
                    <label for="responsibleUserId">Link Responsible User</label>
                    <select formControlName="responsibleUserId">
                        <option
                            *ngFor="let employee of employees"
                            [ngValue]="employee.id">
                            {{ employee.first_name }} {{ employee.last_name }}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="form-group m-b-2">
                    <label for="phaseId">Set Deal Phase</label>
                    <select formControlName="phaseId">
                        <option
                            *ngFor="let phase of phases"
                            [ngValue]="phase.id">
                            {{ phase.name }}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="form-group m-b-2">
                    <label for="amount">Amount</label>
                    <input
                        id="amount"
                        formControlName="amount"
                        type="number"
                        placeholder="Amount (EUR)" />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="form-group m-b-2">
                    <label for="estimatedProbability">Probability</label>
                    <input
                        id="estimatedProbability"
                        formControlName="estimatedProbability"
                        type="number"
                        placeholder="Probability (%)"
                        min="0"
                        max="100"
                        step="1" />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="form-group m-b-2">
                    <label for="estimatedClosingDate">Closing Date</label>
                    <input
                        id="estimatedClosingDate"
                        formControlName="estimatedClosingDate"
                        type="date"
                        placeholder="Closing date*" />
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <input
            [ngClass]="!dealForm.valid ? 'button button--disabled w-100' : 'button button--primary w-100'"
            type="submit"
            [disabled]="!dealForm.valid"
            value="Add Deal" />
        <button
            type="button"
            class="button button--outline"
            (click)="this.modalService.dismissAll()">
            Cancel
        </button>
    </div>
</form>
