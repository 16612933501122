import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';

import { ActionEnum } from '../../models/ActionEnum';
import { ModuleEnum } from '../../models/ModuleEnum';

@Injectable()
export class IsAllowedInterceptor implements HttpInterceptor {
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const permissions = JSON.parse(sessionStorage.getItem('allowedActions'));

        if (permissions == null || permissions['role'] == 'ROLE_SUPER_ADMIN' || permissions['role'] == 'ROLE_OWNER') {
            return next.handle(req);
        }

        const module = this.getModuleFromRequest(req);
        if (module == null) {
            //if no corresponding module could be found it should be allowed (for auth and as a failsafe)
            return next.handle(req);
        } else if (permissions[module.valueOf()] != undefined && permissions[module.valueOf()].includes(this.getActionFromRequest(req))) {
            return next.handle(req);
        }

        console.debug('Skipping the following request because the user is not allowed to access it: ', module.valueOf());
        return EMPTY;
    }

    private getActionFromRequest(req: HttpRequest<any>): ActionEnum {
        switch (req.method) {
            case 'GET':
                return ActionEnum.READ;
            case 'PUT':
                return ActionEnum.UPDATE;
            case 'POST':
                return ActionEnum.CREATE;
            case 'DELETE':
                return ActionEnum.DELETE;
        }
    }

    private getModuleFromRequest(req: HttpRequest<any>): ModuleEnum {
        for (const module in ModuleEnum) {
            if (req.url.includes(module.toLowerCase())) {
                return ModuleEnum[module];
            } else if (module.toLowerCase().endsWith('s') && req.url.includes(module.toLowerCase().slice(0, -1))) {
                return ModuleEnum[module];
            } else if (
                module == ModuleEnum.INVENTORY &&
                (req.url.includes('product') || req.url.includes('warehouse') || req.url.includes('warehouse-location') || req.url.includes('unit'))
            ) {
                return ModuleEnum[module];
            }
        }
        return null;
    }
}
