<div
    *ngIf="yukiDocument.contentType; else empty"
    class="document-container">
    <div class="d-flex space-between align-items-center">
        <strong>{{ yukiDocument.fileName }}</strong>
        <button
            (click)="refreshFile()"
            [ngClass]="{ 'button--refresh': isSyncingFile }"
            [ngbTooltip]="refreshTip"
            class="button button--icon m-b-2"
            id="refresh-btn"
            type="button">
            <i class="las la-sync"></i>
        </button>
    </div>

    <ng-template #refreshTip>
        <p>Refresh file from Yuki API</p>
    </ng-template>

    <div
        *ngIf="(file$ | async) && correctFileIsLoaded; else loading"
        class="w-100 h-100">
        <div
            *ngIf="yukiDocument.contentType.includes('pdf') && url"
            class="w-100 h-100">
            <iframe
                [cachedSrc]="url"
                class="w-100 h-100"></iframe>
        </div>
        <div *ngIf="yukiDocument.contentType.includes('html')">
            <div class="divider"></div>
            <div
                class="yuki-details--html"
                [innerHTML]="html"></div>
        </div>
        <div *ngIf="yukiDocument.contentType.includes('image')">
            <img [src]="createImgSrc()" />
        </div>
    </div>
</div>

<ng-template #empty>
    <app-message-empty [title]="'This document doesn\'t have a file...'"></app-message-empty>
</ng-template>
<ng-template #loading>
    <app-loading-message></app-loading-message>
</ng-template>
