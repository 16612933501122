import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { GetUser } from '../users/user.actions';
import { UserService } from '../users/user.service';
import { UserState } from '../users/user.state';
import { AuthenticationService } from './authentication.service';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard implements CanActivate {
    constructor(private router: Router, private authService: AuthenticationService, private store: Store, private userService: UserService) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        if (this.authService.isUserLoggedIn()) {
            return this.store.select(UserState.userInfo).pipe(
                tap((user) => {
                    if (!user) {
                        this.store.dispatch(new GetUser()).subscribe((state) => (state.user.user ? of(true) : of(false)));
                    }
                    return user ? of(true) : of(false);
                }),
                catchError((error) => {
                    localStorage.removeItem('token');
                    this.router.navigate(['/login']);
                    return of(false);
                }),
                map(() => true),
            );
        } else {
            localStorage.removeItem('token');
            this.router.navigate(['/login'], { queryParams: { redirectURL: `${state.url}` } });
        }
    }
}
