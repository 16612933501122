import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { JiraCredential } from 'src/app/models/JiraCredentials';
import { Module } from 'src/app/models/Module';

import { JiraLoginService } from './jira-login.service';

@Component({
    selector: 'app-jira-login',
    templateUrl: './jira-login.component.html',
    styleUrls: ['./jira-login.component.scss'],
})
export class JiraLoginComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Input() module: Module;
    username: string;
    apiKey: string;
    tempoKey: string;
    form: FormGroup;
    jiraCredential: JiraCredential = null;

    constructor(private fb: FormBuilder, private jiraLoginService: JiraLoginService, private toastr: ToastrService, private logger: NGXLogger) {}

    ngOnInit(): void {
        this.logger.debug('Checking if Jira credentials exist');
        this.subscriptions.add(
            this.jiraLoginService.checkIfJiraCredentialsExistAndFetch().subscribe((e) => {
                this.username = e.username;
                this.apiKey = e.apiKey;
                this.tempoKey = e.tempoKey;
                if (this.apiKey) {
                    this.form.get('username').setValue(this.username);
                    this.form.get('apiKey').setValue(this.apiKey);
                    this.form.get('tempoKey').setValue(this.tempoKey);
                }
            }),
        );
        this.form = this.fb.group({
            username: ['', [Validators.required, Validators.email, Validators.minLength(3), Validators.maxLength(200)]],
            apiKey: ['', [Validators.required, Validators.minLength(30), Validators.maxLength(1200)]],
            tempoKey: ['', [Validators.required, Validators.minLength(20), Validators.maxLength(200)]],
        });
    }

    onSubmit(form: FormGroup) {
        this.jiraCredential = new JiraCredential();
        this.jiraCredential.username = form.get('username').value;
        this.jiraCredential.apiKey = form.get('apiKey').value;
        this.jiraCredential.tempoKey = form.get('tempoKey').value;
        this.logger.debug('Attempt to save Jira credentials');
        this.subscriptions.add(
            this.jiraLoginService.pushJiraCredentials(this.jiraCredential).subscribe(
                (result) => {
                    if (result) {
                        this.logger.debug('Succesfully saved Jira credentials');
                        this.toastr.success('Credentials saved succesfully!');
                    }
                },
                (error) => this.logger.error('Failed to add Jira credentials'),
            ),
        );
    }

    removeKey() {
        this.logger.debug('Attempt to remove Jira credentials');
        this.subscriptions.add(
            this.jiraLoginService.removeJiraCredentials().subscribe(
                (result) => {
                    if (result) {
                        this.logger.debug('Succesfully removed Jira credentials');
                        this.toastr.success('Jira credentials were removed!');
                        this.form.get('username').setValue('');
                        this.form.get('apiKey').setValue('');
                        this.form.get('tempoKey').setValue('');
                        this.form.markAsUntouched();
                    }
                },
                (error) => this.logger.error('Failed to remove Jira credentials'),
            ),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
