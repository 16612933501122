import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import User from 'src/app/models/User';

import { PasswordModalComponent } from '../../shared/password-modal/password-modal.component';
import { UserManageTwoFactorDeleteModalComponent } from '../user-manage-two-factor-delete-modal/user-manage-two-factor-delete-modal.component';
import { GetUser, PostVerifyMfa } from '../user.actions';
import { UserService } from '../user.service';
import { UserState } from '../user.state';

@Component({
    selector: 'app-user-manage-two-factor-modal',
    templateUrl: './user-manage-two-factor-modal.component.html',
    styleUrls: ['./user-manage-two-factor-modal.component.scss'],
})
export class UserManageTwoFactorModalComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Select(UserState.userInfo) user$: Observable<User>;
    @Select(UserState.qrInfo) qrInfo$: Observable<string>;

    //@Select(UserState.userEmail) userEmail$: Observable<string>;
    private user: User;
    isActivated = false;
    qrCode: any;
    isAlreadyActivated = false;
    loading = false;
    inputIsWrong = false;
    inputIsVerified = false;
    errorMessage = false;
    mfaFormGroup: FormGroup;

    constructor(
        public activeModal: NgbActiveModal,
        private store: Store,
        private formBuilder: FormBuilder,
        private logger: NGXLogger,
        private modalService: NgbModal,
        private toastr: ToastrService,
        private userService: UserService,
    ) {}

    ngOnInit(): void {
        this.store.dispatch(new GetUser());
        this.subscriptions.add(
            this.user$.subscribe((user) => {
                this.user = user;
            }),
        );
        this.subscriptions.add(
            this.qrInfo$.subscribe((qr) => {
                this.qrCode = qr;
            }),
        );
        if (this.user.mfaEnabled) {
            this.isAlreadyActivated = true;
            this.isActivated = true;
        }
        this.mfaFormGroup = this.formBuilder.group({
            verificationCode: ['', Validators.compose([Validators.required, Validators.minLength(6), Validators.maxLength(6)])],
        });
    }

    toggleAuth() {
        this.isActivated = !this.isActivated;
        if (!this.isActivated) {
            this.openMfaDisableModal();
            return;
        }
        if (!this.isAlreadyActivated && this.isActivated) {
            this.openPasswordModal();

            /*this.store.dispatch(new EnableMfaAuthentication(this.user.uuid)).subscribe(() => {
                this.subscriptions.add(this.userService.getMfaQRCode(this.user.email).subscribe((qr) => {
                    this.qrCode = qr;
                }))
            }, error => {
                this.toastr.error('Two factor authentication could not be enabled');
                this.isActivated = false;
            });*/
        }
    }

    format() {
        return this.qrCode.qr;
    }

    verifyCode() {
        this.loading = true;
        this.inputIsWrong = false;
        this.inputIsVerified = false;
        this.mfaFormGroup.disable();
        this.store.dispatch(new PostVerifyMfa(this.user.uuid, this.mfaFormGroup.value.verificationCode)).subscribe(
            () => {
                this.inputIsVerified = true;
                this.isActivated = true;
                this.isAlreadyActivated = true;
                this.store.dispatch(new GetUser());
                this.toastr.success('Succesfully enabled two factor authentication');
            },
            (errors) => {
                this.inputIsWrong = true;
                this.inputIsVerified = false;
                this.loading = false;
                this.mfaFormGroup.enable();
            },
        );
    }

    openMfaDisableModal() {
        this.logger.debug('Opening disable mfa modal');
        const modalRef = this.modalService.open(UserManageTwoFactorDeleteModalComponent, { windowClass: 'modal-prompt', animation: false });
        modalRef.componentInstance.user = this.user;
    }

    openPasswordModal() {
        const modalRef = this.modalService.open(PasswordModalComponent, {
            windowClass: 'modal-prompt',
            animation: false,
        });
        this.subscriptions.add(
            modalRef.componentInstance.qrCode.subscribe((qrCode) => {
                console.log(qrCode);
                this.qrCode = qrCode;
            }),
        );
    }

    hideErrorMessage() {
        this.inputIsWrong = false;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
