import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ContactpersonDetailComponent } from './contactperson-detail/contactperson-detail.component';
import { ContactsOverviewComponent } from './contacts-overview/contacts-overview.component';

export const routes: Routes = [
    { path: '', redirectTo: 'overview', pathMatch: 'full' },
    { path: 'overview', component: ContactsOverviewComponent },
    { path: ':id/detail', component: ContactpersonDetailComponent },
];

export const routing: ModuleWithProviders<any> = RouterModule.forChild(routes);
