import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subscription } from 'rxjs';
import { ContactPerson } from 'src/app/models/ContactPerson';

import { ContactpersonDetailModalComponent } from '../contactperson-detail-modal/contactperson-detail-modal.component';
import { FetchContacts } from '../contacts.actions';
import { ContactState } from '../contacts.state';

@Component({
    selector: 'app-contacts-overview',
    templateUrl: './contacts-overview.component.html',
    styleUrls: ['./contacts-overview.component.scss'],
})
export class ContactsOverviewComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Select(ContactState.Contacts) contacts$: Observable<ContactPerson[]>;

    partnerUuid: string;

    constructor(private modalService: NgbModal, private store: Store, private logger: NGXLogger) {}

    ngOnInit(): void {
        this.logger.debug('Fetching contacts');
        this.store.dispatch(new FetchContacts());
    }

    openAddContactPersonsModal() {
        this.logger.debug('opening contactPersonDetailModalComponent');
        const modalRef = this.modalService.open(ContactpersonDetailModalComponent, {
            windowClass: 'modal-pane',
            animation: false,
        });
        modalRef.componentInstance.partnerUuid = this.partnerUuid;
        modalRef.componentInstance.inOverview = true;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
