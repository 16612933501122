import { Component, EventEmitter, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { FetchContacts } from 'src/app/contacts/contacts.actions';
import { ContactState } from 'src/app/contacts/contacts.state';
import { ContactPerson } from 'src/app/models/ContactPerson';
import Customer from 'src/app/models/Customer';
import { ModuleEnum } from 'src/app/models/ModuleEnum';
import { ModuleService } from 'src/app/services/module.service';
import { CountryService } from 'src/app/shared/country.service';

import { AddCustomer, FetchCustomers, UpdateCustomer } from '../customers.actions';

@Component({
    selector: 'app-add-customer-modal',
    templateUrl: './add-customer-modal.component.html',
    styleUrls: ['./add-customer-modal.component.scss'],
})
export class AddCustomerModalComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Select(ContactState.Contacts) contacts$: Observable<ContactPerson[]>;

    public emitObject: EventEmitter<Customer> = new EventEmitter();

    @Input() customer: Customer;

    isLoading = true;
    isUpdate = false;
    customerFormGroup: FormGroup;
    eModule = ModuleEnum;
    allCountries = [];

    constructor(
        private formBuilder: FormBuilder,
        private countryService: CountryService,
        public activeModal: NgbActiveModal,
        private store: Store,
        private logger: NGXLogger,
        private toastr: ToastrService,
        public moduleService: ModuleService,
    ) {}

    ngOnInit(): void {
        this.allCountries = this.countryService.getAllCountries();
        this.customerFormGroup = this.formBuilder.group({
            firstName: ['', Validators.required],
            lastName: ['', Validators.required],
            address: this.formBuilder.group({
                street: ['', [Validators.required]],
                number: [null, [Validators.required]],
                bus: [''],
                ext: [null],
                postal: ['', [Validators.required]],
                city: ['', [Validators.required]],
                country: this.formBuilder.group({
                    iso2: ['', [Validators.required]],
                }),
            }),
            contactInformation: this.formBuilder.group({
                phoneNumber: ['', [Validators.required]],
                email: ['', [Validators.email, Validators.required]],
            }),
            contact: [''],
        });

        if (this.customer) {
            this.isUpdate = true;
        } else {
            this.isLoading = false;
        }

        this.subscriptions.add(
            this.contacts$.subscribe((contacts) => {
                if (contacts == null) {
                    this.store.dispatch(new FetchContacts());
                } else if (this.isUpdate) {
                    const contact = contacts.find((c) => this.customer.contactPersonUuid === c.uuid);
                    this.customerFormGroup.setValue({
                        firstName: this.customer.firstName ? this.customer.firstName : '',
                        lastName: this.customer.lastName ? this.customer.lastName : '',
                        address: this.customer.address ? this.customer.address : '',
                        contactInformation: {
                            phoneNumber: this.customer.contactInformation.phoneNumber ? this.customer.contactInformation.phoneNumber : '',
                            email: this.customer.contactInformation.email ? this.customer.contactInformation.email : '',
                        },
                        contact: contact ? contact : '',
                    });
                    this.isLoading = false;
                }
            }),
        );
    }

    submit() {
        this.isLoading = true;
        if (!this.customerFormGroup.valid) {
            this.isLoading = false;
            this.toastr.warning('You did not enter valid information.');
            return;
        }
        this.customerFormGroup.disable();

        let newCustomer;

        if (this.isUpdate) {
            newCustomer = JSON.parse(JSON.stringify(this.customer));
            newCustomer.address = this.customerFormGroup.get('address').value;
            newCustomer.contactInformation = this.customerFormGroup.get('contactInformation').value;
            newCustomer.firstName = this.customerFormGroup.get('firstName').value;
            newCustomer.lastName = this.customerFormGroup.get('lastName').value;
        } else {
            newCustomer = this.customerFormGroup.value;
        }

        if (this.customerFormGroup.get('contact').value) {
            newCustomer.contactPersonFirstName = this.customerFormGroup.get('contact').value.firstName;
            newCustomer.contactPersonLastName = this.customerFormGroup.get('contact').value.lastName;
            newCustomer.contactPersonUuid = this.customerFormGroup.get('contact').value.uuid;
        }

        if (this.isUpdate) {
            this.logger.debug('attempt to save customer');
            this.logger.debug('TODO Update customer not implemented yet');
            this.subscriptions.add(
                this.store.dispatch(new UpdateCustomer(newCustomer)).subscribe(() => {
                    this.logger.debug('Update customer successfull');
                    this.activeModal.close();
                    this.store.dispatch(new FetchCustomers());
                }),
            );
        } else {
            this.logger.debug('attempt to add customer');
            this.subscriptions.add(
                this.store.dispatch(new AddCustomer(newCustomer)).subscribe((customer) => {
                    this.logger.debug('successfully added customer');
                    this.emitObject.emit(customer);
                    this.activeModal.close();
                    this.store.dispatch(new FetchCustomers());
                }),
            );
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
