<ng-container *ngIf="!isLoading && reasons; else loading">
    <div class="chart_holder">
        <canvas
            baseChart
            height="200px"
            [datasets]="barChartData"
            [labels]="barChartLabels"
            [options]="barChartOptions"
            [plugins]="barChartPlugins"
            [legend]="barChartLegend"
            [chartType]="barChartType">
        </canvas>
    </div>
</ng-container>

<ng-template #loading>
    <app-loading-message></app-loading-message>
</ng-template>
