<app-app-holder>
    <app-navbar></app-navbar>

    <app-sidebar>
        <app-projects-subnav></app-projects-subnav>
    </app-sidebar>

    <app-content>
        <div class="container-fluid">
            <div class="row">
                <div class="col">
                    <div
                        *ngIf="project"
                        class="work">
                        <div class="work__header">
                            <div class="modal-headers">
                                <h2 class="modal-title">{{ project.title }}</h2>
                            </div>
                            <div class="work__info">
                                <time>
                                    {{ project.startDate | date }} -
                                    <span *ngIf="!project.present">{{ project?.endDate | date }}</span>
                                    <span *ngIf="project.present">present</span>
                                </time>

                                <!--
                            <small *ngIf="resume.daysSinceCreation != 0">Created {{resume.daysSinceCreation}} days ago</small>
                            <small *ngIf="resume.daysSinceCreation == 0">Created today</small>
                            --></div>
                            <div class="resume__actions">
                                <ng-template #popContent>
                                    <div class="d-flex">
                                        <span class="color--grey m-r-1">Created by</span>
                                        <span>{{ project.userId }}</span>
                                    </div>
                                    <div
                                        class="d-flex"
                                        *ngIf="work.partnerName">
                                        <span class="color--grey m-r-1">Company</span>
                                        <span>{{ project.partnerName }}</span>
                                    </div>
                                </ng-template>
                                <span
                                    class="button button--outline button--small m-r-2"
                                    [ngbPopover]="popContent"
                                    popoverTitle="Information"
                                    ><i class="uil uil-info-circle"></i> Info</span
                                >

                                <div
                                    ngbDropdown
                                    class="dropdown">
                                    <button
                                        ngbDropdownToggle
                                        class="button button--icon button--small dropdown-toggle"
                                        id="dropdownMenuButton">
                                        <span>Actions</span>
                                    </button>
                                    <div
                                        ngbDropdownMenu
                                        class="dropdown-menu dropdown-menu-right"
                                        aria-labelledby="dropdownMenuButton">
                                        <div class="dropdown-divider"></div>
                                        <a
                                            class="dropdown-item"
                                            (click)="openProjectDetailModalComponent()"
                                            >Edit Project</a
                                        >
                                        <a
                                            class="dropdown-item color--red"
                                            (click)="openWorkDeleteModal(project)"
                                            >Archive Project</a
                                        >
                                    </div>
                                </div>
                                <!--<img src="assets/img/alpinedigital.png" />-->

                                <span
                                    class="tag tag--light tag--big m-r-2 m-b-2"
                                    *ngFor="let tag of project.globalTags | orderBy: 'value':true">
                                    {{ tag.title }}
                                </span>
                            </div>
                        </div>

                        <div>
                            <label>Company name: </label>
                            <span *ngIf="project.companyName">
                                {{ project.companyName }}
                            </span>
                        </div>

                        <div>
                            <label>Location: </label>
                            <span *ngIf="project.location && project.location.number != 0">
                                {{ project.location.street }}
                                {{ project.location.number }},
                                {{ project.location.postal }}
                                {{ project.location.city }}
                            </span>
                            <span *ngIf="!project.location">No Location added...</span>
                        </div>

                        <div>
                            <label>Description: </label>
                            <span
                                class="format-newlines"
                                *ngIf="project.description">
                                {{ project.description }}
                            </span>
                            <span *ngIf="!project.description">No description added...</span>
                        </div>

                        <div>
                            <label>Extra Info: </label>
                            <span *ngIf="project.pdfUpload">
                                <a
                                    [href]="project.pdfUpload"
                                    target="_blank"
                                    >View PDF</a
                                >
                            </span>
                            <span *ngIf="!project.pdfUpload">No PDF uploaded...</span>
                        </div>

                        <div>
                            <label>completion: </label>
                            <span *ngIf="project.finished"> This project is finished </span>
                            <span *ngIf="!project.finished"> This project is still ongoing </span>
                        </div>

                        <div *ngIf="project.products && this.moduleService.checkModuleActive(eModule.INVENTORY)">
                            <div class="sectiontitle">Related products</div>
                            <table class="table-view">
                                <tr>
                                    <th>Product</th>
                                    <th class="td-small"></th>
                                </tr>

                                <tr *ngFor="let product of project.products">
                                    <td>
                                        <span
                                            placement="top"
                                            [ngbTooltip]="product.description"
                                            >{{ product.title }}</span
                                        >
                                    </td>
                                    <td>
                                        <span
                                            ngbDropdown
                                            class="dropdown">
                                            <button
                                                ngbDropdownToggle
                                                class="button button--icon button--small dropdown-toggle"
                                                id="dropdownMenuButton"></button>
                                            <div
                                                ngbDropdownMenu
                                                class="dropdown-menu dropdown-menu-right"
                                                aria-labelledby="dropdownMenuButton">
                                                <div class="dropdown-divider"></div>
                                                <a
                                                    class="dropdown-item color--red"
                                                    (click)="openWorkProductDeleteModal(project, product)"
                                                    >Remove product</a
                                                >
                                            </div>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        colspan="3"
                                        class="table-view__add"
                                        (click)="openWorkAddProductModal(project)">
                                        <i class="las la-plus"></i> Add new product
                                    </td>
                                </tr>
                            </table>
                        </div>

                        <!--   <footer>
                        <div *ngIf="company$ | async as company">
                            {{company.logo}} <br />
                            {{company.title}} - {{company.address.street}} - {{company.address.number}} - {{company.address.postal}} - {{company.address.city}}
                        </div>
                    </footer> -->
                        <footer>
                            <button
                                type="button"
                                class="button button--outline"
                                (click)="workDetail(project); activeModal.dismiss()">
                                <span>Go to detailpage</span>
                            </button>
                        </footer>
                    </div>
                </div>
            </div>
        </div>
    </app-content>

    <ng-template #loading>
        <app-loading-message></app-loading-message>
    </ng-template>
</app-app-holder>
