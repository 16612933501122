<div class="modal-wrapper">
    <div class="modal-header">
        <h2
            class="modal-title"
            id="exampleModalLabel">
            {{ this.data.title }}
        </h2>
        <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="activeModal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <select
            name="sites"
            id="sites"
            (change)="translate($event)"
            *ngIf="this.languages?.length">
            <option
                value="default"
                selected>
                International
            </option>
            <option
                *ngFor="let language of this.languages"
                [value]="language.slug">
                {{ language.country }} - {{ language.languageCode }} ({{ language.title }})
            </option>
        </select>

        <div
            *ngIf="!this.loading"
            class="m-t-2">
            <h2 class="sectiontitle">General</h2>

            <table class="m-t-2 table-view">
                <tr>
                    <th *ngFor="let prop of getKeysForRegularValue(this.data)">
                        {{ prop }}
                    </th>
                </tr>
                <tr>
                    <td *ngFor="let prop of getKeysForRegularValue(this.data)">
                        {{ this.data[prop] }}
                    </td>
                </tr>
            </table>

            <div
                *ngIf="this.data.mainimage?.length > 0"
                class="m-t-2">
                <h2 class="sectiontitle">Main image</h2>
                <img [src]="this.data.mainimage[0].resumoThumbnail" />
            </div>

            <div
                *ngIf="this.data.images?.length > 0"
                class="m-t-2">
                <h2 class="sectiontitle">Images</h2>
                <app-image-gallery
                    [small]="smallImages"
                    [medium]="mediumImages"
                    [big]="bigImages"></app-image-gallery>
            </div>

            <div
                *ngFor="let prop of getKeysForArrayValue(this.data)"
                class="m-t-2">
                <div *ngIf="prop !== 'mainimage' && prop !== 'images' && this.data[prop].length > 0">
                    <h2 class="sectiontitle">{{ prop }}</h2>

                    <table class="m-t-2 table-view">
                        <tr>
                            <th *ngFor="let arrayKey of getKeysForRegularValue(this.data[prop][0])">
                                {{ arrayKey }}
                            </th>
                        </tr>
                        <tr *ngFor="let arrayElement of this.data[prop]; let i = index">
                            <td *ngFor="let arrayElementKey of getKeysForRegularValue(this.data[prop][i])">
                                {{ this.data[prop][i][arrayElementKey] }}
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>

        <div *ngIf="this.loading">
            <app-loading-message></app-loading-message>
        </div>
    </div>

    <div class="modal-footer">
        <button
            type="button"
            class="button button--outline"
            (click)="activeModal.dismiss()">
            Close
        </button>
    </div>
</div>
