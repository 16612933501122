import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-color-palette-list',
    templateUrl: './color-palette-list.component.html',
    styleUrls: ['./color-palette-list.component.scss'],
})
export class ColorPaletteListComponent implements OnInit {
    colours: string[] = [
        '#f6e58d',
        '#f9ca24',
        '#ffbe76',
        '#f0932b',
        '#ff7979',
        '#eb4d4b',
        '#badc58',
        '#6ab04c',
        '#dff9fb',
        '#c7ecee',
        '#7ed6df',
        '#22a6b3',
        '#e056fd',
        '#be2edd',
        '#686de0',
        '#4834d4',
        '#30336b',
        '#130f40',
        '#95afc0',
        '#535c68',
    ];

    @Output() colour = new EventEmitter<string>();
    @Input() selectedColour: string;

    constructor() {}

    ngOnInit(): void {}

    onSelectColour(colour: string) {
        this.selectedColour = colour;
        this.colour.emit(colour);
    }
}
