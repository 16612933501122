import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { append, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { tap } from 'rxjs/operators';
import { CompanyState } from 'src/app/companies/company.state';
import { Role } from 'src/app/models/Role';
import { UserState } from 'src/app/users/user.state';

import { UpdateRoleOfMember } from '../../companies/company.actions';
import { Module } from '../../models/Module';
import {
    AddCustomRole,
    AddCustomRoleToInvite,
    AddCustomRoleToMember,
    ClearCustomRolesState,
    DeleteCustomRole,
    FetchAllowedActions,
    FetchAllowedModules,
    FetchRoles,
    RemoveModuleFromAllowedModules,
    UpdateCustomRole,
    UpdateModulesOnSetupChange,
} from './custom.roles.actions';
import { CustomRolesService } from './custom.roles.service';

export interface CustomRoleStateModel {
    roles: Role[];
    allowedModuleKeys: string[];
    allowedModules: Module[];
    allowedActions: any;
    modules: Module[];
}

@State<CustomRoleStateModel>({
    name: 'role',
    defaults: {
        roles: [],
        allowedModuleKeys: ['SETTINGS', 'DASHBOARD'],
        allowedModules: [],
        allowedActions: {},
        modules: [],
    },
})
@Injectable()
export class CustomRolesState {
    constructor(private customRoleService: CustomRolesService, private store: Store) {}

    @Selector()
    static CustomRoles(state: CustomRoleStateModel): Role[] {
        return state?.roles;
    }

    @Selector()
    static AllowedModuleKeys(state: CustomRoleStateModel): string[] {
        return state?.allowedModuleKeys;
    }

    @Selector()
    static AllowedModules(state: CustomRoleStateModel): Module[] {
        return state?.allowedModules;
    }

    @Selector()
    static AllowedActions(state: CustomRoleStateModel): any {
        return state?.allowedActions;
    }

    @Action(FetchAllowedModules)
    fetchAllowedModules(ctx: StateContext<CustomRoleStateModel>) {
        return this.customRoleService.getAllowedModules().pipe(
            tap((modules) => {
                ctx.setState(
                    patch<CustomRoleStateModel>({
                        allowedModuleKeys: append(modules.map((module) => module.key)),
                        allowedModules: append(modules),
                    }),
                );
                sessionStorage.setItem('allowedModules', JSON.stringify(modules.map((module) => module.key)));
            }),
        );
    }

    @Action(UpdateModulesOnSetupChange)
    updateModulesOnSetupChange(ctx: StateContext<CustomRoleStateModel>, action: UpdateModulesOnSetupChange) {
        if (!ctx.getState().allowedModuleKeys.find((module) => module === action.module.key)) {
            ctx.setState(
                patch<CustomRoleStateModel>({
                    allowedModuleKeys: append([action.module.key]),
                    allowedModules: append([action.module]),
                }),
            );
        } else {
            ctx.setState(
                patch<CustomRoleStateModel>({
                    allowedModuleKeys: removeItem((module) => module == action.module.key),
                    allowedModules: removeItem((module) => module.key == action.module.key),
                }),
            );
        }
        sessionStorage.setItem('allowedModules', JSON.stringify(ctx.getState().allowedModuleKeys));
    }

    @Action(RemoveModuleFromAllowedModules)
    removeAllowedModule(ctx: StateContext<CustomRoleStateModel>, action: RemoveModuleFromAllowedModules) {
        ctx.setState(
            patch<CustomRoleStateModel>({
                allowedModuleKeys: removeItem<string>((m) => m == action.moduleName),
            }),
        );
    }

    @Action(FetchAllowedActions)
    fetchAllowedActions(ctx: StateContext<CustomRoleStateModel>) {
        this.customRoleService.getAllowedActions().subscribe((actions) => {
            const allowed: any = {};
            actions.forEach((action) => {
                if (action.authority) {
                    if (action.authority == 'ROLE_SUPER_ADMIN') {
                        allowed['role'] = 'ROLE_SUPER_ADMIN';
                    } else if (action.authority == 'ROLE_OWNER' && allowed['role'] != 'ROLE_SUPER_ADMIN') {
                        allowed['role'] = 'ROLE_OWNER';
                    }
                }
                if (action.module) {
                    allowed[action.module] = action.permissions;
                }
            });
            ctx.setState(
                patch<CustomRoleStateModel>({
                    allowedActions: allowed,
                }),
            );
            sessionStorage.setItem('allowedActions', JSON.stringify(ctx.getState().allowedActions));
        });
    }

    @Action(FetchRoles)
    fetchCurrent(ctx: StateContext<CustomRoleStateModel>, action: FetchRoles) {
        return this.customRoleService.getCustomRoles().subscribe((fetchedRoles) => {
            const state = ctx.getState();
            ctx.setState({
                ...state,
                roles: [...fetchedRoles],
            });
        });
    }

    @Action(AddCustomRole)
    addCustomRole(ctx: StateContext<CustomRoleStateModel>, action: AddCustomRole) {
        return this.customRoleService.addCustomRole(action.role).pipe(
            tap((customRole) => {
                ctx.setState(
                    patch<CustomRoleStateModel>({
                        roles: append<Role>([customRole]),
                    }),
                );
            }),
        );
    }

    @Action(UpdateCustomRole)
    updateCustomRole(ctx: StateContext<CustomRoleStateModel>, action: UpdateCustomRole) {
        return this.customRoleService.updateCustomRole(action.role).pipe(
            tap((customRole) => {
                ctx.setState(
                    patch<CustomRoleStateModel>({
                        roles: updateItem<Role>((cr) => cr.id === action.role.id, customRole),
                    }),
                );
            }),
        );
    }

    @Action(DeleteCustomRole)
    deleteCustomRole(ctx: StateContext<CustomRoleStateModel>, action: DeleteCustomRole) {
        return this.customRoleService.deleteCustomRole(action.roleId).pipe(
            tap((result) => {
                if (result) {
                    ctx.setState(
                        patch<CustomRoleStateModel>({
                            roles: removeItem<Role>((cr) => cr.id === action.roleId),
                        }),
                    );
                }
            }),
        );
    }

    @Action(ClearCustomRolesState)
    clearCustomRolesState(ctx: StateContext<CustomRoleStateModel>) {
        ctx.setState(
            patch<CustomRoleStateModel>({
                roles: [],
                allowedModuleKeys: ['SETTINGS', 'DASHBOARD'],
            }),
        );
    }

    @Action(AddCustomRoleToMember)
    addCustomRoleToMember(ctx: StateContext<CustomRoleStateModel>, action: AddCustomRoleToMember) {
        return this.customRoleService.addCustomRoleToMember(action.userUuid, action.roleId).pipe(
            tap(() => {
                this.store.dispatch(new UpdateRoleOfMember(action.userUuid, ctx.getState().roles.find((role) => role.id === action.roleId).name));
            }),
        );
    }

    @Action(AddCustomRoleToInvite)
    addCustomRoleToInvite(ctx: StateContext<CustomRoleStateModel>, action: AddCustomRoleToInvite) {
        return this.customRoleService.addCustomRoleToInvite(action.roleId, action.inviteUuid);
    }

    checkActiveAndPermitted(eModuleName, ctx: StateContext<CustomRoleStateModel>) {
        return ctx.getState().allowedModuleKeys.filter((m) => m.toLowerCase() == eModuleName.toLowerCase()).length > 0;
    }

    checkModule(eModule: string): boolean {
        let active = false;
        let permitted = false;
        let modules;
        let currentUserEmail;
        let userRoleName;
        let userRole;

        this.store.selectOnce(CompanyState.company).subscribe((company) => {
            company?.modules ? (modules = company.modules) : (modules = null);
        });

        active = modules.filter((m) => m.key === eModule).length > 0;
        if (eModule.toLowerCase() == 'dashboard' || eModule.toLowerCase() == 'settings') {
            return true;
        }
        this.store.selectOnce(UserState.userEmail).subscribe((email) => {
            email ? (currentUserEmail = email) : (currentUserEmail = null);
        });
        if (currentUserEmail) {
            this.store.select(CompanyState.companyMembers).subscribe((members) => {
                members?.forEach((m) => {
                    if (m.email == currentUserEmail) {
                        m.role ? (userRoleName = m.role) : (userRoleName = null);
                    }
                });
            });
        }
        if (userRoleName) {
            if (userRoleName != 'ROLE_SUPER_ADMIN' && userRoleName != 'ROLE_OWNER') {
                this.store.select(CustomRolesState.CustomRoles).subscribe((roles) => {
                    const role = roles?.find((r) => r.name == userRoleName);
                    role ? (userRole = role) : (userRole = null);
                });
                if (userRole) {
                    permitted = userRole.modules.filter((m) => m.key.toLowerCase() === eModule.toLowerCase()).length > 0;
                }
            } else {
                permitted = true;
            }
            return active && permitted;
        }
    }
}
