import { Clipboard } from '@angular/cdk/clipboard';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { MatTableExporterDirective } from 'mat-table-exporter';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { Employee } from 'src/app/models/Employee';
import Project from 'src/app/models/Project';
import { TIMESHEET_STATUS, Timesheet } from 'src/app/models/Timesheet';
import { Timeslot } from 'src/app/models/Timeslot';
import { ConfirmationModalComponent } from 'src/app/shared/confirmation-modal/confirmation-modal.component';
import { MinutesToMinutesAndHoursPipe } from 'src/app/shared/pipes/minutes-to-minutes-and-hours.pipe';


import { EMOJIS } from '../../models/EMOJIS';
import { AddTimesheetModalComponent } from '../add-timesheet-modal/add-timesheet-modal.component';
import { CronjobRulesSettingsModalComponent } from '../cronjob-rules-settings-modal/cronjob-rules-settings-modal.component';
import { InvoiceDialogComponent } from '../invoice-dialog/invoice-dialog.component';
import { TimesheetInfoModalComponent } from '../timesheet-info-modal/timesheet-info-modal.component';
import { ChangeTimesheetStatus, RemoveTimesheet, SetTimeslotToIsBilled } from '../timesheets.actions';
import { TimesheetsService } from '../timesheets.service';
import { TimesheetState } from '../timesheets.state';

@Component({
    selector: 'app-timesheets-project-list',
    templateUrl: './timesheets-project-list.component.html',
    styleUrls: ['./timesheets-project-list.component.scss'],
})
export class TimesheetsProjectListComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Select(TimesheetState.timesheets) timesheets$: Observable<Timesheet[]>;
    @Select(TimesheetState.selectedDate) selectedDate$: Observable<string>;

    filter;
    isLoading = true;
    sendingReminders = false;

    emojis: EMOJIS[] = [];

    timesheets: Timesheet[] = [];
    projects: Project[] = [];
    timeslots: Timeslot[] = [];
    employee: Employee;
    uniqueProjects: Project[] = [];
    displayedColumns: string[] = ['projects', 'isBilled', 'timeslots', 'totalHours', 'edit'];
    dataSource: MatTableDataSource<Project>;
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatTableExporterDirective, { static: true }) exporter: MatTableExporterDirective;

    constructor(
        public modalService: NgbModal,
        private timesheetService: TimesheetsService,
        private toastr: ToastrService,
        private minutesToMinutesAndHours: MinutesToMinutesAndHoursPipe,
        private logger: NGXLogger,
        private store: Store,
        private clipboard: Clipboard,
        private dialog: MatDialog,
    ) {}

    ngOnInit(): void {
        this.emojis = Object.values(EMOJIS);
        this.subscriptions.add(
            this.selectedDate$.subscribe(() => {
                this.timesheets$.subscribe((t) => {
                    const timesheets = JSON.parse(JSON.stringify(t));
                    this.timesheets = timesheets;
                    /* calculate total worktime */
                    this.timeslots = [];
                    this.uniqueProjects = [];
                    timesheets.forEach((timesheet) => {
                        timesheet.totalHours = this.minutesToMinutesAndHours.transform(Timesheet.getTotalMinutes(timesheet));
                        /*create list of timeslots for the selected Date */
                        timesheet.timeslots.forEach((slot: Timeslot) => {
                            slot.timesheet = timesheet;
                            this.timeslots.push(slot);
                        });
                        /* create uniqueProjects list for the selected Date */
                        this.projects = Timesheet.getProjects(timesheet);
                        for (const project of this.projects) {
                            if (!this.uniqueProjects.some((p) => p.uuid === project.uuid)) {
                                this.uniqueProjects.push(project);
                            }
                        }
                    });

                    /* sort uniqueProjects list alphabetically and ascending */
                    this.uniqueProjects.sort((a, b) => a.title.localeCompare(b.title));

                    /* setup mat-table datasource */
                    this.dataSource = new MatTableDataSource(this.uniqueProjects);
                    this.dataSource.sort = this.sort;

                    /* setup mat-table filter: parse timesheet to JSON to allow filtering on nested objs */
                    this.dataSource.filterPredicate = (data: Project, filter) => {
                        return data.title.toLowerCase().includes(filter.toLowerCase());
                    };

                    /* setup mat-table sorting: allow sorting on nested objs */
                    this.dataSource.sortingDataAccessor = (item, property) => {
                        if (property.includes('.')) return property.split('.').reduce((o, i) => o[i], item);
                        return item[property];
                    };

                    if (t) {
                        this.isLoading = false;
                    }
                });
            }),
        );
    }

    getTimeslotsForProject(project: Project): Timeslot[] {
        const slots: Timeslot[] = [];
        this.timeslots.forEach((slot) => {
            if (slot.project.uuid === project.uuid) {
                slots.push(slot);
            }
        });
        return slots;
    }

    getTotalWorkedMinutesForProject(project: Project): number {
        const slots = [];
        let minutes = 0;
        this.timeslots.forEach((slot) => {
            if (slot.project.uuid === project.uuid) {
                slots.push(slot);
            }
        });
        slots.forEach((s) => {
            minutes += s.minutes;
        });
        return minutes;
    }

    getEmployeeByTimeslot(timeslot: Timeslot) {
        this.employee = null;
        this.timesheets.forEach((sheet) => {
            sheet.timeslots.forEach((slot) => {
                if (slot.uuid === timeslot.uuid) {
                    this.employee = sheet.employee;
                }
            });
        });

        return this.employee;
    }

    applyFilter(filterValue) {
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }

    addNewTimesheet() {
        const employees = [];
        this.timesheets.forEach((el) => employees.push(el.employee));
        this.logger.debug('Opening addTimesheetModal');
        const modalRef = this.modalService.open(AddTimesheetModalComponent, { windowClass: 'modal-prompt' });
        modalRef.componentInstance.currentEmployees = employees;
    }

    sendReminders() {
        this.sendingReminders = true;
        this.subscriptions.add(
            this.selectedDate$.subscribe((selectedDate) => {
                this.logger.debug('Attempt to send reminders');
                this.timesheetService.sendReminders(selectedDate).subscribe(
                    (result) => {
                        this.sendingReminders = false;
                        this.logger.debug('Succesfully sent reminders');
                        this.toastr.success('Reminders where sent successfully.');
                    },
                    (err) => {
                        this.sendingReminders = false;
                        this.logger.error('Failed to send reminders');
                        this.toastr.error('Oops, something went wrong. Please try again later.');
                    },
                );
            }),
        );
    }

    openCronjobSettings() {
        this.logger.debug('Opening cronjobRulesSettingsModal');
        const modalRef = this.modalService.open(CronjobRulesSettingsModalComponent, {
            windowClass: 'modal-pane',
            animation: false,
        });
    }

    deleteTimesheet(timesheet: Timesheet) {
        this.logger.debug('starting remove timesheet flow');
        const modalRef = this.modalService.open(ConfirmationModalComponent, {
            windowClass: 'modal-prompt',
            animation: false,
        });
        modalRef.componentInstance.type = 'Delete';
        modalRef.componentInstance.title = 'Delete timesheet';
        modalRef.componentInstance.message =
            'Are you sure you want to delete ' + timesheet.employee.firstName + "'s timesheet? \n All linked timeslots will be removed allong with it.";

        this.subscriptions.add(
            modalRef.componentInstance.closeEvent.subscribe((val) => {
                if (val) {
                    this.logger.debug('attempting to remove timesheet');
                    this.store.dispatch(new RemoveTimesheet(timesheet.uuid)).subscribe(
                        () => {
                            this.logger.debug('successfully removed timesheet');
                            this.toastr.info('Timesheet was removed successfully.');
                        },
                        (error) => {
                            this.logger.error(error);
                            this.toastr.error('Oops, something went wrong please try again later.');
                        },
                    );
                }
            }),
        );
    }

    changeStatus(status: string, timesheet: Timesheet) {
        this.subscriptions.add(
            this.store.dispatch(new ChangeTimesheetStatus(timesheet.uuid, TIMESHEET_STATUS.getStatus(status))).subscribe(
                () => {
                    this.logger.debug('Succesfully updated timesheet');
                },
                (error) => {
                    this.logger.error('Failed to update timesheet');
                },
            ),
        );
    }

    copyUrlToClipboard(timesheet: Timesheet) {
        this.clipboard.copy(timesheet.url);
    }

    sendReminder(timesheet: Timesheet) {
        this.subscriptions.add(this.timesheetService.sendReminder(timesheet.uuid).subscribe());
    }

    getTotalMinutes = Timesheet.getTotalMinutes;
    getTotalDaysFromTimesheet = Timesheet.getTotalDaysFromTimesheet;
    getTotalDaysFromMinutes = Timesheet.getTotalDaysFromMinutes;
    getRequiredHoursDiff = Timesheet.getRequiredHoursDiff;
    getRequiredHoursDiffPercentage = Timesheet.getRequiredHoursDiffPercentage;

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
        this.uniqueProjects = [];
    }

    openTimesheetDetailModal(timesheet: Timesheet) {
        const modalRef = this.modalService.open(TimesheetInfoModalComponent, {
            windowClass: 'modal-pane',
            animation: false,
        });
        modalRef.componentInstance.timesheet = timesheet;
    }

    openInvoiceDialog(timeslots: Timeslot[]): void {
        const dialogRef = this.dialog.open(InvoiceDialogComponent, {
            width: '600px',
            data: {
                projectTimeslots: timeslots,
                timesheets: this.timesheets,
                uniqueProjects: this.uniqueProjects,
            },
        });

        dialogRef.afterClosed().subscribe((selectedTimeslots: Timeslot[]) => {
            if (selectedTimeslots && selectedTimeslots.length > 0) {
                selectedTimeslots.forEach((slot) => {
                    console.log(slot);
                    const timesheetUUID = slot.timesheet?.uuid;
                    this.timesheetService.setTimeslotToIsBilled(slot.uuid).subscribe();
                    this.store.dispatch(new SetTimeslotToIsBilled(timesheetUUID, slot.uuid, true));
                });
            }
        });
    }
}
