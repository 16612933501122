<div
    cdkDrag
    cdkDragRootElement=".modal-prompt">
    <div class="modal-header">
        <h2 class="modal-title">Sub links (used for financial graphs)</h2>

        <button
            (click)="activeModal.dismiss()"
            aria-label="Close"
            class="close"
            type="button">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <section>
            <p class="sectiontitle sectiontitle--first">Create new sub links</p>

            <div class="d-flex">
                <div>
                    <input
                        (change)="updateLinkType($event.target.value)"
                        checked
                        class="radio-btn"
                        id="project"
                        name="linkTypes"
                        type="radio"
                        value="project" />
                    <label
                        class="radio-label button"
                        for="project"
                        >Project</label
                    >
                </div>
                <div>
                    <input
                        (change)="updateLinkType($event.target.value)"
                        class="radio-btn"
                        id="employee"
                        name="linkTypes"
                        type="radio"
                        value="employee" />
                    <label
                        class="radio-label button"
                        for="employee"
                        >Employee</label
                    >
                </div>
                <div>
                    <input
                        (change)="updateLinkType($event.target.value)"
                        class="radio-btn"
                        id="budget"
                        name="linkTypes"
                        type="radio"
                        value="budget" />
                    <label
                        class="radio-label button"
                        for="budget"
                        >Budget</label
                    >
                </div>
                <div>
                    <input
                        (change)="updateLinkType($event.target.value)"
                        class="radio-btn"
                        id="overheadCategory"
                        name="linkTypes"
                        type="radio"
                        value="overheadCategory" />
                    <label
                        class="radio-label button"
                        for="overheadCategory"
                        >Overhead</label
                    >
                </div>
            </div>
        </section>
        <section class="m-t-4">
            <form [formGroup]="linkFileForm">
                <ng-autocomplete
                    (selected)="addLinkFile($event)"
                    [data]="shownLinkedFiles | orderBy: 'displayName':false"
                    [itemTemplate]="itemTemplate"
                    [notFoundTemplate]="notFoundTemplate"
                    [searchKeyword]="'displayName'"
                    formControlName="object"
                    placeholder="Choose your object...">
                </ng-autocomplete>

                <ng-template
                    #itemTemplate
                    let-item>
                    <a [innerHTML]="item.displayName"></a>
                </ng-template>

                <ng-template
                    #notFoundTemplate
                    let-notFound>
                    <div>No objects found...</div>
                </ng-template>
            </form>
        </section>

        <div
            *ngIf="isSaving || isDeleting"
            class="row">
            <div class="row d-flex align-items-center">
                <app-loading-message class="w-auto"></app-loading-message>
                <p
                    *ngIf="isSaving"
                    class="form-helptext w-auto m-l-2">
                    Linking in process...
                </p>
                <p
                    *ngIf="isDeleting"
                    class="form-helptext w-auto m-l-2">
                    Deleting link in process...
                </p>
            </div>
        </div>

        <section>
            <p
                [ngClass]="{ 'sectiontitle--first': isSaving || isDeleting }"
                class="sectiontitle">
                Already created sub links
            </p>
            <table
                [dataSource]="existingLinksDatasource"
                mat-table>
                <ng-container matColumnDef="object">
                    <th
                        *matHeaderCellDef
                        mat-header-cell>
                        Object
                    </th>
                    <td
                        *matCellDef="let row"
                        mat-cell>
                        <app-pill-overhead-category
                            *ngIf="row.objectType.toLowerCase() === 'overheadCategory'.toLowerCase()"
                            [overheadCategoryId]="row.objectId">
                        </app-pill-overhead-category>
                        <app-pill-employee
                            *ngIf="row.objectType.toLowerCase() === 'employee'.toLowerCase()"
                            [employeeId]="row.objectId">
                        </app-pill-employee>
                        <app-pill-project
                            *ngIf="row.objectType.toLowerCase() === 'project'.toLowerCase()"
                            [projectUuid]="row.objectId">
                        </app-pill-project>
                        <app-pill-budget
                            *ngIf="row.objectType.toLowerCase() === 'budget'.toLowerCase()"
                            [budgetUuid]="row.objectId">
                        </app-pill-budget>
                    </td>
                </ng-container>

                <ng-container matColumnDef="amount">
                    <th
                        *matHeaderCellDef
                        mat-header-cell>
                        Amount
                    </th>
                    <td
                        *matCellDef="let row"
                        class="td-icon"
                        mat-cell>
                        <div class="input--currency">
                            <input
                                (click)="$event.stopPropagation()"
                                (focusout)="updateLinkAmount(row, $event.target.value)"
                                (input)="inputEvent()"
                                [value]="row.amount"
                                type="number" />
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="remove">
                    <th
                        *matHeaderCellDef
                        class="td-small"
                        mat-header-cell></th>
                    <td
                        *matCellDef="let row"
                        class="td-icon"
                        mat-cell>
                        <button
                            (click)="$event.stopPropagation(); deleteExistingLink(row)"
                            class="button button--icon button--small">
                            <i class="las la-trash color--red"></i>
                        </button>
                    </td>
                </ng-container>

                <tr
                    *matHeaderRowDef="displayedColumns"
                    mat-header-row></tr>
                <tr
                    *matRowDef="let row; columns: displayedColumns"
                    mat-row></tr>
                <tr *matNoDataRow>
                    <td colspan="3">No existing sub links yet...</td>
                </tr>
            </table>

            <div class="m-t-4">
                <p
                    *ngIf="unsavedChanges"
                    class="form-error">
                    There are unsaved changes...
                </p>

                <!--
                Button with no event to remove focus from input fields when user clicks
                focusout event will be fired and changes will be saved
                -->
                <div class="d-flex align-items-center">
                    <button
                        class="button button--primary m-r-4"
                        type="button">
                        Save changes
                    </button>
                    <app-loading-message *ngIf="isUpdating"></app-loading-message>
                </div>
            </div>
        </section>
    </div>
</div>
