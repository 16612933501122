import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbDropdownModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { SharedModule } from '../shared/shared.module';
import { WidgetsModule } from '../widgets/widgets.module';
import { ThermostarConfiguratorComponent } from './thermostar-configurator.component';

@NgModule({
    declarations: [ThermostarConfiguratorComponent],
    imports: [CommonModule, SharedModule, WidgetsModule, FormsModule, NgbModule, NgbDropdownModule],
})
export class ThermostarConfiguratorModule {}
