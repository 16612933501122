import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { append, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { GlobalTag } from 'src/app/models/GlobalTag';

import {
    AddGlobalTagToMultipleObjects,
    AddGlobalTagToObject,
    AddUndefinedObjectTag,
    AddUndefinedObjectTagsToObject,
    FetchGlobalTagTaggedObjects,
    FetchGlobalTags,
    RemoveGlobalTagFromMultipleObjects,
    RemoveGlobalTagFromObject,
    RemoveUndefinedObjectTag,
    ResetUndefinedObjectTags,
} from './global-tags.actions';
import { GlobalTagsService } from './global-tags.service';

export interface GlobalTagsStateModel {
    globalTags: GlobalTag[];
    undefinedObjectTags: GlobalTag[][];
}

@State<GlobalTagsStateModel>({
    name: 'globalTag',
    defaults: {
        globalTags: null,
        undefinedObjectTags: [],
    },
})
@Injectable()
export class GlobalTagsState {
    @Selector()
    static GlobalTags(state: GlobalTagsStateModel): GlobalTag[] {
        return state?.globalTags;
    }

    @Selector()
    static UndefinedObjectTags(state: GlobalTagsStateModel): GlobalTag[][] {
        return state?.undefinedObjectTags;
    }

    constructor(private globalTagsService: GlobalTagsService) {}

    @Action(FetchGlobalTags)
    fetch(ctx: StateContext<GlobalTagsStateModel>) {
        return this.globalTagsService.getAllGlobalTags().subscribe((globalTags) => {
            ctx.setState(
                patch<GlobalTagsStateModel>({
                    globalTags: globalTags,
                }),
            );
        });
    }

    @Action(FetchGlobalTagTaggedObjects)
    getTaggedObjects(ctx: StateContext<GlobalTagsStateModel>, action: FetchGlobalTagTaggedObjects) {
        return this.globalTagsService.getGlobalTagTaggedObjects(action.tag.uuid).subscribe((taggedObjects) => {
            if (!ctx.getState().globalTags.find((tag) => tag.uuid == action.tag.uuid)) {
                ctx.setState(
                    patch<GlobalTagsStateModel>({
                        globalTags: append([action.tag]),
                    }),
                );
            }
            ctx.setState(
                patch<GlobalTagsStateModel>({
                    globalTags: updateItem<GlobalTag>(
                        (tag) => tag.uuid == action.tag.uuid,
                        patch<GlobalTag>({
                            taggables: taggedObjects,
                        }),
                    ),
                }),
            );
        });
    }

    @Action(AddGlobalTagToObject)
    addGlobalTagToObject(ctx: StateContext<GlobalTagsStateModel>, action: AddGlobalTagToObject) {
        return this.globalTagsService.addGlobalTagToObject(action.tag, action.objectUuid).subscribe((tag) => {
            if (tag) {
                this.getTaggedObjects(ctx, new FetchGlobalTagTaggedObjects(tag));
            }
        });
    }

    @Action(AddGlobalTagToMultipleObjects)
    addGlobalTagToMultipleObjects(ctx: StateContext<GlobalTagsStateModel>, action: AddGlobalTagToMultipleObjects) {
        return this.globalTagsService.addGlobalTagToMultipleObjects(action.tag, action.objectUuids).subscribe((tag) => {
            if (tag) {
                this.getTaggedObjects(ctx, new FetchGlobalTagTaggedObjects(tag));
            }
        });
    }

    @Action(RemoveGlobalTagFromObject)
    removeGlobalTagFromObject(ctx: StateContext<GlobalTagsStateModel>, action: RemoveGlobalTagFromObject) {
        return this.globalTagsService.removeGlobalTagFromObject(action.tag, action.objectUuid).subscribe((result) => {
            if (result) {
                ctx.setState(
                    patch<GlobalTagsStateModel>({
                        globalTags: updateItem<GlobalTag>(
                            (t) => t.uuid == action.tag.uuid,
                            patch<GlobalTag>({
                                taggables: removeItem((taggable) => taggable.uuid == action.objectUuid),
                            }),
                        ),
                    }),
                );
            }
        });
    }

    @Action(RemoveGlobalTagFromMultipleObjects)
    removeGlobalTagFromMultipleObjects(ctx: StateContext<GlobalTagsStateModel>, action: RemoveGlobalTagFromMultipleObjects) {
        return this.globalTagsService.removeGlobalTagFromMultipleObjects(action.tag, action.objectUuids).subscribe((result) => {
            if (result) {
                action.objectUuids.forEach((uuid) => {
                    ctx.setState(
                        patch<GlobalTagsStateModel>({
                            globalTags: updateItem<GlobalTag>(
                                (t) => t.uuid == action.tag.uuid,
                                patch<GlobalTag>({
                                    taggables: removeItem((taggable) => taggable.uuid == uuid),
                                }),
                            ),
                        }),
                    );
                });
            }
        });
    }

    @Action(AddUndefinedObjectTagsToObject)
    addUndefinedObjectTagsToObject(ctx: StateContext<GlobalTagsStateModel>, action: AddUndefinedObjectTagsToObject) {
        const tags = ctx.getState().undefinedObjectTags[ctx.getState().undefinedObjectTags.length - 1];
        if (tags) {
            tags.forEach((tag) => {
                this.addGlobalTagToObject(ctx, {
                    tag: tag,
                    objectUuid: action.objectUuid,
                });
            });
            this.resetUndefinedObjectTags(ctx);
        }
    }

    @Action(AddUndefinedObjectTag)
    addUndefinedObjectTag(ctx: StateContext<GlobalTagsStateModel>, action: AddUndefinedObjectTag) {
        const newUndefinedObjectTagsCopy = JSON.parse(JSON.stringify(ctx.getState().undefinedObjectTags));
        if (newUndefinedObjectTagsCopy[action.key] == undefined) {
            newUndefinedObjectTagsCopy.push([action.tag]);
        } else {
            newUndefinedObjectTagsCopy[action.key] = [...newUndefinedObjectTagsCopy[action.key], action.tag];
        }
        return ctx.setState(
            patch<GlobalTagsStateModel>({
                undefinedObjectTags: newUndefinedObjectTagsCopy,
            }),
        );
    }

    @Action(RemoveUndefinedObjectTag)
    removeUndefinedObjectTag(ctx: StateContext<GlobalTagsStateModel>, action: RemoveUndefinedObjectTag) {
        let newUndefinedObjectTagsCopy = JSON.parse(JSON.stringify(ctx.getState().undefinedObjectTags));
        newUndefinedObjectTagsCopy = newUndefinedObjectTagsCopy[action.key].filter((o) => o.title != action.tag.title);
        return ctx.setState(
            patch<GlobalTagsStateModel>({
                undefinedObjectTags: newUndefinedObjectTagsCopy,
            }),
        );
    }

    @Action(ResetUndefinedObjectTags)
    resetUndefinedObjectTags(ctx: StateContext<GlobalTagsStateModel>) {
        const newUndefinedObjectTagsCopy = JSON.parse(JSON.stringify(ctx.getState().undefinedObjectTags));
        newUndefinedObjectTagsCopy.pop();
        return ctx.setState(
            patch<GlobalTagsStateModel>({
                undefinedObjectTags: newUndefinedObjectTagsCopy,
            }),
        );
    }
}
