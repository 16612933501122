import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import FileSystemEntity from 'src/app/models/FileSystemEntity';

import { AddDirectory, AddDocument, AddRootDirectory } from '../documents.actions';
import { DocumentState } from '../documents.state';

@Component({
    selector: 'app-documents-add-modal',
    templateUrl: './documents-add-modal.component.html',
    styleUrls: ['./documents-add-modal.component.scss'],
})
export class DocumentsAddModalComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Select(DocumentState.lastAddedDocument)
    lastAddedDocument$: Observable<FileSystemEntity>;
    @Input() currentDirId: string;
    @Input() content: FileSystemEntity[];
    @Input() type: string;
    fileSystemEntityFormGroup: FormGroup;

    constructor(
        private fb: FormBuilder,
        private router: Router,
        public activeModal: NgbActiveModal,
        private store: Store,
        private toastr: ToastrService,
        private logger: NGXLogger,
    ) {}

    ngOnInit(): void {
        this.fileSystemEntityFormGroup = this.fb.group({
            name: ['', [Validators.required]],
        });
    }

    submit() {
        if (this.type == 'Dir') {
            if (this.currentDirId == '') {
                this.logger.debug('Attempt to add rootDirectory');
                this.subscriptions.add(
                    this.store.dispatch(new AddRootDirectory(this.fileSystemEntityFormGroup.get('name').value)).subscribe(
                        (res) => {
                            this.logger.debug('Succesfully added rootFolder');
                            this.toastr.success('Folder was successfully added.');
                        },
                        (err) => {
                            this.logger.error('Failed to add rootFolder');
                            this.toastr.error('Oops, something went wrong.\nPlease try again later.');
                        },
                    ),
                );
            } else {
                this.logger.debug('Attempt add directory');
                this.subscriptions.add(
                    this.store.dispatch(new AddDirectory(this.currentDirId, this.fileSystemEntityFormGroup.get('name').value)).subscribe(
                        (res) => {
                            this.logger.debug('Succesfully added a directory');
                            this.toastr.success('Folder was successfully added.');
                        },
                        (err) => {
                            this.logger.error('Failed to add a directory');
                            this.toastr.error('Oops, something went wrong.\nPlease try again later.');
                        },
                    ),
                );
            }
            this.activeModal.close();
        } else {
            this.logger.debug('Attempt to add document');
            this.subscriptions.add(
                this.store.dispatch(new AddDocument(this.currentDirId, this.fileSystemEntityFormGroup.get('name').value)).subscribe(
                    (res) => {
                        this.logger.debug('Succesfully added document');
                        this.toastr.success('Document created.');
                        this.lastAddedDocument$.subscribe((doc) => {
                            this.logger.debug('Navigating to documentDetails');
                            this.router.navigate(['/documents', 'detail', doc.uuid]);
                            this.activeModal.close();
                        });
                    },
                    (err) => {
                        this.logger.error('Failed to add document');
                        this.toastr.error('Oops, something went wrong.');
                        this.activeModal.close();
                    },
                ),
            );
        }
    }

    checkValid(): boolean {
        if (this.content.find((d) => d.name == this.fileSystemEntityFormGroup.get('name').value)) {
            return false;
        }
        return true;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
