<form
    (ngSubmit)="createDocumentAndLink()"
    [formGroup]="fileUploadForm">
    <div class="modal-header">
        <h2 class="modal-title">Upload file</h2>

        <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="activeModal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <description-input formControlName="description"></description-input>

        <div class="row">
            <div class="col">
                <label>Attachment</label>
                <span class="form-helptext">Supported file types: .pdf</span>
                <span *ngIf="document && document.url">
                    <app-file-upload
                        *ngIf="document && document.url"
                        type="file"
                        formControlName="url"
                        [existingFileUrl]="document.url">
                    </app-file-upload>
                    <app-file-upload
                        *ngIf="document.url && document.url === ''"
                        type="file"
                        formControlName="url">
                    </app-file-upload>
                </span>
                <app-file-upload
                    *ngIf="!document || !document.url"
                    type="file"
                    formControlName="url">
                </app-file-upload>
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <input
            class="button button--primary"
            type="submit"
            value="Save"
            [disabled]="fileUploadForm.invalid" />
        <button
            class="button button--outline"
            (click)="activeModal.dismiss()">
            Cancel
        </button>
    </div>
</form>
