import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

import { AuthenticationService } from '../authentication/authentication.service';
import FileSystemEntity from '../models/FileSystemEntity';

@Injectable({
    providedIn: 'root',
})
export class DocumentService {
    httpOptions = {};

    constructor(private httpClient: HttpClient, private authenticationService: AuthenticationService) {
        this.httpOptions = authenticationService.getHeaders();
    }

    getRootDirectory() {
        return this.httpClient.get<FileSystemEntity[]>(`${environment.baseUrl}/fileSystem/root`, this.httpOptions);
    }

    addRootDirectory(name: string) {
        return this.httpClient.post<FileSystemEntity>(`${environment.baseUrl}/fileSystem/root`, name, this.httpOptions);
    }

    addDirectory(parentId: string, name: string) {
        return this.httpClient.post<FileSystemEntity>(`${environment.baseUrl}/fileSystem/directory/${parentId}`, name, this.httpOptions);
    }

    getDirectory(directoryId: string) {
        return this.httpClient.get<FileSystemEntity>(`${environment.baseUrl}/fileSystem/getById/${directoryId}`, this.httpOptions);
    }

    getDocument(documentId: string) {
        return this.httpClient.get<FileSystemEntity>(`${environment.baseUrl}/fileSystem/document/${documentId}`, this.httpOptions);
    }

    getDirectoryContent(directoryId: string) {
        return this.httpClient.get<FileSystemEntity[]>(`${environment.baseUrl}/fileSystem/content/${directoryId}`, this.httpOptions);
    }

    filterFilesAndDirs(filter: string) {
        return this.httpClient.get<FileSystemEntity[]>(`${environment.baseUrl}/fileSystem/filter/${filter}`, this.httpOptions);
    }

    addDocument(parentId: string, name: string) {
        return this.httpClient.post<FileSystemEntity>(`${environment.baseUrl}/fileSystem/document/${parentId}`, name, this.httpOptions);
    }

    updateDirectory(directoryId: string, name: string) {
        return this.httpClient.put<FileSystemEntity>(`${environment.baseUrl}/fileSystem/directory/${directoryId}`, name, this.httpOptions);
    }

    updateDocument(documentId: string, name: string, content: string) {
        return this.httpClient.put<FileSystemEntity>(`${environment.baseUrl}/fileSystem/document/${documentId}/${name}`, content, this.httpOptions);
    }

    deleteFileSystemEntity(id: string) {
        return this.httpClient.delete<boolean>(`${environment.baseUrl}/fileSystem/${id}`, this.httpOptions);
    }

    getRelations(documentId: string) {
        return this.httpClient.get<any[]>(`${environment.baseUrl}/fileSystem/relations/${documentId}`, this.httpOptions);
    }

    getDocumentPdf(documentUuid) {
        return this.httpClient.get<any>(`${environment.baseUrl}/fileSystem/document/${documentUuid}/pdf`, this.httpOptions);
    }

    getStructure() {
        return this.httpClient.get<any>(`${environment.baseUrl}/fileSystem/structure`, this.httpOptions);
    }
}
