<div class="modal-header">
    <h2
        class="modal-title"
        id="exampleModalLabel">
        Edit deal
    </h2>
    <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div
    class="modal-body"
    *ngIf="isLoading">
    <app-loading-message></app-loading-message>
</div>
<form
    [formGroup]="dealForm"
    class="modal-wrapper"
    *ngIf="!isLoading">
    <div class="modal-body">
        <div class="row">
            <div class="col">
                <div class="form-group m-b-2">
                    <label for="title">Title </label>
                    <small
                        class="form-error"
                        *ngIf="!dealForm.get('title').valid && dealForm.get('title').touched"
                        >please enter a title</small
                    >
                    <input
                        id="title"
                        formControlName="title"
                        type="text"
                        placeholder="Enter a deal title" />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="form-group m-b-2">
                    <label for="price">Price </label>
                    <small
                        class="form-error"
                        *ngIf="!dealForm.get('price').valid && dealForm.get('price').touched"
                        >please enter price</small
                    >
                    <input
                        id="price"
                        formControlName="price"
                        type="number"
                        placeholder="Enter the price" />
                </div>
            </div>
        </div>
        <div
            class="row"
            *ngIf="this.checkModuleActive('PARTNERS')">
            <div class="col">
                <app-global-autocomplete
                    [parentForm]="dealForm"
                    [formControlName]="'company'"
                    [data]="partners"
                    [itemType]="'company'"
                    [label]="'Company'"
                    [notFoundMessage]="'Add a company'"
                    [keyword]="'title'"
                    [placeholder]="'Choose a company'"
                    [active]="this.checkModuleActive('PARTNERS')"
                    [required]="false"></app-global-autocomplete>
            </div>
        </div>
        <div
            class="row"
            *ngIf="this.checkModuleActive('CONTACTS')">
            <div class="col">
                <app-global-autocomplete
                    [parentForm]="dealForm"
                    [formControlName]="'contactPerson'"
                    [data]="filteredContacts"
                    [itemType]="'contact'"
                    [label]="'Contactperson'"
                    [notFoundMessage]="'Add a contact'"
                    [keyword]="'fullName'"
                    [placeholder]="'Choose a contact'"
                    [active]="this.checkModuleActive('CONTACTS')"
                    [required]="false"></app-global-autocomplete>
            </div>
        </div>
        <div
            class="row"
            *ngIf="this.checkModuleActive('CUSTOMERS')">
            <div class="col">
                <app-global-autocomplete
                    [parentForm]="dealForm"
                    [formControlName]="'customer'"
                    [data]="customers$ | async"
                    [itemType]="'customer'"
                    [label]="'Customer'"
                    [notFoundMessage]="'Add a customer'"
                    [keyword]="'firstName'"
                    [placeholder]="'Choose a customer'"
                    [active]="this.checkModuleActive('CUSTOMERS')"
                    [required]="false"></app-global-autocomplete>
            </div>
        </div>
        <div
            class="row"
            *ngIf="this.checkModuleActive('COMANAGE') && (keyExists$ | async) && dealForm.get('comanageOffer')">
            <div class="col">
                <app-global-autocomplete
                    [parentForm]="dealForm"
                    [formControlName]="'comanageOffer'"
                    [data]="offers"
                    [label]="'Comanage Offer'"
                    [notFoundMessage]="'No offers found'"
                    [keyword]="'title'"
                    [placeholder]="'Choose a comanage offer'"
                    [active]="this.checkModuleActive('COMANAGE')"
                    [required]="false"></app-global-autocomplete>
            </div>
        </div>

        <div class="form-group m-b-2">
            <label>Tags</label>
            <app-global-tags
                [type]="'deal'"
                [objectUuid]="deal.uuid"></app-global-tags>
        </div>
        <div class="row">
            <div class="col">
                <div class="form-group m-b-2">
                    <label>Description</label>
                    <textarea
                        class="resize-vertical"
                        formControlName="description"
                        (keyup)="onDescriptionKeyUp()"
                        trim="blur"></textarea>
                    <small> {{ currentAmountOfExtraInfoCharacters }}/{{ descriptionMaxLength }} </small>
                    <span
                        class="form-error"
                        *ngIf="descriptionTooLong"
                        >Extra info cannot contain more than 1000 characters
                    </span>
                </div>
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <button
            type="button"
            class="button button--outline"
            (click)="activeModal.close()">
            Close
        </button>
        <button
            type="button"
            (click)="update()"
            class="button button--primary">
            Save changes
        </button>
    </div>
</form>
