import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';
import { Password } from 'src/app/models/Password';

import { UpdatePassword } from '../user.actions';

@Component({
    selector: 'app-user-change-password-modal',
    templateUrl: './user-change-password-modal.component.html',
    styleUrls: ['./user-change-password-modal.component.scss'],
})
export class UserChangePasswordModalComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    password: Password;
    passwordFormGroup: FormGroup;
    newPassword = '';
    passwordRepeat = '';

    loading = false;
    inputIsWrong = false;
    passwordsDontMatch = false;
    updatePasswordWentWrong = false;
    errorMessage = false;

    constructor(public activeModal: NgbActiveModal, private store: Store, private formBuilder: FormBuilder, private logger: NGXLogger) {}

    ngOnInit(): void {
        this.passwordFormGroup = this.formBuilder.group({
            passCheck: ['', [Validators.minLength(8), Validators.required]],
            newPassword: ['', [Validators.minLength(8), Validators.required]],
            oldPassword: ['', [Validators.minLength(8), Validators.required]],
        });
    }

    updatePassword() {
        this.loading = true;

        if (!this.passwordFormGroup.valid) {
            this.inputIsWrong = true;
            this.loading = false;
            return;
        }

        if (!(this.passwordFormGroup.controls.newPassword.value === this.passwordFormGroup.controls.passCheck.value)) {
            this.passwordsDontMatch = true;
            this.loading = false;
            return;
        }

        this.passwordFormGroup.disable();

        this.password = this.passwordFormGroup.value;

        this.logger.debug('Attempt to update password');
        this.subscriptions.add(
            this.store.dispatch(new UpdatePassword(this.password)).subscribe(
                () => {
                    this.logger.debug('Succesfully updated password');
                    this.activeModal.close();
                },
                (error) => {
                    this.logger.error('Failed to update password');
                    this.errorMessage = error?.error?.message ?? 'Error message was empty';

                    this.loading = false;
                    this.passwordFormGroup.enable();

                    this.updatePasswordWentWrong = true;
                },
            ),
        );
    }

    onNewPasswordChange() {
        this.newPassword = this.passwordFormGroup.controls.newPassword.value;
    }

    onPasswordRepeatChange() {
        this.passwordRepeat = this.passwordFormGroup.controls.passCheck.value;
    }

    // Hides the error messages that appear after a user has submitted invalid data
    hideErrorMessage() {
        this.inputIsWrong = false;
        this.passwordsDontMatch = false;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
