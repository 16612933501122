import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import FileSystemEntity from 'src/app/models/FileSystemEntity';

import { DocumentsListService } from './documents-list.service';
import { LinkDocumentModalComponent } from './link-document-modal/link-document-modal.component';

@Component({
    selector: 'app-documents-list',
    templateUrl: './documents-list.component.html',
    styleUrls: ['./documents-list.component.scss'],
})
export class DocumentsListComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();
    @Input() objectId: string;
    @Input() objectType: string;

    allFiles: Observable<FileSystemEntity[]>;

    documents: FileSystemEntity[];
    isLoading = true;

    constructor(private documentsListService: DocumentsListService, private modalService: NgbModal, private toastr: ToastrService, private logger: NGXLogger) {}

    ngOnInit(): void {
        this.refresh();
        this.logger.debug('Fetching allFiles');
        this.allFiles = this.documentsListService.getAllFiles();
    }

    refresh() {
        let method: Observable<any>;
        switch (this.objectType) {
            case 'product':
                this.logger.debug('Fetching productDocs');
                method = this.documentsListService.getProductDocs(this.objectId);
                break;
            case 'project':
                this.logger.debug('Fetching projectDocs');
                method = this.documentsListService.getProjectDocs(this.objectId);
                break;
            case 'budget':
                this.logger.debug('Fetching projectDocs');
                method = this.documentsListService.getProjectDocs(this.objectId);
                break;
        }

        this.subscriptions.add(
            method.subscribe(
                (docs) => {
                    this.documents = docs;
                    this.isLoading = false;
                },
                (err) => {
                    console.log(err);
                },
            ),
        );
    }

    remove(docId: string, $event) {
        $event.stopPropagation();
        let method: Observable<any>;
        switch (this.objectType) {
            case 'product':
                method = this.documentsListService.deleteProductDocs(docId, this.objectId);
                break;
            case 'project':
                method = this.documentsListService.deleteProjectDocs(docId, this.objectId);
                break;
            case 'budget':
                method = this.documentsListService.deleteProjectDocs(docId, this.objectId);
                break;
        }

        this.subscriptions.add(
            method.subscribe(
                (result) => {
                    this.refresh();
                    this.toastr.success('Document successfully removed.');
                },
                (err) => {
                    this.toastr.error('Oops, something went wrong.\nPlease try again later.');
                },
            ),
        );
    }

    openAddModal() {
        this.logger.debug('Opening linkDocumentModal');
        const modalRef = this.modalService.open(LinkDocumentModalComponent, {
            windowClass: 'modal-prompt',
        });
        this.subscriptions.add(
            modalRef.componentInstance.submitEvent.subscribe((id) => {
                this.add(id);
            }),
        );
    }

    add(docId: string) {
        let method: Observable<any>;
        switch (this.objectType) {
            case 'product':
                this.logger.debug('Adding productDoc');
                method = this.documentsListService.addProductDocs(docId, this.objectId);
                break;
            case 'project':
                this.logger.debug('Adding projectDoc');
                method = this.documentsListService.addProjectDocs(docId, this.objectId);
                break;
            case 'budget':
                this.logger.debug('Adding projectDoc');
                method = this.documentsListService.addProjectDocs(docId, this.objectId);
                break;
        }

        this.subscriptions.add(
            method.subscribe(
                (result) => {
                    if (result == null) {
                        this.refresh();
                        this.logger.debug('Document is already linked.');
                        this.toastr.warning('Document is already linked.');
                    } else {
                        this.refresh();
                        this.logger.debug('Document successfully added.');
                        this.toastr.success('Document successfully added.');
                    }
                },
                (err) => {
                    this.logger.error('Failed to add document');
                    this.toastr.error('Oops, something went wrong.\nPlease try again later.');
                },
            ),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
