import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './authentication/auth.guard';
import { CompaniesModulesGuard } from './companies/companies.modules.guard';
import { CompanyGuard } from './companies/company.guard';
import { CrmOverviewComponent } from './crm/crm-overview/crm-overview.component';
import { DashboardComponent } from './dashboard/dashboard/dashboard.component';
import { ProductStockUpdateComponent } from './products/product-stock-update/product-stock-update.component';
import { OnboardingGuard } from './resumes/onboarding.guard';
import { OnboardingComponent } from './resumes/onboarding/onboarding.component';
import { CodeGuard } from './shared/code.guard';
import { ForbiddenPageComponent } from './shared/forbidden-page/forbidden-page.component';
import { HomeGuard } from './shared/home.guard';
import { HomepageComponent } from './shared/homepage/homepage.component';
import { NotFoundPageComponent } from './shared/not-found-page/not-found-page.component';
import { ThermostarConfiguratorComponent } from './thermostar-configurator/thermostar-configurator.component';
import { UpdateTimeslotComponent } from './timesheets/update-timeslot/update-timeslot.component';

const routes: Routes = [
    { path: '', redirectTo: 'home', pathMatch: 'full' },
    {
        path: 'home',
        component: HomepageComponent,
        canActivate: [HomeGuard, CodeGuard],
    },
    {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [AuthGuard, CompanyGuard],
    },
    {
        path: 'crm',
        component: CrmOverviewComponent,
        canActivate: [AuthGuard, CompanyGuard, CompaniesModulesGuard],
    },
    {
        path: '',
        loadChildren: () => import('./authentication/authentication.module').then((m) => m.AuthenticationModule),
    },
    {
        path: 'onboarding',
        component: OnboardingComponent,
        canActivate: [AuthGuard, OnboardingGuard],
    },
    {
        path: 'company',
        loadChildren: () => import('./companies/companies.module').then((m) => m.CompaniesModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'resume',
        loadChildren: () => import('./resumes/resumes.module').then((m) => m.ResumesModule),
        canActivate: [AuthGuard, CompanyGuard, CompaniesModulesGuard],
    },
    {
        path: 'settings',
        loadChildren: () => import('./settings/settings.module').then((m) => m.SettingsModule),
        canActivate: [AuthGuard, CompanyGuard, CompaniesModulesGuard],
    },
    {
        path: 'documents',
        loadChildren: () => import('./documents/documents.module').then((m) => m.DocumentsModule),
        canActivate: [AuthGuard, CompanyGuard, CompaniesModulesGuard],
    },
    {
        path: 'partners',
        children: [
            {
                path: '',
                loadChildren: () => import('./partners/partners.module').then((m) => m.PartnersModule),
            },
            {
                path: ':id',
                loadChildren: () => import('./partners/partnerdetails.module').then((m) => m.PartnerDetailsModule),
            },
        ],
        canActivate: [AuthGuard, CompanyGuard, CompaniesModulesGuard],
    },
    {
        path: 'contacts',
        loadChildren: () => import('./contacts/contacts.module').then((m) => m.ContactsModule),
        canActivate: [AuthGuard, CompanyGuard, CompaniesModulesGuard],
    },
    {
        path: 'customers',
        loadChildren: () => import('./customers/customer.module').then((m) => m.CustomersModule),
        canActivate: [AuthGuard, CompanyGuard, CompaniesModulesGuard],
    },
    {
        path: 'employees',
        loadChildren: () => import('./employees/employees.module').then((m) => m.EmployeesModule),
        canActivate: [AuthGuard, CompanyGuard, CompaniesModulesGuard],
    },
    {
        path: 'timesheets',
        loadChildren: () => import('./timesheets/timesheets.module').then((m) => m.TimesheetsModule),
        canActivate: [AuthGuard, CompanyGuard, CompaniesModulesGuard],
    },
    {
        path: 'budget',
        loadChildren: () => import('./budget/budget.module').then((m) => m.BudgetModule),
        canActivate: [AuthGuard, CompanyGuard, CompaniesModulesGuard],
    },
    {
        path: '',
        loadChildren: () => import('./applicants/applicants.module').then((m) => m.ApplicantsModule),
        canActivate: [AuthGuard, CompanyGuard, CompaniesModulesGuard],
    },
    {
        path: '',
        loadChildren: () => import('./projects/projects.module').then((m) => m.ProjectsModule),
        canActivate: [AuthGuard, CompanyGuard, CompaniesModulesGuard],
    },
    {
        path: 'planning',
        loadChildren: () => import('./planning/planning.module').then((m) => m.PlanningModule),
        canActivate: [AuthGuard, CompanyGuard, CompaniesModulesGuard],
    },
    {
        path: '',
        loadChildren: () => import('./leads/leads.module').then((m) => m.LeadsModule),
        canActivate: [AuthGuard, CompanyGuard, CompaniesModulesGuard],
    },
    {
        path: '',
        loadChildren: () => import('./products/products.module').then((m) => m.ProductsModule),
        canActivate: [AuthGuard, CompanyGuard, CompaniesModulesGuard],
    },
    {
        path: '',
        loadChildren: () => import('./warehouses/warehouses.module').then((m) => m.WarehousesModule),
        canActivate: [AuthGuard, CompanyGuard, CompaniesModulesGuard],
    },
    {
        path: '',
        loadChildren: () => import('./warehouse-locations/warehouse.locations.module').then((m) => m.WarehouseLocationsModule),
        canActivate: [AuthGuard, CompanyGuard, CompaniesModulesGuard],
    },
    {
        path: '',
        loadChildren: () => import('./units/units.module').then((m) => m.UnitsModule),
        canActivate: [AuthGuard, CompanyGuard],
    },
    {
        path: '',
        loadChildren: () => import('./calendar/calendar.module').then((m) => m.CalendarModule),
        canActivate: [AuthGuard, CompanyGuard],
    },
    {
        path: 'thermostar-configurator',
        component: ThermostarConfiguratorComponent,
        canActivate: [AuthGuard, CompanyGuard, CompaniesModulesGuard],
    },
    {
        path: 'mailchimp',
        loadChildren: () => import('./mailchimp/mailchimp.module').then((m) => m.MailchimpModule),
        canActivate: [AuthGuard, CompanyGuard, CompaniesModulesGuard],
    },
    {
        path: 'exact',
        loadChildren: () => import('./exact/exact.module').then((m) => m.ExactModule),
        canActivate: [AuthGuard, CompanyGuard, CompaniesModulesGuard],
    },
    {
        path: 'teamleader',
        loadChildren: () => import('./teamleader/teamleader.module').then((m) => m.TeamleaderModule),
        canActivate: [AuthGuard, CompanyGuard, CompaniesModulesGuard],
    },
    {
        path: 'comanage',
        loadChildren: () => import('./comanage/comanage.module').then((m) => m.ComanageModule),
        canActivate: [AuthGuard, CompanyGuard, CompaniesModulesGuard],
    },
    {
        path: 'jira',
        loadChildren: () => import('./jira/jira.module').then((m) => m.JiraModule),
        canActivate: [AuthGuard, CompanyGuard, CompaniesModulesGuard],
    },
    {
        path: 'velda-languages',
        loadChildren: () => import('./velda-languages/velda-languages.module').then((m) => m.VeldaLanguagesModule),
        canActivate: [AuthGuard, CompanyGuard, CompaniesModulesGuard],
    },
    {
        path: 'velda-articles',
        loadChildren: () => import('./velda-articles/velda-articles.module').then((m) => m.VeldaArticlesModule),
        canActivate: [AuthGuard, CompanyGuard, CompaniesModulesGuard],
    },
    {
        path: 'invoices',
        loadChildren: () => import('./yuki/yuki.module').then((m) => m.YukiModule),
        canActivate: [AuthGuard, CompanyGuard, CompaniesModulesGuard],
    },
    {
        path: 'financial',
        loadChildren: () => import('./financial/financial.module').then((m) => m.FinancialModule),
        canActivate: [AuthGuard, CompanyGuard, CompaniesModulesGuard],
    },
    {
        path: 'update-stock/:companyUuid/:productUuid',
        component: ProductStockUpdateComponent,
        canActivate: [AuthGuard, CompanyGuard],
    },
    {
        path: 'update-timeslots/:userUuid/:date/:token',
        component: UpdateTimeslotComponent,
    },
    { path: 'forbidden', component: ForbiddenPageComponent },
    { path: '**', component: NotFoundPageComponent, canActivate: [CodeGuard] },
];

@NgModule({
    //enableTracing: true => route debugging
    imports: [
        RouterModule.forRoot(routes, {
            useHash: true,
            enableTracing: false,
            relativeLinkResolution: 'legacy',
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
