import MailingObject from '../models/MailingObject';

export class FetchModules {
    static readonly type = '[Module] Fetch All Modules';
}

export class ActivateModule {
    static readonly type = '[Module] Active Company Module';
    constructor(public companyUuid: string, public moduleUuid: string) {}
}

export class FetchMailingObjects {
    static readonly type = '[MailingObjects] Fetch All MailingObjects';
}

export class AddMailToModule {
    static readonly type = '[Module] add Mail to Module';
    constructor(public moduleUuid: string, public mailingObject: MailingObject) {}
}

export class RemoveMailFromModule {
    static readonly type = '[Module] remove Mail form Module';
    constructor(public moduleUuid: string, public mailingObjectUuid: string) {}
}
