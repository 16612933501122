import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { CompanyState } from 'src/app/companies/company.state';
import { ModuleEnum } from 'src/app/models/ModuleEnum';
import { Warehouse } from 'src/app/models/Warehouse';
import { ModuleService } from 'src/app/services/module.service';
import { EmployeeAssignModalComponent } from 'src/app/shared/employee-assign-modal/employee-assign-modal.component';

import { FetchWarehouses } from '../warehouse.actions';
import { WarehouseState } from '../warehouse.state';

@Component({
    selector: 'app-warehouse-detail-modal',
    templateUrl: './warehouse-detail-modal.component.html',
    styleUrls: ['./warehouse-detail-modal.component.scss'],
})
export class WarehouseDetailModalComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();
    @Input() warehouseUuid: string;

    warehouse: Warehouse;
    eModule = ModuleEnum;
    isLoading = true;

    @Select(CompanyState.companyUuid) companyUuid$: Observable<string>;
    @Select(WarehouseState.Warehouses) warehouses$: Observable<Warehouse[]>;
    constructor(
        private modalService: NgbModal,
        public activeModal: NgbActiveModal,
        private store: Store,
        public moduleService: ModuleService,
        private logger: NGXLogger,
    ) {}

    ngOnInit(): void {
        this.logger.debug('Fetching warehouses');
        this.store.dispatch(new FetchWarehouses());
        this.subscriptions.add(
            this.warehouses$.subscribe((warehouses) => {
                this.warehouse = warehouses.find((w) => w.uuid == this.warehouseUuid);
                if (warehouses) {
                    this.isLoading = false;
                }
            }),
        );
    }

    openEmployeeAssignModal(object: any) {
        this.logger.debug('Opening employeeAssignModal');
        const modalRef = this.modalService.open(EmployeeAssignModalComponent);
        modalRef.componentInstance.object = object;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
