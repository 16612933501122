<form
    [formGroup]="passwordFormGroup"
    (ngSubmit)="updatePassword()"
    class="modal-wrapper">
    <div class="modal-header">
        <h2
            class="modal-title"
            id="exampleModalLabel">
            Change password
        </h2>
        <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="activeModal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <div class="form-group m-b-2">
            <password-input
                (focus)="hideErrorMessage()"
                [id]="'oldPassword'"
                [label]="'Old password'"
                [placeholder]="'Old password'"
                [showLabel]="true"
                formControlName="oldPassword">
            </password-input>
            <span
                class="form-error"
                *ngIf="passwordFormGroup.controls.oldPassword.errors && inputIsWrong"
                >The password needs to be at least 8 characters long</span
            >
        </div>
        <div class="form-group m-b-2">
            <password-input
                (focus)="hideErrorMessage()"
                (keyup)="onNewPasswordChange()"
                [id]="'newPassword'"
                [label]="'New password'"
                [placeholder]="'New password'"
                [showLabel]="true"
                formControlName="newPassword">
            </password-input>

            <password-strength-meter
                [colors]="['#E24600', 'orangered', 'orange', 'yellowgreen', '#26B305']"
                [password]="newPassword">
            </password-strength-meter>

            <span
                class="form-error"
                *ngIf="passwordFormGroup.controls.newPassword.errors && inputIsWrong"
                >The password needs to be at least 8 characters long</span
            >
        </div>
        <div class="form-group m-b-2">
            <password-input
                (focus)="hideErrorMessage()"
                (keyup)="onPasswordRepeatChange()"
                [id]="'passCheck'"
                [label]="'Repeat password'"
                [placeholder]="'Repeat password'"
                [showLabel]="true"
                formControlName="passCheck">
            </password-input>

            <password-strength-meter
                [colors]="['#E24600', 'orangered', 'orange', 'yellowgreen', '#26B305']"
                [password]="passwordRepeat">
            </password-strength-meter>
            <span
                class="form-error"
                *ngIf="passwordFormGroup.controls.passCheck.errors && inputIsWrong"
                >The password needs to be at least 8 characters long</span
            >
            <span
                class="form-error"
                *ngIf="passwordsDontMatch"
                >Doesn't look like a match...</span
            >
        </div>
    </div>

    <div class="modal-footer">
        <button
            type="button"
            class="button button--outline"
            (click)="activeModal.close()">
            Close
        </button>
        <button
            type="submit"
            [ngClass]="{ 'button--loading': loading }"
            class="button button--primary">
            Update
        </button>
        <span
            class="form-error"
            *ngIf="updatePasswordWentWrong"
            >{{ errorMessage }}</span
        >
    </div>
</form>
