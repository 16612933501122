<form
    [formGroup]="planningForm"
    (ngSubmit)="addPlanning()"
    class="modal-wrapper">
    <div class="modal-header">
        <h2
            class="modal-title"
            id="exampleModalLabel">
            {{ type }} planning
        </h2>
        <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="activeModal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <section>
            <div class="sectiontitle sectiontitle--first">Information</div>
            <div class="row">
                <div class="col">
                    <div class="form-group m-b-2 required">
                        <label>Choose project</label>
                        <div class="d-flex">
                            <div class="ng-autocomplete">
                                <ng-autocomplete
                                    [data]="projects"
                                    [itemTemplate]="projectTemplate"
                                    [notFoundTemplate]="projectNotFoundTemplate"
                                    formControlName="project"
                                    placeholder="Choose project"
                                    searchKeyword="title"></ng-autocomplete>

                                <ng-template
                                    #projectTemplate
                                    let-item>
                                    <a [innerHTML]="item.title"></a>
                                </ng-template>

                                <ng-template
                                    #projectNotFoundTemplate
                                    let-notFound>
                                    <div (click)="openCreateProjectModal()">+ Add new project</div>
                                </ng-template>
                            </div>
                            <button
                                type="button"
                                class="button button--outline button--icon m-l-2"
                                (click)="openCreateProjectModal()">
                                <i class="las la-plus"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <div class="form-group m-b-2 required">
                        <label>Choose employee</label>
                        <div class="d-flex">
                            <div class="ng-autocomplete">
                                <ng-autocomplete
                                    [data]="employees"
                                    [itemTemplate]="employeeTemplate"
                                    [notFoundTemplate]="employeeNotFoundTemplate"
                                    formControlName="employee"
                                    placeholder="Choose employee"
                                    searchKeyword="firstName"></ng-autocomplete>

                                <ng-template
                                    #employeeTemplate
                                    let-employee>
                                    <a [innerHTML]="employee.firstName + ' ' + employee.lastName"></a>
                                </ng-template>

                                <ng-template
                                    #employeeNotFoundTemplate
                                    let-notFound>
                                    <div (click)="openAddEmployeeModal()">+ Add new employee</div>
                                </ng-template>
                            </div>
                            <button
                                type="button"
                                class="button button--outline button--icon m-l-2"
                                (click)="openAddEmployeeModal()">
                                <i class="las la-plus"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <description-input formControlName="description"></description-input>
            </div>
        </section>

        <section>
            <div class="sectiontitle m-b-0">Date and time</div>

            <div
                class="form-helptext"
                *ngIf="plannedEvent">
                The old start- and end dates are prefilled. Because updating an event when events are passed, would result in deleting the passed events.
            </div>

            <div class="row">
                <div class="col">
                    <div class="form-group--toggle">
                        <label (click)="setAllDay()">
                            <span
                                class="toggle"
                                [ngClass]="planningForm.get('allDay').value ? 'toggle--active' : ''">
                            </span>
                            All day
                        </label>
                    </div>
                </div>
            </div>

            <div class="row align-items-end">
                <div class="col form-group">
                    <label for="startDate">From</label>
                    <input
                        type="date"
                        id="startDate"
                        formControlName="startDate" />
                </div>
                <div
                    class="col form-group"
                    *ngIf="!planningForm.get('allDay').value">
                    <input
                        type="time"
                        id="startTime"
                        formControlName="startTime" />
                </div>
            </div>

            <div class="row align-items-end">
                <div class="col form-group">
                    <label for="endDate">To</label>
                    <input
                        type="date"
                        id="endDate"
                        formControlName="endDate" />
                </div>
                <div
                    class="col form-group"
                    *ngIf="!planningForm.get('allDay').value">
                    <input
                        type="time"
                        id="endTime"
                        formControlName="endTime" />
                </div>
            </div>
        </section>

        <section>
            <div class="sectiontitle">Repeat options</div>
            <div>
                <label for="recurrenceRule">Rule</label>
                <select
                    formControlName="recurrenceRule"
                    (change)="openCreateCustomRecurrenceRule($event)"
                    class="select"
                    id="recurrenceRule">
                    <option
                        *ngFor="let rule of recurrenceRules"
                        [ngValue]="rule">
                        {{ rule.title }}
                    </option>
                </select>
            </div>
            <div *ngIf="planningForm.value.recurrenceRule.title !== 'Doesn\'t repeat'">
                <div
                    class="m-t-2"
                    formGroupName="recurring">
                    <label> Ends </label>

                    <div
                        class="row d-flew align-items-center m-b-2"
                        [ngClass]="{
                            disabled: planningForm.get('recurring.endsType').value !== 'never'
                        }">
                        <label
                            for="never"
                            class="d-flex align-items-center">
                            <input
                                (change)="updateEndsType()"
                                class="m-r-2"
                                type="radio"
                                value="never"
                                id="never"
                                formControlName="endsType" />
                            Never
                        </label>
                    </div>

                    <div
                        class="row d-flew align-items-center m-b-2"
                        [ngClass]="{
                            disabled: planningForm.get('recurring.endsType').value !== 'on'
                        }">
                        <label
                            for="on"
                            class="d-flex align-items-center">
                            <span class="col-md-4">
                                <input
                                    (change)="updateEndsType()"
                                    class="m-r-2"
                                    type="radio"
                                    value="on"
                                    id="on"
                                    formControlName="endsType" />
                                On
                            </span>
                            <div class="col-md-8">
                                <input
                                    type="date"
                                    formControlName="until"
                                    (click)="planningForm.get('recurring.endsType').setValue('on')"
                                    [readOnly]="planningForm.value.recurring.endsType !== 'on'" />
                            </div>
                        </label>
                    </div>

                    <div
                        class="row d-flew align-items-center m-b-2"
                        [ngClass]="{
                            disabled: planningForm.get('recurring.endsType').value !== 'after'
                        }">
                        <label
                            for="after"
                            class="d-flex align-items-center">
                            <span class="col-md-4">
                                <input
                                    (change)="updateEndsType()"
                                    class="m-r-2"
                                    type="radio"
                                    value="after"
                                    id="after"
                                    formControlName="endsType" />
                                After
                            </span>
                            <div class="col-md-2">
                                <input
                                    type="number"
                                    formControlName="count"
                                    (click)="planningForm.get('recurring.endsType').setValue('after')"
                                    [readOnly]="planningForm.value.recurring.endsType !== 'after'" />
                            </div>
                            <div class="col-md-6 m-l-2">occurences</div>
                        </label>
                    </div>
                </div>

                <div class="m-t-2">
                    <label> Exceptions </label>
                    <div class="form-helptext">Exceptions are dates an event won't happen.</div>
                    <div class="table__overflow">
                        <table class="table-view">
                            <tr>
                                <th>Date</th>
                                <th class="td-small"></th>
                            </tr>
                            <tr *ngFor="let exception of recurringExceptions; let i = index">
                                <td>{{ exception }}</td>
                                <td
                                    (click)="deleteException(i)"
                                    class="pointer">
                                    <i class="las la-trash color--red"></i>
                                </td>
                            </tr>
                            <tr>
                                <td
                                    colspan="2"
                                    class="table-view__add"
                                    (click)="addException()">
                                    <i class="las la-plus"></i> Add recurring exception
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </section>
    </div>

    <div class="modal-footer">
        <input
            class="button"
            type="submit"
            value="{{ type }} planning"
            [disabled]="planningForm.invalid"
            [ngClass]="planningForm.invalid ? 'button--disabled' : 'button--primary'" />
        <button
            class="button button--outline"
            (click)="activeModal.dismiss()">
            Cancel
        </button>
    </div>
</form>
