<app-app-holder>
    <app-navbar></app-navbar>

    <app-sidebar>
        <div class="sidebar-spacing">
            <h3>Calendar</h3>
            <small>Filter by company member</small>
        </div>

        <span class="divider divider--small"></span>

        <div class="app__subnav">
            <a
                (click)="selectMember(currMember)"
                [ngClass]="{
                    app__subnav__active: selectedMember.userUuid === currMember.userUuid
                }">
                <span class="app__subnav__content"> Me </span>
            </a>
            <a
                *ngFor="let member of getMembersWithoutCurrUser()"
                (click)="selectMember(member)"
                [ngClass]="{
                    app__subnav__active: selectedMember.userUuid === member.userUuid
                }">
                <span class="app__subnav__content"> {{ member.firstName }} {{ member.lastName }} </span>
            </a>
        </div>
    </app-sidebar>

    <app-content>
        <app-calendar-event-list [member]="selectedMember"></app-calendar-event-list>

        <div class="dashboard__tile--2">
            <full-calendar
                deepChangeDetection="true"
                [options]="calendarOptions"></full-calendar>
            <button (click)="toggleWeekends()">toggle weekends</button>
        </div>
    </app-content>

    <ng-template #loading>
        <app-loading-message></app-loading-message>
    </ng-template>
</app-app-holder>
