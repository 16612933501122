import CrmDeal from '../models/CrmDeal';
import CrmStage from '../models/CrmStage';

export class FetchCrmStages {
    static readonly type = '[CrmStage] Fetch CrmStages';
}

export class FetchCrmDeals {
    static readonly type = '[CrmDeals] Fetch CrmDeals';
}

export class FetchArchivedCrmDeals {
    static readonly type = '[CrmDeals] Fetch archived CrmDeals';
}

export class FetchReasons {
    static readonly type = '[CrmReasonOfLoss[]] Fetch Reasons';
}
export class AddReason {
    static readonly type = '[CrmReasonOfLoss] Add Reasons';
    constructor(public reason: string) {}
}
export class UpdateDealReasonOfLoss {
    static readonly type = '[CrmReasonOfLoss] Update Reasons';
    constructor(public reasonUuid: string, public reason: string) {}
}
export class RemoveDealReasonOfLoss {
    static readonly type = '[void] Remove Reasons';
    constructor(public reasonUuid: string) {}
}

export class AddCrmStage {
    static readonly type = '[CrmStage] Add CrmStage';
    constructor(public stage: CrmStage) {}
}

export class AddCrmDeal {
    static readonly type = '[CrmDeal] Add CrmDeal';
    constructor(public deal: CrmDeal) {}
}

export class MoveCrmStage {
    static readonly type = '[CrmStage] Move CrmStage';
    constructor(public stageUuid: string, public position: number) {}
}

export class MoveCrmDeal {
    static readonly type = '[CrmDeal] Move CrmDeal';
    constructor(public stageUuid: string, public dealUuid: string, public reason: string, public reasonUuid?: string) {}
}

export class UpdateCrmStage {
    static readonly type = '[CrmStage] Update CrmStage';
    constructor(public stage: CrmStage) {}
}

export class UpdateCrmDeal {
    static readonly type = '[CrmDeal] Update CrmDeal';
    constructor(public deal: CrmDeal) {}
}

export class DeleteCrmStage {
    static readonly type = '[Boolean] Delete CrmStage';
    constructor(public stageUuid: string) {}
}

export class DeleteCrmDeal {
    static readonly type = '[Boolean] Delete CrmDeal';
    constructor(public deal: CrmDeal) {}
}

export class ArchiveCrmDeal {
    static readonly type = '[Boolean] Archive CrmDeal';
    constructor(public deal: CrmDeal) {}
}

export class DearchiveCrmDeal {
    static readonly type = '[Boolean] Dearchive CrmDeal';
    constructor(public deal: CrmDeal) {}
}
