import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { DetailActivityComponent } from './detail-activity/detail-activity.component';
import { DetailContactpersonsComponent } from './detail-contactpersons/detail-contactpersons.component';
import { DetailCvsComponent } from './detail-cvs/detail-cvs.component';
import { DetailProjectsComponent } from './detail-projects/detail-projects.component';
import { DetailSettingsComponent } from './detail-settings/detail-settings.component';

export const routes: Routes = [
    { path: '', redirectTo: 'contactpersons', pathMatch: 'full' },
    { path: 'contactpersons', component: DetailContactpersonsComponent },
    { path: 'activity', component: DetailActivityComponent },
    { path: 'settings', component: DetailSettingsComponent },
    { path: 'cvs', component: DetailCvsComponent },
    { path: 'projects', component: DetailProjectsComponent },
];

export const routing: ModuleWithProviders<any> = RouterModule.forChild(routes);
