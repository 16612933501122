import { Component, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NGXLogger } from 'ngx-logger';
import { Language } from 'src/app/models/resume/Language';

import { LanguageDeleteModalComponent } from '../language-delete-modal/language-delete-modal.component';
import { LanguageDetailModalComponent } from '../language-detail-modal/language-detail-modal.component';

@Component({
    selector: 'app-resume-language-list',
    templateUrl: './resume-language-list.component.html',
    styleUrls: ['./resume-language-list.component.scss'],
})
export class ResumeLanguageListComponent {
    @Input() resumeId: string;
    @Input() languages: Language[];
    @Input() showActions = true;

    constructor(private modalService: NgbModal, private logger: NGXLogger) {}

    openLanguageAddModal() {
        this.logger.debug('Opening languageDetailModal');
        const modalRef = this.modalService.open(LanguageDetailModalComponent, { animation: false });
        modalRef.componentInstance.resumeUuid = this.resumeId;
    }

    openLanguageEditModal(language: Language) {
        this.logger.debug('Opening languageDetailModal');
        const modalRef = this.modalService.open(LanguageDetailModalComponent, { animation: false });
        modalRef.componentInstance.language = language;
    }

    openLanguageDeleteModal(language: Language) {
        this.logger.debug('Opening languageDetailModal');
        const modalRef = this.modalService.open(LanguageDeleteModalComponent, {
            windowClass: 'modal-prompt',
            animation: false,
        });
        modalRef.componentInstance.language = language;
    }
}
