import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subscription } from 'rxjs';
import { CompanyDetailModalComponent } from 'src/app/companies/company-detail-modal/company-detail-modal.component';
import { CompanyState } from 'src/app/companies/company.state';
import { CompanyInvitedMember } from 'src/app/models/CompanyInvitedMember';
import { Module } from 'src/app/models/Module';
import { AcceptCompanyInvite, GetUserInvites } from 'src/app/users/user.actions';
import { UserState } from 'src/app/users/user.state';

@Component({
    selector: 'app-resume-start',
    templateUrl: './onboarding.component.html',
    styleUrls: ['./onboarding.component.scss'],
})
export class OnboardingComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Select(UserState.userInvites) invites$: Observable<CompanyInvitedMember[]>;

    email: string;
    modules: Module[];

    constructor(private modalService: NgbModal, private store: Store, private router: Router, private logger: NGXLogger) {}

    ngOnInit(): void {
        this.subscriptions.add(
            this.store.select(UserState.userEmail).subscribe((email) => {
                this.logger.debug('Fetching userInvites');
                this.store.dispatch(new GetUserInvites(email));
            }),
        );

        this.logger.debug('Fetching company');
        this.subscriptions.add(
            this.store.select(CompanyState.company).subscribe((company) => {
                company?.modules ? (this.modules = company.modules) : (this.modules = null);
            }),
        );
    }

    openModal() {
        this.logger.debug('Opening modal to add a company');
        this.modalService.open(CompanyDetailModalComponent);
    }

    acceptInvite(inviteUuid: string) {
        this.logger.debug('Attempt to accept company invite');
        this.subscriptions.add(this.store.dispatch(new AcceptCompanyInvite(inviteUuid)).subscribe(() => this.router.navigate(['/resume/overview'])));
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
