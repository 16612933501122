import { Component, EventEmitter, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { CheckComanageApiKey, FetchOffers } from 'src/app/comanage/comanage.actions';
import { ComanageState } from 'src/app/comanage/comanage.state';
import { CompanyState } from 'src/app/companies/company.state';
import { FetchContacts } from 'src/app/contacts/contacts.actions';
import { ContactState } from 'src/app/contacts/contacts.state';
import { FetchCustomers } from 'src/app/customers/customers.actions';
import { CustomerState } from 'src/app/customers/customers.state';
import { Company } from 'src/app/models/Company';
import { ContactPerson } from 'src/app/models/ContactPerson';
import CrmDeal from 'src/app/models/CrmDeal';
import CrmStage from 'src/app/models/CrmStage';
import Customer from 'src/app/models/Customer';
import { Module } from 'src/app/models/Module';
import { Partner } from 'src/app/models/Partner';
import { FetchPartners } from 'src/app/partners/partners.actions';
import { PartnerState } from 'src/app/partners/partners.state';

import { AddCrmDeal } from '../crm.actions';

@Component({
    selector: 'app-add-crmdeal-modal',
    templateUrl: './add-crmdeal-modal.component.html',
    styleUrls: ['./add-crmdeal-modal.component.scss'],
})
export class AddCrmdealModalComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();
    public emitObject: EventEmitter<CrmDeal> = new EventEmitter();

    @Select(CompanyState.company) company$: Observable<Company>;
    @Select(ComanageState.offers) coManageOffers$: Observable<any[]>;
    @Select(CustomerState.Customers) customers$: Observable<Customer[]>;
    @Select(ContactState.Contacts) contacts$: Observable<ContactPerson[]>;
    @Select(PartnerState.getPartners) partners$: Observable<Partner[]>;
    @Select(ComanageState.apiKeyExists) keyExists$: Observable<boolean>;
    activeModules: Module[];

    @Input() customer: Customer;
    customerKeyword = 'firstName';

    @Input() partners: Partner[];
    partnerKeyword = 'title';

    @Input() contacts: ContactPerson[] = [];
    filteredContacts: ContactPerson[];
    contactKeyword = 'fullName';

    @Input() stage: CrmStage;
    dealForm: FormGroup;

    @Input() description: string;

    offers: any;

    descriptionTooLong = false;
    currentAmountOfExtraInfoCharacters = 0;
    descriptionMaxLength = 1000;

    constructor(
        private store: Store,
        private fb: FormBuilder,
        public activeModal: NgbActiveModal,
        private toastr: ToastrService,
        private logger: NGXLogger,
        private modalService: NgbModal,
    ) {}

    ngOnInit(): void {
        this.filteredContacts = this.contacts;

        this.subscriptions.add(
            this.company$.subscribe((company) => {
                this.activeModules = company.modules;
                this.store.dispatch(new CheckComanageApiKey()).subscribe(() => {
                    this.keyExists$.subscribe((exists) => {
                        if (this.checkModuleActive('COMANAGE')) {
                            this.coManageOffers$.subscribe((offers) => {
                                if (!offers) {
                                    this.store.dispatch(new FetchOffers()).subscribe();
                                } else {
                                    this.offers = offers.filter((o) => o.status != 'draft');
                                }
                            });
                        }
                    });
                });

                this.dealForm = this.fb.group({
                    title: ['', [Validators.required]],
                    price: ['', [Validators.required, Validators.min(0)]],
                    company: ['', []],
                    contactPerson: ['', []],
                    customer: [this.customer ? this.customer : ''],
                    comanageOffer: ['', []],
                    description: [this.description ? this.description : '', [Validators.maxLength(this.descriptionMaxLength)]],
                });
                this.currentAmountOfExtraInfoCharacters = this.description ? this.description.length : 0;
                this.subscriptions.add(
                    this.partners$.subscribe((p) => {
                        if (p != null) {
                            this.partners = JSON.parse(JSON.stringify(p)).sort((a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase()));
                        } else {
                            this.store.dispatch(new FetchPartners());
                        }
                    }),
                );

                this.subscriptions.add(
                    this.contacts$.subscribe((c) => {
                        if (c != null) {
                            this.filteredContacts = JSON.parse(JSON.stringify(c)).sort((a, b) =>
                                a.firstName.toLowerCase().localeCompare(b.firstName.toLowerCase()),
                            );
                            this.contacts = this.filteredContacts;
                        } else {
                            this.store.dispatch(new FetchContacts());
                        }
                    }),
                );

                this.subscriptions.add(
                    this.customers$.subscribe((c) => {
                        if (c == null) {
                            this.store.dispatch(new FetchCustomers());
                        }
                    }),
                );
                this.dealForm.get('company').valueChanges.subscribe((p) => {
                    this.filteredContacts = this.contacts.filter((c) => c.partnerUuid == p.uuid);
                });
            }),
        );
    }

    addDeal($event) {
        const crmDeal: CrmDeal = {
            uuid: null,
            title: this.dealForm.get('title').value,
            price: this.dealForm.get('price').value,
            status: null,
            reason: null,
            dateSinceLastMovement: null,
            partner: this.dealForm.get('company').value,
            contactPerson: this.dealForm.get('contactPerson').value,
            description: this.dealForm.get('description').value,
            products: [],
            crmStage: null,
            comanageOffer: this.dealForm.get('comanageOffer').value.offer_number,
            globalTags: [],
            customer: this.dealForm.get('customer').value ? this.dealForm.get('customer').value : null,
        };

        if (this.stage != null) {
            crmDeal.crmStage = this.stage;
        }

        this.logger.debug('Attempt to add crmDeal');
        this.subscriptions.add(
            this.store.dispatch(new AddCrmDeal(crmDeal)).subscribe(
                (res) => {
                    this.logger.debug('Succesfully added crmDeal');
                    this.emitObject.emit(res.crm.crmDeals.find((d) => d.title == crmDeal.title));
                    this.toastr.success('Deal successfully added.');
                    this.activeModal.close();
                },
                (err) => {
                    this.logger.error('Failed to add crmDeal');
                    this.toastr.error('Oops, something went wrong. Please try again later.');
                },
            ),
        );
    }

    onDescriptionKeyUp() {
        this.descriptionTooLong = false;
        this.currentAmountOfExtraInfoCharacters = this.dealForm.controls.description.value.length;
        if (this.currentAmountOfExtraInfoCharacters > this.descriptionMaxLength) {
            this.descriptionTooLong = true;
        }
    }

    checkModuleActive(moduleKey: string): boolean {
        return this.activeModules.find((module) => module.key === moduleKey) ? true : false;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
