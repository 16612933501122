import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { Unit } from 'src/app/models/Unit';
import { ModuleService } from 'src/app/services/module.service';

import { AddUnit, UpdateUnit } from '../unit.actions';

@Component({
    selector: 'app-unit-add-modal',
    templateUrl: './unit-add-modal.component.html',
    styleUrls: ['./unit-add-modal.component.scss'],
})
export class UnitAddModalComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    public emitObject: EventEmitter<Unit> = new EventEmitter();
    @Input() unit: Unit;
    @Input() isUpdate: boolean;
    unitFormGroup: FormGroup;
    loading = false;
    inputIsWrong = false;
    unitToAdd: Unit;

    saveUnitWentWrong = false;
    errorMessage: string;
    DESCRIPTION_MAX_LENGTH = 1000;
    currentAmountOfDescriptionCharacters: number;
    descriptionIsTooLong = false;

    @ViewChild('focussed', { static: false })
    set input(element: ElementRef<HTMLInputElement>) {
        if (element) {
            element.nativeElement.focus();
        }
    }

    constructor(
        private formBuilder: FormBuilder,
        public activeModal: NgbActiveModal,
        private store: Store,
        private toastr: ToastrService,
        public moduleService: ModuleService,
        private logger: NGXLogger,
    ) {}

    ngOnInit(): void {
        this.unitFormGroup = this.formBuilder.group({
            type: ['', [Validators.required]],
        });

        if (this.isUpdate) {
            this.setFormValues();
        }
    }

    setFormValues() {
        this.unitFormGroup.setValue({
            type: this.unit.type,
        });
    }

    addUnit() {
        this.loading = true;

        if (!this.unitFormGroup.valid) {
            this.inputIsWrong = true;
            this.loading = false;
            return;
        }

        this.unitToAdd = this.unitFormGroup.value;
        if (this.isUpdate) {
            this.unitToAdd.uuid = this.unit.uuid;
        }

        this.unitFormGroup.disable();

        if (!this.isUpdate) {
            this.logger.debug('Attempt to add unit');
            this.subscriptions.add(
                this.store.dispatch(new AddUnit(this.unitToAdd)).subscribe(
                    (state) => {
                        this.logger.debug('Succesfully added unit');
                        this.toastr.success('Unit added succesfully');
                        this.emitObject.emit(state.unit.units.find((u: Unit) => u.type == this.unitToAdd.type));
                        this.activeModal.close();
                    },
                    (errorMessage) => {
                        this.logger.error('Failed to add unit');
                        this.loading = false;
                        this.errorMessage = errorMessage?.error?.message ?? 'Error message was empty';
                        this.saveUnitWentWrong = true;
                        this.unitFormGroup.enable();
                    },
                ),
            );
        } else {
            this.logger.debug('Attempt to update unit');
            this.subscriptions.add(
                this.store.dispatch(new UpdateUnit(this.unitToAdd)).subscribe(
                    (state) => {
                        this.logger.debug('Succesfully updated unit');
                        this.emitObject.emit(state.unit.units.find((u: Unit) => u.type == this.unitToAdd.type));
                        this.activeModal.close();
                        this.toastr.success('Unit updated succesfully');
                    },
                    (errorMessage) => {
                        this.logger.error('Failed to update unit');
                        this.errorMessage = errorMessage.error.message ?? 'Error message was empty';
                        this.saveUnitWentWrong = true;
                        this.loading = false;
                        this.unitFormGroup.enable();
                    },
                ),
            );
        }
    }

    // Hides the error messages that appear after a user has submitted invalid data
    hideErrorMessage() {
        this.inputIsWrong = false;
    }

    onDescriptionKeyUp() {
        this.descriptionIsTooLong = false;
        this.currentAmountOfDescriptionCharacters = this.unitFormGroup.controls.description.value.length;
        if (this.currentAmountOfDescriptionCharacters > this.DESCRIPTION_MAX_LENGTH) {
            this.descriptionIsTooLong = true;
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
