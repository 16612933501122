<app-app-holder>
    <app-navbar></app-navbar>

    <app-sidebar>
        <div class="sidebar-spacing">
            <h3>Inventory</h3>
            <small>Stockmanagement</small>
        </div>

        <span class="divider divider--small"></span>

        <app-inventory-subnav></app-inventory-subnav>
    </app-sidebar>

    <app-content>
        <div class="app__title">
            <h1>Product Stock Update History</h1>
        </div>

        <div *ngIf="(allProducts$ | async) && (productStockUpdateHistory$ | async) as histories; else loading">
            <app-product-stock-history-list *ngIf="histories.length > 0; else empty"></app-product-stock-history-list>
        </div>
    </app-content>

    <ng-template #empty>
        <app-message-empty
            title="No update history"
            subtitle="Update your product stock to see the changes here..."
            [icon]="'../../assets/img/open-box.png'"></app-message-empty>
    </ng-template>

    <ng-template #loading>
        <app-loading-message></app-loading-message>
    </ng-template>
</app-app-holder>
