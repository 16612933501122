import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { append, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { tap } from 'rxjs/operators';

import { WarehouseLocation } from '../models/WarehouseLocation';
import { AddUndefinedObjectTagsToObject } from '../shared/global-tags/global-tags.actions';
import { AddWarehouseLocation, ArchiveWarehouseLocation, FetchWarehouseLocations, UpdateWarehouseLocation } from './warehouse.location.actions';
import { WarehouseLocationService } from './warehouse.location.service';

export interface WarehouseLocationStateModel {
    warehouseLocations: WarehouseLocation[];
}

@State<WarehouseLocationStateModel>({
    name: 'warehouseLocation',
    defaults: {
        warehouseLocations: null,
    },
})
@Injectable()
export class WarehouseLocationState {
    @Selector()
    static WarehouseLocations(state: WarehouseLocationStateModel): WarehouseLocation[] {
        return state?.warehouseLocations;
    }

    constructor(private warehouseLocationService: WarehouseLocationService, private store: Store) {}

    @Action(FetchWarehouseLocations)
    getLocations(ctx: StateContext<WarehouseLocationStateModel>, action: FetchWarehouseLocations) {
        return this.warehouseLocationService.getAllWarehouseLocations().pipe(
            tap((warehouseLocation) => {
                ctx.setState(
                    patch<WarehouseLocationStateModel>({
                        warehouseLocations: warehouseLocation,
                    }),
                );
            }),
        );
    }

    @Action(AddWarehouseLocation)
    addLocation(ctx: StateContext<WarehouseLocationStateModel>, action: AddWarehouseLocation) {
        return this.warehouseLocationService.addWarehouseLocation(action.warehouseLocation).pipe(
            tap((warehouseLocation) => {
                ctx.setState(
                    patch<WarehouseLocationStateModel>({
                        warehouseLocations: append([warehouseLocation]),
                    }),
                );
                this.store.dispatch(new AddUndefinedObjectTagsToObject(warehouseLocation.uuid));
            }),
        );
    }

    @Action(UpdateWarehouseLocation)
    updateLocation(ctx: StateContext<WarehouseLocationStateModel>, action: UpdateWarehouseLocation) {
        return this.warehouseLocationService.updateWarehouseLocation(action.warehouseLocation).pipe(
            tap((warehouseLocation) => {
                ctx.setState(
                    patch<WarehouseLocationStateModel>({
                        warehouseLocations: updateItem<WarehouseLocation>((w) => w.uuid === action.warehouseLocation.uuid, warehouseLocation),
                    }),
                );
            }),
        );
    }

    @Action(ArchiveWarehouseLocation)
    deleteLocation(ctx: StateContext<WarehouseLocationStateModel>, action: ArchiveWarehouseLocation) {
        return this.warehouseLocationService.archiveWarehouseLocation(action.warehouseLocationUuid).pipe(
            tap(() => {
                ctx.setState(
                    patch<WarehouseLocationStateModel>({
                        warehouseLocations: removeItem<WarehouseLocation>((w) => w.uuid === action.warehouseLocationUuid),
                    }),
                );
            }),
        );
    }
}
