import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';
import { Product } from 'src/app/models/Product';

import { FetchProducts, RemoveProductsLink } from '../product.actions';

@Component({
    selector: 'app-product-delete-link',
    templateUrl: './product-delete-link.component.html',
    styleUrls: ['./product-delete-link.component.scss'],
})
export class ProductDeleteLinkComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Input() product: Product;
    @Input() linkedProduct: Product;

    constructor(public activeModal: NgbActiveModal, private store: Store, private logger: NGXLogger) {}

    ngOnInit(): void {}

    deleteLinkedProduct() {
        this.logger.debug('Attempt to delete linkedProduct');
        this.subscriptions.add(
            this.store.dispatch(new RemoveProductsLink(this.product.uuid, this.linkedProduct.uuid)).subscribe(
                () => {
                    this.logger.debug('Succesfully deleted linkedProduct');
                    this.activeModal.close();
                },
                (error) => this.logger.error('Failed to delete linkedProduct'),
            ),
        );
        this.store.dispatch(new FetchProducts());
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
