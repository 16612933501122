import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthenticationService } from 'src/app/authentication/authentication.service';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class UploadFileService {
    httpOptions = {};

    constructor(private https: HttpClient, private authenticationService: AuthenticationService) {
        this.httpOptions = authenticationService.getHeaders();
    }

    pushFileToStorage(file: File): Observable<HttpEvent<{}>> {
        const data: FormData = new FormData();
        data.append('file', file);
        const newRequest = new HttpRequest('POST', environment.baseUrl + '/upload/uploadFile', data);
        return this.https.request(newRequest);
    }

    deleteFile(file: string): Observable<string> {
        return this.https.post<string>(environment.baseUrl + '/upload/deleteFile', file, this.httpOptions);
    }
}
