import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subscription } from 'rxjs';
import { WarehouseLocation } from 'src/app/models/WarehouseLocation';

import { WarehouseLocationsDeleteModalComponent } from '../warehouse-locations-delete-modal/warehouse-locations-delete-modal.component';
import { WarehouseLocationsUpdateModalComponent } from '../warehouse-locations-update-modal/warehouse-locations-update-modal.component';
import { WarehouseLocationState } from '../warehouse.location.state';

@Component({
    selector: 'app-warehouse-locations-list',
    templateUrl: './warehouse-locations-list.component.html',
    styleUrls: ['./warehouse-locations-list.component.scss'],
})
export class WarehouseLocationsListComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Select(WarehouseLocationState.WarehouseLocations)
    allWarehouseLocations$: Observable<WarehouseLocation[]>;

    filter: string;
    displayedColumns: string[] = ['title', 'warehouse', 'edit'];
    dataSource: MatTableDataSource<WarehouseLocation>;
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    constructor(private modalService: NgbModal, private logger: NGXLogger) {}

    ngOnInit(): void {
        this.subscriptions.add(
            this.allWarehouseLocations$.subscribe((allWarehouseLocations) => {
                this.dataSource = new MatTableDataSource(allWarehouseLocations);
                this.dataSource.sort = this.sort;
            }),
        );
    }

    applyFilter(filterValue) {
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }

    openWarehouseLocationUpdateModal(warehouseLocation: WarehouseLocation) {
        this.logger.debug('Opening warehouseLocationUpdateModal');
        const modalRef = this.modalService.open(WarehouseLocationsUpdateModalComponent, { windowClass: 'modal-prompt' });
        modalRef.componentInstance.warehouseLocation = warehouseLocation;
    }

    openWarehouseLocationDeleteModal(warehouseLocation: WarehouseLocation) {
        this.logger.debug('Opening warehouseLocationDeleteModal');
        const modalRef = this.modalService.open(WarehouseLocationsDeleteModalComponent, { windowClass: 'modal-prompt' });
        modalRef.componentInstance.warehouseLocation = warehouseLocation;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
