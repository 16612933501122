import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ComanageContactsComponent } from './comanage-contacts/comanage-contacts.component';
import { ComanageCustomersComponent } from './comanage-customers/comanage-customers.component';
import { ComanageInvoicesComponent } from './comanage-invoices/comanage-invoices.component';
import { ComanageOffersComponent } from './comanage-offers/comanage-offers.component';

export const routes: Routes = [
    { path: '', redirectTo: 'invoices', pathMatch: 'full' },
    { path: 'invoices', component: ComanageInvoicesComponent },
    { path: 'offers', component: ComanageOffersComponent },
    { path: 'contacts', component: ComanageContactsComponent },
    { path: 'customers', component: ComanageCustomersComponent },
];

export const routing: ModuleWithProviders<any> = RouterModule.forChild(routes);
