import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Permission } from 'src/app/models/Permission';
import { Role } from 'src/app/models/Role';
import { environment } from 'src/environments/environment';

import { AuthenticationService } from '../../authentication/authentication.service';
import { Module } from '../../models/Module';

@Injectable({
    providedIn: 'root',
})
export class CustomRolesService {
    httpOptions = {};

    constructor(private httpClient: HttpClient, private authenticationService: AuthenticationService) {
        this.httpOptions = authenticationService.getHeaders();
    }

    getCustomRoles() {
        return this.httpClient.get<Role[]>(`${environment.baseUrl}/role`, this.httpOptions);
    }

    getModulePermissions(roleId: number, moduleUuid: string) {
        return this.httpClient.get<Permission[]>(`${environment.baseUrl}/role/${roleId}/${moduleUuid}`, this.httpOptions);
    }

    addCustomRole(role: Role) {
        return this.httpClient.post<Role>(`${environment.baseUrl}/role`, role, this.httpOptions);
    }

    updateCustomRole(role: Role) {
        return this.httpClient.put<Role>(`${environment.baseUrl}/role`, role, this.httpOptions);
    }

    deleteCustomRole(roleId: number) {
        return this.httpClient.delete<number>(`${environment.baseUrl}/role/${roleId}`, this.httpOptions);
    }

    addCustomRoleToMember(userUuid: string, roleId: number) {
        return this.httpClient.put<Role>(`${environment.baseUrl}/role/${userUuid}/${roleId}`, {}, this.httpOptions);
    }

    addCustomRoleToInvite(roleId: number, inviteUuid: string) {
        return this.httpClient.put<boolean>(`${environment.baseUrl}/company/add-role-to-invite/${roleId}/${inviteUuid}`, {}, this.httpOptions);
    }

    getAllowedActions() {
        return this.httpClient.get<any>(`${environment.baseUrl}/role/permissions`, this.httpOptions);
    }

    getAllowedModules() {
        return this.httpClient.get<Module[]>(`${environment.baseUrl}/module/allowed`, this.httpOptions);
    }
}
