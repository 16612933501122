import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { TeamleaderDealModel } from 'src/app/models/TeamleaderDealModel';

import { AddDeal } from '../teamleader.actions';
import { TeamleaderState } from '../teamleader.state';

@Component({
    selector: 'app-teamleader-add-deal',
    templateUrl: './teamleader-add-deal.component.html',
    styleUrls: ['./teamleader-add-deal.component.scss'],
})
export class TeamleaderAddDealComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Select(TeamleaderState.employees) employees$: Observable<any[]>;
    employees: any[];

    @Select(TeamleaderState.dealPhases) phases$: Observable<any[]>;
    phases: any[];

    @Select(TeamleaderState.departments) departments$: Observable<any[]>;
    departments: any[];

    @Select(TeamleaderState.contacts) contacts$: Observable<any[]>;
    contacts: any[];

    @Select(TeamleaderState.companies) companies$: Observable<any[]>;
    companies: any[];

    dealForm: FormGroup;
    isLoading = true;

    constructor(private formBuilder: FormBuilder, public activeModal: NgbActiveModal, private store: Store, public modalService: NgbModal) {}

    ngOnInit(): void {
        //TODO: deze subscribes zouden eigenlijk met async in de html geregeld moeten worden
        this.subscriptions.add(
            this.employees$.subscribe((employees) => {
                this.employees = employees;
            }),
        );
        this.subscriptions.add(
            this.phases$.subscribe((phases) => {
                this.phases = phases;
            }),
        );
        this.subscriptions.add(
            this.departments$.subscribe((departments) => {
                this.departments = departments;
            }),
        );
        this.subscriptions.add(
            this.contacts$.subscribe((contacts) => {
                this.contacts = contacts;
            }),
        );
        this.subscriptions.add(
            this.companies$.subscribe((companies) => {
                this.companies = companies;
                this.isLoading = false;
            }),
        );

        this.dealForm = this.formBuilder.group({
            title: ['', [Validators.required]],
            leadType: ['', [Validators.required]],
            leadId: ['', [Validators.required]],
            summary: ['', [Validators.required]],
            departmentId: ['', [Validators.required]],
            contactpersonId: ['', [Validators.required]],
            responsibleUserId: ['', [Validators.required]],
            phaseId: ['', [Validators.required]],
            amount: ['', [Validators.required]],
            estimatedProbability: ['', [Validators.required]],
            estimatedClosingDate: ['', [Validators.required]],
        });
    }

    addDeal() {
        const form = this.dealForm.value;
        const deal: TeamleaderDealModel = {
            lead: {
                customer: {
                    type: form.leadType,
                    id: form.leadId,
                },
                contact_person_id: form.contactpersonId,
            },
            title: form.title,
            summary: form.summary,
            department_id: form.departmentId,
            responsible_user_id: form.responsibleUserId,
            phase_id: form.phaseId,
            estimated_value: {
                amount: form.amount,
                currency: 'EUR',
            },
            estimated_probability: form.estimatedProbability / 100,
            estimated_closing_date: form.estimatedClosingDate,
        };
        this.store.dispatch(new AddDeal(deal));
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
