import { DragDropModule } from '@angular/cdk/drag-drop';
import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { NgbDropdownModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MomentModule } from 'ngx-moment';

import { ResumesModule } from '../resumes/resumes.module';
import { SharedModule } from '../shared/shared.module';
import { AddApplicantModalComponent } from './add-applicant-modal/add-applicant-modal.component';
import { ApplicantDetailModalComponent } from './applicant-detail-modal/applicant-detail-modal.component';
import { ApplicantKanbanItemComponent } from './applicant-kanban-item/applicant-kanban-item.component';
import { ApplicantKanbanComponent } from './applicant-kanban/applicant-kanban.component';
import { ApplicantListComponent } from './applicant-list/applicant-list.component';
import { ApplicantsOverviewComponent } from './applicants-overview/applicants-overview.component';
import { routing } from './applicants.routing';
import { ArchivedApplicantListComponent } from './archived-applicant-list/archived-applicant-list.component';
import { FilterApplicantsByCreatedDate } from './filter-applicant-by-created-date.pipe';
import { FilterApplicantsPipe } from './filter-applicants.pipe';
import { ManageApplicantLanesModalComponent } from './manage-applicant-lanes-modal/manage-applicant-lanes-modal.component';

@NgModule({
    declarations: [
        ApplicantsOverviewComponent,
        ApplicantListComponent,
        ApplicantKanbanComponent,
        FilterApplicantsPipe,
        FilterApplicantsByCreatedDate,
        ManageApplicantLanesModalComponent,
        AddApplicantModalComponent,
        ApplicantKanbanItemComponent,
        ApplicantDetailModalComponent,
        ArchivedApplicantListComponent,
    ],
    imports: [
        CommonModule,
        SharedModule,
        routing,
        NgbModule,
        NgbDropdownModule,
        ResumesModule,
        CdkTableModule,
        MatTableModule,
        DragDropModule,
        MatSortModule,
        MomentModule,
        CKEditorModule,
    ],
})
export class ApplicantsModule {}
