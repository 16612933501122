<div class="login">
    <div class="login__holder">
        <app-session-header [header]="'Welcome back!'"></app-session-header>

        <p class="m-b-8">
            You are currently logged in as <strong>{{ userEmail }}</strong>
        </p>

        <div class="m-b-6">
            <a
                (click)="continueToResumo()"
                class="button m-b-4 button--primary w-100"
                >Back to Resumo <i class="uil uil-arrow-right"></i
            ></a>
        </div>

        <p class="text-center">Other user account? <a (click)="logOut()">Logout</a></p>
    </div>
</div>
