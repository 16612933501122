import Lead from '../models/Lead';

export class UpdateLead {
    static readonly type = '[Lead] Update Lead';
    constructor(public lead: Lead) {}
}

export class UpdateArchivedLead {
    static readonly type = '[Lead] Update Archived Lead';
    constructor(public lead: Lead) {}
}

export class UpdateReadOfLead {
    static readonly type = '[Lead] Update Read Of Lead';
    constructor(public lead: Lead) {}
}

export class ArchiveLead {
    static readonly type = '[Lead] Archive Lead';
    constructor(public lead: Lead, public archived: boolean) {}
}

export class AddLead {
    static readonly type = '[Lead] Add Lead';
    constructor(public lead: Lead) {}
}

export class fetchLeads {
    static readonly type = '[Lead] Fetch Lead';
}

export class GetArchivedLeads {
    static readonly type = '[Lead] Get Archived Leads';
}
