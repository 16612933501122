import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { MailchimpCampaignListComponent } from './mailchimp-campaign-list/mailchimp-campaign-list.component';
import { MailchimpRecipientListListComponent } from './mailchimp-recipient-list-list/mailchimp-recipient-list-list.component';
import { MailchimpTagListComponent } from './mailchimp-tag-list/mailchimp-tag-list.component';
import { MailchimpTemplateListComponent } from './mailchimp-template-list/mailchimp-template-list.component';

export const routes: Routes = [
    { path: '', redirectTo: 'campaigns', pathMatch: 'full' },
    { path: 'campaigns', component: MailchimpCampaignListComponent },
    { path: 'lists', component: MailchimpRecipientListListComponent },
    { path: 'templates', component: MailchimpTemplateListComponent },
    { path: 'tags', component: MailchimpTagListComponent },
];

export const routing: ModuleWithProviders<any> = RouterModule.forChild(routes);
