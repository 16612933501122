<form
    [formGroup]="newRecipientListFormGroup"
    (ngSubmit)="addRecipients()"
    class="modal-wrapper">
    <div class="modal-header">
        <h2 class="modal-title">Add to Mailchimp Audience</h2>
        <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="activeModal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div *ngIf="loading"><app-loading-message></app-loading-message></div>
    <div
        class="modal-body"
        *ngIf="!loading">
        <div class="row">
            <div class="col">
                <div class="form-group required m-b-2">
                    <label for="list">Mailchimp Audience</label>
                    <select
                        id="list"
                        formControlName="list"
                        (change)="onChange($event.target.value)">
                        <option
                            *ngFor="let list of lists"
                            [ngValue]="list.id">
                            {{ list.name }}
                        </option>
                    </select>
                </div>
            </div>
        </div>

        <div
            class="row"
            *ngIf="newRecipientListFormGroup.get('list').valid">
            <div class="col">
                <div class="form-group m-b-2">
                    <label>Tags</label>
                    <tag-input
                        id="tag"
                        formControlName="tag"
                        [(ngModel)]="tags"
                        [addOnBlur]="true"
                        [clearOnBlur]="true">
                        <tag-input-dropdown
                            [autocompleteItems]="allTags"
                            [appendToBody]="false"
                            placeholder="">
                        </tag-input-dropdown>
                    </tag-input>
                    <span class="form-helptext">Enter the tags you want to give to the added members.</span>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <input
            [ngClass]="!newRecipientListFormGroup.valid ? 'button button--disabled w-100' : 'button button--primary w-100'"
            type="submit"
            value="Add"
            [disabled]="!newRecipientListFormGroup.valid" />
    </div>
</form>
