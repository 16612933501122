import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { append, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { Warehouse } from '../models/Warehouse';
import { AddUndefinedObjectTagsToObject } from '../shared/global-tags/global-tags.actions';
import { AddWarehouse, ArchiveWarehouse, FetchWarehouses, UpdateWarehouse } from './warehouse.actions';
import { WarehouseService } from './warehouse.service';

export interface WarehouseStateModel {
    warehouses: Warehouse[];
}

@State<WarehouseStateModel>({
    name: 'warehouse',
    defaults: {
        warehouses: null,
    },
})
@Injectable()
export class WarehouseState {
    @Selector()
    static Warehouses(state: WarehouseStateModel): Warehouse[] {
        return state?.warehouses;
    }

    constructor(private warehouseService: WarehouseService, private store: Store) {}

    @Action(FetchWarehouses)
    fetchWarehouses(ctx: StateContext<WarehouseStateModel>) {
        return this.warehouseService.getAllWarehouses().pipe(
            tap((warehouses) => {
                ctx.setState(
                    patch<WarehouseStateModel>({
                        warehouses: warehouses,
                    }),
                );
            }),
        );
    }

    @Action(AddWarehouse)
    addWarehouse(ctx: StateContext<WarehouseStateModel>, action: AddWarehouse) {
        return this.warehouseService.addWarehouse(action.warehouse).pipe(
            tap((warehouse) => {
                ctx.setState(
                    patch<WarehouseStateModel>({
                        warehouses: append([warehouse]),
                    }),
                );
                this.store.dispatch(new AddUndefinedObjectTagsToObject(warehouse.uuid));
            }),
        );
    }

    @Action(UpdateWarehouse)
    updateWarehouse(ctx: StateContext<WarehouseStateModel>, action: UpdateWarehouse): Observable<Warehouse> {
        return this.warehouseService.updateWarehouse(action.warehouse).pipe(
            tap((warehouse) => {
                ctx.setState(
                    patch<WarehouseStateModel>({
                        warehouses: updateItem<Warehouse>((w) => w.uuid === action.warehouse.uuid, warehouse),
                    }),
                );
            }),
        );
    }

    @Action(ArchiveWarehouse)
    archiveWarehouse(ctx: StateContext<WarehouseStateModel>, action: ArchiveWarehouse) {
        return this.warehouseService.archiveWarehouse(action.warehouseUuid).pipe(
            tap(() => {
                ctx.setState(
                    patch<WarehouseStateModel>({
                        warehouses: removeItem<Warehouse>((w) => w.uuid === action.warehouseUuid),
                    }),
                );
            }),
        );
    }
}
