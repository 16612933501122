<form
    [formGroup]="productFormGroup"
    (ngSubmit)="updateProduct()"
    class="modal-wrapper">
    <div class="modal-header">
        <h2
            class="modal-title"
            id="exampleModalLabel">
            Edit product
        </h2>
        <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="activeModal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <div class="row">
            <div class="col">
                <div class="form-group m-b-2">
                    <label>Title</label>
                    <input
                        type="text"
                        placeholder="Title"
                        formControlName="title"
                        trim="blur"
                        #focussed />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="form-group m-b-2">
                    <label>Description</label>
                    <textarea
                        class="resize-vertical"
                        formControlName="description"
                        placeholder="Description"
                        (keyup)="onDescriptionKeyUp()"
                        trim="blur"></textarea>
                    <small
                        ><span *ngIf="currentAmountOfDescriptionCharacters == null">0</span>{{ currentAmountOfDescriptionCharacters }}/{{
                            DESCRIPTION_MAX_LENGTH
                        }}</small
                    >
                    <span
                        class="form-error"
                        *ngIf="descriptionIsTooLong"
                        >Message cannot contain more than 1000 characters</span
                    >
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-6">
                <div class="form-group m-b-2">
                    <label>EAN</label>
                    <input
                        type="text"
                        formControlName="ean"
                        placeholder="EAN-number"
                        trim="blur" />
                </div>
            </div>
            <div class="col-6">
                <div class="form-group m-b-2">
                    <label>SKU</label>
                    <input
                        type="text"
                        formControlName="sku"
                        placeholder="SKU-number"
                        trim="blur" />
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-6">
                <div class="form-group m-b-2">
                    <label>Purchase price (Excl. VAT)</label>
                    <input
                        type="number"
                        formControlName="purchasePrice"
                        trim="blur" />
                </div>
            </div>
            <div class="col-6">
                <div class="form-group m-b-2">
                    <label>Selling price (Excl. VAT)</label>
                    <input
                        type="number"
                        formControlName="sellingPrice"
                        trim="blur" />
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-4">
                <label>Items in stock</label>
                <div class="form-group required m-b-2">
                    <input
                        type="text"
                        placeholder="Current stock"
                        formControlName="inStock"
                        (focus)="hideErrorMessage()"
                        trim="blur" />
                    <span
                        class="form-error"
                        *ngIf="productFormGroup.controls.inStock.errors && inputIsWrong"
                        >Invalid amount</span
                    >
                </div>
            </div>

            <div class="col-4">
                <app-global-autocomplete
                    [parentForm]="productFormGroup"
                    [formControlName]="'unit'"
                    [data]="allUnits"
                    [itemType]="'unit'"
                    [label]="'Measurement unit'"
                    [notFoundMessage]="'Add a unit'"
                    [keyword]="'type'"
                    [placeholder]="'Choose a unit'"
                    [dataLoaded]="allUnits$ | async"
                    [required]="false"></app-global-autocomplete>
            </div>

            <div class="col-4">
                <label>Notify when lower than...</label>
                <div class="form-group required m-b-2">
                    <input
                        type="text"
                        placeholder="Number"
                        formControlName="stockNotification"
                        (focus)="hideErrorMessage()"
                        trim="blur" />
                    <span
                        class="form-error"
                        *ngIf="productFormGroup.controls.stockNotification.errors && inputIsWrong"
                        >Invalid amount</span
                    >
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <label>Tags</label>
                <div class="form-group m-b-2">
                    <app-global-tags
                        [objectUuid]="product.uuid"
                        [type]="'product'"></app-global-tags>
                </div>
            </div>
        </div>

        <div class="form-group m-b-2">
            <label>Categories</label>
            <app-product-category-link [product]="product"></app-product-category-link>
        </div>

        <div class="row">
            <div class="col">
                <app-global-autocomplete
                    [parentForm]="productFormGroup"
                    [formControlName]="'warehouseLocation'"
                    [data]="allWarehouseLocations"
                    [itemType]="'warehouseLocation'"
                    [label]="'Warehouse location'"
                    [notFoundMessage]="'Add a location'"
                    [keyword]="keyword"
                    [placeholder]="'Choose a warehouse location'"
                    [dataLoaded]="allWarehouseLocations$ | async"
                    [required]="false"></app-global-autocomplete>
            </div>
        </div>
    </div>
    <span
        class="form-error"
        *ngIf="updateProductWentWrong"
        >{{ errorMessage }}</span
    >

    <div class="modal-footer">
        <button
            type="button"
            class="button button--outline"
            (click)="activeModal.close()">
            Close
        </button>
        <button
            type="submit"
            [ngClass]="{ 'button--loading': loading }"
            class="button button--primary">
            Update
        </button>
    </div>
</form>
