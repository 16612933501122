import { formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { AuthenticationService } from '../authentication/authentication.service';

@Injectable({
    providedIn: 'root',
})
export class OutlookCalendarService {
    httpOptions = {};

    constructor(private httpClient: HttpClient, private authenticationService: AuthenticationService) {
        this.httpOptions = authenticationService.getHeaders();
    }

    getCalendarEventsForWeek(start, end): Observable<any> {
        const formatString = 'yyyy-MM-ddTHH:mm:ss';
        return this.httpClient.get<any>(
            environment.baseUrl + `/outlook/calendar/${formatDate(start, formatString, 'en-US')}/${formatDate(end, formatString, 'en-US')}`,
            this.httpOptions,
        );
    }
}
