import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { VeldaCountry } from 'src/app/models/VeldaCountry';
import { VeldaLanguageDTO } from 'src/app/models/VeldaLanguageDTO';
import { VeldaLanguage } from 'src/app/models/VeldaLanuage';

import { EditLanguage } from '../velda-languages.actions';
import { VeldaLanguagesState } from '../velda-languages.state';

@Component({
    selector: 'app-velda-languages-languages-edit-modal',
    templateUrl: './velda-languages-languages-edit-modal.component.html',
    styleUrls: ['./velda-languages-languages-edit-modal.component.scss'],
})
export class VeldaLanguagesLanguagesEditModalComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Select(VeldaLanguagesState.countries) countries$: Observable<VeldaCountry[]>;

    @Input() language: VeldaLanguage;

    countries: VeldaCountry[];
    languageFormGroup: FormGroup;
    countryKeyword = 'countryName';
    loading = true;

    constructor(
        private store: Store,
        public activeModal: NgbActiveModal,
        private formBuilder: FormBuilder,
        private toastrService: ToastrService,
        private logger: NGXLogger,
    ) {}

    ngOnInit(): void {
        this.subscriptions.add(
            this.countries$.subscribe((countries) => {
                this.countries = countries;

                this.languageFormGroup = this.formBuilder.group({
                    languageName: [this.language.languageName, [Validators.required]],
                    languageCode: [this.language.languageCode, [Validators.required]],
                    country: [this.language.country, [Validators.required]],
                });

                this.loading = false;
            }),
        );
    }

    editLanguage() {
        const veldaLanguage: VeldaLanguageDTO = {
            languageName: this.languageFormGroup.value.languageName,
            languageCode: this.languageFormGroup.value.languageCode,
            countryUuid: this.languageFormGroup.value.country.uuid,
        };

        this.logger.debug('Attempt to edit language');

        this.subscriptions.add(
            this.store.dispatch(new EditLanguage(this.language.uuid, veldaLanguage)).subscribe(
                () => {
                    this.toastrService.success('Language edited successfully!');
                    this.logger.debug('Language edited successfully!');
                    this.activeModal.close();
                },
                (error) => {
                    this.toastrService.error('Failed to edit language!');
                    this.logger.error('Failed to edit language!');
                },
            ),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
