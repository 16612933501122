import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthenticationService } from 'src/app/authentication/authentication.service';
import { JiraCredential } from 'src/app/models/JiraCredentials';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class JiraLoginService {
    httpOptions = {};

    constructor(private httpClient: HttpClient, private authenticationService: AuthenticationService) {
        this.httpOptions = authenticationService.getHeaders();
    }

    checkIfJiraCredentialsExistAndFetch() {
        return this.httpClient.get<JiraCredential>(environment.baseUrl + '/jira/credentialsExist', this.httpOptions);
    }

    pushJiraCredentials(jiraCredential: JiraCredential) {
        return this.httpClient.post<boolean>(
            environment.baseUrl +
                `/jira/addCredentials?username=${jiraCredential.username}&apiKey=${jiraCredential.apiKey}&tempoKey=${jiraCredential.tempoKey}`,
            {},
            this.httpOptions,
        );
    }

    removeJiraCredentials() {
        return this.httpClient.post<boolean>(environment.baseUrl + `/jira/removeCredentials`, '', this.httpOptions);
    }

    // eslint-disable-next-line @typescript-eslint/ban-types
    getJiraProjectKeys(): Observable<Object[]> {
        return this.httpClient.get<Map<string, string>[]>(environment.baseUrl + `/jira/projectKeyList`, this.httpOptions);
    }

    // eslint-disable-next-line @typescript-eslint/ban-types
    getJiraEmployeeIDs(): Observable<Object[]> {
        return this.httpClient.get<Map<string, string>[]>(environment.baseUrl + `/jira/employeeIdList`, this.httpOptions);
    }

    getTempoTimeSheetsForProjects(projectKey: string) {
        return this.httpClient.get<any>(environment.baseUrl + `/jira/tempoTimesheetsForProject/${projectKey}`, this.httpOptions);
    }

    importAllTempoTimeSheets(month: number, year: number): Observable<boolean> {
        return this.httpClient.post<boolean>(`${environment.baseUrl}/jira/importFromJira?month=${month}&year=${year}`, null, this.httpOptions);
    }
}
