import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';

import { FetchSimpleInformationForAllEmployees } from '../../employees/employees.actions';
import { EmployeeState } from '../../employees/employees.state';
import { Employee } from '../../models/Employee';
import { GetBillableInformationForEmployees } from '../financial.actions';
import { FinancialState } from '../financial.state';

@Component({
    selector: 'app-employee-graphs',
    templateUrl: './employee-graphs.component.html',
    styleUrls: ['./employee-graphs.component.scss'],
})
export class EmployeeGraphsComponent implements OnInit, OnDestroy {
    @Select(EmployeeState.EmployeesSimpleInformation) employees$: Observable<Employee[]>;
    @Select(FinancialState.employeeIdsOfBillableInformationForEmployee) idsForBillable$: Observable<string[]>;

    BILLABLE_NAV_ID = 'billable';

    @ViewChild('nav') nav?;
    private subscriptions = new Subscription();

    constructor(private store: Store) {}

    ngOnInit(): void {
        this.fetchData();
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    fetchNecessaryInfo($event: NgbNavChangeEvent<any>): void {
        if ($event.nextId === this.BILLABLE_NAV_ID) this.store.dispatch(new GetBillableInformationForEmployees());
    }

    private fetchData() {
        this.subscriptions.add(
            this.employees$.subscribe((employees) => {
                if (!employees) this.store.dispatch(new FetchSimpleInformationForAllEmployees());
            }),
        );
    }
}
