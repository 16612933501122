import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { append, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { tap } from 'rxjs';
import { GlobalFile } from 'src/app/models/GlobalFile';
import { GlobalFilesObject } from 'src/app/models/GlobalFilesObject';
import { LinkedObjectsToFileWithId } from 'src/app/models/LinkedObjectsToFileWithId';
import { YukiFileObject } from 'src/app/models/YukiFileObject';

import ChildInvoiceLink from '../../models/ChildInvoiceLink';
import { LinkFile } from '../../models/LinkFile';
import { LinkedFileToObject } from '../../models/LinkedFileToObject';
import { LinkedFilesToObjectWithId } from '../../models/LinkedFilesToObjectWithId';
import { LinkedObjectToFile } from '../../models/LinkedObjectToFile';
import {
    AddSubLinkToLinkedObjectToFile,
    AddUndefinedObjectFile,
    CreateAndLinkDocument,
    DeleteLinkedObject,
    DeleteSubLinkToLinkedObjectToFile,
    FetchGlobalFiles,
    GetAllFilesLinkedToObject,
    GetAllGlobalFilesObjects,
    GetAllObjectsLinked,
    GetAllObjectsLinkedToFile,
    GetAllObjectsToLink,
    GetAllYukiFileObjects,
    GetAllYukiOverheadFileObjects,
    GetGlobalFilesObject,
    LinkFileToObject,
    LinkFileToOverhead,
    MapLinksToBudgetExpenditures,
    RemoveUndefinedObjectFile,
    ResetUndefinedObjectFiles,
    UpdateChildInvoiceLink,
    UpdateLinkedObject,
} from './global-files.actions';
import { GlobalFilesService } from './global-files.service';

export interface GlobalFilesStateModel {
    globalFilesObjects: GlobalFilesObject[];
    files: GlobalFile[];
    linkedYukiOverheadsObjects: GlobalFilesObject[];
    linkedYukiObjects: YukiFileObject[];
    undefinedObjectFiles: GlobalFile[][];
    linkFiles: LinkFile[];
    linkedObjectsToFile: LinkedObjectsToFileWithId;
    linkedFilesToObject: LinkedFilesToObjectWithId;
    linkedObjects: LinkedObjectsToFileWithId[];
}

@State<GlobalFilesStateModel>({
    name: 'globalFiles',
    defaults: {
        globalFilesObjects: null,
        linkedYukiOverheadsObjects: null,
        linkedYukiObjects: null,
        files: null,
        undefinedObjectFiles: [],
        linkFiles: null,
        linkedObjectsToFile: null,
        linkedFilesToObject: null,
        linkedObjects: null,
    },
})
@Injectable()
export class GlobalFilesState {
    constructor(private globalFilesService: GlobalFilesService, private store: Store) {}

    @Selector()
    static GlobalFileObjects(state: GlobalFilesStateModel): GlobalFilesObject[] {
        return state?.globalFilesObjects;
    }

    @Selector()
    static GlobalFiles(state: GlobalFilesStateModel): GlobalFile[] {
        return state?.files;
    }

    @Selector()
    static GetGlobalFilesByObjectUuid(state: GlobalFilesStateModel): (objectUuid: string) => GlobalFile[] {
        return (objectUuid: string) => {
            return state?.globalFilesObjects?.find((o) => o.uuid === objectUuid)?.linkedFiles;
        };
    }

    @Selector()
    static UndefinedObjectFiles(state: GlobalFilesStateModel): GlobalFile[][] {
        return state?.undefinedObjectFiles;
    }

    @Selector()
    static LinkFiles(state: GlobalFilesStateModel): LinkFile[] {
        return state?.linkFiles;
    }

    @Selector()
    static LinkedObjectsToFileWithId(state: GlobalFilesStateModel): LinkedObjectsToFileWithId {
        return state?.linkedObjectsToFile;
    }

    @Selector()
    static LinkedObjects(state: GlobalFilesStateModel): LinkedObjectsToFileWithId[] {
        return state?.linkedObjects;
    }

    @Selector()
    static LinkedFilesToObjectWithId(state: GlobalFilesStateModel): LinkedFilesToObjectWithId {
        return state?.linkedFilesToObject;
    }

    @Action(FetchGlobalFiles)
    fetchGlobalFiles(ctx: StateContext<GlobalFilesStateModel>) {
        return this.globalFilesService.getAllFiles().pipe(
            tap((files) => {
                ctx.setState(
                    patch<GlobalFilesStateModel>({
                        files: files,
                    }),
                );
            }),
        );
    }

    @Action(GetAllGlobalFilesObjects)
    getAllGlobalFilesObjects(ctx: StateContext<GlobalFilesStateModel>, action: GetAllGlobalFilesObjects) {
        return this.globalFilesService.getALLLinkedFileObjects().pipe(
            tap((linkedFilesObjects) => {
                ctx.setState(
                    patch<GlobalFilesStateModel>({
                        globalFilesObjects: linkedFilesObjects,
                    }),
                );
            }),
        );
    }

    @Action(GetAllYukiFileObjects)
    getAllYukiFileObjects(ctx: StateContext<GlobalFilesStateModel>, action: GetAllYukiFileObjects) {
        return this.globalFilesService.getAllLinkedYukiFileObject(action.fileUuid).pipe(
            tap((linkedYukiObjects) => {
                ctx.setState(
                    patch<GlobalFilesStateModel>({
                        linkedYukiObjects: linkedYukiObjects,
                    }),
                );
            }),
        );
    }

    @Action(GetAllYukiOverheadFileObjects)
    getAllLinkedOverheadFileObjects(ctx: StateContext<GlobalFilesStateModel>, action: GetAllYukiOverheadFileObjects) {
        return this.globalFilesService.getAllLinkedOverheadFileObjects(action.fileUuid).pipe(
            tap((linkedOverheadObjects) => {
                ctx.setState(
                    patch<GlobalFilesStateModel>({
                        linkedYukiOverheadsObjects: linkedOverheadObjects,
                    }),
                );
            }),
        );
    }

    @Action(GetGlobalFilesObject)
    getGlobalFilesObject(ctx: StateContext<GlobalFilesStateModel>, action: GetGlobalFilesObject) {
        return this.globalFilesService.getLinkedFileObject(action.objectUuid).pipe(
            tap((object) => {
                if (!ctx.getState().globalFilesObjects) {
                    ctx.setState(
                        patch<GlobalFilesStateModel>({
                            globalFilesObjects: append([object]),
                        }),
                    );
                }
                if (ctx.getState().globalFilesObjects.find((o) => o.uuid === action.objectUuid)) {
                    ctx.setState(
                        patch<GlobalFilesStateModel>({
                            globalFilesObjects: updateItem<GlobalFilesObject>(
                                (o) => o.uuid === action.objectUuid,
                                patch<GlobalFilesObject>({
                                    linkedFiles: object.linkedFiles,
                                }),
                            ),
                        }),
                    );
                } else {
                    ctx.setState(
                        patch<GlobalFilesStateModel>({
                            globalFilesObjects: append([object]),
                        }),
                    );
                }
            }),
        );
    }

    @Action(LinkFileToOverhead)
    linkFileToOverhead(ctx: StateContext<GlobalFilesStateModel>, action: LinkFileToOverhead) {
        return this.globalFilesService.addFileToOverhead(action.fileUuid, action.price, action.yukiId, action.description).pipe(
            tap((object) => {
                if (!ctx.getState().globalFilesObjects) {
                    ctx.setState(
                        patch<GlobalFilesStateModel>({
                            globalFilesObjects: append([object]),
                        }),
                    );
                } else {
                    ctx.setState(
                        patch<GlobalFilesStateModel>({
                            globalFilesObjects: append([object]),
                        }),
                    );
                }
            }),
        );
    }

    @Action(LinkFileToObject)
    linkFileToObject(ctx: StateContext<GlobalFilesStateModel>, action: LinkFileToObject) {
        return this.globalFilesService.addFileToObject(action.fileUuid, action.linkFileDTOs).pipe(
            tap((linkedObjectsToFile) => {
                linkedObjectsToFile.forEach((obj) => {
                    action.linkFileDTOs.forEach((lfDTO) => {
                        if (obj.objectId == lfDTO.objectId)
                            ctx.setState(
                                patch<GlobalFilesStateModel>({
                                    linkedObjectsToFile: patch<LinkedObjectsToFileWithId>({
                                        linkedObjects: removeItem<LinkedObjectToFile>((obj) => obj.objectId == lfDTO.objectId),
                                    }),
                                }),
                            );
                    });
                });

                ctx.setState(
                    patch<GlobalFilesStateModel>({
                        linkedObjectsToFile: patch<LinkedObjectsToFileWithId>({
                            linkedObjects: append<LinkedObjectToFile>(linkedObjectsToFile),
                        }),
                    }),
                );
            }),
        );
    }

    @Action(AddUndefinedObjectFile)
    addUndefinedObjectFile(ctx: StateContext<GlobalFilesStateModel>, action: AddUndefinedObjectFile) {
        const newUndefinedObjectFilesCopy = JSON.parse(JSON.stringify(ctx.getState().undefinedObjectFiles));
        if (newUndefinedObjectFilesCopy[action.key] == undefined) {
            newUndefinedObjectFilesCopy.push([action.file]);
        } else {
            newUndefinedObjectFilesCopy[action.key] = [...newUndefinedObjectFilesCopy[action.key], action.file];
        }
        return ctx.setState(
            patch<GlobalFilesStateModel>({
                undefinedObjectFiles: newUndefinedObjectFilesCopy,
            }),
        );
    }

    @Action(RemoveUndefinedObjectFile)
    removeUndefinedObjectFile(ctx: StateContext<GlobalFilesStateModel>, action: RemoveUndefinedObjectFile) {
        let newUndefinedObjectFilesCopy = JSON.parse(JSON.stringify(ctx.getState().undefinedObjectFiles));
        newUndefinedObjectFilesCopy = newUndefinedObjectFilesCopy[action.key].filter((o) => o.uuid != action.file.uuid);
        return ctx.setState(
            patch<GlobalFilesStateModel>({
                undefinedObjectFiles: newUndefinedObjectFilesCopy,
            }),
        );
    }

    @Action(DeleteLinkedObject)
    deleteLinkedObject(ctx: StateContext<GlobalFilesStateModel>, action: DeleteLinkedObject) {
        return this.globalFilesService.deleteLinkedObjectFromFile(action.fileUuid, action.objectUuid).pipe(
            tap(() => {
                if (ctx.getState().linkedObjectsToFile) {
                    ctx.setState(
                        patch<GlobalFilesStateModel>({
                            linkedObjectsToFile: patch<LinkedObjectsToFileWithId>({
                                linkedObjects: removeItem<LinkedObjectToFile>((obj) => obj.objectId == action.objectUuid),
                            }),
                        }),
                    );
                }
                if (ctx.getState().linkedFilesToObject) {
                    ctx.setState(
                        patch<GlobalFilesStateModel>({
                            linkedFilesToObject: patch<LinkedFilesToObjectWithId>({
                                linkedFiles: removeItem<LinkedFileToObject>((file) => file.fileId == action.fileUuid),
                            }),
                        }),
                    );
                }
            }),
        );
    }

    @Action(ResetUndefinedObjectFiles)
    resetUndefinedObjectFiles(ctx: StateContext<GlobalFilesStateModel>) {
        const newUndefinedObjectFilesCopy = JSON.parse(JSON.stringify(ctx.getState().undefinedObjectFiles));
        newUndefinedObjectFilesCopy.pop();
        return ctx.setState(
            patch<GlobalFilesStateModel>({
                undefinedObjectFiles: newUndefinedObjectFilesCopy,
            }),
        );
    }

    @Action(GetAllObjectsToLink)
    getAllObjectsToLink(ctx: StateContext<GlobalFilesStateModel>) {
        return this.globalFilesService.getAllObjectsToLink().pipe(
            tap((objects) => {
                ctx.setState(
                    patch<GlobalFilesStateModel>({
                        linkFiles: objects,
                    }),
                );
            }),
        );
    }

    @Action(GetAllObjectsLinkedToFile)
    getAllObjectsLinkedToFile(ctx: StateContext<GlobalFilesStateModel>, action: GetAllObjectsLinkedToFile) {
        return this.globalFilesService.getAllObjectsLinkedToFile(action.fileId).pipe(
            tap((linkedObjectsToFile) => {
                ctx.setState(
                    patch<GlobalFilesStateModel>({
                        linkedObjectsToFile: linkedObjectsToFile,
                    }),
                );
            }),
        );
    }

    @Action(GetAllObjectsLinked)
    getAllObjectsLinked(ctx: StateContext<GlobalFilesStateModel>) {
        return this.globalFilesService.getAllObjectsLinked().pipe(
            tap((linkedObjects) => {
                ctx.setState(
                    patch<GlobalFilesStateModel>({
                        linkedObjects: linkedObjects,
                    }),
                );
            }),
        );
    }

    @Action(GetAllFilesLinkedToObject)
    getAllFilesLinkedToObject(ctx: StateContext<GlobalFilesStateModel>, action: GetAllFilesLinkedToObject) {
        return this.globalFilesService.getAllFilesLinkedToObject(action.objectId).pipe(
            tap((linkedFilesToObject) => {
                ctx.setState(
                    patch<GlobalFilesStateModel>({
                        linkedFilesToObject: linkedFilesToObject,
                    }),
                );
            }),
        );
    }

    @Action(AddSubLinkToLinkedObjectToFile)
    addSubLinkToLinkedObjectToFile(ctx: StateContext<GlobalFilesStateModel>, action: AddSubLinkToLinkedObjectToFile) {
        return this.globalFilesService.addSubLinkToLinkedObjectToFile(action.childInvoiceLinkDTOs).pipe(
            tap((childInvoiceLinks) => {
                const linkedObjectsToFile: LinkedObjectsToFileWithId = JSON.parse(JSON.stringify(ctx.getState().linkedObjectsToFile));
                childInvoiceLinks.forEach((childInvoiceLink) => {
                    linkedObjectsToFile.linkedObjects
                        .filter((linkedObject) => linkedObject.objectId === action.childInvoiceLinkDTOs[0].objectIdForKey)
                        .forEach((linkedObject) => {
                            if (!linkedObject.childInvoiceLinks) {
                                linkedObject.childInvoiceLinks = [];
                            }
                            linkedObject.childInvoiceLinks.push(childInvoiceLink);
                        });
                });
                ctx.setState(
                    patch<GlobalFilesStateModel>({
                        linkedObjectsToFile: linkedObjectsToFile,
                    }),
                );
            }),
        );
    }

    @Action(DeleteSubLinkToLinkedObjectToFile)
    deleteSubLinkToLinkedObjectToFile(ctx: StateContext<GlobalFilesStateModel>, action: DeleteSubLinkToLinkedObjectToFile) {
        return this.globalFilesService.deleteSubLinkToLinkedObjectToFile(action.linkId).pipe(
            tap(() => {
                ctx.setState(
                    patch({
                        linkedObjectsToFile: patch({
                            linkedObjects: updateItem(
                                (linkedObjectToFile: LinkedObjectToFile) =>
                                    !!linkedObjectToFile.childInvoiceLinks.find((childInvoiceLink) => childInvoiceLink.id === action.linkId),

                                patch({
                                    childInvoiceLinks: removeItem<ChildInvoiceLink>((childInvoiceLink) => childInvoiceLink.id === action.linkId),
                                }),
                            ),
                        }),
                    }),
                );

                if (ctx.getState().linkedFilesToObject) {
                    ctx.setState(
                        patch<GlobalFilesStateModel>({
                            linkedFilesToObject: patch<LinkedFilesToObjectWithId>({
                                linkedFiles: removeItem((linkedObjectToFile) => linkedObjectToFile.childLinkId === action.linkId),
                            }),
                        }),
                    );
                }
            }),
        );
    }

    @Action(CreateAndLinkDocument)
    createAndLinkDocument(ctx: StateContext<GlobalFilesStateModel>, action: CreateAndLinkDocument) {
        return this.globalFilesService.createAndLinkDocument(action.documentDTO).pipe(
            tap((linkedObjectToFile) => {
                ctx.setState(
                    patch<GlobalFilesStateModel>({
                        linkedFilesToObject: patch<LinkedFilesToObjectWithId>({
                            linkedFiles: append<LinkedFileToObject>(linkedObjectToFile),
                        }),
                    }),
                );
            }),
        );
    }

    @Action(UpdateLinkedObject)
    updateLinkedObject(ctx: StateContext<GlobalFilesStateModel>, action: UpdateLinkedObject) {
        return this.globalFilesService.updateLinkedObject(action.objectUuid, action.fileUuid, action.linkFileDTO).pipe(
            tap((linkedObjectToFile) => {
                ctx.setState(
                    patch<GlobalFilesStateModel>({
                        linkedObjectsToFile: patch<LinkedObjectsToFileWithId>({
                            linkedObjects: updateItem<LinkedObjectToFile>(
                                (linkedObjectToFile) => linkedObjectToFile.objectId === action.objectUuid,
                                linkedObjectToFile,
                            ),
                        }),
                    }),
                );
            }),
        );
    }

    @Action(UpdateChildInvoiceLink)
    updateChildInvoiceLink(ctx: StateContext<GlobalFilesStateModel>, action: UpdateChildInvoiceLink) {
        return this.globalFilesService.updateChildInvoiceLink(action.childLinkId, action.childInvoiceLinkDTO).pipe(
            tap((childInvoiceLink) => {
                ctx.setState(
                    patch({
                        linkedObjectsToFile: patch({
                            linkedObjects: updateItem(
                                (linkedObjectToFile: LinkedObjectToFile) =>
                                    !!linkedObjectToFile.childInvoiceLinks.find((childInvoiceLink) => childInvoiceLink.id === action.childLinkId),
                                patch({
                                    childInvoiceLinks: updateItem((childInvoiceLink) => childInvoiceLink.id === action.childLinkId, childInvoiceLink),
                                }),
                            ),
                        }),
                    }),
                );
            }),
        );
    }

    @Action(MapLinksToBudgetExpenditures)
    mapLinksToBudgetExpenditures(ctx: StateContext<GlobalFilesStateModel>) {
        return this.globalFilesService.mapLinksToBudgetExpenditures();
    }
}
