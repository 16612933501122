import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { FetchContacts } from 'src/app/contacts/contacts.actions';
import { ContactState } from 'src/app/contacts/contacts.state';
import { FetchCrmDeals } from 'src/app/crm/crm.actions';
import { CrmState } from 'src/app/crm/crm.state';
import { ContactPerson } from 'src/app/models/ContactPerson';
import CrmDeal from 'src/app/models/CrmDeal';
import Customer from 'src/app/models/Customer';
import Project from 'src/app/models/Project';
import { FetchProjects } from 'src/app/projects/project.actions';
import { ProjectState } from 'src/app/projects/project.state';

import { AddCustomerModalComponent } from '../add-customer-modal/add-customer-modal.component';
import { FetchCustomers } from '../customers.actions';
import { CustomerState } from '../customers.state';

@Component({
    selector: 'app-customers-overview',
    templateUrl: './customers-overview.component.html',
    styleUrls: ['./customers-overview.component.scss'],
})
export class CustomersOverviewComponent implements OnInit {
    @Select(CustomerState.Customers) customers$: Observable<Customer[]>;
    @Select(CrmState.CrmDeals) crmDeals$: Observable<CrmDeal[]>;
    @Select(ContactState.Contacts) contacts$: Observable<ContactPerson[]>;
    @Select(ProjectState.projects) projects$: Observable<Project[]>;

    constructor(private store: Store, private logger: NGXLogger, private modalService: NgbModal) {}

    ngOnInit(): void {
        this.logger.debug('fetching customers');
        this.store.dispatch(new FetchCustomers());
        this.store.dispatch(new FetchCrmDeals());
        this.store.dispatch(new FetchProjects());
        this.store.dispatch(new FetchContacts());
    }

    openAddCustomerModal() {
        this.logger.debug('opening addCustomerModalComponent');
        const modalRef = this.modalService.open(AddCustomerModalComponent, {
            windowClass: 'modal-pane',
            animation: false,
        });
    }
}
