<div *ngIf="this.isLoading">
    <app-loading-message></app-loading-message>
</div>

<div *ngIf="!this.isLoading">
    <table class="table-view">
        <tr>
            <th>Employee</th>
            <th class="td-small"></th>
        </tr>

        <tr *ngFor="let employee of this.relatedEmployees">
            <td>
                <app-pill-employee [employee]="employee"></app-pill-employee>
            </td>
            <td>
                <span
                    ngbDropdown
                    class="dropdown">
                    <button
                        ngbDropdownToggle
                        class="button button--icon button--small dropdown-toggle"
                        id="dropdownMenuButton"></button>
                    <div
                        ngbDropdownMenu
                        class="dropdown-menu dropdown-menu-right"
                        aria-labelledby="dropdownMenuButton">
                        <div class="dropdown-divider"></div>
                        <a
                            class="dropdown-item color--red"
                            (click)="openEmployeeDeleteModal(employee)"
                            >Remove employee</a
                        >
                    </div>
                </span>
            </td>
        </tr>
        <tr>
            <td
                colspan="3"
                class="table-view__add"
                (click)="openAddEmployeeModal()">
                <i class="las la-plus"></i> New employee
            </td>
        </tr>
    </table>
</div>
