<div class="page page__forbidden">
    <div class="page__content">
        <h3>🔒 Forbidden</h3>
        <p>You have no rights to access this page... Sorry.</p>
        <a
            routerLink="/dashboard"
            class="button button--primary"
            >To dashboard</a
        >
    </div>
</div>
