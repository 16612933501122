import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';

import { BillableInformationForEmployee } from '../../models/BillableInformationForEmployee';
import { MONTHS } from '../../models/MONTHS';
import { LegendPosition } from '../../shared/charts/default-line-chart/default-line-chart.component';
import { DateNavigationOptions, DateNavigationType, SelectedDateClass } from '../../shared/date-navigation/date-navigation.component';
import { capitalizeFirstLetter } from '../../shared/utils/string-utils';
import { FetchFinancialDataForOverheadCategoryByCategoryId, GetBillableInformationForEmployeeByEmployeeId } from '../financial.actions';
import { FinancialState } from '../financial.state';

@Component({
    selector: 'app-billable-graph-widget',
    templateUrl: './billable-graph-widget.component.html',
    styleUrls: ['./billable-graph-widget.component.scss'],
})
export class BillableGraphWidgetComponent implements OnInit, OnDestroy {
    @Input() employeeId: string;

    billableInformation: BillableInformationForEmployee;

    data: any[] = undefined;
    labels: string[] = [];
    xLabels: string[];
    legendPosition: LegendPosition = LegendPosition.BOTTOM;

    dateNavigationOptions: DateNavigationOptions;

    average: number;

    private subscriptions = new Subscription();

    constructor(private store: Store) {}

    ngOnInit(): void {
        this.dateNavigationOptions = {
            dateNavigationType: DateNavigationType.YEAR,
            selectedDate: new Date(),
            selectedDateClass: SelectedDateClass.SMALL,
        };

        this.fetchData();
        this.createChart();
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    private createChart() {
        if (!this.billableInformation) return;

        this.xLabels = this.seedLabels();
        this.data = [this.billableInformation.billablePercentage];
        this.labels = ['Percentage billable'];
    }

    private seedLabels() {
        return Object.values(MONTHS).map((label) => capitalizeFirstLetter(label.short));
    }

    private fetchData() {
        this.subscriptions.add(
            this.store.select(FinancialState.billableInformationForEmployee(this.employeeId)).subscribe((billableInformation) => {
                this.billableInformation = billableInformation;
                this.createChart();
                this.calculateAverage();
            }),
        );
    }

    private calculateAverage() {
        this.average =
            this.billableInformation.billablePercentage?.reduce((total: number, percentage: number) => total + percentage, 0.0) / (new Date().getMonth() + 1);
    }

    updateSelectedYear() {
        this.store.dispatch(new GetBillableInformationForEmployeeByEmployeeId(this.employeeId, this.dateNavigationOptions.selectedDate.getFullYear()));
    }
}
