<table
    mat-table
    [dataSource]="product.linkedProducts | filterBy: ['childProduct']:false | filterBy: ['archived']:false"
    matSort>
    <ng-container matColumnDef="parent product">
        <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header>
            Parent product
        </th>
        <td
            mat-cell
            *matCellDef="let parentMap">
            <span
                placement="top"
                [ngbTooltip]="parentMap.productName">
                <app-pill-product
                    [productUuid]="parentMap.productUuid"
                    [productName]="parentMap.productName"></app-pill-product>
            </span>
        </td>
    </ng-container>

    <ng-container matColumnDef="edit">
        <th
            mat-header-cell
            *matHeaderCellDef
            class="td-small"></th>
        <td
            mat-cell
            *matCellDef="let parentMap"
            class="td-small">
            <span
                ngbDropdown
                class="dropdown">
                <button
                    ngbDropdownToggle
                    class="button button--icon button--small dropdown-toggle"
                    id="dropdownMenuButton"></button>
                <div
                    ngbDropdownMenu
                    class="dropdown-menu dropdown-menu-right"
                    aria-labelledby="dropdownMenuButton">
                    <a
                        class="dropdown-item color--red"
                        (click)="openProductParentDeleteModal(product, parentMap.productUuid)"
                        >Remove product</a
                    >
                </div>
            </span>
        </td>
    </ng-container>

    <ng-container matColumnDef="add">
        <td
            mat-footer-cell
            *matFooterCellDef
            colspan="2"
            class="table-view__add"
            (click)="openLinkProductModal()">
            <i class="las la-plus"></i> Add parent products
        </td>
    </ng-container>

    <tr
        mat-header-row
        *matHeaderRowDef="displayedColumns"></tr>

    <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"></tr>

    <tr
        mat-footer-row
        *matFooterRowDef="['add']"></tr>
</table>

<table
    class="table-view"
    *ngIf="!product && selectedParentProducts">
    <tr>
        <th>Parent product</th>
        <th class="td-small"></th>
    </tr>
    <tr *ngFor="let parent of selectedParentProducts">
        <td>
            <app-pill-product
                [productUuid]="parent.uuid"
                [productName]="parent.name"></app-pill-product>
        </td>
        <td>
            <a
                type="button"
                class="las la-times"
                aria-label="Close"
                (click)="removeFromSelectedParentProducts(parent)"
                style="color: red; cursor: pointer"></a>
        </td>
    </tr>

    <tr>
        <td
            colspan="2"
            class="table-view__add"
            (click)="openLinkProductModal()">
            <i class="las la-plus"></i> Add parent products
        </td>
    </tr>
</table>
