import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';

import { Notification } from '../../models/Notification';
import { ArchiveNotification, MarkNotificationAsRead } from '../notifications.actions';

@Component({
    selector: 'app-notification-message',
    templateUrl: './notification-message.component.html',
    styleUrls: ['./notification-message.component.scss'],
})
export class NotificationMessageComponent {
    @Input() notification: Notification;

    constructor(private store: Store, private router: Router, private activeModal: NgbActiveModal) {}

    notificationClick() {
        this.store.dispatch(new MarkNotificationAsRead(this.notification.id));
        this.router.navigate([`/${this.notification.route}`]);
    }

    archiveNotification() {
        this.store.dispatch(new ArchiveNotification(this.notification.id));
    }
}
