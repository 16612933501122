<header>
    <div class="container-fluid">
        <div class="row">
            <div class="col-4">
                <div class="marketing__logo"><img src="../../../assets/img/logo.svg" /> Resumo.io</div>
            </div>
            <div class="col-8">
                <nav>
                    <span *ngIf="user">
                        Welcome back, {{ user.firstName }}
                        <a
                            routerLink="/dashboard"
                            class="button button--outline"
                            >Continue to app</a
                        >
                    </span>
                    <span *ngIf="!user">
                        <a
                            routerLink="/login"
                            class="button button--outline"
                            >Login</a
                        >
                        <a
                            routerLink="/register"
                            class="button button--danger"
                            >Register</a
                        >
                    </span>
                </nav>
            </div>
        </div>
    </div>
</header>
