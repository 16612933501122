import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store, createSelector } from '@ngxs/store';
import { append, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { tap } from 'rxjs/operators';

import { AddBudgetToEmployee, ArchiveBudgetForEmployee } from '../employees/employees.actions';
import Budget from '../models/Budget';
import BudgetTemplate from '../models/BudgetTemplate';
import EmployeeWithBudgets from '../models/EmployeeWithBudgets';
import { EmployeeWithCosts } from '../models/EmployeeWithCosts';
import { ROUTES } from '../models/ROUTES';
import { CreateNotification } from '../notifications/notifications.actions';
import {
    AddBudget,
    AddBudgetExpenditure,
    AddBudgetTemplate,
    ArchiveBudget,
    DeleteBudget,
    DeleteBudgetExpenditure,
    DeleteBudgetTemplate,
    FetchAllBudgets,
    FetchArchivedBudgets,
    FetchBudgetById,
    FetchBudgetTemplates,
    FetchBudgetsApproachingEndDate,
    FetchEmployeeWithBudgets,
    FetchEmployeesWithBudgets,
    GetActualCostsForYear,
    GetBudgetedCostsForYear,
    RemoveCosts,
    UpdateBudget,
    UpdateBudgetExpenditure,
    UpdateBudgetTemplate,
} from './budget.actions';
import { BudgetService } from './budget.service';

export interface BudgetStateModel {
    budgets: Budget[];
    budgetTemplates: BudgetTemplate[];
    employeesWithBudgets: EmployeeWithBudgets[];
    employeeWithBudgets: EmployeeWithBudgets;
    budget: Budget;
    archivedBudgets: Budget[];
    budgetsApproachingEndDate: Budget[];
    actualCosts: EmployeeWithCosts[];
    budgetedCosts: EmployeeWithCosts[];
}

@State<BudgetStateModel>({
    name: 'budget',
    defaults: {
        budgets: null,
        budgetTemplates: null,
        employeesWithBudgets: null,
        employeeWithBudgets: null,
        budget: null,
        archivedBudgets: null,
        budgetsApproachingEndDate: null,
        actualCosts: null,
        budgetedCosts: null,
    },
})
@Injectable()
export class BudgetState {
    currentYear: number = new Date().getFullYear();
    constructor(private budgetService: BudgetService, private store: Store) {}

    @Selector()
    static BudgetTemplates(state: BudgetStateModel): BudgetTemplate[] {
        return state?.budgetTemplates;
    }

    @Selector()
    static EmployeesWithBudgets(state: BudgetStateModel): EmployeeWithBudgets[] {
        return state?.employeesWithBudgets;
    }

    @Selector()
    static EmployeeWithBudgets(state: BudgetStateModel): EmployeeWithBudgets {
        return state?.employeeWithBudgets;
    }

    @Selector()
    static Budget(state: BudgetStateModel): Budget {
        return state?.budget;
    }

    @Selector()
    static Budgets(state: BudgetStateModel): Budget[] {
        return state?.budgets;
    }

    @Selector()
    static ArchivedBudgets(state: BudgetStateModel): Budget[] {
        return state?.archivedBudgets;
    }

    @Selector()
    static BudgetsApproachingEndDate(state: BudgetStateModel): Budget[] {
        return state?.budgetsApproachingEndDate;
    }

    static actualCostsByYear(year: number) {
        return createSelector([BudgetState], (state: BudgetStateModel) => state?.actualCosts.filter((costs) => costs.year === year));
    }

    static budgetedCostsByYear(year: number) {
        return createSelector([BudgetState], (state: BudgetStateModel) => state?.budgetedCosts.filter((costs) => costs.year === year));
    }

    @Action(AddBudgetTemplate)
    addBudgetTemplate(ctx: StateContext<BudgetStateModel>, action: AddBudgetTemplate) {
        return this.budgetService.addBudgetTemplate(action.budgetTemplate).pipe(
            tap((budgetTemplate) => {
                ctx.setState(
                    patch<BudgetStateModel>({
                        budgetTemplates: append<BudgetTemplate>([budgetTemplate]),
                    }),
                );
                this.store.dispatch(
                    new CreateNotification({
                        message: `A budgettemplate with type <strong>${budgetTemplate.budgetType}</strong> is added`,
                        route: ROUTES.BUDGET_TEMPLATES.route,
                    }),
                );
            }),
        );
    }

    @Action(AddBudget)
    addBudget(ctx: StateContext<BudgetStateModel>, action: AddBudget) {
        this.store.dispatch(new RemoveCosts());

        return this.budgetService.addBudget(action.budget, action.linkedEmployees).pipe(
            tap((newBudgets) => {
                const newEmployeesForBudget = JSON.parse(JSON.stringify(ctx.getState().employeesWithBudgets));
                newBudgets.forEach((newBudget) => {
                    const budget: Budget = {
                        uuid: newBudget.uuid,
                        budgetType: newBudget.budgetType,
                        startDate: newBudget.startDate,
                        endDate: newBudget.endDate,
                        totalSpend: newBudget.totalSpend,
                        archived: newBudget.isArchived,
                        budgetExpenditures: newBudget.budgetExpenditures,
                        linkedEmployee: newBudget.linkedEmployee,
                        notificationDate: newBudget.notificationDate,
                        recurring: newBudget.recurring,
                        repeat: newBudget.repeat,
                    };
                    const employee = newBudget.linkedEmployee;
                    const existingEmployee = newEmployeesForBudget.find((e) => e.uuid == employee.uuid);
                    if (existingEmployee) {
                        existingEmployee.budgets.push(budget);
                    } else {
                        newEmployeesForBudget.push({
                            firstName: employee.firstName,
                            lastName: employee.lastName,
                            uuid: employee.uuid,
                            budgets: [budget],
                        });
                    }
                });
                ctx.setState(
                    patch<BudgetStateModel>({
                        employeesWithBudgets: newEmployeesForBudget,
                    }),
                );

                this.store.dispatch(new GetActualCostsForYear(this.currentYear));
                this.store.dispatch(new GetBudgetedCostsForYear(this.currentYear));
                this.store.dispatch(
                    new CreateNotification({
                        message: `A budget with type <strong>${newBudgets[0].budgetType}</strong> is added`,
                        route: ROUTES.BUDGETS.route,
                    }),
                );
                this.store.dispatch(new AddBudgetToEmployee(newBudgets[0]));
            }),
        );
    }

    @Action(AddBudgetExpenditure)
    addBudgetExpenditure(ctx: StateContext<BudgetStateModel>, action: AddBudgetExpenditure) {
        this.store.dispatch(new RemoveCosts());

        return this.budgetService.addBudgetExpenditure(action.budgetId, action.budgetExpenditure).pipe(
            tap((expenditure) => {
                let updatedBudget: Budget;
                let updatedEmployee: EmployeeWithBudgets;
                const employeesWithBudgets: EmployeeWithBudgets[] = JSON.parse(JSON.stringify(ctx.getState().employeesWithBudgets));
                employeesWithBudgets.forEach((employeeWithBudget) =>
                    employeeWithBudget.budgets.forEach((budget) => {
                        if (budget.uuid === action.budgetId) {
                            updatedBudget = budget;
                            updatedEmployee = employeeWithBudget;
                            if (budget.budgetExpenditures) {
                                budget.budgetExpenditures.push(expenditure);
                            } else {
                                budget.budgetExpenditures = [expenditure];
                            }
                        }
                    }),
                );

                ctx.setState(
                    patch<BudgetStateModel>({
                        budget: patch<Budget>({
                            budgetExpenditures: append([expenditure]),
                        }),
                        employeesWithBudgets: employeesWithBudgets,
                    }),
                );

                this.store.dispatch(new GetActualCostsForYear(this.currentYear));
                this.store.dispatch(new GetBudgetedCostsForYear(this.currentYear));
                this.store.dispatch(
                    new CreateNotification({
                        message: `A budgetexpenditure for type <strong>${updatedBudget.budgetType}</strong> for <strong>${updatedEmployee.firstName} ${updatedEmployee.lastName}</strong> is added`,
                        route: ROUTES.BUDGETS.route,
                    }),
                );
            }),
        );
    }

    @Action(FetchAllBudgets)
    fetchAllBudgets(ctx: StateContext<BudgetStateModel>) {
        return this.budgetService.fetchAllBudgets().pipe(
            tap((allBudgets) => {
                ctx.setState(
                    patch<BudgetStateModel>({
                        budgets: allBudgets,
                    }),
                );
            }),
        );
    }

    @Action(FetchBudgetTemplates)
    fetchBudgetTemplates(ctx: StateContext<BudgetStateModel>) {
        return this.budgetService.fetchBudgetTemplates().pipe(
            tap((budgetTemplates) => {
                ctx.setState(
                    patch<BudgetStateModel>({
                        budgetTemplates: budgetTemplates,
                    }),
                );
            }),
        );
    }

    @Action(FetchEmployeesWithBudgets)
    fetchEmployeesWithBudgets(ctx: StateContext<BudgetStateModel>) {
        return this.budgetService.fetchEmployeesForBudget().pipe(
            tap((employeesForBudgets) => {
                ctx.setState(
                    patch<BudgetStateModel>({
                        employeesWithBudgets: employeesForBudgets,
                    }),
                );
            }),
        );
    }

    @Action(FetchEmployeeWithBudgets)
    fetchEmployeeWithBudgets(ctx: StateContext<BudgetStateModel>, action: FetchEmployeeWithBudgets) {}

    @Action(FetchArchivedBudgets)
    fetchArchivedBudgets(ctx: StateContext<BudgetStateModel>) {
        return this.budgetService.fetchArchivedBudgets().pipe(
            tap((archivedBudgets) => {
                ctx.setState(
                    patch<BudgetStateModel>({
                        archivedBudgets: archivedBudgets,
                    }),
                );
            }),
        );
    }

    @Action(FetchBudgetsApproachingEndDate)
    fetchBudgetsApproachingEndDate(ctx: StateContext<BudgetStateModel>) {
        return this.budgetService.fetchBudgetsApproachingEndDate().pipe(
            tap((budgets) => {
                ctx.setState(
                    patch<BudgetStateModel>({
                        budgetsApproachingEndDate: budgets,
                    }),
                );
            }),
        );
    }

    @Action(FetchBudgetById)
    fetchBudgetById(ctx: StateContext<BudgetStateModel>, action: FetchBudgetById) {
        return this.budgetService.fetchBudgetById(action.uuid).pipe(
            tap((budget) =>
                ctx.setState(
                    patch<BudgetStateModel>({
                        budget: budget,
                    }),
                ),
            ),
        );
    }

    @Action(DeleteBudgetTemplate)
    deleteBudgetTemplate(ctx: StateContext<BudgetStateModel>, action: DeleteBudgetTemplate) {
        return this.budgetService.deleteBudgetTemplate(action.uuid).pipe(
            tap((result) => {
                if (result) {
                    const budgetTemplate: BudgetTemplate = ctx.getState().budgetTemplates.find((b) => b.uuid === action.uuid);
                    ctx.setState(
                        patch<BudgetStateModel>({
                            budgetTemplates: removeItem<BudgetTemplate>((b) => b.uuid === action.uuid),
                        }),
                    );
                    this.store.dispatch(
                        new CreateNotification({
                            message: `A budgettemplate with type <strong>${budgetTemplate.budgetType}</strong> is deleted`,
                            route: ROUTES.BUDGET_TEMPLATES.route,
                        }),
                    );
                }
            }),
        );
    }

    @Action(ArchiveBudget)
    archiveBudget(ctx: StateContext<BudgetStateModel>, action: ArchiveBudget) {
        this.store.dispatch(new RemoveCosts());

        return this.budgetService.archiveBudget(action.uuid).pipe(
            tap((archivedBudget) => {
                let employeeForBudget: EmployeeWithBudgets;
                ctx.getState().employeesWithBudgets.forEach((employee) => {
                    if (this.containsChangedBudget(employee, archivedBudget.uuid)) {
                        employeeForBudget = JSON.parse(JSON.stringify(employee));
                    }
                });
                employeeForBudget.budgets.forEach((budget) => {
                    if (budget.uuid === archivedBudget.uuid) {
                        budget.archived = !budget.archived;
                    }
                });

                ctx.setState(
                    patch<BudgetStateModel>({
                        employeesWithBudgets: updateItem<EmployeeWithBudgets>((employee) => {
                            for (let i = 0; i < employee.budgets.length; i++) {
                                if (employee.budgets[i].uuid === archivedBudget.uuid) {
                                    return true;
                                }
                            }
                            return false;
                        }, employeeForBudget),
                    }),
                );
                let message: string;
                if (!archivedBudget.archived) {
                    message = 'unarchived';
                    ctx.setState(
                        patch<BudgetStateModel>({
                            archivedBudgets: removeItem<Budget>((budget) => budget.uuid === archivedBudget.uuid),
                        }),
                    );
                } else {
                    message = 'archived';
                    ctx.setState(
                        patch<BudgetStateModel>({
                            archivedBudgets: append<Budget>([archivedBudget]),
                        }),
                    );
                }

                this.store.dispatch(new GetActualCostsForYear(this.currentYear));
                this.store.dispatch(new GetBudgetedCostsForYear(this.currentYear));
                this.store.dispatch(
                    new CreateNotification({
                        message: `A budget with type <strong>${archivedBudget.budgetType}</strong> is ${message}`,
                        route: ROUTES.BUDGET_TEMPLATES.route,
                    }),
                );
                this.store.dispatch(new ArchiveBudgetForEmployee(archivedBudget));
            }),
        );
    }

    @Action(UpdateBudgetTemplate)
    updateBudgetTemplate(ctx: StateContext<BudgetStateModel>, action: UpdateBudgetTemplate) {
        return this.budgetService.updateBudgetTemplate(action.budgetTemplate).pipe(
            tap(() => {
                ctx.setState(
                    patch<BudgetStateModel>({
                        budgetTemplates: updateItem<BudgetTemplate>((b) => b.uuid === action.budgetTemplate.uuid, action.budgetTemplate),
                    }),
                );
                this.store.dispatch(
                    new CreateNotification({
                        message: `A budgettemplate with type <strong>${action.budgetTemplate.budgetType}</strong> is updated`,
                        route: ROUTES.BUDGET_TEMPLATES.route,
                    }),
                );
            }),
        );
    }

    @Action(UpdateBudget)
    updateBudget(ctx: StateContext<BudgetStateModel>, action: UpdateBudget) {
        this.store.dispatch(new RemoveCosts());

        return this.budgetService.updateBudget(action.budget, action.linkedEmployees).pipe(
            tap((newBudgets) => {
                let message = '';
                const newEmployeesForBudget = JSON.parse(JSON.stringify(ctx.getState().employeesWithBudgets));
                for (let i = 0; i < newBudgets.length; i++) {
                    newEmployeesForBudget.forEach((employee) => {
                        if (newBudgets[i].linkedEmployee.uuid === employee.uuid) {
                            message += `${employee.firstName} ${employee.lastName}, `;
                            const existingBudget = employee.budgets.find((b) => b.uuid === newBudgets[i].uuid);
                            if (existingBudget) {
                                existingBudget.budgetType = newBudgets[i].budgetType;
                                existingBudget.totalSpend = newBudgets[i].totalSpend;
                                existingBudget.endDate = newBudgets[i].endDate;
                                existingBudget.startDate = newBudgets[i].startDate;
                                existingBudget.archived = newBudgets[i].archived;
                                existingBudget.recurring = newBudgets[i].recurring;
                                existingBudget.repeat = newBudgets[i].repeat;
                                existingBudget.sendNotification = newBudgets[i].sendNotification;
                                existingBudget.notificationDate = newBudgets[i].notificationDate;
                            } else {
                                employee.budgets.push(newBudgets[i]);
                            }
                        }
                    });
                }
                ctx.setState(
                    patch<BudgetStateModel>({
                        employeesWithBudgets: newEmployeesForBudget,
                        budget: newBudgets[0],
                    }),
                );

                this.store.dispatch(new GetActualCostsForYear(this.currentYear));
                this.store.dispatch(new GetBudgetedCostsForYear(this.currentYear));
                this.store.dispatch(
                    new CreateNotification({
                        message: `A budget with type <strong>${newBudgets[0].budgetType}</strong> is updated for <strong>${message.substring(
                            0,
                            message.length - 2,
                        )}</strong>`,
                        route: ROUTES.BUDGETS.route,
                    }),
                );
            }),
        );
    }

    @Action(DeleteBudget)
    deleteBudget(ctx: StateContext<BudgetStateModel>, action: DeleteBudget) {
        const deletedBudget: Budget = ctx
            .getState()
            .employeesWithBudgets.find((employeeWithBudget) => employeeWithBudget.budgets.find((budget) => budget.uuid === action.id))
            .budgets.find((budget) => budget.uuid === action.id);

        return this.budgetService.deleteBudget(action.id).pipe(
            tap(() => {
                if (ctx.getState().budgets) {
                    ctx.setState(
                        patch<BudgetStateModel>({
                            budgets: removeItem<Budget>((budget) => budget.uuid === action.id),
                        }),
                    );
                }
                if (ctx.getState().budget?.uuid === action.id) {
                    ctx.setState(
                        patch<BudgetStateModel>({
                            budget: null,
                        }),
                    );
                }
                if (ctx.getState().archivedBudgets) {
                    ctx.setState(
                        patch<BudgetStateModel>({
                            archivedBudgets: removeItem<Budget>((budget) => budget.uuid === action.id),
                        }),
                    );
                }
                if (ctx.getState().employeeWithBudgets) {
                    ctx.setState(
                        patch<BudgetStateModel>({
                            employeeWithBudgets: patch<EmployeeWithBudgets>({
                                budgets: removeItem<Budget>((budget) => budget.uuid === action.id),
                            }),
                        }),
                    );
                }
                if (ctx.getState().employeesWithBudgets) {
                    ctx.setState(
                        patch<BudgetStateModel>({
                            employeesWithBudgets: updateItem<EmployeeWithBudgets>(
                                (employeeWithBudgets) => !!employeeWithBudgets.budgets.find((budget) => budget.uuid === action.id),
                                patch<EmployeeWithBudgets>({
                                    budgets: removeItem<Budget>((budget) => budget.uuid === action.id),
                                }),
                            ),
                        }),
                    );
                }

                this.store.dispatch(new GetActualCostsForYear(this.currentYear));
                this.store.dispatch(new GetBudgetedCostsForYear(this.currentYear));
                this.store.dispatch(
                    new CreateNotification({
                        message: `A budget with type <strong>${deletedBudget.budgetType}</strong> is deleted`,
                        route: ROUTES.BUDGETS.route,
                    }),
                );
            }),
        );
    }

    @Action(UpdateBudgetExpenditure)
    updateBudgetExpenditure(ctx: StateContext<BudgetStateModel>, action: UpdateBudgetExpenditure) {
        this.store.dispatch(new RemoveCosts());

        return this.budgetService.updateBudgetExpenditure(action.budgetExpenditure).pipe(
            tap((expenditure) => {
                let updatedBudget: Budget;
                const employeesWithBudgets: EmployeeWithBudgets[] = JSON.parse(JSON.stringify(ctx.getState().employeesWithBudgets));
                employeesWithBudgets.forEach((employeeWithBudget) =>
                    employeeWithBudget.budgets.forEach((budget) => {
                        if (budget.budgetExpenditures) {
                            budget.budgetExpenditures.forEach((budgetExpenditure, i) => {
                                if (budgetExpenditure.uuid === expenditure.uuid) {
                                    updatedBudget = budget;
                                    budget.budgetExpenditures[i] = expenditure;
                                    return;
                                }
                            });
                        }
                    }),
                );

                this.store.dispatch(new GetActualCostsForYear(this.currentYear));
                this.store.dispatch(new GetBudgetedCostsForYear(this.currentYear));
                ctx.setState(
                    patch<BudgetStateModel>({
                        budget: patch<Budget>({
                            budgetExpenditures: updateItem((e) => e.uuid === expenditure.uuid, expenditure),
                        }),
                        employeesWithBudgets: employeesWithBudgets,
                    }),
                );

                this.store.dispatch(
                    new CreateNotification({
                        message: `A budgetexpunditure for budget with type <strong>${updatedBudget.budgetType}</strong> is updated`,
                        route: ROUTES.BUDGETS.route,
                    }),
                );
            }),
        );
    }

    @Action(DeleteBudgetExpenditure)
    deleteBudgetExpenditure(ctx: StateContext<BudgetStateModel>, action: DeleteBudgetExpenditure) {
        this.store.dispatch(new RemoveCosts());

        return this.budgetService.deleteBudgetExpenditure(action.budget, action.budgetExpenditure).pipe(
            tap(() => {
                ctx.setState(
                    patch<BudgetStateModel>({
                        budget: patch<Budget>({
                            budgetExpenditures: removeItem((e) => e.uuid === action.budgetExpenditure.uuid),
                        }),
                    }),
                );

                this.store.dispatch(new GetActualCostsForYear(this.currentYear));
                this.store.dispatch(new GetBudgetedCostsForYear(this.currentYear));
                this.store.dispatch(
                    new CreateNotification({
                        message: `A budgetexpunditure for budget with type <strong>${action.budget.budgetType}</strong> for <strong>${action.budget.linkedEmployee.firstName} ${action.budget.linkedEmployee.lastName}</strong> is deleted`,
                        route: ROUTES.BUDGETS.route,
                    }),
                );
            }),
        );
    }

    @Action(GetActualCostsForYear)
    getActualCostsForYear(ctx: StateContext<BudgetStateModel>, action: GetActualCostsForYear) {
        this.currentYear = action.year;

        if (ctx.getState().actualCosts) {
            ctx.setState(
                patch<BudgetStateModel>({
                    actualCosts: removeItem((employeeWithCosts) => employeeWithCosts.year === action.year),
                }),
            );
        }
        return this.budgetService.getActualCostsForYear(action.year).pipe(
            tap((employeeWithActualCosts) => {
                ctx.setState(
                    patch<BudgetStateModel>({
                        actualCosts: append(employeeWithActualCosts),
                    }),
                );
            }),
        );
    }

    @Action(GetBudgetedCostsForYear)
    getBudgetedCostsForYear(ctx: StateContext<BudgetStateModel>, action: GetBudgetedCostsForYear) {
        this.currentYear = action.year;

        if (ctx.getState().budgetedCosts) {
            ctx.setState(
                patch<BudgetStateModel>({
                    budgetedCosts: removeItem((employeeWithCosts) => employeeWithCosts.year === action.year),
                }),
            );
        }
        return this.budgetService.getBudgetedCostsForYear(action.year).pipe(
            tap((employeeWithBudgetedCosts) => {
                ctx.setState(
                    patch<BudgetStateModel>({
                        budgetedCosts: append(employeeWithBudgetedCosts),
                    }),
                );
            }),
        );
    }

    // This action will be called from remove-financial-data.interceptor when necessary
    @Action(RemoveCosts)
    removeCosts(ctx: StateContext<BudgetStateModel>) {
        ctx.setState(
            patch<BudgetStateModel>({
                actualCosts: null,
                budgetedCosts: null,
            }),
        );
    }

    containsChangedBudget(employee: EmployeeWithBudgets, budgetId: string) {
        for (let i = 0; i < employee.budgets.length; i++) {
            if (employee.budgets[i].uuid === budgetId) {
                return true;
            }
        }
        return false;
    }
}
