import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { fetchLeads } from 'src/app/leads/lead.actions';
import { LeadState } from 'src/app/leads/lead.state';
import Lead from 'src/app/models/Lead';
import MailchimpTagModel from 'src/app/models/MailchimpTagModel';
import RecipientListModel from 'src/app/models/RecipientListModel';
import RecipientModel from 'src/app/models/RecipientModel';

import { RecipientInfoModalComponent } from '../../shared/mailchimp/recipient-info-modal/recipient-info-modal.component';
import { AddRecipient } from '../mailchimp.actions';
import { MailchimpService } from '../mailchimp.service';
import UpdateLeadModel from './update-lead-model';

@Component({
    selector: 'app-mailchimp-recipients-modal',
    templateUrl: './mailchimp-recipients-modal.component.html',
    styleUrls: ['./mailchimp-recipients-modal.component.scss'],
})
export class MailchimpRecipientsModalComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Select(LeadState.getLeads) leads$: Observable<Lead[]>;

    @Input() list: RecipientListModel;

    newRecipientFormGroup: FormGroup;
    tagFormGroup: FormGroup;
    pending = true;
    leads: Lead[];
    recipients: RecipientModel[];
    selectedRecipient: RecipientModel;
    private tags: MailchimpTagModel[];

    constructor(
        public activeModal: NgbActiveModal,
        private formBuilder: FormBuilder,
        private mailchimpService: MailchimpService,
        private store: Store,
        private modalService: NgbModal,
    ) {}

    ngOnInit(): void {
        this.store.dispatch(new fetchLeads());
        this.newRecipientFormGroup = this.formBuilder.group({
            lead: ['', [Validators.required]],
        });

        this.getLeads();

        this.tagFormGroup = this.formBuilder.group({
            tag: ['', [Validators.required]],
        });
    }

    async getLeads() {
        this.subscriptions.add(this.leads$.subscribe(() => this.refresh()));
        this.subscriptions.add(this.mailchimpService.getTags(this.list.id).subscribe((t) => (this.tags = t)));
    }

    generateArray(obj) {
        return Object.keys(obj).map((key) => {
            return { key: key, value: obj[key] };
        });
    }

    refresh() {
        this.pending = false;
    }

    showMemberDetails(member: RecipientModel) {
        const modalRef = this.modalService.open(RecipientInfoModalComponent, {
            windowClass: 'modal-prompt',
        });
        modalRef.componentInstance.recipient = member;
    }

    addLeadToList() {
        const model: UpdateLeadModel = {
            listId: this.list.id,
            leadId: this.newRecipientFormGroup.get('lead').value,
        };
        this.subscriptions.add(this.store.dispatch(new AddRecipient(model)).subscribe());
        this.newRecipientFormGroup.get('lead').setValue('');
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
