import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { AddMemberModalComponent } from 'src/app/companies/add-member-modal/add-member-modal.component';
import { CompanyState } from 'src/app/companies/company.state';
import { Company } from 'src/app/models/Company';
import { UserState } from 'src/app/users/user.state';

@Component({
    selector: 'app-company-members',
    templateUrl: './company-members.component.html',
    styleUrls: ['./company-members.component.scss'],
})
export class CompanyMembersComponent {
    @Select(CompanyState.company) company$: Observable<Company>;
    @Select(UserState.hasUserOwnerRole) isOwner$: Observable<boolean>;

    constructor(private modalService: NgbModal, private store: Store, private logger: NGXLogger) {}

    openAddMemberModal() {
        this.logger.debug('Opening addMemberModal');
        this.modalService.open(AddMemberModalComponent, {
            windowClass: 'modal-pane',
            animation: false,
        });
    }
}
