import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subscription } from 'rxjs';
import { ModuleEnum } from 'src/app/models/ModuleEnum';
import { Partner } from 'src/app/models/Partner';
import { FetchProjects } from 'src/app/projects/project.actions';
import { SettingsState } from 'src/app/settings/settings.state';

import { ContactpersonDetailModalComponent } from '../../contacts/contactperson-detail-modal/contactperson-detail-modal.component';
import { PartnerAddModalComponent } from '../partner-add-modal/partner-add-modal.component';
import { PartnerDeleteModalComponent } from '../partner-delete-modal/partner-delete-modal.component';
import { PartnerUpdateModalComponent } from '../partner-update-modal/partner-update-modal.component';
import { PartnerState } from '../partners.state';

@Component({
    selector: 'app-partner-list',
    templateUrl: './partner-list.component.html',
    styleUrls: ['./partner-list.component.scss'],
})
export class PartnerListComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Select(SettingsState.TableDisplayColumns('partner-list')) displayedColumns$: Observable<string[]>;
    @Select(PartnerState.getPartners) partners$: Observable<Partner[]>;

    filter: string;
    eModule = ModuleEnum;

    displayedColumns: string[] = ['title', 'amountOfContactPersons', 'edit'];
    dataSource: MatTableDataSource<Partner>;
    @ViewChild(MatSort) sort: MatSort;

    constructor(private modalService: NgbModal, private store: Store, private logger: NGXLogger) {}

    ngOnInit(): void {
        this.subscriptions.add(
            this.displayedColumns$.subscribe((columns) => {
                this.displayedColumns = columns ? columns : PartnerListColumns.DEFAULT;
            }),
        );
        this.logger.debug('Fetching works');
        this.subscriptions.add(
            this.partners$.subscribe((partners) => {
                if (!partners) {
                    this.store.dispatch(new FetchProjects()).subscribe();
                } else {
                    let sorted = JSON.parse(JSON.stringify(partners));
                    sorted = sorted.sort((a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase()));
                    this.dataSource = new MatTableDataSource(sorted);
                    this.dataSource.sort = this.sort;
                }
            }),
        );
    }

    applyFilter(filterValue) {
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }

    openAddPartnerModal() {
        this.logger.debug('Opening partnerAddModal');
        this.modalService.open(PartnerAddModalComponent, {
            windowClass: 'modal-prompt',
        });
    }

    openUpdatePartnerModal(partner: Partner) {
        this.logger.debug('Opening partnerUpdateModal');
        const modalRef = this.modalService.open(PartnerUpdateModalComponent);
        modalRef.componentInstance.partner = partner;
    }

    openDeletePartnerModal(partner: Partner) {
        this.logger.debug('Opening partnerDeleteModal');
        const modalRef = this.modalService.open(PartnerDeleteModalComponent, {
            windowClass: 'modal-prompt',
        });
        modalRef.componentInstance.partner = partner;
    }

    openAddContactPersonModal(partnerUuid: string) {
        this.logger.debug('Opening contactpersonDetailModal');
        const modalRef = this.modalService.open(ContactpersonDetailModalComponent);
        modalRef.componentInstance.partnerUuid = partnerUuid;
        modalRef.componentInstance.inOverview = true;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    getAllColumns() {
        return PartnerListColumns.ALL;
    }

    getDefaultColumns() {
        return PartnerListColumns.DEFAULT;
    }
}

export class PartnerListColumns {
    static readonly TITLE = 'title';
    static readonly AMOUNT_OF_CONTACT_PERSONS = 'amountOfContactPersons';
    static readonly EDIT = 'edit';

    static readonly ALL = [this.TITLE, this.AMOUNT_OF_CONTACT_PERSONS, this.EDIT];
    static readonly DEFAULT = [this.TITLE, this.AMOUNT_OF_CONTACT_PERSONS, this.EDIT];
}
