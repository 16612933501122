<h2 class="modal-title m-b-4">Overhead categories</h2>

<app-date-navigation
    (selectedDateUpdated)="updateSelectedDate()"
    [dateNavigationOptions]="dateNavigationOptions"></app-date-navigation>

<table
    (matSortChange)="onSortChange($event)"
    [dataSource]="datasource"
    class="m-t-4"
    mat-table
    matSort>

    <ng-container matColumnDef="overheadCategory">
        <th *matHeaderCellDef mat-header-cell>
            Overhead category
        </th>
        <td *matCellDef="let financialData" mat-cell>
            <app-pill-overhead-category [onFinancialPage]="true" [overheadCategoryId]="financialData.objectId"></app-pill-overhead-category>
        </td>
    </ng-container>

    <ng-container matColumnDef="total">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>Total costs for {{dateNavigationOptions.selectedDate | date:'yyyy'}}</th>
        <td *matCellDef="let financialData" mat-cell>{{financialData.total | currency: 'EUR'}}</td>
    </ng-container>

    <ng-container matColumnDef="average">
        <th *matHeaderCellDef mat-header-cell>
            Average costs for {{dateNavigationOptions.selectedDate | date:'yyyy'}}
        </th>
        <td *matCellDef="let financialData" mat-cell>
            {{financialData.average | currency: 'EUR'}}
        </td>
    </ng-container>

    <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
    <tr (click)="openOverheadCategoryModal(row)" *matRowDef="let row; columns: displayedColumns" class="pointer"
        mat-row></tr>

    <tr *matNoDataRow>
        <td
            [attr.colspan]="displayedColumns.length"
            class="mat-cell">
            <ng-container *ngIf="loadingData; else loading">
                There are no overhead categories with financial data found for this year...
            </ng-container>
        </td>
    </tr>
</table>

<ng-template #loading>
    <app-loading-message></app-loading-message>
</ng-template>
