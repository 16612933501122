import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PasswordStrengthMeterModule } from 'angular-password-strength-meter';
import { NgxTrimDirectiveModule } from 'ngx-trim-directive';

import { SharedModule } from './../shared/shared.module';
import { UserChangePasswordModalComponent } from './user-change-password-modal/user-change-password-modal.component';
import { UserDetailComponent } from './user-detail/user-detail.component';
import { UserManageTwoFactorDeleteModalComponent } from './user-manage-two-factor-delete-modal/user-manage-two-factor-delete-modal.component';
import { UserManageTwoFactorModalComponent } from './user-manage-two-factor-modal/user-manage-two-factor-modal.component';
import { UserUpdateModalComponent } from './user-update-modal/user-update-modal.component';

@NgModule({
    declarations: [
        UserUpdateModalComponent,
        UserChangePasswordModalComponent,
        UserDetailComponent,
        UserManageTwoFactorModalComponent,
        UserManageTwoFactorDeleteModalComponent,
    ],
    imports: [CommonModule, SharedModule, PasswordStrengthMeterModule, NgxTrimDirectiveModule],
})
export class UsersModule {}
