<app-app-holder>
    <app-navbar></app-navbar>
    <app-content>
        <div class="app__title">
            <h1>Planning</h1>
            <div class="d-flex">
                <input
                    class="radio-btn"
                    type="radio"
                    id="calendar"
                    value="calendar"
                    (change)="switchView()"
                    [(ngModel)]="view" />
                <label
                    class="radio-label--icon radio-label"
                    for="calendar"
                    [ngbTooltip]="calendarTip"
                    ><i class="las la-calendar"></i
                ></label>
                <input
                    class="radio-btn"
                    type="radio"
                    id="timeline"
                    value="timeline"
                    (change)="switchView()"
                    [(ngModel)]="view" />
                <label
                    class="radio-label--icon radio-label"
                    for="timeline"
                    [ngbTooltip]="timelineTip"
                    ><i class="las la-stream"></i
                ></label>

                <ng-template #calendarTip>Annual overview - calendar</ng-template>
                <ng-template #timelineTip>Monthly overview - timeline</ng-template>
            </div>
        </div>
        <app-planning-timeline
            *appIsPermitted="{
                module: 'PROJECTS',
                operator: 'AND',
                actions: ['READ']
            }"></app-planning-timeline>
    </app-content>
</app-app-holder>
