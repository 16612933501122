<div class="modal-header">
    <h2
        class="modal-title"
        id="exampleModalLabel">
        Archive company
    </h2>
    <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <strong
        >Are you sure you want to archive <span *ngIf="company$ | async as company">{{ company.title }}</span
        >?</strong
    >
    If you archive your company, you won't be able to access any resumes, companies or projects that you have added. It is possible to unarchive this company at
    a later date.
</div>

<div class="modal-footer">
    <button
        class="button button--danger"
        (click)="archiveCompany()">
        Archive
    </button>
    <button
        type="button"
        class="button button--outline"
        (click)="activeModal.close()">
        Cancel
    </button>
</div>
