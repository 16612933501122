import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { CustomersOverviewComponent } from './customers-overview/customers-overview.component';

export const routes: Routes = [
    { path: '', redirectTo: 'overview', pathMatch: 'full' },
    { path: 'overview', component: CustomersOverviewComponent },
];

export const routing: ModuleWithProviders<any> = RouterModule.forChild(routes);
