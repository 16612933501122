import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';

import { SharedModule } from '../shared/shared.module';
import { VeldaArticlesArticlesCategoriesListComponent } from './velda-articles-articles-categories/velda-articles-articles-categories-list/velda-articles-articles-categories-list.component';
import { VeldaArticlesArticlesCategoriesOverviewComponent } from './velda-articles-articles-categories/velda-articles-articles-categories-overview/velda-articles-articles-categories-overview.component';
import { VeldaArticlesArticlesDetailModalComponent } from './velda-articles-articles/velda-articles-articles-detail-modal/velda-articles-articles-detail-modal.component';
import { VeldaArticlesArticlesListComponent } from './velda-articles-articles/velda-articles-articles-list/velda-articles-articles-list.component';
import { VeldaArticlesArticlesOverviewComponent } from './velda-articles-articles/velda-articles-articles-overview/velda-articles-articles-overview.component';
import { VeldaArticlesBrandsListComponent } from './velda-articles-brands/velda-articles-brands-list/velda-articles-brands-list.component';
import { VeldaArticlesBrandsOverviewComponent } from './velda-articles-brands/velda-articles-brands-overview/velda-articles-brands-overview.component';
import { VeldaArticlesCollectionsListComponent } from './velda-articles-collections/velda-articles-collections-list/velda-articles-collections-list.component';
import { VeldaArticlesCollectionsOverviewComponent } from './velda-articles-collections/velda-articles-collections-overview/velda-articles-collections-overview.component';
import { VeldaArticlesDealersListComponent } from './velda-articles-dealers/velda-articles-dealers-list/velda-articles-dealers-list.component';
import { VeldaArticlesDealersOverviewComponent } from './velda-articles-dealers/velda-articles-dealers-overview/velda-articles-dealers-overview.component';
import { VeldaArticlesSetsListComponent } from './velda-articles-sets/velda-articles-sets-list/velda-articles-sets-list.component';
import { VeldaArticlesSetsOverviewComponent } from './velda-articles-sets/velda-articles-sets-overview/velda-articles-sets-overview.component';
import { VeldaArticlesSubcollectionsListComponent } from './velda-articles-subcollections/velda-articles-subcollections-list/velda-articles-subcollections-list.component';
import { VeldaArticlesSubcollectionsOverviewComponent } from './velda-articles-subcollections/velda-articles-subcollections-overview/velda-articles-subcollections-overview.component';
import { VeldaArticlesSubnavComponent } from './velda-articles-subnav/velda-articles-subnav.component';
import { routing } from './velda-articles.routing';

@NgModule({
    declarations: [
        VeldaArticlesSubnavComponent,
        VeldaArticlesBrandsOverviewComponent,
        VeldaArticlesBrandsListComponent,
        VeldaArticlesCollectionsOverviewComponent,
        VeldaArticlesCollectionsListComponent,
        VeldaArticlesSetsOverviewComponent,
        VeldaArticlesSetsListComponent,
        VeldaArticlesSubcollectionsListComponent,
        VeldaArticlesSubcollectionsOverviewComponent,
        VeldaArticlesArticlesListComponent,
        VeldaArticlesArticlesOverviewComponent,
        VeldaArticlesArticlesCategoriesOverviewComponent,
        VeldaArticlesArticlesCategoriesListComponent,
        VeldaArticlesDealersListComponent,
        VeldaArticlesDealersOverviewComponent,
        VeldaArticlesArticlesDetailModalComponent,
    ],
    imports: [CommonModule, SharedModule, routing, CdkTableModule, MatTableModule, MatSortModule, NgbDropdownModule, AutocompleteLibModule],
})
export class VeldaArticlesModule {}
