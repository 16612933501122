import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subscription } from 'rxjs';
import { FetchApplicants } from 'src/app/applicants/applicant.actions';
import { CompanyState } from 'src/app/companies/company.state';
import { FetchContacts } from 'src/app/contacts/contacts.actions';
import { FetchCrmDeals } from 'src/app/crm/crm.actions';
import { FetchCustomers } from 'src/app/customers/customers.actions';
import { FetchEmployees } from 'src/app/employees/employees.actions';
import { fetchLeads } from 'src/app/leads/lead.actions';
import { GlobalTag } from 'src/app/models/GlobalTag';
import { Resume } from 'src/app/models/resume/Resume';
import { FetchPartners } from 'src/app/partners/partners.actions';
import { FetchProducts } from 'src/app/products/product.actions';
import { FetchProjects } from 'src/app/projects/project.actions';
import { AddTagToResume, GetExperiences, GetResume, RemoveTagFromResume } from 'src/app/resumes/resume.actions';
import { ResumeState } from 'src/app/resumes/resume.state';
import { FetchWarehouseLocations } from 'src/app/warehouse-locations/warehouse.location.actions';
import { FetchWarehouses } from 'src/app/warehouses/warehouse.actions';

import {
    AddGlobalTagToMultipleObjects,
    AddGlobalTagToObject,
    AddUndefinedObjectTag,
    FetchGlobalTags,
    RemoveGlobalTagFromMultipleObjects,
    RemoveGlobalTagFromObject,
    RemoveUndefinedObjectTag,
} from './global-tags.actions';
import { GlobalTagsState } from './global-tags.state';

@Component({
    selector: 'app-global-tags',
    templateUrl: './global-tags.component.html',
    styleUrls: ['./global-tags.component.scss'],
})
export class GlobalTagsComponent implements OnInit, OnDestroy {
    @Select(GlobalTagsState.GlobalTags) globalTags$: Observable<GlobalTag[]>;
    @Select(GlobalTagsState.UndefinedObjectTags) undefinedObjectTags$: Observable<GlobalTag[][]>;
    @Select(CompanyState.companyUuid) companyUuid$: Observable<string>;
    @Select(ResumeState.currentResume) currentResume$: Observable<Resume>;
    @Input() objectUuid: string;
    @Input() objectUuids: [];
    @Input() type: string;
    @Input() resumeUuid: string;
    @Input() isEditing = false;
    @Input() isBulkAdd = false;
    @Input() experienceTags: string[] = [];
    allTags: GlobalTag[] = [];
    allTagsString: any[] = [];
    objectTags: GlobalTag[] = [];
    objectTagsString: any[] = [];
    tagSuggestions: any[] = [];
    currentUndefinedObjectKey: number = undefined;
    isLoading = true;

    @Output() experienceTagAdd = new EventEmitter<GlobalTag>();
    @Output() experienceTagRemove = new EventEmitter<GlobalTag>();

    private subscriptions = new Subscription();

    constructor(private store: Store, private logger: NGXLogger) {}

    ngOnInit(): void {
        if (this.objectUuid) {
            this.subscriptions.add(
                this.globalTags$.subscribe((tags) => {
                    if (tags) {
                        this.allTags = tags;
                        this.allTagsString = [];
                        tags.forEach((el) => {
                            this.allTagsString.push(el.title);
                        });
                        if (tags.every((tag) => tag.taggables != undefined)) {
                            this.objectTags = tags.filter((tag) => tag.taggables.find((object) => object.uuid == this.objectUuid));
                            if (this.objectTags) {
                                this.objectTagsString = [];
                                this.objectTags.forEach((el) => {
                                    this.objectTagsString.push(el.title);
                                });
                            }
                            this.isLoading = false;
                        }

                        if (this.type === 'experience') {
                            this.objectTags = this.allTags.filter((t) => this.experienceTags.some((et) => et === t.title));
                            this.objectTagsString = this.objectTags.map((tag) => tag.title);
                        }
                    } else {
                        this.logger.debug('Fetching globalTags');
                        this.store.dispatch(new FetchGlobalTags());
                    }
                }),
            );
        } else {
            this.subscriptions.add(
                this.globalTags$.subscribe((tags) => {
                    if (tags) {
                        this.allTags = tags;
                        this.allTagsString = [];
                        tags.forEach((el) => {
                            this.allTagsString.push(el.title);
                        });
                        this.isLoading = false;
                    } else {
                        this.logger.debug('Fetching globalTags');
                        this.store.dispatch(new FetchGlobalTags());
                    }
                }),
            );
            this.subscriptions.add(
                this.undefinedObjectTags$.subscribe((undefinedObjectTags) => {
                    if (this.currentUndefinedObjectKey == undefined) {
                        this.currentUndefinedObjectKey = undefinedObjectTags.length;
                    }
                    this.objectTags = undefinedObjectTags[this.currentUndefinedObjectKey];
                }),
            );
        }

        this.checkSuggestions();
    }

    switchEdit() {
        this.isEditing = !this.isEditing;
    }

    add(event) {
        let tag = this.allTags.find((t) => t.title == event.value);
        if (tag == undefined) {
            tag = { uuid: null, title: event.value, taggables: [] };
        }

        if (this.type === 'experience') {
            this.experienceTagAdd.emit(tag);
            if (!this.objectTags) this.objectTags = [];
            this.objectTags.push(tag);
            return;
        }

        if (this.objectUuid && !this.isBulkAdd) {
            this.logger.debug('Adding global tag to obj');
            this.subscriptions.add(
                this.store.dispatch(new AddGlobalTagToObject(tag, this.objectUuid)).subscribe(() => {
                    this.refresh();
                }),
            );

            if (this.type === 'resume') this.store.dispatch(new AddTagToResume(tag, this.objectUuid));
        } else if (this.objectUuids && this.isBulkAdd) {
            this.logger.debug('Adding global tag to multiple objects');
            this.subscriptions.add(
                this.store.dispatch(new AddGlobalTagToMultipleObjects(tag, this.objectUuids)).subscribe(() => {
                    this.refresh();
                }),
            );
        } else {
            this.store.dispatch(new AddUndefinedObjectTag(tag, this.currentUndefinedObjectKey));
        }
    }

    remove(event) {
        let tag = this.objectTags?.find((t) => t.title == event.value);
        if (tag == undefined) {
            tag = this.objectTags?.find((t) => t.title == event);
        }

        if (this.type === 'experience') {
            this.experienceTagRemove.emit(tag);
            this.objectTags = this.objectTags.filter((t) => t.uuid !== tag.uuid);
            this.objectTagsString = this.objectTags.map((tag) => tag.title);
            return;
        }

        if (this.objectUuid && !this.isBulkAdd) {
            this.logger.debug('Deleting globalTag from obj');
            this.subscriptions.add(
                this.store.dispatch(new RemoveGlobalTagFromObject(tag, this.objectUuid)).subscribe(() => {
                    this.refresh();

                    if (this.type === 'resume') {
                        this.tagSuggestions.push(tag.title);
                        this.store.dispatch(new RemoveTagFromResume(tag.uuid, this.objectUuid));
                    }
                }),
            );
        } else if (this.objectUuids && this.isBulkAdd) {
            this.logger.debug('Deleting global tag from multiple objects');
            tag = this.allTags.find((t) => t.title == event.value);
            this.subscriptions.add(
                this.store.dispatch(new RemoveGlobalTagFromMultipleObjects(tag, this.objectUuids)).subscribe(() => {
                    this.refresh();
                }),
            );
        } else {
            this.store.dispatch(new RemoveUndefinedObjectTag(tag, this.currentUndefinedObjectKey));
        }
    }

    refresh() {
        switch (this.type) {
            case 'applicant':
                this.logger.debug('Fetching applicants');
                this.store.dispatch(new FetchApplicants());
                break;
            case 'project':
                this.logger.debug('Fetching project');
                this.store.dispatch(new FetchProjects());
                break;
            case 'employee':
                this.logger.debug('Fetching employees');
                this.store.dispatch(new FetchEmployees());
                break;
            case 'contact':
                this.logger.debug('Fetching contacts');
                this.store.dispatch(new FetchContacts());
                break;
            case 'product':
                this.logger.debug('Fetching product');
                this.store.dispatch(new FetchProducts());
                break;
            case 'warehouse':
                this.logger.debug('Fetching warehouse');
                this.store.dispatch(new FetchWarehouses());
                break;
            case 'warehouselocation':
                this.logger.debug('Fetching warehouselocation');
                this.store.dispatch(new FetchWarehouseLocations());
                break;
            case 'partner':
                this.logger.debug('Fetching partners');
                this.store.dispatch(new FetchPartners());
                break;
            case 'experience':
                this.logger.debug('Fetching experiences');
                this.store.dispatch(new GetExperiences(this.resumeUuid));
                break;
            case 'deal':
                this.logger.debug('Fetching deals');
                this.store.dispatch(new FetchCrmDeals());
                break;
            case 'lead':
                this.logger.debug('Fetching leads');
                this.store.dispatch(new fetchLeads());
                break;
            case 'customer':
                this.logger.debug('Fetching customers');
                this.store.dispatch(new FetchCustomers());
                break;
            case 'resume':
                break;
        }
    }

    checkSuggestions() {
        switch (this.type) {
            case 'resume':
                this.subscriptions.add(
                    this.store.dispatch(new GetResume(this.objectUuid)).subscribe(() => {
                        this.store.dispatch(new GetExperiences(this.objectUuid)).subscribe(() => {
                            this.currentResume$.subscribe((resume) => {
                                resume.experiences?.forEach((e) => {
                                    e.tags.forEach((experienceTag) => {
                                        if (!resume.globalTags.find((resumeTag) => resumeTag.title === experienceTag)) {
                                            this.tagSuggestions.push(experienceTag);
                                        }
                                    });
                                });

                                const uniqueSetOfSuggestions = [...new Set(this.tagSuggestions)];
                                this.tagSuggestions = uniqueSetOfSuggestions;
                            });
                        });
                    }),
                );
                break;
        }
    }

    addSuggestion($event) {
        const tag = this.allTags.find((t) => t.title == $event.target.innerText);

        this.logger.debug('Adding global tag to obj');
        this.subscriptions.add(
            this.store.dispatch(new AddGlobalTagToObject(tag, this.objectUuid)).subscribe(() => {
                this.refresh();
                this.tagSuggestions = this.tagSuggestions.filter((t) => t !== tag.title);
            }),
        );

        if (this.type === 'resume') this.store.dispatch(new AddTagToResume(tag, this.objectUuid));
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
