import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { NgbDropdownModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { TagInputModule } from 'ngx-chips';
import { OrderModule } from 'ngx-order-pipe';
import { NgPipesModule } from 'ngx-pipes';

import { ProductsModule } from '../products/products.module';
import { SharedModule } from '../shared/shared.module';
import { WarehouseAddModalComponent } from './warehouse-add-modal/warehouse-add-modal.component';
import { WarehouseDeleteModalComponent } from './warehouse-delete-modal/warehouse-delete-modal.component';
import { WarehouseDetailModalComponent } from './warehouse-detail-modal/warehouse-detail-modal.component';
import { WarehouseUpdateModalComponent } from './warehouse-update-modal/warehouse-update-modal.component';
import { WarehousesListComponent } from './warehouses-list/warehouses-list.component';
import { WarehousesOverviewComponent } from './warehouses-overview/warehouses-overview.component';
import { routing } from './warehouses.routing';

@NgModule({
    declarations: [
        WarehousesOverviewComponent,
        WarehouseAddModalComponent,
        WarehouseUpdateModalComponent,
        WarehouseDeleteModalComponent,
        WarehouseDetailModalComponent,
        WarehousesListComponent,
    ],
    imports: [
        CommonModule,
        SharedModule,
        AutocompleteLibModule,
        routing,
        NgbModule,
        NgbDropdownModule,
        NgPipesModule,
        OrderModule,
        TagInputModule,
        ProductsModule,
        CdkTableModule,
        MatTableModule,
        MatSortModule,
    ],
    exports: [WarehouseAddModalComponent],
})
export class WarehousesModule {}
