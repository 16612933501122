<div>
    <div
        class="sidebar-spacing"
        *ngIf="project">
        <div class="d-flex space-between">
            <h3>{{ project.title }}</h3>
            <div
                ngbDropdown
                placement="bottom-right"
                class="dropdown">
                <button
                    type="button"
                    ngbDropdownToggle
                    class="button button--small button--icon dropdown-toggle"
                    id="dropdownMenuButton"></button>
                <div
                    ngbDropdownMenu
                    class="dropdown-menu"
                    aria-labelledby="dropdownMenuButton">
                    <a
                        class="dropdown-item"
                        (click)="openWorkUpdateModal(project)"
                        >Edit project</a
                    >
                    <a
                        class="dropdown-item color--red"
                        (click)="openWorkDeleteModal(project)"
                        >Delete</a
                    >
                </div>
            </div>
        </div>
        <small *ngFor="let tag of project.globalTags">
            {{ tag.title }}
        </small>
        <div *ngIf="addressNotEmpty; else noaddress">
            <small
                >{{ project?.location?.street }}
                <span *ngIf="project?.location?.number != 0">{{ project?.location?.number }}</span>
                {{ project?.location?.bus }}
                <span *ngIf="project?.location?.ext != 0">{{ project?.location?.ext }}</span
                ><br />{{ project?.location?.postal }}
                {{ project?.location?.city }}
                {{ project?.location?.country.iso2 }}</small
            >
        </div>
        <ng-template #noaddress> <small>No address added...</small><br /> </ng-template>
    </div>

    <span class="divider"></span>

    <nav class="app__subnav">
        <a
            [routerLink]="['/', 'projects', projectUuid, 'info']"
            *ngIf="moduleService.checkModuleActive(eModule.CONTACTS)"
            [class.app__subnav__active]="isActive(['/', 'projects', projectUuid, 'info'])"
            >Info</a
        >
        <a
            *ngIf="this.moduleService.checkModuleActive(eModule.EMPLOYEES)"
            [routerLink]="['/', 'projects', projectUuid, 'employees']"
            [class.app__subnav__active]="isActive(['/', 'projects', projectUuid, 'employees'])"
            >Employees</a
        >
        <a
            *ngIf="this.moduleService.checkModuleActive(eModule.RESUME)"
            [routerLink]="['/', 'projects', projectUuid, 'resumes']"
            [class.app__subnav__active]="isActive(['/', 'projects', projectUuid, 'resumes'])"
            >Resumes</a
        >
        <a
            *ngIf="this.moduleService.checkModuleActive(eModule.INVENTORY)"
            [routerLink]="['/', 'projects', projectUuid, 'products']"
            [class.app__subnav__active]="isActive(['/', 'projects', projectUuid, 'products'])"
            >Products</a
        >
    </nav>
</div>
