import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { AddCrmdealModalComponent } from 'src/app/crm/add-crmdeal-modal/add-crmdeal-modal.component';
import { FetchCrmStages } from 'src/app/crm/crm.actions';
import { CrmState } from 'src/app/crm/crm.state';
import { AddCustomer } from 'src/app/customers/customers.actions';
import { ContactInformation } from 'src/app/models/ContactInformation';
import CrmStage from 'src/app/models/CrmStage';
import Customer from 'src/app/models/Customer';
import Lead from 'src/app/models/Lead';

import { ArchiveLead } from '../lead.actions';

@Component({
    selector: 'app-lead-to-deal-modal',
    templateUrl: './lead-to-deal-modal.component.html',
    styleUrls: ['./lead-to-deal-modal.component.scss'],
})
export class LeadToDealModalComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Select(CrmState.CrmStages) stages$: Observable<CrmStage[]>;
    @Input() lead: Lead;

    leadToDealForm: FormGroup;

    isLoading = true;

    constructor(
        private logger: NGXLogger,
        private store: Store,
        public activeModal: NgbActiveModal,
        private fb: FormBuilder,
        private modalService: NgbModal,
        private toastr: ToastrService,
    ) {}

    ngOnInit(): void {
        this.logger.debug('Fetching CrmStages');
        this.subscriptions.add(
            this.stages$.subscribe((stages) => {
                if (stages != null) {
                    this.leadToDealForm = this.fb.group({
                        stage: [stages.find((s) => s.sequence == 0), [Validators.required]],
                        createCustomer: [false],
                        archiveLead: [false],
                    });
                    this.isLoading = false;
                } else {
                    this.store.dispatch(new FetchCrmStages());
                }
            }),
        );
    }

    submit() {
        if (this.getToggle('createCustomer')) {
            const contactInfo: ContactInformation = {
                uuid: null,
                phoneNumber: this.lead.phoneNumber,
                email: this.lead.email,
                website: null,
                linkedIn: null,
                facebook: null,
                github: null,
            };
            const customer: Customer = {
                uuid: null,
                createdOn: null,
                lastModified: null,
                firstName: this.lead.firstName ? this.lead.firstName : '',
                lastName: this.lead.lastName ? this.lead.lastName : '',
                address: this.lead.address ? this.lead.address : null,
                contactInformation: contactInfo,
                contactPersonUuid: null,
                contactPersonFirstName: null,
                contactPersonLastName: null,
                creatorId: null,
                crmDeals: [],
                projects: [],
            };
            this.subscriptions.add(
                this.store.dispatch(new AddCustomer(customer)).subscribe((state) => {
                    this.toastr.success('Customer ' + customer.firstName + ' ' + customer.lastName + ' successfully created.');
                    this.createDeal(state.customers.customers.find((c) => c.firstName + c.lastName == customer.firstName + customer.lastName));
                }),
            );
        } else {
            this.createDeal(null);
        }
    }

    createDeal(customer: Customer | null) {
        const modalRef = this.modalService.open(AddCrmdealModalComponent, {
            windowClass: 'modal-pane',
            animation: false,
        });
        modalRef.componentInstance.customer = customer;
        modalRef.componentInstance.description = this.getFields();
        modalRef.componentInstance.stage = this.leadToDealForm.get('stage').value;
        this.subscriptions.add(
            modalRef.componentInstance.emitObject.subscribe((deal) => {
                if (this.getToggle('archiveLead')) {
                    this.store.dispatch(new ArchiveLead(this.lead, false)).subscribe(() => {
                        this.toastr.info('Lead has been archived.');
                    });
                }
            }),
        );
        this.activeModal.dismiss();
    }

    getFields(): string {
        let list = '';
        if (this.lead.fields != null && this.lead.fields != undefined) {
            Object.keys(this.lead.fields).forEach((key) => {
                list += key.toString() + ':\t' + this.lead.fields[key] + '\n';
            });
        }
        return list;
    }

    setToggle(toggleName: string) {
        this.leadToDealForm.get(toggleName).setValue(!this.getToggle(toggleName));
    }

    getToggle(toggleName: string): boolean {
        return this.leadToDealForm.get(toggleName).value;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
