import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-financial-subnav',
    templateUrl: './financial-subnav.component.html',
    styleUrls: ['./financial-subnav.component.scss'],
})
export class FinancialSubnavComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
