<div>
    <div class="sectiontitle">Invited members</div>

    <div *ngIf="(invitedMembers$ | async)?.length === 0">
        <div class="modal-visual">
            <img src="https://image.flaticon.com/icons/svg/2206/2206437.svg" />
            <p class="m-t-2">You haven't invited anyone yet...</p>
        </div>
    </div>
    <div *ngIf="(invitedMembers$ | async)?.length > 0; else loading">
        <div
            class="list__item"
            *ngFor="let invitedMember of invitedMembers$ | async">
            <div class="list__item__content">
                {{ invitedMember.email }}
                <small>{{ invitedMember.createdOn | date: 'short':timezone | amTimeAgo }}</small>
                <small *ngIf="invitedMember.roleName"
                    >Role: <span class="badge m-r-2">{{ invitedMember.roleName }}</span></small
                >
            </div>
            <div class="list__item__extra">
                <span class="badge m-r-2">{{ invitedMember.status }}</span>
                <div ngbDropdown>
                    <span
                        ngbDropdownToggle
                        class="button button--icon dropdown-toggle button--small"></span>
                    <div
                        ngbDropdownMenu
                        class="dropdown-menu dropdown-menu-right">
                        <a class="dropdown-item">Resend invitation</a>
                        <a
                            *ngIf="invitedMember.status === 'PENDING'"
                            (click)="addRoleToInvite(invitedMember)"
                            class="dropdown-item"
                            >Give role</a
                        >
                        <a
                            *ngIf="isOwner | async"
                            class="dropdown-item color--red"
                            (click)="openCompanyInvitedMemberDeleteModal(invitedMember)"
                            >Delete invite</a
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>

    <ng-template #loading>
        <app-loading-message></app-loading-message>
    </ng-template>
</div>
<!-- / If invited members > 0 -->
