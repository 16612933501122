<div class="standalone__page">
    <div class="standalone__page__content standalone__page__content--wide">
        <div class="standalone__page__logo">
            <img src="../../../assets/img/logo.svg" />
        </div>

        <app-loading-message *ngIf="isLoading"></app-loading-message>
        <div
            *ngIf="!isLoading && error === ''"
            class="form-group m-b-4">
            <span class="d-flex space-between">
                <span>
                    <h2
                        class="modal-title"
                        id="exampleModalLabel">
                        Upload timesheet
                    </h2>
                    <p class="m-b-5">
                        {{ timesheet.employee.firstName }}
                        {{ timesheet.employee.lastName }} -
                        {{ timesheet.expirationDate | date: 'MMMM, yyyy' }}
                    </p>
                </span>
                <span>
                    <span
                        *ngIf="timesheet.status.key === 'EDIT'"
                        class="tag">
                        {{ timesheet.status.key }}
                    </span>
                    <span
                        *ngIf="timesheet.status.key === 'REVIEW'"
                        class="tag tag--danger">
                        {{ timesheet.status.key }}
                    </span>
                    <span
                        *ngIf="timesheet.status.key === 'DONE'"
                        class="tag tag--green">
                        APPROVED
                    </span>
                </span>
            </span>

            <ul class="list__specs">
                <li *ngIf="timesheet.hoursRequired">
                    <label>Required hours</label>
                    {{ timesheet.hoursRequired }} ({{ getTotalDaysFromMinutes(timesheet.hoursRequired * 60) }} days)
                </li>
                <li>
                    <label>Total hours</label>
                    {{ getTotalMinutes(timesheet) | minutesToMinutesAndHours }}
                    ({{ getTotalDaysFromTimesheet(timesheet) }} days)
                </li>
                <li>
                    <label>Hour difference</label>
                    <span
                        *ngIf="timesheet.hoursRequired > getTotalMinutes(timesheet) / 60"
                        class="tag tag--danger m-l-2">
                        <i class="las la-angle-double-down"></i>
                        {{ getRequiredHoursDiff(timesheet) | minutesToMinutesAndHours }}
                        needed ({{ getRequiredHoursDiffPercentage(timesheet) }}%)
                    </span>
                    <span
                        *ngIf="timesheet.hoursRequired == getTotalMinutes(timesheet) / 60"
                        class="tag tag--green m-l-2">
                        <i class="las la-check-circle"></i>
                        hours match
                    </span>
                    <span
                        *ngIf="timesheet.hoursRequired < getTotalMinutes(timesheet) / 60"
                        class="tag tag--danger m-l-2">
                        <i class="las la-angle-double-up"></i>
                        {{ getRequiredHoursDiff(timesheet) | minutesToMinutesAndHours }}
                        to much ({{ getRequiredHoursDiffPercentage(timesheet) }}%)
                    </span>
                </li>
            </ul>

            <app-public-timeslot-list
                [isEditable]="timesheet.status.key === 'EDIT'"
                [timesheet]="timesheet"></app-public-timeslot-list>

            <span
                *ngIf="timesheet.status.key === 'EDIT'"
                class="form-helptext">
                Changes are saved automatically. Click the request review button if your timesheet is complete.
            </span>

            <div
                class="row m-t-2"
                *ngIf="!isLoading">
                <div class="col">
                    <div class="form-group m-b-2">
                        <label>Comments</label>
                        <div
                            *ngIf="timesheet.status.key !== 'EDIT'"
                            class="message message--info m-t-2 m-b-2">
                            <p>{{ timesheet.extraInfo }}</p>
                        </div>
                        <textarea
                            *ngIf="timesheet.status.key === 'EDIT'"
                            class="resize-vertical"
                            [(ngModel)]="description"
                            (keyup)="onDescriptionKeyUp()"
                            trim="blur"></textarea>
                        <span class="d-flex space-between">
                            <span>
                                <small> {{ currentAmountOfExtraInfoCharacters }}/{{ descriptionMaxLength }} </small>
                                <span
                                    *ngIf="timesheet.status.key === 'EDIT'"
                                    class="form-helptext">
                                    In case your hours are not equal to the required amount upon requesting a review, please add an explanation why.
                                </span>
                            </span>
                        </span>
                        <span
                            class="form-error"
                            *ngIf="descriptionTooLong">
                            Extra info cannot contain more than 1000 characters
                        </span>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <div class="form-group m-b-2">
                        <label> How happy are you with your job during the last month? </label>
                        <small
                            class="form-error"
                            *ngIf="happy === null || happy === undefined">
                            Don't forget this...
                        </small>
                        <div class="d-flex">
                            <span *ngFor="let emoji of emojis">
                                <input
                                    [(ngModel)]="happy"
                                    type="radio"
                                    [id]="emoji.score"
                                    [value]="emoji.score"
                                    class="radio-btn"
                                    name="happiness"
                                    [disabled]="timesheet.status.key !== 'EDIT'" />
                                <label
                                    [for]="emoji.score"
                                    class="radio-label--icon radio-label">
                                    {{ emoji.emoji }}
                                </label>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div
            *ngIf="!isLoading && error !== ''"
            class="d-flex align-items-center">
            <p class="form-error">{{ error }}</p>
        </div>

        <div
            *ngIf="!isLoading && error === '' && timesheet.status.key === 'EDIT'"
            class="d-flex align-items-center">
            <button
                class="button button--primary w-100 m-r-1"
                [disabled]="descriptionTooLong || happy === null || happy === undefined"
                (click)="openReadyForReviewModal()">
                Save changes
            </button>
        </div>

        <div *ngIf="!isLoading && error === '' && timesheet.status.key !== 'EDIT'">
            <p>This timesheet is no longer editable. To make changes, please contact your administrator.</p>
        </div>
    </div>
</div>
