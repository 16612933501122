import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subscription, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { AuthenticationService } from '../authentication/authentication.service';
import { Company } from '../models/Company';
import { FetchCompany } from './company.actions';
import { CompanyState } from './company.state';

@Injectable({
    providedIn: 'root',
})
export class CompanyGuard implements CanActivate, OnDestroy {
    private subscriptions = new Subscription();

    @Select(CompanyState.company) company$: Observable<Company>;
    company: Company;

    constructor(private store: Store, private router: Router, private authService: AuthenticationService, private logger: NGXLogger) {}

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        if (!this.authService.isUserLoggedIn()) this.router.navigate(['/login'], { queryParams: { redirectURL: `${state.url}` } });

        this.subscriptions.add(
            this.company$.subscribe((company) => {
                this.company = company;
            }),
        );

        if (this.company) return of(true);

        this.logger.debug('Attempt to fetch Company (company-guard)');
        return this.store.dispatch(new FetchCompany()).pipe(
            tap((state) => (!state.company.company.title ? this.router.navigate(['/onboarding']) : of(true))),
            catchError((error: HttpErrorResponse) => {
                this.logger.debug('Fetching company failed, navigate to onboarding');
                this.router.navigate(['/onboarding']);
                return of(false);
            }),
            map(() => true),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
