import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';

import ChildInvoiceLink from '../../../models/ChildInvoiceLink';
import { LinkFile } from '../../../models/LinkFile';
import { LinkedObjectToFile } from '../../../models/LinkedObjectToFile';
import { Timesheet } from '../../../models/Timesheet';
import { Timeslot } from '../../../models/Timeslot';
import YukiDocument from '../../../models/yuki/YukiDocument';
import { FetchTimesheetsByProjectIdAndYearAndMonth, ResetTimesheetsByProjectIdAndYearAndMonth } from '../../../timesheets/timesheets.actions';
import { TimesheetState } from '../../../timesheets/timesheets.state';
import { roundNumber } from '../../utils/number-utils';
import { AddChildLinkConfirmFromTimesheetsModalComponent } from '../add-child-link-confirm-from-timesheets-modal/add-child-link-confirm-from-timesheets-modal.component';
import { AddSubLinkToLinkedObjectToFile, DeleteSubLinkToLinkedObjectToFile, GetAllObjectsToLink, UpdateChildInvoiceLink } from '../global-files.actions';
import { GlobalFilesState } from '../global-files.state';

@Component({
    selector: 'app-add-child-link-modal',
    templateUrl: './add-child-link-modal.component.html',
    styleUrls: ['./add-child-link-modal.component.scss'],
})
export class AddChildLinkModalComponent implements OnInit, OnDestroy {
    @Input() link: LinkedObjectToFile;
    @Input() file: YukiDocument;
    @Select(GlobalFilesState.LinkFiles) linkFiles$: Observable<LinkFile[]>;
    @Select(TimesheetState.timesheetsForProjectInMonthAndYear) timesheetsForProjectInMonthAndYear$: Observable<Timesheet[]>;
    linkFiles: LinkFile[] = [];
    shownLinkedFiles: LinkFile[] = [];
    newChildLinks: ChildInvoiceLinkDTO[] = [];
    existingLinksDatasource: MatTableDataSource<ChildInvoiceLink> = new MatTableDataSource<ChildInvoiceLinkDTO>();
    displayedColumns: string[] = ['object', 'amount', 'remove'];
    linkFileForm: FormGroup;
    amountOverflowMessage: string;
    linkFilesLoaded = false;
    timesheetsLoaded = false;
    timesheetsForProjectInMonthAndYear: Timesheet[];
    employeeWorkedMinutesForProjectMap: Map<string, number> = new Map<string, number>();
    totalHoursForProject: number;

    isUpdating = false;
    isSaving = false;
    isDeleting = false;
    unsavedChanges: boolean;

    private subscriptions = new Subscription();

    constructor(private store: Store, public activeModal: NgbActiveModal, private fb: FormBuilder, private modalService: NgbModal) {}

    ngOnInit(): void {
        this.createLinkFileForm();
        this.fetchData();
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
        this.store.dispatch(new ResetTimesheetsByProjectIdAndYearAndMonth());
    }

    updateLinkType(value: string) {
        this.shownLinkedFiles = this.linkFiles.filter((linkFile) => linkFile.type.toLowerCase() === value.toLowerCase());
    }

    addLinkFile(linkFile: LinkFile) {
        const newChildLink: ChildInvoiceLinkDTO = {
            objectIdForKey: this.link.objectId,
            fileIdForKey: this.file.uuid,
            newObjectLinkId: linkFile.uuid,
            type: linkFile.type,
            amount: this.calculateAmount(),
        };
        this.newChildLinks.push(newChildLink);
        this.saveNewChildLinks();
        this.linkFileForm.controls['object'].reset();
    }

    saveNewChildLinks() {
        this.isSaving = true;
        this.subscriptions.add(
            this.store.dispatch(new AddSubLinkToLinkedObjectToFile(this.newChildLinks)).subscribe({
                next: () => {
                    this.isSaving = false;
                    this.newChildLinks = [];
                    this.store
                        .selectOnce(GlobalFilesState.LinkedObjectsToFileWithId)
                        .subscribe(
                            (linkedObjects) =>
                                (this.existingLinksDatasource.data = linkedObjects.linkedObjects.find(
                                    (linkedObject) => linkedObject.objectId === this.link.objectId,
                                ).childInvoiceLinks),
                        );
                },
            }),
        );
    }

    deleteExistingLink(row) {
        this.isDeleting = true;
        this.subscriptions.add(
            this.store.dispatch(new DeleteSubLinkToLinkedObjectToFile(row.id)).subscribe({
                next: (value) => {
                    this.isDeleting = false;
                    this.existingLinksDatasource.data = value.globalFiles.linkedObjectsToFile.linkedObjects.find(
                        (linkedObject) => linkedObject.objectId === this.link.objectId,
                    ).childInvoiceLinks;
                },
            }),
        );
    }

    updateLinkAmount(row: ChildInvoiceLink, newPrice: number) {
        this.isUpdating = true;
        const childInvoiceLinkDTO: ChildInvoiceLinkDTO = {
            amount: newPrice,
        };
        this.subscriptions.add(
            this.store.dispatch(new UpdateChildInvoiceLink(row.id, childInvoiceLinkDTO)).subscribe({
                next: () => {
                    this.isUpdating = false;
                    this.unsavedChanges = false;
                },
            }),
        );
    }

    inputEvent() {
        this.unsavedChanges = true;
    }

    checkAmountOverflow(): void {
        if (this.calculateLinkedAmount() > this.link.price) {
            this.amountOverflowMessage = `Linked amount is over total amount of parent by `;
        } else if (this.calculateLinkedAmount() < this.link.price) {
            this.amountOverflowMessage = `Linked amount is under total amount of parent by `;
        } else {
            this.amountOverflowMessage = null;
        }
    }

    private fetchData() {
        this.existingLinksDatasource.data = this.link.childInvoiceLinks;
        this.subscriptions.add(
            this.linkFiles$.subscribe((linkFiles) => {
                if (!linkFiles) {
                    this.store.dispatch(new GetAllObjectsToLink());
                    return;
                }
                this.linkFilesLoaded = true;
                this.linkFiles = linkFiles;
                this.shownLinkedFiles = this.linkFiles.filter((linkFile) => linkFile.type.toLowerCase() === 'project'.toLowerCase());

                if (this.timesheetsLoaded) this.calculateDefaultSublinkPercentages();
            }),
        );

        if (this.link.objectType.toLowerCase() !== 'project' || this.file.folderId !== 2 || !this.fetchingTimesheetsIsNecessary()) return;

        this.subscriptions.add(
            this.timesheetsForProjectInMonthAndYear$.subscribe((timesheets) => {
                if (!timesheets) {
                    const documentDate: Date = new Date(this.file.documentDate);
                    this.store.dispatch(
                        new FetchTimesheetsByProjectIdAndYearAndMonth(this.link.objectId, documentDate.getFullYear(), documentDate.getMonth() + 1),
                    );

                    return;
                }

                this.timesheetsLoaded = true;
                this.timesheetsForProjectInMonthAndYear = timesheets;

                if (this.linkFilesLoaded) this.calculateDefaultSublinkPercentages();
            }),
        );
    }

    private fetchingTimesheetsIsNecessary(): boolean {
        return !this.link.childInvoiceLinks || this.link.childInvoiceLinks?.length === 0;
    }

    private calculateDefaultSublinkPercentages() {
        this.totalHoursForProject = this.timesheetsForProjectInMonthAndYear.reduce(
            (total: number, timesheet: Timesheet) =>
                total +
                timesheet.timeslots.reduce((total: number, timeslot: Timeslot) => {
                    if (timeslot.project.uuid === this.link.objectId) {
                        this.employeeWorkedMinutesForProjectMap.set(timesheet.employee.uuid, timeslot.minutes);
                        return total + timeslot.minutes;
                    }

                    return total;
                }, 0),
            0,
        );

        const newLinksFromTimesheets: ChildInvoiceLinkDTO[] = [];
        this.timesheetsForProjectInMonthAndYear.forEach((timesheet) => {
            const newChildLink: ChildInvoiceLinkDTO = {
                objectIdForKey: this.link.objectId,
                fileIdForKey: this.file.uuid,
                newObjectLinkId: timesheet.employee.uuid,
                amount: roundNumber((this.employeeWorkedMinutesForProjectMap.get(timesheet.employee.uuid) / this.totalHoursForProject) * this.link.price),
                type: 'employee',
            };
            newLinksFromTimesheets.push(newChildLink);
        });

        if (newLinksFromTimesheets.length > 0) {
            const modalRef = this.modalService.open(AddChildLinkConfirmFromTimesheetsModalComponent, {
                windowClass: 'modal-prompt modal-prompt--min-content',
                animation: false,
            });
            modalRef.componentInstance.newChildLinks = newLinksFromTimesheets;
            modalRef.componentInstance.employeeWorkedMinutesForProjectMap = this.employeeWorkedMinutesForProjectMap;
            modalRef.componentInstance.totalHoursForProject = this.totalHoursForProject;
            modalRef.componentInstance.linkPrice = this.link.price;
            modalRef.componentInstance.alreadyLinkedPrice = this.link.childInvoiceLinks?.reduce(
                (previousValue: number, currentValue: ChildInvoiceLinkDTO) => previousValue + currentValue.amount,
                0,
            );

            modalRef.result.then(() => {
                this.store
                    .selectOnce(GlobalFilesState.LinkedObjectsToFileWithId)
                    .subscribe(
                        (linkedObjects) =>
                            (this.existingLinksDatasource.data = linkedObjects.linkedObjects.find(
                                (linkedObject) => linkedObject.objectId === this.link.objectId,
                            )?.childInvoiceLinks),
                    );
            });
        }
    }

    private createLinkFileForm() {
        this.linkFileForm = this.fb.group({
            object: [null, Validators.required],
        });
    }

    private calculateLinkedAmount(): number {
        const totalAssignedAmount =
            this.existingLinksDatasource.data?.reduce((previousValue: number, currentValue: ChildInvoiceLinkDTO) => previousValue + currentValue.amount, 0) +
            this.newChildLinks.reduce((previousValue: number, currentValue: ChildInvoiceLinkDTO) => previousValue + currentValue.amount, 0);
        return roundNumber(totalAssignedAmount);
    }

    private calculateAmount(): number {
        return roundNumber(this.link.price - this.calculateLinkedAmount());
    }
}

export class ChildInvoiceLinkDTO {
    objectIdForKey?: string;
    fileIdForKey?: string;
    newObjectLinkId?: string;
    amount?: number;
    type?: string;
}
