import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-mfa-not-enabled-popup',
    templateUrl: './mfa-not-enabled-popup.component.html',
    styleUrls: ['./mfa-not-enabled-popup.component.scss'],
})
export class MfaNotEnabledPopupComponent {
    constructor(public activeModal: NgbActiveModal) {}
}
