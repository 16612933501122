import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { RemoveApplicantReasonOfLoss, UpdateApplicantReasonOfLoss } from 'src/app/applicants/applicant.actions';
import { RemoveDealReasonOfLoss, UpdateDealReasonOfLoss } from 'src/app/crm/crm.actions';
import ReasonOfLossModel from 'src/app/models/ReasonOfLossModel';

import { EditReasonModalComponent } from '../edit-reason-modal/edit-reason-modal.component';

@Component({
    selector: 'app-reason-of-loss-list',
    templateUrl: './reason-of-loss-list.component.html',
    styleUrls: ['./reason-of-loss-list.component.scss'],
})
export class ReasonOfLossListComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Input() reasons: ReasonOfLossModel[];
    @Input() linkedObjectType: string;
    @Input() linkedObjects: any[];
    loading = true;
    removeFunction;
    updateFunction;

    displayedColumns: string[] = ['reason', 'recurrence', 'references', 'edit'];
    dataSource: MatTableDataSource<ReasonOfLossModel> = new MatTableDataSource<ReasonOfLossModel>();
    @ViewChild(MatSort) sort: MatSort;

    constructor(
        private store: Store,
        public activeModal: NgbActiveModal,
        private logger: NGXLogger,
        private toastr: ToastrService,
        private modalService: NgbModal,
    ) {}

    ngOnInit(): void {
        console.log(this.reasons);
        console.log(this.linkedObjectType);
        console.log(this.linkedObjects);
        switch (this.linkedObjectType) {
            case 'applicant':
                this.removeFunction = RemoveApplicantReasonOfLoss;
                this.updateFunction = UpdateApplicantReasonOfLoss;
                break;
            case 'deal':
                this.removeFunction = RemoveDealReasonOfLoss;
                this.updateFunction = UpdateDealReasonOfLoss;
        }
        this.dataSource.data = this.reasons;
        this.loading = false;
        console.log(this.reasons);
        console.log(this.linkedObjectType);
        console.log(this.linkedObjects);
    }

    getLinkedObjectsByReason(reasonUuid) {
        return this.linkedObjects.filter((obj) => obj.reason?.uuid === reasonUuid);
    }

    updateReason(reason: ReasonOfLossModel) {
        const modalRef = this.modalService.open(EditReasonModalComponent, {
            windowClass: 'modal-prompt',
        });
        modalRef.componentInstance.reason = reason;
    }

    deleteReason(reasonUuid: string) {
        this.subscriptions.add(
            this.store.dispatch(new this.removeFunction(reasonUuid)).subscribe({
                next: () => {
                    this.toastr.info('Successfully removed reason of loss.');
                },
                error: (error) => {
                    this.logger.error(error);
                    this.toastr.error('Oops something went wrong, please try again later.');
                },
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
