import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subscription } from 'rxjs';
import { CompanyInvitedMember } from 'src/app/models/CompanyInvitedMember';
import DateHelper from 'src/app/services/DateHelper';
import { RoleMemberAssignModalComponent } from 'src/app/settings/roles/role-member-assign-modal/role-member-assign-modal.component';
import { UserState } from 'src/app/users/user.state';

import { CompanyInvitedMemberDeleteModalComponent } from '../company-invited-member-delete-modal/company-invited-member-delete-modal.component';
import { GetCompanyInvitedMembers } from '../company.actions';
import { CompanyState } from '../company.state';

@Component({
    selector: 'app-company-invited-members-list',
    templateUrl: './company-invited-members-list.component.html',
    styleUrls: ['./company-invited-members-list.component.scss'],
})
export class CompanyInvitedMembersListComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Input() companyUuid: string;
    @Select(CompanyState.companyInvitedMembers) invitedMembers$: Observable<CompanyInvitedMember[]>;
    @Select(UserState.hasUserOwnerRole) isOwner$: Observable<boolean>;
    currentDate: Date;
    timeZone = DateHelper.getLocalTimeZone();

    constructor(private store: Store, private modalService: NgbModal, private logger: NGXLogger) {}

    ngOnInit(): void {
        this.logger.debug('Attempt to retrieve Invited companyMembers');
        this.subscriptions.add(
            this.store.dispatch(new GetCompanyInvitedMembers()).subscribe(
                () => undefined,
                (error) => this.logger.error('Failed to retrieve invited CompanyMembers'),
            ),
        );
        this.currentDate = new Date();
    }

    openCompanyInvitedMemberDeleteModal(invitedMember: CompanyInvitedMember) {
        this.logger.debug('Opening companyInvitedMemberDeleteModalComponent');
        const modalRef = this.modalService.open(CompanyInvitedMemberDeleteModalComponent, { windowClass: 'modal-prompt' });
        modalRef.componentInstance.inviteMember = invitedMember;
        modalRef.componentInstance.companyUuid = this.companyUuid;
    }

    addRoleToInvite(invitedMember: CompanyInvitedMember) {
        this.logger.debug('Opening AddRole');
        const modalRef = this.modalService.open(RoleMemberAssignModalComponent, { windowClass: 'modal-prompt' });
        modalRef.componentInstance.invite = invitedMember;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
