import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { RemoveCosts } from '../../budget/budget.actions';
import { ResetFinancialData } from '../../financial/financial.actions';

@Injectable()
export class RemoveFinancialDataInterceptor implements HttpInterceptor {
    constructor(private store: Store) {}

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if ((request.url.includes('budget') || request.url.includes('file/link') || request.url.includes('yuki')) && request.method !== 'GET') {
            this.store.dispatch(new ResetFinancialData());
            this.store.dispatch(new RemoveCosts());
        }
        return next.handle(request);
    }
}
