import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subscription } from 'rxjs';
import { FetchCrmDeals } from 'src/app/crm/crm.actions';
import { CrmState } from 'src/app/crm/crm.state';
import CrmDeal from 'src/app/models/CrmDeal';

@Component({
    selector: 'app-dashboard-crm-warnings-widget',
    templateUrl: './dashboard-crm-warnings-widget.component.html',
    styleUrls: ['./dashboard-crm-warnings-widget.component.scss'],
})
export class DashboardCrmWarningsWidgetComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Select(CrmState.CrmDeals) deals$: Observable<CrmDeal[]>;

    dealsInWarningStatus: CrmDeal[] = [];

    displayedColumns: string[] = ['deal', 'price', 'dateSinceLastMovement'];
    dataSource: MatTableDataSource<CrmDeal>;
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    isLoading = true;

    constructor(private store: Store, private router: Router, private logger: NGXLogger) {}

    ngOnInit(): void {
        this.subscriptions.add(
            this.deals$.subscribe((deals) => {
                if (deals == null) {
                    this.store.dispatch(new FetchCrmDeals());
                } else {
                    this.dealsInWarningStatus = [];
                    deals.forEach((deal) => {
                        if (this.isWarning(deal.dateSinceLastMovement, deal.crmStage.rottingIn, deal.crmStage.type)) {
                            this.dealsInWarningStatus.push(deal);
                        }
                    });
                    this.dataSource = new MatTableDataSource(this.dealsInWarningStatus);
                    this.dataSource.sort = this.sort;
                    this.isLoading = false;
                }
            }),
        );
    }

    isWarning(dateSinceLastMovement, rottingIn: number, type: string): boolean {
        if (type != 'WIN' && type != 'LOSS' && !this.isOverdue(dateSinceLastMovement, rottingIn, type)) {
            const date = new Date(dateSinceLastMovement);
            const now = new Date();
            return date.getDate() + rottingIn - 1 <= now.getDate();
        }
        return false;
    }

    isOverdue(dateSinceLastMovement, rottingIn: number, type: string): boolean {
        if (type != 'WIN' && type != 'LOSS') {
            const date = new Date(dateSinceLastMovement);
            const now = new Date();
            return date.getDate() + rottingIn <= now.getDate();
        }
        return false;
    }

    goToCRM() {
        this.logger.debug('redirecting to CRM');
        this.router.navigate(['/', 'crm']);
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
